export class SecurityQuestion {
    id: any;
    value: string;
    question: string;
    errors = [];

    constructor(key, obj) {
        if (key) {
            this.id = key;
        }
        if (obj) {
            this.question = obj['secretQuestion'];
        }
    }
}
