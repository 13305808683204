import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'material-selectgroup',
    templateUrl: './selectgroup.component.html',
})
export class MaterialSelectGroupComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() appearance: any = 'standard';
    @Input() options: any[];
    @Input() placeholder: string;
    @Input() name: string;
    @Input() title: any;
    @Input() model: any;
    @Input() item: string;
    @Input() id: string;
    @Input() classList: string[];
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() disabled: boolean;
    @Input() optionDisplayNameProperty?: string;
    @Input() optionValueProperty?: string;
    @Input() groupDisplayNameProperty?: string;
    @Input() groupValueProperty?: string;
    @Input() emitFirstEvent?: boolean;
    @Input() noSelectIfValidOptionSelected: boolean;
    @Input() hideSelectIfValueExists: boolean;
    @Input() isMultple: boolean;
    @Input() noOptionsInstruction: string;

    @Output() modelChange = new EventEmitter();
    @Output() optionData = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() selectedOption = new EventEmitter();

    searchColumns: string;
    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;

    constructor() {}

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({ errors: this.errors });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.model) {
            this.control.value !== changes.model.currentValue && this.control.setValue(this.model);
        }
        if (changes.options) {
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    modelChanged = (event) => {
        this.model = event;
        this.modelChange.next(this.model);
        this.debounceEventHandler.emit(event);
    };

    sendOptionsData = (option) => {
        this.optionData.next(option);
    };

    selected = (option) => {
        this.selectedOption.emit(option);
    };

    ngOnInit() {
        this.appearance = this.appearance ? this.appearance : 'standard';
        this.valueSubscription = this.control.valueChanges.subscribe((event) => this.modelChanged(event));
        if (this.model !== undefined && this.emitFirstEvent) {
            this.modelChanged(this.model);
        }
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
