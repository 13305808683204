import { EntityAdapter } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { AppIntegration } from '../../interface/appintegration.interface';
import { Integration } from '../../interface/integration.interface';
import { IntegrationConfigField } from '../../interface/integrationmodal.interface';
import { IntegrationRecordPayload } from '../../models/integrations/integrationrecordpayload.class';
import {
    AppIntegrationState,
    IntegrationOrganizationsState,
    IntegrationsState,
    IntergrationsConfigState,
} from '../interface/integration-state.interface';

export enum IntegrationActionTypes {
    INTEGRATION_SET_ACTIVE_TAB = '[INTEGRATIONS] Set Active Tab',
    INTEGRATION_SET_ORGANIZATION_ENTITY = '[INTEGRATIONS] Set Organization Entity',
    INTEGRATION_SET_CONFIG_ENTITY = '[INTEGRATIONS] Set Config Entity',
    INTEGRATION_GET_RECORDS = '[INTEGRATIONS] Get Records',
    INTEGRATION_SET_RECORDS = '[INTEGRATIONS] Set Records',
    INTEGRATION_TEST_CONFIGURATION = '[INTEGRATIONS] Test Configuration',
    INTEGRATION_SAVE_CONFIGURATION = '[INTEGRATIONS] Save Configuration',
    INTEGRATION_VALIDATE_CONFIGURATION = '[INTEGRATIONS] Validate Configuration',
    INTEGRATION_VALIDATE_CONFIGURATION_SUCCESS = '[INTEGRATIONS] Validate Configuration Success',
    INTEGRATION_TOGGLE_CONFIGURATION = '[INTEGRATIONS] Toggle Configuration',
    INTEGRATION_UPDATE_CONFIGURATION_CHANGE = '[INTEGRATIONS] Update Configuration Change',
    INTEGRATION_PUSH_CONFIGURATION = '[INTEGRATIONS] Push Configuration',
    INTEGRATION_DELETE_CONFIGURATION = '[INTEGRATIONS] Delete Configuration',
    INTEGRATION_REMOVE_CONFIGURATION = '[INTEGRATIONS] Remove Configuration',
    GET_APP_INTEGRATION = '[GET_APP_INTEGRATIO] Get App Integration',
    SET_APP_INTEGRATION = '[SET_APP_INTEGRATIO] Set App Integration',
    DELETE_APP_INTEGRATION_DETAILS = '[DELETE_APP_INTEGRATION_DETAILS] Delete App Integration Details',
    DELETE_APP_INTEGRATION_DETAILS_SUCCESS = '[DELETE_APP_INTEGRATION_DETAILS_SUCCESS] Delete App Integration Details Success',
    GET_INTEGRATION_CONFIGURATIONS = '[INTEGRATIONS] Get Integration Configurations',
    SET_APP_INTEGRATION_CONFIGURATIONS = '[INTEGRATIONS] Set App Integration Configurations',
}

export const SetIntegrationOrganizationEntity = createAction(
    IntegrationActionTypes.INTEGRATION_SET_ORGANIZATION_ENTITY,
    props<{
        organizationsEntityAdapter: EntityAdapter<IntegrationOrganizationsState>;
        organizationEntity: IntegrationOrganizationsState;
    }>()
);

export const SetActiveIntegrationTab = createAction(
    IntegrationActionTypes.INTEGRATION_SET_ACTIVE_TAB,
    props<{
        tab: IntegrationsState['activeTab'];
    }>()
);

export const SetIntegrationConfigEntity = createAction(
    IntegrationActionTypes.INTEGRATION_SET_CONFIG_ENTITY,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        organizationEntity: IntegrationOrganizationsState;
        configsEntityAdapter: EntityAdapter<IntergrationsConfigState>;
        configsEntity: IntergrationsConfigState;
    }>()
);

export const GetIntegrationRecords = createAction(
    IntegrationActionTypes.INTEGRATION_GET_RECORDS,
    props<{
        config?: IntegrationsState['activeTab'];
        isMaster?: boolean;
        dontFetchUsingMasters?: boolean;
    }>()
);

export const SetIntegrationRecords = createAction(
    IntegrationActionTypes.INTEGRATION_SET_RECORDS,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        configsAdapter: EntityAdapter<IntergrationsConfigState>;
        integrationsAdapter: EntityAdapter<Integration>;
        records: Integration[];
    }>()
);

export const TestIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_TEST_CONFIGURATION,
    props<{
        payload: {
            configuredData: string;
            appId: string;
            name: string;
            organizationId: string;
            type: string;
        };
    }>()
);

export const SaveIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_SAVE_CONFIGURATION,
    props<{
        payload: IntegrationRecordPayload;
    }>()
);

export const ValidateIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_VALIDATE_CONFIGURATION,
    props<{
        payload: IntegrationRecordPayload;
    }>()
);

export const ValidateIntegrationConfigurationSuccess = createAction(
    IntegrationActionTypes.INTEGRATION_VALIDATE_CONFIGURATION_SUCCESS,
    props<{
        redirectURL: string;
    }>()
);

export const ToggleIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_TOGGLE_CONFIGURATION,
    props<{
        payload: IntegrationRecordPayload;
    }>()
);

export const PushIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_PUSH_CONFIGURATION,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        configsAdapter: EntityAdapter<IntergrationsConfigState>;
        integrationsAdapter: EntityAdapter<Integration>;
        record: IntegrationRecordPayload;
    }>()
);

export const UpdateIntegrationConfigurationChange = createAction(
    IntegrationActionTypes.INTEGRATION_UPDATE_CONFIGURATION_CHANGE,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        configsAdapter: EntityAdapter<IntergrationsConfigState>;
        integrationsAdapter: EntityAdapter<Integration>;
        record: IntegrationRecordPayload;
    }>()
);

export const DeleteIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_DELETE_CONFIGURATION,
    props<{
        record: IntegrationRecordPayload;
    }>()
);

export const RemoveIntegrationConfiguration = createAction(
    IntegrationActionTypes.INTEGRATION_REMOVE_CONFIGURATION,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        configsAdapter: EntityAdapter<IntergrationsConfigState>;
        integrationsAdapter: EntityAdapter<Integration>;
        record: IntegrationRecordPayload;
    }>()
);

export const GetAppIntegration = createAction(
    IntegrationActionTypes.GET_APP_INTEGRATION,
    props<{
        integrationType: string;
        serviceId: string;
    }>()
);

export const SetAppIntegration = createAction(
    IntegrationActionTypes.SET_APP_INTEGRATION,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        appIntegrationAdapter: EntityAdapter<AppIntegrationState>;
        integrationsAdapter: EntityAdapter<AppIntegration>;
        serviceId: string;
        appIntegrationDetails: any;
    }>()
);

export const DeleteAppIntegrationDetails = createAction(
    IntegrationActionTypes.DELETE_APP_INTEGRATION_DETAILS,
    props<{
        configName: string;
        serviceId: string;
    }>()
);

export const DeleteAppIntegrationDetailsSuccess = createAction(
    IntegrationActionTypes.DELETE_APP_INTEGRATION_DETAILS_SUCCESS,
    props<{
        organizationAdapter: EntityAdapter<IntegrationOrganizationsState>;
        appIntegrationAdapter: EntityAdapter<AppIntegrationState>;
        integrationsAdapter: EntityAdapter<AppIntegration>;
        configName: string;
        serviceId: string;
    }>()
);

export const GetIntegrationsConfig = createAction(IntegrationActionTypes.GET_INTEGRATION_CONFIGURATIONS);

export const SetAppIntegrationConfigs = createAction(
    IntegrationActionTypes.SET_APP_INTEGRATION_CONFIGURATIONS,
    props<{
        appIntegrationConfigs: {
            inbound: IntegrationConfigField['options'];
            outbound: IntegrationConfigField['options'];
            masterinbound: IntegrationConfigField['options'];
        };
    }>()
);
