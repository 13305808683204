import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface SharedState extends EntityState<any> {
    messages: string[];
    lastMessage: string;
    logoHref: string;
    supportedRegions: string[];
    selectedCategory: string;
    searchText: string;
    leftNavMap: {
        name: string;
        id: string;
        src: string;
        srcHover: string;
        apps: { order: number; serviceId: string }[];
        groups: { id: string; order: number }[];
        packages: {
            name: string;
            id: string;
            src: string;
            srcHover: string;
            apps: { order: number; serviceId: string; displayName: string; restApiName: string }[];
            groups: { id: string; order: number }[];
        }[][];
    }[];
    groupsMap: {
        [property: string]: {
            order: number;
            serviceId: string;
        }[];
    };
}

export const SharedAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const sharedState: SharedState = SharedAdapter.getInitialState({
    messages: [],
    lastMessage: undefined,
    logoHref: '',
    supportedRegions: [],
    selectedCategory: '',
    searchText: '',
    leftNavMap: [],
    groupsMap: {},
});
