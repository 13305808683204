<div class="rolePermissionsContainer w-100" *transloco="let translate;">
    <mat-tab-group #matTabGroup (selectedTabChange)="selected && permissionNames[selected.value] && setPermissionContext(AllRolePermissions[selected.value])" (selectedIndexChange)="selected.setValue($event)" [selectedIndex]="selected?.value">
        <mat-tab *ngFor="let permissionName of permissionNames">
            <ng-template mat-tab-label>
                {{permissionName}} {{translate('Permissions')}}
            </ng-template>
            <div class="permissionsTabContentContainer">
                <perfect-scrollbar [ngClass]="isCheckboxNeeded? 'parentHeight1':'parentHeight2'">
                    <div class="permissionsTopRow col-12" *ngIf="isCheckboxNeeded">
                        <ng-container *ngIf="(activePermission === 'Assets' || activePermission === 'BridgeAssets') || (searchAssetValue && searchAssetValue.length > 0)">
                            <div class="searchAssetDiv col-6">
                                <mat-form-field class="search-form-field col-8" floatLabel='never'>
                                    <input matInput placeholder="{{translate('Search')}}" [(ngModel)]="searchAssetValue" (keyup)='searchAssets(searchAssetValue)'>
                                    <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearch()'>
                                        <mat-icon class='locationManagementIcons' *ngIf='searchAssetValue'>
                                            close
                                        </mat-icon>
                                        <mat-icon *ngIf='!searchAssetValue' class="locationManagementIcons">
                                            search
                                        </mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="displayList && activePermission === 'Widgets'">
                            <div class="masterSelectAllDiv col-6">
                                <div class="col-6" *ngIf="(displayList.permissions !== undefined && displayList.permissions.length !== 0) || (displayList.subSections && displayList.subSections.length)">
                                    <material-checkbox (click)="$event.stopPropagation()" [name]="translate('Select All')" [showName]="true" [model]="displayList.selected" (modelChange)="displayList.selected = $event; assignPermissionSelection($event, displayList, displayList)">
                                    </material-checkbox>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="displayList && activePermission === 'Masters'">
                            <div class="masterSelectAllDiv col-6">
                                <div class="col-6" *ngIf="displayList.subSections !== undefined && displayList.subSections.length !== 0">
                                    <material-checkbox (click)="$event.stopPropagation()" [name]="translate('Select All')" [showName]="true" [model]="displayList.selected" (modelChange)="displayList.selected = $event; assignPermissionSelection($event,displayList, displayList)">
                                    </material-checkbox>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="displayList && !(isEmpty(displayList))">
                        <ng-container *ngTemplateOutlet="permissionsTemplate; context: { displayList: displayList, mainParent: displayList}"></ng-container>
                        <ng-container *ngIf="displayList && displayList.subSections && displayList.subSections.length > 0 && (activePermission === 'Assets' || activePermission === 'BridgeAssets') && isCheckboxNeeded">
                            <encomply-material-paginator [pagination]="pagination" (fetchItemsInPage)="fetchPermissionsInPage($event)" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize($event)" [customPageSizeOptions]="true">
                            </encomply-material-paginator>
                        </ng-container>
                    </ng-container>
                </perfect-scrollbar>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #permissionsTemplate let-displayList="displayList" let-mainParent="mainParent">
    <ng-container *ngIf="displayList.permissions && displayList.permissions.length > 0">
        <ng-container *transloco="let translate;">
            <div class="orgSelectAllDiv" *ngIf="isCheckboxNeeded">
                <material-checkbox (click)="$event.stopPropagation()" [name]="translate('Select All')" [showName]="true" [model]="displayList.permissions.selected" (modelChange)="displayList.permissions.selected = $event; assignPermissionSelection($event,displayList.permissions, mainParent)">
                </material-checkbox>
            </div>
            <ng-container *ngIf="isCheckboxNeeded">
                <div class="row parentPermissionsDiv">
                    <ng-container *ngFor="let permission of displayList.permissions; trackBy: trackPermission">
                        <div class="col-3 orgPermissionsContainer" *ngIf="!(removePermissions.indexOf(permission.displayName)>-1)">
                            <ul>
                                <li>
                                    <material-checkbox [name]="permission.displayName" [showName]="true" [id]="permission.uuid" [model]="permission.selected" (modelChange)="permission.selected = $event; assignPermissionSelection($event,permission, mainParent)">
                                    </material-checkbox>
                                    <span class="privateMaster" *ngIf="privateMasters[permission.key]" [title]="translate('Private Master')">
                                        <span class="material-icons">
                                            vpn_key
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!isCheckboxNeeded">
                <div class="row parentPermissionsDiv">
                    <ng-container *ngFor="let permission of displayList.permissions; trackBy: trackPermission">
                        <div class="col-3 orgPermissionsContainer viewPermissionItem" *ngIf="(!(removePermissions.indexOf(permission.displayName)>-1)) && permission.selected">
                            <ul>
                                <li>
                                    <span>{{permission.displayName}}</span>
                                    <span class="privateMaster" *ngIf="privateMasters[permission.key]" [title]="translate('Private Master')">
                                        <span class="material-icons">
                                            vpn_key
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="displayList.subSections?.length > 0; else noPermissionsTemplate">
        <div *transloco="let translate;">
            <div class="assetSelectAllDiv" *ngIf="isCheckboxNeeded && (activePermission === 'Assets' || activePermission === 'BridgeAssets')">
                <material-checkbox (click)="$event.stopPropagation()" [name]="translate('Select All')" [showName]="true" [model]="displayList.selected" (modelChange)="displayList.selected = $event; checkAllPermissions($event, displayList, mainParent)">
                </material-checkbox>
            </div>
        </div>
        <perfect-scrollbar [ngClass]="isCheckboxNeeded? 'height1':'height2'">
            <ng-container *ngFor="let subSectionPermission of displayList.subSections; trackBy: trackSubSection">
                <ng-container *ngIf="isCheckboxNeeded">
                    <material-accordion [showFooter]="false" [showContentOnlyOnExpand]="true">
                        <div class="materialAccordionHeader" *transloco="let translate;">
                            <material-checkbox (click)="$event.stopPropagation()" [name]="subSectionPermission.displayName || subSectionPermission.name" [showName]="true" [model]="subSectionPermission.selected" (modelChange)="subSectionPermission.selected = $event; assignPermissionSelection($event,subSectionPermission,mainParent)">
                            </material-checkbox>
                            <span class="privateMaster" *ngIf="privateMasters[subSectionPermission.key]" [title]="translate('Private Master')">
                                <span class="material-icons">
                                    vpn_key
                                </span>
                            </span>
                        </div>
                        <div class="materialAccordionBody">
                            <ng-container *ngTemplateOutlet="mainSubpermissionsTemplate; context: { displayList: subSectionPermission, parentPermission: displayList, mainParent: mainParent}"></ng-container>
                        </div>
                    </material-accordion>
                </ng-container>
                <ng-container *ngIf="!isCheckboxNeeded && subSectionPermission.selected">
                    <material-accordion [showFooter]="false" [showContentOnlyOnExpand]="true">
                        <div class="materialAccordionHeader" *transloco="let translate;">
                            <span class="assetCheckboxSpan">{{subSectionPermission.displayName || subSectionPermission.name}}</span>
                            <span class="privateMaster" *ngIf="privateMasters[subSectionPermission.key]" [title]="translate('Private Master')">
                                <span class="material-icons">
                                    vpn_key
                                </span>
                            </span>
                        </div>
                        <div class="materialAccordionBody">
                            <ng-container *ngTemplateOutlet="mainSubpermissionsTemplate; context: { displayList: subSectionPermission, parentPermission: displayList, mainParent: mainParent}"></ng-container>
                        </div>
                    </material-accordion>
                </ng-container>
            </ng-container>
        </perfect-scrollbar>
    </ng-container>
</ng-template>

<ng-template #mainSubpermissionsTemplate let-displayList="displayList" let-parentPermission="parentPermission" let-mainParent="mainParent">
    <ng-container *ngIf="displayList.permissions && displayList.permissions.length > 0">
        <div class="row permissionSectionHead" *ngIf="isCheckboxNeeded" [ngClass]="{'mainPermissionSection':['Masters', 'Widgets'].indexOf(parentPermission.name) < 0}">
            <div class="col-3" *ngIf="['Masters', 'Widgets'].indexOf(parentPermission.name) < 0">
                <material-checkbox class="assetCheckbox" (click)="$event.stopPropagation()" [name]="'selectAllPermissions'" [model]="displayList.permissions.selected" (modelChange)="displayList.permissions.selected = $event; assignPermissionSelection($event, displayList.permissions, mainParent)">
                </material-checkbox>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'Assets'">Menu & Actions Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'assets'">Selected Asset Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'BridgeAssets'">Bridge Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'Recons'">Selected Recon Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="['Assets', 'assets', 'BridgeAssets', 'Recons', 'recons'].indexOf(parentPermission.name) < 0">Select All</span>
            </div>
            <div class="col-9">
                <div class="row PermissionsDiv">
                    <ng-container *ngFor="let permission of displayList.permissions; trackBy: trackPermission">
                        <div class="col-3 rolePermissionsCheckBoxContainer">
                            <ul>
                                <li class="appPermissionsList">
                                    <material-checkbox [name]="permission.displayName" [showName]="true" [id]="permission.uuid" [model]="permission.selected" (modelChange)="permission.selected = $event; assignPermissionSelection($event,permission, mainParent)">
                                    </material-checkbox>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row permissionSectionHead" *ngIf="!isCheckboxNeeded && checkPermissionSelected(displayList.permissions)" [ngClass]="{'mainPermissionSection':['Masters', 'Widgets'].indexOf(parentPermission.name) < 0}">
            <div class="col-3" *ngIf="['Masters', 'Widgets'].indexOf(parentPermission.name) < 0">
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'Assets'">Menu & Actions Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'assets'">Selected Asset Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'BridgeAssets'">Bridge Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="parentPermission.name === 'Recons'">Selected Recon Permissions</span>
                <span class="assetCheckboxSpan" *ngIf="['Assets', 'assets', 'BridgeAssets', 'Recons', 'recons'].indexOf(parentPermission.name) < 0">Select All</span>
            </div>
            <div class="col-9">
                <div class="row PermissionsDiv">
                    <ng-container *ngFor="let permission of displayList.permissions; trackBy: trackPermission">
                        <ng-container *ngIf="permission.selected">
                            <div class="col-3 rolePermissionsCheckBoxContainer">
                                <ul>
                                    <li class="appPermissionsList permissionList">
                                        <span>{{permission.displayName}}</span>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="displayList.subSections && displayList.subSections.length > 0">
        <ng-container *ngFor="let subSectionPermission of displayList.subSections; trackBy: trackSubSection">
            <ng-container *ngIf="isCheckboxNeeded">
                <ng-container *ngIf="subSectionPermission.name !== 'assets'">
                    <ng-container *ngIf="subSectionPermission && ((subSectionPermission.permissions && subSectionPermission.permissions.length > 0) || (subSectionPermission.subSections && subSectionPermission.subSections.length > 0) )">
                        <div class="row subSectionPermissionDiv">
                            <div class="col-3">
                                <material-checkbox class="assetCheckbox" (click)="$event.stopPropagation()" [name]="subSectionPermission.name | capitalize" [showName]="true" [model]="subSectionPermission.selected" (modelChange)="subSectionPermission.selected = $event; assignPermissionSelection($event,subSectionPermission,mainParent)">
                                </material-checkbox>
                            </div>
                            <div class="col-9">
                                <ng-container *ngTemplateOutlet="SubpermissionsTemplate; context: { displayList: subSectionPermission, mainParent: mainParent}"></ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="subSectionPermission.name === 'assets'">
                    <ng-container *ngTemplateOutlet="bridgeAssetsTemplate; context: { displayList: subSectionPermission, subDisplayList : {}, mainParent: mainParent}"></ng-container>
                </ng-container>

            </ng-container>
            <ng-container *ngIf="!isCheckboxNeeded && subSectionPermission.selected">
                <ng-container *ngIf="subSectionPermission.name !== 'assets'">
                    <ng-container *ngIf="subSectionPermission && ((subSectionPermission.permissions && subSectionPermission.permissions.length > 0) || (subSectionPermission.subSections && subSectionPermission.subSections.length > 0) )">
                        <div class="row subSectionPermissionDiv">
                            <div class="col-3">
                                <span class="assetCheckboxSpan">{{subSectionPermission.name | capitalize}}</span>
                            </div>
                            <div class="col-9">
                                <ng-container *ngTemplateOutlet="SubpermissionsTemplate; context: { displayList: subSectionPermission, mainParent: mainParent}"></ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="subSectionPermission.name === 'assets'">
                    <ng-container *ngTemplateOutlet="bridgeAssetsTemplate; context: { displayList: subSectionPermission, subDisplayList : {}, mainParent: mainParent}"></ng-container>
                </ng-container>

            </ng-container>

        </ng-container>
    </ng-container>
</ng-template>

<ng-template #bridgeAssetsTemplate let-displayList="displayList" let-subDisplayList="subDisplayList" let-mainParent="mainParent">
    <ng-container *ngIf="displayList.subSections && displayList.subSections.length > 0">
        <ng-container *ngIf="isCheckboxNeeded">
            <div class="assetSelectionDiv" *transloco="let translate;">
                <material-select [options]="displayList.subSections" [optionValueProperty]="'name'" [optionDisplayNameProperty]="'name'" [placeholder]="translate('Select Asset')" (optionData)="updateSubdisplayList(subDisplayList, $event)">
                </material-select>
            </div>
        </ng-container>
        <ng-container *ngIf="!isCheckboxNeeded">
            <div class="assetSelectionDiv" *transloco="let translate;">
                <material-select [options]="getSelectedAssets(displayList.subSections)" [optionValueProperty]="'name'" [optionDisplayNameProperty]="'name'" [placeholder]="translate('Select Asset')" (optionData)="updateSubdisplayList(subDisplayList, $event)">
                </material-select>
            </div>
        </ng-container>
        <ng-container *ngIf="subDisplayList">
            <ng-container *ngTemplateOutlet="mainSubpermissionsTemplate; context: { displayList: subDisplayList,  parentPermission: displayList, mainParent: mainParent}"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>



<ng-template #SubpermissionsTemplate let-displayList="displayList" let-mainParent="mainParent">
    <ng-container *ngIf="displayList.permissions && displayList.permissions.length > 0">
        <div class="row childPermissionsDiv">
            <div class="col-12">
                <div class="row subPermisns">
                    <ng-container *ngFor="let permission of displayList.permissions; trackBy: trackPermission">
                        <ng-container *ngIf="!isCheckboxNeeded && permission.selected">
                            <div class="col-3 rolePermissionsCheckBoxContainer childPermissionsDivCheckBoxContainer">
                                <ul>
                                    <li class="permissionList">
                                        <span>{{permission.displayName}}</span>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isCheckboxNeeded">
                            <div class="col-4 rolePermissionsCheckBoxContainer childPermissionsDivCheckBoxContainer">
                                <ul>
                                    <li>
                                        <material-checkbox [showName]="true" [name]="permission.displayName" [id]="permission.uuid" [model]="permission.selected" (modelChange)="permission.selected = $event; assignPermissionSelection($event,permission, mainParent)">
                                        </material-checkbox>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="displayList.subSections && displayList.subSections.length > 0">
        <ng-container *ngFor="let subSectionPermission of displayList.subSections">
            <ng-container *ngIf="!isCheckboxNeeded && subSectionPermission.selected">
                <div class="row subChildPermissionDiv">
                    <div class="col-12 childPermission">
                        <span class="assetCheckboxSpan">{{subSectionPermission.name}}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isCheckboxNeeded">
                <div class="row subChildPermissionDiv">
                    <div class="col-12 childPermission">
                        <material-checkbox class="assetCheckbox" (click)="$event.stopPropagation()" [name]="subSectionPermission.name" [showName]="true" [model]="subSectionPermission.selected" (modelChange)="subSectionPermission.selected = $event; assignPermissionSelection($event, subSectionPermission, mainParent)">
                        </material-checkbox>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-12">
                    <ng-container *ngTemplateOutlet="SubpermissionsTemplate; context: { displayList: subSectionPermission, mainParent: mainParent}"></ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #noPermissionsTemplate>
    <ng-container *ngIf="activePermission !== 'Organization' && activePermission !== 'User'">
        <div class="noPermissionsDiv" *transloco="let translate;">
            <span>{{translate('No permissions found.')}}</span>
        </div>
    </ng-container>
</ng-template>
