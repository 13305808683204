import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AlertError } from 'taxilla-library';

import { NotificationEventType } from '../../interface/event-type.interface';
import { BridgeService } from '../bridge/bridge.service';

@Injectable({
    providedIn: 'root',
})
export class WorkqueService {
    constructor(private _bridge: BridgeService, private store$: Store) {}

    /**
     * method to get inbox messages
     * @param data Contains message type,fetch size,next paging stage, previous paging stage
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getInboxMessages = (data: { messageType: string; fetchSize: number; nextPagingState: string }) => {
        return new Observable((observer) => {
            this._bridge.getInboxMessages(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    this.store$.dispatch(AlertError(res));
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get EVENT TYPES
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getNotifications = (callbacks?: { successCallback: (...args: any[]) => void; failureCallback: (...args: any[]) => void }) => {
        this._bridge.getNotifications(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to update EVENT TYPES of email and workqueue
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    updateNotification = (
        data: NotificationEventType[],
        callbacks?: {
            successCallback: ({ msg }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.updateNotification(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to get count of active action messages
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getCountOfActiveMessages = () => {
        return new Observable((observer) => {
            this._bridge.getCountOfActiveMessages(
                (res) => {
                    observer.next(res?.response?.count || 0);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to make record as readed
     * @param data Contains message type, message id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    markMessageAsRead = (data: { noAlerts?: boolean; messageId: string }) => {
        return new Observable((observer) => {
            this._bridge.markAsRead(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
