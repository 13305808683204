import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[fileDnD]',
})
export class FileDragDropDirective {
    @Output() droppedFiles: EventEmitter<File[]> = new EventEmitter();
    @Output() fileHovered: EventEmitter<boolean> = new EventEmitter();

    @HostListener('dragover', ['$event']) onDragOver = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        this.fileHovered.emit(true);
    };

    @HostListener('dragleave', ['$event']) onDragLeave = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        this.fileHovered.emit(false);
    };

    @HostListener('drop', ['$event']) onDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        this.fileHovered.emit(false);
        if (files.length > 0) {
            this.droppedFiles.emit(files);
        }
        document.querySelector('body')?.classList.remove('fileDragDetected');
    };
}
