window['storage'] = {};

export class LocalStorageMock {
    storage = window['storage'];

    get = (key: string) => {
        return this.storage[key];
    };

    set = (key: string, value: any) => {
        this.storage[key] = value;
    };

    del = (key: string) => {
        delete this.storage[key];
    };

    clearAll = () => {
        const keys = Object.keys(this.storage);
        for (let i = 0; i < keys.length; i++) {
            delete this.storage[keys[i]];
        }
    };

    retrieve(key: string): any {
        let value: any;
        value = this.get(key);
        return value;
    }

    store(key: string, value: any): any {
        this.set(key, value);
        return value;
    }

    clear(key?: string): void {
        if (key !== undefined) {
            this.del(key);
        } else {
            this.clearAll();
        }
    }

    getStrategyName(): string {
        return 'Test';
    }
}
