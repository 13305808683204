import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'material-checkbox',
    templateUrl: './checkbox.component.html',
})
export class MaterialCheckboxComponent implements OnInit, OnDestroy {
    @Input() name: string | null;
    @Input() id: string;
    @Input() disabled: boolean;
    @Input() model: any;
    @Input() showName: boolean;
    @Input() description: string;
    @Input() indeterminate: boolean;

    @Output() change = new EventEmitter();
    @Output() modelChange = new EventEmitter();

    control = new UntypedFormControl('');
    valueSubscription: Subscription;

    constructor() {}

    customOnChange($event) {
        this.change.emit($event);
    }

    modelChanged = (event) => {
        this.model = event;
        this.modelChange.next(this.model);
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => this.modelChanged(event));
    }

    ngOnDestroy() {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
