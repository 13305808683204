import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { Subscription } from 'rxjs';
import { Address, CommonUtilsService } from 'taxilla-library';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'countries',
    templateUrl: './countries.html',
})
export class CountriesComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() data: Address;
    @Input() tabindex: string;
    @Input() id: string;
    @Input() errors: string[];
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() registerAddressCountry;
    @Input() blockEvent = false;

    @Output() countryNameChanged = new EventEmitter();

    matcher = new ModifiedErrorStateMatcher();
    control: UntypedFormControl;
    valueSubscription: Subscription;
    countries: any[];
    public country: string;

    constructor(public R: RootScope, public _utils: Utils, public _taxilla: TaxillaApiService, private _commonUtils: CommonUtilsService) {
        this.control = new UntypedFormControl({ value: (this.data && this.data.country) || '', disabled: this.disabled });
    }

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({ errors: this.errors });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.model) {
            this.control.value !== changes.model.currentValue && this.control.setValue(this.data.country);
        }
        if (changes.data) {
            this.control.value !== changes.data.currentValue && this.control.setValue(this.data.country);
        }
        if (changes.registerAddressCountry) {
            if (this.disabled) {
                this.control.setValue(this.data.country);
            }
        }

        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    getCountry = () => {
        const countries = this._commonUtils.getFromStorage('countries');
        if (countries === null || countries === undefined) {
            this._taxilla.common.getCountries({
                successCallback: (response) => {
                    const countries = response || [];
                    for (let i = 0; i < countries.length; i++) {
                        this._utils.transformText(countries[i].name);
                    }
                    this.countries = countries;
                    this.R.countries = countries;
                    this._commonUtils.setInStorage('countries', this.R.countries);
                },
                failureCallback: (res) => {},
            });
        } else {
            this.countries = countries;
            this.R.countries = countries;
        }
    };

    countryChanged = (newValue: string) => {
        this.errors = [];
        if (!newValue) {
            delete this.data.country;
            delete this.data.countryName;
        } else {
            this.data.state = '';
            this.data.city = '';
            this.data.zipCode = '';
            this.data.country = newValue;
            for (let i = 0; i < this.countries.length; i++) {
                if (parseInt(newValue, undefined) === this.countries[i].id) {
                    this.data.countryName = this.countries[i].name;
                }
            }
        }
        this.countryNameChanged.emit(this.data.country);
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => {
            if (!this.blockEvent) {
                this.countryChanged(event);
            }
        });
        this.getCountry();
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
