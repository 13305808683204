<ng-container *ngIf="!mainApp?.assetType || mainApp?.assetType !== 'BRIDGE_ASSET'">
    <button class="menuButton !text-current" mat-icon-button [matMenuTriggerFor]="shortcutsMenu" #matRegionsMenuTrigger="matMenuTrigger" aria-label="icon-button with a menu" (click)="openShortcutsMenu()" stop-propagation>
        <mat-icon *ngIf="!showSettingIcon">more_vert</mat-icon>
        <mat-icon *ngIf="isNewUI && showSettingIcon" class="material-symbols-outlined settingIcon text-graniteGray">settings</mat-icon>
        <span *ngIf="isNewUI && showSettingIcon" class="material-icons !text-3xl dropDownIcon !text-current"> arrow_drop_down </span>
        <span *ngIf="isNewUI && showSettingIcon" class="material-icons !text-3xl arrowUpIcon">arrow_drop_up</span>
    </button>
    <mat-menu xPosition="before" #shortcutsMenu="matMenu" [overlapTrigger]="false" class="shortcutMenu {{!showSettingIcon ? '' : 'menuClass'}}">
        <ng-container *transloco="let translate;">
            <ng-container *ngIf="shortCutItems.length; else noLength">
                <ng-container *ngFor="let shortCutItem of shortCutItems; trackBy:MenuTrackby">
                    <ng-container [ngSwitch]="shortCutItem.itemName">
                        <ng-container *ngSwitchCase="'All Processes'">
                            <button mat-menu-item (click)="selectionEvent('All Processes')" [title]="translate(shortCutItem.itemName)">
                                <mat-icon>{{shortCutItem.icon}}</mat-icon>
                                <span>{{translate(shortCutItem.itemName)}}</span>
                            </button>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Process Now'">
                            <button mat-menu-item (click)="selectionEvent('Process Now')" [title]="translate(shortCutItem.itemName)">
                                <mat-icon>{{shortCutItem.icon}}</mat-icon>
                                <span>{{translate(shortCutItem.itemName)}}</span>
                            </button>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <button mat-menu-item [routerLink]="shortCutItem.navigateTo" (click)="selectedItem()" [title]="translate(shortCutItem.itemName)">
                                <mat-icon>{{shortCutItem.icon}}</mat-icon>
                                <span>{{translate(shortCutItem.itemName)}}</span>
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #noLength>
                <mat-progress-bar mode="query"></mat-progress-bar>
            </ng-template>
        </ng-container>
    </mat-menu>
</ng-container>
