import { AfterViewInit, Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';

import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[AppProcessesSelection]',
})
export class AppProcessesSelectionDirective implements AfterViewInit, OnChanges {
    @Input() app: AssetService;
    @Input() bridge: AssetService;
    @Input() report: BridgeNode;
    // @Input() assetMetaData: AssetData;
    @Input() getDirectLink?: boolean;
    @Input() appId: string;
    @Input() bridgeId: string;
    @Input() reportName: string;
    @Input() allProcessesInSameTab: boolean;
    @Input() allReportsInSameTab: boolean;
    @Input() uiType: string;

    filterObjectId: string;
    filterId: string;

    private pollForAssetMetaData: any;

    constructor(
        private _api: ApiService,
        private _utils: UtilsService,
        protected _router: Router,
        private _commonUtils: CommonUtilsService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.appId || changes.bridgeId || changes.reportName) {
                this.startComponent();
            }
        }
    }

    @HostListener('click', ['$event'])
    onAppSelection = async (event?: Event) => {
        if (this.app.assetType === 'BRIDGE_ASSET') {
            return;
        }
        if (this.bridge?.serviceId) {
            await this._api.permissions.transformAppWithPermissions(this.bridge);
        } else if (this.app?.serviceId) {
            await this._api.permissions.transformAppWithPermissions(this.app);
        }
        if (!this.app.canViewAllProcesses && this.app.assetType !== 'RECON') {
            return this._utils.alertError('You donot have permissions for viewing the processes.');
        }
        if (this.allProcessesInSameTab || this.allReportsInSameTab) {
            event && event.stopPropagation();
            this.checkRouteBasedOnTag();
        } else if (this.getDirectLink && event) {
            if (environment['enreport-ui']) {
            } else {
                event && event.stopPropagation();
            }
        } else {
            event && event.stopPropagation();
            if (['encomply', 'enreport', 'eninvoice', 'encollab', 'enreconcile'].includes(this.uiType)) {
                this.routeToUI(this.uiType === 'encomply' ? 'enreport' : (this.uiType as any));
            } else {
                this.checkRouteBasedOnTag();
            }
        }
    };

    private waitTillLoadingCompleted = (callback) => {
        if (this.app['loading'] && this.app['loading']['assetMetaData']) {
            setTimeout(() => {
                this.waitTillLoadingCompleted(callback);
            }, 100);
        } else {
            callback();
        }
    };

    private checkRouteBasedOnTag = () => {
        const assetTags = this.app.tags || [];
        const tagValues = assetTags.reduce((list, tag) => {
            list.push(tag.tagValue);
            return list;
        }, []);
        this.setWindowRoute(tagValues);
    };

    private setWindowRoute = async (tags: string[]) => {
        let found = false;
        const href = window.location.href;
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        if (this.app.assetType === 'RECON') {
            this.routeToUI('enreconcile');
        } else if (tags?.length > 0 && href.indexOf(enReport) === -1 && href.indexOf(enInvoice) === -1 && href.indexOf(enCollab) === -1) {
            tags.forEach((tag) => {
                if (found) {
                    return;
                }
                if (
                    environment.restrictedProcessingUIs &&
                    environment.restrictedProcessingUIs.length &&
                    environment.restrictedProcessingUIs.indexOf(tag.toLowerCase()) >= 0
                ) {
                    tag = environment.defaultProcessingUI ? environment.defaultProcessingUI : 'enreport';
                }
                switch (tag.toLowerCase()) {
                    case 'eninvoice':
                        found = true;
                        this.routeToUI('eninvoice');
                        break;
                    case 'enreport':
                        found = true;
                        this.routeToUI();
                        break;
                    case 'enCollab':
                        found = true;
                        this.routeToUI('encollab');
                        break;
                }
            });
            if (!found) {
                const cumulativeTags = tags.reduce((values, tag) => {
                    values.push(tag);
                    return values;
                }, []);
                const supportedTag = ['eninvoice', 'encollab', 'enreport', 'enreconcile'].find((tag) => cumulativeTags.includes(tag));
                if (supportedTag) {
                    this.routeToUI(supportedTag as any);
                    return;
                }
                const collaborationApps = await this.fetchCollaborationApps();
                const isEnCollaborateApp = collaborationApps?.find((app) => app.restApiName === this.app.restApiName);
                this.routeToUI(isEnCollaborateApp ? 'encollab' : 'enreport');
            }
        } else {
            this.routeToUI();
        }
    };

    private fetchCollaborationApps = (): Promise<AssetService[]> => {
        return new Promise((resolve) => {
            this._api.subscriptions.getCollaboratedApps(
                {},
                {
                    successCallback: (response) => {
                        resolve(response as any);
                    },
                }
            );
        });
    };

    private routeToUI = (ui?: 'eninvoice' | 'enreport' | 'encollab' | 'enreconcile') => {
        const href = window.location.href;
        const enInvoice = environment['eninvoice-ui'];
        const enReport = environment['enreport-ui'];
        const enCollab = environment['encollab-ui'];
        const enReconcile = environment['enreconcile-ui'];
        ui =
            ui ||
            (href.indexOf(enInvoice) > -1 && ui && ui.length > 0 && 'eninvoice') ||
            (href.indexOf(enReport) > -1 && 'enreport') ||
            (href.indexOf(enCollab) > -1 && ui && ui.length > 0 && 'encollab') ||
            (href.indexOf(enReconcile) > -1 && ui && ui.length > 0 && 'enreconcile') ||
            (environment.defaultProcessingUI as any) ||
            'enreport';

        const routes: string[] = [ui];
        routes.push('organizations', this._commonUtils.getFromStorage('currentOrganizationId'));
        ui === 'enreconcile' && routes.push('home');
        if (this.bridge) {
            routes.push('packages', this.bridge.restApiName);
        }
        if (this.app) {
            routes.push('apps', this.app.restApiName);
        }
        if (this.report) {
            routes.push('reports', encodeURIComponent(this.report.name));
        }
        let reroute = '/' + routes.join('/') + '/processes';
        if (ui === 'enreport') {
            reroute += '/state/all';
        }
        if (this.getDirectLink && !this.allProcessesInSameTab && !this.allReportsInSameTab) {
            if (this.report) {
                this.report['appProcessesLink'] = reroute;
            } else {
                this.app['appProcessesLink'] = reroute;
            }
        } else {
            let rerouteUrl = location.pathname;
            this._commonUtils.removeFromStorage('gstfiling-redirect-url');
            rerouteUrl.includes('gst-filing') && this._commonUtils.setInStorage('gstfiling-redirect-url', rerouteUrl);
            this._router.navigate(routes, {
                queryParams: {
                    objectId: this.filterObjectId,
                    filterId: this.filterId,
                },
            });
        }
    };

    private initiateController = () => {
        this.app['loading'] = this.app['loading'] || {};
        if (this.app['loading'].assetMetaData) {
            this.pollForAssetMetaData = setTimeout(this.initiateController, 1000);
        } else {
            clearTimeout(this.pollForAssetMetaData);
            // this.assetMetaData = this.assetMetaData || this.app && this.app['assetData'];
            setTimeout(this.onAppSelection, 100);
        }
    };

    private startComponent = () => {
        if (this.getDirectLink && !this.allProcessesInSameTab && !this.allReportsInSameTab) {
            if (
                this.app &&
                !(this.app as Object).hasOwnProperty('appProcessesLink') &&
                (!this.report || !(this.app as Object).hasOwnProperty('appProcessesLink'))
            ) {
                this.initiateController();
            }
        }
    };

    ngAfterViewInit() {
        this.startComponent();
    }
}
