import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'lib-session-expired-modal',
    templateUrl: './session-expired-modal.component.html',
    styleUrls: ['./session-expired-modal.component.scss'],
})
export class SessionExpiredModalComponent implements OnInit {
    redirect: boolean;

    constructor(private dialog: MatDialog) {}

    startRedirect: (...args: any[]) => void;

    closeModal = () => {
        this.dialog.closeAll();
        this.startRedirect();
    };

    ngOnInit() {}
}
