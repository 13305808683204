import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WORKFLOW_REDUCER_KEY } from '../reducers';
import { WorkflowState } from '../states';
import { getAppMetaDataWorkflow$ } from './apps.selector';

const currentState = createFeatureSelector<WorkflowState>(WORKFLOW_REDUCER_KEY);

export const getWorkflowInstances$ = createSelector(currentState, (state) => state.instanceIdVsWorkflowState);

export const getInstanceWorkflow$ = (instanceId: string) => createSelector(getWorkflowInstances$, (instances) => instances?.[instanceId]);

export const getInstanceCurrentWorkflow$ = (instanceId: string) =>
    createSelector(getInstanceWorkflow$(instanceId), (workflows) =>
        workflows?.find((workflow) => (workflow.startTime && !workflow.endTime) || workflow.cancelled)
    );

export const getAvailableWorkflowActions$ = (serviceId: string, instanceId: string) =>
    createSelector(
        getAppMetaDataWorkflow$(serviceId),
        getInstanceCurrentWorkflow$(instanceId),
        (workflowMetaData, workflowStage) =>
            workflowMetaData?.stages?.find((stage) => stage.id === workflowStage?.taskDefinitionKey)?.userActions
    );

const getWorkflowsLoadingMap$ = createSelector(currentState, (state) => state.loading);

export const getWorkflowLoadingStatus$ = (instanceId: string) =>
    createSelector(getWorkflowsLoadingMap$, (loadingMap) => loadingMap?.[instanceId] || false);
