import { Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { translate } from '@ngneat/transloco';
import { PaginationInterface } from 'taxilla-library';

@Component({
    selector: 'encomply-material-paginator',
    templateUrl: './encomply-paginator.component.html',
    styleUrls: ['./encomply-paginator.component.css'],
})
export class EnComplyMaterialPaginatorComponent implements OnInit, DoCheck {
    @Input() pagination: PaginationInterface;
    @Input() customPageSizeOptions = false;
    @Input() showFirstLastButtons = true;

    @Output() fetchItemsInPage = new EventEmitter();
    @Output() refreshItemsWithNewPageSize = new EventEmitter();

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    pageSizeOptions = [10, 20, 50];
    pageSizeOptions2 = [5, 10, 20, 50];
    pageSize: number;

    currentPagination: PaginationInterface;

    constructor() {}

    ngDoCheck() {
        if (JSON.stringify(this.pagination) !== JSON.stringify(this.currentPagination)) {
            this.currentPagination = Object.assign({}, this.pagination);
            this.setPageValues();
        }
    }

    setPageValues = () => {
        this.pageSize = this.currentPagination.size;
    };

    setPageEvent = (event: PageEvent) => {
        if (event.pageSize !== this.pagination.size) {
            this.pagination.size = event.pageSize;
            this.refreshItemsWithNewPageSize.emit(event.pageSize);
        } else if (event.previousPageIndex !== event.pageIndex) {
            this.fetchItemsInPage.emit(event.pageIndex);
        }
    };

    ngOnInit() {
        this.pageSize = this.pagination && this.pagination.size;
    }

    ngAfterViewInit() {
        this.paginator?._intl && (this.paginator._intl.itemsPerPageLabel = translate('Items per page'));
        this.paginator?._intl && (this.paginator._intl.nextPageLabel = translate('Next Page'));
        this.paginator?._intl && (this.paginator._intl.lastPageLabel = translate('Last Page'));
        this.paginator?._intl && (this.paginator._intl.firstPageLabel = translate('First Page'));
        this.paginator?._intl && (this.paginator._intl.previousPageLabel = translate('Previous Page'));
    }
}
