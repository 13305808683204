import { Injectable } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarConfig as MatSnackBarConfig,
    MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
    MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AlertError, ApiService, AssetService, CommonUtilsService, getSubscribedApps$, getSubscribedAppsMap$ } from 'taxilla-library';

import * as actions from '../actions/shared.actions';

@Injectable()
export class SharedEffects {
    private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    constructor(
        private actions$: Actions,
        private _sanitizer: DomSanitizer,
        public snackBar: MatSnackBar,
        private _api: ApiService,
        private store$: Store
    ) {}

    public alertError$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AlertError),
            mergeMap((action) => {
                const config = new MatSnackBarConfig();
                config.verticalPosition = this.verticalPosition;
                config.horizontalPosition = this.horizontalPosition;
                config.duration = action.timeout || 6000;
                config.panelClass = ['failurePanelClass'];
                const message = Array.isArray(action.message) ? action.message.join('<br>') : action.message;
                if (Array.isArray(action.message)) {
                    config.data = message;
                    this.snackBar.open(message, undefined, config);
                } else {
                    this.snackBar.open(message, undefined, config);
                }
                return [];
            })
        );
    });

    public alertSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AlertSuccess),
            mergeMap((action) => {
                const config = new MatSnackBarConfig();
                config.verticalPosition = this.verticalPosition;
                config.horizontalPosition = this.horizontalPosition;
                config.duration = action.timeout || 4000;
                config.panelClass = ['successPanelClass', 'word-break-all'];
                this.snackBar
                    .open(Array.isArray(action.message) ? action.message.join('<br>') : action.message, action.actionText, config)
                    .onAction?.()
                    .subscribe(() => {
                        action?.onActionCallBack();
                    });
                return [];
            })
        );
    });

    private getSupportedRegions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetSupportedRegions),
            mergeMap(() => {
                return this._api.user.getSupportedRegions().pipe(
                    map((response) => {
                        return actions.SetSupportedRegions({ supportedRegions: response });
                    }),
                    catchError((errorResponse) => {
                        return of(AlertError({ message: errorResponse }));
                    })
                );
            })
        );
    });

    private getSubscribedApps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.CreateNavMap),
            delay(20),
            withLatestFrom(this.store$.select(getSubscribedApps$), this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, apps, appsMap]) => {
                let { packages, groupsMap } = this.createLeftNavMap(CommonUtilsService.cloneObject(apps));
                packages = this.sortPackages(packages as any, appsMap, true) as any;
                Object.keys(groupsMap).forEach((key) => {
                    groupsMap[key] = this.sortApps(groupsMap[key], appsMap);
                });
                this.store$.dispatch(actions.SetNavMap({ payload: packages as any }));
                this.store$.dispatch(actions.SetGroupsMap({ payload: groupsMap as any }));
                return [];
            })
        )
    );

    private createLeftNavMap = (apps: AssetService[]) => {
        const packages = [
            {
                name: 'Transactions',
                id: 'transactions',
                apps: [],
                groups: [],
                src: '/assets/images/new-ui/transctions_icon_w.png',
                srcHover: '/assets/images/new-ui/transctions_icon.png',
            },
            {
                name: 'Data Preparation',
                id: 'data-preparation',
                apps: [],
                groups: [],
                icon: 'database',
            },
            {
                name: 'Closures',
                id: 'closures',
                apps: [],
                groups: [],
                src: '/assets/images/new-ui/closures_icon_w.png',
                srcHover: '/assets/images/new-ui/closures_icon.png',
            },
            {
                name: 'Returns',
                id: 'returns',
                apps: [],
                groups: [],
                src: '/assets/images/new-ui/returns_icon_w.png',
                srcHover: '/assets/images/new-ui/returns_icon.png',
            },
            {
                name: 'Utilities',
                id: 'utilities',
                apps: [],
                packages: [],
                groups: [],
                icon: 'dynamic_feed',
            },
        ];
        const groupsMap: {
            [property: string]: {
                order: number;
                serviceId: string;
            }[];
        } = {};
        const others = packages.find((packageObject) => packageObject.id?.toLowerCase() === 'utilities');
        apps.forEach((app) => {
            const hasPackageTag = app?.tags?.find((tag) => tag.tagKey === 'package' && tag.tagValue !== 'ewb') !== undefined;
            app?.tags?.filter((tag) => {
                if (hasPackageTag) {
                    return;
                }
                if (tag.tagKey?.toLowerCase() === 'package' && tag.tagValue === 'ewb') {
                    tag.tagValue = 'transactions';
                } else if (tag.tagKey === 'uitype' && tag.tagValue === 'eninvoice') {
                    tag.tagKey = 'package';
                    tag.tagValue = 'transactions';
                }
            });
            const packageTag = app?.tags?.find((tag) => tag.tagKey?.toLowerCase() === 'package')?.tagValue;
            const groupTags = app?.tags?.filter((tag) => tag.tagKey?.toLowerCase() === 'group-order');
            if (groupTags?.length > 0) {
                groupTags?.forEach((tag) => {
                    const groupTag = tag.tagValue;
                    const group = groupTag?.split('--')?.[0];
                    const groupOrder = groupTag?.split('--')?.[1];
                    this.pushInCategory(packages, others, groupsMap, packageTag, group, groupOrder, app);
                });
            } else {
                this.pushInCategory(packages, others, groupsMap, packageTag, undefined, undefined, app);
            }
        });
        return { packages, groupsMap };
    };

    private pushInCategory = (
        packages,
        others,
        groupsMap: {
            [property: string]: {
                order: number;
                serviceId: string;
            }[];
        },
        packageTag: string,
        group: string,
        groupOrder: string,
        app: AssetService
    ) => {
        const category = packageTag && packages.find((packageObject) => packageObject.id?.toLowerCase() === packageTag?.toLowerCase());
        if (category && category.id?.toLowerCase() !== 'others') {
            if (group) {
                this.pushGroup(groupsMap, category.groups, group, groupOrder && parseInt(groupOrder), app);
            } else {
                this.pushApp(category.apps, app);
            }
        } else if (packageTag) {
            this.pushPackage(others.packages, groupsMap, packageTag, group, groupOrder, app);
        } else if (group) {
            this.pushGroup(groupsMap, others.groups, group, groupOrder && parseInt(groupOrder), app);
        } else {
            this.pushApp(others.apps, app);
        }
    };

    private pushPackage = (
        packages: {
            id: string;
            apps: any[];
            groups: any[];
            src?: string;
            srcHover?: string;
            packages?: undefined;
        }[],
        groupsMap: {
            [property: string]: {
                order: number;
                serviceId: string;
            }[];
        },
        packageTag: string,
        group: string,
        groupOrder: string,
        app: AssetService
    ) => {
        let packageInOthers = packages.find((packageObject) => packageObject.id?.toLowerCase() === packageTag?.toLowerCase());
        if (!packageInOthers) {
            packageInOthers = {
                id: packageTag,
                apps: [],
                groups: [],
            };
            packages.push(packageInOthers);
        }
        if (group) {
            this.pushGroup(groupsMap, packageInOthers?.groups, group, parseInt(groupOrder), app);
        } else {
            this.pushApp(packageInOthers.apps, app);
        }
    };

    private pushGroup = (
        groupsMap: {
            [property: string]: {
                order: number;
                serviceId: string;
            }[];
        },
        groups: { id: string; order: number }[],
        id: string,
        groupOrder: number,
        app: AssetService
    ) => {
        if (!groups.find((group) => group.id?.toLowerCase() === id?.toLowerCase())) {
            groups?.push({ id, order: groupOrder });
        }
        if (!groupsMap[id]) {
            groupsMap[id] = [];
        }
        this.pushApp(groupsMap[id], app);
    };

    private pushApp = (apps: { order: number; serviceId: string }[], app: AssetService) => {
        const appTag = app?.tags?.find((tag) => tag.tagKey?.toLowerCase() === 'app-order')?.tagValue;
        const appOrder = appTag?.split('--')?.[1];
        apps.push({
            order: appOrder && parseInt(appOrder),
            serviceId: app.serviceId,
        });
    };

    private sortPackages = (
        packages: (
            | {
                  name: string;
                  id: string;
                  apps: any[];
                  groups: any[];
                  src: string;
                  srcHover: string;
                  packages?: undefined;
                  icon?: string;
              }
            | {
                  name: string;
                  id: string;
                  apps: any[];
                  packages: any[];
                  src: string;
                  srcHover?: string;
                  icon?: string;
                  groups: any[];
              }
        )[],
        appsMap: { [property: string]: AssetService },
        dontSort?: boolean
    ) => {
        packages.forEach((packageList) => {
            packageList.apps = this.sortApps(packageList.apps, appsMap);
            packageList.groups = this.sortGroups(packageList.groups);
            packageList.packages = packageList.packages && this.sortPackages(packageList.packages, appsMap);
        });
        return !dontSort
            ? packages.sort((a, b) => {
                  const aName = a.id,
                      bName = b.id;
                  return aName.toLowerCase().localeCompare(bName.toLowerCase());
              })
            : packages;
    };

    sortApps = (apps: { serviceId: string; order: number }[], appsMap: { [property: string]: AssetService }) => {
        return apps.sort((a, b) => {
            const aOrder = a.order,
                bOrder = b.order;
            if (aOrder && bOrder) {
                return aOrder - bOrder;
            } else if (aOrder) {
                return -1;
            } else if (bOrder) {
                return 1;
            }
            const aName = appsMap?.[a.serviceId]?.displayName,
                bName = appsMap?.[b.serviceId]?.displayName;
            return aName?.toLowerCase().localeCompare(bName.toLowerCase());
        });
    };

    private sortGroups = (groups: { id: string; order: number }[]) => {
        return groups?.sort((a, b) => {
            const aOrder = a.order,
                bOrder = b.order;
            if (aOrder && bOrder) {
                return aOrder - bOrder;
            } else if (aOrder) {
                return -1;
            } else if (bOrder) {
                return 1;
            }
            const aName = a.id,
                bName = b.id;
            return aName.toLowerCase().localeCompare(bName.toLowerCase());
        });
    };
}
