import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'AlphabeticalOrder', // Changed to camelCase as per Angular's style guide
})
export class AlphabeticalOrderPipe implements PipeTransform {
    transform(array: Array<any>, args: string): Array<any> {
        if (!array) return [];
        return array.sort((a: any, b: any) => {
            const fieldA = a[args];
            const fieldB = b[args];
            if (fieldA < fieldB) return -1;
            if (fieldA > fieldB) return 1;
            const gstinA = a.flexFields?.find((field) => field.fieldDefinition.fieldName === 'gstin')?.userValue ?? '';
            const gstinB = b.flexFields?.find((field) => field.fieldDefinition.fieldName === 'gstin')?.userValue ?? '';
            if (gstinA < gstinB) return -1;
            if (gstinA > gstinB) return 1;
            return 0;
        });
    }
}
