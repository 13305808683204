import { Chart, ChartConfiguration, ChartEvent, ChartType, LegendItem, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as color from 'color';
import { BehaviorSubject } from 'rxjs';

import { CommonUtilsService } from '../services/commonutils/common-utils.service';
import { WidgetData } from './widget-data.interface';

Chart.register(...registerables, ChartDataLabels);

export class ChartData {
    private response: WidgetData;

    private chart: Chart;

    private canvasElement: HTMLCanvasElement;

    private context: CanvasRenderingContext2D;

    public chartConfiguration: ChartConfiguration;

    private isFromEnCollab: boolean;

    private hexToRGBAColors: { [property: string]: [number, number, number] } = {};

    private rgbLab: { [property: string]: [number, number, number] } = {};

    private deltaDiff: { [property: string]: number } = {};

    public chartImageURL: BehaviorSubject<string> = new BehaviorSubject(undefined);

    private actualYAxisLabels: any[];

    private valueDataType: 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function';

    public minWidth: number;

    public minHeight: number;

    constructor(
        parentElement: HTMLDivElement,
        response: WidgetData,
        chartIndexInList: number,
        isFromEnCollab: boolean,
        public legendToggle: boolean
    ) {
        this.minWidth = response?.minWidth;
        this.minHeight = response?.minHeight;
        this.response = response;
        this.canvasElement = <HTMLCanvasElement>parentElement.querySelector('canvas');
        this.context = this.canvasElement.getContext('2d');
        this.context.fillStyle = '#FFFFFF';
        this.isFromEnCollab = isFromEnCollab;
        this.buildData();
    }

    private buildData = () => {
        this.addCommonConfigurations();
        this.addPieDonutConfigurations();
        this.addBarChartConfiguration();
        this.addLineChartConfiguration();
        this.chart = new Chart(this.context, this.chartConfiguration);
    };

    public update = (data: WidgetData) => {
        this.minWidth = data.minWidth;
        this.minHeight = data.minHeight;
        this.response = data;
        this.chartConfiguration.data = this.transformData();
        this.addLineChartConfiguration();
        this.chart.legend.legendItems.filter((e) => e.hidden).forEach((e) => this.chart.legend.chart.toggleDataVisibility(e['index']));
        this.chart.update();
    };

    private addCommonConfigurations = () => {
        const names = [];
        this.response.dataSet.forEach((set) => names.push(set.name));
        let showLegend = true;
        names.forEach((name) => (showLegend = showLegend && ['NO_GROUPBY', 'GROUP_BY'].indexOf(name) === -1));
        const transformedData = this.transformData();
        const greaterDataLength = transformedData.datasets[0]?.data.length > 2;
        const beforeDrawPlugin = {
            id: 'custom_canvas_background_color',
            beforeDraw: (chart) => {
                const ctx = chart.canvas.getContext('2d');
                ctx.save();
                ctx.globalCompositeOperation = 'source-over';
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        };
        const chartType = this.getChartType();
        this.chartConfiguration = {
            plugins: [ChartDataLabels, beforeDrawPlugin],
            type: chartType,
            data: transformedData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        bottom: 30,
                        left: 30,
                        right: 30,
                        top: 30,
                    },
                },
                scales: {
                    xAxes: {
                        axis: 'x',
                        display: chartType !== 'doughnut' && chartType !== 'pie',
                        title: {
                            text: this.response.xAxisName,
                        },
                        ticks: {
                            maxRotation: greaterDataLength ? 45 : 0,
                            minRotation: 0,
                            autoSkip: false,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    yAxes: {
                        axis: 'y',
                        display: chartType !== 'doughnut' && chartType !== 'pie',
                        title: {
                            text: this.response.yAxisName,
                        },
                        ticks: {
                            maxRotation: greaterDataLength ? 45 : 0,
                            minRotation: 0,
                            autoSkip: this.valueDataType !== undefined,
                            callback: (label, index, labels) => {
                                switch (this.valueDataType) {
                                    case 'string':
                                        return this.actualYAxisLabels[(label as number) - 1];
                                    default:
                                        return label;
                                }
                            },
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                animation: {
                    onComplete: () => {
                        this.chartImageURL.next(this.chart?.toBase64Image());
                    },
                },
                plugins: {
                    tooltip: {
                        position: 'nearest',
                        callbacks: {
                            label: (tooltipItem) => {
                                const setIndex = tooltipItem.datasetIndex;
                                const dataSet = tooltipItem.dataset;
                                const chartType = this.getChartType();
                                const showLabelHeader =
                                    chartType === 'pie' ||
                                    chartType === 'doughnut' ||
                                    ['NO_GROUPBY', 'GROUP_BY'].indexOf(dataSet.label) === -1;
                                let label = (showLabelHeader && dataSet.label) || '';
                                if (chartType === 'pie' || chartType === 'doughnut') {
                                    label = `${this.chart.data.labels[tooltipItem.dataIndex]}`;
                                }
                                label && (label += ': ');
                                switch (this.valueDataType) {
                                    case 'string':
                                        label += this.actualYAxisLabels[(tooltipItem.raw as number) - 1];
                                        break;
                                    default:
                                        if (tooltipItem.raw !== undefined && tooltipItem.raw !== null) {
                                            label += tooltipItem.raw;
                                        } else {
                                            label += tooltipItem.raw;
                                        }
                                        break;
                                }
                                return label;
                            },
                            beforeFooter: (tooltipItems) => {
                                const values = [];
                                tooltipItems.forEach((tooltipItem) => {
                                    const chartType = this.getChartType();
                                    if (chartType !== 'pie' && chartType !== 'doughnut') {
                                        return;
                                    }
                                    let dataset = tooltipItem.dataset;
                                    let total = (dataset.data as any)?.reduce((previousValue, currentValue) => {
                                        return previousValue + currentValue;
                                    });
                                    let currentValue = dataset?.data[tooltipItem.dataIndex];
                                    let percentage = (((currentValue as any) / total) * 100).toFixed(2);
                                    values.push(`${currentValue} (${percentage}%)`);
                                });
                                return values;
                            },
                        },
                    },
                    datalabels: {
                        display: 'auto',
                        color: 'black',
                        anchor: 'end',
                        align: 'end',
                        font: {
                            weight: 'normal',
                        },
                        formatter: (value, context) => {
                            if (this.valueDataType === 'string') {
                                return this.actualYAxisLabels[value - 1];
                            }
                            let val = Math.abs(value);
                            let formattedVal;
                            if (val >= 10000000) {
                                formattedVal = (value / 10000000).toFixed(1) + ' C';
                            } else if (val >= 100000) {
                                formattedVal = (value / 100000).toFixed(1) + ' L';
                            } else if (val >= 1000) {
                                formattedVal = (val / 1000).toFixed(1) + ' K';
                            } else if (val === 0) {
                                formattedVal = '';
                            }
                            return formattedVal;
                        },
                    },
                    legend: {
                        position: 'bottom',
                        display: showLegend,
                        onClick: this.onLegendClick as any,
                        labels: {
                            padding: 25,
                        },
                    },
                    title: {
                        display: false,
                    },
                },
            },
        };
    };

    private addPieDonutConfigurations = () => {
        if (this.response.chartType !== 'PIE_CHART' && this.response.chartType !== 'DONUT_CHART') {
            return;
        }
        this.chartConfiguration.options.plugins.datalabels.offset = 0.3 * this.response.dataSet[0].coordinatesList.length;
        this.chartConfiguration.options = {
            ...this.chartConfiguration.options,
        };
    };

    private addBarChartConfiguration = () => {
        if (this.response.chartType !== 'BAR_CHART') {
            return;
        }
    };

    private addLineChartConfiguration = () => {
        if (this.response.chartType !== 'LINE_CHART') {
            return;
        }
    };

    private onLegendClick = (event: ChartEvent, legendItem: LegendItem, legend) => {
        event?.native?.stopPropagation();
        switch (this.chart.data.datasets.length) {
            case 1:
                this.handleLegendClickForSingleDataSets(legendItem, legend);
                break;
            default:
                this.handleLegendClickForMultipleDataSets(legendItem, legend);
                break;
        }
        this.chart.data.datasets.forEach((set, setIndex) => {
            const meta = this.chart.getDatasetMeta(setIndex);
            let hidden = typeof meta.hidden === 'boolean' ? meta.hidden : true;
            meta.data.forEach((item) => {
                hidden = hidden && item['hidden'];
            });
            this.chart.config.data.datasets[setIndex].borderWidth = hidden ? 0 : 1;
        });
        this.chart.update();
    };

    private handleLegendClickForSingleDataSets = (legendItem, legend) => {
        const ci = legend.chart;
        switch (this.legendToggle) {
            case true:
                ci.toggleDataVisibility(legendItem.index);
                break;
            default:
                const isHidden = legendItem.hidden;
                if (isHidden) {
                    ci.toggleDataVisibility(legendItem.index);
                    legend.legendItems
                        .filter((e) => e.index !== legendItem.index)
                        .forEach((e) => {
                            ci.hide(e.index);
                        });
                } else {
                    legend.legendItems
                        .filter((e) => e.index !== legendItem.index)
                        .forEach((e) => {
                            ci.toggleDataVisibility(e.index);
                        });
                }
                break;
        }
    };

    private handleLegendClickForMultipleDataSets = (legendItem, legend) => {
        const ci = legend.chart;
        const index = legendItem.datasetIndex;
        switch (this.legendToggle) {
            case true:
                legendItem.hidden ? ci.show(index) : ci.hide(index);
                break;
            default:
                const isHidden = legendItem.hidden;
                if (isHidden) {
                    ci.show(index);
                    legend.legendItems
                        .filter((e) => e.datasetIndex !== legendItem.datasetIndex)
                        .forEach((e) => {
                            ci.hide(e.datasetIndex);
                        });
                } else {
                    const alteastOneHidden = legend.legendItems?.find((e) => e.hidden);
                    legend.legendItems
                        .filter((e) => e.datasetIndex !== legendItem.datasetIndex)
                        .forEach((e) => {
                            alteastOneHidden ? ci.show(e.datasetIndex) : ci.hide(e.datasetIndex);
                        });
                }
                break;
        }
    };

    private transformData = () => {
        const data: ChartConfiguration['data'] = {
            labels: [],
            datasets: [],
        };
        this.checkDataType(this.response);
        if (this.response?.dataSet?.length > 1) {
            this.transformGroupedData(data);
        } else if (this.response?.dataSet) {
            this.transformNonGroupedData(data);
        }
        return data;
    };

    private checkDataType = (response: WidgetData) => {
        let stringCoordinate = false;
        response?.dataSet?.forEach((set) => {
            set.coordinatesList?.forEach((coordinate) => {
                stringCoordinate = stringCoordinate || typeof coordinate.y === 'string';
            });
        });
        if (stringCoordinate) {
            this.valueDataType = 'string';
            this.transformYAxisValues(response?.dataSet);
        } else {
            this.valueDataType = undefined;
            this.actualYAxisLabels = [];
        }
    };

    private transformYAxisValues = (
        dataSet: {
            name: string;
            coordinatesList: {
                x: string;
                y: number;
            }[];
        }[]
    ) => {
        const labelsData = dataSet.reduce((labels, set) => {
            set.coordinatesList.forEach((coordinate) => labels.push(coordinate.y));
            return labels;
        }, []);
        this.actualYAxisLabels = [...new Set(labelsData)];
        dataSet.forEach((set) => {
            set.coordinatesList.forEach((coordinate) => {
                coordinate.y = this.actualYAxisLabels.indexOf(coordinate.y) + 1;
            });
        });
    };

    private getChartType = () => {
        let type: ChartType;
        switch (this.response.chartType) {
            case 'BAR_CHART':
                type = 'bar';
                break;
            case 'LINE_CHART':
                type = 'line';
                break;
            case 'PIE_CHART':
                type = 'pie';
                break;
            case 'DONUT_CHART':
                type = 'doughnut';
                break;
        }
        return type;
    };

    private transformGroupedData = (data: ChartConfiguration['data']) => {
        this.response.dataSet.forEach((set) => {
            set.coordinatesList.forEach((coordinate) => {
                if (data.labels.indexOf(coordinate.x) === -1) {
                    data.labels.push(coordinate.x);
                }
            });
        });
        const colors = this.getColors();
        const chartType = this.getChartType();
        data.labels.forEach((label) => {
            let colorIndex = -1;
            this.response.dataSet.forEach((set) => {
                colorIndex++;
                const dsColor = colors[colorIndex];
                if (!data.datasets.find((dataSet) => dataSet.label === set.name)) {
                    data.datasets.push({
                        label: set.name,
                        backgroundColor: color(dsColor).alpha(0.5).toString(),
                        borderColor: chartType === 'line' ? color(dsColor).alpha(0.5).toString() : '#00000000',
                        borderWidth: 1,
                        data: [],
                        minBarLength: 3,
                        barThickness: 40,
                        maxBarThickness: 10,
                    });
                }
                const dataSetObject = data.datasets.find((dataSet) => dataSet.label === set.name);
                set.coordinatesList
                    .filter((coordinate) => coordinate.x === label)
                    .forEach((coordinate) => {
                        dataSetObject.data.push(coordinate.y);
                    });
            });
        });
    };

    private transformNonGroupedData = (data: ChartConfiguration['data']) => {
        this.response.dataSet.forEach((set) => {
            set.coordinatesList.forEach((coordinate) => {
                if (data.labels.indexOf(coordinate.x) === -1) {
                    data.labels.push(coordinate.x);
                }
            });
        });
        const chartType = this.response.chartType;
        let name;
        if (this.isFromEnCollab && (chartType === 'PIE_CHART' || chartType === 'DONUT_CHART')) {
            name = this.response.xAxisName;
        } else {
            name = (this.response.dataSet[0] && this.response.dataSet[0].name) || '';
        }
        const dataSet: ChartConfiguration['data']['datasets'][0] = {
            label: name,
            data: [],
            fill: false,
            backgroundColor: [],
            borderColor: this.getChartType() === 'line' ? [] : ['rgba(0, 0, 0, 0)'],
            borderWidth: 1,
            maxBarThickness: 40,
        };
        let colorIndex = -1;
        const colors = this.getColors();
        this.response.dataSet.forEach((set) => {
            set.coordinatesList.forEach((coordinate) => {
                colorIndex++;
                const dsColor = colors[colorIndex];
                dataSet.data.push(coordinate.y);
                (<string[]>dataSet.backgroundColor).push(color(dsColor).alpha(0.7).toString());
            });
        });
        data.datasets.push(dataSet);
    };

    private getColors = () => {
        let neededColorsCount: number = 0;
        this.response.dataSet.forEach((set) => {
            neededColorsCount = Math.max(set.coordinatesList?.length || 0, neededColorsCount);
        });
        const colors = [];
        let i = 0;
        let requiredDelta: number;
        const savedColors = CommonUtilsService.getFromStorage('colors') || [];
        const initialColorsCount = savedColors.length;
        let neededDeltaAdjustment = false;
        let constantNumber = neededColorsCount < 10 ? 150 : neededColorsCount < 20 ? 250 : 1500;
        while (i < neededColorsCount) {
            const savedColor = savedColors[i];
            if (savedColor) {
                colors.push(savedColor);
                neededDeltaAdjustment = true;
                i++;
            } else {
                if (!requiredDelta || neededDeltaAdjustment) {
                    requiredDelta = Math.ceil(12 + constantNumber / ((i || 1) * neededColorsCount * 0.44));
                    requiredDelta = requiredDelta >= 49 ? 49 : requiredDelta;
                    neededDeltaAdjustment = false;
                }
                const color = this.getRandomColor();
                if (i === 0) {
                    colors.push(color);
                    !savedColor && savedColors.push(color);
                    requiredDelta = 49;
                    i++;
                } else if (this.checkDeltaDiffWithAllColors(colors, color, requiredDelta)) {
                    requiredDelta = Math.ceil(12 + constantNumber / ((i || 1) * neededColorsCount * 0.44));
                    requiredDelta = requiredDelta >= 49 ? 49 : requiredDelta;
                    i++;
                }
            }
        }
        if (initialColorsCount < colors.length) {
            CommonUtilsService.setInStorage('colors', colors);
        }
        return colors;
    };

    private getRandomColor = () => {
        var color = '#' + ('00000' + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
        return color;
    };

    private checkDeltaDiffWithAllColors = (list: string[], color: string, requiredDelta: number) => {
        let result = true;
        for (let i = 0; i < list.length % 60; i++) {
            const delta = this.deltaE(list[i], color);
            result = result && delta > requiredDelta;
            if (!result) {
                break;
            }
        }
        if (result) {
            list.push(color);
        }
        return result;
    };

    private hexToRgb = (hex: string): [number, number, number] => {
        if (this.hexToRGBAColors[hex]) {
            return this.hexToRGBAColors[hex];
        }
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) {
            var r = parseInt(result[1], 16);
            var g = parseInt(result[2], 16);
            var b = parseInt(result[3], 16);
            this.hexToRGBAColors[hex] = [r, g, b];
            return [r, g, b];
        }
        return null;
    };

    private deltaE = (hexA: string, hexB: string) => {
        const hexString = hexA + hexB;
        if (this.deltaDiff[hexString]) {
            return this.deltaDiff[hexString];
        }
        const rgbA = this.hexToRgb(hexA),
            rgbB = this.hexToRgb(hexB);
        let labA = this.rgb2lab(rgbA);
        let labB = this.rgb2lab(rgbB);
        let deltaL = labA[0] - labB[0];
        let deltaA = labA[1] - labB[1];
        let deltaB = labA[2] - labB[2];
        let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
        let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
        let deltaC = c1 - c2;
        let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
        deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
        let sc = 1.0 + 0.045 * c1;
        let sh = 1.0 + 0.015 * c1;
        let deltaLKlsl = deltaL / 1.0;
        let deltaCkcsc = deltaC / sc;
        let deltaHkhsh = deltaH / sh;
        let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
        this.deltaDiff[hexString] = i < 0 ? 0 : Math.sqrt(i);
        return this.deltaDiff[hexString];
    };

    private rgb2lab = (rgb: [number, number, number]) => {
        const str = rgb.join('');
        if (this.rgbLab[str]) {
            return this.rgbLab[str];
        }
        let r = rgb[0] / 255,
            g = rgb[1] / 255,
            b = rgb[2] / 255,
            x,
            y,
            z;
        r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
        g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
        b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
        x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
        y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
        z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
        x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
        y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
        z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
        this.rgbLab[str] = [116 * y - 16, 500 * (x - y), 200 * (y - z)];
        return this.rgbLab[str];
    };
}
