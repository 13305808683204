import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { translate } from '@ngneat/transloco';
import { QueryBuilderConfig } from 'ng-query-builder';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MY_DATE_FORMATS, MyDateAdapter } from '../../extensions/dateadapter/date.adapter';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

@Component({
    selector: 'app-elasticquerybuilder',
    templateUrl: './elasticquerybuilder.component.html',
    styleUrls: ['./elasticquerybuilder.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MyDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_DATE_FORMATS,
        },
    ],
})
export class ElasticQueryBuilderComponent implements OnInit, OnDestroy {
    @Input() query;
    @Input() config: QueryBuilderConfig;
    @Input() allowRuleset;
    @Input() allowCollapse;
    @Input() isRelationConfig: boolean;
    @Input() isRelationDisabled: boolean;

    @Output() queryChanged = new EventEmitter<any>();
    @Output() relationChanged = new EventEmitter<any>();

    queryCopy: any;
    disabled = true;
    addOnBlur = true;
    chipDateCtrl = new UntypedFormControl();
    customOperatorsMap: { [key: string]: string[] } = {
        string: ['EQ', 'NOT_EQUALS', 'IN', 'CONTAINS', 'is null', 'is not null'],
        number: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
        time: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
        date: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
        category: ['EQ', 'NOT_EQUALS', 'IN', 'is null', 'is not null'],
        boolean: ['EQ', 'NOT_EQUALS', 'is null', 'is not null'],
    };
    separatorKeysCodes = [ENTER, COMMA];
    unSubscribe = new Subject<void>();

    constructor(public broadcaster: BroadcasterService) {}

    onParentChange = () => {
        const clone = CommonUtilsService.cloneObject(this.query);
        this.queryChanged.emit(clone);
    };

    printQuery = () => {
        this.query = undefined;
    };

    addNewTag = (event: MatChipInputEvent, rule: any): void => {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            rule.value.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
        // this.chipDateCtrl.setValue(null);
    };

    removeTag = (value: string, rule: any): void => {
        const index = rule.value.indexOf(value);
        if (index >= 0) {
            rule.value.splice(index, 1);
        }
    };

    isArray = (val): boolean => {
        return Array.isArray(val);
    };

    clearDateValue = (rule) => {
        if (rule) {
            rule.value = undefined;
        }
    };

    translateMsg = (msg: string): string => translate(msg);

    onOperatorChange = (operator: string, rule: any) => {
        if (operator === 'IN') {
            rule.value = [];
        } else {
            rule.value = '';
        }
        this.onParentChange();
    };

    openPanel = () => {
        this.query['collapsed'] = false;
    };

    trackByOptionMethod(index: number, option: any): any {
        return option.value;
    }

    trackByOperatorsMethod(index: number, operator: any): any {
        return operator;
    }

    trackByEntityMethod(index: number, entity: any): any {
        return entity.name;
    }

    trackByFieldMethod(index: number, item: any): any {
        return index;
    }

    ngOnInit() {
        this.broadcaster
            .on('queryConfigChange')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: { searchConfig: QueryBuilderConfig; sortConfig: QueryBuilderConfig }) => {
                this.config = Object.assign({}, data.searchConfig);
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
