<div class="eventLogHedderDiv">
    <span>{{translateMsg('Please refresh to get the latest notifications')}}.</span>
</div>
<div class="eventFilter" *transloco="let translate">
    <ng-container *ngIf="eventTypesList && eventTypesList.length !== 0">
        <mat-form-field class="col-5">
            <mat-select [(ngModel)]="selectedEventType" [placeholder]="translateMsg('Event Type')" (ngModelChange)="getAllNotifications()">
                <mat-option value="">{{translateMsg('All')}}</mat-option>
                <mat-form-field class="searchEntities searchInputField SearcEvents ">
                    <input matInput [placeholder]="translateMsg('Search Events')" type="text" [(ngModel)]="allAssetsName" />
                    <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="allAssetsName = ''">
                        <mat-icon class='searchEntitiesIcons' *ngIf='allAssetsName'>
                            close
                        </mat-icon>
                        <mat-icon *ngIf='!allAssetsName' class="searchEntitiesIcons">
                            search
                        </mat-icon>
                    </button>
                </mat-form-field>
                <mat-option *ngFor="let event of eventTypesList | multiFilter: allAssetsName | AlphabeticalOrder: 'displayName'; trackBy: trackEventType" [value]="event.id">{{translate(event.displayName)}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container>
        <button mat-mini-fab color="default" [title]="translateMsg('Refresh')" class=" refreshLogs mx-2 bottom-1" (click)="refreshNotifications()">
            <mat-icon class="refresh">refresh</mat-icon>
        </button>
    </ng-container>
</div>
<div *transloco="let translate">
    <material-table [allTableColumns]="allTableColumns" [selectedColumns]="selectedColumns" [data]="tableData" [noLog]="true" [pagination]="$any(pagination)" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize($event)" (fetchItemsInPage)="fetchItemsInPage($event)" [noRecordMsg]="translateMsg('No Event Logs Available')"></material-table>
</div>
