import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
    providedIn: 'root',
})
export class RootScopeService {
    LOGO_HREF = environment.logoHref;
    PLATFORM = environment.envName;
    SHOWMARQUEE = environment.showMarquee;
    MARQUEETEXT = environment.marqueeText;
    onLocalHost = environment.onLocalhost;

    constructor() {}
}
