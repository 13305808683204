import { LookupInformationMetadata } from './lookupinformationmetadata.class';
import { LookupValues } from './lookupvalues.class';
import { MasterField } from './masters/masterfield.class';
import { Masters } from './masters/masters.class';
import { MastersMetaData } from './masters/mastersmetadata.class';
import { Rule } from './rule.class';

export class Field {
    allowBulkUpdate: boolean;

    captureOnce: any;

    datatype: string;

    description: string;

    mandatory: boolean;

    name: string;

    displayName: string;

    readOnly: boolean;

    referredAssetFieldMetadata: ReferredAssetFieldMetadata;

    relatedFields: any[];

    ruleReferences: string[];

    rules: Rule[];

    searchable: boolean;

    searchableIndex: string;

    show: boolean;

    showInList: boolean;

    showInMap: boolean;

    sortable: boolean;

    uiTypeMetadata: string;

    uid: string;

    lookupInformationMetadata: LookupInformationMetadata;

    isBusinessKey: boolean;

    lookupValues: LookupValues;

    hasLookup: boolean;

    masterData: EntityFieldMasterData;

    outputFormat: string;

    fieldSection: string;

    defaultValue: any;

    gridConstraints?: GridConstraints;

    idGenerator: string;

    /** UI related fields */
    hasMasterLookup: boolean;

    masterField: MasterField;

    masterTable: MastersMetaData;

    preCaptureType?: string;

    attachment?: {
        maxFilesCount?: number;
        maxSize?: number;
        supportedTypes?: string[];
    };

    primaryKey?: string;

    dependentForFields?: string[];

    autoCalculate?: boolean;

    tagEntries?: {
        key: string;
        value: string;
    }[];

    order: number;

    constructor(data, rules, businessKeys, masters: Masters, fieldTemplate?: Field) {
        this.allowBulkUpdate = data?.allowBulkUpdate;
        this.captureOnce = data?.captureOnce;
        this.datatype = data?.datatype;
        this.description = fieldTemplate?.description || data?.description;
        this.mandatory = data?.mandatory;
        this.displayName = fieldTemplate?.displayName || data?.displayName;
        this.name = fieldTemplate?.displayName || data?.name;
        this.readOnly = data?.readOnly;
        this.referredAssetFieldMetadata = data?.referredAssetFieldMetadata;
        this.relatedFields = data?.relatedFields;
        this.ruleReferences = data?.ruleReferences || [];
        this.searchable = data?.searchable;
        this.searchableIndex = data?.searchableIndex;
        this.show = fieldTemplate?.show !== undefined ? fieldTemplate?.show && data?.show : data?.show;
        this.showInList = data?.showInList;
        this.showInMap = data?.showInMap;
        this.sortable = data?.sortable;
        this.uid = data?.uid;
        this.uiTypeMetadata = data?.uiTypeMetadata;
        this.masterData = data?.masterData || {};
        if (this.masterData?.columnRefMetadatas?.length > 1) {
            this.masterData.columnRefMetadatas = this.masterData.columnRefMetadatas?.filter(
                (reference, index) =>
                    this.masterData.columnRefMetadatas.findIndex(
                        (childReference) =>
                            childReference.entityUid === reference.entityUid && childReference.fieldUid === reference.fieldUid
                    ) === index
            );
        }
        this.outputFormat = data?.outputFormat;
        this.fieldSection = fieldTemplate?.['section'] || data?.fieldSection;
        this.attachment = data?.attachment;
        this.gridConstraints = data?.gridConstraints;
        this.datatype === 'GRID' && this.translateGridData(fieldTemplate);
        this.autoCalculate = data?.autoCalculate;
        this.tagEntries = data?.tagEntries;
        const searchInMaster = this.canSearchInMaster(this.masterData, masters);
        this.hasMasterLookup = searchInMaster.hasMasterLookup;
        this.masterField = searchInMaster.masterField;
        if (data?.datatype === 'DATE' && !data.lookupInformationMetadata) {
            this.uiTypeMetadata = 'DATE';
        }
        this.rules = [];
        this.isBusinessKey = businessKeys && businessKeys.indexOf(this.uid) > -1;
        this.lookupInformationMetadata = undefined;
        if (data?.lookupInformationMetadata) {
            this.hasLookup = true;
            this.lookupInformationMetadata = new LookupInformationMetadata(data.lookupInformationMetadata);
        } else {
            this.hasLookup = false;
        }
        if (data?.lookupValues && data.lookupValues.lookupId) {
            this.lookupValues = new LookupValues(data.lookupValues);
        }
        for (let i = 0; i < this.ruleReferences.length; i++) {
            const rule = Rule.getRule(rules, this.ruleReferences[i]);
            if (rule) {
                this.rules.push(rule);
            }
        }
        this.preCaptureType = data?.preCaptureType;
        this.defaultValue = data?.defaultValue;
        this.idGenerator = data?.idGenerator;
        if (this.gridConstraints?.rowDetails?.length > 0) {
            this.gridConstraints.rowDetails.forEach((row) => {
                row.rules = [];
                row.ruleReferences = row.ruleReferences || [];
                row.ruleReferences.forEach((ruleReference) => {
                    const rule = Rule.getRule(rules, ruleReference);
                    if (rule) {
                        row.rules.push(rule);
                    }
                });
            });
        }
        if (this.gridConstraints) {
            this.transformGrid();
        }
        this.dependentForFields = [];
        this.order = fieldTemplate?.['fieldOrder'] !== undefined ? fieldTemplate?.['fieldOrder'] : data?.order;
    }

    private translateGridData = (fieldTemplate) => {
        this.gridConstraints.columnDetails?.forEach((cell) => {
            const cellIndex = cell.index + '';
            const cellTemplateData = fieldTemplate?.columnDetails?.find((cellTemplate) => cellTemplate.index === cellIndex);
            cell.name = cellTemplateData?.displayName || cellTemplateData?.name || cell?.displayName || cell?.name;
        });
        this.gridConstraints.rowDetails?.forEach((cell) => {
            const cellIndex = cell.index + '';
            const cellTemplateData = fieldTemplate?.rowDetails?.find((cellTemplate) => cellTemplate.index === cellIndex);
            cell.name = cellTemplateData?.displayName || cellTemplateData?.name || cell?.displayName || cell?.name;
        });
    };

    setLookupValues = (lookupResponse) => {
        this.lookupValues = new LookupValues(lookupResponse);
    };

    canSearchInMaster = (masterData: EntityFieldMasterData, masters: Masters): { hasMasterLookup: boolean; masterField: MasterField } => {
        const master: MastersMetaData = masters && masters.getTable(masterData.tableUid);
        const keyColumns = master && master.masterMetadata.keyColumns;
        const hasMasterLookup = keyColumns && keyColumns.indexOf(masterData.columnName) > -1;
        const masterField = hasMasterLookup && master.getColumn(masterData.columnName);
        return { hasMasterLookup, masterField };
    };

    transformGrid = () => {
        const constraints = this.gridConstraints;
        constraints.cellDetails.forEach((cell) => {
            if (!cell.rules) {
                cell.rules = [];
            }
            const columnData = constraints.columnDetails.find((column) => column.index === cell.columnIndex);
            const rowData = constraints.rowDetails.find((row) => row.index === cell.rowIndex);
            const dataTypes = this.getDataTypeForCell(cell);
            cell.datatype = dataTypes.inputType;
            cell.outputFormat = dataTypes.outputFormat;
            cell.name = columnData.name;
            rowData.rules.forEach((rule) => {
                cell.rules.push(rule);
            });
            if (cell.lookupInformationMetadata) {
                cell.datatype = 'LOOKUP';
            }
        });
    };

    getDataTypeForCell = (cell: GridConstraintCellDetail) => {
        const constraints = this.gridConstraints;
        const supportLevel: 'ROW' | 'COLUMN' = constraints.levelDatatype as any;
        if (supportLevel === 'ROW') {
            const row = constraints.rowDetails[cell.rowIndex];
            return {
                inputType: row.datatype as any,
                outputFormat: row.outputFormat,
            };
        } else {
            const column = constraints.columnDetails[cell.columnIndex];
            return {
                inputType: column.datatype as any,
                outputFormat: column.outputFormat,
            };
        }
    };
}

interface EntityFieldMasterData {
    columnName: string;

    columnRefMetadatas: {
        entityUid: string;
        fieldUid: string;
    }[];

    tableName: string;

    tableUid: string;
}

export interface GridConstraints {
    levelDatatype: string;

    rowDetails: GridConstraintRowDetail[];

    columnDetails: GridConstraintColumnDetail[];

    cellDetails: GridConstraintCellDetail[];
}

export interface GridConstraintRowDetail {
    index: number;

    name: string;

    displayName: string;

    datatype: string;

    show: boolean;

    outputFormat: string;

    defaultValue: string;

    readOnly: boolean;

    ruleReferences: [];

    rules: Rule[];

    mandatory: boolean;
}

export interface GridConstraintColumnDetail {
    index: number;

    name: string;

    displayName: string;

    datatype: string;

    show: boolean;

    outputFormat: string;

    defaultValue: string;

    readOnly: boolean;

    mandatory: boolean;
}

export interface GridConstraintCellDetail {
    rowIndex: number;

    columnIndex: number;

    show: boolean;

    defaultValue: string;

    readOnly?: boolean;

    ruleReferences: [];

    mandatory: boolean;

    datatype: string;

    outputFormat: string;

    name: string;

    displayName: string;

    rules: Rule[];

    lookupInformationMetadata?: LookupInformationMetadata;
}

export interface ReferredAssetFieldMetadata {
    assetName: string;
    assetUid: string;
    entityUid: string;
    fieldUid: string;
    fieldReferences: any[];
    useBusinessKeyReference: boolean;
}

export interface TargetField {
    fieldUid: string;
    dataToGetFrom: string;
    referenceMetadata: ReferredAssetFieldMetadata;
}
