import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SESSION_REDUCER_KEY } from '../reducers';
import { SessionState } from '../states/session.state';

const getSessionsState = createFeatureSelector<SessionState>(SESSION_REDUCER_KEY);

export const getSessionState$ = createSelector(getSessionsState, (state) => state.session);

export const getCurrentOrganizationId$ = createSelector(getSessionsState, (state) => state.currentOrganizationId as string);

export const getLoggedInOrganizationId$ = createSelector(getSessionsState, (state) => state.loggedInOrganizationId as string);

export const getSessionUserId$ = createSelector(getSessionsState, (state) => state.sessionUser?.id);

export const getApplicationMiscPropertiesState = createSelector(getSessionsState, (state) => state.applicationMiscProperties);

export const getActiveLanguage = createSelector(getSessionsState, (state) => state.activeLanguage);

export const getSessionDetails$ = createSelector(getSessionsState, (state) => {
    return {
        loggedInOrganizationId: state?.loggedInOrganizationId,
        currentOrganizationId: state?.currentOrganizationId,
        user: state?.sessionUser,
        lastLogin: state?.lastLogin,
        isAdmin: state?.isAdmin,
        passwordExpiresInDays: state?.passwordExpiresInDays,
        timeZoneOffset: state?.timeZoneOffset,
    };
});

export const getWorkQCount$ = createSelector(getSessionsState, (state) => state.workQCount);

export const getWorkQMessages$ = createSelector(getSessionsState, (state) => state.workQMessages);

export const isProviderAdmin$ = createSelector(getSessionsState, (state) => state.providerAdmin);

export const isProviderOrganization$ = createSelector(getSessionsState, (state) => state.providerOrganization);

export const isProviderOrganizationAndAdmin$ = createSelector(
    isProviderAdmin$,
    isProviderOrganization$,
    (admin, organization) => admin && organization
);
