import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import {
    AlertError,
    AlertSuccess,
    ApiService,
    DeleteSearchCriteria,
    GetAppSearchFilters,
    getAppSearchOrganizationMap$,
    getAppsMetaDataMap$,
    getCurrentOrganizationId$,
    getSubscribedAppsMap$,
    SavedSearchCriteria,
    SaveSearchCriteria,
    SetAppSearchFilters,
    SetDefaultSearchFilters,
    UtilsService,
} from 'taxilla-library';

@Injectable()
export class SearchEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store, private _utils: UtilsService) {}

    private getSearchDefaultFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAppSearchFilters),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAppSearchOrganizationMap$)
            ),
            mergeMap(([action, appsMap, organizationId, searchMap]) => {
                const app = appsMap?.[action.serviceId];
                const defaultFilters = searchMap?.[action.serviceId]?.defaultFilters;
                if (defaultFilters?.['default-filters']?.length > 0) {
                    return [];
                }
                return this._api.instances.getDefaultSearchFilters(app?.assetMetaUId).pipe(
                    map((res) => {
                        return SetDefaultSearchFilters({
                            serviceId: action.serviceId,
                            organizationId,
                            filters: res?.['default-filters'] || [],
                            criterias: res.filterCriterias,
                        });
                    }),
                    catchError((e) => [])
                );
            })
        )
    );

    private getAppSearchFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAppSearchFilters),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAppSearchOrganizationMap$)
            ),
            mergeMap(([action, appsMap, metaDataMap, organizationId, searchMap]) => {
                const app = appsMap?.[action.serviceId];
                const metaData = metaDataMap?.[app?.serviceId]?.[app?.assetMetaUId];
                const objectId = `${app?.restApiName}.${metaData?.entities?.find((entity) => entity.primary)?.uid}`;
                const searchFilters = searchMap?.[action.serviceId]?.searchFilters;
                if (searchFilters?.length > 0) {
                    return [];
                }
                return this._api.instances.getSearchConfiguration(objectId).pipe(
                    map((res) => {
                        return SetAppSearchFilters({
                            serviceId: action.serviceId,
                            organizationId,
                            filters: res?.['searchFilters'] || [],
                        });
                    }),
                    catchError((e) => [])
                );
            })
        )
    );

    private saveSearchCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SaveSearchCriteria),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAppSearchOrganizationMap$)
            ),
            mergeMap(([action, appsMap, metaDataMap, organizationId, searchMap]) => {
                const app = appsMap?.[action.serviceId];
                const metaData = metaDataMap?.[app?.serviceId]?.[app?.assetMetaUId];
                const objectId = `${app?.restApiName}.${metaData?.entities?.find((entity) => entity.primary)?.uid}`;
                const searchFilters = searchMap?.[action.serviceId]?.searchFilters || [];
                return this._api.instances
                    .saveSearchConfiguration({
                        criteria: action.criteria,
                        filterName: action.filterName,
                        objectId: objectId,
                        id: action.id,
                    })
                    .pipe(
                        map((res: any) => {
                            this.store$.dispatch(SavedSearchCriteria({ criteria: res?.response, serviceId: action.serviceId }));
                            return SetAppSearchFilters({
                                serviceId: action.serviceId,
                                organizationId,
                                filters: [res?.response, ...searchFilters.filter((filter) => filter.id !== action.id)],
                            });
                        }),
                        catchError((e) => {
                            const message = (e?.msg && this._utils.destructStringedArray(e?.msg)) || translate('Failed to save filter');
                            return of(AlertError({ message }));
                        })
                    );
            })
        )
    );

    private deleteSearchCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteSearchCriteria),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getAppSearchOrganizationMap$)),
            mergeMap(([action, organizationId, searchMap]) => {
                const searchFilters = searchMap?.[action.serviceId]?.searchFilters || [];
                const filter = searchFilters.find((criteria) => criteria.id === action.id);
                return this._api.instances
                    .deleteSearchConfiguration({
                        id: action.id,
                    })
                    .pipe(
                        map((res: any) => {
                            this.store$.dispatch(AlertSuccess({ message: translate('Deleted') + ' ' + filter.filterName }));
                            return SetAppSearchFilters({
                                serviceId: action.serviceId,
                                organizationId,
                                filters: searchFilters.filter((criteria) => criteria.id !== action.id),
                            });
                        }),
                        catchError((e) => {
                            return [];
                        })
                    );
            })
        )
    );
}
