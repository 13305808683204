import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Subject } from 'rxjs';

import { CommonNotificationsService } from '../../services/commonnotifications/common-notifications.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'masterrequests-notifications',
    templateUrl: './masterrequests.component.html',
})
export class MasterRequestsNotificationsComponent implements OnInit {
    createdRequestDate: any;
    source: string;
    unSubscribe = new Subject<void>();

    notificationsState: BehaviorSubject<{
        eventTypesList: {
            id: string;
            displayName: string;
        }[];
    }> = new BehaviorSubject(undefined);

    constructor(
        public dialog: MatDialog,
        private _notification: CommonNotificationsService,
        private _libUtils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    loadEventsDetails = (eventTypes) => {
        eventTypes.map((item) => {
            item.id = item.name;
            item.displayName = this._libUtils.convertEventTypesToNewNames(this._libUtils.convertEnumsToNormalWords(item.name));
        });
        this.notificationsState.next({
            eventTypesList: eventTypes,
        });
    };

    closeModal = () => {
        this.dialog.closeAll();
    };

    ngOnInit() {
        this._notification.getEventTypes(
            this.loadEventsDetails,
            () => {
                //no-op
            },
            'ASSET'
        );
        // this.loadEventsDetails();
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
