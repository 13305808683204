<div class="assignUserFieldsContainer userMainTableConatiner" *transloco="let translate;">
    <div class="settingsHeader" *ngIf="selectedRoleName">
        <span class="settingsNameText">{{translate('Assign Users')}} : {{selectedRoleName}}</span>
        <div class="settingsDataActionsContainer">
            <mat-icon title="{{translate('Close')}}" class="closeIcon" aria-hidden="true" (click)="cancelView.emit()">cancel</mat-icon>
        </div>
    </div>
    <perfect-scrollbar class="assignUsersMainDiv" [style.height]="!selectedRoleName ? '38vh' : 'calc(100vh - 174px)'">
        <div class="partnersSelectionDiv col-3">
            <mat-form-field appearance="outline" appRemoveAriaOwns>
                <mat-label>{{translate('Select Organization')}}</mat-label>
                <mat-select [disabled]="radioDisabled" [(value)]="selectedPartnerOrg" (selectionChange)="selectedUser = null; partnerChange($event)" required>
                    <mat-option *ngFor="let option of partnerOrganizations" [value]="option.value">{{option.displayName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="errors?.org?.length">
                {{translate('Select Organization')}}
            </mat-error>
        </div>
        <app-users-view *ngIf="users?.length" [errors]="errors" [users]="users" [selectedUser]="selectedUser" [radioDisabled]="radioDisabled" [searchUser]="searchUser" [noAssignUsers]="noAssignUsers" [allUsersSelected]="allUsersSelected" (searchUserChange)="searchUserChange.emit()" (clearSearchUsers)="clearSearchUsers.emit()" (searchAssignUsers)="searchAssignUsers.emit()" (selectAllUsers)="selectAllUsers.emit($event)" (checkUsersList)="checkUsersList.emit($event)"></app-users-view>
        <ng-container *ngIf="!selectedPartnerOrg">
            <div class="align-items-center d-flex justify-content-center noUsersSelectedDiv" *transloco="let translate;">
                <div class="noUserMsg">
                    <span>{{translate('Please select Associate Organization')}}</span>
                </div>
            </div>
        </ng-container>
    </perfect-scrollbar>
    <div class="assignUsersActionButtons" *ngIf="selectedRoleName">
        <div class="displyInlineBlock floatRight buttonsDiv">
            <button mat-button mat-raised-button class="nextButton" (click)="assignUsersToLocations.emit()" [disabled]="!formValid">{{translate('Assign Users')}}</button>
        </div>
        <div class="displyInlineBlock buttonsDiv cancelButtonDiv floatRight">
            <button mat-button mat-raised-button class="cancelButton" (click)="cancelAssignUsers.emit()">{{translate('Cancel')}}</button>
        </div>
    </div>
</div>
