import { Rule } from '../rule.class';

export class MasterField {
    active: boolean;

    dataType: string;

    id: string;

    name: string;

    displayName: string;

    searchable: string;

    sortable: string;

    primaryKey: boolean;

    uiTypeMetadata?: string;

    dateFormat?: string;

    rules?: Rule[];

    ruleReferences?: string[];

    mandatory?: boolean;

    description?: string;

    constructor(field, isPrimaryKey: boolean, rules: Rule[]) {
        this.active = field?.active;
        this.dataType = field?.datatype;
        this.id = field?.id;
        this.name = field?.name;
        this.displayName = field?.displayName?.trim() || field?.name?.trim();
        this.searchable = field?.searchable;
        this.sortable = field?.sortable;
        this.uiTypeMetadata = field?.uiTypeMetadata;
        this.primaryKey = isPrimaryKey;
        this.dateFormat = field?.dateFormat;
        this.rules = [];
        this.ruleReferences = field?.ruleReferences || [];
        this.mandatory = field?.mandatory;
        this.description = field?.description;
        if (this.ruleReferences?.length > 0) {
            this.ruleReferences.forEach((reference) => {
                this.rules.push(rules.find((rule) => rule.uid === reference));
            });
        }
    }
}
