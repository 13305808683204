<div class="partnerUsersDiv" *transloco="let translate;">
    <div class="align-items-center d-flex justify-content-between">
        <span class="selectLocSpan">{{translate('Assign Users')}}:</span>
        <mat-form-field class="col-3">
            <input matInput placeholder="{{translate('Search User')}}" type="text" name="searchUser" [(ngModel)]="searchUser" (ngModelChange)="searchUserChange.emit($event)" (keyup)="searchAssignUsers.emit()">
            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="clearSearchUsers.emit()">
                <mat-icon class="locationManagementIcons" *ngIf="searchUser">close </mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div class="selectAllDiv" *ngIf="!noAssignUsers">
        <mat-checkbox class="mr-1" [(ngModel)]="allUsersSelected" name="'selectAllUsers'" (change)="selectAllUsers.emit($event)" id="selectAllPartnerUsers"></mat-checkbox>
        <span>{{translate('Select All')}}</span>
    </div>
    <div class="partnerUsersMainDiv">
        <ng-container *ngIf="!noAssignUsers;else radioType">
            <div class="form-check assignUsersList">
                <ng-container *ngIf="(users | FilterPipe: 'firstName': searchUser | orderBy: 'firstName') as result">
                    <ng-container *ngIf="result.length;else noSearchUsers">
                        <span *ngFor="let user of result">
                            <mat-checkbox class="selectbox mr-1" name="{{user.id}}" id="{{user.id}}" (change)="checkUsersList.emit({user, $event})" [(ngModel)]="user.selected"></mat-checkbox>
                            <span class="userFullName"> {{user.firstName}} {{user.lastName}}</span>
                        </span>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #radioType>
            <mat-radio-group aria-labelledby="users" [(ngModel)]="selectedUser" (change)="checkUsersList.emit({selectedUser, $event})">
                <ng-container *ngIf="(users | FilterPipe: 'firstName': searchUser | orderBy: 'firstName') as result">
                    <ng-container *ngIf="result.length;else noSearchUsers">
                        <mat-radio-button [disabled]="radioDisabled" *ngFor="let user of result" [value]=" user" [checked]="user?.checked">
                            {{user.firstName}} {{user.lastName}}
                        </mat-radio-button>
                    </ng-container>
                </ng-container>
            </mat-radio-group>
        </ng-template>
        <mat-error *ngIf="errors?.user?.length">
            {{errors?.user?.[0]}}
        </mat-error>
    </div>
</div>

<ng-template *ngIf="!users.length">
    <div class="noUsersSelectedDiv" *transloco="let translate;">
        <div class="noUserMsg">
            <span>{{translate('There are no users')}}</span>
        </div>
    </div>
</ng-template>
<ng-template #noSearchUsers>
    <div class="d-flex justify-content-center noUsersFound" *transloco="let translate;">
        <span>{{translate('No users found')}}</span>
    </div>
</ng-template>
