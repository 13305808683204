import { AssetData } from './assetdata.class';
import { BridgeNode } from './bridgeNode.interface';
import { WorkFlow } from './workflow.class';

export class AssetService {
    assetMetaUId?: string;

    createdDate?: string;

    filableReport?: string;

    id?: string;

    lastModifiedByUserId?: string;

    lastModifiedDate?: string;

    name?: string;

    displayName?: string;

    restApiName?: string;

    serviceId?: string;

    assetType?: 'BRIDGE_ASSET' | 'RECON' | 'ASSET' | '' | undefined;

    assets?: AssetService[];

    nextNodes?: string[];

    level?: number;

    tags?: { tagKey: string; tagValue: string }[];

    originalLastModifiedDate?: string;

    canCreateNewProcess?: boolean;

    canViewAllProcesses?: boolean;

    canViewIntergrations?: boolean;

    canManageWorkflow?: boolean;

    canViewVDM?: boolean;

    canProcessReports?: boolean;

    bridgePermissions?: any;

    show?: boolean;

    workflowStages?: WorkFlow[];

    assetData?: AssetData;

    request?: Request;

    filingAttributes?:
        | {
              captureOnce: boolean;
              dataType: string;
              entityName: string;
              fullId: string;
              isBusinessKey: boolean;
              isPrecaptured: boolean;
              keyId: string;
              label: string;
              value: string;
          }[]
        | boolean;

    // @property loading is used for loading symbols in home page.
    loading?: any;

    instance?: any;

    hasPermissionsFor?: { allProcesses: boolean; newProcess: boolean };

    reports?: BridgeNode[];

    componentType?: string;

    canCancelProcess?: boolean;

    status?: any;

    assetChecked?: boolean;

    noPermissions?: boolean;

    order?: number;

    constructor(asset?: AssetService) {
        this.assetMetaUId = asset?.assetMetaUId;
        this.createdDate = asset?.createdDate;
        this.filableReport = asset?.filableReport;
        this.id = asset?.id;
        this.lastModifiedByUserId = asset?.lastModifiedByUserId;
        this.lastModifiedDate = asset?.lastModifiedDate;
        this.name = asset?.name;
        this.displayName = asset?.displayName;
        this.restApiName = asset?.restApiName;
        this.serviceId = asset?.serviceId;
        this.assetType = asset?.assetType;
        this.assets = [];
        if (asset?.assets) {
            for (let i = 0; i < asset.assets.length; i++) {
                this.assets.push(new AssetService(asset.assets[i]));
            }
        }
        this.nextNodes = asset?.nextNodes;
        this.level = asset?.level;
        this.tags = asset?.tags;
        this.originalLastModifiedDate = asset?.originalLastModifiedDate;
        this.canCreateNewProcess = asset?.canCreateNewProcess;
        this.canViewAllProcesses = asset?.canViewAllProcesses;
        this.canViewIntergrations = asset?.canViewIntergrations;
        this.canManageWorkflow = asset?.canManageWorkflow;
        this.canViewVDM = asset?.canViewVDM;
        this.bridgePermissions = asset?.bridgePermissions;
        this.show = asset?.show;
        this.reports = asset?.reports || [];
        this.componentType = asset?.componentType;
        this.canCancelProcess = asset?.canCancelProcess;
        this.order = asset?.order;
    }

    pushAsset? = (asset: AssetService) => {
        this.assets.push(new AssetService(asset));
    };
}
