export class UserPermissions {
    transporterPermission?: boolean = false;

    outboundPermission?: boolean = false;

    inboundPermission?: boolean = false;

    rolePermission?: boolean = false;

    userPermission?: boolean = false;

    mastersPermission?: boolean = false;

    partnerPermission?: boolean = false;

    locationPermission?: boolean = false;

    integrationSettingsPermission?: boolean = false;

    reportPermission?: boolean = false;

    assetsPermission?: boolean = false;

    ewayBillUpdatePermission?: boolean = false;

    manageAppsPermission?: boolean = false;

    constructor(userPermissions?) {
        if (userPermissions.transporterPermission) {
            this.transporterPermission = userPermissions.transporterPermission;
        }

        if (userPermissions.outboundPermission) {
            this.outboundPermission = userPermissions.outboundPermission;
        }

        if (userPermissions.inboundPermission) {
            this.inboundPermission = userPermissions.inboundPermission;
        }
        if (userPermissions.rolePermission) {
            this.rolePermission = userPermissions.rolePermission;
        }
        if (userPermissions.userPermission) {
            this.userPermission = userPermissions.userPermission;
        }
        if (userPermissions.mastersPermission) {
            this.mastersPermission = userPermissions.mastersPermission;
        }
        if (userPermissions.locationPermission) {
            this.locationPermission = userPermissions.locationPermission;
        }
        if (userPermissions.partnerPermission) {
            this.partnerPermission = userPermissions.partnerPermission;
        }
        if (userPermissions.integrationSettingsPermission) {
            this.integrationSettingsPermission = userPermissions.integrationSettingsPermission;
        }
        if (userPermissions.reportPermission) {
            this.reportPermission = userPermissions.reportPermission;
        }

        if (userPermissions.assetsPermission) {
            this.assetsPermission = userPermissions.assetsPermission;
        }

        if (userPermissions.ewayBillUpdatePermission) {
            this.ewayBillUpdatePermission = userPermissions.ewayBillUpdatePermission;
        }

        if (userPermissions.manageAppsPermission) {
            this.manageAppsPermission = userPermissions.userPermissions;
        }
    }
}
