import { createAction, props } from '@ngrx/store';

import { Permission } from '../../models/permission.class';
import { Role } from '../../models/role.class';
import { RolesState } from '../states';

const enum rolesActionTypes {
    GET_ORGANIZATION_USER_ROLES = '[ROLES] Get User Roles in Organization',
    GET_CURRENT_ORG_SESSION_USER_ROLES = '[ROLES] Get Session User Current Organization Roles',
    SET_USER_ROLES = '[ROLES] Set User Roles',
    GET_ROLES = '[ROLES] Get Roles',
    SET_ROLES = '[ROLES] Set Roles',
    SET_BUNDLE_Id = '[ROLES] Set Bundle Id',
    GET_BUNDLE_ROLES = '[ROLES] Get Bundle Roles',
    SET_BUNDLE_ROLES = '[ROLES] Set Bundle Roles',
    SET_SELECTED_ROLE_ID = '[ROLES] Set Selected Role Id',
    CREATE_BUNDLE_ROLE = '[ROLES] Create Bundle Role',
    UPDATE_BUNDLE_ROLE = '[ROLES] Update Bundle Role',
    DELETE_BUNDLE_ROLE = '[ROLES] Delete Bundle Role',
    CREATE_ROLE = '[ROLES] Create Role',
    UPDATE_ROLES_AND_PERMISSIONS = '[ROLES] Update Roles And Permissions',
    UPDATE_ROLE_DATA = '[ROLES] Update Roles Data',
    GET_ROLE_USERS = '[ROLES] Get Role Users',
    SET_ROLE_USERS = '[ROLES] Set Role Users',
    DELETE_ROLE = '[ROLES] Delete Role',
    ROLE_CRUD_OPERTAIONS = '[ROLES] Role Crud Operations',
    UPDATE_ROLE_WITHOUT_PERMISSIONS = '[ROLES] Update Role Without Permissions',
    COPY_ROLE = '[ROLES] Copy Role',
    COPY_ROLE_SUCCESS_ACTION = '[ROLES] Copy Role Success Action',
    ASSIGN_AND_UNASSIGN_USERS = '[ROLES] Assign And Unassign Users',
    SET_ASSIGN_AND_UNASSIGN_USERS = '[ROLES] Set Assign And Unassign Users',
}

export const GetOrganizationUserRoles = createAction(rolesActionTypes.GET_ORGANIZATION_USER_ROLES);

export const GetSessionUserCurrentOrganizationRoles = createAction(rolesActionTypes.GET_CURRENT_ORG_SESSION_USER_ROLES);

export const SetUserRoles = createAction(
    rolesActionTypes.SET_USER_ROLES,
    props<{
        userId: string;
        organizationId: string;
        roles: RolesState['rolesMap']['0'][];
    }>()
);

export const GetRoles = createAction(rolesActionTypes.GET_ROLES);

export const SetRoles = createAction(
    rolesActionTypes.SET_ROLES,
    props<{
        organizationId: string;
        roles: any;
    }>()
);

export const SetBundleId = createAction(
    rolesActionTypes.SET_BUNDLE_Id,
    props<{
        bundleId: string;
    }>()
);

export const SetSelectedRoleId = createAction(
    rolesActionTypes.SET_SELECTED_ROLE_ID,
    props<{
        roleId: string;
    }>()
);

export const GetBundleRoles = createAction(
    rolesActionTypes.GET_BUNDLE_ROLES,
    props<{
        bundleId: string;
    }>()
);

export const SetBundleRoles = createAction(
    rolesActionTypes.SET_BUNDLE_ROLES,
    props<{
        organizationId: string;
        bundleId: string;
        roles: any;
    }>()
);

export const CreateBundleRole = createAction(
    rolesActionTypes.CREATE_BUNDLE_ROLE,
    props<{
        bundleId: string;
        roleData: any;
        permissions: any[];
    }>()
);

export const UpdateBundleRole = createAction(
    rolesActionTypes.UPDATE_BUNDLE_ROLE,
    props<{
        bundleId: string;
        roleId: string;
        roleData: any;
    }>()
);

export const DeleteBundleRole = createAction(
    rolesActionTypes.DELETE_BUNDLE_ROLE,
    props<{
        bundleId: string;
        roleId: string;
    }>()
);

export const CreateRole = createAction(
    rolesActionTypes.CREATE_ROLE,
    props<{
        rolesData: any;
        permissions: any[];
        has4ECAccess: boolean;
        actionType?: string;
    }>()
);

export const UpdateRolesAndPermissions = createAction(
    rolesActionTypes.UPDATE_ROLES_AND_PERMISSIONS,
    props<{
        data: {
            requestId: string;
            roleId: string;
            role: any;
            permissions: any[];
        };
    }>()
);

export const UpdateRoleData = createAction(
    rolesActionTypes.UPDATE_ROLE_DATA,
    props<{
        role: Role;
        permissions: Permission[];
        actionType?: string;
    }>()
);

export const GetRoleUsers = createAction(
    rolesActionTypes.GET_ROLE_USERS,
    props<{
        roleId: string;
        isDelete?: boolean;
    }>()
);

export const SetRoleUsersIds = createAction(
    rolesActionTypes.SET_ROLE_USERS,
    props<{
        organizationId: string;
        roleId: string;
        userIds: any;
    }>()
);

export const DeleteRole = createAction(
    rolesActionTypes.DELETE_ROLE,
    props<{
        roleId: string;
    }>()
);

export const RoleCrudOperataions = createAction(
    rolesActionTypes.ROLE_CRUD_OPERTAIONS,
    props<{
        actionType: string;
    }>()
);

export const UpdateRoleWithoutPermissions = createAction(
    rolesActionTypes.UPDATE_ROLE_WITHOUT_PERMISSIONS,
    props<{
        role: any;
    }>()
);

export const CopyRole = createAction(
    rolesActionTypes.COPY_ROLE,
    props<{
        data: {
            roleId: string;
            locationIds: any;
        };
    }>()
);

export const CopyRoleSuccessAction = createAction(rolesActionTypes.COPY_ROLE_SUCCESS_ACTION);

export const AssignAndUnassignUsers = createAction(
    rolesActionTypes.ASSIGN_AND_UNASSIGN_USERS,
    props<{
        roleId: string;
        selectedUserIds: string[];
    }>()
);

export const SetAssignAndUnassignUsers = createAction(
    rolesActionTypes.SET_ASSIGN_AND_UNASSIGN_USERS,
    props<{
        msg: string;
    }>()
);
