import { createReducer, on } from '@ngrx/store';

import {
    SetActiveTransformationsByType,
    SetChainDisplayNames,
    SetInboundDetailedTransformations,
    SetIntegrationsChains,
    SetMasterSchedulerTransformations,
    SetOutboundChains,
    SetTransformationNamesInChain,
    SetTransformations,
    SetTransformationsLoading,
    SetUpdatedTransformations,
} from '../actions';
import * as sessionActions from '../actions/session.actions';
import { transformationsState } from '../states';

export const TRANSFORMATIONS_REDUCER = createReducer(
    transformationsState,
    on(SetInboundDetailedTransformations, (state, action) => {
        const { inbound: inBoundTransformations, serviceId, organizationId, relativeOrganizations } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            relativeOrganizations: {
                                ...relativeOrganizations,
                            },
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                inboundDetailed: inBoundTransformations,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetUpdatedTransformations, (state, action) => {
        const { inbound: inBoundTransformations, serviceId, organizationId, relativeOrganizations } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            relativeOrganizations: {
                                ...relativeOrganizations,
                            },
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                inboundDetailed: inBoundTransformations,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetChainDisplayNames, (state, { serviceId, chainVsDisplaynames, organizationId }) => {
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            chainDisplayNames: chainVsDisplaynames,
                        },
                    },
                },
            },
        };
    }),
    on(SetTransformationsLoading, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    services: {
                        ...state.organizationsMap?.[action.organizationId]?.services,
                        [action.serviceId]: {
                            ...state.organizationsMap?.[action.organizationId]?.services?.[action.serviceId],
                            loading: {
                                ...state.organizationsMap?.[action.organizationId]?.services?.[action.serviceId]?.loading,
                                [action.category]: action.loading,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetTransformations, (state, action) => {
        const { serviceId, transformations, organizationId } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                inbound: transformations?.['INBOUND'],
                                outbound: transformations?.['OUTBOUND'],
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetActiveTransformationsByType, (state, action) => {
        const { serviceId, organizationId, transformationNames, transformationType } = action;
        let inboundActiveTransformations: string[];
        let outboundActiveTransformations: string[];

        if (transformationType === 'INBOUND') {
            inboundActiveTransformations = transformationNames;
            outboundActiveTransformations =
                state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations?.activeTransformations?.outbound;
        } else {
            inboundActiveTransformations =
                state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations?.activeTransformations?.inbound;
            outboundActiveTransformations = transformationNames;
        }
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                activeTransformations: {
                                    ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations
                                        ?.activeTransformations,
                                    inbound: inboundActiveTransformations,
                                    outbound: outboundActiveTransformations,
                                },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetMasterSchedulerTransformations, (state, action) => {
        const { serviceId, organizationId, transformations } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                masterScheduler: {
                                    ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations?.masterScheduler,
                                    inbound: transformations?.INBOUND,
                                    outbound: transformations?.OUTBOUND,
                                },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetIntegrationsChains, (state, action) => {
        const { serviceId, organizationId, chains } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                integrationsChains: {
                                    ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations?.integrationsChains,
                                    inbound: chains?.INBOUND,
                                    outbound: chains?.OUTBOUND,
                                },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetOutboundChains, (state, action) => {
        const { serviceId, organizationId, chains } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            transformations: {
                                ...state.organizationsMap?.[organizationId]?.services?.[serviceId]?.transformations,
                                chains: chains,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetTransformationNamesInChain, (state, action) => {
        const { organizationId, serviceId, chainName, transformations } = action;
        return {
            ...state,
            organizationsMap: {
                [organizationId]: {
                    ...state.organizationsMap?.[organizationId],
                    services: {
                        ...state.organizationsMap?.[organizationId]?.services,
                        [serviceId]: {
                            ...state.organizationsMap?.[organizationId]?.services?.[serviceId],
                            transformationsByChainName: {
                                ...state.organizationsMap[organizationId].services?.[serviceId].transformationsByChainName,
                                [chainName]: transformations,
                            },
                        },
                    },
                },
            },
        };
    }),

    on(sessionActions.SessionLogoutSuccessfull, () => {
        return transformationsState;
    })
);

export const TRANSFORMATIONS_REDUCER_KEY = 'transformations';
