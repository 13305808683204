<div *ngIf="field" stop-propagation>
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title>{{field.displayName}}: </h4>
        <span class="material-icons mb-2 matTableActionIcon" mat-dialog-close>close</span>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div *ngIf="allColumns && selectedColumns && tableData" class="gridTable" stop-propagation>
        <material-table [allTableColumns]="allColumns" [selectedColumns]="selectedColumns" [data]="tableData" [noLog]="true" [cellDataTypes]="cellDataTypes" [noSticky]="true"></material-table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="matDialogFooter" *transloco="let translate;" stop-propagation>
    <material-button mat-dialog-close class="mx-2" [color]="'default'" [label]="translate('Close')" (onTrigger)="closeModal()"></material-button>
    <material-button [hidden]="readOnly || hideDisplayNameData" [color]="'primary'" [label]="translate('Save')" [disabled]="recordUpdateDisabled" (onTrigger)="saveAndClose()" stop-propagation></material-button>
</mat-dialog-actions>
