import { createAction, props } from '@ngrx/store';

import { TagsState } from '../states';

enum TagsActions {
    TAGS_GET_KEYS = '[TAGS] Get Keys',
    TAGS_SET_KEYS = '[TAGS] Set Keys',
    TAGS_SET_KEY = '[TAGS] Set Key',
    TAGS_SET_KEY_VALUES = '[TAGS] Set All Key Values',
    TAGS_SET_VALUE = '[TAGS] Set Value',
    TAGS_GET_APPS_BY_KEY_VALUES = '[TAGS] Get Apps By Key Values',
    TAGS_SET_APPS_BY_KEY_VALUES = '[TAGS] Set Apps By Key Values',
    TAGS_RESET = '[TAGS] Reset Current Values',
    TAGS_GET_ASSET = '[TAGS] Get Asset Tags',
    TAGS_SET_ASSET = '[TAGS] Set Asset Tags',
}

export const GetTagKeys = createAction(TagsActions.TAGS_GET_KEYS);

export const SetTagKeys = createAction(TagsActions.TAGS_SET_KEYS, props<{ keys: string[]; organizationId: string }>());

export const SetTagKey = createAction(
    TagsActions.TAGS_SET_KEY,
    props<{
        key: string;
        organizationId?: string;
    }>()
);

export const SetTagKeyValues = createAction(
    TagsActions.TAGS_SET_KEY_VALUES,
    props<{
        values: string[];
        organizationId: string;
    }>()
);

export const SetTagValue = createAction(
    TagsActions.TAGS_SET_VALUE,
    props<{
        value: string;
        organizationId?: string;
    }>()
);

export const GetTagAppsByKeyValues = createAction(
    TagsActions.TAGS_GET_APPS_BY_KEY_VALUES,
    props<{
        tagKey: string;
        tagValue: string;
        noAlerts?: boolean;
    }>()
);

export const SetTagAppsByKeyValues = createAction(
    TagsActions.TAGS_SET_APPS_BY_KEY_VALUES,
    props<{
        apps: TagsState['organizationsMap']['key']['tagKeyValuesVsAppDetails']['key'];
        tagKey?: string;
        tagValue?: string;
        status?: string;
        organizationId?: string;
    }>()
);

export const ResetTag = createAction(TagsActions.TAGS_RESET, props<{ organizationId?: string }>());

export const GetAssetTags = createAction(
    TagsActions.TAGS_GET_ASSET,
    props<{
        serviceId: string;
    }>()
);

export const SetAssetTags = createAction(
    TagsActions.TAGS_SET_ASSET,
    props<{
        serviceId: string;
        tagKeyValues: { [property: string]: string };
    }>()
);
