<div [ngClass]="{'row': !maxNumberRecords}" *transloco="let translate;">
    <div class="feedMainDiv" [ngClass]="{'col-3': !maxNumberRecords}">
        <div class="workqFeedContainer">
            <div *ngIf="_utils.isCurrentPath('workq')" class="workqFeedSerchContainer uniqueSearch row">
                <mat-form-field class="search-form-field allevents col-10 p-0" appRemoveAriaOwns>
                    <mat-select [(value)]="formData.eventType" [placeholder]="translateMsg('Event Type')">
                        <mat-option value="" (click)="getAllEvents(formData.eventType)" [value]="">{{translate('All')}}</mat-option>
                        <mat-form-field class="searchEntities searchInputField SearcEvents ">
                            <input matInput [placeholder]="translateMsg('Search Events')" type="text" [(ngModel)]="allAssetsName" />
                            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="allAssetsName = ''">
                                <mat-icon class='searchEntitiesIcons' *ngIf='allAssetsName'>
                                    close
                                </mat-icon>
                                <mat-icon *ngIf='!allAssetsName' class="searchEntitiesIcons">
                                    search
                                </mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-option *ngFor="let event of eventTypesList | multiFilter: allAssetsName | AlphabeticalOrder: 'displayName'; trackBy: trackEventType " [value]="event.id" (click)="getAllEvents(formData.eventType)">{{translate(event.displayName)}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="col-2 refreshButton">
                    <button [title]="translateMsg('Refresh')" class="downloadIcon outbound" (click)="refreshEvents()" mat-icon-button>
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>
            <perfect-scrollbar class="workqFeedDiv" (psYReachEnd)="onScrollFetchRecords()" style="height: 75vh;">
                <div *ngIf="!records || !records.length" class="col-12 text-center padding15 noRecordMessage">{{translate('No records found')}}
                </div>
                <div *ngIf="records?.length" class="recordsContainer">
                    <ng-container *ngFor="let record of records">
                        <div class="workqFeedItem" (click)="onFeedItemSelect(record)" [class.feedActiveWorkqItem]="selectedRecordId === record.id  && (_utils.isCurrentPath('workq'))">
                            <div class="workqfeesSubjectField">{{record.subject}}</div>
                            <div>
                                <span>{{record.messageCategory}}</span>
                                <span class="markAsReadSpan" *ngIf="!record.isRead && _utils.isCurrentPath('workq')" (click)="makeRecordAsRead(record,$event)">{{translate('Mark as read')}}</span>
                            </div>
                            <div>
                                {{getRecordCreatedOnDate(record)}}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <div class="col-9 workdetailMainDiv" *ngIf="!maxNumberRecords">
        <router-outlet #selectedWorkqRecord="outlet"></router-outlet>
        <div *ngIf="!selectedWorkqRecord.isActivated" class="noRecordMainContainer">
            <div class="workqDetailsContainer">
                <div class="noRecordClass">{{translate('Select a record from the feed column')}}.</div>
                <perfect-scrollbar class="notifyContentContainer" style="height: calc(100vh - 101px);">
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
