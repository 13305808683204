import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { AssetData } from '../../models/assetdata.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { Masters } from '../../models/masters/masters.class';

@Injectable({
    providedIn: 'root',
})
export class RootScopeService {
    initialized: Promise<any>;
    assets: { [property: string]: AssetData } = {};
    bridgeNodes: { [property: string]: BridgeNode[] } = {};
    sessionExpiredModal: MatDialog;
    organizationHirarchy = {};
    tags = {};
    downtimeSchedules = {};
    tagKeysByServiceId = {};
    selectedMaster: any = {};
    masterSearchValidRules = true;
    masters: Masters = new Masters();
    widgetChartData = {};
    INSTANCE_STATES: any[] = [
        {
            name: 'In-Progress',
            value: 'IN_PROGRESS',
        },
        {
            name: 'Request Completed',
            value: 'REQUEST_COMPLETED',
        },
        {
            name: 'Process Cancelled',
            value: 'REQUEST_CANCELLED',
        },
    ];
    VALIDATION_STATUS: any[] = [
        {
            name: 'Success',
            value: 'SUCCESS',
        },
        {
            name: 'Warning',
            value: 'WARNING',
        },
        {
            name: 'Error',
            value: 'ERROR',
        },
    ];

    clearRootScope = () => {
        this.assets = {};
        this.bridgeNodes = {};
        this.organizationHirarchy = {};
        this.tags = {};
        this.downtimeSchedules = {};
        this.tagKeysByServiceId = {};
        this.selectedMaster = {};
        this.masterSearchValidRules = true;
        this.masters = new Masters();
        this.widgetChartData = {};
    };

    constructor() {}
}
