export class LookupValues {
    displayVsValue: Array<{
        key: string;
        value: string;
    }>;

    fieldId: string;

    lookupId: string;

    constructor(data) {
        this.displayVsValue = [];
        if (data && data.displayVsValue) {
            if (Array.isArray(data.displayVsValue)) {
                for (let i = 0; i < data.displayVsValue.length; i++) {
                    this.displayVsValue.push({
                        key: data.displayVsValue[i].key,
                        value: data.displayVsValue[i].value,
                    });
                }
            } else {
                //IMPTAX-5547 Return Month Values should start with 01 insted it is starting with 10
                if (data && data.displayVsValue) {
                    const keysSorted = Object.keys(data.displayVsValue).sort(function (a, b) {
                        return data.displayVsValue[a] - data.displayVsValue[b];
                    });
                    keysSorted &&
                        keysSorted.forEach((key) => {
                            this.displayVsValue.push({
                                key: key,
                                value: data.displayVsValue[key],
                            });
                        });
                }
                // tslint:disable-next-line:forin

                // for (const key in data.displayVsValue) {
                //     this.displayVsValue.push({
                //         key: key,
                //         value: data.displayVsValue[key],
                //     });
                // }
            }
        }
        this.fieldId = data && data.fieldId;
        this.lookupId = data && data.lookupId;
    }
}
