import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ROLES_REDUCER_KEY } from '../reducers';
import { RolesState } from '../states';
import { getSelectedBundleId$ } from './bundles.selector';
import { getCurrentOrganizationId$, getSessionUserId$ } from './session.selector';

const currentState = createFeatureSelector<RolesState>(ROLES_REDUCER_KEY);

export const getRolesOrganizationMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizationsMap?.[organizationId]
);

export const getRolesMap$ = createSelector(currentState, (state) => state.rolesMap);

export const roleOrganizationMap$ = createSelector(currentState, (state) => state.assignedRolesMap);

export const getSelectedRoleId$ = createSelector(currentState, (state) => state.selectedRoleId);

export const getSessionUserCurrentOrganizationRoleIds$ = createSelector(
    roleOrganizationMap$,
    getCurrentOrganizationId$,
    getSessionUserId$,
    (assignedRolesMap, organizationId, userId) => assignedRolesMap?.[organizationId]?.[userId]
);

export const getSessionUserCurrentOrganizationRoles$ = createSelector(
    getSessionUserCurrentOrganizationRoleIds$,
    getRolesMap$,
    (roleIds, rolesMap) =>
        roleIds?.reduce((roles, id) => {
            roles.push(rolesMap?.[id]);
            return roles;
        }, [] as RolesState['rolesMap'][0][])
);

export const getRoles$ = createSelector(getRolesOrganizationMap$, (organizationMap) => organizationMap?.roles);

export const getBundleRoles$ = createSelector(
    getRolesOrganizationMap$,
    getSelectedBundleId$,
    (organizationMap, bundleId) => organizationMap?.bundleRoles?.[bundleId]
);

export const getRoleUserIds$ = createSelector(
    getRolesOrganizationMap$,
    getSelectedRoleId$,
    (organizationMap, roleId) => organizationMap?.roleUserIds?.[roleId]
);
