import { EntityAdapter } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { Organization } from '../../models/organization.class';
import { ManageAppsOrganizationEntity, ManageAppsState, Service } from '../states/manage-apps.state';

export enum ManageAppsActionTypes {
    MANAGE_APPS_SET_ASSET_TYPE = '[MANAGE APPS] Set Asset Type',
    MANAGE_APPS_SET_ORGANIZATION_ENTITY = '[MANAGE APPS] Set Organization Entity',
    MANAGE_APPS_SET_STATUS = '[MANAGE APPS] Set Apps Status',
    MANAGE_APPS_SET_PAGE_INDEX = '[MANAGE APPS] Set Apps Page Index',
    MANAGE_APPS_SET_PAGE_SIZE = '[MANAGE APPS] Set Apps Page Size',
    MANAGE_APPS_SET_RESPONSE = '[MANAGE APPS] Set Apps Response',
    MANAGE_APPS_UPDATE_SERVICE = '[MANAGE APPS] Update Service',
    MANAGE_APPS_UPDATE_BRIDGE_NODE = '[MANAGE APPS] Update Bridge Node',
    MANAGE_APPS_SET_SEARCH_TEXT = '[MANAGE APPS] Set Search Text',
    MANAGE_APPS_SET_TAGS_FILTER = '[MANAGE APPS] Set Tags Filter',
    MANAGE_APPS_SET_TAGGED_APPS = '[MANAGE APPS] Set Tagged Apps',
    MANAGE_APPS_GET_TAGGED_APPS_RESPONSE = '[MANAGE APPS] Get Tagged Apps Response',
    MANAGE_APPS_SET_TAGGED_APPS_RESPONSE = '[MANAGE APPS] Set Tagged Apps Response',
    MANAGE_APPS_RESET_SEARCH = '[MANAGE APPS] Reset Search',
    MANAGE_APPS_SET_BRIDGE_ID = '[MANAGE APPS] Set Bridge Id',
    MANAGE_APPS_SET_BRIDGE_NODES = '[MANAGE APPS] Set Bridge Nodes',
    MANAGE_APPS_SET_SELECTED_SERVICE_IDS = '[MANAGE APPS] Set Selected Service Ids',
    MANAGE_APPS_SUBMIT_SELECTED_SERVICE_IDS = '[MANAGE APPS] Submit Selected Service Ids',
    MANAGE_APPS_UPDATE_SERVICES = '[MANAGE APPS] Update Services',
    MANAGE_APPS_GET_ASSIGNABLE_SUBSCRIPTION_IDS = '[MANAGE APPS] Get Assignable Subscription Ids',
    MANAGE_APPS_SET_ASSIGNABLE_SUBSCRIPTION_IDS = '[MANAGE APPS] Set Assignable Subscription Ids',
    MANAGE_APPS_SET_BUNDLE_SUBSCRIPTION_IDS = '[MANAGE APPS] Set Bundle Subscription Ids',
    MANAGE_APPS_SELECT_SERVICES = '[MANAGE APPS] Select Services',
    MANAGE_APPS_CLEAR_SUBSCRIPTIONS_APPROVAL = '[MANAGE APPS] Clear Subscriptions Approval',
    MANAGE_APPS_CLEAR_DATA = '[MANAGE APPS] Clear Data',
    MANAGE_APPS_SET_ORGANIZATION = '[MANAGE APPS] Set Organization',
    MANAGE_APPS_SET_ORGS_APPS = '[MANAGE APPS] Set Organizations and Apps',
    MANAGE_APPS_SET_GET_BY_PAGINATION = '[MANAGE APPS] Set Get By Pagination',
    MANAGE_APPS_SET_COUNT = '[MANAGE APPS] Set Count',
    MANAGE_APPS_SUBMIT_APP_STATUS = '[MANAGE APPS] Submit App Status',
    MANAGE_APPS_UPDATE_PROPERTY = '[MANAGE APPS] Update Property',
}

export const ManageAppsSetOrganization = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_ORGANIZATION,
    props<{
        organization: Organization;
    }>()
);

export const ManageAppsSetOrgsAndApps = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_ORGS_APPS,
    props<{
        itemIdArray: string[];
        orgIdArray: string[];
        itemNames: string[];
    }>()
);

export const ManageAppsChangeCategory = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_ASSET_TYPE,
    props<{
        assetType: ManageAppsState['assetType'];
    }>()
);

export const SetManageAppsOrganizationEntity = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_ORGANIZATION_ENTITY,
    props<{
        servicesEntity: ManageAppsOrganizationEntity;
        organizationsAdapter: EntityAdapter<ManageAppsOrganizationEntity>;
    }>()
);

export const SetManageAppsStatus = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_STATUS,
    props<{
        subscriptionStatus: ManageAppsState['subscriptionStatus'];
        organizationId: string;
    }>()
);

export const SetManageAppsPageSize = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_PAGE_SIZE,
    props<{
        size: number;
    }>()
);

export const SetManageAppsPageIndex = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_PAGE_INDEX,
    props<{
        pageIndex: number;
    }>()
);

export const SetManageAppsSearchText = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_SEARCH_TEXT,
    props<{
        searchText: string;
    }>()
);

export const SetManageAppsTagsFilter = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_TAGS_FILTER,
    props<{
        serviceIds: string[];
        searchText: string;
        tagKey: string;
        tagValue: string;
    }>()
);

export const SetManageAppsResponse = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_RESPONSE,
    props<{
        organizationsAdapter: EntityAdapter<ManageAppsOrganizationEntity>;
        servicesAdapter: EntityAdapter<Service>;
        services: Service[];
        orgIdVsName?: { [property: string]: string };
    }>()
);

export const UpdateManageAppsService = createAction(
    ManageAppsActionTypes.MANAGE_APPS_UPDATE_SERVICE,
    props<{
        organizationsAdapter: EntityAdapter<ManageAppsOrganizationEntity>;
        servicesAdapter: EntityAdapter<Service>;
        assetId: string;
        property: string;
        propertyValue: any;
    }>()
);

export const UpdateManageAppsBridgeNode = createAction(
    ManageAppsActionTypes.MANAGE_APPS_UPDATE_BRIDGE_NODE,
    props<{
        organizationsAdapter: EntityAdapter<ManageAppsOrganizationEntity>;
        servicesAdapter: EntityAdapter<Service>;
        assetId: string;
        bridgeNodeId: string;
        property: string;
        propertyValue: any;
    }>()
);

export const GetManageAppsTaggedApps = createAction(
    ManageAppsActionTypes.MANAGE_APPS_GET_TAGGED_APPS_RESPONSE,
    props<{
        serviceIds: string[];
    }>()
);

export const SetManageAppsTaggedAppsResponse = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_TAGGED_APPS_RESPONSE,
    props<{
        filteredAppsByTags: {
            value: string;
            display: string;
            id: string;
        }[];
    }>()
);

export const ManageAppsResetSearchText = createAction(ManageAppsActionTypes.MANAGE_APPS_RESET_SEARCH);

export const ManageAppsSetBridgeId = createAction(ManageAppsActionTypes.MANAGE_APPS_SET_BRIDGE_ID, props<{ assetId: string }>());

export const SetManageAppsSelectedServiceIds = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_SELECTED_SERVICE_IDS,
    props<{
        ids: string[];
    }>()
);

export const SubmitManageAppsSelectedServiceIds = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SUBMIT_SELECTED_SERVICE_IDS,
    props<{
        ids: string[];
        orgIds: string[];
    }>()
);

export const ManageAppsUpdateMany = createAction(
    ManageAppsActionTypes.MANAGE_APPS_UPDATE_SERVICES,
    props<{
        ids: string[];
        organizationsAdapter: EntityAdapter<ManageAppsOrganizationEntity>;
        servicesAdapter: EntityAdapter<Service>;
        property: string;
        propertyValue: any;
    }>()
);

export const GetManageAppsAssignableSubscriptionIds = createAction(ManageAppsActionTypes.MANAGE_APPS_GET_ASSIGNABLE_SUBSCRIPTION_IDS);

export const SetManageAppsAssignableSubscriptionIds = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_ASSIGNABLE_SUBSCRIPTION_IDS,
    props<{ ids: string[] }>()
);

export const SetManageAppsBundleSubscriptionIds = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_BUNDLE_SUBSCRIPTION_IDS,
    props<{ ids: string[] }>()
);

export const SelectManageAppsServices = createAction(ManageAppsActionTypes.MANAGE_APPS_SELECT_SERVICES, props<{ ids: string[] }>());

export const ClearManageAppsSubscriptionApproval = createAction(ManageAppsActionTypes.MANAGE_APPS_CLEAR_SUBSCRIPTIONS_APPROVAL);

export const ManageAppsSetGetByPagination = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_GET_BY_PAGINATION,
    props<{
        status: boolean;
    }>()
);

export const ManageAppsSetCount = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SET_COUNT,
    props<{
        count: number;
    }>()
);

export const ManageAppsSubmitAppStatus = createAction(
    ManageAppsActionTypes.MANAGE_APPS_SUBMIT_APP_STATUS,
    props<{
        payload: {
            subscriptionIds: string[];
            status: string;
        };
    }>()
);

export const ManageAppsUpdateProperty = createAction(
    ManageAppsActionTypes.MANAGE_APPS_UPDATE_PROPERTY,
    props<{
        ids: string[];
        organizationsAdapter: EntityAdapter<ManageAppsOrganizationEntity>;
        servicesAdapter: EntityAdapter<Service>;
        property: string;
        propertyValue: any;
    }>()
);
