<ng-container *transloco="let translate;">
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom noBorder">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('session expired')}}</h4>
        <span class="material-icons mb-1" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody" align="center">
        <div class="sessionContentDiv my-4">
            {{translate('Session expired Click on OK button to proceed to login')}}
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="matDialogFooter noBorder">
        <material-button class='noOutline submitColor' [label]="translate('OK')" color="primary" (onTrigger)="closeModal()"></material-button>
    </mat-dialog-actions>
</ng-container>
