import { AuthorizationSystem } from '../../models/integrations/authorization-system.class';

export interface AuthorizationSystemsState {
    configs: AuthorizationSystem[];
    configOptions: {
        RESTAPI: { displayName: string; id: string }[];
        EMAIL: { displayName: string; id: string }[];
    };
    authorizationId: string;
    authorizationName: string;
}

export const authorizationsSystemState: AuthorizationSystemsState = {
    configs: undefined,
    configOptions: {
        EMAIL: [],
        RESTAPI: [],
    },
    authorizationId: undefined,
    authorizationName: undefined,
};
