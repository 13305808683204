import { createReducer, on } from '@ngrx/store';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import * as actions from '../actions';
import { identityProviderState } from '../states';

export const IDENTITY_PROVIDERS_REDUCER = createReducer(
    identityProviderState,
    on(actions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(actions.SetIdentityProviders, (state, action) => {
        if (state?.currentOrganizationId?.length === 0) {
            return;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [state.currentOrganizationId]: {
                    ...state.organizationsMap?.[state.currentOrganizationId]?.identityProviders,
                    identityProviders: action.identityProviders,
                },
            },
        };
    }),
    on(actions.SetIdentityProvider, (state, action) => {
        const providersObservable = state?.organizationsMap?.[state.currentOrganizationId]?.identityProviders || [];
        let providers = CommonUtilsService.cloneObject(providersObservable);
        switch (action.actionType) {
            case 'CREATE':
                providers.unshift(action.identityProvider);
                break;
            case 'EDIT':
                providers.forEach((provider) => {
                    if (provider.id === action.identityProvider.id) {
                        Object.assign(provider, action.identityProvider);
                    }
                });
                break;
            case 'DELETE':
                providers = providers.filter((provider) => provider.id !== action.identityProvider.id);
                break;
        }

        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [state.currentOrganizationId]: {
                    ...state.organizationsMap?.[state.currentOrganizationId]?.identityProviders,
                    identityProviders: providers,
                },
            },
        };
    })
);
export const IDENTITY_PROVIDERS_REDUCER_KEY = 'identityProviders';
