import { createAction, props } from '@ngrx/store';

import { SharedState } from '../states';

export enum SharedActionTypes {
    ALERT_ERROR = '[SHARED] Alert Error',
    ALERT_SUCCESS = '[SHARED] Alert Success',
    SET_LOGO_HREF = '[SHARED] Set Logo Herf',
    GET_SUPPORTED_REGIONS = '[SHARED] Get Supported Regions',
    SET_SUPPORTED_REGIONS = '[SHARED] Set Supported Regions',
    CREATE_NAV_MAP = '[SHARED] Create Nav Map',
    SET_NAV_MAP = '[SHARED] Set Left Nav Map',
    SET_GROUP_MAP = '[SHARED] Set GROUP Map',
    SET_SEARCHED_VALUE = '[SHARED] Set Searched Value',
    SET_SELECTED_CATEGORY = '[SHARED] Set Selected Category',
}

export const AlertError = createAction(
    SharedActionTypes.ALERT_ERROR,
    props<{
        message: string;
        timeout?: number;
    }>()
);

export const SetLogoHref = createAction(
    SharedActionTypes.SET_LOGO_HREF,
    props<{
        logoHref: string;
    }>()
);

export const SetSearchedValue = createAction(
    SharedActionTypes.SET_SEARCHED_VALUE,
    props<{
        searchText: string;
    }>()
);

export const SetSelectedCategory = createAction(
    SharedActionTypes.SET_SELECTED_CATEGORY,
    props<{
        selectedCategory: string;
    }>()
);

export const AlertSuccess = createAction(
    SharedActionTypes.ALERT_SUCCESS,
    props<{
        message: string;
        timeout?: number;
        actionText?: string;
        onActionCallBack?: () => void;
    }>()
);

export const CreateNavMap = createAction(SharedActionTypes.CREATE_NAV_MAP);

export const SetNavMap = createAction(
    SharedActionTypes.SET_NAV_MAP,
    props<{
        payload: SharedState['leftNavMap'];
    }>()
);

export const SetGroupsMap = createAction(
    SharedActionTypes.SET_GROUP_MAP,
    props<{
        payload: SharedState['groupsMap'];
    }>()
);

export const GetSupportedRegions = createAction(SharedActionTypes.GET_SUPPORTED_REGIONS);

export const SetSupportedRegions = createAction(
    SharedActionTypes.SET_SUPPORTED_REGIONS,
    props<{
        supportedRegions: any;
    }>()
);
