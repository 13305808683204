import { Injectable } from '@angular/core';

import { PermissionsObject } from '../../interface/permissions.interface';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    constructor(
        private _bridge: BridgeService,
        private _utils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private _store: StoreService
    ) {}

    /**
     * @param data contains permissions object
     * @param callbacks contains both success and failure callbacks
     */
    private getPermissionsFromMapro = (
        data: {
            actionName: string;
            category: string;
            createdDate: string;
            displayName: string;
            id: number;
            objectId: string;
            permissionType: string;
            pageNum?: number;
            pageSize?: number;
            categories?: string[];
            objectIds?: string[];
            hideAlerts?: boolean;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.getUserPermissions(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * @param data contains permissions object
     * @param callbacks contains both success and failure callbacks
     */
    private getPermissionsFromIDM = (
        data: {
            actionName: string;
            category: string;
            createdDate: string;
            displayName: string;
            id: number;
            objectId: string;
            permissionType: string;
            pageNum?: number;
            pageSize?: number;
            categories?: string[];
            objectIds?: string[];
            hideAlerts?: boolean;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.getUserIDMPermissions(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    private getPermissionsByPage = (
        pageNum: number,
        pageSize: number,
        categories: string[],
        objectIds: string[],
        idm?: boolean,
        hideAlerts?: boolean
    ) => {
        return new Promise((resolve, reject) => {
            const payload = this._commonUtils.getFromStorage('user');
            if (!payload) {
                return resolve([]);
            }
            payload.pageNum = pageNum;
            payload.pageSize = pageSize;
            payload.categories = categories;
            payload.objectIds = objectIds;
            payload.hideAlerts = hideAlerts;
            const method = idm ? this.getPermissionsFromIDM : this.getPermissionsFromMapro;
            method(payload as any, {
                successCallback: (res) => {
                    resolve(res && res.response);
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || 'Failed to get Permissions response');
                    reject();
                },
            });
        });
    };

    getPermissionsByCriteria = (objectIds?: string[], categories?: string[], idm?: boolean, hideAlerts?: boolean): Promise<any> => {
        return new Promise(async (resolve) => {
            const idmCheck = idm ? 'idmPermissions' : 'permissions';
            await this._store.privateScope.fetchValues(
                () => {
                    return new Promise(async (serverResolve) => {
                        let pageNum = 1;
                        const pageSize = 5000;
                        const permsObj = {};
                        permsObj[pageNum] = await this.getPermissionsByPage(pageNum, pageSize, categories, objectIds, idm, hideAlerts);
                        while (permsObj[pageNum] && permsObj[pageNum].length && permsObj[pageNum].length === pageSize) {
                            pageNum++;
                            permsObj[pageNum] = await this.getPermissionsByPage(pageNum, pageSize, categories, objectIds, idm, hideAlerts);
                        }
                        let permissions = [];
                        Object.keys(permsObj).forEach((key) => {
                            permissions = [...permissions, ...permsObj[key]];
                        });
                        const userPermissions: PermissionsObject = {} as any;
                        this._utils.transformPermissionStructure(permissions, userPermissions);
                        serverResolve(userPermissions);
                    });
                },
                idmCheck,
                objectIds,
                categories
            );
            resolve(this.getStoreValues());
        });
    };

    getBridgePermissions = (serviceId: string, hideAlerts?: boolean) => {
        return this.getPermissionsByCriteria(['BRIDGE_ASSET'], [serviceId], undefined, hideAlerts);
    };

    getAppPermissions = (serviceId: string, hideAlerts?: boolean) => {
        return this.getPermissionsByCriteria(['ASSET'], [serviceId], undefined, hideAlerts);
    };

    getWidgetPermissions = (serviceId: string) => {
        return this.getPermissionsByCriteria(['WIDGET'], [serviceId]);
    };

    getReconPermissions = (serviceId: string) => {
        return this.getPermissionsByCriteria(['RECON'], [serviceId]);
    };

    getDashboardPermissions = (serviceId: string) => {
        return this.getPermissionsByCriteria(['DASHBOARD'], [serviceId]);
    };

    private transformBridgeNodes = (app: AssetService) => {
        if (app.assets?.length > 0) {
            app.assets.forEach((subApp) => {
                this._utils.transformNodePermissions(subApp as any as BridgeNode, app.bridgePermissions);
            });
        }
        if (app.reports?.length > 0) {
            app.reports.forEach((subApp) => {
                this._utils.transformNodePermissions(subApp as any as BridgeNode, app.bridgePermissions);
            });
        }
        if (Object.keys(app['subApps'] || {}).length > 0) {
            Object.keys(app['subApps']).forEach((appId) => {
                this._utils.transformNodePermissions(app['subApps'][appId] as any as BridgeNode, app.bridgePermissions);
            });
        }
    };

    transformAppWithPermissions = async (app: AssetService) => {
        let permissionSections;
        let permissions;
        const type = app.assetType || (app as any).type || 'ASSET';
        if (type === 'BRIDGE_ASSET') {
            const userPermissions = await this.getBridgePermissions(app?.serviceId);
            permissionSections = userPermissions[type];
            permissions = permissionSections && permissionSections[app.serviceId];
            app.bridgePermissions = permissions;
            this.transformBridgeNodes(app);
        } else if (type === 'WIDGET') {
            const userPermissions = await this.getWidgetPermissions(app?.serviceId);
            permissionSections = userPermissions[type];
            permissions = permissionSections && permissionSections[app.serviceId];
        } else if (type === 'RECON') {
            const userPermissions = await this.getReconPermissions(app?.serviceId);
            permissionSections = userPermissions[type];
            permissions = permissionSections && permissionSections[app.serviceId];
        } else if (type === 'DASHBOARD') {
            const userPermissions = await this.getDashboardPermissions(app?.serviceId);
            permissionSections = userPermissions[type];
            permissions = permissionSections && permissionSections[app.serviceId];
        } else {
            const userPermissions = await this.getAppPermissions(app?.serviceId);
            permissionSections = userPermissions[type];
            permissions = permissionSections && permissionSections[app.serviceId];
        }
        this._utils.addNodePermissions(app, permissions?.permissions);
    };

    transformAppsWithPermissions = async (apps: AssetService[], hideAlerts?: boolean) => {
        return new Promise(async (resolve) => {
            const objectIds = [];
            const categories = [];
            apps.forEach((app) => {
                if (app.serviceId) {
                    categories.push(app.serviceId);
                }
                const type = app.assetType || (app as any).type || 'ASSET';
                if (objectIds.indexOf(type) === -1) {
                    objectIds.push(type);
                }
            });
            const userPermissions = await this.getPermissionsByCriteria(objectIds, categories, undefined, hideAlerts);
            const promises = [];
            apps.forEach(async (app) => {
                promises.push(
                    new Promise<void>(async (appResolve) => {
                        let permissionSections;
                        let permissions;
                        const type = app.assetType || (app as any).type || 'ASSET';
                        if (type === 'BRIDGE_ASSET') {
                            permissionSections = userPermissions['BRIDGE_ASSET'];
                            permissions = permissionSections && permissionSections[app.serviceId];
                            app.bridgePermissions = permissions;
                            this.transformBridgeNodes(app);
                        } else {
                            permissionSections = userPermissions[type];
                            permissions = permissionSections && permissionSections[app.serviceId];
                        }
                        this._utils.addNodePermissions(app, permissions?.permissions);
                        appResolve();
                    })
                );
            });
            Promise.all(promises).then(() => {
                resolve(this._store.privateScope.getScope().permissions);
            });
        });
    };

    public getOrganizationRelatedPermissions = () => {
        return new Promise((resolve) => {
            const maproPermissions = this.getPermissionsByCriteria(['PROVIDER', 'CUSTOMER']);
            const idmPermissions = this.getPermissionsByCriteria(['PROVIDER', 'CUSTOMER'], undefined, true);
            return Promise.all([maproPermissions, idmPermissions]).then(() => {
                resolve(this.getStoreValues());
            });
        });
    };

    private getStoreValues = () => {
        const mapro = this._store.privateScope.getScope()?.permissions;
        const idm = this._store.privateScope.getScope()?.idmPermissions;
        return this._utils.copyObjectToObject(CommonUtilsService.cloneObject(mapro || {}), idm || {});
    };
}
