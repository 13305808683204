import { CommonUtilsService } from '../services/commonutils/common-utils.service';
import { Field } from './field.class';
import { Message } from './record/message.class';
import { Rule } from './rule.class';

export class FilingAttributeField {
    dataType: string;
    entityId: string;
    fullId: string;
    id: string;
    isBusinessKey: boolean;
    lookupConditionExpressions: any;
    lookupDisplayField: string;
    lookupId: string;
    lookupValueField: string;
    mandatory: boolean;
    name: string;
    displayName: string;
    readOnly: boolean;
    searchable: boolean;
    show: boolean;
    sortable: boolean;
    uiType: string;
    message: Message;
    value: any;
    outputFormat: any;
    rules: Rule[];
    originalUiType: string;
    lookupValues?: any;
    description?: string;
    dateDisplayValue?: string;
    autoCalculate?: boolean;
    hasMasterLookup?: boolean;
    label?: string;

    constructor(field, entityField?: Field) {
        this.dataType = field?.dataType;
        this.entityId = field?.entityId;
        this.fullId = field?.fullId;
        this.id = field?.id;
        this.isBusinessKey = field?.isBusinessKey;
        this.lookupConditionExpressions = field?.lookupConditionExpressions;
        this.lookupDisplayField = field?.lookupDisplayField;
        this.lookupId = field?.lookupId;
        this.lookupValueField = field?.lookupValueField;
        this.mandatory = field?.mandatory;
        this.name = entityField?.displayName || entityField?.name || field?.name;
        this.readOnly = field?.readOnly;
        this.searchable = field?.searchable;
        this.show = field?.show;
        this.sortable = field?.sortable;
        this.uiType = field?.uiType || 'TEXT';
        if (field?.dataType === 'boolean') {
            this.uiType = 'BOOLEAN';
        } else if (this.uiType === 'RADIOBUTTON') {
            this.uiType = 'DROPDOWN';
            this.originalUiType = 'RADIOBUTTON';
        }
        if (field?.dataType === 'date' && (!field?.lookupId || !field?.lookupValueField)) {
            this.uiType = 'DATE';
        }
        this.outputFormat = field?.outputFormat;
        this.message = new Message((field?.message !== undefined && field?.message) || {});
        this.value = field?.value !== undefined ? field?.value : field?.defaultValue;
        this.rules = (entityField && CommonUtilsService.cloneObject(entityField.rules)) || [];
        this.lookupValues = (entityField && CommonUtilsService.cloneObject(entityField.lookupValues)) || [];
        this.description = field?.description || entityField?.description;
        this.dateDisplayValue = field?.dateDisplayValue;
        this.autoCalculate = field?.autoCalculate;
    }
}
