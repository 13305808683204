import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BroadcasterService } from 'ng-broadcaster';
import { Observable } from 'rxjs';
import { CommonUtilsService } from 'taxilla-library';

import { RootScope } from '../rootscope/rootscope.service';
import { Utils } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CanActivateRouteService  {
    constructor(
        private R: RootScope,
        private _utils: Utils,
        private _broadcaster: BroadcasterService,
        private _router: Router,
        private _commonUtils: CommonUtilsService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (window.location !== window.parent.location) {
            return;
        }
        const session = this._commonUtils.getFromStorage('session');
        const browserSession = this.R.session.getValue();
        let proceed = true;
        const isSecuredRoute = route.data.secured;
        if (isSecuredRoute && (!session || session !== browserSession)) {
            proceed = true;
        } else if (session && browserSession && !isSecuredRoute) {
            if (!route.data.continueIfInSession) {
                this.setRoute(route);
                proceed = false;
            }
        } else if (!isSecuredRoute && session) {
            if (route.data.skipSession) {
                proceed = true;
            } else {
                this.setRoute(route);
                proceed = false;
            }
        }
        return proceed;
    }

    private setRoute = (route: ActivatedRouteSnapshot) => {
        const returnUrl = route.queryParams.returnUrl;
        if (returnUrl) {
            if (returnUrl.indexOf('http')) {
                this._broadcaster.broadcast('setCrossStorage', { route: returnUrl });
            } else {
                this._router.navigateByUrl(returnUrl && returnUrl.indexOf('/') === 0 ? returnUrl.substring(1) : returnUrl);
            }
        } else {
            this._utils.setRoute('organizations');
        }
    };
}
