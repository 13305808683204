import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BUNDLES_REDUCER_KEY } from '../reducers/bundles.reducer';
import { Bundle, BundlesState } from '../states';

const currentState = createFeatureSelector<BundlesState>(BUNDLES_REDUCER_KEY);

export const getBundlesOrganization$ = createSelector(currentState, (state) => state.currentOrganizationId);

export const getBundlesOrganizationEntity$ = createSelector(currentState, (state) => {
    return state.organizations.entities?.[state.currentOrganizationId];
});

export const getSelectedBundleId$ = createSelector(currentState, (state) => {
    return state.selectedBundleId;
});

export const getSelectedBundle$ = createSelector(currentState, (state) => {
    return (
        state.organizations.entities?.[state.currentOrganizationId]?.bundles?.entities?.[state.selectedBundleId] || {
            createdByUserId: undefined,
            createdDate: undefined,
            description: undefined,
            id: undefined,
            lastModifiedByUserId: undefined,
            lastModifiedDate: undefined,
            name: undefined,
            unitId: state.currentOrganizationId,
        }
    );
});

export const getBundles$ = createSelector(currentState, (state) => {
    const bundlesEntity = state.organizations.entities?.[state.currentOrganizationId]?.bundles;
    return (bundlesEntity?.ids as string[])?.reduce((bundles, id) => {
        bundles.push(bundlesEntity.entities[id]);
        return bundles;
    }, []);
});

export const getBundlesByFilters$ = createSelector(currentState, (state) => {
    const bundlesEntity = state.organizations.entities?.[state.currentOrganizationId]?.bundles;
    const searchText = state.searchText || '';
    return (
        ((bundlesEntity?.ids as string[])?.reduce((bundles, id) => {
            bundles.push(bundlesEntity.entities[id]);
            return bundles;
        }, []) || []) as Bundle[]
    )
        .filter((bundle) => bundle?.name?.includes(searchText) || bundle?.name?.toLowerCase()?.includes(searchText?.toLowerCase()))
        .sort((a, b) => {
            const aName = a?.name;
            const bName = b?.name;
            if (aName < bName) {
                return -1;
            } else if (aName > bName) {
                return 1;
            }
            return 0;
        });
});

export const getBundleInvitations$ = createSelector(currentState, (state) => {
    return state.bundleInvitations[state.selectedBundleId];
});
