import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AssetService, CommonUtilsService, FetchAssetsModalComponent, getApp$, getCurrentOrganizationId$ } from 'taxilla-library';

import { AppProcessesSelectionDirective } from '../../directives/app-processes/app-processes.directive';

@Component({
    selector: 'app-left-nav-apps-template',
    templateUrl: './left-nav-apps-template.component.html',
    styleUrls: ['./left-nav-apps-template.component.scss'],
    providers: [AppProcessesSelectionDirective],
})
export class LeftNavAppsTemplateComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;
    @Input() groupId: string;
    @Input() package: {
        name: string;
        id: string;
        src: string;
        srcHover: string;
        apps: {
            order: number;
            serviceId: string;
            displayName: string;
            restApiName: string;
        }[];
        groups: {
            id: string;
            order: number;
        }[];
    };
    @Output() closeMenu = new EventEmitter();
    @Output() bridgeDialogOpen = new EventEmitter<boolean>();

    public app: AssetService;
    private unsubscribe = new Subject<void>();
    private currentOrganizationId: string;
    public asset: any;

    constructor(
        private store$: Store,
        private _router: Router,
        private _allProcesses: AppProcessesSelectionDirective,
        private dialog: MatDialog
    ) {}

    public navigateToGroup = (id: string) => {
        this.closeMenu.emit();
        this._router.navigate(['gst-filing', 'organizations', this.currentOrganizationId, 'home', id]);
        this.dialog.closeAll();
    };

    selectionEvent = () => {
        this.closeMenu.emit();
        this._allProcesses.app = this.app;
        this._allProcesses.onAppSelection();
    };

    public fetchBridgeAssets = (app: AssetService) => {
        this.bridgeDialogOpen.emit(true);
        const dialog = this.dialog.open(FetchAssetsModalComponent, {
            panelClass: ['fetchAssetModalParent', 'subscribeAssetModal'],
            disableClose: true,
        });
        dialog.componentInstance.appServiceId = app.serviceId;
        dialog.componentInstance.appAssetId = app.assetMetaUId;
        dialog.afterClosed().subscribe(() => {
            this.bridgeDialogOpen.emit(false);
        });
    };

    public trackById = (index, item) => {
        return item?.id || item?.serviceId || index;
    };

    ngOnInit(): void {
        this.serviceId &&
            this.store$
                .select(getApp$(this.serviceId))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((event) => {
                    this.app = CommonUtilsService.cloneObject(event);
                });
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
                this.currentOrganizationId = event;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
