<ng-container *transloco="let translate;">
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom noBorder">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('Password Expiry Info')}}</h4>
        <span class="material-icons mb-1" mat-dialog-close (click)="closeModal()">close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody" align="center">
        <div class="passwordContentDiv my-2">
            <ng-container *ngIf="data.passwordExpiryDate === 0 else expiredays">
                <span>{{translate('Your current password will expire TODAY. We recommend you to change it.')}}</span>
            </ng-container>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="matDialogFooter noBorder">
        <material-button class='mx-3 noOutline' [label]="translate('I’ll do it later')" color="default" (onTrigger)="closeModal()"></material-button>
        <material-button class='noOutline submitColor' [label]="translate('Change it')" color="primary" (onTrigger)="openChangePasswordModal()"></material-button>
    </mat-dialog-actions>

    <ng-template #expiredays>
        <span>{{translate('Your current password will expires in')}} {{data.passwordExpiryDate}} {{translate('days. We recommend you to change it.')}}</span>
    </ng-template>
</ng-container>
