import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { WorkFlow } from '../../models/workflow.class';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-card',
    templateUrl: './home-app-process.component.html',
    styleUrls: ['./home-app-process.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class HomeAppProcessComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() app: AssetService;
    @Input() workflowStages: WorkFlow[];
    @Input() assetData: AssetData;
    @Input() request: Request | false;
    @Input() filingAttributes;
    @Input() hideActionButtons = true;
    @Input() expandAcrdn: boolean;
    @Input() disabledAccordion: boolean;
    @Input() fromAppHome = false;
    @Input() bridge: AssetService;
    @Input() loading: { [property: string]: boolean };
    @Input() report?: BridgeNode;
    @Input() instance: any | false;
    @Input() processInSameTab: boolean;

    @ViewChild('horizontalStepper') matStepper: MatStepper;

    createdOn: any;

    constructor(private _router: Router, public _utils: UtilsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.workflowStages && changes.workflowStages.currentValue && changes.workflowStages.currentValue.length > 0) {
            this.checkCurrentWorkflowIndex();
        }
    }

    getLastProcessed = (instance, workflowStages: WorkFlow[]): string => {
        const index = workflowStages && workflowStages.findIndex((workflow) => workflow.endTime === undefined);
        if (index === undefined || index === -1 || workflowStages.length === 0) {
            return instance && (instance.createdOn || instance.createdDate);
        } else {
            return (
                workflowStages[index > 0 ? index - 1 : 0] &&
                (workflowStages[index > 0 ? index - 1 : 0].endTime || workflowStages[index > 0 ? index - 1 : 0].startTime)
            );
        }
    };

    checkCurrentWorkflowIndex = () => {
        setTimeout(() => {
            let currentIndex = 0;
            if (this.workflowStages && this.workflowStages.length) {
                for (let i = 0; i < this.workflowStages.length; i++) {
                    if (this.workflowStages[i].endTime) {
                        currentIndex++;
                    }
                }
                this.matStepper && (this.matStepper.selectedIndex = currentIndex > 0 ? currentIndex - 1 : 0);
            }
        }, 100);
    };

    continueProcess = () => {
        // console.log(this.app);
    };

    navigateToAppHome = () => {
        this._router.navigate(['apps', this.app.restApiName, 'home']);
    };

    initiateProcess = () => {};

    getCreatedDate = (data) => {
        if (!this.createdOn) {
            this.createdOn = this._utils.transformDateToLocale(data, 'YYYY-MM-DDTHH:MM:SSZ', 'DD-MM-YYYY HH:MM:SS AM', false);
        }
        return this.createdOn;
    };

    ngOnInit() {
        this.checkCurrentWorkflowIndex();
    }

    ngAfterViewInit(): void {
        this.checkCurrentWorkflowIndex();
    }
}
