import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { QueryBuilderConfig } from 'ng-query-builder';

import { ApiService } from '../api/api.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class ElasticsearchService {
    validRules = []; // this is just to keep a check of valid rules for validation
    searchConfig: QueryBuilderConfig;

    constructor(
        private _taxilla: ApiService,
        private _libUtils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private R: RootScopeService
    ) {}

    /**
     * Method to get search results
     * param searchCriteria is JSON stringified criteria submittable to server. It is this object -> { searchQueryString: {}, sortQueryString: {} }
     * param searchQuery is a query object given by elastic search plugin for SEARCH
     * param sortQuery is a query object given by elastic search plugin for SORT
     * param successCb is a callback to be called after search is successfully performed
     */

    searchMasterEntities = (searchData: { searchCriteria?: any; searchQuery?: any; sortQuery?: any; searchConfig?: any }) => {
        this.validRules = [];
        let searchQueryString = {};
        let sortQueryString = {};
        let searchQueryStringRef;
        let sortQueryStringRef;
        this.searchConfig = searchData.searchConfig;
        this.R.masterSearchValidRules = true;
        let searchCriteriaObj;
        if (searchData.searchCriteria) {
            searchCriteriaObj = JSON.parse(searchData.searchCriteria);
            searchQueryStringRef = searchCriteriaObj.searchQueryString;
            if (!searchQueryStringRef && searchCriteriaObj.query) {
                // This is an old query string
                searchQueryStringRef = {
                    bool: {
                        must: searchCriteriaObj['query'],
                    },
                };
            }
            sortQueryStringRef = searchCriteriaObj.sortQueryString;
        }
        if (searchQueryStringRef) {
            const query = this.buildQueryObj(searchQueryStringRef);
            searchQueryString = this.buildSearchQueryString({ query, searchConfig: searchData.searchConfig });
            this.removeInvalidFieldsFromQueryString(searchQueryStringRef);
        } else if (searchData.searchQuery) {
            const searchQuery2 = CommonUtilsService.cloneObject(searchData.searchQuery);
            const query = { query: searchQuery2, isFirstCall: true, searchConfig: searchData.searchConfig };
            searchQueryString = this.buildSearchQueryString(query);
        }
        if (sortQueryStringRef) {
            sortQueryString = sortQueryStringRef;
        } else if (searchData.sortQuery) {
            const sortQuery2 = CommonUtilsService.cloneObject(searchData.sortQuery);
            sortQueryString = this.buildSortQueryString(sortQuery2);
        }
        if (!this.validRules.length || this.hasEmptyValues(searchData.searchQuery)) {
            this._libUtils.alertError(translate('Please create valid rule'));
            this.R.masterSearchValidRules = false;
            return;
        }
        this.appendMandatoryFields(searchQueryString);
        return {
            queryString: JSON.stringify(searchQueryString),
            sortQueryString: sortQueryString,
        };
    };

    hasEmptyValues = (searchData: { rules: any[] }) => {
        searchData?.rules.some((rule) => {
            if (!Array.isArray(rule.value) && typeof rule.value === 'string') {
                return rule.value.trim() === '';
            }
        });
    };

    /**
     * Method to add necessary properties to the queryString  before making a search call
     * Param queryString : This is the {bool: { must: []}} query
     */
    appendMandatoryFields = (queryString) => {
        const currentOrgId = this._commonUtils.getFromStorage('currentOrganizationId');
        if (!queryString || !queryString.bool) {
            return;
        }
        let conditionArr = [];

        if (queryString.bool.must) {
            conditionArr = queryString.bool.must;
        } else if (queryString.bool.should) {
            conditionArr = queryString.bool.should;
        }
        const masterIdTerm = {
            term: {
                masterId: this.R.selectedMaster.itemId,
            },
        };

        const unitIdTerm = {
            term: {
                unitId: currentOrgId,
            },
        };

        conditionArr.push(masterIdTerm);
        conditionArr.push(unitIdTerm);
    };

    /**
     * Method to get the count of records for the search query string
     * Param countData: This is the same payload for search call
     * {
     *      queryString: JSON.stringify(searchQueryString),
            size: 10,
            from: 0,
            masterId: this.R.selectedMaster.key,
            unitId: this.R.rootOrganizationId.value,
            sort: sortQueryString || {}
     * }
     */

    getTotalMdEntityRecordsCount = (countData, successCbOfCount?) => {
        delete countData.payload.size;
        delete countData.payload.from;

        this._taxilla.masters.getSearchMasterRecordsCount(countData, {
            successCallback: (res) => {
                // console.log("Count success: ", res);
                successCbOfCount && successCbOfCount(res);
            },
            failureCallback: () => {
                // console.log("Count failure: ", res);
            },
        });
    };

    /**
     * Method to clean up invalid fields from getting added to the search query.
     * Param query:
     * {
     *      condition: "and",
     *       rules: [ {
     *              field: "street",
     *              operator: "contains",
     *              value: "Kondapur"
     *          },
     *          {
     *             ...
     *          }
     *          ...
     *        ]
     * }
     */
    removeInvalidFieldsFromQueryObj = (query, searchConfig) => {
        if (!query) {
            return;
        }
        this.validRules = [];
        let finalQuery = CommonUtilsService.cloneObject(query);
        const data = { query: finalQuery, searchConfig };
        finalQuery = this.buildQueryObj(this.buildSearchQueryString(data));
        return finalQuery;
    };

    transformQueryObjToQueryString = () => {
        // const query = this.copySearchQuery;
        // this.queryString = this.buildSearchQueryString(query);
        // console.log("queryString: ", this.queryString);
    };

    /**
     * Method to build the elastic search language query from plugin object format
     * Param query:
     * {
     *      condition: "and",
     *       rules: [ {
     *              field: "street",
     *              operator: "contains",
     *              value: "Kondapur"
     *          },
     *          {
     *             ...
     *          }
     *          ...
     *        ]
     * }
     */
    buildSearchQueryString = (data: { query: any; isFirstCall?: boolean; searchConfig: QueryBuilderConfig }) => {
        const { query, searchConfig, isFirstCall } = data;
        if (searchConfig) {
            this.searchConfig = searchConfig;
        }
        const queryStr = {
            bool: {},
        };
        let updatedQueryStr = queryStr;
        /**
        * Introducing isFirstCall argument because of Issue #TAF-527.
        * The below query does not fetch correct results and throws exception on change of page size
        * "{"bool":{"should":[{"wildcard":{"column1":"*adaequaresz*"}},{"bool":{"should":[{"match":{"column3":"ddd"}}]}},{"term":{"masterId":"master_dcywxvtquwpuwmq"}},{"term":{"unitId":"111"}}]}}"

        * Based on my observation from comparing with old_ui, every query should be inside an 'AND' condition.
        * So, the same query from above, when ANDified becomes
        * "{"bool":{"must":[{"bool":{"should":[{"bool":{"should":[{"wildcard":{"column1":"*adaeq*"}},{"bool":{"should":[{"match":{"column3":"d"}}]}}]}}]}},{"term":{"masterId":"master_dcywxvtquwpuwmq"}},{"term":{"unitId":"111"}}]}}"
        */

        if (isFirstCall && query.condition === 'or') {
            queryStr.bool['must'] = [
                {
                    bool: {},
                },
            ];
            updatedQueryStr = queryStr.bool['must'][0];
        }
        let type = 'must';
        if (query.condition === 'or') {
            type = 'should';
        }
        updatedQueryStr.bool[type] = [];
        query.rules.forEach((rule) => {
            if (
                (typeof rule.value === 'string' &&
                    rule.value.trim().length === 0 &&
                    rule.operator !== 'is null' &&
                    rule.operator !== 'is not null') ||
                (rule.operator === 'IN' && rule.value.length === 0)
            ) {
                return;
            }
            const ruleObj = this.processRuleForBuildingSearchQueryString(
                rule,
                data?.searchConfig?.fields?.[rule.field],
                data?.searchConfig
            );
            if (ruleObj) {
                if (ruleObj?.bool?.hasOwnProperty('should') && type === 'should') {
                    updatedQueryStr.bool[type] = updatedQueryStr.bool[type].concat(ruleObj.bool.should);
                } else {
                    updatedQueryStr.bool[type].push(ruleObj);
                }
            }
        });

        return queryStr;
    };

    /**
     * Method to process rule from ng-query-builder into elastic search format
     * Param rule:
     *
     * {
     *    field: "street",
     *    operator: "contains",
     *    value: "Kondapur"
     *  },
     */
    processRuleForBuildingSearchQueryString = (rule, fieldConfig, searchConfig) => {
        if (!rule) return;
        let ruleObj: any = {};
        const { field, operator, condition } = rule;
        const fieldType = this.getDataTypeOfField(field);
        let { value } = rule;
        if (condition) {
            ruleObj = this.buildSearchQueryString({ query: rule, searchConfig });
        } else if (value || ['is null', 'is not null'].indexOf(operator) !== -1) {
            if (fieldType === 'date') {
                value = this.buildValidDate(value, fieldConfig?.format);
            }
            switch (operator) {
                case 'CONTAINS':
                case 'contains':
                    ruleObj.wildcard = {};
                    if (value.indexOf('*') === 0) {
                        ruleObj.wildcard[field] = value;
                    } else {
                        if (typeof value === 'string') {
                            value = value.trim();
                        }
                        ruleObj.wildcard[field] = '*' + value + '*';
                    }
                    this.validRules.push(ruleObj);
                    break;
                case 'EQ':
                case '=':
                    if (
                        this.checkIfexpectedDataType('number', field) ||
                        this.checkIfexpectedDataType('boolean', field) ||
                        this.checkIfexpectedDataType('date', field)
                    ) {
                        ruleObj.term = {};
                        ruleObj.term[field] = value;
                    } else {
                        ruleObj.match = {};
                        ruleObj.match[field] = value;
                    }
                    this.validRules.push(ruleObj);
                    break;
                case 'NOT_EQUALS':
                case '!=':
                    if (
                        this.checkIfexpectedDataType('number', field) ||
                        this.checkIfexpectedDataType('boolean', field) ||
                        this.checkIfexpectedDataType('date', field)
                    ) {
                        ruleObj.bool = {
                            must_not: {
                                term: {},
                            },
                        };
                        ruleObj.bool.must_not.term[field] = value;
                    } else {
                        ruleObj.bool = {
                            must_not: {
                                match: {},
                            },
                        };
                        ruleObj.bool.must_not.match[field] = value;
                    }
                    this.validRules.push(ruleObj);
                    break;
                case 'is null':
                    ruleObj.bool = {
                        must_not: {
                            exists: {
                                field,
                            },
                        },
                    };
                    this.validRules.push(ruleObj);
                    break;
                case 'is not null':
                    ruleObj.exists = {
                        field,
                    };
                    this.validRules.push(ruleObj);
                    break;
                case 'LT':
                case '<':
                    ruleObj.range = {};
                    ruleObj.range[field] = {
                        lt: value,
                    };
                    this.validRules.push(ruleObj);
                    break;
                case 'GT':
                case '>':
                    ruleObj.range = {};
                    ruleObj.range[field] = {
                        gt: value,
                    };
                    this.validRules.push(ruleObj);
                    break;
                case 'LTE':
                case '<=':
                    ruleObj.range = {};
                    ruleObj.range[field] = {
                        lte: value,
                    };
                    this.validRules.push(ruleObj);
                    break;
                case 'GTE':
                case '>=':
                    ruleObj.range = {};
                    ruleObj.range[field] = {
                        gte: value,
                    };
                    this.validRules.push(ruleObj);
                    break;
                case 'IN':
                case 'in':
                    ruleObj.bool = {
                        should: [],
                    };
                    if (Array.isArray(value)) {
                        value.forEach((val) => {
                            ruleObj.bool.should.push({
                                [fieldType === 'string' ? 'match' : 'term']: {
                                    [field]: val,
                                },
                            });
                        });
                    } else {
                        ruleObj.bool.should.push({
                            match: {
                                [field]: value,
                            },
                        });
                    }
                    // ruleObj.terms = {};
                    // ruleObj.terms[field] = value;
                    this.validRules.push(ruleObj);
                    break;
                case 'NOT IN':
                case 'not in':
                    ruleObj.bool = {
                        must_not: {
                            terms: {},
                        },
                    };
                    ruleObj.bool.must_not.terms[field] = value;
                    this.validRules.push(ruleObj);
                    break;
                default:
                    ruleObj.term = {};
                    ruleObj.term[field] = value;
                    this.validRules.push(ruleObj);
                    break;
            }
        }
        return ruleObj;
    };

    // TAX-4106: Method to check if expected and current recieved data types match
    checkIfexpectedDataType = (expexted: string, current: string | number): boolean => {
        if (this.searchConfig && this.searchConfig.fields && Object.keys(this.searchConfig.fields).length > 0) {
            if (this.searchConfig.fields[current].type === expexted) {
                return true;
            }
        }
        return false;
    };

    /**
     * Method to clean up invalid fields from getting added to the search query.
     * Param query:
     * {"bool":{"must":[{"match":{"column1":"Banglore"}},{"term":{"masterId":"master_dcywxvtquwpuwmq"}},{"term":{"unitId":"111"}}]}}
     * }
     */
    removeInvalidFieldsFromQueryString = (queryStr) => {
        if (!queryStr) {
            return;
        }
        this.validRules = [];
        let finalQuery = CommonUtilsService.cloneObject(queryStr);
        finalQuery = this.buildQueryObj(finalQuery);
        return finalQuery;
    };

    transformQueryStringToQueryObj = () => {
        // const queryString = this.queryString;
        // this.query2 = this.buildQueryObj(queryString);
        // console.log("New query2: ", this.query2);
    };

    /**
     * Method to build the ng-query-builder object from elastic search query
     * @param queryString stringified query
     * @param searchConfig contains fields map of the searchable fields
     */
    public buildQueryObj = (queryString, searchConfig?) => {
        if (searchConfig) {
            this.searchConfig = searchConfig;
        }
        let q = {
            condition: 'and',
            rules: [],
        };
        const qs = queryString;
        let ruleArr = [];
        if (qs.bool) {
            if (qs.bool.should) {
                q.condition = 'or';
                ruleArr = qs.bool.should;
            } else {
                ruleArr = qs.bool.must;
            }
            ruleArr.forEach((rule) => {
                const ruleObj = this.processRuleForBuildingQueryObj(rule);
                const rulePresent = q.rules.find((item) => item.field === ruleObj.field && item.operator === ruleObj.operator);
                if (rulePresent) {
                    rulePresent.value = Array.isArray(rulePresent.value) ? rulePresent.value : [rulePresent.value];
                    const valueToPush = Array.isArray(ruleObj.value) ? ruleObj.value[0] : ruleObj.value;
                    rulePresent.value.push(valueToPush);
                } else {
                    q.rules.push(ruleObj);
                }
            });
            q.rules.forEach((rule) => {
                rule.operator === 'EQ' && Array.isArray(rule.value) ? (rule.operator = 'IN') : undefined;
            });
        } else if (qs.must) {
            const qsBool = {
                bool: qs,
            };
            q = this.buildQueryObj(qsBool);
        } else if (qs.match) {
            const ruleObj = this.processRuleForBuildingQueryObj(qs);
            const rulePresent = q.rules.find((item) => item.field === ruleObj.field && item.operator === ruleObj.operator);
            if (rulePresent) {
                rulePresent.value = Array.isArray(rulePresent.value) ? rulePresent.value : [rulePresent.value];
                const valueToPush = Array.isArray(ruleObj.value) ? ruleObj.value[0] : ruleObj.value;
                rulePresent.value.push(valueToPush);
            } else {
                q.rules.push(ruleObj);
            }
        }
        return q;
    };
    /**
     * Method to build rule object for ng-query-builder plugin from elastic search query
     * @param r Rule object on which the operation needs to be performed
     * {"match":{"column1":"Banglore"}}
     */
    private processRuleForBuildingQueryObj = (r) => {
        if (!r) {
            return;
        }

        let rule: any = {};
        let field = '';
        let compCrit = {};
        let compCritKey = '';
        let boolKey = '';
        let boolFieldValueObj = {};
        let boolField = '';
        const operatorCriteria = Object.keys(r)[0];
        switch (operatorCriteria) {
            case 'range':
                field = Object.keys(r[operatorCriteria])[0];
                rule.field = field;
                compCrit = r[operatorCriteria][field];
                compCritKey = Object.keys(compCrit)[0];
                rule.value = compCrit[compCritKey];
                switch (compCritKey) {
                    case 'lt':
                        rule.operator = '<';
                        break;
                    case 'lte':
                        rule.operator = '<=';
                        break;
                    case 'gt':
                        rule.operator = '>';
                        break;
                    case 'gte':
                        rule.operator = '>=';
                        break;
                }
                this.validRules.push(rule);
                break;
            case 'match':
                field = Object.keys(r[operatorCriteria])[0];
                rule.field = field;
                const fieldConfig = this.searchConfig.fields[field].type;
                rule.operator = fieldConfig === 'string' ? 'EQ' : 'IN';
                rule.value = rule.operator === 'EQ' ? r[operatorCriteria][field] : [r[operatorCriteria][field]];
                if (this.getDataTypeOfField(field) === 'date') {
                    rule.value = [this.buildValidDate(rule.value, r?.searchConfig?.fields?.[rule.field]?.format)];
                }
                this.validRules.push(rule);
                break;
            case 'term':
                field = Object.keys(r[operatorCriteria])[0];
                rule.field = field;
                rule.operator = 'EQ';
                rule.value = r[operatorCriteria][field];
                if (this.getDataTypeOfField(field) === 'date') {
                    rule.value = this.buildValidDate(rule.value, r?.searchConfig?.fields?.[rule.field]?.format);
                }
                this.validRules.push(rule);
                break;
            case 'wildcard':
                field = Object.keys(r[operatorCriteria])[0];
                rule.field = field;
                rule.operator = 'contains';
                if (typeof r[operatorCriteria][field] === 'string') {
                    r[operatorCriteria][field] = r[operatorCriteria][field].trim();
                }
                rule.value = r[operatorCriteria][field];
                this.validRules.push(rule);
                break;
            case 'exists':
                field = Object.keys(r[operatorCriteria])[0];
                rule.field = r[operatorCriteria][field];
                rule.operator = 'is not null';
                this.validRules.push(rule);
                break;
            case 'bool':
                boolKey = Object.keys(r[operatorCriteria])[0];
                switch (boolKey) {
                    case 'must_not':
                        compCrit = r[operatorCriteria][boolKey];
                        compCritKey = Object.keys(compCrit)[0];
                        boolFieldValueObj = compCrit[compCritKey];
                        boolField = Object.keys(boolFieldValueObj)[0];
                        rule.field = boolField;
                        switch (compCritKey) {
                            case 'match':
                                rule.operator = 'NOT_EQUALS';
                                rule.value = boolFieldValueObj[boolField];
                                this.validRules.push(rule);
                                break;
                            case 'exists':
                                rule.operator = 'is null';
                                rule.field = boolFieldValueObj[boolField];
                                this.validRules.push(rule);
                                break;
                            case 'terms':
                                rule.operator = 'not in';
                                rule.value = boolFieldValueObj[boolField];
                                this.validRules.push(rule);
                                break;
                            case 'term':
                                rule.operator = 'NOT_EQUALS';
                                rule.value = boolFieldValueObj[boolField];
                                this.validRules.push(rule);
                                break;
                        }
                        break;
                    default:
                        rule.field = field;
                        rule = this.buildQueryObj(r);
                        break;
                }
                break;
            case 'terms':
                field = Object.keys(r[operatorCriteria])[0];
                rule.field = field;
                if (Array.isArray(r[operatorCriteria][field])) {
                    rule.operator = 'in';
                    rule.value = r[operatorCriteria][field];
                }
                this.validRules.push(rule);
                break;
            default:
                rule = this.buildQueryObj(r);
                break;
        }

        return rule;
    };

    /**
     * Method to find the data type of fields based on the config object
     */
    getDataTypeOfField = (fieldName) => {
        let type = 'string';
        if (this.searchConfig.fields?.[fieldName]?.type !== undefined) {
            type = this.searchConfig.fields[fieldName].type;
        } else if (fieldName === 'validationStatus') {
            type = 'category';
        }
        return type;
    };

    /**
     * Method to process the sort Object from ng-query-builder to api format
     * Param sortQS:
     * [
     * {
     *    field: "column1",
     *    operator: "=",
     *    value: "ASC"
     * },
     * {
     *    field: "column2",
     *    operator: "=",
     *    value: "DESC"
     * },
     * ]
     *
     * Output
     * {column1, "ASC", column2: "DESC"}
     *
     */
    buildSortQueryString = (sortQS) => {
        const queryStr = {};
        if (sortQS) {
            sortQS.rules.forEach((rule) => {
                if (rule.value) {
                    queryStr[rule.field] = rule.value;
                }
            });
        }
        return queryStr;
    };

    /**
     * Method to build Sort Object from stored criteria into ng-query-builder format for re-rendering into the dialog
     * Param sortObj
     * {column1, "ASC", column2: "DESC"}
     *
     * Output
     * {"bool":{"must":[{match":{"column1":"ASC"}}, {match":{"column2":"DESC"}}]}}
     */

    buildSortQueryObject = (sortQObj: any) => {
        const q = {
            condition: 'and',
            rules: [],
        };

        for (const sObj in sortQObj) {
            const fieldObj = {
                field: sObj,
                operator: '=',
                value: sortQObj[sObj],
            };
            q.rules.push(fieldObj);
        }

        return q;
    };

    private buildValidDate(value: any, format: any) {
        if (value instanceof Date) {
            const dateObject = value;
            // get date
            const date = dateObject.getDate();
            // get month
            const month = dateObject.getMonth() + 1;
            // get year
            const year = dateObject.getFullYear();
            value = `${date}/${month}/${year}/;`;
        }
        const formatedDate = this._commonUtils.transformDateToLocale(value, format, 'dd-mm-yyyy', true);
        value = formatedDate;
        return value;
    }
}
