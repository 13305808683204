import { UtilsService } from 'taxilla-library';

import { ReconciliationSource } from './reconciliation-source.class';
import { Message } from './record/message.class';

export class ReconciliationConfiguration {
    repository: string;

    // id: string;

    version: number;

    name: string;

    sources: ReconciliationSource[];

    requestParameters: {
        id: string;

        name: string;

        type: string;

        defaultValue: number;

        mandatory: boolean;

        uiType?: string;

        message?: Message;
    }[];

    groups: {
        id: string;
        name: string;
        isSynchronous: boolean;
        description: string;
        detached: boolean;
        counts: {
            displayName: string;
            count: number;
        }[];
    }[];

    defaultGroup: string;

    mismatchGroup: string;

    steps: {
        exclusive: boolean;
        group: string;
        id: string;
        index: number;
        keyMatcher: any;
        keySelectorFunctions: any;
        mandatory: boolean;
        name: string;
        valueMatcher: any;
        selected: boolean;
        description: string;
    }[];

    commonFunctions: any[];

    restapiname: string;

    constructor(data: ReconciliationConfiguration, private _utils: UtilsService, private serviceId: string) {
        this.repository = data?.repository;
        // this.id = data?.id;
        this.version = data?.version;
        this.name = data?.name;
        this.requestParameters = [];
        this.groups = data?.groups.slice(0);
        this.groups?.forEach((group) => {
            group.isSynchronous = !group.detached && data?.mismatchGroup !== group.id;
        });
        this.defaultGroup = data?.defaultGroup;
        this.mismatchGroup = data?.mismatchGroup;
        this.steps = data?.steps || [];
        this.commonFunctions = data?.commonFunctions;
        this.restapiname = data?.restapiname;
        this.sources = [];
        this.steps.forEach((step) => (step.selected = true));
        if (data instanceof ReconciliationConfiguration) {
            this._utils = data._utils;
            this.serviceId = data.serviceId;
        }
        data?.sources.forEach((source) => this.sources.push(new ReconciliationSource(source, this._utils, serviceId)));
        data?.requestParameters?.forEach?.((field) => this.createFilingAttributeField(field as any));
    }

    createFilingAttributeField = (field: { id: string; name: string; type: string; defaultValue: any; mandatory: boolean }) => {
        let uiType: string;
        switch (field.type) {
            case 'BOOLEAN':
            case 'DATE':
                uiType = field.type;
                break;
            default:
                uiType = 'TEXTFIELD';
                break;
        }
        this.requestParameters.push({ ...field, uiType, message: new Message({}) });
    };

    getSourceNames = () => {
        return this.sources.reduce((dataToReturn, currentValue) => {
            dataToReturn.push(currentValue.name);
            return dataToReturn;
        }, []);
    };
}
