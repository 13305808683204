import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GetAppMetaData, getAppMetaData$ } from 'taxilla-library';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { Entity } from '../../models/entity.class';
import { Field } from '../../models/field.class';
import { FilingAttributeField } from '../../models/filingattributefield.class';
import { FilingAttributeFieldPayload } from '../../models/filingattributefield.payload';
import { Masters } from '../../models/masters/masters.class';
import { Organization, OrganizationData } from '../../models/organization.class';
import { Record } from '../../models/record/record.class';
import { Transformation } from '../../models/transformation';
import { ApiService } from '../api/api.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class NewProcessService {
    private allTenants: any[];
    private tempAllTenants: any;
    private allTenantsTree: any[];
    public allTenantsMap: {};
    private subTenants: Organization[];
    private parentOrgId: any;
    private tenantsToRender: any;
    private selectedTenant: any;
    selectedTenantId: any;
    reconProcesssTemplates: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private appMetaSubscription: Subscription;

    constructor(
        private _api: ApiService,
        private _commonUtils: CommonUtilsService,
        private R: RootScopeService,
        private _utils: UtilsService,
        private store$: Store
    ) {}

    public getAssetTransformations = ({ assetId, restApiName }: { assetId: string; restApiName: string }) => {
        return new Promise<{
            transformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[];
            assetToAssetTransformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[];
        }>((resolve) => {
            let transformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            let assetToAssetTransformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            if (assetId?.length) {
                const cachedTransformations = this.getCachedTransformations([assetId]);
                if (!cachedTransformations) {
                    this._api.assets
                        .getAllMapTransformations({
                            assetMetaUId: assetId,
                        })
                        .then(async (res) => {
                            transformations.splice(0);
                            assetToAssetTransformations.splice(0);
                            const relativeOrganizations = res.organizations;
                            const organizations = res.transformations;
                            const nameVsDisplayNames = await this.getChainDisplayNames({
                                restApiName,
                                assetId,
                            });
                            Object.keys(organizations || {}).forEach((key) => {
                                const transformationObject = {
                                    orgName: this.getOrganizations(key, relativeOrganizations),
                                    repositoryId: key,
                                    transformations: [],
                                    assetToAssetTransformations: [],
                                };
                                Object.keys(organizations[key]).forEach((transformationKey) => {
                                    const transformation = new Transformation(
                                        organizations[key][transformationKey],
                                        nameVsDisplayNames[transformationKey]
                                    );
                                    transformation.chainName = transformationKey;
                                    if (
                                        transformation.assetToAssetMetadata &&
                                        Object.keys(transformation.assetToAssetMetadata).length > 0
                                    ) {
                                        transformationObject.assetToAssetTransformations.push(transformation);
                                    } else {
                                        transformationObject.transformations.push(transformation);
                                    }
                                });
                                if (transformationObject.transformations.length > 0) {
                                    transformations.push({
                                        orgName: transformationObject.orgName,
                                        transformations: transformationObject.transformations,
                                        repositoryId: transformationObject.repositoryId,
                                    });
                                }
                                if (transformationObject.assetToAssetTransformations.length > 0) {
                                    assetToAssetTransformations.push({
                                        orgName: transformationObject.orgName,
                                        transformations: transformationObject.assetToAssetTransformations,
                                        repositoryId: transformationObject.repositoryId,
                                    });
                                }
                            });
                            this.storeTransformations(undefined, [assetId], transformations, assetToAssetTransformations);
                            resolve({
                                transformations,
                                assetToAssetTransformations,
                            });
                        })
                        .catch((res) => {
                            resolve({
                                transformations,
                                assetToAssetTransformations,
                            });
                        });
                } else {
                    setTimeout(() => {
                        transformations = cachedTransformations.transformations as any;
                        assetToAssetTransformations = cachedTransformations.assetToAssetTransformations;
                        resolve({
                            transformations,
                            assetToAssetTransformations,
                        });
                    });
                }
            }
        });
    };

    private storeTransformations = (prefix: string, props: string[], transformations: any, assetToAssetTransformations: any) => {
        let transformationsList = this._commonUtils.getFromStorage(`${prefix + '_' || ''}transformations`) || {};
        const currentOrganizationId = this._commonUtils.getFromStorage('currentOrganizationId');
        let mainObject = transformationsList;
        props = [currentOrganizationId, ...props];
        props.forEach((prop) => {
            if (!mainObject[prop]) {
                mainObject[prop] = {};
            }
            mainObject = mainObject[prop];
        });
        mainObject.transformations = transformations;
        mainObject.assetToAssetTransformations = assetToAssetTransformations;
        this._commonUtils.setInStorage(`${prefix + '_' || ''}transformations`, transformationsList);
    };

    public getTransformations = (assetId: string, restApiName: string) => {
        return new Promise<{
            transformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[];
        }>((resolve) => {
            let transformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            const cachedTransformations = this.getCachedTransformations([assetId], 'all');
            if (!cachedTransformations) {
                this._api.assets
                    .getAllMapTransformations({
                        assetMetaUId: assetId,
                    })
                    .then(async (res) => {
                        transformations.splice(0);
                        const relativeOrganizations = res.organizations;
                        const organizations = res.transformations;
                        const nameVsDisplayNames = await this.getChainDisplayNames({
                            restApiName,
                            assetId,
                        });
                        Object.keys(organizations || {}).forEach((key) => {
                            const transformationObject = {
                                orgName: this.getOrganizations(key, relativeOrganizations),
                                repositoryId: key,
                                transformations: [],
                            };
                            Object.keys(organizations[key]).forEach((transformationKey) => {
                                const transformation = new Transformation(
                                    organizations[key][transformationKey],
                                    nameVsDisplayNames[transformationKey]
                                );
                                transformation.chainName = transformationKey;
                                transformationObject.transformations.push(transformation);
                            });
                            if (transformationObject.transformations.length > 0) {
                                transformations.push({
                                    orgName: transformationObject.orgName,
                                    transformations: transformationObject.transformations,
                                    repositoryId: transformationObject.repositoryId,
                                });
                            }
                        });
                        this.storeTransformations('all', [assetId], transformations, undefined);
                        resolve({
                            transformations,
                        });
                    })
                    .catch((res) => {
                        resolve({
                            transformations,
                        });
                    });
            } else {
                setTimeout(() => {
                    resolve({
                        transformations: cachedTransformations.transformations as any,
                    });
                });
            }
        });
    };

    public getReconciliationTransformations = ({
        assetId,
        restApiName,
        sourceId,
    }: {
        assetId: string;
        restApiName: string;
        sourceId: string;
    }) => {
        return new Promise<{
            transformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[];
            assetToAssetTransformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[];
        }>((resolve) => {
            let transformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            let assetToAssetTransformations: {
                orgName: string;
                transformations: Transformation[];
                repositoryId: string;
            }[] = [];
            const cachedTransformations = this.getCachedTransformations([assetId, sourceId], 'reconciliation');
            if (!cachedTransformations) {
                this._api.assets.getReconciliationTransformations(
                    {
                        assetId: assetId,
                        sourceId,
                    },
                    {
                        successCallback: async (res) => {
                            transformations.splice(0);
                            assetToAssetTransformations.splice(0);
                            const relativeOrganizations = res.organizations;
                            const organizations = res.transformations;
                            Object.keys(organizations || {}).forEach((key) => {
                                const transformationObject = {
                                    orgName: this.getOrganizations(key, relativeOrganizations),
                                    repositoryId: key,
                                    transformations: [],
                                    assetToAssetTransformations: [],
                                };
                                organizations[key].forEach((subTransformation) => {
                                    const transformation = new Transformation(
                                        subTransformation,
                                        subTransformation.chainName,
                                        subTransformation
                                    );
                                    transformation.chainName = subTransformation.chainName;
                                    if (transformation.isSearchAsset) {
                                        transformationObject.assetToAssetTransformations.push(transformation);
                                    } else {
                                        transformationObject.transformations.push(transformation);
                                    }
                                });
                                if (transformationObject.transformations.length > 0) {
                                    transformations.push({
                                        orgName: transformationObject.orgName,
                                        transformations: transformationObject.transformations,
                                        repositoryId: transformationObject.repositoryId,
                                    });
                                }
                                if (transformationObject.assetToAssetTransformations.length > 0) {
                                    assetToAssetTransformations.push({
                                        orgName: transformationObject.orgName,
                                        transformations: transformationObject.assetToAssetTransformations,
                                        repositoryId: transformationObject.repositoryId,
                                    });
                                }
                            });
                            this.storeTransformations('reconciliation', [assetId, sourceId], transformations, assetToAssetTransformations);
                            resolve({
                                transformations,
                                assetToAssetTransformations,
                            });
                        },
                        failureCallback: (res) => {
                            resolve({
                                transformations,
                                assetToAssetTransformations,
                            });
                        },
                    }
                );
            } else {
                setTimeout(() => {
                    resolve({
                        transformations: cachedTransformations.transformations as any,
                        assetToAssetTransformations: cachedTransformations.assetToAssetTransformations as any,
                    });
                });
            }
        });
    };

    public changeTransformationsHierarchy = (
        transformations: {
            orgName: string;
            transformations: Transformation[];
            repositoryId: string;
        }[]
    ) => {
        let allTransfromations = CommonUtilsService.cloneObject(transformations);
        const providerIds = this._utils.getProviderIds(undefined, []);
        transformations.splice(0);
        providerIds.forEach((orgId) => {
            allTransfromations.find((object, index) => {
                if (object.repositoryId === orgId) {
                    transformations.push(allTransfromations[index]);
                }
            });
        });
    };

    private getCachedTransformations = (props: string[], prefix?: string) => {
        const transformations = this._commonUtils.getFromStorage(`${prefix + '_' || ''}transformations`);
        const currentOrganizationId = this._commonUtils.getFromStorage('currentOrganizationId');
        let propsObject = transformations;
        props = [currentOrganizationId, ...props];
        props.forEach((prop) => {
            propsObject = propsObject?.[prop];
        });
        return propsObject;
    };

    private getChainDisplayNames = ({ restApiName, assetId }: { restApiName: string; assetId: string }) => {
        return new Promise((resolve) => {
            const storedChainDisplaynames = this.getCachedChainNames(restApiName);
            if (!storedChainDisplaynames) {
                this._api.assets
                    .getChainDisplayNames({
                        restApiName: restApiName,
                        assetId: assetId,
                    })
                    .then((res) => {
                        let chainVsDisplaynames = this._commonUtils.getFromStorage('chainVsDisplaynames');
                        if (chainVsDisplaynames) {
                            chainVsDisplaynames[restApiName] = res?.chainNameVsChainDisplayName;
                        } else {
                            chainVsDisplaynames = {};
                            chainVsDisplaynames[restApiName] = res?.chainNameVsChainDisplayName;
                        }
                        this._commonUtils.setInStorage('chainVsDisplaynames', chainVsDisplaynames);
                        resolve(res?.chainNameVsChainDisplayName);
                    });
            } else {
                resolve(storedChainDisplaynames);
            }
        });
    };

    private getCachedChainNames = (restApiName) => {
        const storedDetails = this._commonUtils.getFromStorage('chainVsDisplaynames');
        if (storedDetails) {
            if (storedDetails[restApiName]) {
                return storedDetails[restApiName];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    private getOrganizations = (key, relativeOrganizations) => {
        return relativeOrganizations[key];
    };

    public getAllTenantsForFlatStructure = () => {
        return new Promise<{
            tenantsToRender: any;
            subTenants: Organization[];
            selectedTenantId: string;
        }>((resolve) => {
            const currentOrganizationId = this._commonUtils.getFromStorage('currentOrganizationId');
            const cachedOrgNodes = this.getCachedOrgNodes(currentOrganizationId);
            if (!cachedOrgNodes) {
                this._api.organization.getOrganizationHierarchy(
                    {
                        organizationId: currentOrganizationId,
                    },
                    {
                        successCallback: (res) => {
                            const organizationNodes = (res && res.orgNodes) || [];
                            this.buildOrgnizationHirarchy(organizationNodes);
                            if (this.R.organizationHirarchy) {
                                this.R.organizationHirarchy[currentOrganizationId] = (res && res.orgNodes) || [];
                            } else {
                                this.R.organizationHirarchy = {};
                                this.R.organizationHirarchy[currentOrganizationId] = (res && res.orgNodes) || [];
                            }
                            resolve({
                                tenantsToRender: this.tenantsToRender,
                                subTenants: this.subTenants,
                                selectedTenantId: this.selectedTenantId,
                            });
                        },
                        failureCallback: (res) => {},
                    }
                );
            } else {
                this.buildOrgnizationHirarchy(cachedOrgNodes);
                resolve({
                    tenantsToRender: this.tenantsToRender,
                    subTenants: this.subTenants,
                    selectedTenantId: this.selectedTenantId,
                });
            }
        });
    };

    private buildOrgnizationHirarchy = (organizationNodes) => {
        const organizations = [];
        this.pushToList(organizationNodes, organizations);
        this.allTenants = organizations.slice(0);
        this.tempAllTenants = undefined;
        this.allTenantsTree = [];
        this.tempAllTenants = CommonUtilsService.cloneObject(this.allTenants);
        this.allTenantsMap = {};
        for (let i = 0; i < 2; i++) {
            this.generateTenantNodeHierarchy();
        }
        this.generatePartnerTenantNodeHierarchy();
        this.generateTenantDisplayNames();
        this.tenantsToRender = CommonUtilsService.cloneObject(this.allTenantsTree);
        if (this.tenantsToRender && this.tenantsToRender.length > 0) {
            this.selectTenant(this.tenantsToRender[0]);
        }
    };

    private generateTenantDisplayNames = () => {
        // tslint:disable-next-line:forin
        for (const tenant in this.allTenantsMap) {
            this.allTenantsMap[tenant].displayTenantName = this.getTenantName(this.allTenantsMap[tenant]);
        }
    };

    public selectTenant = (tenant?: OrganizationData, buildSubTenant?: boolean, originalTenant?: OrganizationData) => {
        // When a tenant is selected, find it in the list of allTenantsMap and build the names to display
        this.subTenants = [];
        this.selectedTenant = tenant;
        if (this.selectedTenant.parent) {
            this.selectTenant(this.selectedTenant.parent as any, buildSubTenant, originalTenant);
            return;
        } else {
            this.selectedTenantId = this.selectedTenant.id;
        }
        this.tenantPreOrderTraversal(originalTenant || tenant);
    };

    public tenantPreOrderTraversal = (tenant?, subTenants?: Organization[]) => {
        if (!tenant) {
            return;
        }
        subTenants = subTenants || this.subTenants;
        if (!tenant.isPartnerOrg && tenant.id !== this.parentOrgId) {
            tenant.displayTenantName = tenant.displayTenantName || tenant.name;
            subTenants.push(tenant);
        }
        if (!tenant.nextNodes || tenant.nextNodes.length === 0) {
            tenant.nextNodes = this.allTenantsMap[tenant.id] && this.allTenantsMap[tenant.id].nextNodes;
        }
        if (tenant.nextNodes) {
            for (let i = 0; i < tenant.nextNodes.length; i++) {
                this.tenantPreOrderTraversal(this.allTenantsMap[tenant.nextNodes[i]], subTenants);
            }
        }
        this.checkTenantandSubTenantStructure(subTenants);
        return subTenants;
    };

    private checkTenantandSubTenantStructure = (subTenants?: Organization[]) => {
        subTenants = subTenants || this.subTenants;
        if (subTenants && subTenants.length > 0) {
            if (this.tenantsToRender.length < 1) {
                const parentObj = CommonUtilsService.cloneObject(subTenants[0].parent);
                this.allTenantsTree = this.allTenantsTree || [];
                this.allTenantsTree.push(parentObj);
                this.tenantsToRender = CommonUtilsService.cloneObject(this.allTenantsTree);
                this.selectedTenant = parentObj;
            }
        }
    };

    private getTenantName = (tenant?, delimiter?) => {
        if (!tenant) {
            return;
        }
        if (!tenant.parent) {
            return tenant.name;
        } else {
            return this.getTenantName(tenant.parent) + (delimiter ? '  ' + delimiter + '  ' : '  |  ') + tenant.name;
        }
    };

    private pushToList = (nodes: OrganizationNode[], organizationsList: Organization[]) => {
        nodes.forEach((node) => {
            organizationsList.push(node.organization);
            if (node.nextNodes) {
                this.pushToList(node.nextNodes, organizationsList);
            }
        });
    };

    private getCachedOrgNodes = (orgId) => {
        if (this.R.organizationHirarchy) {
            if (this.R.organizationHirarchy[orgId]) {
                return this.R.organizationHirarchy[orgId];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    private generateTenantNodeHierarchy = () => {
        // Using a reverse for-loop as it involves splicing the same array.
        // Reference: https://gist.github.com/chad3814/2924672
        for (let i = this.tempAllTenants.length - 1; i >= 0; i -= 1) {
            const tenant = this.tempAllTenants[i];
            let node;
            if (this.allTenantsMap && this.allTenantsMap[tenant.id]) {
                node = this.allTenantsMap[tenant.id];
            } else {
                node = this.createNode(tenant);
                this.addToMap(node);
            }
            if (!tenant.parent) {
                // If there is no parent it's gotta be a root node.
                // So add it to tenantsTree. And remove it from the tempAllTenants array.
                this.allTenantsTree = this.allTenantsTree || [];
                this.allTenantsTree.push(node);
                this.tempAllTenants.splice(i, 1);
            } else {
                // If it has a parent then see if its parent exists in the map.
                // If the parent exists in the map then add this node to the parent node's nextNodes array. And remove this node from tempAllTenants array.
                // If the parent does not exist in the map, leave the tenant in the tempAllTenants array.
                if (this.allTenantsMap[tenant.parent.id]) {
                    if (this.allTenantsMap[tenant.parent.id].nextNodes.indexOf(tenant.id) > -1) {
                        // no operation
                    } else {
                        this.allTenantsMap[tenant.parent.id].nextNodes.push(tenant.id);
                    }
                    this.tempAllTenants.splice(i, 1);
                } else {
                    // No operation
                }
            }
        }
    };

    private createNode = (org?) => {
        if (!org || typeof org !== 'object') {
            return;
        }
        org.nextNodes = [];
        return org;
    };

    private addToMap = (node?) => {
        if (!node) {
            return;
        }
        if (!this.allTenantsMap) {
            this.allTenantsMap = {};
        }
        this.allTenantsMap[node.id] = node;
    };

    private generatePartnerTenantNodeHierarchy = () => {
        for (let i = this.tempAllTenants.length - 1; i >= 0; i -= 1) {
            const tenant = this.tempAllTenants[i];
            if (tenant.parent) {
                let lastParent = tenant.parent;
                while (lastParent.parent) {
                    if (this.allTenantsMap[lastParent.id]) {
                        lastParent = lastParent.parent;
                    } else {
                        const parentNode = this.createPartnerNode(lastParent);
                        this.addToMap(parentNode);
                        lastParent = lastParent.parent;
                    }
                }
                if (!this.allTenantsMap[lastParent.id]) {
                    const node = this.createPartnerNode(lastParent);
                    this.addToMap(node);
                    this.allTenantsTree = this.allTenantsTree || [];
                    if (!this.isNodePresentInTree(node, this.allTenantsTree)) {
                        this.allTenantsTree.push(node);
                    }
                }
                this.allTenantsMap[lastParent.id] && this.allTenantsMap[lastParent.id].nextNodes.push(tenant.id);
                this.tempAllTenants.splice(i, 1);
            }
        }
    };

    private createPartnerNode = (org?) => {
        if (!org) {
            return;
        }
        const partnerNode = this.createNode(org);
        partnerNode.isPartnerOrg = true;
        return partnerNode;
    };

    /**
     * Is node present in tree already
     */
    private isNodePresentInTree = (node, tree) => {
        if (!node || !tree || !tree.length) {
            return;
        }
        let isPresent = false;
        for (let i = 0; i < tree.length; i++) {
            if (tree[i].id === node.id) {
                isPresent = true;
                break;
            }
        }
        return isPresent;
    };

    public buildFilingAttributes = (
        report: BridgeNode,
        bridge: AssetService,
        app: AssetService,
        assetMetadata: BehaviorSubject<AssetData>,
        appPermissions: any
    ): Promise<{
        businessKeysRecord: Record;
        filingAttributeFields: FilingAttributeField[];
        assetData: AssetData;
    }> => {
        return new Promise(async (resolve) => {
            const assetData = assetMetadata?.value;
            const response = await this.getFilingAtributes(report, bridge, app);
            let newAssetData: AssetData;
            if (assetData && (!report || (report && !report.name)) && response.assetId && assetData.uid !== response.assetId) {
                newAssetData = await this.buildMetaData({ assetMetaUId: response.assetId }, appPermissions);
            }
            const filingAttributeFields: FilingAttributeField[] = [];
            let businessKeysRecord: Record;
            if (response.businessKeys && response.businessKeys.length > 0) {
                businessKeysRecord = this.createRecordWithFieldIds(assetData, response.assetId, response.businessKeys, true);
            }
            if (response.filingAttributeFields?.length > 0) {
                const entity = assetData?.getPrimaryEntity();
                response.filingAttributeFields.forEach((field) => {
                    const entityField = entity?.getField(typeof field === 'string' ? field : field && (field.id || field.uid));
                    filingAttributeFields.push(new FilingAttributeField(field, entityField));
                });
            }
            resolve({
                businessKeysRecord,
                filingAttributeFields,
                assetData: newAssetData || assetData,
            });
            if (newAssetData) {
                setTimeout(() => {
                    assetMetadata?.next(newAssetData);
                }, 100);
            }
        });
    };

    private createRecordWithFieldIds = (appMetaData: AssetData, assetId: string, fieldIds: string[], businessKeyValue?: boolean) => {
        const entity = appMetaData?.getPrimaryEntity();
        const record: Record = new Record({}, entity);
        if (businessKeyValue || (fieldIds && fieldIds.length > 0)) {
            record.fields = record.fields
                .filter((field) => !fieldIds || fieldIds.length === 0 || fieldIds.indexOf(field.id) > -1)
                .filter((field) => businessKeyValue === undefined || field.isBusinessKey === businessKeyValue);
        }
        record.assetId = assetId;
        return record;
    };

    public buildMetaData = (service: AssetService, appPermissions?: any): Promise<AssetData> => {
        return new Promise(async (resolve) => {
            const appDependencyPromises = [this.getAssetMetaData(service)];
            if (!appPermissions) {
                appDependencyPromises.push(this._api.permissions.getAppPermissions(service.serviceId));
            }
            Promise.all(appDependencyPromises).then(([metaData, permissions]) => {
                const response = new AssetData(this._utils.transformAppMetaDataWithPermissions(metaData, appPermissions));
                const mastersPromise = this.fetchMastersData(response);
                const LookupsPromise = this.fetchAssetLookups(response as AssetData);
                Promise.all([mastersPromise, LookupsPromise]).then(([masters]) => {
                    response.masters = masters as any;
                    resolve(new AssetData(response));
                });
            });
        });
    };

    private fetchMastersData = (assetResponse): Promise<Masters> => {
        return new Promise((resolve) => {
            const tablesToFetch = (assetResponse && assetResponse.masterRefMetaDatas) || [];
            if (!tablesToFetch || tablesToFetch.length === 0) {
                resolve(undefined);
            }
            let mastersTablesCount = 0;
            const tablePromises = [];
            const masters = new Masters();
            mastersTablesCount = tablesToFetch.length;
            mastersTablesCount = tablesToFetch.length;
            const currentOrganizationId = this._commonUtils.getFromStorage('currentOrganizationId');
            tablesToFetch.forEach((tableData, index) => {
                tablePromises[index] = new Promise<void>((tableResolve) => {
                    this._api.masters.getMetaData(
                        {
                            organizationId: currentOrganizationId,
                            tableId: tableData.tableUid,
                            noAlerts: true,
                        },
                        {
                            successCallback: (response) => {
                                masters.setMasterTable(tableData.tableUid, response);
                                tableResolve();
                            },
                        }
                    );
                });
            });
            Promise.all(tablePromises).then(() => {
                resolve(masters);
            });
        });
    };

    private fetchAssetLookups = (assetData: AssetData) => {
        return new Promise<void>(async (resolve) => {
            const lookupsToFetch = [];
            this.getLookupToFetchByEntities(assetData, lookupsToFetch, assetData.entities);
            await this.fetchLookups(lookupsToFetch, assetData);
            resolve();
        });
    };

    private fetchLookups = (lookupsToFetch, assetData: AssetData) => {
        return new Promise((resolve) => {
            this._api.lookups.fetchMultipleLookupValues(
                {
                    lookupsData: lookupsToFetch,
                },
                {
                    successCallback: (response) => {
                        this.transformResponseToBindLookupValues(response || {}, assetData.entities);
                        resolve(assetData);
                    },
                }
            );
        });
    };

    private transformResponseToBindLookupValues = (lookupResponse, entities: Entity[]) => {
        for (const key in lookupResponse) {
            if (lookupResponse.hasOwnProperty(key)) {
                this.findEntityForLookup(lookupResponse[key], entities);
            }
        }
    };

    private findEntityForLookup = (lookupValue, entities: Entity[]) => {
        for (let i = 0; i < entities.length; i++) {
            const field: Field = entities[i].getFieldByLookupId(lookupValue.lookupId, lookupValue.fieldId);
            if (field && field.uid) {
                field.setLookupValues(lookupValue);
                break;
            }
            if (entities[i].entities && entities[i].entities.length > 0) {
                this.transformResponseToBindLookupValues(lookupValue, entities[i].entities);
            }
        }
    };

    private getLookupToFetchByEntities = (assetData: AssetData, lookupsToFetch: any[], entities: Entity | Entity[]) => {
        const assetId = assetData.uid;
        if (Array.isArray(entities)) {
            for (let i = 0; i < entities.length; i++) {
                this.getLookupToFetchByEntities(assetData, lookupsToFetch, entities[i]);
            }
        } else {
            for (let i = 0; i < entities.fields.length; i++) {
                const field = entities.fields[i];
                if (field.lookupInformationMetadata && field.uid !== 'hsn') {
                    const lookupdata = {
                        assetId: assetId,
                        entityId: entities.uid,
                        fieldId: field.uid,
                        lookupId: field.lookupInformationMetadata.id,
                        lookupValueFieldId: field.lookupInformationMetadata.valueField,
                        lookupDisplayFieldId: field.lookupInformationMetadata.displayField,
                    };
                    lookupsToFetch.push(lookupdata);
                }
            }
            if (entities.entities && entities.entities.length > 0) {
                this.getLookupToFetchByEntities(assetData, lookupsToFetch, entities.entities);
            }
        }
    };

    private getAssetMetaData = (app: AssetService) => {
        this.store$.dispatch(
            GetAppMetaData({
                noAlerts: false,
                serviceId: app?.serviceId,
                assetId: app?.assetMetaUId,
            })
        );
        return new Promise<AssetData>((resolve) => {
            this.appMetaSubscription?.unsubscribe();
            this.appMetaSubscription = this.store$
                .select(getAppMetaData$(app.serviceId, app.assetMetaUId))
                .pipe(filter((data) => data.templateUpdated && data.uid?.length > 0))
                .subscribe((event) => {
                    this.appMetaSubscription?.unsubscribe();
                    resolve(new AssetData(JSON.parse(JSON.stringify(event))));
                });
        });
    };

    public getFilingAtributes = (
        report: BridgeNode,
        bridge: AssetService,
        app: AssetService
    ): Promise<{
        assetId: string;
        businessKeys: string[];
        filingAttributeFields: any[];
        lookups: {};
    }> => {
        return new Promise((resolve) => {
            if (report) {
                if (bridge?.serviceId) {
                    this._api.assets.getReportFilingAttributes(
                        {
                            fileName: report.name,
                            serviceId: bridge.serviceId,
                        },
                        {
                            successCallback: (res) => {
                                resolve(res);
                            },
                        }
                    );
                }
            } else {
                this._api.assets.getFilingAttributes(
                    {
                        bridge: bridge,
                        service: app,
                    },
                    {
                        successCallback: (response) => {
                            resolve(response);
                        },
                        failureCallback: (response) => {
                            this._utils.alertError(response?.msg || translate('Failed to get filing data'));
                            resolve({
                                assetId: undefined,
                                businessKeys: [],
                                filingAttributeFields: [],
                                lookups: {},
                            });
                        },
                    }
                );
            }
        });
    };

    public validateFilingAttributeFields = ({
        filingAttributeFields,
        app,
        bridge,
    }: {
        filingAttributeFields: FilingAttributeField[];
        app: AssetService;
        bridge: AssetService;
    }) => {
        return new Promise<boolean>((resolve) => {
            const data = {
                businessKeys: [],
                entityAttributes: [],
            };
            if (this.validateFilingAttributes(filingAttributeFields)) {
                filingAttributeFields
                    .filter((field) => !field.autoCalculate)
                    .forEach((field: FilingAttributeField) => {
                        data['businessKeys'].push(field.id);
                        if (field.value !== undefined) {
                            data['entityAttributes'].push(new FilingAttributeFieldPayload(field));
                        }
                    });
                const payloadObj = {
                    payload: {
                        entityAttributes: data['entityAttributes'],
                    },
                    restApiName: app?.restApiName,
                    bridgeRestApiName: bridge?.restApiName,
                };
                if (bridge?.restApiName) {
                    payloadObj.payload['assetId'] = app.assetMetaUId;
                    payloadObj.payload['participantName'] = app.name;
                }
                if (data.businessKeys.length === 0) {
                    resolve(true);
                } else {
                    this._api.assets.validateFilingAttributes(payloadObj, {
                        successCallback: (res) => {
                            resolve(true);
                        },
                        failureCallback: (res) => {
                            this._utils.alertError(res?.msg || translate('Failed to validate attributes'));
                            resolve(false);
                        },
                    });
                }
            }
        });
    };

    public validateFilingAttributes = (attributes: FilingAttributeField[]) => {
        attributes
            .filter((field) => !field.autoCalculate)
            .forEach((field) => {
                field.message.errors = [];
                if (field.mandatory || field.isBusinessKey) {
                    if (field.value === undefined || field.value === '') {
                        field.message.errors.push(translate('Please enter value'));
                    } else {
                        if (field && field.value && field.value.length > 0) {
                            if (field.dataType === 'int' || field.dataType === 'long') {
                                if (!this._utils.checkInteger(field.value)) {
                                    field.message.errors.push(translate('Please enter only numbers'));
                                } else {
                                    field.message.errors = [];
                                }
                            }
                            if (field.dataType === 'double' || field.dataType === 'float') {
                                if (!this._utils.checkFloat(field.value)) {
                                    field.message.errors.push(translate('Only digits allowed(with decimal)'));
                                } else {
                                    field.message.errors = [];
                                }
                            }
                        }
                    }
                } else {
                    if (field?.value?.length > 0) {
                        if (field.dataType === 'int' || field.dataType === 'long') {
                            if (!this._utils.checkInteger(field.value)) {
                                field.message.errors.push(translate('Please enter only numbers'));
                            } else {
                                field.message.errors = [];
                            }
                        }
                        if (field.dataType === 'double' || field.dataType === 'float') {
                            if (!this._utils.checkFloat(field.value)) {
                                field.message.errors.push(translate('Only digits allowed(with decimal)'));
                            } else {
                                field.message.errors = [];
                            }
                        }
                    } else {
                        field.message.errors = [];
                    }
                }
            });
        return attributes.filter((field) => field.message?.errors?.length > 0).length === 0;
    };

    public saveReconTemplate = (data: { formData: FormData; restApiName: string; selectedTemplateId: string }) => {
        this._api.requests.createNewReconTemplate({
            payload: data.formData,
            restApiName: data.restApiName,
            selectedTemplateId: data.selectedTemplateId,
            successCallBack: (res: any) => {
                this._utils.alertSuccess(res?.message || res?.msg || translate('Template saved successfully'));
                this.getReconTemplates(data.restApiName);
            },
        });
    };

    public getReconTemplates = (restApiName: string) => {
        this._api.requests
            .getAllReconTemplates({
                restApiName: restApiName,
            })
            .then((response) => {
                this.reconProcesssTemplates.next(response.records);
            })
            .catch((res) => {
                this.reconProcesssTemplates.next([]);
                res?.response?.msg || translate('Failed to get recon templates');
            });
    };

    public deleteReconTemplate = (restApiName: string, template: any, isMultiDelete?: boolean) => {
        return new Promise((resolve) => {
            this._api.requests
                .deleteReconTemplate({
                    restApiName: restApiName,
                    templateId: template.templateId,
                })
                .then((res) => {
                    const message = `${translate('Recon template')} [ ${template.templateName} ] ${translate('deleted sucessfully')}`;
                    !isMultiDelete && this._utils.alertSuccess(message);
                    const existingTemplates = this.reconProcesssTemplates.value;
                    const templates = existingTemplates.filter((temp) => temp.templateId !== template.templateId);
                    this.reconProcesssTemplates.next(templates);
                    resolve({
                        message: template.templateName,
                        success: true,
                    });
                })
                .catch((error) => {
                    const message = error?.response?.msg || translate('Failed to delete recon template');
                    !isMultiDelete && this._utils.alertError(message);
                    resolve({
                        message: template.templateName,
                        success: false,
                    });
                });
        });
    };
}

interface OrganizationNode {
    organization: Organization;

    nextNodes: OrganizationNode[];
}
