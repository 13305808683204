import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { AssetEntityFieldConfigurationInterface } from '../../models/configurations/assetentityfieldconfiguration.interface';
import { AssetRuleConfigurationInterface } from '../../models/configurations/assetruleconfiguration.interface';
import { ConfigurationInterface } from '../../models/configurations/configuration.interface';
import { RuleParamConfigurationInterface } from '../../models/configurations/ruleparamconfiguration.interface';
import { Entity } from '../../models/entity.class';
import { GridConstraintCellDetail } from '../../models/field.class';
import { MasterRecord, MasterRecordField } from '../../models/masters/masterrecord.class';
import { GridCellData } from '../../models/record/gridfield.interface';
import { Message } from '../../models/record/message.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { Rule } from '../../models/rule.class';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class ValidationsService {
    private _configurations: ConfigurationInterface[];

    constructor(private _libUtils: UtilsService) {}

    /**
     * Method to check max length
     * @param value Value to be checked
     * @param ruleParam Rule param
     */
    private checkMaxLength(value, ruleParam): boolean {
        return value === undefined || value === null || value === '' || value.length <= parseInt(ruleParam.value, null);
    }

    /**
     * Method to check pattern
     * @param value Value to be checked
     * @param ruleParam Rule param
     */
    private checkPattern(value, ruleParam): boolean {
        try {
            if (ruleParam.value === 'd+(.d+)?') {
                return value === '' || value === undefined || value === null || /^\d+(\.\d+)?$/.test(value);
            } else if (ruleParam.value === '([1-3]?d{1,3}|4000)') {
                return (
                    value === '' ||
                    value === undefined ||
                    value === null ||
                    (/^\d+(\.\d+)?$/.test(value) && parseInt(value, undefined) <= 4000)
                );
            }
            return value === '' || value === undefined || value === null || new RegExp(ruleParam.value).test(value);
        } catch {
            return true;
        }
    }

    /**
     * Method to check future date
     * @param value Value to be checked
     * @param ruleParam Rule param
     */
    private checkFutureDateValidation(value): boolean {
        const givenTimeStamp = new Date(this.getSystemFormatTime(value)).getTime();
        const currentTime = new Date().getTime();
        return value === undefined || value === null || givenTimeStamp < currentTime;
    }

    /**
     * Method to convert given time to system formatted time
     * @param value Value to be converted
     */
    private getSystemFormatTime(value) {
        const keys = value.split(/[\/-]/);
        return keys[1] + '/' + keys[0] + '/' + keys[2];
    }

    /**
     * Method to check max value
     * @param value Value to be checked
     * @param ruleParam Rule param
     */
    private checkMaxValue(value, ruleParam): boolean {
        return value === undefined || value === null || parseFloat(value) <= parseFloat(ruleParam.value);
    }

    /**
     * Method to check min value
     * @param value Value to be checked
     * @param ruleParam Rule param
     */
    private checkMinValue(value, ruleParam): boolean {
        return value === undefined || value === null || parseFloat(value) >= parseFloat(ruleParam.value);
    }

    /**
     * Method to check min length
     * @param value Value to be checked
     * @param ruleParam Rule param
     */
    private checkMinLength(value, ruleParam): boolean {
        return value === undefined || value === null || value === '' || value.length >= parseInt(ruleParam.value, null);
    }

    /**
     * Method to check phone number with limit
     * @param str Value to be checked
     */
    private checkPhoneNumberwithLimit = (str): boolean => {
        const phoneno = /^(?!0{10,15})([0-9]{10,15})$/;
        if (str) {
            if (str.match(phoneno)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Method to check if field value type is integer
     */
    private validateIntegerType = (value: RecordField['value']) => {
        return this._libUtils.checkInteger(value) ? true : translate('Enter numbers only');
    };

    /**
     * Method to check if field value type is double
     */
    private validateNumberType = (value: RecordField['value']) => {
        return this._libUtils.checkDouble(value) ? true : translate('Enter numbers only');
    };

    /**
     * check GSTN format
     * @param gst_in GSTIN value
     */
    private acceptGSTIN(gst_in) {
        const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
        gst_in = gst_in && gst_in.trim();
        return gst_in && (regex.test(gst_in) || /URP/.test(gst_in));
    }

    private applyFieldRules = (
        value: any,
        name: string,
        required: boolean,
        datatype: string,
        rules: Rule[],
        message: Message,
        readOnly: boolean
    ) => {
        let result: boolean | string = true;
        if (readOnly && value === undefined) {
            return;
        }
        if (required) {
            result = (value !== undefined && value !== '') || name + ' ' + translate('is a mandatory field');
        }
        if (value !== undefined && value !== null && value !== '') {
            if (result === true) {
                if (datatype !== undefined) {
                    switch (datatype) {
                        case 'INT':
                        case 'LONG':
                            result = this.validateIntegerType(value);
                            break;
                        case 'DOUBLE':
                            result = this.validateNumberType(value);
                            break;
                        case 'BOOLEAN':
                            result =
                                value === 'false' || value === false || value === true || value === 'true'
                                    ? true
                                    : 'Please enter a boolean value (true/false)';
                            break;
                    }
                }
            }
        }
        if (result === true) {
            rules
                ?.filter((rule) => rule.ruleCategory !== 'CALCULATION_RULE')
                .forEach((rule) => {
                    result === true && (result = rule?.ruleParams && this.applyRule(rule, value, name));
                });
        }
        if (result !== true) {
            message.errors.push(result);
        }
    };

    private applyRule = (rule: Rule, value: any, name: string) => {
        let result: boolean | string = true;
        if (rule.ruleLanguage === 'JAVASCRIPT') {
            value?.length > 0 &&
                rule.ruleParams.forEach((param) => {
                    const ruleArgumentValues = [value];
                    if (result !== true) {
                        return;
                    }
                    param.value && ruleArgumentValues.push(param.value);
                    let ruleMethod = rule.method;
                    if (ruleMethod.indexOf('=function') > -1 || ruleMethod.indexOf('= function') > -1) {
                        ruleMethod = ruleMethod.substring(ruleMethod.indexOf('function'));
                    }
                    const method = new Function(`"use strict";return (${ruleMethod})`)();
                    result = method(...ruleArgumentValues) || rule.failureMessage;
                });
            return result;
        }
        value !== undefined &&
            rule.ruleParams.forEach((ruleParam) => {
                if (result !== true) {
                    return;
                }
                switch (rule.ruleType) {
                    case 'max_length':
                    case 'length':
                        result =
                            this.checkMaxLength(value, ruleParam) ||
                            rule.failureMessage ||
                            'Max allowed characters length is ' + ruleParam.value;
                        break;
                    case 'pattern':
                        result = this.checkPattern(value, ruleParam) || rule.failureMessage || 'Enter a valid ' + name;
                        break;
                    case 'futuredatevalidation':
                        result =
                            !value || this.checkFutureDateValidation(value) || rule.failureMessage || 'Entered data cannot be future date';
                        break;
                    case 'max_value':
                        result = this.checkMaxValue(value, ruleParam) || rule.failureMessage || 'Max value can be ' + ruleParam.value;
                        break;
                    case 'min_length':
                        result = this.checkMinLength(value, ruleParam) || rule.failureMessage || 'Min value must be ' + ruleParam.value;
                        break;
                    case 'phoneNumber':
                        result =
                            value && (value as string).length > 0
                                ? this.checkPhoneNumberwithLimit(value) || rule.failureMessage || 'Enter a valid phone number'
                                : true;
                        break;
                    case 'gstinewaybill':
                        result = true;
                        // result = (!value || this.acceptGSTIN(value)) ? true : rule.failureMessage;
                        break;
                    case 'equal':
                        let valueToCheck: string;
                        switch (typeof valueToCheck) {
                            case 'string':
                                valueToCheck = value?.trim();
                                break;
                            case 'boolean':
                                valueToCheck = '' + value;
                                break;
                            default:
                                valueToCheck = value;
                                break;
                        }
                        const isEqual = valueToCheck === ruleParam.value;
                        result = isEqual || rule.failureMessage || `Entered value must be ${ruleParam.value}`;
                        break;
                    case 'min_value':
                        result = this.checkMinValue(value, ruleParam) || rule.failureMessage || 'Max value can be ' + ruleParam.value;
                        break;
                    default:
                        console.error('Rule not present :' + rule.ruleType);
                        break;
                }
            });
        return result;
    };

    /**
     * Method to check grid cell value
     * @param cellData Contains cell data details
     * @param cellMetaData Contains cell metadata details
     */
    private checkGridCellValue = (cellData: GridCellData, cellMetaData: GridConstraintCellDetail, readOnly: boolean) => {
        const value = cellData.value as any;
        cellData.errors = [];
        this.applyFieldRules(
            value,
            cellMetaData.name,
            cellMetaData.mandatory,
            cellMetaData.datatype,
            cellMetaData.rules,
            cellData as any,
            readOnly
        );
        return cellData.errors.length > 0;
    };

    /**
     * Check field value with rules
     * @param field Record -> Field
     */
    private checkField(field: RecordField): boolean {
        let value = field.value;
        switch (field?.uiTypeMetadata) {
            case 'TEXTAREA':
                value = (field.value as string)?.length > 0 ? decodeURIComponent(field.value as string) : field.value;
                return;
        }
        this.applyFieldRules(value, field.displayName, field.isMandatory, field.datatype, field.rules, field.message, field.readOnly);
        return field.message.errors.length > 0;
    }

    /**
     * Check field value with rules
     * @param field Record -> Field
     */
    applyFieldCalculations(field: RecordField, record: Record): void {
        const methodArguments = [];
        field.rules
            .filter((rule) => rule.ruleCategory === 'CALCULATION_RULE' && rule.ruleLanguage === 'JAVASCRIPT')
            .forEach((rule) => {
                rule.ruleParams.forEach((param) => {
                    param.entityField?.fieldUid && methodArguments.push(record.getFieldValue(param.entityField?.fieldUid));
                    param.value !== undefined && methodArguments.push(param.value);
                });
                let ruleMethod = rule.method;
                if (ruleMethod.indexOf('=function') > -1 || ruleMethod.indexOf('= function') > -1) {
                    ruleMethod = ruleMethod.substring(ruleMethod.indexOf('function'));
                }
                const method = new Function(`"use strict";return (${ruleMethod})`)();
                field.value = method(...methodArguments);
            });
    }

    private checkMasterField = (field: MasterRecordField) => {
        const value = field.value;
        this.applyFieldRules(
            value,
            field.displayName || field.name,
            field.mandatory || field.primaryKey,
            field.dataType,
            field.rules,
            field.message,
            false
        );
        return field.message.errors.length > 0;
    };

    /**
     * Method to check for dependent field values
     * @param fieldValue Current field value
     * @param ruleParam Rule params of the custom rule
     * @param rule Custom rule
     * @param entities List of Entity
     */
    private checkEitherOrAllRequired(
        fieldValue: any,
        ruleParam: RuleParamConfigurationInterface,
        rule: AssetRuleConfigurationInterface,
        entities: Entity[]
    ): boolean {
        let requiredConditionSatisfied = false;
        const givenParamFieldValue = this._libUtils.getEntityFieldValue(
            entities,
            ruleParam.dependencyEntity,
            null,
            ruleParam.dependencyField
        );
        if (ruleParam.name === 'notEqual') {
            requiredConditionSatisfied = givenParamFieldValue && givenParamFieldValue !== ruleParam.value;
        } else if (ruleParam.name === 'equal') {
            requiredConditionSatisfied = givenParamFieldValue && givenParamFieldValue === ruleParam.value;
        }
        let checkMandatoryField;
        switch (rule.ruleType) {
            case 'eitherIsRequired':
                checkMandatoryField = false;
                break;
            case 'allAreRequired':
                checkMandatoryField = true;
                break;
            case 'noneRequired':
                checkMandatoryField = true;
                break;
        }
        if (requiredConditionSatisfied) {
            for (let i = 0; i < rule.mandatoryFields.length; i++) {
                const mandatoryFieldValue = this._libUtils.getEntityFieldValue(
                    entities,
                    rule.mandatoryFields[i].entityId,
                    null,
                    rule.mandatoryFields[i].fieldId
                );
                if (rule.mandatoryFields[i].type === 'isOptional') {
                    checkMandatoryField =
                        checkMandatoryField || (mandatoryFieldValue && mandatoryFieldValue !== undefined && mandatoryFieldValue !== '');
                } else if (rule.mandatoryFields[i].type === 'isRequired') {
                    checkMandatoryField =
                        checkMandatoryField &&
                        mandatoryFieldValue &&
                        mandatoryFieldValue !== undefined &&
                        mandatoryFieldValue !== '' &&
                        (!rule.mandatoryFields[i].value || (rule.mandatoryFields[i].value && rule.mandatoryFields[i].value === fieldValue));
                } else if (rule.mandatoryFields[i].type === 'mustBeEmpty') {
                    checkMandatoryField =
                        checkMandatoryField && !mandatoryFieldValue && (mandatoryFieldValue === undefined || mandatoryFieldValue === '');
                } else if (rule.mandatoryFields[i].type === 'pattern') {
                    checkMandatoryField =
                        checkMandatoryField &&
                        mandatoryFieldValue &&
                        this.checkPattern(mandatoryFieldValue, rule.mandatoryFields[i].pattern);
                }
            }
        } else {
            checkMandatoryField = true;
        }
        return checkMandatoryField;
    }

    /**
     * Method to check if the field is important for the process
     * @param value Value of the field
     * @param ruleParam Custom rule param
     * @param process Submit action of the process
     */
    private checkProcessField(value: string, ruleParam: RuleParamConfigurationInterface, process: string) {
        return ruleParam.value === process ? value && value.length > 0 : true;
    }

    /**
     * Check field value with custom rules
     * @param field Record -> Field
     * @param rules Custom rules
     * @param entities List of entity
     * @param process Process name of the submit action if any
     */
    private checkCustomFieldRules(
        field: RecordField,
        rules: AssetRuleConfigurationInterface[],
        entities: Entity[],
        process?: string
    ): boolean {
        let result: boolean | string = true;
        let value = field.value;
        switch (field?.uiTypeMetadata) {
            case 'TEXTAREA':
                value = (field.value as string)?.length > 0 ? decodeURIComponent(field.value as string) : field.value;
                break;
        }
        if (result === true) {
            /**
             * if mandatory check passed then checking system rules
             */
            rules.forEach((rule) => {
                for (let i = 0; i < rule.ruleParams.length; i++) {
                    if (result !== true) {
                        return;
                    }
                    const ruleParam = rule.ruleParams[i];
                    switch (rule.ruleType) {
                        case 'pattern':
                            result = this.checkPattern(value, ruleParam) || rule.failureMessage || 'Enter a valid ' + field.displayName;
                            break;
                        case 'eitherIsRequired':
                            result = this.checkEitherOrAllRequired(value, ruleParam, rule, entities) || rule.failureMessage;
                            break;
                        case 'allAreRequired':
                            result = this.checkEitherOrAllRequired(value, ruleParam, rule, entities) || rule.failureMessage;
                            break;
                        case 'noneRequired':
                            result = this.checkEitherOrAllRequired(value, ruleParam, rule, entities) || rule.failureMessage;
                            break;
                        case 'processCheck':
                            result = this.checkProcessField(value as string, ruleParam, process) || rule.failureMessage;
                            break;
                        default:
                            console.error('Rule not present :' + rule.ruleType);
                            // console.log(rule);
                            break;
                    }
                }
            });
        }
        if (result !== true) {
            field.message.errors.push(result);
        }
        return field.message.errors.length > 0;
    }

    /**
     * Sorting field rules
     * @param field Record field data
     */
    validateFieldRules(field: RecordField): boolean {
        /**
         * running system defined rules first
         */
        const hasAssetRuleErrors = this.checkField(field);
        return hasAssetRuleErrors;
    }

    /**
     * Sorting field rules
     * @param field Record field data
     * @param _configurationsField Custom rules of the field
     * @param entities List of entity
     * @param process Process name of the submit action if any
     */
    private validateCustomFieldRules(
        field: RecordField,
        _configurationsField: AssetEntityFieldConfigurationInterface,
        entities: Entity[],
        process: string
    ): boolean {
        /**
         * running system defined rules first
         */
        const hasAssetRuleErrors = this.checkCustomFieldRules(field, _configurationsField.rules, entities, process);
        return hasAssetRuleErrors;
    }

    /**
     * Method to validate grid
     */
    validateGrid = (field: RecordField) => {
        let hasErrors = false;
        field.message = new Message();
        const gridContraints = field.gridConstraints;
        const gridData = field.gridData;
        if (gridData?.cells?.length > 0) {
            gridData.cells.forEach((cellData) => {
                const cellMetaData = gridContraints.cellDetails.find(
                    (cell) => cell.columnIndex === cellData.columnIndex && cell.rowIndex === cellData.rowIndex
                );
                hasErrors = this.checkGridCellValue(cellData, cellMetaData, field.readOnly) || hasErrors;
            });
        }
        hasErrors && field.message.errors.push('Fix errors to proceed');
        return hasErrors;
    };

    public hasValidEntitiesData = (entities: Entity[]) => {
        let hasValidData = true;
        entities.forEach((entity) => {
            if (entity.entityData instanceof Record) {
                hasValidData =
                    this.hasValidData({
                        configurations: undefined,
                        entities: entities,
                        entityId: entity.uid,
                        record: entity.entityData,
                        restAPIName: undefined,
                        process: undefined,
                    }) && hasValidData;
                entity.entityData.entities?.length > 0 &&
                    (hasValidData = this.hasValidEntitiesData(entity.entityData.entities) && hasValidData);
            } else {
                entity.entityData.forEach((record) => {
                    hasValidData =
                        this.hasValidData({
                            configurations: undefined,
                            entities: entities,
                            entityId: entity.uid,
                            record,
                            restAPIName: undefined,
                            process: undefined,
                        }) && hasValidData;
                    record.entities?.length > 0 && (hasValidData = this.hasValidEntitiesData(record.entities) && hasValidData);
                });
            }
        });
        return hasValidData;
    };

    /**
     * check record object to see if it has valid data, also clears all the errors if it doesnt have any
     * @param record Entity -> Record data
     * @param restAPIName Service -> RestAPIName
     * @param entityId Id of the entity
     * @param entities List of entity
     * @param process Process name of the submit action if any
     */
    hasValidData = (data: {
        record: Record;
        restAPIName: string;
        entityId: string;
        entities: Entity[];
        process?: string;
        configurations: ConfigurationInterface[];
    }): boolean => {
        let asset;
        this._configurations = data.configurations || [];
        for (let i = 0; i < this._configurations.length; i++) {
            if (this._configurations[i].restAPIName === data.restAPIName) {
                asset = this._configurations[i];
                break;
            }
        }
        let fields;
        if (asset) {
            for (const key in asset.entities) {
                if (key === data.entityId) {
                    fields = asset.entities[key];
                    break;
                }
            }
        }
        let errorFound = false;
        data.record.fields
            .filter((field) => field.show && !field.autoCalculate)
            .forEach((field) => {
                field.message.errors = [];
                let _configurationsField;
                if (fields) {
                    for (let i = 0; i < fields.fields.length; i++) {
                        if (fields.fields[i].id === field.id) {
                            _configurationsField = fields.fields[i];
                            break;
                        }
                    }
                }
                if (field.datatype === 'GRID') {
                    errorFound = this.validateGrid(field) || errorFound;
                } else {
                    errorFound = this.validateFieldRules(field) || errorFound;
                    errorFound =
                        (_configurationsField && this.validateCustomFieldRules(field, _configurationsField, data.entities, data.process)) ||
                        errorFound;
                }
            });
        /** @todo Need to validate if this necessary */
        // (!errorFound) && this.applyRecordCalculations({ record: data.record });
        return !errorFound;
    };

    /**
     * Apply record calculations
     * @param record Entity -> Record data
     */
    applyRecordCalculations = (data: { record: Record }): void => {
        data.record.fields
            .filter((field) => field.show)
            .forEach((field) => {
                field.message.errors = [];
                if (field.show && field.datatype === 'GRID') {
                    /**
                     * Do nothing for now
                     */
                } else {
                    this.applyFieldCalculations(field, data.record);
                }
            });
    };

    hasValidMasterRecordData = (record: MasterRecord): boolean => {
        let errorFound = false;
        Object.keys(record.fields)
            .filter((key) => record.fields[key].active)
            .forEach((key) => {
                const field = record.fields[key];
                field.message.errors.splice(0);
                if (field.active) {
                    errorFound = this.checkMasterField(field) || errorFound;
                }
            });
        return !errorFound;
    };

    hasValidMasterRecordFieldData = (record: MasterRecordField): boolean => {
        let errorFound = false;
        record.message.errors.splice(0);
        if (record.active) {
            errorFound = this.checkMasterField(record) || errorFound;
        }
        return !errorFound;
    };
}
