import { createAction, props } from '@ngrx/store';
import { AppTemplate, AssetData, AssetService, Message, ReconciliationConfiguration } from 'taxilla-library';

import { AppsState } from '../states';

enum AppsActionTypes {
    GET_DESCRIPTION = '[APPS] Get Description',
    SET_DESCRIPTION = '[APPS] Set Description',
    GET_HELP_DOCUMENTS = '[APPS] Get Help Documents',
    SET_HELP_DOCUMENTS = '[APPS] Set Help Documents',
    GET_BRIDGE_NODES = '[APPS] Get Bridge Nodes',
    SET_BRIDGE_NODES = '[APPS] Set Bridge Nodes',
    GET_SUBSCRIBED_SERVICES = '[APPS] Get Subscribed Services',
    SET_SUBSCRIBED_SERVICES = '[APPS] Set Subscribed Services',
    GET_METADATA = '[APPS] Get Metadata',
    SET_METADATA = '[APPS] Set Metadata',
    GET_RECON_CONFIGURATION = '[APPS] Get Recon Configuration',
    SET_RECON_CONFIGURATION = '[APPS] Set Recon Configuration',
    SET_LOADING = '[APPS] Set Loading',
    SET_TEMPLATE_LOADING = '[APPS] Set Template Loading',
    SET_RECON_CONFIGURATION_LOADING = '[APPS] Set Recon Configuration Loading',
    SET_SERVICE_ID = '[APPS] Set Service ID',
    SET_BRIDGE_SERVICE_ID = '[APPS] Set Bridge Service ID',
    SET_CATEGORY = '[APPS] Set Category',
    SET_ENTITY = '[APPS] Set Entity',
    ROUTE_CATEGORY = '[APPS] Route Category',
    GET_TEMPLATE = '[APPS] Get Template',
    SET_TEMPLATE = '[APPS] Set Template',
    TRIGGER_SUBMIT_TEMPLATE = '[APPS] Trigger Submit Template',
    SUBMIT_TEMPLATE = '[APPS] Submit Template',
    DELETE_TEMPLATE = '[APPS] Delete Template',
}

export const GetAppDescription = createAction(
    AppsActionTypes.GET_DESCRIPTION,
    props<{
        serviceId: string;
        assetId?: string;
    }>()
);

export const SetAppDescription = createAction(
    AppsActionTypes.SET_DESCRIPTION,
    props<{
        serviceId: string;
        description: string;
        assetId: string;
    }>()
);

export const GetAppHelpDocumentation = createAction(
    AppsActionTypes.GET_HELP_DOCUMENTS,
    props<{
        serviceId: string;
        bridgeServiceId?: string;
        bridgeNodeId?: string;
    }>()
);

export const SetAppHelpDocumentation = createAction(
    AppsActionTypes.SET_HELP_DOCUMENTS,
    props<{
        serviceId: string;
        helpDocuments: AppsState['appsHelpDocumentsByServiceId'][''];
        bridgeNodeId: string;
        bridgeServiceId?: string;
    }>()
);

export const GetAppBridgeNodes = createAction(
    AppsActionTypes.GET_BRIDGE_NODES,
    props<{
        assetId?: string;
        serviceId?: string;
    }>()
);

export const SetAppBridgeNodes = createAction(
    AppsActionTypes.SET_BRIDGE_NODES,
    props<{
        bridgeNodes: AppsState['bridgeServices'][''];
        assetId: string;
    }>()
);

export const GetSubscribedApps = createAction(AppsActionTypes.GET_SUBSCRIBED_SERVICES);

export const SetSubscribedApps = createAction(
    AppsActionTypes.SET_SUBSCRIBED_SERVICES,
    props<{
        services: AssetService[];
    }>()
);

export const GetAppMetaData = createAction(
    AppsActionTypes.GET_METADATA,
    props<{
        serviceId: string;
        assetId?: string;
        noAlerts?: boolean;
        guest?: boolean;
        forceFetch?: boolean;
    }>()
);

export const SetAppMetaData = createAction(
    AppsActionTypes.SET_METADATA,
    props<{
        serviceId: string;
        metaData: AssetData;
    }>()
);

export const GetReconConfiguration = createAction(
    AppsActionTypes.GET_RECON_CONFIGURATION,
    props<{
        serviceId: string;
        noAlerts: boolean;
    }>()
);

export const SetReconConfiguration = createAction(
    AppsActionTypes.SET_RECON_CONFIGURATION,
    props<{
        serviceId: string;
        configuration: ReconciliationConfiguration;
    }>()
);

export const SetAppLoading = createAction(
    AppsActionTypes.SET_LOADING,
    props<{
        serviceId: string;
        loading: boolean;
    }>()
);

export const SetAppTemplateLoading = createAction(
    AppsActionTypes.SET_TEMPLATE_LOADING,
    props<{
        serviceId: string;
        loading: boolean;
    }>()
);

export const SetReconConfigurationLoading = createAction(
    AppsActionTypes.SET_RECON_CONFIGURATION_LOADING,
    props<{
        serviceId: string;
        loading: boolean;
    }>()
);

export const SetSelectedAppServiceId = createAction(
    AppsActionTypes.SET_SERVICE_ID,
    props<{
        serviceId: string;
    }>()
);

export const SetSelectedBridgeServiceId = createAction(
    AppsActionTypes.SET_BRIDGE_SERVICE_ID,
    props<{
        serviceId: string;
    }>()
);

export const RouteSelectedAppCategory = createAction(
    AppsActionTypes.ROUTE_CATEGORY,
    props<{
        category: AppsState['selectedApp']['category'];
    }>()
);

export const SetSelectedAppCategory = createAction(
    AppsActionTypes.SET_CATEGORY,
    props<{
        category: AppsState['selectedApp']['category'];
    }>()
);

export const SetSelectedAppEntity = createAction(
    AppsActionTypes.SET_ENTITY,
    props<{
        entityId: string;
    }>()
);

export const GetAppTemplate = createAction(
    AppsActionTypes.GET_TEMPLATE,
    props<{
        serviceId: string;
        noAlerts: boolean;
        guest?: boolean;
        forceFetch?: boolean;
    }>()
);

export const SetAppTemplate = createAction(
    AppsActionTypes.SET_TEMPLATE,
    props<{
        organizationId: string;
        serviceId: string;
        template: AppsState['appsTemplate']['orgId']['serviceId'];
    }>()
);

export const AppTriggerSubmitTemplate = createAction(
    AppsActionTypes.TRIGGER_SUBMIT_TEMPLATE,
    props<{
        trigger: boolean;
    }>()
);

export const AppSubmitTemplate = createAction(
    AppsActionTypes.SUBMIT_TEMPLATE,
    props<{
        details: {
            id: string;
            label: string;
            value: string;
            readOnly: boolean;
            required?: boolean;
            message?: Message;
        }[];
        entitiesOrder: AppTemplate['entities'];
        entityDetails: {
            id: string;
            label: string;
            value: string | boolean;
            readOnly: boolean;
            required?: boolean;
            message?: Message;
        }[];
        fieldDetails: {
            fieldId: string;
            fieldOrder: number;
            details: {
                id: string;
                value: any;
            }[];
        }[];
    }>()
);

export const AppDeleteTemplate = createAction(AppsActionTypes.DELETE_TEMPLATE);
