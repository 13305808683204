import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';

import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { FieldLevelFileAttachmentComponent } from '../field-level-file-attachment/field-level-file-attachment.component';

@Component({
    selector: 'lib-recordfields',
    templateUrl: './recordfields.component.html',
    styleUrls: ['./recordfields.component.css'],
})
export class RecordFieldsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() entity: Entity;
    @Input() record: Record;
    @Input() instanceId: string;
    @Input() showGroups: boolean;
    @Input() service: AssetService;
    @Input() inputDisabled: boolean;
    @Input() fieldsToHighlight: string[] = [];
    @Input() readOnly: boolean;
    @Input() masterEntityFieldValueMap: { [property: string]: string };
    @Input() fieldsMap: BehaviorSubject<{
        [property: string]: { keyColumns: string[]; valuesMap: { id: string; masterColumn: string; value: any }[] };
    }>;
    @Input() inAuditTrailMode = false;
    @Input() hasEditPermission: boolean;
    @Input() recordUpdateDisabled: boolean;
    @Input() showOnlyBusinessKeyFields: boolean;

    @Output() recordUpdated = new EventEmitter();
    @Output() getRecord = new EventEmitter();
    @Output() editRecord = new EventEmitter();

    checkNaN = isNaN;
    currentEntity: string;
    groups: any[];
    isRecordDefined: string;
    definedFields: RecordField[];
    readOnlyGroups: any[];
    attachmentUri = environment.taxilla_api + '/asset/attachments?fileUri=';

    constructor(private dialog: MatDialog) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.record || changes.readOnly) {
            this.record?.fields && this.getAllGroups(this.record.fields);
        }
    }

    getAllGroups = (fields: RecordField[]) => {
        this.groups = [];
        this.readOnlyGroups = [];
        this.definedFields = fields && fields.filter((field) => field.value !== undefined);
        this.isRecordDefined = this.record.id;
        if (this.showGroups) {
            fields &&
                fields.forEach((field) => {
                    if (field.show && this.groups.indexOf(field.fieldSection) === -1) {
                        if (field.fieldSection) {
                            this.groups.push(field.fieldSection);
                        } else {
                            this.groups.unshift(field.fieldSection);
                        }
                    }
                    if (
                        field.datatype !== 'GRID' &&
                        (field.value !== undefined || (field.newValue || field.oldValue) !== undefined) &&
                        field.show &&
                        this.readOnlyGroups.indexOf(field.fieldSection) === -1
                    ) {
                        if (field.fieldSection) {
                            this.readOnlyGroups.push(field.fieldSection);
                        } else {
                            this.readOnlyGroups.unshift(field.fieldSection);
                        }
                    } else if (field.datatype === 'GRID' && field.show && this.readOnlyGroups.indexOf(field.fieldSection) === -1) {
                        if (field.fieldSection) {
                            this.readOnlyGroups.push(field.fieldSection);
                        } else {
                            this.readOnlyGroups.unshift(field.fieldSection);
                        }
                    }
                    if (this.record && this.record.message.errors && this.record.message.errors.length > 0) {
                        if (field && field.isMandatory && (field.value === '' || field.value === undefined || field.value === null)) {
                            const message = field.displayName + ' is mandatory';
                            if (field.message.errors.indexOf(message) === -1) {
                                field.message.errors.push(message);
                            }
                        }
                    }
                });
        }
    };

    trackByFieldId = (index: number, field: RecordField) => {
        return field.id;
    };

    trackByGroup = (index: number, section: string) => {
        return section;
    };

    trackByFile = (index: number, file: string) => {
        return index;
    };
    openAttachModal = (field: RecordField) => {
        const dialogRef = this.dialog.open(FieldLevelFileAttachmentComponent, {
            panelClass: ['fieldFileUploadModal', 'matDialogContainer'],
            disableClose: true,
        });
        dialogRef.componentInstance.record = this.record;
        dialogRef.componentInstance.field = field;
        dialogRef.componentInstance.instanceId = this.instanceId;
        dialogRef.componentInstance.recordUpdateDisabled = true;
        dialogRef.componentInstance.inReadOnlyMode = true;
        dialogRef.componentInstance.generateUploadedFiles();
    };

    ngOnInit() {
        this.record?.fields && this.getAllGroups(this.record.fields);
    }

    ngOnDestroy(): void {}
}
