<footer id="footerContainer" [title]="platformVersion ? 'Version ' + platformVersion : ''">
    <span class="tLogo">
        <a href="http://www.taxilla.com/" target="_blank" rel="noopener">
            <img class="logo" src="assets/images/encomply/t-logo.png" alt="Taxilla">
        </a>
    </span>
    <span class="taxillaLogo">
        <span>
            <a href="http://www.taxilla.com/" target="_blank" rel="noopener">
                <ng-container *ngIf="R.PLATFORM === 'emguat' || R.PLATFORM === 'emgprod'">
                    <img class="logo" src="assets/images/encomply/footer-logo" alt="Taxilla">
                </ng-container>
                <ng-container *ngIf="R.PLATFORM !== 'emguat' && R.PLATFORM !== 'emgprod'">
                    <img class="logo" src="assets/images/{{R.LOGO_HREF}}footer-logo.png" alt="Taxilla">
                </ng-container>
            </a>
        </span>
        <span class="content">
            {{platformVersion ? 'V' + platformVersion : 'V3.6.8'}}
        </span>
        <span class="content">
            &copy; {{currentYear}}
        </span>
    </span>
</footer>
