import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AlertError } from '../../store/actions';
import { BridgeService } from '../bridge/bridge.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(private _bridge: BridgeService, private store$: Store) {}

    getDashboards = (noAlerts?: boolean) => {
        return this._bridge.getDashboards(noAlerts);
    };

    getQuickSightDashboardURL = (data: { serviceId: string; noAlerts?: boolean }) => {
        return this._bridge.getQuickSightDashboardURL(data);
    };

    refreshQuicksightDashboard = (data: { widgetId: string; noAlerts?: boolean }) => {
        return this._bridge.refreshQuicksightDashboard(data);
    };

    quickSightDashboardStatus = (data: { widgetId: string; noAlerts?: boolean }) => {
        return this._bridge.quickSightDashboardStatus(data);
    };

    public getAnalyticsSearchCriteria = (data: {
        chainRestApiName: string;
        sourceName: string;
        searchArguments: { [property: string]: string };
        serviceId: string;
    }) => {
        const criteriaPromise = this._bridge.getAnalyticsSearchCriteria(data);
        criteriaPromise.catch((e) => {
            this.store$.dispatch(
                AlertError({
                    message: e.msg,
                })
            );
        });
        return criteriaPromise;
    };
}
