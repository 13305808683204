import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'material-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css'],
})
export class MaterialButtonComponent implements OnInit {
    @Input() label;
    @Input() icon;
    @Input() color;
    @Input() disabled;
    @Input() classList: string[];

    @Output() onTrigger = new EventEmitter();

    constructor() {}

    ngOnInit() {}
}
