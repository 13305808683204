import { Injectable } from '@angular/core';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { CollaborationRequestInfo } from '../../interface/collaboration-request.interface';
import { Request } from '../../interface/request.interface';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { RequestBusinessKeysPayload } from '../../models/payload/businesskeys.class';
import { ReconciliationRequest } from '../../models/reconciliation-request.class';
import { AlertError } from '../../store/actions';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class RequestsService {
    constructor(
        private _bridge: BridgeService,
        private _utils: UtilsService,
        private _store: StoreService,
        private _commonUtils: CommonUtilsService,
        private store$: Store
    ) {}

    /**
     * Method to get requests by sending assetName and serviceId
     * @param data Contains Asset name, Service Id, size, previous paging state
     * @param callbacks Contains Success callback method, Failure callback method,
     */
    getRequests = (data: {
        assetName: string;
        serviceId: string;
        size?: number;
        previousPagingState?: string;
        noAlerts?: boolean;
        restApiName?: string;
        status?: string;
        timeRange?: any[];
    }) => {
        return new Promise<{
            assetName: string;
            assetRequests: Request[];
            fetchSize: number;
            nextPagingState: string;
            orgId: string;
            previousPagingState: string;
            serviceId: string;
            unitId: string;
        }>((resolve, reject) => {
            this._bridge.getReportProcesses(
                {
                    reportName: data.assetName,
                    serviceId: data.serviceId,
                    size: data.size,
                    previousPagingState: data.previousPagingState,
                    noAlerts: data.noAlerts,
                    restApiName: data.restApiName,
                    timeRange: data.timeRange,
                    status: data.status,
                },
                async (res) => {
                    const wrapper = environment.specialUI === 'KSA' && (await this._bridge.getWrapperAPITags(data.serviceId));
                    if (wrapper) {
                        const response = this._utils.transformWrapperRequestsToGeneral(res?.response);
                        resolve(response as any);
                    } else if (data.serviceId && !data.restApiName) {
                        resolve(res?.response?.requests);
                    } else if (data.restApiName) {
                        resolve(res?.requests || res?.response);
                    }
                },
                (response) => {
                    this.store$.dispatch(AlertError({ message: response?.msg || translate('Failed to get Request Logs') }));
                    reject(response);
                }
            );
        });
    };

    getReconciliationRequests = (data: {
        restApiName: string;
        fromDate: string;
        toDate: string;
        pagingState: string;
        size: number;
        noAlerts?: boolean;
    }): Promise<{
        records: ReconciliationRequest[];
        nextPagingState: string;
        previousPagingState: string;
    }> => {
        return new Promise((resolve) => {
            this._bridge
                .getReconciliationRequests(data)
                .then((response) => {
                    const requests: ReconciliationRequest[] = [];
                    response?.records?.forEach((record) => {
                        requests.push(new ReconciliationRequest(record));
                    });
                    resolve({
                        nextPagingState: response.pagingState,
                        records: requests,
                        previousPagingState: response.previousPagingState,
                    });
                })
                .catch((response) => {
                    this.store$.dispatch(AlertError({ message: response?.msg || 'Failed to get requests' }));
                });
        });
    };

    /**
     * Method to refresh for a wrapper request
     * @param data
     * @param callbacks
     */
    refreshWrapperRequest = (data: { serviceId: string; requestId: string; restAPIName: string }) => {
        const request = this._bridge.refreshWrapperRequest(data);
        request.catch((e) => {
            this._utils.alertError(e.error || e.msg || 'Failure to trigger request refresh');
        });
        return request;
    };

    /**
     * Method to create a new request
     * @param data Contains payload, asset service data,
     * @param callbacks Contains success callback method,
     */
    createNewRequest = (data: {
        payload: FormData;
        service: AssetService;
        bridge?: AssetService;
        report?: BridgeNode;
        noAlerts?: boolean;
    }) => {
        return new Promise<{ msg: string; response: { requestId: string; REQUEST_EXISTS: string } }>((resolve, reject) => {
            this._bridge.createNewRequest(
                {
                    serviceId: data.service.serviceId,
                    payload: data.payload,
                    noAlerts: data.noAlerts,
                    appRestApiName: data.service.restApiName,
                    bridgeRestApiName: data.bridge && data.bridge.restApiName,
                    reportName: data.report && data.report.name,
                },
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res);
                    !data.noAlerts && this.store$.dispatch(AlertError({ message: res?.response?.msg || 'Failed to create new request' }));
                }
            );
        });
    };

    /**
     * Method to create a new request
     * @param data Contains payload, asset service data,
     * @param callbacks Contains success callback method,
     */
    createNewReconciliation = (data: { payload: FormData; restApiName: string }) => {
        return new Promise<any>((resolve, reject) => {
            this._bridge.createNewReconciliation(
                data,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.alertError(res?.response?.msg || 'Failed to create new reconciliation');
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to create a new recon template
     * @param data Contains payload, asset service data,
     * @param callbacks Contains success callback method,
     */
    createNewReconTemplate = (data: { payload: FormData; restApiName: string; successCallBack: any; selectedTemplateId: string }) => {
        this._bridge.createNewReconTemplate(
            data,
            (res) => {
                data.successCallBack(res?.response);
            },
            (res) => {
                this._utils.alertError(res?.response?.msg || 'Failed to create new recon template');
            }
        );
    };

    /**
     * Method to get all  recon templates
     * @param data Contains payload, asset service data,
     * @param callbacks Contains success callback method,
     */
    getAllReconTemplates = (data: { restApiName: string; noAlerts?: boolean }) => {
        return new Promise<any>((resolve, reject) => {
            this._bridge.getAllReconTemplates(
                data,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.response?.msg || translate('Failed to get recon templates') }));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to delete recon templates
     * @param data Contains payload, asset service data,
     * @param callbacks Contains success callback method,
     */
    deleteReconTemplate = (data: { restApiName: string; templateId: string }) => {
        return new Promise((resolve, reject) => {
            this._bridge.deleteReconTemplate(
                data,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to create a new request
     * @param data Contains bridge data, service data, request business keys payload,
     * @param callbacks Contains Success callback method,
     */
    submitBusinessKeys = (
        data: { bridge: AssetService; service: AssetService; payload: RequestBusinessKeysPayload },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.submitNewRequestBusinessKeys(
            {
                bridge: data.bridge,
                payload: data.payload,
                service: data.service,
            },
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to save business keys');
                }
            }
        );
    };

    /**
     * Method to get asset filing attributes
     * @param data Contains bridge data, service data,
     * @param callbacks Contains success callback method,
     */
    getFilingAttributes = (
        data: { restApiName: string; noAlerts?: boolean; requestId: string },
        callbacks: {
            successCallback?: (
                response: {
                    captureOnce: boolean;
                    dataType: string;
                    entityName: string;
                    fullId: string;
                    isBusinessKey: boolean;
                    isPrecaptured: boolean;
                    keyId: string;
                    label: string;
                    value: string;
                }[]
            ) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getRequestFilingAttributes(
            data,
            (res) => {
                callbacks.successCallback((res && res.response && res.response.filingAttributeValues) || []);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get filing data');
                }
            }
        );
    };

    /**
     * Method to get request level errors
     * @param data Contains requestId data,
     * @param callbacks Contains success callback method,
     */
    getRequestLevelErrors = (
        data: { serviceId: string; requestId: string },
        callbacks: { successCallback: (response: any) => void; failureCallback: (response: any) => void }
    ) => {
        this._bridge.getRequestLevelErrors(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get request index data
     * @param data Contains requestId data,
     * @param callbacks Contains success callback method,
     */
    getRequestIndexData = (
        data: { orgId: string; serviceId: string; requestId: string },
        callbacks: { successCallback: (response: any) => void; failureCallback: (response: any) => void }
    ) => {
        this._bridge.getRequestIndexData(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get request index status
     * @param data Contains requestId data,
     * @param callbacks Contains success callback method,
     */
    getRequestIndexStatus = (
        data: { serviceId: string; requestId: string },
        callbacks: { successCallback: (response: any) => void; failureCallback: (response: any) => void }
    ) => {
        this._bridge.getRequestIndexStatus(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to download new uploaded files
     * @param data Contains requestId data,
     * @param callbacks Contains success callback method,
     */
    downloadNewUploadedFiles = (data: { resourceId: string; restApiName: string; noAlerts?: boolean }) => {
        return new Promise<
            {
                checkSum: string;
                checksumLockEnabled: boolean;
                createOn: string;
                createdBy: string;
                fileUri: string;
                requestId: string;
            }[]
        >((resolve, reject) => {
            this._bridge.downloadNewUploadedFiles(
                data,
                (res) => {
                    resolve(res?.inboundTrasnmmissions);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get an  integration status
     * @param data Contains requestId, assetId,
     * @param callbacks Contains success callback method,
     */
    getIntegrationStatus = (
        data: { requestId: string; restApiName: string; orgId: string },
        callbacks: { successCallback: (statuses: any) => void; failureCallback?: (response) => void }
    ) => {
        this._bridge.getIntegrationStatus(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get integration status');
                }
            }
        );
    };

    /**
     * Method to get requests with macre search model
     */
    searchRequests = (data: { entityCriterias: any; size: number; searchAfter?: any; from: number; noAlerts: boolean }) => {
        return new Promise<any>((resolve, reject) => {
            this._bridge.searchRequests(
                data,
                (res) => {
                    const response = res && res.response;
                    if (response && response.searchAfter) {
                        if (data.size && response.requests && response.requests.length < data.size) {
                            delete response.searchAfter;
                        }
                    }
                    resolve(response || {});
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to get inbound transmissions';
                    this.store$.dispatch(
                        AlertError({
                            message: msg,
                        })
                    );
                    reject();
                }
            );
        });
    };

    /**
     * Method to get request Status using request id
     */
    getRequestWithRequestId = (
        data: {
            assetRestApiName: string;
            requestId: string;
        },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getRequestWithRequestId(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get request status');
                }
            }
        );
    };

    /*
     * Method to get requests by instanceIds
     * @param data Contains serviceId and assetDataIds,
     * @param callbacks Contains success callback method,
     */
    getRequestsByInstanceId = (
        data: { restApiName: string; assetDataIds: string[]; noAlerts: boolean },
        callbacks: { successCallback: (statuses: any) => void; failureCallback?: (response) => void }
    ) => {
        this._bridge.getRequestsByInstanceId(
            data,
            (res) => {
                callbacks.successCallback(res && res.response);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get integration status');
                }
            }
        );
    };

    /**
     * Method to Get processes based on statuses
     */
    getStatusBasedProcesses = (data: { serviceId: string; noAlerts: boolean }) => {
        return new Observable((observer) => {
            this._bridge.getStatusBasedProcesses(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to Get processes based on statuses with requestId
     */
    getStatusIdBasedProcesses = (
        data: {
            serviceId: string;
            requestId: string;
        },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getStatusIdBasedProcesses(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get request status');
                }
            }
        );
    };

    /**
     * Method to generate processConsignorReport
     * @param data ontains service restAPIName, payload as form data, transformation,
     * @param callbacks Contains success callback method,
     */
    processConsignorReport = (data: { appRestApiName: string; payload: FormData | any; transformationName: string }) => {
        return new Observable((observer) => {
            this._bridge.processConsignorReport(
                {
                    payload: data.payload,
                    appRestApiName: data.appRestApiName,
                    transformationName: data.transformationName,
                },
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to Cancel Requests
     * @param data ontains restApiName, requestId
     */
    cancelRequest = (
        data: {
            restApiName: string;
            requestId: string;
            comment?: string;
        },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.cancelRequest(
            {
                restApiName: data.restApiName,
                requestId: data.requestId,
                comment: data.comment,
            },
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to Cancel Request');
                }
            }
        );
    };

    /**
     * Method to Request Info
     * @param data contains requestId
     */
    getRequestInfo = (data: { restApiName: string; requestId: string }) => {
        return new Promise((resolve) => {
            this._bridge.getRequestInfo(
                {
                    restApiName: data.restApiName,
                    requestId: data.requestId,
                },
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.alertError(res?.msg ?? 'Failed to get request info');
                }
            );
        });
    };

    /**
     * Method to Request Info
     * @param data contains requestId
     */
    getCollaborationRequestInfo = async (
        data: {
            collaborationId: string;
        },
        callbacks: {
            successCallback: (response: CollaborationRequestInfo) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const id = encodeURIComponent(data.collaborationId);
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise<void>((resolve) => {
                    this._bridge.getCollaborationInfo(
                        data,
                        (res) => {
                            resolve(res?.response);
                        },
                        (res) => {
                            if (callbacks.failureCallback) {
                                callbacks.failureCallback(res);
                            } else {
                                this._utils.alertError(res?.msg || 'Failed to get request info');
                            }
                        }
                    );
                });
            },
            'collaborationDetails',
            id
        );
        callbacks.successCallback(response?.collaborationDetails?.[id]);
    };

    /**
     * Method to Reconciliation Request Info
     * @param data contains requestId
     */
    getReconciliationRequestInfo = async (data: { restApiName: string; requestId: string }) => {
        return new Promise<ReconciliationRequest>(async (resolveRequest) => {
            const response = await this._store.privateScope.fetchValues(
                () => {
                    return new Promise<void>((resolve) => {
                        this._bridge.getReconciliationRequestInfo(
                            {
                                restApiName: data.restApiName,
                                requestId: data.requestId,
                            },
                            (res) => {
                                resolve(res?.response);
                            },
                            (res) => {
                                this._utils.alertError(res?.msg ?? 'Failed to get request info');
                            }
                        );
                    });
                },
                'reconciliationRequest',
                data.restApiName,
                data.requestId
            );
            const request = response?.reconciliationRequest?.[data.restApiName]?.[data.requestId];
            resolveRequest(new ReconciliationRequest(request));
        });
    };

    /**
     * Method to Reconciliation Request State
     * @param data contains requestId
     */
    getReconciliationState = async (data: { restApiName: string; requestId: string }) => {
        return new Promise<{
            configId: string;
            eventTime: string;
            message: string;
            organization: string;
            recordedTime: string;
            requestId: string;
            requestWeek: string;
            status: string;
        }>((resolve) => {
            this._bridge.getReconciliationState(
                {
                    restApiName: data.restApiName,
                    requestId: data.requestId,
                },
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.alertError(res?.msg ?? 'Failed to get request info');
                }
            );
        });
    };

    /**
     * Method to load reconciliation log data
     * @param data contains requestId
     */
    getReconciliationLogs = async (data: { restApiName: string; requestId: string; noAlerts?: boolean; hideMessages?: boolean }) => {
        return new Promise<
            {
                configId: string;
                eventTime: string;
                message: string;
                organization: string;
                recordedTime: string;
                requestId: string;
                requestWeek: string;
                status: string;
                statusDisplay: string;
            }[]
        >((resolve) => {
            this._bridge.getReconciliationLogs(
                data,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    !data.hideMessages && this._utils.alertError(res?.msg ?? 'Failed to get request info');
                }
            );
        });
    };
}
