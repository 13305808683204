import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appRemoveAriaOwns]',
})
export class RemoveAriaOwnsDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        const elements = this.el?.nativeElement?.querySelectorAll?.('[aria-owns]');
        elements?.forEach((element) => {
            element.removeAttribute('aria-owns');
        });
    }
}
