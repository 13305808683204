<div [ngSwitch]="field?.uiTypeMetadata" class="recordFieldContainer w-full h-full position-relative" [class.text-center]="field?.uiTypeMetadata !== 'TEXTAREA'" [class.hasError]="field.message?.errors?.length > 0" [matTooltip]="field.message?.errors?.join('/n')" *transloco="let translate;">
    <span *ngSwitchCase="'TEXTFIELD'">
        <ng-container [ngSwitch]="field.datatype">
            <ng-container *ngSwitchCase="'GRID'">
                <span class="fieldAttachmentIcon !text-center">
                    <button [title]="translate('Open Grid View')" (click)="openGrid.emit(field)">
                        <mat-icon class="text-xl !text-center">grid_on</mat-icon>
                    </button>
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="'ATTACHMENT'">
                <span class="d-flex">
                    <span class="fieldAttachmentIcon inline-block w-5 h-5" *transloco="let translate">
                        <button [title]="translate('Attach File')" class="relative right-1" (click)="record.id ? openAttachment.emit(field) : null"
                                [disabled]="!record.id || disabled">
                            <mat-icon class="text-xl">attach_file</mat-icon>
                        </button>
                    </span>
                </span>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="field.hasMasterLookup">
                    <ng-container *ngIf="disabled else showDropdown">
                        <div class="relative">
                            <input [(ngModel)]="field.value" class="relative top-[6px] px-0.5 w-full h-full" [disabled]="disabled">
                            <mat-icon [title]="translate('Master Search')" class="absolute !text-xl !h-[21px] !w-[21px] right-0 top-[12px] transform -translate-y-1/2" aria-hidden="true">blur_linear</mat-icon>
                        </div>
                    </ng-container>
                    <ng-template #showDropdown>
                        <div class="autoCompleteField">
                            <form class="example-form h-30px !important">
                                <mat-form-field class="example-full-width">
                                    <input type="text"
                                           aria-label="Description" matInput [formControl]="autoCompleteField"
                                           [matAutocomplete]="auto" (focus)="getMasterData()" [(ngModel)]="field.value" (ngModelChange)="masterValueChanged()" (mousedown)="getMasterData()" (keydown)="toggleKeyboardEvent($event)" #autoCompleteInput>
                                    <mat-icon [title]="translate('Master Search')" class="absolute !text-xl !h-[21px] !w-[21px] right-0 top-[12px] transform -translate-y-1/2" aria-hidden="true" (click)="autoCompleteInput.focus()">blur_linear</mat-icon>
                                    <mat-icon class="relative top-[10px] font-semibold" matSuffix (click)="autoCompleteInput.focus()">keyboard_arrow_down</mat-icon>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <ng-container>
                                            <mat-option [value]="''">- Select -</mat-option>
                                            <mat-option *ngFor="let option of masterOptions; trackBy: trackOption" [value]="option">{{option}}</mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="!field.hasMasterLookup">
                    <ng-container [ngSwitch]="field.datatype">
                        <ng-container *ngSwitchCase="'BOOLEAN'">
                            <select class="w-full h-full bg-white px-0.5" [disabled]="disabled" [(ngModel)]="field.value">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span class="text-right" *transloco="let translate">
                                <input [(ngModel)]="field.value" class="px-0.5 w-full h-full" [disabled]="disabled">
                            </span>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </span>
    <span *ngSwitchCase="'TEXTAREA'">
        <span class="fieldAttachmentIcon w-full inline-flex justify-end" *transloco="let translate">
            <button *ngIf="eventType !== 'view'" [title]="translate('Edit')" class="relative" (click)="$event.stopPropagation();$event.preventDefault(); editTextArea.emit(field)">
                <mat-icon class="text-xl">edit</mat-icon>
            </button>
        </span>
        <div class="-mt-2 angular-editor-textarea plain-text" [innerHTML]="decodeTextFieldValue(field.value)"></div>
    </span>
    <span *ngSwitchCase="'DROPDOWN'">
        <div class="relative !top-[5px]">
            <ng-container *ngIf="disabled">
                {{field.value}}
            </ng-container>
        </div>
        <ng-container *ngIf="!disabled">
            <select [name]="field.id" [disabled]="disabled" class="w-full h-full bg-white px-0.5" [(ngModel)]="field.value" (ngModelChange)="lookupValueChanged()" (mousedown)="getLookupData()" (keydown)="toggleKeyboardEvent($event)">
                <option [value]="undefined">- Select-</option>
                <option [value]="option.value" *ngFor="let option of field?.lookupValues?.displayVsValue
                " [selected]="option.value === field.value">{{option.key}}</option>
            </select>
        </ng-container>
    </span>
    <span *ngSwitchCase="'DATE'">
        <material-input-date [formFieldClass]="'datefield'" [disabled]="disabled" [title]="field.outputFormat" [model]="field.value" [errors]="[]" [debounceAfter]="1000" [dateFormat]="field.outputFormat" [inputOnlyDisabled]="true" (modelChange)="field.value = $event;">
        </material-input-date>
    </span>
    <span *ngSwitchCase="'PASSWORD'">
        <input [(ngModel)]="field.value" [type]="showPassword ? 'text' : 'password'" class="px-0.5 w-full h-full" [disabled]="disabled">
        <span class="material-symbols-outlined postFixIcon" [hidden]="!field.value || $any(field.value).length === 0 || !showPassword" (click)="showPassword = !showPassword" [matTooltip]="translate('Hide')">
            visibility
        </span>
        <span class="material-symbols-outlined postFixIcon" [hidden]="!field.value || $any(field.value).length === 0 || showPassword" [matTooltip]="translate('Show')" (click)="showPassword = !showPassword">
            visibility_off
        </span>
    </span>
    <span *ngSwitchCase="'RADIOBUTTON'">
        <ng-container *ngIf="disabled">
            <div class="leading-[27px]">
                {{field.value}}
            </div>
        </ng-container>
        <ng-container *ngIf="!disabled">
            <select class="w-full h-full bg-white px-0.5" [(ngModel)]="field.value">
                <option [value]="undefined">- Select -</option>
                <option *ngFor="let option of field?.lookupValues?.displayVsValue" [value]="option.value">{{option.key}}</option>
            </select>
        </ng-container>
    </span>
    <span *ngSwitchCase="'CHECKBOX'">
        <ng-container *ngTemplateOutlet="checkbox"></ng-container>
    </span>
    <span *ngSwitchCase="'ATTACHMENT'">
        <span class="fieldAttachmentIcon inline-block w-5 h-5" *transloco="let translate">
            <button [title]="translate('Attach File')" class="relative right-2" (click)="record.id ? openAttachment.emit(field) : null" [disabled]="!record.id  || disabled">
                <mat-icon class="text-xl">attach_file</mat-icon>
            </button>
        </span>
        <ng-container *ngFor="let file of attachmentfileNames.slice(0,1); let i = index;">
            <span class="fieldFileName attachmentFiles inline-block max-w-200 whitespace-nowrap overflow-hidden text-ellipsis h-5 leading-5" [title]="file">
                <ng-container [ngSwitch]="file.indexOf('http://') > (-1) || file.indexOf('https://') > (-1)">
                    <ng-container *ngSwitchDefault>
                        <a class="text-taxillaGreen cursor-pointer top-1 relative" [href]="attachmentUrl + field.value" target="_blank">{{ file.substring(file.lastIndexOf('/') + 1) }}</a>
                    </ng-container>
                </ng-container>
            </span>
        </ng-container>
    </span>
    <span *ngSwitchCase="'GRID'">
        <span class="fieldAttachmentIcon" *transloco="let translate">
            <button [title]="translate('Open Grid View')" (click)="openGrid.emit(field)">
                <mat-icon class="attachment">grid_on</mat-icon>
            </button>
        </span>
    </span>
    <span *ngSwitchDefault>
        {{field?.uiTypeMetadata}}
    </span>
</div>

<ng-template #checkbox>
    <div *transloco="let translate" class="materialGroupCheckboxContainer">
        <button mat-button [matMenuTriggerFor]="menu" [disabled]="disabled" class="checkboxButton !border-0" [title]="selectedOptions?.join(', ')">
            <span class="btnDisplayText groupCheckboxSelectedText">
                <ng-container *ngIf="selectedOptions?.length > 0">
                    {{selectedOptions?.join(', ')}}
                </ng-container>
                <ng-container *ngIf="!(selectedOptions?.length > 0)">
                    {{ field.displayName }}
                </ng-container>
            </span>
            <span class="material-icons dropdowns defaultDropdownIcon"> arrow_drop_down </span>
            <span class="material-icons dropdowns fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
        </button>
        <mat-menu #menu="matMenu" class="groupCheckboxContainer" xPosition="before">
            <div class="groupCheckboxListContainer position-relative" style="max-height: 45vh;">
                <mat-option>
                    - Select -
                </mat-option>
                <div class="checkBoxContainer" *ngFor="let option of lookupOptions" stop-propagation>
                    <material-checkbox [name]="option.key" [model]="option.selected" [showName]="true" (modelChange)="option.selected = $event; updateSelection()" [title]="option.key"> </material-checkbox>
                </div>
            </div>
        </mat-menu>
    </div>
</ng-template>
