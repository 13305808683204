<div id="signupPageContainer">
    <div class="signupContainer" *transloco="let translate;">
        <mat-card class="signupCard">
            <div class="row">
                <div class="signupDetailsContainer col-7">
                    <mat-card-title class="text-center">
                        {{translate('signUp')}}
                    </mat-card-title>
                    <div class="signupDetailsContainer">
                        <div class="signupForm">
                            <ng-container *ngTemplateOutlet="invitationId ? partnerTemplate : selfTemplate"></ng-container>
                        </div>
                        <div class="signupTerms col">
                            <material-checkbox [name]="'Terms & Conditions'" [id]="'terms&Conditions'" [model]="user.accepted" (modelChange)="user.accepted = $event; termsValidationMsg = false">
                            </material-checkbox>
                            <span>
                                {{translate('I agree on')}} <span class="btn-link" (click)="showTermsAndConditions()">{{translate('Terms & Conditions')}}</span>
                            </span>
                            <div class="validateOptionsDiv" *ngIf="termsValidationMsg">
                                <span>{{translate('Please accept Terms and Conditions')}}</span>
                            </div>
                        </div>
                        <div class="partnerTerms col" [hidden]="canHidePartnerTerms()">
                            <material-checkbox [name]="'Partner Terms'" [id]="'partnerTerms'" [model]="organization.partnerTerms" (modelChange)="organization.partnerTerms = $event; partnerTermsValidationMsg = false">
                            </material-checkbox>
                            <span>
                                {{translate('I agree on')}} <span class="btn-link" (click)="showPartnerTermsAndConditions()">{{translate('Partnership Terms & Conditions')}}</span>
                            </span>
                            <div class="validateOptionsDiv" *ngIf="partnerTermsValidationMsg">
                                <span>{{translate('Please accept Partnership Terms and Conditions')}}</span>
                            </div>
                        </div>
                        <div class="row signupCaptcha col" *ngIf="!invitationId">
                            <label class="col-6 signupDetailLabel signupCaptchaLabel">
                                <ng-container *ngIf="captchaUrl?.length > 0">
                                    <div class="signupCaptchaImg">
                                        <img src="data:image/jpeg;base64,{{captchaUrl}}" class="" alt="captcha">
                                    </div>
                                    <mat-icon class="material-icons cursorPointer" [title]="translate('Refresh Captcha')" (click)="getCaptcha()">refresh</mat-icon>
                                </ng-container>
                            </label>
                            <div class="signupDetailValue col-6 signupCaptchaInput">
                                <material-input [autofocus]="false" [placeholder]="translate('Captcha')" [required]="true" [errors]="errors.captcha" [model]="captcha" [classList]="[]" [debounceAfter]="1000" (modelChange)="captcha = $event" (debounceEventHandler)="checkCaptcha()">
                                </material-input>
                            </div>
                        </div>
                        <div class="submitSignup text-center col">
                            <material-button [label]="translate('signUp')" (onTrigger)="signup()"></material-button>
                        </div>
                        <div class="signupRedirectToLogin">
                            <span>{{translate('Already have an account')}}? <span class="btn-link" [routerLink]="'/auth'">{{translate('signIn')}}.</span></span>
                        </div>
                    </div>
                </div>
                <div class="col-5 signupInfoContainer">
                    <div class="signupInfoSubContainer">
                        <div class="signupSubInfoContainer">
                            <div class="signupSubInfoLogo">
                                <img src="assets/images/{{R.LOGO_HREF}}logo.png" alt="logo">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<ng-template #selfTemplate>
    <ng-container *transloco="let translate;">
        <div class="row">
            <div class="col-6">
                <material-input [autofocus]="true" [placeholder]="translate('Organization Name')" [errors]="errors.organizationName" [model]="organization.name" [classList]="[]" [debounceAfter]="1000" [required]="true" (modelChange)="organization.name = $event" [maxLength]="100" (debounceEventHandler)="checkOrganizationName()">
                </material-input>
            </div>
            <div class="col-6">
                <material-select [required]="true" [options]="providerOrganizations" [errors]="errors.providerOrganizations" [optionDisplayNameProperty]="'name'" [optionValueProperty]="'id'" [placeholder]="translate('Provider Organization')" [model]="organization.providerOrganizationId" (modelChange)="organization.providerOrganizationId = $event">
                </material-select>
            </div>
            <div class="col-6 mt-1" *ngIf="organization.organizationType != 'ksa-wrapper'">
                <mat-form-field appRemoveAriaOwns>
                    <mat-select [placeholder]="translate('Organization Type')" [(ngModel)]="organization.organizationType" (ngModelChange)="organization.organizationType = $event">
                        <mat-option [value]=""><em>--{{translate('Please select')}}--</em></mat-option>
                        <ng-container *ngFor="let organization of organizationTypes">
                            <mat-option [value]="organization.value">
                                {{organization.displayName}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('Doing Business As')" [hint]="translate('Abbreviated Company Name')" [errors]="errors.orgBusiness" [model]="organization.orgBusiness" [classList]="[]" [debounceAfter]="1000" (modelChange)="organization.orgBusiness = $event" [maxLength]="100" (debounceEventHandler)="checkOrganizationBusiness()">
                </material-input>
            </div>
        </div>
        <div class="row flex-wrap">
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('First Name')" [errors]="errors.firstName" [model]="user.firstName" [classList]="[]" [debounceAfter]="1000" [required]="true" (modelChange)="user.firstName = $event" (debounceEventHandler)="checkFirstName()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('Last Name')" [errors]="errors.lastName" [model]="user.lastName" [classList]="[]" [debounceAfter]="1000" [required]="true" (modelChange)="user.lastName = $event" (debounceEventHandler)="checkLastName()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('email')" [errors]="errors.email" [model]="user.email" [required]="true" [classList]="[]" [debounceAfter]="1000" (modelChange)="user.email = $event" (debounceEventHandler)="checkUserEmail()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('userId')" [errors]="errors.userId" [model]="user.userId" [required]="true" [classList]="[]" [debounceAfter]="1000" (debounceEventHandler)="checkUserId()" (modelChange)="user.userId = $event">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('Designation')" [errors]="errors.designation" [model]="user.designation" [classList]="[]" [debounceAfter]="1000" [maxLength]="50" (modelChange)="user.designation = $event" (debounceEventHandler)="checkDesignation()">
                </material-input>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #partnerTemplate>
    <ng-container *transloco="let translate;">
        <div class="row">
            <div class="col-6">
                <material-input [autofocus]="true" [placeholder]="translate('Organization Name')" [errors]="errors.organizationName" [model]="organization.name" [classList]="[]" [debounceAfter]="1000" [required]="true" (modelChange)="organization.name = $event" [maxLength]="100" (debounceEventHandler)="checkOrganizationName()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('Doing Business As')" [hint]="translate('Abbreviated Company Name')" [errors]="errors.orgBusiness" [model]="organization.orgBusiness" [classList]="[]" [debounceAfter]="1000" (modelChange)="organization.orgBusiness = $event" [maxLength]="100" (debounceEventHandler)="checkOrganizationBusiness()">
                </material-input>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('First Name')" [errors]="errors.firstName" [model]="user.firstName" [classList]="[]" [debounceAfter]="1000" [required]="true" (modelChange)="user.firstName = $event" (debounceEventHandler)="checkFirstName()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('Last Name')" [errors]="errors.lastName" [model]="user.lastName" [classList]="[]" [debounceAfter]="1000" [required]="true" (modelChange)="user.lastName = $event" (debounceEventHandler)="checkLastName()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('email')" [errors]="errors.email" [model]="user.email" [required]="true" [classList]="[]" [debounceAfter]="1000" (modelChange)="user.email = $event" [disabled]="true" (debounceEventHandler)="checkUserEmail()">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('userId')" [errors]="errors.userId" [model]="user.userId" [required]="true" [classList]="[]" [debounceAfter]="1000" (debounceEventHandler)="checkUserId()" (modelChange)="user.userId = $event">
                </material-input>
            </div>
            <div class="col-6">
                <material-input [autofocus]="false" [placeholder]="translate('Designation')" [errors]="errors.designation" [model]="user.designation" [classList]="[]" [debounceAfter]="1000" [maxLength]="50" (modelChange)="user.designation = $event" (debounceEventHandler)="checkDesignation()">
                </material-input>
            </div>
        </div>
    </ng-container>
</ng-template>
