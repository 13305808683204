<ng-container *ngIf="!isMultiple">
    <mat-form-field *transloco="let translate;" [appearance]="appearance" [class.noHints]="noHints" [class.noLabel]="noLabel" appRemoveAriaOwns>
        <mat-select placeholder="{{placeholder}}" [formControl]="control" [errorStateMatcher]="matcher" disableOptionCentering (selectionChange)="errors && errors.splice(0,1);" title="{{control.value}}">
            <mat-select-trigger>
                {{selectedOption?.translate ? translate(optionDisplayNameProperty ? selectedOption?.[optionDisplayNameProperty] : (selectedOption?.displayName !== undefined ? selectedOption?.displayName : selectedOption)) : (optionDisplayNameProperty ? selectedOption?.[optionDisplayNameProperty] : (selectedOption?.displayName !== undefined ? selectedOption?.displayName : selectedOption))}}
            </mat-select-trigger>
            <mat-option *ngIf="(hideSelectIfValueExists !== undefined) ? ((model !== undefined) && !hideSelectIfValueExists) : true">
                <div>
                    <em>--{{translate('Please select')}}--</em>
                </div>
                <div *ngIf="createOptionRequired">
                    <button mat-button [title]="translate('Create New')" mat-icon-button (click)="$event.stopPropagation(); $event.preventDefault(); createOptionCallBack.emit()">
                        <mat-icon class="mr-0 addIconColor">add_circle</mat-icon>
                    </button>
                </div>
            </mat-option>
            <ng-container *ngIf="options && options.length > 0">
                <div class="selectSearchFilter addToIgnoreList" *ngIf="!isSearchNotRequired">
                    <mat-form-field>
                        <input matInput class="inputSearchField addToIgnoreList" autocomplete="off" placeholder="{{translate('Search')}}" [(ngModel)]="searchColumns" (keydown)="$event.stopPropagation()" />
                        <button mat-button *ngIf="searchColumns" class="selectCloseIcon addToIgnoreList" matSuffix mat-icon-button aria-label="Clear" (click)='$event.stopPropagation();searchColumns ="";' (keydown)="$event.stopPropagation()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <mat-option *ngFor="let option of options | FilterPipe: (optionValueProperty || 'value') : searchColumns : null : (optionDisplayNameProperty || 'displayName')" [value]="(optionValueProperty !== undefined) ? option[optionValueProperty] : (option.value !== undefined ? option.value : option)" (click)="sendOptionsData(option); selectedOption = option" title="{{optionDisplayNameProperty ? option[optionDisplayNameProperty] : option.displayName}}">
                    <div>
                        {{option.translate ? translate(optionDisplayNameProperty ? option[optionDisplayNameProperty] : (option.displayName !== undefined ? option.displayName : option)) : (optionDisplayNameProperty ? option[optionDisplayNameProperty] : (option.displayName !== undefined ? option.displayName : option))}}
                    </div>
                    <div *ngIf="option.actionsRequired">
                        <button class="custom-option-btn" mat-button *ngFor="let action of option.actions" [title]="action.name" mat-icon-button (click)='$event.stopPropagation(); $event.preventDefault(); editOptionCallBack.emit(option)'>
                            <mat-icon class="mr-0 text-xl">{{action.icon}}</mat-icon>
                        </button>
                    </div>
                </mat-option>
                <mat-option *ngIf="(options | FilterPipe: (optionValueProperty || 'value') : searchColumns : null : (optionDisplayNameProperty || 'displayName')).length === 0">
                    {{translate('No results found')}}
                </mat-option>
                <!-- <mat-option class="create-new" *ngIf="createOptionRequired" (click)="$event.stopPropagation(); $event.preventDefault(); createOptionCallBack.emit()">
                    <material-button class="flex justify-around" [label]="translate('Create New')">
                    </material-button>
                </mat-option> -->
            </ng-container>
        </mat-select>
        <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
            <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}">
                info
            </span>
        </button>
        <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
            <strong>
                <span>* </span>{{translate('required')}}</strong>
        </mat-hint>
        <mat-error *ngIf="control.hasError('errors')" title="{{errors}}">
            {{errors}}
        </mat-error>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="isMultiple">
    <mat-form-field *transloco="let translate;" appRemoveAriaOwns>
        <mat-select multiple placeholder="{{placeholder}}" [formControl]="control" [errorStateMatcher]="matcher" disableOptionCentering (selectionChange)="errors && errors.splice(0,1);" title="{{control.value}}">
            <ng-container *ngIf="options && options.length > 0">
                <div class="selectSearchFilter addToIgnoreList" *ngIf="!isSearchNotRequired">
                    <mat-form-field>
                        <input matInput class="inputSearchField addToIgnoreList" autocomplete="off" placeholder="Search" [(ngModel)]="searchColumns" (keydown)="$event.stopPropagation()" />
                        <button mat-button *ngIf="searchColumns" class="selectCloseIcon addToIgnoreList" matSuffix mat-icon-button aria-label="Clear" (click)='$event.stopPropagation();searchColumns ="";' (keydown)="$event.stopPropagation()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <mat-option *ngFor="let option of options | FilterPipe: 'value' : searchColumns : null : (optionDisplayNameProperty || 'displayName')" [value]="(optionValueProperty !== undefined) ? option[optionValueProperty] : (option.value !== undefined ? option.value : option)" (click)="sendOptionsData(option)" title="{{optionDisplayNameProperty ? option[optionDisplayNameProperty] : option.displayName}}">
                    {{optionDisplayNameProperty ? option[optionDisplayNameProperty] : (option.displayName !== undefined ? option.displayName : option)}}
                </mat-option>
            </ng-container>
        </mat-select>
        <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
            <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}">
                info
            </span>
        </button>
        <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
            <strong>
                <span>* </span>{{translate('required')}}</strong>
        </mat-hint>
        <mat-error *ngIf="control.hasError('errors')" title="{{errors}}">
            {{errors}}
        </mat-error>
    </mat-form-field>
</ng-container>
