import { createAction, props } from '@ngrx/store';

import { Organization } from '../../models/organization.class';
import { User } from '../../models/user.class';
import { SessionState } from '../states';

export enum SessionActionTypes {
    GET_SESSION_DETAILS = '[SESSION] Get Details',
    SET_SESSION_DETAILS = '[SESSION] Set Details',
    LOGOUT = '[SESSION] Session Logout',
    LOGOUT_SUCCESSFULL = '[SESSION] Session Logout successful',
    GET_APPLICATION_MISC_PROPERTIES = '[SESSION] Get Application Misc Properties',
    SET_APPLICATION_MISC_PROPERTIES = '[SESSION] Set Application Misc Properties',
    CLEAR_APPLICATION_MISC_PROPERTIES = '[SESSION] Clear Application Misc Properties',
    SET_CURRENT_ORGANIZATION = '[SESSION] Set Current Organization',
    SET_LOGGED_IN_ORGANIZATION = '[SESSION] Set Logged in Organization',
    SET_ACTIVE_LANGUAGE = '[SESSION] Set Active Language',
    SELECTED_ORGANIZATION = '[SESSION] Selected Organization',
    GET_WORK_Q_COUNT = '[SESSION] Get WorkQ Count',
    SET_WORK_Q_COUNT = '[SESSION] Set WorkQ Count',
    GET_WORK_Q_MESSAGES = '[SESSION] Get WorkQ Messages',
    SET_WORK_Q_MESSAGES = '[SESSION] Set WorkQ Messages',
    WORK_Q_MESSAGE_MARK_AS_READ = '[SESSION] WorkQ Message Mark As Read',
    SET_WORK_Q_MESSAGE_AS_READ = '[SESSION] Set WorkQ Message As Read',
    CHECK_ORGANIZATION_EXISTS = '[LOCATIONS] Check Organization Exists',
    CAN_SWITCH_TO_ORGANIZATION = '[LOCATIONS] Can Switch To Organization',
    CANNOT_SWITCH_TO_ORGANIZATION = '[LOCATIONS] Cannot Switch To Organization',
    SWITCH_TO_ORGANIZATION = '[LOCATIONS] Switch To Organization',
    COMPLETED_SWITCH_TO_ORGANIZATION = '[LOCATIONS] Completed Switch To Organization',
    REDIRECTION_FAILURE = '[SESSION] Redirection Failure',
    SET_PROVIDER_ADMIN = '[PROVIDER ADMIN] SET Provider Admin',
}

export const SessionLogout = createAction(SessionActionTypes.LOGOUT);

export const SessionLogoutSuccessfull = createAction(SessionActionTypes.LOGOUT_SUCCESSFULL);

export const GetApplicationMiscProperties = createAction(SessionActionTypes.GET_APPLICATION_MISC_PROPERTIES);

export const ClearApplicationMiscProperties = createAction(SessionActionTypes.CLEAR_APPLICATION_MISC_PROPERTIES);

export const SetApplicationMiscProperties = createAction(
    SessionActionTypes.SET_APPLICATION_MISC_PROPERTIES,
    props<SessionState['applicationMiscProperties']>()
);

export const SetCurrentOrganization = createAction(SessionActionTypes.SET_CURRENT_ORGANIZATION, props<{ organization: Organization }>());

export const SetLoggedInOrganization = createAction(SessionActionTypes.SET_LOGGED_IN_ORGANIZATION, props<{ organization: Organization }>());

export const SetActiveLanguage = createAction(
    SessionActionTypes.SET_ACTIVE_LANGUAGE,
    props<{ language: SessionState['activeLanguage'] }>()
);

export const GetSessionDetails = createAction(
    SessionActionTypes.GET_SESSION_DETAILS,
    props<{
        noAlerts?: boolean;
    }>()
);

export const SetSessionDetails = createAction(
    SessionActionTypes.SET_SESSION_DETAILS,
    props<{
        currentOrg: Organization;
        loggedInOrg: Organization;
        user: User;
        lastLogin: any;
        isAdmin: boolean;
        passwordExpiresInDays: number;
        timeZoneOffset?: number;
    }>()
);

export const GetWorkQCount = createAction(SessionActionTypes.GET_WORK_Q_COUNT);

export const setWorkQCount = createAction(
    SessionActionTypes.SET_WORK_Q_COUNT,
    props<{
        count: number;
    }>()
);

export const SetProviderAdmin = createAction(
    SessionActionTypes.SET_PROVIDER_ADMIN,
    props<{
        providerAdmin: boolean;
    }>()
);
export const getWorkQMessages = createAction(
    SessionActionTypes.GET_WORK_Q_MESSAGES,
    props<{
        messageType: string;
        fetchSize: number;
        nextPagingState: any;
    }>()
);

export const setWorkQMessages = createAction(
    SessionActionTypes.SET_WORK_Q_MESSAGES,
    props<{
        messages: any[];
    }>()
);

export const workQMessageMarkAsRead = createAction(
    SessionActionTypes.WORK_Q_MESSAGE_MARK_AS_READ,
    props<{
        messageId: string;
        noAlerts: boolean;
    }>()
);

export const setWorkQMessageAsRead = createAction(SessionActionTypes.SET_WORK_Q_MESSAGE_AS_READ);

export const CanSwitchToOrganization = createAction(
    SessionActionTypes.CAN_SWITCH_TO_ORGANIZATION,
    props<{
        organizationId: string;
    }>()
);

export const CheckOrganizationExists = createAction(
    SessionActionTypes.CHECK_ORGANIZATION_EXISTS,
    props<{
        organizationId: string;
    }>()
);

export const CannotSwitchToOrganization = createAction(
    SessionActionTypes.CANNOT_SWITCH_TO_ORGANIZATION,
    props<{
        failed: boolean;
    }>()
);

export const SwitchToOrganization = createAction(
    SessionActionTypes.SWITCH_TO_ORGANIZATION,
    props<{
        organizationId: string;
    }>()
);

export const CompletedSwitchToOrganization = createAction(
    SessionActionTypes.COMPLETED_SWITCH_TO_ORGANIZATION,
    props<{
        organization: Organization;
        switched: boolean;
    }>()
);

export const RedirectFailure = createAction(SessionActionTypes.REDIRECTION_FAILURE);
