import { Injectable } from '@angular/core';

import { ReportResponseInterface } from '../../models/reportsreponse.interface';
import { BridgeService } from '../bridge/bridge.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    constructor(private _bridge: BridgeService, private _utils: UtilsService, private _store: StoreService) {}

    /**
     * Method to get Report Processes
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    getReportProcesses = (
        data: {
            serviceId: string;
            reportName?: string;
            size?: number;
            requestState?: string;
            previousPagingState?: string;
            noAlerts?: boolean;
            restApiName?: string;
            timeRange?: any[];
        },
        callbacks: {
            successCallback: (response: ReportResponseInterface[], pagingState: string, chainNameVsChainDisplayName: any) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getReportProcesses(
            data,
            (res) => {
                if (data.serviceId && !data.restApiName) {
                    callbacks.successCallback(
                        res?.response?.requests?.assetRequests || [],
                        res?.response?.requests?.nextPagingState,
                        res?.response?.chainNameVsChainDisplayName
                    );
                } else if (data.restApiName) {
                    //Removed {} as an alternate value from here.
                    callbacks.successCallback(
                        res?.requests?.assetRequests || [],
                        res?.requests?.nextPagingState,
                        res && res.chainNameVsChainDisplayName
                    );
                }
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                } else {
                    this._utils.alertError(response?.msg);
                }
            }
        );
    };

    getReportRequest = (
        data: {
            serviceId: string;
            reportName?: string;
            size?: number;
            requestState?: string;
            previousPagingState?: string;
            fetchFileDetails?: boolean;
            restApiName?: string;
        },
        callbacks: {
            successCallback: (response: ReportResponseInterface[]) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getReportRequest(
            data,
            (res) => {
                if (data.serviceId && !data.restApiName) {
                    callbacks.successCallback(res?.response?.requests || {});
                } else if (data.restApiName) {
                    callbacks.successCallback(res?.requests || {});
                }
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                } else {
                    this._utils.alertError(response && response.msg);
                }
            }
        );
    };

    /**
     * Method to get transformation chain names
     */
    generateErrorReport = (
        data,
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.generateErrorReport(
            data,
            (res) => {
                callbacks.successCallback(res?.response || []);
            },
            (res) => {
                const msg = res?.msg || 'Failed to get instances';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback({ msg: msg });
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    /**
     * Method to get asset filing attributes
     * @param data Contains bridge data, service data,
     * @param callbacks Contains success callback method,
     */
    getFilingAttributes = (
        data: { requestId?: string; serviceId: string; noAlerts?: boolean },
        callbacks: {
            successCallback?: (
                response: {
                    captureOnce: boolean;
                    dataType: string;
                    entityName: string;
                    fullId: string;
                    isBusinessKey: boolean;
                    isPrecaptured: boolean;
                    keyId: string;
                    label: string;
                    value: string;
                }[]
            ) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getReportAttributes(
            data,
            (res) => {
                callbacks.successCallback(res?.response?.filingAttributeValues || []);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.msg || 'Failed to get filing data');
                }
            }
        );
    };

    getReconciliationReports = async (
        restApiName: string
    ): Promise<
        {
            displayName: string;
            name: string;
            repository: string;
            webEndpoint: string;
            failureReason?: string;
            reportURL?: string;
            fileName?: string;
        }[]
    > => {
        const response = await this._store.privateScope.fetchValues(
            () => {
                const promise = this._bridge.getReconciliationReports(restApiName);
                promise.catch((e) => {
                    this._utils.alertError(e?.msg || 'Failed to get reports');
                });
                return promise;
            },
            'reconciliationReports',
            restApiName
        );
        return response?.['reconciliationReports']?.[restApiName];
    };

    getGeneratedReconciliationReports = (restApiName: string, requestId: string) => {
        const promise = this._bridge.getGeneratedReconciliationReports(restApiName, requestId);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get generated reports');
        });
        return promise;
    };

    printFile = (url: string) => {
        this._bridge.getFile(
            url,
            (res) => {
                const blob = new Blob([res], {
                    type: 'application/pdf',
                });
                this.takeBlobAction(blob, 'PRINT');
            },
            (res) => {
                this._utils.alertError(res?.msg || 'Failed to get report');
            }
        );
    };

    takeBlobAction = (blob: Blob, actionType: 'PRINT' | 'DOWNLOAD', reportName?: string) => {
        const blobUrl = URL.createObjectURL(blob);
        let childElement: HTMLIFrameElement | HTMLAnchorElement;
        switch (actionType) {
            case 'PRINT':
                const previousElement = document.querySelector('#reportPdf');
                previousElement && document.body.removeChild(previousElement);
                childElement = document.createElement('iframe');
                childElement.style.display = 'none';
                childElement.id = 'reportPdf';
                childElement.src = blobUrl;
                document.body.appendChild(childElement);
                try {
                    childElement.contentWindow.print();
                } catch (e) {
                    (window.navigator as any).msSaveOrOpenBlob(blob);
                }
                break;
            case 'DOWNLOAD':
                childElement = document.querySelector('.downloadFileViaScript') as HTMLAnchorElement;
                if (!childElement) {
                    childElement = document.createElement('a');
                    childElement.setAttribute('class', 'downloadFileViaScript');
                    document.body.appendChild(childElement);
                }
                childElement.href = blobUrl;
                childElement.download = reportName;
                childElement.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(childElement);
                }, 1000);
                break;
        }
    };
}
