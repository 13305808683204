import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { TableCellDataTypesInterface } from '../../interface/tablecelldatatype.interface';
import { Message } from '../../models/record/message.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { UtilsService } from '../../services/utils/utils.service';
import { ValidationsService } from '../../services/validations/validations.service';

@Component({
    selector: 'app-entity-record-field-grid',
    templateUrl: './entity-record-field-grid.component.html',
    styleUrls: ['./entity-record-field-grid.component.scss'],
})
export class EntityRecordFieldGridComponent implements OnInit, OnDestroy {
    @Input() public fieldId: string;
    @Input() public record?: Record;
    @Input() public recordUpdateDisabled?: boolean;
    @Input() public hideDisplayNameData?: boolean;
    @Input() public eventType?: string;

    private recordId?: string;
    public allColumns: TableColumn[] = [];
    public selectedColumns: string[] = [];
    public tableData: TableData[] = [];
    public errorField?: RecordField;
    public cellDataTypes?: TableCellDataTypesInterface;
    public readOnly: boolean = false;
    public disabled?: boolean;
    public field: RecordField;

    constructor(private _validations: ValidationsService, private _utils: UtilsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.recordId?.currentValue || changes?.eventType?.currentValue) {
            this.applyFieldConfigs(this.eventType);
        }
    }

    public save = () => {
        const field: RecordField = this.errorField || this.field;
        const data: TableData[] = this.tableData;
        const constraints = field.gridConstraints;
        const cellsMetaData = constraints.cellDetails;
        if (!field.gridData) {
            field.gridData = {
                cells: [],
                hasCellErros: false,
                id: field.id,
                message: new Message(undefined),
                modified: true,
            };
        }
        cellsMetaData.forEach((cellData) => {
            const rowIndex = cellData.rowIndex;
            const columnIndex = cellData.columnIndex;
            const record = data.find((tableRecord, index) => index === rowIndex);
            let cellValue = (record as any) && record[columnIndex];
            const gridCell = field.gridData.cells.find(
                (cell) => cell.columnIndex === cellData.columnIndex && cell.rowIndex === cellData.rowIndex
            );
            if (gridCell) {
                gridCell.errors = [];
                gridCell.warnings = [];
                gridCell.modified = true;
                gridCell.value = cellValue as any;
            } else {
                field.gridData.cells.push({
                    columnIndex: cellData.columnIndex,
                    errors: [],
                    warnings: [],
                    modified: true,
                    rowIndex: cellData.rowIndex,
                    value: cellValue as any,
                });
            }
        });
        const hasErrors = this._validations.validateGrid(field);
        if (hasErrors) {
            this.errorField = field;
            this.buildTableData();
            return this._utils.alertError(translate('Fix issues to proceed'));
        } else {
            this.field.gridData = field.gridData;
        }
    };

    private applyFieldConfigs = (event) => {
        this.disabled =
            event === 'view' ||
            this.field?.autoCalculate ||
            this.field?.disabled ||
            (this.recordId && this.field?.isBusinessKey) ||
            this.field?.idGenerator !== undefined;
    };

    private buildSupportedDataTypes = () => {
        const constraints = this.field?.gridConstraints;
        this.cellDataTypes = {};
        constraints.cellDetails.forEach((cell) => {
            if (!this.cellDataTypes[cell.rowIndex]) {
                this.cellDataTypes[cell.rowIndex] = {};
            }
            this.cellDataTypes[cell.rowIndex][cell.columnIndex] = {
                inputType: cell.datatype,
                outputFormat: cell.outputFormat,
                lookupInformationMetadata: cell.lookupInformationMetadata,
                entityUid: this.field?.entityUid,
                fieldId: this.field?.id,
                readOnly: this.recordId && cell.readOnly,
            };
        });
    };

    private buildColumns = () => {
        this.selectedColumns = [];
        const grid = this.field?.gridConstraints;
        const columns: TableColumn[] = [];
        columns.push({
            hide: false,
            icon: undefined,
            id: 'firstRowColumn',
            name: translate('Rows'),
            type: 'text',
            hideInAllColumnsList: false,
            options: undefined,
            showCheckboxInHeader: false,
        });
        grid?.columnDetails.forEach((column) => {
            columns.push({
                hide: false,
                icon: undefined,
                id: column.index.toString(),
                name: column.name,
                type: this.readOnly ? 'text' : 'input',
                inputType: column.datatype,
                outputFormat: column.outputFormat,
            });
        });
        columns.forEach((column) => this.selectedColumns.push(column.id));
        this.allColumns = columns;
    };

    public buildTableData = () => {
        const field: RecordField = this.errorField || this.field;
        const rows = field.gridConstraints.rowDetails;
        const data: TableData[] = [];
        rows.forEach((row) => {
            data.push({
                recordId: row.index as any,
                disabled: false,
                firstRowColumn: row.name,
                errors: undefined,
                rowIndex: row.index,
            });
        });
        const cells = field.gridConstraints.cellDetails;
        if (!field.gridData) {
            field.gridData = {
                cells: [],
                hasCellErros: false,
                id: field.id,
                message: new Message(undefined),
                modified: true,
            };
        }
        cells.forEach((cellData) => {
            const gridCell = field.gridData.cells.find(
                (cell) => cell.columnIndex === cellData.columnIndex && cell.rowIndex === cellData.rowIndex
            );
            const row = data.find((record) => record.recordId === (cellData.rowIndex as any));
            if (!gridCell) {
                field.gridData.cells.push({
                    columnIndex: cellData.columnIndex,
                    errors: [],
                    warnings: [],
                    modified: true,
                    rowIndex: cellData?.rowIndex,
                    value: row?.[cellData.columnIndex] as any,
                });
            }
            if (!row.errors) {
                row.errors = {};
            }
            if (!row.errors[cellData.columnIndex]) {
                row.errors[cellData.columnIndex] = new Message(undefined);
            }
            row[cellData.columnIndex] = cellData.defaultValue;
        });
        this.tableData = data;
    };

    ngOnInit(): void {
        this.field = this.record.fields.find((fieldObject) => fieldObject.id === this.fieldId);
        this.buildColumns();
        this.buildTableData();
    }

    ngOnDestroy(): void {}
}
