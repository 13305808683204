import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pascalToCamel',
    pure: true,
})
export class PascalToCamelPipe implements PipeTransform {
    transform(value: any, ...args): any {
        if (args?.[0]) {
            for (let i = 0; i < args[0].length; i++) {
                if (args[0][i].name === value) {
                    let pascalCaseString = args[0][i].displayName;
                    return pascalCaseString;
                }
            }
        } else {
            let cameCamelCaseString = value?.replace(/([a-z])([A-Z])/g, '$1 $2');
            cameCamelCaseString = cameCamelCaseString?.charAt(0).toUpperCase() + cameCamelCaseString?.slice(1);
            return cameCamelCaseString;
        }
    }
}
