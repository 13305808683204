import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-time-filters',
    templateUrl: './time-filters.component.html',
    styleUrls: ['./time-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFiltersComponent implements OnInit {
    @Input() selectedOutlookFilter: any;

    @Input() timeFilter: any;

    darkTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#00a651',
        },
        dial: {
            dialBackgroundColor: '#00a651',
        },
        clockFace: {
            clockHandColor: '#00a651',
            clockFaceTimeInactiveColor: '#00a651',
        },
    };
    isIE: boolean;

    constructor(private _libUtils: UtilsService) {}

    private isIEBrowser = (): void => {
        this.isIE = this._libUtils.isIEBrowser();
    };

    ngOnInit() {
        this.isIEBrowser();
    }
}
