<div *transloco="let translate;">
    <div class="processAppsMainContainer">
        <div class="processAppsSearchContainer">
            <div class="cssmarqueeCntr" *ngIf="showMarquee && marqueeText && !hideMarqueeMsg">
                <span class="material-icons closeImpMsg" matTooltip="Close" (click)="hideMarqueeMsg = true;">close</span>
                <div class="cssmarquee">
                    <h1 title="{{marqueeText}}">{{marqueeText}} </h1>
                </div>
            </div>
            <div class="platformsListContainer">
                <ng-container *ngFor="let ui of uiList">
                    <button matBadge="BETA" [matBadgeHidden]="!ui.beta" class="platformContainer" (click)="setRoute(ui.routeLink)" *ngIf="ui.show" mat-raised-button [title]="ui.displayName">
                        {{ui.displayName}}
                    </button>
                </ng-container>
            </div>
            <div class="processAppsSearch" [class.processSearch]="isSearch">
                <mat-form-field [floatLabel]='"never"' class="homePageAppSearch">
                    <input matInput class="processAppsSearchInput" autocomplete="off" [placeholder]="((selectedAsset && selectedAsset.length > 0) ||(isTagBasedSearch))? translate('In Search Mode') : translate('Search Apps')" [(ngModel)]="searchText" (keyup)="checkSearchTextEvent($event)" (focus)="searchFocus($event)" (blur)="searchFocus($event)" [disabled]="(selectedAsset && selectedAsset.length > 0) ||(isTagBasedSearch)">
                    <div class="searchAlign clearSpanDiv" (click)="clearSearch()" *ngIf="(searchText && searchText.length > 0) || (selectedAsset && selectedAsset.length > 0) || (isTagBasedSearch)" [title]="translate('Clear Search')">
                        <mat-icon>close</mat-icon>
                    </div>
                    <div class="searchAlign filterSpanDiv" (click)="onSearchFocus()" stop-propagation>
                        <span>{{translate('Filters')}}</span>
                    </div>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!appsToShow || !appsToShow.length">
            <div>
                <ng-container *ngIf="((loadingCall?.subscribedServices !== false) && (loadingCall?.subscribedServices !== undefined)) || loading">
                    <ng-container *ngFor="let count of [1,2,3]">
                        <div class="homeProcessesAppContainer">
                            <mat-card>
                                <div class="homeAppProcessesTitle mat-card-title isLoading">
                                    <div class="homeAppProcessesTitleText homeAppTitleText loaderBlock isLoading"> </div>
                                    <div class="homeAppProcessesLastModified loaderBlock isLoading servicesLoading">
                                        <span> {{translate('Version')}}: | </span> {{translate('Last published')}}:
                                    </div>
                                </div>
                                <div class="homeAppProcessesContent mat-card-content">
                                    <div class="homeAppProcessMainContainer">
                                        <div class="workflowStagesContainer loaderBlock isLoading"></div>
                                        <div class="workflowStagesContainer loaderBlock isLoading"></div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="(!loadingCall.subscribedServices || loadingCall.subscribedServices === false) && !loading && ((searchText && searchText.length > 0) || (selectedAsset && selectedAsset.length > 0) ||(isTagBasedSearch))">
                    <div class="noRecordMessage">
                        {{translate('There are no apps matching your search')}}.
                    </div>
                </ng-container>
                <ng-container *ngIf="(noApps && (searchText?.length < 1) && !isTagBasedSearch)">
                    <div class="noRecordMessage">
                        {{translate('You have not subscribed to any app')}}.
                    </div>
                </ng-container>
            </div>
        </div>
        <div *ngIf="appsToShow && appsToShow.length">
            <ng-container *ngFor="let app of appsToShow">
                <div class="homeProcessesAppContainer">
                    <mat-card>
                        <div class="homeAppProcessesTitle mat-card-title">
                            <div class="homeAppProcessesTitleText homeAppTitleText font-weight-bold">
                                <app-display-name [serviceId]="app.serviceId"></app-display-name>
                            </div>
                            <div class="homeAppProcessesNewText homeAppNewText" *ngIf="isAppNew(app)">
                                {{translate('New')}}
                            </div>
                            <div class="homeAppProcessesLastModified loaderBlock" [class.isLoading]="app?.loading?.assetMetaData">
                                <span class="displayInlineBlock">{{translate('Version')}}:</span> {{app.assetData && app.assetData.version || ''}} |
                                <span class="displayInlineBlock"> {{translate('Last published')}}:</span>{{getOriginalLastModifiedDate(app)}}
                            </div>
                        </div>
                        <div class="homeAppProcessesContent mat-card-content">
                            <ng-container [ngSwitch]="app.assetType">
                                <ng-container *ngSwitchCase="'BRIDGE_ASSET'">
                                    <package-card [bridge]="app" [newProcessInSameTab]="newProcessInSameTab" [allProcessesInSameTab]="allProcessesInSameTab" [processInSameTab]="processInSameTab" [navigateToAppHomeOnly]="navigateToAppHomeOnly" [allReportsInSameTab]="allReportsInSameTab"></package-card>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div class="homeAppProcessMainContainer" AppData [app]="app" (assetDataEmitter)="app.assetData = $event" (requestEmitter)="app.request = $event" (instanceEmitter)="app.instance = $event" (statusEmitter)="app.status = $event" (workflowStagesEmitter)="app.workflowStages = $event" (filingAttributesEmitter)="app.filingAttributes = $event">
                                        <div class="homeAppProcessContainer">
                                            <app-card-container [app]="app" [loading]="app.loading" [newProcessInSameTab]="newProcessInSameTab" [allProcessesInSameTab]="allProcessesInSameTab" [processInSameTab]="processInSameTab" [navigateToAppHomeOnly]="navigateToAppHomeOnly"></app-card-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-card>
                </div>
            </ng-container>
            <div *ngIf="!isLoadMoreDisable" class="text-center">
                <span (click)="loadMoreApps()" class="cursor-pointer font-bold shadow-md bg-white hover:bg-[#00a651] text-[#00a651] hover:text-white py-2 w-2/4 inline-block text-xs rounded-2xl text-center no-underline">{{translate('Load More Apps')}}</span>
            </div>
        </div>
    </div>
</div>


<!-- Search slider -->
<div class="mainSearchFilters homePageSearchFilter mat-elevation-z8" *transloco="let translate;" [class.filterVisible]="isFilterVisible" stop-propagation>
    <div class="srchHeading">
        <span class="srchHeadingLabel"> {{translate('Filters')}}</span>
        <div class="closeOutlookSearch">
            <span class="material-icons cursorPointer" (click)="closeFilterPopup()">close</span>
        </div>
    </div>
    <div class="tagSearchMainDiv">
        <div class="searchMainDiv">
            <div class="row">
                <div class="col-4">
                    <material-input-autocomplete [classList]="['tagKeyFocus']" [placeholder]="translate('Select Tag Key')" [title]="tagKey" [model]="tagKey" [options]="totalKeys" (modelChange)="tagKey = $event;" (debounceEventHandler)="getTagValuesByKey()" [totalOptions]="keys">
                    </material-input-autocomplete>
                </div>
                <div class="col-4">
                    <material-input-autocomplete [placeholder]="translate('Select Tag Value')" [title]="tagValue" [model]="tagValue" [options]="keyValues" (modelChange)="tagValue = $event;" (debounceEventHandler)="searchServices()" [totalOptions]="values">
                    </material-input-autocomplete>
                </div>
                <div class="col-4">
                    <material-input-autocomplete [placeholder]="translate('Select App')" [title]="selectedAsset" [model]="selectedAsset" [options]="searchedAssets" (modelChange)="selectedAsset = $event" [totalOptions]="assets">
                    </material-input-autocomplete>
                </div>
            </div>
        </div>
        <div class="searchFooterDiv">
            <material-button [label]="translate('Search')" [color]="'primary'" (onTrigger)="applySearch()" [disabled]="(!tagKey || !tagValue) || (tagKey && tagKey.length === 0 && tagValue && tagValue.length === 0)"></material-button>
            <material-button [label]="translate('Clear')" [color]="'default'" (onTrigger)="clearSearch()" class="cancelBtn"></material-button>
        </div>
    </div>
</div>
