import { createReducer, on } from '@ngrx/store';

import { GetWidgetTranslations, SetWidgetTranslations } from '../actions';
import { intialWidgetState } from '../states/widgets.state';

export const WIDGET_REDUCER = createReducer(
    intialWidgetState,
    on(GetWidgetTranslations, (state, action) => {
        return {
            ...state,
        };
    }),
    on(SetWidgetTranslations, (state, action) => {
        return {
            ...state,
            widgetTranslations: {
                ...state.widgetTranslations,
                [action.language]: {
                    ...state.widgetTranslations[action.language],
                    [action.widgetId]: action.translations,
                },
            },
        };
    })
);

export const WIDGET_REDUCER_KEY = 'widget';
