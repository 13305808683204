<span *ngIf="field.show" class="gridLabelContainer">
    <ng-container *ngIf="!hideDisplayNameData">
        <span class="fieldAttachmentLabel requiredAttachment" *transloco="let translate">
            <div class="fieldAttachmentLabelText font-bold !leading-9">
                {{field.displayName}}:
            </div>
            <div class="fieldAttachmentRequiredText" [class.mat-error]="field.message.errors.length > 0 || field.message.warnings.length > 0" [class.mat-has-warning]="field.message.warnings.length > 0">
                <div *ngIf="field.message.errors.length > 0" class="error-message" [title]="field.message.errors?.[0]">
                    <span class="material-symbols-outlined">error</span> <span class="relative bottom-2 left-1">:{{ field.message.errors[0] }}</span>
                </div>
                <div *ngIf="field.message.warnings.length > 0" class="warning-message" [title]="field.message.warnings?.[0]">
                    <span class="material-symbols-outlined">warning</span> <span class="relative bottom-2 left-1">:{{ field.message.warnings[0] }}</span>
                </div>
                <div *ngIf="!(field.message.errors.length > 0 || field.message.warnings.length > 0) && (field.isMandatory || field.isBusinessKey)" class="priority-message" [title]="'Required'">
                    <span class="material-symbols-outlined">priority_high</span>
                </div>
            </div>
        </span>
    </ng-container>
    <div class="!pt-2.5 !pb-4">
        <div *ngIf="allColumns && selectedColumns && tableData" class="gridTable">
            <table aria-describedby="entity grid field" class="table table-bordered border-collapse">
                <thead>
                    <tr>
                        <th scope="col" class="bg-[#f5f5f5] header-row text-center text-sm !leading-7">{{ allColumns[0].name }}</th>
                        <ng-container *ngFor="let column of allColumns let i = index">
                            <ng-container *ngIf="i !== 0">
                                <th scope="col" class=" bg-[#f5f5f5] text-sm !leading-3">{{ column.name }}</th>
                            </ng-container>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableData; let i = index">
                        <td class="bg-[#f5f5f5] font-bold text-sm !leading-7 text-center">{{ row[selectedColumns[0]] }}</td>
                        <ng-container *ngFor="let column of allColumns let j = index">
                            <ng-container *ngIf="j !== 0">
                                <td class="text-sm text-center !leading-7">
                                    <app-entity-record-field-grid-table [eventType]="eventType" [disabled]="disabled" [readOnly]="readOnly" [row]="row" [column]="column" [record]="record" [field]="field"></app-entity-record-field-grid-table>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</span>
