import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { UtilsService } from 'taxilla-library';

import { AssetData } from './assetdata.class';
import { ReconciliationSourceField } from './reconciliation-source-field.class';
import { Search } from './search.class';
import { Transformation } from './transformation';

export class ReconciliationSource {
    id: string;

    name: string;

    fields: ReconciliationSourceField[];

    keyFields: ReconciliationSourceField[];

    selected: UntypedFormControl;

    selectedTransformationName?: string;

    transformationError?: any[];

    uploadError?: boolean;

    transformationSources?: any[];

    selectedRepositoryTransformations?: any;

    selectedTransformation?: Transformation;

    selectedAssetToAssetTrnsName?: string;

    selectedAssetToAssetTransformation?: any;

    assetToAssetTransformationMetaData: AssetData;

    filterTenantName?: string;

    selectAllOrganizations?: boolean;

    selectedTenantId?: string;

    filterSubTenantName?: string;

    tenants?: { [property: string]: boolean };

    search?: Search;

    sourceTableData = new BehaviorSubject([]);

    unitConsiderationEnabled?: boolean;

    transformations: {
        orgName: string;
        transformations: Transformation[];
        repositoryId: string;
    }[];

    assetToAssetTransformations: {
        orgName: string;
        transformations: Transformation[];
        repositoryId: string;
    }[];

    constructor(data: ReconciliationSource, private _utils: UtilsService, serviceId: string) {
        this.id = data?.id;
        this.name = data?.name;
        this.fields = [];
        this.keyFields = [];
        data?.fields?.forEach((field) => this.fields.push(new ReconciliationSourceField(field)));
        data?.keyFields?.forEach((field) => this.keyFields.push(new ReconciliationSourceField(field)));
        this.selected = new UntypedFormControl(0);
        this.transformationSources = [];
        this.tenants = {};
        this.search = new Search(this._utils, serviceId);
        this.search.origin = 'PROCESSES';
        this.search.isSortConfigEmpty = true;
        this.search.needSourceStructure = true;
        this.search.allowRuleset = true;
        this.search.isAddRuleVisible = true;
        this.search.allowCollapse = true;
        this.search.showSearchCriteriaTitle = false;
        this.search.showSortCriteriaTitle = false;
        this.search.buildQuerries();
        this.transformations = data?.transformations;
        this.assetToAssetTransformations = data?.assetToAssetTransformations;
    }
}
