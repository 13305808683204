import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ApiService,
    AuthGetClientDetails,
    ClientDetails,
    CommonUtilsService,
    FetchCaptcha,
    fetchCaptchaSuccessState,
    getClientDetailsFailedState,
    getClientDetailsState,
    UtilsService,
} from 'taxilla-library';

import { LoginService } from '../../services/login.service';
import { RootScopeService } from '../../services/rootscope.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss', '../login/login.component.scss'],
})
export class AuthComponent implements OnInit {
    @ViewChild('drawer', { read: MatDrawer, static: false }) drawer: MatDrawer;

    unsubscribe = new Subject<void>();
    login = {
        userId: '',
        password: '',
        domain: '',
    };
    hideLoginSection = true;
    hideDomain: boolean;
    forgotPswdCaptchaUrl: string;
    forgotUsernameCaptchaUrl: string;
    isForgotPaswd = false;
    isForgotUserId = false;
    securityAuthenticate = false;
    errors = {
        userId: [],
        password: [],
    };
    forgotPaswdErrors = {
        userId: [],
        captcha: [],
    };
    forgot = {
        userId: '',
        captcha: '',
    };
    forgotUsername = {
        orgId: '',
        email: '',
        captcha: '',
    };
    forgotUsernameErrors = {
        orgId: [],
        email: [],
        captcha: [],
    };
    navigateTimeout: any;
    hideAuthForm = true;
    language: string;
    public availableLanguages: any = [];
    public currentLanguage: string;
    public hideSignUpPage = environment.hideSignUpPage;
    public supportSystem = environment.supportSystem;
    public selectedLanguage: {
        label: string;
        id: string;
    };

    constructor(
        private _commonUtils: CommonUtilsService,
        private _utils: UtilsService,
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        public R: RootScopeService,
        private _broadcaster: BroadcasterService,
        private _loginService: LoginService,
        private ngRxStore: Store
    ) {
        this.hideDomain =
            this.R.onLocalHost ||
            this._commonUtils.isBrowserIE() ||
            [
                'dbcloud',
                'dbtest',
                'dbuat',
                'dbprod',
                'dbdr',
                'kpmg2aprod',
                'kpmgprod',
                'kpmguat',
                'adityabirlauat',
                'adityabirlaprod',
            ].indexOf(R.PLATFORM) > 0;
    }

    private startComponent = async () => {
        if (window.location.pathname !== '/auth') {
            this.hideLoginSection = true;
            this.login = { password: '', userId: '', domain: '' };
            return;
        }
        if (!this.hideDomain) {
            this.hideDomain = false;
            this._commonUtils.setInStorage('showDomain', !this.hideDomain, true);
        }
        this._commonUtils.removeFromStorage('code');
        this._commonUtils.removeFromStorage('encodedCode');
        const params = this._route.queryParams['value'];
        params?.stateId && this._commonUtils.setInStorage('stateId', params?.stateId);
        const storedClientId = this._commonUtils.getFromStorage('clientId');
        const storedSuccessRedirect = this._commonUtils.getFromStorage('successRedirect');
        this._commonUtils.setInStorage('clientId', params?.clientId || storedClientId || environment.clientId);
        this._commonUtils.setInStorage('successRedirect', params?.redirectURL || storedSuccessRedirect);
        this._commonUtils.setInStorage('redirectURL', `${location.origin}/auth/validate`);
        const sessionExists = await this._loginService.checkSession();
        if (sessionExists) {
            this.hideLoginSection = true;
            this._loginService.redirect();
            return;
        }
        if (this.hideDomain && this.login.userId?.length > 0 && this.login.password?.length > 0) {
            this.submitDetails();
            return;
        }
        this.hideLoginSection = false;
        this.initiateVariables();
    };

    private initiateVariables = () => {
        this.login = {
            domain: this._commonUtils.getCookie('domain') || '',
            password: '',
            userId: '',
        };
        if (this.login.domain?.length > 0) {
            this.hideAuthForm = true;
            this.submitDetails();
            return;
        }
        this.hideAuthForm = false;
    };

    submitDetails = () => {
        const params = this._route.queryParams['value'];
        if (this.login.domain?.length > 0 && this.login.userId?.length > 0 && this.login.password?.length > 0) {
            this._utils.alertError('Enter domain or user credentials only');
        } else if (this.login.domain?.length > 0) {
            this.externalAuthentication(params?.domain || this.login.domain);
        } else {
            if (this.validateLoginDetails()) {
                this.hideLoginSection = true;
                this._commonUtils.deleteCookie('domain');
                const userId = window.btoa(unescape(encodeURIComponent(this.login.userId)));
                const password = window.btoa(unescape(encodeURIComponent(this.login.password)));
                this._commonUtils.setInStorage('userId', userId, true);
                this._commonUtils.setInStorage('password', password, true);
                this.taxillaAuthentication();
            } else {
                this.hideLoginSection = false;
                this.hideAuthForm = false;
            }
        }
    };

    /**
     * Validations
     */
    private validateLoginDetails = () => {
        let count = 0;
        if (this.login.userId === undefined || (this.login.userId && this.login.userId.length < 0) || this.login.userId === '') {
            this.errors.userId.push(translate('error_userIdRequired'));
            count++;
        } else {
            this.errors.userId = [];
        }
        if (this.login.password === undefined || (this.login.password && this.login.password.length < 0) || this.login.password === '') {
            this.errors.password.push(translate('error_passwordRequired'));
            count++;
        } else {
            this.errors.password = [];
        }
        return count === 0;
    };

    private externalAuthentication = (domain: string) => {
        this.ngRxStore.dispatch(
            AuthGetClientDetails({
                payload: {
                    domain,
                },
            })
        );
    };

    private authorizeExternalIP = (IDPCredentials: ClientDetails) => {
        const idpType = this._commonUtils.getFromStorage('authenticationType');
        if (idpType === 'LDAP') {
            this._router.navigate(['auth', 'login']);
            return;
        } else if (idpType === 'SAML' && IDPCredentials.config.bindingType === 'POST') {
            this._loginService.setSAMLRoute(IDPCredentials.config.singleSignOnUrl, {
                SAMLRequest: IDPCredentials.config.encodedSamlRequest,
            });
            return;
        }
        let stateId = this._commonUtils.getFromStorage('stateId');
        if (!stateId) {
            stateId = this._utils.guid(16);
            this._commonUtils.setInStorage('stateId', stateId);
        }
        const code = this._utils.guid(43);
        this._commonUtils.setInStorage('code', code);
        const encodedCode = this._utils.encryptWithSHA256(code, 'BASE64');
        this._commonUtils.setInStorage('encodedCode', encodedCode);
        this._api.session.authorizeExternalIP(
            {
                authURL: this._commonUtils.getFromStorage('authenticationURL'),
                clientId: this._commonUtils.getFromStorage('clientId'),
                code: encodedCode as any,
                stateId,
                redirectUri: this._commonUtils.getFromStorage('redirectURL'),
                type: this._commonUtils.getFromStorage('authenticationType'),
            },
            {
                successCallback: (res) => {
                    console.log(res);
                },
                failureCallback: (res) => {
                    if (!res || res?.msg || res?.text) {
                        this.hideAuthForm = false;
                        this.hideLoginSection = false;
                        if (res?.msg) {
                            this._utils.alertError(res?.msg);
                        }
                    } else {
                        this._router.navigate(['auth', 'error']);
                    }
                },
            }
        );
    };

    private taxillaAuthentication = () => {
        this.createState();
    };

    private createState = () => {
        if (this.hideDomain) {
            this._router.navigate(['auth', 'login']);
            return;
        }
        let stateId = this._commonUtils.getFromStorage('stateId');
        if (!stateId) {
            stateId = this._utils.guid(16);
            this._commonUtils.setInStorage('stateId', stateId);
        }
        const code = this._utils.guid(16);
        this._commonUtils.setInStorage('code', code);
        const encodedCode = this._utils.encryptWithSHA256(code, 'BASE64');
        this._commonUtils.setInStorage('encodedCode', encodedCode);
        this._api.session.authorizeCode(
            {
                clientId: this._commonUtils.getFromStorage('clientId'),
                code: encodedCode as any,
                stateId,
                redirectUri: this._commonUtils.getFromStorage('redirectURL'),
            },
            {
                successCallback: (res) => {
                    this._router.navigateByUrl(
                        `/auth/login?response_type=code&client_id=${this._commonUtils.getFromStorage(
                            'clientId'
                        )}&scope=null&state=${this._commonUtils.getFromStorage('stateId')}&auth_code=${res?.auth_code}`
                    );
                },
                failureCallback: (res) => {
                    if (!res || res?.msg || res?.text) {
                        this.hideLoginSection = false;
                        if (res?.msg) {
                            this._utils.alertError(res?.msg);
                        }
                    } else {
                        this._router.navigate(['auth', 'error']);
                    }
                },
            }
        );
    };

    navigateToForgotUserName = () => {
        const drawer = this.drawer;
        this.isForgotPaswd = false;
        this.isForgotUserId = true;
        this.securityAuthenticate = false;
        // tslint:disable-next-line:forin
        for (const key in this.errors) {
            this.errors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotPaswdErrors) {
            this.forgotPaswdErrors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotUsernameErrors) {
            this.forgotUsernameErrors[key] = [];
        }
        drawer.toggle();
        this.getForgotUsernameCaptcha();
    };

    getForgotUsernameCaptcha = () => {
        this.forgotUsername.captcha = '';
        const payload = {
            forgotUsername: true,
        };
        this.ngRxStore.dispatch(FetchCaptcha({ payload }));
        // this._api.session.fetchCaptcha({
        //     successCallback: (res) => {
        //         this.forgotUsernameCaptchaUrl = res?.captcha;
        //         callback && callback();
        //     },
        //     failureCallback: (response) => {
        //         this._utils.alertError((response && response.msg) || translate('error_failedToGetCaptcha'));
        //     },
        // });
    };

    checkForgotPswdCaptcha = () => {
        let count = 0;
        this.forgotPaswdErrors.captcha = [];
        if (!this.forgot.captcha || this.forgot.captcha.trim() === '') {
            this.forgotPaswdErrors.captcha = [translate('error_validCaptcha')];
            count++;
        }
        return count === 0;
    };

    submitForgotUserNameDetails = () => {
        const data = CommonUtilsService.cloneObject(this.forgotUsername);
        if (this.validateForgotUserNameDetails()) {
            this._api.session.forgotUsername(data, {
                successCallback: (res) => {
                    if (res?.msg) {
                        this._utils.alertSuccess((res && res.msg) || translate('success_resetUserNameSuccess'));
                    }
                    this.redirectToLoginDrawer();
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || translate('error_submit'));
                },
            });
        }
    };

    redirectToLoginDrawer = () => {
        if (this.navigateTimeout) {
            clearTimeout(this.navigateTimeout);
        }
        setTimeout(() => {
            this.navigateTologin();
        }, 1000);
    };

    /**
     * navigation to login page
     */
    navigateTologin = () => {
        const drawer = this.drawer;
        this.isForgotPaswd = false;
        this.isForgotUserId = false;
        this.securityAuthenticate = false;
        this.forgotUsername = {
            orgId: '',
            email: '',
            captcha: '',
        };
        drawer.toggle();
        // tslint:disable-next-line:forin
        for (const key in this.errors) {
            this.errors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotPaswdErrors) {
            this.forgotPaswdErrors[key] = [];
        }
    };

    private validateForgotUserNameDetails = () => {
        let count = 0;
        if (
            this.forgotUsername.orgId === undefined ||
            (this.forgotUsername.orgId && this.forgotUsername.orgId.length < 0) ||
            this.forgotUsername.orgId === ''
        ) {
            this.forgotUsernameErrors.orgId.push(translate('error_organizationCodeRequired'));
            count++;
        } else if (!this._utils.checkOrgCode(this.forgotUsername.orgId)) {
            this.forgotUsernameErrors.orgId.push(translate('error_organizationCode'));
            count++;
        } else {
            this.forgotUsernameErrors.orgId = [];
        }
        if (
            this.forgotUsername.email === undefined ||
            (this.forgotUsername.email && this.forgotUsername.email.length < 0) ||
            this.forgotUsername.email === ''
        ) {
            this.forgotUsernameErrors.email.push(translate('error_emailRequired'));
            count++;
        } else if (this._utils.invalidChars(this.forgotUsername.email)) {
            this.forgotUsernameErrors.email.push(translate('error_email'));
            count++;
        } else if (!this._utils.acceptEmail(this.forgotUsername.email)) {
            this.forgotUsernameErrors.email.push(translate('error_email'));
            count++;
        } else {
            this.forgotUsernameErrors.email = [];
        }
        const isUsernameCaptchaValid = this.checkForgotUsernameCaptcha();
        return count === 0 && isUsernameCaptchaValid;
    };

    checkForgotUsernameCaptcha = () => {
        let count = 0;
        this.forgotUsernameErrors.captcha = [];
        if (!this.forgotUsername.captcha || this.forgotUsername.captcha.trim() === '') {
            this.forgotUsernameErrors.captcha = [translate('error_validCaptcha')];
            count++;
        }
        return count === 0;
    };

    checkKeyCode = (event: any, section?: 'IP' | 'Login') => {
        if (section?.length > 0) {
            switch (section) {
                case 'IP':
                    if (this.login.domain?.length > 0) {
                        this.login.userId = '';
                        this.login.password = '';
                        this.errors.userId = [];
                        this.errors.password = [];
                    }
                    break;
                case 'Login':
                    if (this.login.userId?.length > 0 || this.login.password?.length > 0) {
                        this.login.domain = '';
                    }
                    break;
            }
        }
        if (event.keyCode === 13) {
            this.submitDetails();
        }
    };

    /**
     * navigation to forgot password page
     */
    navigateToForgotPassword = () => {
        const drawer = this.drawer;
        this.isForgotPaswd = true;
        this.isForgotUserId = false;
        this.securityAuthenticate = false;
        drawer.toggle();
        // tslint:disable-next-line:forin
        for (const key in this.errors) {
            this.errors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotPaswdErrors) {
            this.forgotPaswdErrors[key] = [];
        }
        this.forgot.userId = this.login.userId;
        this.getForgotPswdCaptcha();
    };

    getForgotPswdCaptcha = () => {
        this.forgot.captcha = '';
        const payload = {
            forgotPswd: true,
        };
        this.ngRxStore.dispatch(FetchCaptcha({ payload }));
        // this._api.session.fetchCaptcha({
        //     successCallback: (res) => {
        //         this.forgotPswdCaptchaUrl = res?.captcha;
        //         callback && callback();
        //     },
        //     failureCallback: (response) => {
        //         this._utils.alertError((response && response.msg) || translate('error_failedToGetCaptcha'));
        //     },
        // });
    };

    /**
     * sumbit email details
     */
    submitForgotPasswordDetails = () => {
        const data = CommonUtilsService.cloneObject(this.forgot);
        if (this.validateForgotPasswordDetails()) {
            this._api.session.forgotPassword(data, {
                successCallback: (res) => {
                    this._utils.alertSuccess((res && res.msg) || translate('success_resetPasswordSuccess'));
                    this.redirectToLoginDrawer();
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || translate('error_submit'));
                },
            });
        }
    };

    /**
     * Validations
     */
    validateForgotPasswordDetails = () => {
        let count = 0;
        if (this.forgot.userId === undefined || (this.forgot.userId && this.forgot.userId.length < 0) || this.forgot.userId === '') {
            this.forgotPaswdErrors.userId.push(translate('error_userIdRequired'));
            count++;
        } else {
            this.errors.userId = [];
        }
        const isCaptchaValid = this.checkForgotPswdCaptcha();
        return count === 0 && isCaptchaValid;
    };

    navigatingToSignUp = () => {
        this.hideLoginSection = true;
    };

    private startLanguages = () => {
        this.availableLanguages = this._commonUtils.getAvailableLanguages();
        this.currentLanguage = this._commonUtils.getActiveLanguage();
        this.selectedLanguage = this.availableLanguages.find((language) => language.id === this.currentLanguage);
    };

    public languageChanged = (event) => {
        this.selectedLanguage = this.availableLanguages.find((language) => language.id === event);
        this._utils.setLanguage(event);
    };

    private initiateSubscriptions = () => {
        this.ngRxStore
            .select(getClientDetailsState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((response) => {
                this._commonUtils.setCookie('domain', response?.originKey);
                this._commonUtils.setInStorage('clientSecret', response?.config?.relyingPartySecret);
                this._commonUtils.setInStorage('clientId', response?.config?.relyingPartyId);
                this._commonUtils.setInStorage('authenticationURL', response?.config?.authUrl || response?.config?.endPointUrl);
                this._commonUtils.setInStorage('tokenURL', response?.config?.tokenUrl);
                this._commonUtils.setInStorage('keyURL', response?.config?.tokenKeyUrl);
                this._commonUtils.setInStorage('authenticationType', response?.idpType);
                this._commonUtils.setInStorage('authenticationId', response?.id);
                this._commonUtils.setInStorage('userIdIdentifier', response?.config?.uniqueIdentifier);
                this.authorizeExternalIP(response);
            });
        this.ngRxStore
            .select(getClientDetailsFailedState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                if (!res || res?.msg || res?.text) {
                    this._commonUtils.deleteCookie('domain');
                    this.hideAuthForm = false;
                    this.hideLoginSection = false;
                    if (res?.msg) {
                        this._utils.alertError(res?.msg || translate('error_identityError'));
                    }
                } else {
                    this._router.navigate(['auth', 'error']);
                }
            });
        this.ngRxStore
            .select(fetchCaptchaSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe({
                next: (res) => {
                    this.forgotPswdCaptchaUrl = res?.forgotPswd && res?.captcha;
                    this.forgotUsernameCaptchaUrl = res?.forgotUsername && res?.captcha;
                },
            });
    };

    ngOnInit(): void {
        this.initiateSubscriptions();
        setTimeout(() => {
            this.startComponent();
        }, 500);
        this._router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.startComponent();
            }
        });
        this._broadcaster
            .on('logout')
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this._commonUtils.clearStorage();
                this.startComponent();
            });
        this.startLanguages();
    }

    ngOnDestroy(): void {
        this.unsubscribe?.next();
        this.unsubscribe?.complete();
    }
}
