<div class="notifyContentContainer" *transloco="let translate;">
    <div class="eventLogHedderDiv">
        <span>{{translateMsg('Please refresh to get the latest notifications')}}.</span>
    </div>
    <div class="eventFilter">
        <ng-container *ngIf="eventTypesList && eventTypesList.length !== 0">
            <mat-form-field class="col-5" appRemoveAriaOwns>
                <mat-select [(value)]="formData.eventType" [placeholder]="translateMsg('Event Type')">
                    <mat-option value="" (click)="getAllEvents(formData)">{{translateMsg('All')}}</mat-option>
                    <mat-form-field class="searchEntities searchInputField SearcEvents ">
                        <input matInput [placeholder]="translateMsg('Search Events')" type="text" [(ngModel)]="allAssetsName" />
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="allAssetsName = ''">
                            <mat-icon class='searchEntitiesIcons' *ngIf='allAssetsName'>
                                close
                            </mat-icon>
                            <mat-icon *ngIf='!allAssetsName' class="searchEntitiesIcons">
                                search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let event of eventTypesList | multiFilter: allAssetsName | AlphabeticalOrder: 'displayName'; trackBy: trackEventType " [value]="event.id" (click)="getAllEvents(formData)">{{translate(event.displayName)}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="activityYears.length !== 0">
            <mat-form-field class="col-2" appRemoveAriaOwns>
                <mat-select [(ngModel)]="formData.currentYear" [placeholder]="translateMsg('Year')" (openedChange)="searchYear = ''">
                    <mat-form-field class="searchEntities searchInputField SearcEvents ">
                        <input matInput [placeholder]="translateMsg('Search Year')" type="text" [(ngModel)]="searchYear" />
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="searchYear = ''">
                            <mat-icon class='searchEntitiesIcons' *ngIf='searchYear'>
                                close
                            </mat-icon>
                            <mat-icon *ngIf='!searchYear' class="searchEntitiesIcons">
                                search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let year of activityYears | multiFilter: searchYear; trackBy: trackActivityYears" [value]="year.id" (click)="getAllEvents(formData)">{{year.displayName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="activityMonths.length !== 0">
            <mat-form-field class="col-2" appRemoveAriaOwns>
                <mat-select [(ngModel)]="formData.currentMonth" [placeholder]="translateMsg('Month')" (openedChange)="searchMonth = ''">
                    <mat-form-field class="searchEntities searchInputField SearcEvents ">
                        <input matInput [placeholder]="translateMsg('Search Month')" type="text" [(ngModel)]="searchMonth" />
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="searchMonth = ''">
                            <mat-icon class='searchEntitiesIcons' *ngIf='searchMonth'>
                                close
                            </mat-icon>
                            <mat-icon *ngIf='!searchMonth' class="searchEntitiesIcons">
                                search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let month of activityMonths | multiFilter: searchMonth; trackBy: trackActivityMonths" [value]="month.id" (click)="getAllEvents(formData)">{{translate(month.displayName)}}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container>
            <button mat-mini-fab color="default" [title]="translateMsg('Refresh')" class=" refreshLogs mx-2" (click)="refreshNotifications()">
                <mat-icon class="refresh">refresh</mat-icon>
            </button>
        </ng-container>
    </div>
    <div class="eventTable">
        <material-table [allTableColumns]="allTableColumns" [selectedColumns]="selectedColumns" [data]="tableData" [noLog]="true" [pagination]="paginationData" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize($event)" (fetchItemsInPage)="fetchItemsInPage($event)" [noRecordMsg]="translateMsg('No Event Logs Available')"></material-table>
    </div>
</div>
