<div *transloco="let translate">
    <div class="dropZone fileBrowseContainer" [hidden]="appendedFileName?.length > 0" (click)="!isDisabled && openFileSelection()">
        <span>{{translate('Drag and drop file here')}}</span>
        <button mat-raised-button color="primary" class="mat-elevation-z1 fileBrowseContainer buttonClass">
            <span>{{data.title}}</span>
        </button>
    </div>
    <div class="dropZone fileBrowseContainer fileNameContainer" [hidden]="!appendedFileName" [title]="appendedFileName" (click)="!isDisabled && openFileSelection()">
        <span>{{appendedFileName}}</span>
    </div>
    <div class="fileDropZoneContainer" fileDnD (droppedFiles)="onFilesDropEvent($event)" (fileHovered)="fileHovered = $event">
        <div class="fileDropZoneText dropText" [class.fileHovered]="fileHovered" *transloco="let translate">
            {{translate('Drop files here')}}
        </div>
    </div>
</div>

<input #fileInput name="{{data.name}}" hidden class="fileUploadbox" stop-propagation (click)="fileInput.value = null" type="file" id="{{id}}" [(ngModel)]="files" (change)="onInputChange($event)" accept="{{supportedFormats?.join(',') || undefined}}">
