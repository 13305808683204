<div style="position: static;" [class.ps]="usePSClass" [perfectScrollbar]="config" [disabled]="disabled">
  <div class="ps-content">
    <ng-content></ng-content>
  </div>

  <div *ngIf="scrollIndicators" class="ps-overlay" [class.ps-at-top]="states.top" [class.ps-at-left]="states.left" [class.ps-at-right]="states.right" [class.ps-at-bottom]="states.bottom">
    <div class="ps-indicator-top" [class.ps-indicator-show]="indicatorY && interaction"></div>
    <div class="ps-indicator-left" [class.ps-indicator-show]="indicatorX && interaction"></div>
    <div class="ps-indicator-right" [class.ps-indicator-show]="indicatorX && interaction"></div>
    <div class="ps-indicator-bottom" [class.ps-indicator-show]="indicatorY && interaction"></div>
  </div>
</div>
