import { Injectable } from '@angular/core';

import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(private _bridge: BridgeService) {}

    /**
     * Method to getAllEvents
     * @param data contains eventType, fetchSize, monthYear
     * @param callbacks Contains success callback method, failure callback method
     */
    getNotifications = (data: {
        eventType?: string;
        fetchSize?: number;
        pagingState?: string;
        resourceId?: string;
        noAlerts?: boolean;
    }) => {
        return new Promise<{
            result: any[];
            pagingState: string;
        }>((resolve, reject) => {
            this._bridge.getNotificationEvents(
                {
                    ...data,
                    fetchSize: data.fetchSize || 20,
                },
                async (res) => {
                    res.result?.forEach((event) => {
                        event.createdOn = CommonUtilsService.transformDateToLocale(
                            event.createdOn,
                            'YYYY-MM-DDTHH:MM:SSZ',
                            'ddMMMyyyy HH:MM:SS AM',
                            false
                        );
                    });
                    resolve({
                        result: res?.result,
                        pagingState: res?.pagingState,
                    });
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };
}
