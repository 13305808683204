<div id="signinmaindiv" class="loggedInContainer">
    <div class="signInDetailsDiv">
        <mat-card class="signInCard">
            <div class="row signInRowCls">
                <div class="loginSummary col-6">
                    <div class="loginSummaryContainer">
                        <div class="loginSummaryHeader">
                            <span>
                                <img src="assets/images/{{R.LOGO_HREF}}logo.png" class="taxillaLogo" alt="logo">
                            </span>
                        </div>
                        <div class="loginfooterLogo" *ngIf="['emguat', 'emgprod', 'kpmguat', 'kpmgprod'].indexOf(R.PLATFORM) >= 0">
                            <span>Powered by<img class="logo loginPageFooterLogo" src="assets/images/encomply/footer-logo.png" alt="Taxilla"></span>
                        </div>
                    </div>
                </div>
                <div class="loginDetails col-6">
                    <span class="showLoggedInText">
                        Logged in Successfully. <a [routerLink]="'/'">Click here</a> to go to home page.
                    </span>
                </div>
            </div>
        </mat-card>
    </div>
</div>
