<div *transloco="let translate" class="matCardComponent">
    <mat-card class="appMatcard">
        <mat-card-content class="cardContent">
            <div class="leftActionsContainer">
                <div [hidden]="!showAppCheckBox">
                    <material-checkbox (click)="$event.stopPropagation()" [name]="translate('Select App')" [model]="asset.selected" (modelChange)="asset.selected = $event; logChange.emit(asset)">
                    </material-checkbox>
                </div>
                <div class="keyIconContainer" *ngIf="(asset?.organizationId === currentOrgDetails?.id) &&  (asset?.organizationId !== currentOrgDetails?.providerOrganizationId)" [title]="translate('Private App')">
                    <span class="material-icons">vpn_key</span>
                </div>
            </div>
            <div class="middleContentContainer">
                <div class="appImage" [ngSwitch]="asset.type || asset.assetType">
                    <div *ngSwitchCase="'ASSET'">
                        <img mat-card-image src="/assets/images/app-icon.png" alt="app-icon">
                    </div>
                    <div *ngSwitchCase="'BRIDGE_ASSET'">
                        <img mat-card-image src="/assets/images/bridge-icon.png" alt="bridge-icon">
                    </div>
                    <div *ngSwitchCase="'WIDGET'">
                        <img mat-card-image src="/assets/images/widget.png" alt="widget">
                    </div>
                    <div *ngSwitchCase="'RECON'">
                        <img mat-card-image src="/assets/images/recon_large.png" alt="recon_large">
                    </div>
                    <div *ngSwitchCase="'DASHBOARD'">
                        <img mat-card-image src="/assets/images/dashboard.png" alt="dashboard">
                    </div>
                    <div *ngSwitchDefault>
                        <img mat-card-image *ngIf="fromComponent !== 'packages'" src="/assets/images/app-icon.png" alt="app-icon">
                        <img mat-card-image *ngIf="fromComponent === 'packages'" src="/assets/images/bundle-icon.png" alt="bundle icon">
                    </div>
                </div>

                <div *ngIf="fromComponent !== 'assetModelApps__report'" class="appName textEllipsis" [attr.title]="asset.displayName || asset.serviceName || asset.displayName || asset.itemName">{{asset.displayName || asset.serviceName || asset.name || asset.itemName}}
                </div>

                <div *ngIf="fromComponent === 'assetModelApps__report'" class="menuName tax-ellipsis" [attr.title]="(report.name) + ' / ' + '(' + (asset.displayName || asset.name) + ')'">{{report.name}}<span class="reportUnderApp"> ( {{asset.name}} ) </span>
                </div>
            </div>
            <div class="rightActionsContainer">
                <div class="appActions" [ngSwitch]="asset.type || asset.assetType">
                    <ng-container *ngIf="fromComponent !== 'packages'">
                        <ng-container *ngSwitchCase="'ASSET'">
                            <ng-container *ngIf="fromComponent === 'approvedApps'">
                                <ng-container *ngTemplateOutlet="defaultHomeIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                                <ng-container *ngTemplateOutlet="shotCutIcons; context:{ asset: asset}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="fromComponent === 'assetModelApps'">
                                <ng-container *ngTemplateOutlet="defaultHomeIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                                <ng-container *ngTemplateOutlet="shotCutIcons; context:{ asset: asset}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="fromComponent === 'subscriptionManagement' || fromComponent === 'appsPendingApproval' || fromComponent === 'deactivatedApps'">
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'FILE'">
                            <ng-container *ngIf="fromComponent === 'approvedApps'">
                                <ng-container *ngTemplateOutlet="defaultHomeIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                                <ng-container *ngTemplateOutlet="shotCutIcons; context:{ asset: asset}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="fromComponent === 'subscriptionManagement' || fromComponent === 'appsPendingApproval'|| fromComponent === 'deactivatedApps'">
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'BRIDGE_ASSET'">
                            <ng-container *ngIf="fromComponent === 'approvedApps'">
                                <ng-container *ngTemplateOutlet="defaultHomeIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="fromComponent === 'subscriptionManagement' || fromComponent === 'appsPendingApproval'|| fromComponent === 'deactivatedApps'">
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'RECON'">
                            <ng-container *ngIf="fromComponent === 'approvedApps'">
                                <ng-container *ngTemplateOutlet="defaultHomeIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="fromComponent === 'subscriptionManagement' || fromComponent === 'appsPendingApproval'|| fromComponent === 'deactivatedApps'">
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="helpIcon; context:{ asset: asset, translate: translate}"></ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            <!-- Widget Section Starts -->
                            <ng-container *ngIf="fromComponent === 'approvedApps'">
                                <ng-container *ngTemplateOutlet="widgetHomeIcon; context:{ asset: asset}"></ng-container>
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="fromComponent === 'subscriptionManagement' || fromComponent === 'appsPendingApproval'|| fromComponent === 'deactivatedApps'">
                                <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                            </ng-container>
                            <!-- Widget Section Ends -->
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="fromComponent === 'packages'">
                        <ng-container *ngTemplateOutlet="viewIcon; context:{ asset: asset, translate: translate}"></ng-container>
                        <ng-container *ngTemplateOutlet="deleteIcon; context:{ asset: asset, translate: translate}"></ng-container>
                        <ng-container *ngTemplateOutlet="editIcon; context:{ asset: asset, translate: translate}"></ng-container>
                        <ng-container *ngTemplateOutlet="infoIcon; context:{ asset: asset}"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="fromComponent === 'assetModelApps_app' || fromComponent === 'assetModelApps__report'">
                        <ng-container *ngTemplateOutlet="defaultHomeIcon; context:{ asset: asset}"></ng-container>
                    </ng-container>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions class="cardActions">
            <ng-container *ngIf="fromComponent === 'approvedApps' || fromComponent === 'assetModelApps_app'">
                <ng-container [ngSwitch]="asset.type || asset.assetType">
                    <ng-container *ngSwitchCase="'ASSET'">
                        <div class="actionsContainer" *ngIf="!hideLinks && !asset.noPermissions">
                            <ng-container *ngIf="asset.canCreateNewProcess">
                                <ng-container *ngTemplateOutlet="leftBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Process Now'}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="asset.canViewAllProcesses">
                                <ng-container *ngTemplateOutlet="rightBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'All Processes'}"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'RECON'">
                        <div class="actionsContainer">
                            <ng-container *ngTemplateOutlet="leftBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Process Now'}"></ng-container>
                            <ng-container *ngTemplateOutlet="rightBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'All Processes'}"></ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'BRIDGE_ASSET'">
                        <div class="actionsContainer">
                            <ng-container *ngTemplateOutlet="fullWidthBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'View Participating Apps'}"></ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="actionsContainer" *ngIf="!hideLinks && !asset.noPermissions">
                            <ng-container *ngIf="asset.canCreateNewProcess">
                                <ng-container *ngTemplateOutlet="leftBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Process Now'}"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="asset.canViewAllProcesses">
                                <ng-container *ngTemplateOutlet="rightBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'All Processes'}"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="fromComponent === 'subscriptionManagement'">
                <ng-container [ngSwitch]="asset.type || asset.assetType">
                    <ng-container *ngSwitchCase="'BRIDGE_ASSET'">
                        <div class="actionsContainer">
                            <ng-container *ngTemplateOutlet="leftBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Subscribe'}"></ng-container>
                            <ng-container *ngTemplateOutlet="rightBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Participating Apps'}"></ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <div class="actionsContainer">
                            <ng-container *ngTemplateOutlet="fullWidthBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Subscribe'}"></ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="fromComponent === 'appsPendingApproval'|| fromComponent === 'deactivatedApps'">
                <ng-container *ngIf="(asset.type || asset.assetType) === 'BRIDGE_ASSET'">
                    <div class="actionsContainer">
                        <ng-container *ngTemplateOutlet="fullWidthBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'View Participating Apps'}"></ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="fromComponent === 'packages'">
                <div class="actionsContainer">
                    <ng-container *ngTemplateOutlet="fullWidthBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'Send Invitation'}"></ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="fromComponent === 'assetModelApps__report'">
                <ng-container *ngIf="asset && (bridge.status === 'APPROVED' || appServiceId) && report.canProcessReports">
                    <div class="actionsContainer" *ngIf="asset.name">
                        <ng-container *ngTemplateOutlet="fullWidthBtnTemplate; context:{ asset: asset, translate: translate, btnLabel: 'All Reports'}"></ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </mat-card-actions>
    </mat-card>
</div>


<ng-template #widgetHomeIcon let-asset="asset">
    <ng-container *ngIf="!hideLinks && !asset.noPermissions">
        <div class="assetInformation">
            <div class="material-icons-outlined infoIcon" matTooltip="home" [routerLink]="[widgetsHomePage]">home</div>
        </div>
    </ng-container>
</ng-template>

<ng-template #infoIcon let-asset="asset">
    <div class="assetInformation">
        <div class="material-icons-outlined infoIcon" (mouseover)="!asset?.description && getAssetDescription.emit(asset)" (mouseleave)="onInfoOut.emit($event)" matTooltip="{{asset.description}}">info</div>
    </div>
</ng-template>

<ng-template #defaultHomeIcon let-asset="asset">
    <ng-container *ngIf="!asset.noPermissions">
        <div class="assetInformation">
            <div class="material-icons-outlined infoIcon" (mouseleave)="onInfoOut.emit($event)" matTooltip="home" AppSelection [navigateToAppHomeOnly]="true" [app]="asset" [bridge]="bridge" (click)="closeModal.emit(asset)">home</div>
        </div>
    </ng-container>
</ng-template>

<ng-template #helpIcon let-asset="asset" let-translate="translate">
    <div class="assetHelpDoc assetInformation">
        <div class="material-symbols-outlined infoIcon" (click)="!asset.helpDocuments && getHelpDocument.emit(asset)" [matTooltip]="translate('Help Document')" [matMenuTriggerFor]="documentsMenu">help</div>
        <mat-menu #documentsMenu="matMenu" class="py-1" stop-propagation xPosition="after">
            <app-help-document-view [documentList]="asset.helpDocuments" (downloadHelpDocument)="downloadHelpDocument.emit($event)" (viewHelpDocument)="viewHelpDocument.emit($event)"></app-help-document-view>
        </mat-menu>
    </div>
</ng-template>

<ng-template #shotCutIcons let-asset="asset">
    <div class="assetInformation">
        <app-header-shortcuts [mainApp]="asset" [restrictedMenuItems]="['HOME', 'PROCESSES', 'NEW_PROCESS']"></app-header-shortcuts>
    </div>
</ng-template>

<ng-template #leftBtnTemplate let-asset="asset" let-translate="translate" let-btnLabel="btnLabel">
    <ng-container *ngIf="btnLabel === 'Process Now'">
        <button class="actionButton loaderBlock leftBtn" [class.isLoading]="asset.loading?.assetMetaData" AppNewProcess [app]="asset" [appId]="asset.id" [bridge]="bridge" [bridgeId]="bridge?.id" [routerLink]="asset['appNewProcessLink']" (click)="closeModal.emit()" title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
    <ng-container *ngIf="btnLabel === 'Subscribe'">
        <button class="actionButton loaderBlock leftBtn" (click)='initiateSubscription.emit({assets: [asset], individual: true})' title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
</ng-template>

<ng-template #rightBtnTemplate let-asset="asset" let-translate="translate" let-btnLabel="btnLabel">
    <ng-container *ngIf="btnLabel === 'All Processes'">
        <button class="actionButton loaderBlock rightBtn" [class.isLoading]="asset.loading?.assetMetaData" AppProcessesSelection [app]="asset" [appId]="asset.id" [bridge]="bridge" [bridgeId]="bridge?.id" [routerLink]="asset['appProcessesLink']" (click)="closeModal.emit()" title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
    <ng-container *ngIf="btnLabel === 'Participating Apps'">
        <button class="actionButton loaderBlock rightBtn" [class.isLoading]="asset.loading?.assetMetaData" (click)='fetchBridgeAssets.emit(asset)' title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
</ng-template>

<ng-template #fullWidthBtnTemplate let-asset="asset" let-translate="translate" let-btnLabel="btnLabel">
    <ng-container *ngIf="btnLabel === 'View Participating Apps'">
        <button class="actionButton loaderBlock fullWidthBtn" (click)='fetchBridgeAssets.emit(asset)' title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
    <ng-container *ngIf="btnLabel === 'Subscribe'">
        <button class="actionButton loaderBlock fullWidthBtn" (click)='initiateSubscription.emit({assets: [asset], individual: true})' title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
    <ng-container *ngIf="btnLabel === 'Send Invitation'">
        <button class="actionButton loaderBlock fullWidthBtn" (click)='sendBundleInvite.emit(asset)' title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
    <ng-container *ngIf="btnLabel === 'All Reports'">
        <button class="actionButton loaderBlock leftBtn" [class.isLoading]="asset.loading?.assetMetaData" AppProcessesSelection [app]="asset" [bridge]="bridge" [appId]="asset?.id" [bridgeId]="bridge?.id" [report]="report" [routerLink]="[report['appProcessesLink']]" [getDirectLink]="true" (click)="closeModal.emit()" title="{{btnLabel}}">{{translate(btnLabel)}}
        </button>
    </ng-container>
</ng-template>

<ng-template #viewIcon let-asset="asset" let-translate="translate">
    <div class="assetInformation">
        <div class="material-icons-outlined infoIcon" (click)="getInvitations.emit({bundle: asset, triggerOrigin: 'view'})" matTooltip="{{translate('View')}}">remove_red_eye</div>
    </div>
</ng-template>

<ng-template #deleteIcon let-asset="asset" let-translate="translate">
    <div class="assetInformation">
        <div class="material-icons-outlined infoIcon" (click)="deleteBundle.emit(asset)" matTooltip="{{translate('Delete')}}">delete</div>
    </div>
</ng-template>

<ng-template #editIcon let-asset="asset" let-translate="translate">
    <div class="assetInformation">
        <div class="material-icons-outlined infoIcon" (click)="createBundle.emit(asset)" matTooltip="{{translate('Edit')}}">edit</div>
    </div>
</ng-template>
