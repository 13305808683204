export interface IdentityProvidersState {
    currentOrganizationId: string;
    organizationsMap: {
        // Organization id vs identity providers
        [Property: string]: {
            identityProviders: any[];
        };
    };
}

export const identityProviderState: IdentityProvidersState = {
    currentOrganizationId: undefined,
    organizationsMap: {},
};
