import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dots-loader',
    templateUrl: './dotsloader.html',
    styleUrls: ['./dotsloader.component.scss'],
})
export class DotsLoaderComponent {
    @Input() showLoading: boolean;

    constructor() {}
}
