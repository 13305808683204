import { UIReport } from 'taxilla-library';

export const reportsState: ReportsState = {
    instanceIdVsReports: {},
    loadingReports: {},
};

export interface ReportsState {
    instanceIdVsReports: {
        [property: string]: UIReport[];
    };
    loadingReports: {
        [property: string]: boolean;
    };
}
