<div class="loaderImageContainer" *ngIf="R.loadingCalls?.length > 0">
    <div class="loader">
        <img *ngIf="!isIE" src="assets/images/loader.svg" alt="Loading...">
        <mat-spinner *ngIf="isIE"></mat-spinner>
    </div>
    <div class="loaderMessage">
        <ng-container *ngIf="R.loadingCalls?.length > 0">
            <ng-container *ngIf="R.loadingCalls[0]">
                <div *ngIf="R.loadingCalls[0].message">{{R.loadingCalls[0].message}}</div>
            </ng-container>
        </ng-container>
    </div>
</div>
