import { RoleInterface } from '../../interface/role.interface';

export interface RolesState {
    assignedRolesMap: {
        // orgId vs map
        [property: string]: {
            // user id vs role ids
            [property: string]: string[];
        };
    };
    rolesMap: {
        // role id vs role
        [property: string]: RoleInterface;
    };

    selectedBundleId: string;

    selectedRoleId: string;

    organizationsMap: {
        // OrganizationId vs details
        [property: string]: {
            roles: RoleInterface[];
            roleUserIds: {
                // roleId vs roleUserIds
                [property: string]: any;
            };
            bundleRoles: {
                // bundlelId vs Roles
                [property: string]: RoleInterface[];
            };
        };
    };
}

export const rolesState: RolesState = {
    assignedRolesMap: {},
    rolesMap: {},
    organizationsMap: {},
    selectedBundleId: undefined,
    selectedRoleId: undefined,
};
