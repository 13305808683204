export const intialWidgetState: WidgetState = {
    widgetTranslations: {},
};

export interface WidgetState {
    widgetTranslations: {
        [property: string]: {
            [property: string]: {
                displayName: string;
                description: string;
            };
        };
    };
}
