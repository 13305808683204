<mat-form-field *transloco="let translate;" [appearance]="appearance" appRemoveAriaOwns>
    <mat-label>{{placeholder}}</mat-label>
    <mat-select placeholder="{{placeholder}}" [formControl]="control" [errorStateMatcher]="matcher" disableOptionCentering (selectionChange)="errors && errors.splice(0,1);" title="{{title}}" panelClass="selectGroupClass addToIgnoreList" [multiple]="isMultple">
        <mat-option *ngIf="(hideSelectIfValueExists !== undefined) ? ((model !== undefined) && !hideSelectIfValueExists) : true" (click)="selected('')">
            <em>--{{translate('Please select')}}--</em>
        </mat-option>
        <div class="selectSearchFilter addToIgnoreList" *ngIf="options?.length !== 0">
            <mat-form-field class="addToIgnoreList">
                <input matInput class="inputSearchField addToIgnoreList" autocomplete="off" [placeholder]="translate('Search')" [(ngModel)]="searchColumns" (keydown)="$event.stopPropagation()" />
                <button mat-button *ngIf="searchColumns" class="selectCloseIcon addToIgnoreList" matSuffix mat-icon-button aria-label="Clear" (click)='$event.stopPropagation();searchColumns ="";' (keydown)="$event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <mat-option *ngIf="options?.length === 0" class="noReportRecord" [disabled]="true">{{noOptionsInstruction}}</mat-option>
        <mat-optgroup *ngFor="let option of options" [label]="option[groupDisplayNameProperty]">
            <mat-option *ngFor="let subOption of option[groupValueProperty] | FilterPipe: (groupValueProperty || 'value') : searchColumns : null : optionDisplayNameProperty" [value]="(optionValueProperty !== undefined) ? subOption[optionValueProperty] : option.value" title="{{optionDisplayNameProperty ? subOption[optionDisplayNameProperty] : subOption.displayName}}" (click)="selected(subOption)" (onSelectionChange)="selected(subOption)">
                {{optionDisplayNameProperty ? subOption[optionDisplayNameProperty] : subOption.displayName}}
            </mat-option>
            <mat-option class="noReportRecord" [disabled]="true" *ngIf="(option[groupValueProperty || 'value'] | FilterPipe: (groupValueProperty || 'value') : searchColumns : null : (optionDisplayNameProperty || 'displayName')).length === 0">
                {{translate('No results found')}}
            </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
        <strong>
            <span>* </span>{{translate('required')}}</strong>
    </mat-hint>
    <mat-error *ngIf="errors && errors.length > 0" title="{{errors}}">
        {{errors}}
    </mat-error>
</mat-form-field>
