<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 class="matDialogTitle" mat-dialog-title>Alert!</h4>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography">
    <span>Process exists with the given business key(s). Would you like to redirect to the existing process ?</span>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <material-button class='mx-4 noOutline submitColor' [label]="'Proceed to existing process'" color="primary" (onTrigger)="openCurrentInstance()">
    </material-button>
    <ng-container *ngIf="data.isAssetToAssetCase">
        <material-button class='mx-4 noOutline submitColor' [disabled]="!data.requestId" [label]="'Update process'" color="primary" (onTrigger)="updateRequest()">
        </material-button>
    </ng-container>
    <material-button class='noOutline' [label]="'Back to New process'" color="default" (onTrigger)="backToNewProcesspage();closeModal()">
    </material-button>
</mat-dialog-actions>
