import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyChipInputEvent } from '@angular/material/legacy-chips';
import { EventSubscription } from '@encomply/feature/settings/classes/eventsubscription.class';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { QueryBuilderConfig } from 'ng-query-builder';
import { Subject, takeUntil } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import { getCurrentOrganizationId$ } from '../../store/selectors';

@Component({
    selector: 'app-events-subscription-advanced-edit',
    templateUrl: './events-subscription-advanced-edit.component.html',
    styleUrls: ['./events-subscription-advanced-edit.component.scss'],
})
export class EventsSubscriptionAdvancedEditComponent implements OnInit, OnDestroy {
    @Input() public eventTypes: {
        displayName: string;
        eventType: string;
    }[];
    @Input() public event: EventSubscription;
    @Input() public selectedEventType: string;
    @Input() public eventDefinitions: {
        [x: string]: string;
    }[] = [];
    @Input() public isAdvancedEdit: boolean;

    @Output() createdSubscription = new EventEmitter();
    @Output() onCloseDrawer = new EventEmitter();
    @Output() onCreateSubscription = new EventEmitter();
    @Output() onAdvancedUpdateSubscription = new EventEmitter();

    isEdit: boolean;
    searchType = 'single';
    public query: {
        condition: string;
        rules: [];
    };
    public singleQuery = {
        fields: [],
        field: '',
        operators: {},
        operator: '',
        value: '',
    };
    public config: QueryBuilderConfig = {
        fields: {},
    };
    public customQuery = [
        {
            field: '',
            operator: '=',
            operators: ['='],
            value: '',
        },
    ];
    public eventErrors = {
        subscriptionId: [],
        toEmails: [],
        ccEmails: [],
        bccEmails: [],
        subjectTemplate: [],
        contentTemplate: [],
        eventType: [],
        channel: [],
        criterion: [],
    };
    public channels = ['Email'];
    public channel: any;
    public customFieldsCondition = 'and';
    public isIE: boolean;
    private unsubscribe = new Subject<void>();
    private organizationId: string;
    public separatorKeysCodes = [ENTER, COMMA];
    public nestedPayloadFields: string[] = [];
    public payloadFields: string[] = [];

    constructor(private _utils: UtilsService, private _commonUtils: CommonUtilsService, private store$: Store, private _api: ApiService) {}

    public onEventTypeChange = (event, triggerOrigin?: string) => {
        const truthy = triggerOrigin && triggerOrigin === 'modelChange';
        if (truthy && !this.isEdit) {
            this.resetQueryBuilder();
            this.resetSingleQuery();
            this.resetCustomQuery();
            this.searchType = 'single';
        }
        if (event) {
            this.event.eventType = event;
            this.selectedEventType = event;
            this.event.subscriptionId = this.isEdit ? this.event.subscriptionId : event + '_' + this._utils.guid(4, true);
            const eventType = JSON.parse(
                this.eventDefinitions.find((val) => val.eventType === this.event.eventType.toUpperCase()).definition
            );
            this.buildConfig(eventType.properties.details.properties);
            if (truthy && !this.isEdit) {
                this.buildDefaultQuery(this.config, this.query);
                this.buildDefaultSingleQuery(this.config);
            }
        }
    };

    private buildDefaultSingleQuery = (config) => {
        if (!config) {
            return;
        }
        const fields = Object.keys(this.config.fields);
        this.singleQuery.field = this.config.fields[fields[0]].name;
        this.singleQuery.operator = this.config.fields[fields[0]].operators[0];
    };

    private buildDefaultQuery = (config, query) => {
        if (!config || !query) {
            return;
        }
        const fields = Object.keys(this.config.fields);
        for (let i = 0; i < 2 && i < fields.length; i++) {
            query.rules[i] = {
                field: this.config.fields[fields[i]].name,
                operator: this.config.fields[fields[i]].operators[0],
                value: '',
            };
        }
    };

    private resetQueryBuilder = () => {
        this.query = {
            condition: 'and',
            rules: [],
        };
        this.config = {
            fields: {},
        };
    };

    private resetSingleQuery = () => {
        this.singleQuery = {
            fields: [],
            field: '',
            operators: {},
            operator: '',
            value: '',
        };
    };

    private resetCustomQuery = () => {
        this.customQuery = [
            {
                field: '',
                operator: '=',
                operators: ['='],
                value: '',
            },
        ];
    };

    private buildConfig = (fields) => {
        this.nestedPayloadFields = [];
        Object.keys(fields).forEach((fieldKey) => {
            if (['number', 'string'].indexOf(fields[fieldKey]['type']) >= 0) {
                this.config.fields['details.' + fieldKey] = {
                    name: 'details.' + fieldKey,
                    type: fields[fieldKey]['type'],
                    operators: fields[fieldKey]['type'] === 'number' ? ['=', '>', '<'] : ['='],
                };
                if (this.singleQuery.fields.indexOf('details.' + fieldKey) === -1) {
                    this.singleQuery.fields.push('details.' + fieldKey);
                    this.singleQuery.operators['details.' + fieldKey] = fields[fieldKey]['type'] === 'number' ? ['=', '>', '<'] : ['='];
                }
            } else {
                this.nestedPayloadFields.push('details.' + fieldKey);
            }
        });
        this.payloadFields = Object.keys(this.config.fields);
    };

    private validateEventsData = () => {
        let count = 0;
        if (!this.event.toEmails || !this.event.toEmails.length) {
            this.eventErrors.toEmails = [translate('To Email Id is required')];
            count++;
        } else {
            this.checkToEmails();
            if (this.eventErrors.toEmails.length) {
                count++;
            }
        }

        if (this.event.ccEmails && this.event.ccEmails.length) {
            this.checkCCEmails();
            if (this.eventErrors.ccEmails.length) {
                count++;
            }
        } else {
            this.eventErrors.ccEmails = [];
        }

        if (this.event.bccEmails && this.event.bccEmails.length) {
            this.checkBccEmails();
            if (this.eventErrors.bccEmails.length) {
                count++;
            }
        } else {
            this.eventErrors.bccEmails = [];
        }

        if (!this.event.subjectTemplate) {
            this.eventErrors.subjectTemplate = [translate('Subject is required')];
            count++;
        } else {
            this.eventErrors.subjectTemplate = [];
        }

        if (!this.event.contentTemplate) {
            this.eventErrors.contentTemplate = [translate('Content is required')];
            count++;
        } else {
            this.eventErrors.contentTemplate = [];
        }

        if (!this.event.eventType) {
            this.eventErrors.eventType = [translate('Event type is required')];
            count++;
        } else {
            this.eventErrors.eventType = [];
        }

        if (!this.channel) {
            this.eventErrors.channel = [translate('Channel is required')];
            count++;
        } else {
            this.eventErrors.channel = [];
        }

        if (!this.event.subscriptionId) {
            this.eventErrors.subscriptionId = [translate('Subscription name is required. (This must be unique)')];
            count++;
        } else {
            this.eventErrors.subscriptionId = [];
        }

        if (this.searchType === 'single') {
            if (!this.singleQuery.field || !this.singleQuery.operator || !this.singleQuery.value) {
                this.eventErrors.criterion = [translate('Enter valid criteria')];
                count++;
            } else {
                this.eventErrors.criterion = [];
            }
        } else if (this.searchType === 'custom') {
            if (!this.validateCustomCriterion(this.customQuery)) {
                this.eventErrors.criterion = [translate('Enter valid criteria')];
                count++;
            } else {
                this.eventErrors.criterion = [];
            }
        } else {
            if (!this.validateCriterion(this.query)) {
                this.eventErrors.criterion = [translate('Enter valid criteria')];
                count++;
            } else {
                this.eventErrors.criterion = [];
            }
        }

        return count === 0;
    };

    private validateCustomCriterion = (query) => {
        let valid = true;
        if (!query) {
            valid = false;
        }
        for (let i = 0; i < query.length; i++) {
            if (!query[i].field || !query[i].operator || !query[i].value) {
                valid = false;
                break;
            }
        }

        return valid;
    };

    /**
     * Method to validate events query criteria
     * @param query Events query criteria
     * @returns
     */
    private validateCriterion = (query) => {
        let valid = true;
        if (!query) {
            return false;
        }
        if (query?.rules?.length < 1) {
            return false;
        }
        query.rules.forEach((rule) => {
            if (!valid) {
                return;
            }
            if (rule.rules?.length) {
                valid = this.validateCriterion(rule);
                return;
            }
            if (rule['value'] === null || rule['value'] === undefined || rule['value'] === '') {
                valid = false;
            }
        });
        return valid;
    };

    private validateEmails = (email, field) => {
        // if (this._utils.invalidChars(email, true)) {
        //     field.push('Enter a valid email');
        // } else if (!this._utils.acceptEmail(email, true)) {
        //     field.push('Enter a valid email');
        // }
    };

    private checkToEmails = () => {
        this.eventErrors.toEmails = [];
        this.validateEmails(this.event.toEmails, this.eventErrors.toEmails);
    };

    private checkCCEmails = () => {
        this.eventErrors.ccEmails = [];
        if (this.event.ccEmails) {
            this.validateEmails(this.event.ccEmails, this.eventErrors.ccEmails);
        }
    };

    private checkBccEmails = () => {
        this.eventErrors.bccEmails = [];
        if (this.event.bccEmails) {
            this.validateEmails(this.event.bccEmails, this.eventErrors.bccEmails);
        }
    };

    private clearEventErrors = () => {
        this.eventErrors = {
            subscriptionId: [],
            toEmails: [],
            ccEmails: [],
            bccEmails: [],
            subjectTemplate: [],
            contentTemplate: [],
            eventType: [],
            channel: [],
            criterion: [],
        };
    };

    public onSearchTypeChanged = () => {
        this.eventErrors.criterion = [];
    };

    public onSingleQueryFieldChanged = ($event) => {
        this.singleQuery.operator = '';
        this.singleQuery.value = '';
    };

    public onSingleQueryOperatorChanged = ($event) => {
        this.singleQuery.value = '';
    };

    public trackByIndexMethod(index: number, item: any): any {
        return index;
    }

    public addCustomRule = () => {
        this.customQuery.push({
            field: '',
            operator: '=',
            operators: ['='],
            value: '',
        });
    };

    public removeCustomRule = (index) => {
        this.customQuery.splice(index, 1);
    };

    public copyPlaceholder = (field, asIs?) => {
        const text = asIs ? field : '${' + field + '}';
        this._commonUtils.copyTextToClipboard(
            text,
            () => {
                this._utils.alertSuccess(translate('Copied to clipboard'));
            },
            () => {
                this._utils.alertError(`${translate('Could not copy.You may use')} ${text} ${translate('explicitly')}.`);
            }
        );
    };

    public onChannelChange = (event) => {
        this.channel = event;
    };

    public removeMails = (email: string, origin: string) => {
        const index = (this.event[origin] ??= []).indexOf(email);
        if (index >= 0) {
            this.event[origin].splice(index, 1);
        }
    };

    public addMails = (event: MatLegacyChipInputEvent, origin: string): void => {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            (this.event[origin] ??= []).push(value.trim());
        }
        if (input) {
            input.value = '';
        }
    };

    public closeDrawer = () => {
        this.clearEventErrors();
        this.onCloseDrawer.emit();
    };

    private getCriteriaFromSingleQuery = () => {
        return {
            property: this.singleQuery.field,
            criterionType: this.getCriterionTypeFromOperator(this.singleQuery.operator),
            value: this.singleQuery.value,
        };
    };

    private getCriterionTypeFromOperator = (operator) => {
        let criterionType = 'EQ';
        if (operator === '=') {
            criterionType = 'EQ';
        } else if (operator === '<') {
            criterionType = 'LT';
        } else if (operator === '>') {
            criterionType = 'GT';
        }
        return criterionType;
    };
    private getCriteriaFromCustomQuery = (query) => {
        const criteria = {};
        if (query?.length) {
            if (query.length > 1) {
                criteria['criterionType'] = this.customFieldsCondition;
                for (let i = 0; i < query.length; i++) {
                    criteria['criterion' + (i + 1)] = {
                        criterionType: this.getCriterionTypeFromOperator(query[i]['operator']),
                        property: query[i]['field'],
                        value: query[i]['value'].toString(),
                    };
                }
            } else {
                criteria['criterionType'] = this.getCriterionTypeFromOperator(query[0]['operator']);
                criteria['property'] = query[0]['field'];
                criteria['value'] = query[0]['value'].toString();
            }
        }
        return criteria;
    };
    private getCriteriaFromQuery = (query) => {
        const criteria = {};
        if (query?.rules?.length) {
            criteria['criterionType'] = query.condition;
            for (let i = 0; i < query.rules.length; i++) {
                if (query.rules[i].rules?.length) {
                    criteria['criterion' + (i + 1)] = this.getCriteriaFromQuery(query.rules[i]);
                } else {
                    criteria['criterion' + (i + 1)] = {
                        criterionType: this.getCriterionTypeFromOperator(query.rules[i]['operator']),
                        property: query.rules[i]['field'],
                        value: query.rules[i]['value'].toString(),
                    };
                }
            }
        }
        return criteria;
    };

    public createSubscription = () => {
        this.onCreateSubscription.emit(translate('Create Subscription'));
        const data = {
            orgId: this.organizationId,
            eventType: this.event.eventType,
            subscriptionId: this.event.subscriptionId,
            criterion: '{}',
            toEmails: this.event.toEmails || [],
            ccEmails: this.event.ccEmails || [],
            bccEmails: this.event.bccEmails || [],
            subjectTemplate: this.event.subjectTemplate,
            contentTemplate: this.event.contentTemplate,
            attachment: this.event.attachment,
            includeHierarchy: this.event.includeHierarchy,
            groupId: this.event.groupId || '-',
        };
        if (this.validateEventsData()) {
            data.criterion =
                this.searchType === 'single'
                    ? JSON.stringify(this.getCriteriaFromSingleQuery())
                    : this.searchType === 'custom'
                    ? JSON.stringify(this.getCriteriaFromCustomQuery(this.customQuery))
                    : JSON.stringify(this.getCriteriaFromQuery(this.query));
            this.selectedEventType = this.event.eventType;
            if (!this.isEdit) {
                this._api.events.createEventSubscriptions(data).subscribe({
                    next: (res: { msg: string }) => {
                        this.createdSubscription.emit();
                        this.closeDrawer();
                        this._utils.alertSuccess(res.msg || translate('Event subscription created successfully'));
                    },
                    error: (res) => {
                        if (res && res.msg) {
                            this._utils.alertError(res.msg);
                        }
                    },
                });
            } else {
                if (this.isAdvancedEdit) {
                    this.onAdvancedUpdateSubscription.emit(data);
                    return;
                }
                this._api.events.editEventSubscriptions(data).subscribe({
                    next: (res: { msg: string }) => {
                        this.closeDrawer();
                        this._utils.alertSuccess(res.msg || translate('Event subscription edited successfully'));
                    },
                    error: (res) => {
                        if (res && res.msg) {
                            this._utils.alertError(res.msg);
                        }
                    },
                });
            }
        }
    };

    private editSubscription = () => {
        const event = new EventSubscription(this.event || {});
        this.event = event;
        this.channel = 'Email';
        this.isEdit = event?.orgId?.length > 0;
        const parsedEvent = JSON.parse(this.eventDefinitions.find((val) => val.eventType === event.eventType.toUpperCase()).definition);
        this.buildConfig(parsedEvent?.properties.details.properties);
        const criterion = event.criterion && JSON.parse(event.criterion);
        const displayableFields = Object.keys(this.config.fields);
        if (criterion?.property) {
            if (displayableFields.indexOf(criterion.property) > -1) {
                this.searchType = 'single';
                this.getSingleQueryFromCriteria(criterion);
                this.buildDefaultQuery(this.config, this.query);
            } else {
                this.searchType = 'custom';
                this.customQuery = this.getCustomQueryFromCriteria(criterion);
                this.customFieldsCondition = criterion.criterionType || 'and';
            }
        } else {
            this.searchType = 'multiple';
            this.query = this.getQueryFromCriteria(criterion) as any;
            this.buildDefaultSingleQuery(this.config);
        }
        if (!this.isEdit) {
            this.event.subscriptionId = this.event.eventType + '_' + this._utils.guid(4, true);
        }
    };

    private getCustomQueryFromCriteria = (criteria) => {
        const query = [];
        if (criteria.property) {
            query.push({
                field: criteria['property'],
                value: criteria['value'],
                operator: this.getOperatorFromCriterionType(criteria['criterionType']),
                operators: ['='],
            });
        } else if (criteria.criterionType) {
            Object.keys(criteria).forEach((criteriaKey) => {
                if (criteriaKey !== 'criterionType') {
                    query.push({
                        field: criteria[criteriaKey]['property'],
                        value: criteria[criteriaKey]['value'],
                        operator: this.getOperatorFromCriterionType(criteria[criteriaKey]['criterionType']),
                        operators: ['='],
                    });
                }
            });
        }
        return query;
    };

    private getOperatorFromCriterionType = (criterionType) => {
        let operator = '=';
        if (criterionType === 'EQ') {
            operator = '=';
        } else if (criterionType === 'LT') {
            operator = '<';
        } else if (criterionType === 'GT') {
            operator = '>';
        }
        return operator;
    };

    private getQueryFromCriteria = (criteria) => {
        const query = {
            condition: criteria?.criterionType || 'and',
            rules: [],
        };
        const displayableFields = Object.keys(this.config.fields);
        if (criteria?.criterionType) {
            query['condition'] = criteria.criterionType;
            query['rules'] = [];
            Object.keys(criteria).some((criteriaKey) => {
                if (criteriaKey !== 'criterionType') {
                    if (!criteria[criteriaKey]['property']) {
                        query['rules'].push(this.getQueryFromCriteria(criteria[criteriaKey]));
                    } else {
                        if (displayableFields.indexOf(criteria[criteriaKey]['property']) > -1) {
                            query['rules'].push({
                                field: criteria[criteriaKey]['property'],
                                value: criteria[criteriaKey]['value'],
                                operator: this.getOperatorFromCriterionType(criteria[criteriaKey]['criterionType']),
                            });
                        } else {
                            this.searchType = 'custom';
                            this.customQuery = this.getCustomQueryFromCriteria(criteria);
                            this.customFieldsCondition = criteria.criterionType || 'and';
                            return true;
                        }
                    }
                }
            });
        }
        return query;
    };

    private getSingleQueryFromCriteria = (criteria) => {
        this.singleQuery.field = criteria.property;
        this.singleQuery.operator = this.getOperatorFromCriterionType(criteria.criterionType);
        this.singleQuery.value = criteria.value;
    };

    ngOnInit() {
        this.resetQueryBuilder();
        this.isIE = this._utils.isIEBrowser();
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => (this.organizationId = event));
        this.editSubscription();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
