import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, from, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import {
    AlertError,
    AlertSuccess,
    AssignAndUnassignUsers,
    CopyRole,
    CopyRoleSuccessAction,
    CreateBundleRole,
    CreateRole,
    DeleteBundleRole,
    DeleteRole,
    GetBundleRoles,
    GetRoles,
    GetRoleUsers,
    GetSessionUserCurrentOrganizationRoles,
    RoleCrudOperataions,
    SetAssignAndUnassignUsers,
    SetBundleRoles,
    SetRolePermissions,
    SetRoles,
    SetRoleUsersIds,
    SetUserRoles,
    UpdateBundleRole,
    UpdateBundleRolePermissions,
    UpdateRoleData,
    UpdateRolesAndPermissions,
    UpdateRoleWithoutPermissions,
} from '../actions';
import {
    getBundleRoles$,
    getCurrentOrganizationId$,
    getOrgUsers$,
    getRoles$,
    getRoleUserIds$,
    getSessionUserId$,
    roleOrganizationMap$,
} from '../selectors';

@Injectable()
export class RolesEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService, private _libUtils: UtilsService) {}

    private translateMsg = (msg: string): string => translate('' + msg);

    private getSessionUserCurrentOrganizationRoles = createEffect(() =>
        this.actions$.pipe(
            ofType(GetSessionUserCurrentOrganizationRoles),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSessionUserId$),
                this.store$.select(roleOrganizationMap$)
            ),
            mergeMap(([action, organizationId, userId, roleIdsMap]) => {
                const roleIds = roleIdsMap?.[organizationId]?.[userId];
                if (roleIds?.length > 0) {
                    return [];
                }
                return from(this._api.user.getUserRolesPromise(userId)).pipe(
                    map((res) => {
                        return SetUserRoles({
                            organizationId,
                            roles: res as any,
                            userId,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private getRoles = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRoles),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                if (roles?.length > 0) {
                    return [];
                }
                return from(this._api.roles.getRoles(organizationId)).pipe(
                    map((res) => {
                        return SetRoles({
                            organizationId,
                            roles: res,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private getBundleRoles = createEffect(() =>
        this.actions$.pipe(
            ofType(GetBundleRoles),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getBundleRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                if (roles?.length > 0) {
                    return [];
                }
                return from(this._api.roles.getBundleRoles(action.bundleId)).pipe(
                    map((res) => {
                        return SetBundleRoles({
                            organizationId,
                            bundleId: action.bundleId,
                            roles: res.response.roles,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private CreateBundleRole = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateBundleRole),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getBundleRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                return from(this._api.roles.createBundleRole(action.bundleId, action.roleData)).pipe(
                    map((res) => {
                        let storedBundleRoles = CommonUtilsService.cloneObject(roles);
                        storedBundleRoles.push(res?.response);
                        this.store$.dispatch(
                            UpdateBundleRolePermissions({
                                bundleId: action.bundleId,
                                permissions: action.permissions,
                                actionType: 'CREATE',
                            })
                        );
                        return SetBundleRoles({
                            organizationId,
                            bundleId: action.bundleId,
                            roles: storedBundleRoles,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private UpdateBundleRole = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateBundleRole),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getBundleRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                return from(this._api.roles.updateBundleRole(action.bundleId, action.roleId, action.roleData)).pipe(
                    map((res) => {
                        let storedBundleRoles = CommonUtilsService.cloneObject(roles);
                        let updatedRole = storedBundleRoles.find((role) => role.id === action.roleId);
                        if (updatedRole) {
                            updatedRole.roleName = action.roleData.roleName;
                            updatedRole.displayName = action.roleData.displayName;
                            updatedRole.description = action.roleData.description;
                        }
                        return SetBundleRoles({
                            organizationId,
                            bundleId: action.bundleId,
                            roles: storedBundleRoles,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private DeleteBundleRole = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteBundleRole),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getBundleRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                return from(this._api.roles.deleteBundleRole(action.bundleId, action.roleId)).pipe(
                    map((res) => {
                        this._libUtils.alertSuccess(res?.msg || this.translateMsg('Role deleted successfully'));
                        let storedBundleRoles = CommonUtilsService.cloneObject(roles);
                        let updatedRoles = storedBundleRoles.filter((role) => role.id !== action.roleId);
                        return SetBundleRoles({
                            organizationId,
                            bundleId: action.bundleId,
                            roles: updatedRoles,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private CreateRole = createEffect(() =>
        this.actions$.pipe(
            ofType(CreateRole),
            mergeMap((action) => {
                return from(this._api.roles.createRole(action.rolesData)).pipe(
                    map((res) => {
                        const requestId = res['ec-request-id'] || res.response['ec-request-id'];
                        let updatedRoleData = CommonUtilsService.cloneObject(action.rolesData);
                        updatedRoleData['id'] = res?.response?.role?.id;
                        if (action.has4ECAccess) {
                            let data = {
                                requestId,
                                roleId: res?.response?.role?.id,
                                role: updatedRoleData,
                                permissions: action.permissions,
                            };
                            return UpdateRolesAndPermissions({ data });
                        } else {
                            return UpdateRoleData({
                                role: updatedRoleData,
                                permissions: action.permissions,
                                actionType: action.actionType,
                            });
                        }
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res?.msg }));
                    })
                );
            })
        )
    );

    private UpdateRolesAndPermissions = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateRolesAndPermissions),
            mergeMap((action) => {
                return from(this._api.roles.updateRoleData(action.data)).pipe(
                    map((res) => {
                        this.store$.dispatch(RoleCrudOperataions({ actionType: '' }));
                        return AlertSuccess({
                            message: res?.msg || this.translateMsg("Your request has been placed. It will effect after admin's approval"),
                        });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res?.msg || this.translateMsg('Failed to add role') }));
                    })
                );
            })
        )
    );

    private UpdateRoleWithoutPermissions = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateRoleWithoutPermissions),
            mergeMap((action) => {
                return from(this._api.roles.updateRoleWithoutPermissions(action.role)).pipe(
                    map((res) => {
                        this.store$.dispatch(RoleCrudOperataions({ actionType: '' }));
                        return AlertSuccess({
                            message: res?.msg || this.translateMsg("Your request has been placed. It will effect after admin's approval"),
                        });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res?.msg || this.translateMsg('Failed to add role') }));
                    })
                );
            })
        )
    );

    private UpdateRoleData = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateRoleData),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                let data = {
                    role: action.role,
                    permissions: action.permissions,
                };
                return from(this._api.roles.updateRole(data)).pipe(
                    map((res: any) => {
                        this.store$.dispatch(
                            RoleCrudOperataions({
                                actionType: action.actionType,
                            })
                        );
                        const updatedPermissions = data.permissions.map((item) => item.uuid);
                        this.store$.dispatch(
                            SetRolePermissions({
                                organizationId: organizationId,
                                roleId: data.role.id,
                                rolePermissions: updatedPermissions,
                            })
                        );
                        let storedRoles = CommonUtilsService.cloneObject(roles);
                        let updatedRoles = storedRoles.filter((role) => role.id !== action.role.id);
                        updatedRoles.push(res?.[0]?.response?.role);
                        return SetRoles({
                            organizationId,
                            roles: updatedRoles,
                        });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res?.msg }));
                    })
                );
            })
        )
    );

    private getRoleUsers = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRoleUsers),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getRoleUserIds$)),
            mergeMap(([action, organizationId, roleUsers]) => {
                if (!action.isDelete && roleUsers?.length > 0) {
                    return [];
                }
                if (action.isDelete && roleUsers?.length > 0) {
                    return of(AlertError({ message: this.translateMsg('Role cannot be deleted, as users are assigned to role.') }));
                }
                return from(this._api.roles.roleUsers(action.roleId)).pipe(
                    map((res: any) => {
                        let roleUserIds = res?.reduce((userIds, user) => {
                            userIds.push(user.userId);
                            return userIds;
                        }, []);
                        if (action.isDelete) {
                            if (res.length > 0) {
                                this._libUtils.alertError(this.translateMsg('Role cannot be deleted, as users are assigned to role.'));
                                return SetRoleUsersIds({
                                    organizationId,
                                    roleId: action.roleId,
                                    userIds: roleUserIds,
                                });
                            } else {
                                return DeleteRole({
                                    roleId: action.roleId,
                                });
                            }
                        }
                        if (!action.isDelete || action.isDelete === undefined) {
                            return SetRoleUsersIds({
                                organizationId,
                                roleId: action.roleId,
                                userIds: roleUserIds,
                            });
                        }
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res?.msg }));
                    })
                );
            })
        )
    );

    private DeleteRole = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteRole),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getRoles$)),
            mergeMap(([action, organizationId, roles]) => {
                return from(this._api.roles.deleteRole(action.roleId)).pipe(
                    map((res) => {
                        this._libUtils.alertSuccess(res?.msg || this.translateMsg('Role deleted successfully'));
                        let storedRoles = CommonUtilsService.cloneObject(roles);
                        let updatedRoles = storedRoles.filter((role) => role.id !== action.roleId);
                        return SetRoles({
                            organizationId,
                            roles: updatedRoles,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private CopyRole = createEffect(() =>
        this.actions$.pipe(
            ofType(CopyRole),
            mergeMap((action) => {
                return from(this._api.roles.copyRole(action.data)).pipe(
                    map((res) => {
                        this._libUtils.alertSuccess(res?.msg || this.translateMsg('Permissions copied successfully'));
                        return CopyRoleSuccessAction();
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    private AssignAndUnassignUsers = createEffect(() =>
        this.actions$.pipe(
            ofType(AssignAndUnassignUsers),
            withLatestFrom(
                this.store$.select(getRoleUserIds$),
                this.store$.select(getOrgUsers$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([action, roleUserIds, orgUsers, currentOrganizationId]) => {
                const allUserIds = orgUsers?.reduce((userIds, user) => {
                    userIds.push(user.id);
                    return userIds;
                }, []);
                const newlySelected = allUserIds.filter(
                    (userId) => action.selectedUserIds.includes(userId) && !roleUserIds.includes(userId)
                );
                const newlyUnselected = allUserIds.filter(
                    (userId) => !action.selectedUserIds.includes(userId) && roleUserIds.includes(userId)
                );
                const assignPromise = this.assignUsersRole('ASSIGN', newlySelected, action.roleId);
                const unassignPromise = this.assignUsersRole('UNASSIGN', newlyUnselected, action.roleId);
                return from(Promise.all([assignPromise, unassignPromise])).pipe(
                    map((res: any) => {
                        let roleUserIdsCopy = CommonUtilsService.cloneObject(roleUserIds);
                        let updatedRoleIds =
                            newlyUnselected?.length > 0
                                ? roleUserIdsCopy.filter((user) => !newlyUnselected.includes(user))
                                : roleUserIdsCopy;
                        let filteredRoleUsers = orgUsers.filter((user) => action.selectedUserIds?.includes(user.id));
                        filteredRoleUsers.forEach((user) => {
                            updatedRoleIds.push(user?.id);
                        });
                        this.store$.dispatch(
                            SetAssignAndUnassignUsers({
                                msg: res?.[0]?.response?.msg || this.translateMsg('Roles update successful'),
                            })
                        );
                        return SetRoleUsersIds({
                            organizationId: currentOrganizationId,
                            roleId: action.roleId,
                            userIds: updatedRoleIds,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg || this.translateMsg('Failed to update roles') })))
                );
            })
        )
    );

    private assignUsersRole = (action: 'ASSIGN' | 'UNASSIGN', userIds, selectedRoleId) => {
        return new Promise((resolve) => {
            if (userIds?.length === 0) {
                return resolve({});
            }
            const data = {
                roleId: selectedRoleId,
                ids: [...userIds],
                action,
            };
            this._api.roles.assignUsers(data, {
                successCallback: (res) => {
                    resolve(res);
                    userIds;
                },
                failureCallback: (res) => {
                    this._libUtils.alertError(res?.msg || this.translateMsg('Failed due to server error'));
                },
            });
        });
    };
}
