import { Injectable } from '@angular/core';
import { ApiService, ValidationsService } from 'taxilla-library';

@Injectable()
export class TaxillaApiService {
    constructor(private _api: ApiService, private _validations: ValidationsService) {}

    session = this._api.session;

    common = this._api.common;

    locations = this._api.locations;

    subscriptions = this._api.subscriptions;

    assets = this._api.assets;

    instances = this._api.instances;

    user = this._api.user;

    integrations = this._api.integrations;

    masters = this._api.masters;

    lookups = this._api.lookups;

    roles = this._api.roles;

    workflow = this._api.workflow;

    organization = this._api.organization;

    permissions = this._api.permissions;

    transformations = this._api.transformations;

    requests = this._api.requests;

    workQ = this._api.workQ;

    records = this._api.records;

    validations = this._validations;

    collaboration = this._api.collaboration;

    reports = this._api.reports;

    tags = this._api.tags;
}
