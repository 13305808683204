<div class="absolute right-2 top-2.5 z-50" [class.supportEnabled]="supportSystem" *transloco="let translate">
    <div title="{{currentLanguage}}">
        <button mat-icon-button class="!w-auto bg-white shadow-md !rounded-lg !px-2.5 right-2.5" [matMenuTriggerFor]="languagesMenu" #matLanguagesMenuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
            <div class="text-ellipsis overflow-hidden">
                <img alt="{{translate('Selected Language')}}" class="w-6 mr-2" src="assets/images/languages/{{selectedLanguage?.id}}.png">
                <span class="mr-2">{{selectedLanguage?.label}}</span>
                <span *ngIf="!matLanguagesMenuTrigger.menuOpen" class="material-icons align-middle">keyboard_arrow_down</span>
                <span *ngIf="matLanguagesMenuTrigger.menuOpen" class="material-icons align-middle">keyboard_arrow_up</span>
            </div>
        </button>
        <mat-menu #languagesMenu="matMenu">
            <ng-container *ngFor="let language of availableLanguages">
                <button mat-menu-item (click)="languageChanged(language.id)" [title]="language.label">
                    <img alt="{{translate('Language')}}" class="w-6 mr-2" src="assets/images/languages/{{language.id}}.png">
                    <span>{{language.label}}</span>
                </button>
            </ng-container>
        </mat-menu>
    </div>
</div>
<div id="signinmaindiv" [class.authFormContainer]="!hideDomain" [hidden]="hideLoginSection" *transloco="let translate;">
    <div class="signInDetailsDiv">
        <div class="cssmarquee" *ngIf="R.SHOWMARQUEE && R.MARQUEETEXT">
            <h1 title="{{R.MARQUEETEXT}}">{{R.MARQUEETEXT}} </h1>
        </div>
        <mat-card class="signInCard">
            <div class="row signInRowCls">
                <div class="loginSummary col-6">
                    <div class="loginSummaryContainer">
                        <div class="loginSummaryHeader">
                            <span>
                                <img src="assets/images/{{R.LOGO_HREF}}logo.png" class="taxillaLogo" alt="{{translate('Logo')}}">
                            </span>
                        </div>
                        <div class="loginfooterLogo" *ngIf="['emguat', 'emgprod', 'kpmguat', 'kpmgprod'].indexOf(R.PLATFORM) >= 0">
                            <span>Powered by<img class="logo loginPageFooterLogo" src="assets/images/encomply/footer-logo.png" alt="{{translate('Taxilla')}}"></span>
                        </div>
                    </div>
                </div>
                <div class="loginDetails col-6">
                    <mat-drawer-container class="drawerContainer" autosize>
                        <mat-drawer #drawer class="loginMatDrawer" mode="side" disableClose position='end'>
                            <ng-container *ngIf="isForgotUserId && !isForgotPaswd && !securityAuthenticate">
                                <mat-card-title class="text-center">
                                    {{translate('forgotUserId')}}
                                </mat-card-title>
                                <div class="loginDetailSection">
                                    <div class="pdbtm15">
                                        <material-input [autofocus]="false" [placeholder]="translate('organizationCode')" [model]="forgotUsername.orgId" (modelChange)="forgotUsername.orgId = $event" [name]="'forgotUserNameOrgCode'" [id]="'forgotUserNameOrgCode'" [required]="true" [errors]="forgotUsernameErrors.orgId">
                                        </material-input>
                                    </div>
                                    <div>
                                        <material-input [autofocus]="false" [placeholder]="translate('email')" [model]="forgotUsername.email" (modelChange)="forgotUsername.email = $event" [name]="'forgotUserNameEmail'" [id]="'forgotUserNameEmail'" [required]="true" [errors]="forgotUsernameErrors.email">
                                        </material-input>
                                    </div>
                                    <div class="row signupCaptcha">
                                        <label class="col-6 signupDetailLabel signupCaptchaLabel">
                                            <div class="signupCaptchaImg">
                                                <img *ngIf="forgotUsernameCaptchaUrl && forgotUsernameCaptchaUrl.length > 0" src="data:image/jpeg;base64,{{forgotUsernameCaptchaUrl}}" class="" alt="{{translate('captcha')}}">
                                            </div>
                                            <mat-icon class="material-icons cursorPointer" [title]="translate('refreshCaptcha')" (click)="getForgotUsernameCaptcha()">refresh</mat-icon>
                                        </label>
                                        <div class="signupDetailValue col-6 signupCaptchaInput">
                                            <material-input [autofocus]="false" [placeholder]="translate('captcha')" [required]="true" [errors]="forgotUsernameErrors.captcha" [model]="forgotUsername.captcha" [classList]="[]" [debounceAfter]="1000" (modelChange)="forgotUsername.captcha = $event" (debounceEventHandler)="checkForgotPswdCaptcha()">
                                            </material-input>
                                        </div>
                                    </div>
                                    <div class="submitSignup text-center">
                                        <material-button [label]="translate('submit')" (onTrigger)="submitForgotUserNameDetails()">
                                        </material-button>
                                    </div>
                                    <div class="forgotPasswordLink">
                                        <span (click)="navigateTologin()" [title]="translate('signIn')">{{translate('signIn')}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isForgotPaswd && !isForgotUserId && !securityAuthenticate">
                                <mat-card-title class="text-center">
                                    {{translate('forgotPassword')}}
                                </mat-card-title>
                                <div class="forgotPasswordSection">
                                    <div class="forgotPasswordInputField">
                                        <material-input [autofocus]="true" [placeholder]="translate('userId')" [model]="forgot.userId" (modelChange)="forgot.userId = $event" [name]="'forgotUserId'" [id]="'forgotUserId'" [required]="true" [errors]="forgotPaswdErrors.userId"></material-input>
                                    </div>
                                    <div class="row signupCaptcha">
                                        <label class="col-6 signupDetailLabel signupCaptchaLabel">
                                            <div class="signupCaptchaImg">
                                                <img *ngIf="forgotPswdCaptchaUrl?.length > 0" src="data:image/jpeg;base64,{{forgotPswdCaptchaUrl}}" class="" alt="{{translate('captcha')}}">
                                            </div>
                                            <mat-icon class="material-icons cursorPointer" [title]="translate('refreshCaptcha')" (click)="getForgotPswdCaptcha()">refresh</mat-icon>
                                        </label>
                                        <div class="signupDetailValue col-6 signupCaptchaInput">
                                            <material-input [autofocus]="false" [placeholder]="translate('captcha')" [required]="true" [errors]="forgotPaswdErrors.captcha" [model]="forgot.captcha" [classList]="[]" [debounceAfter]="1000" (modelChange)="forgot.captcha = $event" (debounceEventHandler)="checkForgotPswdCaptcha()">
                                            </material-input>
                                        </div>
                                    </div>
                                    <div class="submitSignup text-center">
                                        <material-button [label]="translate('submit')" (onTrigger)="submitForgotPasswordDetails()">
                                        </material-button>
                                    </div>
                                    <div class="forgotPasswordLink">
                                        <span (click)="navigateTologin()" [title]="translate('signIn')">{{translate('signIn')}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-drawer>
                        <mat-drawer-content>
                            <mat-card-title class="text-center">
                                <strong>{{translate('signIn')}}</strong>
                            </mat-card-title>
                            <div class="loginDetailSection" [hidden]="hideAuthForm">
                                <div class="domainSection" [hidden]="hideDomain">
                                    <material-input [autofocus]="true" [placeholder]="translate('identityProvider')" [model]="login.domain" (modelChange)="login.domain = $event" [name]="translate('identityProvider')" [id]="'domain'" (keyUpEventHandler)="checkKeyCode($event, 'IP')" [debounceAfter]="1000">
                                    </material-input>
                                </div>
                                <div class="domainAndLoginSeparator" [hidden]="hideDomain">
                                    <span class="separatorText">
                                        {{translate('or')}}
                                    </span>
                                </div>
                                <div class="loginSection">
                                    <div>
                                        <material-input [autofocus]="true" [placeholder]="translate('userId')" [model]="login.userId" (modelChange)="login.userId = $event" [name]="'userId'" [id]="'userId'" [required]="true" [errors]="errors.userId" (keyUpEventHandler)="checkKeyCode($event, 'Login')" [debounceAfter]="1000">
                                        </material-input>
                                        <div class="forgotUserLink" *ngIf="['dbcloud', 'dbtest', 'dbuat', 'dbprod', 'dbdr'].indexOf(R.PLATFORM) < 0">
                                            <span (click)="navigateToForgotUserName()" class="forgotUsernameCls" [title]="translate('forgotUserId')">{{translate('forgotUserId')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <material-input-password [autofocus]="false" [placeholder]="translate('password')" [model]="login.password" (modelChange)="login.password = $event" [name]="'userPassword'" [id]="'userPassword'" [required]="true" [errors]="errors.password" (keyUpEventHandler)="checkKeyCode($event, 'Login')" [debounceAfter]="1000">
                                        </material-input-password>
                                        <div class="forgotUserLink" *ngIf="['dbcloud', 'dbtest', 'dbuat', 'dbprod', 'dbdr'].indexOf(R.PLATFORM) < 0">
                                            <span (click)="navigateToForgotPassword()" class="forgotUsernameCls" [title]="translate('forgotPassword')">{{translate('forgotPassword')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="submitSignup text-center">
                                    <material-button [label]="translate('submit')" (onTrigger)="submitDetails()"></material-button>
                                </div>
                                <div class="forgotPasswordLink signupLink" *ngIf="['emguat', 'emgprod'].indexOf(R.PLATFORM) < 0 && !hideSignUpPage">
                                    {{translate('dontHaveAccount')}} <span class="btn-link" (click)="navigatingToSignUp()" [routerLink]="'/auth/signup'" [title]="translate('signUp')">{{translate('signUp')}}</span>
                                </div>
                            </div>
                            <div class="domainAutoLogin" [hidden]="!hideAuthForm">
                                <div class="autoLoginTextContainer">
                                    <div class="authLoginText">Logging In With ...</div>
                                    <div class="authLoginTextTitle">
                                        {{login.domain.substring(0, 1)}}
                                    </div>
                                    <div class="authLoginBody">
                                        {{login.domain}}
                                    </div>
                                </div>
                            </div>
                        </mat-drawer-content>
                    </mat-drawer-container>

                </div>
            </div>
        </mat-card>
    </div>
</div>

<router-outlet></router-outlet>
