import { Component } from '@angular/core';

import {
    bounceInDownOnEnterAnimation,
    bounceInLeftOnEnterAnimation,
    bounceInOnEnterAnimation,
    bounceInRightOnEnterAnimation,
    bounceInUpOnEnterAnimation,
    bounceOnEnterAnimation,
    bounceOutDownOnLeaveAnimation,
    bounceOutLeftOnLeaveAnimation,
    bounceOutOnLeaveAnimation,
    bounceOutRightOnLeaveAnimation,
    bounceOutUpOnLeaveAnimation,
    collapseLeftOnLeaveAnimation,
    collapseOnLeaveAnimation,
    expandOnEnterAnimation,
    expandRightOnEnterAnimation,
    fadeInDownBigOnEnterAnimation,
    fadeInDownOnEnterAnimation,
    fadeInExpandOnEnterAnimation,
    fadeInExpandRightOnEnterAnimation,
    fadeInLeftBigOnEnterAnimation,
    fadeInLeftOnEnterAnimation,
    fadeInOnEnterAnimation,
    fadeInRightBigOnEnterAnimation,
    fadeInRightOnEnterAnimation,
    fadeInUpBigOnEnterAnimation,
    fadeInUpOnEnterAnimation,
    fadeOutCollapseLeftOnLeaveAnimation,
    fadeOutCollapseOnLeaveAnimation,
    fadeOutDownBigOnLeaveAnimation,
    fadeOutDownOnLeaveAnimation,
    fadeOutLeftBigOnLeaveAnimation,
    fadeOutLeftOnLeaveAnimation,
    fadeOutOnLeaveAnimation,
    fadeOutRightBigOnLeaveAnimation,
    fadeOutRightOnLeaveAnimation,
    fadeOutUpBigOnLeaveAnimation,
    fadeOutUpOnLeaveAnimation,
    flashOnEnterAnimation,
    flipInXOnEnterAnimation,
    flipInYOnEnterAnimation,
    flipOnEnterAnimation,
    flipOutXOnLeaveAnimation,
    flipOutYOnLeaveAnimation,
    hingeOnLeaveAnimation,
    jackInTheBoxOnEnterAnimation,
    jelloOnEnterAnimation,
    lightSpeedInOnEnterAnimation,
    lightSpeedOutOnLeaveAnimation,
    pulseOnEnterAnimation,
    rollInOnEnterAnimation,
    rollOutOnLeaveAnimation,
    rotateInDownLeftOnEnterAnimation,
    rotateInDownRightOnEnterAnimation,
    rotateInOnEnterAnimation,
    rotateInUpLeftOnEnterAnimation,
    rotateInUpRightOnEnterAnimation,
    rotateOutDownLeftOnLeaveAnimation,
    rotateOutDownRightOnLeaveAnimation,
    rotateOutOnLeaveAnimation,
    rotateOutUpLeftOnLeaveAnimation,
    rotateOutUpRightOnLeaveAnimation,
    rubberBandOnEnterAnimation,
    shakeOnEnterAnimation,
    slideInDownOnEnterAnimation,
    slideInLeftOnEnterAnimation,
    slideInRightOnEnterAnimation,
    slideInUpOnEnterAnimation,
    slideOutDownOnLeaveAnimation,
    slideOutLeftOnLeaveAnimation,
    slideOutRightOnLeaveAnimation,
    slideOutUpOnLeaveAnimation,
    swingOnEnterAnimation,
    tadaOnEnterAnimation,
    wobbleOnEnterAnimation,
    zoomInDownOnEnterAnimation,
    zoomInLeftOnEnterAnimation,
    zoomInOnEnterAnimation,
    zoomInRightOnEnterAnimation,
    zoomInUpOnEnterAnimation,
    zoomOutDownOnLeaveAnimation,
    zoomOutLeftOnLeaveAnimation,
    zoomOutOnLeaveAnimation,
    zoomOutRightOnLeaveAnimation,
    zoomOutUpOnLeaveAnimation,
} from '../../lib/';

@Component({
    selector: 'app-demo-on-enter-on-leave',
    templateUrl: './demo-on-enter-on-leave.component.html',
    styleUrls: ['./demo-on-enter-on-leave.component.scss'],
    animations: [
        bounceInRightOnEnterAnimation({ anchor: 'enter1' }),
        bounceInRightOnEnterAnimation({ anchor: 'enter2', delay: 100 }),
        bounceInRightOnEnterAnimation({ anchor: 'enter3', delay: 200, animateChildren: 'none' }),
        bounceOnEnterAnimation(),
        flashOnEnterAnimation(),
        pulseOnEnterAnimation(),
        rubberBandOnEnterAnimation(),
        shakeOnEnterAnimation(),
        swingOnEnterAnimation(),
        tadaOnEnterAnimation(),
        wobbleOnEnterAnimation(),
        jelloOnEnterAnimation(),
        flipOnEnterAnimation(),
        bounceInOnEnterAnimation(),
        bounceInUpOnEnterAnimation(),
        bounceOutOnLeaveAnimation(),
        bounceOutDownOnLeaveAnimation(),
        bounceInDownOnEnterAnimation(),
        bounceOutUpOnLeaveAnimation(),
        bounceInLeftOnEnterAnimation(),
        bounceInRightOnEnterAnimation(),
        bounceOutLeftOnLeaveAnimation(),
        bounceOutRightOnLeaveAnimation(),
        fadeInOnEnterAnimation(),
        fadeInUpOnEnterAnimation(),
        fadeInDownOnEnterAnimation(),
        fadeInLeftOnEnterAnimation(),
        fadeInRightOnEnterAnimation(),
        fadeInUpBigOnEnterAnimation(),
        fadeInDownBigOnEnterAnimation(),
        fadeInLeftBigOnEnterAnimation(),
        fadeInRightBigOnEnterAnimation(),
        fadeOutOnLeaveAnimation(),
        fadeOutUpOnLeaveAnimation(),
        fadeOutDownOnLeaveAnimation(),
        fadeOutLeftOnLeaveAnimation(),
        fadeOutRightOnLeaveAnimation(),
        fadeOutUpBigOnLeaveAnimation(),
        fadeOutDownBigOnLeaveAnimation(),
        fadeOutLeftBigOnLeaveAnimation(),
        fadeOutRightBigOnLeaveAnimation(),
        flipInXOnEnterAnimation(),
        flipInYOnEnterAnimation(),
        flipOutXOnLeaveAnimation(),
        flipOutYOnLeaveAnimation(),
        lightSpeedInOnEnterAnimation(),
        lightSpeedOutOnLeaveAnimation(),
        rotateInOnEnterAnimation(),
        rotateInUpLeftOnEnterAnimation(),
        rotateInUpRightOnEnterAnimation(),
        rotateInDownLeftOnEnterAnimation(),
        rotateInDownRightOnEnterAnimation(),
        rotateOutOnLeaveAnimation(),
        rotateOutUpLeftOnLeaveAnimation(),
        rotateOutUpRightOnLeaveAnimation(),
        rotateOutDownLeftOnLeaveAnimation(),
        rotateOutDownRightOnLeaveAnimation(),
        slideInRightOnEnterAnimation(),
        slideInUpOnEnterAnimation(),
        slideInDownOnEnterAnimation(),
        slideInLeftOnEnterAnimation(),
        slideOutUpOnLeaveAnimation(),
        slideOutDownOnLeaveAnimation(),
        slideOutLeftOnLeaveAnimation(),
        slideOutRightOnLeaveAnimation(),
        zoomInOnEnterAnimation(),
        zoomInUpOnEnterAnimation(),
        zoomInDownOnEnterAnimation(),
        zoomInLeftOnEnterAnimation(),
        zoomInRightOnEnterAnimation(),
        zoomOutOnLeaveAnimation(),
        zoomOutUpOnLeaveAnimation(),
        zoomOutDownOnLeaveAnimation(),
        zoomOutLeftOnLeaveAnimation(),
        zoomOutRightOnLeaveAnimation(),
        hingeOnLeaveAnimation(),
        jackInTheBoxOnEnterAnimation(),
        rollInOnEnterAnimation(),
        rollOutOnLeaveAnimation(),
        expandOnEnterAnimation({ duration: 400 }),
        collapseOnLeaveAnimation({ duration: 400 }),
        fadeInExpandOnEnterAnimation({ duration: 400 }),
        fadeOutCollapseOnLeaveAnimation({ duration: 400 }),
        expandRightOnEnterAnimation({ duration: 400 }),
        collapseLeftOnLeaveAnimation({ duration: 400 }),
        fadeInExpandRightOnEnterAnimation({ duration: 400 }),
        fadeOutCollapseLeftOnLeaveAnimation({ duration: 400 }),
    ],
})
export class DemoOnEnterOnLeaveComponent {
    options = [
        {
            label: 'Bouncing',
            animations: ['bounceIn', 'bounceInUp', 'bounceInDown', 'bounceInLeft', 'bounceInRight'],
        },
        {
            label: 'Fading',
            animations: [
                'fadeInOut',
                'fadeInUpOutUp',
                'fadeInDownOutDown',
                'fadeInLeftOutRight',
                'fadeInRightOutLeft',
                'fadeInUpBigOutUpBig',
                'fadeInDownBigOutDownBig',
                'fadeInLeftBigOutRightBig',
                'fadeInRightBigOutLeftBig',
            ],
        },
        {
            label: 'Flippers',
            animations: ['flipX', 'flipY'],
        },
        {
            label: 'LightSpeed',
            animations: ['lightSpeed'],
        },
        {
            label: 'Rotating',
            animations: ['rotateInOut', 'rotateInOutDownLeft', 'rotateInOutDownRight', 'rotateInOutUpLeft', 'rotateInOutUpRight'],
        },
        {
            label: 'Sliding',
            animations: ['slideInUpOutUp', 'slideInDownOutDown', 'slideInLeftOutRight', 'slideInRightOutLeft'],
        },
        {
            label: 'Zooming',
            animations: ['zoomInOut', 'zoomInUpOutUp', 'zoomInDownOutDown', 'zoomInLeftOutRight', 'zoomInRightOutLeft'],
        },
        {
            label: 'Specials',
            animations: ['jackInTheBoxOnEnterHingeOnLeave', 'rollInOut'],
        },
        {
            label: 'Attention Seekers',
            animations: ['bounce', 'flash', 'pulse', 'rubberBand', 'shake', 'swing', 'tada', 'wobble', 'jello', 'flip'],
        },
        {
            label: 'Other',
            animations: [
                'expandCollapse',
                'fadeInExpandFadeOutCollapse',
                'expandRightCollapseLeft',
                'fadeInExpandRightFadeOutCollapseLeft',
            ],
        },
    ];
    animation = 'bounceIn';

    state = true;

    toggleState() {
        this.state = !this.state;
    }
}
