import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    AlertSuccess,
    AssetService,
    AssignAndUnassignUsers,
    BundleRoleCrudOperataions,
    CommonUtilsService,
    ConfirmationDialogService,
    Connection,
    CopyRole,
    CopyRoleSuccessAction,
    CreateBundleRole,
    CreateRole,
    DeleteBundleRole,
    GetAllRolePermissions,
    getAllRolePermissions$,
    GetBundlePermissions,
    getBundlePermissions$,
    GetBundleRolePermissions,
    getBundleRolePermissions$,
    GetBundleRoles,
    getBundleRoles$,
    getCurrentOrganization$,
    GetOrganizationChildHierarchy,
    getOrganizationChildHierarchy$,
    GetOrganizationPartners,
    getOrganizationPartners$,
    GetOrgUsers,
    getOrgUsers$,
    GetRolePermissions,
    getRolePermissions$,
    GetRoles,
    getRoles$,
    getRoleUserIds$,
    GetRoleUsers,
    getServicesByFilterWithoutPagination$,
    Permission,
    Role,
    RoleCrudOperataions,
    SetAssignAndUnassignUsers,
    SetBundleId,
    SetSelectedOrgId,
    SetSelectedRoleId,
    UpdateBundleRole,
    UpdateBundleRolePermissions,
    UpdateRoleData,
    UpdateRoleWithoutPermissions,
    UtilsService,
} from 'taxilla-library';

import { UsersListService } from '../../services/users-list.service';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
    @Input() organizationId: string;
    @Input() bundleId: string;

    @ViewChild('rolesStepper', { static: false }) rolesStepper: MatStepper;
    @ViewChild('rolesDrawer', { static: false }) rolesDrawer: MatDrawer;

    public roleTable: BehaviorSubject<Role[]> = new BehaviorSubject([]);
    public rolesObject = new Role({});
    private roles = [];
    private organization: any;
    public roleTableColumns = [
        {
            displayName: translate('Role Name'),
            name: 'roleName',
            show: true,
        },
        {
            displayName: translate('Display Name'),
            name: 'displayName',
            show: true,
        },
        {
            displayName: translate('Description'),
            name: 'description',
            show: true,
        },
        {
            displayName: translate('Role Type'),
            name: 'roleType',
            show: false,
        },
        {
            displayName: translate('Organization Id'),
            name: 'organizationId',
            show: false,
        },
        {
            displayName: translate('Permissions'),
            name: 'permissions',
            show: false,
        },
        {
            displayName: translate('Role ID'),
            name: 'roleId',
            show: false,
        },
        {
            displayName: translate('ID'),
            name: 'id',
            show: false,
        },
    ];
    public hideTableColumns = [
        translate('Organization Id'),
        translate('Permissions'),
        translate('Role ID'),
        translate('ID'),
        translate('Role Type'),
    ];
    public primaryColumns = ['Display Name', 'Description'];
    public errors = {
        displayName: [],
        description: [],
        roleName: [],
    };
    private selectedRole: any;
    private allRolePermissions = {};
    private rolePermissions: any;
    private isStep1Completed: number;
    private selectedRolePermissions = [];
    public updateRole: boolean = false;
    public roleLabel: string;
    public copyRoleBol: boolean;
    public locations = [];
    private selectedRoleId: string;
    public assignUsersBol: boolean;
    public partnerOrganizations = [];
    public selectedPartnerOrg: string;
    public users = [];
    private oldUserIdsArray = [];
    public roleSearchValue: string;
    public selectedRoleName: string;
    public allUsersSelected: boolean;
    public allLocationsSelected: boolean;
    private reviewPermissions = [];
    public totalPermissions = new BehaviorSubject([]);
    public formValid = false;
    public totalReviewPermissions = new BehaviorSubject([]);
    public isViewRole = false;
    private allRoles = new BehaviorSubject([]);
    private previousPage = undefined;
    private removePermissions = ['Manage Catalogue', 'Manage Event Calendar'];
    public searchUser: string;
    public searchLocation: string;
    public noAssignUsers = false;
    public noRelatedLocations = false;
    public isAddRole = false;
    public isNextClicked = false;
    public originalRoleObject: Role;
    public originalPermissions: any;
    private bundleApps: AssetService[];
    private unSubscribe = new Subject<void>();
    private loadingBundleRoles: boolean = false;
    private isView: boolean = false;
    selectedPermissionIds: any[];

    constructor(
        public _utils: Utils,
        private _taxilla: TaxillaApiService,
        private route: ActivatedRoute,
        private R: RootScope,
        private confirmationService: ConfirmationDialogService,
        private _commonUtils: CommonUtilsService,
        private _libUtils: UtilsService,
        private element: ElementRef,
        private store$: Store,
        private _usersList: UsersListService,
        private actions: Actions
    ) {}

    private startController = () => {
        this.getRoles();
        this.getAllRolePermissions();
    };

    /**
     * to copy the role
     */
    public copyRole = (role?) => {
        const record = this.roles.find((roleObject) => roleObject.id === role.id);
        if (record.authority === 'PROVIDER_ADMIN' || record.authority === 'CUSTOMER_ADMIN') {
            return this._libUtils.alertError(translate('Cannot copy system default roles'));
        }
        this.updateRole = false;
        this.copyRoleBol = true;
        this.allLocationsSelected = false;
        this.selectedRoleId = role.id;
        this.selectedRoleName = role.roleName;
        this.formValid = false;
        this.locations = [];
        this.searchLocation = '';
        this.rolesDrawer.toggle();
        this.store$.dispatch(GetOrganizationChildHierarchy());
    };

    private getNextNodes = (organization) => {
        organization.forEach((org) => {
            this.locations.push(org.organization);
            if (org.nextNodes.length > 0) {
                this.getNextNodes(org.nextNodes);
            }
        });
    };

    public assignUsers = (role?) => {
        this.users = [];
        this.partnerOrganizations = [];
        this.selectedPartnerOrg = '';
        this.copyRoleBol = false;
        this.searchUser = '';
        this.updateRole = false;
        this.assignUsersBol = true;
        this.allUsersSelected = false;
        this.formValid = false;
        this.rolesDrawer.toggle();
        this.selectedRole = role;
        this.selectedRoleId = role.id;
        this.selectedRoleName = role.roleName;
        this.store$.dispatch(GetOrganizationPartners({ organizationId: this.organizationId }));
    };

    private getRoleUsers = (role) => {
        if (role) {
            this.store$.dispatch(SetSelectedRoleId({ roleId: role.id }));
            this.store$.dispatch(GetRoleUsers({ roleId: role.id }));
        }
    };

    public getOrgUsers = (value) => {
        this.allUsersSelected = false;
        this.formValid = false;
        if (value?.length) {
            this.store$.dispatch(SetSelectedOrgId({ orgId: value }));
            this.store$.dispatch(GetOrgUsers());
        }
    };

    /**
     * Submit copy Role Details
     */
    public SubmitCopyRole = () => {
        let locationsIds = '';
        for (let i = 0; i < this.locations.length; i++) {
            if (this.locations[i].selected) {
                locationsIds += this.locations[i].id + ',';
            }
        }
        locationsIds = locationsIds.substring(0, locationsIds.length - 1);
        const data = {
            roleId: this.selectedRoleId,
            locationIds: locationsIds,
        };
        this.store$.dispatch(CopyRole({ data }));
    };

    public cancelCopyRole = () => {
        this.copyRoleBol = false;
        this.allLocationsSelected = false;
        this.formValid = false;
        this.rolesDrawer.toggle();
    };
    /**
     * to delete the role
     */
    public deleteRole = (role?) => {
        const confirmConfig = {
            title: this.translateMsg('Delete Confirmation'),
            message: this.translateMsg('Are you sure you want to delete ?'),
            btnOkText: this.translateMsg('Ok'),
            btnCancelText: this.translateMsg('Cancel'),
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText, 'sm')
            .subscribe((action) => {
                if (action) {
                    if (this.bundleId) {
                        this.store$.dispatch(
                            DeleteBundleRole({
                                bundleId: this.bundleId,
                                roleId: role.id,
                            })
                        );
                        return;
                    }
                    this.store$.dispatch(SetSelectedRoleId({ roleId: role.id }));
                    this.store$.dispatch(GetRoleUsers({ roleId: role.id, isDelete: true }));
                }
            });
    };

    /**
     * to add Roles
     */
    private addRole = (role?, isView?) => {
        this.isView = isView;
        if (this.rolePermissions === undefined && !this.bundleId) {
            this.rolePermissions = this._commonUtils.getFromStorage('rolePermissions');
        }
        this.rolesObject = new Role(
            role || {
                roleType: 'custom',
                organizationId: this.organizationId,
            }
        );
        this.copyRoleBol = false;
        this.assignUsersBol = false;
        Object.keys(this.errors).forEach((key) => {
            this.errors[key] = [];
        });
        if (role?.id) {
            this.updateRole = true;
            this.roleLabel = this.translateMsg('Update Role');
            this.store$.dispatch(SetSelectedRoleId({ roleId: role.id }));
            this.loadingBundleRoles = true;
            this.getRolePermissions(role);
        } else {
            this.updateRole = false;
            this.isAddRole = true;
            this.roleLabel = this.translateMsg('Add Role');
            if (this.rolePermissions) {
                this.rolePermissions.forEach((element) => {
                    this.clearRolePermissions(element);
                });
            }
        }
        this.buildRolePermissions(isView);
    };

    private getRolePermissions = (role) => {
        if (this.bundleId) {
            this.store$.dispatch(
                GetBundleRolePermissions({
                    bundleId: this.bundleId,
                    roleId: role.id,
                })
            );
            return;
        } else {
            this.store$.dispatch(GetRolePermissions({ role }));
            return;
        }
    };

    private buildRolePermissions = (isView) => {
        this.loadingBundleRoles = false;
        this.buildRolePermissionsData();
        !this.rolesDrawer?.opened && this.rolesDrawer?.toggle();
        setTimeout(() => this.viewRoles(isView), 100);
        this.originalRoleObject = CommonUtilsService.cloneObject(this.rolesObject);
        this.originalPermissions = CommonUtilsService.cloneObject(this.rolePermissions);
    };

    private viewRoles = (isView?) => {
        const rolecls = this.element.nativeElement.querySelector('.manageroles');
        if (isView) {
            (rolecls.querySelector('.mat-horizontal-stepper-header-container') as HTMLElement).style.display = 'none';
            this.validateRolePermissions(true);
            (rolecls.querySelector('.roleMatDrawer') as HTMLElement).classList.add('transform');
        } else {
            (rolecls.querySelector('.mat-horizontal-stepper-header-container') as HTMLElement).style.display = 'flex';
            (rolecls.querySelector('.roleMatDrawer') as HTMLElement).classList.add('transform');
        }
        this.isViewRole = isView;
        this.updateRole = !isView;
        this.rolesStepper && (this.rolesStepper.selectedIndex = this.isViewRole ? 2 : 0);
    };

    private clearRolePermissions = (permission) => {
        if (permission.permissions?.length > 0) {
            permission.permissions.selected = false;
            permission.permissions.forEach((subPermission) => {
                subPermission.selected = false;
            });
        }
        if (permission.subSections?.length > 0) {
            permission.subSections.forEach((element) => {
                element.selected = false;
                this.clearRolePermissions(element);
            });
        }
        permission.selected = false;
    };

    private getSelectedRolePermissions = (Selectedpermissions) => {
        this.rolePermissions?.forEach((permissions) => {
            this.getPermissions(permissions, Selectedpermissions);
        });
    };

    private getPermissions = (permission, selectedPermissions) => {
        if (permission.permissions?.length) {
            permission.permissions.selected = false;
            let selectedPermissionsLength = 0;
            const slectedPermissionType = typeof selectedPermissions[0];
            permission.permissions.forEach((subPermission) => {
                let found = false;
                if (Array.isArray(selectedPermissions)) {
                    switch (slectedPermissionType) {
                        case 'object':
                            found =
                                selectedPermissions.find(
                                    (permissionObject) => permissionObject.id === (subPermission.id || subPermission.uuid)
                                ) !== undefined;
                            break;
                        case 'number':
                        case 'string':
                            found = selectedPermissions.includes(subPermission.id) || selectedPermissions.includes(subPermission.uuid);
                            break;
                        default:
                            found = selectedPermissions.includes(subPermission.id) || selectedPermissions.includes(subPermission.uuid);
                            break;
                    }
                    if (found) {
                        subPermission.selected = true;
                        selectedPermissionsLength++;
                    } else {
                        subPermission.selected = false;
                    }
                }
            });
            permission.permissions.selected = selectedPermissionsLength === permission.permissions.length;
        }
        if (permission.subSections && permission.subSections.length > 0) {
            permission.subSections.forEach((element) => {
                element.selected = false;
                this.getPermissions(element, selectedPermissions);
            });
        }
        // Deciding whether the section heading or SelectAll should be enabled.
        let selectedSubSectionPermissionsLength = 0;
        for (let i = 0; i < permission.subSections.length; i++) {
            if (
                ((permission.subSections[i].permissions && permission.subSections[i].permissions.length > 0) ||
                    (permission.subSections[i].subSections && permission.subSections[i].subSections.length > 0)) &&
                permission.subSections[i].selected
            ) {
                selectedSubSectionPermissionsLength++;
            }
            if (
                permission.subSections[i].permissions &&
                permission.subSections[i].permissions.length === 0 &&
                permission.subSections[i].subSections &&
                permission.subSections[i].subSections.length === 0
            ) {
                selectedSubSectionPermissionsLength++;
            }
        }
        if (permission.permissions && permission.permissions.length > 0 && permission.subSections && permission.subSections.length > 0) {
            permission.selected = permission.permissions.selected && permission.subSections.length === selectedSubSectionPermissionsLength;
        } else if (
            permission.permissions &&
            permission.permissions.length > 0 &&
            !(permission.subSections && permission.subSections.length > 0)
        ) {
            permission.selected = permission.permissions.selected;
        } else if (
            !(permission.permissions && permission.permissions.length > 0) &&
            permission.subSections &&
            permission.subSections.length > 0
        ) {
            permission.selected = permission.subSections.length === selectedSubSectionPermissionsLength;
        } else {
            permission.selected = false;
        }
    };

    /**
     * get Roles
     */
    private getRoles = () => {
        if (this.bundleId) {
            this.store$.dispatch(SetBundleId({ bundleId: this.bundleId }));
            this.store$.dispatch(GetBundleRoles({ bundleId: this.bundleId }));
        } else {
            this.store$.dispatch(GetRoles());
        }
    };

    private buildRolesTableData = () => {
        this.roleTable.next(
            this.roles.sort((a, b) =>
                a.roleName.trim().toLowerCase() > b.roleName.trim().toLowerCase()
                    ? 1
                    : a.roleName.trim().toLowerCase() < b.roleName.trim().toLowerCase()
                    ? -1
                    : 0
            )
        );
    };

    public validateRolePermissions = (view?: boolean) => {
        this.selectedRolePermissions = [];
        let allPermissions = [];
        this.rolePermissions?.forEach((permissions) => {
            this.getSelectedPermissions(permissions);
        });
        if (view || this.selectedRolePermissions?.length > 0) {
            this.complete();
        } else {
            this._libUtils.alertError(this.translateMsg('Please Select Role Permissions'));
        }
        allPermissions = CommonUtilsService.cloneObject(this.rolePermissions);
        allPermissions.forEach((permissions) => {
            permissions.selected = this.isSelected(permissions);
        });
        this.getReviewPermissions(allPermissions);
    };

    private getReviewPermissions = (permissions) => {
        this.reviewPermissions = [];
        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].selected) {
                this.reviewPermissions.push(permissions[i]);
            }
        }
        this.buildReviewPermissions();
    };

    private buildReviewPermissions = () => {
        this.totalReviewPermissions.next(this.reviewPermissions);
    };

    private getSelectedPermissions = (permission) => {
        if (permission.permissions && permission.permissions.length > 0) {
            permission.permissions.forEach((subPermission) => {
                if (subPermission.selected && !(this.removePermissions.indexOf(subPermission.displayName) > -1)) {
                    this.selectedRolePermissions.push(subPermission);
                }
            });
        }
        if (permission.subSections && permission.subSections.length > 0) {
            permission.subSections.forEach((element) => {
                this.getSelectedPermissions(element);
            });
        }
    };

    /**
     * to get all role permissions
     */
    private getAllRolePermissions = () => {
        if (this.bundleId) {
            const selectedApps = this.bundleApps.reduce((ids, app) => {
                app['selected'] && ids.push(app.serviceId);
                return ids;
            }, []);
            this.store$.dispatch(
                GetBundlePermissions({
                    bundleId: this.bundleId,
                    selectedApps: selectedApps,
                })
            );
            return;
        }
        if (this.rolePermissions === null || this.rolePermissions === undefined) {
            this.store$.dispatch(GetAllRolePermissions());
        } else {
            this.buildRolePermissionsData();
        }
    };

    private buildRolePermissionsData = () => {
        this.totalPermissions.next(this.rolePermissions);
    };

    private convertPermissions = (permissionsObject) => {
        const permissions = [];
        for (const key in permissionsObject) {
            if (key !== 'name' && key !== 'permissions' && key !== 'displayName') {
                if (permissionsObject.hasOwnProperty(key)) {
                    const permissionObject = permissionsObject[key];
                    const obj = {
                        name: '',
                        displayName: '',
                        permissions: permissionObject['permissions'] || [],
                        subSections: [],
                        key,
                        uid: this._utils.guid(),
                    };
                    obj['name'] = key;
                    if (Array.isArray(permissionObject)) {
                        permissionObject.forEach((element) => {
                            if (element && element.actionName) {
                                obj['permissions'].push(element);
                            }
                            if (element && element.name) {
                                obj['subSections'].push({
                                    name: element.name,
                                    displayName: element.displayName || element.name,
                                    permissions: element.permissions || [],
                                    subSections: this.convertPermissions(element),
                                    key,
                                    uid: this._utils.guid(),
                                });
                            }
                            if ((element.serviceDisplayName || element.permissionType) && !obj['displayName']) {
                                obj['displayName'] = element.serviceDisplayName || element.permissionType;
                            }
                        });
                    } else {
                        obj['subSections'] = this.convertPermissions(permissionObject);
                    }
                    permissions.push(obj);
                }
            }
        }
        return permissions;
    };

    public validateRoleDetails = () => {
        this.isStep1Completed = 0;
        if (
            this.rolesObject['description'] === undefined ||
            (this.rolesObject && this.rolesObject['description'].length < 0) ||
            this.rolesObject['description'].trim() === ''
        ) {
            this.errors.description.push(this.translateMsg('Description is required'));
            this.isStep1Completed++;
        } else if (this.rolesObject && this.rolesObject['description'].length < 2) {
            this.errors.description.push(this.translateMsg('Description should be minimum 2 and maximum 255 characters'));
            this.isStep1Completed++;
        } else if (this.rolesObject && this.rolesObject['description'].length > 255) {
            this.errors.description.push(this.translateMsg('Description should be minimum 2 and maximum 255 characters'));
            this.isStep1Completed++;
        } else {
            this.errors.description = [];
        }
        if (
            this.rolesObject['displayName'] === undefined ||
            (this.rolesObject && this.rolesObject['displayName'] && this.rolesObject['displayName'].length < 0) ||
            this.rolesObject['displayName'].trim() === ''
        ) {
            this.errors.displayName.push(this.translateMsg('Display Name is required'));
            this.isStep1Completed++;
        } else if (this.rolesObject && this.rolesObject['displayName'].length < 2) {
            this.errors.displayName.push(this.translateMsg('Display Name should be minimum 2 and maximum 255 characters'));
            this.isStep1Completed++;
        } else if (this.rolesObject && this.rolesObject['displayName'].length > 255) {
            this.errors.displayName.push(this.translateMsg('Display Name should be minimum 2 and maximum 255 characters'));
            this.isStep1Completed++;
        } else {
            this.errors.displayName = [];
        }
        if (!this.rolesObject['roleName'] || this.rolesObject['roleName'].trim() === '') {
            this.errors.roleName.push(this.translateMsg('Role Name is required'));
            this.isStep1Completed++;
        } else if (!this._utils.acceptRoleName(this.rolesObject['roleName'])) {
            this.errors.roleName.push(this.translateMsg('Only alphabets, digits and spaces are allowed'));
            this.isStep1Completed++;
        } else if (!this._utils.checkMaxLength(this.rolesObject['roleName'], 256)) {
            this.errors.roleName.push(this.translateMsg('Maximum number of characters allowed is 255'));
            this.isStep1Completed++;
        } else {
            this.errors.roleName = [];
        }
        if (this.isStep1Completed === 0) {
            this.complete();
        }
    };

    private complete() {
        this.rolesStepper.selected.completed = true;
        this.rolesStepper.next();
        this.isNextClicked = true;
    }

    private isSelected = (permissionObject) => {
        let isSelected = false;
        const selectedPermission = (permissionObject.permissions as any[]).find((permission) => permission.selected);
        if (selectedPermission && selectedPermission.selected) {
            isSelected = selectedPermission.selected;
        }
        if (isSelected && (!permissionObject.subSections || !permissionObject.subSections.length)) {
            return true;
        } else {
            if (permissionObject.subSections && permissionObject.subSections.length) {
                for (let i = 0; i < permissionObject.subSections.length; i++) {
                    isSelected = this.isSelected(permissionObject.subSections[i]);
                    if (isSelected) {
                        permissionObject.subSections[i].selected = true;
                    }
                }
                let count = 0;
                if (permissionObject && permissionObject.subSections.length > 0) {
                    for (let i = 0; i < permissionObject.subSections.length; i++) {
                        if (permissionObject.subSections[i].selected) {
                            count = count + 1;
                        }
                    }
                }
                if (permissionObject && permissionObject.permissions.length > 0) {
                    for (let i = 0; i < permissionObject.permissions.length; i++) {
                        if (permissionObject.permissions[i].selected) {
                            count = count + 1;
                        }
                    }
                }
                if (count) {
                    permissionObject.selected = true;
                    isSelected = true;
                } else {
                    permissionObject.selected = false;
                    isSelected = false;
                }
            }
        }
        return isSelected;
    };

    public submitData = () => {
        const rolesData = new Role(CommonUtilsService.cloneObject(this.rolesObject));
        this.selectedPermissionIds = [];
        this.selectedRolePermissions.forEach((element) => {
            this.selectedPermissionIds.push(new Permission(element));
        });
        const noRoleChanges = JSON.stringify(this.rolesObject) === JSON.stringify(this.originalRoleObject);
        const noPermissionsChanges = JSON.stringify(this.rolePermissions) === JSON.stringify(this.originalPermissions);
        if (noRoleChanges && noPermissionsChanges) {
            if (noRoleChanges) {
                return this, this._libUtils.alertError(this.translateMsg('No changes in role data'));
            } else if (noPermissionsChanges) {
                return this, this._libUtils.alertError(this.translateMsg('No changes in permissions'));
            }
        }
        if (!rolesData.id || rolesData.id === '') {
            if (this.bundleId) {
                if (this.bundleId) {
                    let roleData = {
                        roleName: rolesData.roleName,
                        displayName: rolesData.displayName,
                        description: rolesData.description,
                    };
                    this.store$.dispatch(
                        CreateBundleRole({
                            bundleId: this.bundleId,
                            roleData,
                            permissions: this.selectedPermissionIds,
                        })
                    );
                    return;
                }
            } else {
                this.store$.dispatch(
                    CreateRole({
                        rolesData,
                        permissions: this.selectedPermissionIds,
                        has4ECAccess: this.R.has4EcAprrovalAcess.value,
                        actionType: 'CREATE',
                    })
                );
            }
        } else if (this.bundleId) {
            let roleData = {
                roleName: rolesData.roleName,
                displayName: rolesData.displayName,
                description: rolesData.description,
            };
            this.store$.dispatch(
                UpdateBundleRole({
                    bundleId: this.bundleId,
                    roleId: rolesData.id,
                    roleData: roleData,
                })
            );
        } else if (this.R.has4EcAprrovalAcess.value) {
            this.store$.dispatch(
                UpdateRoleWithoutPermissions({
                    role: rolesData,
                })
            );
        }
        if (this.bundleId) {
            this.store$.dispatch(
                UpdateBundleRolePermissions({
                    bundleId: this.bundleId,
                    permissions: this.selectedPermissionIds,
                })
            );
            return;
        } else if (rolesData?.id) {
            this.store$.dispatch(
                UpdateRoleData({
                    role: rolesData,
                    permissions: this.selectedPermissionIds,
                    actionType: 'UPDATE',
                })
            );
        }
    };

    public assignUsersToLocations = () => {
        this.store$.dispatch(
            AssignAndUnassignUsers({
                roleId: this.selectedRoleId,
                selectedUserIds: this.users
                    .filter((user) => user.selected)
                    .reduce((ids, user) => {
                        ids.push(user.id);
                        return ids;
                    }, []),
            })
        );
    };

    public cancelAssignUsers = () => {
        this.assignUsersBol = false;
        this.allUsersSelected = false;
        this.selectedPartnerOrg = '';
        this.rolesDrawer.toggle();
    };

    public searchRole = (value) => {
        if (value && value.length > 0) {
            const searchedRoles = [];
            for (let i = 0; i < this.roles.length; i++) {
                if (
                    this.roles[i].displayName.toLowerCase().includes(value.toLowerCase()) ||
                    this.roles[i].roleName.toLowerCase().includes(value.toLowerCase())
                ) {
                    searchedRoles.push(this.roles[i]);
                }
            }
            this.roleTable.next(searchedRoles);
        } else {
            this.roleTable.next(this.roles);
        }
    };

    public clearSearch = () => {
        this.roleSearchValue = '';
        this.roleTable.next(this.roles);
    };

    public selectAllLoctions = (event) => {
        if (event?.checked) {
            this.locations.forEach((location) => {
                location.selected = true;
            });
        } else {
            this.locations.forEach((location) => {
                location.selected = false;
            });
        }
        this.checkFormValid(this.locations);
    };

    public selectAllUsers = (event) => {
        this.allUsersSelected = event?.checked;
        this.users = this._usersList.selectAllUsers(event, this.users);
        this.checkFormValid(this.users);
    };

    public checkUsersList = () => {
        this.allUsersSelected = this._usersList.checkUsersList(this.users);
        this.checkFormValid(this.users);
    };

    public checkLocList = () => {
        let count = 0;
        for (let i = 0; i < this.locations.length; i++) {
            if (this.locations[i].selected === undefined || !this.locations[i].selected) {
                count = count + 1;
            }
        }
        if (count) {
            this.allLocationsSelected = false;
        } else {
            this.allLocationsSelected = true;
        }
        this.checkFormValid(this.locations);
    };

    private checkFormValid = (allData) => {
        this.formValid = this._usersList.checkFormValid(allData);
    };

    public manageRoles = (role?, actionType?, from?) => {
        role = role || this.rolesObject;
        if (actionType === 'edit' && (role.roleName === 'CUSTOMER_ADMIN' || role.roleName === 'PROVIDER_ADMIN')) {
            this._libUtils.alertError("Can't edit " + role.roleName + ' role');
            return;
        }
        if (from !== undefined) {
            this.previousPage = from;
        } else {
            this.previousPage = '';
        }
        this._commonUtils.setInStorage('clickedRole', role);
        if (this.bundleId) {
            this.R.clickedRole = role;
            this.addRole(this.R.clickedRole, actionType === 'view');
        } else {
            const path = `/organizations/${this.R.currentOrganizationId.value}/settings/roles/${actionType}${
                role?.id ? `/${role.id}` : ''
            }`;
            this._utils.setRoute(path);
        }
    };

    public goToRoles = () => {
        this.rolesObject = new Role({});
        if (this.bundleId) {
            this.rolesDrawer?.opened && this.rolesDrawer.toggle();
        } else if (this.isViewRole || (this.updateRole && this.previousPage === undefined)) {
            this.cancelView();
        } else if (this.updateRole && this.previousPage === 'view') {
            const selectedRole = this._commonUtils.getFromStorage('clickedRole');
            this.manageRoles(selectedRole, 'view');
        } else if (this.isAddRole) {
            this.cancelView();
        } else {
            this._utils.refreshCurrentRoute();
        }
    };

    public cancelView = () => {
        this.assignUsersBol = false;
        this.updateRole = false;
        this.rolesDrawer?.opened && this.rolesDrawer.toggle();
        if (!this.bundleId) {
            const path = '/organizations/' + this.R.currentOrganizationId.value + '/settings/roles';
            this._utils.setRoute(path);
        }
    };

    private routeRoles = () => {
        setTimeout(() => {
            this.route.params.subscribe((params) => {
                this.R.clickedRole = this._commonUtils.getFromStorage('clickedRole');
                if (params.actionType === 'view' && this.R.clickedRole) {
                    this.addRole(this.R.clickedRole, true);
                } else if (params.actionType === 'edit' && this.R.clickedRole) {
                    this.addRole(this.R.clickedRole, false);
                } else if (params.actionType === 'create-new') {
                    this.addRole(undefined, false);
                } else {
                    this.startController();
                }
            });
        }, 100);
    };

    public searchAssignUsers = () => {
        this.noAssignUsers = this._usersList.searchAssignUsers(this.element);
    };

    public searchRelatedLocations = () => {
        let matOptionsCount = 0;
        matOptionsCount = this.element.nativeElement.querySelectorAll('.searchLocationsList').length;
        if (matOptionsCount === 0) {
            this.noRelatedLocations = true;
        } else {
            this.noRelatedLocations = false;
        }
    };

    public clearSearchLocations = () => {
        this.searchLocation = '';
        this.noRelatedLocations = false;
    };

    public clearSearchUsers = () => {
        this.searchUser = '';
        this.noAssignUsers = false;
    };

    private translateMsg = (msg: string): string => translate('' + msg);

    initiateSubscriptions = () => {
        this.store$
            .select(getCurrentOrganization$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((org) => org !== undefined)
            )
            .subscribe((org) => {
                this.organization = org;
                this.organizationId = org.id;
            });

        this.store$
            .select(getRoles$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((roles) => {
                this.roles = roles.filter((role) => role.roleName !== 'PROVIDER_ADMIN');
                this.buildRolesTableData();
            });

        if (this.bundleId?.length > 0) {
            this.store$
                .select(getServicesByFilterWithoutPagination$)
                .pipe(takeUntil(this.unSubscribe))
                .subscribe((services) => {
                    this.bundleApps = CommonUtilsService.cloneObject(services);
                });

            this.store$
                .select(getBundleRoles$)
                .pipe(
                    takeUntil(this.unSubscribe),
                    filter((data) => data !== undefined)
                )
                .subscribe((roles) => {
                    this.roles = roles.filter((role) => role.roleName !== 'PROVIDER_ADMIN');
                    this.buildRolesTableData();
                });
            this.store$
                .select(getBundlePermissions$)
                .pipe(
                    takeUntil(this.unSubscribe),
                    filter((data) => data !== undefined)
                )
                .subscribe((permissions) => {
                    this.allRolePermissions = CommonUtilsService.cloneObject(permissions);
                    this.rolePermissions = this.convertPermissions(this.allRolePermissions);
                    this.rolePermissions.forEach((element) => {
                        if (element.name === 'Organization') {
                            element.permissions.forEach((permission, index, Object) => {
                                if (this.removePermissions.indexOf(permission.displayName) > -1) {
                                    Object.splice(index, 1);
                                }
                            });
                        }
                    });
                    this.buildRolePermissionsData();
                });
            this.store$
                .select(getBundleRolePermissions$)
                .pipe(
                    takeUntil(this.unSubscribe),
                    filter((data) => data !== undefined)
                )
                .subscribe((permissions) => {
                    let bundleRolePermissions = CommonUtilsService.cloneObject(permissions);
                    this.getSelectedRolePermissions(bundleRolePermissions);
                    this.loadingBundleRoles && this.buildRolePermissions(this.isView);
                });

            this.actions.pipe(ofType(BundleRoleCrudOperataions), takeUntil(this.unSubscribe)).subscribe((data) => {
                let msg = '';
                msg =
                    data?.actionType === 'CREATE'
                        ? this.translateMsg('Role added successfully')
                        : this.translateMsg('Role updated successfully');
                const rolesData = new Role(CommonUtilsService.cloneObject(this.rolesObject));
                if (!this.R.has4EcAprrovalAcess.value || this.bundleId) {
                    const roleIndex = this.allRoles.value.findIndex((roleItem) => roleItem.id === rolesData.id);
                    roleIndex > -1 ? this.allRoles.value.splice(roleIndex, 1, rolesData) : this.allRoles.value.push(rolesData);
                }
                this.goToRoles();
                this.store$.dispatch(AlertSuccess({ message: msg }));
            });
        }
        this.store$
            .select(getAllRolePermissions$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((permissions) => {
                this.allRolePermissions = CommonUtilsService.cloneObject(permissions);
                this.rolePermissions = this.convertPermissions(this.allRolePermissions);
                this.rolePermissions.forEach((element) => {
                    if (element.name === 'Organization') {
                        element.permissions.forEach((permission, index, Object) => {
                            if (this.removePermissions.indexOf(permission.displayName) > -1) {
                                Object.splice(index, 1);
                            }
                        });
                    }
                });
                this.buildRolePermissionsData();
            });
        this.store$
            .select(getRolePermissions$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((permissions) => {
                let rolePermissions = CommonUtilsService.cloneObject(permissions);
                this.getSelectedRolePermissions(rolePermissions);
                !this.bundleId && this.buildRolePermissions(this.isView);
            });

        this.store$
            .select(getRoleUserIds$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((UserIds) => {
                this.oldUserIdsArray = UserIds;
            });
        this.actions.pipe(ofType(RoleCrudOperataions), takeUntil(this.unSubscribe)).subscribe((data) => {
            let msg = '';
            if (data?.actionType === 'CREATE') {
                msg = this.translateMsg('Role added successfully');
            } else if (data?.actionType === 'UPDATE') {
                msg = this.translateMsg('Role updated successfully');
            }
            const rolesData = new Role(CommonUtilsService.cloneObject(this.rolesObject));
            if (!this.R.has4EcAprrovalAcess.value || this.bundleId) {
                const roleIndex = this.allRoles.value.findIndex((roleItem) => roleItem.id === rolesData.id);
                roleIndex > -1 ? this.allRoles.value.splice(roleIndex, 1, rolesData) : this.allRoles.value.push(rolesData);
            }
            this.goToRoles();
            msg?.length > 0 && this.store$.dispatch(AlertSuccess({ message: msg }));
        });
        this.store$
            .select(getOrganizationChildHierarchy$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                let childHierarchy = CommonUtilsService.cloneObject(res);
                this.getNextNodes(childHierarchy?.orgNodes[0]?.nextNodes);
                this.checkFormValid(this.locations);
            });
        this.actions.pipe(ofType(CopyRoleSuccessAction), takeUntil(this.unSubscribe)).subscribe((data) => {
            this.cancelCopyRole();
        });
        this.store$
            .select(getOrganizationPartners$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((partners) => {
                let partnerOrgs = CommonUtilsService.cloneObject(partners);
                const partnerOrganizations = [];
                const partOrgArray = [];
                for (let i = 0; i < partnerOrgs?.length; i++) {
                    partOrgArray.push(new Connection(partnerOrgs[i]));
                }
                for (let i = 0; i < partOrgArray.length; i++) {
                    const obj = {};
                    obj['displayName'] = partOrgArray[i].partnerOrganization.name;
                    obj['value'] = partOrgArray[i].partnerOrganization.id;
                    partnerOrganizations.push(obj);
                }
                const orgObj = {
                    displayName: this.organization.name,
                    value: this.organization.id,
                };
                partnerOrganizations.push(orgObj);
                this.partnerOrganizations = partnerOrganizations;
                this.getRoleUsers(this.selectedRole);
            });
        this.store$
            .select(getOrgUsers$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                let orgUsers = CommonUtilsService.cloneObject(res);
                const users: any[] = [];
                for (let i = 0; i < orgUsers.length; i++) {
                    const user = orgUsers[i];
                    user['selected'] = this.oldUserIdsArray?.includes(user.id);
                    users.push(user);
                }
                this.users = orgUsers;
                this.checkUsersList();
            });
        this.actions.pipe(ofType(SetAssignAndUnassignUsers), takeUntil(this.unSubscribe)).subscribe((res) => {
            this._libUtils.alertSuccess(res?.msg);
            this.cancelAssignUsers();
        });
    };

    ngOnInit() {
        this.routeRoles();
        this.initiateSubscriptions();
    }

    ngAfterViewInit() {
        this.rolesStepper._getIndicatorType = () => 'number';
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
