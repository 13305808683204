import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AlertError, AlertSuccess } from 'taxilla-library';

import { AuditTrailLog } from '../../interface/audit-trail-log.interface';
import { AssetService } from '../../models/assetservice.class';
import { ConfigurationInterface } from '../../models/configurations/configuration.interface';
import { Entity } from '../../models/entity.class';
import { RecordPayload } from '../../models/payload/recordpayload.class';
import { Message } from '../../models/record/message.class';
import { Record } from '../../models/record/record.class';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { UtilsService } from '../utils/utils.service';
import { ValidationsService } from '../validations/validations.service';

@Injectable({
    providedIn: 'root',
})
export class RecordsService {
    constructor(
        private _validationService: ValidationsService,
        private _bridge: BridgeService,
        private _utils: UtilsService,
        private store$: Store
    ) {}

    /**
     * check record data with asset validations and custom validations
     * @param record Record data
     * @param restApiName Entity -> RestApiName
     * @param entities List of Entity
     * @param process Process name of the submit action if any
     */
    private checkRecordData = (
        record: Record,
        restAPIName: AssetService['restApiName'],
        entities: Entity[],
        process,
        configurations: ConfigurationInterface[]
    ): boolean => {
        return this._validationService.hasValidData({ record, restAPIName, entityId: record.entityId, entities, process, configurations });
    };

    /**
     * update record data
     * @param payload Record payload object
     * @param record Record data
     * @param instanceId Instance id of the record
     * @param successCallback Success callback method
     * @param noAlerts No alerts
     */
    private updateRecord = (
        data: {
            recordPayload: RecordPayload;
            record: Record;
            instanceId: string;
            noAlerts: boolean;
            entity: Entity;
            restApiName: string;
            parentId: string;
            isArray: boolean;
        },
        callbacks?: {
            successCallback: (response: Record) => void;
        }
    ) => {
        this._bridge.submitRecord(
            {
                payload: data.recordPayload,
                restApiName: data.restApiName,
                assetDataId: data.instanceId || data.record.assetDataId,
                entityId: data.entity?.uid || data.record?.entityId,
                entityRecordId: data.record.id,
                parentId: data.parentId,
            },
            (res) => {
                !data.noAlerts &&
                    this.store$.dispatch(
                        AlertSuccess({
                            message: res.msg,
                        })
                    );
                this.getRecord(
                    {
                        entity: data.entity,
                        entityId: data.record.entityId,
                        instanceId: data.instanceId,
                        noAlerts: data.noAlerts,
                        parentRecordId: data.record.parentId,
                        recordId: data.record.id,
                        restApiName: data.restApiName,
                        response: res.response,
                    },
                    {
                        successCallback: (response) => {
                            data.record.bindValuesPostSave(CommonUtilsService.cloneObject(response));
                            callbacks?.successCallback(data.record);
                        },
                    }
                );
            },
            (res) => {
                /**
                 * In case the record failed to save and alerts can be shown
                 */
                !data.noAlerts && this._utils.alertError((res && res.msg) || 'Failed to submit ' + data.record.displayName + ' data');
            }
        );
    };

    /**
     * update record data
     * @param payload Record payload object
     * @param record Record data
     * @param instanceId Instance id of the record
     * @param successCallback Success callback method
     * @param noAlerts No alerts
     */
    public updateRecords = (data: {
        recordsPayload: RecordPayload[];
        records?: Record[];
        instanceId: string;
        noAlerts: boolean;
        entityId: string;
        restApiName: string;
    }) => {
        return new Promise<
            {
                id: string;
                parentRecordId: string;
                fields: { id: string; value: string }[];
                grids: [];
                errors: string[];
                warnings: string[];
            }[]
        >((resolve) => {
            this._bridge.updateRecords(
                {
                    payload: data.recordsPayload,
                    restApiName: data.restApiName,
                    assetDataId: data.instanceId,
                    entityId: data.entityId,
                },
                (res) => {
                    res?.response?.records.forEach((record) => {
                        const recordToUpdate = data.records?.find((item) => item.id === record.id);
                        recordToUpdate?.bindValuesPostSave(CommonUtilsService.cloneObject(record));
                    });
                    this.store$.dispatch(AlertSuccess({ message: res?.msg }));
                    resolve(res?.response?.records);
                },
                (res) => {
                    /**
                     * In case the record failed to save and alerts can be shown
                     */
                    !data.noAlerts && this._utils.alertError(res && res.msg);
                }
            );
        });
    };

    /**
     * Update record data
     * @param payload Record payload object
     * @param record Record data
     * @param successCallback Success callback method
     * @param noAlerts No alerts
     */
    private saveRecord = (
        data: {
            recordPayload: RecordPayload;
            record: Record;
            instanceId: string;
            noAlerts: boolean;
            entity: Entity;
            restApiName: string;
            parentId: string;
            isArray: boolean;
        },
        callbacks?: {
            successCallback: (response: Record) => void;
        }
    ) => {
        this._bridge.submitRecord(
            {
                payload: data.recordPayload,
                restApiName: data.restApiName,
                assetDataId: data.instanceId || data.record.assetDataId,
                entityId: data.entity?.uid || data.record?.entityId,
                entityRecordId: undefined,
                parentId: data.parentId,
            },
            (res) => {
                !data.noAlerts &&
                    this.store$.dispatch(
                        AlertSuccess({
                            message: res.msg,
                        })
                    );
                const resRecord = res?.response;
                data.record.id = resRecord?.id;
                data.record.parentId = resRecord?.parentRecordId || data.parentId;
                this.getRecord(
                    {
                        entity: data.entity,
                        entityId: data.record.entityId,
                        instanceId: data.instanceId,
                        noAlerts: data.noAlerts,
                        parentRecordId: data.record.parentId,
                        recordId: data.record.id,
                        restApiName: data.restApiName,
                        response: resRecord,
                    },
                    {
                        successCallback: (response) => {
                            data.record.message = new Message(response.message || {});
                            data.record.bindValuesPostSave(CommonUtilsService.cloneObject(response));
                            callbacks && callbacks.successCallback(data.record);
                        },
                    }
                );
            },
            (res) => {
                /**
                 * In case the record failed to save and alerts can be shown
                 */
                !data.noAlerts && this._utils.alertError((res && res.msg) || 'Failed to submit ' + data.record.displayName + ' data');
            }
        );
    };

    /**
     * Save records data
     * @param payload Record payload object
     * @param record Record data
     * @param successCallback Success callback method
     * @param noAlerts No alerts
     */
    public saveRecords = (data: {
        payload: RecordPayload[];
        restApiName: string;
        assetDataId: string;
        entityId: string;
        noAlerts?: boolean;
        noMessages?: boolean;
    }) => {
        return new Observable<{
            msg: string;
            response: {
                records: {
                    id: string;
                    parentRecordId: string;
                    fields: { id: string; value: string }[];
                    grids: [];
                    errors: string[];
                    warnings: string[];
                }[];
            };
        }>((observer) => {
            this._bridge
                .saveRecords(data)
                .then((res) => {
                    !data.noMessages && this.store$.dispatch(AlertSuccess({ message: res.msg }));
                    observer.next(res);
                    observer.complete();
                })
                .catch((e) => {
                    this.store$.dispatch(AlertError({ message: e.msg }));
                    observer.error(e);
                    observer.complete();
                });
        });
    };

    /**
     * Update records data
     * @param payload Record payload object
     * @param record Record data
     * @param successCallback Success callback method
     * @param noAlerts No alerts
     */
    public updateRecordsViaPromise = (data: {
        payload: RecordPayload[];
        restApiName: string;
        assetDataId: string;
        entityId: string;
        noAlerts?: boolean;
        noMessages?: boolean;
    }) => {
        return new Observable<{
            msg: string;
            response: {
                records: {
                    id: string;
                    parentRecordId: string;
                    fields: { id: string; value: string }[];
                    grids: [];
                    errors: string[];
                    warnings: string[];
                }[];
            };
        }>((observer) => {
            this._bridge
                .updateRecordsViaPromise(data)
                .then((res) => {
                    !data.noMessages && this.store$.dispatch(AlertSuccess({ message: res.msg }));
                    observer.next(res);
                    observer.complete();
                })
                .catch((e) => {
                    this.store$.dispatch(AlertError({ message: e.msg }));
                    observer.error(e);
                    observer.complete();
                });
        });
    };

    /**
     * Get instance record
     * @param instanceId Instance id of the record to get
     * @param entityId Entity id of the record
     * @param recordId Id of the record to fetch
     */
    getRecord = (
        data: {
            instanceId: string;
            entityId: Entity['uid'];
            recordId: string;
            noAlerts?: boolean;
            entity: Entity;
            parentRecordId?: string;
            restApiName: string;
            response?: {
                id: string;
                parentRecordId: string;
                fields: { id: string; value: string }[];
                grids: [];
                errors: string[];
                warnings: string[];
            };
        },
        callbacks?: {
            successCallback: (response: Record) => void;
        }
    ) => {
        if (data.response?.id?.length > 0) {
            const recordData = data.response;
            let record: Record;
            if (data.entity && data.entity.uid) {
                record = new Record(recordData, data.entity);
            } else {
                record = recordData as any;
                record.message = new Message({
                    errors: recordData?.errors || [],
                    warnings: recordData?.warnings || [],
                });
            }
            record.parentId = data?.parentRecordId;
            callbacks && callbacks.successCallback(record);
        } else {
            this.getRecordViaPromise(data as any).then((recordData) => {
                let record: Record;
                if (data.entity && data.entity.uid) {
                    record = new Record(recordData, data.entity);
                } else {
                    record = recordData as any;
                    record.message = new Message({
                        errors: recordData?.errors || [],
                        warnings: recordData?.warnings || [],
                    });
                }
                callbacks && callbacks.successCallback(record);
            });
        }
    };

    public getRecordViaPromise = (data: {
        instanceId: string;
        entityId: string;
        recordId: string;
        parentRecordId: string;
        restApiName: string;
        noAlerts?: boolean;
    }) => {
        return new Promise<{
            id: string;
            fields: {
                id: string;
                value: string;
                errors: string[];
                warnings: string[];
            }[];
            grids: {
                id: string;
                cells: {
                    rowIndex: number;
                    columnIndex: number;
                    value: string;
                    errors: string[];
                    warnings: string[];
                }[];
                errors: string[];
                warnings: string[];
            }[];
            errors: string[];
            warnings: string[];
        }>((resolve) => {
            this._bridge.getRecord(
                {
                    instanceId: data.instanceId,
                    entityId: data.entityId,
                    recordId: data.recordId,
                    parentRecordId: data.parentRecordId,
                    restApiName: data.restApiName,
                },
                (res) => {
                    const recordData = res.response;
                    recordData['parentId'] = data?.parentRecordId;
                    resolve(recordData);
                },
                (res) => {
                    !data.noAlerts && this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to get record data' }));
                }
            );
        });
    };

    /**
     * Method to check and execute custom callbacks
     * @param data Contains record, entities list, instance id, process, no alerts check,
     * @param callbacks Contains success callback method, failure callback method
     * @param chainCallbacks List of callbacks that needs to be called
     */
    private postSaveCallback = (
        data: { record: Record; entities: Entity[]; instanceId: string; service: AssetService; process?: string; noAlerts: boolean },
        callbacks: { successCallback: () => void; failureCallback?: () => void },
        chainCallbacks: ((...args: any[]) => void)[],
        isNewRecord?: boolean
    ) => {
        if (isNewRecord) {
            let parentEntity: Entity;
            for (let i = 0; i < data.entities.length; i++) {
                parentEntity = data.entities[i].getRecordEntity(data.record.id);
                if (parentEntity && parentEntity.uid) {
                    if (parentEntity.entities && parentEntity.entities.length > 0) {
                        data.record.entities = [];
                        for (let j = 0; j < parentEntity.entities.length; j++) {
                            data.record.entities.push(
                                new Entity(parentEntity.entities[j], parentEntity.entities[j].rules, parentEntity.entities[j].masters)
                            );
                        }
                    }
                    break;
                }
            }
        }
        const nextCallback = chainCallbacks && chainCallbacks[0];
        if (nextCallback) {
            const otherChainCallbacks = chainCallbacks['clone']();
            nextCallback(data, callbacks, otherChainCallbacks.splice(0, 1));
        } else {
            callbacks && callbacks.successCallback && callbacks.successCallback();
        }
    };

    /**
     * Saving / Updating an entity record data
     * @param data Contains record, entities list, instance id, process, no alerts check, service data, configurations data
     * @param callbacks Contains success callback method, failure callback method
     * @param chainCallbacks List of callbacks that needs to be called
     */
    submitRecord = (
        data: {
            record: Record;
            entities: Entity[];
            instanceId: string;
            service: AssetService;
            process?: string;
            noAlerts: boolean;
            configurations: ConfigurationInterface[];
            parentRecordId?: string;
            restApiName?: string;
            isArray: boolean;
            isFromEninvoiceWorkflow?: boolean;
        },
        callbacks: { successCallback: () => void; failureCallback?: () => void },
        chainCallbacks?: ((...args: any[]) => void)[]
    ) => {
        /**
         * Checking if the record has valid data
         */
        if (this.checkRecordData(data.record, data.service.restApiName, data.entities, data.process, data.configurations)) {
            let parentRecord: Record;
            let entity: Entity;
            if (!data.record.parentId && !data.parentRecordId && data.record.id?.length > 0) {
                for (let i = 0; i < data.entities.length; i++) {
                    const tempEntity = data.entities[i];
                    parentRecord = tempEntity.getEntity(data.record.entityId)?.getParentRecordByChildRecordId(data.record.id);
                    if (typeof parentRecord !== 'undefined') {
                        break;
                    }
                }
            }
            const payload = new RecordPayload(data.record);
            // TAX 5669 Reason: update,extend actions are not working
            if (!data.isFromEninvoiceWorkflow) {
                if (Object.keys(payload.fields).length === 0 && (!payload.grids || payload.grids.length === 0)) {
                    return this._utils.alertError(translate('No fields to update'));
                }
            }
            const isNewRecord = data.record && (!data.record.id || data.record.id.length === 0);
            const restApiName = data.restApiName || data.service.restApiName;
            const parentId =
                data.parentRecordId || parentRecord?.id || data.record.parentId || this._utils.getParentRecordId(data.record.id);
            payload.parentRecordId = parentId;
            if (data.record.id && data.record.id.length > 0) {
                /**
                 * If the record has id then it has to be updated
                 */
                this.updateRecord(
                    {
                        entity,
                        instanceId: data.instanceId,
                        noAlerts: data.noAlerts,
                        record: data.record,
                        recordPayload: payload,
                        restApiName,
                        parentId,
                        isArray: data.isArray,
                    },
                    {
                        successCallback: (response) => {
                            this.postSaveCallback(data, callbacks, chainCallbacks, isNewRecord);
                        },
                    }
                );
            } else {
                /**
                 * As the record doesnt have an id, it has to be saved
                 */
                this.saveRecord(
                    {
                        entity,
                        instanceId: data.instanceId,
                        noAlerts: data.noAlerts,
                        record: data.record,
                        recordPayload: payload,
                        restApiName,
                        parentId,
                        isArray: data.isArray,
                    },
                    {
                        successCallback: (response) => {
                            this.postSaveCallback(data, callbacks, chainCallbacks, isNewRecord);
                        },
                    }
                );
            }
        } else {
            /**
             * If the record has validation errors and alerts can be shown then,
             */
            !data.noAlerts && this._utils.alertError(translate('Fix errors to proceed'));
        }
    };

    /**
     * Method to get list of recrods
     * @param data Map of required properties to get records
     * @param callbacks Map of scenario based callbacks
     */
    getRecords = (
        data: {
            entity: Entity;
            instanceId: string;
            size: number;
            pagingState: any;
            parentRecordId: string;
            restApiName: string;
        },
        callbacks: {
            successCallback: (records: Record[], response: any) => void;
        }
    ) => {
        this.getRecordsPromise({
            ...data,
            entityId: data.entity.uid,
        }).then((response) => {
            const records: Record[] = [];
            const responseRecords = response?.records || [];
            responseRecords.forEach((responseRecord) => {
                const record = new Record(responseRecord, data.entity);
                records.push(record);
            });
            callbacks.successCallback(records, response);
        });
    };

    public getRecordsPromise = (data: {
        entityId: string;
        instanceId: string;
        size: number;
        pagingState: any;
        parentRecordId: string;
        restApiName: string;
        noAlerts?: boolean;
    }) => {
        return new Promise<{ records: Record[]; pagingState: any }>((resolve) => {
            this._bridge.getEntityRecords(
                data,
                (res) => {
                    resolve({ records: res.response?.records, pagingState: res?.response?.pagingState } as any);
                },
                (res) => this.store$.dispatch(AlertError({ message: res?.msg }))
            );
        });
    };

    getErrorInRecords = (
        data: {
            slug: string;
            criteria: { [property: string]: any };
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getErrorInRecords(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                const msg = (res && res.msg) || 'Failed to get Records Data';
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(msg);
                } else {
                    this._utils.alertError(msg);
                }
            }
        );
    };

    deleteRecord = (
        data: {
            instanceId: string;
            entityId: string;
            assetId: string;
            restApiName: string;
            payload: { id: string; parentRecordId: string }[];
        },
        callbacks: {
            successCallback: (response: { msg: string; response: { deletionStatus: { recordId: string; deleted: boolean }[] } }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.deleteRecord(data, callbacks.successCallback, (res) => {
            this._utils.alertError((res && res.msg) || 'Failed to delete record');
            callbacks && callbacks.failureCallback(res);
        });
    };

    /**
     * Method to delete entity records
     * @param data contains instanceId, entityId, assetId, restApiName, payload of records
     * @returns
     */
    public deleteRecords = (data: {
        instanceId: string;
        entityId: string;
        assetId: string;
        restApiName: string;
        payload: { id: string; parentRecordId: string }[];
        noMessages?: boolean;
        noAlerts?: boolean;
    }) => {
        return new Promise((resolve) => {
            this._bridge.deleteRecord(
                data,
                (res) => {
                    !data.noMessages && this.store$.dispatch(AlertSuccess({ message: res?.msg }));
                    resolve(res);
                },
                (res) => {
                    this._utils.alertError(res?.msg || 'Failed to delete records');
                }
            );
        });
    };

    fetchAuditTrails = (
        data: {
            assetDataId: string;
            auditType: string;
            pagingState: string;
            size: number;
        },
        callbacks: {
            successCallback: (response: AuditTrailLog[], pagingState: string) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.fetchAuditTrails(
            data,
            (res) => {
                callbacks.successCallback(res?.auditTrail?.auditTrailLogs || [], res?.auditTrail?.queryOptions?.pagingState);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get logs');
                callbacks?.failureCallback && callbacks?.failureCallback(res);
            }
        );
    };

    downloadIntegrationAuditTrail = (auditData: {
        orgId: string;
        orgConfigName: string;
        method: string;
        locationId?: string;
        serviceId?: string;
        type?: string;
        appConfigName?: string;
    }) => {
        this._bridge.downloadIntegrationAuditTrail(auditData);
    };

    /**
     * Method to get audit trail log changes
     * @param data Contains assetId, assetDataId and uniqueTaskId
     * @param callbacks Contains success and failure callbacks
     */
    getAuditChanges = (
        data: {
            assetDataId: string;
            parentRecordId: string;
            timeStamp: number;
        },
        callbacks: {
            successCallback: (response: string[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getAuditChanges(
            data,
            (res) => {
                callbacks && callbacks.successCallback(res && res.modifiedEntities);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to download logs');
                callbacks?.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get records in an entity by audit trail log
     * @param data Contains assetId, assetDataId and uniqueTaskId
     * @param callbacks Contains success and failure callbacks
     */
    getEntityRecordsByAuditLog = (
        data: {
            assetDataId: string;
            entityId: string;
            assetId: string;
            parentEntityRecordId: string;
            auditType: string;
            timeStamp: number;
            pagingState: string;
            size: number;
        },
        callbacks: {
            successCallback: (records: Record[], response: any) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getEntityRecordsByAuditLog(
            data,
            (res) => {
                callbacks.successCallback(res?.entityRecords, res?.searchAfter);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get data for ' + data.entityId);
            }
        );
    };

    /**
     * Method to get record changes in an audit log
     * @param data Contains assetId, assetDataId, uniqueTaskId, entityId, recordId and entityRecordId
     * @param callbacks Contains success and failure callbacks
     */
    getAuditRecordChangeLog = (
        data: {
            assetId: string;
            assetDataId: string;
            entityId: string;
            recordId: string;
            parentRecordId: string;
        },
        callbacks: {
            successCallback: (
                changes: {
                    fieldId: string;
                    isGridColumn: boolean;
                    modifiedDate: string;
                    newValue: string;
                    oldValue: string;
                    userName: string;
                }[]
            ) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getAuditRecordChangeLog(
            data,
            (response) => {
                callbacks.successCallback((response && response.changeLogs) || []);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get logs');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get record change logs
     * @param data Contains assetId, assetDataId, entityId, recordId and entityRecordId
     * @param callbacks Contains success and failure callbacks
     */
    getRecordChangeLog = (
        data: {
            assetId: string;
            assetDataId: string;
            entityId: string;
            recordId: string;
            parentRecordId: string;
        },
        callbacks: {
            successCallback: (
                changes: {
                    fieldId: string;
                    isGridColumn: boolean;
                    modifiedDate: string;
                    newValue: string;
                    oldValue: string;
                    userName: string;
                }[]
            ) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getRecordChangeLog(
            data,
            (response) => {
                callbacks.successCallback((response && response.changeLogs) || []);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get logs');
                callbacks && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to delete field attachment
     * @param data Contains field id, file url, assetDataId, assetId, entityId, recordId, parentRecordId
     * @param callbacks Contains success callback method, failure callback method
     */
    deleteFieldAttachment = (
        data: {
            fieldId: string;
            fileUri: string;
            assetDataId: string;
            assetId: string;
            entityId: string;
            recordId: string;
            parentRecordId: string;
        },
        callbacks: {
            successCallback: (...args: any[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.deleteFieldAttachment(data, callbacks.successCallback, (res) => {
            if (!callbacks.failureCallback) {
                this._utils.alertError((res && res.msg) || 'Failed to delete attachment');
            } else {
                callbacks.failureCallback(res);
            }
        });
    };

    /**
     * Method to get records based on filter type
     * @param data contains instanceId, entityId, assetId, filterType, size
     * @param callbacks with successCallback and failureCallback
     */
    getRecordsBasedOnFilterType = (
        data: {
            instanceId: string;
            entityId: string;
            filterType: string;
            size: number;
            pagingState: string;
            restApiName: string;
            parentRecordId: string;
        },
        callbacks?: {
            successCallback: (response: {
                response: {
                    records: {
                        errors: string[];
                        warnings: string[];
                        grids: string[];
                        id: string;
                        fields: {
                            id: string;
                            value: string;
                            errors: string[];
                            warnings: string[];
                        }[];
                    }[];
                };
            }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getRecordsBasedOnFilterType(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to fetch instances
     * @param data Contains Assetname, Querystring, SearchAfter, Entity ID, Restapiservicename, Size, Sortable Fields
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    searchEntityRecords = (
        data: {
            assetName?: string;
            query: any;
            searchAfter: any;
            searchEntityId: string;
            restApiServiceName?: string;
            size?: number;
            sortableFields?: {};
            resultsOnPrimaryEntity?: boolean;
            responseEntityCriteria?: { entityId?: string; queryString?: string; resultNeeded?: boolean; sort?: Map<string, any> };
        },
        callbacks: {
            successCallback: (response: { records: Record[]; searchAfter: any }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.searchEntityRecords(
            data,
            (res) => {
                callbacks.successCallback(res?.response || {});
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    searchEntityRecordsObservable = (data: {
        assetName?: string;
        query: any;
        searchAfter: any;
        searchEntityId: string;
        restApiServiceName?: string;
        size?: number;
        noAlerts?: boolean;
        sortableFields?: {};
        resultsOnPrimaryEntity?: boolean;
        responseEntityCriteria?: { entityId?: string; queryString?: string; resultNeeded?: boolean; sort?: Map<string, any> };
    }) => {
        return new Observable((observer) => {
            this._bridge.searchEntityRecords(
                data,
                (res) => {
                    observer.next(res?.response || {});
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
