import { Request } from '../../interface/request.interface';
import { ReconciliationRequest } from '../../models/reconciliation-request.class';

export const requestsState: RequestsState = {
    organizationsMap: {},
    idVsRequest: {},
    lastCreatedRequestId: undefined,
    idVsRequestUploadedFile: {},
};

export interface RequestsState {
    organizationsMap: {
        // orgId vs services map
        [property: string]: {
            // serviceId vs requestsMap
            [property: string]: {
                requestIds: string[];
                searchedRequestIds: {
                    // stringified criteria vs ids
                    [property: string]: string[];
                };
                templates?: any[];
                loading: {
                    templates: boolean;
                    requests: boolean;
                    uploadedFiles: boolean;
                };
            };
        };
    };
    idVsRequest: {
        [property: string]: Request | ReconciliationRequest;
    };
    idVsRequestUploadedFile: {
        [property: string]: { fileName: string; fileLink: string };
    };
    lastCreatedRequestId: string;
}
