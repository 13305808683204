import { createReducer, on } from '@ngrx/store';

import { Request } from '../../interface/request.interface';
import {
    ClearReconRequests,
    ClearRequests,
    RequestCreated,
    SessionLogoutSuccessfull,
    SetReconRequests,
    SetReconTemplates,
    SetRequestLoading,
    SetRequests,
    SetUploadedFile,
} from '../actions';
import { requestsState } from '../states';

export const REQUESTS_REDUCER = createReducer(
    requestsState,
    on(SetRequestLoading, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[action.organizationId]?.[action.serviceId],
                        loading: {
                            ...state.organizationsMap?.[action.organizationId]?.[action.serviceId]?.loading,
                            [action.category]: action.loading,
                        },
                    },
                },
            },
        };
    }),
    on(SetRequests, (state, action) => {
        const requestsMap: {
            [property: string]: Request;
        } = {
            ...state.idVsRequest,
            ...action.requests.reduce((requestsMap, request) => {
                requestsMap[request.requestId] = request;
                return requestsMap;
            }, {}),
        };
        const searchedRequestsMap = state.organizationsMap?.[action.organizationId]?.[action.serviceId]?.searchedRequestIds;
        const searchedRequests = searchedRequestsMap?.[action.searchCriteria]?.slice(0) || [];
        action.requests.forEach((request) => !searchedRequests.includes(request.requestId) && searchedRequests.push(request.requestId));
        searchedRequests?.sort((a, b) => {
            return new Date(requestsMap[b].createdDate).getTime() - new Date(requestsMap[a].createdDate).getTime();
        });
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[action.organizationId]?.[action.serviceId],
                        searchedRequestIds: {
                            ...searchedRequestsMap,
                            [action.searchCriteria]: searchedRequests,
                        },
                    },
                },
            },
            idVsRequest: requestsMap,
        };
    }),
    on(ClearRequests, (state, action) => {
        const searchedRequestsMap = state.organizationsMap?.[action.organizationId]?.[action.serviceId]?.searchedRequestIds;
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[action.organizationId]?.[action.serviceId],
                        searchedRequestIds: {
                            ...searchedRequestsMap,
                            [action.criteria]: undefined,
                        },
                    },
                },
            },
        };
    }),
    on(SetReconRequests, (state, action) => {
        const searchedRequestsMap = state.organizationsMap?.[action.organizationId]?.[action.serviceId]?.searchedRequestIds;
        const criteria = `${action.fromDate || ''}|${action.toDate || ''}`;
        const searchedRequests = searchedRequestsMap?.[criteria]?.slice(0) || [];
        action.requests
            .filter((request) => !searchedRequests.includes(request.requestId))
            .forEach((request) => searchedRequests?.[action.pushAtTop ? 'unshift' : 'push']?.(request.requestId));
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[action.organizationId]?.[action.serviceId],
                        searchedRequestIds: {
                            ...searchedRequestsMap,
                            [criteria]: searchedRequests,
                        },
                    },
                },
            },
            idVsRequest: {
                ...state.idVsRequest,
                ...action.requests.reduce((requestsMap, request) => {
                    requestsMap[request.requestId] = request;
                    return requestsMap;
                }, {}),
            },
        };
    }),
    on(ClearReconRequests, (state, action) => {
        const searchedRequestsMap = state.organizationsMap?.[action.organizationId]?.[action.serviceId]?.searchedRequestIds;
        const criteria = action.criteria;
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[action.organizationId]?.[action.serviceId],
                        searchedRequestIds: {
                            ...searchedRequestsMap,
                            [criteria]: undefined,
                        },
                    },
                },
            },
            idVsRequest: {
                ...state.idVsRequest,
            },
        };
    }),
    on(SetReconTemplates, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[action.organizationId]?.[action.serviceId],
                        templates: action.templates,
                    },
                },
            },
        };
    }),
    on(RequestCreated, (state, action) => {
        return {
            ...state,
            lastCreatedRequestId: action.requestId,
        };
    }),
    on(SetUploadedFile, (state, action) => {
        return {
            ...state,
            idVsRequestUploadedFile: {
                ...state.idVsRequestUploadedFile,
                [action.requestId]: action.fileObject,
            },
        };
    }),
    on(SessionLogoutSuccessfull, (state, action) => {
        return requestsState;
    })
);

export const REQUESTS_REDUCER_KEY = 'requests';
