import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModifiedErrorStateMatcher } from '../../extensions/errorstatematcher/errorstatematcher';
import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';

@Component({
    selector: 'lib-masterlookupinput',
    templateUrl: './masterlookupinput.component.html',
    styleUrls: ['./masterlookupinput.component.css'],
})
export class MasterlookupinputComponent implements OnInit {
    @Input() placeholder;
    @Input() name;
    @Input() title;
    @Input() model;
    @Input() id;
    @Input() classList;
    @Input() debounceAfter: number;
    @Input() options: any[];
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() lookupHint: string;
    @Input() hidePaginator: boolean;
    @Input() tableData: BehaviorSubject<TableData[]>;
    @Input() selectedColumns: string[];
    @Input() dataPresentation: string;
    @Input() disabled: boolean;
    @Input() panelWidth: string | number;
    @Input() maxLength: number;
    @Input() allColumns: BehaviorSubject<TableColumn[]>;
    @Input() description: string;
    @Input() hasSearch: boolean;
    @Input() clearSearch: Subject<any>;
    @Input() masterDisabled: boolean;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() selectedLookupRecord = new EventEmitter();
    @Output() searchChanged = new EventEmitter();

    panelOpen: boolean;
    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;
    MastersService: any;
    masterMetaData: any;
    noRecordsText: string;
    noRecordsIcon: string;
    searchOptions: string;
    unSubscribe = new Subject<void>();

    constructor() {}

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({
                errors: this.errors,
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.model) {
            this.control.setValue(this.model);
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    modelChanged = (event) => {
        this.model = event;
        this.modelChange.next(this.model);
    };

    updateModelAndEmitDebounceEvent = (event) => {
        this.modelChange.emit(this.control.value);
    };

    selectedOption = (event) => {
        if (this.options.length > 1 || this.hasSearch) {
            this.debounceEventHandler.emit(event);
        }
    };

    onRecordSelect = (event) => {
        this.selectedLookupRecord.emit(event);
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => this.modelChanged(event));
        if (this.clearSearch) {
            this.clearSearch.pipe(takeUntil(this.unSubscribe)).subscribe((data) => {
                this.searchOptions = '';
            });
        }
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
