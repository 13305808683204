import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AppTemplate } from '../../models/app-template.class';
import { AssetService } from '../../models/assetservice.class';
import { ApiService } from '../../services/api/api.service';
import { AlertError, AlertSuccess } from '../actions';
import * as actions from '../actions/apps.actions';
import * as sharedActions from '../actions/shared.actions';
import {
    getAppDescriptions$,
    getAppHelpDocuments$,
    getAppsLoading$,
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getBridgeNodesMap$,
    getCurrentOrganizationId$,
    getManageAppsServicesByServiceId$,
    getManageAppsServicesMap$,
    getReconConfigurations$,
    getSelectedAppEntityId$,
    getSelectedAppServiceId$,
    getSubscribedApps$,
    getSubscribedAppsMap$,
} from '../selectors';
import { Service } from '../states';

@Injectable()
export class AppsEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store, private _router: Router) {}

    private getSubscribedApps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetSubscribedApps),
            delay(10),
            withLatestFrom(this.store$.select(getSubscribedApps$)),
            mergeMap(([action, appsMap]) => {
                if (appsMap?.length > 0) {
                    this.store$.dispatch(sharedActions.CreateNavMap());
                    return [];
                }
                return from(this._api.subscriptions.getSubscribedServices()).pipe(
                    map((res) => {
                        this.store$.dispatch(sharedActions.CreateNavMap());
                        return actions.SetSubscribedApps({
                            services: res?.sort((a: AssetService, b: AssetService) => a.displayName.localeCompare(b.displayName)),
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e })))
                );
            })
        )
    );

    private getAppDescription = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAppDescription),
            withLatestFrom(this.store$.select(getAppDescriptions$), this.store$.select(getManageAppsServicesByServiceId$)),
            mergeMap(([action, descriptions, services]) => {
                const asset = services[action.serviceId];
                if (descriptions[action.serviceId]) {
                    return [];
                }
                return from(
                    this._api.assets.getAssetDescription({
                        assetMetaUId: asset?.itemId || action.assetId,
                        assetType: asset?.type,
                        id: undefined,
                        noLoading: true,
                    })
                ).pipe(
                    map((response) =>
                        actions.SetAppDescription({
                            description: response,
                            serviceId: action.serviceId,
                            assetId: asset?.id || action.assetId,
                        })
                    )
                );
            })
        );
    });

    private getAppHelpDocumentation = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAppHelpDocumentation),
            withLatestFrom(this.store$.select(getAppHelpDocuments$), this.store$.select(getManageAppsServicesByServiceId$)),
            mergeMap(([action, helpDocuments, services]) => {
                const asset = services[action.bridgeServiceId]?.bridgeNodes?.[action.bridgeNodeId] || services[action.serviceId];
                if (helpDocuments[action.serviceId]) {
                    return [];
                }
                return from(
                    this._api.assets.getHelpDocument({
                        serviceId: action.serviceId,
                        noLoader: true,
                    })
                ).pipe(
                    map((response) =>
                        actions.SetAppHelpDocumentation({
                            helpDocuments: response as any,
                            serviceId: action.serviceId,
                            bridgeNodeId: asset.id,
                            bridgeServiceId: action.bridgeServiceId,
                        })
                    )
                );
            })
        );
    });

    private getAppBridgeNodes = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAppBridgeNodes),
            withLatestFrom(
                this.store$.select(getBridgeNodesMap$),
                this.store$.select(getManageAppsServicesMap$),
                this.store$.select(getSubscribedAppsMap$)
            ),
            mergeMap(([action, bridgeNodes, services, appsMap]) => {
                const asset: Service = services?.[action.assetId];
                const app = appsMap?.[action.serviceId];
                if (bridgeNodes[action.assetId] || bridgeNodes[app?.assetMetaUId]) {
                    return [];
                }
                return this._api.subscriptions.fetchBridgeNodes(asset?.itemId || app?.assetMetaUId).pipe(
                    map((response) => {
                        return actions.SetAppBridgeNodes({
                            assetId: asset?.itemId || app?.assetMetaUId,
                            bridgeNodes: response,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    private getAppMetaData = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAppMetaData),
            delay(10),
            withLatestFrom(
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsLoading$)
            ),
            mergeMap(([action, metaDataMap, appsMap, appsLoading]) => {
                if (!action.serviceId) {
                    return [];
                }
                const loading = appsLoading?.[action.assetId || action.serviceId]?.meta;
                const app = appsMap?.[action.serviceId];
                const assetId = action.assetId || app?.assetMetaUId;
                const metaData = metaDataMap?.[action.serviceId]?.[assetId];
                if (loading || app?.assetType === 'BRIDGE_ASSET') {
                    return [];
                }
                if (metaData?.uid) {
                    return [];
                }
                if (!app && Object.keys(appsMap || {}).length === 0 && !action.guest) {
                    return of(actions.GetAppMetaData(action));
                } else if (!app && !action.guest) {
                    return of(AlertError({ message: 'Failed to get app meta data as it is not subscribed' }));
                }
                this.store$.dispatch(actions.SetAppLoading({ loading: true, serviceId: action.assetId || action.serviceId }));
                return from(
                    this._api.assets.getAssetMetaDataViaPromise({
                        assetMetaUId: assetId,
                        name: app?.name,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((response) => {
                        this.store$.dispatch(actions.SetAppLoading({ loading: false, serviceId: action.assetId || action.serviceId }));
                        return actions.SetAppMetaData({
                            metaData: response,
                            serviceId: action.serviceId,
                        });
                    }),
                    catchError((e) => of(actions.SetAppLoading({ loading: false, serviceId: action.assetId || action.serviceId })))
                );
            })
        );
    });

    private getReconConfiguration = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetReconConfiguration),
            delay(10),
            withLatestFrom(
                this.store$.select(getReconConfigurations$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsLoading$)
            ),
            mergeMap(([action, configs, appsMap, appsLoading]) => {
                const loading = appsLoading?.[action.serviceId]?.reconConfiguration;
                if (loading) {
                    return [];
                }
                if (configs?.[action.serviceId]) {
                    return [];
                }
                const app = appsMap?.[action.serviceId];
                if (!app && Object.keys(appsMap || {}).length === 0) {
                    return of(actions.GetReconConfiguration(action));
                } else if (!app) {
                    return of(AlertError({ message: 'Failed to get app meta data as it is not subscribed' }));
                }
                this.store$.dispatch(actions.SetReconConfigurationLoading({ loading: true, serviceId: action.serviceId }));
                return from(
                    this._api.assets.getReconciliationConfiguration({
                        restApiName: app.restApiName,
                        serviceId: app.serviceId,
                        dontTransform: true,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((response) => {
                        this.store$.dispatch(actions.SetReconConfigurationLoading({ loading: false, serviceId: action.serviceId }));
                        return actions.SetReconConfiguration({
                            configuration: response,
                            serviceId: action.serviceId,
                        });
                    }),
                    catchError((e) => of(actions.SetReconConfigurationLoading({ loading: false, serviceId: action.serviceId })))
                );
            })
        );
    });

    private routeAppsCategory = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.RouteSelectedAppCategory),
            delay(10),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getSelectedAppServiceId$)
            ),
            mergeMap(([action, organizationId, appsMap, serviceId]) => {
                if (!serviceId) {
                    return of(AlertError({ message: 'Service id not assigned' }));
                }
                const app = appsMap?.[serviceId];
                if (!app && Object.keys(appsMap || {}).length === 0) {
                    return of(actions.RouteSelectedAppCategory(action));
                } else if (!app) {
                    return of(AlertError({ message: 'Failed to route to category as it is not subscribed' }));
                }
                const routes = ['organizations', organizationId];
                if (app['bridge']) {
                    routes.push('packages', app['bridge'].restApiName);
                }
                routes.push('apps', app.restApiName, 'configure-app', action.category);
                this._router.navigate(routes);
                return [];
            })
        );
    });

    private getAppTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAppMetaData, actions.GetAppTemplate),
            delay(10),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsLoading$),
                this.store$.select(getAppsTemplate$)
            ),
            mergeMap(([action, organizationId, appsMap, appsLoading, appsTemplate]) => {
                const serviceId = action.serviceId;
                if (!serviceId) {
                    return [];
                }
                const app = appsMap?.[serviceId];
                const loading = appsLoading?.[serviceId]?.template;
                if (loading || app?.assetType === 'BRIDGE_ASSET') {
                    return [];
                }
                const template = appsTemplate?.[serviceId];
                if (template && !action.forceFetch) {
                    return [];
                }
                if (!app && Object.keys(appsMap || {}).length === 0 && !action.guest) {
                    return of(actions.GetAppMetaData(action));
                } else if (!app && !action.guest) {
                    return of(AlertError({ message: 'Failed to get app template as it is not subscribed' }));
                }
                this.store$.dispatch(actions.SetAppTemplateLoading({ loading: true, serviceId }));
                return from(this._api.assets.getAppTemplate(app?.serviceId || action.serviceId, action.noAlerts)).pipe(
                    map((response) => {
                        this.store$.dispatch(actions.SetAppTemplateLoading({ loading: false, serviceId }));
                        return actions.SetAppTemplate({
                            organizationId,
                            template: response,
                            serviceId,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(AlertError({ message: e?.msg }));
                        return of(actions.SetAppTemplateLoading({ loading: false, serviceId: serviceId }));
                    })
                );
            })
        );
    });

    private submitAppTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AppSubmitTemplate),
            delay(10),
            withLatestFrom(
                this.store$.select(getSelectedAppServiceId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getAppsTemplate$),
                this.store$.select(getSelectedAppEntityId$),
                this.store$.select(getAppsLoading$)
            ),
            mergeMap(([action, serviceId, appsMap, metaDataMap, appsTemplate, entityId, appsLoading]) => {
                const template = appsTemplate?.[serviceId]?.uiTemplate;
                const metaData = metaDataMap?.[serviceId];
                const app = appsMap?.[serviceId];
                const payload = new AppTemplate(metaData?.[app?.assetMetaUId], template);
                const loading = appsLoading?.[serviceId]?.template;
                action.details && payload.setAppDetails(action.details);
                action.entitiesOrder && payload.setEntitiesOrder(action.entitiesOrder, undefined);
                action.entityDetails && payload.setAppDetails(undefined, entityId, action.entityDetails);
                action.fieldDetails && payload.setAppDetails(undefined, entityId, undefined, action.fieldDetails);
                if (loading) {
                    return [];
                }
                this.store$.dispatch(actions.SetAppTemplateLoading({ loading: true, serviceId }));
                return from(this._api.assets.submitAppTemplate(app.serviceId, payload)).pipe(
                    map((response) => {
                        this.store$.dispatch(actions.SetAppTemplateLoading({ loading: false, serviceId }));
                        this.store$.dispatch(AlertSuccess({ message: translate('Configuration updated') }));
                        return actions.GetAppTemplate({
                            serviceId,
                            noAlerts: true,
                            forceFetch: true,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(AlertError({ message: e?.msg }));
                        return of(actions.SetAppTemplateLoading({ loading: false, serviceId: serviceId }));
                    })
                );
            })
        );
    });

    private deleteAppTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AppDeleteTemplate),
            delay(10),
            withLatestFrom(
                this.store$.select(getSelectedAppServiceId$),
                this.store$.select(getAppsTemplate$),
                this.store$.select(getAppsLoading$)
            ),
            mergeMap(([action, serviceId, appsTemplate, appsLoading]) => {
                const loading = appsLoading?.[serviceId]?.template;
                if (loading) {
                    return [];
                }
                if (!appsTemplate?.[serviceId]?.uiTemplate?.uid) {
                    return of(AlertError({ message: translate('No template found to delete') }));
                }
                this.store$.dispatch(actions.SetAppTemplateLoading({ loading: true, serviceId }));
                return from(this._api.assets.deleteAppTemplate(serviceId)).pipe(
                    map((response) => {
                        this.store$.dispatch(actions.SetAppTemplateLoading({ loading: false, serviceId }));
                        this.store$.dispatch(AlertSuccess({ message: translate('Configuration reset successful') }));
                        return actions.GetAppTemplate({
                            serviceId,
                            noAlerts: true,
                            forceFetch: true,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(AlertError({ message: e?.msg }));
                        return of(actions.SetAppTemplateLoading({ loading: false, serviceId: serviceId }));
                    })
                );
            })
        );
    });
}
