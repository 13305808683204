import { Record } from '../record/record.class';
import { RecordField } from '../record/recordfield.class';

export class RecordPayload {
    id: string;

    parentRecordId: string;

    grids: RecordGridPayload[];

    fields?: {
        id: string;
        value: any;
    }[];

    constructor(record: Record, pushOriginalValue?: boolean) {
        this.fields = [];
        this.id = record?.id;
        this.parentRecordId = record?.parentId;
        !pushOriginalValue &&
            record?.fields
                .filter((field) => field.datatype !== 'GRID')
                .forEach((field) => {
                    const fieldPayload = new RecordPayloadField(field);
                    if (fieldPayload.modified) {
                        this.fields.push({
                            id: fieldPayload.id,
                            value: fieldPayload.value,
                        });
                    }
                });
        const gridFields = record?.fields.filter(
            (field) => field.datatype === 'GRID' && field.gridData && field.gridData.cells && Object.keys(field.gridData.cells).length > 0
        );
        if (gridFields?.length > 0) {
            this.grids = [];
            !pushOriginalValue &&
                gridFields.forEach((field) => {
                    this.grids.push(new RecordGridPayload(field));
                });
        }
    }
}

class RecordPayloadField {
    id: string;

    value: string | number | string[] | boolean;

    modified: boolean;

    constructor(fieldsData: RecordField) {
        this.id = fieldsData.id;
        this.value = fieldsData.value !== undefined ? fieldsData.value : '';
        if (fieldsData.value === '' && fieldsData.originalValue === undefined) {
            this.modified = false;
        } else {
            this.modified = this.value !== fieldsData.originalValue;
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
class RecordGridPayload {
    id: string;

    cells: {
        columnIndex: any;
        rowIndex: any;
        value: any;
    }[];

    constructor(field: RecordField) {
        this.id = field.id;
        this.cells = [];
        field.gridData.cells.forEach((cell) => {
            this.cells.push({
                columnIndex: cell.columnIndex,
                rowIndex: cell.rowIndex,
                value: cell.value,
            });
        });
    }
}
