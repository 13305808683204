import { Action } from '@ngrx/store';
import { EditSavedDefaultFilter, EditSavedFilter, FilterCriteria, ServiceId, UpdateSearch } from 'taxilla-library';

/**
 * [] represents the a particular area, whether it be a component page, backend API, or browser API.
 * After [] text is a description about what event occurred from this action
 */
export enum SearchCriteriaActionTypes {
    SEARCH_UPDATE_SERVICEID = '[SEARCH CRITERIA] Update ServiceId',
    SEARCH_UPDATE = '[SEARCH CRITERIA] Update Search Criteria',
    SEARCH_CLEAR = '[SEARCH CRITERIA] Clear Search Criteria',
    EDIT_SEARCH_FILTER = '[SEARCH CRITERIA] Edit Search Filter',
    EDIT_DEFAULT_FILTER = '[SEARCH CRITERIA] Edit Default Filter',
}
export class EditSearchCriteriaFilter implements Action {
    readonly type = SearchCriteriaActionTypes.EDIT_SEARCH_FILTER;

    constructor(public payload: EditSavedFilter) {}
}
export class EditDefaultSearchCriteriaFilter implements Action {
    readonly type = SearchCriteriaActionTypes.EDIT_DEFAULT_FILTER;

    constructor(public payload: EditSavedDefaultFilter) {}
}
export class UpdateSearchCriteriaServiceId implements Action {
    readonly type = SearchCriteriaActionTypes.SEARCH_UPDATE_SERVICEID;

    constructor(public payload: ServiceId) {}
}
export class SEARCH_CRITERIA_CLEAR implements Action {
    readonly type = SearchCriteriaActionTypes.SEARCH_CLEAR;

    constructor(public payload: FilterCriteria) {}
}
export class SEARCH_CRITERIA_UPDATE implements Action {
    readonly type = SearchCriteriaActionTypes.SEARCH_UPDATE;

    constructor(public payload: UpdateSearch) {}
}

export type Actions =
    | EditSearchCriteriaFilter
    | EditDefaultSearchCriteriaFilter
    | UpdateSearchCriteriaServiceId
    | SEARCH_CRITERIA_CLEAR
    | SEARCH_CRITERIA_UPDATE;
