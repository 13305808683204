import { createReducer, on } from '@ngrx/store';

import * as sessionActions from '../actions/session.actions';
import * as usersActions from '../actions/users.actions';
import { initialUsersState } from '../states';

export const USERS_REDUCER = createReducer(
    initialUsersState,
    on(usersActions.SetUserSettings, (state, action) => {
        return {
            ...state,
            settings: {
                passwordPolicy: action?.passwordPolicy,
                passwordPolicyMsg: action?.passwordPolicyMsg,
                settingsFailedMessage: undefined,
            },
        };
    }),
    on(usersActions.GetUserSettingsFailed, (state, action) => {
        return {
            ...state,
            settings: {
                passwordPolicy: undefined,
                passwordPolicyMsg: undefined,
                settingsFailedMessage: action?.msg,
            },
        };
    }),
    on(usersActions.UpdatePassword, (state, action) => {
        return {
            ...state,
            passwordUpdate: {
                message: undefined,
            },
        };
    }),
    on(usersActions.ClearUpdatePasswordSuccessfullMessage, (state, action) => {
        return {
            ...state,
            passwordUpdate: {
                message: undefined,
            },
        };
    }),
    on(usersActions.UpdatePasswordSuccessfull, (state, action) => {
        return {
            ...state,
            passwordUpdate: {
                message: action.message,
            },
        };
    }),
    on(usersActions.SetUsersPagination, (state, action) => {
        return {
            ...state,
            usersPagination: {
                index: action.pageNumber,
                pageSize: action.pageSize,
            },
        };
    }),
    on(usersActions.ClearUsersSearch, (state, action) => {
        return {
            ...state,
            search: action.search,
            usersPagination: {
                index: 0,
                pageSize: 0,
            },
        };
    }),
    on(usersActions.SetUsersSearch, (state, action) => {
        return {
            ...state,
            search: action.search,
            usersPagination: {
                ...state.usersPagination,
                index: 0,
            },
        };
    }),
    on(usersActions.SetUsers, (state, action) => {
        const organizationId = action.organizationId;
        const search = JSON.stringify(state.search);
        const newUsersList = action.data.reduce((userIds, user) => {
            userIds.push(user.id);
            return userIds;
        }, []);
        const usersList = [...new Set([...(state.usersList[`${organizationId}|${search}`] || []), ...newUsersList])];
        return {
            ...state,
            usersList: {
                ...state.usersList,
                [`${organizationId}|${search}`]: usersList,
            },
            usersMap: {
                ...state.usersMap,
                ...action.data.reduce((usersMap, user) => {
                    usersMap[user.id] = user;
                    return usersMap;
                }, {}),
            },
        };
    }),
    on(usersActions.SetUser, (state, action) => {
        const search = JSON.stringify(state.search);
        if (action.newUser) {
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    [`${action.organizationId}|${search}`]: [
                        ...(state.usersList[`${action.organizationId}|${search}`] || []),
                        action.user.id,
                    ],
                },
                usersMap: {
                    ...state.usersMap,
                    [action.user.id]: action.user,
                },
            };
        }
        return {
            ...state,
            usersMap: {
                ...state.usersMap,
                [action.user.id]: action.user,
            },
        };
    }),
    on(usersActions.SetSelectedOrgId, (state, action) => {
        return {
            ...state,
            selectedOrgIdToAssignUsers: action.orgId,
        };
    }),
    on(usersActions.SetOrgUsers, (state, action) => {
        return {
            ...state,
            organizationUsers: {
                ...state.organizationUsers,
                [action.orgId]: action.users,
            },
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, (state, action) => {
        return {
            settings: {
                passwordPolicy: undefined,
                passwordPolicyMsg: undefined,
                settingsFailedMessage: undefined,
            },
            passwordUpdate: {
                message: undefined,
            },
            usersMap: {},
            usersList: {},
            usersPagination: {
                index: 0,
                pageSize: 0,
            },
            addUser: undefined,
            search: {
                email: '',
                firstName: '',
                lastName: '',
                userId: '',
                contactNumber: '',
                department: '',
                designation: '',
                id: '',
                organizationId: '',
                userName: '',
            },
            selectedOrgIdToAssignUsers: undefined,
            organizationUsers: {},
        };
    })
);

export const USERS_REDUCER_KEY = 'users';
