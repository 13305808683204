import { ReconciliationConfiguration } from '../reconciliation-configuration.class';
import { ReconciliationSource } from '../reconciliation-source.class';
import { ReconciliationRecord } from './reconciliation-record.class';

export class ReconciliationProcess {
    id: string;

    group: string;

    score: number;

    stepId: number;

    primarySourceData: { [property: string]: any };

    secondarySourceData: { [property: string]: any };

    validationMessages: {
        match: boolean;
        message: string;
        property: string;
    }[];

    primarySource?: ReconciliationSource;

    secondarySource?: ReconciliationSource;

    primaryRecord?: ReconciliationRecord;

    secondaryRecord?: ReconciliationRecord;

    private readonly firstSourceData: { [property: string]: any };

    private readonly secondSourceData: { [property: string]: any };

    constructor(data?: ReconciliationProcess, configuration?: ReconciliationConfiguration, soureId?: string) {
        this.id = data?.id;
        this.group = data?.group;
        this.score = data?.score;
        this.stepId = data?.stepId;
        this.validationMessages = data?.validationMessages;
        this.primarySourceData = data?.firstSourceData;
        this.secondarySourceData = data?.secondSourceData;
        this.primarySource = configuration?.sources?.find((source) => source.id === soureId);
        this.secondarySource = configuration?.sources?.find((source) => source.id !== soureId);
        this.primaryRecord = new ReconciliationRecord(
            this.id,
            this.primarySourceData,
            this.primarySource,
            data.score,
            data.validationMessages
        );
        this.secondaryRecord = new ReconciliationRecord(
            this.id,
            this.secondarySourceData,
            this.secondarySource,
            data.score,
            data.validationMessages
        );
    }
}
