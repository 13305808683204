import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

import { AssetService } from '../../models/assetservice.class';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-card-container',
    templateUrl: './home-app-mat-container.component.html',
    styleUrls: ['./home-app-mat-container.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class HomeAppMatContainerComponent implements OnInit, AfterViewInit {
    @Input() app: AssetService;
    @Input() loading: { [property: string]: boolean };
    @Input() newProcessInSameTab?: boolean;
    @Input() allProcessesInSameTab?: boolean;
    @Input() processInSameTab?: boolean;
    @Input() navigateToAppHomeOnly: boolean;

    constructor(private _router: Router) {}

    navigateToAppHome = () => {
        this._router.navigate(['apps', this.app.restApiName, 'home']);
    };

    ngOnInit() {}

    ngAfterViewInit(): void {
        // this.matStepper.selectedIndex = 0;
    }
}
