import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import * as actions from '../actions/tags.actions';
import { getCurrentOrganizationId$ } from '../selectors';
import {
    getAssetsTags$,
    getTaggedApps$,
    getTagKey$,
    getTagKeys$,
    getTagKeyValues$,
    getTagsObject$,
    getTagSubscriptionStatus$,
} from '../selectors/tags.selector';

@Injectable()
export class TagsEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store) {}

    private getTagKeys = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetTagKeys),
            withLatestFrom(this.store$.select(getTagKeys$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, keys, organizationId]) => {
                if (keys.length > 0) {
                    return [];
                }
                if (!(organizationId?.length > 0)) {
                    setTimeout(() => {
                        this.store$.dispatch(actions.GetTagKeys());
                    }, 1000);
                    return [];
                }
                return from(this._api.assets.getAllKeys()).pipe(
                    map((response) =>
                        actions.SetTagKeys({
                            keys: response,
                            organizationId,
                        })
                    )
                );
            })
        );
    });

    private setTagKey = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SetTagKey),
            delay(10),
            withLatestFrom(this.store$.select(getTagKeyValues$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, values, organizationId]) => {
                if (values.length > 0 || !action.key || action.key?.length === 0) {
                    return [];
                }
                if (!(organizationId?.length > 0) || !(action.organizationId?.length > 0)) {
                    setTimeout(() => {
                        this.store$.dispatch(actions.SetTagKey({ ...action, organizationId }));
                    }, 1000);
                    return [];
                }
                return from(this._api.assets.getTagValuesByKey({ key: action.key })).pipe(
                    map((response) =>
                        actions.SetTagKeyValues({
                            values: response as [],
                            organizationId,
                        })
                    )
                );
            })
        );
    });

    private getTaggedApps = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetTagAppsByKeyValues),
            delay(10),
            withLatestFrom(this.store$.select(getTagsObject$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, apps, organizationId]) => {
                const taggedApps = apps?.[`${action.tagKey}}|APPROVED`] || [];
                if (taggedApps.length > 0 || !action.tagKey) {
                    return [];
                }
                if (!(organizationId?.length > 0)) {
                    setTimeout(() => {
                        this.store$.dispatch(actions.GetTagAppsByKeyValues(action));
                    }, 1000);
                    return [];
                }
                let method: Promise<
                    {
                        metadataId: string;
                        metadataName: string;
                        metadataType: string;
                        repositoryId: string;
                        serviceId: string;
                        restApiName: string;
                        tagKey: string;
                        tagValue: string;
                    }[]
                >;
                if (action.tagValue?.length > 0) {
                    method = this._api.tags.getTaggedApps(organizationId, action.tagKey, action.tagValue, action.noAlerts, [
                        'ASSET',
                        'BRIDGEASSET',
                        'WIDGET',
                        'DASHBOARD',
                        'RECON',
                    ]);
                } else {
                    const key = action.tagKey + (action.tagValue?.length > 0 ? `:${action.tagValue}` : '');
                    method = this._api.assets.getServicesByTagValues({
                        key: key,
                        serviceTypes: ['ASSET', 'BRIDGEASSET', 'WIDGET', 'DASHBOARD', 'RECON'],
                        subscriptionStatus: 'APPROVED',
                    });
                }
                return from(method).pipe(
                    map((response) =>
                        actions.SetTagAppsByKeyValues({
                            apps: response,
                            status: 'APPROVED',
                            tagKey: action.tagKey,
                            tagValue: action.tagValue,
                            organizationId,
                        })
                    )
                );
            })
        );
    });

    private setTagValue = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SetTagValue),
            delay(10),
            withLatestFrom(
                this.store$.select(getTagKey$),
                this.store$.select(getTagSubscriptionStatus$),
                this.store$.select(getTaggedApps$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([action, key, subscriptionStatus, apps, organizationId]) => {
                if (apps.length > 0 || !action.value || action.value.length === 0) {
                    return [];
                }
                if (!(organizationId?.length > 0) || !(action.organizationId?.length > 0)) {
                    setTimeout(() => {
                        this.store$.dispatch(actions.SetTagValue({ ...action, organizationId }));
                    }, 1000);
                    return [];
                }
                return from(
                    this._api.assets.getServicesByTagValues({
                        key: key,
                        serviceTypes: ['ASSET', 'BRIDGEASSET', 'WIDGET', 'DASHBOARD', 'RECON'],
                        subscriptionStatus,
                    })
                ).pipe(
                    map((response) =>
                        actions.SetTagAppsByKeyValues({
                            apps: response,
                            organizationId,
                        })
                    )
                );
            })
        );
    });

    private getAssetTags = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAssetTags),
            delay(10),
            withLatestFrom(this.store$.select(getAssetsTags$)),
            mergeMap(([action, tags]) => {
                const appTags = tags?.[action.serviceId];
                if (Object.keys(appTags || {})?.length > 0) {
                    return [];
                }
                return from(this._api.tags.getAssetTagKeyValues(action.serviceId)).pipe(
                    map((tags) =>
                        actions.SetAssetTags({
                            serviceId: action.serviceId,
                            tagKeyValues: tags,
                        })
                    )
                );
            })
        );
    });

    private resetTag = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ResetTag),
            delay(10),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                if (action.organizationId) {
                    return [];
                }
                if (!organizationId) {
                    setTimeout(() => {
                        this.store$.dispatch(actions.ResetTag(action));
                    }, 1000);
                    return [];
                }
                this.store$.dispatch(actions.ResetTag({ organizationId }));
            })
        );
    });
}
