import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MastersResponseRecord, RecordField } from 'taxilla-library';

import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';

@Component({
    selector: 'app-entities-template',
    templateUrl: './entities-template.component.html',
    styleUrls: ['./entities-template.component.scss'],
})
export class EntitiesTemplateComponent implements OnInit {
    @Input() public entities: Entity[];
    @Input() public parentEntityUid: string;
    @Input() public parentId: string;
    @Input() public parentTempId: string;
    @Input() public opened: boolean;
    @Input() public parentMasterTablesData: string = '{}';
    @Input() public masterRecordsResponse: {
        [tableId: string]: {
            [pipedColumnKeys: string]: MastersResponseRecord[];
        };
    };
    @Input() public eventType: string;
    @Input() public instanceId: string;
    @Input() public service: AssetService;
    @Input() public parentLookupsData: string;
    @Input() public lookupsResponse: {
        [condition: string]: RecordField['lookupValues'];
    };

    @Output() lookUpUpdated = new EventEmitter();
    @Input() public field: RecordField;

    constructor() {}

    ngOnInit(): void {}
}
