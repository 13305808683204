import { EntityAdapter } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { Bundle, BundlesOrganizationEntity, Invitation } from '../states';

export enum BundleActionTypes {
    BUNDLES_SET_ORGANIZATION_ENTITY = '[BUNDLES] Set Organization Entity',
    GET_BUNDLES = '[BUNDLES] Get Bundles',
    SET_BUNDLES = '[BUNDLES] Set Bundles',
    SET_BUNDLES_SEARCH_TEXT = '[BUNDLES] Set Search Text',
    RESET_BUNDLES_SEARCH_TEXT = '[BUNDLES] Reset Search Text',
    RESET_BUNDLES = '[BUNDLES] Reset',
    SUBMIT_BUNDLE_DETAILS = '[BUNDLES] Submit Details',
    ADD_BUNDLE_DETAILS = '[BUNDLES] Add Details',
    UPDATE_BUNDLE_DETAILS = '[BUNDLES] Update Details',
    UPDATE_BUNDLE_SUCCESS = '[BUNDLES] Update Success',
    SELECT_BUNDLE = '[BUNDLES] Select Bundle',
    UPDATE_BUNDLE_SUBSCRIPTIONS = '[Bundle] Update Bundle Subscriptions',
    GET_BUNDLE_INVITATIONS = '[BUNDLE] Get Bundle Invitations',
    SET_BUNDLE_INVITATIONS = '[BUNDLE] Set Bundle Invitations',
    DELETE_BUNDLE_INVITATION = '[BUNDLE] Delete Bundle Invitation',
    DELETE_BUNDLE = '[BUNDLE] Delete Bundle',
    REMOVE_BUNDLE = '[BUNDLE] Remove Bundle',
    SELECT_BUNDLE_FOR_INVITATION = '[BUNDLES] Select Bundle For Invitation',
    SEND_BUNDLE_INVITATIONS = '[BUNDLES] Select Bundle Invitations',
    SEND_BUNDLE_INVITATIONS_SUCCESS = '[BUNDLES] Select Bundle Invitations Success',
}

export const SetBundlesOrganizationEntity = createAction(
    BundleActionTypes.BUNDLES_SET_ORGANIZATION_ENTITY,
    props<{
        bundlesEntity: BundlesOrganizationEntity;
        organizationsAdapter: EntityAdapter<BundlesOrganizationEntity>;
    }>()
);

export const GetBundles = createAction(BundleActionTypes.GET_BUNDLES);

export const SetBundles = createAction(
    BundleActionTypes.SET_BUNDLES,
    props<{
        organizationsAdapter: EntityAdapter<BundlesOrganizationEntity>;
        bundlesAdapter: EntityAdapter<Bundle>;
        bundlesResponse: Bundle[];
    }>()
);

export const SetBundlesSearchText = createAction(
    BundleActionTypes.SET_BUNDLES_SEARCH_TEXT,
    props<{
        searchText: string;
    }>()
);

export const ResetBundlesSearchText = createAction(BundleActionTypes.RESET_BUNDLES_SEARCH_TEXT);

export const ResetBundles = createAction(BundleActionTypes.RESET_BUNDLES);

export const SubmitBundleDetails = createAction(
    BundleActionTypes.SUBMIT_BUNDLE_DETAILS,
    props<{
        description: string;
        name: string;
        id: string;
    }>()
);

export const AddBundleDetails = createAction(
    BundleActionTypes.ADD_BUNDLE_DETAILS,
    props<{
        organizationsAdapter: EntityAdapter<BundlesOrganizationEntity>;
        bundlesAdapter: EntityAdapter<Bundle>;
        response: Bundle;
    }>()
);

export const UpdateBundleDetails = createAction(
    BundleActionTypes.UPDATE_BUNDLE_DETAILS,
    props<{
        organizationsAdapter: EntityAdapter<BundlesOrganizationEntity>;
        bundlesAdapter: EntityAdapter<Bundle>;
        response: Bundle;
    }>()
);

export const BundleUpdateSuccess = createAction(
    BundleActionTypes.UPDATE_BUNDLE_SUCCESS,
    props<{
        success: boolean;
    }>()
);

export const SelectBundle = createAction(
    BundleActionTypes.SELECT_BUNDLE,
    props<{
        id: string;
    }>()
);

export const UpdateBundleAppSubscriptions = createAction(BundleActionTypes.UPDATE_BUNDLE_SUBSCRIPTIONS);

export const GetBundleInvitations = createAction(
    BundleActionTypes.GET_BUNDLE_INVITATIONS,
    props<{
        id: string;
    }>()
);

export const SetBundleInviations = createAction(
    BundleActionTypes.SET_BUNDLE_INVITATIONS,
    props<{
        invitations: Invitation[];
    }>()
);

export const DeleteBundleInvitation = createAction(
    BundleActionTypes.DELETE_BUNDLE_INVITATION,
    props<{
        invitationId: string;
    }>()
);

export const DeleteBundle = createAction(
    BundleActionTypes.DELETE_BUNDLE,
    props<{
        bundleId: string;
    }>()
);

export const RemoveBundle = createAction(
    BundleActionTypes.REMOVE_BUNDLE,
    props<{
        organizationsAdapter: EntityAdapter<BundlesOrganizationEntity>;
        bundlesAdapter: EntityAdapter<Bundle>;
        bundleId: string;
    }>()
);

export const SelectBundleForInvitation = createAction(BundleActionTypes.SELECT_BUNDLE_FOR_INVITATION, props<{ bundleId: string }>());

export const SendBundleInvitations = createAction(
    BundleActionTypes.SEND_BUNDLE_INVITATIONS,
    props<{ partnerRefs: { partnerRef: string; type: string }[] }>()
);

export const SendBundleInvitationSuccess = createAction(BundleActionTypes.SEND_BUNDLE_INVITATIONS_SUCCESS, props<{ success: boolean }>());
