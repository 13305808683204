export class Address {
    addressLine1?: string;

    addressLine2?: string;

    city?: string;

    state?: string;

    zipCode?: string;

    country?: string;

    emailId?: string;

    mobileNo?: string;

    fax?: string;

    website?: string;

    addressType?: string;

    countryName?: string;

    stateName?: string;

    cityName?: string;

    _id?: string;

    constructor(address: Address) {
        if (address !== undefined) {
            this.addressLine1 = address['addressLine1'];
            this.addressLine2 = address['addressLine2'];
            this.city = address['city'] || '';
            this.state = address['state'] || '';
            this.zipCode = address['zipCode'];
            this.country = address['country'] || '';
            // this.addressType = address["fax"];
            this.emailId = address['emailId'];
            this.mobileNo = address['mobileNo'];
            this.addressType = address['addressType'];
            if (address['mobileNo']) {
                this.mobileNo = address['mobileNo'];
            }
            if (address['fax']) {
                this.fax = address['fax'];
            }
            if (address['website']) {
                this.website = address['website'];
            }
            if (address['countryName']) {
                this.countryName = address['countryName'];
            }
            if (address['stateName']) {
                this.stateName = address['stateName'];
            }
            if (address['cityName']) {
                this.cityName = address['cityName'];
            }
        }
    }

    static getAddressByType = (addressesArray: Array<Address>, addressType) => {
        for (let i = 0; i < addressesArray.length; i++) {
            if (addressesArray[i].addressType === addressType) {
                return addressesArray[i];
            }
        }
        return new Address({});
    };

    static pushAddress = (addressesArray: Array<Address>, address: Address) => {
        let found = false;
        const newAddress = new Address(address);
        newAddress._id = address._id;
        for (let i = 0; i < addressesArray.length; i++) {
            if (addressesArray[i]._id === newAddress._id) {
                addressesArray[i] = newAddress;
                found = true;
                break;
            }
        }
        if (!found) {
            addressesArray.push(new Address(newAddress));
        }
    };

    static deleteAddress = (addressesArray: Array<Address>, address: Address) => {
        for (let i = 0; i < addressesArray.length; i++) {
            if (addressesArray[i]._id === address._id) {
                addressesArray.splice(i, 1);
                break;
            }
        }
    };
}
