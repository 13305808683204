import { AssetData } from './assetdata.class';
import { Entity } from './entity.class';
import { Field } from './field.class';
import { Message } from './record/message.class';

export class AppTemplate {
    public 'uid': string;

    public 'name': string;

    public 'displayName': string;

    public 'description': string;

    private 'version': string;

    public 'entities': EntityTemplate[];

    private 'rules': [];

    private 'workflow': [];

    constructor(metaData: AssetData, template: AppTemplate) {
        this.uid = template?.uid || metaData?.uid;
        this.description = template?.description;
        this.displayName = template?.displayName;
        this.entities = [];
        this.name = template?.name || metaData?.name;
        this.rules = template?.rules;
        this.workflow = template?.workflow;
        this.version = template?.version;
        metaData?.entities
            ?.slice(0)
            ?.sort((a, b) => {
                const aIndex = template?.entities?.find((entity) => entity.uid === a.uid)?.entityOrder || -1;
                const bIndex = template?.entities?.find((entity) => entity.uid === b.uid)?.entityOrder || -1;
                return aIndex - bIndex;
            })
            ?.forEach((entity) => {
                this.entities.push(
                    new EntityTemplate(
                        template?.entities?.find((dataEntity) => dataEntity.uid === entity.uid),
                        entity
                    )
                );
            });
    }

    public setEntitiesOrder = (payload: AppTemplate['entities'], entities: AppTemplate['entities']) => {
        payload?.forEach((payloadEntity, index) => {
            const entity = (entities || this.entities)?.find((item) => item.uid === payloadEntity.uid);
            entity.entityOrder = payloadEntity.entityOrder;
            this.setEntitiesOrder(payloadEntity.entities, entity?.entities);
        });
    };

    setAppDetails = (
        details?: {
            id: string;
            label: string;
            value: string;
            readOnly: boolean;
            required?: boolean;
            message?: Message;
        }[],
        entityId?: string,
        entityDetails?: {
            id: string;
            label: string;
            value: string | boolean;
            readOnly: boolean;
            required?: boolean;
            message?: Message;
        }[],
        entityFieldDetails?: {
            fieldId: string;
            fieldOrder: number;
            details: {
                id: string;
                value: any;
            }[];
        }[]
    ) => {
        details?.forEach((detail) => (this[detail.id] = detail.value));
        if (entityDetails || entityFieldDetails) {
            const entity = this.getEntity(this.entities, entityId);
            entityDetails?.forEach((detail) => (entity[detail.id] = detail.value));
            entityFieldDetails?.forEach((fieldDetail) => {
                const field = entity.fields.find((fieldData) => fieldData.uid === fieldDetail.fieldId);
                field.fieldOrder = fieldDetail.fieldOrder;
                fieldDetail.details.forEach((detail) => {
                    field[detail.id] = detail.value;
                });
            });
        }
    };

    private getEntity = (entities: EntityTemplate[], entityId: string) => {
        let entityFound: EntityTemplate = undefined;
        entities?.forEach((entity) => {
            if (entityFound) {
                return;
            }
            if (entity.uid === entityId) {
                entityFound = entity;
                return;
            }
            entity?.entities?.length > 0 && (entityFound = this.getEntity(entity.entities, entityId));
        });
        return entityFound;
    };
}

class EntityTemplate {
    public 'uid': string;

    public 'name': string;

    public 'section': string;

    public 'displayName': string;

    public 'description': string;

    public 'entities': EntityTemplate[];

    public 'fields': FieldTemplate[];

    public entityOrder: number;

    public 'grids': FieldTemplate[];

    public show: boolean;

    public showDisabled: boolean;

    constructor(template: EntityTemplate, entity: Entity) {
        this.uid = template?.uid || entity?.uid;
        this.name = template?.name || entity?.name;
        this.section = template?.section || '';
        this.displayName = template?.displayName;
        this.description = template?.description;
        this.entityOrder = template?.entityOrder;
        this.entities = [];
        this.fields = [];
        this.grids = [];
        this.show = template?.show === undefined ? entity?.show : template?.show;
        this.showDisabled = !entity?.show || entity?.primary || entity?.mandatory;
        entity?.fields
            ?.slice(0)
            ?.sort((a, b) => {
                const aIndex = template?.fields?.find((meta) => meta.uid === a.uid)?.fieldOrder || -1;
                const bIndex = template?.fields?.find((meta) => meta.uid === b.uid)?.fieldOrder || -1;
                return aIndex - bIndex;
            })
            ?.forEach((field) => {
                let fieldTemplate = template?.fields?.find((meta) => meta.uid === field.uid);
                if (fieldTemplate === undefined) {
                    fieldTemplate = template?.grids?.find((meta) => meta.uid === field.uid);
                }
                this.fields.push(new FieldTemplate(fieldTemplate, field));
            });
        entity?.entities
            ?.slice(0)
            ?.sort((a, b) => {
                const aIndex = template?.entities?.find((entity) => entity.uid === a.uid)?.entityOrder || -1;
                const bIndex = template?.entities?.find((entity) => entity.uid === b.uid)?.entityOrder || -1;
                return aIndex - bIndex;
            })
            ?.forEach((subEntity) => {
                this.entities.push(
                    new EntityTemplate(
                        template?.entities?.find((dataEntity) => dataEntity.uid === subEntity.uid),
                        subEntity
                    )
                );
            });
    }
}

class FieldTemplate {
    public 'uid': string;

    public 'name': string;

    public 'section': string;

    public 'displayName': string;

    public 'description': string;

    public fieldOrder: number;

    public show: boolean;

    public showDisabled: boolean;

    public rowDetails?: {
        index: string;
        name: string;
        displayName: string;
    }[];

    public columnDetails?: {
        index: string;
        name: string;
        displayName: string;
    }[];

    constructor(template: FieldTemplate, field: Field) {
        this.uid = template?.uid || field?.uid;
        this.name = template?.name || field?.name;
        this.section = template?.section;
        this.displayName = template?.displayName;
        this.description = template?.description;
        this.fieldOrder = template?.fieldOrder;
        this.show = template?.show === undefined ? field?.show : template?.show;
        this.showDisabled = field?.isBusinessKey || field?.captureOnce === 'FILING' || !field?.show || field.mandatory;
        this.columnDetails = template?.columnDetails;
        this.rowDetails = template?.rowDetails;
    }
}
