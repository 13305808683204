export class LookupData {
    array: boolean;

    caseSensitive: boolean;

    displayField: string;

    fields: any[];

    idGenerators: any[];

    mandatory: boolean;

    name: boolean;

    orderByField: boolean;

    orderType: boolean;

    primary: boolean;

    readOnly: boolean;

    show: boolean;

    sizeType: string;

    type: string;

    uid: string;

    valueField: string;

    constructor(data) {
        this.array = data && data.array;
        this.caseSensitive = data && data.caseSensitive;
        this.displayField = data && data.displayField;
        this.fields = data && data.fields;
        this.idGenerators = data && data.idGenerators;
        this.mandatory = data && data.mandatory;
        this.name = data && data.name;
        this.orderByField = data && data.orderByField;
        this.orderType = data && data.orderType;
        this.primary = data && data.primary;
        this.readOnly = data && data.readOnly;
        this.show = data && data.show;
        this.sizeType = data && data.sizeType;
        this.type = data && data.type;
        this.uid = data && data.uid;
        this.valueField = data && data.valueField;
    }
}
