<div id="signinmaindiv" [class.authFormContainer]="!hideDomain" *transloco="let translate;">
    <div class="signInDetailsDiv">
        <div class="cssmarquee" *ngIf="R.SHOWMARQUEE && R.MARQUEETEXT">
            <h1 title="{{R.MARQUEETEXT}}">{{R.MARQUEETEXT}} </h1>
        </div>
        <mat-card class="signInCard">
            <div class="row signInRowCls">
                <div class="loginSummary col-6">
                    <div class="loginSummaryContainer">
                        <div class="loginSummaryHeader">
                            <span>
                                <img src="assets/images/{{R.LOGO_HREF}}logo.png" class="taxillaLogo" alt="logo">
                            </span>
                        </div>
                        <div class="loginfooterLogo" *ngIf="['emguat', 'emgprod', 'kpmguat', 'kpmgprod'].indexOf(R.PLATFORM) >= 0">
                            <span>{{translate('poweredBy')}}<img class="logo loginPageFooterLogo" src="assets/images/encomply/footer-logo.png" alt="Taxilla"></span>
                        </div>
                    </div>
                </div>
                <div class="loginDetails col-6">
                    <mat-drawer-container class="drawerContainer" autosize>
                        <mat-drawer #drawer class="loginMatDrawer" mode="side" disableClose position='end'>
                            <ng-container *ngIf="isForgotUserId && !isForgotPaswd && !securityAuthenticate">
                                <mat-card-title class="text-center">
                                    {{translate('forgotUserId')}}
                                </mat-card-title>
                                <div class="loginDetailSection">
                                    <div class="pdbtm15">
                                        <material-input [autofocus]="false" [placeholder]="translate('organizationCode')" [model]="forgotUsername.orgId" (modelChange)="forgotUsername.orgId = $event" [name]="'forgotUserNameOrgCode'" [id]="'forgotUserNameOrgCode'" [required]="true" [errors]="forgotUsernameErrors.orgId">
                                        </material-input>
                                    </div>
                                    <div>
                                        <material-input [autofocus]="false" [placeholder]="translate('email')" [model]="forgotUsername.email" (modelChange)="forgotUsername.email = $event" [name]="'forgotUserNameEmail'" [id]="'forgotUserNameEmail'" [required]="true" [errors]="forgotUsernameErrors.email">
                                        </material-input>
                                    </div>
                                    <div class="row signupCaptcha">
                                        <label class="col-6 signupDetailLabel signupCaptchaLabel">
                                            <div class="signupCaptchaImg">
                                                <img *ngIf="forgotUsernameCaptchaUrl && forgotUsernameCaptchaUrl.length > 0" src="data:image/jpeg;base64,{{forgotUsernameCaptchaUrl}}" class="" alt="captcha">
                                            </div>
                                            <mat-icon class="material-icons cursorPointer" [title]="translate('refreshCaptcha')" (click)="getForgotUsernameCaptcha()">{{translate('refresh')}}</mat-icon>
                                        </label>
                                        <div class="signupDetailValue col-6 signupCaptchaInput">
                                            <material-input [autofocus]="false" [placeholder]="translate('captcha')" [required]="true" [errors]="forgotUsernameErrors.captcha" [model]="forgotUsername.captcha" [classList]="[]" [debounceAfter]="1000" (modelChange)="forgotUsername.captcha = $event" (debounceEventHandler)="checkForgotPswdCaptcha()">
                                            </material-input>
                                        </div>
                                    </div>
                                    <div class="submitSignup text-center">
                                        <material-button [label]="translate('submit')" (onTrigger)="submitForgotUserNameDetails()">
                                        </material-button>
                                    </div>
                                    <div class="forgotPasswordLink">
                                        <span (click)="navigateTologin()" [title]="translate('signIn')">{{translate('signIn')}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isForgotPaswd && !isForgotUserId && !securityAuthenticate">
                                <mat-card-title class="text-center">
                                    {{translate('forgotPassword')}}
                                </mat-card-title>
                                <div class="forgotPasswordSection">
                                    <div class="forgotPasswordInputField">
                                        <material-input [autofocus]="true" [placeholder]="translate('userId')" [model]="forgot.userId" (modelChange)="forgot.userId = $event" [name]="'forgotUserId'" [id]="'forgotUserId'" [required]="true" [errors]="forgotPaswdErrors.userId"></material-input>
                                    </div>
                                    <div class="row signupCaptcha">
                                        <label class="col-6 signupDetailLabel signupCaptchaLabel">
                                            <div class="signupCaptchaImg">
                                                <img *ngIf="forgotPswdCaptchaUrl?.length > 0" src="data:image/jpeg;base64,{{forgotPswdCaptchaUrl}}" class="" alt="captcha">
                                            </div>
                                            <mat-icon class="material-icons cursorPointer" [title]="translate('refreshCaptcha')" (click)="getForgotPswdCaptcha()">refresh</mat-icon>
                                        </label>
                                        <div class="signupDetailValue col-6 signupCaptchaInput">
                                            <material-input [autofocus]="false" [placeholder]="translate('captcha')" [required]="true" [errors]="forgotPaswdErrors.captcha" [model]="forgot.captcha" [classList]="[]" [debounceAfter]="1000" (modelChange)="forgot.captcha = $event" (debounceEventHandler)="checkForgotPswdCaptcha()">
                                            </material-input>
                                        </div>
                                    </div>
                                    <div class="submitSignup text-center">
                                        <material-button [label]="translate('submit')" (onTrigger)="submitForgotPasswordDetails()">
                                        </material-button>
                                    </div>
                                    <div class="forgotPasswordLink">
                                        <span (click)="navigateTologin()" [title]="translate('signIn')">{{translate('signIn')}}</span>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="securityAuthenticate && !isForgotUserId && !isForgotPaswd">
                                <mat-card-title class="text-center">
                                    <strong>{{translate('Two-factor Authentication')}}</strong>
                                </mat-card-title>
                                <div class="2faTabContentContainer">
                                    <div class="domainSection googleCodeField" *ngIf="twoFactorType === 'GOOGLE_AUTHENTICATOR_OTP'">
                                        <material-input [autofocus]="true" [placeholder]="translate('Authenticator code')" [model]="googleCode" (modelChange)="googleCode = $event" [name]="'googleCode'" [id]="'googleCode'" (keyUpEventHandler)="checkKeyCode($event, 'security', 'google')" [errors]="googleCodeErrors" [maxLength]="6" [debounceAfter]="1000">
                                        </material-input>
                                    </div>
                                    <div class="domainSection googleCodeField" *ngIf="twoFactorType === 'EMAIL_OTP'">
                                        <material-input [autofocus]="true" [placeholder]="translate('Enter Email OTP')" [model]="emailOTP" (modelChange)="emailOTP = $event" [name]="'emailOTP'" [id]="'emailOTP'" (keyUpEventHandler)="checkKeyCode($event, 'security', 'email_OTP')" [errors]="emailOTPErrors" [maxLength]="6" [debounceAfter]="1000">
                                        </material-input>
                                    </div>
                                    <div *ngIf="randomQuestionsStatus.loading" class="noQuestionDiv">
                                        <div class="padding25pxComplete"></div>
                                        <ng-container *ngFor="let item of [1,2];">
                                            <div class="authenticateInputField displayBlock loaderBlock isLoading">
                                                <span class='visibilityHidden'>security question</span>
                                            </div>
                                        </ng-container>
                                        <div class="submitSignup displayBlock authenticateButn text-center loaderBlock isLoading">
                                            <material-button class="visibilityHidden" [label]="translate('submit')" (onTrigger)="authenticateDetails()">
                                            </material-button>
                                        </div>
                                    </div>
                                    <div class="authenticationQuestionsBlock mt-5" *ngIf="twoFactorType === 'SECRET_QUESTION'">
                                        <div *ngIf="randomQuestionsStatus.randomQuestions" class="authenticateInputFieldsContainer">
                                            <ng-container *ngFor="let item of randomQuestions; let i = index">
                                                <div class="authenticateInputField">
                                                    <material-input-password [autofocus]="i === 0" [placeholder]="translate(item.question)" [model]="item.value" (modelChange)="item.value = $event" [name]="item.question" [id]="item.question" [required]="true" [errors]="item.errors" (keyUpEventHandler)="checkKeyCode($event,'security', 'questions')"></material-input-password>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div *ngIf="!randomQuestionsStatus.loading && twoFactorType !== 'EMAIL_OTP'" class="submitSignup authenticateButn text-center" id="submitBtn">
                                        <material-button [label]="translate('submit')" (onTrigger)="authenticateDetails()">
                                        </material-button>
                                    </div>
                                    <div *ngIf="!randomQuestionsStatus.loading && twoFactorType === 'EMAIL_OTP'" class="resendSubmit authenticateButn !mt-[15px]">
                                        <material-button class="mr-[15px]" [label]="buttonText" (onTrigger)="sendOTP()" [disabled]="resendDisabled">
                                        </material-button>
                                        <material-button [label]="translate('submit')" (onTrigger)="authenticateDetails()">
                                        </material-button>
                                        <div class="mt-[12px]">
                                            <strong>{{translate('Note')}} :</strong>
                                            {{translate("Didn't receive an OTP? Click on Resend OTP")}}
                                        </div>
                                    </div>
                                    <div class="forgotPasswordLink signupLink" *ngIf="twoFactorType === 'SECRET_QUESTION' && randomQuestionsStatus.randomQuestions">
                                        {{translate('Do you want to answer different set of questions?')}} <span class="btn-link" [title]="translate('refresh')" (click)="getRandomQuestions()">{{translate('refresh')}}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </mat-drawer>
                        <mat-drawer-content>
                            <mat-card-title class="text-center">
                                <strong [hidden]="inLdapMode"> {{translate('signIn')}}</strong>
                                <strong [hidden]="!inLdapMode"> {{translate('LDAP Credentials')}}</strong>
                            </mat-card-title>
                            <div class="loginDetailSection" [class.ldapMode]="inLdapMode" [hidden]="hideLoginForm">
                                <div class="domainSection" [hidden]="hideDomain || inLdapMode">
                                    <material-input [autofocus]="true" [placeholder]="translate('identityProvider')" [model]="login.domain" (modelChange)="login.domain = $event" [name]="'domain'" [id]="'loginDomain'" (keyUpEventHandler)="checkKeyCode($event, 'login', 'IP')" [debounceAfter]="1000">
                                    </material-input>
                                </div>
                                <div class="domainAndLoginSeparator" [hidden]="hideDomain || inLdapMode">
                                    <span class="separatorText">
                                        {{translate('or')}}
                                    </span>
                                </div>
                                <div class="loginSection">
                                    <div>
                                        <material-input [autofocus]="true" [placeholder]="userIdIdentifier || translate('userId')" [model]="login.userId" (modelChange)="login.userId = $event" [name]="'userId'" [id]="'loginUserId'" [required]="true" [errors]="errors.userId" (keyUpEventHandler)="checkKeyCode($event,'login', 'Login')" [debounceAfter]="1000">
                                        </material-input>
                                        <div class="forgotUserLink" *ngIf="['dbcloud', 'dbtest', 'dbuat', 'dbprod', 'dbdr'].indexOf(R.PLATFORM) < 0 && !inLdapMode">
                                            <span (click)="navigateToForgotUserName(drawer)" class="forgotUsernameCls" [title]="translate('forgotUserId')">{{translate('forgotUserId')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <material-input-password [autofocus]="false" [placeholder]="translate('password')" [model]="login.password" (modelChange)="login.password = $event" [name]="'userPassword'" [id]="'loginUserPassword'" [required]="true" [errors]="errors.password" (keyUpEventHandler)="checkKeyCode($event,'login', 'Login')" [debounceAfter]="1000">
                                        </material-input-password>
                                        <div class="forgotUserLink" *ngIf="['dbcloud', 'dbtest', 'dbuat', 'dbprod', 'dbdr'].indexOf(R.PLATFORM) < 0 && !inLdapMode">
                                            <span (click)="navigateToForgotPassword()" class="forgotUsernameCls" [title]="translate('forgotPassword')">{{translate('forgotPassword')}}</span>
                                        </div>
                                    </div>
                                    <div class="submitSignup text-center">
                                        <material-button [label]="translate('submit')" (onTrigger)="submitDetails()"></material-button>
                                    </div>
                                    <div class="forgotPasswordLink signupLink" *ngIf="['emguat', 'emgprod'].indexOf(R.PLATFORM) < 0 && !inLdapMode && !hideSignUpPage"> {{translate('dontHaveAccount')}}
                                        <span class="btn-link" [routerLink]="'/auth/signup'" [title]="translate('signUp')">{{translate('signUp')}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="logggingTextContainer" [hidden]="!hideLoginForm">
                                <span class="loggingText">
                                    {{translate('Logging in...')}}
                                </span>
                            </div>
                        </mat-drawer-content>
                    </mat-drawer-container>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<ng-container *ngIf='(!randomQuestionsStatus.loading && !randomQuestionsStatus.randomQuestions && !randomQuestionsStatus.noQuestionsFound)'>
    <div class="noQuestionDiv" *transloco="let translate;">
        <div class="noQuestionClass">
            {{translate('No questions were found.')}}
        </div>
    </div>
</ng-container>
