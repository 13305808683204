import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PaginationInterface } from '../../../interface/pagination.interface';
import { CommonUtilsService } from '../../../services/commonutils/common-utils.service';
import { UtilsService } from '../../../services/utils/utils.service';

@Component({
    selector: 'material-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class MaterialPaginatorComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() paginationSubject: BehaviorSubject<PaginationInterface> = new BehaviorSubject({});
    @Input() showFirstLastButtons = true;

    @Output() fetchItemsInPage = new EventEmitter();
    @Output() refreshItemsWithNewPageSize = new EventEmitter();

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    pageSizeOptions = [5, 10, 20, 50, 100, 500];
    pageSize: PaginationInterface['size'];
    unSubscribe = new Subject<void>();
    pagination: PaginationInterface;
    customOptions: number[];

    constructor(protected _utils: UtilsService, private _elementRef: ElementRef) {}

    setPageValues = (data: PaginationInterface) => {
        this.pagination = CommonUtilsService.cloneObject(data);
        this.pageSize = data.size;
        this.setPageNumber();
    };

    setPageEvent = (event: PageEvent) => {
        if (event.pageSize !== this.pagination.size) {
            this.pagination.size = event.pageSize;
            this.refreshItemsWithNewPageSize.emit(event.pageSize);
        } else if (event.previousPageIndex !== event.pageIndex) {
            this.fetchItemsInPage.emit(event.pageIndex);
        }
    };

    setPageNumber = () => {
        const innerText = `${translate('Page')}: ${this.pagination.pageIndex + 1}`;
        this.manipulateText(innerText);
        this.checkForTraces(innerText);
    };

    checkForTraces = (innerText: string) => {
        const contains = innerText.includes('of');
        if (contains) {
            this.manipulateText(innerText);
        }
    };

    manipulateText = (innerText: string) => {
        const paginatorLabel = this._elementRef.nativeElement.querySelector('.mat-paginator-range-label') as HTMLElement;
        paginatorLabel.innerHTML = innerText;
    };

    ngOnInit() {
        this.paginationSubject
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                const newData: PaginationInterface = CommonUtilsService.cloneObject(data);
                if (newData.searchAfter !== undefined) {
                    if (newData.count === (newData.pageIndex + 1) * newData.size) {
                        newData.count += 1;
                    }
                }
                this.customOptions = data.pageSizeOptions;
                this.setPageValues(newData);
            });
    }

    ngAfterViewInit() {
        this.setPageNumber();
        this.paginator?._intl && (this.paginator._intl.itemsPerPageLabel = translate('Items per page'));
        this.paginator?._intl && (this.paginator._intl.nextPageLabel = translate('Next Page'));
        this.paginator?._intl && (this.paginator._intl.lastPageLabel = translate('Last Page'));
        this.paginator?._intl && (this.paginator._intl.firstPageLabel = translate('First Page'));
        this.paginator?._intl && (this.paginator._intl.previousPageLabel = translate('Previous Page'));
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
