import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, Subject } from 'rxjs';

import { CommonNotificationsService } from '../../services/commonnotifications/common-notifications.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    createdRequestDate: any;
    source: string;
    unSubscribe = new Subject<void>();

    notificationsState: BehaviorSubject<{
        eventTypesList: {
            id: string;
            displayName: string;
        }[];
    }> = new BehaviorSubject(undefined);
    isFromRequestLogs: boolean;

    constructor(
        private dialog: MatDialog,
        private _notification: CommonNotificationsService,
        private _broadcaster: BroadcasterService,
        private _libUtils: UtilsService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    loadEventsDetails = (eventTypes) => {
        const resourceId = this.data.resourceId;
        let currentCreatedDate;
        if (!this.data.isFromRequestLogs) {
            currentCreatedDate = this.data.createdRequestDate.includes('T')
                ? this._libUtils.transformDateToLocale(this.data.createdRequestDate, 'YYYY-MM-DDTHH:MM:SSZ', 'ddMMMyyyy HH:MM:SS AM', false)
                : this.data.createdRequestDate;
        } else {
            currentCreatedDate = this.data.createdRequestDate;
        }

        eventTypes.map((item) => {
            item.id = item.name;
            item.displayName = this._libUtils.convertEventTypesToNewNames(this._libUtils.convertEnumsToNormalWords(item.name));
        });
        this.notificationsState.next({
            eventTypesList: eventTypes,
        });
    };

    closeModal = () => {
        // this.dialog.closeAll();
        if (this.data.source !== undefined) {
            const eventType = this.data.source;
            this._broadcaster.broadcast('triggerOnNotificationClose', { eventType });
        }
    };

    ngOnInit() {
        this._notification.getEventTypes(
            this.loadEventsDetails,
            () => {
                //no-op
            },
            'ASSET'
        );
        this._broadcaster.on('logout').subscribe((data: { noRedirect: boolean; hidePopup: boolean }) => {
            this.dialog.closeAll();
        });
    }

    // tslint:disable-next-line: use-life-cycle-interface
    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
