<div class="fieldAttachmentModal" *transloco="let translate">
    <div class="d-flex  align-items-center mt-4" *ngIf="eventType === 'edit'">
        <h4 class="matDialogTitle">Upload File :</h4>
        <span class="field-display-name relative top-[-3px] left-[10px] font-bold">{{field.displayName}}</span>
    </div>
    <div class="mat-dialog-content mat-typography matDialogBody">
        <div>
            <div class="fieldAttachmentRow" [hidden]="inReadOnlyMode" *ngIf="eventType === 'edit'">
                <span class="field-display-name">{{field.displayName}} :</span>
                <span class="file-upload-item">
                    <fileUpload [data]="fieldAttachmentData" [clearFiles]="clearFiles" (newValue)="onNewValue($event)" (fileName)="fileName = $event" [supportedFormats]="field.attachment && field.attachment.supportedTypes" (isFileTypeSupported)="onFileTypeSupportCheck($event)" [isDisabled]="maxFileCountReached"></fileUpload>
                </span>
                <span class="upload-btn" *transloco="let translate">
                    <material-button [disabled]="!files || files.length === 0 || !isFileTypeSupported || !isMaxFileSizeAllowed || maxFileCountReached" [label]="translate('Upload')" (onTrigger)="startUpload()"></material-button>
                </span>
            </div>
            <div [hidden]="inReadOnlyMode" *ngIf="eventType === 'edit'">
                <span>{{translate('Maximum allowed files count')}}:<strong>{{field.attachment && field.attachment.maxFilesCount}}</strong><span class="alreadyReached" *ngIf="maxFileCountReached"> {{translate('Reached')}}</span></span>
            </div>
            <div class="fieldAttachedFileName my-3 ">
                <span class="col-xs-5 fileNameLabel font-sans font-bold">{{translate('File(s) Name')}} :</span>
                <span class="col-xs-7 fileNameValue ml-2">{{fileName}}</span>
                <div class="fieldAttachedFileName row" [hidden]="!inReadOnlyMode">
                    <span class="col-xs-5 fileNameLabel">
                        {{translate('Field Name')}} :
                    </span>
                    <span class="col-xs-7 fileNameValue" title="{{field.displayName}}">
                        {{field.displayName}}
                    </span>
                </div>
                <div class="fileTypeSupport" *ngIf="!isFileTypeSupported">
                    <span>{{translate('Please select')}} {{field.attachment && field.attachment.supportedTypes}} {{translate('file format to upload')}}</span>
                </div>
                <div class="fileTypeSupport maxFileSizeAllowed" *ngIf="!isMaxFileSizeAllowed">
                    <span> {{translate('Please upload a file of size less than')}} {{field.attachment && field.attachment.maxSize}} {{translate('MB')}}</span>
                </div>
                <div class="uploadedFilesListContainer pt-2 pb-3">
                    <table aria-describedby="uploaded file list" class="w-full border-collapse ">
                        <thead class="bg-[#f5f5f5] table-header header-row text-center text-sm !leading-7">
                            <tr>
                                <th *ngFor="let header of allColumns" scope="col" class="font-medium bg-[#f5f5f5] border text-center py-2  relative text-sm !leading-3">{{header}}</th>
                                <th *ngIf="eventType === 'edit'" scope="col" class="font-medium !leading-3 bg-[#f5f5f5] border text-center py-2 text-sm relative">{{translate('Actions')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="tableData | async as data">
                                <ng-container *ngIf="data.length > 0; else noRecords">
                                    <tr *ngFor="let item of data; index as i" class="border !leading-3 text-center">
                                        <td class="border  text-sm !leading-3">{{ item.fileName }}</td>
                                        <td class="border  text-sm !leading-3">
                                            <a class="text-taxillaGreen cursor-pointer" [href]="item.url" [download]="item.fileName">Download</a>
                                        </td>
                                        <td *ngIf="eventType === 'edit'" class="border  text-sm !line-height-4px">
                                            <ng-container *ngFor="let action of recordActions">
                                                <button mat-icon-button (click)="deleteFile( item ,i)" class="  !h-7 !w-7">
                                                    <mat-icon class="!text-[20px] relative bottom-2">{{action.iconName}}</mat-icon>
                                                </button>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-template #noRecords>
                                <tr *transloco="let translate" class="border text-center">
                                    <td colspan="3" class="py-1">{{ translate('No records found')}}</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
