import { BridgeNode } from './bridgeNode.interface';
import { Entity } from './entity.class';
import { Field } from './field.class';
import { LookupData } from './lookupdata.class';
import { Masters } from './masters/masters.class';
import { Rule } from './rule.class';
import { Tag } from './tag.class';
import { WorkflowStageData, WorkflowUserAction } from './workflowstagedata.class';
import { WorkflowStageReferences } from './workflowStageReferences.class';

export class AssetData {
    active: boolean;

    dataStreamEnabled: boolean;

    entities: Entity[];

    flushOutputData: boolean;

    idGenerators: any[];

    lookups: LookupData[];

    masterRefMetaDatas: any[];

    name: string;

    displayName?: string;

    referredAssets: any[];

    relatedAssetUids: any[];

    restAPIName: string;

    rules: Rule[];

    tagEntries: Tag[];

    tags: any[];

    uid: string;

    unified: boolean;

    version: string;

    workflowMetadata: {
        customizable?: boolean;
        id?: string;
        name?: string;
        stages?: WorkflowStageData[];
        status?: string;
        version?: string;
        displayName?: string;
    };
    workflowStageReferences: WorkflowStageReferences[];

    masters: Masters;

    description?: string;

    bridgeNodes?: BridgeNode[];

    canTriggerWorkflow?: boolean;

    canCreateNewProcess?: boolean;

    canCancelInboundTransmission?: boolean;

    workflowPermissions?: { [property: string]: string[] };

    computationalRules?: { [property: string]: string };

    templateUpdated?: boolean;

    constructor(data?, templateData?: AssetData) {
        this.active = data?.active;
        this.dataStreamEnabled = data?.dataStreamEnabled;
        this.flushOutputData = data?.flushOutputData;
        this.idGenerators = data?.idGenerators;
        this.masterRefMetaDatas = data?.masterRefMetaDatas;
        this.name = data?.name;
        this.displayName = templateData?.displayName || data?.displayName;
        this.referredAssets = data?.referredAssets;
        this.relatedAssetUids = data?.relatedAssetUids;
        this.restAPIName = data?.restAPIName;
        this.uid = data?.uid;
        this.unified = data?.unified;
        this.version = data?.version;
        this.entities = [];
        this.lookups = [];
        this.rules = [];
        this.tagEntries = [];
        this.tags = data?.tags;
        this.description = templateData?.description || data?.description;
        this.workflowMetadata = {};
        this.workflowStageReferences = [];
        this.computationalRules = data?.computationalRules;
        if (data) {
            ((data.rules || []) as Rule[]).forEach((rule) => {
                const ruleTemplate = templateData?.rules?.find((item) => item.uid === rule.uid);
                Object.assign(rule, Object.assign({}, ruleTemplate));
            });
            ((data.rules || []) as Rule[])
                .filter((rule) => rule.ruleLanguage === 'JAVASCRIPT')
                .forEach((rule) => {
                    rule.method = this.computationalRules[rule.ruleType];
                });
            data.entities
                ?.slice(0)
                ?.sort((a, b) => {
                    if (!templateData) {
                        return 0;
                    }
                    const aIndex = templateData?.entities?.find((e) => e.uid === a.uid)?.['entityOrder'];
                    const bIndex = templateData?.entities?.find((e) => e.uid === b.uid)?.['entityOrder'];
                    if (aIndex !== undefined && bIndex !== undefined) {
                        return aIndex - bIndex;
                    } else if (aIndex !== undefined) {
                        return -1;
                    } else if (bIndex !== undefined) {
                        return 1;
                    }
                    return 0;
                })
                .forEach((entity) => {
                    const templateEntity = templateData?.entities?.find((templateEntity) => templateEntity.uid === entity.uid);
                    this.entities.push(new Entity(entity, data.rules, data.masters, templateEntity));
                });
            data.lookups?.forEach((lookup) => {
                this.lookups.push(new LookupData(lookup));
            });
            data.rules?.forEach((rule) => {
                this.rules.push(new Rule(rule));
            });
            data.tagEntries?.forEach((tag) => {
                this.tagEntries.push(new Tag(tag));
            });
            if (data.workflowMetadata) {
                this.workflowMetadata.customizable = data.workflowMetadata.customizable;
                this.workflowMetadata.id = data.workflowMetadata.id;
                this.workflowMetadata.name = templateData?.['workflow']?.displayName || data.workflowMetadata.name;
                this.workflowMetadata.status = data.workflowMetadata.status;
                this.workflowMetadata.version = data.workflowMetadata.version;
                this.workflowMetadata.stages = [];
                (data.workflowMetadata.stages as any[])?.forEach((stage) => {
                    const stageTemplate = templateData?.['workflow']?.['workflowStages']?.find(
                        (stageTemplate) => stageTemplate?.uid === stage.id
                    );
                    this.workflowMetadata.stages.push(new WorkflowStageData(stage, stageTemplate));
                });
            }
            if (data.workflowStageReferences) {
                this.workflowStageReferences = data.workflowStageReferences.map((stage) => new WorkflowStageReferences(stage));
            }
        }
        this.masters = data?.masters;
        this.bridgeNodes = data?.bridgeNodes;
        this.canTriggerWorkflow = data?.canTriggerWorkflow;
        this.canCreateNewProcess = data?.canCreateNewProcess;
        this.canCancelInboundTransmission = data?.canCancelInboundTransmission;
        this.workflowPermissions = data?.workflowPermissions;
        this.createMasterColumnRefData(this.entities);
    }

    getPrimaryEntity = (): Entity => {
        return this.entities.find((entity) => entity.primary);
    };

    getEntity = (entityId): Entity => {
        let entity;
        for (let i = 0; i < this.entities.length; i++) {
            if (this.entities[i].uid === entityId) {
                entity = this.entities[i];
                break;
            } else if (this.entities[i].entities && this.entities[i].entities.length > 0) {
                entity = this.entities[i].getEntity(entityId);
                if (entity) {
                    break;
                }
            }
        }
        return entity;
    };

    getWorkflowUserActions = (workflowId: string): WorkflowUserAction[] => {
        for (let i = 0; i < this.workflowMetadata.stages.length; i++) {
            if (workflowId === this.workflowMetadata.stages[i].id) {
                return this.workflowMetadata.stages[i].userActions;
            }
        }
        return [];
    };

    getAllEntityNames? = () => {
        const entitiesObj = {};
        const allEntities = this.getAllEntitiesAsArray();
    };

    getAllEntitiesAsArray? = () => {
        const entities = [];
        for (let i = 0; i < this.entities.length; i++) {
            entities.push(this.entities[i]);
            const childEntities = this.entities[i].getAllEntitiesAsArray();
            for (let j = 0; j < childEntities.length; j++) {
                entities.push(childEntities[j]);
            }
        }
        return entities;
    };

    getEntityIdVsName? = (): { property?: Entity['name'] } => {
        const entities: Entity[] = this.getAllEntitiesAsArray();
        const entitiesObj = {};
        for (let i = 0; i < entities.length; i++) {
            entitiesObj[entities[i].uid] = entities[i].name;
        }
        return entitiesObj;
    };

    private createMasterColumnRefData = (entities: Entity[]) => {
        entities.forEach(this.checkEntitiesMasterColumnRefData);
    };

    private checkEntitiesMasterColumnRefData = (entity: Entity) => {
        entity.fields
            .filter((field) => !field.hasMasterLookup && field.masterData.columnRefMetadatas?.length > 0)
            .forEach(this.checkFieldMasterColumnRefData);
        entity.entities?.length > 0 && this.createMasterColumnRefData(entity.entities);
    };

    private checkFieldMasterColumnRefData = (field: Field) => {
        field.masterData.columnRefMetadatas.forEach((ref, index) => {
            const refsToPush = index > 0 ? field.masterData.columnRefMetadatas.slice(0, index) : [];
            if (!(refsToPush.length > 0)) {
                return;
            }
            const entity = this.getEntity(ref.entityUid);
            const entityField = entity.getField(ref.fieldUid);
            this.pushEntityRefs(entityField, refsToPush);
        });
    };

    private pushEntityRefs = (field: Field, refs: Field['masterData']['columnRefMetadatas']) => {
        refs.filter(
            (ref) =>
                !field.masterData.columnRefMetadatas.find(
                    (fieldRef) => fieldRef.entityUid === ref.entityUid && fieldRef.fieldUid === ref.fieldUid
                )
        ).forEach((ref) => {
            field.masterData.columnRefMetadatas.push(ref);
        });
    };
}
