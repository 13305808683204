<div class="filterByTimeRangeContainer" *ngIf="selectedOutlookFilter?.name === 'filterByTime'">
    <div class="row" *transloco="let translate;">
        <div class="col-4">
            <material-input-date [placeholder]="translate('From Date')" [id]="'processStartingDate'" [model]="timeFilter.startDate" [inputOnlyDisabled]="true" (modelChange)="timeFilter.startDate = $event;"></material-input-date>
        </div>
        <div class="col-4">
            <mat-form-field>
                <ng-container *ngIf="isIE && timeFilter.startTime;else noTime">
                    <input matInput placeholder="{{translate('Start Time')}}" [(ngModel)]="timeFilter.startTime" readonly />
                </ng-container>
                <ng-template #noTime>
                    <input matInput [ngxTimepicker]="StartTimePick" placeholder="{{translate('Start Time')}}" [(ngModel)]="timeFilter.startTime" readonly />
                </ng-template>
                <button mat-button matSuffix mat-icon-button tabindex="-1" class="timePick">
                    <ngx-material-timepicker-toggle [for]="StartTimePick"></ngx-material-timepicker-toggle>
                </button>
                <ngx-material-timepicker #StartTimePick [theme]="darkTheme" (timeChanged)="timeFilter.startTime = $event;"></ngx-material-timepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *transloco="let translate;">
        <div class="col-4">
            <material-input-date [placeholder]="translate('To Date')" [id]="'processEndDate'" [model]="timeFilter.endDate" [inputOnlyDisabled]="true" (modelChange)="timeFilter.endDate = $event;"></material-input-date>
        </div>
        <div class="col-4">
            <mat-form-field>
                <ng-container *ngIf="isIE && timeFilter.endTime;else noEndTime">
                    <input matInput placeholder="{{translate('End time')}}" [(ngModel)]="timeFilter.endTime" readonly />
                </ng-container>
                <ng-template #noEndTime>
                    <input matInput [ngxTimepicker]="endTimePick" placeholder="{{translate('End time')}}" [(ngModel)]="timeFilter.endTime" readonly />
                </ng-template>
                <button mat-button matSuffix mat-icon-button tabindex="-1" class="timePick">
                    <ngx-material-timepicker-toggle [for]="endTimePick"></ngx-material-timepicker-toggle>
                </button>
                <ngx-material-timepicker #endTimePick [theme]="darkTheme" (timeChanged)="timeFilter.endTime = $event;"></ngx-material-timepicker>
            </mat-form-field>
        </div>
    </div>
</div>
