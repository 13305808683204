import { createFeatureSelector, createSelector } from '@ngrx/store';

import { INSTANCES_REDUCER_KEY } from '../reducers';
import { getCurrentOrganizationId$ } from './session.selector';

import type { InstancesState } from '../states';
const currentState = createFeatureSelector<InstancesState>(INSTANCES_REDUCER_KEY);

export const getInstancesOrgServicesMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizationsMap?.[organizationId]
);

export const getServicesInstancesMap$ = createSelector(currentState, (state) => state.instances);

export const getServiceInstancesMap$ = (serviceId: string) =>
    createSelector(getInstancesOrgServicesMap$, (instancesMap) => instancesMap?.[serviceId]);

export const getSearchInstancesMap$ = (serviceId: string) =>
    createSelector(getServiceInstancesMap$(serviceId), (instancesMap) => instancesMap?.searchedInstances);

export const getSearchInstanceIds$ = (serviceId: string, criteria: string) =>
    createSelector(getSearchInstancesMap$(serviceId), (searchInstances) => searchInstances?.[criteria]);

export const getInstance$ = (instanceId: string) => createSelector(getServicesInstancesMap$, (instancesMap) => instancesMap?.[instanceId]);

export const getInstancesState$ = createSelector(currentState, (state) => state.instancesState);

export const getInstanceErrors$ = (instanceId: string) =>
    createSelector(getInstancesState$, (instancesStatus) => instancesStatus?.[instanceId]?.errors);

export const hasInstanceErrors$ = (instanceId: string) => createSelector(getInstanceErrors$(instanceId), (errors) => errors?.length > 0);

export const getInstancesEntityStatus$ = createSelector(currentState, (state) => state.instanceEntitiesState);

export const getInstanceEntityErrors$ = (instanceId: string) =>
    createSelector(getInstancesEntityStatus$, (instancesStatus) => instancesStatus?.[instanceId]);

export const hasInstanceEntityErrors$ = (instanceId: string) =>
    createSelector(
        getInstanceEntityErrors$(instanceId),
        (errors) => Object.keys(errors || {}).find((key) => errors?.[key] === 'ERROR') !== undefined
    );

export const getSelectedInstancesMap$ = (serviceId: string) =>
    createSelector(getServiceInstancesMap$(serviceId), (instancesMap) => instancesMap?.selectedInstances);

export const getSelectedInstanceIds$ = (serviceId: string, criteria: string) =>
    createSelector(getSelectedInstancesMap$(serviceId), (selectedInstancesMap) => selectedInstancesMap?.[criteria]);

export const hasSelectedCompletedInstance$ = (serviceId: string, criteria: string) =>
    createSelector(
        getSelectedInstanceIds$(serviceId, criteria),
        getServicesInstancesMap$,
        (instanceIds, instances) => instanceIds?.find((id) => instances?.[id]?.instanceState === 'REQUEST_COMPLETED') !== undefined
    );

export const getProcessStatuses$ = (serviceId: string) =>
    createSelector(getServiceInstancesMap$(serviceId), (serviceInstance) => serviceInstance?.statuses);

export const getSelectedProcessStatus$ = (serviceId: string) =>
    createSelector(getServiceInstancesMap$(serviceId), (serviceInstance) => serviceInstance?.selectedStatus);

const getInstancesLoadingMap$ = (serviceId: string) =>
    createSelector(getServiceInstancesMap$(serviceId), (serviceMap) => serviceMap?.loading);

export const getInstancesLoadingStatus$ = (serviceId: string) =>
    createSelector(getInstancesLoadingMap$(serviceId), (loadingMap) => loadingMap?.instances);

export const getInstancesStatusesLoadingStatus$ = (serviceId: string) =>
    createSelector(getInstancesLoadingMap$(serviceId), (loadingMap) => loadingMap?.statuses);
