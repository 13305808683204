<div class="w-80 flex justify-end fixed right-[3.0rem] top-[1.9rem]" *transloco="let translate;">
    <div *ngIf="!isClosed">
        <button [title]="translate('Edit')" *ngIf="eventType === 'view'" (click)="editTable('edit')">
            <mat-icon class="text-[16px] width-[16px] height-[16px]">edit</mat-icon>
        </button>
    </div>
    <div class="mr-2 -mt-1 font-bold" *ngIf="eventType === 'edit'">Edit Mode</div>
    <div>
        <button [title]="translate('Close')" (click)="closeModal()">
            <mat-icon class="font-bold text-[16px] width-[16px] height-[16px]">close</mat-icon>
        </button>
    </div>
</div>
<mat-dialog-content class="dialogContainer">
    <app-entities-template [instanceId]="instanceId" [entities]="entities" [opened]="true" [parentEntityUid]="parentEntityUid" [parentId]="parentId" [service]="service" [parentMasterTablesData]="parentMasterTablesData" [eventType]="eventType" [parentLookupsData]="parentLookupsData" [masterRecordsResponse]="masterRecordsResponse" [lookupsResponse]="lookupsResponse" (lookUpUpdated)="lookUpUpdated($event)"></app-entities-template>
</mat-dialog-content>
<mat-dialog-actions align="center" *transloco="let translate;">
    <material-button [label]="'Close'" [color]="'default'" (onTrigger)="closeModal()"></material-button>
    <material-button *ngIf="eventType !== 'view'" class="ml-3" [label]="translate('Submit')" color="primary" (onTrigger)="validateRecords()"></material-button>
</mat-dialog-actions>
