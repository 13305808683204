import { AfterViewInit, Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';
import { BridgeNode } from 'taxilla-library';

import { AssetService } from '../../models/assetservice.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[AppNewProcess]',
})
export class AppNewProcessDirective implements AfterViewInit, OnChanges {
    @Input() app: AssetService;
    @Input() bridge: AssetService;
    @Input() report: BridgeNode;
    @Input() getDirectLink: boolean;
    @Input() appId: string;
    @Input() bridgeId: string;
    @Input() filingAttributeFields: any;
    @Input() filingAttributeFieldContainer: any;
    @Input() newProcessInSameTab: boolean;
    @Input() uiType: string;

    pollForAssetMetaData: any;

    constructor(
        private _api: ApiService,
        private _utils: UtilsService,
        protected _router: Router,
        private _commonUtils: CommonUtilsService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.appId || changes.bridgeId) {
                this.startComponent();
            }
        }
    }

    @HostListener('click', ['$event'])
    onAppSelection = async (event?: Event) => {
        if (this.newProcessInSameTab) {
            if (this.app.assetType === 'BRIDGE_ASSET') {
                return;
            }
            event && event.stopPropagation();
            await this.getAppTags();
            this.checkRouteBasedOnTag();
        } else if (this.getDirectLink && event) {
            this.setFilingAttributesInStorage();
            if (!environment['enreport-ui']) {
                event && event.stopPropagation();
            }
        } else {
            if (this.app.assetType === 'BRIDGE_ASSET') {
                return;
            }
            event && event.stopPropagation();
            await this.getAppTags();
            if (['encomply', 'enreport', 'eninvoice', 'encollab', 'enreconcile'].includes(this.uiType)) {
                this.routeToUI(this.uiType === 'encomply' ? 'enreport' : (this.uiType as any));
            } else {
                this.checkRouteBasedOnTag();
            }
        }
    };

    private getAppTags = (): Promise<any> => {
        const promise = new Promise((resolve, reject) => {
            this.waitTillLoadingCompleted(() => {
                if (this.app?.tags) {
                    resolve(this.app.tags);
                } else if (this.app?.assetType === 'RECON') {
                    this.app.tags = [
                        {
                            tagKey: 'uiType',
                            tagValue: 'RECON',
                        },
                    ];
                    resolve(this.app.tags);
                } else {
                    const serviceId = this.bridge ? this.bridge.serviceId : this.app.serviceId;
                    this.app['loading'] && (this.app['loading']['assetMetaData'] = true);
                    this._api.assets.getAssetTags(
                        {
                            serviceId,
                            tagKey: 'uitype',
                            noAlerts: true,
                        },
                        {
                            successCallback: (response: {
                                taggedMetadatas: {
                                    metadataId: string;
                                    metadataName: string;
                                    metadataType: string;
                                    repositoryId: string;
                                    serviceId: string;
                                    tagKey: string;
                                    tagValue: string;
                                }[];
                            }) => {
                                setTimeout(() => {
                                    this.app['loading'] && (this.app['loading']['assetMetaData'] = false);
                                }, 10);
                                const tags: AssetService['tags'] = [];
                                response.taggedMetadatas.forEach((tagObject) => {
                                    tags.push(tagObject);
                                });
                                this.app.tags = tags;
                                resolve(tags);
                            },
                            failureCallback: (response) => {
                                this.app['loading'] && (this.app['loading']['assetMetaData'] = false);
                                this.app['appNewProcessLink'] = undefined;
                                this._utils.alertError(response && response.msg);
                            },
                        }
                    );
                }
            });
        });
        return promise;
    };

    waitTillLoadingCompleted = (callback) => {
        if (this.app['loading'] && this.app['loading']['assetMetaData']) {
            setTimeout(() => {
                this.waitTillLoadingCompleted(callback);
            }, 100);
        } else {
            callback();
        }
    };

    private checkRouteBasedOnTag = () => {
        const assetTags = this.app.tags || [];
        const tagValues = assetTags.reduce((list, tag) => {
            list.push(tag.tagValue);
            return list;
        }, []);
        this.setWindowRoute(tagValues);
    };

    private setWindowRoute = async (tags: string[]) => {
        let found = false;
        const href = window.location.href;
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        if (this.app.assetType === 'RECON') {
            this.routeToUI('enreconcile');
        } else if (
            tags &&
            tags.length > 0 &&
            href.indexOf(enReport) === -1 &&
            href.indexOf(enInvoice) === -1 &&
            href.indexOf(enCollab) === -1
        ) {
            tags.forEach((tag) => {
                if (found) {
                    return;
                }
                if (
                    environment.restrictedProcessingUIs &&
                    environment.restrictedProcessingUIs.length &&
                    environment.restrictedProcessingUIs.indexOf(tag.toLowerCase()) >= 0
                ) {
                    tag = environment.defaultProcessingUI ? environment.defaultProcessingUI : 'enreport';
                }
                switch (tag.toLowerCase()) {
                    case 'eninvoice':
                        found = true;
                        this.routeToUI('eninvoice');
                        break;
                    case 'enreport':
                        found = true;
                        this.routeToUI();
                        break;
                    case 'enCollab':
                        found = true;
                        this.routeToUI('encollab');
                        break;
                }
            });
            if (!found) {
                const cumulativeTags = tags.reduce((values, tag) => {
                    values.push(tag);
                    return values;
                }, []);
                const supportedTag = ['eninvoice', 'encollab', 'enreport', 'enreconcile'].find((tag) => cumulativeTags.includes(tag));
                if (supportedTag) {
                    this.routeToUI(supportedTag as any);
                    return;
                }
                const collaborationApps = await this.fetchCollaborationApps();
                const isEnCollaborateApp = collaborationApps?.find((app) => app.restApiName === this.app.restApiName);
                this.routeToUI(isEnCollaborateApp ? 'encollab' : 'enreport');
            }
        } else {
            this.routeToUI();
        }
    };

    private fetchCollaborationApps = (): Promise<AssetService[]> => {
        return new Promise((resolve) => {
            this._api.subscriptions.getCollaboratedApps(
                {},
                {
                    successCallback: (response) => {
                        resolve(response as any);
                    },
                }
            );
        });
    };

    private routeToUI = (ui?: 'eninvoice' | 'enreport' | 'encollab' | 'enreconcile') => {
        const href = window.location.href;
        const enInvoice = environment['eninvoice-ui'];
        const enReport = environment['enreport-ui'];
        const enCollab = environment['encollab-ui'];
        const enReconcile = environment['enreconcile-ui'];
        ui =
            ui ||
            (href.indexOf(enInvoice) > -1 && 'eninvoice') ||
            (href.indexOf(enReport) > -1 && 'enreport') ||
            (href.indexOf(enCollab) > -1 && 'encollab') ||
            (href.indexOf(enReconcile) > -1 && 'enreconcile') ||
            (environment.defaultProcessingUI as any) ||
            'enreport';
        const routes: string[] = [ui];
        routes.push('organizations', this._commonUtils.getFromStorage('currentOrganizationId'));
        ui === 'enreconcile' && routes.push('home');
        if (this.bridge) {
            routes.push('packages', this.bridge.restApiName);
        }
        if (this.app) {
            routes.push('apps', this.app.restApiName);
        }
        if (this.report) {
            routes.push('reports', this.report.name);
        }
        if (routes.indexOf('eninvoice') > -1 || routes.indexOf('encollab') > -1 || routes.indexOf('enreconcile') > -1) {
            routes.push('processes');
        }
        if (this.report) {
            routes.push('report-new');
        } else {
            routes.push('new');
        }
        const reroute = '/' + routes.join('/');
        if (this.getDirectLink && !this.newProcessInSameTab) {
            if (this.report) {
                this.report['appNewProcessLink'] = reroute;
            } else {
                this.app['appNewProcessLink'] = reroute;
            }
        } else {
            this._router.navigate(routes);
        }
    };

    setFilingAttributesInStorage = () => {
        if (this.filingAttributeFields && this.filingAttributeFields.length > 0) {
            this._commonUtils.setInStorage('filingAttributeFields', this.filingAttributeFields);
        }
    };

    private initiateController = () => {
        this.app['loading'] = this.app['loading'] || {};
        if (this.app['loading'].assetMetaData) {
            this.pollForAssetMetaData = setTimeout(this.initiateController, 1000);
        } else {
            clearTimeout(this.pollForAssetMetaData);
            setTimeout(this.onAppSelection, 100);
        }
    };

    private startComponent = () => {
        if (this.getDirectLink && !this.newProcessInSameTab) {
            if (this.app && !(this.app as any).hasOwnProperty('appNewProcessLink')) {
                this.initiateController();
            }
        }
    };

    ngAfterViewInit() {
        this.startComponent();
    }
}
