import { Location } from '@angular/common';
import { Injectable, Sanitizer } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
    MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { environment } from '@env';
import { BroadcasterService } from 'ng-broadcaster';
import { Subscription } from 'rxjs';
import { AssetService, BridgeNode, BridgeService, CommonUtilsService, Entity, Organization, Record, UtilsService } from 'taxilla-library';

import { RootScope } from '../rootscope/rootscope.service';

declare var offsetTime: number;

/**
 * Utils provider
 * This provider is used to handle all common utility methods.
 */
@Injectable()
export class Utils {
    constructor(
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private R: RootScope,
        public snackBar: MatSnackBar,
        private browserUrl: Location,
        private _sanitizer: Sanitizer,
        private _bridge: BridgeService,
        private _commonUtils: CommonUtilsService,
        private _libUtils: UtilsService,
        private _broadcaster: BroadcasterService
    ) {
        this.setOffsetTime();
    }

    static offsetTime: number;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    checkNumber = Utils.checkNumber;
    checkDoubleNumber = Utils.checkDoubleNumber;
    checkNumberWithoutDecimal = Utils.checkNumberWithoutDecimal;
    checkNumberForAccessApi = Utils.checkNumberForAccessApi;
    checkDistance = Utils.checkDistance;
    checkZeroes = Utils.checkZeroes;
    checkPhoneNumberwithLimit = Utils.checkPhoneNumberwithLimit;
    checkInvoiceNumber = Utils.checkInvoiceNumber;
    acceptGSTIN = Utils.acceptGSTIN;
    getPropertyValue = Utils.getPropertyValue;
    transformDate = CommonUtilsService.transformDate;
    checkMaxLength = Utils.checkMaxLength;
    RouteRefreshSubscription: Subscription;
    checkInteger = Utils.checkInteger;
    checkFloat = Utils.checkFloat;
    checkOrgCode = Utils.checkOrgCode;
    transformDateToLocale = CommonUtilsService.transformDateToLocale;
    transformDateToDefaultFormat = CommonUtilsService.transformDateToDefaultFormat;
    /**
     * Regex method to check the if the input string contains only number.
     */
    static checkNumber = (number) => {
        const pattern = /^\d+(\.\d+)?$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input is integer or not.
     */
    static checkInteger = (number) => {
        const pattern = /^\d+$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input is float.
     */
    static checkFloat = (number) => {
        const pattern = /^[+-]?([0-9]*[.])?[0-9]+/;
        return pattern.test(number);
    };
    /**
     * Regex method to check the if the input string contains only number without decimal.
     */
    static checkNumberWithoutDecimal = (number) => {
        const pattern = /^\d+(\d+)?$/;
        return pattern.test(number);
    };
    /**
     * Regex method to check the if the input string contains double number.
     */
    static checkDoubleNumber = (number) => {
        const pattern = /^\d{0,2}(\.\d{0,2}){0,1}$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input string contains only number.
     */
    static checkNumberForAccessApi = (number) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(number);
    };

    static checkOrgCode = (code) => {
        const pattern = /^([a-zA-Z0-9-]+)$/;
        return pattern.test(code);
    };

    static checkDistance = (number) => {
        const pattern = /^([1-3]?\d{1,3}|4000)$/;
        if (number) {
            if (number.match(pattern)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    static checkZeroes = (number) => {
        const pattern = /^[^123456789]+$/;
        if (number.match(pattern)) {
            return false;
        } else {
            return true;
        }
    };

    static checkPhoneNumberwithLimit = (str) => {
        const phoneno = /^(?!0{10,15})([0-9]{10,15})$/;
        if (str) {
            if (str.match(phoneno)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    // To check Invoice Number

    static checkInvoiceNumber = (number) => {
        const regex = /^[[A-Za-z0-9\\/\_-]{0,16}]*$/;
        if (number) {
            if (number.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    // checking length of value
    static checkMaxLength = (value, charcount) => {
        if (value && charcount) {
            if (value.length < charcount) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid GSTIN number.
     */
    static acceptGSTIN = (gst_in) => {
        // var regex = /^[[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[A-Za-z0-9]{1}[Z]{1}[A-Za-z0-9]{1}]*$/;
        const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
        gst_in = gst_in && gst_in.trim();
        return gst_in && (regex.test(gst_in) || /URP/.test(gst_in));
    };

    /**
     * Method to generate random string
     */
    static s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(10)
            .substring(0, 1);
    };

    /**
     * Method to generate random guid
     */
    static guid = (length?) => {
        let str = '';
        for (let i = 0; i < (length || 4); i++) {
            const rn = Utils.s4();
            str += rn + '';
        }
        return str;
    };

    static getPropertyValue = (propertyKeys: string[], object: Object) => {
        let returnObject: any;
        propertyKeys &&
            propertyKeys.forEach((key) => {
                if (typeof object[key] === 'object') {
                    /** Do Something */
                    returnObject = object[key];
                } else if (typeof object[key] === 'string' && propertyKeys.indexOf(key) === propertyKeys.length - 1) {
                    returnObject = object[key];
                } else if (typeof object[key] === 'string' && propertyKeys.indexOf(key) !== propertyKeys.length - 1) {
                    if (object[object[key]]) {
                        returnObject = Utils.getPropertyValue(propertyKeys.splice(propertyKeys.indexOf(key), 1), object[object[key]]);
                    }
                }
            });
        return returnObject;
    };

    setOffsetTime = () => {
        const tzOffset: any = this.getFromStorage('timeZoneOffset');
        offsetTime = !isNaN(parseInt(tzOffset)) ? tzOffset : -330;
    };

    /**
     * Setting values in Local storage
     */
    setInStorage = (property, value) => {
        this._commonUtils.setInStorage(property, value);
    };

    /**
     * Retrieving values from Local storage
     */
    getFromStorage = (property) => {
        return this._commonUtils.getFromStorage(property);
    };

    /**
     * Removing a value from Local storage
     */
    removeFromStorage = (property) => {
        if (property && property.length > 0) {
            this._commonUtils.removeFromStorage(property);
        }
    };

    /**
     * Removing all values from Local storage
     */
    clearStorage = () => {
        this._commonUtils.clearStorage();
    };

    /**
     * Setting a page route
     */
    setRoute = (url, options?) => {
        this.setUrlRoute({ url: url }, options);
    };

    /**
     * Setting a page route with router
     */
    setUrlRoute = (data, options?) => {
        if (typeof data.url === 'string') {
            this._router.navigate([data.url], options);
        } else if (Array.isArray(data.url)) {
            this._router.navigate(data.url, options);
        }
    };

    /**
     * Setting a page route with router
     */
    setSilentRoute = (url) => {
        this._router.navigateByUrl(url);
    };

    /**
     * Setting a page route with params
     */
    setRouteWithParam = (url, params) => {
        this._router.navigate([url, params]);
    };

    log = (messsage: any) => {
        if (environment.production) {
            // console.log(messsage);
        }
    };

    /**
     * Regex to control the input length to 16 characters max
     */
    checkForMaxNums = (str) => {
        const pattern = /^[a-zA-Z]{16}$/;
        if (!str.match(pattern)) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Regex method to check the if the input string has atleast one alphabet and one numeric character.
     */
    atleastOneAlphaNumeric = (str) => {
        const patternd = /\d/;
        const patterna = /[a-zA-Z]/i;
        const result = patterna.test(str) && patternd.test(str) && !this.invalidChars(str);
        return !result;
    };

    atleastOneAlphaNumericForLoc = (str) => {
        const patternd = /\d/;
        const patterna = /[a-zA-Z]/i;
        const result = patterna.test(str) && patternd.test(str);
        return result;
    };
    /**
     * Regex method to check if the input has one alphabet and one numeric character[custom method for adding location modal]
     */
    alphaNumericMust = (str) => {
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return this._libUtils.alertNotify('Location name must be alphanumeric', 3000);
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string contains any invalid characters like ( > < . : ) characters.
     */
    invalidChars = (str) => {
        const pattern2 = /^(.)*<(.)*>(.)*$/;
        const pattern3 = /^(.)*:(.)*$/;
        return pattern2.test(str) || pattern3.test(str);
    };

    /**
     * Regex method to check the if the input string contains number or hyphen.
     */
    checkNumberPlusHyphen = (numberspecial) => {
        const pattern = /^[+]?[0-9-]+[0-9]+$/;
        return pattern.test(numberspecial);
    };

    /**
     * Regex method to check the if the input string  is a valid phone number.
     */
    checkPhoneNumber = (str) => {
        const phoneno = /^(?!0{10})([0-9]{10})$/;
        return str && str.match(phoneno) && str.trim().length >= 5 && str.trim().length <= 10;
    };

    checkSpecialChrs = (str) => {
        const pattern = /^[[A-Za-z]{1}[A-Za-z0-9_$!&*. -]*$/;
        if (str && str.match(pattern)) {
            return true;
        }
        return false;
    };
    checkSpecialChrsWithHyphen = (str) => {
        const pattern = /^[[A-Za-z]{1}[A-Za-z0-9-]*$/;
        if (str && str.match(pattern)) {
            return true;
        }
        return false;
    };

    checkUserId = (str) => {
        // const pattern = /^[[A-Za-z0-9_.@-]*$/;
        const pattern = /^[\\sA-Za-z0-9\\//@\\.\\-\\_]+$/;
        if (str && str.match(pattern)) {
            return true;
        }
        return false;
    };

    checkForSpecialChars = (str) => {
        const pattern = /[!@#$%^&*(),.?":{}|<>]/;
        if (str.match(pattern)) {
            return true;
        } else {
            return false;
        }
    };

    checkPostalCode = (str) => {
        const zipcode = /^(?!0{6})([0-9]{6})$/;
        if (str) {
            if (str.match(zipcode)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };
    /**
     * Regex method to check the if the input string  is a valid phone number.
     */
    checkFaxNumber = (str) => {
        // var faxNo = /^[+][0-9]{2}-[0-9]{2,3}-[0-9]{7}$/;
        const faxNo =
            /^([\(\+])?([0-9]{1,2}([\s])?)?([\+|\(|\-|\)|\s])?([0-9]{2,3})([\-|\)|\.|\s]([\s])?)?([0-9]{2,3})?([\.|\-|\s])?([0-9]{4,7})$/;
        // return str && str.match(faxNo) && str.trim().length >= 10 && str.trim().length <= 15;
        return faxNo.test(str);
    };

    /**
     * Regex method to check the if the input string is a valid URL.
     */
    checkUrl = (url) => {
        let regex: RegExp;
        if (/^(http[s]?:\/\/www\.)/.test(url)) {
            regex =
                /^((http|HTTP)[sS]?:\/\/[wW]{3}.)([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        } else if (/^((http|HTTP)[sS]?:\/\/)/.test(url)) {
            regex =
                /^(http|HTTP)[sS]?:\/\/([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        } else if (/^([wW]{3}\.)/.test(url)) {
            regex =
                /^[wW]{3}\.([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        } else {
            regex =
                /^([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        }
        return regex.test(url);
    };

    checkWebUrl = (url) => {
        // const urlExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        const urlExp =
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        if (url) {
            if (url.match(urlExp)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    checkName = (name) => {
        name = (name as string).trim();
        if (!name || (name as string).trim() === '') {
            // this.errors.organizationName = ['Enter organization name'];
            return false;
        } else if (name && (name as string).length > 30) {
            // this.errors.organizationName = ['Maximum number of characters allowed is 30'];
            return false;
        } else if (this.invalidChars(name)) {
            // this.errors.organizationName = ['Enter a valid organization name'];
            return false;
        } else if (!this.acceptOrgName(name)) {
            // this.errors.organizationName = ['Organization name must be alpha numeric'];
            return false;
        }
        return true;
    };

    /**
     * Regex to replace multiple spaces with single
     */
    replaceMultipleSpacesWithSingle = (str) => {
        return str.replace(/\s\s+/g, ' ');
    };

    /**
     * Regex method to replace special characters with - character.
     */
    replaceSpecialChars = (str) => {
        return str.replace(/[^a-zA-Z0-9]/g, '-');
    };

    /**
     * Regex method to check the if the input string is a valid organization name.
     */
    acceptOrgName = (org_name) => {
        // var regex = /^[[A-Z]{1}[A-Za-z0-9+ +.+'+\-+\x26]{0,49}]*$/;
        // const regex = /^([A-Za-z0-9-\s]{0,99})*$/;
        const regex = /^([A-Za-z0-9 _]{0,99})*$/;
        return org_name && regex.test(org_name);
    };

    acceptUserId = (userId) => {
        // var regex = /^[[A-Z]{1}[A-Za-z0-9+ +.+'+\-+\x26]{0,49}]*$/;
        const regex = /^[A-Za-z0-9_.@\s-]*$/;
        if (userId) {
            if (userId.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string includes letters,numbers and space.
     */
    acceptLocationName = (loc_name) => {
        const regex = /^[0-9A-Za-z\s]+$/;
        if (loc_name) {
            if (loc_name.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    removeWhitespaces = (data) => {
        if (data && data !== undefined) {
            const gstin = data.trim();
            return gstin;
        }
    };
    /**
     * Regex method to check the if the input string is a valid PAN number.
     */
    acceptPAN = (pan) => {
        const regex = /^([A-Z]{3}[C,P,H,F,A,T,B,L,J,G][A-Z][0-9]{4}[A-Z])$|^$|^(\\d *?){11}$/;
        return pan && pan.match(regex);
    };

    /**
     * Regex method to check the if the input string is a valid ABN number.
     */
    acceptABN = (pan) => {
        const regex = /^(\d *?){11}$/;
        return pan && pan.match(regex);
    };

    /**
     * Regex method to check the address
     */
    acceptAddress = (address) => {
        const regex = /^[[A-Za-z0-9+ +:+.+#+,+/+\x26+\-]{0,49}]*$/;
        if (address) {
            if (address.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid phone number.
     */
    acceptPhoneNo = (phone_no) => {
        const regex = /^[0-9]{10}$|^$/;
        if (phone_no) {
            if (phone_no.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid australia phone number.
     */
    acceptAusPhoneNo = (phone_no) => {
        const regex = /^[0-9]{11}$|^$/;
        if (phone_no) {
            if (phone_no.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string contains alphabets.
     */
    acceptAlphabets = (str) => {
        const regex = /^[A-Za-z+ +]*$/;

        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    acceptPortNumber = (str) => {
        const regex = /^([0-9]{0,4})$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptIpAddress = (str) => {
        // var regex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
        const regex = /^[A-Za-z0-9\/_.:-]*$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptFolderPath = (str) => {
        // var regex = /^[A-Za-z0-9+\/?+_?+-?]*$/;
        const regex = /^[A-Za-z0-9\\/\_-]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    toFindStarInString = (str) => {
        const regex = /^[/*]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };
    acceptAlphaNumeric = (str) => {
        const regex = /^[A-Za-z0-9 ]*$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptRoleName = (str) => {
        // const regex = /^[[A-Za-z]{1}[A-Za-z0-9_ ]*$/;
        const regex = /^[0-9A-Za-z ]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    checkValueExists = (value: any): boolean => {
        if (typeof value === 'string') {
            return value !== undefined && value.trim().length > 0;
        }
        if (typeof value === 'object' && this.isEmpty(value)) {
            return false;
        }
        return value !== undefined;
    };

    /**
     * Transforming a text to first letter upper case and remaining lower case
     */
    transformText = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.substring(1);
    };

    /**
     * method to check object empty or not
     */
    isEmpty = (obj) => {
        return obj === null || undefined
            ? true
            : (() => {
                  for (const prop in obj) {
                      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                          return false;
                      }
                  }
                  return true;
              })();
    };

    guid = (length?) => Utils.guid(length);

    /**
     * Get operators per datatype for elastic search query builder
     */

    getOperatorsPerDataType = (dt?) => {
        const operatorsPerDataType = {
            date: ['EQ', 'NOT_EQUALS', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
            number: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
            textarea: ['EQ', 'NOT_EQUALS', 'CONTAINS', 'is null', 'is not null'],
            string: ['EQ', 'NOT_EQUALS', 'IN', 'CONTAINS', 'is null', 'is not null'],
            options: ['EQ', 'NOT_EQUALS', 'IN', 'is null', 'is not null'],
            equals: ['EQ', 'NOT_EQUALS'],
            boolean: ['EQ', 'NOT_EQUALS', 'is null', 'is not null'],
        };

        if (dt) {
            return operatorsPerDataType[dt];
        }

        return operatorsPerDataType;
    };

    /**
     * Get allowed datatype
     */

    getDataType = (dataType: string) => {
        const dt = dataType && dataType.toLowerCase();
        let allowedDT = '';
        switch (dt) {
            case 'string':
                allowedDT = 'string';
                break;
            case 'textarea':
                allowedDT = 'textarea';
                break;
            case 'boolean':
                allowedDT = 'boolean';
                break;
            case 'date':
                allowedDT = 'date';
                break;
            case 'double':
                allowedDT = 'number';
                break;
            case 'int':
                allowedDT = 'number';
                break;
            case 'number':
                allowedDT = 'number';
                break;
            case 'checkbox':
                allowedDT = 'category';
                break;
            case 'equals':
                allowedDT = 'equals';
                break;
            case 'options':
                allowedDT = 'multiselect';
                break;
            case 'long':
                allowedDT = 'number';
                break;
            default:
                allowedDT = 'string';
                break;
        }
        return allowedDT;
    };

    getAlphabetsArray = () => {
        return [
            'A',
            'B',
            'c',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ];
    };

    /*
    Checking the given path is present on the URL
    */
    isCurrentPath = (path) => {
        return this.activatedRoute['_routerState'] && this.activatedRoute['_routerState'].snapshot.url.indexOf(path) > -1;
    };
    /**
     * Check current path in the browser
     */
    hasCurrentPathInUrl = (path) => {
        return this.browserUrl && this.browserUrl.path() && this.browserUrl.path().indexOf(path) > -1;
    };

    createNode = (org?) => {
        if (!org) {
            return;
        }
        org.nextNodes = [];
        return org;
    };

    addToMap = (node, map) => {
        if (!node) {
            return;
        }
        if (!map) {
            map = {};
        }
        // Update: Decided to override it as in createNode a new node object will be created in the second iterations. This will have a new address in memory. So, we'll need this new address.
        map[node.id] = node;
        return map;
    };

    getOrganizationName = (organization?, delimiter?) => {
        if (!organization) {
            return;
        }
        if (!organization.parent) {
            return organization.name;
        } else {
            return this.getOrganizationName(organization.parent) + (delimiter ? '  ' + delimiter + '  ' : '  |  ') + organization.name;
        }
    };

    isObject = (obj) => {
        return obj && typeof obj === 'object' && obj instanceof Object && !(obj instanceof Array);
    };

    getOrgLoginId = (name) => {
        // const currentDate = new Date();
        let orgName = (name as string).trim();
        orgName = this.replaceMultipleSpacesWithSingle(orgName);
        orgName = this.replaceSpecialChars(orgName);
        orgName = orgName.toLowerCase();
        const orgLoginId = orgName + '-' + this.guid(5);
        return orgLoginId;
    };

    isEquivalent = (a, b) => {
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            const propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    };

    getAppCategory = (tags: AssetService['tags']) => {
        const category = 'enReport';
        // const displayTags = {
        //     'enreport': 'enReport',
        //     'eninvoice': 'enInvoice'
        // };
        // const validTags = Object.keys(displayTags);
        // if (tags) {
        //     for (let i = 0; i < tags.length; i++) {
        //         if (validTags.indexOf(tags[i].toLowerCase()) >= 0) {
        //             if (this.R.RESTRICTEDPROCESSINGUIS && this.R.RESTRICTEDPROCESSINGUIS.length && this.R.RESTRICTEDPROCESSINGUIS.indexOf(tags[i].toLowerCase()) >= 0) {
        //                 category = this.R.DEFAULTPROCESSINGUI ? displayTags[this.R.DEFAULTPROCESSINGUI] : displayTags['enreport'];
        //                 break;
        //             } else {
        //                 category = displayTags[tags[i]];
        //                 break;
        //             }
        //         }
        //     }
        // }
        return category;
    };

    findParentNode = (element: Element, parentClass: string): Element => {
        if (element.parentElement) {
            const isParent = element.parentElement.classList.value.indexOf(parentClass) > -1;
            if (isParent) {
                return element;
            } else {
                return this.findParentNode(element.parentElement, parentClass);
            }
        }
    };

    transformSortingOrder = (array, args) => {
        if (array && array !== undefined) {
            array.sort((a: any, b: any) => {
                if (a[args] < b[args]) {
                    return -1;
                } else if (a[args] > b[args]) {
                    return 1;
                } else {
                    if (a.flexFields && b.flexFields) {
                        let x;
                        let y;
                        for (let i = 0; i < a.flexFields.length; i++) {
                            if (a.flexFields[i].fieldDefinition.fieldName === 'gstin') {
                                x = a.flexFields[i].userValue;
                            }
                        }
                        for (let j = 0; j < b.flexFields.length; j++) {
                            if (b.flexFields[j].fieldDefinition.fieldName === 'gstin') {
                                y = b.flexFields[j].userValue;
                            }
                        }
                        if (x < y) {
                            return -1;
                        } else if (x > y) {
                            return 1;
                        }
                    } else {
                        return 0;
                    }
                }
            });
            return array;
        }
    };

    /**
     * Refresh current route
     */
    refreshCurrentRoute = () => {
        // completely removing reloading of components on switch of locations
        const currentUrl = this._router.url;
        this._router.navigateByUrl('dummy');
        ((url) => {
            this.RouteRefreshSubscription = this._router.events.subscribe((event) => {
                if (event instanceof ChildActivationEnd) {
                    this._router.navigate([url]);
                    this.RouteRefreshSubscription && this.RouteRefreshSubscription.unsubscribe();
                }
            });
        })(currentUrl);
    };

    /**
     * {JSDoc}
     *
     * The splice() method changes the content of a string by removing a range of
     * characters and/or adding new characters.
     *
     * @param string newSubStr The String that is spliced in.
     * @param number start Index at which to start changing the string.
     * @param number delCount An integer indicating the number of old chars to remove.
     * @return string A new string with the spliced substring.
     */
    insertInString = (string: string, start: number, delCount: number, newSubStr: string): string => {
        return string.slice(0, start) + newSubStr + string.slice(start + Math.abs(delCount));
    };

    protected generateBaseRoute = (bridge?: AssetService, app?: AssetService, report?: BridgeNode): string[] => {
        const routes = ['enreport'];
        if (!bridge && !app && !report) {
            bridge = this.R.current.bridge;
            app = this.R.current.app;
            report = this.R.current.report as any;
        }
        routes.push('organizations', this.R.currentOrganizationId.value);
        if (bridge) {
            routes.push('packages', this.R.current.bridge.restApiName);
        }
        if (app) {
            routes.push('apps', this.R.current.app.restApiName);
        }
        if (report) {
            routes.push('reports', this.R.current.report.name);
        }
        return routes;
    };

    navigateToInboundTransmissionsFromNewProcessPage = (requestId) => {
        this.R.fromNewProcess = true;
        this.R.currentRequestId = requestId;
        const routes = this.generateBaseRoute();
        routes.push('processes');
        this._router.navigate(routes);
    };

    navigateToNewProcessPage = () => {
        const routes = this.generateBaseRoute();
        if (this.R.current.report) {
            routes.push('report-new');
        } else {
            routes.push('new');
        }
        this._router.navigate(routes);
    };

    navigateToProcessesPage = (bridge?: AssetService, app?: AssetService, report?: BridgeNode) => {
        this.R.current.showing = 'PROCESSES';
        const routes = this.generateBaseRoute(bridge, app, report);
        routes.push('processes');
        if (!report) {
            routes.push('state', 'all');
        }
        this._router.navigate(routes);
    };

    navigateToFilterInstancesWithRequestId = (inboundTransmissionId: string) => {
        const routes = this.generateBaseRoute();
        routes.push('processes', 'inbound-transmissions');
        routes.push(inboundTransmissionId, 'instances');
        this.R.current.showing = 'PROCESSES';
        this._router.navigate(routes);
    };

    navigateToInstancePage = (inboundTransmissionId: string, instanceId: string) => {
        const routes = this.generateBaseRoute();
        routes.push('processes');
        /// we have to add router for incomplete dataIds
        if (this.isCurrentPath('incomplete')) {
            routes.push('incomplete');
        }
        if (inboundTransmissionId) {
            routes.push('inbound-transmissions', inboundTransmissionId, 'instances');
        }
        routes.push(instanceId);
        this.R.current.showing = 'PROCESS';
        this._router.navigate(routes);
    };

    navigateToIncompleteInstancesPage = (appRestApiName) => {
        const routes = [];
        routes.push('enreport');
        routes.push('organizations', this.R.currentOrganizationId.value);
        routes.push('packages', this.R.current.bridge.restApiName);
        routes.push('apps', appRestApiName);
        routes.push('processes');
        routes.push('incomplete');
        this._router.navigate(routes);
    };

    navigateToOrganzationsPage = () => {
        const routes = this.generateBaseRoute();
        this._router.navigate(routes);
    };

    createNewRecord = (entity?: Entity) => {
        const record = new Record({}, entity || new Entity({}, [], undefined));
        return record;
    };

    sortArray = (arr: string[]) => {
        return arr.sort((a: string, b: string) => {
            if (a < b) {
                return -1;
            } else if (a > b) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    convertJSONtoBlob = (object): Blob => {
        const blob = new Blob([JSON.stringify(object)], {
            type: 'application/json',
        });
        return blob;
    };

    getServiceId = (getFull?: boolean): string => {
        const app = this.R.current.app;
        const bridge = this.R.current.bridge;
        const report = this.R.current.report;
        if (report && !getFull) {
            return (bridge && bridge.serviceId) || (app && app.serviceId);
        } else if (bridge) {
            return app.serviceId ? app.serviceId : bridge.serviceId + '.' + app.serviceId;
        }
        return app && app.serviceId;
    };

    getRestApiName = () => {
        const app = this.R.current.app;
        const report = this.R.current.report;
        if (report) {
            return report.restApiName;
        } else if (app) {
            return app.restApiName;
        }
    };

    isInPdf = (reportName: string) => {
        const format = reportName.substring(reportName.lastIndexOf('.'));
        return format && format.toLowerCase() === '.pdf';
    };

    findRootOrganization = (organization: Organization): Organization => {
        if (!organization) {
            return;
        } else if (organization.parent) {
            return this.findRootOrganization(organization.parent);
        } else if (organization.id) {
            return organization;
        }
    };

    waitTillLoadingCompleted = (callback: (...args: any) => void): void => {
        this._broadcaster.broadcast('message', {
            type: 'waitForLoading',
            callback,
        });
    };

    setCurrentView = () => {
        const url = this._router.url;
        const state = url.substring(url.lastIndexOf('/') + 1);
        const selectedState = this.R.current.state.value;
        switch (state) {
            case 'processes':
                if (this.R.fromNewProcess) {
                    this.R.current.showing = 'INBOUND_TRANSMISSIONS';
                    this.R.fromNewProcess = false;
                } else {
                    this.R.current.showing = 'PROCESSES';
                }
                break;
            case 'inbound-transmissions':
                this.R.current.showing = 'INBOUND_TRANSMISSIONS';
                break;
            case 'incomplete':
                this.R.current.showing = 'PROCESSES';
                break;
            case 'all':
                if (this.R.fromNewProcess) {
                    this.R.current.showing = 'INBOUND_TRANSMISSIONS';
                    this.R.fromNewProcess = false;
                } else {
                    this.R.current.showing = 'PROCESSES';
                }
                break;
            case 'in-progress':
            case 'completed':
            case 'cancelled':
            case 'incomplete':
            case 'terminated':
                if (url.indexOf('processes/state/' + state) > -1) {
                    this.R.current.showing = 'PROCESSES';
                } else if (url.indexOf('inbound-transmissions/state/' + state) > -1) {
                    this.R.current.showing = 'INBOUND_TRANSMISSIONS';
                }
                break;
            default:
                const currentURL = location.href;
                const currentState = currentURL.indexOf('/processes') > -1;
                if (selectedState && (selectedState as string).length > 0) {
                    this.R.current.showing = 'PROCESSES';
                } else if (this.R.current.inboundTransmissionId.value?.length > 0 && !this.R.current.instanceId.value && !currentState) {
                    this.R.current.showing = 'INBOUND_TRANSMISSIONS';
                } else if (this.R.current.inboundTransmissionId.value?.length > 0 && !this.R.current.instanceId.value && currentState) {
                    this.R.current.showing = 'PROCESSES';
                } else {
                    this.R.current.showing = 'PROCESS';
                }
                break;
        }
    };

    pushAtIndex = (array: any[], items: any, index: number) => {
        const clone = [];
        array
            .filter((item, itemIndex) => itemIndex < index)
            .forEach((item) => {
                clone.push(item);
            });
        if (Array.isArray(items)) {
            items.forEach((item) => clone.push(item));
        } else {
            clone.push(items);
        }
        array
            .filter((item, itemIndex) => itemIndex >= index)
            .forEach((item) => {
                clone.push(item);
            });
        return clone;
    };

    isIEBrowser = (): boolean => {
        const userAgent = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(userAgent);
        return isIE;
    };
}
