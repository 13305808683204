import { Organization } from './organization.class';

export class User {
    firstName: string;

    lastName: string;

    email: string;

    contactNumber: string;

    department: string;

    designation: string;

    emailNotification: boolean;

    expiryDate: string;

    organizationId: string;

    userId: string;

    enabled: boolean;

    id: string;

    displayName?: string;

    createdDate?: string;

    lastModifiedDate?: string;

    partnerOrganization?: Organization;

    partnerOrgId?: string;

    status?: string;

    systemDefined?: boolean;

    association?: string;

    requestedBy?: string;

    secretAnswer1?: string;

    secretAnswer2?: string;

    secretAnswer3?: string;

    secretAnswer4?: string;

    secretQuestion1?: string;

    secretQuestion2?: string;

    secretQuestion3?: string;

    secretQuestion4?: string;

    signatureUrls?: string;

    signImageUrl?: string;

    passphrase?: string;

    signatureFileName?: string;

    signatureImageFileName?: string;

    locked?: boolean;

    serviceUser?: boolean;

    admin?: boolean;

    shadowUser?: boolean;

    twoFAType?: string;

    constructor(userObject) {
        this.email = userObject?.email;
        this.firstName = userObject?.firstName;
        this.lastName = userObject?.lastName;
        this.userId = userObject?.userId;
        this.contactNumber = userObject?.contactNumber;
        this.department = userObject?.department;
        this.designation = userObject?.designation;
        this.emailNotification = userObject?.emailNotification;
        this.expiryDate = userObject?.expiryDate;
        this.organizationId = userObject?.organizationId;
        this.id = userObject?.id;
        this.enabled = userObject?.enabled;
        this.partnerOrganization = new Organization(userObject?.partnerOrganization);
        this.createdDate = userObject?.createdDate;
        this.lastModifiedDate = userObject?.lastModifiedDate;
        this.partnerOrgId = userObject?.partnerOrgId;
        this.status = userObject?.status;
        this.systemDefined = userObject?.systemDefined;
        this.association = userObject?.association;
        this.requestedBy = userObject?.requestedBy;
        this.secretAnswer1 = userObject?.secretAnswer1;
        this.secretAnswer2 = userObject?.secretAnswer2;
        this.secretAnswer3 = userObject?.secretAnswer3;
        this.secretAnswer4 = userObject?.secretAnswer4;
        this.secretQuestion1 = userObject?.secretQuestion1;
        this.secretQuestion2 = userObject?.secretQuestion2;
        this.secretQuestion3 = userObject?.secretQuestion3;
        this.secretQuestion4 = userObject?.secretAnswer4;
        this.signatureUrls = userObject?.signatureUrls;
        this.signImageUrl = userObject?.signImageUrl;
        this.passphrase = userObject?.passphrase;
        this.signatureFileName = userObject?.signatureFileName;
        this.signatureImageFileName = userObject?.signatureImageFileName;
        this.locked = userObject?.locked;
        this.serviceUser = userObject?.serviceUser;
        this.admin = userObject?.admin;
        this.shadowUser = userObject?.shadowUser;
        this.twoFAType = userObject?.twoFAType;
    }
}
