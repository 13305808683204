import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { translate, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { CollaboratePartners, Organization } from 'taxilla-library';

import { CollabConfiguration } from '../../models/collab-configuration.class';
import { ApiService } from '../../services/api/api.service';
import { CollaborationService } from '../../services/collaboration/collaboration.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { LocationsService } from '../../services/locations/locations.service';
import { RootScopeService } from '../../services/rootscope/rootscope.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-commoncollaborationlist',
    templateUrl: './commoncollaborationlist.component.html',
    styleUrls: ['./commoncollaborationlist.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class CommoncollaborationlistComponent implements OnInit {
    @Input() collaborationPartnerList;
    @Input() collabConfig: CollabConfiguration;
    @Input() instanceId: any;
    @Input() partnerFunctions: any[];
    @Input() assetId: any;
    @Input() collaborationAddList;
    @Input() required: boolean;

    @Output() resetCollaborators = new EventEmitter();

    targetAssetId: string;
    partnerField: string;
    collaborationModes: any[];
    collaborationMode: any;
    partnerOrganizations: Organization[];
    partnerId: string;
    comments: string;
    toEmails: any[];
    partnerFunction: string;
    addOnBlur = true;
    listOfCollaborators: any;
    addCollaboratorsForm: boolean;
    showListOfCollaborators: boolean;
    separatorKeysCodes = [ENTER, COMMA];
    chipDateCtrl = new UntypedFormControl('');

    constructor(
        private dialog: MatDialog,
        private _collabService: CollaborationService,
        private R: RootScopeService,
        public _utils: CommonUtilsService,
        public _taxilla: ApiService,
        private _libUtils: UtilsService,
        public _locations: LocationsService
    ) {}

    startComponent = () => {
        this.resetPartnerList();
        this.fetchCollaboratorsList();
    };

    onPartnerFunctionChange = (partnerFunction) => {
        this.partnerField = '';
        this.targetAssetId = '';
        const partnerAppSetting = this.getPartnerAppSettingByPartnerFunction(partnerFunction);
        if (partnerAppSetting) {
            this.partnerField = partnerAppSetting.partnerField;
            this.targetAssetId = partnerAppSetting.targetAssetId;
        }
        if (this.partnerField) {
            this.collaborationModes = [
                {
                    id: 1,
                    name: translate('Invite from Document'),
                },
                {
                    id: 2,
                    name: translate('Invite Individual'),
                },
                {
                    id: 3,
                    name: translate('Invite Partners'),
                },
            ];
        } else {
            this.collaborationModes = [
                {
                    id: 2,
                    name: translate('Invite Individual'),
                },
                {
                    id: 3,
                    name: translate('Invite Partners'),
                },
            ];
        }
    };

    getPartnerAppSettingByPartnerFunction = (partnerFunction) => {
        const partnerAppSetting = this.collabConfig.partnerAppSettings.find((setting) => setting.partnerFunction === partnerFunction);
        return partnerAppSetting;
    };

    onCollaborationModeChange = (mode) => {
        this.collaborationMode = mode.id;
        if (this.collaborationMode === 3) {
            this.getAllPartners();
        }
    };

    removeMails = (email) => {
        const index = (this.toEmails ??= []).indexOf(email);
        if (index >= 0) {
            this.toEmails.splice(index, 1);
        }
    };

    addMails = (event: MatChipInputEvent): void => {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            (this.toEmails ??= []).push(value.trim());
        }
        if (input) {
            input.value = '';
        }
        this.chipDateCtrl.setValue('');
    };

    getAllPartners = () => {
        const data = {
            organizationId: this._utils.getFromStorage('currentOrganizationId'),
        };
        this._locations.getPartnersInvitedByOrg(data, {
            successCallback: (res) => {
                this.partnerOrganizations = this.transformPartnersResponse(res, undefined);
            },
            failureCallback: (res) => {
                this._libUtils.alertError(res?.msg || translate('Failed to get partners'));
            },
        });
    };

    private transformPartnersResponse = (response: CollaboratePartners | CollaboratePartners[], organizations: Organization[] = []) => {
        if (Array.isArray(response)) {
            response.forEach((node) => {
                this.transformPartnersResponse(node, organizations);
            });
        } else {
            if (response?.organization?.id) {
                response.organization.displayTenantName = this._libUtils.getTenantName(response.organization);
                organizations.push(response.organization);
            }
            response?.nextNodes && this.transformPartnersResponse(response?.nextNodes, organizations);
        }
        return organizations;
    };

    onPartnerOrgChange = (partner) => {
        this.partnerId = partner;
    };

    sharePartnerRequest = () => {
        const data = {
            instanceId: this.instanceId,
            assetId: this.assetId,
            comments: this.comments,
            partnerFunction: this.partnerFunction,
        };

        if (this.collaborationMode === 1 && this.partnerField) {
            data['inviteUsingPartnerFields'] = true;
        } else if (this.collaborationMode === 2) {
            data['emails'] = this.toEmails;
        } else if (this.collaborationMode === 3) {
            data['partnerId'] = this.partnerId;
        }

        this._taxilla.collaboration.createPartnerRequest(data, {
            successCallback: (res) => {
                this._libUtils.alertSuccess(res?.msg || translate('Added collaboration configuration successfully'));
                this.resetPartnerList();
                this.fetchCollaboratorsList();
                this.resetCollaborators.emit();
            },
            failureCallback: (res) => {
                this._libUtils.alertError(res?.msg || translate('There was a problem adding collaboration configuration'));
            },
        });
    };

    fetchCollaboratorsList = () => {
        const data = {
            assetId: this.assetId,
            instanceId: this.instanceId,
        };
        this._taxilla.collaboration.getCollaboratorsList(data, {
            successCallback: (res) => {
                this.listOfCollaborators = res.response.collaborators;
                this.collaborationPartnerList = true;
                this.collaborationAddList = false;
            },
            failureCallback: (res) => {},
        });
    };

    CancelCollaboratorForm = () => {
        this.resetCollaborators.emit();
        this.resetPartnerList();
    };

    resetPartnerList = () => {
        this.partnerFunction = '';
        this.collaborationMode = '';
        this.comments = '';
        this.toEmails = [];
        this.partnerId = '';
    };

    closeModal = () => {
        this.dialog.closeAll();
    };

    ngOnInit(): void {
        this.startComponent();
    }
}
