<div class="snackBarMainDiv d-flex justify-content-between align-items-center">
    <div *ngIf="!data.icon" class="message cursorPointer">{{data.message}}<span class="actionText"
              (click)="snackBarRef.dismissWithAction()">{{data.action}}</span></div>
    <div *ngIf="data.icon" class="message cursorPointer">
        <mat-icon class="snackbarIcon">{{data.icon}}</mat-icon> <strong>{{data.iconText}} </strong>{{data.message}}<span class="actionText"
              (click)="snackBarRef.dismissWithAction()">{{data.action}}</span>
    </div>
    <mat-icon title="Close" class="material-icons-filled cancelIcon snackCloseBtnIcon position-relative" (click)="snackBarRef.dismiss()">
        close</mat-icon>
</div>
