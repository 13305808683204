import { CommonUtilsService } from '../services/commonutils/common-utils.service';
import { AssetData } from './assetdata.class';
import { Search } from './search.class';
import { FilterCriteria } from './searchcriteria.global';
import { Transformation } from './transformation';

export class ReconciliationRequest {
    configId: string;

    configName: string;

    configVersion: number;

    createdBy: string;

    createdDate: string;

    creationTime: number;

    organization: string;

    providerOrganization: string;

    requestId: string;

    requestParams: {};

    requestWeek: string;

    status: string;

    sources: { [property: string]: string };

    sourcesDetails: {
        id?: string;
        displayName?: string;
        restApiName: string;
        metaData?: AssetData;
        searchQuery?: {
            restApiServiceName: string;
            unitId: string;
            orgId: string;
            filterCriteria: FilterCriteria;
            unitConsiderationEnabled: boolean;
        };
        search?: Search;
        transformationId: string;
        transformation: Transformation;
        fileName?: string;
        fileURL?: string;
    }[];

    steps: [];

    stepDetails: {
        exclusive: boolean;
        group: string;
        id: string;
        index: number;
        keyMatcher: any;
        keySelectorFunctions: any;
        mandatory: boolean;
        name: string;
        valueMatcher: any;
        selected: boolean;
    }[];

    transformations: {};

    requestParameters: {
        id: string;
        name: string;
        value: any;
    }[];

    constructor(data: any) {
        Object.keys(data || {}).forEach((key) => {
            this[key] = data[key];
        });
        this.createdDate = CommonUtilsService.transformDateToLocale(
            new Date(this.creationTime) as any,
            'DATE',
            'ddMMMyyyy HH:MM:SS AM',
            true
        );
    }
}
