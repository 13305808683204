import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject, Subject } from 'rxjs';
import { AssetData, AssetService, BridgeNode, MasterTables, Organization, User, UserPermissions } from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class RootScope {
    session = new BehaviorSubject<boolean>(false);
    TAXILLA_API_URL = environment.taxilla_api;
    TAXILLA_IDM_API_URL = environment.taxilla_user_identity_api;
    ENREPORT_URL = environment['enreport-ui'];
    ENCOMPLY_URL = environment['encomply-ui'];
    LOGO_HREF = environment.logoHref;
    PLATFORM = environment.envName;
    RESTRICTEDPROCESSINGUIS = environment.restrictedProcessingUIs || undefined;
    DEFAULTPROCESSINGUI = environment.defaultProcessingUI || undefined;
    SHOWMARQUEE = environment.showMarquee;
    MARQUEETEXT = environment.marqueeText;
    selectedSubMenu = '';
    device = '';
    orientation = '';
    countries = [];
    cities = {};
    states = {};
    locations = [];
    allTenants: any[];
    allTenantsMap: any;
    allTenantsTree: any[];
    subTenants: any[];
    setWorkQRecordItem: any;
    allSubscribedApps: BehaviorSubject<AssetService[]> = new BehaviorSubject([]);
    selectedApp: BehaviorSubject<AssetService> = new BehaviorSubject({});
    selectedAppAPI = new BehaviorSubject('');
    selectedSubAppAPI = new BehaviorSubject('');
    clickedApp = new BehaviorSubject({});
    selectedSubApp: BehaviorSubject<AssetService> = new BehaviorSubject({});
    removeCheckedAppsSubject = new BehaviorSubject({});
    appSubscribed = new BehaviorSubject({});
    allSubApps: any = {};
    currentOrganization = new BehaviorSubject(new Organization({}));
    rootOrganization = new BehaviorSubject(new Organization({}));
    currentOrganizationId = new BehaviorSubject('');
    rootOrganizationId = new BehaviorSubject('');
    workflowEntities = new BehaviorSubject([]);
    graphModalSubscription = new BehaviorSubject([]);
    workflowReports = new BehaviorSubject([]);
    countryClicked = new Subject<void>();
    stateClicked = new Subject<void>();
    cityClicked = new Subject<void>();
    addEntity = new Subject();
    addReport = new Subject();
    entityUpdate = new Subject();
    currentEntityDetail = new BehaviorSubject({});
    user = new BehaviorSubject(new User({}));
    subApps = [];
    isAdmin = false;
    permissions = new BehaviorSubject(new UserPermissions({}));
    masterTables: MasterTables[] = [];
    scrollCount: any;
    scrollTop: any;
    fixed = new BehaviorSubject<boolean>(false);
    scrollHome: BehaviorSubject<any> = new BehaviorSubject('');
    transformations: {
        [property: string]: {
            INBOUND?: {
                chainName?: string;
                chainType?: string;
                displayName?: string;
                executionPlan?: string;
                metadataId?: string;
                metadataType?: string;
                repositoryId?: string;
            };
            OUTBOUND?: {
                chainName?: string;
                chainType?: string;
                displayName?: string;
                executionPlan?: string;
                metadataId?: string;
                metadataType?: string;
                repositoryId?: string;
            };
        };
    } = {};
    searchLocation = new Subject();
    newOrgAdded = new Subject<void>();
    selectedColumnsOftable: any[];
    clickedAsset = new BehaviorSubject({});
    // initiateFetchBridgeAssets = new BehaviorSubject({});
    userPermissions: BehaviorSubject<any> = new BehaviorSubject(undefined);
    initialized: Promise<void>;
    appsMetaData = [];
    selectedWorkqItem = new BehaviorSubject({});
    selectedTenant = new BehaviorSubject({});
    toggleSliderSubs = new BehaviorSubject({});
    sideNavTggledEvent = new BehaviorSubject('');
    // showAssetOptions = false;
    selectedReportName = new BehaviorSubject('');
    selectedReport: BehaviorSubject<BridgeNode> = new BehaviorSubject({} as any);
    clickedRole: any;
    clickedLocation: any;
    loadingCall = {
        subscribedServices: undefined,
        getMasters: undefined,
        getParentOrgDetails: undefined,
        activeMessagesCount: undefined,
        subscriptionsSearch: undefined,
        widgetSubscriptionsSearch: undefined,
        allAssetsSearch: undefined,
    };
    // Google Analytics Tracking
    googleTrackingCode = 'UA-144051568-1';
    currentAssetMetaData: BehaviorSubject<AssetData> = new BehaviorSubject(undefined);
    current: {
        app: AssetService;
        bridge: AssetService;
        appBridgePermissions: any;
        appPermissions: any;
        appFilingAttributes: any;
        report: BridgeNode;
        reportPermissions: any;
        mainPermissions: any;
        uiIdentifierId: string;
        appIntegrationConfiguration?: BehaviorSubject<string>;
    } = {
        app: undefined,
        bridge: undefined,
        appBridgePermissions: undefined,
        appFilingAttributes: undefined,
        appPermissions: undefined,
        report: undefined,
        reportPermissions: undefined,
        mainPermissions: undefined,
        uiIdentifierId: undefined,
        appIntegrationConfiguration: new BehaviorSubject(undefined),
    };
    totalCreatedAssets = undefined;
    totalCreatedReconApps = undefined;
    totalCreatedBridges = undefined;
    totalCreatedWidgets = undefined;
    totalCreatedDashboards = undefined;
    totalCreatedServices = undefined;
    tableChartGetWidget = new BehaviorSubject({});
    selectedWorkqDetails = new BehaviorSubject({});
    allTimeZones: any;
    orgTimeZone: any;
    browserTimeZone: any;
    has4EcAprrovalAcess = new BehaviorSubject<boolean>(false);
    loadingCompleted = false;
    subscribedWidgetData: any = [];
    vdmToggleSave = new BehaviorSubject<boolean>(false);
    hasAppsMasterScheduler: boolean;
    descriptionKeyMap = {};
    widgetChartData = {};
    helpDocumentsMap = {};
    locationCodes: any;
    has4EcCheck = new BehaviorSubject<boolean>(false);
    allMasters: any;
    providerOrganizations: any = [];

    constructor() {}

    clearRootScope = () => {
        this.isAdmin = true;
        this.rootOrganization.next(undefined);
        this.currentOrganization.next(undefined);
        this.user.next(undefined);
        this.currentOrganizationId.next('');
        this.rootOrganizationId.next('');
        this.permissions.next(undefined);
        this.allTenants = [];
        this.allTenantsMap = {};
        this.allTenantsTree = [];
        this.subTenants = [];
        this.allSubscribedApps.next(undefined);
        this.clickedApp.next(undefined);
        this.selectedApp.next(undefined);
        this.selectedAppAPI.next(undefined);
        this.selectedSubAppAPI.next(undefined);
        this.selectedSubApp.next(undefined);
        this.selectedTenant.next(undefined);
        this.scrollHome.next('');
        this.allSubApps = [];
        this.currentAssetMetaData.next(undefined);
        this.current = {
            app: undefined,
            appFilingAttributes: undefined,
            appBridgePermissions: undefined,
            bridge: undefined,
            appPermissions: undefined,
            report: undefined,
            reportPermissions: undefined,
            mainPermissions: undefined,
            uiIdentifierId: undefined,
            appIntegrationConfiguration: new BehaviorSubject(undefined),
        };
        Object.keys(this.loadingCall).forEach((key) => (this.loadingCall[key] = undefined));
        this.totalCreatedAssets = undefined;
        this.totalCreatedReconApps = undefined;
        this.totalCreatedBridges = undefined;
        this.totalCreatedWidgets = undefined;
        this.totalCreatedServices = undefined;
        this.locations = [];
        this.subscribedWidgetData = [];
        this.locationCodes = {};
        this.states = {};
        this.cities = {};
        this.selectedWorkqDetails.next(undefined);
        this.providerOrganizations = [];
        this.allMasters = undefined;
    };
}
