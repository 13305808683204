export class Rule {
    failureMessage: string;

    location: string;

    name: string;

    predefined: boolean;

    ruleCategory: string;

    ruleLanguage: string;

    ruleLevel: string;

    ruleParams: {
        entityField?: { entityUid: string; fieldUid: string };
        name: string;
        paramType: string;
        value: string;
    }[];

    ruleType: string;

    uid: string;

    method?: string;

    constructor(rule) {
        this.failureMessage = rule?.failureMessage;
        this.location = rule?.location;
        this.name = rule?.displayName || rule?.name;
        this.predefined = rule?.predefined;
        this.ruleCategory = rule?.ruleCategory;
        this.ruleLanguage = rule?.ruleLanguage;
        this.ruleLevel = rule?.ruleLevel;
        this.ruleParams = rule?.ruleParams;
        this.ruleType = rule?.ruleType;
        this.uid = rule?.uid;
        this.method = rule?.method;
    }

    static getRule = (rules: Rule[], uid) => {
        const rule = rules.find((ruleItem) => ruleItem.uid === uid);
        return rule;
    };
}
