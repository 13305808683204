<ng-container [ngSwitch]="cellDataTypes[element.rowIndex][columnId].inputType">
    <ng-container *ngSwitchCase="(
        cellDataTypes[element.rowIndex][columnId].inputType === 'STRING' ||
        cellDataTypes[element.rowIndex][columnId].inputType === 'INT' ||
        cellDataTypes[element.rowIndex][columnId].inputType === 'LONG' ||
        cellDataTypes[element.rowIndex][columnId].inputType === 'DOUBLE'
    ) ? cellDataTypes[element.rowIndex][columnId].inputType : ''">
        <material-input [placeholder]="column.name" [title]="column.name" [model]="element[columnId]" [debounceAfter]="1000" (modelChange)="element[columnId] = $event;" [required]="false" [description]="column.name" [errors]="element.errors[columnId].errors" [warnings]="element.errors[columnId].warnings" (debounceEventHandler)="columnValueChanged(element[columnId])">
        </material-input>
    </ng-container>
    <ng-container *ngSwitchCase="'DATE'">
        <material-input-date [placeholder]="column.name" [title]="column.name" [model]="element[columnId]" [debounceAfter]="1000" [required]="false" [description]="column.name" [dateFormat]="cellDataTypes[element.rowIndex][columnId].outputFormat" [inputOnlyDisabled]="true" (modelChange)="element[columnId] = $event;" [errors]="element.errors[columnId].errors" [warnings]="element.errors[columnId].warnings" (debounceEventHandler)="columnValueChanged(element[columnId])">
        </material-input-date>
    </ng-container>
    <span *ngSwitchCase="'BOOLEAN'">
        <div class="d-flex justify-content-between booleanFieldContainer gridBooleanvalues">
            <div class="booleanValues">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="element[columnId]" [name]="column.name + element.rowIndex + element.columnIndex">
                    <mat-radio-button [value]="'true'">Yes</mat-radio-button>
                    <mat-radio-button [value]="'false'">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </span>
    <ng-container *ngSwitchCase="'LOOKUP'">
        <app-assetlookup [field]="field" [errors]="element.errors[columnId].errors" [name]="column.name" [required]="false" [gridCellIndex]="gridCellIndex" (modelChange)="element[columnId] = $event; field.value = $event">
        </app-assetlookup>
    </ng-container>
</ng-container>
