export class Mapping {
    defaulted: boolean;

    description: string;

    engineProps: any;

    mappedTargetMetadata: any;

    mappingMetadata: any;

    sourceParts: any;

    name: string;

    xmlVersion: string;

    value: any;

    assetToAssetMetadata: any;

    isAssetToAssetTransformation: boolean;

    id: any;

    displayName: string;

    constructor(transformation, id, name) {
        if (transformation) {
            this.defaulted = transformation.defaulted;
            this.description = transformation.description;
            this.engineProps = transformation.engineProps;
            this.mappedTargetMetadata = transformation.mappedTargetMetadata;
            this.mappingMetadata = transformation.mappingMetadata;
            this.sourceParts = transformation.sourceParts;
            this.name = transformation.name;
            this.id = id;
            this.displayName = name || id;
            this.xmlVersion = transformation.xmlVersion;
            this.value = transformation.value;
            this.assetToAssetMetadata = transformation.assetToAssetMetadata;
            if (this.assetToAssetMetadata) {
                this.isAssetToAssetTransformation = true;
            } else {
                this.isAssetToAssetTransformation = false;
            }
        }
    }
}
