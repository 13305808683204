export class ReconciliationSourceField {
    name: string;

    type: string;

    fields: ReconciliationSourceField[];

    key: boolean;

    eventTime: boolean;

    id: string;

    constructor(data: ReconciliationSourceField) {
        this.id = data?.id;
        this.name = data?.name;
        this.type = data?.type;
        this.eventTime = data?.eventTime;
        this.key = data?.key;
        this.fields = [];
        data?.fields?.forEach((field) => this.fields.push(new ReconciliationSourceField(field)));
    }
}
