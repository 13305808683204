import { createAction, props } from '@ngrx/store';

import type { RoleUser } from '../../models/roleuser.class';
import type { User } from '../../models/user.class';
import type { UsersState } from '../states';

export enum UserActionTypes {
    GET_SETTINGS = '[USER] Get User Settings',
    SET_SETTINGS = '[USER] Set User Settings',
    GET_SETTINGS_FAILED = '[USER] Get User Settings Failed',
    OPEN_UPDATE_PASSWORD = '[USER] Open Update Password modal',
    UPDATE_PASSWORD = '[USER] Update Password Details',
    UPDATE_PASSWORD_SUCCESSFULL = '[USER] Update Password Successfull',
    UPDATE_PASSWORD_FAILED = '[USER] Update Password Failed',
    CLEAR_UPDATE_PASSWORD_SUCESSFULL_MESSAGE = '[USER] Clear Password Successfull message',
    SET_USERS_PAGINATION = '[USER] Set Users Pagination',
    SET_USERS = '[USER] Set Users',
    UPDATE_USER = '[USER] Add User',
    USER_UPDATED_SUCCESSFULLY = '[USER] User Updated Successfully',
    USERS_SET_SEARCH = '[USER] Set Search',
    USERS_CLEAR_SEARCH = '[USER] Clear Search',
    USER_UNLOCK = '[USER] unlock',
    RESET_USER_PASSWORD = '[USER] Reset User Password',
    RESET_USER_PASSWORD_SUCCESS = '[USER] Reset User Password Successfull',
    RESET_USER_PASSWORD_FAILED = '[USER] Reset User Password Failure',
    GET_SESSION_USER_DETAILS = '[USER] Get Session User Details',
    GET_USER_DETAILS = '[USER] Get User Details',
    SET_SELECTED_ORG_ID = '[USER] Set Selected Org Id',
    GET_ORG_USERS = '[USER] Get Org Users',
    SET_ORG_USERS = '[USER] Set Org Users',
}

export const GetUserSettings = createAction(UserActionTypes.GET_SETTINGS);

export const SetUserSettings = createAction(
    UserActionTypes.SET_SETTINGS,
    props<{
        passwordPolicy: string;
        passwordPolicyMsg: string;
    }>()
);

export const GetUserSettingsFailed = createAction(
    UserActionTypes.GET_SETTINGS_FAILED,
    props<{
        msg: string;
    }>()
);

export const OpenUpdatePassword = createAction(UserActionTypes.OPEN_UPDATE_PASSWORD);

export const UpdatePassword = createAction(
    UserActionTypes.UPDATE_PASSWORD,
    props<{
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
    }>()
);

export const UpdatePasswordSuccessfull = createAction(
    UserActionTypes.UPDATE_PASSWORD_SUCCESSFULL,
    props<{
        message: string;
    }>()
);

export const ResetUserPassword = createAction(
    UserActionTypes.RESET_USER_PASSWORD,
    props<{
        payload: { userId: string };
    }>()
);

export const ResetUserPasswordSuccess = createAction(
    UserActionTypes.RESET_USER_PASSWORD_SUCCESS,
    props<{
        msg: string;
    }>()
);

export const ResetUserPasswordFailed = createAction(
    UserActionTypes.RESET_USER_PASSWORD_FAILED,
    props<{
        msg: string;
    }>()
);

export const UpdatePasswordFailed = createAction(
    UserActionTypes.UPDATE_PASSWORD_FAILED,
    props<{
        message: string;
    }>()
);

export const ClearUpdatePasswordSuccessfullMessage = createAction(UserActionTypes.CLEAR_UPDATE_PASSWORD_SUCESSFULL_MESSAGE);

export const SetUsersPagination = createAction(
    UserActionTypes.SET_USERS_PAGINATION,
    props<{
        pageNumber: number;
        pageSize: number;
        criterias?: {};
    }>()
);

export const SetUsers = createAction(
    UserActionTypes.SET_USERS,
    props<{
        data: User[];
        organizationId: string;
    }>()
);

export const UpdateUser = createAction(
    UserActionTypes.UPDATE_USER,
    props<{
        data: RoleUser;
        userAction: boolean;
    }>()
);

export const SetUser = createAction(
    UserActionTypes.USER_UPDATED_SUCCESSFULLY,
    props<{
        user: User;
        organizationId: string;
        message: string;
        newUser?: boolean;
    }>()
);

export const SetUsersSearch = createAction(
    UserActionTypes.USERS_SET_SEARCH,
    props<{
        search: UsersState['search'];
    }>()
);

export const ClearUsersSearch = createAction(
    UserActionTypes.USERS_CLEAR_SEARCH,
    props<{
        search: UsersState['search'];
    }>()
);

export const UnlockPassword = createAction(
    UserActionTypes.USER_UNLOCK,
    props<{
        userId: string;
    }>()
);

export const GetSessionUserDetails = createAction(UserActionTypes.GET_SESSION_USER_DETAILS);

export const GetUserDetails = createAction(
    UserActionTypes.GET_USER_DETAILS,
    props<{
        userId: string;
    }>()
);

export const SetSelectedOrgId = createAction(
    UserActionTypes.SET_SELECTED_ORG_ID,
    props<{
        orgId: string;
    }>()
);

export const GetOrgUsers = createAction(UserActionTypes.GET_ORG_USERS);

export const SetOrgUsers = createAction(
    UserActionTypes.SET_ORG_USERS,
    props<{
        orgId: string;
        users: any[];
    }>()
);
