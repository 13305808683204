import { User } from '../../models/user.class';

export interface UsersState {
    settings: {
        passwordPolicy: string;
        passwordPolicyMsg: string;
        settingsFailedMessage: string;
    };
    passwordUpdate: {
        message: string;
    };
    usersMap: {
        [property: string]: User;
    };
    usersList: {
        [property: string]: string[];
    };
    usersPagination: {
        pageSize: number;
        index: number;
    };
    addUser: {
        message: string;
        id: number;
    };
    search: {
        firstName: string;
        lastName: string;
        userId: string;
        email: string;
        contactNumber: string;
        department: string;
        designation: string;
        id: string;
        organizationId: string;
        userName: string;
    };
    selectedOrgIdToAssignUsers: string;
    organizationUsers: {
        // organizationId vs users
        [property: string]: any;
    };
}

export const initialUsersState: UsersState = {
    settings: {
        passwordPolicy: undefined,
        passwordPolicyMsg: undefined,
        settingsFailedMessage: undefined,
    },
    passwordUpdate: {
        message: undefined,
    },
    usersMap: {},
    usersList: {},
    usersPagination: {
        index: 0,
        pageSize: 0,
    },
    addUser: {
        message: undefined,
        id: undefined,
    },
    search: {
        contactNumber: '',
        department: '',
        designation: '',
        email: '',
        firstName: '',
        id: '',
        lastName: '',
        organizationId: '',
        userId: '',
        userName: '',
    },
    selectedOrgIdToAssignUsers: undefined,
    organizationUsers: {},
};
