import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AssetService } from '../../models/assetservice.class';
import { AssetsService } from '../../services/assets/assets.service';
import { CommonService } from '../../services/common/common.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { RootScopeService } from '../../services/rootscope/rootscope.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-downtime-active-message',
    templateUrl: './downtime-active-message.html',
    styleUrls: ['./downtime-active-message.scss'],
})
export class DowntimeActiveMessageComponent implements OnInit, OnChanges {
    @Input() app: AssetService;
    @Input() widget: any;
    assetServiceData: AssetService = new AssetService();
    totalKeys = [];
    showDowntimeMessage = false;
    currentDowntime: any;
    startTime: any;
    endTime: any;

    constructor(
        private _common: CommonService,
        private _assets: AssetsService,
        protected R: RootScopeService,
        private _commonUtils: CommonUtilsService,
        public _utils: UtilsService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.app &&
            changes.app.currentValue?.serviceId &&
            changes.app.previousValue?.serviceId &&
            changes.app.previousValue?.serviceId !== changes.app.currentValue?.serviceId
        ) {
            this.getDowntimeWindows(this.getAppTags);
        }
    }

    startComponent = () => {
        const app = this.widget || this.app || this._commonUtils.getFromStorage('selectedapp');
        if (app?.serviceId) {
            this.app = app;
            this.getDowntimeWindows(this.getAppTags);
        } else {
            this.showDowntimeMessage = false;
        }
    };

    getDowntimeWindows = (cb?) => {
        const currentOrgId = this._commonUtils.getFromStorage('currentorganizationid');
        this.showDowntimeMessage = false;
        this.totalKeys = [];
        this.currentDowntime = null;
        if (this.R.downtimeSchedules[currentOrgId]) {
            this.invokePostDowntimeSchedules(this.R.downtimeSchedules[currentOrgId], cb);
        } else {
            this._common.getDowntimeWindows({
                successCallback: (res) => {
                    const currentOrgId = this._commonUtils.getFromStorage('currentorganizationid');
                    this.R.downtimeSchedules[currentOrgId] = res.response;
                    this.invokePostDowntimeSchedules(this.R.downtimeSchedules[currentOrgId], cb);
                },
                failureCallback: (res) => {
                    this._utils.alertError(res?.msg || 'Could not get downtime schedules');
                },
            });
        }
    };

    invokePostDowntimeSchedules = (downtimeSchedules, cb?) => {
        let currentDowntime = null;
        if (downtimeSchedules?.length && cb) {
            downtimeSchedules.some((schedule) => {
                const startDateTime = new Date(schedule.startDateTime);
                const endDateTime = new Date(schedule.endDateTime);
                const currentDateTime = new Date();
                if (startDateTime <= currentDateTime && currentDateTime <= endDateTime) {
                    currentDowntime = schedule;
                    return true;
                }
            });
            currentDowntime && cb && cb(currentDowntime);
        } else {
            this.showDowntimeMessage = false;
        }
    };

    getServiceId = () => {
        return this.app.serviceId;
    };

    getAppTags = (currentDowntime) => {
        const serviceId = this.getServiceId();
        if (this.R.tagKeysByServiceId[serviceId]) {
            this.totalKeys = this.R.tagKeysByServiceId[serviceId];
            this.invokePostAppTags(this.totalKeys, currentDowntime);
        } else {
            const data = {
                serviceId: serviceId,
                noAlerts: true,
            };
            this._assets.getAssetTags(data, {
                successCallback: (res) => {
                    if (res && res.taggedMetadatas && res.taggedMetadatas.length > 0) {
                        res.taggedMetadatas.forEach((element) => {
                            this.totalKeys.push(element.tagKey);
                        });
                    } else {
                        this.totalKeys = [];
                    }
                    this.R.tagKeysByServiceId[serviceId] = this.totalKeys;
                    this.invokePostAppTags(this.totalKeys, currentDowntime);
                },
                failureCallback: (res) => {
                    this._utils.alertError(res?.msg || 'Could not get tags for this app');
                    this.invokePostAppTags([], currentDowntime);
                },
            });
        }
    };

    invokePostAppTags = (totalKeys, currentDowntime) => {
        if (totalKeys?.length && this._commonUtils.hasCommonElements(totalKeys, currentDowntime.tagKeys)) {
            this.currentDowntime = currentDowntime;
            this.startTime = CommonUtilsService.transformDateToLocale(
                currentDowntime.startDateTime,
                'YYYY-MM-DDTHH:MM:SSZ',
                'DD-MM-YYYY HH:MM:SS AM',
                false
            );
            this.endTime = CommonUtilsService.transformDateToLocale(
                currentDowntime.endDateTime,
                'YYYY-MM-DDTHH:MM:SSZ',
                'DD-MM-YYYY HH:MM:SS AM',
                false
            );
            this.showDowntimeMessage = true;
        } else {
            this.showDowntimeMessage = false;
        }
    };

    ngOnInit() {
        this.startComponent();
    }
}
