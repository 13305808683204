export class EventSubscription {
    orgId: string;

    eventType: string;

    subscriptionId: string;

    includeHierarchy: boolean;

    criterion: string;

    toEmails: string[];

    ccEmails: string[];

    bccEmails: string[];

    subjectTemplate: string;

    contentTemplate: string;

    attachment: string;

    groupId: string;

    constructor(subscription) {
        this.orgId = subscription?.orgId;
        this.eventType = subscription?.eventType && this.transformToUpperCase(subscription?.eventType);
        this.subscriptionId = subscription?.subscriptionId;
        this.includeHierarchy = subscription?.includeHierarchy;
        this.criterion = subscription?.criterion;
        this.toEmails = subscription?.toEmails;
        this.ccEmails = subscription?.ccEmails;
        this.bccEmails = subscription?.bccEmails;
        this.subjectTemplate = subscription?.subjectTemplate;
        this.contentTemplate = subscription?.contentTemplate;
        this.attachment = subscription?.attachment;
        this.groupId = subscription?.groupId;
    }

    transformToUpperCase? = (eventType: string) => eventType?.toUpperCase();
}
