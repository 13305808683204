import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    get href(): string {
        return window.location.href;
    }

    set href(url: string) {
        window.location.href = url;
    }
}
