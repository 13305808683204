<div class="notificationsContentContainer" *transloco="let translate">
    <div class="d-flex align-items-center matDialogBorderBottom" [ngClass]="{'justify-content-between': isPopup, 'justify-content-end': !isPopup}">
        <h4 *ngIf="isPopup" class="matDialogTitle" mat-dialog-title style="
        margin-left: 10px;">{{translate('Process Logs')}}</h4>
        <div class="header-actions">
            <button mat-mini-fab color="default" [title]="translateMsg('Refresh')" class=" refreshLogs mx-2 my-2" (click)="refreshNotifications()">
                <mat-icon class="refresh">refresh</mat-icon>
            </button>
            <span *ngIf="isPopup" class="material-icons mb-1" mat-dialog-close>close</span>
        </div>
    </div>
    <div class="eventTable">
        <material-table [allTableColumns]="allTableColumns" [selectedColumns]="selectedColumns" [data]="tableData" [noLog]="true" [noRecordMsg]="translateMsg('No Process Logs Available')" [defaultPaginator]='true'></material-table>
    </div>
</div>
