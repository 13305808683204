import { Injectable } from '@angular/core';

import { CommonUtilsService } from '../commonutils/common-utils.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class DashBoradSettingsServiceService {
    searchConfig: any;
    searchQuery: any;

    constructor(private _utils: UtilsService) {}

    formEntityOptions = (source, selecEntity?) => {
        const entityArrayOptions = [];
        let selectedEntity: any;
        if (source && source.criteriaNameVsEntityName) {
            for (const key in source.criteriaNameVsEntityName) {
                const obj = {
                    key,
                    name: source.criteriaNameVsEntityName[key],
                };
                entityArrayOptions.push(obj);
            }
        }
        if (selecEntity) {
            selectedEntity = entityArrayOptions[0];
        } else {
            selectedEntity = 'defaultEntity';
        }
        return {
            selectedEntity,
            entityArrayOptions,
        };
    };

    getAssetsettingData = (queryData, selectedEntity) => {
        let searchbuiltData = {};
        if (queryData?.selectedSource?.searchQuery && queryData?.selectedSource?.fields && selectedEntity === true) {
            return {
                searchConfig: queryData?.selectedSource?.fields,
                searchQuery: queryData?.selectedSource?.searchQuery,
            };
        } else if (queryData.selectedSource?.sourceName) {
            const criteriaDefinition =
                queryData.sourceVsSetting[queryData.selectedSource.sourceName]['integrationSettings'].criteriaDefinitions;
            if (criteriaDefinition?.length > 0) {
                criteriaDefinition.filter((entityRule) => {
                    if (entityRule.criteriaName === queryData.key) {
                        if (entityRule.type !== 'ReferredCriteriaDefinition') {
                            const tempConfig = this.buildSearchConfig(
                                entityRule.restriction.rules,
                                entityRule.restriction.condition,
                                queryData
                            );
                            this.searchConfig = {
                                fields: tempConfig.searchObj,
                            };
                            this.searchQuery = {
                                condition: tempConfig.condition.toLowerCase(),
                                rules: tempConfig.rules,
                            };

                            searchbuiltData = {
                                searchConfig: tempConfig.searchObj,
                                searchQuery: this.searchQuery,
                            };
                        }
                        if (entityRule.type === 'ReferredCriteriaDefinition') {
                            searchbuiltData = entityRule;
                        }
                    }
                });
                return searchbuiltData;
            }
        }
    };

    buildSearchConfig = (ruleObj, condition, queryData?) => {
        let searchObj = {};
        const rules = [];
        if (ruleObj?.length > 0) {
            ruleObj.filter((settingObj) => {
                if (!settingObj?.id && settingObj.restrictionType) {
                    const ruleConfig = this.buildSearchConfig(settingObj.rules, settingObj.condition, queryData);
                    rules.push({
                        condition: ruleConfig.condition.toLowerCase(),
                        rules: ruleConfig.rules,
                    });
                    searchObj = { ...searchObj, ...ruleConfig.searchObj };
                } else {
                    const dataType = this._utils.getDataType(settingObj.type);
                    const sourceArrayOption = queryData.sourceArrayOptions.find(
                        (option) => option.sourceName === queryData.selectedSource.sourceName
                    );
                    const name = sourceArrayOption['searchableIdVsFieldName'][settingObj.id];
                    if (!name) {
                        return;
                    }
                    let operators = [];
                    if (searchObj[name]?.operators.length > 0) {
                        searchObj[name].operators.push(settingObj.operator);
                        operators = searchObj[name].operators;
                    } else {
                        operators.push(settingObj.operator);
                    }
                    searchObj[name] = {
                        name,
                        type: dataType,
                        operators,
                        nullable: true,
                        fieldId: settingObj.fieldId,
                    };

                    switch (dataType) {
                        case 'number':
                            searchObj[name]['minimum'] = 0;
                            break;
                        case 'category':
                            searchObj[name]['choices'] = ['SUCCESS', 'ERROR'];
                            break;
                        case 'date':
                            searchObj[name]['defaultValue'] = new Date();
                            searchObj[name]['format'] = 'dd-mm-yyyy';
                            break;
                        case 'boolean':
                            searchObj[name]['options'] = [
                                {
                                    name: 'true',
                                    value: 'true',
                                },
                                {
                                    name: 'false',
                                    value: 'false',
                                },
                            ];
                            break;
                    }
                    let dateValue = '';
                    if (settingObj.type === 'date') {
                        // transformDateToLocale expects a date string in the format 'dd-mm-yyyy'
                        // so other than string value transformDateToLocale method breaks
                        // Below will make sure if the widget created is of type date or whatever and doesn't contain a value.
                        // Ex: user created a widget and field with IN Operator and didn't enter any value, then server would return empty [].
                        const isString = typeof settingObj.value === 'string';
                        if (isString) {
                            dateValue = CommonUtilsService.transformDateToLocale(settingObj.value, 'dd-MMM-yyyy', 'yyyy-MM-dd', true);
                        }
                    }
                    const searchQueryObj = {
                        fieldId: settingObj.fieldId || settingObj.id,
                        field: name,
                        type: settingObj.type,
                        operator: settingObj.operator,
                        value:
                            settingObj.value && typeof settingObj.value !== 'string'
                                ? settingObj.value
                                : settingObj.type === 'date'
                                ? new Date(dateValue)
                                : settingObj.value,
                    };
                    rules.push(searchQueryObj);
                }
            });
        }

        return {
            searchObj,
            rules,
            condition,
        };
    };
}
