import { Injectable } from '@angular/core';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, delay, from, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { MasterRecordsResponse } from '../../interface/master-records-response.interface';
import { ChangeLog } from '../../interface/record-change-log.interface';
import { SearchFilter } from '../../models/searchfilter.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { InstancesService } from '../../services/instances/instances.service';
import { MastersService } from '../../services/masters/masters.service';
import { UtilsService } from '../../services/utils/utils.service';
import { AlertError } from '../actions';
import * as masterActions from '../actions';
import { SetMasterChainDisplayNames } from '../actions/master-management.actions';
import {
    getCurrentOrganizationId$,
    getIsRecordsLoading$,
    getMasterchainDisplayNames$,
    getMasterLanguageTemplate$,
    getMasterRecordPagination$,
    getMasterRecords$,
    getMasterRequests$,
    getMasters$,
    getMastersInboundMappings$,
    getMastersOrganizationMap$,
    getMastersOutboundMappings$,
    getMasterUploadFiles$,
    getParentMasterRecords$,
    getParentPrimaryKeys$,
    getReportDetails$,
    getSearchFilters$,
    getSelectedRestApiName$,
    getSelectedRootOrganization$,
    getSelectedTableId$,
} from '../selectors';

@Injectable()
export class MasterEffects {
    constructor(
        private store$: Store,
        private actions$: Actions,
        private _taxilla: TaxillaApiService,
        private _masters: MastersService,
        private _instances: InstancesService,
        private _utils: UtilsService,
        private _api: ApiService
    ) {}
    private getMasterData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasters),
            withLatestFrom(this.store$.select(getMasters$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, masters, currentOrganizationId]) => {
                if (masters?.length > 0 || !currentOrganizationId || currentOrganizationId.length === 0) {
                    return [];
                }
                return this._taxilla.masters.getAllMasters(currentOrganizationId).pipe(
                    map((res) => {
                        const mastersList = Object.values(res);
                        return masterActions.GetMastersSuccess({
                            masters: mastersList || [],
                            organizationId: currentOrganizationId,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private getMasterMetadata$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasterMetadata),
            withLatestFrom(this.store$.select(getMastersOrganizationMap$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, masterOrgMap, currentOrganizationId]) => {
                if (!action.tableId) {
                    return [];
                }
                const masterMetaData = masterOrgMap?.metaData?.[action.tableId] || undefined;
                if (masterMetaData) {
                    return [];
                }
                return this._masters.getMasterMetaDataObservable(action).pipe(
                    map((res) => {
                        return masterActions.SetMasterMetadata({
                            metaData: res,
                            organizationId: currentOrganizationId,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private getMastersLanaguageTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasterLanguageTemplate),
            withLatestFrom(this.store$.select(getSelectedRestApiName$), this.store$.select(getMasterLanguageTemplate$)),
            mergeMap(([action, restApiName, masterTemplate]) => {
                if (!action.tableId) {
                    return [];
                }
                if (masterTemplate) {
                    return [];
                }
                return from(this._masters.getMasterLanguageTemplateObservable(action)).pipe(
                    map((response) => {
                        return masterActions.SetMasterLanguageTemplate({
                            masterLanguageTemplate: response,
                            organizationId: action.organizationId,
                            restApiName: restApiName,
                        });
                    }),
                    catchError((errorResponse) => {
                        if (errorResponse?.msg) {
                            return of(AlertError({ message: errorResponse?.msg }));
                        } else {
                            return of(
                                masterActions.SetMasterLanguageTemplate({
                                    masterLanguageTemplate: {},
                                    organizationId: action.organizationId,
                                    restApiName: restApiName,
                                })
                            );
                        }
                    })
                );
            })
        );
    });

    private getCustomSearchFilters$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetCustomFilters),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$),
                this.store$.select(getSearchFilters$),
                this.store$.select(getSelectedTableId$)
            ),
            mergeMap(([action, currentOrganizationId, restApiName, searchFilters, tableId]) => {
                if (searchFilters?.length > 0) {
                    return [];
                }
                return this._instances.getSearchConfigurationObservable(tableId).pipe(
                    map((response: SearchFilter[]) => {
                        response.forEach((filter) => {
                            filter['filterType'] = 'custom';
                            filter['value'] = JSON.parse(filter.criteria);
                            filter['searchId'] = filter.objectId;
                        });
                        return masterActions.SetCustomFilters({
                            searchFilters: response,
                            organizationId: currentOrganizationId,
                            restApiName: restApiName,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private getMasterRecords$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasterRecords),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$),
                this.store$.select(getMasterRecords$),
                this.store$.select(getIsRecordsLoading$)
            ),
            mergeMap(([action, currentOrganizationId, selectedRestApiName, records, loader]) => {
                if (!selectedRestApiName) {
                    return [];
                }
                if (records?.length > 0) {
                    return [];
                }
                if (loader) {
                    return [];
                }
                this.store$.dispatch(masterActions.setRecordsLoading({ loading: true }));
                return this._masters
                    .getRecords({
                        size: action.size,
                        pagingState: action.pagingState,
                        restApiName: selectedRestApiName,
                    })
                    .pipe(
                        map((res: MasterRecordsResponse) => {
                            this.store$.dispatch(masterActions.setRecordsLoading({ loading: false }));
                            let records = res?.records?.length > 0 ? this.generateId(res?.records) : [];
                            this.store$.dispatch(
                                masterActions.SetMasterRecordPagination({
                                    organizationId: currentOrganizationId,
                                    restApiName: selectedRestApiName,
                                    pageSize: action.size,
                                    pageIndex: action.pageIndex,
                                    pageState: res?.pagingState,
                                    search: {},
                                    isUpdate: true,
                                })
                            );
                            return masterActions.SetMasterRecords({
                                records: records,
                                organizationId: currentOrganizationId,
                                restApiName: selectedRestApiName,
                                isClearSearch: false,
                            });
                        }),
                        catchError((errorResponse) => {
                            this.store$.dispatch(masterActions.setRecordsLoading({ loading: false }));
                            return of(AlertError({ message: errorResponse?.msg }));
                        })
                    );
            })
        );
    });

    private getParentMasterRecords$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetParentMasterRecords),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getParentMasterRecords$)),
            mergeMap(([action, currentOrganizationId, records]) => {
                if (records?.length > 0) {
                    return of(
                        masterActions.SetMasterRecords({
                            records: records,
                            organizationId: currentOrganizationId,
                            restApiName: action.restApiName,
                            isClearSearch: false,
                        })
                    );
                }
                return this._masters
                    .getRecords({
                        size: action.size,
                        pagingState: action.pagingState,
                        restApiName: action.restApiName,
                        noAlerts: action.noAlerts,
                    })
                    .pipe(
                        map((res: MasterRecordsResponse) => {
                            if (res?.pagingState?.length > 0) {
                                this.store$.dispatch(
                                    masterActions.GetParentMasterRecords({
                                        size: action.size,
                                        pagingState: res?.pagingState,
                                        restApiName: action.restApiName,
                                    })
                                );
                            }
                            let records = res?.records?.length > 0 ? this.generateId(res?.records) : [];
                            return masterActions.SetMasterRecords({
                                records: records,
                                organizationId: currentOrganizationId,
                                restApiName: action.restApiName,
                                isClearSearch: false,
                            });
                        }),
                        catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                    );
            })
        );
    });

    private getSearchedMasterRecords$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetSearchedMasterRecords),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRootOrganization$),
                this.store$.select(getMasterRecordPagination$),
                this.store$.select(getSelectedTableId$),
                this.store$.select(getSelectedRestApiName$),
                this.store$.select(getMasterRecords$),
                this.store$.select(getIsRecordsLoading$)
            ),
            mergeMap(([action, orgId, rootOrg, pagination, masterId, restApiName, records, loader]) => {
                if (restApiName && records?.length > 0) {
                    return [];
                }
                if (loader) {
                    return [];
                }
                this.store$.dispatch(masterActions.setRecordsLoading({ loading: true }));
                let payload = {
                    organizationId: rootOrg?.id,
                    payload: {
                        queryString: pagination?.search?.queryString,
                        size: pagination?.pageSize,
                        from: pagination?.pageIndex ? pagination?.pageIndex * pagination?.pageSize : 0,
                        masterId: masterId,
                        unitId: orgId,
                        sort: pagination?.search?.sortQuery,
                    },
                };
                return this._masters.searchMastersObservable(payload).pipe(
                    map((res: any) => {
                        this.store$.dispatch(masterActions.setRecordsLoading({ loading: false }));
                        this.store$.dispatch(
                            masterActions.SetMasterRecordPagination({
                                organizationId: orgId,
                                restApiName: restApiName,
                                pageSize: pagination.pageSize,
                                pageIndex: pagination.pageIndex,
                                pageState: '',
                                search: {
                                    searchAfter: res?.response?.records?.searchAfter,
                                },
                                isUpdate: true,
                            })
                        );
                        return masterActions.SetMasterRecords({
                            records: res?.response?.masterRecords,
                            organizationId: orgId,
                            restApiName: restApiName,
                            isClearSearch: false,
                        });
                    }),
                    catchError((errorResponse) => {
                        this.store$.dispatch(masterActions.setRecordsLoading({ loading: false }));
                        return of(AlertError({ message: errorResponse?.msg }));
                    })
                );
            })
        );
    });

    private getSearchedMasterRecordsCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetSearchedMasterRecordsCount),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRootOrganization$),
                this.store$.select(getMasterRecordPagination$),
                this.store$.select(getSelectedTableId$)
            ),
            mergeMap(([action, orgId, rootOrg, pagination, masterId]) => {
                let payload = {
                    organizationId: rootOrg?.id,
                    payload: {
                        queryString: pagination?.search?.queryString,
                        masterId: masterId,
                        unitId: orgId,
                        sort: pagination?.search?.sortQuery,
                    },
                };
                return this._masters.getSearchMasterRecordsCountObservable(payload).pipe(
                    map((res: any) => {
                        return masterActions.SetSearchedRecordsCount({
                            searchRecordsCount: res?.response?.count,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private CreateMasterRecord$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.CreateRecord),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSelectedRestApiName$)),
            mergeMap(([action, orgId, selectedRestApiName]) => {
                return this._masters.addRecord(action.data).pipe(
                    map((res: any) => {
                        let records = res?.response ? this.generateId([res?.response]) : [];
                        this.store$.dispatch(masterActions.CRUDOperationRecord({ actionType: 'CREATE' }));
                        return masterActions.SetMasterRecords({
                            records: records,
                            organizationId: orgId,
                            restApiName: selectedRestApiName,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private UpdateMasterRecord$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.UpdateMasterRecord),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$),
                this.store$.select(getMasterRecords$)
            ),
            mergeMap(([action, orgId, selectedRestApiName, records]) => {
                return this._masters.updateRecord(action.data).pipe(
                    map((res: any) => {
                        let record = res?.response;
                        delete record.businessKey;
                        record['recordId'] = action.recordId;
                        this.store$.dispatch(masterActions.CRUDOperationRecord({ actionType: 'UPDATE' }));
                        return masterActions.UpdateMasterRecordSuccess({
                            record: record,
                            organizationId: orgId,
                            restApiName: selectedRestApiName,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private DeleteMasterRecords$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.DeleteMasterRecords),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$),
                this.store$.select(getSelectedTableId$)
            ),
            mergeMap(([action, orgId, restApiName, tableId]) => {
                return this._masters.deleteMastersRecords({ masterDataRowKeys: action.data, masterRestApiName: restApiName }).pipe(
                    map((res: any) => {
                        this.store$.dispatch(
                            masterActions.SetMasterRecords({
                                records: [],
                                organizationId: orgId,
                                restApiName: restApiName,
                                isClearSearch: true,
                            })
                        );
                        this.store$.dispatch(masterActions.DeleteParentRelatedChildRecords({ tableId, orgId }));
                        return masterActions.CRUDOperationRecord({ actionType: 'DELETE' });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private getParentPrimaryKeys$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetParentPrimaryKeys),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getParentPrimaryKeys$)),
            mergeMap(([action, orgId, primaryKeys]) => {
                if (primaryKeys?.keyColumns?.length > 0) {
                    return of(
                        masterActions.SetParentPrimaryKeys({
                            organizationId: orgId,
                            tableId: action.tableId,
                            primaryKeys: primaryKeys?.keyColumns,
                        })
                    );
                }
                return this._masters.getParentPrimaryKeysObservable(action).pipe(
                    map((res: any) => {
                        this.store$.dispatch(
                            masterActions.SetParentTableId({
                                parentTableId: action.tableId,
                            })
                        );
                        return masterActions.SetParentPrimaryKeys({
                            organizationId: orgId,
                            tableId: action.tableId,
                            primaryKeys: res?.response?.parentPrimaryKeyColumns,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private generateId = (records) => {
        records.forEach((record) => {
            record['recordId'] = this._utils.guid(16);
        });
        return records;
    };

    private GetMastersInboundMappings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMastersInboundMappings),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getMastersInboundMappings$)),
            mergeMap(([action, orgId, inboundMap]) => {
                if (inboundMap?.mappings) {
                    return of(
                        masterActions.SetMastersInboundMappings({
                            organizationId: orgId,
                            tableId: action.masterTableId,
                            inboundMappings: CommonUtilsService.cloneObject(inboundMap),
                        })
                    );
                }
                return this._masters
                    .getMastersInboundMappingsObservable({ organizationId: orgId, masterTableId: action.masterTableId })
                    .pipe(
                        map((res: any) => {
                            return masterActions.SetMastersInboundMappings({
                                organizationId: orgId,
                                tableId: action.masterTableId,
                                inboundMappings: res?.response,
                            });
                        }),
                        catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                    );
            })
        );
    });

    private GetChainDisplayNames = createEffect(() =>
        this.actions$.pipe(
            ofType(masterActions.GetMasterChainDisplayNames),
            delay(10),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getMasterchainDisplayNames$)),
            mergeMap(([action, organizationId, chainDisplayNames]) => {
                if (chainDisplayNames?.length > 0) {
                    return [];
                }
                return from(
                    this._api.assets.getChainDisplayNames({
                        restApiName: action.restAPIName,
                    })
                ).pipe(
                    map((res) => {
                        return SetMasterChainDisplayNames({
                            restAPIName: action.restAPIName,
                            organizationId,
                            chainDisplayNames: res?.chainNameVsChainDisplayName,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        )
    );

    private GetMasterRequests$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasterRequests),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$),
                this.store$.select(getMasterRequests$)
            ),
            mergeMap(([action, currentOrganizationId, selectedRestApiName, requestsMap]) => {
                if (!selectedRestApiName) {
                    return [];
                }
                if (requestsMap?.requests?.length > 0) {
                    return [];
                }
                return this._masters.getMasterRequestsObservable(action).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            masterActions.SetMasterRequestsPagination({
                                organizationId: currentOrganizationId,
                                restApiName: selectedRestApiName,
                                pageSize: action.size,
                                pageIndex: action.pageIndex,
                                pageState: res?.nextPagingState,
                                timeRange: [],
                                isUpdate: true,
                            })
                        );
                        return masterActions.SetMasterRequests({
                            requests: res?.assetRequests,
                            organizationId: currentOrganizationId,
                            restApiName: action.restApiName,
                        });
                    }),
                    catchError((errorResponse) => {
                        return of(AlertError({ message: errorResponse?.msg }));
                    })
                );
            })
        );
    });

    private GetMastersOutboundMappings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMastersOutboundMappings),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getMastersOutboundMappings$)),
            mergeMap(([action, orgId, outboundMap]) => {
                if (outboundMap?.mappings) {
                    return [];
                }
                return this._masters.getOutboundMappings({ organizationId: orgId, tableId: action.masterTableId }).pipe(
                    map((res: any) => {
                        return masterActions.SetMastersOutboundMappings({
                            organizationId: orgId,
                            tableId: action.masterTableId,
                            outboundMappings: res,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    private saveSearchCriteria$ = createEffect(() =>
        this.actions$.pipe(
            ofType(masterActions.SaveSearchConfiguration),
            withLatestFrom(
                this.store$.select(getSearchFilters$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$)
            ),
            mergeMap(([action, searchMap, orgId, restApiName]) => {
                const searchFilters = searchMap || [];
                return this._api.instances
                    .saveSearchConfiguration({
                        criteria: action.criteria,
                        filterName: action.filterName,
                        objectId: action.objectId,
                        id: action.id,
                    })
                    .pipe(
                        map((res: any) => {
                            this.store$.dispatch(masterActions.SavedSearchFilter({ filterObject: res?.response }));
                            return masterActions.SetCustomFilters({
                                searchFilters: [res?.response, ...searchFilters.filter((filter) => filter.id !== action.id)],
                                organizationId: orgId,
                                restApiName: restApiName,
                            });
                        }),
                        catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                    );
            })
        )
    );

    private deleteSearchConfiguration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(masterActions.DeleteSearchConfiguration),
            withLatestFrom(
                this.store$.select(getSearchFilters$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedRestApiName$)
            ),
            mergeMap(([action, searchMap, orgId, restApiName]) => {
                const searchFilters = searchMap || [];
                return this._api.instances
                    .deleteSearchConfiguration({
                        id: action?.id,
                    })
                    .pipe(
                        map((res: any) => {
                            this.store$.dispatch(
                                masterActions.AlertSuccess({ message: res?.msg || translate('Filter deleted successfully') })
                            );
                            return masterActions.SetCustomFilters({
                                searchFilters: searchFilters.filter((filter) => filter?.id !== action.id),
                                organizationId: orgId,
                                restApiName: restApiName,
                            });
                        }),
                        catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                    );
            })
        )
    );

    private GetReportDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetReportDetails),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getReportDetails$)),
            mergeMap(([action, orgId, reportDetails]) => {
                if (reportDetails !== undefined && !action?.isFromGenerate) {
                    return [];
                }
                return this._masters.getReportDetails({ organizationId: orgId, tableId: action.tableId }).pipe(
                    map((res: any) => {
                        return masterActions.SetReportDetails({
                            organizationId: orgId,
                            tableId: action.tableId,
                            reportDetails: res,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    private generateReport$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GenerateMasterReport),
            mergeMap((action) => {
                return this._masters.generateMasterReport(action.data).pipe(
                    map((res: any) => {
                        this.store$.dispatch(masterActions.AlertSuccess({ message: res?.msg }));
                        return masterActions.GenerateMasterReportSuccess();
                    }),
                    catchError((errorResponse) => {
                        return of(
                            masterActions.GenerateMasterReportFailure({
                                msg: errorResponse?.msg,
                                transformationName: action?.data?.transformationName,
                            })
                        );
                    })
                );
            })
        );
    });

    private getfileName = (name) => {
        let checkSumFileName = name.split('/');
        checkSumFileName = checkSumFileName[checkSumFileName.length - 1];
        return checkSumFileName;
    };

    private GetMasterUploadFiles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasterUploadFiles),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSelectedTableId$),
                this.store$.select(getMasterUploadFiles$)
            ),
            mergeMap(([action, orgId, tableId, uploadedFiles]) => {
                if (uploadedFiles?.length > 0) {
                    return of(
                        masterActions.SetMasterUploadFiles({
                            organizationId: orgId,
                            tableId: tableId,
                            uploadedFiles: CommonUtilsService.cloneObject(uploadedFiles),
                        })
                    );
                }
                return this._masters.getMasterUploadFiles({ organizationId: orgId, masterTableId: tableId }).pipe(
                    map((res: any) => {
                        res?.filter((obj) => {
                            if (obj?.createOn) {
                                obj.createOn = this._utils.transformDateToLocale(
                                    obj.createOn,
                                    'YYYY-MM-DDTHH:MM:SSZ',
                                    'ddMMMyyyy HH:MM:SS AM',
                                    false
                                );
                            }
                        });
                        res.forEach((detail) => {
                            detail['fileName'] = this.getfileName(detail.fileUri);
                            detail['downloadable'] = true;
                        });
                        return masterActions.SetMasterUploadFiles({
                            organizationId: orgId,
                            tableId: tableId,
                            uploadedFiles: res,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    private GetMasterRecordChangeLogs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(masterActions.GetMasterRecordChangeLogs),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSelectedRestApiName$)),
            mergeMap(([action, orgId, restApiName]) => {
                return this._masters
                    .getMasterRecordChangeLogsObservable({
                        orgId,
                        masterRestApiName: restApiName,
                        keyValuePairs: action?.keyvalues,
                    })
                    .pipe(
                        map((res: ChangeLog[]) => {
                            return masterActions.SetMasterRecordChangeLogs({ changeLogs: res });
                        }),
                        catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                    );
            })
        );
    });
}
