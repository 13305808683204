<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 class="matDialogTitle" mat-dialog-title>{{translateMsg('Change Logs')}}</h4>
    <span class="material-icons mb-2" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography changeLogsMatBody">
    <div style="height: 50vh; overflow-y: auto;">
        <div class="changeLogDataContainer">
            <div *ngIf="data.changeLogs && data.changeLogs.length > 0">
                <mat-accordion>
                    <div *ngFor="let log of data.changeLogs; trackBy: trackByFn" class="auditChangeLogContainer">
                        <ng-container *ngFor="let userLog of log.users; let i = index">
                            <app-commonchangelogrecord [log]="userLog" [entity]="data.masterRecord" [index]='i'></app-commonchangelogrecord>
                        </ng-container>
                    </div>
                </mat-accordion>
            </div>
        </div>
    </div>
</mat-dialog-content>
