import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IntegrationModal } from '../../interface/integrationmodal.interface';
import { IntegrationRecord } from '../../models/integrations/integrationrecord.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { ApiService } from '../../services/api/api.service';

@Component({
    selector: 'app-addConfiguration',
    templateUrl: './addconfigurationmodal.component.html',
})
export class AddConfigurationModalComponent implements OnInit {
    record: IntegrationRecord;
    submitConfiguration: any;
    title: any;
    currentTab: IntegrationModal;
    masterTables: MasterTables[];
    subTenants: any;

    constructor(private dialog: MatDialog, private _api: ApiService) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    checkRecord = () => {
        if (this.record.id && !this.record.lastUpdatedOn) {
            this._api.integrations.getConfigurationById(
                {
                    id: this.record.id,
                },
                {
                    successCallback: (res) => {
                        const record = new IntegrationRecord(res as any, this.currentTab);
                        Object.assign(this.record, record);
                    },
                }
            );
        }
    };

    ngOnInit() {
        setTimeout(this.checkRecord, 100);
    }
}
