import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MANAGE_APPS_REDUCER_KEY } from '../reducers';
import { ManageAppsState, Service } from '../states/manage-apps.state';

const currentState = createFeatureSelector<ManageAppsState>(MANAGE_APPS_REDUCER_KEY);

export const getManageAppsOrganization$ = createSelector(currentState, (state) => state.currentOrganizationId);

export const getManageAppsSubscriptionStatus$ = createSelector(currentState, (state) => state.subscriptionStatus);

export const getManageAppsAssetType$ = createSelector(currentState, (state) => state.assetType);

export const getManageAppsSearchText$ = createSelector(currentState, (state) => state.searchText);

export const getManageAppsFilterByTags$ = createSelector(currentState, (state) => state.filterByTags);

export const getManageAppsSelectedBundleId$ = createSelector(currentState, (state) => state.selectedBundleId);

export const getManageAppsOrgIdArray$ = createSelector(currentState, (state) => state.orgIdArray);

export const getManageAppsAssignableSubscriptionIds$ = createSelector(currentState, (state) => state.assignableSubscriptionIds);

export const getManageAppsItemNames$ = createSelector(currentState, (state) => state.itemNames);

export const getManageAppsPageSize$ = createSelector(currentState, (state) => state.pageSize);

export const getManageAppsPageIndex$ = createSelector(currentState, (state) => state.pageIndex);

export const getManageAppsByPagination$ = createSelector(currentState, (state) => state.getAppsByPagination);

export const getManageAppsAssignableSubscriptionIdsByBundleId$ = createSelector(currentState, (state) => {
    return state.assignableSubscriptionIds[state.currentOrganizationId] || [];
});

export const getManageAppsBundleSubscribedAppIds$ = createSelector(currentState, (state) => {
    return state.bundleSubscribedAppIds[state.selectedBundleId] || [];
});

export const getManageAppsSelectedServicesIds$ = createSelector(currentState, (state) => {
    return state.selectedServiceIds || [];
});

export const getManageAppsOrganizationEntity$ = createSelector(currentState, (state) => {
    return state.organizations.entities?.[state.currentOrganizationId];
});

export const getManageAppsorgIdVsName$ = createSelector(getManageAppsOrganizationEntity$, (organizationEntity) => {
    return organizationEntity?.orgIdVsName;
});

export const getManageAppsServicesMap$ = createSelector(getManageAppsOrganizationEntity$, (organizationEntity) => {
    return organizationEntity?.services?.entities;
});

export const getManageAppsServiceIds$ = createSelector(
    getManageAppsOrganizationEntity$,
    (organizationEntity) => organizationEntity?.services?.ids as string[]
);

export const getManageAppsServices$ = createSelector(
    getManageAppsServicesMap$,
    getManageAppsServiceIds$,
    (servicesMap, serviceIds): Service[] => {
        return serviceIds?.reduce((items, id) => {
            items.push(servicesMap[id]);
            return items;
        }, []);
    }
);

export const getManageAppsServicesByServiceId$ = createSelector(getManageAppsServices$, (services) => {
    return services?.reduce((map, service) => {
        map[service.serviceId] = service;
        return map;
    }, {} as { [property: string]: Service });
});

export const manageAppsFilterParams$ = createSelector(
    getManageAppsSubscriptionStatus$,
    getManageAppsAssetType$,
    getManageAppsSearchText$,
    getManageAppsFilterByTags$,
    getManageAppsSelectedBundleId$,
    getManageAppsOrgIdArray$,
    getManageAppsAssignableSubscriptionIds$,
    getManageAppsItemNames$,
    (subscriptionStatus, assetType, searchText, filterByTags, selectedBundleId, orgIdArray, assignableSubscriptionIds, itemNames) => {
        return {
            subscriptionStatus,
            assetType,
            searchText,
            filterByTags,
            selectedBundleId,
            orgIdArray,
            assignableSubscriptionIds,
            itemNames,
        };
    }
);

export const getServicesByFilterWithoutPagination$ = createSelector(manageAppsFilterParams$, getManageAppsServices$, (params, services) => {
    const status = params.subscriptionStatus;
    const serviceType = params.assetType;
    const searchText =
        params.searchText?.length > 0
            ? params.searchText
            : params.filterByTags.searchText?.length > 0
            ? params.filterByTags.searchText
            : undefined;
    let filterByServiceIds = params.filterByTags.serviceIds?.length > 0 ? params.filterByTags.serviceIds : undefined;
    if (params.filterByTags.tagValue?.length > 0 && params.filterByTags.serviceIds.length === 0) {
        filterByServiceIds = ['none'];
    }
    const bundleId = params.selectedBundleId;
    const assignableSubscriptionIds = bundleId?.length > 0 && params.assignableSubscriptionIds[bundleId];
    const orgIdsArray = params.orgIdArray?.length > 0 ? params.orgIdArray : undefined;
    const itemNames = params.itemNames?.length > 0 ? params.itemNames : undefined;
    return services
        ?.filter((service) => (status === 'ALL' ? true : service.status === status))
        ?.filter((service) => (serviceType === undefined ? true : service.assetType === serviceType))
        ?.filter((service) => !assignableSubscriptionIds || assignableSubscriptionIds.includes(service.serviceId))
        ?.filter((service) => (filterByServiceIds ? filterByServiceIds.includes(service.serviceId) : true))
        ?.filter((service) =>
            searchText === undefined ? true : (service.displayName || service.itemName).toLowerCase().includes(searchText)
        )
        ?.filter((service) => !orgIdsArray || orgIdsArray.includes(service.organizationId))
        ?.filter(
            (service) =>
                !itemNames ||
                itemNames.includes((service.itemName || service['serviceName']).toLowerCase()) ||
                itemNames.includes(service.displayName?.toLowerCase())
        );
});

export const getManageAppsPageStatus$ = createSelector(
    getManageAppsPageSize$,
    getManageAppsPageIndex$,
    getManageAppsByPagination$,
    getServicesByFilterWithoutPagination$,
    (pageSize, pageIndex, getByPagination, services) => {
        return {
            count: services?.length,
            pageSize,
            pageIndex,
            getByPagination,
        };
    }
);

export const getServicesByFilter$ = createSelector(
    getManageAppsPageStatus$,
    getServicesByFilterWithoutPagination$,
    (pagination, services) => {
        return services?.slice(pagination.pageSize * pagination.pageIndex, pagination.pageSize * (pagination.pageIndex + 1));
    }
);

export const getServicesCountBySubscriptionStatus$ = createSelector(currentState, getManageAppsServices$, (state, services) => {
    const status = state.subscriptionStatus;
    return services?.filter((service) => (status === 'ALL' ? true : service.status === status)).length;
});

export const getBridgeService$ = createSelector(currentState, getManageAppsServicesMap$, (state, servicesMap) => {
    const itemId = state.currentBridgeId;
    const id = Object.keys(servicesMap).find((key) => servicesMap[key].itemId === itemId);
    return servicesMap?.[id];
});

export const getManageAppsSelectedServices$ = createSelector(currentState, (state) => {
    const serviceIds = state.selectedServiceIds || [];
    const organizationEntity = state.organizations.entities[state.currentOrganizationId];
    const servicesEnity: EntityState<Service> = organizationEntity?.services;
    return (servicesEnity?.ids as string[])
        .filter((id) => serviceIds.includes(servicesEnity.entities[id].serviceId))
        .reduce((items, id) => {
            items.push(servicesEnity.entities[id]);
            return items;
        }, []) as Service[];
});

export const getSubscriptionApprovalData$ = createSelector(getManageAppsorgIdVsName$, getManageAppsPageStatus$, (orgIdVsName, pageData) => {
    return {
        orgIdVsName,
        pageData,
    };
});

export const getSubscriptionApprovalCount$ = createSelector(currentState, (state) => {
    const orgIdString = state.orgIdArray.join(',');
    const itemIdString = state.itemIdArray.join(',');
    return state.count?.[`${state.subscriptionStatus}|${orgIdString}|${itemIdString}`] || 0;
});

export const getManageAppsSelectedOrgAndAppIds$ = createSelector(currentState, (state) => {
    return { itemIdArray: state.itemIdArray, orgIdArray: state.orgIdArray };
});
