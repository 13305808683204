import { FieldDefinition } from '../../models/field-definition.class';
import { Organization } from '../../models/organization.class';

export const locationsInitialState: LocationInitialState = {
    associatedOrganizations: [],
    flexfields: [],
    organizationLocations: {},
    locationCodes: {},
    logoMapWithHierarchy: {},
    logoMap: {},
    orgDetailsMessage: {
        orgDetails: undefined,
        logo: undefined,
    },
    orgChildHierarchy: {},
};

export interface LocationInitialState {
    associatedOrganizations: Organization[];
    flexfields: FieldDefinition[];
    organizationLocations: {
        [organizationId: string]: Organization[];
    };
    locationCodes: {};
    logoMapWithHierarchy: {
        [property: string]: string;
    };
    logoMap: {
        [property: string]: string;
    };
    orgChildHierarchy: {
        // orgId vs orgChildHierarchy
        [property: string]: any;
    };
    orgDetailsMessage: {
        orgDetails: {
            success: boolean;
            msg: string;
        };
        logo: {
            success: boolean;
            msg: string;
        };
    };
}
