export class CollabConfiguration {
    isAutoInvite: boolean;
    isEnabled: boolean;
    partnerAppSettings: partnerAppSettings[];
    serviceId: string;

    constructor(config) {
        if (config) {
            this.isAutoInvite = config.isAutoInvite || false;
            this.isEnabled = config.isEnabled || false;
            this.partnerAppSettings = config.partnerAppSettings || [
                {
                    partnerFunction: '',
                    partnerField: '',
                    targetAssetId: '',
                    targetServiceId: '',
                    collaborationActions: {},
                    changeAllowedStages: [],
                    isAutoInvite: false,
                    isEnabled: false,
                },
            ];
            this.serviceId = config.serviceId;
        }
    }
}

export interface partnerAppSettings {
    partnerFunction: string;
    partnerField: string;
    targetAssetId: string;
    targetServiceId: string;
    collaborationActions: { [property: string]: any };
    changeAllowedStages: any[];
    isAutoInvite: boolean;
    isEnabled: boolean;
}
