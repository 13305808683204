export class Message {
    errors: any[];

    warnings: any[];

    constructor(data?: any) {
        this.errors = data?.errors || [];
        this.warnings = data?.warnings || [];
    }
}
