export interface RecordsState {
    recordIds: {
        //<orgId> vs <serviceId> map
        [property: string]: {
            //<serviceId> vs <instanceId> Map
            [property: string]: {
                //<instanceId> vs <parentRecordId>|<entityId> Map or
                [property: string]: {
                    // <parentRecordId>|<entityId> vs recordsData
                    [property: string]: {
                        recordIds: string[];
                        loading: boolean;
                        pageSize: number;
                        currentPageIndex: number;
                        pagingState: string;
                        searchRecordIds: string[];
                        searchCriteria: string;
                        reconSpecific: {
                            source1: {
                                pageSize: number;
                                currentPageIndex: number;
                                criteria: any;
                                recordIds: string[];
                                pagingState: string;
                            };
                            source2: {
                                pageSize: number;
                                currentPageIndex: number;
                                criteria: any;
                                recordIds: string[];
                                pagingState: string;
                            };
                            grouped: {
                                pageSize: number;
                                currentPageIndex: number;
                                criteria: any;
                                recordIds: string[];
                                pagingState: string;
                            };
                        };
                    };
                };
            };
        };
    };
    records: {
        [property: string]: {
            id: string;
            fields: {
                id: string;
                value: any;
                errors: string[];
                warnings: string[];
            }[];
            errors: string[];
            warnings: string[];
            parentId: string;
        };
    };
}

export const recordsState: RecordsState = {
    records: {},
    recordIds: {},
};
