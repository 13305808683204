export class WorkflowStageReferences {
    workflowStageUid: string;
    workflowStageEntityReferences: WorkflowStageEntityReference[];
    workflowStageRuleReferences: any[];
    constructor(data: any) {
        this.workflowStageUid = data?.workflowStageUid;
        this.workflowStageEntityReferences = data?.workflowStageEntityReferences;
        this.workflowStageRuleReferences = data?.workflowStageRuleReferences;
    }
}

export interface WorkflowStageEntityReference {
    entityUid: string;
    readOnly: boolean;
    show: boolean;
}
