import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ClearApplicationMiscProperties,
    FetchCaptcha,
    fetchCaptchaSuccessState,
    GetApplicationMiscProperties,
    getApplicationMiscPropertiesState,
    GetContextDetailWithKey,
    getContextSuccessState,
    getLogoHref$,
    GetSecurityQuestions,
    GetSecurityQuestionsSuccessState,
    Organization,
    PasswordUpdated,
    SetPassword,
    User,
    UtilsService,
} from 'taxilla-library';

@Component({
    selector: 'app-setpassword',
    templateUrl: './setpassword.component.html',
    styleUrls: ['./setpassword.component.scss'],
})
export class SetPasswordComponent implements OnInit {
    organizationId: string;
    userId: string;
    key: string;
    email: string;
    organization: Organization = new Organization({});
    user: User = new User({});
    passwords = {
        password: '',
        confirmPassword: '',
    };
    errors: { [property: string]: string[] } = {};

    secretQuestions = [];
    securityQuestionsStructure = [];
    captchaUrl: string;
    captcha: string;
    passwordPolicyRegex: string;
    passwordPolicyMsg: string;
    passwordPolicyTitle: string;
    settings: { passwordPolicy: string; passwordPolicyMsg: string };
    mmfaQuestions: number;
    unsubscribe = new Subject<void>();
    logoHref: Observable<string>;

    constructor(
        private _route: ActivatedRoute,
        private _libUtils: UtilsService,
        private _router: Router,
        private store$: Store,
        private actions$: Actions
    ) {}

    private startComponent = async (isInit?) => {
        if (!this.key) {
            return;
        }
        this.passwords = {
            password: '',
            confirmPassword: '',
        };
        this.organization = new Organization({});
        this.user = new User({});
        this.errors = {};
        isInit && (await this.getApplicationMiscProps());
    };

    private getApplicationMiscProps = async () => {
        this.store$.dispatch(ClearApplicationMiscProperties());
        this.store$.dispatch(GetApplicationMiscProperties());
    };

    public pushQuestions = (count: number) => {
        count = this.securityQuestionsStructure.length + count;
        for (let i = 0; i < count; i++) {
            if (this.securityQuestionsStructure.length === count) {
                return;
            }
            const id = `${i + 1}`;
            const question = this.securityQuestionsStructure.find((question) => question.id === id);
            if (!question) {
                this.securityQuestionsStructure.push({
                    id: id,
                    displayName: `Security Question`,
                    key: '',
                    value: '',
                    errors: {
                        value: [],
                        key: [],
                    },
                });
            }
        }
    };

    private fetchOrgAndUserDetails = () => {
        const payload = { key: this.key };
        this.store$.dispatch(GetContextDetailWithKey({ payload }));
    };

    getSecurityQuestions = () => {
        this.store$.dispatch(GetSecurityQuestions());
    };

    public setPassword = () => {
        this.errors = {};
        const securityQuestionsPayload = this.buildSecurityQuestionPayload();
        if (this.validatePassword()) {
            const passwordPayload = {
                orgId: this.organizationId,
                payload: {
                    authenticationKey: this.key,
                    userId: this.userId,
                    userName: this.user.id,
                    newPassword: this.passwords.password,
                    confirmPassword: this.passwords.confirmPassword,
                    secretQuestionAndAnswersCommand: securityQuestionsPayload,
                    captcha: this.captcha,
                },
            };
            this.store$.dispatch(SetPassword({ payload: passwordPayload }));
        } else {
            this._libUtils.alertError(translate('Correct the errors to proceed'));
        }
    };

    private buildSecurityQuestionPayload = () => {
        const obj = {};
        this.securityQuestionsStructure.forEach((question) => {
            obj['secretQuestion' + question.id] = question.key;
            question.value = question.value?.trim();
            obj['secretAnswer' + question.id] = question.value?.trim();
        });
        return obj;
    };

    private validatePassword = (): boolean => {
        let count = 0;
        const passwordMessage = this._libUtils.checkPassword(
            this.passwords.password,
            (this.settings?.passwordPolicy?.length > 0 && this.settings?.passwordPolicy) || this.passwordPolicyRegex,
            (this.settings?.passwordPolicyMsg?.length > 0 && this.settings?.passwordPolicyMsg) || this.passwordPolicyMsg
        );
        if (!this.passwords.password || this.passwords.password.length === 0) {
            this.errors.password = [translate('Enter password')];
            count++;
        } else if (passwordMessage !== true) {
            this.errors.password = [passwordMessage];
            count++;
        }
        if (!this.passwords.confirmPassword || this.passwords.confirmPassword.length === 0) {
            this.errors.confirmPassword = [translate('Enter confirm password')];
            count++;
        } else if (this.passwords.password !== this.passwords.confirmPassword) {
            this.errors.confirmPassword = [translate('Passwords must match')];
            count++;
        }

        if (this.securityQuestionsStructure && this.securityQuestionsStructure.length > 0) {
            this.securityQuestionsStructure.forEach((question) => {
                if (!question.key || (question.key && question.key.length === 0)) {
                    question.errors.key = [translate('Please select question')];
                } else {
                    question.errors.key = question.errors.key || [];
                }
                if (!question.value || (question.value && question.value.length === 0)) {
                    question.errors.value = [translate('Please Answer the question')];
                } else {
                    question.errors.value = question.errors.value || [];
                }
            });
        }

        if (this.securityQuestionsStructure && this.securityQuestionsStructure.length > 0) {
            let errorCount = 0;
            this.securityQuestionsStructure.forEach((question) => {
                if (
                    question &&
                    question.errors &&
                    ((question.errors.key && question.errors.key.length > 0) || (question.errors.value && question.errors.value.length > 0))
                ) {
                    errorCount = errorCount + 1;
                }
            });
            if (errorCount) {
                count++;
            }
        }
        const isCaptchaValid = this.checkCaptcha();
        return count === 0 && isCaptchaValid;
    };

    public checKQuestions = (value, selectedQuestion) => {
        if (value && value.length > 0) {
            let exist = false;
            for (let i = 0; i < this.securityQuestionsStructure.length; i++) {
                const question = this.securityQuestionsStructure[i];
                if (selectedQuestion.id !== question.id && question.key === value) {
                    exist = true;
                }
            }
            for (let j = 0; j < this.securityQuestionsStructure.length; j++) {
                if (this.securityQuestionsStructure[j].id === selectedQuestion.id) {
                    if (exist) {
                        this.securityQuestionsStructure[j].errors.key = [
                            translate('The question has been selected.Please select another question'),
                        ];
                    } else {
                        this.securityQuestionsStructure[j].errors.key = [];
                    }
                }
            }
        }

        this.securityQuestionsStructure.forEach((element) => {
            if (element.errors.key.length > 0) {
                let duplicateCount = 0;
                this.securityQuestionsStructure.forEach((question) => {
                    if (element.key === question.key) {
                        duplicateCount++;
                    }
                });

                if (duplicateCount < 2) {
                    element.errors.key = [];
                }
            }
        });
    };

    public getCaptcha = () => {
        this.captcha = '';
        const payload = {
            plain: true,
        };
        this.store$.dispatch(FetchCaptcha({ payload }));
    };

    public checkCaptcha = () => {
        let count = 0;
        this.errors.captcha = [];
        if (!this.captcha || this.captcha.trim() === '') {
            this.errors.captcha = [translate('error_validCaptcha')];
            count++;
        }
        return count === 0;
    };

    initiateStoreSubscriptions = () => {
        this.store$
            .select(GetSecurityQuestionsSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe({
                next: (secretQuestions) => {
                    if (secretQuestions?.length) {
                        secretQuestions.forEach((element) => {
                            const obj = {};
                            obj['value'] = element;
                            this.secretQuestions.push(obj);
                        });
                    }
                },
            });
        this.store$
            .select(getApplicationMiscPropertiesState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data?.['seed-token'] !== undefined)
            )
            .subscribe((response) => {
                this.mmfaQuestions = response?.['min-mfa-answers'];
                this.pushQuestions(this.mmfaQuestions);
                this.passwordPolicyRegex = response['password-policy'];
                this.passwordPolicyMsg = response['password-policy-msg'];
                this.passwordPolicyTitle =
                    this.passwordPolicyMsg ||
                    translate(
                        'Enter a combination of minimum 8 alpha numerics with atleast 1 upper case,1 lower case letter and a special symbol (like ! @ $ % * & ? #) without spaces.'
                    );
                this.fetchOrgAndUserDetails();
                this.getSecurityQuestions();
                this.getCaptcha();
            });
        this.store$
            .select(getContextSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe({
                next: (response) => {
                    this.organization = response.organization;
                    this.user = response.user;
                    this.settings = response?.settings;
                },
            });
        this.store$
            .select(fetchCaptchaSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe({
                next: (res) => {
                    this.captchaUrl = res.captcha;
                },
            });
        this.actions$
            .pipe(ofType(PasswordUpdated))
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((event) => {
                if (event.success) {
                    this._router.navigate(['']);
                    this.startComponent();
                }
            });
        this.logoHref = this.store$.select(getLogoHref$).pipe(takeUntil(this.unsubscribe));
    };

    ngOnInit(): void {
        this._route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
            this.organizationId = params && params.orgId;
            this.userId = params && params.userId;
            this.key = params && params.key;
            this.email = params && params.email;
            this.startComponent(true);
        });
        this.initiateStoreSubscriptions();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
