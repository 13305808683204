import { environment } from '@env';

import { Report } from '../report.interface';
import { ReportMetaData } from '../reportmetadata.interface';

export class ReportData {
    autoGenerate?: boolean;
    chainTypeMetadata?: string;
    conditionalRule?: {
        location: string;
        name: string;
        predefined: boolean;
        ruleCategory: string;
        ruleLanguage: string;
        uid: string;
    };
    defaulted?: boolean;
    description?: string;
    executionPlanMetadata?: string;
    name?: string;
    order?: number;
    orderedTransformations?: Array<{
        name: string;
        reportNameConfig: any[];
        targetFieldIds: any[];
        targetStores: any[];
    }>;
    uid?: string;
    report?: Report;
    reportLink?: string;
    constructor(reportMetaData: ReportMetaData, report: Report, instanceId: string) {
        // tslint:disable-next-line:forin
        for (const key in reportMetaData) {
            this[key] = reportMetaData[key];
        }
        if (instanceId && report.reportUrl && report.reportName) {
            this.reportLink =
                environment.taxilla_api +
                '/instances/' +
                instanceId +
                '/files?fileUrl=' +
                report.reportUrl +
                '&fileName=' +
                report.reportName;
        }
        this.report = report;
    }
}
