<span [ngSwitch]="field.uiType">
    <span *ngSwitchCase="'TEXTFIELD'">
        <ng-container *ngIf="!field.hasMasterLookup">
            <material-input [disabled]="false" [placeholder]="field.name" [errors]="field.message.errors" [title]="field.name" [model]="field.value" [classList]="classList" [debounceAfter]="500" (modelChange)="field.value = $event; modelChange.emit($event)" [required]="field.mandatory || field.isBusinessKey" [errors]="field.message.errors" [warnings]="field.message.warnings" [description]="field.description || field.name" (debounceEventHandler)="debounceEventHandler.emit($event)">
            </material-input>
        </ng-container>
    </span>
    <span *ngSwitchCase="'TEXTAREA'">
        <material-textarea [placeholder]="field.name" [model]="field.value" (modelChange)="field.value = $event; modelChange.emit($event)" [name]="field.name" [required]="field.mandatory || field.isBusinessKey" [errors]="field.message.errors" [debounceAfter]="500" [description]="field.description || field.name" (debounceEventHandler)="debounceEventHandler.emit($event)"></material-textarea>
    </span>
    <span *ngSwitchCase="'DROPDOWN'">
        <ng-container *ngIf="field.id !== 'hsn'">
            <app-assetlookup [disabled]="false" [field]="field" [errors]="field.message.errors" [required]="field.mandatory || field.isBusinessKey" [description]="field.description || field.name" [conditionalValue]="getConditionValue()" (modelChange)="field.value = $event; modelChange.emit($event)" [service]="service">
            </app-assetlookup>
        </ng-container>

    </span>
    <span *ngSwitchCase="'DATE'">
        <material-input-date [disabled]="false" [placeholder]="field.name" [title]="field.name" [model]="field.value" [errors]="field.message.errors" [classList]="classList" [debounceAfter]="1000" [required]="field.mandatory || field.isBusinessKey" [dateFormat]="field.outputFormat" [inputOnlyDisabled]="true" [description]="field.description || field.name" (modelChange)="field.value = $event; modelChange.emit($event)" (debounceEventHandler)="debounceEventHandler.emit($event)">
        </material-input-date>
    </span>
    <span *ngSwitchCase="'PASSWORD'">
        <material-input-password [disabled]="false" [errors]="field.message.errors" [placeholder]="field.name" [title]="field.name" [model]="field.value" [classList]="classList" [debounceAfter]="1000" (modelChange)="field.value = $event; modelChange.emit($event)" [required]="field.mandatory || field.isBusinessKey" [description]="field.description || field.name" (debounceEventHandler)="debounceEventHandler.emit($event)">
        </material-input-password>
    </span>
    <span *ngSwitchCase="'BOOLEAN'">
        <div class="row booleanFieldContainer">
            <div class="col-6 booleanLabel" [title]="field.name">
                <span>{{field.name}} : </span>
                <button mat-button matSuffix mat-icon-button aria-label="Description" tabindex="-1" title="{{field.description || field.name}}">
                    <span class="material-icons-outlined fieldDescInfoIcon">
                        info
                    </span>
                </button>
            </div>
            <div class="col-6 booleanValues">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="field.value" [name]="field.displayName || field.name">
                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </span>
    <span *ngSwitchCase="'RADIOBUTTON'">
        <div class="row booleanFieldContainer">
            <div class="col-xs-4 booleanLabel" [title]="field.name">
                <span>{{field.name}} : </span>
            </div>
            <div class="col-xs-8 booleanValues filingAttributeRadioBtnCls">
                <app-assetlookup [disabled]="false" [field]="field" [errors]="field.message.errors" [required]="field.mandatory || field.isBusinessKey" [description]="field.description || field.name" (modelChange)="field.value = $event; modelChange.emit($event)" [service]="service">
                </app-assetlookup>
            </div>
        </div>
    </span>
    <span *ngSwitchCase="'CHECKBOX'">
        <material-group-checkbox [menuName]="field.name" [classList]="[]" [options]="field.lookupValues && field.lookupValues.displayVsValue" [value]="field.value" [optionDisplayNameProperty]="'key'" [optionValueProperty]="'value'" [errors]="field.message.errors" [required]="field.mandatory || field.isBusinessKey" [warnings]="field.message.warnings" [description]="field.description || field.name" (onOptionsChanged)="field.value = $event; modelChange.emit($event)" [currentApp]="service" [currentBridge]="bridge"></material-group-checkbox>
    </span>
    <span *ngSwitchDefault>
        {{field.uiType}}
    </span>
</span>
