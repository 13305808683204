import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { NotificationEvent } from '../../interface/notifications-event.interface';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import * as actions from '../actions/notifications-event.actions';
import {
    AlertError,
    CheckAppPollEvents,
    CheckReconPollEvents,
    ClearEventPolling,
    ClearNotifications,
    GetNotifications,
    GetReconEvents,
    PollForEvents,
    PollingTerminated,
    SetNotifications,
    GetNotificationsViaPagination,
    SetReconEvents,
    SetEmailNotifications,
    AlertSuccess,
} from '../actions';
import { ClearProcessLogs, GetProcessLogs, SetProcessLogs } from '../actions/notifications-event.actions';
import {
    getCurrentOrganizationId$,
    getEmailContent$,
    getEmailNotifications$,
    getEmailNotificationsViaPagination$,
    getEventSubscriptions$,
    getEventSubscriptionsViaPagination$,
    getEventTypes$,
    getNotificationsMap$,
    getNotificationsPagingState$,
    getNotificationsPagingStateMap$,
    getReconEventsMap$,
    getSubscribedAppsMap$,
} from '../selectors';
import { translate } from '@ngneat/transloco';
@Injectable()
export class NotificationEffects {
    private pollingResource: { [property: string]: any } = {};
    private pollingCounter: { [property: string]: number } = {};
    private pollingResourceType: { [property: string]: string } = {};
    private requestEvents = ['REQUEST_CREATED', 'REQUEST_CANCELLED', 'REQUEST_COMPLETED', 'REQUEST_DONE_FOR_NOW', 'REQUEST_TERMINATED'];
    private instanceEvents = ['TASK_STARTED', 'TASK_FAILED', 'TASK_COMPLETED'];
    private reportEndEvents = [
        'OUTBOUND_TRANSFORMATION_STARTED',
        'OUTBOUND_TRANSFORMATION_SKIPPED',
        'OUTBOUND_TRANSFORMATION_COMPLETED',
        'OUTBOUND_TRANSFORMATION_TERMINATED',
    ];
    private bulkReportsEndEvents = ['BULK_REPORTS_DOWNLOAD_COMPLETED', 'BULK_REPORTS_DOWNLOAD_TERMINATED'];
    private reconEndEvents = ['RECON_RESULT_AGGREGATION_COMPLETED', 'INBOUND_TRANSFORMATION_FAILED', 'RECON_FAILED', 'FAILED'];

    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    private pollForEvents = createEffect(() =>
        this.actions$.pipe(
            ofType(PollForEvents),
            mergeMap((action) => {
                const pollingResourceType = this.pollingResourceType[action.resourceId];
                if (this.pollingResource[action.resourceId] && action.resourceType === pollingResourceType) {
                    return [];
                } else if (this.pollingResource[action.resourceId] && action.resourceType === pollingResourceType) {
                    clearInterval(this.pollingResource[action.resourceId]);
                }
                this.store$.dispatch(
                    ClearNotifications({
                        resourceId: action.resourceId,
                    })
                );
                !action.delay && this.dispatchEvents(action);
                this.pollingCounter[action.resourceId] = 0;
                this.pollingResourceType[action.resourceId] = action.resourceType;
                this.pollingResource[action.resourceId] = setInterval(() => {
                    this.dispatchEvents(action);
                    this.pollingCounter[action.resourceId] += 1;
                }, 10000);
                return [];
            })
        )
    );

    private dispatchEvents = (action: {
        resourceId: string;
        appType: 'APP' | 'RECON';
        resourceType: 'REQUEST' | 'INSTANCE' | 'REPORTS' | 'BULK_REPORTS';
        serviceId: string;
        timeStamp: number;
        noAutoClear?: boolean;
    }) => {
        this.store$.dispatch(
            ClearNotifications({
                resourceId: action.resourceId,
            })
        );
        switch (action.appType) {
            case 'APP':
                this.store$.dispatch(
                    GetNotifications({
                        eventType: undefined,
                        fetchSize: 100,
                        resourceId: action.resourceId,
                        noAlerts: true,
                        noPagingState: true,
                        pollingResourceType: action.resourceType,
                        timeStamp: action.timeStamp,
                        noAutoClear: action.noAutoClear,
                    })
                );
                break;
            case 'RECON':
                this.store$.dispatch(
                    GetReconEvents({
                        resourceId: action.resourceId,
                        serviceId: action.serviceId,
                        noAlerts: true,
                        pollingResourceType: action.resourceType as 'REQUEST',
                    })
                );
                break;
        }
    };

    private getNotifications = createEffect(() =>
        this.actions$.pipe(
            ofType(GetNotifications),
            delay(10),
            withLatestFrom(this.store$.select(getNotificationsPagingStateMap$)),
            mergeMap(([action, pagination]) => {
                if (typeof action.noPagingState === 'string' && action.noPagingState === 'end' && !action.noPagingState) {
                    return [];
                }
                return from(
                    this._api.notifications.getNotifications({
                        eventType: action.eventType,
                        fetchSize: action.fetchSize,
                        noAlerts: action.noAlerts,
                        pagingState: action.noPagingState ? undefined : pagination?.[action.resourceId]?.pagingState,
                        resourceId: action.resourceId,
                    })
                ).pipe(
                    map((res) => {
                        action.pollingResourceType &&
                            this.store$.dispatch(
                                CheckAppPollEvents({
                                    resourceId: action.resourceId,
                                    resourceType: action.pollingResourceType,
                                    timeStamp: action.timeStamp,
                                    noAutoClear: action.noAutoClear,
                                })
                            );
                        return SetNotifications({
                            notifications: res?.result,
                            resourceId: action.resourceId,
                            pagingState: res?.pagingState || 'end',
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e })))
                );
            })
        )
    );

    private getNotificationsViaPagination = createEffect(() =>
        this.actions$.pipe(
            ofType(GetNotificationsViaPagination, actions.SetPagination),
            delay(10),
            withLatestFrom(this.store$.select(getNotificationsPagingState$), this.store$.select(getNotificationsMap$)),
            mergeMap(([action, pagination, eventsMap]) => {
                const logIds = eventsMap?.[action.resourceId]?.ids as string[];
                const paginationMap = pagination?.[action.resourceId];
                const expectedLength = paginationMap.size * (paginationMap?.index + 1);
                if (logIds && logIds.length > 0 && logIds.length > expectedLength) {
                    return [];
                }
                return from(
                    this._api.notifications.getNotifications({
                        eventType: action.eventType,
                        fetchSize: paginationMap.size,
                        resourceId: action.resourceId,
                        noAlerts: action?.['noAlerts'],
                        pagingState: paginationMap.noPagingState ? undefined : paginationMap?.pagingState,
                    })
                ).pipe(
                    map((res) => {
                        return SetNotifications({
                            notifications: res?.result,
                            resourceId: action.resourceId,
                            pagingState: res?.pagingState,
                            eventType: action.eventType,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e })))
                );
            })
        )
    );

    private getReconEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReconEvents),
            delay(10),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                if (!app) {
                    return [];
                }
                return from(
                    this._api.requests.getReconciliationLogs({
                        noAlerts: action.noAlerts,
                        requestId: action.resourceId,
                        restApiName: app.restApiName,
                    })
                ).pipe(
                    map((res) => {
                        res?.forEach((item) => {
                            item.eventTime = CommonUtilsService.transformDateToLocale(
                                item.eventTime,
                                'YYYY-MM-DDTHH:MM:SSZ',
                                'ddMMMyyyy HH:MM:SS AM',
                                false
                            );
                        });
                        switch (action.pollingResourceType) {
                            case 'REQUEST':
                                this.store$.dispatch(
                                    CheckReconPollEvents({
                                        resourceId: action.resourceId,
                                        resourceType: action.pollingResourceType,
                                    })
                                );
                                break;
                        }
                        return SetReconEvents({
                            events: res,
                            resourceId: action.resourceId,
                            pagingState: 'end',
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e })))
                );
            })
        )
    );

    private checkAppPollEvents = createEffect(() =>
        this.actions$.pipe(
            ofType(CheckAppPollEvents),
            delay(100),
            filter((data) => this.pollingResource[data.resourceId] !== undefined),
            withLatestFrom(this.store$.select(getNotificationsMap$)),
            mergeMap(([action, eventsMap]) => {
                const logIds = eventsMap?.[action.resourceId]?.ids as string[];
                const logsMap = eventsMap?.[action.resourceId]?.entities;
                if (!logIds || !logsMap || !this.pollingResource[action.resourceId]) {
                    return [];
                }
                let events: string[] = [];
                switch (action.resourceType) {
                    case 'REQUEST':
                        events = this.requestEvents;
                        break;
                    case 'INSTANCE':
                        events = this.instanceEvents;
                        break;
                    case 'REPORTS':
                        events = this.reportEndEvents;
                        break;
                    case 'BULK_REPORTS':
                        events = this.bulkReportsEndEvents;
                        break;
                }
                const eventId = logIds
                    ?.filter((id) => !action.timeStamp || new Date(logsMap?.[id].createdOn)?.getTime() > action.timeStamp)
                    ?.find((id) => events.includes(logsMap?.[id]?.eventType));
                const count = this.pollingCounter[action.resourceId];
                if (eventId !== undefined) {
                    if (action.resourceType === 'INSTANCE' && logsMap[eventId].eventType === 'TASK_STARTED') {
                        // Do Nothing
                    } else if (
                        action.resourceType === 'REQUEST' &&
                        ((logsMap[eventId].eventType === 'REQUEST_CREATED' && logsMap[eventId]?.payload?.sourceType === 'PSG') ||
                            logsMap[eventId].eventType !== 'REQUEST_CREATED')
                    ) {
                        return of(ClearEventPolling({ resourceId: action.resourceId }));
                    } else if (action.resourceType === 'REPORTS') {
                        if (logsMap[eventId].eventType === 'OUTBOUND_TRANSFORMATION_STARTED') {
                            // Do Nothing
                        } else if (this.reportEndEvents.includes(logsMap[eventId].eventType)) {
                            return of(ClearEventPolling({ resourceId: action.resourceId }));
                        }
                    } else if (action.resourceType === 'BULK_REPORTS') {
                        if (logsMap[eventId].eventType === 'BULK_REPORTS_DOWNLOAD_STARTED') {
                            // Do Nothing
                        } else if (this.bulkReportsEndEvents.includes(logsMap[eventId].eventType)) {
                            return of(ClearEventPolling({ resourceId: action.resourceId }));
                        }
                    } else if (action.resourceType !== 'REQUEST') {
                        if (!logsMap[eventId]?.payload?.doneForNow) {
                            return [];
                        }
                        return of(ClearEventPolling({ resourceId: action.resourceId }));
                    }
                }
                if (action.resourceType === 'REPORTS' && count >= 4 && !action.noAutoClear) {
                    this.store$.dispatch(
                        PollingTerminated({
                            resourceId: action.resourceId,
                            resourceType: action.resourceType,
                            timeStamp: action.timeStamp,
                        })
                    );
                    return of(ClearEventPolling({ resourceId: action.resourceId }));
                }
                return [];
            })
        )
    );

    private checkReconPollEvents = createEffect(() =>
        this.actions$.pipe(
            ofType(CheckReconPollEvents),
            delay(100),
            filter((data) => this.pollingResource[data.resourceId] !== undefined),
            withLatestFrom(this.store$.select(getReconEventsMap$)),
            mergeMap(([action, eventsMap]) => {
                const logIds = eventsMap?.[action.resourceId]?.ids as string[];
                const logsMap = eventsMap?.[action.resourceId]?.entities;
                if (!logIds || !logsMap || !this.pollingResource[action.resourceId]) {
                    return [];
                }
                let events: string[] = [];
                switch (action.resourceType) {
                    case 'REQUEST':
                        events = this.reconEndEvents;
                        break;
                }
                const event = logIds?.find((id) => events.includes(logsMap?.[id]?.status));
                if (event !== undefined) {
                    this.pollingCounter[action.resourceId] = undefined;
                    return of(ClearEventPolling({ resourceId: action.resourceId }));
                }
                return [];
            })
        )
    );

    private clearEventPolling = createEffect(() =>
        this.actions$.pipe(
            ofType(ClearEventPolling),
            filter((data) => this.pollingResource?.[data.resourceId] !== undefined),
            mergeMap((action) => {
                clearInterval(this.pollingResource[action.resourceId]);
                this.pollingResource[action.resourceId] = undefined;
                this.pollingCounter[action.resourceId] = undefined;
                return [];
            })
        )
    );

    private getInstanceProcessLogs = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetProcessLogs, ClearProcessLogs),
            delay(10),
            mergeMap((action) => {
                if (action.type === actions.types.CLEAR_PROCESS_LOGS) {
                    return [];
                }
                return this._api.common
                    .getProcessLogs({
                        resourceId: action.resourceId,
                        fetchSize: action.fetchSize,
                        pagingState: action.pagingState,
                    })
                    .pipe(
                        map((res: { result: NotificationEvent[]; fetchSize: number; pagingState: string }) => {
                            return SetProcessLogs({
                                processLogs: res?.result,
                                resourceId: action.resourceId,
                                pagingState: res?.pagingState || 'end',
                            });
                        }),
                        catchError((e) => of(AlertError({ message: e })))
                    );
            })
        );
    });

    private getEventTypes = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetEventTypes),
            withLatestFrom(this.store$.select(getEventTypes$)),
            mergeMap(([action, eventTypes]) => {
                if (eventTypes?.length > 0) {
                    return [];
                }
                return from(this._api.events.getEventDefinitions()).pipe(
                    map((res: any) => {
                        return actions.SetEventTypes({
                            eventTypes: res?.response?.definitions,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error?.msg })))
                );
            })
        )
    );

    private getEventSubscriptions = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetSubscriptionsViaPagination),
            withLatestFrom(this.store$.select(getEventSubscriptionsViaPagination$), this.store$.select(getEventSubscriptions$)),
            delay(10),
            mergeMap(([action, eventSubscriptions, allSubscriptions]) => {
                let workflowEventTypes = ['TASK_STARTED', 'TASK_COMPLETED'];
                if (workflowEventTypes.includes(action.eventType)) {
                    const hasWorkflowEvent = eventSubscriptions.some((subscription) =>
                        workflowEventTypes.includes(subscription.eventType.toUpperCase())
                    );
                    if (hasWorkflowEvent) {
                        return [];
                    }
                } else if (
                    eventSubscriptions?.length > 0 &&
                    allSubscriptions.some((subscription) => subscription.eventType === action.eventType.toLowerCase())
                ) {
                    return [];
                }
                return from(
                    this._api.events.getSubscribedEvents({
                        orgId: action.orgId,
                        fetchSize: action.fetchSize,
                        pagingState: action.pagingState,
                        eventType: action.eventType,
                        groupId: action.groupId,
                    })
                ).pipe(
                    map((res: { result: any[]; fetchSize: number; pagingState: string }) => {
                        return actions.SetEventSubscriptions({
                            fetchSize: res?.fetchSize,
                            pagingState: res?.pagingState,
                            index: action.index,
                            eventSubscriptions: res?.result,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e.msg })))
                );
            })
        )
    );

    private deleteSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteEventSubscription),
            mergeMap((action) => {
                return this._api.events.deleteEventSubscription(action.data).pipe(
                    map((res: { msg: string }) => {
                        this.store$.dispatch(AlertSuccess({ message: res?.msg || translate('Event subscription deleted successfully') }));
                        return actions.DeleteEventSubscriptionSuccess({ subscriptionId: action?.data?.subscriptionId });
                    }),
                    catchError((e) => {
                        return of(AlertError({ message: e?.msg || translate('Failed to delete event subscription') }));
                    })
                );
            })
        );
    });

    private updateSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.UpdateEventSubscription),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                return this._api.events.editEventSubscriptions(action.data).pipe(
                    map((res: { msg: string }) => {
                        this.store$.dispatch(AlertSuccess({ message: res?.msg || translate('Event subscription updated successfully') }));
                        this.store$.dispatch(actions.ClearEventSubscriptions());
                        let data = {
                            orgId: organizationId,
                            fetchSize: 10,
                            pagingState: '',
                            eventType: '',
                            index: 0,
                            groupId: action.data.groupId,
                        };
                        if (action.data.eventType === 'OUTBOUND_TRANSFORMATION_COMPLETED') {
                            data.eventType = 'OUTBOUND_TRANSFORMATION_COMPLETED';
                            return actions.GetSubscriptionsViaPagination(data);
                        } else {
                            data.eventType = 'TASK_STARTED';
                            this.store$.dispatch(actions.GetSubscriptionsViaPagination(data));
                            data.eventType = 'TASK_COMPLETED';
                            return actions.GetSubscriptionsViaPagination(data);
                        }
                    }),
                    catchError((e) => {
                        return of(AlertError({ message: e?.msg || translate('Failed to update event subscription') }));
                    })
                );
            })
        );
    });

    private createSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CreateEventSubscription),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                return this._api.events.createEventSubscriptions(action.data).pipe(
                    map((res: { msg: string }) => {
                        this.store$.dispatch(AlertSuccess({ message: res?.msg || translate('Event subscription created successfully') }));
                        this.store$.dispatch(actions.ClearEventSubscriptions());
                        let data = {
                            orgId: organizationId,
                            fetchSize: 10,
                            pagingState: '',
                            eventType: '',
                            index: 0,
                            groupId: action.data.groupId,
                        };
                        if (action.data.eventType === 'OUTBOUND_TRANSFORMATION_COMPLETED') {
                            data.eventType = 'OUTBOUND_TRANSFORMATION_COMPLETED';
                            return actions.GetSubscriptionsViaPagination(data);
                        } else {
                            data.eventType = 'TASK_STARTED';
                            this.store$.dispatch(actions.GetSubscriptionsViaPagination(data));
                            data.eventType = 'TASK_COMPLETED';
                            return actions.GetSubscriptionsViaPagination(data);
                        }
                    }),
                    catchError((e) => {
                        return of(AlertError({ message: e?.msg || translate('Failed to create event subscription') }));
                    })
                );
            })
        );
    });

    private getEmailNotifications = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.SetEmailPagination),
            withLatestFrom(this.store$.select(getEmailNotificationsViaPagination$)),
            delay(10),
            mergeMap(([action, emailNotifications]) => {
                if (emailNotifications?.length > 0) {
                    return [];
                }
                return from(
                    this._api.events.getEmailNotifications({
                        groupId: action.groupId,
                        eventType: action.eventType,
                        subscriptionId: action.subscriptionId,
                        size: action.size,
                        pageState: action.pageState,
                        fromDate: action.fromDate,
                        status: action.status,
                        mailId: action.mailId,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res: { result: any[]; fetchSize: number; pagingState: string }) => {
                        return SetEmailNotifications({
                            fetchSize: res?.fetchSize,
                            emailNotifications: res?.result,
                            pagingState: res?.pagingState,
                            index: action.index,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e.msg })))
                );
            })
        )
    );

    private getEmailContent = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetEmailContent),
            withLatestFrom(this.store$.select(getEmailContent$)),
            mergeMap(([action, emailContents]) => {
                if (emailContents?.[action.messageId]) {
                    if (action.resend) {
                        this.store$.dispatch(
                            actions.ResendEmail({
                                messageId: action.messageId,
                                emailId: action.emailId,
                            })
                        );
                    }
                    return [];
                }
                return from(
                    this._api.events.getEmailContent({
                        messageId: action.messageId,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res: { attachmentList?: any[]; messageContent?: string; subject?: string }) => {
                        if (action.resend) {
                            this.store$.dispatch(actions.ResendEmail({ messageId: action.messageId, emailId: action.emailId }));
                        }
                        return actions.SetEmailContent({
                            attachmentList: res?.attachmentList,
                            messageContent: res?.messageContent,
                            subject: res?.subject,
                            messageId: action.messageId,
                            emailId: action.emailId,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e })))
                );
            })
        )
    );
    private resendEmail = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ResendEmail),
            delay(10),
            withLatestFrom(this.store$.select(getEmailNotifications$), this.store$.select(getEmailContent$)),
            mergeMap(([action, events, emailContents]) => {
                const event = events?.find((log) => log.messageId === action.messageId && log.emailId === action.emailId);
                const message = emailContents?.[action.messageId];
                return this._api.events
                    .resendEmail({
                        attachmentList: message.attachmentList,
                        ccEmails: [],
                        bccEmails: [],
                        contextProperties: {
                            eventType: event.eventType,
                            groupId: event.groupId,
                            subscriptionId: event.subscriptionId,
                            unitId: event.orgId,
                        },
                        messageContent: message.messageContent,
                        orgId: event.orgId,
                        subject: message.subject,
                        toEmails: event.emailId.split(',').map((email) => email.trim()),
                    })
                    .pipe(
                        map((res: { msg: string }) => {
                            return AlertSuccess({ message: res.msg || '' });
                        }),
                        catchError((res) => {
                            return of(AlertError({ message: res.msg || '' }));
                        })
                    );
            })
        );
    });
}
