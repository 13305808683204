import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { User } from '../../models/user.class';

import { ApiService } from '../../services/api/api.service';
import * as sharedActions from '../actions/shared.actions';
import * as userActions from '../actions/users.actions';
import {
    getCurrentOrganizationId$,
    getOrgUsers$,
    getRoleUserIds$,
    getSelectedOrgIdToAssignUsers$,
    getUsersByPagination$,
    getUserSettingsState$,
    getUsersPaginationStatus$,
    getUsersSearchState$,
    has4ECAccess$,
} from '../selectors';
import { UsersState } from '../states/users.state';
import { AlertError, AlertSuccess } from '../actions/shared.actions';
import { translate } from '@ngneat/transloco';
import { UsersListService } from '@encomply/shared/services/users-list.service';
import { CommunicationsService } from '../../services/communications/communications.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

@Injectable()
export class UserEffects {
    constructor(
        private store: Store<UsersState>,
        private actions$: Actions,
        private _api: ApiService,
        private _usersList: UsersListService
    ) {}

    private getUserSettings = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.GetUserSettings),
            mergeMap((action) => {
                let userSettings: UsersState['settings'];
                this.store
                    .pipe(select(getUserSettingsState$))
                    .pipe(take(1))
                    .subscribe((settings) => (userSettings = settings));
                if (userSettings?.passwordPolicy !== undefined) {
                    return [];
                }
                return from(this._api.session.getUserMiscProps()).pipe(
                    map((response) => userActions.SetUserSettings(response)),
                    catchError((errorResponse) => of(sharedActions.AlertError({ message: errorResponse })))
                );
            })
        );
    });

    private resetUserPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.ResetUserPassword),
            mergeMap((action) => {
                return this._api.user.resetUserPassword(action.payload).pipe(
                    map((response) => {
                        return AlertSuccess({ message: response.msg });
                    }),
                    catchError((response) => {
                        return of(AlertError({ message: response.msg }));
                    })
                );
            })
        );
    });

    private updatePassword = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.UpdatePassword),
            mergeMap((action) => {
                const data = {
                    oldPassword: action.oldPassword,
                    newPassword: action.newPassword,
                    confirmPassword: action.confirmPassword,
                };
                return from(this._api.session.updatePassword(data)).pipe(
                    map((response) => {
                        this.store.dispatch(sharedActions.AlertSuccess({ message: response }));
                        return userActions.UpdatePasswordSuccessfull({ message: response });
                    }),
                    catchError((errorResponse) => {
                        this.store.dispatch(userActions.UpdatePasswordFailed({ message: errorResponse }));
                        return of(sharedActions.AlertError({ message: errorResponse }));
                    })
                );
            })
        );
    });

    private getUsers = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.SetUsersPagination, userActions.SetUsersSearch),
            delay(10),
            withLatestFrom(
                this.store.select(getCurrentOrganizationId$),
                this.store.select(getUsersSearchState$),
                this.store.select(getUsersPaginationStatus$),
                this.store.select(getUsersByPagination$)
            ),
            mergeMap(([action, currentOrganizationId, search, pagination, users]) => {
                if (users?.length > 0 && users?.length >= pagination?.pageSize) {
                    return [];
                }
                return from(
                    this._api.user.getUsersByPagination({
                        organizationId: currentOrganizationId,
                        pageNumber: pagination.index + 1,
                        pageSize: pagination.pageSize,
                        criterias: search,
                    })
                ).pipe(
                    map((response) => {
                        return userActions.SetUsers({ data: response as any as User[], organizationId: currentOrganizationId });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || 'Failed to get users' })))
                );
            })
        );
    });

    private addUser = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.UpdateUser),
            delay(10),
            withLatestFrom(this.store.select(getCurrentOrganizationId$), this.store.select(has4ECAccess$)),
            mergeMap(([action, organizationId, hasAccess]) => {
                if (action.data.user?.id) {
                    return from(this._api.user.updateUserDetails(action.data)).pipe(
                        map((response: any) => {
                            const message = hasAccess
                                ? translate(`Your request has been placed, it will effect after admin's approval`)
                                : translate('User Updated Successfully');
                            this.store.dispatch(
                                AlertSuccess({
                                    message,
                                })
                            );
                            return userActions.SetUser({
                                user: response,
                                newUser: false,
                                organizationId,
                                message: action.userAction && message,
                            });
                        }),
                        catchError((error) => of(AlertError({ message: error.msg || 'Failed to update users' })))
                    );
                }
                return from(this._api.user.addUser(action.data)).pipe(
                    map((response: any) => {
                        const message = hasAccess
                            ? translate(`Your request has been placed, it will effect after admin's approval`)
                            : translate('User added successfully');
                        action.userAction &&
                            this.store.dispatch(
                                AlertSuccess({
                                    message,
                                })
                            );
                        return userActions.SetUser({
                            user: response,
                            newUser: true,
                            organizationId,
                            message: action.userAction && message,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || 'Failed to update users' })))
                );
            })
        );
    });

    private getSessionUserDetails = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.GetSessionUserDetails),
            delay(10),
            withLatestFrom(this.store.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                return from(this._api.user.getCurrentUserDetails()).pipe(
                    map((response: any) => {
                        return userActions.SetUser({
                            user: response,
                            newUser: false,
                            organizationId,
                            message: undefined,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || 'Failed to update users' })))
                );
            })
        );
    });

    private getUserDetails = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.GetUserDetails),
            delay(10),
            withLatestFrom(this.store.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                return from(this._api.user.getUserDetails(action.userId)).pipe(
                    map((response: any) => {
                        return userActions.SetUser({
                            user: response,
                            newUser: true,
                            organizationId,
                            message: undefined,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || 'Failed to update users' })))
                );
            })
        );
    });

    private UnlockPassword = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.UnlockPassword),
            mergeMap((action) => {
                return this._api.user.unlockPassword({ userId: action.userId }).pipe(
                    map((response: any) => {
                        return AlertSuccess({
                            message: response?.msg || 'User unlocked successfully',
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || 'Failed to unlock user' })))
                );
            })
        );
    });

    private GetOrgUsers = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.GetOrgUsers),
            withLatestFrom(this.store.select(getSelectedOrgIdToAssignUsers$), this.store.select(getOrgUsers$)),
            mergeMap(([action, selectedOrgId, orgUsers]) => {
                if (orgUsers?.length > 0) {
                    return of(
                        userActions.SetOrgUsers({
                            orgId: selectedOrgId,
                            users: CommonUtilsService.cloneObject(orgUsers),
                        })
                    );
                }
                return this._api.user.getOrgUsersObservable({ organizationId: selectedOrgId }).pipe(
                    map((res: any) => {
                        return userActions.SetOrgUsers({
                            orgId: selectedOrgId,
                            users: res,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg })))
                );
            })
        );
    });
}
