import { Configuration } from './configuration.interface';

export const environment: Configuration = {
    'auth-ui': undefined,
    'encollab-ui': undefined,
    'encomply-ui': undefined,
    'eninvoice-ui': undefined,
    'enreconcile-ui': undefined,
    'enreport-ui': undefined,
    'ksa-wrapper': undefined,
    'macre-ui': undefined,
    envName: undefined,
    production: undefined,
    additionalFeatures: undefined,
    analytics_api: undefined,
    appId: undefined,
    bundle_api: undefined,
    canShowAnalytics: undefined,
    clientId: undefined,
    countryCode: undefined,
    defaultLanguage: undefined,
    defaultProcessingUI: undefined,
    encollabAppsConfig: undefined,
    encollabAutoSubscribeTags: undefined,
    encollabTags: undefined,
    eninvoice_api: undefined,
    eninvoiceAppId: undefined,
    eninvoiceAppsConfig: undefined,
    eninvoiceAutoSubscribeTags: undefined,
    eninvoiceTags: undefined,
    enreportTags: undefined,
    integration_api: undefined,
    logoHref: undefined,
    marqueeText: undefined,
    notifications_api: undefined,
    oauth_api: undefined,
    onLocalhost: undefined,
    recon_api: undefined,
    redirectOnLogin: undefined,
    region: undefined,
    restrictedFeatures: undefined,
    restrictedProcessingUIs: undefined,
    returnPage: undefined,
    showMarquee: undefined,
    showNewProcessToEdit: undefined,
    specialUI: undefined,
    tags: undefined,
    taxilla_api: undefined,
    taxilla_search_api: undefined,
    taxilla_user_identity_api: undefined,
    wrappers: undefined,
    hideSignUpPage: undefined,
    supportSystem: undefined,
};
