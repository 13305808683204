import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    GetSecurityQuestions,
    GetSecurityQuestionsSuccessState,
    getSessionUser$,
    getSessionUserCurrentOrganizationRoleIds$,
    UpdateUser,
} from 'taxilla-library';

import { User } from '../../models/user.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ConfirmationDialogService } from '../../services/confirmation/confirmation-dialog.service';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { UtilsService } from '../../services/utils/utils.service';
import { GetSessionUserCurrentOrganizationRoles, GetSessionUserDetails, SetUser } from '../../store/actions';

@Component({
    selector: 'app-editprofile',
    templateUrl: './editprofile.component.html',
    styleUrls: ['./editprofile.component.scss'],
})
export class EditprofileComponent implements OnInit {
    errors: { [property: string]: string[] } = {};
    loggedInUser = {
        user: new User({}),
        roleIds: [],
    };
    loggedInUserCopy = {};
    securityQuestionsStructureCopy: any;
    secretQuestions = [];
    securityQuestionsStructure = [];
    fileName = translate('No file chosen') as string;
    fileName2 = translate('No image chosen') as string;
    clearFiles = new BehaviorSubject('clear');
    files: any[] = [undefined, undefined];
    filenames: any[] = [undefined, undefined];
    userDscFileUrl: string;
    userDscFileName: string;
    userDscImgUrl: string;
    userDscImgName: string;
    isImageTypeSupported = true;
    isFileTypeSupported = true;
    tempFile: any;
    tempImage: any;
    updateUserObject: any;
    organizationId = this._commonUtils.getFromStorage('currentOrganizationId');
    orgRoles = [];
    allOrgRoles = new BehaviorSubject([]);
    canShowWorkQ = !environment.restrictedFeatures?.workQ;
    secretKey: string;
    qrCode: string;
    selected = new UntypedFormControl(0);
    authTypes = [
        {
            id: 'SECRET_QUESTION',
            name: 'Questions',
        },
        {
            id: 'GOOGLE_AUTHENTICATOR_OTP',
            name: 'Google Authenticator',
        },
        {
            id: 'EMAIL_OTP',
            name: 'Email OTP Authenticator',
        },
    ];
    user: any;
    mmfaQuestions: number;
    unsubscribe = new Subject<void>();

    constructor(
        public dialog: MatDialog,
        public _utils: UtilsService,
        private fileupload: FileUpload,
        private _commonUtils: CommonUtilsService,
        private _broadcaster: BroadcasterService,
        private _confirmation: ConfirmationDialogService,
        private _api: ApiService,
        private store$: Store,
        private actions$: Actions
    ) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    submitUser = () => {
        if (this.validateUser()) {
            if (!this.checkforChange() || !this.checkChangeInSecretQuestions()) {
                this.buildSecurityQuestionPayload();
                this.loggedInUser.user.displayName = this.loggedInUser.user.firstName + ' ' + this.loggedInUser.user.lastName;
                this.store$.dispatch(UpdateUser({ data: CommonUtilsService.cloneObject(this.loggedInUser), userAction: true }));
            } else {
                this._utils.alertError(translate('No change found for update'));
            }
        } else {
            this._utils.alertError(translate('Please add valid details'));
        }
    };

    private buildSecurityQuestionPayload = () => {
        let count = Object.keys(this.loggedInUser.user).filter((key) => /secretQuestion/.test(key)).length;
        Object.keys(this.loggedInUser.user)
            .filter((key) => /secretQuestion/.test(key) || /secretAnswer/.test(key))
            .forEach((key) => {
                delete this.loggedInUser.user[key];
            });
        Object.keys(this.loggedInUser.user)
            .filter((key) => /question/.test(key) && /Modified/.test(key))
            .forEach((key) => {
                this.loggedInUser.user[key] = undefined;
            });
        this.securityQuestionsStructure?.forEach((question) => {
            this.loggedInUser.user[`secretQuestion${question.id}`] = question.key;
            if (question && !question.encrypted) {
                this.loggedInUser.user[`secretAnswer${question.id}`] = question.value ?? '';
            }
            this.loggedInUser.user[`question${question.id}Modified`] = !question.encrypted;
        });
        for (let i = 1; i <= count; i++) {
            if (this.loggedInUser.user[`question${i}Modified`] === undefined) {
                this.loggedInUser.user[`question${i}Modified`] = true;
            }
        }
    };

    validateUser = () => {
        let count = 0;
        if (!this.loggedInUser.user['firstName'] || this.loggedInUser.user['firstName'].trim() === '') {
            this.errors.firstName = [translate('Enter First Name')];
            count++;
        } else if (this._utils.invalidChars(this.loggedInUser.user['firstName'])) {
            this.errors.firstName = [translate('Enter alphabets and spaces only')];
            count++;
        } else if (!this._utils.acceptAlphabets(this.loggedInUser.user['firstName'])) {
            this.errors.firstName = [translate('Enter alphabets and spaces only')];
            count++;
        } else if (this.loggedInUser.user['firstName'].length > 50) {
            this.errors.firstName = [translate('Enter max 50 characters')];
            count++;
        }
        if (!this.loggedInUser.user['lastName'] || this.loggedInUser.user['lastName'].trim() === '') {
            this.errors.lastName = [translate('Enter Last Name')];
            count++;
        } else if (this._utils.invalidChars(this.loggedInUser.user['lastName'])) {
            this.errors.lastName = [translate('Enter alphabets and spaces only')];
            count++;
        } else if (!this._utils.acceptAlphabets(this.loggedInUser.user['lastName'])) {
            this.errors.lastName = [translate('Enter alphabets and spaces only')];
            count++;
        } else if (this.loggedInUser.user['lastName'].length > 50) {
            this.errors.lastName = [translate('Enter max 50 characters')];
            count++;
        }
        if (!this.loggedInUser.user['email'] || this.loggedInUser.user['email'].trim() === '') {
            this.errors.email = [translate('Enter Email')];
            count++;
        } else if (this._utils.invalidChars(this.loggedInUser.user['email'])) {
            this.errors.email = [translate('Enter a valid Email')];
            count++;
        } else if (!this._utils.acceptEmail(this.loggedInUser.user['email'])) {
            this.errors.email = [translate('Enter a valid Email')];
            count++;
        }
        if (this.loggedInUser.user['department']) {
            if (this._utils.invalidChars(this.loggedInUser.user['department'])) {
                this.errors.department = [translate('Enter a valid Department')];
                count++;
            } else if (this.loggedInUser.user['department'].length > 255) {
                this.errors.department = [translate('Enter max 255 characters')];
                count++;
            }
        }
        if (this.loggedInUser.user['designation']) {
            if (this._utils.invalidChars(this.loggedInUser.user['designation'])) {
                this.errors.designation = [translate('Enter a valid Designation')];
                count++;
            } else if (this.loggedInUser.user['designation'].length > 255) {
                this.errors.designation = [translate('Enter max 255 characters')];
                count++;
            }
        }
        if (!this.loggedInUser.user['userId'] || this.loggedInUser.user['userId'] === '') {
            this.errors.userId = [translate('Enter User Id')];
            count++;
        } else if (this.loggedInUser.user['userId'].length < 6) {
            this.errors.userId = [translate('Enter userid min of 6 characters')];
            count++;
        } else if (!this._utils.acceptUserId(this.loggedInUser.user['userId'])) {
            this.errors.userId = [
                translate('Enter a valid userid which allows alphabets,digits and dot(.),underscore(_),hyphen(-), and @'),
            ];
            count++;
        }
        this.securityQuestionsStructure.forEach((question) => {
            if (!question.key || question.key?.length === 0) {
                question.errors.key = [translate('Please select question')];
            } else {
                question.errors.key = question.errors.key || [];
            }
            question.errors.key.length > 0 && count++;
            if (question.encrypted) {
                return;
            }
            if (!question.value || question.value?.length === 0) {
                question.errors.value = [translate('Please Answer the question')];
            } else if (question.value.trim().length !== question.value.length) {
                question.errors.value = [translate('Please remove leading and trailing spaces')];
            } else {
                question.errors.value = question.errors.value || [];
            }
            question.errors.value.length > 0 && count++;
        });
        return count === 0;
    };

    downloadDsc = () => {
        window.location.href = environment.taxilla_user_identity_api + '/users/' + this.loggedInUser.user.id + '/signature';
    };

    getUserSecretQuestions = (user: User) => {
        if (user) {
            const questions = Object.keys(user).filter((key) => /secretQuestion/.test(key));
            this.securityQuestionsStructure = [];
            questions.forEach((question) => {
                const id = question.substring('secretQuestion'.length);
                this.securityQuestionsStructure.push({
                    id: id,
                    displayName: `Security Question`,
                    key: user[question],
                    value: user['secretAnswer' + id],
                    encrypted: true,
                    errors: {
                        value: [],
                        key: [],
                    },
                });
            });
        }
    };

    checKQuestions = (value, selectedQuestion) => {
        if (value && value.length > 0) {
            let exist = false;
            for (let i = 0; i < this.securityQuestionsStructure.length; i++) {
                const question = this.securityQuestionsStructure[i];
                if (selectedQuestion.id !== question.id && question.key === value) {
                    exist = true;
                }
            }
            for (let j = 0; j < this.securityQuestionsStructure.length; j++) {
                if (this.securityQuestionsStructure[j].id === selectedQuestion.id) {
                    if (exist) {
                        this.securityQuestionsStructure[j].errors.key = [
                            translate('The question has been selected.Please select another question'),
                        ];
                    } else {
                        this.securityQuestionsStructure[j].errors.key = [];
                    }
                }
            }
        }
    };

    /**
     * get Roles
     */
    getAllOrgRoles = () => {
        this.orgRoles = this._commonUtils.getFromStorage('roles');
        if (this.orgRoles === null || this.orgRoles === undefined || this.orgRoles.length === 0) {
            this._api.roles.getRoles(this.organizationId).subscribe((res) => {
                this.orgRoles = res;
                this._commonUtils.setInStorage('roles', this.orgRoles);
                this.allOrgRoles.next(this.orgRoles);
            });
        } else {
            this.allOrgRoles.next(this.orgRoles);
        }
    };

    checkforChange = () => {
        return JSON.stringify(this.loggedInUser) === JSON.stringify(this.loggedInUserCopy);
    };

    checkChangeInSecretQuestions = () => {
        return JSON.stringify(this.securityQuestionsStructure) === JSON.stringify(this.securityQuestionsStructureCopy);
    };

    getGoogleCredentials = () => {
        this._api.session.getGoogleAuthenticatorSecretKey().then((res) => {
            this.secretKey = res?.secretKey;
            this.secretKey &&
                this._api.session.getGoogleAuthenticatorQRCode().then((response) => {
                    this.qrCode = response?.QRCode;
                });
        });
    };

    createGoogleSecretkey = () => {
        const confirmConfig = {
            title: translate('Confirmation') as string,
            message: translate(
                'You will be prompted with code rather than 2FA questions from next time you login. Are you sure to do this'
            ) as string,
            btnOkText: translate('Ok') as string,
            btnCancelText: translate('Cancel') as string,
        };
        this._confirmation
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                console.log(action);
                if (action) {
                    this._api.session.setGoogleAuthenticatorSecretKey().then((res) => {
                        this._utils.alertSuccess(res?.['msg'] || 'Created authenticator successfully.');
                        this.getGoogleCredentials();
                    });
                }
            });
    };

    createEmailOTP = () => {
        const confirmConfig = {
            title: translate('Confirmation') as string,
            message: translate(
                'You will be prompted with OTP rather than 2FA questions from next time you login. Are you sure to do this'
            ) as string,
            btnOkText: translate('Ok') as string,
            btnCancelText: translate('Cancel') as string,
        };
        this._confirmation
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                console.log(action);
                if (action) {
                    this._utils.alertSuccess('Updated Authenticator to Email OTP successfully.');
                }
            });
    };

    changeAuthType = () => {
        this.loggedInUser.user.twoFAType = this.authTypes[this.selected.value].id;
        if (this.selected.value === 1 && (!this.secretKey || !this.secretKey?.length)) {
            this.createGoogleSecretkey();
        }
        if (this.selected.value === 2) {
            this.createEmailOTP();
        }
    };

    public pushQuestions = (count: number) => {
        count = this.securityQuestionsStructure.length + count;
        for (let i = 0; i < count; i++) {
            if (this.securityQuestionsStructure.length === count) {
                return;
            }
            const id = `${i + 1}`;
            const question = this.securityQuestionsStructure.find((question) => question.id === id);
            if (!question) {
                this.securityQuestionsStructure.push({
                    id: id,
                    displayName: `Security Question`,
                    key: '',
                    value: '',
                    errors: {
                        value: [],
                        key: [],
                    },
                });
            }
        }
    };

    intitializeStoreSubscriptions = () => {
        this.store$
            .select(GetSecurityQuestionsSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe({
                next: (secretQuestions) => {
                    if (secretQuestions?.length) {
                        secretQuestions.forEach((element) => {
                            const obj = {};
                            obj['value'] = element;
                            obj['translate'] = true;
                            this.secretQuestions.push(obj);
                        });
                    }
                },
            });
        this.actions$
            .pipe(
                ofType(SetUser),
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((event) => {
                this._commonUtils.setInStorage('user', event.user);
                event.message && this.closeModal();
            });
        combineLatest([this.store$.select(getSessionUserCurrentOrganizationRoleIds$), this.store$.select(getSessionUser$)])
            .pipe(
                takeUntil(this.unsubscribe),
                filter(([roleIds, user]) => roleIds !== undefined && user !== undefined)
            )
            .subscribe(([roleIds, user]) => {
                this.loggedInUser = {
                    roleIds: CommonUtilsService.cloneObject(roleIds),
                    user: CommonUtilsService.cloneObject(user),
                };
                if (this.loggedInUser.user.createdDate) {
                    delete this.loggedInUser.user.createdDate;
                }
                if (this.loggedInUser.user.lastModifiedDate) {
                    delete this.loggedInUser.user.lastModifiedDate;
                }
                this.loggedInUserCopy = JSON.parse(JSON.stringify(this.loggedInUser));
                this.getUserSecretQuestions(this.loggedInUser.user);
                this.securityQuestionsStructureCopy = JSON.parse(JSON.stringify(this.securityQuestionsStructure));
                this.userDscFileUrl = this.loggedInUser.user.signatureUrls;
                if (this.loggedInUser.user.signatureFileName) {
                    this.userDscFileName = this.loggedInUser.user.signatureFileName;
                } else if (this.userDscFileUrl) {
                    this.userDscFileName = this.userDscFileUrl.substring(this.userDscFileUrl.lastIndexOf('/') + 1);
                }
                this.userDscImgUrl = this.loggedInUser.user.signImageUrl;
                if (this.loggedInUser.user.signatureImageFileName) {
                    this.userDscImgName = this.loggedInUser.user.signatureImageFileName;
                } else if (this.userDscImgUrl) {
                    this.userDscImgName = this.userDscImgUrl.substring(this.userDscImgUrl.lastIndexOf('/') + 1);
                }
            });
    };

    ngOnInit(): void {
        this.mmfaQuestions = parseInt(this._commonUtils.getFromStorage('mmfaQuestions') || '2');
        this.pushQuestions(this.mmfaQuestions);
        this.user = this._commonUtils.getFromStorage('user');
        this.selected.setValue(this.authTypes.findIndex((type) => type.id === this.user.twoFAType) || 0);
        this.store$.dispatch(GetSecurityQuestions());
        this.store$.dispatch(GetSessionUserDetails());
        this.store$.dispatch(GetSessionUserCurrentOrganizationRoles());
        this.getGoogleCredentials();
        this._broadcaster.on('logout').subscribe(() => {
            this.dialog.closeAll();
        });
        this.intitializeStoreSubscriptions();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
