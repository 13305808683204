import { UtilsService } from '../services/utils/utils.service';
import { AssetData } from './assetdata.class';
import { Search } from './search.class';

export class Transformation {
    defaulted: boolean;

    description: string;

    engineProps: any;

    mappedTargetMetadata: any;

    mappingMetadata: any;

    sourceParts: {
        copyOf?: string;
        bulkUpload: boolean;
        entities: any[];
        fileExtension: string;
        name: string;
        settings: { scanAllChildData: boolean };
        sourceDataFormat: string;
        sourceType: string;
        useDefaultEscapeChar: boolean;
        file: File;
        sourceAssetOrMasterName: string;
        search?: Search;
        defaultSearchFilters?: any;
        appDetails?: {
            metadataId: string;
            restApiName: string;
            serviceId: string;
            serviceName: string;
            sourceType: string;
            metaDataLoading?: boolean;
            metaData?: AssetData;
        };
    }[];

    name: string;

    xmlVersion: string;

    value: any;

    assetToAssetMetadata: any;

    isAssetToAsset: boolean;

    isSearchAsset: boolean;

    isFileUpload: boolean;

    id: any;

    displayName: string;

    chainRestApiName: string;

    chainName?: string;

    active?: boolean;

    repositoryId?: string;

    constructor(
        transformation,
        name,
        reconciliationTransformation?: {
            repositoryId: string;
            chainName: string;
            displayName: string;
            restApiName: string;
            sourceTransformation: {
                name: string;
                description: string;
                engineProps: {
                    type: string;
                    version: string;
                };
                sourceParts: [
                    {
                        copyOf?: string;
                        name: string;
                        sourceDataFormat: string;
                        fileExtension: string;
                        sourceType: string;
                        useDefaultEscapeChar: boolean;
                        bulkUpload: boolean;
                        entities: [];
                    }
                ];
                mappedTargetMetadata: {
                    name: string;
                    dataFormat: string;
                    fileExtension: string;
                    mappedEntities: [];
                    disableStreamBased: boolean;
                };
                mappingMetadata: {
                    mapName: string;
                };
                defaulted: boolean;
                isService: boolean;
                xmlVersion: string;
            };
        }
    ) {
        const reconTransformation = reconciliationTransformation?.sourceTransformation;
        this.defaulted = transformation?.defaulted || reconTransformation?.defaulted;
        this.description = transformation?.description || reconTransformation?.description;
        this.engineProps = transformation?.engineProps || reconTransformation?.engineProps;
        this.mappedTargetMetadata = transformation?.mappedTargetMetadata || reconTransformation?.mappedTargetMetadata;
        this.mappingMetadata = transformation?.mappingMetadata || reconTransformation?.mappingMetadata;
        this.sourceParts = transformation?.sourceParts || reconTransformation?.sourceParts;
        this.name = transformation?.name || reconTransformation?.name;
        this.id = transformation.id || UtilsService.guid();
        this.displayName = reconciliationTransformation?.displayName || name;
        this.xmlVersion = transformation?.xmlVersion || reconTransformation?.xmlVersion;
        this.value = transformation?.value;
        this.assetToAssetMetadata = transformation?.assetToAssetMetadata;
        this.isAssetToAsset =
            this.sourceParts?.find((sourcePart) => sourcePart.sourceType === 'ASSET') && this.assetToAssetMetadata !== undefined;
        this.isSearchAsset =
            this.sourceParts?.find((sourcePart) => sourcePart.sourceType === 'ASSET') && this.assetToAssetMetadata === undefined;
        this.isFileUpload = !!this.sourceParts?.find(
            (sourcePart) => sourcePart.sourceType === 'FILE_UPLOAD' || sourcePart.sourceType !== 'ASSET'
        );
        this.chainRestApiName = reconciliationTransformation?.restApiName;
        this.chainName = transformation?.chainName;
    }
}
