<mat-expansion-panel [expanded]="expandAccordion ? expandAccordion: false" (opened)="showContentOnlyOnExpand && showAccordionBody = true;openMatAccordion($event)" (closed)="showContentOnlyOnExpand && showAccordionBody = false" [disabled]="disabled ? disabled : false" [hideToggle]="hideToggle">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <ng-content select=".materialAccordionHeader">
                <!-- material accordion header -->
            </ng-content>
        </mat-panel-title>
        <mat-panel-description>
            <ng-content select=".materialAccordionHeaderDescription">
                <!-- material accordion header -->
            </ng-content>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
        <ng-container *ngIf="showAccordionBody">
            <ng-content select=".materialAccordionBody">
                <!-- material accordion header -->
            </ng-content>
        </ng-container>
    </ng-template>
    <mat-action-row *ngIf="showFooter !== false">
        <ng-content select=".materialAccordionActions">
            <!-- material accordion header -->
        </ng-content>
    </mat-action-row>
</mat-expansion-panel>
