import { createAction, props } from '@ngrx/store';

import { PermissionsObject } from '../../interface/permissions.interface';

const enum actionTypes {
    GET_PRIMARY_PERMISSIONS = '[PERMISSIONS] Get Primary Permissions',
    SET_PERMISSIONS = '[PERMISSIONS] Set Permissions',
    GET_BUNDLE_PERMISSIONS = '[PERMISSIONS] Get Bundle Permissions',
    SET_BUNDLE_PERMISSIONS = '[PERMISSIONS] Set Bundle Permissions',
    GET_BUNDLE_ROLE_PERMISSIONS = '[PERMISSIONS] Get Bundle Role Permissions',
    SET_BUNDLE_ROLE_PERMISSIONS = '[PERMISSIONS] Set Bundle Role Permissions',
    UPDATE_BUNDLE_ROLE_PERMISSIONS = '[PERMISSIONS] Update Bundle Role Permissions',
    BUNDLE_ROLE_CRUD_OPERATIONS = '[PERMISSIONS] Bundle Role Crud Operataions',
    GET_ROLE_PERMISSIONS = '[PERMISSIONS] Get Role Permissions',
    SET_ROLE_PERMISSIONS = '[PERMISSIONS] Set Role Permissions',
    GET_ALL_ROLE_PERMISSIONS = '[PERMISSIONS] Get All Role Permissions',
    SET_ALL_ROLE_PERMISSIONS = '[PERMISSIONS] Set All Role Permissions',
}

export const GetPrimaryPermissions = createAction(actionTypes.GET_PRIMARY_PERMISSIONS);

export const SetPermissions = createAction(
    actionTypes.SET_PERMISSIONS,
    props<{
        currentOrganizationId: string;
        permissions: PermissionsObject;
    }>()
);

export const GetBundlePermissions = createAction(
    actionTypes.GET_BUNDLE_PERMISSIONS,
    props<{
        bundleId: string;
        selectedApps: any[];
    }>()
);

export const SetBundlePermissions = createAction(
    actionTypes.SET_BUNDLE_PERMISSIONS,
    props<{
        organizationId: string;
        bundleId: string;
        permissions: any;
    }>()
);

export const GetBundleRolePermissions = createAction(
    actionTypes.GET_BUNDLE_ROLE_PERMISSIONS,
    props<{
        bundleId: string;
        roleId: string;
    }>()
);

export const SetBundleRolePermissions = createAction(
    actionTypes.SET_BUNDLE_ROLE_PERMISSIONS,
    props<{
        organizationId: string;
        bundleId: string;
        roleId: string;
        rolePermissions: string[];
    }>()
);

export const UpdateBundleRolePermissions = createAction(
    actionTypes.UPDATE_BUNDLE_ROLE_PERMISSIONS,
    props<{
        bundleId: string;
        permissions: any;
        actionType?: string;
    }>()
);

export const BundleRoleCrudOperataions = createAction(
    actionTypes.BUNDLE_ROLE_CRUD_OPERATIONS,
    props<{
        msg: string;
        actionType: string;
    }>()
);

export const GetRolePermissions = createAction(
    actionTypes.GET_ROLE_PERMISSIONS,
    props<{
        role: any;
    }>()
);

export const SetRolePermissions = createAction(
    actionTypes.SET_ROLE_PERMISSIONS,
    props<{
        organizationId: string;
        roleId: string;
        rolePermissions: string[];
    }>()
);

export const GetAllRolePermissions = createAction(actionTypes.GET_ALL_ROLE_PERMISSIONS);

export const SetAllRolePermissions = createAction(
    actionTypes.SET_ALL_ROLE_PERMISSIONS,
    props<{
        organizationId: string;
        permissions: string[];
    }>()
);
