import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilingAttributeField } from '../../models/filingattributefield.class';

@Component({
    selector: 'filingattribute-field',
    templateUrl: './filingattributefield.component.html',
})
export class FilingAttributeFieldComponent {
    @Input() field: FilingAttributeField;
    @Input() service: any;
    @Input() bridge: any;
    @Input() classList: any;
    @Input() type: any;
    @Input() record: FilingAttributeField[];

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();

    constructor() {}

    dateFormatCap = (outputFormat) => {
        return outputFormat.toUpperCase();
    };

    getConditionValue = () => {
        let value;
        if (this.field.lookupConditionExpressions?.length > 0) {
            const conditionalFieldId = this.field.lookupConditionExpressions[0]?.lookupConditionField;
            const conditionalField = this.record.find((field) => field.id === conditionalFieldId);
            value = conditionalField?.value;
        }
        return value;
    };
}
