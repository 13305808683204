import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
    months = {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mar',
        '04': 'Apr',
        '05': 'May',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
    };

    monthsFull = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December',
    };

    parse(value: any): Date | null {
        if (typeof value === 'string' && value.indexOf('/') > -1) {
            const str = value.split('/');
            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    format(date: Date, displayFormat: Object): string {
        const day = date.getDate();
        const dateString = day < 10 ? '0' + day : day;
        const month = date.getMonth() + 1;
        const monthString = month < 10 ? '0' + month : '' + month;
        const year = date.getFullYear();
        let output: string;
        const monthShort = this.months[monthString];
        switch (displayFormat) {
            case 'default':
                output = `${dateString}/${monthString}/${year}`;
                break;
            case 'DD MMM YYYY':
                output = `${dateString} ${monthShort} ${year}`;
                break;
            case 'DD-MMM-YYYY':
                output = `${dateString}-${monthShort}-${year}`;
                break;
            case 'dd/yyyy/MM':
                output = `${dateString}/${year}/${monthString}`;
                break;
            case 'MM/dd/yyyy':
                output = `${monthString}/${dateString}/${year}`;
                break;
            case 'yyyy-MM-dd':
                output = `${year}-${monthString}-${dateString}`;
                break;
            case 'ddMMyyyy':
                output = `${dateString} ${monthString} ${year}`;
                break;
            case 'dd-mm-yyyy':
            case 'dd-MM-yyyy':
                output = `${dateString}-${monthString}-${year}`;
                break;
            case 'dd-yyyy-MM':
                output = `${dateString}-${year}-${monthString}`;
                break;
            case 'MM-yyyy-dd':
                output = `${monthString}-${year}-${dateString}`;
                break;
            case 'MM/yyyy/dd':
                output = `${monthString}/${year}/${dateString}`;
                break;
            case 'MM-dd-yyyy':
                output = `${monthString}-${dateString}-${year}`;
                break;
            case 'yyyy/mm/dd':
            case 'yyyy/MM/dd':
                output = `${year}/${monthString}/${dateString}`;
                break;
            case 'yyyy-dd-MM':
                output = `${year}-${dateString}-${monthString}`;
                break;
            case 'yyyy/dd/MM':
                output = `${year}/${dateString}/${monthString}`;
                break;
            case 'yyyyMMdd':
                output = `${year} ${monthString} ${dateString}`;
                break;
            default:
                output = date.toDateString();
                break;
        }
        return output;
    }

    getDayOfWeekNames(): string[] {
        const SHORT_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return SHORT_NAMES;
    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'default',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};
