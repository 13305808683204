<div class="main-side-nav" (appAppDetectOutsideClick)="removeSelectedTab()" *transloco="let translate">
    <div class="logo-container" routerLink="/organizations/{{currentOrganizationId}}/category">
        <ng-container *ngIf="!imgSrc">
            <img src="/assets/images/new-ui/taxilla_logo.png" alt="{{translate('Taxilla Logo')}}" />
        </ng-container>
        <ng-container *ngIf="imgSrc?.length > 0">
            <img src="data:image/jpeg;base64,{{imgSrc}}" alt="{{translate('Organization')}}">
        </ng-container>
    </div>
    <div class="leftNavMap">
        <ng-container *ngFor="let leftNav of leftNavMap">
            <ng-container *ngTemplateOutlet="isInEnComply ? navTemplate : leftnavTemplate; context: { leftNav: leftNav }"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #navTemplate let-leftNav="leftNav">
    <div class="nav-items">
        <div class="nav-item" [class.selected]="activeTab === leftNav.id" (click)="navigate(leftNav.id)">
            <div class="items-wrapper" *transloco="let translate">
                <div class="img-container" *ngIf="leftNav.src">
                    <img class="image-white" [hidden]="activeTab !== leftNav.id" [ngClass]="activeTab === leftNav.id ? 'block' : 'hidden'" src={{leftNav.src}} alt="{{translate('Leftnav Icons')}}" />
                    <img class="image-black" [hidden]="activeTab === leftNav.id" src={{leftNav.srcHover}} alt="{{translate('Leftnav Icons')}}" />
                </div>
                <div class="img-container" *ngIf="leftNav.icon">
                    <span class="material-symbols-outlined">
                        {{leftNav.icon}}
                    </span>
                </div>
                <div class="nav-title">
                    <p>{{leftNav.name}}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #leftnavTemplate let-leftNav="leftNav">
    <div class="nav-items">
        <div mat-button class="nav-item fulwidthmenu" [class.selected]="activeTab === leftNav.id" [class.popup]="selectedTab === leftNav.id" [class.appCurtainOpened]="matAppsMenuTrigger.menuOpen" [matMenuTriggerFor]="appsMenu" #matAppsMenuTrigger="matMenuTrigger" (click)='getApps(leftNav)'>
            <div class="items-wrapper" *transloco="let translate">
                <div class="img-container" *ngIf="leftNav.src">
                    <img class="image-white" [hidden]="activeTab !== leftNav.id" [ngClass]="activeTab === leftNav.id ? 'block' : 'hidden'" src={{leftNav.src}} alt="{{translate('Leftnav Icons')}}" />
                    <img class="image-black" [hidden]="activeTab === leftNav.id" src={{leftNav.srcHover}} alt="{{translate('Leftnav Icons')}}" />
                </div>
                <div class="img-container" *ngIf="leftNav.icon">
                    <span class="material-symbols-outlined">
                        {{leftNav.icon}}
                    </span>
                </div>
                <div class="nav-title">
                    <p>{{leftNav.name}}</p>
                </div>
                <span class="material-icons defaultLeftIcon">arrow_left</span>
            </div>
        </div>
        <mat-menu #appsMenu="matMenu" class="fulWidthMatMenu appsMenu leftNavMenu" backdropClass="header-sub-menu" [overlapTrigger]="false">
            <div (click)="$event.stopPropagation()" *transloco="let translate;">
                <mat-card>
                    <mat-card-content class="appsMenuContent">
                        <ng-container *ngTemplateOutlet="appsMenuTemplate; context: { leftNav: leftNav, trigger: matAppsMenuTrigger }"> </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-menu>
    </div>
</ng-template>

<ng-template #appsMenuTemplate let-leftNav="leftNav" let-trigger="trigger">
    <app-categories (bridgeDialogOpen)="isBridgeDialogOpen = $event" (closeMenu)="closeMatmenu(trigger)" [selectedLeftNav]="leftNav"></app-categories>
</ng-template>
