import { createAction, props } from '@ngrx/store';

import { SessionState, WidgetState } from '../states';

export enum widgetTranslationsActionTypes {
    GET_WIDGET_TRANSLATIONS = '[WIDGETS] Get Widget Translations',
    SET_WIDGET_TRANSLATIONS = '[WIDGET] Set Widget Translations',
    GET_WIDGET_CRITERIA = '[WIDGET] Get Widget Search Criteria',
}

export const GetWidgetTranslations = createAction(
    widgetTranslationsActionTypes.GET_WIDGET_TRANSLATIONS,
    props<{
        widgetId: string;
    }>()
);

export const SetWidgetTranslations = createAction(
    widgetTranslationsActionTypes.SET_WIDGET_TRANSLATIONS,
    props<{
        widgetId: string;
        translations: WidgetState['widgetTranslations']['key']['key'];
        language: SessionState['activeLanguage'];
    }>()
);

export const GetWidgetCriteria = createAction(
    widgetTranslationsActionTypes.GET_WIDGET_CRITERIA,
    props<{
        chainRestApiName: string;
        sourceName: string;
        searchParams: { [property: string]: string };
    }>()
);
