import { Rule } from '../rule.class';
import { TableDefinition } from './tabledefinition.class';

export class MasterMetaData {
    keyColumns: string[];

    name: string;

    relatedMasterNames: any[];

    relatedMasters: any[];

    tableDefinition: TableDefinition;

    uid: string;

    tags: any[];

    parentTableId: string;

    parentTableName: string;

    restAPIName: string;

    rules: Rule[];

    description: string;

    constructor(data) {
        this.keyColumns = data?.keyColumns;
        this.name = data?.name || data?.displayName;
        this.relatedMasterNames = data?.relatedMasterNames;
        this.relatedMasters = data?.relatedMasters;
        this.uid = data?.uid;
        this.tags = data?.tags;
        this.parentTableId = data?.parentTableId;
        this.parentTableName = data?.parentTableName;
        this.restAPIName = data?.restAPIName;
        this.rules = data?.rules || [];
        this.description = data?.description;
        this.tableDefinition = new TableDefinition(data?.tableDefinition || {}, data?.keyColumns || [], this.rules);
    }

    applyLanguageAlterations = (template: MasterLanguageTemplate) => {
        this.name = template?.displayName || this.name;
        this.description = template?.description;
        template?.columnDefinitions?.forEach((definition) => {
            const columnDefinition = this.tableDefinition.columnDefinitions.find((item) => item.id === definition.uid);
            columnDefinition.displayName = definition?.displayName || columnDefinition.displayName;
            columnDefinition.name = definition?.name || columnDefinition.name;
            columnDefinition.description = definition?.description || columnDefinition.description;
        });
        template?.rules?.forEach((rule) => {
            const columnRule = this.rules.find((item) => item.uid === rule.uid);
            columnRule.name = rule?.name || columnRule.name;
            columnRule.failureMessage = rule?.failureMessage || columnRule.failureMessage;
        });
    };
}

export interface MasterLanguageTemplate {
    uid: string;

    name: string;

    displayName: string;

    description: string;

    columnDefinitions: {
        uid: string;
        name: string;
        displayName: string;
        description: string;
    }[];
    rules: {
        uid: string;
        name: string;
        failureMessage: string;
    }[];
}
