import { createAction, props } from '@ngrx/store';
import { UIReport } from 'taxilla-library';

enum reportTypes {
    GET_REPORTS = '[REPORTS] Get Reports',
    SET_REPORTS = '[REPORTS] Set Reports',
    GENERATE_REPORT = '[REPORTS] Generate Reports',
    GENERATED_REPORT = '[REPORTS] Generated Report',
    GENERATED_REPORT_ERROR = '[REPORTS] Generated Report Error',
    LOADING_REPORTS = '[REPORTS] Set Loading',
}

export const GetReports = createAction(
    reportTypes.GET_REPORTS,
    props<{
        instanceId: string;
        serviceId: string;
        assetId: string;
        forceFetch?: boolean;
        noAlerts: boolean;
    }>()
);

export const SetReports = createAction(
    reportTypes.SET_REPORTS,
    props<{
        instanceId: string;
        reports: UIReport[];
    }>()
);

export const GenerateReport = createAction(
    reportTypes.GENERATE_REPORT,
    props<{
        instanceId: string;
        serviceId: string;
        repositoryId: string;
        chainName: UIReport['chains']['0']['name'];
        transformationName?: string;
        noAlerts: boolean;
        timeStamp: number;
    }>()
);

export const GeneratedReport = createAction(
    reportTypes.GENERATED_REPORT,
    props<{
        instanceId: string;
        serviceId: string;
        repositoryId: string;
        chainName: UIReport['chains']['0']['name'];
        transformationName: string;
        timeStamp: number;
    }>()
);

export const GenerateReportError = createAction(
    reportTypes.GENERATED_REPORT_ERROR,
    props<{
        instanceId: string;
        transformationName?: string;
    }>()
);
export const LoadingReports = createAction(
    reportTypes.LOADING_REPORTS,
    props<{
        instanceId: string;
        loading: boolean;
    }>()
);
