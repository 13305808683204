import { Address } from './address.class';
import { FlexFields } from './flexfields.class';

// importing classes
export class Organization {
    readonly id: string;

    name: string;

    nickName: string;

    pan?: string;

    enabled: boolean;

    orgLoginId: string;

    // readonly repositoryId: string;

    readonly providerOrganizationId: string;

    readonly vdmAccess: boolean;

    addresses: Array<Address>;

    flexFields: Array<FlexFields>;

    organizationTypes: string;

    parentId: string;

    type: string;

    requestedBy: string;

    parent?: Organization;

    childOrganizations?: { [property: string]: Organization };

    providerUrl?: string;

    [property: string]: any;

    createdDate?: string;

    lastModifiedDate?: string;

    hidden?: boolean;

    timeZone?: string;

    numberOfLastPasswords?: number;

    nextNodes: string[];

    displayTenantName?: string;

    expirePasswordInDays?: any;

    workQueueEnabled?: boolean;

    selected?: boolean;

    baseRegion: string;

    constructor(organizationObject) {
        if (organizationObject?.id) {
            this.id = organizationObject['id'];
        }
        if (organizationObject?.name) {
            this.name = organizationObject['name'];
        }
        if (organizationObject?.nickName) {
            this.nickName = organizationObject.nickName;
        }
        if (organizationObject?.pan) {
            this.pan = organizationObject.pan;
        }
        if (organizationObject?.enabled) {
            this.enabled = organizationObject['enabled'];
        }
        if (organizationObject?.orgLoginId) {
            this.orgLoginId = organizationObject['orgLoginId'];
        }
        // if (organizationObject?.repositoryId) {
        //     this.repositoryId = organizationObject['repositoryId'];
        // }
        if (organizationObject?.requestedBy) {
            this.requestedBy = organizationObject['requestedBy'];
        }
        if (organizationObject?.providerOrganizationId) {
            this.providerOrganizationId = organizationObject.providerOrganizationId;
        }
        if (organizationObject && typeof organizationObject.vdmAccess === 'boolean') {
            this.vdmAccess = organizationObject['vdmAccess'];
        }
        if (organizationObject?.parent) {
            this.parent = new Organization(organizationObject.parent);
        }
        if (organizationObject?.createdDate) {
            this.createdDate = organizationObject['createdDate'];
        }
        if (organizationObject?.lastModifiedDate) {
            this.lastModifiedDate = organizationObject['lastModifiedDate'];
        }
        if (organizationObject?.pan) {
            this.pan = organizationObject['pan'];
        }
        if (organizationObject?.providerUrl) {
            this.providerUrl = organizationObject.providerUrl;
        }
        this.addresses = [];
        if (organizationObject && organizationObject['addresses']) {
            for (let i = 0; i < organizationObject['addresses'].length; i++) {
                this.addresses.push(new Address(organizationObject['addresses'][i]));
            }
        }
        this.flexFields = [];
        if (organizationObject?.flexFields?.length) {
            for (let i = 0; i < organizationObject['flexFields'].length; i++) {
                this.flexFields.push(new FlexFields(organizationObject['flexFields'][i]));
            }
        }
        if (organizationObject?.organizationTypes) {
            this.organizationTypes = organizationObject.organizationTypes;
        } else {
            this.organizationTypes = 'CUSTOMER';
        }
        if (organizationObject?.parentId) {
            this.parentId = organizationObject.parentId;
        } else if (organizationObject?.parent && organizationObject.parent.id) {
            this.parentId = organizationObject.parent.id;
        }
        if (organizationObject?.hidden !== undefined && organizationObject?.hidden !== null) {
            this.hidden = organizationObject.hidden;
        }
        if (organizationObject?.timeZone) {
            this.timeZone = organizationObject.timeZone;
        }
        if (organizationObject?.baseRegion) {
            this.baseRegion = organizationObject.baseRegion;
        }
        this.type = organizationObject?.type !== undefined ? organizationObject.type : 'envoice';
        this.numberOfLastPasswords = organizationObject?.numberOfLastPasswords || 5;
        this.expirePasswordInDays = organizationObject?.expirePasswordInDays;
        this.workQueueEnabled = organizationObject?.workQueueEnabled;
    }

    static pushOrganizationsToArray = (organizationsArray: Array<Organization>, newElements: Array<Organization> | Organization) => {
        if (Array.isArray(newElements)) {
            for (let i = 0; i < newElements.length; i++) {
                Organization.pushOrUpdateOrganization(organizationsArray, newElements[i]);
            }
        } else {
            Organization.pushOrUpdateOrganization(organizationsArray, newElements);
        }
    };

    static pushOrUpdateOrganization = (organizationsArray: Array<Organization>, org: Organization) => {
        let found = false;
        for (let i = 0; i < organizationsArray.length; i++) {
            if (organizationsArray[i].id === org.id) {
                found = true;
                organizationsArray[i] = new Organization(org);
                break;
            }
        }
        if (!found) {
            organizationsArray.push(new Organization(org));
        }
    };

    getFlexValue = (fieldName) => FlexFields.getFlexValue(this.flexFields, fieldName);

    getFlexIndex = (fieldName) => FlexFields.getFlexIndex(this.flexFields, fieldName);

    getAddressByType = (addressType) => Address.getAddressByType(this.addresses, addressType);

    pushAddress = (address: Address) => Address.pushAddress(this.addresses, address);

    deleteAddress = (address: Address) => Address.deleteAddress(this.addresses, address);

    getOrgName = () => {
        return this.parent && this.parent.parent ? this.parent.parent.name : this.parent ? this.parent.name : this.name;
    };

    getStateName = () => {
        return this.parent && this.parent.parent ? this.parent.name : this.parent ? this.name : null;
    };

    getCityName = () => {
        return this.parent && this.parent.parent ? this.name : null;
    };
}

export class OrganizationData extends Organization {
    nextNodes: string[];

    displayTenantName?: string;

    constructor(data) {
        super(data);
    }
}
