import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-help-doc-preview',
    templateUrl: './help-doc-preview.component.html',
    styleUrls: ['./help-doc-preview.component.scss'],
})
export class HelpDocPreviewComponent implements OnInit {
    helpDoc: {
        fileUrl: any;
        fileName: string;
    } = {
        fileUrl: this._sanitizer.bypassSecurityTrustResourceUrl(''),
        fileName: '',
    };

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public docData: {
            blob: Blob;
            fileName: string;
        },
        private _sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        this.constructAndSanitizeUrl();
    }

    constructAndSanitizeUrl = () => {
        this.helpDoc.fileName = this.docData?.fileName || '';
        const url = this.docData?.blob && window.URL.createObjectURL(this.docData.blob);
        if (url?.length) {
            this.helpDoc.fileUrl = url && this._sanitizer.bypassSecurityTrustResourceUrl(url);
        }
    };
}
