<div class="d-flex justify-content-between align-items-center">
    <h2 mat-dialog-title>{{helpDoc?.fileName}} Preview</h2>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography" *transloco="let translate">
    <iframe [src]='helpDoc?.fileUrl' title="{{translate('Help Document')}}" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen>
    </iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
