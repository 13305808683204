import { Injectable } from '@angular/core';

import {
    CriteriaDefinitionInterface,
    ElasticFieldRule,
    ElasticFieldRules,
    EntityFilterCriteria,
    QueryInterface,
    QueryRuleInterface,
} from '../../models/query.interface';

@Injectable({
    providedIn: 'root',
})
export class QueryBuilderService {
    constructor() {}

    transformElasticRules = (query: ElasticFieldRules, serviceId: string, restAPIName: string) => {
        const entityId = query.rules && query.rules[0].entity && query.rules[0].entity.uid;
        return this.generateQuery(entityId, query.condition, query.rules, serviceId, restAPIName);
    };

    protected generateQuery = (
        entityId: string,
        condition: ElasticFieldRules['condition'],
        rules: ElasticFieldRule[],
        serviceId: string,
        restAPIName: string
    ) => {
        const criteria: QueryInterface = {
            restApiServiceName: restAPIName,
            entityId: entityId,
            filterCriteria: {
                serviceId: serviceId,
                entityFilterCriterias: [],
            },
        };
        this.addFilterCriteria(criteria.filterCriteria.entityFilterCriterias, condition, entityId, rules);
        return criteria;
    };

    protected addFilterCriteria = (
        criterias: EntityFilterCriteria[],
        condition: ElasticFieldRules['condition'],
        entityId: string,
        rules: ElasticFieldRule[]
    ) => {
        const critera: EntityFilterCriteria = {
            entityUid: entityId,
            criteriaDefinitions: [],
        };
        this.addCriteriaLevel(critera.criteriaDefinitions, entityId, condition, rules);
        criterias.push(critera);
    };

    protected addCriteriaLevel = (
        criteriaDefinitions: CriteriaDefinitionInterface[],
        entityId: string,
        condition: ElasticFieldRules['condition'],
        rules: ElasticFieldRule[]
    ) => {
        const definition: CriteriaDefinitionInterface = {
            criteriaName: entityId,
            type: 'CriteriaDefinition',
            restriction: undefined,
        };
        this.checkRuleRestrictedType(definition, condition, rules);
        criteriaDefinitions.push(definition);
    };

    protected checkRuleRestrictedType = (
        criteriaDefinition: CriteriaDefinitionInterface,
        ruleCategory: ElasticFieldRules['condition'],
        rules: ElasticFieldRule[]
    ) => {
        if (!criteriaDefinition.restriction) {
            criteriaDefinition.restriction = {
                condition: this.getRestrictionCondition(ruleCategory),
                rules: [],
                restrictionType: 'JoinedRestrictionMetadata',
            };
        }
        rules.forEach((rule) => {
            this.pushRule(criteriaDefinition.restriction.rules, rule.field, undefined, rule.value);
        });
    };

    protected getRestrictionCondition = (ruleCategory: ElasticFieldRules['condition']) => {
        let category: 'AND' | 'OR';
        switch (ruleCategory) {
            case 'and':
                category = 'AND';
                break;
            default:
                throw new Error('Rule Category not found');
                break;
        }
        return category;
    };

    protected pushRule = (rules: QueryRuleInterface[], fieldId: string, operatorType: string, value: ElasticFieldRule['value']) => {
        if (!operatorType) {
            switch (typeof value) {
                case 'object':
                    operatorType = Array.isArray(value) ? 'IN' : 'EQ';
                    break;
                default:
                    operatorType = 'EQ';
                    break;
            }
        }
        rules.push({
            fieldId: fieldId,
            operator: operatorType,
            value: value,
            restrictionType: 'SingleRestrictionMetadata',
        });
    };
}
