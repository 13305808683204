<ng-container *transloco="let translate">
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('Change Password')}}</h4>
        <span class="material-icons mb-1" mat-dialog-close (click)="closeModal()">close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody">
        <form name="changepassword" autocomplete="off">
            <div class="row">
                <div class="col-6 passwordInfo">
                    <material-input-password [autofocus]="true" [placeholder]="translate('Enter current password')" [title]="translate('Enter current password')" [model]="passwords.oldPassword" [debounceAfter]="1000" [errors]="errors.oldPassword" (modelChange)="passwords.oldPassword = $event;errors.oldPassword = []" [required]="true">
                    </material-input-password>
                </div>
            </div>
            <div class="row">
                <div class="col-6 passwordInfo">
                    <material-input-password [autofocus]="false" [placeholder]="translate('Enter new password')" [title]="translate('Enter new password')" [model]="passwords.newPassword" [debounceAfter]="1000" [errors]="errors.newPassword" (modelChange)="passwords.newPassword = $event;errors.newPassword=[]" [required]="true">
                    </material-input-password>
                </div>
                <div class=" col-6 passwordInfo">
                    <material-input-password [autofocus]="false" [placeholder]="translate('Re-enter new password')" [title]="translate('Re-enter new password')" [model]="passwords.confirmPassword" [debounceAfter]="1000" [errors]="errors.confirmPassword" (modelChange)="passwords.confirmPassword = $event;errors.confirmPassword=[]" [required]="true">
                    </material-input-password>
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="matDialogFooter noBorder">
        <material-button class='mx-2 noOutline' [label]="translate('Close')" color="default" (onTrigger)="closeModal()"></material-button>
        <material-button class='noOutline submitColor' [label]="translate('Update')" color="primary" (onTrigger)="submitPassword()"></material-button>
    </mat-dialog-actions>
</ng-container>
