import { WorkFlow } from '../../models/workflow.class';

export const workflowState: WorkflowState = {
    instanceIdVsWorkflowState: {},
    loading: {},
};

export interface WorkflowState {
    instanceIdVsWorkflowState: {
        [property: string]: WorkFlow[];
    };
    loading: {
        [property: string]: boolean;
    };
}
