import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, CommonUtilsService } from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private _api: ApiService, private _commonUtils: CommonUtilsService, private _router: Router) {}

    public checkSession = (f?: () => void) => {
        return new Promise((resolve, reject) => {
            this._api.session
                .getSessionDetails({
                    noAlerts: true,
                })
                .subscribe({
                    next(value) {
                        resolve(true);
                    },
                    error(err) {
                        resolve(false);
                    },
                });
        });
    };

    public redirect = () => {
        const redirectURL = this._commonUtils.getFromStorage('successRedirect');
        if (redirectURL !== 'undefined' && redirectURL !== undefined) {
            const queryParams =
                (redirectURL.indexOf('?') > 0 ? '&' : '?') +
                `clientId=${encodeURIComponent(this._commonUtils.getFromStorage('clientId'))}&stateId=${encodeURIComponent(
                    this._commonUtils.getFromStorage('stateId')
                )}`;
            this._commonUtils.redirectToPage(`${redirectURL}${queryParams}`);
        } else {
            if (redirectURL !== 'undefined' && redirectURL !== undefined) {
                window.location.href = `${redirectURL}?clientId=${encodeURIComponent(
                    this._commonUtils.getFromStorage('clientId')
                )}&stateId=${encodeURIComponent(this._commonUtils.getFromStorage('stateId'))}`;
            } else {
                this._router.navigate(['']);
            }
        }
    };

    public setSAMLRoute = (url: string, params: { [property: string]: string }) => {
        const formElement = this.createFormElements(url, params);
        formElement.submit();
    };

    private createFormElements = (url: string, params: { [property: string]: string }) => {
        const formElement = document.createElement('form');
        formElement.method = 'POST';
        formElement.action = url;
        formElement.setAttribute('class', 'hidden');
        Object.keys(params).forEach((param) => {
            const paramElement = document.createElement('input');
            paramElement.name = param;
            paramElement.value = params[param];
            formElement.appendChild(paramElement);
        });
        document.body.appendChild(formElement);
        return formElement;
    };
}
