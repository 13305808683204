import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PERMISSIONS_REDUCER_KEY } from '../reducers';
import { PermissionsState } from '../states';
import { getSelectedBundleId$ } from './bundles.selector';
import { getSelectedRoleId$ } from './roles.selector';
import { getCurrentOrganizationId$ } from './session.selector';

const state = createFeatureSelector<PermissionsState>(PERMISSIONS_REDUCER_KEY);

const permissions$ = createSelector(state, (state) => state.organizationsMap);

const getCurrentOrganizationPermissions$ = createSelector(permissions$, getCurrentOrganizationId$, (orgsMap, orgId) => orgsMap?.[orgId]);

export const getSessionUserPermissions$ = createSelector(getCurrentOrganizationPermissions$, (state) => state?.sessionUserPermissions);

export const getBundlePermissions$ = createSelector(
    getCurrentOrganizationPermissions$,
    getSelectedBundleId$,
    (state, bundleId) => state?.bundlesMap?.[bundleId]?.bundlePermissions
);

export const getBundleRolePermissions$ = createSelector(
    getCurrentOrganizationPermissions$,
    getSelectedBundleId$,
    getSelectedRoleId$,
    (state, bundleId, roleId) => state?.bundlesMap?.[bundleId]?.bundleRolePermissions?.[roleId]
);

export const getRolePermissions$ = createSelector(
    getCurrentOrganizationPermissions$,
    getSelectedRoleId$,
    (state, roleId) => state?.rolePermissions?.[roleId]
);

export const getAllRolePermissions$ = createSelector(getCurrentOrganizationPermissions$, (state) => state?.allRolePermissions);
