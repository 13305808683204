import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-users-view',
    templateUrl: './users-view.component.html',
    styleUrls: ['./users-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersViewComponent {
    @Input() users: any[];
    @Input() searchUser: string;
    @Input() noAssignUsers: boolean;
    @Input() allUsersSelected: boolean;
    @Input() selectedUser: any;
    @Input() radioDisabled: boolean;
    @Input() errors: {
        org: string[];
        user: string[];
    };

    @Output() searchUserChange = new EventEmitter();
    @Output() clearSearchUsers = new EventEmitter();
    @Output() searchAssignUsers = new EventEmitter();
    @Output() selectAllUsers = new EventEmitter();
    @Output() checkUsersList = new EventEmitter();
}
