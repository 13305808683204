<div class="notificationsContentContainer min-h-[37vw]" *transloco="let translate">
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title style="
        margin-left: 10px;">{{translate('Notifications')}}</h4>
        <span class="material-icons mb-1" mat-dialog-close (click)="closeModal()">close</span>
    </div>
    <mat-dialog-content class="mat-typography">
        <app-common-notifications-eventlogs [resourceId]="data?.resourceId" [notificationsState]="notificationsState"></app-common-notifications-eventlogs>
    </mat-dialog-content>
</div>
