import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const bundleAdapters: { [property: string]: EntityAdapter<any> } = {
    organizations: createEntityAdapter<BundlesOrganizationEntity>(),
};

export const bundlesState: BundlesState = {
    currentOrganizationId: undefined,
    searchText: undefined,
    selectedBundleIds: [],
    organizations: bundleAdapters.organizations.getInitialState(),
    selectedBundleId: undefined,
    bundleInvitations: {},
};

export interface BundlesState {
    currentOrganizationId: string;
    searchText: string;
    organizations: EntityState<BundlesOrganizationEntity>;
    selectedBundleIds: string[];
    selectedBundleId: string;
    bundleInvitations: {
        [property: string]: Invitation[];
    };
}

export interface BundlesOrganizationEntity {
    id: string;
    bundles: EntityState<Bundle>;
}

export interface Bundle {
    createdByUserId: string;
    createdDate: string;
    description: string;
    id: string;
    lastModifiedByUserId: string;
    lastModifiedDate: string;
    name: string;
    unitId: string;
}

export interface Invitation {
    context: string;
    contextType: string;
    createdByUserId: string;
    createdDate: string;
    id: string;
    lastModifiedByUserId: string;
    lastModifiedDate: string;
    organizationId: string;
    partnerId: string;
    partnerRef: string;
    status: string;
    type: string;
}
