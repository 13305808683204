import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DASHBOARD_REDUCER_KEY } from '../reducers';
import { DashboardState } from '../states';
import { getCurrentOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<DashboardState>(DASHBOARD_REDUCER_KEY);

export const getSelectedDashboard$ = createSelector(currentState, (state) => state.selectedDashboard);

export const getAnalyticsDashboards$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, currentOrgId) => state?.analyticDashboards?.[currentOrgId]?.dashboards
);

export const getDashboardLoader$ = createSelector(currentState, (state) => state.isDashboardsLoading);
