import { createAction, props } from '@ngrx/store';

import { Request } from '../../interface/request.interface';
import { ReconciliationRequest } from '../../models/reconciliation-request.class';
import { Transformation } from '../../models/transformation';

enum requestTypes {
    CREATE_REQUEST_VIA_UPLOAD = '[REQUEST] Create Request Via Upload',
    CREATE_REQUEST_MANUALLY = '[REQUEST] Create Request Manually',
    CREATE_RECON_REQUEST = '[REQUEST] Create Recon Request',
    OPEN_RECON_REQUEST = '[REQUEST] Open Recon Request',
    REQUEST_CREATED = '[REQUEST] Created Request',
    REQUEST_DETAILS = '[REQUEST] Get Request',
    GET_FILING_REQUESTS = '[REQUEST] Get Filing Requests',
    SET_SEARCHED_REQUESTS = '[REQUEST] Set Searched Requests',
    GET_RECON_REQUESTS = '[REQUEST] Get Recon Requests',
    SET_RECON_REQUESTS = '[REQUEST] Set Recon Requests',
    CLEAR_RECON_REQUESTS = '[REQUEST] Clear Recon Requests',
    CLEAR_REQUESTS = '[REQUEST] Clear Requests',
    GET_RECON_TEMPLATES = '[REQUEST] Get Recon Templates',
    SET_RECON_TEMPLATES = '[REQUEST] Set Recon Templates',
    CREATE_REQUEST_VIA_SEARCH = '[REQUEST] Create Request Via Search',
    SET_LOADING = '[REQUEST] Set Loading',
    GET_UPLOADED_FILES = '[REQUEST] Get Uploaded Files',
    SET_UPLOADED_FILES = '[REQUEST] Set Uploaded Files',
    CREATE_REPORT_REQUEST = '[REQUEST] Create Report Request',
    DOWNLOAD_REPORT_REQUEST = '[REQUEST] Download Report Request',
    SET_REQUEST = '[REQUEST] Set Request',
    LOADING_BULK_REPORTS_ERROR = '[REQUEST] Loading Bulk Reports Error',
}

export const GetGstFilingRequests = createAction(
    requestTypes.GET_FILING_REQUESTS,
    props<{
        serviceId: string;
        forceFetch?: boolean;
        noAlerts?: boolean;
        timelineContextNotRequired?: boolean;
    }>()
);

export const CreateRequestViaUpload = createAction(
    requestTypes.CREATE_REQUEST_VIA_UPLOAD,
    props<{
        serviceId: string;
        transformation: Transformation;
    }>()
);

export const CreateRequestManually = createAction(
    requestTypes.CREATE_REQUEST_MANUALLY,
    props<{
        serviceId: string;
        formData: FormData;
        [property: string]: any;
        multipleCalls?: boolean;
    }>()
);

export const RequestCreated = createAction(
    requestTypes.REQUEST_CREATED,
    props<{
        serviceId: string;
        requestId: string;
        newRequest: boolean;
        [property: string]: any;
    }>()
);

export const SetRequest = createAction(
    requestTypes.SET_REQUEST,
    props<{
        requestResponse: any;
    }>()
);

export const SetRequests = createAction(
    requestTypes.SET_SEARCHED_REQUESTS,
    props<{
        organizationId: string;
        serviceId: string;
        requests: Request[];
        searchCriteria: string;
    }>()
);

export const GetReconRequests = createAction(
    requestTypes.GET_RECON_REQUESTS,
    props<{
        serviceId: string;
        fromDate: string;
        pagingState: string;
        restApiName: string;
        size: number;
        toDate: string;
        pushAtTop?: boolean;
        noAlerts?: boolean;
    }>()
);

export const SetReconRequests = createAction(
    requestTypes.SET_RECON_REQUESTS,
    props<{
        organizationId: string;
        serviceId: string;
        requests: ReconciliationRequest[];
        fromDate: string;
        toDate: string;
        pagingState: string;
        pushAtTop?: boolean;
    }>()
);

export const ClearReconRequests = createAction(
    requestTypes.CLEAR_RECON_REQUESTS,
    props<{
        organizationId: string;
        serviceId: string;
        criteria: string;
    }>()
);

export const ClearRequests = createAction(
    requestTypes.CLEAR_REQUESTS,
    props<{
        organizationId: string;
        serviceId: string;
        criteria: string;
    }>()
);

export const GetReconTemplates = createAction(
    requestTypes.GET_RECON_TEMPLATES,
    props<{
        serviceId: string;
        forceFetch?: boolean;
        noAlerts: boolean;
    }>()
);

export const SetReconTemplates = createAction(
    requestTypes.SET_RECON_TEMPLATES,
    props<{
        serviceId: string;
        organizationId: string;
        templates: any[];
    }>()
);

export const CreateReconRequest = createAction(
    requestTypes.CREATE_RECON_REQUEST,
    props<{
        serviceId: string;
        formData: FormData;
    }>()
);

export const OpenReconRequest = createAction(
    requestTypes.OPEN_RECON_REQUEST,
    props<{
        serviceId: string;
        requestId: string;
    }>()
);

export const CreateRequestViaSearch = createAction(
    requestTypes.CREATE_REQUEST_VIA_SEARCH,
    props<{
        serviceId: string;
        payload: FormData;
        transformationName: string;
    }>()
);

export const SetRequestLoading = createAction(
    requestTypes.SET_LOADING,
    props<{
        loading: boolean;
        organizationId: string;
        serviceId: string;
        category: 'templates' | 'requests' | 'uploadedFiles';
    }>()
);

export const GetUploadedFile = createAction(
    requestTypes.GET_UPLOADED_FILES,
    props<{
        serviceId: string;
        requestId: string;
        noAlerts?: boolean;
    }>()
);

export const SetUploadedFile = createAction(
    requestTypes.SET_UPLOADED_FILES,
    props<{
        requestId: string;
        fileObject: {
            fileName: string;
            fileLink: string;
        };
    }>()
);

export const CreateReportRequest = createAction(
    requestTypes.CREATE_REPORT_REQUEST,
    props<{
        serviceId: string;
        defaultFilterName?: string;
        filterCriteria?: any;
        reportName: string;
        repositoryId: string;
        generateCompleteInstanceReport: boolean;
    }>()
);

export const DownloadReportRequest = createAction(
    requestTypes.DOWNLOAD_REPORT_REQUEST,
    props<{
        serviceId: string;
        downloadUrl: string;
        requestId: string;
    }>()
);

export const LoadingBulkReportsError = createAction(requestTypes.LOADING_BULK_REPORTS_ERROR);
