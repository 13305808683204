import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';

import { CommonUtilsService } from '../../../services/commonutils/common-utils.service';

@Component({
    selector: 'material-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class MaterialCardComponent implements OnInit, AfterContentChecked {
    @Input() showAppCheckBox: boolean;
    @Input() asset: any;
    @Input() bridge: any;
    @Input() report: any;
    @Input() hideLinks: boolean;
    @Input() widgetsHomePage: string;
    @Input() isBundlePage: boolean = false;
    @Input() fromComponent: string;
    @Input() appServiceId: string;
    @Output() logChange = new EventEmitter<any>();
    @Output() getAssetDescription = new EventEmitter<any>();
    @Output() onInfoOut = new EventEmitter<any>();
    @Output() getHelpDocument = new EventEmitter<any>();
    @Output() downloadHelpDocument = new EventEmitter<any>();
    @Output() viewHelpDocument = new EventEmitter<any>();
    @Output() fetchBridgeAssets = new EventEmitter<any>();
    @Output() initiateSubscription = new EventEmitter<{ assets: any[]; individual: boolean }>();
    @Output() deleteBundle = new EventEmitter<any>();
    @Output() createBundle = new EventEmitter<any>();
    @Output() getInvitations = new EventEmitter<{ bundle: any; triggerOrigin: string }>();
    @Output() sendBundleInvite = new EventEmitter<any>();
    @Output() closeModal = new EventEmitter<any>();

    public currentOrgDetails: any;

    constructor(private _commonUtils: CommonUtilsService, public R: RootScope, private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.currentOrgDetails = this._commonUtils.getFromStorage('currentOrganization');
    }

    ngAfterContentChecked() {
        this.ref.detectChanges();
    }
}
