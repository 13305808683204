<app-downtime-active-message></app-downtime-active-message>
<div id="newRequestComponent" class="mat-elevation-z8">
    <div class="newRequestComponentHeader" *transloco="let translate;">
        <span class="createNewProcessSpan">{{translate('Create New Process')}}</span>
    </div>
    <perfect-scrollbar class="newRequestComponentBody" style="height: calc(100vh - 150px);">
        <div class="materialStepperDiv">
            <mat-horizontal-stepper labelPosition="bottom" #stepper [linear]="false" class="currentIndex-{{stepper.selectedIndex}}">
                <ng-template matStepperIcon="edit" let-index="index">
                    {{index + 1}}
                </ng-template>
                <mat-step>
                    <ng-container *transloco="let translate;">
                        <ng-template matStepLabel>{{translate('Define Pre-Capture Attributes')}}</ng-template>
                        <div class="preCaptureAttributesContainer">
                            <ng-container *ngIf="filingAttributeFields?.length > 0; else noFilingAtributes">
                                <div class="preCaptureAttributeFieldsContainer row">
                                    <ng-container *ngFor="let field of filingAttributeFields">
                                        <div class="col-3" *ngIf="!field.autoCalculate && field.show">
                                            <filingattribute-field [field]="field" [record]="filingAttributeFields" [service]="currentApp" [type]="'filingDate'" [bridge]="currentBridge"></filingattribute-field>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="filingAttributeSubmitButn">
                            <material-button [label]="translate('Cancel')" [color]="'default'" (onTrigger)="cancelEntity(false)"></material-button>
                            <material-button class='initiateBtnCls' [label]="translate('Initiate Process')" [color]="'primary'" *ngIf="(!transformations || (transformations && transformations.length === 0)) && (!assetToAssetTransformations || (assetToAssetTransformations && assetToAssetTransformations.length === 0)) && (!businessKeysRecord || (businessKeysRecord && businessKeysRecord.fields.length === 0)) " (onTrigger)="submitAttributeFields()"></material-button>
                            <material-button class='nextBtnCls' [label]="translate('Next: Add Data')" [color]="'default'" *ngIf="filingAttributeFields && filingAttributeFields.length > 0" (onTrigger)="validateFilingAttributeFields()"></material-button>
                            <material-button class='nextBtnCls' [label]="translate('Next: Add Data')" [color]="'default'" *ngIf="!filingAttributeFields || filingAttributeFields.length === 0" (onTrigger)="goToNextPage()"></material-button>
                        </div>
                    </ng-container>
                </mat-step>
                <mat-step>
                    <ng-container *transloco="let translate;">
                        <ng-template matStepLabel>{{translate('Add Data')}}</ng-template>
                        <div class="addDataHeader" *transloco="let translate;">
                            <span>{{translate('To initiate new process please use any one of the below options.')}}</span>
                        </div>
                        <mat-tab-group #inputTypeGroup [(selectedIndex)]="selectedIndex">
                            <mat-tab [label]="translate('Electronic Form')">
                                <div class="electronicForm row" *ngIf="businessKeysRecord && businessKeysRecord.fields && businessKeysRecord.fields.length > 0;else noBusinessKeys">
                                    <ng-container *ngFor="let field of businessKeysRecord.fields">
                                        <div class="col-3" *ngIf="field.isBusinessKey && !field.autoCalculate">
                                            <div class="formFieldContainer">
                                                <ng-container *ngIf="field">
                                                    <app-field-input [enableField]="true" [field]="field" [record]="businessKeysRecord" (modelChange)="field.value = $event" [service]="currentApp" [currentOrganizationId]="currentOrganizationId" [bridge]="currentBridge" [masterEntityFieldValueMap]="masterEntityFieldValueMap" [fieldsMap]="fieldsMap">
                                                    </app-field-input>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                            <mat-tab [label]="translate('File Upload')">
                                <div class="trasformationListDiv">
                                    <material-selectgroup [required]="true" [options]="transformations" [placeholder]="translate('Select Transformation')" [model]="selectedTransformationName" [optionDisplayNameProperty]="'chainDisplayName'" [optionValueProperty]="'id'" (modelChange)="selectedTransformationName = $event;getSelectedTransformation()" [groupDisplayNameProperty]="'orgName'" [groupValueProperty]="'transformations'" [title]="selectedTransformationName" [noOptionsInstruction]="translate('No active transformations found')">
                                    </material-selectgroup>
                                </div>
                                <div *ngIf="selectedTransformationName?.length; else noTransformation" class="transformationTableDiv">
                                    <div class="sourceTableHeaderClass">
                                        <span>{{translate('Upload below files to initiate process')}}</span>
                                    </div>
                                    <div>
                                        <source-table [sourceParts]="sourceTableData" [sourceTableColumns]="sourceTableColumns" [hidePaginator]="hidePaginator" [primaryColumns]="primaryColumns" (downloadSourceTemplateUrl)="downloadSourceTemplateUrl($event)" (isFileUploaded)="scrollToBottm($event)"></source-table>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab [label]="translate('Pull from App')">
                                <div *ngIf="currentBridge?.restApiName && bridgeSourceNodes?.length" class="assetToAssetInBridgeCls">
                                    <div class="requestReviewDataContainer" *ngIf="bridgeSourceNodes?.length; else noTransformationFound">
                                        <div class="reviewPreCaptureHeader">
                                            {{translate('Source Names')}}:
                                        </div>
                                        <ng-container *ngFor="let sourceName of bridgeSourceNodes">
                                            <div class="requestReviewFieldContainer col-3">
                                                <span>{{sourceName.name}}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div *ngIf="!currentBridge || (currentBridge && !currentBridge.restApiName) || (currentBridge && currentBridge.restApiName && bridgeSourceNodes && bridgeSourceNodes.length === 0)">
                                    <div class="trasformationListDiv">
                                        <material-selectgroup [required]="true" [options]="assetToAssetTransformations" [placeholder]="translate('Select Transformation')" [model]="selectedAssetToAssetTrnsName" [optionDisplayNameProperty]="'chainDisplayName'" [optionValueProperty]="'id'" (modelChange)="selectedAssetToAssetTrnsName = $event;getSelectedAssetToAssetTransformation()" [groupDisplayNameProperty]="'orgName'" [title]="selectedAssetToAssetTrnsName" [groupValueProperty]="'transformations'" [noOptionsInstruction]="translate('No active transformations found')">
                                        </material-selectgroup>
                                    </div>
                                    <div class="transformationTableDiv assettoasset" *ngIf="selectedAssetToAssetTrnsName && selectedAssetToAssetTrnsName.length > 0 && selectedAssetToAssetTransformation.isAssetToAsset === true;">
                                        <div class="filteringAttributesDiv" *ngIf="filteringAttributes && filteringAttributes.length > 0">
                                            <div class="filterAttributesHeaderDiv">
                                                <span>{{translate('Filtering Attributes')}}:</span>
                                            </div>
                                            <div class="preCaptureAttributeFieldsContainer d-flex flex-wrap">
                                                <ng-container *ngFor="let field of filteringAttributes">
                                                    <div class="col-3 fieldInput" *ngIf="field && !field.autoCalculate">
                                                        <filingattribute-field [field]="field" [record]="filteringAttributes" [service]="currentApp" [type]="'filteringDate'"></filingattribute-field>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="source-filters organizationsListContainer" *ngIf="selectedSearchAssetToAssetTrnsName?.length > 0 && selectedAssetToAssetTransformation.isSearchAsset">
                                        <div class="orgSelectDivHeader col-12 mb-[24px]">
                                            <span>{{translate('Search')}}:</span>
                                        </div>
                                        <ng-container *ngFor="let source of selectedSearchAssetToAssetTransformation.sourceParts">
                                            <ng-container *ngIf="!(source.copyOf?.length > 0) && source?.appDetails?.metaData">
                                                <div class="col-12 px-0 mt-4 flex">
                                                    <div class="col-2 px-0 appDisplayName">
                                                        {{source?.appDetails?.metaData?.displayName}}
                                                    </div>
                                                    <div class="col-10 mb-4">
                                                        <div class="absolute filterClass !z-10 !right-[10px]">
                                                            <mat-form-field appearance="outline" class="!mr-[10px] !w-[130px]" appRemoveAriaOwns>
                                                                <mat-select placeholder="{{translate('Select Filter')}}" class="pl-[10px] dropIcon" [(value)]="selectedFilter">
                                                                    <mat-option [value]=""><em>--{{translate('Please select')}}--</em></mat-option>
                                                                    <mat-form-field class="searchContentClass">
                                                                        <input matInput [placeholder]="translate('Search Filter')" type="text" [(ngModel)]="searchedFilter" onclick="event.stopPropagation()" />
                                                                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="searchedFilter = ''">
                                                                            <mat-icon class='searchEntitiesIcons' *ngIf='searchedFilter'>
                                                                                close
                                                                            </mat-icon>
                                                                            <mat-icon *ngIf='!searchedFilter' class="searchEntitiesIcons">
                                                                                search
                                                                            </mat-icon>
                                                                        </button>
                                                                    </mat-form-field>
                                                                    <mat-option *ngFor="let filter of source?.defaultSearchFilters?.['default-filters'] | multiFilter:searchedFilter;" [value]="filter">
                                                                        {{filter}}
                                                                    </mat-option>
                                                                    <div class="noTemplatesMsg" *ngIf="(source?.defaultSearchFilters?.['default-filters'] | multiFilter:searchedFilter)?.length === 0">
                                                                        {{translate('No Filter Found')}}
                                                                    </div>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            <material-button [label]="translate('Apply Filter')" (click)="bindingTemplateValues(source)"></material-button>
                                                        </div>
                                                        <ng-container *ngTemplateOutlet="customSearch; context: { source: source}"></ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!(source.copyOf?.length > 0) && !source?.appDetails?.metaData && !source?.appDetails?.metaDataLoading">
                                                {{source?.sourceAssetOrMasterName}} is not subscribed
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="organizationsListContainer row" *ngIf="selectedAssetToAssetTrnsName && selectedAssetToAssetTrnsName.length > 0">
                                        <div class="orgSelectDivHeader flex justify-content-between col-12">
                                            <span>{{translate('Select Organizations')}}:</span>
                                        </div>
                                        <fieldset class="w-full flex">
                                            <div class="levelOneTenants col-6">
                                                <mat-form-field class="searchTenants searchInputField">
                                                    <input matInput placeholder="{{translate('Search Organization')}}" type="text" [(ngModel)]="filterTenantName">
                                                    <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="filterTenantName = ''">
                                                        <mat-icon class='locationManagementIcons' *ngIf='filterTenantName'>
                                                            close </mat-icon>
                                                        <mat-icon *ngIf='!filterTenantName' class="locationManagementIcons">
                                                            search </mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <perfect-scrollbar class="tenantsList position-relative" style="height: 40vh;">
                                                    <ng-container *ngIf="(tenantsToRender | FilterPipe: 'name' : filterTenantName) as result">
                                                        <div *ngFor="let tenant of result">
                                                            <span class="organizationSelect">
                                                                <material-checkbox [name]='"transOrgName" + tenant.name' [id]='"transOrgName" + tenant.id' [model]="selectAllOrganizations" (modelChange)="selectTenantAndSubTenantsForTransformation(tenant, $event)">
                                                                </material-checkbox>
                                                            </span>
                                                            <button mat-menu-item (click)="_newProcessService.selectTenant(tenant)" [class.activeOrganization]="tenant.id === selectedTenantId" title="{{tenant.name}} ({{tenant.orgLoginId}})">
                                                                <span>{{tenant.name}} <span class="orgId">({{tenant.orgLoginId}})</span></span>
                                                            </button>
                                                        </div>
                                                        <label class="noSearchResults" *ngIf="(tenantsToRender?.length) && !(result?.length)">{{translate('No organizations matching your search')}}</label>
                                                    </ng-container>
                                                </perfect-scrollbar>
                                            </div>
                                            <div class="tenantFlatStructure col-6">
                                                <mat-form-field class="searchSubTenants searchInputField">
                                                    <input matInput placeholder="{{translate('Search Tenant')}}" type="text" [(ngModel)]="filterSubTenantName">
                                                    <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchSubTenant()'>
                                                        <mat-icon class='locationManagementIcons' *ngIf='filterSubTenantName'>
                                                            close </mat-icon>
                                                        <mat-icon *ngIf='!filterSubTenantName' class="locationManagementIcons">
                                                            search </mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <perfect-scrollbar class="tenantsList position-relative" style="height: 40vh;" *ngIf="(subTenants | FilterPipe: 'displayTenantName' : filterSubTenantName) as result">
                                                    <div *ngFor="let subTenant of result">
                                                        <span class="organizationSelect">
                                                            <material-checkbox [name]='"transSubOrgName" + subTenant.name' [id]='"transSubOrgName" + subTenant.id' [model]="subTenant.selected" (modelChange)="subTenant.selected = $event;selectIfParentOrg($event, subTenant)">
                                                            </material-checkbox>
                                                        </span>
                                                        <button mat-menu-item title="{{subTenant.displayTenantName || subTenant.name}} ({{subTenant.orgLoginId}})" (click)="checkSubTenant(subTenant)">
                                                            <span>{{subTenant.displayTenantName || subTenant.name}} <span class="orgId">({{subTenant.orgLoginId}})</span></span>
                                                        </button>
                                                    </div>
                                                    <label class="noSearchResults" *ngIf="(subTenants?.length) && (result?.length === 0)">{{translate('No tenants matching your search')}}</label>
                                                </perfect-scrollbar>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                        <div class="filingAttributeSubmitButn" id="addDataBtnDiv">
                            <material-button class="pull-left" [label]="translate('Back')" [color]="'default'" (onTrigger)="goToBackPage()"></material-button>
                            <material-button [label]="translate('Cancel')" [color]="'default'" (onTrigger)="cancelEntity(false)"></material-button>
                            <material-button class='addDataBtnCls' [label]="translate('Initiate Process')" *ngIf="selectedIndex === 0" [color]="'primary'" (onTrigger)="validateBusinessKeys() && submitAttributeFields()"></material-button>
                            <material-button class='addDataBtnCls' [label]="translate('Initiate Process')" *ngIf="selectedIndex === 1" [color]="'primary'" (onTrigger)="validateBusinessKeys() && uploadFile()"></material-button>
                            <material-button class='addDataBtnCls' [label]="translate('Initiate Process')" *ngIf="selectedIndex === 2" [color]="'primary'" (onTrigger)="validateBusinessKeys() && (selectedAssetToAssetTransformation?.isSearchAsset ? startSearchAssetTransformation() : startAssetToAssetTransformation() )"></material-button>
                            <ng-container *ngIf="isInitiateVisible">
                                <material-button class='addDataBtnCls' [label]="translate('Initiate Process & Go to Edit page')" *ngIf="selectedIndex === 0" [color]="'primary'" (onTrigger)="validateBusinessKeys() && submitAttributeFields(true)"></material-button>
                            </ng-container>
                            <button mat-button mat-raised-button class="" #nextBtn [color]="'default'" (click)="validateBusinessKeys() && goToNextPage()">{{translate('Next: Review')}}</button>
                        </div>
                    </ng-container>
                </mat-step>
                <mat-step>
                    <ng-container *transloco="let translate;">
                        <ng-template matStepLabel>{{translate('Review')}}</ng-template>
                        <div class="reviewMainContainer" *transloco="let translate;">
                            <div class="reviewPreCaptureAttrContainer">
                                <div class="reviewPreCaptureHeader">
                                    {{translate('Pre-Capture Attributes')}}:
                                </div>
                                <div *ngIf="filingAttributeFields && filingAttributeFields.length > 0;else noFilingAtributes">
                                    <div class="requestReviewPreCaptureContainer row">
                                        <ng-container *ngFor="let field of filingAttributeFields">
                                            <div class="requestReviewFieldContainer col-3 wordBreak" *ngIf="field.show">
                                                <span class="requestReviewFieldLabel">{{field?.displayName || field?.name}} : </span>
                                                <span class="requestReviewFieldValue" *ngIf="!(field?.values?.length > 0)"> {{field?.value}}</span>
                                                <span class="requestReviewFieldValue" *ngIf="(field?.values?.length > 0)">{{field.values}}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <ng-container [ngSwitch]="selectedIndex">
                                <ng-container *ngSwitchCase="0">
                                    <div class="requestReviewDataContainer">
                                        <div class="reviewPreCaptureHeader">
                                            {{translate('Business Keys')}}:
                                        </div>
                                        <ng-container *ngIf="businessKeysRecord && businessKeysRecord.fields && businessKeysRecord.fields.length > 0; else noBusinessKeys">
                                            <div class="row">
                                                <ng-container *ngFor="let field of businessKeysRecord.fields">
                                                    <div class="requestReviewFieldContainer col-3 wordBreak" *ngIf="field.isBusinessKey && field.show">
                                                        <span class="requestReviewFieldLabel">{{field.displayName}} : </span>
                                                        <span class="requestReviewFieldValue"> {{field.value}}</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="requestReviewDataContainer">
                                        <div class="reviewPreCaptureHeader">
                                            {{translate('Input Details')}}:
                                        </div>
                                        <div class="noTrasformationSelectedCls">
                                            <div class="renderContent">
                                                <div class="requestReviewFieldContainer col-3 noPadding">
                                                    <span class="requestReviewFieldLabel">{{translate('Input type')}} : </span>
                                                    <span class="requestReviewFieldValue"> {{translate('Electronic form')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="1">
                                    <div class="requestReviewDataContainer">
                                        <div class="reviewPreCaptureHeader">
                                            {{translate('Input Details')}}:
                                        </div>
                                        <div class="noTrasformationSelectedCls">
                                            <div class="renderContent">
                                                <div class="row noPadding">
                                                    <div class="requestReviewFieldContainer col-12 noPadding">
                                                        <span class="requestReviewFieldLabel">{{translate('Input type')}} : </span>
                                                        <span class="requestReviewFieldValue"> {{translate('File Upload')}}</span>
                                                    </div>
                                                </div>
                                                <div class="row noPadding">
                                                    <div class="requestReviewFieldContainer col-12 noPadding">
                                                        <span class="requestReviewFieldLabel">{{translate('Selected Transformation')}} : </span>
                                                        <span class="requestReviewFieldValue"> {{selectedTransformation && (selectedTransformation.displayName || selectedTransformation.chainName) }}</span>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="sourceTableData.value && sourceTableData.value.length > 0">
                                                    <div class="reviewHeaderDiv">
                                                        {{translate('Uploaded Files')}}:
                                                    </div>
                                                    <div class="row noPadding">
                                                        <ng-container *ngIf="sourceTableData.value && sourceTableData.value.length === 1">
                                                            <ng-container *ngFor="let source of sourceTableData.value">
                                                                <div class="requestReviewFieldContainer col-12 noPadding">
                                                                    <span class="requestReviewFieldLabel" title="{{translate('Source Name')}}">{{source.name}} : </span>
                                                                    <span class="requestReviewFieldValue" title="{{translate('File Name')}}"> {{source.fileName}}</span>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="sourceTableData.value && sourceTableData.value.length > 1">
                                                            <ng-container *ngFor="let source of sourceTableData.value">
                                                                <div class="requestReviewFieldContainer col-4 noPadding">
                                                                    <span class="requestReviewFieldLabel" title="{{translate('Source Name')}}">{{source.name}} : </span>
                                                                    <span class="requestReviewFieldValue" title="{{translate('File Name')}}"> {{source.fileName}}</span>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    <div class="requestReviewDataContainer">
                                        <div class="reviewPreCaptureHeader">
                                            {{translate('Input Details')}}:
                                        </div>
                                        <div class="noTrasformationSelectedCls">
                                            <div class="renderContent">
                                                <div class="row">
                                                    <div class="requestReviewFieldContainer col-3 noPadding">
                                                        <span class="requestReviewFieldLabel">{{translate('Input type')}} : </span>
                                                        <span class="requestReviewFieldValue"> {{translate('Pull from App')}}</span>
                                                    </div>

                                                    <div class="requestReviewFieldContainer col-12 noPadding" *ngIf="bridgeSourceNodes && bridgeSourceNodes.length > 0">
                                                        <div class="row">
                                                            <div class="requestReviewFieldLabel noPadding">
                                                                {{translate('Source Names')}}:
                                                            </div>
                                                            <div class="col-9">
                                                                <ng-container *ngFor="let sourceName of bridgeSourceNodes">
                                                                    <div class="requestReviewFieldValue col-3">
                                                                        <span>{{sourceName.name}}</span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="bridgeSourceNodes && bridgeSourceNodes.length === 0">
                                                    <div class="requestReviewDataContainer">
                                                        <div class="requestReviewFieldContainer col-12 noPadding">
                                                            <span class="requestReviewFieldLabel">{{translate('Selected Transformation')}} : </span>
                                                            <span class="requestReviewFieldValue"> {{selectedAssetToAssetTransformation && (selectedAssetToAssetTransformation.displayName || selectedTransformation.chainName) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="requestReviewDataContainer" *ngIf="selectedSearchAssetToAssetTrnsName?.length > 0 && selectedAssetToAssetTransformation.isSearchAsset">
                                                        <ng-container *ngFor="let source of selectedSearchAssetToAssetTransformation.sourceParts">
                                                            <ng-container *ngIf="!(source?.copyOf?.length > 0) && source?.appDetails?.metaData">
                                                                <div class="col-12 px-0 mt-4 flex">
                                                                    <div class="col-2 px-0 appDisplayName">
                                                                        {{source?.appDetails?.metaData?.displayName}}
                                                                    </div>
                                                                    <div class="col-10 mb-4">
                                                                        <ng-container *ngTemplateOutlet="customSearch; context: { source: source, disabled: true }"></ng-container>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                    <div class="requestReviewDataContainer">
                                                        <div class="row">
                                                            <div class="reviewSelectedOrganizationsHeader requestReviewFieldLabel col-3 noPadding">
                                                                {{translate('Selected Organizations')}} :
                                                            </div>
                                                            <div class="reviewTenantNamesDisplayContainer col-9 noPadding">
                                                                <div class="row">
                                                                    <ng-container *ngFor='let orgid of allTenantsMap | ObjNgFor; let i = index;'>
                                                                        <ng-container *ngIf="allTenantsMap[orgid] &&allTenantsMap[orgid].selected">
                                                                            <div class="reviewTenantNameDisplay col-4 noPadding">
                                                                                {{allTenantsMap[orgid].displayTenantName || allTenantsMap[orgid].name}}
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="filingAttributeSubmitButn" id="reviewBtnDiv">
                            <material-button class="pull-left" [label]="translate('Back')" [color]="'default'" (onTrigger)="goToBackPage()"></material-button>
                            <material-button [label]="translate('Cancel')" [color]="'default'" (onTrigger)="cancelEntity(false)"></material-button>
                            <material-button class='nextBtnCls' [label]="translate('Initiate Process')" *ngIf="selectedIndex === 0" [color]="'primary'" (onTrigger)="submitAttributeFields()"></material-button>
                            <material-button class='nextBtnCls' [label]="translate('Initiate Process')" *ngIf="selectedIndex === 1" [color]="'primary'" (onTrigger)="uploadFile()"></material-button>
                            <material-button class='nextBtnCls' [label]="translate('Initiate Process')" *ngIf="selectedIndex === 2" [color]="'primary'" (onTrigger)="(selectedAssetToAssetTransformation?.isSearchAsset ? startSearchAssetTransformation() : startAssetToAssetTransformation() )"></material-button>
                        </div>
                    </ng-container>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </perfect-scrollbar>
</div>

<ng-template #customSearch let-source="source" let-disabled="disabled">
    <ng-container *ngIf="source?.appDetails?.metaData?.uid">
        <mat-tab-group class="w-100" [selectedIndex]="source.selectedSearchTab" *transloco="let translate">
            <mat-tab label="{{translate('Rules')}}">
                <app-search-builder [search]="source?.search" [disabled]="disabled" searchType="SEARCH"></app-search-builder>
                <div [hidden]="disabled" class="d-flex align-items-center justify-content-end pb-1 mt-4">
                    <material-button class="noOutline submitColor mx-2" [color]="'primary'" [label]="!source.search.criteriaUpdated ? translate('Add Criteria') : translate('Update Criteria')" (onTrigger)="source.search.buildCriterias()"></material-button>
                </div>
            </mat-tab>
            <mat-tab label="{{translate('Relations')}}">
                <div class="relationsTab pt-4">
                    <div class="queryBuilderMainCntr">
                        <app-search-builder [disabled]="disabled" [search]="source.search" [showQueryBuilderDirectly]="true" searchType="RELATION"></app-search-builder>
                    </div>
                    <div [hidden]="disabled" class="d-flex align-items-center justify-content-end mt-4">
                        <material-button class="noOutline submitColor" [label]="translate('Apply Relations')" (onTrigger)="source.search.applyRelations()"></material-button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
</ng-template>

<ng-template #noTransformation>
    <div *transloco="let translate;">
        <div class="noTrasformationSelectedCls">
            <span class="renderContent">{{translate('Please select a transformation')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #noBusinessKeys>
    <div *transloco="let translate;">
        <div class="noTrasformationSelectedCls">
            <span class="renderContent">{{translate('No Business keys found')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #noFilingAtributes>
    <div *transloco="let translate;">
        <div class="noTrasformationSelectedCls">
            <span class="renderContent">{{translate('No Pre-Capture Attributes found')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #noTransformationFound>
    <div *transloco="let translate;">
        <div class="noTrasformationSelectedCls">
            <span class="renderContent">{{translate('No transformations found for this app')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #notificationPopupModal *transloco="let translate;">
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('Alert')}}!</h4>
        <span class="material-icons mb-1" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content class="mat-typography">
        {{notificationPopupMessage}}
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <material-button class="mx-4" [label]="translate('Close')" [color]="'default'" (onTrigger)="closePopup()"></material-button>
        <material-button [label]="translate('Continue')" [color]="'primary'" (onTrigger)="processSourceInstances()"></material-button>
    </mat-dialog-actions>
</ng-template>
