import { MastersResponseRecord } from '../../interface/master-records-response.interface';
import { UtilsService } from '../../services/utils/utils.service';
import { Message } from '../record/message.class';
import { Rule } from '../rule.class';
import { MasterField } from './masterfield.class';
import { MastersMetaData } from './mastersmetadata.class';

export class MasterRecord {
    recordId?: string;
    errors?: string[];
    warnings?: string[];
    fields?: {
        [property: string]: MasterRecordField;
    };
    hasFields?: boolean;
    idVsValue: {
        [property: string]: string | boolean;
    };

    constructor(record: MastersResponseRecord, mastersMetaData: MastersMetaData) {
        this.recordId = record?.recordId === undefined ? UtilsService.guid() : record?.recordId;
        this.fields = {};
        this.errors = record?.errors;
        this.warnings = record?.warnings;
        this.idVsValue = {};
        const columns = record?.fields?.length > 0 ? this.prepareFields(record) : {};
        const metaDataColumns = mastersMetaData?.masterMetadata?.tableDefinition?.columnDefinitions;
        metaDataColumns?.forEach((column) => {
            const key = column.id;
            this.setField(key, columns[key], column);
        });
        this.hasFields = metaDataColumns?.length > 0;
    }
    prepareFields = (record: MastersResponseRecord) => {
        return record?.fields.reduce((fields, fld) => {
            this.idVsValue[fld.id] = fld.value;
            fields[fld.id] = fld;
            return fields;
        }, {});
    };
    setField? = (id: string, field, fieldMetaData) => {
        const name = id === 'record_key' ? 'Record Id' : id === 'unit_id' ? 'Unit Id' : fieldMetaData?.name;
        this.fields[id] = new MasterRecordField(field, fieldMetaData, name);
    };

    getFieldByFieldId? = (id: string): MasterRecordField => {
        const self: any = this.fields || {};
        for (const key in self) {
            if (key === id) {
                return self[key];
            }
        }
        return undefined;
    };

    getFieldByFieldName? = (name: string): MasterRecordField => {
        const self: any = this.fields || {};
        for (const key in self) {
            if (self[key].displayName === name?.trim()) {
                return self[key];
            }
        }
        return undefined;
    };

    createNewRecord? = (mastersMetaData: MastersMetaData) => {
        const columnIds = mastersMetaData.getAllColumnIds() || [];
        columnIds &&
            columnIds.forEach((e) => {
                this.setField(e, {}, mastersMetaData.getColumn(e));
            });
    };
}

export class MasterRecordField {
    id: string;

    value: string;

    name?: string;

    displayName?: string;

    active?: boolean;

    primaryKey?: boolean;

    dateFormat?: string;

    message?: Message;

    rules?: Rule[];

    dataType?: string;

    originalValue?: any;

    mandatory?: boolean;

    description?: string;

    constructor(data, metaDataField: MasterField, name) {
        this.id = metaDataField?.id;
        let value =
            metaDataField?.dataType !== undefined && metaDataField.dataType === 'BOOLEAN' ? this.checkBool(data?.value) : data?.value;
        this.value = value;
        this.name = name;
        this.displayName = metaDataField?.displayName?.trim();
        this.active = metaDataField?.active !== undefined ? metaDataField?.active : true;
        this.primaryKey = metaDataField?.primaryKey || false;
        this.dateFormat = metaDataField?.dateFormat;
        this.message = new Message(data?.message);
        this.dataType = metaDataField?.dataType;
        this.rules = (metaDataField?.rules && [...metaDataField.rules]) || [];
        this.mandatory = metaDataField?.mandatory;
        this.description = metaDataField?.description;
    }

    checkBool = (value) => {
        if (typeof value === undefined) {
            return '';
        } else if (value === 'true') {
            return true;
        } else if (value === 'false') {
            return false;
        } else if (typeof value === 'boolean') {
            return value;
        } else {
            return value;
        }
    };
}
