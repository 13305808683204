import { Injectable } from '@angular/core';

import { CollabConfiguration } from '../../models/collab-configuration.class';
import { ApiService } from '../api/api.service';
import { CollaborationService } from '../collaboration/collaboration.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { LocationsService } from '../locations/locations.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CommonCollaborationService {
    constructor(
        private _collabService: CollaborationService,
        private R: RootScopeService,
        public _utils: CommonUtilsService,
        public _taxilla: ApiService,
        private _libUtils: UtilsService,
        public _locations: LocationsService
    ) {}

    getCollabConfig = (that, callback?) => {
        const data = {
            assetId: that.actualAssetId || that.assetData.uid,
            instanceId: that.instanceId,
        };
        this._collabService.getCollabConfig(data, {
            successCallback: (res) => {
                const collabConfig = CommonUtilsService.cloneObject(res.response);
                that.collabConfig = new CollabConfiguration(collabConfig);
                that.partnerFunctions = this.getAllPartnerFunctions(that);
                callback && callback();
            },
            failureCallback: (res) => {
                this._libUtils.alertError(res?.msg || 'There was a problem getting collaboration configuration.');
            },
        });
    };

    getAllPartnerFunctions = (that) => {
        const partnerFunctions = [];
        that.collabConfig?.isEnabled &&
            that.collabConfig?.partnerAppSettings.forEach((setting) => {
                setting.isEnabled && setting.partnerFunction && partnerFunctions.push(setting.partnerFunction);
            });
        return partnerFunctions;
    };
}
