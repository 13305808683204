import { FilterCriteria, SavedSearchOption, SearchCriteria } from 'taxilla-library';

export interface SearchState {
    organizations: {
        // organizationId vs map
        [organizationId: string]: {
            // serviceId vs map
            [serviceId: string]: {
                defaultFilters: {
                    'default-filters': string[];
                    filterCriterias: {
                        [property: string]: FilterCriteria;
                    };
                };
                searchFilters: SavedSearchOption[];
            };
        };
    };
    searchCriteria: {
        [serviceId: string]: SearchCriteria;
    };
}

export const searchState: SearchState = {
    organizations: {},
    searchCriteria: {},
};
