import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ChangePasswordComponent } from 'taxilla-library';

@Component({
    selector: 'app-passwordexpire',
    templateUrl: './passwordexpire.component.html',
    styleUrls: ['./passwordexpire.component.scss'],
})
export class PasswordexpireComponent implements OnInit {
    passwordExpiryDate: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    openChangePasswordModal = () => {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            panelClass: ['changePasswordModal', 'matDialogContainer', 'w-50'],
            disableClose: true,
        });
    };

    ngOnInit(): void {}
}
