import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { filter, Subject, takeUntil } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import { GetSessionDetails, SessionLogout } from '../../store/actions';
import { getSessionDetails$ } from '../../store/selectors';

@Component({
    selector: 'lib-session-check',
    templateUrl: './session-check.component.html',
    styleUrls: ['./session-check.component.css'],
})
export class SessionCheckComponent implements OnInit, OnDestroy {
    private unSubscribe = new Subject<void>();

    constructor(
        private _api: ApiService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _utils: UtilsService,
        private _broadcaster: BroadcasterService,
        private _commonUtils: CommonUtilsService,
        private store$: Store,
        private actions$: Actions
    ) {}

    private checkSession = () => {
        this._utils.log('checking origin');
        const session = this._commonUtils.getFromStorage('session');
        this.checkKeys(session);
    };

    private checkKeys = (session: boolean) => {
        if (session) {
            this.store$.dispatch(GetSessionDetails({}));
        } else {
            this._utils.log('redirecting to encomply');
            this._utils.redirectToLogin(true);
        }
    };

    private postSessionCheck = () => {
        const routeData = this._route.data?.['value'];
        const redirectTo = this._route.snapshot.data.redirectTo;
        this._broadcaster.broadcast('startSessionServices');
        const href = window.location.href;
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        const enReconcile = environment['enreconcile-ui'];
        const gstFiling = environment['gst-filing'];
        if (routeData.skipSession) {
            return;
        } else if (!routeData.secured) {
            const routes = ['organizations'];
            if (href.indexOf(enInvoice) > -1) {
                routes.unshift('eninvoice');
            } else if (href.indexOf(enReport) > -1) {
                routes.unshift('enreport');
            } else if (href.indexOf(enCollab) > -1) {
                routes.unshift('encollab');
            } else if (href.indexOf(enReconcile) > -1) {
                routes.unshift('enreconcile');
            } else if (href.indexOf(gstFiling) > -1) {
                routes.unshift('gst-filing');
            }
            this._router.navigate(routes);
            return;
        }
        if (redirectTo) {
            const routes = ['organizations'];
            if (href === enInvoice) {
                routes.unshift('eninvoice');
            } else if (href === enReport) {
                routes.unshift('enreport');
            } else if (href === enCollab) {
                routes.unshift('encollab');
            } else if (href === enReconcile) {
                routes.unshift('enreconcile');
            } else if (href === gstFiling) {
                routes.unshift('gst-filing');
            }
            this._router.navigate(routes);
        } else {
            const currentOrgId = this._commonUtils.getFromStorage('currentOrganizationId');
            if (currentOrgId) {
                this._router.navigate(['organizations', currentOrgId]);
            } else {
                this._broadcaster.broadcast('logout', { hidePopup: true, noRedirect: false });
            }
        }
    };

    ngOnInit() {
        this.checkSession();
        this.store$
            .select(getSessionDetails$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data.lastLogin !== undefined)
            )
            .subscribe((event) => {
                this.postSessionCheck();
            });
        this.actions$.pipe(ofType(SessionLogout), takeUntil(this.unSubscribe)).subscribe((event) => {
            this._utils.postLogout(false, true);
        });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
