import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    CommonUtilsService,
    getCurrentOrganization$,
    GetWorkQCount,
    getWorkQCount$,
    getWorkQMessages,
    getWorkQMessages$,
    Organization,
    setWorkQCount,
    UtilsService,
    workQMessageMarkAsRead,
    WorkqRecord,
} from 'taxilla-library';

@Component({
    selector: 'workq-feed',
    templateUrl: './workqfeed.component.html',
    styleUrls: ['./workqfeed.component.scss'],
})
export class WorkQFeedComponent implements OnInit, OnDestroy {
    @Input() maxNumberRecords: number;
    @Input() offsetValue: number;
    @Input() heightLeftValue: number;
    @Input() noScroll: boolean;

    private destory: Subject<void> = new Subject<void>();
    public translateMsg = (msg: string): string => translate(msg);
    public formData = {
        eventType: '',
        currentMonth: '',
        currentYear: '',
    };
    private workQType: string;
    private workQObj = {
        messageType: '',
        fetchSize: 20,
        nextPagingState: null,
        previousPagingState: null,
    };
    public records = [];
    private totalWorkQCount: number = 0;
    private myWorkCount = 0;
    private updatesCount = 0;
    private routerMap = [
        {
            messageCategory: 'Subscription',
            currentUrlString: '/organizations/' + this.R.currentOrganizationId.value + '/settings/subscriptionapproval',
            redirect: false,
            needModal: false,
        },
        {
            messageCategory: 'Report Generation',
            currentUrlString: '/organizations/' + this.R.currentOrganizationId.value + '/home',
            redirect: true,
            needModal: false,
        },
        {
            messageCategory: 'Asset Processing',
            currentUrlString: '/organizations/' + this.R.currentOrganizationId.value + '/home',
            redirect: true,
            needModal: false,
        },
        {
            messageCategory: 'Account',
            currentUrlString: '/organizations/' + this.R.currentOrganizationId.value + '/settings/users',
            redirect: false,
            needModal: true,
        },
        {
            messageCategory: 'Partner Assignment',
            currentUrlString: '/organizations/' + this.R.currentOrganizationId.value + '/settings/partners',
            redirect: false,
            needModal: true,
        },
        {
            messageCategory: 'Request Processing',
            currentUrlString: '/organizations/' + this.R.currentOrganizationId.value + '/home',
            redirect: true,
            needModal: false,
        },
    ];
    private originalRecords = [];
    private recordIds = [];
    public selectedRecordId: string;
    private appsSubscription: Subscription;
    private recordCreatedOnObj = {};
    private unSubscribe = new Subject<void>();
    public eventTypesList = [];
    public allAssetsName: string;
    private pagingState: any;
    private fetchingRecords: boolean;

    constructor(
        private _taxilla: TaxillaApiService,
        private R: RootScope,
        public _utils: Utils,
        private _broadcaster: BroadcasterService,
        private _libUtils: UtilsService,
        private store$: Store,
        private actions$: Actions
    ) {}

    private startComponent = (workqType) => {
        this.records = [];
        this.originalRecords = [];
        this.recordIds = [];
        this.workQObj.messageType = workqType;
        this.pagingState = null;
        this.workQObj.previousPagingState = null;
        this.R.selectedWorkqItem.next({});
        this.getNotifications();
        this.getCountOfActiveMessages();
        this.getWorkqRecords();
    };

    private getWorkqRecords = () => {
        if (this.fetchingRecords) {
            return;
        }
        this.originalRecords = [];
        const data = {
            messageType: this.workQObj.messageType,
            fetchSize: this.workQObj.fetchSize,
            nextPagingState: this.pagingState,
        };
        this.fetchingRecords = true;
        this.store$.dispatch(getWorkQMessages(data));
    };

    private processWorkQRecords = (res) => {
        this.fetchingRecords = false;
        const records = res?.response?.messages;
        this.pagingState = res?.response?.pagingState;
        if (records) {
            if (records.length > 0) {
                records.forEach((element) => {
                    if (this.recordIds.indexOf(element.messageId) > -1) {
                    } else {
                        if (this.maxNumberRecords === undefined || (this.maxNumberRecords && this.records.length < 10)) {
                            this.records.push(new WorkqRecord(element, this.routerMap, records, this.workQObj.messageType));
                            this.recordIds.push(element.messageId);
                        }
                    }
                });
            }
        }
        if (this.selectedRecordId) {
            this.broadcastRecord();
        }
        if (res.response && res.response.unread_count) {
            if (this.workQObj.messageType === 'Action Item') {
                this.myWorkCount = res.response.unread_count['Action Item'];
                this.updatesCount = this.totalWorkQCount - this.myWorkCount;
            }
            if (this.workQObj.messageType === 'Information') {
                this.updatesCount = res.response.unread_count['Information'];
                this.myWorkCount = this.totalWorkQCount - this.updatesCount;
            }
        }
        this.records.forEach((record) => {
            this.originalRecords.push(record);
        });
    };

    public getAllEvents = (data) => {
        this.workQObj.messageType = data;
        this.records = [];
        this.recordIds = [];
        this.pagingState = undefined;
        this.getWorkqRecords();
        this._utils.setRoute('/organizations/' + this.R.currentOrganizationId.value + '/workq/feed/');
    };

    public refreshEvents = () => this.getAllEvents(this.workQObj?.messageType);

    private getNotifications = (callback?) => {
        this.originalRecords = [];
        this._taxilla.workQ.getNotifications({
            successCallback: (res) => {
                this.eventTypesList = [];
                const records = res?.response?.config;
                const eventTypes = records.filter((record) => record.workqueueConfigEditable === true);
                eventTypes.forEach((item) => {
                    let value = item.eventType;
                    this.eventTypesList.push({
                        id: item.eventType,
                        displayName: this._libUtils.convertEventTypesToNewNames(this._libUtils.convertEnumsToNormalWords(value)),
                    });
                });
                if (callback) {
                    callback();
                }
            },
            failureCallback: (res) => {},
        });
    };

    public trackEventType(index: number, eventType: any): any {
        return eventType.id;
    }

    private getCountOfActiveMessages = () => {
        this.store$.dispatch(GetWorkQCount());
    };

    public onFeedItemSelect = (record: WorkqRecord) => {
        this._utils.setRoute('/organizations/' + this.R.currentOrganizationId.value + '/workq/feed/' + record.id);
    };

    private broadcastRecord = () => {
        const selectedRecord = this.records.find((record) => record.id === this.selectedRecordId);
        if (selectedRecord) {
            this._broadcaster.broadcast('selectedWorkqFeed', {
                record: selectedRecord,
            });
            this.makeRecordAsRead(selectedRecord);
        }
    };

    public onScrollFetchRecords = () => {
        if (this.maxNumberRecords === undefined && this.records.length > 10) {
            this.getWorkqRecords();
        }
    };

    public makeRecordAsRead = (record, event?) => {
        event && event.stopPropagation();
        if (record.isRead === true) return;
        this.store$.dispatch(workQMessageMarkAsRead({ messageId: record.id, noAlerts: false }));
    };

    private processMarkAsRead = (recordId: string) => {
        switch (this.workQType) {
            case 'Action Item':
                this.myWorkCount -= this.myWorkCount;
                break;
            case 'Information':
                this.updatesCount -= this.updatesCount;
                break;
        }
        this.records.forEach((element) => {
            if (element.id === recordId) {
                element.isRead = true;
            }
        });
        this.store$.dispatch(setWorkQCount({ count: this.totalWorkQCount - 1 }));
    };

    public getRecordCreatedOnDate = (record) => {
        let recordCreatedOnDate = '';
        recordCreatedOnDate = this.recordCreatedOnObj[record.id];
        if (recordCreatedOnDate !== undefined) {
            return recordCreatedOnDate;
        } else {
            recordCreatedOnDate = this._utils.transformDateToLocale(
                record.createdOn,
                'YYYY-MM-DDTHH:MM:SSZ',
                'DD-MM-YYYY HH:MM:SS AM',
                false
            );
            this.recordCreatedOnObj[record.id] = recordCreatedOnDate;
        }
        return recordCreatedOnDate;
    };

    ngOnInit() {
        const messageType = location.href.indexOf('/Action Item/') > -1 ? 'Action Item' : '';
        this.startComponent(messageType);
        this.store$
            .select(getCurrentOrganization$)
            .pipe(
                takeUntil(this.destory),
                filter((data) => data?.workQueueEnabled !== undefined)
            )
            .subscribe((event) => {
                const canShowWorkQ = !environment.restrictedFeatures?.workQ && event.workQueueEnabled;
                if (!canShowWorkQ) {
                    this._libUtils.navigateToOrganization(undefined);
                    return;
                }
            });

        this.R.selectedWorkqDetails.pipe(takeUntil(this.destory)).subscribe((details) => {
            if (details && details['workqId']) {
                this.selectedRecordId = details['workqId'];
                if (this.records && this.records.length > 0) {
                    this.broadcastRecord();
                } else {
                    this.getWorkqRecords();
                }
            } else {
                this.selectedRecordId = undefined;
            }
        });
        this._broadcaster
            .on('switchedOrganization')
            .pipe(takeUntil(this.destory))
            .subscribe((organization: Organization) => {
                this.startComponent(messageType ?? '');
                if (!this.records && this.records.length < 1) {
                    this.startComponent('Action Item');
                }
            });

        this.store$
            .select(getWorkQCount$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((count) => {
                this.totalWorkQCount = count;
            });

        this.store$
            .select(getWorkQMessages$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                let messages = CommonUtilsService.cloneObject(data);
                this.processWorkQRecords(messages);
            });

        this.actions$.pipe(ofType(workQMessageMarkAsRead), takeUntil(this.unSubscribe)).subscribe((data) => {
            this.processMarkAsRead(data.messageId);
        });
    }

    ngOnDestroy(): void {
        this.appsSubscription && this.appsSubscription.unsubscribe();
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.destory.next();
        this.destory.complete();
    }
}
