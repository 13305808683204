<div *transloco="let translate" class="materialGroupCheckboxContainer" [class.mat-error]="errors?.length > 0" [class.warning]="warnings && warnings.length > 0 && errors.length === 0">
    <button mat-button [matMenuTriggerFor]="menu" [class.mat-error]="errors && errors.length > 0" [class.warning]="warnings && warnings.length > 0 && errors.length === 0" [disabled]="disabled">
        <ng-container *ngIf="dontShowValue">
            <span class="btnDisplayText groupCheckboxSelectedText" [title]="menuName">
                {{ menuName }}
            </span>
        </ng-container>
        <ng-container *ngIf="!dontShowValue">
            <span class="btnDisplayText groupCheckboxSelectedText" title="{{ optionsSelected !== undefined ? (menuName + ':' + (optionsSelected.join ? optionsSelected.join(', ') : optionsSelected)) : menuName }}">
                {{ optionsSelected !== undefined ? (menuName + ':' + (optionsSelected.join ? optionsSelected.join(', ') : optionsSelected)) : menuName }}
            </span>
            <span class="material-icons dropdowns defaultDropdownIcon"> arrow_drop_down </span>
            <span class="material-icons dropdowns fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
            <button class="descriptionButton" mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
                <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}">
                    info
                </span>
            </button>
        </ng-container>
    </button>
    <div class="hintsContainer">
        <div class="mat-form-field-underline ng-tns-c11-31 ng-star-inserted">
            <span class="mat-form-field-ripple"></span>
        </div>
        <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
            <strong>
                <span>* </span>{{translate('Required')}}</strong>
        </mat-hint>
        <mat-hint align="start" *ngIf="!required && !(errors && errors.length > 0) && hint && hint.length > 0">
            {{hint}}
        </mat-hint>
        <mat-error *ngIf="errors && errors.length > 0" title="{{errors}}">
            {{errors}}
        </mat-error>
    </div>
    <mat-menu #menu="matMenu" class="groupCheckboxContainer" [class]="classList?.join(' ')" xPosition="before">
        <div class="groupCheckboxListContainer position-relative" style="max-height: 45vh;">
            <div>
                <div class="searchOptionsContainer" stop-propagation>
                    <material-input [autofocus]="false" [placeholder]="translate('Search...')" [model]="searchOptions" [name]="'searchOptions'" [id]="'searchOptions'" [showClearText]="true" (modelChange)="searchOptions = $event">
                    </material-input>
                </div>
                <div class="checkBoxContainer" *ngFor="let option of checkboxOptions | FilterPipe : 'displayName': searchOptions; trackBy: trackByIndexMethod" stop-propagation>
                    <material-checkbox [name]="option.displayName" [model]="option.isSelected" [showName]="true" (modelChange)="changedValue(option.value, $event)" [title]="option.displayName"> </material-checkbox>
                </div>
                <mat-option *ngIf="(options | FilterPipe: (optionValueProperty || 'value') : searchOptions : null : (optionDisplayNameProperty || 'displayName')).length === 0">
                    {{translate('No results found')}}
                </mat-option>
            </div>
        </div>
    </mat-menu>
</div>
