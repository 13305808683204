import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Organization } from '../../models/organization.class';
import { LOCATIONS_REDUCER_KEY } from '../reducers';
import { LocationInitialState } from '../states';
import { getCurrentOrganizationId$, getLoggedInOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<LocationInitialState>(LOCATIONS_REDUCER_KEY);

export const getFlexFields$ = createSelector(currentState, (state) => state.flexfields);

export const getAssociatedOrganizations$ = createSelector(currentState, (state) => state?.associatedOrganizations);

export const getAssociatedOrganizationsMap$ = createSelector(getAssociatedOrganizations$, (list) => getFlatMap({}, list));

const getFlatMap = (map: { [property: string]: Organization } = {}, list: Organization[]) => {
    list?.forEach((org) => {
        map[org.id] = org;
        org.childNodes?.length > 0 && getFlatMap(map, org.childNodes);
    });
    return map;
};

export const getLocationsCodes$ = createSelector(currentState, (state) => state.locationCodes);

export const getOrganization$ = (organizationId: string) => createSelector(getAssociatedOrganizationsMap$, (map) => map?.[organizationId]);

export const getCurrentOrganization$ = createSelector(
    getAssociatedOrganizationsMap$,
    getCurrentOrganizationId$,
    (map, organizationId) => map?.[organizationId]
);

export const getLogo$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.logoMapWithHierarchy?.[organizationId]
);

export const getCurrentLogo$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.logoMap?.[organizationId]
);

export const getLoggedInOrganization$ = createSelector(
    getAssociatedOrganizationsMap$,
    getLoggedInOrganizationId$,
    (map, organizationId) => map?.[organizationId]
);

export const getSelectedRootOrganization$ = createSelector(
    getCurrentOrganizationId$,
    getAssociatedOrganizationsMap$,
    (currentOrgId, orgsMap) => getRootOrg(currentOrgId, orgsMap)
);

const getRootOrg = (orgId: string, orgsMap: { [property: string]: Organization }) => {
    const org = orgsMap?.[orgId];
    if (org?.parentId) {
        return getRootOrg(org.parentId, orgsMap);
    }
    return org;
};

export const getCurrentLocationHierarchy$ = createSelector(
    getCurrentOrganization$,
    getAssociatedOrganizationsMap$,
    (currentOrganization, orgsMap) => getHierarchyMap([], currentOrganization, orgsMap)
);

const getHierarchyMap = (list: Organization[], organization: Organization, orgsMap: { [property: string]: Organization }) => {
    organization?.id && list.push(organization);
    const parentId = organization?.parentId || organization?.providerOrganizationId;
    if (parentId && !list.find((org) => org.id === parentId) && orgsMap?.[parentId]) {
        getHierarchyMap(list, orgsMap?.[parentId], orgsMap);
    }
    return list;
};

export const getCurrentLocationIdsHierarchy$ = createSelector(
    getCurrentOrganization$,
    getAssociatedOrganizationsMap$,
    (currentOrganization, orgsMap) => getIdHierarchyMap([], currentOrganization?.id, currentOrganization, orgsMap)
);

const getIdHierarchyMap = (list: string[], id: string, organization: Organization, orgsMap: { [property: string]: Organization }) => {
    id && list.push(id);
    if (organization) {
        const parentId = organization?.parentId || organization?.providerOrganizationId;
        if (parentId && !list.includes(parentId)) {
            getIdHierarchyMap(list, parentId, orgsMap?.[parentId] || organization?.parent, orgsMap);
        }
        !list.includes(organization?.providerOrganizationId) && list.push(organization?.providerOrganizationId);
    }
    return list;
};

export const getOrganizationLocations$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.organizationLocations?.[organizationId]
);

export const getLocationsMap$ = createSelector(getOrganizationLocations$, (list) => getFlatMap({}, list));

export const setOrgDetailsMessage$ = createSelector(currentState, (state) => state.orgDetailsMessage);

export const getLocation$ = (locationId: string) =>
    createSelector(getOrganizationLocations$, (locations) => locations?.find((loc) => loc.id === locationId));

export const getOrganizationChildHierarchy$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.orgChildHierarchy?.[organizationId]
);
