import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MasterLanguageTemplate, MastersMetaData } from 'taxilla-library';

import { MasterRecordsResponse, MastersResponseRecord } from '../../interface/master-records-response.interface';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class MastersService {
    constructor(
        private _bridge: BridgeService,
        private _utils: UtilsService,
        private _store: StoreService,
        private _commonUtils: CommonUtilsService
    ) {}

    /**
     * Method to get all master tables
     */
    getAllMasters = (currentOrganizationId: string) => {
        return new Observable<any>((observer) => {
            this._bridge.getAllMasters(
                {
                    currentOrganizationId,
                },
                (res) => {
                    observer.next(res?.response?.masters);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.response?.masters);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master table meta data
     * @param data Contains master table, organization ID,
     * @param callbacks Contains success callback method,
     */
    getMetaData = async (
        data: { tableId: string; organizationId: string; noAlerts?: boolean },
        callbacks: { successCallback?: (...args: any[]) => void }
    ) => {
        const appsResponse: any = await this._store.privateScope.fetchValues(
            () => {
                return new Promise((resolve, reject) => {
                    const promises = [];
                    promises.push(this.getMasterMetaData(data));
                    promises.push(this.getMasterLanguageTemplate(data));
                    Promise.all(promises).then(([metaData, languageData]: [any, MasterLanguageTemplate]) => {
                        metaData.languageData = languageData;
                        resolve(metaData);
                    });
                });
            },
            'mastersMetaData',
            data.tableId
        );
        const metaData = new MastersMetaData(appsResponse?.['mastersMetaData']?.[data.tableId] || []);
        callbacks.successCallback(metaData);
    };

    /**
     * Method to get master table meta data
     * @param data Contains master table, organization ID,
     * @param callbacks Contains success callback method,
     */
    getMasterMetaData = async (data: { tableId: string; organizationId: string; noAlerts?: boolean }) => {
        return new Promise<any>((resolve, reject) => {
            this._bridge.getMastersMetadata(
                {
                    itemName: data.tableId,
                    organizationId: data.organizationId,
                    noAlerts: data.noAlerts,
                },
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.alertError(res?.msg || 'Failed to get masters meta data');
                    reject(undefined);
                }
            );
        });
    };

    getMasterMetaDataObservable = (data: { tableId: string; organizationId: string; noAlerts?: boolean }) => {
        return new Observable<any>((observer) => {
            this._bridge.getMastersMetadata(
                {
                    itemName: data.tableId,
                    organizationId: data.organizationId,
                    noAlerts: data.noAlerts,
                },
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master table meta data
     * @param data Contains master table, organization ID,
     * @param callbacks Contains success callback method,
     */
    getMasterLanguageTemplate = async (data: { tableId: string; organizationId: string; noAlerts?: boolean }) => {
        return new Promise((resolve) => {
            this._bridge
                .getMasterLanguageTemplate({
                    masterId: data.tableId,
                    noAlerts: data.noAlerts,
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    resolve(undefined);
                });
        });
    };

    getMasterLanguageTemplateObservable = (data: { tableId: string; organizationId: string; noAlerts?: boolean }) => {
        return new Observable((observer) => {
            this._bridge.getMasterLanguageTemplateObservable(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master records
     */
    getRecords = (data: { size?: number; restApiName: string; pagingState: any; noAlerts?: boolean }) => {
        return new Observable((observer) => {
            this._bridge.getMastersRecords(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to edit master records
     */
    updateRecord = (data) => {
        return new Observable((observer) => {
            this._bridge.updateMasterRecord(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to add masters records
     */
    addRecord = (data) => {
        return new Observable((observer) => {
            this._bridge.addMasterRecord(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to search master records
     * @param data Contains organizationId, displayName, payload,
     * @param callbacks Contains success callback method,
     */
    searchMasters = (
        data: {
            organizationId: string;
            displayName: string;
            payload: {
                queryString: string;
                size: number;
                from: number;
                masterId: string;
                unitId: string;
                sort: {};
                searchParent?: boolean;
            };
        },
        callbacks: {
            successCallback: (
                records: {
                    [property: string]: {
                        newValue: any;
                        oldValue: any;
                    };
                }[],
                searchAfter: any
            ) => void;
        }
    ) => {
        this._bridge.searchMasterRecords(
            data,
            (res) => {
                callbacks.successCallback(res?.response?.masterRecords || [], res?.response?.searchAfter);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get masters data by ' + data.displayName);
            }
        );
    };

    searchMastersObservable = (data) => {
        return new Observable((observer) => {
            this._bridge.searchMasterRecords(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get total count of search master records
     * @param data Contains organizationId, displayName, payload,
     * @param callbacks Contains success callback method,
     */
    getSearchMasterRecordsCount = (
        data: {
            organizationId: string;
            displayName: string;
            payload: {
                queryString: string;
                masterId: string;
                unitId: string;
                sort: {};
            };
        },
        callbacks: {
            successCallback: (count: number) => void;
            failureCallback?: (res: any) => void;
        }
    ) => {
        this._bridge.getSearchMasterRecordsCount(
            data,
            (res) => {
                callbacks.successCallback(res && res.response && res.response.count);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get records count');
                }
            }
        );
    };

    getSearchMasterRecordsCountObservable = (data) => {
        return new Observable((observer) => {
            this._bridge.getSearchMasterRecordsCount(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get masters Inbound mappings
     * @param data Contains organizationId, master table id
     * @param callbacks Contains success callback method, failure callbackmethod
     */
    getMastersInboundMappings = (
        data: {
            organizationId: string;
            masterTableId: string;
        },
        callbacks: {
            successCallback: (response: {
                mappings: {
                    [property: string]: {
                        name: string;
                        description: string;
                        engineProps: {
                            type: string;
                            version: string;
                        };
                        sourceParts: {
                            name: string;
                            sourceDataFormat: string;
                            fileExtension: string;
                            bulkUpload: false;
                            entities: any[];
                            settings: {};
                        }[];
                        mappedTargetMetadata: {
                            name: string;
                            dataFormat: string;
                            fileExtension: string;
                            mappedEntities: any[];
                            disableStreamBased: boolean;
                        };
                        mappingMetadata: {
                            mapId: string;
                            mapName: string;
                        };
                        defaulted: false;
                        xmlVersion: string;
                    };
                };
                repositoryId: string;
            }) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getMastersInboundMappings(
            data,
            (res) => {
                callbacks.successCallback(res.response || {});
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to get Master sources');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    getMastersInboundMappingsObservable = (data: { organizationId: string; masterTableId: string }) => {
        return new Observable((observer) => {
            this._bridge.getMastersInboundMappings(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master primaryKey meta data
     * @param data Contains master tableId, organization ID,
     * @param callbacks Contains success callback method,
     */
    getParentPrimaryKeys = (
        data: { organizationId: string; tableId: string },
        callbacks: { successCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getParentPrimaryKeys(
            {
                tableId: data.tableId,
                organizationId: data.organizationId,
            },
            (res) => {
                callbacks.successCallback(res && res.response);
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get masters parent primary key');
            }
        );
    };

    getParentPrimaryKeysObservable = (data: { organizationId: string; tableId: string }) => {
        return new Observable((observer) => {
            this._bridge.getParentPrimaryKeys(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master OutboundMappings
     * @param data Contains master tableId, organization ID,
     * @param callbacks Contains success callback method,
     */
    getOutboundMappings = (data: { organizationId: string; tableId: string }) => {
        return new Observable((observer) => {
            this._bridge.getOutboundMappings(
                data,
                (res) => {
                    Object.keys(res?.response?.mappings || {}).forEach((key) => {
                        res.response.mappings[key].chainName = key;
                    });
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master reportDetails
     * @param data Contains master tableId, organization ID,
     * @param callbacks Contains success callback method,
     */
    getReportDetails = (data: { organizationId: string; tableId: string }) => {
        return new Observable((observer) => {
            this._bridge.getReportDetails(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to generate masters report
     *  @param data Contains master tableId, organization ID, transformationName, reportName
     *  @param callbacks Contains success callback method,
     */
    generateMasterReport = (data: { transformationName: string; restApiName: string }) => {
        return new Observable((observer) => {
            this._bridge.generateMasterReport(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get master uploaded Files
     * @param data Contains masterTableId, organization ID,
     * @param callbacks Contains success callback method,
     */

    getMasterUploadFiles = (data: { organizationId: string; masterTableId: string }) => {
        return new Observable((observer) => {
            this._bridge.getMasterUploadFiles(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to check masters records
     */
    checkMasterRecord = (
        data: {
            masterDataRow: {
                columnValues: {
                    [property: string]: string;
                };
                tableId: string;
                unitId: string;
            };
            masterSearchDetail: {
                searchString: string;
                pageNo: number;
            };
            organizationId: string;
        },
        callbacks?: {
            successCallback: (res: MasterRecordsResponse) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.checkMasterRecord(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to Delete master records
     * @param data Contains organizationId, masterRestApiName, masterDataRowKeys
     * @param callbacks Contains success callback method,
     */

    deleteMastersRecords = (data) => {
        return new Observable((observer) => {
            this._bridge.deleteMastersRecords(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get primary column values of a master table
     */
    getPrimaryValuesInMasterTable = (
        payload: {
            masterDataRow: {
                tableId: string;
                unitId: string;
                columnValues: {
                    [property: string]: {
                        newValue: string;
                    };
                };
            };
            masterSearchDetail: {
                searchString: string;
                pageNo: number;
            };
            hideLoader?: boolean;
        },
        callbacks: {
            successCallback: (records: MastersResponseRecord[], searchAfter: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getPrimaryValuesInMasterTable(
            payload,
            (response) => {
                callbacks.successCallback(response?.records, response?.searchAfter || response?.pagingState);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get master tables');
                }
            }
        );
    };

    /**
     * Method to get master record with primary value
     */
    getMasterRecordWithprimaryValue = (
        payload: {
            masterDataRow: {
                tableId: string;
                unitId: string;
                columnValues: {
                    [property: string]: {
                        newValue: string;
                    };
                };
            };
            masterSearchDetail: {
                searchString: string;
                pageNo: number;
            };
        },
        callbacks: {
            successCallback: (records: MastersResponseRecord[], searchAfter: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getMasterRecordWithprimaryValue(
            payload,
            (response) => {
                callbacks.successCallback(response?.records, response?.searchAfter || response?.pagingState);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                }
                this._utils.alertError(res?.msg || 'Failed to get master tables', 10000);
            }
        );
    };

    /**
     * Method to get Master Requests
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    getMasterRequests = (
        data: {
            size: number;
            previousPagingState: any;
            restApiName: string;
            timeRange?: any[];
        },
        callbacks: {
            successCallback: (response: [], pagingState: string, chainNameVsChainDisplayName: any) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getMasterRequests(
            data,
            (res) => {
                callbacks.successCallback(
                    res?.requests?.assetRequests || [],
                    res?.requests?.nextPagingState,
                    res?.chainNameVsChainDisplayName
                );
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                } else {
                    this._utils.alertError(response && response.msg);
                }
            }
        );
    };

    getMasterRequestsObservable = (data: { size: number; previousPagingState: any; restApiName: string; timeRange?: any[] }) => {
        return new Observable<any>((observer) => {
            this._bridge.getMasterRequests(
                data,
                (res) => {
                    observer.next(res?.requests);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get Master record change logs
     * @param data contains orgId, masterRestApiname, primarykeyvalues
     * @param s Success callback
     * @param f Failure callback
     */
    getMasterRecordChangeLogs = (
        data: {
            orgId: string;
            masterRestApiName: string;
            keyValuePairs: any;
        },
        callbacks: {
            successCallback: (
                changes: {
                    fieldId: string;
                    isGridColumn: boolean;
                    modifiedDate: string;
                    newValue: string;
                    oldValue: string;
                    userName: string;
                }[]
            ) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getMasterRecordChangeLogs(
            data,
            (res) => {
                callbacks.successCallback(res?.response);
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                } else {
                    this._utils.alertError(response && response.msg);
                }
            }
        );
    };

    getMasterRecordChangeLogsObservable = (data: { orgId: string; masterRestApiName: string; keyValuePairs: any }) => {
        return new Observable<any>((observer) => {
            this._bridge.getMasterRecordChangeLogs(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };
}
