import { createFeatureSelector, createSelector } from '@ngrx/store';

import { REPORTS_REDUCER_KEY } from '../reducers/reports.reducer';
import { ReportsState } from '../states';

const currentState = createFeatureSelector<ReportsState>(REPORTS_REDUCER_KEY);

export const getReportsMap$ = createSelector(currentState, (state) => state.instanceIdVsReports);

export const getReportsLoadingMap$ = createSelector(currentState, (state) => state.loadingReports);

export const getInstanceReports$ = (instanceId: string) => createSelector(getReportsMap$, (reportsMap) => reportsMap?.[instanceId]);

export const getReportsLoadingStatus$ = (instanceId: string) =>
    createSelector(getReportsLoadingMap$, (loadingMap) => loadingMap?.[instanceId]);
