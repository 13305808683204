import { Permission } from './permission.class';

export class Role {
    id: string;

    displayName: string;

    description: string;

    roleName: string;

    roleType: string;

    organizationId: string;

    permissions?: Array<Permission>;

    roleId?: string;

    uuid?: string;

    actionName?: string;

    objectId?: string;

    category?: string;

    resourceId?: string;

    constructor(roleObject) {
        if (roleObject) {
            if (roleObject.id) {
                this.id = roleObject.id;
            }

            if (roleObject.uuid) {
                this.uuid = roleObject.uuid;
            }

            if (roleObject.displayName) {
                this.displayName = roleObject.displayName;
            }

            if (roleObject.description) {
                this.description = roleObject.description;
            }

            if (roleObject.roleName) {
                this.roleName = roleObject.roleName;
            }

            if (roleObject.roleType) {
                this.roleType = roleObject.roleType;
            }

            if (roleObject.organizationId) {
                this.organizationId = roleObject.organizationId;
            }

            if (roleObject.roleId) {
                this.roleId = roleObject.roleId;
            }

            if (roleObject.actionName) {
                this.actionName = roleObject.actionName;
            }

            if (roleObject.objectId) {
                this.objectId = roleObject.objectId;
            }

            if (roleObject.category) {
                this.category = roleObject.category;
            }

            if (roleObject.resourceId) {
                this.resourceId = roleObject.resourceId;
            }
        }
    }
}
