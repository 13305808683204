<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 mat-dialog-title>MASTER TERMS OF SERVICE - TAXILLA ONLINE SERVICE</h4>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography">
    <div class="EulaBody">
        <p class="terms-p">This Master [Software as a Service] Services Agreement (the “Agreement”) is entered into on August 24, 2017 (the “Effective Date”), by and between and Udyog Software India Limited, a company registered under Indian Companies Act 1956 with registered office address at Office No. 205, Level 2, Wing C, Neelkanth Business Park, Near Vidyavihar Station, Vidyavihar (West), Mumbai – 400086 (“Provider”), and <strong>Customer</strong>, a Company registered under Indian Companies Act 1956, with its registered office at <strong>Address</strong> (the “Customer”). The Provider and the Customer are hereinafter sometimes referred to as the “Parties” and individually as a “Party.”</p>
        <p class="terms-p">1.Definitions. Capitalized terms not otherwise defined in this Agreement shall have the meanings ascribed to them below.</p>
        <p class="terms-p">1.1 "Access Credentials" means any user name, identification number, password, license or security key, security token, PIN or other security code, method, technology or device used, alone or in combination, to verify an individual's identity and authorization to access and use the Application.</p>
        <p class="terms-p">1.2 “Affiliate” of a Party means any company or entity that directly or indirectly, controls, is controlled by, or is under common control with such Party, where “control” means the possession of the power to direct or cause the direction of the management or policies of that company or entity, whether through ownership of voting securities, by agreement, or otherwise.</p>
        <p class="terms-p">1.3 “Application” means each application made available by Company to Customer on the Platform under the terms and conditions this Agreement, which is more fully described in the Offerings List.</p>
        <p class="terms-p">1.4 “Authorized Users” means all users, including but not limited to employees, contractors and agents, authorized by Customer to access the Platform to access the Applications, Service, Software, Products and Services pursuant to Section 3 and the other terms and conditions of this Agreement and as may be specified in the applicable Service Order.</p>
        <p class="terms-p">1.5 “Confidential Information” means any information disclosed by one Party to the other or accessed by the other under this Agreement, which, (i) if in written, graphic, machine readable or other tangible form is marked “Confidential” or “Proprietary” or which, if disclosed orally or by demonstration, is identified at the time of disclosure as confidential and reduced to a writing marked “Confidential” and delivered to the Receiving Party (as defined below) within thirty (30) days of such disclosure; or (ii) by the nature of the circumstances surrounding the disclosure, ought in good faith to be treated as confidential. Notwithstanding any failure to so identify them, all technology or proprietary information underlying the Platform and the Provider System shall be deemed Confidential Information of Provider, and the Customer Data, the identity of the Customer Clients, and the existence of this Agreement shall be deemed Confidential Information of Customer.</p>
        <p class="terms-p">1.6 “Customer Data” means data and other content, in any form or medium that is collected, downloaded or otherwise received, directly or indirectly from Customer or an Authorized User by or through the Application. For the avoidance of doubt, Customer Data includes information reflecting the access or use of the Application by or on behalf of Customer or any Authorized User other than Resultant Data.</p>
        <p class="terms-p">1.7 “Customer’s Systems” means Customer’s information technology infrastructure, including computers, software, hardware, databases, electronic systems (including database management systems) and networks, whether operated directly by the Customer or through the use of third-party services.</p>
        <p class="terms-p">1.8 “Deliverable” means any item to be delivered by Provider to Customer as part of the Services and explicitly identified as a “deliverable” in the applicable Service Order or Statement of Work.</p>
        <p class="terms-p">1.9 “Documentation” means any documentation provided by Provider for use with the Platform under this Agreement.</p>
        <p class="terms-p">1.10 “Harmful Code" means any software, hardware or other technology, device or means, including any virus, worm, malware or other malicious computer code, the purpose or effect of which is to (a) permit unauthorized access to, or to destroy, disrupt, disable, distort, or otherwise harm or impede in any manner any (i) computer, software, firmware, hardware, system or network or (ii) any application or function of any of the foregoing or the security, integrity, confidentiality or use of any data processed thereby, or (b) prevent Customer or any Authorized Users from accessing or using the Services or Provider Systems as intended by this Agreement.</p>
        <p class="terms-p">1.11 “Intellectual Property Rights” means any and all registered and unregistered rights granted, applied for or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.</p>
        <p class="terms-p">1.12 "Losses" means any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs or expenses of whatever kind, including reasonable attorneys' fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.</p>
        <p class="terms-p">1.13 “Marks” means a Party’s corporate or trade name, trademark(s), logo(s), domain names or other identification of such Party.</p>
        <p class="terms-p">1.14 “Offerings List” means Company’s online directory of certain offerings including the Platform, the Applications, Data Converter Applications, the Products, the Complementary Products, and the Services offered by the Provider. The Provider may also list accepted Partner Applications, Data Converter Applications or Complementary Products. The Offerings List is subject to revision by the Company from time to time, such revision may include addition, deletion or change to description, specifications or terms and conditions of an offering. Customer acknowledges that the Offerings List and an offering is subject to change and Customer agrees to refer to Offerings List published online from time to time.</p>
        <p class="terms-p">1.15 “Permitted Use” means any use of the Product by an Authorized User for the benefit of the Customer, Customer Clients solely for their internal business operations.</p>
        <p class="terms-p">1.16 “Platform” means the interface created by Company where the Customer can access the Applications and all changes, corrections, bug fixes, enhancements, updates and other modifications thereto, whether made by or on behalf of Company, Customer, or any third Party.</p>
        <p class="terms-p">1.17 "Process" means to take any action or perform any operation or set of operations that the Application are capable of taking or performing on any data, information or other content, including to collect, receive, input, upload, download, record, reproduce, store, organize, compile, combine, log, catalog, cross-reference, manage, maintain, copy, adapt, alter, translate or make other derivative works or improvements, process, retrieve, output, consult, use, perform, display, disseminate, transmit, submit, post, transfer, disclose or otherwise provide or make available, or block, erase or destroy. "Processing" and "Processed" have correlative meanings</p>
        <p class="terms-p">1.18 “Product(s)” means any Application, Provider Materials, Platform or other Deliverable provided by Provider pursuant to this Agreement, including any interface created by Provider where the Customer and Authorized Users accesses the Platform, branded dashboard (if any) and data streams, and all changes, corrections, bug fixes, enhancements, updates and other modifications thereto, whether made by or on behalf of Provider, Customer, or any third party. Product(s) are fully described in the Offerings List.</p>
        <p class="terms-p">1.19 “Complementary Product(s)” means mean any Application, Provider Materials, Platform or other Deliverable provided by Provider pursuant to this Agreement, that either works as an independent application or as an integrated application with the Platform. Complementary Product(s) may require independent Service Orders.</p>
        <p class="terms-p">1.20 “Provider System” means the information technology infrastructure used by or on behalf of Provider in performing the Services or providing the Application, including all computers, software, hardware, databases, electronic systems and networks, whether operated directly by Provider or through the use of third-party services.</p>
        <p class="terms-p">1.21 “Provider Materials” means the Application, Products, Services Software, Specifications, Documentation and Provider Systems, Provider’s Confidential Information and any and all other proprietary documents, materials, devices, methods, processes, hardware, software and other technologies and inventions, technical or functional descriptions, requirements, plans or reports of Provider, that are provided or used by or on behalf of Provider or any Provider personnel in connection with the Application or relate to the Services or Provider Systems.</p>
        <p class="terms-p">1.22 "Resultant Data" means information, data and other content that is derived by or through the Application from Processing Customer Data and is sufficiently different from such Customer Data that such Customer Data cannot be reverse engineered or otherwise identified from the inspection, analysis or further Processing of such information, data or content.</p>
        <p class="terms-p">1.23 “Service Order” or “SO” means any service order described in Section 2, and in materially the form of Exhibit B, attached hereto or another form agreed to by the Parties in writing.</p>
        <p class="terms-p">1.24 “Service Level Agreement” means the service level agreement defining the uptime and support availability for the Platform and Services as described in as Exhibit C, attached hereto.</p>
        <p class="terms-p">1.25 “Services” means any maintenance, training, configuration, data extraction, data feed, support, hosting, professional, and any other service provided by Provider to Customer as identified in a Service Order or a Statement of Work in the form of Exhibit D attached hereto or another form agreed to by the Parties in writing.</p>
        <p class="terms-p">1.26 “Service Software” means Provider’s proprietary software application or applications and any third party software (and any computer program or module related thereto), including all Documentation, new versions, updates, enhancements, upgrades, revisions, improvements and modifications of the foregoing that Provider provides remote access to and use of as part of the Services which is specified in each Service Order or Statement of Work entered into pursuant to this Agreement.</p>
        <p class="terms-p">1.27 “Specifications” means the requirements for the Application, Service Software, the Provider Materials, and the Services provided in the applicable Documentation, Statement of Work, or Service Order.</p>
        <p class="terms-p">1.28 “Statement of Work” or “SOW” means any statement of work that describes the work and Deliverables, agreed to in writing by the Parties, to be provided by Provider to Customer under the terms and conditions of this Agreement.</p>
        <p class="terms-p">1.29 “Territory” means India.</p>
        <p class="terms-p">1.30 “Third Party Materials” means materials and information, in any form or medium, including any open-source or other software, documents, data, content, specifications, products, equipment or components of or relating to the Products that are not proprietary to Provider.</p>
        <p class="terms-p">2. Service Orders; Statement of Work.</p>
        <p class="terms-p">2.1 Service Orders. From time to time during the Term of this Agreement, Customer may request Provider to provide certain Products and Services to Customer for the Customer or for the Customer to provide data processing services or tax professional services to Customer’s Client(s), and Provider may provide Customer access and a subscription license to Product(s) and provide Customer the Services subject to the terms and conditions of this Agreement. Any rights granted shall not be effective until the Parties have executed a Service Order, a Service Order shall be required for each Customer Client Organization to subscribe for Product(s) on the Platform, the form of which is attached hereto as Exhibit B. A Service Order can consist only Product(s) mentioned in Exhibit A attached hereto, which may be a subset of Offerings List that are applicable to the Territory and available for the Customer to subscribe from. The description, specifications and other pertinent details of any Product(s) listed in Exhibit A are subject to update or availability as per the Offerings List.</p>
        <p class="terms-p">2.2 Statement of Work. From time to time during the Term of this Agreement, Customer may request Provider to provide certain additional Services or change request Services to Customer for the Customer or for the Customer’s client(s), and Provider may provide implementation services integration services, data transformation services, change order services or other professional services the form of which shall be agreed to by the Parties subject to the terms and conditions of this Agreement. Any rights granted shall not be effective until the Parties have executed a Statement of Work, the form of which is attached hereto as Exhibit D.</p>
        <p class="terms-p">2.3 Each Service Order and Statement of Work must be signed by Customer and Provider and contain, at a minimum: (w) a description of the Applications, Products and the Services to be licensed or provided for access to use; (x) the time period in which such licenses or Services are to be in effect; (y) the fees to be paid under the Service Order or the Statement of Work (the “Fees”); and (z) any other duties and fees associated with such license or Services. Each Service Order or Statement of Work executed shall constitute a separate agreement that is subject to the terms and conditions of this Agreement and shall be binding, when duly executed by each of the Parties hereto, upon such Parties and their respective successors, legal representatives and permitted assigns. To the extent any provision in a Service Order or Statement of Work conflicts with any provision in this Agreement, the provision in the Service Order or Statement of Work will control.</p>
        <p class="terms-p">3. Access to Services.</p>
        <p class="terms-p">3.1 Subscription License Grant. Subject to and conditioned on Customer’s and its Authorized Users’ compliance with the terms and conditions of this Agreement, Provider hereby grants during the Term to Customer, and Customer hereby accepts, a subscription, limited, non-exclusive, non-transferrable, non-sublicensable, non-assignable license to access the Application, Service Software and Provider Materials as Provider may supply or make available to Customer solely for the Permitted Use by and through Customer’s Authorized Users in accordance with the Specifications. The license granted hereunder shall apply to Customer in the Territory. The Application, the Platform, the Service Software and Provider Materials may be used by Customer and its Authorized Users under the terms and conditions of this Agreement. Provider shall use commercially reasonable efforts to provide to Customer and its Authorized Users the Application, Service Software, Provider Materials, and the Services as described in a Service Order in accordance with the Specifications and the conditions hereof, including to host, manage, operate and maintain the Application, Service Software for remote electronic access and use by Customer and its Authorized Users in substantial conformity with the Specifications, such access to be provided twenty-four (24) hours per day, seven (7) days per week every day of the year except for:</p>
        <ul class="list_style">
            <li>(i) Scheduled Downtime in accordance with the Service Level Agreement;</li>
            <li>(ii) Application, Platform or Service Software downtime or degradation due to a Force Majeure Event in accordance with Exhibit C;</li>
            <li>(iii) Any other circumstances beyond Provider’s reasonable control, including Customer’s or any Authorized User’s misuse of Third Party Materials or use of the Application, the Platform or the Service Software other than in compliance with the expressly stated terms and conditions of this Agreement and the Specifications; and
            </li>
            <li>(iv) Any suspension or termination of Customer’s or any Authorized User’s access to or use of the Application, the Platform or the Service Software as permitted by this Agreement.</li>
            <p class="terms-p">3.2 Provider System Control. Except as otherwise expressly provided in this Agreement, as between the parties:</p>
            <ul class="list_style">
                <li>(i) Provider has and will retain sole control over the operation, provision, maintenance and management of the Application, Platform, Service Software and Provider Materials, including the: (a) Provider Systems; (b) location(s) where any of the Services are performed, provided that it is in the Territory unless approved by Customer; (c) selection, deployment, modification and replacement of the Service Software, provided that Provider will not modify the Application or the Service Software to remove any material features and will not modify any feature that is Customer Client facing without prior written notice to the Customer; and (d) performance of support Services and Service maintenance, upgrades, updates, corrections and repairs; and
                </li>
                <li>(ii) Customer has and will retain sole control over the operation, maintenance and management of, and all access to and use of, the Customer Systems, and sole responsibility for all access to and use of the Application, Platform, Service Software and Provider Materials by any Authorized Users by or through the Customer Systems or any other means controlled by Customer or any Authorized User.
                </li>
                <li>(iii) Provider will have the right to review and monitor all use of the Provider System by Customer and its Authorized Users to ensure compliance with all of the terms of the Documentation and this Agreement.</li>
                <p class="terms-p">3.3 Changes. Provider reserves the right, in its sole discretion, to make any changes to the Application, Platform, Service Software and Provider Materials that it deems necessary or useful to: (a) maintain or enhance</p>
                <ul class="list_style">
                    <li>(i) the quality or delivery of Provider's services to its customers,</li>
                    <li>(ii) the competitive strength of or market for Provider's services or</li>
                    <li>(iii) the Application’s, Platform’s, Service Software’s cost efficiency or performance, provided that in each case, Provider will not make any changes to remove any material features or make any changes to any feature that is Customer Client facing, without prior written notice to the Customer; or (b) to comply with applicable law. Without limiting the foregoing, either Party may, at any time during the Term, request in writing changes to the Application, Platform or Service Software but any such changes will not be implemented unless mutually agreed to in writing. Whether or not Customer’s prior written notice is required, Provider will notify the Customer in advance in writing of any changes to the Service Software or Provider Material that may have a material impact on Customer or Customer Client. </li>
                    <p class="terms-p">3.4 Reservation of Rights. Except for the license and access rights granted in Section 3.1 for the term set forth in a Service Order, nothing in this Agreement grants any title or ownership interest in or to any Intellectual Property Rights in or relating to, the Application, Platform, the Service Software, Provider Materials or Third Party Materials, whether expressly, by implication, estoppel or otherwise. All title and ownership interest in and to the Services, the Application, Platform, Service Software, the Provider Materials and the Third Party Materials are and will remain with Provider and the respective rights holders in the Third Party Materials.</p>
                    <p class="terms-p">3.5 Authorization Limitations and Restrictions. Customer’s access to and use of the Service Application, Platform, Software (or any Provider Materials) is subject to the restrictions in this Section 3.5. Customer agrees to be bound by the actions of its Authorized Users and shall not permit any Authorized User to access or use the Application, Platform, Service Software or the Provider Materials except as expressly permitted by this Agreement and, in the case of Third Party Materials that require, by their terms, a separate license agreement, such third-party license agreement shall be provided to and agreed to in writing by Customer prior to Customer being subject to its terms. The Parties agree and the Customer acknowledges that a violation of this Section 3.5 shall be deemed a material breach of this Agreement. For purposes of clarity and without limiting the generality of the foregoing, Customer or its Authorized Users shall not, except as this Agreement expressly permits:</p>
                    <ul class="list_style">
                        <li>(i) copy, modify or create derivative works or improvements of the Application, Service Software or Provider Materials, except that, in the case of Documentation, Customer may create a reasonable number of copies of the written Documentation and training materials for training purposes;</li>
                        <li>(ii) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available any Application, Service Software or Provider Materials to any third party, including on or in connection with the internet or any time-sharing, service bureau, software as a service, cloud or other technology or service;</li>
                        <li>(iii) reverse-engineer, disassemble, decompile, decode, adapt or otherwise attempt to derive or gain access to the source code of the Application, Platform, Service Software or Provider Materials, in whole or in part;</li>
                        <li>(iv) bypass or breach any security device or protection used by the Application, Platform, Service Software or Provider Materials or access or use the Application, Platform, Service Software or Provider Materials other than by an Authorized User through the use of his or her own then valid Access Credentials;</li>
                        <li>(v) input, upload, transmit or otherwise provide to or through the Application, Platform, Service Software or Provider Systems, any information or materials that Customer knows to be unlawful or injurious, or contain, transmit or activate any Harmful Code;</li>
                        <li>(vi) damage, destroy, disrupt, disable, impair, interfere with or otherwise impede or harm in any manner the Application, Platform, Service Software, Provider Systems or Provider's provision of services to any third party;</li>
                        <li>(vii) remove, delete, alter or obscure any trademarks, Specifications, Documentation, warranties or disclaimers, or any copyright, trademark, patent or other intellectual property or proprietary rights notices from any Services or Provider Materials, including any copy thereof, provided that Provider will not include its name or trademark or any proprietary rights notices in any feature that is Customer Client facing without Customer’s prior written consent;</li>
                        <li>(viii) access or use the Application, Platform, Service Software or Provider Materials in any manner or for any purpose that violates any applicable law;</li>
                        <li>(ix) access or use the Service Software to communicate any message or material that is harassing, libelous, threatening, obscene or would violate the copyright or other intellectual property right or privacy right of any person or is otherwise unlawful or that would give rise to civil liability or that constitutes or encourages conduct that could constitute a criminal offense under any applicable law or regulation;</li>
                        <li>(x) access or use the Application, Platform, Service Software or Provider Materials for purposes of competitive analysis of the Application, Service Software or Provider Materials, the development, provision or use of a competing software service or product or any other purpose that is to the Provider’s detriment or commercial disadvantage; or</li>
                        <li>(xi) otherwise access or use the Application, Platform, Service Software or Provider Materials beyond the scope of the authorization set forth in this Agreement or in any manner or for any purpose that is unlawful under applicable law.</li>
                        <p class="terms-p">3.6 Works for Hire. The Parties do not contemplate the development by Provider of any custom, developed Deliverables for Customer. In the event Customer requests custom development of deliverables by Provider in the future, the Parties will agree to the governing terms at that time. Notwithstanding the foregoing, any suggestions or product feedback rendered by a Customer relating to the Services shall not be considered “works made for hire” or comparable terms, as defined in the Copyright Act or other central or state laws of applicable jurisdiction within the Territory. Unless otherwise agreed to in writing by the Provider, all rights in and to the Applications, Platform, Deliverables, Services and Provider Materials (including Third-Party Materials) are expressly reserved by Provider and the respective third-party licensors. Unless specifically specified in a Service Order or Statement of Work, no work for hire is being provided under this Agreement. In furtherance of the foregoing, Customer hereby unconditionally and irrevocably grants to Provider an unrestricted license to use any feedback or suggestions (excluding any Customer Data included therein) given to Provider, including all Intellectual Property Rights relating thereto.</p>
                        <p class="terms-p">4. Customer Obligations.</p>
                        <p class="terms-p">4.1 Customer Systems and Cooperation. Customer shall at all times during the Term: (a) set up, maintain and operate in good condition, repair and in accordance with the Specifications all Customer Systems on or through which the Services are accessed or used; (b) provide Provider personnel with such access to Customer's premises and Customer’s Systems as may be reasonably necessary for Provider to perform the Services in accordance with the terms of this Agreement; and (c) provide all cooperation and assistance as Provider may reasonably request to enable Provider to exercise its rights and perform its obligations hereunder.</p>
                        <p class="terms-p">4.2 Effect of Customer Failure or Delay. Provider is not responsible or liable for any delay or failure of performance caused in whole or in part by Customer's delay in performing, or failure to perform, any of its obligations under this Agreement (each, a "Customer Failure"). In such case, Provider will be given an extension of time equal to the number of days delayed by Customer to perform Provider’s obligations. </p>
                        <p class="terms-p">4.3 Corrective Action and Notice. If Customer becomes aware of any actual or threatened prohibited activity by Customer or any Authorized User, Customer shall, and shall cause its Authorized Users to, immediately: (a) take all reasonable and lawful measures that are necessary to stop the activity or threatened activity and to mitigate its effects (including, where applicable, by discontinuing and preventing any unauthorized access to the Application, Platform, Service Software and Provider Materials and permanently erasing from their systems and destroying any data to which any of them have gained unauthorized access); and (b) notify Provider of any such actual or threatened activity.</p>
                        <p class="terms-p">4.4 Non-Solicitation. During the Term and for 1 year thereafter, Customer shall not, and shall not directly or indirectly recruit or solicit (other than by general advertisement not directed specifically to any for employment) or engagement as an independent contractor any person then or within the prior 6 months employed or engaged by Provider or any Subcontractor and involved in any respect with the Products or the performance of this Agreement. In the event of a violation of this Section 4.4, Provider will be entitled to liquidated damages equal to the compensation paid by Provider to the applicable employee or contractor during the prior 24 months.</p>
                        <p class="terms-p">5. Acceptance Testing. Any Services made available by Provider shall be subject to acceptance testing as described in a Statement of Work or Service Order (“Acceptance Testing”). </p>
                        <p class="terms-p">5.1 Acceptance. Provider will notify Customer when it believes it has completed a Milestone (as may be defined in the applicable Service Order or a Statement of Work) or a Deliverable (as may be defined in the applicable Service Order or Statement of Work). Customer will have 15 days to evaluate whether the Milestone or Deliverable is complete according to the applicable Specifications in the applicable Service Order. If Customer believes that a Milestone or Deliverable is not complete according to the applicable Specifications, Customer will promptly provide a written notice of rejection to Provider specifying in reasonable detail the basis for Customer’s rejection. In the event Customer has not provided a written notice of rejection within 15 days after Provider’s notification of completion of a Milestone or Deliverable, the Milestone or Deliverable shall be deemed accepted. Upon receipt of a written notice of rejection, Provider shall make commercially reasonable efforts to complete the Milestone or Deliverable within 30 days, and the completion of the Milestone or Deliverable will again be subject to the acceptance procedure described above.</p>
                        <p class="terms-p">5.2 Changes. Any Customer request to alter the Specifications for Services in a Service Order may be subject to additional fees as mutually agreed upon by the Parties.</p>
                        <p class="terms-p">6. Payment; Taxes.</p>
                        <p class="terms-p">6.1 Fees. Customer shall pay Provider the Fees in the amount set forth in the applicable Service Order in accordance with the terms set forth in this Section 6 and as may be specified in the Service Order. The Fees are non-refundable and not subject to allocation.</p>
                        <p class="terms-p">6.2 Taxes. Customer shall, in addition to the other amounts payable under this Agreement, pay all applicable customs, duties, sales, use, value added or other taxes, central (federal), state or otherwise, however designated, which are levied or imposed by reason of the transactions contemplated by this Agreement, excluding only taxes based on Provider’s net income. Customer agrees to indemnify, defend, and hold Provider, its officers, directors, consultants, employees, successors and assigns harmless from all claims and liability arising from Customer’s failure to report or pay any such taxes, duties or assessments.</p>
                        <p class="terms-p">6.3 Payment Terms. Customer shall pay all Fees and any reimbursable expenses within 30 days after the date of the invoice therefor. Customer shall make all payments hereunder in the Territory currency by electronic payment, wire transfer or cheque (check). Customer shall make payments to the address or account as the Parties agree to and may specify in writing from time to time.</p>
                        <p class="terms-p">6.4 Late Payment. If Customer fails to make any payment when due then, in addition to all other remedies that may be available, Provider may charge interest on the past due amount at the rate of 1.5% per month, or the maximum allowable under applicable law, whichever is more. Customer shall reimburse Provider for all reasonable costs incurred by Provider in collecting any late payments or interest, including attorneys' fees, court costs and collection agency fees; and if such failure to pay continues for thirty days following written notice thereof, Provider may suspend performance of the Application, Platform or Service Software until all past due amounts have been paid, without incurring any obligation or liability to Customer or any other person by reason of such suspension.</p>
                        <p class="terms-p">6.5 No Deductions or Setoffs. All amounts payable to Provider under this Agreement shall be paid by Customer to Provider in full without any setoff, recoupment, counterclaim, deduction, debit or withholding for any reason or deduction or withholding of tax as may be required by applicable Law.</p>
                        <p class="terms-p">7. Intellectual Property Rights.</p>
                        <p class="terms-p">7.1 Services and Provider Materials. All right, title and interest in and to the Products, Applications, Service Software and Provider Materials, any changes, corrections, bug fixes, enhancements, customizations, updates and other modifications thereto including all Intellectual Property Rights therein, are and will remain with Provider and the respective rights holders in the Third-Party Materials. Customer has no right, license or authorization with respect to any of the Applications, Platform, Service Software or Provider Materials except as expressly set forth in Section 3.1 in each case subject to Section 3.3 All other rights in and to the Application, Platform, Service Software and Provider Materials are expressly reserved by Provider and the respective third-party licensors. In furtherance of the foregoing, Customer hereby unconditionally and irrevocably grants to Provider an assignment of all right, title and interest in and to the Resultant Data, including all Intellectual Property Rights relating thereto.</p>
                        <p class="terms-p">7.2 Customer Data. As between Customer and Provider, Customer is and will remain the sole and exclusive owner of all right, title and interest in and to all Customer Data, including all Intellectual Property Rights relating thereto, subject to the rights and permissions granted in Section</p>
                        <p class="terms-p">7.3 Consent to Use Customer Data. Subject to the terms and conditions of this Agreement, the Customer hereby grants Provider a limited, non-transferable, non-exclusive, non-sub licensable, royalty-free license during the Term to use, reproduce, electronically distribute, transmit, have transmitted, perform, display, store, archive, and make derivative works of the Customer Data solely in order to enable the Platform to use the Provider System solely for purposes of providing the Products and the Services to Customer. Provider shall have no right to use the Customer Data for any other purpose or share the Customer Data with anyone other than the Customer.</p>
                        <p class="terms-p">7.4 Limited Trademark License; Marketing Materials. Customer hereby grants Provider a royalty-free, non-exclusive, non-transferable, non-sublicensable, limited term license to use Customer’s Marks solely for the purpose of aligning the appearance of the Product to Customer’s branding and only as specifically authorized by, and subject to any restrictions stated in, this Agreement. Such license shall be limited to the duration of this Agreement. During the Term of the Agreement, Provider may include Customer in any of Provider’s customer lists and testimonials, solely for the purpose of identifying Customer as a customer of Provider. Provider shall not use any of Customer’s Marks in any manner that Customer, in its sole discretion, deems to be an explicit or implicit endorsement of Provider, or which is likely to cause confusion as to Customer’s relationship to Provider. Customer and Provider acknowledge that the provisions of this paragraph do not convey any right, title or ownership interest in Customer’s Marks to Provider.</p>
                        <p class="terms-p">8. Representations and Warranty.</p>
                        <p class="terms-p">8.1 Provider Warranty. Provider warrants to Customer that during the Term, (a) the Products and the Services will perform substantially in accordance with the terms of Documentation, (b) Provider has the right to grant the license granted under this Agreement, and (c) Provider has the necessary resources, expertise, and personnel to perform the Services in a professional manner according to the terms and conditions of this Agreement, the Service Order, and the Statement of Work. The foregoing warranty in clause (a) shall not apply to performance issues of the Provider System (i) caused solely by factors outside of Provider’s reasonable control; (ii) that result solely from any improper actions or inactions of Customer, its Authorized Users, or any third parties; or (iii) that result solely from Customer’s data structure, operating environment or equipment.</p>
                        <p class="terms-p">8.2 Customer Representations, Warranties and Covenants. Customer represents, warrants and covenants to Provider that Customer owns or otherwise has and will have the necessary rights and consents in and relating to the Customer Data so that, as received by Provider and processed in accordance with this Agreement, they do not and will not infringe, misappropriate or otherwise violate any Intellectual Property Rights, or any privacy or other rights of any third party or violate any applicable law.</p>
                        <p class="terms-p">8.3 Disclaimer of Any Other Warranties. EXCEPT FOR THE EXPRESS WARRANTIES PROVIDED IN THIS SECTION 8, ALL APPLICATIONS, THE PLATFORM, THE SERVICE SOFTWARE AND PROVIDER MATERIALS ARE PROVIDED "AS IS" AND PROVIDER HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHER, AND PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE APPLICATIONS, THE PLATFORM, THE SERVICE SOFTWARE OR PROVIDER MATERIALS, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES EXCEPT IF AND TO THE EXTENT EXPRESSLY SET FORTH IN THE SPECIFICATIONS, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE OR ERROR FREE. ALL THIRD-PARTY MATERIALS ARE PROVIDED "AS IS" AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.</p>
                        <p class="terms-p">9. Term; Termination.</p>
                        <p class="terms-p">9.1 Term. The term of each Service Order or Statement of Work shall be as set forth in such Service Order or Statement of Work. Unless terminated earlier in accordance with the termination rights set forth in this Section 9, this Agreement shall commence upon the Effective Date and remain in full force and effect for three years or until all Service Orders and Statement of Works associated herewith have expired or terminated (the “Agreement Term”). Unless otherwise indicated in the Service Order or the Statement of work, the term of the Service Order or the Statement of Work shall be three years (the “Initial Term”).</p>
                        <p class="terms-p">9.2 Additional Terms. Unless a Service Order or Statement of Work is terminated in accordance with this Section 9 or as set forth in the Service Order or Statement of Work, the Service Order or Statement of Work shall automatically continue in effect for additional terms (each an “Additional Term” and, with the Initial Term, the “Term” ). Each Additional Term shall be the same length as the Initial Term set forth in such Service Order or Statement of Work. The Provider may increase the fees by up to 10% upon written notice to the Customer at least 30 days before the end of the applicable Term.</p>
                        <p class="terms-p">9.3 Termination.</p>
                        <ul class="list_style">
                            <li>(i) By Either Party. This Agreement or a Service Order or a Statement of Work may be terminated by either Party upon delivery of written notice of termination to the other Party, as follows:</li>
                            (a) if the other Party fails to perform or observe any material term or condition in this Agreement or the Service Order or the Statement of Work and fails to cure such breach within thirty (30) days after receipt of written notice of such breach from the non-breaching Party; or (b) if the other Party (i) makes a general assignment for the benefit of creditors,
                            <li> (ii) admits in writing its inability to pay debts as they come due,</li>
                            <li> (iii) voluntarily files a petition or similar document initiating any bankruptcy or reorganization proceeding, or </li>
                            <li>(iv) involuntarily becomes the subject of a petition in bankruptcy or reorganization proceeding and such proceeding shall not have been dismissed or stayed within sixty (60) days after such filing; (c) thirty (30) days prior to the end of a Term; or (d) pursuant to any termination clauses in a Service Order or Statement of Work. (ii) Effect of Termination.</li>
                            <p class="terms-p">10. Confidentiality</p>
                            <p class="terms-p">10.1 Confidential Information. In connection with this Agreement each party (as the "Disclosing Party") may disclose or make available Confidential Information to the other party (as the "Receiving Party"). Subject to Section 10.2, "Confidential Information" means information in any form or medium (whether oral, written, electronic or other) that the Disclosing Party considers confidential or proprietary, including information consisting of or relating to the Disclosing Party’s technology, trade secrets, know-how, business operations, plans, strategies, customers, and pricing, and information with respect to which the Disclosing Party has contractual or other confidentiality obligations, in each case whether or not marked, designated or otherwise identified as "confidential". Without limiting the foregoing: all Provider Materials are the Confidential Information of Provider and the financial terms and existence of this Agreement are the Confidential Information of each of the parties. </p>
                            <p class="terms-p">10.2 Exclusions. Confidential Information does not include information that the Receiving Party can demonstrate by written or other documentary records: (a) was rightfully known to the Receiving Party without restriction on use or disclosure prior to such information's being disclosed or made available to the Receiving Party in connection with this Agreement; (b) was or becomes generally known by the public other than by the Receiving Party's or any of its Representatives' noncompliance with this Agreement; (c) was or is received by the Receiving Party on a non-confidential basis from a third party that was not or is not, at the time of such receipt, under any obligation to maintain its confidentiality; or (d) the Receiving Party can demonstrate by written or other documentary records was or is independently developed by the Receiving Party without reference to or use of any Confidential Information.</p>
                            <p class="terms-p">10.3 Protection of Confidential Information. As a condition to being provided with any disclosure of or access to Confidential Information, the Receiving Party shall for a period of five (5) years:</p>
                            <ul class="list_style">
                                <li>(i) not access or use Confidential Information other than as necessary to exercise its rights or perform its obligations under and in accordance with this Agreement; </li>
                                <li>(ii) except as may be permitted by and subject to its compliance with Section 10.4, not disclose or permit access to Confidential Information other than to its Representatives who: (i) need to know such Confidential Information for purposes of the Receiving Party's exercise of its rights or performance of its obligations under and in accordance with this Agreement; (ii) have been informed of the confidential nature of the Confidential Information and the Receiving Party's obligations under this Section 10.3; and (iii) are bound by confidentiality and restricted use obligations at least as protective of the Confidential Information as the terms set forth in this Section</li>
                                <p class="terms-p">10.3; (iii) safeguard the Confidential Information from unauthorized use, access or disclosure using at least the degree of care it uses to protect its similarly sensitive information and in no event less than a reasonable degree of care; and (iv) ensure its representatives' compliance with, and be responsible and liable for any of its representatives' non-compliance with, the terms of this Section 10.</p>
                                <p class="terms-p">10.4 Compelled Disclosures. If the Receiving Party is compelled by applicable law to disclose any Confidential Information then, to the extent permitted by applicable law, the Receiving Party shall: (a) promptly, and prior to such disclosure, notify the Disclosing Party in writing of such requirement so that the Disclosing Party can seek a protective order or other remedy or waive its rights under Section 10.3; and (b) provide reasonable assistance to the Disclosing Party in opposing such disclosure or seeking a protective order or other limitations on disclosure. If the Disclosing Party waives compliance or, after providing the notice and assistance required under this Section 10.4, the Receiving Party remains required by law to disclose any Confidential Information, the Receiving Party shall disclose only that portion of the Confidential Information that the Receiving Party is legally required to disclose and, on the Disclosing Party's request, shall use commercially reasonable efforts to obtain assurances from the applicable court or other presiding authority that such Confidential Information will be afforded confidential treatment.</p>
                                <p class="terms-p">10.5 Nondisclosure. Each Party (each a “Receiving Party”) agrees that it</p>
                                <ul class="list_style">
                                    <li>(i) shall use and reproduce the Confidential Information of the other Party (the “Disclosing Party”) only for purposes of exercising its rights and performing its obligations under this Agreement and only to the extent necessary for such purposes, </li>
                                    <li>(ii) shall restrict disclosure of such Confidential Information to the Receiving Party’s employees, consultants, or advisors who have a bona fide need to know for such purposes, and</li>
                                    <li> (iii) shall not disclose such Confidential Information to any third party without the prior written approval of the Disclosing Party. The foregoing obligations shall be satisfied by the Receiving Party through the exercise of at least the same degree of care used to restrict disclosure and use of its own information of like importance, but not less than reasonable care. All third parties to whom the Receiving Party discloses Confidential Information must be bound in writing by obligations of confidentiality and non-use at least as protective of such information as this Agreement. Notwithstanding the foregoing, it shall not be a breach of this Agreement for the Receiving Party to disclose Confidential Information if compelled to do so under law, in a judicial or other governmental investigation or proceeding, provided that, to the extent permitted by law, the Receiving Party has given the Disclosing Party prior notice and reasonable assistance to permit the Disclosing Party a reasonable opportunity to object to and/or limit the judicial or governmental requirement to disclosure.</li>
                                    <p class="terms-p">10.6 Exceptions. Notwithstanding anything to the contrary herein, the Receiving Party shall not be liable for using or disclosing information that the Receiving Party can prove:</p>
                                    <p class="terms-p">10.5 Nondisclosure. Each Party (each a “Receiving Party”) agrees that it</p>
                                    <ul class="list_style">
                                        <li>(i) was in the public domain at the time it was disclosed or has entered the public domain through no fault of the Receiving Party; </li>
                                        <li>(ii) was rightfully known to the Receiving Party, without restriction, at the time of disclosure, as demonstrated by files in existence at the time of disclosure; </li>
                                        <li>(iii) is disclosed with the prior written approval of the Disclosing Party; (iv) was independently developed by the Receiving Party without any use of the Confidential Information, as demonstrated by files created at the time of such independent development; (v) becomes rightfully known to the Receiving Party, without restriction, from a source other than the Disclosing Party without breach of this Agreement by the Receiving Party and otherwise not in violation of the Disclosing Party’s rights; or</li>
                                        <li> (vi) is disclosed generally to third parties by the Disclosing Party without restrictions similar to those contained in this Agreement.</li>
                                        <p class="terms-p">10.7 Remedies. The Receiving Party agrees that a breach of this Section 10 may result in immediate and irreparable harm to the Disclosing Party that money damages alone may be inadequate to compensate. Therefore, in the event of such a breach, the Disclosing Party will be entitled to seek equitable relief, including but not limited to a temporary restraining order, temporary injunction or permanent injunction without the posting of a bond or other security.</p>
                                        <p class="terms-p">11. Limitation on Damages.</p>
                                        <p class="terms-p">11.1 EXCLUSION OF INCIDENTAL AND CONSEQUENTIAL DAMAGES. EXCEPT FOR BREACH OF SECTION 10 (“CONFIDENTIALITY”) AND INDEMNIFICATION FOR THIRD-PARTY DAMAGES ARISING UNDER SECTION 12 OF THIS AGREEMENT, IN NO EVENT WILL PROVIDER OR ANY OF ITS LICENSORS, SERVICE PROVIDERS OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, FOR ANY: (a) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT OR DIMINUTION IN VALUE; (b) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICES, OTHER THAN FOR THE ISSUANCE OF ANY APPLICABLE SERVICE CREDITS PURSUANT TO ,(c) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY, OR (d) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</p>
                                        <p class="terms-p">11.2 CAP ON MONETARY LIABILITY. EXCEPT AS OTHERWISE PROVIDED IN SECTION 11.3, IN NO EVENT WILL THE AGGREGATE LIABILITY OF PROVIDER UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE AGGREGATE AMOUNT OF THE DISCRETE VALUE OF SERVICES PROVIDED UNDER THIS AGREEMENT IN THE TWELVE-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO LIABILITY. CUSTOMER ACKNOWLEDGES THAT THE AMOUNTS PAYABLE HEREUNDER ARE BASED IN PART ON THESE LIMITATIONS. THE PARTIES AGREE THAT THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</p>
                                        <p class="terms-p">11.3 Exceptions. The exclusions and limitations in Section 11.1 and Section 11.2 do not apply to Provider's obligations under Section 12 (Indemnification) or liability for Provider's gross negligence or willful misconduct.</p>
                                        <p class="terms-p">12. Indemnification.</p>
                                        <p class="terms-p">12.1 Provider Indemnification. Provider shall indemnify, defend and hold harmless Customer and Customer's officers, directors, employees, agents, permitted successors and assigns (each, a "Customer Indemnitee") from and against any and all Losses incurred by such Customer Indemnitee arising out of or relating to any claim, suit, action or proceeding (each, an "Action") by a third party (other than an Affiliate of a Customer Indemnitee) to the extent that such Losses arise from any allegation in such Action that Customer's or an Authorized User's use of the Services (excluding Customer Data and Third Party Materials) in compliance with this Agreement (including the Specifications) infringes an Intellectual Property Right or other federal/central or state laws as per the jurisdiction of this Agreement. The foregoing obligation does not apply to any Action or Losses arising out of or relating to any:</p>
                                        <ul class="list_style">
                                            <li>(i) access to or use of the Services or Provider Materials in combination with any hardware, system, software, network or other materials or service not provided or authorized in writing by Provider;</li>
                                            <li>(ii) modification of the Services or Provider Materials other than: (i) by or on behalf of Provider; or (ii) with Provider's written approval in accordance with Provider's written specification;</li>
                                            <li>(iii) failure to timely implement any modifications, upgrades, replacements or enhancements made available to Customer by or on behalf of Provider; or</li>
                                            <li>(iv) act, omission or other matter described in Section 12.2(i), Section 12.2(ii), Section 12.2(iii) or Section 12.2(iv), whether or not the same results in any Action against or Losses by any Provider Indemnitee.</li>
                                            <p class="terms-p">12.2 Customer Indemnification. Customer shall indemnify, defend and hold harmless Provider and its officers, directors, employees, agents, successors and assigns (each, a "Provider Indemnitee") from and against any and all Losses incurred by such Provider Indemnitee in connection with any Action by a third party (other than an Affiliate of a Provider Indemnitee) to the extent that such Losses arise out of or relate to any: </p>
                                            <ul class="list_style">
                                                <li>(i) Customer Data, including any processing of Customer Data by or on behalf of Provider in accordance with this Agreement;</li>
                                                <li>(ii) any other materials or information (including any documents, data, specifications, software, content or technology) provided by or on behalf of Customer or any Authorized User, including Provider's compliance with any specifications or directions provided by or on behalf of Customer or any Authorized User to the extent prepared without any contribution by Provider; </li>
                                                <li>(iii) allegation of facts that, if true, would constitute Customer's breach of any of its representations, warranties, covenants or obligations under this Agreement; or</li>
                                                <li>(iv) gross negligence or more culpable act or omission (including recklessness or willful misconduct) by Customer, any Authorized User, or any third party on behalf of Customer or any Authorized User, in connection with this Agreement.</li>
                                                <p class="terms-p">12.3 Indemnification Procedure. Each Party shall promptly notify the other party in writing of any Action for which such party believes it is entitled to be indemnified pursuant to Section 12.1 or Section 12.2, as the case may be. The party seeking indemnification (the "Indemnitee") shall cooperate with the other Party (the "Indemnitor") at the Indemnitor's sole cost and expense. The Indemnitor shall immediately take control of the defense and investigation of such Action and shall employ counsel of its choice to handle and defend the same, at the Indemnitor's sole cost and expense. The Indemnitee's failure to perform any obligations under this Section 12.3 will not relieve the Indemnitor of its obligations under this Section 12 except to the extent that the Indemnitor can demonstrate that it has been materially prejudiced as a result of such failure. The Indemnitee may participate in and observe the proceedings at its own cost and expense with counsel of its own choosing.</p>
                                                <p class="terms-p">12.4 Mitigation. If any of the Services or Provider Materials are, or in Provider's opinion are likely to be, claimed to infringe, misappropriate or otherwise violate any third-party Intellectual Property Right, or if Customer's or any Authorized User's use of the Services or Provider Materials is enjoined or threatened to be enjoined, Provider may, at its option and sole cost and expense: </p>
                                                <ul class="list_style">
                                                    <li>(i) obtain the right for Customer to continue to use the Services and Provider Materials as contemplated by this Agreement; </li>
                                                    <li>(ii) modify or replace the Services and Provider Materials, in whole or in part, to seek to make the Services and Provider Materials (as so modified or replaced) non-infringing, while providing equivalent features and functionality, in which case such modifications or replacements will constitute Services and Provider Materials, as applicable, under this Agreement; or </li>
                                                    <li>(iii) by written notice to Customer, terminate this Agreement and require Customer to immediately cease any use of the Services and Provider Materials or any specified part or feature thereof, provided that if such termination occurs prior to 2 years after the Effective Date, subject to Customer's compliance with its post-termination obligations, Customer will be entitled to a refund of any pre-paid but unused Fees.</li>
                                                    <p class="terms-p">12.5 Sole Remedy. THIS SECTION 12 SETS FORTH CUSTOMER'S SOLE REMEDIES AND PROVIDER'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED OR ALLEGED CLAIMS THAT THIS AGREEMENT OR ANY SUBJECT MATTER HEREOF (INCLUDING THE SERVICES AND PROVIDER MATERIALS) INFRINGES, MISAPPROPRIATES OR OTHERWISE VIOLATES ANY THIRD PARTY INTELLECTUAL PROPERTY RIGHT.</p>
                                                    <p class="terms-p">13. Security.</p>
                                                    <p class="terms-p">13.1 Provider Systems and Security Obligations. Provider will employ security measures in accordance with applicable industry practice ("Privacy and Security Policy").</p>
                                                    <p class="terms-p">13.2 Data Breach Procedures. Provider maintains a data breach plan in accordance with the criteria set forth in Provider's Privacy and Security Policy and shall implement the procedures required under such data breach plan on the occurrence of a "Data Breach" (as defined in such plan).</p>
                                                    <p class="terms-p">13.3 Customer Control and Responsibility. Customer has and will retain sole responsibility for: (a) all Customer Data, including its content and use; (b) all information, instructions and materials provided by or on behalf of Customer or any Authorized User in connection with the Products; (c) Customer's information technology infrastructure, including computers, software, databases, electronic systems (including database management systems) and networks, whether operated directly by Customer or through the use of third-party services ("Customer Systems"); (d) the security and use of Customer's and its Authorized Users'; and (e) all access to and use of the Applications, the Platform, the Service Software and Provider Materials directly or indirectly by or through the Customer Systems or its or its Authorized Users' Access Credentials, with or without Customer’s knowledge or consent, including all results obtained from, and all conclusions, decisions and actions based on, such access or use.</p>
                                                    <p class="terms-p">13.4 Access and Security. Customer shall employ all physical, administrative and technical controls, screening and security procedures and other safeguards necessary to: (a) securely administer the distribution and use of all Access Credentials and protect against any unauthorized access to or use of the Hosted Services; and (b) control the content and use of Customer Data, including the uploading or other provision of Customer Data for processing by the Products.</p>
                                                    <p class="terms-p">14. Miscellaneous.</p>
                                                    <p class="terms-p">14.1 Assignment. Neither Party may assign, sublicense, delegate or otherwise transfer any of its rights or obligations under this Agreement without the prior written consent of the other Party. Notwithstanding the foregoing, either Party may, without the consent of the other Party, assign this Agreement to an entity merging with, consolidating with, or purchasing substantially all its assets or stock, provided that the Party assuming obligations agrees to do so in writing and has adequate resources to meet its obligations hereunder. Notwithstanding the foregoing, Provider may not assign this Agreement to any competitor of Customer without Customer’s prior written consent. Any change in control of Provider will be deemed to be an assignment for purposes of this Section. Any permitted assignment of this Agreement shall be binding upon and enforceable by and against the Parties’ successors and assigns, provided that any unauthorized assignment shall be null and void and constitute a breach of this Agreement. Provider shall be fully responsible for any and all actions and omissions of its subcontractors, whether or not Customer has approved such subcontractors. </p>
                                                    <p class="terms-p">14.2 Entire Agreement. This Agreement, any exhibits and amendments thereto, any SO’s, and the Business Associate Agreement entered into concurrently with this Agreement constitute the entire agreement between the Parties and supersede all previous agreements, oral or written, with respect to the subject matter of this Agreement. This Agreement may not be amended without the prior written consent of both Parties.</p>
                                                    <p class="terms-p">14.3 Restricted Rights. If Customer is an agency, department or entity of a central (federal), state or local government, Customer agrees, that</p>
                                                    <ul class="list_style">
                                                        <li>(i) use, reproduction, release, modification or disclosure of the Services Software, or any part thereof, including technical data, is restricted in accordance with Federal Acquisition Regulation (“FAR”) 12.212 for civilian agencies and Defence Federal Acquisition Regulation Supplement (“DFARS”) 227.7202 for military agencies as per the United States Government (“Government”) or in accordance with similar available regulations within the Agreement jurisdiction,</li>
                                                        <li> (ii) the Platform is a commercial product, which was developed at private expense, and </li>
                                                        <li>(iii) use of the Platform by any Government agency, department or other agency of the Government is further restricted as set forth in this Agreement. </li>
                                                        <p class="terms-p">14.4 Import and Export Requirements. Customer acknowledges and agrees that the Platform is subject to export control laws and regulations. Customer may not download or otherwise export or re-export the Platform or any underlying information or technology except in full compliance with all applicable laws and regulations, in particular, but without limitation, Agreement jurisdiction country’s export control laws. The Platform and applicable information or technology may be downloaded or otherwise exported or re-exported: (a) into, or to a national or resident of, any country to which the Agreement jurisdiction country has embargoed goods; or (b) to anyone on the Agreement jurisdiction country’s Treasury Department’s list of specially designated nationals or the Agreement jurisdiction country’s Commerce Department’s list of prohibited countries or debarred or denied persons or entities. Customer hereby agrees to the foregoing and warrants that Customer is not located in, or under the control of, or a national or resident of any such country or on any such list.</p>
                                                        <p class="terms-p">14.3 Force Majeure. Except for payment obligations, if either Party is prevented from performing or is unable to perform any of its obligations under this Agreement due to causes beyond the reasonable control of the Party invoking this provision, including but not limited to acts of God, acts of civil or military authorities, riots or civil disobedience, wars, strikes or labor disputes (other than those limited to the affected Party) (each, a “Force Majeure Event”), such Party’s performance shall be excused and the time for performance shall be extended accordingly provided that the Party affected immediately notifies the other Party and immediately takes all reasonably necessary steps to resume full performance. If Force Majeure Event lasts for more than 30 days, then either Party may terminate this Agreement or the applicable Service Order. </p>
                                                        <p class="terms-p">14.4 Governing Law. This Agreement shall be governed by and interpreted in accordance with the laws of the Territory without giving effect to its conflicts of law rules. Each of the Parties to this Agreement consents to the exclusive jurisdiction and venue of the state courts located in Mumbai, Maharashtra & India.</p>
                                                        <p class="terms-p">14.5 Notices. All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given when delivered in person (including by overnight courier) or three days after being mailed by registered or certified mail (postage prepaid, return receipt requested), and on the date the notice is sent when sent by verified facsimile, in each case to the respective Parties at the address first set forth hereto. Either Party may change its contact information by providing the other Party with notice of the change in accordance with this section.</p>
                                                        <p class="terms-p">14.6 Relationship of Parties. The Parties are independent contractors and will have no right to assume or create any obligation or responsibility on behalf of the other Party. Neither Party shall hold itself out as an agent of the other Party. This Agreement will not be construed to create or imply any partnership, agency, joint venture or formal business entity of any kind.</p>
                                                        <p class="terms-p">14.7 Severability. If any provision of this Agreement is held invalid or unenforceable, it shall be replaced with the valid provision that most closely reflects the intent of the Parties and the remaining provisions of the Agreement will remain in full force and effect.</p>
                                                        <p class="terms-p">14.8 Waiver. No delay or failure by either Party to exercise any right or remedy under this Agreement will constitute a waiver of such right or remedy. All waivers must be in writing and signed by an authorized representative of the Party waiving its rights. A waiver by any Party of any breach or covenant shall not be construed as a waiver of any succeeding breach of any other covenant.</p>
                                                        <p class="terms-p">14.9 Headings. The headings of the articles and paragraphs contained in this Agreement are inserted for convenience and are not intended to be part of or to affect the interpretation of this Agreement.</p>
                                                        <p class="terms-p">14.10 Construction; Advice of Counsel. Both Parties acknowledge and agree that the Agreement has been jointly prepared and its provisions will not be construed more strictly against either Party as a result of its participation in such preparation. Each Party acknowledges and represents that, in executing this Agreement, it has had the opportunity to seek advice as to its legal rights from legal counsel and that the person signing on its behalf has read and understood all of the terms and provisions of this Agreement.</p>
                                                        <h3 class="terms-h3">In Witness hereof, the Parties hereto have executed this Agreement by persons duly authorized as of the date and year first above written.</h3>
                                                    </ul>
                                                </ul>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                            </ul>
                        </ul>
                    </ul>
                </ul>
            </ul>
        </ul>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <material-button [label]="'Accept'" (onTrigger)="acceptEula(); closeBtnAction()"></material-button>
</mat-dialog-actions>
