import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup } from '@angular/material/legacy-tabs';
import { MatStepper } from '@angular/material/stepper';
import { RootScope } from '@eninvoice-core/services/rootscope';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { ApiService, getSubscribedApps$ } from 'taxilla-library';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { FilingAttributeField } from '../../models/filingattributefield.class';
import { Organization, OrganizationData } from '../../models/organization.class';
import { RequestBusinessKeysPayload } from '../../models/payload/businesskeys.class';
import { Record } from '../../models/record/record.class';
import { Search } from '../../models/search.class';
import { Transformation } from '../../models/transformation';
import { AssetsService } from '../../services/assets/assets.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { InstancesService } from '../../services/instances/instances.service';
import { NewProcessService } from '../../services/new-process/new-process.service';
import { RequestsService } from '../../services/requests/requests.service';
import { TransformationsService } from '../../services/transformations/transformations.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ValidationsService } from '../../services/validations/validations.service';
import {
    AlertError,
    CreateRequestViaSearch,
    GetActiveTransformationsByType,
    GetInboundDetailedTransformations,
    SetRequest,
} from '../../store/actions';
import {
    getCurrentOrganizationId$,
    getFileUploadTransformations$,
    getSearchAssetTransformations$,
    getSearchOrAssetToAssetTransformations$,
} from '../../store/selectors';
import { UpdateRequestComponent } from '../updateRequestModal/updaterequest.component';

declare var $: any;

@Component({
    selector: 'lib-new-process',
    templateUrl: './new-process.component.html',
    styleUrls: ['./new-process.component.scss'],
})
export class NewProcessComponent implements OnInit {
    @Input() filingAttributeFields: any[];
    @Input() businessKeysRecord: Record;
    @Input() currentApp: AssetService;
    @Input() currentBridge: AssetService;
    @Input() currentReport: BridgeNode | AssetService;
    @Input() currentOrganizationId: string;
    @Input() assetMetaData: AssetData;
    @Input() taxillaApiUrl: string;
    @Input() isEninvoice: boolean;
    @Input() isInitiateVisible: boolean;

    @Output() navigateToProcesses = new EventEmitter();
    @Output() navigateToInstancePage = new EventEmitter();
    @Output() navigateToInboundTransmissionsFromNewProcessPage = new EventEmitter();
    @Output() navigateToSourceAsset = new EventEmitter();

    @ViewChild('stepper', { static: true }) stepper: MatStepper;
    @ViewChild('nextBtn', { static: true }) nextBTnDiv: ElementRef;
    @ViewChild('notificationPopupModal') notificationModal: TemplateRef<any>;
    @ViewChild('inputTypeGroup') inputTypeGroup: MatLegacyTabGroup;

    bridgeSourceNodes = [];
    transformations: { orgName: string; transformations: Transformation[]; repositoryId: string }[] = [];
    assetToAssetTransformations: { orgName: string; transformations: Transformation[]; repositoryId: string }[] = [];
    searchAssetToAssetTransformations: { orgName: string; transformations: Transformation[]; repositoryId: string }[] = [];
    searchOrAssetToAssetTransformations: { orgName: string; transformations: Transformation[]; repositoryId: string }[] = [];
    selectedTransformationName: string;
    selectedAssetToAssetTrnsName: string;
    selectedSearchAssetToAssetTrnsName: string;
    selectedsearchOrAssetToAssetTrnsName: string;
    sourceTableData = new BehaviorSubject([]);
    sourceTableColumns = [
        {
            name: 'name',
            displayName: 'Source Name',
            show: true,
            type: 'text',
        },
        {
            name: 'sourceDataFormat',
            displayName: 'Supported Format',
            show: true,
            type: 'text',
        },
        {
            name: 'from',
            displayName: 'Choose File',
            show: true,
            type: 'file',
        },
        {
            name: 'downloadSourceTemplate',
            displayName: 'Download Source Template',
            show: true,
            type: 'link',
        },
        {
            name: 'fileExtension',
            displayName: 'File Extension',
            show: false,
            type: 'text',
        },
    ];
    transformationSources = [];
    selectedRepositoryTransformations: any;
    selectedTransformation: Transformation;
    selectedAssetToAssetTransformation: Transformation;
    selectedSearchAssetToAssetTransformation: Transformation;
    selectedSearchOrAssetToAssetTransformation: Transformation;
    allSubscribedApps: AssetService[];
    scrollToBottom = new BehaviorSubject(0);
    allInputTypes = [];
    allTenants: any;
    tempAllTenants: any;
    allTenantsTree: any[];
    allTenantsMap: {};
    tenantsToRender: any;
    subTenants: any[];
    selectedTenant: OrganizationData;
    selectedTenantId: string;
    parentOrgId: any;
    selectedSubTenant: Organization;
    subTentantToParentStructure: string[];
    filterSubTenantName: string;
    filterTenantName: string;
    selectAllOrganizations: boolean;
    filteringAttributes = [];
    notificationPopupMessage: string;
    sourceRestApiName: string;
    existInstanceId: string;
    requestId: string;
    navigateToEdit: boolean | null;
    hidePaginator = true;
    primaryColumns = ['Source Name', 'Supported Format'];
    filingAttributeFieldValues;
    masterEntityFieldValueMap: { [property: string]: string } = {};
    fieldsMap: BehaviorSubject<{
        [property: string]: { keyColumns: string[]; valuesMap: { id: string; entityId: string; masterColumn: string; value: any }[] };
    }> = new BehaviorSubject(undefined);
    unsubscribe = new Subject<void>();
    public selectedFilter: string;
    public searchedFilter: string;
    private currentOrgId: string[] = [];
    private uploadTabTimeout: NodeJS.Timeout;
    public selectedIndex = 0;

    constructor(
        private _api: ApiService,
        public _utils: UtilsService,
        private _AssetsService: AssetsService,
        private dialog: MatDialog,
        private _validations: ValidationsService,
        private _fileUpload: FileUpload,
        private _instances: InstancesService,
        private _transformations: TransformationsService,
        private _requests: RequestsService,
        private _commonUtils: CommonUtilsService,
        public R: RootScope,
        public _newProcessService: NewProcessService,
        private store$: Store,
        private actions$: Actions
    ) {}

    startComponent = async () => {
        if (this.filingAttributeFields && this.filingAttributeFields.length > 0) {
            this.filingAttributeFieldValues = this._commonUtils.getFromStorage('filingAttributeFields');
            if (
                this.filingAttributeFieldValues &&
                this.filingAttributeFieldValues !== 'undefined' &&
                this.filingAttributeFieldValues.length > 0
            ) {
                this.getFieldValues(this.filingAttributeFieldValues, this.filingAttributeFields);
            }
            this.resetFilingAttributes();
        }
        this.initiateStepper();
        if (this.currentBridge && this.currentBridge.restApiName) {
            this.getBridgeSourceNodes();
        }
        this.store$.dispatch(
            GetActiveTransformationsByType({
                noAlerts: false,
                serviceId: this.currentApp?.serviceId,
                transformationType: 'INBOUND',
                forceFetch: true,
            })
        );
        this.store$.dispatch(
            GetInboundDetailedTransformations({
                serviceId: this.currentApp.serviceId,
                assetMetaUid: this.currentApp?.assetMetaUId,
                restApiName: this.currentApp?.assetMetaUId,
                forceFetch: true,
                noAlerts: false,
            })
        );
    };

    private initiateStepper = () => {
        const filteredFilingAttributes = this.filingAttributeFields?.filter((field) => !field.autoCalculate && field.show);
        this.stepper && (this.stepper.selectedIndex = filteredFilingAttributes?.length > 0 ? 0 : 1);
    };

    getFieldValues = (fillingAttributeValues, fields) => {
        fillingAttributeValues.forEach((fillingAttributes) => {
            fields.forEach((field) => {
                if (fillingAttributes.id === field.id) {
                    this.filingAttributeFields.filter((element) => {
                        if (element.id === fillingAttributes.id) {
                            if (element.dataType === 'boolean') {
                                if (fillingAttributes.value === 'true') {
                                    element.value = true;
                                } else {
                                    element.value = false;
                                }
                            } else {
                                element.value = fillingAttributes.value;
                            }
                        }
                    });
                }
            });
        });
    };

    resetFilingAttributes = () => {
        this._commonUtils.setInStorage('filingAttributeFields', undefined);
    };

    validateFilingAttributeFields = (callback?: any) => {
        this._newProcessService
            .validateFilingAttributeFields({
                app: this.currentApp,
                bridge: this.currentBridge,
                filingAttributeFields: this.filingAttributeFields,
            })
            .then(() => {
                if (callback) {
                    callback();
                } else if (this.stepper) {
                    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
                    if (this.bridgeSourceNodes && this.bridgeSourceNodes.length > 0) {
                        this.selectedIndex = 2;
                    } else if (this.transformations?.length > 0) {
                        this.selectUploadTab();
                    } else {
                        this.selectElectronicForm();
                    }
                }
                this.getBusinessKeyFieldValues();
            });
    };

    getBridgeSourceNodes = () => {
        this.bridgeSourceNodes = [];
        this._AssetsService.getBridgeSourceNodes(
            {
                service: this.currentApp,
                bridge: this.currentBridge,
            },
            {
                successCallback: (res) => {
                    if (res?.response?.sourceNodes) {
                        res.response.sourceNodes.forEach((element) => {
                            this.bridgeSourceNodes.push(element);
                        });
                    }
                },
                failureCallback: (res) => {},
            }
        );
    };

    getBusinessKeyFieldValues = () => {
        this.businessKeysRecord?.fields?.forEach((field) => {
            if (field) {
                const value = this.getFilteringAttributeFieldValue(field);
                if (value) {
                    field.value = value;
                }
            }
        });
    };

    getFilteringAttributeFieldValue = (field) => {
        let fieldValue;
        this.filingAttributeFields.forEach((element) => {
            if (element.id === field.id) {
                fieldValue = element.value;
            }
        });
        return fieldValue;
    };

    selectUploadTab = () => {
        if (this.selectedIndex === 1) {
            if (!this.selectedTransformationName && this.transformations?.length > 0) {
                this.selectFirstTransformation();
            }
            return;
        }
        clearTimeout(this.uploadTabTimeout);
        if (!this.inputTypeGroup || !document.hasFocus()) {
            this.uploadTabTimeout = setTimeout(() => {
                this.selectUploadTab();
            }, 1000);
            return;
        }
        this.selectedIndex = 1;
        this.selectFirstTransformation();
    };

    selectFirstTransformation = () => {
        const transObj = this.transformations;
        if (transObj?.length > 0) {
            const trans = transObj[0].transformations;
            if (trans?.length > 0) {
                this.selectedTransformationName = trans[0].id;
                this.getSelectedTransformation();
            }
        }
    };

    getSelectedTransformation = () => {
        this.transformationSources = [];
        if (this.selectedTransformationName?.length > 0) {
            for (let i = 0; i < this.transformations.length; i++) {
                for (let j = 0; j < this.transformations[i].transformations.length; j++) {
                    if (this.selectedTransformationName === this.transformations[i].transformations[j].id) {
                        this.selectedRepositoryTransformations = this.transformations[i];
                        this.selectedTransformation = this.transformations[i].transformations[j];
                        this.transformationSources = CommonUtilsService.cloneObject(this.transformations[i].transformations[j].sourceParts);
                    }
                }
            }
            this.scrollToBottm();
        }
        this.buildTableData();
    };

    getSelectedAssetToAssetTransformation = () => {
        this.transformationSources = [];
        if (this.selectedAssetToAssetTrnsName && this.selectedAssetToAssetTrnsName.length > 0) {
            for (let i = 0; i < this.assetToAssetTransformations.length; i++) {
                for (let j = 0; j < this.assetToAssetTransformations[i].transformations.length; j++) {
                    if (this.selectedAssetToAssetTrnsName === this.assetToAssetTransformations[i].transformations[j].id) {
                        this.selectedAssetToAssetTransformation = this.assetToAssetTransformations[i].transformations[j];
                    }
                }
            }

            if (this.selectedAssetToAssetTransformation.isAssetToAsset === true) {
                this.getFilteringAttributes();
            }
            if (!this.tenantsToRender || (this.tenantsToRender && this.tenantsToRender.length === 0)) {
                this._newProcessService.getAllTenantsForFlatStructure().then((data) => {
                    this.tenantsToRender = data.tenantsToRender;
                    this.subTenants = data.subTenants;
                    this.selectedTenantId = data.selectedTenantId;
                    this.clearSearchSubTenant();
                });
            }
            if (this.selectedAssetToAssetTransformation.isSearchAsset == true) {
                this.selectedSearchAssetToAssetTrnsName = this.selectedAssetToAssetTrnsName;
                this.getSelectedSearchAssetToAssetTransformation();
            }
        }
    };

    getSelectedSearchAssetToAssetTransformation = () => {
        if (this.selectedSearchAssetToAssetTrnsName && this.selectedSearchAssetToAssetTrnsName.length > 0) {
            for (let i = 0; i < this.searchAssetToAssetTransformations.length; i++) {
                for (let j = 0; j < this.searchAssetToAssetTransformations[i].transformations.length; j++) {
                    if (this.selectedSearchAssetToAssetTrnsName === this.searchAssetToAssetTransformations[i].transformations[j].id) {
                        this.selectedSearchAssetToAssetTransformation = this.searchAssetToAssetTransformations[i].transformations[j];
                        this.selectedSearchAssetToAssetTrnsName?.length > 0 && this.buildTransformationAsset();
                    }
                }
            }
        }
    };

    private buildTransformationAsset = () => {
        const transformation = this.selectedSearchAssetToAssetTransformation;
        transformation?.sourceParts
            ?.filter((sourcePart) => !(sourcePart?.copyOf?.length > 0))
            .forEach(async (source) => {
                const restAPIName = source?.appDetails?.restApiName;
                if (!restAPIName) {
                    return;
                }
                const app = this.allSubscribedApps.find((service) => service.restApiName === restAPIName);
                if (!app) {
                    return;
                }
                this.getDefaultSearchFilters(source);
                source.appDetails.metaDataLoading = true;
                source.appDetails.metaData = await this.getAppMetaData(app);
                source.search = new Search(this._utils, app.serviceId);
                source.search.setSources(source.search.convertEntities(source.appDetails.metaData.entities));
                source.search.setSource(source.appDetails.metaData.getPrimaryEntity()?.uid);
                source.search.isAddRuleVisible = true;
                source.search.allowCollapse = true;
                source.search.allowRuleset = true;
                source.search.needSourceStructure = true;
                source.appDetails.metaDataLoading = false;
            });
    };

    private getAppMetaData = (app: AssetService) => {
        return new Promise<AssetData>((resolve) => {
            this._api.assets.getAssetMetadata(
                {
                    assetMetaUId: app.assetMetaUId || app.id,
                    name: app.displayName || app.name,
                },
                {
                    successCallback: (response) => {
                        resolve(response);
                    },
                }
            );
        });
    };

    private getDefaultSearchFilters = (source) => {
        const appName = source?.sourceAssetOrMasterName;
        const app = this.allSubscribedApps.find((service) => service.name === appName);
        const assetId = app.assetMetaUId;
        if (!assetId) {
            return;
        } else {
            source.defaultSearchFilters = {};
            this._api.instances.getDefaultSearchFilters(assetId).subscribe({
                next: (res) => {
                    if (res?.['default-filters']?.length) {
                        source.defaultSearchFilters = res;

                        let cachedDefaultFilters = CommonUtilsService.getFromStorage('defaultFilters');
                        if (cachedDefaultFilters) {
                            cachedDefaultFilters[assetId] = res;
                        } else {
                            cachedDefaultFilters = {};
                            cachedDefaultFilters[assetId] = res;
                        }
                        CommonUtilsService.setInStorage('defaultFilters', cachedDefaultFilters);
                    }
                },
                error: (res) => {},
            });
        }
    };

    bindingTemplateValues = (source) => {
        let selectedFilterData = source.defaultSearchFilters['filterCriterias'][this.selectedFilter];
        source.search.setCriterias(selectedFilterData);
        source.search.criteriaUpdated = true;
    };

    getFilteringAttributes = () => {
        const group = this.getSelectedAssetToAssetTransformationGroup();
        this._AssetsService.getFilteringAttributes(
            {
                bridge: this.currentBridge,
                service: this.currentApp,
                transformationName: this.selectedAssetToAssetTransformation.name,
                repositoryId: group && group.repositoryId,
            },
            {
                successCallback: (res) => {
                    this.filteringAttributes = [];
                    if (res?.attributes?.length > 0) {
                        res.attributes
                            .filter((field) => !field.autoCalculate)
                            .forEach((field) => {
                                this.filteringAttributes.push(new FilingAttributeField(field));
                            });
                        this.getFilteringAttributesValues();
                    }
                },
                failureCallback: (res) => {},
            }
        );
    };

    getFilteringAttributesValues = () => {
        this.filteringAttributes
            .filter((field) => field && !field.autoCalculate)
            .forEach((field) => {
                const value = this.getFilteringAttributeFieldValue(field);
                field.value = value;
            });
    };

    getSelectedAssetToAssetTransformationGroup = () => {
        let group;
        for (let i = 0; i < this.assetToAssetTransformations.length; i++) {
            for (let j = 0; j < this.assetToAssetTransformations[i].transformations.length; j++) {
                if (this.assetToAssetTransformations[i].transformations[j].id === this.selectedAssetToAssetTrnsName) {
                    group = this.assetToAssetTransformations[i];
                    break;
                }
            }
            if (group) {
                break;
            }
        }
        return group;
    };

    buildTableData = () => {
        this.sourceTableData.next(this.transformationSources);
    };

    selectElectronicForm = () => {
        this.selectedIndex = 0;
    };

    cancelEntity = (requestId?) => {
        this.navigateToProcesses.emit(requestId);
    };

    goToBackPage = () => {
        this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
    };

    validateBusinessKeys = async () => {
        if (this.selectedIndex !== 0) {
            if (this.selectedIndex === 1) {
                if (this.selectedTransformationName?.length > 0) {
                    const sources = this.sourceTableData.value;
                    let count = 0;
                    let sourceFileFound = 0;
                    sources.forEach((source) => {
                        if (source.files) {
                            sourceFileFound = sourceFileFound + 1;
                            const givenFileFormat = source.files[0].name.substring(source.files[0].name.lastIndexOf('.'));
                            const requiredFileFormats = [];
                            requiredFileFormats.push(source.fileExtension);
                            requiredFileFormats.push('.zip');
                            if (requiredFileFormats.indexOf(givenFileFormat.toLowerCase()) === -1) {
                                count = count + 1;
                            }
                        }
                    });
                    if (!sourceFileFound) {
                        this._utils.alertError(translate('Please upload a file'));
                        return false;
                    } else {
                        if (count) {
                            this._utils.alertError(translate('Please select valid file format'));
                            return false;
                        } else {
                            return true;
                        }
                    }
                } else {
                    this._utils.alertError(translate('Please select transformation'));
                    return false;
                }
            } else if (this.selectedIndex === 2) {
                if (this.bridgeSourceNodes && this.bridgeSourceNodes.length > 0) {
                    return true;
                } else {
                    if (this.selectedAssetToAssetTrnsName && this.selectedAssetToAssetTrnsName.length > 0) {
                        const selectedTenantIds = [];
                        const allTenantsMap = this.getAllTenantMap();
                        for (const key in allTenantsMap) {
                            const tenant = allTenantsMap[key];
                            if (tenant && tenant.selected) {
                                selectedTenantIds.push(tenant.id);
                            }
                        }
                        if (this.filteringAttributes?.length > 0 || this.selectedAssetToAssetTransformation.isSearchAsset === true) {
                            const isValid = this._newProcessService.validateFilingAttributes(this.filteringAttributes);
                            if (isValid && selectedTenantIds && selectedTenantIds.length > 0) {
                                return true;
                            } else if (isValid && selectedTenantIds && selectedTenantIds.length === 0) {
                                this._utils.alertError(translate('Please select atleast one location'));
                                return false;
                            } else {
                                this._utils.alertError(translate('Please fix issues to proceed'));
                                return false;
                            }
                        } else {
                            if (!selectedTenantIds || selectedTenantIds.length > 0) {
                                this._utils.alertError(translate('Please select atleast one location'));
                                return false;
                            }
                        }
                    } else {
                        this._utils.alertError(translate('Please select transformation'));
                        return false;
                    }
                }
            } else {
                return true;
            }
        }
        const record: Record = CommonUtilsService.cloneObject(this.businessKeysRecord);
        if (record && record.fields) {
            for (let i = record.fields.length - 1; i >= 0; i--) {
                if (!record.fields[i].isBusinessKey) {
                    record.fields.splice(i, 1);
                }
            }
            if (record.fields.length === 0) {
                return true;
            }
        }
        if (this.businessKeysRecord === undefined) {
            return true;
        }
        if (this.businessKeysRecord) {
            await this.checkAppVersion();
            const success = this._validations.hasValidData({
                configurations: [],
                entities: this.assetMetaData.entities,
                entityId: this.businessKeysRecord.entityId,
                process: undefined,
                record: this.businessKeysRecord,
                restAPIName: this.assetMetaData.restAPIName,
            });
            if (!success) {
                this._utils.alertError('Fix issues to proceed');
            }
            return success;
        }
    };

    private checkAppVersion = () => {
        return new Promise<void>((resolve) => {
            if (this.assetMetaData?.uid === this.currentApp?.assetMetaUId) {
                resolve();
            }
            this.getAppMetaData(this.currentApp).then((metaData) => {
                this.assetMetaData = metaData;
                resolve();
            });
        });
    };

    getAllTenantMap = () => this._newProcessService.allTenantsMap;

    goToNextPage = () => {
        this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        if (this.stepper.selectedIndex === 1) {
            if (this.transformations && this.transformations.length > 0) {
                this.selectUploadTab();
            } else {
                this.selectElectronicForm();
            }
        }
        if (this.stepper.selectedIndex === 2) {
            setTimeout(() => {
                this.scrollToBottom.next(this.scrollToBottom.value + 1);
            });
        }
    };

    scrollToBottm = (record?, time?) => {
        let timeOut;
        if (time) {
            timeOut = time;
        } else {
            timeOut = 0;
        }
        setTimeout(() => {
            this.scrollToBottom.next(this.scrollToBottom.value + 1);
            if (this.nextBTnDiv) {
                const buttnElemt = this.nextBTnDiv && this.nextBTnDiv['_elementRef'] && this.nextBTnDiv['_elementRef']['nativeElement'];
                if (buttnElemt) {
                    buttnElemt.focus();
                }
            }
        }, timeOut);
    };

    submitAttributeFields = (isFromEdit?: boolean) => {
        this.navigateToEdit = isFromEdit;
        this.validateFilingAttributeFields(this.submit);
    };

    submit = () => {
        let errorsCount = 0;
        if (this.businessKeysRecord?.fields) {
            this.businessKeysRecord.fields
                .filter((field) => !field.autoCalculate)
                .forEach((field) => {
                    field.message.errors = [];
                    if (field.isBusinessKey) {
                        if (field.value === '' || field.value === undefined || field.value === null) {
                            field.message.errors.push(`Please enter ${field.displayName}`);
                            errorsCount++;
                        }
                    }
                });
        }
        if (errorsCount === 0) {
            this.submitNewRequestBusinessKeys();
        } else {
            this._utils.alertError(translate('Fix issues to proceed with the generation'), 3000, 'Failure');
        }
    };

    submitNewRequestBusinessKeys = async () => {
        const bridge = this.currentBridge;
        const service = this.currentApp;
        const report = this.currentReport;
        let isFirstAssetInBridge: boolean;
        if (bridge && bridge.assets && bridge.assets.length > 0) {
            for (let i = 0; i < bridge.assets.length; i++) {
                if (bridge.assets[i].id === service.assetMetaUId) {
                    if (bridge.assets[i].level === 0) {
                        isFirstAssetInBridge = true;
                    }
                    break;
                }
            }
        }
        let errorsCount = 0;
        this.businessKeysRecord?.fields
            .filter((field) => !field.autoCalculate)
            .forEach((field) => {
                field.message.errors = [];
                if (field && field.isBusinessKey && field.id === 'inv_doc_num') {
                    if (field.value && this._utils.checkForSpecialChars(field.value)) {
                        field.message.errors.push(translate('Special characters are not allowed'));
                        errorsCount++;
                    } else if ((field.value as any)?.length > 16) {
                        field.message.errors.push(translate('Maximum number of characters allowed is 16'));
                        errorsCount++;
                    }
                }
            });
        if (errorsCount === 0) {
            if (report) {
                this.createBridgeRequest(undefined);
            } else if (isFirstAssetInBridge) {
                await this.checkAppVersion();
                const payload = new RequestBusinessKeysPayload(
                    this.businessKeysRecord,
                    this.assetMetaData.getPrimaryEntity(),
                    this.currentApp,
                    this.currentBridge
                );
                this._requests.submitBusinessKeys(
                    {
                        bridge: this.currentBridge,
                        payload,
                        service: this.currentApp,
                    },
                    {
                        successCallback: () => {
                            this.createBridgeRequest(undefined);
                        },
                        failureCallback: (res) => {
                            if (res.response && res.response.AssetDataId && res.response.requestId) {
                                this.existInstanceId = res.response.AssetDataId;
                                const initialState = {
                                    requestId: res.response.requestId,
                                    instanceId: res.response.AssetDataId,
                                    assetId: this.currentApp && this.currentApp.assetMetaUId,
                                    bridgeId: this.currentBridge && this.currentBridge.assetMetaUId,
                                    bridgeRestAPIName: this.currentBridge && this.currentBridge.restApiName,
                                    bridgeSourceNodes: this.bridgeSourceNodes,
                                    assetRestApiName: this.currentApp && this.currentApp.restApiName,
                                    isAssetToAssetCase: false,
                                    filingAttributeFields: this.filingAttributeFields,
                                };
                                const dialogRef = this.dialog.open(UpdateRequestComponent, {
                                    panelClass: ['updateRequestModalClass', 'matDialogContainer'],
                                    disableClose: true,
                                    data: {
                                        ...initialState,
                                    },
                                });
                                dialogRef.componentInstance.backToNewProcesspage = this.resetBackToFirstPage;
                                dialogRef.componentInstance.navigateToInstancePage = this.navigateInstances;
                            } else {
                                this._utils.alertError(res?.msg || res?.response?.msg || translate('Failed to save business keys'));
                            }
                        },
                    }
                );
            } else {
                this.createBridgeRequest(true);
            }
        } else {
            this._utils.alertError(translate('Fix issues to proceed with the generation'), 3000, 'Failure');
        }
    };

    private createBridgeRequest = (isNotFirstAssetInBridge?, callback?: (...args: any) => void) => {
        const bridge = this.currentBridge;
        const report = this.currentReport;
        if (report) {
            this.getNewRequestIdWithBusinessKeys();
        } else if (bridge && bridge.restApiName && bridge.restApiName.length > 0) {
            /**
             * if bridge exists then the instance has to be pulled into the asset.
             */
            const formData = new FormData();
            const record: Record = this.businessKeysRecord;
            const fieldIds = [];
            record?.fields
                ?.filter((field) => !field.autoCalculate && field.isBusinessKey)
                .forEach((field) => {
                    formData.append(field.id, field.value as string);
                    fieldIds.push(field.id);
                });
            if (this.filingAttributeFields?.length > 0) {
                this.filingAttributeFields
                    .filter((field) => !field.autoCalculate && fieldIds.indexOf(field.fullId) == -1 && field.value !== undefined)
                    .forEach((attribute: FilingAttributeField) => {
                        if (Array.isArray(attribute.value)) {
                            fieldIds.push(attribute.fullId);
                            attribute.value.forEach((element) => {
                                formData.append(attribute.fullId, element);
                            });
                        } else {
                            formData.append(attribute.fullId, attribute.value);
                            fieldIds.push(attribute.fullId);
                        }
                    });
            }
            this._instances.createInstanceFromBridge(
                {
                    bridge: this.currentBridge,
                    isNotFirstAssetInBridge,
                    payload: formData,
                    service: this.currentApp,
                    isAssetToAssetTransformation: false,
                },
                {
                    successCallback: (res) => {
                        this.requestId = res.response.requestId ? res.response.requestId : res.response;
                        this._utils.alertSuccess((res && res.msg) || translate('Request created successfully'));
                        this._utils.showLoading();
                        setTimeout(
                            () => {
                                this._utils.hideLoading();
                                if (this.navigateToEdit) {
                                    this.navigateToProcesses.emit(this.requestId);
                                } else {
                                    this.navigateToInboundTransmissionsFromNewProcessPage.emit(this.requestId);
                                }
                            },
                            bridge?.restApiName?.length > 0 ? 5000 : 0
                        );
                    },
                    failureCallback: (res) => {
                        this._utils.alertError(
                            (res && res.msg) || (res && res.response && res.response.msg) || translate('Failed to create new request')
                        );
                    },
                }
            );
        } else {
            this.getNewRequestIdWithBusinessKeys();
        }
    };

    private getNewRequestIdWithBusinessKeys = () => {
        const formData = new FormData();
        const record: Record = this.businessKeysRecord;
        const fieldIds = [];
        record?.fields
            ?.filter((field) => !field.autoCalculate && field.isBusinessKey)
            .forEach((field) => {
                let value: string = field.value as string;
                formData.append(field.id, value);
                fieldIds.push(field.id);
            });
        this.filingAttributeFields
            ?.filter((field) => !field.autoCalculate && fieldIds.indexOf(field.fullId) === -1 && field.value !== undefined)
            .forEach((field) => {
                if (Array.isArray(field.value)) {
                    fieldIds.push(field.fullId);
                    field.value.forEach((element) => {
                        formData.append(field.fullId, element);
                    });
                } else {
                    formData.append(field.fullId, field.value);
                    fieldIds.push(field.fullId);
                }
            });
        this._requests
            .createNewRequest({
                payload: formData,
                service: this.currentApp,
                bridge: this.currentBridge as AssetService,
                report: this.currentReport as BridgeNode,
            })
            .then((res) => {
                if (res.response && (res.response as any).REQUEST_EXISTS) {
                    this.existInstanceId = res.response.REQUEST_EXISTS;
                    const initialState = {
                        requestId: '',
                        instanceId: res.response.REQUEST_EXISTS,
                        assetId: this.currentApp && this.currentApp.assetMetaUId,
                        bridgeId: this.currentBridge && this.currentBridge.assetMetaUId,
                        bridgeRestAPIName: this.currentBridge && this.currentBridge.restApiName,
                        bridgeSourceNodes: this.bridgeSourceNodes,
                        assetRestApiName: this.currentApp && this.currentApp.restApiName,
                        isAssetToAssetCase: false,
                    };
                    const dialogRef = this.dialog.open(UpdateRequestComponent, {
                        panelClass: ['updateRequestModalClass', 'matDialogContainer'],
                        disableClose: true,
                        data: {
                            ...initialState,
                        },
                    });
                    dialogRef.componentInstance.backToNewProcesspage = this.resetBackToFirstPage;
                    dialogRef.componentInstance.navigateToInstancePage = this.navigateInstances;
                } else {
                    this.requestId = res.response && res.response.requestId;
                    this._utils.alertSuccess((res && res.msg) || translate('Failed to create new request'));
                    this._utils.showLoading();
                    setTimeout(() => {
                        this._utils.hideLoading();
                        if (this.navigateToEdit) {
                            this.navigateToProcesses.emit(this.requestId);
                        } else {
                            this.navigateToInboundTransmissionsFromNewProcessPage.emit(this.requestId);
                        }
                    }, 5000);
                }
            })
            .catch((res) => {
                if (res && res.response && res.response.REQUEST_EXISTS) {
                    this.existInstanceId = res.response.REQUEST_EXISTS;
                    const initialState = {
                        requestId: '',
                        instanceId: res.response.REQUEST_EXISTS,
                        assetId: this.currentApp && this.currentApp.assetMetaUId,
                        bridgeId: this.currentBridge && this.currentBridge.assetMetaUId,
                        bridgeRestAPIName: this.currentBridge && this.currentBridge.restApiName,
                        bridgeSourceNodes: this.bridgeSourceNodes,
                        assetRestApiName: this.currentApp && this.currentApp.restApiName,
                        isAssetToAssetCase: false,
                    };
                    const dialogRef = this.dialog.open(UpdateRequestComponent, {
                        panelClass: ['updateRequestModalClass', 'matDialogContainer'],
                        disableClose: true,
                        data: {
                            ...initialState,
                        },
                    });
                    dialogRef.componentInstance.backToNewProcesspage = this.resetBackToFirstPage;
                    dialogRef.componentInstance.navigateToInstancePage = this.navigateInstances;
                } else {
                    this._utils.alertError(
                        (res && res.msg) || (res && res.response && res.response.msg) || translate('Failed to create new request')
                    );
                }
            });
    };

    uploadFile = async (isFromEdit?: boolean) => {
        this.navigateToEdit = isFromEdit;
        const formData = new FormData();
        const sources = this.sourceTableData.value;
        sources.forEach((source) => {
            source.files?.forEach((file) => {
                const isIE = this._utils.isIEBrowser();
                if (isIE) {
                    formData.append(source.name, file, file.name);
                } else {
                    formData.append(source.name, file);
                }
            });
        });
        this.filingAttributeFields
            .filter((field) => !field.autoCalculate && field.value !== undefined)
            .forEach((field) => {
                if (Array.isArray(field.value)) {
                    field.value.forEach((element) => {
                        formData.append(field.fullId, element);
                    });
                } else {
                    formData.append(field.fullId, field.value);
                }
            });
        const bridge = this.currentBridge;
        const app = this.currentApp;
        let url = this.taxillaApiUrl + '/process/v1/' + app.restApiName + '?sourceType=UPLOAD';
        if (bridge?.restApiName) {
            url += '&bridgeRestApiName=' + bridge.restApiName;
        }
        url += '&transformationName=' + this.selectedTransformation.chainName;
        this._fileUpload.uploadRequest(
            'POST',
            url,
            formData,
            undefined,
            (res) => {
                this._utils.hideLoading();
                if (this.isEninvoice) {
                    this._utils.alertSuccess(
                        res &&
                            translate(
                                "File has been uploaded. However, it might take some while to process the data based on the volume. Kindly refresh after some time if you don't see the records."
                            )
                    );
                } else {
                    this._utils.alertSuccess((res && res.msg) || translate('Files has been uploaded'));
                }
                if (this.navigateToEdit) {
                    setTimeout(() => {
                        this.navigateToProcesses.emit(res?.response?.requestId);
                    }, 500);
                } else {
                    this.navigateToInboundTransmissionsFromNewProcessPage.emit(res?.response?.requestId);
                }
            },
            (res) => {
                this._utils.hideLoading();
                this._utils.alertError((res && res.msg) || translate('Failed to upload files'));
            }
        );
    };

    getSelectedGroup = () => {
        let group;
        for (let i = 0; i < this.transformations.length; i++) {
            for (let j = 0; j < this.transformations[i].transformations.length; j++) {
                if (this.transformations[i].transformations[j].name === this.selectedTransformation.name) {
                    group = this.transformations[i];
                    break;
                }
            }
            if (group) {
                break;
            }
        }
        return group;
    };

    startAssetToAssetTransformation = (isFromEdit?: boolean) => {
        this.navigateToEdit = isFromEdit;
        if (this.bridgeSourceNodes && this.bridgeSourceNodes.length > 0) {
            const payload = new FormData();
            this.filingAttributeFields
                .filter((field) => !field.autoCalculate && field.value !== undefined)
                .forEach((field) => {
                    if (Array.isArray(field.value)) {
                        field.value.forEach((element) => {
                            payload.append(field.fullId, element);
                        });
                    } else {
                        payload.append(field.fullId, field.value);
                    }
                });

            this._instances.createInstanceFromBridge(
                {
                    bridge: this.currentBridge,
                    isNotFirstAssetInBridge: true,
                    payload,
                    service: this.currentApp,
                    isAssetToAssetTransformation: true,
                },
                {
                    successCallback: (res) => {
                        if (res && res.response && res.response.pendingAssetDataIds) {
                            this.notificationPopupMessage =
                                'There exists some pending processes matching the criteria! Please complete them before proceeding with the current process.';
                            this.sourceRestApiName = res.response.restApiName;
                            this.dialog.open(this.notificationModal, {
                                panelClass: ['newProcessNotificaionModal', 'matDialogContainer'],
                                disableClose: true,
                            });
                        } else {
                            this.postInitiationOfRequest(res);
                        }
                    },
                    failureCallback: (res) => {
                        this.processIncompleteData(res);
                    },
                }
            );
        } else {
            const payload = new FormData();
            const attributes = [];
            [...(this.filteringAttributes || []), ...(this.filingAttributeFields || [])]
                .filter((field) => !field.autoCalculate)
                .forEach((field) => {
                    attributes.push(field);
                });
            const playloadFieldIds = [];
            attributes.forEach((field) => {
                if (playloadFieldIds.includes(field.fullId)) {
                    return;
                }
                playloadFieldIds.push(field.fullId);
                if (field.value !== undefined) {
                    if (Array.isArray(field.value)) {
                        field.value.forEach((element) => {
                            payload.append(field.fullId, element);
                        });
                    } else {
                        payload.append(field.fullId, field.value);
                    }
                }
            });
            const selectedTenantIds = [];
            const allTenantsMap = this.getAllTenantMap();
            for (const key in allTenantsMap) {
                const tenant = allTenantsMap[key];
                if (tenant && tenant.selected) {
                    selectedTenantIds.push(tenant.id);
                }
            }
            if (selectedTenantIds.length === 0) {
                return this._utils.alertError('Select locations');
            }
            payload.append('unitIds', this._utils.convertJSONtoBlob(selectedTenantIds));
            this._transformations.processTransformations(
                {
                    bridgeRestAPIName: this.currentBridge && this.currentBridge.restApiName,
                    restApiName: this.currentApp && this.currentApp.restApiName,
                    transformationName: this.selectedAssetToAssetTransformation.chainName,
                    payload,
                    override: false,
                },
                {
                    successCallback: (res) => {
                        if (res && res.response && res.response.pendingAssetDataIds) {
                            this.notificationPopupMessage =
                                'There exists some pending processes matching the criteria! Please complete them before proceeding with the current process.';
                            this.sourceRestApiName = res.response.restApiName;
                            this.dialog.open(this.notificationModal, {
                                panelClass: ['newProcessNotificaionModal', 'matDialogContainer'],
                                disableClose: true,
                            });
                        } else {
                            this.postInitiationOfRequest(res);
                        }
                    },
                    failureCallback: (res) => {
                        this._utils.alertError((res && res.msg) || translate('Failed to start transformation'));
                    },
                }
            );
        }
    };

    startSearchAssetTransformation = async () => {
        const selectedTenantIds = [];
        this.subTenants.forEach((tenant) => {
            if (tenant.selected) {
                selectedTenantIds.push(tenant.id);
            }
        });
        if (selectedTenantIds.length === 0) {
            return this._utils.alertError(translate('Select locations'));
        }
        const transformation = this.selectedSearchAssetToAssetTransformation;
        const sourceParts = transformation?.sourceParts;
        const payloadPromise = this.getAssetToAssetCriteria(sourceParts, selectedTenantIds);
        payloadPromise
            .then((payload) => {
                const requestData = new FormData();
                requestData.append('dataSources', JSON.stringify(payload));
                const playloadFieldIds = [];
                const attributes = [];
                [...(this.filteringAttributes || []), ...(this.filingAttributeFields || [])]
                    .filter((field) => !field.autoCalculate)
                    .forEach((field) => {
                        attributes.push(field);
                    });
                attributes.forEach((field) => {
                    if (playloadFieldIds.includes(field.fullId)) {
                        return;
                    }
                    playloadFieldIds.push(field.fullId);
                    if (field.value !== undefined) {
                        if (Array.isArray(field.value)) {
                            field.value.forEach((element) => {
                                requestData.append(field.fullId, element);
                            });
                        } else {
                            requestData.append(field.fullId, field.value);
                        }
                    }
                });
                this.store$.dispatch(
                    CreateRequestViaSearch({
                        serviceId: this.currentApp.serviceId,
                        payload: requestData,
                        transformationName: this.selectedSearchAssetToAssetTransformation?.chainName,
                    })
                );
            })
            .catch((e) => {
                this.store$.dispatch(AlertError({ message: e }));
            });
    };

    private getAssetToAssetCriteria = (sourceParts: Transformation['sourceParts'], locationIds: string[]) => {
        return new Promise((resolve, reject) => {
            const promises = [];
            sourceParts
                ?.filter((part) => !part.copyOf && part.appDetails?.metaData)
                .forEach((sourcePart) => {
                    promises.push(this.getSourcePartCriteria(sourcePart, locationIds));
                });
            Promise.all(promises).then(([...criterias]) => {
                if (criterias.filter((criteria) => criteria !== undefined).length === 0) {
                    reject(translate('Create search criteria to proceed'));
                }
                const payload = [...criterias.filter((criteria) => criteria !== undefined)];
                resolve(payload);
            });
        });
    };

    private getSourcePartCriteria = async (sourcePart: Transformation['sourceParts'][0], locationIds: string[]) => {
        const appDetails = sourcePart?.appDetails;
        if (!sourcePart?.search?.criteriaUpdated) {
            return undefined;
        }
        const criteria = sourcePart?.search.optimizeCriteria();
        const updatedLocationIds = locationIds;
        return {
            type: 'SearchCriteriaDataSourceType',
            name: sourcePart?.name,
            searchCriteria: {
                filterCriteria: criteria,
                restApiServiceName: appDetails.restApiName,
                unitIds: updatedLocationIds,
            },
        };
    };

    postInitiationOfRequest = (responseObj) => {
        if (responseObj?.response?.REQUEST_EXISTS?.length > 0) {
            const instanceId = responseObj.response.REQUEST_EXISTS;
            this._instances
                .getInstance({
                    instanceId,
                    serviceId: this.getServiceId(),
                    noAlerts: false,
                })
                .then((instance) => {
                    const requestId = instance.assetRequests?.[0]?.requestId;
                    this.existInstanceId = responseObj.response.REQUEST_EXISTS;
                    const initialState = {
                        requestId: responseObj.response.requestId || requestId,
                        instanceId: responseObj.response.REQUEST_EXISTS,
                        assetId: this.currentApp && this.currentApp.assetMetaUId,
                        bridgeId: this.currentBridge && this.currentBridge.assetMetaUId,
                        bridgeRestAPIName: this.currentBridge && this.currentBridge.restApiName,
                        bridgeSourceNodes: this.bridgeSourceNodes,
                        assetRestApiName: this.currentApp && this.currentApp.restApiName,
                        isAssetToAssetCase: true,
                        filingAttributeFields: this.filingAttributeFields,
                    };
                    const dialogRef = this.dialog.open(UpdateRequestComponent, {
                        panelClass: ['updateRequestModalClass', 'matDialogContainer'],
                        disableClose: true,
                        data: {
                            ...initialState,
                        },
                    });
                    dialogRef.componentInstance.backToNewProcesspage = this.resetBackToFirstPage;
                    dialogRef.componentInstance.updateAssetRequest = this.updateAssetRequest;
                    dialogRef.componentInstance.navigateToInstancePage = this.navigateInstances;
                    dialogRef.componentInstance.processIncompleteInstances = this.processIncompleteData;
                });
        } else {
            this._utils.alertSuccess((responseObj && responseObj.msg) || 'Asset to Asset pull request created sucessfully');
            if (this.navigateToEdit) {
                this.navigateToProcesses.emit(responseObj?.response?.requestId);
            } else {
                this.navigateToInboundTransmissionsFromNewProcessPage.emit(responseObj?.response?.requestId);
            }
        }
    };

    navigateInstances = () => {
        this.navigateToInstancePage.emit(this.existInstanceId);
    };

    getServiceId = (): string => {
        const app = this.currentApp;
        const bridge = this.currentBridge;
        const report = this.currentReport;
        if (report) {
            return (bridge && bridge.serviceId) || (app && app.serviceId);
        } else if (bridge) {
            return app.serviceId ? app.serviceId : bridge.serviceId + '.' + app.serviceId;
        }
        return app && app.serviceId;
    };

    updateAssetRequest = () => {
        const payload = new FormData();
        const attributes = [];
        [...(this.filteringAttributes || []), ...(this.filingAttributeFields || [])]
            .filter((field) => !field.autoCalculate)
            .forEach((field) => {
                attributes.push(field);
            });
        const playloadFieldIds = [];
        attributes.forEach((field) => {
            if (playloadFieldIds.includes(field.fullId)) {
                return;
            }
            playloadFieldIds.push(field.fullId);
            if (field.value !== undefined) {
                if (Array.isArray(field.value)) {
                    field.value.forEach((element) => {
                        payload.append(field.fullId, element);
                    });
                } else {
                    payload.append(field.fullId, field.value);
                }
            }
        });
        const selectedTenantIds = [];
        this.subTenants.forEach((tenant) => {
            if (tenant.selected) {
                selectedTenantIds.push(tenant.id);
            }
        });
        if (selectedTenantIds.length === 0) {
            return this._utils.alertError(translate('Select locations'));
        }
        payload.append('unitIds', this._utils.convertJSONtoBlob(selectedTenantIds));
        this._transformations.processTransformations(
            {
                bridgeRestAPIName: this.currentBridge && this.currentBridge.restApiName,
                restApiName: this.currentApp && this.currentApp.restApiName,
                transformationName: this.selectedAssetToAssetTransformation.chainName,
                payload,
                override: true,
            },
            {
                successCallback: (res) => {
                    this._utils.alertSuccess((res && res.msg) || translate('Asset To Asset Pull Request Accepted'));
                    this.dialog.closeAll();
                    this.navigateToInstancePage.emit(this.existInstanceId);
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || translate('Failed to start transformation'));
                },
            }
        );
    };

    processIncompleteData = (res) => {
        if (res && res.response && res.response.pendingAssetDataIds) {
            this.notificationPopupMessage = translate(
                'There exists some pending processes matching the criteria! Please complete them before proceeding with the current process'
            );
            this.sourceRestApiName = res.response.restApiName;
            this.dialog.open(this.notificationModal, {
                panelClass: ['newProcessNotificaionModal', 'matDialogContainer'],
                disableClose: true,
            });
        } else {
            this._utils.alertError((res && res.msg) || translate('Failed to start transformation'));
        }
    };

    resetBackToFirstPage = () => {
        if (this.filingAttributeFields && this.filingAttributeFields.length > 0) {
            if (this.stepper) {
                this.stepper.selectedIndex = 0;
            }
        } else {
            if (this.stepper) {
                this.stepper.selectedIndex = 1;
                if (this.transformations && this.transformations.length > 0) {
                    this.selectUploadTab();
                } else {
                    this.selectElectronicForm();
                }
            }
        }
    };

    processSourceInstances = () => {
        const data = {
            targetAssetRestApiName: this.currentApp && this.currentApp.restApiName,
            sourceAssetRestApiName: this.sourceRestApiName,
            filingAttributeFields: this.filingAttributeFields,
        };
        this.navigateToSourceAsset.emit(data);
        this.dialog.closeAll();
    };

    closePopup = () => {
        this.dialog.closeAll();
    };

    downloadSourceTemplateUrl = async (data) => {
        await this.checkAppVersion();
        let url = this.taxillaApiUrl + '/user-template/download';
        url =
            url +
            '?repositoryId=' +
            this.selectedRepositoryTransformations.repositoryId +
            '&metadataId=' +
            this.assetMetaData.uid +
            '&transformationName=' +
            this.selectedTransformation.chainName +
            '&sourceName=' +
            data.name;
        window.open(url);
    };

    selectTenantAndSubTenantsForTransformation = (tenant: Organization, selected: boolean) => {
        this.subTenants = this._newProcessService.tenantPreOrderTraversal(tenant, []);
        this.subTenants.forEach((subTenant) => (subTenant['selected'] = selected));
        this.addSelectedTotenantsMap(tenant, selected);
        this.scrollToBottm();
    };

    addSelectedTotenantsMap = (tenant: Organization, selected: boolean) => {
        this.allTenantsMap ??= this.getAllTenantMap();
        if (this.allTenantsMap[tenant.id]) {
            this.allTenantsMap[tenant.id].selected = selected;
        } else {
            this.allTenantsMap[tenant.id] = {
                ...tenant,
                selected,
            };
        }
    };

    toggleTenants = (tenant: Organization) => {
        this.selectAllOrganizations = !this.selectAllOrganizations;
        this.subTenants = this._newProcessService.tenantPreOrderTraversal(tenant, []);
        this.subTenants.forEach((subTenant) => (subTenant['selected'] = this.selectAllOrganizations));
        this.scrollToBottm();
    };

    selectIfParentOrg = (event, tenant) => {
        this.addSelectedTotenantsMap(tenant, event);
        this.scrollToBottm();
    };

    checkSubTenant = (subTenant) => {
        if (subTenant.selected) {
            subTenant.selected = false;
            this.addSelectedTotenantsMap(subTenant, false);
        } else {
            subTenant.selected = true;
            this.addSelectedTotenantsMap(subTenant, true);
        }
        this.scrollToBottm();
    };

    clearSearchSubTenant = () => {
        this.filterSubTenantName = '';
    };

    IECheck = (): void => {
        if (FileReader.prototype.readAsBinaryString === undefined) {
            FileReader.prototype.readAsBinaryString = function (fileData) {
                const pt = this;
                const reader = new FileReader();
                reader.onload = function (e) {
                    const blobURL = URL.createObjectURL(fileData);
                    const xhr = new XMLHttpRequest();
                    xhr.open('get', blobURL);
                    xhr.overrideMimeType('text/plain; charset=x-user-defined');
                    xhr.onload = function () {
                        const g = {
                            target: {
                                result: xhr.response,
                            },
                        };
                        pt.onload(g);
                    };
                    xhr.send();
                };
                reader.readAsArrayBuffer(fileData);
            };
        }
    };

    private initializeSubscriptions = () => {
        this.store$
            .select(getFileUploadTransformations$(this.currentApp.serviceId))
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined && JSON.stringify(this.transformations) !== JSON.stringify(data))
            )
            .subscribe((data) => {
                this.transformations = CommonUtilsService.cloneObject(data);
                if (this.transformations?.length > 0) {
                    this._newProcessService.changeTransformationsHierarchy(this.transformations);
                    setTimeout(() => {
                        this.selectedTransformation = undefined;
                        this.selectedTransformationName = undefined;
                        this.selectedAssetToAssetTrnsName = undefined;
                        this.selectedAssetToAssetTransformation = undefined;
                        this.selectUploadTab();
                    }, 1000);
                } else {
                    this.selectElectronicForm();
                }
            });

        this.store$
            .select(getSearchOrAssetToAssetTransformations$(this.currentApp.serviceId))
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined && JSON.stringify(this.transformations) !== JSON.stringify(data))
            )
            .subscribe((data) => {
                this.assetToAssetTransformations = CommonUtilsService.cloneObject(data);
            });
        this.store$
            .select(getSearchAssetTransformations$(this.currentApp.serviceId))
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined && JSON.stringify(this.transformations) !== JSON.stringify(data))
            )
            .subscribe((data) => {
                this.searchAssetToAssetTransformations = CommonUtilsService.cloneObject(data);
            });
        this.store$
            .select(getSearchOrAssetToAssetTransformations$(this.currentApp.serviceId))
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.searchOrAssetToAssetTransformations = CommonUtilsService.cloneObject(data);
            });
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.currentOrgId.push(data);
            });
        this.store$
            .select(getSubscribedApps$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.allSubscribedApps = CommonUtilsService.cloneObject(data);
            });
        this.actions$.pipe(ofType(SetRequest), takeUntil(this.unsubscribe)).subscribe((data) => {
            let res = data?.requestResponse;
            if (res?.response?.pendingAssetDataIds) {
                this.notificationPopupMessage =
                    'There exists some pending processes matching the criteria! Please complete them before proceeding with the current process.';
                this.sourceRestApiName = res.response.restApiName;
                this.dialog.open(this.notificationModal, {
                    panelClass: ['newProcessNotificaionModal', 'matDialogContainer'],
                    disableClose: true,
                });
            } else {
                this.postInitiationOfRequest(res);
            }
        });
    };

    ngOnInit() {
        this.R.isInCreateNewPage = true;
        this.initializeSubscriptions();
        this.startComponent();
        if (this._utils.isIEBrowser()) {
            this.IECheck();
        }
    }

    ngOnDestroy() {
        this.R.isInCreateNewPage = false;
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
