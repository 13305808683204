export const partnerInitialState: PartnerInitialState = {
    partnersDetails: {},
    allOrganizationsList: [],
    partnerOrgs: [],
    organizationPartners: {},
};

export interface PartnerInitialState {
    partnersDetails: {
        [property: string]: {
            sentPartnerInvitations: any[];
            receivedInvitations: any[];
        };
    };
    allOrganizationsList: any[];
    partnerOrgs: any[];
    organizationPartners: {
        // orgId vs orgPartners
        [property: string]: any;
    };
}
