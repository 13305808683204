import { createReducer, on } from '@ngrx/store';

import * as tokenAction from '../actions/tokens.actions';
import { ActiveTokensInitialState } from '../states';

export const ACTIVE_TOKENS_REDUCER = createReducer(
    ActiveTokensInitialState,
    on(tokenAction.ActiveTokensSuccess, (state, action) => {
        return {
            ...state,
            tokens: {
                activeTokens: action.activeTokens,
                count: action.count,
                error: undefined,
            },
        };
    }),
    on(tokenAction.ActiveTokensFailed, (state, action) => {
        return {
            ...state,
            tokens: {
                activeTokens: [],
                count: 0,
                error: action.msg,
            },
        };
    }),
    on(tokenAction.InvalidateTokensSuccess, (state, action) => {
        return {
            ...state,
            invalidate: {
                success: action.msg,
                error: undefined,
            },
        };
    }),
    on(tokenAction.ClearInvalidatedToken, (state) => {
        return {
            ...state,
            invalidate: {
                success: undefined,
                error: undefined,
            },
        };
    }),
    on(tokenAction.InvalidateTokensFailed, (state, action) => {
        return {
            ...state,
            invalidate: {
                success: undefined,
                error: action.msg,
            },
        };
    })
);
export const ACTIVE_TOKENS_REDUCER_KEY = 'tokens';
