import { Dashboard } from '../../interface/dashboard.interface';

export const dashboardState: DashboardState = {
    currentOrganizationId: '',
    selectedDashboard: undefined,
    analyticDashboards: {},
    isDashboardsLoading: undefined,
};

export interface DashboardState {
    currentOrganizationId: string;
    selectedDashboard: Dashboard;
    analyticDashboards: {
        [property: string]: {
            dashboards: [];
        };
    };
    isDashboardsLoading: boolean;
}
