<mat-form-field *transloco="let translate" appRemoveAriaOwns>
    <mat-select [placeholder]="translate('Select State')" [formControl]="control" id="{{id || 'state'}}" [errorStateMatcher]="matcher" [errorStateMatcher]="matcher" disableOptionCentering>
        <mat-option>
            <em>--{{translate('Please select')}}--</em>
        </mat-option>
        <div class="selectSearchFilter">
            <mat-form-field>
                <input matInput class="inputSearchField" autocomplete="off" placeholder="{{translate('Search')}}" [(ngModel)]="state" />
                <button mat-button *ngIf="state" class="selectCloseIcon" matSuffix mat-icon-button aria-label="Clear" (click)='$event.stopPropagation(); state ="";' (keydown)="$event.stopPropagation()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <mat-option *ngFor="let option of states | multiFilter : state" value="{{option.id}}">
            {{option.name}}
        </mat-option>
    </mat-select>
    <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
        <strong>
            <span>* </span>{{translate('required')}}</strong>
    </mat-hint>
    <mat-error *ngIf="control.hasError('errors')" title="{{errors}}">
        {{errors}}
    </mat-error>
</mat-form-field>
