import { Injectable } from '@angular/core';

import { AssetService } from '../../models/assetservice.class';
import { ConfigurationInterface } from '../../models/configurations/configuration.interface';
import { Entity } from '../../models/entity.class';
import { Record } from '../../models/record/record.class';
import { RecordsService } from '../records/records.service';

@Injectable({
    providedIn: 'root',
})
export class EntityService {
    constructor(private _recordService: RecordsService) {}

    /**
     * Submiting all the records that exist in selected entities
     * @param data Contains list of entity ID's to submit, list to entities to pick data, instance ID of the string, isChildEntity property <optional>, process name of action, noAlerts tag
     * @param callbacks Contains success callback method and failure callback method
     */
    private submitEntitiesRecordData = (
        data: {
            entitiesToSubmit: string[];
            entities: Entity[];
            instanceId: string;
            isChildEntity?: boolean;
            service: AssetService;
            process: string;
            noAlerts: boolean;
            configurations: ConfigurationInterface[];
            isArray: boolean;
        },
        callbacks: { successCallback: () => void; failureCallback: () => void }
    ) => {
        /**
         * Looping through all the entities
         */
        let found = false;
        let count = 0;
        data.entities?.forEach((entity) => {
            /**
             * Checking if the entity exists in entitiesToSubmit list
             */
            if (data.entitiesToSubmit.indexOf(entity.uid) > -1) {
                found = true;
                /**
                 * Entity exists in entitiesToSubmit list
                 */
                if (entity.array) {
                    /**
                     * If the entity records type is array then looping through all the records and saving them
                     */
                    (<Record[]>entity.entityData).forEach((record) => {
                        count++;
                        /**
                         * Submitting the record
                         */
                        this._recordService.submitRecord(
                            {
                                entities: data.entities,
                                instanceId: data.instanceId,
                                record: record,
                                service: data.service,
                                process: data.process,
                                noAlerts: data.noAlerts,
                                configurations: data.configurations,
                                isArray: data.isArray,
                                isFromEninvoiceWorkflow: true,
                            },
                            {
                                successCallback: () => {
                                    /**
                                     * If the record was successfully saved
                                     */
                                    count--;
                                    const checkEntities = data.entitiesToSubmit;
                                    let childEntitiesFound =
                                        !checkEntities ||
                                        checkEntities?.length === 0 ||
                                        record.entities?.find(
                                            (entity) =>
                                                checkEntities.includes(entity.uid) &&
                                                ((<Record>entity.entityData)?.id || (<Record[]>entity.entityData)?.length > 0)
                                        ) !== undefined;
                                    if (record.entities && record.entities.length > 0 && childEntitiesFound) {
                                        /**
                                         * If there are child entities then looping through the child entities to submit record data
                                         */
                                        count++;
                                        this.submitEntitiesRecordData(
                                            {
                                                entities: record.entities,
                                                entitiesToSubmit: data.entitiesToSubmit,
                                                instanceId: data.instanceId,
                                                isChildEntity: true,
                                                service: data.service,
                                                process: data.process,
                                                noAlerts: data.noAlerts,
                                                configurations: data.configurations,
                                                isArray: data.isArray,
                                            },
                                            {
                                                successCallback: () => {
                                                    count--;
                                                    if (count === 0) {
                                                        /**
                                                         * If all the child entities are successfully saved then the callback is called
                                                         */
                                                        callbacks.successCallback();
                                                    }
                                                },
                                                failureCallback: () => callbacks.failureCallback,
                                            }
                                        );
                                    } else if (count === 0) {
                                        /**
                                         * If there are no child entities then success callback method is called
                                         */
                                        callbacks.successCallback();
                                    }
                                },
                                failureCallback: callbacks.failureCallback,
                            }
                        );
                    });
                } else {
                    /**
                     * If its entity records data is not array type
                     */
                    count++;
                    this._recordService.submitRecord(
                        {
                            entities: data.entities,
                            instanceId: data.instanceId,
                            record: <Record>entity.entityData,
                            service: data.service,
                            process: data.process,
                            noAlerts: data.noAlerts,
                            configurations: data.configurations,
                            isArray: data.isArray,
                            isFromEninvoiceWorkflow: true,
                        },
                        {
                            successCallback: () => {
                                count--;
                                const checkEntities = data.entitiesToSubmit;
                                const record = <Record>entity.entityData;
                                let childEntitiesFound =
                                    !checkEntities ||
                                    checkEntities?.length === 0 ||
                                    record.entities?.find(
                                        (entity) =>
                                            checkEntities.includes(entity.uid) &&
                                            ((<Record>entity.entityData)?.id || (<Record[]>entity.entityData)?.length > 0)
                                    ) !== undefined;
                                if (record.entities?.length > 0 && childEntitiesFound) {
                                    /**
                                     * If there are any child entities in the record, then those entities are also checked
                                     */
                                    count++;
                                    this.submitEntitiesRecordData(
                                        {
                                            entities: (<Record>entity.entityData).entities,
                                            entitiesToSubmit: data.entitiesToSubmit,
                                            instanceId: data.instanceId,
                                            service: data.service,
                                            process: data.process,
                                            noAlerts: data.noAlerts,
                                            configurations: data.configurations,
                                            isArray: data.isArray,
                                        },
                                        {
                                            successCallback: () => {
                                                count--;
                                                if (count === 0) {
                                                    /**
                                                     * Upon successful save of all the child entities, success callback method is called
                                                     */
                                                    callbacks.successCallback();
                                                }
                                            },
                                            failureCallback: () => callbacks.failureCallback,
                                        }
                                    );
                                } else {
                                    /**
                                     * If there are no child entities, then success callback method is executed
                                     */
                                    if (count === 0) {
                                        /**
                                         * Upon successful save of all the child entities, success callback method is called
                                         */
                                        callbacks.successCallback();
                                    }
                                }
                            },
                            failureCallback: callbacks.failureCallback,
                        }
                    );
                }
            }
        });
        /**
         * If its a child entity and there is no matching child entity to operate on then success callback is called
         */
        !found && data.isChildEntity && callbacks.successCallback();
        !found && !data.isChildEntity && data.entitiesToSubmit.length === 0 && callbacks.successCallback();
    };

    /**
     * Method to list out all the entity ids
     * @param entities List of entities
     */
    private getEntityIdsList = (entities: Array<Entity>): Array<string> => {
        const list = [];
        entities &&
            entities.forEach((element) => {
                /**
                 * Looping through all the entities and listing out the entity ids
                 */
                list.push(element.uid);
                /**
                 * Checking if it has any child entities and listing them out also
                 */
                const childEntities =
                    (element && element.entities && element.entities.length > 0 && this.getEntityIdsList(element.entities)) || [];
                /**
                 * Pushing all the child entitiy ids if they exist
                 */
                childEntities.forEach((child) => list.push(child));
            });
        return list;
    };

    /**
     * Submiting records in list of selective entities
     * @param data Contains List of entities to submit, List of all entities, Instance id of the collection, Process name of the submit action if any, noAlerts No alert messages
     * @param callbacks Contains Success callback method, Failure callback method
     */
    submitEntitiesData = (
        data: {
            entities: Array<Entity>;
            instanceId: string;
            entitiesToSubmit: string[] | true;
            service: AssetService;
            process?: string;
            noAlerts: boolean;
            configurations: ConfigurationInterface[];
            isArray: boolean;
        },
        callbacks: { successCallback: () => void; failureCallback?: () => void }
    ) => {
        let entitiesList = [];
        if (Array.isArray(data.entitiesToSubmit)) {
            /**
             * Id the list already exists then copying the list as it is
             */
            entitiesList = data.entitiesToSubmit;
        } else if (data.entitiesToSubmit) {
            /**
             * If the list doesnt exist and all the records have to be saved then making a list of all existing entity ids
             */
            entitiesList = this.getEntityIdsList(data.entities);
        }
        /**
         * Passing the list to a private method to save the data
         */
        this.submitEntitiesRecordData(
            {
                entitiesToSubmit: entitiesList,
                entities: data.entities,
                instanceId: data.instanceId,
                service: data.service,
                process: data.process,
                noAlerts: data.noAlerts,
                configurations: data.configurations,
                isArray: data.isArray,
            },
            {
                successCallback: callbacks.successCallback,
                failureCallback: callbacks.failureCallback,
            }
        );
    };
}
