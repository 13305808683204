import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

import { RecordUserChangeLog } from '../../interface/record-change-log.interface';
import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { Entity } from '../../models/entity.class';
import { MasterRecord } from '../../models/masters/masterrecord.class';
import { Record } from '../../models/record/record.class';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-commonchangelogrecord',
    templateUrl: './commonchangelogrecord.component.html',
    styleUrls: ['./commonchangelogrecord.component.scss'],
})
export class CommonchangelogrecordComponent implements OnInit {
    @Input() log: RecordUserChangeLog = { logs: [], userName: undefined };
    @Input() entity: Entity | Record | MasterRecord;
    @Input() index: any;
    @Input() fields: any;
    @Input() isnotFromMaster: boolean;
    translateMsg = (msg: string): string => translate('' + msg);

    allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([
        {
            hide: false,
            hideInAllColumnsList: false,
            icon: undefined,
            id: 'field_name',
            name: this.translateMsg('Field Name'),
            type: 'text',
        },
        {
            hide: false,
            hideInAllColumnsList: false,
            icon: undefined,
            id: 'oldValue',
            name: this.translateMsg('Old Value'),
            type: 'text',
        },
        {
            hide: false,
            hideInAllColumnsList: false,
            icon: undefined,
            id: 'newValue',
            name: this.translateMsg('New Value'),
            type: 'text',
        },
    ]);
    selectedColumns = ['field_name', 'oldValue', 'newValue'];
    tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    selectedIndex = 0;

    constructor(public _utils: UtilsService, private sanitizer: DomSanitizer) {}

    startComponent = () => {
        const data = [];
        this.log.logs.forEach((log) => {
            let field;
            let fieldName;
            if (this.entity instanceof Entity) {
                field = this.entity.getField(log.gridId || log.fieldId);
            } else if (this.entity instanceof Record) {
                field = this.entity.getFieldByFieldId(log.gridId || log.fieldId);
            } else if (this.entity instanceof MasterRecord) {
                field = this.entity.getFieldByFieldId(log.fieldId);
            } else {
                field = this.getFieldNameById(log.fieldId);
            }
            if (log && log.modifiedDate) {
                log.modifiedDate = this._utils.transformDateToLocale(
                    log.modifiedDate,
                    'YYYY-MM-DDTHH:MM:SSZ',
                    'ddMMMyyyy HH:MM:SS AM',
                    false
                );
            }
            fieldName = field?.displayName || field?.name || log.fieldId;
            if (log.isGridColumn) {
                fieldName = fieldName + ': ' + log.fieldId;
            }
            data.push({
                recordId: log.fieldId,
                field_name: fieldName,
                oldValue: log.oldValue,
                newValue: log.newValue,
                modifiedDate: log.modifiedDate,
                uiTypeMetadata: field.uiTypeMetadata,
            });
        });
        this.tableData.next(data);
    };

    decodeTextAreaFieldValue = (value: any) => {
        let html = value ? decodeURIComponent(value) : value;
        return html ? this.sanitizer.bypassSecurityTrustHtml(html) : '';
    };

    fetchmore = (index) => {
        this.selectedIndex = index;
    };

    getFieldNameById = (id) => {
        let currentField;
        this.fields.forEach((field) => {
            if (field.id === id) {
                currentField = field;
            }
        });
        return currentField;
    };

    ngOnInit() {
        this.startComponent();
    }
}
