<ng-container *ngTemplateOutlet="package?.id && packageTemplate"></ng-container>
<ng-container *ngTemplateOutlet="groupId && groupTemplate"></ng-container>
<ng-container *ngTemplateOutlet="app?.assetType === 'ASSET' && appTemplate"></ng-container>
<ng-container *ngTemplateOutlet="app?.assetType === 'RECON' && reconTemplate"></ng-container>
<ng-container *ngTemplateOutlet="app?.assetType === 'BRIDGE_ASSET' && bridgeTemplate"></ng-container>

<ng-template #packageTemplate>
    <div class="package">
        <mat-icon class="material-symbols-outlined packageIcon"> web_stories </mat-icon>
        <span class="packageName">{{package.id}}</span>
    </div>
    <div class="packageApps" *ngIf="package.groups?.length > 0">
        <ng-container *ngFor="let group of package.groups; trackBy: trackById">
            <app-left-nav-apps-template (bridgeDialogOpen)="bridgeDialogOpen.emit($event)" (closeMenu)="closeMenu.emit();" [groupId]="group.id" (bridgeDialogOpen)="bridgeDialogOpen.emit($event)"></app-left-nav-apps-template>
        </ng-container>
    </div>
    <div class="packageApps" *ngIf="package.apps?.length > 0">
        <ng-container *ngFor="let app of package.apps; trackBy: trackById">
            <app-left-nav-apps-template (bridgeDialogOpen)="bridgeDialogOpen.emit($event)" (closeMenu)="closeMenu.emit();" [serviceId]="app.serviceId" (bridgeDialogOpen)="bridgeDialogOpen.emit($event)"></app-left-nav-apps-template>
        </ng-container>
    </div>
</ng-template>

<ng-template #groupTemplate>
    <div class="groupTitleLayout relatedApps" [title]="groupId | pascalToCamel" (click)="navigateToGroup(groupId)">
        <div class="groupsContent">
            <div class="groupIconCircle" *transloco="let translate;">
                <img class="groupImg" src="/assets/images/folder.png" alt="{{translate('folder')}}" />
            </div>
        </div>
        <span class="groupName">{{groupId | pascalToCamel}}</span>
    </div>
</ng-template>

<ng-template #appTemplate>
    <div class="groupTitleLayout relatedApps" [title]="app.displayName" (click)="selectionEvent()">
        <div class="groupsContent">
            <div class="groupIconCircle" *transloco="let translate;">
                <img class="groupImg" src="/assets/images/file.png" alt="{{translate('file')}}" />
            </div>
        </div>
        <span class="groupName">{{app.displayName}}</span>
    </div>
</ng-template>

<ng-template #reconTemplate>
    <div class="groupTitleLayout relatedApps" [title]="app.displayName" (click)="selectionEvent()">
        <div class="groupsContent">
            <div class="groupIconCircle">
                <mat-icon class="material-symbols-outlined reconIcon"> compare </mat-icon>
            </div>
        </div>
        <span class="groupName">{{app.displayName}}</span>
    </div>
</ng-template>

<ng-template #bridgeTemplate>
    <div class="groupTitleLayout relatedApps" [title]="app.displayName" (click)='fetchBridgeAssets(app)'>
        <div class="groupsContent">
            <div class="groupIconCircle" *transloco="let translate;">
                <img class="groupImg" src="/assets/images/folder.png" alt="{{translate('folder')}}">
            </div>
        </div>
        <span class="groupName">{{app.displayName}}</span>
    </div>
</ng-template>
