import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { AssetService } from '../assetservice.class';
import { Entity } from '../entity.class';
import { Record } from '../record/record.class';
import { AttributePayload } from './attribute.payload';

export class RequestBusinessKeysPayload {
    entityAttributes: Array<AttributePayload>;

    /**
     * bridge asset id
     */
    bridgeAssetId?: string;

    /**
     * service asset name
     */
    participantName?: string;

    /**
     * service asset id
     */
    assetId?: string;

    serviceId?: string;

    constructor(record: Record, entity: Entity, service: AssetService, bridge: AssetService) {
        this.entityAttributes = [];
        record?.fields
            ?.filter((field) => field.isBusinessKey && !field.autoCalculate)
            .forEach((field) => {
                const entityField = entity.getField(field.id);
                let value = field.value;
                if (entityField.uiTypeMetadata === 'DATE') {
                    value = CommonUtilsService.transformDate(<any>value, 'dd/mm/yyyy', <any>entityField.outputFormat);
                }
                this.entityAttributes.push({
                    dataType: entityField && entityField.datatype && entityField.datatype.toLowerCase(),
                    label: entityField && entityField.name,
                    fullId: entityField && entityField.uid,
                    keyId: (entityField && entity.uid) + '^' + (entityField && entityField.uid),
                    value: value,
                    isPrecaptured:
                        entityField && entityField.captureOnce === undefined
                            ? false
                            : entityField && entityField.captureOnce === 'FILING'
                            ? true
                            : false,
                    isBusinessKey: true,
                    outputFormat: entityField && entityField.outputFormat ? entityField.outputFormat : undefined,
                });
            });

        if (bridge?.restApiName?.length > 0) {
            this.assetId = service.assetMetaUId;
            this.participantName = service.name;
            this.bridgeAssetId = bridge.assetMetaUId;
        }
        if (service.serviceId) {
            this.serviceId = service.serviceId;
        }
    }
}
