import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AssetsService } from '../../services/assets/assets.service';
import { GetWidgetTranslations, SetWidgetTranslations } from '../actions';
import { getActiveLanguage, getWidgetTranslations$ } from '../selectors';

export * as widgetActions from '../actions';
@Injectable()
export class widgetTranslateEffects {
    constructor(private actions$: Actions, private Assest: AssetsService, private store: Store) {}

    private widgetTranslations = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetWidgetTranslations),
            withLatestFrom(this.store.select(getActiveLanguage), this.store.select(getWidgetTranslations$)),
            mergeMap(([action, language, widgets]) => {
                if (widgets?.[action.widgetId]) {
                    return [];
                }
                return from(
                    this.Assest.getAssetMetadataLanguageTemplate({
                        assetMetaUId: action.widgetId,
                    })
                ).pipe(
                    map((response) =>
                        SetWidgetTranslations({
                            language,
                            translations: (response || {}) as any,
                            widgetId: action.widgetId,
                        })
                    )
                );
            })
        );
    });
}
