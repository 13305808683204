<div class="customSearchContainer row mx-0" *ngIf="selectedOutlookFilter?.name === 'customSearch'">
    <mat-tab-group (selectedTabChange)="selectedTabChange.emit($event)" class="w-100" [selectedIndex]="selectedTab">
        <mat-tab label="{{translateMsg('Rules')}}">
            <app-search-builder [search]="search" [searchType]="'SEARCH'"></app-search-builder>
        </mat-tab>
        <mat-tab label="{{translateMsg('Relations')}}" *ngIf="(origin === 'PROCESSES' || origin === 'INBOUND_TRANSMISSIONS')">
            <ng-container *ngIf="relationClicked">
                <div class="relationsTab pl-3">
                    <perfect-scrollbar style="height: 45vh;">
                        <div class="queryBuilderMainCntr">
                            <app-search-builder [search]="search" [showQueryBuilderDirectly]="true" searchType="RELATION" [relationConfigExists]="isRelationConfig"></app-search-builder>
                        </div>
                    </perfect-scrollbar>
                </div>
            </ng-container>
        </mat-tab>
        <mat-tab label="{{translateMsg('Download Report')}}" *ngIf="(origin === 'PROCESSES' || origin === 'INBOUND_TRANSMISSIONS')">
            <ng-container *ngIf="isCustomSearchDownloadReport; else showCriteriaMessage">
                <perfect-scrollbar style="height: 45vh;">
                    <div class="processDownloadReport downloadReportDropdownContainer mat-card w-75">
                        <div class="transformationsContainer">
                            <div class="col-9">
                                <span class="bulkReportCheckBoxCls">
                                    <mat-checkbox name="'checkForMultiple'" (change)="fetchReportTransformations.emit($event)">
                                    </mat-checkbox>
                                </span>
                                <span class="checkBoxSpanCls">{{translateMsg('Is bulk report?')}}</span>
                                <span class="bulkReportSearchSpan">
                                    <span title="{{translateMsg('Processes for which reports have been generated will be downloaded using this option.')}}" class="material-icons-outlined bulkinfoIcon">
                                        info
                                    </span>
                                </span>
                            </div>
                            <div class="col-9 completeInstanceReportContainer">
                                <span class="bulkReportCheckBoxCls">
                                    <mat-checkbox [(ngModel)]="isCompleteInstanceReport" name="'checkForCompleteInstanceReport'" (change)="checkCompleteInstanceReport.emit($event)">
                                    </mat-checkbox>
                                </span>
                                <span class="checkBoxSpanCls">{{translateMsg('Is generate complete process report?')}}</span>
                            </div>
                            <ng-container *ngIf="checkMultiple">
                                <div class="row noMargin">
                                    <div class="col-6">
                                        <material-selectgroup [required]="false" [options]="reportMetadata" [placeholder]="translateMsg('Select Transformations')" [model]="$any(bulkTransformationsList)" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'chainName'" (modelChange)="bulkTransformationsListChange.emit($event);" [groupDisplayNameProperty]="'orgName'" [groupValueProperty]="'transformations'" [title]="$any(bulkTransformationsList)" [isMultple]="true" [hideSelectIfValueExists]="true">
                                        </material-selectgroup>
                                    </div>
                                    <div class="col-4 bulkDownloadBtn">
                                        <material-button [label]="'Initiate'" (onTrigger)="createDownloadBulkRequest.emit()" [disabled]="!bulkTransformationsList || (!bulkTransformationsList?.length)"></material-button>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!checkMultiple">
                                <div class="row noMargin">
                                    <div class="col-6">
                                        <mat-form-field appRemoveAriaOwns>
                                            <mat-select placeholder="{{translateMsg('Select Transformation')}}" [(value)]="selectedReportTransformation" name="transformation" disableOptionCentering (selectionChange)="selectedReportTransformationChange.emit(selectedReportTransformation)">
                                                <mat-optgroup *ngFor="let group of downloadReportTransformations; trackBy: trackByDownloadReportTransformations" [label]="group.orgName">
                                                    <mat-option *ngFor="let transformation of group.values" [value]="transformation" (onSelectionChange)="downloadErrorRepositoryIdChange.emit(group.orgId);">
                                                        {{transformation.displayName || transformation.name}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4 bulkDownloadBtn">
                                        <material-button [label]="translateMsg('Initiate')" (onTrigger)="initiateDownloadReport.emit('downloadReport')" [disabled]="!selectedReportTransformation"></material-button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </perfect-scrollbar>
            </ng-container>
            <ng-template #showCriteriaMessage>
                <div class="d-flex align-items-center justify-content-center w-75" style="height: 50vh;">
                    <span class="px-2">{{translateMsg('Create Criteria From Rules Tab')}}</span>
                    <a (click)="selectedTabChange.emit({index:0})">{{translateMsg('Click Here')}}</a>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{translateMsg('Save Filter')}}">
            <div class="d-flex align-items-center saveFilter">
                <div class="d-flex align-items-center w-75">
                    <mat-form-field class="col-6">
                        <input type="text" matInput placeholder="{{translateMsg('Enter Filter Name')}}" [(ngModel)]="saveFilterName" name="Filter" (ngModelChange)="saveFilterNameChange.emit($event)">
                        <mat-icon matSuffix>mode_edit</mat-icon>
                    </mat-form-field>
                    <material-button class="col-4" [label]="translateMsg('Save and Search')" (onTrigger)="saveAndSearchWithFilter.emit()" [disabled]="!saveFilterName"></material-button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
