import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';

import { NewProcessService } from '../../services/new-process/new-process.service';

@Component({
    selector: 'app-save-recon-template',
    templateUrl: './save-recon-template.component.html',
    styleUrls: ['./save-recon-template.component.scss'],
})
export class SaveReconTemplateComponent {
    title: any;
    formData: FormData;
    restApiName: string;
    templateName: string = '';
    templates: any[] = [];
    public nameError: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SaveReconTemplateComponent>,
        private _newProcessService: NewProcessService
    ) {}

    public saveTemplate = () => {
        this.nameError = '';
        const name = this.templateName.trim();
        const ismatched = this.data.templates.some((template) => template.templateName === name);
        if (!name) {
            this.nameError = translate('Template name is mandatory');
            return;
        }
        if (ismatched) {
            this.nameError = translate('The name is already exists.Use another name to save');
            return;
        }
        this.nameError = '';
        this.data.formData.set('template_name', name);
        this._newProcessService.saveReconTemplate(this.data);
        this.dialogRef.close();
    };

    translateMsg = (msg: string): string => translate(msg);
}
