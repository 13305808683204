<div class="homeAppProcessMainContainer" AppData [app]="bridge" (nodesEmitter)="bridge.assets = $event" (bridgeMetadataEmitter)='bridge.assetData = $event'>
    <ng-container *ngIf="bridge.loading && bridge.loading.bridgeNodes">
        <div class="workflowStagesContainer loaderBlock isLoading"></div>
        <div class="workflowStagesContainer loaderBlock isLoading"></div>
    </ng-container>
    <ng-container *ngIf="bridge.loading && !bridge.loading.bridgeNodes">
        <mat-accordion>
            <ng-container *ngFor="let app of bridge.assets; let i = index">
                <ng-container *ngTemplateOutlet="appTemplate; context:{i: i, app: app, bridge: bridge}"></ng-container>
                <ng-container *ngIf="app.assets?.length > 0">
                    <ng-container *ngFor="let file of app.assets; let j = index">
                        <ng-container *ngTemplateOutlet="reportTemplate; context:{i: j, report: file, app: app, bridge: bridge}"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-accordion>
    </ng-container>
</div>

<ng-template #appTemplate let-i="i" let-app="app" let-bridge="bridge">
    <div [ngClass]="{'alternativeColorDiv': (i%2 !== 0)}" class="matAccordionContainerDiv" *transloco="let translate;">
        <material-accordion [showFooter]="false" [showContentOnlyOnExpand]="true" [expandAccordion]="(i === 0) ? (app.componentType !==  'FILE'):false" [disabled]="false" (openAccrodion)="fetchmore(i)" [ngClass]="{'removePaddingBottom': i === selectedIndex}">
            <div class="materialAccordionHeader">
                <div class="appActionsContainer">
                    <div class="bridge-parcticipanttitle">
                        <span class="participantAppTitle">{{translate('Participant App')}}: </span>
                        <span title="{{(app.displayName || app.name)}}">
                            <app-display-name [serviceId]="app.serviceId"></app-display-name>
                        </span>
                    </div>
                </div>
            </div>
            <div class="materialAccordionHeaderDescription">
                <div class="floatRight">
                    <ng-container *ngIf="app?.canCreateNewProcess">
                        <span class="appRedirectLinks loaderBlock" [class.isLoading]="app.loading?.assetMetaData" AppNewProcess [app]="app" [bridge]="bridge" [getDirectLink]="true" [appId]="app?.id" [bridgeId]="bridge?.id" [newProcessInSameTab]="newProcessInSameTab">
                            <a [routerLink]="[app['appNewProcessLink']]" [hidden]="newProcessInSameTab">{{translate('Process Now')}}</a>
                            <a [hidden]="!newProcessInSameTab">{{translate('Process Now')}}</a>
                        </span>
                        <span class="appsLinks appLinkPipe"> | </span>
                    </ng-container>

                    <ng-container *ngIf="app?.canViewAllProcesses">
                        <ng-container *ngIf="app?.name">
                            <span class="appRedirectLinks loaderBlock" [class.isLoading]="app.loading && app.loading.assetMetaData" AppProcessesSelection [app]="app" [bridge]="bridge" [getDirectLink]="true" [appId]="app && app.id" [bridgeId]="bridge && bridge.id" [allProcessesInSameTab]="allProcessesInSameTab">
                                <a [routerLink]="app && [app['appProcessesLink']]" [hidden]="allProcessesInSameTab">{{translate('All Processes')}}</a>
                                <a [hidden]="!allProcessesInSameTab">{{translate('All Processes')}}</a>
                            </span>
                        </ng-container>
                        <span class="appsLinks appLinkPipe"> | </span>
                    </ng-container>

                    <span class="appRedirectLinks loaderBlock" [class.isLoading]="app.loading && app.loading.assetMetaData" AppSelection [app]="app" [bridge]="bridge" [navigateToAppHomeOnly]="navigateToAppHomeOnly">{{translate('App Home')}}</span>
                </div>
            </div>
            <div class="materialAccordionBody">
                <ng-container *ngIf="i === selectedIndex">
                    <div class="homeAppProcessBodyMainContainer packageApp" AppData [app]="app" [bridge]="bridge" (assetDataEmitter)="app.assetData = $event" (requestEmitter)="app.request = $event" (instanceEmitter)="app.instance = $event" (statusEmitter)="app.status = $event" (workflowStagesEmitter)="app.workflowStages = $event" (filingAttributesEmitter)="app.filingAttributes = $event">
                        <app-card [app]="app" [assetData]="app.assetData" [request]="app.request" [workflowStages]="app.workflowStages" [filingAttributes]="app.filingAttributes" [bridge]="bridge" [loading]="app.loading" [instance]="app.instance" [processInSameTab]="processInSameTab"></app-card>
                    </div>
                </ng-container>
            </div>
        </material-accordion>
    </div>
</ng-template>

<ng-template #reportTemplate let-i="i" let-report="report" let-app="app" let-bridge="bridge">
    <div [ngClass]="{'alternativeColorDiv': (i%2 !== 0)}" class="matAccordionContainerDiv" *transloco="let translate;">
        <material-accordion [showFooter]="false" [showContentOnlyOnExpand]="true" [expandAccordion]="(i === 0) ? (report.componentType !==  'FILE'):false" [disabled]="false" (openAccrodion)="fetchmore(i)" [ngClass]="{'removePaddingBottom': report.componentType ===  'FILE'}">
            <div class="materialAccordionHeader">
                <div class="appActionsContainer">
                    <div class="bridge-parcticipanttitle">
                        <span class="participantAppTitle">{{translate('Participant File')}}: </span>
                        <span title="{{(report.displayName || report.name)}}">{{(report.displayName || report.name)}}</span>
                    </div>
                </div>
            </div>
            <div class="materialAccordionHeaderDescription">
                <div class="floatRight">
                    <ng-container>
                        <span class="appRedirectLinks loaderBlock" [class.isLoading]="report.loading && report.loading.assetMetaData" AppNewProcess [app]="app" [bridge]="bridge" [getDirectLink]="true" [appId]="report && report.id" [bridgeId]="bridge && bridge.id" [report]="report" [newProcessInSameTab]="newProcessInSameTab">
                            <a [routerLink]="[report['appNewProcessLink']]" [hidden]="newProcessInSameTab">{{translate('Process Now')}}</a>
                            <a [hidden]="!newProcessInSameTab">{{translate('Process Now')}}</a>
                        </span>
                        <span class="appsLinks appLinkPipe"> | </span>
                    </ng-container>
                    <ng-container *ngIf="report.canProcessReports">
                        <ng-container *ngIf="report && report.name">
                            <span class="appRedirectLinks loaderBlock" [class.isLoading]="report.loading && report.loading.assetMetaData" AppProcessesSelection [app]="app" [bridge]="bridge" [getDirectLink]="true" [appId]="report && report.id" [bridgeId]="bridge && bridge.id" [report]="report" [allReportsInSameTab]="allReportsInSameTab">
                                <a [routerLink]="report && [report.appProcessesLink]" [hidden]="allReportsInSameTab">{{translate('All Processes')}}</a>
                                <a [hidden]="!allReportsInSameTab">{{translate('All Processes')}}</a>
                            </span>
                        </ng-container>
                        <span class="appsLinks appLinkPipe"> | </span>
                    </ng-container>

                    <span class="appRedirectLinks loaderBlock" [class.isLoading]="report.loading && report.loading.assetMetaData" AppSelection [app]="app" [report]="report" [bridge]="bridge" [navigateToAppHomeOnly]="navigateToAppHomeOnly">{{translate('App Home')}}</span>
                </div>
            </div>
            <div class="materialAccordionBody">
                <ng-container *ngIf="i === selectedIndex">
                    <div class="homeAppProcessBodyMainContainer packageApp" AppData [app]="app" [bridge]="bridge" (assetDataEmitter)="app.assetData = $event" (requestEmitter)="app.request = $event" (instanceEmitter)="app.instance = $event" (statusEmitter)="app.status = $event" (workflowStagesEmitter)="app.workflowStages = $event" (filingAttributesEmitter)="app.filingAttributes = $event; report.filingAttributes = $event;">
                        <app-card [app]="app" [report]="report" [assetData]="report.assetData" [request]="report.request" [workflowStages]="report.workflowStages" [filingAttributes]="report.filingAttributes" [bridge]="bridge" [loading]="report.loading" [instance]="report.instance"></app-card>
                    </div>
                </ng-container>
            </div>
        </material-accordion>
    </div>
</ng-template>
