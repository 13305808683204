import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { NotificationEvent } from '../../interface/notifications-event.interface';
import { ReconEvent } from '../../interface/recon-event.interface';
import {
    ClearEmailContent,
    ClearEmailNotifications,
    ClearEventSubscriptions,
    ClearEventTypes,
    ClearNotifications,
    ClearProcessLogs,
    ClearReconEvents,
    DeleteEventSubscriptionSuccess,
    GetNotificationsViaPagination,
    GetSubscriptionsViaPagination,
    SessionLogoutSuccessfull,
    SetEmailContent,
    SetEmailNotifications,
    SetEmailPagination,
    SetEventSubscriptions,
    SetEventTypes,
    SetNotifications,
    SetPagination,
    SetProcessLogs,
    SetReconEvents,
} from '../actions';
import { notificationsState } from '../states';

const instancesAdapter: { [property: string]: EntityAdapter<NotificationEvent> } = {};
const reconAdapter: { [property: string]: EntityAdapter<ReconEvent> } = {};
const selectEventId = (a: NotificationEvent) => {
    return a.eventId;
};
const selectReconId = (a: ReconEvent) => {
    return a.recordedTime;
};

export const NOTIFICATIONS_REDUCER = createReducer(
    notificationsState,
    on(SetNotifications, (state, action) => {
        let adapter: EntityAdapter<NotificationEvent> = instancesAdapter?.[action.resourceId];
        let adapterState: EntityState<NotificationEvent>;
        if (!instancesAdapter?.[action.resourceId]) {
            adapter = createEntityAdapter<NotificationEvent>({
                selectId: selectEventId,
            });
            instancesAdapter[action.resourceId] = adapter;
        }
        adapterState = state.instanceIdVsEvents?.[action.resourceId] || adapter.getInitialState();

        return {
            ...state,
            instanceIdVsEvents: {
                ...state.instanceIdVsEvents,
                [action.resourceId]: adapter.addMany(action.notifications, adapterState),
            },
            pagination: {
                ...state.pagination,
                [action.resourceId]: {
                    ...state.pagination[action.resourceId],
                    pagingState: action.pagingState,
                },
            },
        };
    }),

    on(ClearNotifications, (state, action) => {
        return {
            ...state,
            instanceIdVsEvents: {
                ...state.instanceIdVsEvents,
                [action.resourceId]: {
                    ids: [],
                    entities: {},
                },
            },
            pagination: {
                ...state.pagination,
                [action.resourceId]: {
                    ...state.pagination[action.resourceId],
                    pagingState: undefined,
                },
            },
        };
    }),

    on(SetReconEvents, (state, action) => {
        let adapter: EntityAdapter<ReconEvent> = reconAdapter?.[action.resourceId];
        let adapterState: EntityState<ReconEvent>;
        if (!reconAdapter?.[action.resourceId]) {
            adapter = createEntityAdapter<ReconEvent>({
                selectId: selectReconId,
            });
            reconAdapter[action.resourceId] = adapter;
        }
        adapterState = state.reconIdVsEvents?.[action.resourceId] || adapter.getInitialState();
        return {
            ...state,
            reconIdVsEvents: {
                ...state.reconIdVsEvents,
                [action.resourceId]: adapter.addMany(action.events, adapterState),
            },
        };
    }),
    on(ClearReconEvents, (state, action) => {
        return {
            ...state,
            reconIdVsEvents: {
                ...state.reconIdVsEvents,
                [action.resourceId]: {
                    entities: {},
                    ids: [],
                },
            },
        };
    }),
    on(SessionLogoutSuccessfull, (state, action) => {
        return {
            instanceIdVsEvents: {},
            pagination: {},
            reconIdVsEvents: {},
        };
    }),
    on(GetNotificationsViaPagination, (state, action) => {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                [action.resourceId]: {
                    ...state.pagination[action.resourceId],
                    eventType: action.eventType,
                },
            },
        };
    }),
    on(SetProcessLogs, (state, action) => {
        let adapter: EntityAdapter<NotificationEvent> = instancesAdapter?.[action.resourceId];
        let adapterState: EntityState<NotificationEvent>;
        if (!instancesAdapter?.[action.resourceId]) {
            adapter = createEntityAdapter<NotificationEvent>({
                selectId: selectEventId,
            });
            instancesAdapter[action.resourceId] = adapter;
        }
        adapterState = state.instanceIdVsEvents?.[action.resourceId] || adapter.getInitialState();
        return {
            ...state,
            instanceIdVsEvents: {
                ...state.instanceIdVsEvents,
                [action.resourceId]: adapter.addMany(action.processLogs, adapterState),
            },
            pagingState: {
                ...state.pagination,
                [action.resourceId]: action.pagingState,
            },
        };
    }),
    on(ClearProcessLogs, (state, action) => {
        return {
            ...state,
            instanceIdVsEvents: {
                ...state.instanceIdVsEvents,
                [action.resourceId]: {
                    entities: {},
                    ids: [],
                },
            },
        };
    }),
    on(SetPagination, (state, action) => {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                [action.resourceId]: {
                    ...state.pagination[action.resourceId],
                    index: action.index,
                    size: action.size,
                },
            },
        };
    }),
    on(SetEmailPagination, (state, action) => {
        return {
            ...state,
            emailNotificationsPagination: {
                ...state.emailNotificationsPagination,
                index: action.index !== undefined ? action.index : action.size === undefined ? state.emailNotificationsPagination.index : 0,
                size: action.size !== undefined ? action.size : state.emailNotificationsPagination?.size,
            },
        };
    }),
    on(SetEmailNotifications, (state, action) => {
        return {
            ...state,
            emailNotifications: [...(state.emailNotifications || []), ...action.emailNotifications],
            emailNotificationsPagination: {
                ...state.emailNotificationsPagination,
                size: action.fetchSize !== undefined ? action.fetchSize : state.emailNotificationsPagination.size,
                pagingState: action.pagingState !== undefined ? action.pagingState : state.emailNotificationsPagination.pagingState,
                index: action.index !== undefined ? action.index : state.emailNotificationsPagination.index,
            },
        };
    }),
    on(GetSubscriptionsViaPagination, (state, action) => {
        return {
            ...state,
            eventSubscriptionsPagination: {
                ...state.eventSubscriptionsPagination,
                index:
                    action.index !== undefined
                        ? action.index
                        : action.fetchSize === undefined
                        ? state.eventSubscriptionsPagination.index
                        : 0,
                size: action.fetchSize !== undefined ? action.fetchSize : state.eventSubscriptionsPagination?.size,
            },
        };
    }),

    on(SetEventSubscriptions, (state, action) => {
        return {
            ...state,
            eventSubscriptions: [...(state.eventSubscriptions || []), ...action?.eventSubscriptions],
            eventSubscriptionsPagination: {
                ...state.eventSubscriptionsPagination,
                size: action.fetchSize !== undefined ? action.fetchSize : state.eventSubscriptionsPagination.size,
                pagingState: action.pagingState !== undefined ? action.pagingState : state.eventSubscriptionsPagination.pagingState,
                index: action.index !== undefined ? action.index : state.eventSubscriptionsPagination.index,
            },
        };
    }),

    on(ClearEventSubscriptions, (state) => {
        return {
            ...state,
            eventSubscriptions: [],
            eventSubscriptionsPagination: {
                size: 0,
                pagingState: '',
                index: 0,
            },
        };
    }),

    on(DeleteEventSubscriptionSuccess, (state, action) => {
        return {
            ...state,
            eventSubscriptions: state.eventSubscriptions.filter((subscription) => subscription.subscriptionId !== action.subscriptionId),
        };
    }),

    on(ClearEmailNotifications, (state) => {
        return {
            ...state,
            emailNotifications: [],
            emailNotificationsPagination: {
                size: 0,
                pagingState: '',
                index: 0,
            },
        };
    }),
    on(SetEventTypes, (state, action) => {
        return {
            ...state,
            eventTypes: [...(state.eventTypes || []), ...action.eventTypes],
        };
    }),
    on(ClearEventTypes, (state) => {
        return {
            ...state,
            eventTypes: [],
        };
    }),
    on(SetEmailContent, (state, action) => {
        return {
            ...state,
            emailContent: {
                ...state?.emailContent,
                [action.messageId]: {
                    ...state?.emailContent?.[action?.messageId],
                    attachmentList: [...(state?.emailContent?.[action?.messageId]?.attachmentList || []), ...action.attachmentList],
                    messageContent: action.messageContent,
                    subject: action.subject,
                    emailId: action.emailId,
                    messageId: action.messageId,
                },
            },
        };
    }),
    on(ClearEmailContent, (state) => {
        return {
            ...state,
            emailContent: {},
        };
    })
);
export const NOTIFICATIONS_REDUCER_KEY = 'notifications';
