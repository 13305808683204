import { createAction, props } from '@ngrx/store';

export enum PartnerActionType {
    GET_ALL_PARTNERS = '[GET_ALL_PARTNERS] Get All Partners',
    GET_ALL_PARTNERS_SUCCESS = '[GET_ALL_PARTNERS_SUCCESS] Get All Partners Success',
    GET_RECEIVED_INVITATIONS = '[GET_RECEIVED_INVITATIONS] Get Received Invitations',
    GET_RECEIVED_INVITATIONS_SUCCESS = '[GET_RECEIVED_INVITATIONS_SUCCESS] Get Received Invitations Success',
    SEND_PARTNER_INVITATION = '[SEND_PARTNER_INVITATION] Send Partner Invitation',
    SEND_PARTNER_INVITATION_SUCCESS = 'Send Partner Invitation Success',
    RESEND_PARTNER_INVITATION = 'Resend Partner Invitation',
    PARTNER_INVITATION_ACTION = 'Partner Invitation Action',
    DELETE_PARTNER_INVITATION = 'Delete Partner Invitation',
    GET_ALL_ORGANIZATIONS = 'Get All Organizations',
    GET_ALL_ORGANIZATIONS_SUCCESS = 'Get All Organizations Success',
    GET_PARTNERS = '[PARTNER] Get Partner Orgs',
    GET_PARTNER_ORGS = '[PARTNER] Get Partner Orgs',
    SET_PARTNER_ORGS_SUCCESS = '[Partner]Set Partner Orgs Success',
    UPDATE_PARTNER_EXTERNAL_ID = '[Partner] Update Partner External id',
    UPDATE_PARTNER_EXTERNAL_ID_SUCCESSFUL = '[Partner] Update Partner External id Successful',
    GET_ORGANIZATION_PARTNERS = '[Partner] Get Organization Partners',
    SET_ORGANIZATION_PARTNERS = '[Partner] Set Organization Partners',
}

export const GetAllPartners = createAction(
    PartnerActionType.GET_ALL_PARTNERS,
    props<{
        payload: {
            searchString: string;
            actionType: string;
            status: string;
        };
    }>()
);

export const SetInvitedPartners = createAction(
    PartnerActionType.GET_ALL_PARTNERS_SUCCESS,
    props<{
        organizationId: string;
        sentPartners: any[];
    }>()
);

export const GetReceivedInvitations = createAction(
    PartnerActionType.GET_RECEIVED_INVITATIONS,
    props<{
        payload: { organizationId: string; status: string };
    }>()
);

export const SetReceivedInvitations = createAction(
    PartnerActionType.GET_RECEIVED_INVITATIONS_SUCCESS,
    props<{
        organizationId: string;
        receivedInvitations: any[];
    }>()
);

export const SendPartnerInvitation = createAction(
    PartnerActionType.SEND_PARTNER_INVITATION,
    props<{
        data: {
            invitationArray: any[];
        };
    }>()
);

export const SendPartnerInvitationSuccess = createAction(PartnerActionType.SEND_PARTNER_INVITATION_SUCCESS);

export const ResendPartnerInvitation = createAction(
    PartnerActionType.RESEND_PARTNER_INVITATION,
    props<{
        data: {
            partnerOrganizationId: string;
        };
    }>()
);

export const PartnerInvitationAction = createAction(
    PartnerActionType.PARTNER_INVITATION_ACTION,
    props<{
        data: {
            partnerOrganizationId: string;
            type: string;
        };
    }>()
);

export const DeletePartnerInvitation = createAction(
    PartnerActionType.DELETE_PARTNER_INVITATION,
    props<{
        data: {
            partnerOrganizationId: string;
            type: string;
        };
    }>()
);

export const GetAllOrganizations = createAction(
    PartnerActionType.GET_ALL_ORGANIZATIONS,
    props<{
        data: {
            hidden: boolean;
            enabled: boolean;
            providerOrganizationId: string;
        };
    }>()
);

export const GetAllOrganizationsSuccess = createAction(
    PartnerActionType.GET_ALL_ORGANIZATIONS_SUCCESS,
    props<{
        allOrganizationsList: any[];
    }>()
);

export const GetPartnerOrgs = createAction(PartnerActionType.GET_PARTNER_ORGS);

export const SetPartnerOrgs = createAction(
    PartnerActionType.SET_PARTNER_ORGS_SUCCESS,
    props<{
        partnerOrgs: any[];
    }>()
);

export const UpdatePartnerExternalId = createAction(
    PartnerActionType.UPDATE_PARTNER_EXTERNAL_ID,
    props<{
        data: {
            partnerOrganizationId: string;
            partnerExternalId: string;
        };
    }>()
);

export const UpdateExternalIdSuccessful = createAction(
    PartnerActionType.UPDATE_PARTNER_EXTERNAL_ID_SUCCESSFUL,
    props<{
        message: string;
    }>()
);

export const GetOrganizationPartners = createAction(
    PartnerActionType.GET_ORGANIZATION_PARTNERS,
    props<{
        organizationId: string;
    }>()
);

export const SetOrganizationPartners = createAction(
    PartnerActionType.SET_ORGANIZATION_PARTNERS,
    props<{
        organizationId: string;
        partners: any;
    }>()
);
