import { AssetService } from './assetservice.class';

export class SearchFilter {
    createdByUserId: string;

    createdDate: string;

    criteria: string;

    filterName: string;

    id: string;

    lastModifiedByUserId: string;

    lastModifiedDate: string;

    objectId: string;

    organizationId: string;

    userId: string;

    restAPIName?: string;

    app?: AssetService;

    x?: number;

    y?: number;

    rows?: number;

    cols?: number;

    originalCoordinates?: { x: number; y: number; height: number; width: number };

    constructor(data: SearchFilter) {
        this.createdByUserId = data?.createdByUserId;
        this.createdDate = data?.createdDate;
        this.criteria = data?.criteria;
        this.filterName = data?.filterName;
        this.id = data?.id;
        this.lastModifiedByUserId = data?.lastModifiedByUserId;
        this.lastModifiedDate = data?.lastModifiedDate;
        this.objectId = data?.objectId;
        this.organizationId = data?.organizationId;
        this.userId = data?.userId;
        this.restAPIName = data?.restAPIName;
        this.app = data?.app;
        this.updateCoordinates(data?.x, data?.y, data?.rows, data?.cols);
    }

    setAppService? = (app: AssetService) => {
        this.app = app;
    };

    updateCoordinates? = (x: number, y: number, width: number, height: number) => {
        this.x = x;
        this.y = y;
        this.rows = width;
        this.cols = height;
        this.originalCoordinates = { x, y, width, height };
    };

    isPositionChanged? = (x: number, y: number, width: number, height: number) => {
        const original = this.originalCoordinates;
        return original.x !== x || original.y !== y || original.width !== width || original.height !== height;
    };
}
