import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { report } from 'process';

import { AssetService } from '../../models/assetservice.class';
import { RecordReports } from '../../models/record/recordreports.class';
import { Report } from '../../models/report.interface';
import { ReportMetaData } from '../../models/reportmetadata.interface';
import { AssetsService } from '../assets/assets.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { InstancesService } from '../instances/instances.service';
import { LocationsService } from '../locations/locations.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CommonReportsService {
    analyticsMetaData: {
        [property: string]: RecordReports[];
    };

    constructor(
        private _instances: InstancesService,
        private _locations: LocationsService,
        private _utils: CommonUtilsService,
        private _assets: AssetsService,
        private _libUtils: UtilsService,
        protected R: RootScopeService,
        private store$: Store
    ) {}

    generateReports = (
        orgNamesMap,
        reports: UIReport[],
        instanceId: string,
        assetId: string,
        currentApp: AssetService,
        noAlerts: boolean
    ) => {
        return new Promise((resolve) => {
            const promises = [];
            promises[0] = this.getInstanceReports(instanceId, assetId, currentApp, noAlerts);
            promises[1] = this.getInstanceAnalytics(instanceId, assetId, currentApp, noAlerts);
            Promise.all(promises).then(([{ orgIdVsNamesMap, reportsResponse }, { analyticsResponse, analyticsMetaData }]) => {
                const analytics: Report[] = analyticsResponse;
                orgNamesMap = orgIdVsNamesMap;
                const providerIds = this._libUtils.getProviderIds(undefined, []);
                if (reports === undefined) {
                    reports = [];
                } else {
                    reports.splice(0);
                }
                providerIds.forEach((id) => {
                    (analyticsMetaData[id] || []).forEach((report) => {
                        this.generateReportsOrganizationObject(orgNamesMap, reports, id, report, instanceId);
                    });
                });
                ([...analytics, ...reportsResponse] || []).forEach((report) => {
                    this.generateReportsOrganizationObject(
                        orgNamesMap,
                        reports,
                        report.transformationRepositoryId,
                        undefined,
                        instanceId,
                        report
                    );
                });
                this.changeReportsHierarchy(reports, providerIds);
                resolve(reports);
            });
        });
    };

    protected getInstanceReports = (instanceId, assetId, currentApp, noAlerts: boolean) => {
        return new Promise((resolve: (data: { reportsResponse: Report[]; orgIdVsNamesMap: { [property: string]: string } }) => void) => {
            const restApiName = this._libUtils.getRestApiName(currentApp);
            let reportsData;
            if (restApiName) {
                reportsData = {
                    restApiName,
                    assetDataId: instanceId,
                    noAlerts: noAlerts,
                };
            } else {
                reportsData = {
                    assetDataId: instanceId,
                    assetMetaUId: assetId || currentApp.assetMetaUId,
                    noAlerts: noAlerts,
                };
            }
            this._instances.fetchReports(reportsData).then(async ({ orgIdVsName, reports }) => {
                // As we have cache control i'm using below method again instead of storing in localstorage using it
                const displaynames = await this.getChainDisplayNames(currentApp.restApiName, assetId);
                reports?.forEach((report) => {
                    report.hideRegenerate = true;
                    report.chainName = displaynames[report.chainName];
                });
                const providerIds = this._libUtils.getProviderIds(undefined, []);
                const orgIdVsNames = await this.getOrgNamesByIds(providerIds, noAlerts);
                resolve({
                    reportsResponse: reports,
                    orgIdVsNamesMap: orgIdVsName || orgIdVsNames,
                });
            });
        });
    };

    protected getInstanceAnalytics = (instanceId: string, assetId: string, currentApp: AssetService, noAlerts: boolean) => {
        return new Promise((resolve) => {
            const promises = [];
            this.getAnalyticNames(
                {
                    instanceId,
                    assetId,
                    currentApp,
                    noAlerts,
                },
                (res) => {
                    const providerIds = this._libUtils.getProviderIds(undefined, []);
                    const oneProviderOrg = providerIds.length ? [providerIds[0]] : [];
                    oneProviderOrg.forEach((providerId, index) => {
                        promises[index] = this.getAnalyticsByProviderId(instanceId, providerId, currentApp, noAlerts);
                    });
                    Promise.all(promises).then(async (response) => {
                        // As we have cache control i'm using below method again instead of storing in localstorage using it
                        const displaynames = await this.getChainDisplayNames(currentApp.restApiName, assetId);
                        const reports = [];
                        response.forEach((responseObject) => {
                            Object.keys(responseObject || {}).forEach((analyticKey) => {
                                (responseObject[analyticKey] || []).forEach((report, index) => {
                                    responseObject[analyticKey][index].chainName = displaynames[report.chainName];
                                    reports.push(report);
                                });
                            });
                        });
                        resolve({ analyticsResponse: reports, analyticsMetaData: res || {} });
                    });
                }
            );
        });
    };

    getChainDisplayNames = (restApiName: string, assetId: string) => {
        return new Promise((resolve) => {
            this._assets.getChainDisplayNames({ restApiName, assetId }).then((res) => {
                resolve(res.chainNameVsChainDisplayName);
            });
        });
    };

    generateReportsOrganizationObject = (
        orgNamesMap,
        reports,
        organizationId: string,
        reportMetaData: ReportMetaData,
        instanceId: string,
        report?: Report
    ) => {
        let organization = reports.find((organizationObject) => organizationObject.id === organizationId);
        if (!organization) {
            organization = {
                id: organizationId,
                name: orgNamesMap[organizationId],
                chains: [],
            };
            reports.push(organization);
        }
        this.generateReportsChainObject(organization, reportMetaData, instanceId, report);
    };

    changeReportsHierarchy = (reports: UIReport[], providerIds) => {
        let allReports = CommonUtilsService.cloneObject(reports);
        reports.splice(0);
        providerIds.forEach((orgId) => {
            allReports.find((object, index) => {
                if (object.id === orgId) {
                    reports.push(allReports[index]);
                }
            });
        });
    };

    getOrgNamesByIds = (orgIdList, noAlerts: boolean) => {
        return new Promise((resolve: (orgIdVsNamesMap: { [property: string]: string }) => void) => {
            const cachedOrgidsObj = this.getCachedOrgNames(orgIdList);
            if (!cachedOrgidsObj || (cachedOrgidsObj && cachedOrgidsObj.orgIdList && cachedOrgidsObj.orgIdList.length > 0)) {
                const data = {
                    orgIds: (cachedOrgidsObj && cachedOrgidsObj.orgIdList) || orgIdList,
                    noAlerts,
                };
                this._locations.getOrgNamesByIds(data).then((res) => {
                    const storedOrgIdsList = this._utils.getFromStorage('orgIdVsNames');
                    const orgNamesList = {
                        ...(cachedOrgidsObj?.orgIdVsNamesList || {}),
                        ...(res || {}),
                        ...(storedOrgIdsList || {}),
                    };
                    this._utils.setInStorage('orgIdVsNames', orgNamesList);
                    resolve(orgNamesList);
                });
            } else {
                resolve(cachedOrgidsObj?.orgIdVsNamesList);
            }
        });
    };

    getCachedOrgNames = (orgIdsList) => {
        const orgIdVsNames = this._utils.getFromStorage('orgIdVsNames');
        const obj = {};
        const cloneOrgidsList = CommonUtilsService.cloneObject(orgIdsList);
        if (orgIdVsNames) {
            const totalKeys = Object.keys(orgIdVsNames);
            for (let i = 0; i < orgIdsList.length; i++) {
                if (totalKeys.indexOf(orgIdsList[i]) > -1) {
                    obj[orgIdsList[i]] = orgIdVsNames[orgIdsList[i]];
                    cloneOrgidsList.forEach((id, index) => {
                        if (id === orgIdsList[i]) {
                            cloneOrgidsList.splice(index, 1);
                        }
                    });
                }
            }
            const data = {
                orgIdList: cloneOrgidsList,
                orgIdVsNamesList: obj,
            };
            return data;
        } else {
            return undefined;
        }
    };

    protected generateReportsChainObject = (
        organization: {
            name: string;
            displayName: string;
            id: string;
            chains: { name: string; displayName: string; transformations: { name: string; reports: UIReport[] }[] }[];
        },
        reportMetaData: ReportMetaData,
        instanceId: string,
        report?: Report
    ) => {
        const chains = organization.chains;
        const name = (reportMetaData && reportMetaData.name) || (report && report.chainName);
        const displayName = (reportMetaData && reportMetaData.displayName) || (report && report.chainName);
        let chain: {
            name: string;
            displayName: string;
            transformations: any[];
        } = this.getChain(chains, name);
        if (!chain) {
            chain = {
                name,
                displayName,
                transformations: [],
            };
            chains.push(chain);
        }
        const transformations = chain.transformations;
        if (reportMetaData?.orderedTransformations?.length > 0) {
            reportMetaData.orderedTransformations
                .filter((order) => order.targetStores?.indexOf('TAXILLA') > -1)
                .forEach((order) => {
                    this.generateReportsTransformationObject(chain, transformations, order, instanceId);
                });
        } else if (report?.transformationName) {
            this.generateReportsTransformationObject(
                chain,
                transformations,
                { name: report.transformationName, reportNameConfig: undefined, targetFieldIds: undefined, targetStores: undefined },
                instanceId,
                report
            );
        }
    };

    protected generateReportsTransformationObject = (
        chain: { name: string; transformations: { name: string; reports: any[] }[] },
        transformations: { name: string; reports: any[] }[],
        reportMetaData: ReportMetaData['orderedTransformations'][0],
        instanceId: string,
        report?: Report
    ) => {
        const name = reportMetaData?.name || report?.transformationName;
        let transformation = this.getTransformation(transformations, reportMetaData.name);
        if (!transformation) {
            transformation = {
                name,
                reports: [],
            };
            transformations.push(transformation);
        }
        this.generateReportsMetdaDataObject(transformation.reports, chain.name, transformation.name, instanceId, report);
    };

    protected getTransformation = (
        transformations,
        transformationName
    ): {
        name: string;
        reports: {
            chainName: string;
            reportCategory: string;
            transformationName: string;
            reportsList: Report[];
        }[];
    } => {
        return transformations.find((transformation) => transformation.name === transformationName);
    };

    protected generateReportsMetdaDataObject = (reportMetaDataList, chainName, transformationName, instanceId: string, report?: Report) => {
        let reportMetaData = this.getReportsMetaData(reportMetaDataList, chainName, transformationName, instanceId);
        if (!reportMetaData) {
            reportMetaData = {
                chainName,
                reportCategory: 'ANALYTICS',
                transformationName,
                name: transformationName,
                reportsList: [],
            };
            reportMetaDataList.push(reportMetaData);
        }
        if (report) {
            report['reportLink'] =
                environment.taxilla_api +
                '/process/v1/files?fileUrl=' +
                encodeURIComponent(report.reportUrl) +
                '&fileName=' +
                encodeURIComponent(report.reportName);
            report['isPrinatable'] = this._libUtils.isInPdf(report.reportName);
            report.checkSum && (report.checkSum = this._libUtils.convertCheckSumStrToKeyValue(report.checkSum));
            reportMetaData.reportsList.push(report);
        }
    };

    protected getReportsMetaData = (
        reportMetaDataList,
        chainName: string,
        transformationName: string,
        instanceId: string
    ): {
        chainName: string;
        reportCategory: string;
        transformationName: string;
        reportsList: Report[];
        name: string;
        checkSum?: string;
    } => {
        return reportMetaDataList.find((item) => item.chainName === chainName && item.transformationName === transformationName);
    };

    protected getAnalyticNames = (data: { instanceId: string; assetId: string; currentApp: AssetService; noAlerts: boolean }, callback) => {
        this._instances.getAnalyticReportsMetaData(
            {
                instanceId: data.instanceId,
                providerOrgId: undefined,
                service: data.currentApp,
                assetId: data.assetId,
                noAlerts: data.noAlerts,
            },
            {
                successCallback: (res) => {
                    this.analyticsMetaData = res;
                    callback(res);
                },
            }
        );
    };

    protected getChain = (chains, chainName) => {
        return chains.find((chain) => chain.name === chainName || chain.displayName === chainName);
    };

    protected getAnalyticsByProviderId = (instanceId: string, providerId: string, currentApp: AssetService, noAlerts: boolean) => {
        return new Promise((resolve) => {
            this._instances.getAnalyticReportsWithNames(
                {
                    instanceId,
                    service: currentApp,
                    noAlerts: noAlerts,
                },
                undefined,
                {
                    successCallback: (res: any) => {
                        resolve(res.response || res);
                    },
                }
            );
        });
    };

    generateReport = ({
        chainName,
        instanceId,
        orgNamesMap,
        reports,
        assetId,
        currentService,
        dontFetchReports,
        noAlerts,
    }: {
        chainName: string;
        instanceId: string;
        orgNamesMap: { [property: string]: string };
        reports: UIReport[];
        assetId: string;
        currentService: AssetService;
        dontFetchReports?: boolean;
        noAlerts?: boolean;
    }) => {
        return new Promise((resolve, reject) => {
            let user = this._utils.getFromStorage('user');
            let repositoryId = '';
            reports.forEach((element) => {
                element.chains.forEach((chain) => {
                    if (chainName === chain.name) {
                        repositoryId = element.id;
                    }
                });
            });
            this._instances.generateReport(
                {
                    chainName,
                    instanceId,
                    service: currentService,
                    userName: user.id,
                    repositoryId,
                    noAlerts: noAlerts,
                },
                {
                    successCallback: async (res) => {
                        this._libUtils.alertSuccess('Report generation is in progress, please check the notifications');
                        if (!dontFetchReports) {
                            await this.generateReports(orgNamesMap, reports, instanceId, assetId, currentService, noAlerts);
                        }
                        resolve(reports);
                    },
                    failureCallback: async (res) => {
                        reject();
                    },
                }
            );
        });
    };

    generateAuditReports = async (
        orgNamesMap,
        reports: UIReport[],
        instanceId: string,
        assetId: string,
        currentApp: AssetService,
        noAlerts: boolean
    ) => {
        const promises = [];
        promises[0] = this.getGeneratedAuditReports(instanceId, assetId, currentApp, noAlerts);
        promises[1] = this.getInstanceAuditAnalytics(assetId, currentApp);
        Promise.all(promises).then(([{ orgIdVsNamesMap, reportsResponse }, { analyticsMetaData }]) => {
            orgNamesMap = orgIdVsNamesMap;
            const providerIds = this._libUtils.getProviderIds(undefined, []);
            if (reports === undefined) {
                reports = [];
            } else {
                reports.splice(0);
            }
            providerIds.forEach((id) => {
                (analyticsMetaData[id] || []).forEach((report) => {
                    this.generateReportsOrganizationObject(orgNamesMap, reports, id, report, instanceId);
                });
            });
            (reportsResponse || []).forEach((report) => {
                this.generateReportsOrganizationObject(
                    orgNamesMap,
                    reports,
                    report.transformationRepositoryId,
                    undefined,
                    instanceId,
                    report
                );
            });
        });
    };

    getGeneratedAuditReports = (instanceId, assetId, currentApp, noAlerts: boolean) => {
        return new Promise((resolve: (data: { reportsResponse: Report[]; orgIdVsNamesMap: { [property: string]: string } }) => void) => {
            const data = {
                assetId: assetId || currentApp.assetMetaUId,
                instanceId: instanceId,
            };

            this._instances.getGeneratedAuditReports(data, {
                successCallback: async (response, orgIdVsNamesMap) => {
                    const providerIds = this._libUtils.getProviderIds(undefined, []);
                    const orgIdVsNames = await this.getOrgNamesByIds(providerIds, noAlerts);
                    resolve({
                        reportsResponse: response,
                        orgIdVsNamesMap: orgIdVsNamesMap || orgIdVsNames,
                    });
                },
            });
        });
    };

    getInstanceAuditAnalytics = (assetId, currentApp) => {
        return new Promise((resolve) => {
            this._instances.getAuditReportMetadata(
                {
                    assetId: assetId || currentApp.assetMetaUId,
                },
                {
                    successCallback: async (res) => {
                        resolve({ analyticsMetaData: res || {} });
                    },
                    failureCallback: (res) => {},
                }
            );
        });
    };

    generateAuditReport = ({
        chainName,
        instanceId,
        orgNamesMap,
        reports,
        assetId,
        currentApp,
        noAlerts,
    }: {
        chainName: string;
        instanceId: string;
        orgNamesMap: { [property: string]: string };
        reports: UIReport[];
        assetId: string;
        currentApp: AssetService;
        noAlerts: boolean;
    }) => {
        this._instances.generateAuditReport(
            {
                assetId: assetId || currentApp.assetMetaUId,
                instanceId: instanceId,
                chainName: chainName,
            },
            {
                successCallback: (res) => {
                    this._libUtils.alertSuccess(res?.msg || 'Report generation is in progress, please check the notifications');
                    this.generateAuditReports(orgNamesMap, reports, instanceId, assetId, currentApp, noAlerts);
                },
            }
        );
    };
}

export interface UIReport {
    name: string;

    id: string;

    chains: {
        name: string;
        transformations: {
            name: string;
            reports: UIReportData[];
        }[];
    }[];
}

interface UIReportData {
    chainName: string;

    reportCategory: string;

    transformationName: string;

    reportsList: Report[];

    name: string;
}
