<ng-container *ngIf="search[configType]">
    <ng-container *ngTemplateOutlet="showQueryBuilderDirectly ? queryBuilder : searchTemplate"></ng-container>
</ng-container>

<ng-template #queryBuilder>
    <app-query-builder-new [disabled]="search.disabled || disabled" [query]="search[queryType]" [config]="search[configType]" [allowRuleset]="search.allowRuleset" [isAddRuleVisible]="search.isAddRuleVisible" [allowCollapse]="search.allowCollapse" [origin]="search.origin" [queryConfigChange]="search[changeObserver]" [queryType]="'SEARCH'" [relationConfigExists]="relationConfigExists" (relationChanged)="search.applyRelations()"></app-query-builder-new>
</ng-template>
<ng-template #searchTemplate>
    <div class="d-flex searchTabRow">
        <div class="sources col-3 pt-4" *ngIf="search.needSourceStructure">
            <perfect-scrollbar class="sourceSearchLevelSearch searchScroll">
                <ng-template *ngTemplateOutlet="radioGroupParent; context: { sources: search.sources }"></ng-template>
                <ng-template #radioGroupParent let-parentSources="sources">
                    <ng-container *ngFor="let source of parentSources; let i = index; trackBy: trackByIndexMethod">
                        <ng-container *ngIf="source.sources?.length; else noSubEntities">
                            <div class="withSubEntities source SubEntities">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="showSubSource mb-1">
                                        <ng-template [ngTemplateOutlet]="radioGroup" [ngTemplateOutletContext]="{source: source}"></ng-template>
                                    </div>
                                    <span class="material-icons cursorPointer {{source.id}} {{isSubSourceVisible[source.id] ?? false}}" [hidden]="isSubSourceVisible[source.id]" (click)="isSubSourceVisible[source.id] = !isSubSourceVisible[source.id]">chevron_right</span>
                                    <span class="material-icons cursorPointer" [hidden]="!isSubSourceVisible[source.id]" (click)="isSubSourceVisible[source.id] = !isSubSourceVisible[source.id]">expand_more</span>
                                </div>
                                <div class="subSource ml-4" [hidden]="!isSubSourceVisible[source.id]">
                                    <ng-template [ngTemplateOutlet]="radioGroupParent" [ngTemplateOutletContext]="{sources: source.sources}"></ng-template>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noSubEntities>
                            <div class="withOutSubEntities source mb-1">
                                <ng-template [ngTemplateOutlet]="radioGroup" [ngTemplateOutletContext]="{source: source}"></ng-template>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-template>
                <ng-template #radioGroup let-source="source">
                    <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="search.selectedSource" (change)="search.setSource(source?.id)">
                        <mat-radio-button class="mx-2" [value]="source" [title]="source.name" [checked]="search.selectedSource?.id === source.id">
                            {{source.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-template>
            </perfect-scrollbar>
        </div>
        <perfect-scrollbar class="sourceSearchLevelSearch searchScroll border-left col-9" [ngClass]="{'col-12': !search.needSourceStructure}">
            <ng-container *ngIf="search.queryBuilderShouldVisible;else queryBuilderShouldNotVisible">
                <div class="queryBuilderMainCntr serachCriteria">
                    <ng-container *ngIf="search.sourceClicked">
                        <div class="qbSection searchQbSection" *transloco="let translate;">
                            <h5 class="sectionTitle mt-3" [hidden]="!search.showSearchCriteriaTitle">{{translate('Search Criteria')}}: </h5>
                            <app-query-builder-new [disabled]="search.disabled || disabled" [query]="search[queryType]" [config]="search.searchConfig" [allowRuleset]="search.allowRuleset" [isAddRuleVisible]="search.isAddRuleVisible" [allowCollapse]="search.allowCollapse" [origin]="search.origin" [queryConfigChange]="search[changeObserver]" [queryType]="'SEARCH'"></app-query-builder-new>
                        </div>
                        <ng-container *ngIf="!search.isSortConfigEmpty && (search.origin === 'PROCESSES' || search.origin === 'PROCESS' || search.origin === 'ENCOLLAB')">
                            <div class="qbSection sortQbSection" *transloco="let translate;">
                                <h5 class="sectionTitle mt-3" [hidden]="!search.showSortCriteriaTitle">{{translate('Sort Criteria')}}: </h5>
                                <app-query-builder-new [disabled]="search.disabled || disabled" [query]="search.sortQuery" [config]="search.sortConfig" [allowRuleset]="search.allowRuleset" [isAddRuleVisible]="search.isAddRuleVisible" [allowCollapse]="search.allowCollapse" [origin]="search.origin" [queryConfigChange]="search[changeObserver]" [queryType]="'SORT'"></app-query-builder-new>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!search.sourceClicked">
                        <div class="qbSection searchQbSection" *transloco="let translate;">
                            <h5 class="sectionTitle mt-3" [hidden]="!search.showSearchCriteriaTitle">{{translate('Search Criteria')}}: </h5>
                            <app-query-builder-new [disabled]="search.disabled || disabled" [query]="search[queryType]" [config]="search.searchConfig" [allowRuleset]="search.allowRuleset" [isAddRuleVisible]="search.isAddRuleVisible" [allowCollapse]="search.allowCollapse" [origin]="search.origin" [queryConfigChange]="search[changeObserver]" [queryType]="'SEARCH'"></app-query-builder-new>
                        </div>
                        <ng-container *ngIf="!search.isSortConfigEmpty && (search.origin === 'PROCESSES' || search.origin === 'PROCESS' || search.origin === 'ENCOLLAB')">
                            <div class="qbSection sortQbSection" *transloco="let translate;">
                                <h5 class="sectionTitle mt-3" [hidden]="!search.showSortCriteriaTitle">{{translate('Sort Criteria')}}: </h5>
                                <app-query-builder-new [disabled]="search.disabled || disabled" [query]="search.sortQuery" [config]="search.sortConfig" [allowRuleset]="search.allowRuleset" [origin]="search.origin" [isAddRuleVisible]="search.isAddRuleVisible" [allowCollapse]="search.allowCollapse" [queryConfigChange]="search[changeObserver]" [queryType]="'SORT'"></app-query-builder-new>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #queryBuilderShouldNotVisible>
                <div class="d-flex justify-content-center align-items-center" style="height: inherit;" *transloco="let translate;">
                    {{translate('There are no searchable fields available for')}} {{search.selectedSource?.name}}
                </div>
            </ng-template>
        </perfect-scrollbar>
    </div>
</ng-template>
