import { Pipe, PipeTransform } from '@angular/core';
import { RecordField } from 'taxilla-library';

@Pipe({ name: 'FieldsGroup' })
export class FieldsGroupPipe implements PipeTransform {
    transform(fields: RecordField[], group: string): RecordField[] {
        let filteredFields = fields;
        filteredFields =
            fields?.filter((field) => {
                return field.fieldSection === group && field.show;
            }) || [];
        return filteredFields;
    }
}
