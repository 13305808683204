import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getCurrentOrganizationId$ } from '.';
import { SEARCH_REDUCER_KEY } from '../reducers';
import { SearchState } from '../states';

const currentState = createFeatureSelector<SearchState>(SEARCH_REDUCER_KEY);

export const getAppSearchOrganizationMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizations?.[organizationId]
);

const getAppSearchFiltersMap$ = (serviceId: string) => createSelector(getAppSearchOrganizationMap$, (orgMap) => orgMap?.[serviceId]);

export const getSearchDefaultFilters$ = (serviceId: string) =>
    createSelector(getAppSearchFiltersMap$(serviceId), (servicesMap) => servicesMap?.defaultFilters);

export const getSearchAppFilters$ = (serviceId: string) =>
    createSelector(getAppSearchFiltersMap$(serviceId), (servicesMap) => servicesMap?.searchFilters);

export const getSearchCriteriaMap$ = createSelector(currentState, (state) => state.searchCriteria);

export const getSearchCriteriaByServiceId$ = (serviceId: string) => createSelector(getSearchCriteriaMap$, (map) => map?.[serviceId]);
