<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 class="matDialogTitle" mat-dialog-title>{{data.title}}</h4>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<hr id="hrLine">
<mat-form-field appearance="outline" *transloco="let translate;">
    <mat-label>{{translate('Enter template name to save')}}</mat-label>
    <input required matInput placeholder="placeholder" [(ngModel)]="templateName">
</mat-form-field>
<span class="errorMsg"> {{nameError}}</span>

<mat-dialog-actions align="center" *transloco="let translate;">
    <button class='mx-2 noOutline' mat-raised-button mat-dialog-close>{{translate('Cancel')}}</button>
    <material-button class="noOutline submitColor" [label]="translate('Save Template')" (onTrigger)="saveTemplate()"></material-button>
</mat-dialog-actions>
