<div class="commonMastersContainer" *transloco="let translateMsg" appFileDragDetector>
    <mat-drawer-container class="drawerContainer" autosize>
        <mat-drawer #drawer class="masterMatDrawer" mode="side" disableClose position='end'>
            <ng-container *ngIf="!unassignMaster && !downloadMasterFlag && !uploadMasterFlag && !isUploadedFiles && !synchronizeFlag && !notificationFlag && !showRequests && editRecord?.hasFields">
                <div class="userMainTableConatiner">
                    <div class="settingsHeader masterDataSettings">
                        <span class="settingsNameText">{{translateMsg('Master Data for')}}: {{masterDataName}}</span>
                        <div class="settingsDataActionsContainer">
                            <button *ngIf="editRecord?.errors?.length > 0 || editRecord?.warnings?.length > 0" mat-icon-button class="actionButtons" #errorMessagesTrigger [matMenuTriggerFor]="messagesMenu" [title]="translateMsg('Errors and Warnings')">
                                <span class="material-icons matTableActionIcon material-icons-outlined hasError">info</span>
                            </button>
                            <mat-icon [title]="translateMsg('Close')" class="closeIcon" aria-hidden="true" (click)="toggleSliders()">cancel</mat-icon>
                        </div>
                    </div>
                    <mat-card class="masterCard ps position-relative" [perfectScrollbar]>
                        <div class="row" *ngIf="editRecord?.hasFields">
                            <div class="masterChildContainer col-12">
                                <div class="masterAcionField !overflow-auto" [ngClass]="isFromEnInvoice ? '!max-h-[calc(100vh-18rem)]' : '!max-h-[calc(100vh-16rem)]' ">
                                    <ng-container *ngIf="parentPrimaryKeyColumns?.length !== 0">
                                        <ng-container *ngFor="let parentKeyColumn of parentPrimaryKeyColumns">
                                            <ng-container *ngIf="!updateRecord && parentRecord && parentPrimaryKeyValues && parentKeyColumn?.id && parentPrimaryKeyValues[parentKeyColumn.id]">
                                                <material-select class="col-4 masterField" [options]="parentPrimaryKeyValues[parentKeyColumn.id]" [placeholder]='parentKeyColumn.displayName' [name]="parentKeyColumn.displayName" [required]="true" [model]="editRecord.fields[parentKeyColumn.id].value" (modelChange)="editRecord.fields[parentKeyColumn.id].value = $event; getDependentPrimaryValues(parentKeyColumn.id)" [errors]="editRecord.fields[parentKeyColumn.id].message.errors" [title]="parentKeyColumn.displayName" [warnings]="editRecord.fields[parentKeyColumn.id].message.warnings" disableOptionCentering></material-select>
                                            </ng-container>
                                            <ng-container *ngIf="updateRecord && parentKeyColumn?.id && editRecord?.fields[parentKeyColumn.id]">
                                                <material-input [autofocus]="false" class="col-4 masterField" [placeholder]="parentKeyColumn.displayName" [model]="editRecord.fields[parentKeyColumn.id].value" (modelChange)="editRecord.fields[parentKeyColumn.id].value = $event" [disabled]="updateRecord" [errors]="editRecord.fields[parentKeyColumn.id].message.errors" [warnings]="editRecord.fields[parentKeyColumn.id].message.warnings" [required]="true" [description]="editRecord.fields[parentKeyColumn.id]?.description"></material-input>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngFor="let fieldId of editRecord?.fields | ObjNgFor">
                                        <ng-container *ngIf="checkFieldType(fieldId) && hiddenFields.indexOf(fieldId) === (-1)">
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId !== undefined">
                                                <material-select [options]="$any(parentPrimaryKeyValues)" [placeholder]="editRecord.fields[fieldId].displayName" [model]="editRecord.fields[fieldId].value" [errors]="editRecord.fields[fieldId].message.errors" [warnings]="editRecord.fields[fieldId].message.warnings" (modelChange)="editRecord.fields[fieldId].value = $event" [description]="editRecord.fields[fieldId].description">
                                                </material-select>
                                            </ng-container>
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId === undefined && masterKeyMetaData[fieldId].dataType === 'STRING' && masterKeyMetaData[fieldId].uiTypeMetadata !== 'PASSWORD' && masterKeyMetaData[fieldId].uiTypeMetadata !== 'TEXTAREA'">
                                                <material-input [autofocus]="false" class="col-4 masterField" [placeholder]="editRecord.fields[fieldId].displayName" [model]="editRecord.fields[fieldId].value" (focusout)="onFocusOut(fieldId)" (modelChange)="editRecord.fields[fieldId].value = $event" [disabled]="editRecord.fields[fieldId].primaryKey && updateRecord" [required]="editRecord.fields[fieldId].mandatory" [errors]="editRecord.fields[fieldId].message.errors" [warnings]="editRecord.fields[fieldId].message.warnings" [description]="editRecord.fields[fieldId].description"></material-input>
                                            </ng-container>
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId === undefined && masterKeyMetaData[fieldId].dataType === 'STRING' && masterKeyMetaData[fieldId].uiTypeMetadata === 'TEXTAREA'">
                                                <material-textarea class="col-4 masterField" [placeholder]="editRecord.fields[fieldId].displayName" [model]="editRecord.fields[fieldId].value" (modelChange)="editRecord.fields[fieldId].value = $event" [required]="editRecord.fields[fieldId].mandatory" [errors]="editRecord.fields[fieldId].message.errors" [warnings]="editRecord.fields[fieldId].message.warnings" [description]="editRecord.fields[fieldId].description"></material-textarea>
                                            </ng-container>
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId === undefined && masterKeyMetaData[fieldId].dataType === 'STRING' && masterKeyMetaData[fieldId].uiTypeMetadata === 'PASSWORD'">
                                                <material-input-password class="col-4 masterField" [autofocus]="false" [name]="editRecord.fields[fieldId].displayName" [placeholder]="editRecord.fields[fieldId].displayName" [model]="editRecord.fields[fieldId].value" [debounceAfter]="1000" (modelChange)="editRecord.fields[fieldId].value = $event" [required]="editRecord.fields[fieldId].mandatory" [errors]="editRecord.fields[fieldId].message.errors" [warnings]="editRecord.fields[fieldId].message.warnings" [description]="editRecord.fields[fieldId].description">
                                                </material-input-password>
                                            </ng-container>
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId === undefined && masterKeyMetaData[fieldId].dataType === 'BOOLEAN'">
                                                <div class="col-4 radioContainer">
                                                    <div class="col-6 radioItem">
                                                        {{editRecord.fields[fieldId].displayName}}
                                                        <button mat-button matSuffix mat-icon-button aria-label="Description" tabindex="-1" title="{{editRecord.fields[fieldId].description}}" *ngIf="editRecord.fields[fieldId].description">
                                                            <span class="material-icons-outlined fieldDescInfoIcon">
                                                                info
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <span *ngIf="editRecord.fields[fieldId].mandatory && (!editRecord.fields[fieldId].message.errors || editRecord.fields[fieldId].message.errors.length === 0)" class="mat-form-field-subscript-wrapper requiredCls">*{{translateMsg('Required')}}</span>
                                                    <div class="col-6 radioItem">
                                                        <mat-radio-group class="radioGroup" [(ngModel)]="editRecord.fields[fieldId].value" [name]="fieldId" [disabled]="editRecord.fields[fieldId].primaryKey && updateRecord">
                                                            <mat-radio-button class="radioButton" [value]="true">
                                                                {{translateMsg('Yes')}}
                                                            </mat-radio-button>
                                                            <mat-radio-button class="radioButton" [value]="false">
                                                                {{translateMsg('No')}}
                                                            </mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    <mat-error class="mat-form-field-subscript-wrapper requiredCls" *ngIf="editRecord.fields[fieldId].message.errors.length > 0">
                                                        {{editRecord.fields[fieldId].message.errors[0]}}
                                                    </mat-error>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId === undefined && (masterKeyMetaData[fieldId].dataType === 'DOUBLE' || masterKeyMetaData[fieldId].dataType === 'FLOAT' || masterKeyMetaData[fieldId].dataType === 'INT' || masterKeyMetaData[fieldId].dataType === 'LONG')">
                                                <material-input [autofocus]="false" class="col-4 masterField" [placeholder]="editRecord.fields[fieldId].displayName" [model]="editRecord.fields[fieldId].value" (modelChange)="editRecord.fields[fieldId].value = $event" [disabled]="editRecord.fields[fieldId].primaryKey && updateRecord" [required]="editRecord.fields[fieldId].mandatory" [errors]="editRecord.fields[fieldId].message.errors" [warnings]="editRecord.fields[fieldId].message.warnings" [description]="editRecord.fields[fieldId].description"></material-input>
                                            </ng-container>
                                            <ng-container *ngIf="masterKeyMetaData[fieldId] !== undefined && masterKeyMetaData[fieldId].parentTableId === undefined && masterKeyMetaData[fieldId].dataType === 'DATE'">
                                                <material-input-date class="col-4 masterField" [disabled]="editRecord.fields[fieldId].primaryKey && updateRecord" [placeholder]="editRecord.fields[fieldId].displayName" [model]="editRecord.fields[fieldId].value" [errors]="editRecord.fields[fieldId].message.errors" [warnings]="editRecord.fields[fieldId].message.warnings" [required]="editRecord.fields[fieldId].mandatory" [dateFormat]="editRecord.fields[fieldId].dateFormat" (modelChange)="editRecord.fields[fieldId].value = $event" [description]="editRecord.fields[fieldId].description">
                                                </material-input-date>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="row col-12 masterFooter mt-2">
                                    <material-button class="cancel" [label]="translateMsg('Cancel')" (click)="toggleSliders()">
                                    </material-button>
                                    <material-button [label]="updateRecord ? translateMsg('Update'): translateMsg('Save')" (onTrigger)="submitMaster()">
                                    </material-button>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </ng-container>
            <ng-container *ngIf="unassignMaster && !downloadMasterFlag && !uploadMasterFlag && !isUploadedFiles && !notificationFlag && !showRequests">
                <mat-card class="masterCard ps position-relative" style="height: calc(100vh - 100px);" [perfectScrollbar]>
                    <mat-card-content>
                        <div class="masterAcionField" appRemoveAriaOwns>
                            <ng-container *ngIf="updateRecord">
                                <mat-selection-list #checklist [(ngModel)]="checkedList" (ngModelChange)="checkedChanged($event)">
                                    <mat-list-option class="parentOption" checkboxPosition="before" [value]="organization.id" [selected]="(units_associated.indexOf(organization.id) !== (-1)) ? true : false">
                                        {{organization.name}}
                                    </mat-list-option>
                                    <mat-list-option class="childOption" checkboxPosition="before" *ngFor="let location of locationIdList" [value]="location.id" [selected]="(units_associated.indexOf(location.id) !== (-1)) ? true : false">
                                        {{location.name}}
                                    </mat-list-option>
                                </mat-selection-list>
                            </ng-container>
                        </div>
                        <div class="row col-12 masterFooter">
                            <material-button class="cancel" [label]="translateMsg('Cancel')" (onTrigger)="toggleSliders()">
                            </material-button>
                            <material-button *ngIf="!updateRecord" [label]="translateMsg('Update')"></material-button>
                            <material-button *ngIf="updateRecord" [label]="translateMsg('Update')" (onTrigger)="updateMasterData(checkedList)"></material-button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="downloadMasterFlag && !uploadMasterFlag && !isUploadedFiles && !notificationFlag && !showRequests">
                <div class="userMainTableConatiner">
                    <div class="settingsHeader masterDataSettings">
                        <span class="settingsNameText">{{translateMsg('Download Master Data for')}}: {{masterDataName}}</span>
                        <div class="settingsDataActionsContainer">
                            <mat-icon [title]="translateMsg('Close')" class="closeIcon" (click)="toggleSliders()">cancel</mat-icon>
                        </div>
                    </div>
                    <mat-card class="masterCard ps position-relative" style="height: calc(100vh - 100px);" [perfectScrollbar]>
                        <mat-card-content>
                            <div class="masterAcionField">
                                <div class="col-12" *ngIf="_utils.isEmpty(outboundMappings)">
                                    <p><label>{{translateMsg('No Master Data')}}</label></p>
                                </div>
                                <div class="col-12" *ngIf="!_utils.isEmpty(outboundMappings)">
                                    <div class="col-4 outbound">
                                        <mat-form-field appRemoveAriaOwns>
                                            <mat-select [placeholder]="translateMsg('Select')" disableOptionCentering [id]="'selectDownload'" [(ngModel)]="masterDataOutboundMap" (ngModelChange)="selectedOutboundTransformation($event)" name="masterDownload" [disabled]="false">
                                                <ng-container *ngFor="let mapping of outboundMappings | ObjNgFor">
                                                    <mat-option [value]="mapping">
                                                        {{mapping}}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6 outbound" *ngIf="outboundTransformationSourceFile;else noTransformationSelected">
                                        <ng-container *ngIf="!showRefreshIcon">
                                            <button [title]="translateMsg('Generate Report')" class="downloadIcon outbound" (click)="generateReport(masterDataOutboundMap)" mat-icon-button>
                                                <mat-icon>move_to_inbox</mat-icon>
                                            </button>
                                        </ng-container>
                                        <ng-container *ngIf="showRefreshIcon">
                                            <button [title]="translateMsg('Refresh')" class="downloadIcon outbound" (click)="getReportDetails(true,true)" mat-icon-button>
                                                <mat-icon>refresh</mat-icon>
                                            </button>
                                        </ng-container>
                                        <label class="outbound" *ngIf="!_utils.isEmpty(masterDownloads) && downloadMasterReports && masterDownloads[masterDataOutboundMap]">
                                            <span class="downloadFile" (click)="downloadOutboundFile(masterDataOutboundMap)" title="{{downloadReportName}}">
                                                {{downloadReportName}}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
            <ng-container *ngIf="uploadMasterFlag && !isUploadedFiles && !notificationFlag && !showRequests">
                <div class="userMainTableConatiner">
                    <div class="settingsHeader masterDataSettings">
                        <span class="settingsNameText">{{translateMsg('Upload Master Data for')}}: <span class="masterNameSpan">{{masterDataName}}</span></span>
                        <div class="settingsDataActionsContainer">
                            <mat-icon [title]="translateMsg('Close')" class="closeIcon" aria-hidden="true" (click)="toggleSliders()">cancel</mat-icon>
                        </div>
                    </div>
                    <mat-card class="masterCard ps position-relative" style="height: calc(100vh - 155px);" [perfectScrollbar]>
                        <mat-card-content>
                            <div class="masterAcionField">
                                <div class="col-12" *ngIf="!_utils.isEmpty(mappings)">
                                    <div class="col-4 outbound">
                                        <material-select [options]="transformedMappings" [placeholder]="translateMsg('Select Transformation')" [name]="'selecteUpload'" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" [required]="false" [model]="masterDataMap" (modelChange)="selectedTransformation($event)"></material-select>
                                        <div *ngIf="!mapingSelected" class="mapingSelected"> {{translateMsg('*Please select transformation')}}</div>

                                    </div>
                                </div>
                                <div class="col-12" *ngIf="transformationSourceFile">
                                    <table aria-describedby="commonmasters table" class="table uploadTable">
                                        <thead>
                                            <tr>
                                                <th scope="col">{{translateMsg('Source Name')}}</th>
                                                <th scope="col" colspan="2">{{translateMsg('Supported Format')}}</th>
                                                <th scope="col">{{translateMsg('Download Source Templates')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let source of mappings[masterDataMap]['sourceParts']">
                                                <td class="uploadTd">{{source.name}}</td>
                                                <td class="uploadTd">
                                                    <label class="uploadFormat">{{source.sourceDataFormat}}</label>
                                                </td>
                                                <td class="uploadTd d-flex align-items-center">
                                                    <span class="tableFilesUploadContainer mr-3" title="{{fileName}}">
                                                        <fileUpload [data]="mastersUploadData" [clearFiles]="clearFiles" (newValue)="files = $event; uploadMaster()" (fileName)="fileName = $event">
                                                        </fileUpload>
                                                    </span>
                                                    <span class="tableFilesName" [title]="fileName || '--'">{{fileName}}</span>
                                                </td>
                                                <td class="uploadTd">
                                                    <span class="downloadFile" (click)="getSourceFile(masterDataMap,source);">{{translateMsg('Download')}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row col-12 masterFooter">
                                <material-button class="cancel" [label]="translateMsg('Cancel')" (onTrigger)="toggleSliders()">
                                </material-button>
                                <material-button [label]="translateMsg('Upload')" [disabled]="!transformationSourceFile" (onTrigger)="uploadMasterData(masterDataMap)"></material-button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
            <ng-container *ngIf="isUploadedFiles">
                <div class="userMainTableConatiner">
                    <div class="settingsHeader masterDataSettings">
                        <span class="settingsNameText"> {{translateMsg('User Uploaded Files for')}}: {{masterDataName}}</span>
                        <div class="settingsDataActionsContainer">
                            <mat-icon [title]="translateMsg('Close')" class="closeIcon" aria-hidden="true" (click)="toggleSliders()">cancel</mat-icon>
                        </div>
                    </div>
                    <mat-card class="masterCard ps position-relative" style="height: calc(100vh - 120px);" [perfectScrollbar]>
                        <mat-card-content>
                            <div class="masterAcionField">
                                <div class="col-12" *ngIf="!hasChecksum">
                                    <p><label>{{translateMsg('No Files available')}}</label></p>
                                </div>
                                <div class="col-12 tablePosition" *ngIf="hasChecksum">
                                    <material-table [allTableColumns]="allColumns" [selectedColumns]="selectedFileUploadColumns" [data]="fileDetailstableData" (downloadFile)="onDownloadToggle($event)" [defaultPaginator]="true">
                                    </material-table>
                                </div>
                            </div>
                            <div class="row col-12 masterFooter">
                                <material-button class="cancel" [label]="translateMsg('Cancel')" (onTrigger)="toggleSliders()">
                                </material-button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
            <ng-container *ngIf="synchronizeFlag">
                <mat-card class="masterCard synchronizeMasterCard ps position-relative">
                    <div id="inboundIntegrationsPageContainer">
                        <div class="inboundIntegrationsContainer">
                            <ng-container *ngIf="record && transformationMappings">
                                <app-integrations-template [subTenants]="subTenants" [masterTables]="allMasters" [title]="translateMsg('Synchronize Master for')+': ' + masterDataName" [record]="record" [config]="config" [app]="app" [subApp]="subApp" [master]="selectedMasterTable" [masterTableColumns]="masterTableColumns" [sources]="sources" [bridge]="bridge" [openWidget]="true" [drawer]="drawer" [transformationMappings]="transformationMappings" (gotoIntegrationsPage)="toggleSliders($event)"> </app-integrations-template>
                                <div class="inboundIntegrationsFooterContainer">
                                    <material-button [label]="translateMsg('Cancel')" [color]="'cancelBtn'" (onTrigger)="toggleSliders()"></material-button>
                                    <material-button [label]="translateMsg('Delete')" [hidden]="!record || !record.originalData || !record.originalData.locationId" [color]="'cancelBtn'" (onTrigger)="deleteConfiguration($event)">
                                    </material-button>
                                    <ng-container *ngIf="canShowImmediateTrigger()">
                                        <material-button [label]="translateMsg('Sync Now')" color="primary" (onTrigger)="initiateTrigger()">
                                        </material-button>
                                    </ng-container>
                                    <material-button [label]="record && record.originalData.serviceId && record.originalData.serviceId.length > 0 ? translateMsg('Update'): translateMsg('Save')" color="primary" (onTrigger)="submitConfiguration()"></material-button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="notificationFlag">
                <div class="notifyContainer">
                    <div class="notifyMainContainer userMainTableConatiner">
                        <div class="settingsHeader">
                            <span class="settingsNameText"> {{translateMsg('Notifications')}} </span>
                        </div>
                        <div class="closeNotification cursorPointer">
                            <mat-icon [title]="translateMsg('Close')" class="closeIcon" aria-hidden="true" (click)="toggleSliders()">cancel</mat-icon>
                        </div>
                        <div class="notifyContentContainer">
                            <app-common-notifications-eventlogs [resourceId]="selectedMasterTable?.['itemId']" [sourcePage]="'masters'" [notificationsState]="notificationsState"></app-common-notifications-eventlogs>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="showRequests">
                <div class="userMainTableConatiner masterRequestsTable">
                    <div class="settingsHeader masterDataSettings">
                        <span class="settingsNameText"> {{translateMsg('Requests')}} : {{masterDataName}}</span>
                        <div class="settingsDataActionsContainer">
                            <mat-icon [title]="translateMsg('Refresh')" class="closeIcon refreshIcon" (click)="clearAllFilters()">refresh</mat-icon>
                            <mat-icon [title]="translateMsg('Close')" class="closeIcon" aria-hidden="true" (click)="toggleSliders('requests')">cancel</mat-icon>
                        </div>
                        <div class="floatRight masterDateSearchDiv">
                            <div class="searchModeSuffix cursorPointer masterDateFilter displyInlineBlock" *ngIf="searchMode">
                                <span>{{translateMsg('In Search Mode')}}</span>
                            </div>
                            <div class="clearSearchSuffix cursorPointer masterDateFilter displyInlineBlock" [title]="translateMsg('Clear')" (click)="clearAllFilters()" *ngIf="searchMode">
                                <span>{{translateMsg('Clear')}}</span>
                            </div>
                            <span class="masterDateFilter">
                                <app-date-filter [isModal]="isFromEnInvoice" [timeFilter]="timeFilter" (onSearchByTimeRange)="searchByTimeRange($event)" (onClearSearch)="clearAllFilters()"></app-date-filter>
                            </span>
                        </div>
                    </div>
                    <mat-card class="masterCard ps position-relative">
                        <mat-card-content *ngIf=" requestsPagination?.count > 0; else noRequests">
                            <div class="masterAcionField">
                                <div class="col-12 tablePosition">
                                    <material-table [allTableColumns]="allMasterRequestColumns" [selectedColumns]="selectedMasterRequestColumns" [data]="requeststableData" [noRecordMsg]="'No Inbound Transmissions Available'" (downloadFile)="downloadFile($event)" [recordActions]='masterRequestrecordActions' [defaultPaginator]="true" [pageIndexSelected]="requestsPagination.pageIndex + 1" [customPageIndex]="requestsPagination.pages" [newCustomPaginator]="true" [hidePaginator]="true" [defaultPaginator]="false" (fetchItemsInPage)="fetchMasterRequestsInPage($event)" (refreshItemsWithNewPageSize)="refreshMasteritemsWithNewPageSize($event)" [sizeSelected]="requestsPagination.size" [disablePrevious]="requestsPagination.pageIndex === 0" [disableNext]="requestsPagination.disableNext" (fetchItemsInPageWithPageIndex)="fetchMasterRequestsInPage($event - 1)">
                                    </material-table>
                                </div>
                            </div>
                            <div class="row col-12 masterFooter">
                                <material-button class="cancel" [label]="translateMsg('Cancel')" (onTrigger)="toggleSliders('requests')">
                                </material-button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
        </mat-drawer>
        <mat-drawer-content>
            <ng-container>
                <div class="userMainTableConatiner" id="masterTable">
                    <div class="settingsHeader masterSettingHeader masterDataSettings">
                        <div class="selectedMasterNameCls">
                            <ng-container *ngIf="selectedMasterTable?.itemName;else noMasterSelected">
                                <span class="settingsNameText"> {{translateMsg('Master Data Column Definitions')}} : {{(selectedMasterTable?.displayName || selectedMasterTable?.itemName)}}</span>
                            </ng-container>
                        </div>
                        <div class="settingsDataActionsContainer masterSettingDataActionCls" *ngIf="isFromEnComply || instanceId || isFromEnInvoice">
                            <mat-icon [title]="isFromEnComply ? translateMsg('Back to master list') : translateMsg('Close')" class="closeIcon" aria-hidden="true" (click)="clearMastersPage()">cancel</mat-icon>
                        </div>
                        <div class="allMastersDropdownContainer">
                            <button mat-button class="allMastersMatMenuButton" [title]="translateMsg('Change Master')" [matMenuTriggerFor]="allMastersMenu" #matMasterMenuTrigger="matMenuTrigger">
                                <span>{{translateMsg('Change Master')}}</span>
                                <span *ngIf="!matMasterMenuTrigger.menuOpen" class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
                                <span *ngIf="matMasterMenuTrigger.menuOpen" class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
                            </button>
                            <mat-menu #allMastersMenu="matMenu" xPosition="after" [overlapTrigger]="false">
                                <div class="allMastersList" stop-propagation>
                                    <div class="mastersSearch px-3">
                                        <mat-form-field>
                                            <input matInput [placeholder]="translateMsg('Search Master')" type="text" [(ngModel)]="masterSearchText" #masterSearchInput (blur)="focusSearchField()" (keyup)='searchRelatedMasters()'>
                                            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearMasterMenuSearch()'>
                                                <mat-icon class='locationManagementIcons' *ngIf='masterSearchText'>close </mat-icon>
                                                <mat-icon *ngIf='!masterSearchText' class="locationManagementIcons">search </mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                    <div *ngFor="let master of allMasters | FilterPipe: 'displayName' : masterSearchText; trackBy:docListTrakBy" mat-menu-item [class.activeMasterItem]="selectedMasterTable?.id === master.id">
                                        <div class="masterDisplayName" (click)="onMasterSelect(master.itemName)" title="{{master.displayName}}">{{master.displayName}}</div>
                                    </div>
                                    <ng-container *ngIf='masterSearchText && noRelatedMasters'>
                                        <div class="d-flex justify-content-center noRecords">
                                            <span>{{translateMsg('No masters found')}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <mat-card class="masterCard ps position-relative">
                    <div class="masterChildContainer col-12">
                        <div class="col-12 childMasterRow">
                            <div *ngIf="selectedMasterTable?.itemName && (location === '' || location === undefined)" class="childAction">
                                <button [title]="translateMsg('View Requests')" class="actionButton masterRequestIconButton" (click)="actionMaster('requests')" mat-icon-button>
                                    <img src="/assets/images/inbound-icon-white.png" alt="{{translateMsg('Inbound Transmissions')}}">
                                </button>
                                <button [title]="translateMsg('Notification')" class="actionButton" (click)="actionMaster('notification')" mat-icon-button>
                                    <mat-icon>notifications</mat-icon>
                                </button>
                                <button *ngIf="(searchQuery && searchQuery.rules && searchQuery.rules.length) || (sortQuery && sortQuery.rules && sortQuery.rules.length) || (defaultFilter && defaultFilter.criteria) || validationFilter" [title]="translateMsg('Clear Search/Filter')" class="actionButton clearSearch" (click)="actionMaster('clearsearch')" mat-icon-button>
                                    <mat-icon>close</mat-icon>
                                </button>
                                <button [title]="translateMsg('Search')" class="actionButton" (click)="actionMaster('search')" mat-icon-button>
                                    <mat-icon>search</mat-icon>
                                </button>
                                <button [title]="translateMsg('Index Data')" class="actionButton" (click)="actionMaster('index')" mat-icon-button>
                                    <mat-icon>view_list</mat-icon>
                                </button>
                                <button *ngIf="allPermissions.indexOf(actions_list.update_record) !== -1" class="actionButton" (click)="actionMaster('synchronize')" mat-icon-button>
                                    <mat-icon [title]="translateMsg('Synchronize')">settings_input_antenna</mat-icon>
                                </button>
                                <button *ngIf="allPermissions.indexOf(actions_list.update_record) !== -1" [title]="translateMsg('Uploaded Files')" class="actionButton" (click)="actionMaster('uploadedfiles')" mat-icon-button>
                                    <mat-icon>assignment_turned_in</mat-icon>
                                </button>
                                <button *ngIf="allPermissions.indexOf(actions_list.update_record) !== -1" [title]="translateMsg('Download')" [matMenuTriggerFor]="masterDownloadMenu" class="actionButton" (click)="actionMaster('download')" mat-icon-button>
                                    <mat-icon>work</mat-icon>
                                </button>
                                <mat-menu class="mastersReportsDownloadMenu" #masterDownloadMenu="matMenu" xPosition="before">
                                    <button class="reportsBtn" mat-menu-item *ngFor="let mapping of outboundMappings | ObjNgFor">
                                        <span class="d-flex justify-content-between">
                                            <span class="mr-3 text-truncate mastersReportName" title="{{(chainNamesVsDiplayNames && chainNamesVsDiplayNames[mapping]) || mapping}}">
                                                {{(chainNamesVsDiplayNames && chainNamesVsDiplayNames[mapping]) || mapping}}
                                            </span>
                                            <span class="d-flex justify-content-between align-items-center">
                                                <ng-container *ngIf="masterDownloads[outboundMappings[mapping]?.name]; else noMaster">
                                                    <span class="material-icons mr-3" (click)="downloadOutboundFile(outboundMappings[mapping]?.chainName)" stop-propagation>cloud_download</span>
                                                </ng-container>
                                                <ng-template #noMaster>
                                                    --
                                                </ng-template>
                                                <span class="material-icons" (click)="generateReport(mapping)" stop-propagation>refresh</span>
                                            </span>
                                        </span>
                                    </button>
                                </mat-menu>
                                <button *ngIf="allPermissions.indexOf(actions_list.update_record) !== -1" [title]="translateMsg('Upload')" class="actionButton" (click)="actionMaster('upload')" mat-icon-button>
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                                <button [title]="translateMsg('Delete')" class="actionButton" (click)="actionMaster('delete')" mat-icon-button [disabled]="allPermissions?.indexOf(actions_list.delete_record) === (-1) || selectedRecordsCount === 0">
                                    <mat-icon [ngClass]="{'disable':(allPermissions?.indexOf(actions_list.delete_record) === (-1) || selectedRecordsCount === 0)}">delete</mat-icon>
                                </button>
                                <button [title]="translateMsg('Edit')" class="actionButton" (click)="actionMaster('edit')" mat-icon-button [disabled]="allPermissions?.indexOf(actions_list.update_record) === (-1) || selectedRecordsCount !== 1">
                                    <mat-icon [ngClass]="{'disable':(allPermissions?.indexOf(actions_list.update_record) === (-1) || selectedRecordsCount !== 1)}">edit</mat-icon>
                                </button>
                                <button *ngIf="allPermissions.indexOf(actions_list.add_record) !== -1" [title]="translateMsg('Add')" class="actionButton" (click)="actionMaster('add')" mat-icon-button [disabled]="list.length !== 0">
                                    <mat-icon [ngClass]="{'disable':list.length !== 0}">add_circle</mat-icon>
                                </button>
                                <button [title]="translateMsg('Refresh')" class="actionButton" (click)="actionMaster('refresh')" mat-icon-button>
                                    <mat-icon class="refreshIconClass">refresh</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="row col-12 childMasterRow">
                            <div class="mastersContainerRow" *ngIf="allMasters?.length > 0; else noMastersBlock">
                                <div class="masterData">
                                    <ng-container *ngIf="_utils.isEmpty(selectedMasterTable) else noRecordLength">
                                        <div class="noMasterRecordContainer mt-[15%]">
                                            <div class="noMasterRecordDiv d-flex justify-content-center align-items-center">
                                                <span>{{translateMsg('Select any master from Change Master menu to view data')}}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-9 masterData">
                                    <ng-template #noRecordLength>
                                        <div *ngIf="records.length !== 0 || pagination.count !== 0; else noRecordBlock" class="masterTable">
                                            <ng-container *ngTemplateOutlet="(openDrawer) ? noRecordBlock : tableTemplate; context: { record: editRecord }">
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </ng-container>
        </mat-drawer-content>
    </mat-drawer-container>
</div>

<ng-template #noRecordBlock>
    <div class="centerDiv">
        <div class="noRecords" *ngIf="!_utils.isEmpty(selectedMasterTable) && records?.length === 0 && !editRecord?.hasFields && !showRequests">
            <span class="material-icons">{{noRecordsIcon}}</span>
            <div>{{noRecordsText}}</div>
        </div>
    </div>
</ng-template>

<ng-template #tableTemplate>
    <material-table [allTableColumns]="allTableColumns" [selectedColumns]='selectedColumns' [data]="tableData" [recordActions]="tableRecordActions" [pageIndexSelected]="pagination.pageIndex + 1" [customPageIndex]="pagination.pages" [newCustomPaginator]="true" [hidePaginator]="true" [defaultPaginator]="false" [passwordColumns]="passwordColumns" [sizeSelected]="pagination.size" [disablePrevious]="pagination.pageIndex === 0" [disableNext]="pagination.disableNext" (fetchItemsInPage)="fetchItemsInPage($event)" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize($event)" (fetchItemsInPageWithPageIndex)="fetchItemsInPage($event - 1)" (recordSelectedEvent)="recordSelectedEvent($event)" (onDoubleClickEvent)="onRecordSelect($event)">
    </material-table>
</ng-template>

<ng-template #masterConfigField let-record="configRecord" let-field="field" let-hide="hide">
    <app-integrations-field-template [subTenants]="subTenants" [masterTables]="allMasters" [record]="$any(configRecord)" [field]="field" [hide]="hide" [configuredRecords]="configuredRecords" (apiConfigChange)="apiConfigChange($event)">
    </app-integrations-field-template>
</ng-template>

<ng-template #masterSubSections let-record="configRecord" let-subSection="subSection">
    <ng-container *ngIf="!hideSubSection(subSection, configRecord)">
        <ng-container [ngSwitch]="subSection.structure">
            <ng-container *ngSwitchCase="'FLEX_FORM'">
                <ng-container *ngTemplateOutlet="flexFormStructure; context: { configRecord: configRecord, subSection: subSection }">
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #flexFormStructure let-record="configRecord" let-subSection="subSection">
    <div class="flexFormContainer">
        <ng-container *ngIf="subSection.fields && subSection.fields.length > 0">
            <ng-container *ngFor="let field of subSection.fields; let i = index">
                <ng-container *ngIf="!hideField(field, configRecord)">
                    <div class="inboundIntegrationFieldContainer">
                        <ng-container *ngTemplateOutlet="integrationField; context: { configRecord: configRecord, field: field }">
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #integrationField let-record="configRecord" let-field="field" let-hide="hide">
    <app-integrations-field-template [subTenants]="subTenants" [masterTables]="allMasters" [record]="$any(configRecord)" [field]="field" [hide]="hide" [configuredRecords]="configuredRecords" (apiConfigChange)="apiConfigChange($event)">
    </app-integrations-field-template>
</ng-template>

<mat-menu #messagesMenu="matMenu" xPosition="before" class="messagesMenuMainContainer">
    <div class="messagesMainContainer" *ngIf="editRecord?.errors" [ngClass]="{'errorMenuMessages': editRecord.errors.length}">
        <span #messagesContainer>
            <ng-container *ngIf="editRecord?.errors?.length > 0">
                <div class="mat-menu-head errors" stop-propagation>
                    {{translateMsg('Errors')}}
                </div>
                <div class="mat-menu-body messagesContainer" stop-propagation [class.hasWarnings]="editRecord.warnings.length > 0">
                    <ng-container *ngFor="let alert of editRecord?.errors; let i = index">
                        <div class="messageContainer">
                            <span class="messageIndex">
                                {{i + 1}}.
                            </span>
                            <span class="messageBody word-break">
                                {{alert}}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="editRecord?.warnings?.length > 0">
                <div class="mat-menu-head warnings" stop-propagation>
                    {{translateMsg('Warnings')}}
                </div>
                <div class="mat-menu-body messagesContainer" stop-propagation [class.hasWarnings]="editRecord.warnings.length > 0">
                    <ng-container *ngFor="let alert of editRecord.warnings; let i = index; trackBy: trackByIndexMethod">
                        <div class="messageContainer">
                            <span class="messageIndex">
                                {{i + 1}}.
                            </span>
                            <span class="messageBody word-break">
                                {{alert}}
                            </span>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </span>
    </div>
</mat-menu>

<ng-template #noMasterSelected>
    <span class="settingsNameText">{{translateMsg('Masters')}}</span>
</ng-template>

<ng-template #noMasterRecords>
    <div class="noMasterRecordContainer">
        <div class="noMasterRecordDiv d-flex justify-content-center align-items-center">
            <span>{{translateMsg('No records found')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #noMastersBlock>
    <div class="noMasterRecordContainer mt-[15%]">
        <div class="noMasterRecordDiv d-flex justify-content-center align-items-center">
            <span>{{translateMsg('No masters found')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #noRequests>
    <div class="noMasterRecordContainer mt-[15%]">
        <div class="noMasterRecordDiv d-flex justify-content-center align-items-center">
            <span class="text-lg font-bold">{{translateMsg('No requests found')}}</span>
        </div>
    </div>
</ng-template>

<ng-template #noTransformationSelected>
    <div class="noMasterRecordContainer">
        <div class="noMasterRecordDiv d-flex justify-content-center align-items-center">
            <span>{{translateMsg('Please select transformation to download reports.')}}</span>
        </div>
    </div>
</ng-template>
