import { createAction, props } from '@ngrx/store';
import { Organization, User } from 'taxilla-library';

import type { ClientDetails } from '../../interface/client-details.interface';
export enum AuthActionTypes {
    AUTH_GET_CLIENT_DETAILS = '[AUTH_GET_CLIENT_DETAILS] Get Client Details',
    AUTH_CLIENT_DETAILS_SUCCESS = '[AUTH_GET_CLIENT_SUCCESS] Get Client Details Success',
    AUTH_CLIENT_DETAILS_FAILED = '[AUTH_GET_CLIENT_FAILED] Get Client Details Failed',
    INTERNAL_AUTH_TOKEN_VALIDATE = '[INTERNAL_AUTH_TOKEN_VALIDATE] Validate Auth Token',
    INTERNAL_AUTH_TOKEN_VALIDATE_SUCCESS = '[INTERNAL_AUTH_TOKEN_VALIDATE_SUCCESS] Validate Auth Token Success',
    INTERNAL_AUTH_TOKEN_VALIDATE_FAILED = '[INTERNAL_AUTH_TOKEN_VALIDATE_FAILED] Validate Auth Token Failed',
    INTERNAL_ACCESS_TOKEN_AUTHENTICATE = '[INTERNAL_ACCESS_TOKEN_AUTHENTICATE] Authenticate Access Token',
    INTERNAL_ACCESS_TOKEN_AUTHENTICATE_SUCCESS = '[INTERNAL_ACCESS_TOKEN_AUTHENTICATE_SUCCESS] Authenticate Access Token Success',
    INTERNAL_ACCESS_TOKEN_AUTHENTICATE_FAILED = '[INTERNAL_ACCESS_TOKEN_AUTHENTICATE_FAILED] Authenticate Access Token Failed',
    AUTH_VALIDATE_EXTERNALLY = '[AUTH_VALIDATE_EXTERNALLY] Validate Auth Code Externally',
    AUTH_VALIDATION_EXTERNALLY = '[AUTH_VALIDATION_EXTERNALLY] Auth Code External Validation',
    AUTH_VALIDATION_SUCCESS = '[AUTH_VALIDATE_SUCCESS] Validate Auth Code Success',
    AUTH_VALIDATION_FAILED = '[AUTH_VALIDATE_FAILURE] Validate Auth Code Failed',
    CLEAR_AUTH_STATE = '[CLEAR_AUTH_STATE] Clear Auth State',
    AUTH_LDAP_SUBMIT = '[AUTH] Submit LDAP client details',
    AUTH_LDAP_LOGIN_SUCCESS = '[AUTH] LDAP login success',
    AUTH_LDAP_LOGIN_FAILED = '[AUTH] LDAP login failed',
    RESET_PASSWORD = '[RESET] Reset Password',
    RESET_PASSWORD_SUCCESS = '[RESET] Reset Password Successfull',
    RESET_PASSWORD_FAILED = '[RESET] Reset Password Failure',
    SET_PASSWORD = '[SET_PASSWORD] Set Password',
    PASSWORD_UPDATED = '[SET] Password updated',
    FETCH_CAPTCHA = '[CAPTCHA] Fetch Captcha',
    FETCH_CAPTCHA_SUCCESS = '[CAPTCHA] Fetch Captcha Successfull',
    FETCH_CAPTCHA_FAILED = '[CAPTCHA] Fetch Captcha Failure',
    GET_SECURITY_QUESTIONS = '[SECURITY_QUESTIONS] Get Security Questions',
    GET_SECURITY_QUESTIONS_SUCCESS = '[SECURITY_QUESTIONS_SUCCESS] Security Questions Successfull',
    GET_SECURITY_QUESTIONS_FAILED = '[GET_SECURITY_QUESTIONS_FAILED] Security Questions Failure',
    GET_CONTEXT_DETAIL_WITH_AUTH_KEY = '[GET_CONTEXT_DETAIL_WITH_AUTH_KEY] Get Context Detail With Authorization Key',
    GET_CONTEXT_DETAIL_WITH_AUTH_KEY_SUCCESS = '[GET_CONTEXT_DETAIL_WITH_AUTH_KEY_SUCCESS] Get Context Detail With Authorization Key Successfull',
    GET_CONTEXT_DETAIL_WITH_AUTH_KEY_FAILED = '[GET_CONTEXT_DETAIL_WITH_AUTH_KEY_FAILED] Get Context Detail With Authorization Key Failure',
    GET_PROVIDER_ORGANIZATIONS = '[GET_PROVIDER_ORGANIZATIONS] Get Provider Organizations',
    GET_PROVIDER_ORGANIZATIONS_SUCCESS = '[GET_PROVIDER_ORGANIZATIONS_SUCCESS] Get Provider Organizations Successfull',
    GET_PROVIDER = '[GET_PROVIDER] Get Provider',
    GET_PROVIDER_CALLBACK = '[GET_PROVIDER_CALLBACK] Get Provider Callback',
    GET_APPLICATION_PROPERTIES = '[GET_APPLICATION_PROPERTIES] Get Application Properties',
    SET_APPLICATION_PROPERTIES = '[AUTH] Set Application Properties',
    USER_REGISTRATION = '[USER_REGISTRATION] User Registration',
    USER_REGISTRATION_SUCCESS = '[USER_REGISTRATION_SUCCESS] User Registration Successfull',
    USER_REGISTRATION_FAILED = '[USER_REGISTRATION_FAILED] User Registration Failure',
    GET_4EC_PERMISSION = '[AUTH] Get 4EC Permission',
    SET_4EC_PERMISSION = '[AUTH] Set 4EC Permission',
}

export const AuthGetClientDetails = createAction(
    AuthActionTypes.AUTH_GET_CLIENT_DETAILS,
    props<{
        payload: {
            domain: string;
        };
    }>()
);

export const ClientDetailsSuccess = createAction(AuthActionTypes.AUTH_CLIENT_DETAILS_SUCCESS, props<ClientDetails>());

export const ClientDetailsFailed = createAction(
    AuthActionTypes.AUTH_CLIENT_DETAILS_FAILED,
    props<{
        msg?: string;
        text?: string;
    }>()
);

export const InternalAuthTokenValidation = createAction(
    AuthActionTypes.INTERNAL_AUTH_TOKEN_VALIDATE,
    props<{
        payload: {
            authCode: string;
            clientId: string;
            code: string;
        };
    }>()
);

export const InternalAuthTokenValidationSuccess = createAction(
    AuthActionTypes.INTERNAL_AUTH_TOKEN_VALIDATE_SUCCESS,
    props<{
        access_token: string;
        expires_in: number;
        orgId: string;
        refresh_token: string;
        resourceOwnerId: string;
        scope: [];
        token_type: string;
    }>()
);

export const InternalAccessTokenValidation = createAction(
    AuthActionTypes.INTERNAL_ACCESS_TOKEN_AUTHENTICATE,
    props<{
        token: string;
    }>()
);

export const InternalAccessTokenValidationSuccess = createAction(AuthActionTypes.INTERNAL_ACCESS_TOKEN_AUTHENTICATE_SUCCESS);

export const AuthCodeExternalValidation = createAction(
    AuthActionTypes.AUTH_VALIDATE_EXTERNALLY,
    props<{
        payload: {
            domain: string;
            redirectURL: string;
            authCode: string;
            code: string;
        };
    }>()
);

export const ExternalCodeValidationSuccess = createAction(AuthActionTypes.AUTH_VALIDATION_SUCCESS);

export const ExternalCodeValidationFailed = createAction(AuthActionTypes.AUTH_VALIDATION_FAILED);

export const SubmitLDAPDetails = createAction(
    AuthActionTypes.AUTH_LDAP_SUBMIT,
    props<{
        payload: {
            attribute: string;
            password: string;
            identityProviderId: string;
        };
    }>()
);

export const LdapLoginSuccess = createAction(
    AuthActionTypes.AUTH_LDAP_LOGIN_SUCCESS,
    props<{
        msg: string;
    }>()
);

export const LdapLoginFailed = createAction(
    AuthActionTypes.AUTH_LDAP_LOGIN_FAILED,
    props<{
        msg: string;
    }>()
);

export const ResetPassword = createAction(
    AuthActionTypes.RESET_PASSWORD,
    props<{
        payload: ResetPasswordPayload;
    }>()
);

export const SetPassword = createAction(
    AuthActionTypes.SET_PASSWORD,
    props<{
        payload: SetPasswordPayload;
    }>()
);

export const PasswordUpdated = createAction(
    AuthActionTypes.PASSWORD_UPDATED,
    props<{
        success: boolean;
    }>()
);

export const GetSecurityQuestions = createAction(AuthActionTypes.GET_SECURITY_QUESTIONS);

export const GetSecurityQuestionsSuccess = createAction(
    AuthActionTypes.GET_SECURITY_QUESTIONS_SUCCESS,
    props<{ secretQuestions: string[] }>()
);

export const GetProviderOrganizations = createAction(AuthActionTypes.GET_PROVIDER_ORGANIZATIONS);

export const GetProviderOrganizationsSuccess = createAction(
    AuthActionTypes.GET_PROVIDER_ORGANIZATIONS_SUCCESS,
    props<{
        result: any[];
    }>()
);

export const GetProvider = createAction(AuthActionTypes.GET_PROVIDER);

export const GetProviderCallback = createAction(
    AuthActionTypes.GET_PROVIDER_CALLBACK,
    props<{
        result: {
            providerName: string | number | boolean;
            providerId: string | number | boolean;
        };
    }>()
);

export const GetApplicationProperties = createAction(AuthActionTypes.GET_APPLICATION_PROPERTIES);

export const SetApplicationProperties = createAction(
    AuthActionTypes.SET_APPLICATION_PROPERTIES,
    props<{
        result: any;
    }>()
);

export const UserRegistration = createAction(
    AuthActionTypes.USER_REGISTRATION,
    props<{
        payload: UserRegistrationPayload;
    }>()
);

export const UserRegistrationSuccess = createAction(
    AuthActionTypes.USER_REGISTRATION_SUCCESS,
    props<{
        result: any;
    }>()
);

export const UserRegistrationFailed = createAction(
    AuthActionTypes.USER_REGISTRATION_FAILED,
    props<{
        result: any;
    }>()
);

export const FetchCaptcha = createAction(
    AuthActionTypes.FETCH_CAPTCHA,
    props<{
        payload: {
            plain?: boolean;
            forgotPswd?: boolean;
            forgotUsername?: boolean;
        };
    }>()
);

export const FetchCaptchaSuccess = createAction(
    AuthActionTypes.FETCH_CAPTCHA_SUCCESS,
    props<{
        captcha: string;
        plain: boolean;
        forgotPswd: boolean;
        forgotUsername: boolean;
    }>()
);

export const GetContextDetailWithKey = createAction(
    AuthActionTypes.GET_CONTEXT_DETAIL_WITH_AUTH_KEY,
    props<{
        payload: {
            key: string;
        };
    }>()
);

export const GetContextDetailWithKeySuccess = createAction(
    AuthActionTypes.GET_CONTEXT_DETAIL_WITH_AUTH_KEY_SUCCESS,
    props<{
        organization: Organization;
        user: User;
        settings: {
            passwordPolicy: string;
            passwordPolicyMsg: string;
        };
    }>()
);

export interface ResetPasswordPayload {
    key: string;
    password: string;
    confirmPassword: string;
    secretQuestionAndAnswersCommand: object;
    captcha: string;
}

export interface SetPasswordPayload {
    orgId: string;
    payload: {
        authenticationKey: string;
        userId: string;
        userName: string;
        newPassword: string;
        confirmPassword: string;
        secretQuestionAndAnswersCommand: object;
        captcha: string;
    };
}
export interface UserRegistrationPayload {
    captcha: string;
    invitationId: string;
    org: any;
    user;
}

export const Get4ECPermission = createAction(AuthActionTypes.GET_4EC_PERMISSION);

export const Set4ECPermission = createAction(
    AuthActionTypes.SET_4EC_PERMISSION,
    props<{
        hasAccess: boolean;
    }>()
);
