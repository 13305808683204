import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PARTNER_REDUCER_KEY } from '../reducers';
import { PartnerInitialState } from '../states/partner.state';
import { getCurrentOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<PartnerInitialState>(PARTNER_REDUCER_KEY);

export const getSentPartners$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.partnersDetails[organizationId]?.sentPartnerInvitations || []
);

export const getReceivedInvitations$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.partnersDetails[organizationId]?.receivedInvitations || []
);

export const getAllOrganizationList$ = createSelector(currentState, (state) => state.allOrganizationsList);

export const getPartnerOrgs$ = createSelector(currentState, (state) => state.partnerOrgs);

export const getOrganizationPartners$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.organizationPartners?.[organizationId]
);
