import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';

@Component({
    selector: 'material-slidetoggle',
    templateUrl: './slidetoggle.component.html',
})
export class MaterialSlidetoggleComponent implements OnInit, OnDestroy {
    @ViewChild('slideToggle') slideToggle: MatSlideToggle;

    @Input() name: string | null;
    @Input() id: string;
    @Input() model: boolean;
    @Input() disabled: boolean;
    @Input() dontChangeImmediately: boolean;

    @Output() change = new EventEmitter();
    @Output() modelChange = new EventEmitter();
    @Output() changeToggle = new EventEmitter();

    counter = 0;

    constructor() {}

    modelChanged = (event, input?) => {
        if (this.dontChangeImmediately) {
            this.slideToggle.checked = event;
        } else {
            this.model = input.checked;
        }
        this.modelChange.emit(!event);
        this.changeToggle.emit({ model: !event, inputSource: !event });
    };

    changeStatus = () => {
        this.counter = this.counter + 1;
        this.changeToggle.emit(this.counter);
    };

    ngOnInit() {}

    ngOnDestroy() {}
}
