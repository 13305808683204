<div class="templateTableMainDiv" *transloco="let translate;">
    <div class="detailsHeaderContainer row">
        <div class="detailsHeaderText">
            <strong>{{translate('Template List')}}</strong>
        </div>
        <div class="iconsDiv">
            <button mat-icon-button [disabled]="selectedTemplates.length === 0" (click)="deleteSelectedTemplates()">
                <mat-icon [title]="translate('Delete Selected Templates')">delete_sweep</mat-icon>
            </button>
            <mat-icon [title]="translate('Back To Run Reconciliation')" (click)="navigateToNewProcess()">close</mat-icon>
        </div>
    </div>
    <div class="notifyContentContainer">
        <perfect-scrollbar style="height: calc(100vh - 140px)">
            <material-table [allTableColumns]="allTableColumns" [selectedColumns]="selectedColumns" [data]="tableData" [recordActions]="recordActions" [noRecordMsg]="translate('No Templates Found')" [noLog]="true" [defaultPaginator]="true" (recordSelectedEvent)="selectectedTemplate($event)"></material-table>
        </perfect-scrollbar>
    </div>
</div>
