import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BroadcasterService } from 'ng-broadcaster';
import { QueryBuilderConfig } from 'ng-query-builder';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MY_DATE_FORMATS, MyDateAdapter } from '../../extensions/dateadapter/date.adapter';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

@Component({
    selector: 'app-query-builder-new',
    templateUrl: './query-builder-new.component.html',
    styleUrls: ['./query-builder-new.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MyDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_DATE_FORMATS,
        },
    ],
})
export class QueryBuilderNewComponent implements OnInit, OnDestroy {
    @Input() query;
    @Input() config: QueryBuilderConfig;
    @Input() allowRuleset;
    @Input() isAddRuleVisible: boolean;
    @Input() allowCollapse: boolean;
    @Input() relationConfigExists: boolean;
    @Input() isRelationDisabled: boolean;
    @Input() origin: string;
    @Input() queryType: 'SEARCH' | 'SORT';
    @Input() queryConfigChange: Subject<{
        searchConfig?: QueryBuilderConfig;
        sortConfig?: QueryBuilderConfig;
    }>;
    @Input() disabled: boolean;

    @Output() queryChanged = new EventEmitter<any>();
    @Output() relationChanged = new EventEmitter<any>();

    customOperatorsMap: { [key: string]: string[] } = {
        string: ['EQ', 'NOT_EQUALS', 'IN', 'CONTAINS', 'is null', 'is not null'],
        number: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
        time: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
        date: ['EQ', 'NOT_EQUALS', 'IN', 'LT', 'LTE', 'GT', 'GTE', 'is null', 'is not null'],
        category: ['EQ', 'NOT_EQUALS', 'IN', 'is null', 'is not null'],
        boolean: ['EQ', 'NOT_EQUALS', 'is null', 'is not null'],
    };
    addOnBlur = true;
    separatorKeysCodes = [ENTER, COMMA];
    queryCopy: any;
    unSubscribe = new Subject<void>();
    public searchField: string;

    constructor(private broadcaster: BroadcasterService) {}

    onParentChange = () => {
        // console.log('On Parent Change');
        if (this.origin !== 'ENCOLLAB') {
            const clone = CommonUtilsService.cloneObject(this.query);
            this.queryChanged.emit(clone);
        }
    };

    onOperatorChange = (operator: string, rule: any) => {
        if (operator === 'IN') {
            rule.value = [];
        } else {
            rule.value = '';
        }
        this.onParentChange();
    };

    addNewTag = (event: MatChipInputEvent, rule: any): void => {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            rule.value.push(value.trim());
        }
        if (input) {
            input.value = '';
        }
        this.onParentChange();
    };

    removeTag = (value: string, rule: any): void => {
        const index = rule.value.indexOf(value);
        if (index >= 0) {
            rule.value.splice(index, 1);
        }
    };

    openPanel = () => {
        this.query['collapsed'] = false;
    };

    trackByOptionMethod(index: number, option: any): any {
        return option.value;
    }

    trackByOperatorsMethod(index: number, operator: any): any {
        return operator;
    }

    trackByEntityMethod(index: number, entity: any): any {
        return entity.name;
    }

    trackByFieldMethod(index: number, item: any): any {
        return index;
    }

    ngOnInit() {
        this.broadcaster
            .on('queryConfigChange')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: { searchConfig: QueryBuilderConfig; sortConfig: QueryBuilderConfig }) => {
                this.config = Object.assign({}, data.searchConfig);
            });
        this.queryConfigChange
            ?.pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data: { searchConfig: QueryBuilderConfig; sortConfig: QueryBuilderConfig }) => {
                switch (this.queryType) {
                    case 'SEARCH':
                        this.config = Object.assign({}, data.searchConfig);
                        break;
                    case 'SORT':
                        this.config = Object.assign({}, data.sortConfig);
                        break;
                }
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
