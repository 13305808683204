<ng-container [ngSwitch]="field.uiType">
    <ng-container *ngSwitchCase="'RADIOBUTTON'">
        <mat-radio-button class="inputRadioButton margin5px" *ngFor="let option of options" [value]="option.value" [checked]='checkIfRadioBtnSelected(option)' (change)='modelChange.emit(option.value)'>
            {{option.displayName}}
        </mat-radio-button>
    </ng-container>
    <ng-container *ngSwitchCase="'CHECKBOX'">
        <material-group-checkbox [menuName]="field.displayName || field.name" [classList]="[]" [options]="options" [value]="field.value" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'value'" [required]="field.isMandatory || field.isBusinessKey" [description]="field.description || field.displayName || field.name" (onOptionsChanged)="field.value = $event; modelChange.emit($event)"></material-group-checkbox>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <material-select [disabled]="disabled" [options]="options" [placeholder]="field.displayName || field.name" [errors]="errors" [name]="field.displayName || field.name" [title]="field.displayName || field.name" [required]="required" [model]="field.value || fieldValue" (modelChange)="modelChange.emit($event)"></material-select>
    </ng-container>
</ng-container>
