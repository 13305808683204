import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'role-fields',
    templateUrl: './rolefields.component.html',
})
export class RoleFieldsComponent implements OnInit {
    @Input() rolesObject;
    @Input() errors;

    constructor() {}

    ngOnInit() {}
}
