import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent {
    @Input() selectedRoleName: string;
    @Input() partnerOrganizations: any[];
    @Input() users: any[];
    @Input() selectedPartnerOrg: string;
    @Input() allUsersSelected: boolean;
    @Input() formValid: boolean;
    @Input() noAssignUsers: boolean;
    @Input() radioDisabled?: boolean;
    @Input() searchUser: string;
    @Input() selectedUser: any;
    @Input() errors: {
        org: string[];
        user: string[];
    };

    @Output() searchUserChange = new EventEmitter();
    @Output() cancelView = new EventEmitter();
    @Output() selectAllUsers = new EventEmitter();
    @Output() clearSearchUsers = new EventEmitter();
    @Output() assignUsersToLocations = new EventEmitter();
    @Output() cancelAssignUsers = new EventEmitter();
    @Output() checkUsersList = new EventEmitter();
    @Output() searchAssignUsers = new EventEmitter();
    @Output() selectedPartnerOrgChange = new EventEmitter();

    partnerChange = (partnerChange: MatSelectChange) => {
        this.selectedPartnerOrgChange.emit(partnerChange.value);
    };
}
