<ng-container *ngIf="!app.canViewAllProcesses && app.assetType !== 'RECON'">
    <div class="cannotViewProcesses" *transloco="let translate;">
        {{translate('You donot have permissions for viewing the processes')}}.
    </div>
</ng-container>
<ng-container *ngIf="loading && loading.instance && app.canViewAllProcesses">
    <div class="homeAppProcessWorkflowContainer" *transloco="let translate;">
        <div style="cursor:pointer">
            <p class="processInfoPara">
                <span class="processInfoTitle"> {{translate('Status')}}:</span>
                <span class="appRequestStatus loaderBlock isLoading"></span>
            </p>
            <p class="processInfoPara">
                <span class="processInfoTitle"> {{translate('Process Date / Time')}}: </span>
                <span class="lastProcessedTime loaderBlock isLoading"></span>
            </p>
            <p class="inProgressFilingAttributesContainer processInfoPara">
                <span class="processInfoTitle"> {{translate('Reporting Attributes')}}:</span>
                <span class="appProcessReportingAttributes loaderBlock isLoading"> </span>
            </p>
        </div>
        <div class="workflowStagesContainer loaderBlock isLoading"></div>
        <div class="workflowStagesContainer loaderBlock isLoading"></div>
        <div class="workflowStagesContainer loaderBlock isLoading"></div>
    </div>
</ng-container>
<ng-container *ngIf="(!loading || !loading.instance) && app.canViewAllProcesses">
    <div *ngIf="instance === false" class="homeAppProcessWorkflowContainer">
        <div *transloco="let translate;">
            {{translate('No processes found')}}.
        </div>
    </div>
    <div *ngIf="instance && (instance.instanceState || instance.requestState)" class="homeAppProcessWorkflowContainer">
        <div AppProcessRequest [app]="app" [bridge]="bridge" style="cursor:pointer" *ngIf="hideActionButtons" [getDirectLink]="true" [appId]="app && app.id" [bridgeId]="bridge && bridge.id" [assetInstance]="instance" [processInSameTab]="processInSameTab">
            <a class="noLink" [routerLink]="instance && [instance['requestLink']]" [hidden]="processInSameTab">
                <ng-container *ngTemplateOutlet="processStatus"> </ng-container>
            </a>
            <a class="noLink" [hidden]="!processInSameTab">
                <ng-container *ngTemplateOutlet="processStatus"> </ng-container>
            </a>
        </div>
        <div class="inProgressFilingAttributesContainer loaderBlock" [class.isLoading]="loading && loading.attributes" [ngClass]="{'fromAppHome': fromAppHome}" *ngIf="fromAppHome">
            <div class="row" *transloco="let translate;">
                <div class="col-12 padding0px">
                    <span class="col-8 displyInlineBlock padding0px">
                        <div class="col-12 ">
                            <span *ngIf="(filingAttributes && filingAttributes.length > 0)" class="reportingAttributesLabel d-block">{{translate('Reporting Attributes')}}: </span>
                            <ng-container *ngFor="let attribute of filingAttributes;index as i">
                                <span class="inProgressFilingContent reportattributefieldValues d-inline-block">
                                    <label> <span *ngIf="i > 0" class="inProgressFilingAttrSeparator" [title]="attribute.value">|</span>{{attribute.name || attribute.label}} -
                                    </label>
                                    <span class="reportAttributeValueColor fontWeightBold" [title]="attribute.value">{{attribute.value}}</span>
                                    <span *ngIf="(i+1)!==filingAttributes.length" class="reportsAttributePipe">|</span>
                                </span>
                            </ng-container>
                        </div>
                        <div class="col-12 ">
                            <div class="inProgresslastProcessedData">
                                <span>
                                    <span class="reportingAttributesLabel d-inline-block"> {{translate('Last Processed')}}: </span>{{getCreatedDate(instance.createdOn || instance.createdDate)}}
                                </span>
                            </div>
                        </div>
                    </span>
                    <span class="col-4 displyInlineBlock verticalAlingTop padding0px" *ngIf="(!workflowStages || (workflowStages && workflowStages.length < 1))">
                        <div class="col-12 " *ngIf="!report || !report.name">
                            <div class="processDetails" AppProcessRequest [app]="app" [bridge]="bridge" [getDirectLink]="true" [appId]="app && app.id" [bridgeId]="bridge && bridge.id" [assetInstance]="instance" [report]="report" [processInSameTab]="processInSameTab">
                                <a class="noLink" [routerLink]="instance && [instance['requestLink']]" [hidden]="processInSameTab">
                                    <material-button class='noOutline submitColor inProgressFilingAttrBtn' [label]="translate('Process Details')" color="primary">
                                    </material-button>
                                </a>
                                <a class="noLink" [hidden]="!processInSameTab">
                                    <material-button class='noOutline submitColor inProgressFilingAttrBtn' [label]="translate('Process Details')" color="primary">
                                    </material-button>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 " *ngIf="instance && (instance.reportLink || instance.multipleReports)">
                            <div class="processDetails">
                                <ng-container *ngIf="instance.multipleReports">
                                    <a *ngFor="let link of instance.reportLinks" class="btn-link btn" [href]="link" target="_blank">Download</a>
                                </ng-container>
                                <ng-container *ngIf="!instance.multipleReports">
                                    <a class="btn-link btn" [href]="instance.reportLink" target="_blank">Download</a>
                                </ng-container>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <ng-container *ngIf="loading && loading.workflow">
            <div class="workflowStagesContainer loaderBlock isLoading"></div>
            <div class="workflowStagesContainer loaderBlock isLoading"></div>
            <div class="workflowStagesContainer loaderBlock isLoading"></div>
        </ng-container>
        <ng-container *ngIf="!(loading && loading.workflow)">
            <div class="workflowStagesContainer" AppProcessRequest [app]="app" [bridge]="bridge" [getDirectLink]="true" [appId]="app && app.id" [bridgeId]="bridge && bridge.id" [assetInstance]="instance" [report]="report" [processInSameTab]="processInSameTab">
                <ng-container *ngIf="workflowStages && workflowStages.length > 0">
                    <a class="noLink stepperTooltipEnabled" [routerLink]="instance && [instance['requestLink']]" [hidden]="processInSameTab">
                        <ng-container *ngTemplateOutlet="workflowTemplate"></ng-container>
                    </a>
                    <a class="noLink stepperTooltipEnabled" [hidden]="!processInSameTab">
                        <ng-container *ngTemplateOutlet="workflowTemplate"></ng-container>
                    </a>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #processStatus>
    <p class="processInfoPara" *transloco="let translate;">
        <span class="processInfoTitle">{{translate('Status')}}:</span>
        <span class="appRequestStatus loaderBlock" [class.isLoading]="instance.loading && instance.loading.status">
            <ng-container [ngSwitch]="instance.instanceState || instance.requestState">
                <ng-container *ngSwitchCase="'IN_PROGRESS'">
                    <span class="inprogress"> {{translate('In Progress')}}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'TERMINATED'">
                    <span class="terminated"> {{translate('Terminated')}}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'REQUEST_COMPLETED'">
                    <span class="completed"> {{translate('Completed')}} </span>
                </ng-container>
                <ng-container *ngSwitchCase="'REQUEST_CANCELLED'">
                    <span class="terminated"> {{translate('Cancelled')}}</span>
                </ng-container>
            </ng-container>
        </span>
    </p>
    <p class="processInfoPara" *transloco="let translate;">
        <span class="processInfoTitle"> {{translate('Process Date / Time')}}: </span>
        <span class="lastProcessedTime loaderBlock" [class.isLoading]="loading && loading.workflow || loading && loading.instance">{{getCreatedDate(instance.createdOn || instance.createdDate)}}</span>
    </p>
    <ng-container *ngIf="filingAttributes?.length > 0 || loading && loading.attributes === true">
        <p class="inProgressFilingAttributesContainer processInfoPara" *transloco="let translate;">
            <span class="processInfoTitle d-block"> {{translate('Reporting Attributes')}}:</span>
            <span class="appProcessReportingAttributes loaderBlock" [class.isLoading]="loading && loading.attributes">
                <ng-container *ngFor="let attribute of filingAttributes;index as i">
                    <span class="inProgressFilingContent reportattributefieldValues d-inline-block">
                        <label> <span *ngIf="i > 0" class="inProgressFilingAttrSeparator" [title]="attribute.value">&nbsp;|&nbsp;</span>{{attribute.name || attribute.label}}</label>
                        <span>:&nbsp;</span>
                        <span [title]="attribute.value">{{attribute.value}}</span>
                    </span>
                </ng-container>
            </span>
        </p>
    </ng-container>
</ng-template>

<ng-template #workflowTemplate>
    <mat-horizontal-stepper labelPosition="bottom" #horizontalStepper [selectedIndex]="0" [linear]="false">
        <ng-template matStepperIcon="edit" let-index="index">
            <mat-icon [class.completed]="horizontalStepper.selectedIndex >= index">fiber_manual_record
            </mat-icon>
        </ng-template>
        <ng-template matStepperIcon="number" let-index="index">
            <mat-icon [class.completed]="horizontalStepper.selectedIndex >= index">fiber_manual_record
            </mat-icon>
        </ng-template>
        <mat-step *ngFor="let workflow of workflowStages; let i = index" [stepControl]="workflow.stepControl" [class.completedWorkflow]="workflow.endTime">
            <ng-template matStepLabel>
                <div *transloco="let translate;">
                    <span class="workFlowName homePageStepperWorkFlowName" [class.completed]="horizontalStepper.selectedIndex >= i" title="{{workflow.name}}">
                        {{workflow.name}}
                    </span>
                </div>
                <div *ngIf="i === horizontalStepper.selectedIndex + 1 && !hideActionButtons">
                    <div *transloco="let translate;">
                        <material-button class='noOutline submitColor inProgressFilingAttrBtn pointer' [label]="translate('Process Now')" color="primary" (onTrigger)="initiateProcess()">
                        </material-button>
                    </div>
                </div>
            </ng-template>
        </mat-step>
    </mat-horizontal-stepper>
</ng-template>
