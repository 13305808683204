import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/apps.actions';
import * as sessionActions from '../actions/session.actions';
import { appsState } from '../states/apps.state';

export const APPS_REDUCER = createReducer(
    appsState,
    on(sessionActions.CompletedSwitchToOrganization, (state, { organization }) => ({
        ...state,
        currentOrganizationId: organization?.id,
    })),
    on(sessionActions.SetCurrentOrganization, (state, { organization }) => ({
        ...state,
        currentOrganizationId: organization?.id,
    })),
    on(actions.SetAppDescription, (state, { serviceId, description }) => ({
        ...state,
        appsDescriptionByServiceId: {
            ...state.appsDescriptionByServiceId,
            [serviceId]: description,
        },
    })),
    on(actions.SetAppHelpDocumentation, (state, { serviceId, helpDocuments }) => ({
        ...state,
        appsHelpDocumentsByServiceId: {
            ...state.appsHelpDocumentsByServiceId,
            [serviceId]: helpDocuments,
        },
    })),
    on(actions.SetAppBridgeNodes, (state, { assetId, bridgeNodes }) => ({
        ...state,
        bridgeServices: {
            ...state.bridgeServices,
            [assetId]: bridgeNodes,
        },
    })),
    on(actions.SetSubscribedApps, (state, { services }) => ({
        ...state,
        subscribedServices: {
            ...state.subscribedServices,
            [state.currentOrganizationId]: services,
        },
    })),
    on(actions.SetAppMetaData, (state, { serviceId, metaData }) => ({
        ...state,
        assetsMetaData: {
            ...state.assetsMetaData,
            [serviceId]: {
                ...state.assetsMetaData[serviceId],
                [metaData.uid]: metaData,
            },
        },
    })),
    on(actions.SetReconConfiguration, (state, { serviceId, configuration }) => ({
        ...state,
        reconConfiguration: {
            ...state.reconConfiguration,
            [serviceId]: configuration,
        },
    })),
    on(actions.SetAppLoading, (state, { serviceId, loading }) => ({
        ...state,
        loading: {
            ...state.loading,
            [serviceId]: {
                ...state.loading[serviceId],
                meta: loading,
            },
        },
    })),
    on(actions.SetAppTemplateLoading, (state, { serviceId, loading }) => ({
        ...state,
        loading: {
            ...state.loading,
            [serviceId]: {
                ...state.loading[serviceId],
                template: loading,
            },
        },
    })),
    on(actions.SetReconConfigurationLoading, (state, { serviceId, loading }) => ({
        ...state,
        loading: {
            ...state.loading,
            [serviceId]: {
                ...state.loading[serviceId],
                reconConfiguration: loading,
            },
        },
    })),
    on(actions.SetSelectedAppServiceId, (state, { serviceId }) => ({
        ...state,
        selectedApp: {
            ...state.selectedApp,
            serviceId,
        },
    })),
    on(actions.SetSelectedBridgeServiceId, (state, { serviceId }) => ({
        ...state,
        selectedApp: {
            ...state.selectedApp,
            bridgeServiceId: serviceId,
        },
    })),
    on(actions.SetSelectedAppCategory, (state, { category }) => ({
        ...state,
        selectedApp: {
            ...state.selectedApp,
            category,
        },
    })),
    on(actions.SetSelectedAppEntity, (state, { entityId }) => ({
        ...state,
        selectedApp: {
            ...state.selectedApp,
            entityId,
        },
    })),
    on(actions.SetAppTemplate, (state, { organizationId, serviceId, template }) => ({
        ...state,
        appsTemplate: {
            ...state.appsTemplate,
            [organizationId]: {
                ...state.appsTemplate[organizationId],
                [serviceId]: template,
            },
        },
    })),
    on(sessionActions.SessionLogoutSuccessfull, () => appsState)
);

export const APPS_REDUCER_KEY = 'apps';
