export const ActiveTokensInitialState: ActiveTokensInitialState = {
    tokens: {
        activeTokens: [],
        count: 0,
        error: undefined,
    },
    invalidate: {
        error: undefined,
        success: undefined,
    },
};

export interface ActiveTokensInitialState {
    tokens: {
        activeTokens: any[];
        count: number;
        error: string;
    };
    invalidate: {
        error: string;
        success: string;
    };
}
