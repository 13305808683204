import { AssetEntityConfigurationInterface } from 'taxilla-library';

import { EntityBusinessKeys } from './entitybusinesskeys.class';
import { Field } from './field.class';
import { Masters } from './masters/masters.class';
import { Record } from './record/record.class';
import { RecordField } from './record/recordfield.class';
import { Rule } from './rule.class';

export class Entity {
    array: boolean;

    businessKeys: EntityBusinessKeys;

    caseSensitive: Boolean;

    entities: Array<Entity>;

    fields: Array<Field>;

    idGenerators: any[];

    mandatory: boolean;

    name: string;

    displayName: string;

    primary: boolean;

    readOnly: boolean;

    ruleReferences: any[];

    rules: Array<Rule>;

    show: boolean;

    type: string;

    uid: string;

    maxElements: number;

    // UI related variables
    entityData: Array<Record> | Record;

    pagination: { searchAfter?; size?: number; count?: number; pageIndex?: number; page? };

    pagingState: string;

    masters: Masters;

    showEntity?: boolean;

    canAddRecord?: boolean;

    canUpdateRecord?: boolean;

    canDeleteRecord?: boolean;

    description?: string;

    hasAddRecordPermission: boolean;

    hasDeleteRecordPermission: boolean;

    hasReadRecordPermission: boolean;

    hasUpdateRecordPermission: boolean;

    maxNumberOfRecords?: number;

    labelName?: string;

    saveBtnName?: string;

    updateBtnName?: string;

    templates?: AssetEntityConfigurationInterface['templates'];

    mapToPrimaryEntity?: {
        [property: string]: string;
    };

    fieldsToHideOnNewRecord: string[];

    fieldsToAutoPopulate?: {
        id?: string;
        by?: string;
        value?: string;
        record?: string;
        showAfter?: boolean;
        entityId?: string;
        fieldId?: string;
    }[];

    editMode?: boolean;

    disableIfValueExists?: string[];

    enableChildEntitiesOnlyIfFieldValueExists?: string[];

    disableSubmitIfValueExists?: string[];

    fieldsToDisableOnOldRecord?: string[];

    mandateFieldsOnOldRecord?: string[];

    fieldsToAutoPopulateOnOldRecord?: {
        id?: string;
        by?: string;
        value?: string;
        record?: string;
        showAfter?: boolean;
        entityId?: string;
        fieldId?: string;
    }[];

    fieldsToDisableOnCondtion?: {
        id?: string;
        requiredField: string;
        condition?: string;
    }[];

    hasSearchableFields?: boolean;

    hasSortableFields?: boolean;

    constructor(data, rules: Array<Rule>, masters: Masters, template?: Entity) {
        this.array = data?.array;
        this.businessKeys = new EntityBusinessKeys(data?.businessKeys || data?.businessKey);
        this.caseSensitive = data?.caseSensitive;
        this.idGenerators = data?.idGenerators;
        this.mandatory = data?.mandatory;
        this.displayName = template?.displayName || data?.displayName;
        this.name = template?.displayName || data?.name;
        this.primary = data?.primary;
        this.readOnly = data?.readOnly;
        this.ruleReferences = data?.ruleReferences;
        this.show = template?.show !== undefined ? template?.show && data?.show : data?.show;
        this.type = data?.type;
        this.uid = data?.uid;
        this.description = template?.description || data?.description;
        this.masters = masters;
        this.maxElements = data?.maxElements;
        this.entities = [];
        this.fields = [];
        this.rules = [];
        if (this.array) {
            this.entityData = [];
            data.entityData?.forEach((entityData) => {
                (<Record[]>this.entityData).push(new Record(entityData, data));
            });
        } else {
            this.entityData = new Record(data?.entityData || {}, data);
        }
        data?.pagination !== undefined && (this.pagination = data.pagination);
        (data?.entities as Entity[])
            ?.slice(0)
            ?.sort((a, b) => {
                if (!template || template?.entities?.length === 0) {
                    return 0;
                }
                const aIndex = template?.entities?.find((e) => e.uid === a.uid)?.['entityOrder'];
                const bIndex = template?.entities?.find((e) => e.uid === b.uid)?.['entityOrder'];
                if (aIndex !== undefined && bIndex !== undefined) {
                    return aIndex - bIndex;
                } else if (aIndex !== undefined) {
                    return -1;
                } else if (bIndex !== undefined) {
                    return 1;
                }
                return 0;
            })
            .forEach((entity) => {
                const entityTemplate = template?.entities?.find((childTemplate) => childTemplate.uid === entity.uid);
                this.entities.push(new Entity(entity, rules, masters, entityTemplate));
            });
        data?.fields
            ?.slice(0)
            ?.sort((a, b) => {
                if (template || template?.fields?.length === 0) {
                    return 0;
                }
                const aIndex = this.getFieldOrder(template, a) || -1;
                const bIndex = this.getFieldOrder(template, b) || -1;
                if (aIndex > -1 && bIndex > -1) {
                    return aIndex - bIndex;
                } else if (aIndex > -1) {
                    return -1;
                } else if (bIndex > -1) {
                    return 1;
                }
                return 0;
            })
            ?.forEach((field) => {
                const fieldTemplate = this.getFieldTemplate(template, field);
                this.fields.push(new Field(field, rules, this.businessKeys.fields, masters, fieldTemplate));
            });
        this.fields.forEach((field) => {
            field.rules
                .filter((rule) => rule.ruleCategory === 'CALCULATION_RULE' && rule.ruleLanguage === 'JAVASCRIPT')
                .forEach((rule) => {
                    rule.ruleParams
                        .filter((param) => param.entityField?.fieldUid)
                        .forEach((param) => {
                            const fieldId = param.entityField.fieldUid;
                            const constraintField = this.getField(fieldId);
                            constraintField.dependentForFields.push(field.uid);
                        });
                });
        });
        this.rules = [...(rules || [])];
        this.pagination = Object.assign({}, data?.pagination || {});
        this.showEntity = data?.showEntity !== undefined ? data.showEntity : true;
        this.canAddRecord = data?.canAddRecord !== undefined ? data.canAddRecord : true;
        this.canUpdateRecord = data?.canUpdateRecord !== undefined ? data.canUpdateRecord : true;
        this.canDeleteRecord = data?.canDeleteRecord !== undefined ? data.canDeleteRecord : true;
        this.hasDeleteRecordPermission = data?.hasDeleteRecordPermission;
        this.hasAddRecordPermission = data?.hasAddRecordPermission;
        this.hasReadRecordPermission = data?.hasReadRecordPermission;
        this.hasUpdateRecordPermission = data?.hasUpdateRecordPermission;
        this.maxNumberOfRecords = data?.maxNumberOfRecords;
        this.labelName = data?.labelName;
        this.saveBtnName = data?.saveBtnName;
        this.updateBtnName = data?.updateBtnName;
        this.mapToPrimaryEntity = data?.mapToPrimaryEntity;
        this.templates = data?.templates;
        this.fieldsToHideOnNewRecord = data?.fieldsToHideOnNewRecord;
        this.fieldsToAutoPopulate = data?.fieldsToAutoPopulate;
        this.editMode = data?.editMode;
        this.enableChildEntitiesOnlyIfFieldValueExists = data?.enableChildEntitiesOnlyIfFieldValueExists;
        this.disableIfValueExists = data?.disableIfValueExists;
        this.disableSubmitIfValueExists = data?.disableSubmitIfValueExists;
        this.fieldsToDisableOnOldRecord = data?.fieldsToDisableOnOldRecord;
        this.fieldsToAutoPopulateOnOldRecord = data?.fieldsToAutoPopulateOnOldRecord;
        this.mandateFieldsOnOldRecord = data?.mandateFieldsOnOldRecord;
        this.fieldsToDisableOnCondtion = data?.fieldsToDisableOnCondtion;
        this.checkSearchableFields();
        this.checkSortableFields();
    }

    private getFieldOrder = (template, a: Field) => {
        return template
            ? template.fields?.find((e) => e.uid === a.uid)?.['fieldOrder'] || template?.grids?.find((e) => e.uid === a.uid)?.['fieldOrder']
            : a.order;
    };

    private getFieldTemplate = (template, a: Field) => {
        return template?.fields?.find((e) => e.uid === a.uid) || template?.grids?.find((e) => e.uid === a.uid);
    };

    private checkSearchableFields = () => {
        this.hasSearchableFields = this.fields?.find((field) => field.searchable) !== undefined;
    };

    private checkSortableFields = () => {
        this.hasSortableFields = this.fields?.find((field) => field.sortable) !== undefined;
    };

    getField = (fieldId) => {
        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].uid === fieldId) {
                return this.fields[i];
            }
        }
    };

    getFieldByFieldName = (fieldName) => {
        for (let i = 0; i < this.fields.length; i++) {
            if (this.fields[i].name === fieldName) {
                return this.fields[i];
            }
        }
    };

    isBusinessKey = (fieldId) => {
        if (this.businessKeys && this.businessKeys.fields && this.businessKeys.fields.length) {
            for (let i = 0; i < this.businessKeys.fields.length; i++) {
                return this.businessKeys.fields.indexOf(fieldId) > -1;
            }
        }
        return false;
    };

    getFieldByLookupId = (lookupId: string, fieldId: string) => {
        for (let i = 0; i < this.fields.length; i++) {
            if (
                this.fields[i].lookupInformationMetadata &&
                this.fields[i].lookupInformationMetadata.id &&
                this.fields[i].lookupInformationMetadata.id === lookupId &&
                this.fields[i].uid === fieldId
            ) {
                return this.fields[i];
            }
        }
        for (let i = 0; i < this.entities.length; i++) {
            const field: Field = this.entities[i].getFieldByLookupId(lookupId, fieldId);
            if (field && field.uid) {
                return field;
            }
        }
        return undefined;
    };

    getEntityDataRecord = (recordId) => {
        let parentRecord;
        if (Array.isArray(this.entityData)) {
            for (let i = 0; i < this.entityData.length; i++) {
                parentRecord = this.entityData[i].getRecord(recordId);
                if (parentRecord) {
                    break;
                }
            }
        } else {
            parentRecord = this.entityData.getRecord(recordId);
        }
        return parentRecord;
    };

    getParentRecordByChildRecordId = (childRecordId, parentRecord?: Record): Record => {
        let record: Record;
        if (Array.isArray(this.entityData)) {
            for (let i = 0; i < this.entityData.length; i++) {
                if (this.entityData[i].id === childRecordId) {
                    record = parentRecord;
                } else if (this.entityData[i].entities && this.entityData[i].entities.length > 0) {
                    for (let j = 0; j < this.entityData[i].entities.length; j++) {
                        record = this.entityData[i].entities[j].getParentRecordByChildRecordId(childRecordId, this.entityData[i]);
                        if (record?.id) {
                            break;
                        }
                    }
                }
                if (record?.id) {
                    break;
                }
            }
        } else if (this.entityData) {
            if (this.entityData.id === childRecordId) {
                record = parentRecord;
            } else if (this.entityData.entities && this.entityData.entities.length > 0) {
                for (let j = 0; j < this.entityData.entities.length; j++) {
                    record = this.entityData.entities[j].getParentRecordByChildRecordId(childRecordId, this.entityData);
                    if (record?.id) {
                        break;
                    }
                }
            }
        }
        return record;
    };

    getRecordEntity = (recordId, entity?: Entity): Entity => {
        entity = entity || this;
        if (!entity) {
            return;
        }
        let entityData: Entity;
        // check entity data for the corresponding record
        if (!Array.isArray(entity.entityData)) {
            const record: Record = entity.entityData;
            if (record) {
                if (record.id === recordId) {
                    entityData = entity;
                }
                if (!entityData) {
                    const childEntities = record.entities;
                    if (childEntities && childEntities.length > 0) {
                        for (let j = 0; j < childEntities.length; j++) {
                            entity = childEntities[j].getRecordEntity(recordId, childEntities[j]);
                            if (entity) {
                                break;
                            }
                        }
                    }
                }
            }
        } else if (entity && Array.isArray(entity.entityData)) {
            const records: Array<Record> = entity.entityData || [];
            for (let i = 0; i < records.length; i++) {
                if (entity && entity.entityData && entity.entityData[i] && entity.entityData[i].id === recordId) {
                    entityData = entity;
                    break;
                }
                if (!entityData) {
                    const childEntities = entity && entity.entityData && entity.entityData[i] && entity.entityData[i].entities;
                    if (childEntities && childEntities.length > 0) {
                        for (let j = 0; j < childEntities.length; j++) {
                            entity = childEntities[j].getRecordEntity(recordId, childEntities[j]);
                            if (entity) {
                                break;
                            }
                        }
                    }
                }
            }
        }
        return entityData;
    };

    removeRecordWhoseParentIdIsAndIdIs = (parentId, id) => {
        if (Array.isArray(this.entityData)) {
            for (let i = 0; i < this.entityData.length; i++) {
                if (parentId === undefined && this.entityData[i].id === id) {
                    this.entityData.splice(i, 1);
                } else {
                    this.entityData[i].removeRecordWhoseParentIdIsAndIdIs(parentId, id);
                }
            }
        } else {
            this.entityData.removeRecordWhoseParentIdIsAndIdIs(parentId, id);
        }
    };

    getEntity = (entityId: Entity['uid'], entities?): Entity => {
        let entity;
        if (this.uid === entityId) {
            entity = this;
        } else if (this.entities && this.entities.length > 0) {
            for (let i = 0; i < this.entities.length; i++) {
                if (this.entities[i].uid === entityId) {
                    entity = this.entities[i];
                    break;
                } else if (this.entities[i].entities && this.entities[i].entities.length > 0) {
                    entity = this.entities[i].getEntity(entityId);
                    if (entity) {
                        break;
                    }
                }
            }
        }
        return entity;
    };

    setFieldValue = (fieldId: RecordField['id'], newFieldValue: RecordField['value'], recordId?: Record['id']) => {
        let field: RecordField;
        if (Array.isArray(this.entityData)) {
            for (let i = 0; i < this.entityData.length; i++) {
                if (this.entityData[i].id === recordId) {
                    field = this.entityData[i].getFieldByFieldId(fieldId);
                    break;
                }
            }
        } else {
            field = this.entityData.getFieldByFieldId(fieldId);
        }
        field.value = newFieldValue;
    };

    toggleShowEntity = (toggle: boolean) => {
        this.showEntity = toggle;
        if (this.entityData) {
            if (Array.isArray(this.entityData)) {
                this.entityData.forEach((e) => e.toggleShowRecord(toggle));
            } else {
                this.entityData.toggleShowRecord(toggle);
            }
        }
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleShowEntity(toggle);
            });
        }
    };

    toggleReadOnlyEntity = (toggle: boolean) => {
        this.readOnly = toggle;
        if (this.entityData) {
            if (Array.isArray(this.entityData)) {
                this.entityData.forEach((e) => e.toggleReadOnlyRecord(toggle));
            } else {
                this.entityData.toggleReadOnlyRecord(toggle);
            }
        }
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleReadOnlyEntity(toggle);
            });
        }
    };

    toggleCanAddRecordInEntity = (toggle: boolean) => {
        this.canAddRecord = toggle;
        if (this.entityData) {
            if (Array.isArray(this.entityData)) {
                this.entityData.forEach((e) => e.toggleCanAddRecord(toggle));
            } else {
                this.entityData.toggleCanAddRecord(toggle);
            }
        }
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleCanAddRecordInEntity(toggle);
            });
        }
    };

    toggleCanUpdateRecordInEntity = (toggle: boolean) => {
        this.canUpdateRecord = toggle;
        if (this.entityData) {
            if (Array.isArray(this.entityData)) {
                this.entityData.forEach((e) => e.toggleCanUpdateAddRecord(toggle));
            } else {
                this.entityData.toggleCanUpdateAddRecord(toggle);
            }
        }
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleCanUpdateRecordInEntity(toggle);
            });
        }
    };

    toggleCanDeleteRecordInEntity = (toggle: boolean) => {
        this.canDeleteRecord = toggle;
        if (this.entityData) {
            if (Array.isArray(this.entityData)) {
                this.entityData.forEach((e) => e.toggleCanDeleteRecord(toggle));
            } else {
                this.entityData.toggleCanDeleteRecord(toggle);
            }
        }
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleCanDeleteRecordInEntity(toggle);
            });
        }
    };

    getAllEntitiesAsArray? = () => {
        const entities = [];
        for (let i = 0; i < this.entities.length; i++) {
            entities.push(this.entities[i]);
            const childEntities = this.entities[i].getAllEntitiesAsArray();
            for (let j = 0; j < childEntities.length; j++) {
                entities.push(childEntities[j]);
            }
        }
        return entities;
    };
}
