import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'source-table',
    templateUrl: './sourcetable.component.html',
    styleUrls: ['./sourcetable.component.scss'],
})
export class SourceTableComponent implements OnInit, OnDestroy {
    @Input() sourceParts = new BehaviorSubject([]);
    @Input() hidePaginator;
    @Input() sourceTableColumns;
    @Input() hideTableColumns;
    @Input() primaryColumns;
    @Output() selectedRecord = new EventEmitter();
    @Output() downloadSourceTemplateUrl = new EventEmitter();
    @Output() isFileUploaded = new EventEmitter();

    sourcePartsSubscription: Subscription;
    data = [];
    columns = [];
    allColumns = [];
    selectedColumns = [];
    allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([]);
    tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);

    constructor(public _utils: UtilsService) {}

    buildDataObjectForTable = () => {
        (!this.columns || this.columns.length === 0) && this.buildColumns();
    };

    buildColumns = () => {
        this.columns = [];
        const columnsData = [];
        for (let i = 0; i < this.sourceTableColumns.length; i++) {
            const element = this.sourceTableColumns[i];
            this.allColumns.push(element.displayName);
            columnsData.push({
                icon: undefined,
                id: element.name,
                name: element.displayName,
                options: undefined,
                type: element.type,
                hide: false,
            });
            this.selectedColumns.splice(0);
            columnsData
                .filter((column, index) => index <= 3)
                .forEach((column) => {
                    this.selectedColumns.push(column.id);
                });
        }
        this.allTableColumns.next(columnsData);
    };

    buildData = (sourceParts) => {
        const tableData: TableData[] = [];
        sourceParts &&
            sourceParts.forEach((record) => {
                const allColumns = this.allTableColumns.value;
                const recordData: TableData = {
                    recordId: this._utils.guid(),
                };
                allColumns.forEach((column) => {
                    const fieldValue = this.getFieldValue(column.name, this.sourceTableColumns, record);

                    recordData[column.id] = fieldValue;
                });
                tableData.push(recordData);
            });
        this.tableData.next(tableData);
    };

    onRecordSelect = (data) => {
        const record: any = {};
        for (let j = 0; j < this.allColumns.length; j++) {
            const field = this.getField(this.allColumns[j], this.sourceTableColumns);
            record[field] = data[this.allColumns[j]];
        }
        this.selectedRecord.emit(record);
    };

    DownloadSourceTemplate = (data) => {
        this.downloadSourceTemplateUrl.emit(data);
    };

    getFieldValue = (displayValue, roleColumns, role) => {
        let fieldValue = '';
        for (let i = 0; i < roleColumns.length; i++) {
            if (displayValue === roleColumns[i].displayName) {
                fieldValue = role[roleColumns[i].name];
                break;
            }
        }
        return fieldValue;
    };

    getField = (displayValue, roleColumns) => {
        let field = '';
        for (let i = 0; i < roleColumns.length; i++) {
            if (displayValue === roleColumns[i].displayName) {
                field = roleColumns[i].name;
                break;
            }
        }
        return field;
    };

    filesChanged = (record) => {
        const index = this.tableData.getValue().findIndex((dataRecord) => dataRecord.id === record.id);
        const sources = this.sourceParts.value;
        for (let i = 0; i < sources.length; i++) {
            if (sources[i].name === record['name']) {
                for (const key in record) {
                    if (record.hasOwnProperty(key)) {
                        sources[i][key] = record[key];
                    }
                }
            }
        }
        this.isFileUploaded.emit(record);
        this.tableData.getValue().splice(index, 1, record);
    };

    ngOnInit() {
        this.buildDataObjectForTable();
        this.sourcePartsSubscription = this.sourceParts.subscribe((sources) => {
            this.buildData(sources);
        });
    }

    ngOnDestroy() {
        this.sourcePartsSubscription && this.sourcePartsSubscription.unsubscribe();
    }
}
