import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { GetAppTemplate } from '../../store/actions';
import { getAppDisplayName$ } from '../../store/selectors';

@Component({
    selector: 'app-display-name',
    templateUrl: './app-display-name.component.html',
    styleUrls: ['./app-display-name.component.scss'],
})
export class AppDisplayNameComponent implements OnInit, OnDestroy {
    @Input() serviceId: string;

    private unSubscribe = new Subject<void>();
    public displayName: string;

    constructor(private store$: Store) {}

    ngOnInit(): void {
        this.store$.dispatch(
            GetAppTemplate({
                serviceId: this.serviceId,
                noAlerts: true,
            })
        );
        this.store$
            .select(getAppDisplayName$(this.serviceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.displayName = event;
            });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
