import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { AssetService } from '../../../models/assetservice.class';
import { RecordField } from '../../../models/record/recordfield.class';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { UtilsService } from '../../../services/utils/utils.service';

@Component({
    selector: 'material-group-checkbox',
    templateUrl: './material-group-checkbox.component.html',
    styleUrls: ['./material-group-checkbox.component.scss'],
})
export class MaterialGroupCheckboxComponent implements OnChanges, OnInit {
    @Input() menuName: string;
    @Input() classList: string[];
    @Input() options: any[];
    @Input() value: any;
    @Input() optionDisplayNameProperty?: string;
    @Input() optionValueProperty?: string;
    @Input() errors?: string[];
    @Input() warnings?: string[];
    @Input() required?: boolean;
    @Input() hint?: any;
    @Input() description?: string;
    @Input() disabled?: boolean;
    @Input() field: RecordField;
    @Input() conditionalValue: string;
    @Input() currentApp;
    @Input() currentBridge;
    @Input() dontShowValue: boolean;

    @Output() onOptionsChanged: EventEmitter<string[]> = new EventEmitter();

    guid: string;
    checkboxOptions: { displayName: string; value: string; isSelected: boolean }[] = [];
    searchOptions = '';
    public optionsSelected: string[] = [];

    constructor(private _utils: UtilsService, private _lookUps: LookupsService) {
        this.guid = this._utils.guid(4);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.constructOptionsModal();
        if (changes.options || changes.value) {
            this.pushDisplayNamesToSelectedOptions();
        }
        if (this.field) {
            if (changes.conditionalValue) {
                if (!changes.conditionalValue.firstChange) {
                    this.value = [];
                    this.onOptionsChanged.emit(this.value);
                    this.fetchOptionsByCondition();
                } else if (
                    changes.conditionalValue &&
                    changes.conditionalValue.currentValue &&
                    changes.conditionalValue.currentValue.length > 0
                ) {
                    this.fetchOptionsByCondition();
                }
            }
        }
    }

    pushDisplayNamesToSelectedOptions = () => {
        this.optionsSelected = [];
        this.options.reduce((options, option) => {
            (option.isSelected || this.value?.indexOf?.(option[this.optionValueProperty || 'id']) > -1) &&
                options.push(option[this.optionDisplayNameProperty || 'displayName']);
            return options;
        }, this.optionsSelected);
    };

    fetchOptionsByCondition = () => {
        let service: AssetService;
        service = this.currentApp;
        if (this.currentBridge?.restApiName) {
            service.assetMetaUId = service.id;
        }
        if (this.field instanceof RecordField) {
            this._lookUps.fetchLookupValuesByRecordField(
                {
                    conditionalValue: this.conditionalValue,
                    field: this.field,
                    service,
                    gridCellIndex: undefined,
                },
                {
                    successCallback: (lookupValues) => {
                        if (this.field instanceof RecordField) {
                            this.field.setLookupValues(lookupValues);
                            this.options = this.field.lookupValues;
                            this.constructOptionsModal();
                        } else {
                            const response = lookupValues && lookupValues.displayVsValue;
                            this.options = [];
                            response &&
                                Object.keys(response).forEach((key) => {
                                    this.options.push({
                                        displayName: key,
                                        value: response[key],
                                    });
                                });
                            this.constructOptionsModal();
                        }
                    },
                }
            );
        } else {
            this._lookUps.fetchLookupValuesByField(
                {
                    conditionalValue: this.conditionalValue,
                    field: this.field,
                    service,
                    gridCellIndex: undefined,
                },
                {
                    successCallback: (lookupValues) => {
                        if (this.field instanceof RecordField) {
                            this.field.setLookupValues(lookupValues);
                            this.options = this.field.lookupValues;
                            this.constructOptionsModal();
                        } else {
                            const response = lookupValues && lookupValues.displayVsValue;
                            this.options = [];
                            response &&
                                Object.keys(response).forEach((key) => {
                                    this.options.push({
                                        displayName: key,
                                        value: response[key],
                                    });
                                });
                            this.constructOptionsModal();
                        }
                    },
                }
            );
        }
    };

    private constructOptionsModal = () => {
        if (!this.value) {
            this.value = [];
        }
        this.checkboxOptions = [];
        (this.options || []).forEach((option) => {
            const value = this.optionValueProperty !== undefined ? option[this.optionValueProperty] : option;
            const displayName = this.optionDisplayNameProperty !== undefined ? option[this.optionDisplayNameProperty] : option;
            this.checkboxOptions.push({
                displayName,
                value,
                isSelected: this.value.indexOf(value) > -1,
            });
        });
    };

    changedValue = (value: string, isSelected: boolean) => {
        const valueIndex = this.value.indexOf(value);
        if (isSelected) {
            if (valueIndex > -1) {
                /** Do Nothing */
            } else {
                this.value.push(value);
                this.onOptionsChanged.emit(this.value);
            }
        } else {
            if (valueIndex > -1) {
                this.value.splice(valueIndex, 1);
                this.onOptionsChanged.emit(this.value);
            } else {
                /** Do Nothing */
            }
        }
        this.errors?.splice(0);
        this.warnings?.splice(0);
        this.hint = undefined;
        this.pushDisplayNamesToSelectedOptions();
    };

    trackByIndexMethod(index: number, item: any): any {
        return index;
    }

    ngOnInit() {
        if (!this.classList) {
            this.classList = [];
        }
        this.classList.push('groupCheckboxContainer');
    }
}
