import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';
import { CommonUtilsService } from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class RootScope {
    loadingCalls = [];
    session: BehaviorSubject<boolean> = new BehaviorSubject(this._commonUtils.getFromStorage('session') || undefined);
    LOGO_HREF = environment.logoHref;
    PLATFORM = environment.envName;
    SHOWMARQUEE = environment.showMarquee;
    MARQUEETEXT = environment.marqueeText;
    orientation = '';
    device = '';
    responsive = '';
    loadingCall = {
        subscribedServices: undefined,
        getMasters: undefined,
        getParentOrgDetails: undefined,
        activeMessagesCount: undefined,
        subscriptionsSearch: undefined,
        widgetSubscriptionsSearch: undefined,
        allAssetsSearch: undefined,
    };
    loggingIn = false;

    constructor(private _commonUtils: CommonUtilsService) {}

    clearRootScope = () => {
        this.loadingCalls = [];
        this.session.next(undefined);
        this.loadingCall = {
            subscribedServices: undefined,
            getMasters: undefined,
            getParentOrgDetails: undefined,
            activeMessagesCount: undefined,
            subscriptionsSearch: undefined,
            widgetSubscriptionsSearch: undefined,
            allAssetsSearch: undefined,
        };
    };
}
