import { Component, OnDestroy, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, delay, filter, Subject, takeUntil } from 'rxjs';
import {
    AlertError,
    AlertSuccess,
    AssetData,
    CommonUtilsService,
    CreateBulkReportsDownloadRequest,
    CreateReportRequest,
    DeleteSearchCriteria,
    DownloadReportRequest,
    FilterCriteria,
    GetAllTransformations,
    getAppMetaData$,
    GetAppSearchFilters,
    GetFilingInstances,
    getFilingProcessStatuses$,
    getNotifications$,
    GetOutboundChains,
    getOutboundChains$,
    getOutboundTransformationsMap$,
    getSearchAppFilters$,
    getSearchDefaultFilters$,
    LoadingBulkReportsError,
    RecordField,
    SavedSearchCriteria,
    SaveSearchCriteria,
    SearchCriteria,
    SetBulkReportsDownloadRequest,
    UtilsService,
} from 'taxilla-library';

import { SavedSearchOption } from '../../interface/savedsearchoption.interface';
import { Search } from '../../models/search.class';

@Component({
    selector: 'app-search-modal',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss'],
})
export class SearchModalComponent implements OnInit, OnDestroy {
    public selectedOutlookFilter: string;
    private unSubscribe = new Subject<void>();
    public outlookSearchFilters = [
        {
            name: 'searchFilters',
            displayName: 'Search Filters',
        },
        {
            name: 'processStatus',
            displayName: 'Process Status',
        },
        {
            name: 'customSearch',
            displayName: 'Custom Search',
        },
        {
            name: 'filterByTime',
            displayName: 'Filter By Date',
        },
    ];
    public searchFilters = [];
    public selectedSearchFilter: string | SavedSearchOption;
    public selectAssetsName = '';
    public savedSearchOptions: SavedSearchOption[] = [];
    public selectedSearchOption = '';
    public selectedProcessFilter: any;
    public inputProcessFilter = '';
    public selectedTab: number;
    public searchBuilder: Search;
    public saveFilterName: string;
    public timeFilter = {
        startTime: null,
        startDate: null,
        endTime: null,
        endDate: null,
    };
    public processStatuses: string[] = [];
    public serviceId: string;
    private defaultSearchFilters: {
        'default-filters': string[];
        filterCriterias: {
            [property: string]: {
                search: FilterCriteria;
                sort: Search['sortCriteria'];
            };
        };
    };
    private isCustomSearchDownloadReport: boolean;
    public isCompleteInstanceReport: boolean;
    public checkMultiple: boolean;
    public reportMetadata = [];
    public bulkTransformationsList = [];
    public selectedReportTransformation: any;
    public downloadReportTransformations = [];
    public otpApp: boolean;
    private isGetFilingInstancesCalled = false;
    private isSavedFilterViewed: any;
    private selectedDefaultFilter: null;
    public checkCompleteInstanceReport: boolean;
    private downloadReportFilter: { criteria: any };
    private appMetaData: AssetData;
    public loadingBulkReports: boolean;
    private bulkReportNotificationsSubscription: any;
    public downloadErrorRepositoryId: string;
    public searchFilter: any;
    public applySearch: (
        criteria: SearchCriteria,
        typeofCriteria: {
            timeFilter: SearchModalComponent['timeFilter'];
            type: string;
            filterName: string;
            status: string;
        }
    ) => void;
    public hideStatusFilters = false;

    constructor(
        private store$: Store,
        private _commonUtils: CommonUtilsService,
        private _libUtils: UtilsService,
        private actions$: Actions
    ) {}

    public selectedOutlookFilterChange = (triggerOrigin?: any) => {
        if (this.selectedOutlookFilter === 'customSearch') {
            if (triggerOrigin === 'downloadReportWithSavedFilter') {
                this.downloadSearchReport();
            } else {
                this.selectedTab = 0;
                if (!this.isSavedFilterViewed) {
                    this.saveFilterName = '';
                }
            }
        }
        this.selectedReportTransformation = '';
        this.bulkTransformationsList = [];
        this.checkMultiple = false;
        this.checkCompleteInstanceReport = false;
    };

    private downloadSearchReport = () => {
        this.selectedTab = 2;
        this.checkMultiple = false;
        this.checkCompleteInstanceReport = false;
        this.bulkTransformationsList = [];
    };

    public editSaveFilter = (filter: any) => {
        if (typeof filter === 'string' && this.defaultSearchFilters.filterCriterias[filter]) {
            const criteria = this.defaultSearchFilters.filterCriterias[filter];
            this.isSavedFilterViewed = true;
            this.saveFilterName = filter;
            const search = (criteria?.['search'] || criteria) as FilterCriteria;
            const sort = criteria?.['sort'];
            this.searchBuilder.inEditFilterMode = true;
            this.searchBuilder.setCriterias(search, sort);
            this.searchBuilder.setSource(search?.entityFilterCriterias?.[0]?.entityUid);
        } else if (!filter?.criteria) {
            this.saveFilterName = '';
        } else {
            this.isSavedFilterViewed = true;
            this.saveFilterName = filter.filterName;
            this.searchFilter = filter;
            const criteria = typeof filter.criteria === 'string' ? JSON.parse(filter.criteria) : filter.criteria;
            const search = criteria?.search || criteria;
            const sort = criteria?.sort;
            this.searchBuilder.inEditFilterMode = true;
            this.searchBuilder.setCriterias(search, sort);
            this.searchBuilder.setSource(search?.entityFilterCriterias?.[0]?.entityUid);
        }
        this.selectedOutlookFilter = 'customSearch';
        this.selectedOutlookFilterChange('editSaveFilter');
    };

    public downloadReportWithSavedFilter = (selectedSavedFilter: any) => {
        let filterCriteria: { criteria: any };
        if (typeof selectedSavedFilter === 'string') {
            const criteria = this.searchFilters.find((criteria) => criteria['default-filters']?.includes(selectedSavedFilter));
            criteria?.['filterCriterias']?.[selectedSavedFilter] &&
                (filterCriteria = {
                    criteria: criteria['filterCriterias'][selectedSavedFilter],
                });
        } else {
            filterCriteria = CommonUtilsService.cloneObject(selectedSavedFilter);
        }
        if (typeof filterCriteria?.criteria === 'string') {
            filterCriteria.criteria = JSON.parse(filterCriteria.criteria);
        }
        if (typeof filterCriteria?.criteria?.query === 'string') {
            filterCriteria.criteria.query = JSON.parse(filterCriteria.criteria.query);
        }
        const previousCriterias =
            filterCriteria?.criteria?.filterCriteria ||
            filterCriteria?.criteria?.query?.filterCriteria ||
            filterCriteria?.criteria?.search ||
            (filterCriteria?.criteria?.entityFilterCriterias && filterCriteria?.criteria);
        const previousSort = filterCriteria?.criteria?.sort || filterCriteria?.criteria?.query?.sort;
        this.searchBuilder.setCriterias(previousCriterias, previousSort);
        this.selectedOutlookFilter = this.outlookSearchFilters[1]?.name;
        this.selectedOutlookFilterChange('downloadReportWithSavedFilter');
        if (selectedSavedFilter?.criteria) {
            this.downloadReportFilter = filterCriteria;
            this.selectedDefaultFilter = undefined;
        } else {
            this.downloadReportFilter = undefined;
            this.selectedDefaultFilter = selectedSavedFilter;
        }
    };

    public deleteSaveFilter = (filter: SavedSearchOption) => {
        if (filter?.id === (this.selectedSearchFilter as SavedSearchOption)?.id || filter === this.selectedSearchFilter) {
            this.selectedSearchFilter = undefined;
        }
        if (filter?.id === (this.searchFilter as SavedSearchOption)?.id || filter === this.searchFilter) {
            this.searchFilter = undefined;
        }
        this.store$.dispatch(
            DeleteSearchCriteria({
                id: filter.id,
                serviceId: this.serviceId,
            })
        );
    };

    public processSearchFilter() {
        const processFilter = this.processStatuses?.filter((status) => status.includes(this.inputProcessFilter));
        return processFilter;
    }

    public selectedTabChange = (tabChangeEvent: { index: number }) => {
        this.selectedTab = tabChangeEvent.index;
    };

    private checkForExistingFilter = (): { hasDefaultFilter: boolean | undefined; hasSavedFilter: boolean } => {
        const defaultFilters: object | undefined = this.searchFilters.find((searchFilter) => searchFilter['default-filters']);
        const searchFilters: SavedSearchOption[] = this.searchFilters.filter((searchFilter) => !searchFilter['default-filters']);
        const hasDefaultFilter = defaultFilters?.['default-filters'].includes(this.saveFilterName.toLowerCase());
        const hasSavedFilter = searchFilters.some(
            (searchFilter) => searchFilter.filterName.toLowerCase() === this.saveFilterName.toLowerCase()
        );
        return { hasDefaultFilter, hasSavedFilter };
    };

    public saveAndSearchWithFilter = () => {
        const { hasDefaultFilter, hasSavedFilter } = this.checkForExistingFilter();
        if ((hasSavedFilter || hasDefaultFilter) && !this.isSavedFilterViewed) {
            this._libUtils.alertError(`${translate('Filter')} ${this.saveFilterName} ${translate('Already Exists')}`);
            return;
        }
        if (!this.searchBuilder.buildCriterias(true)) {
            this._libUtils.alertNotify(translate('Create Search Criteria From Rules Tab'));
        } else if (this.saveFilterName.trim().length === 0) {
            this._libUtils.alertError(translate('Enter valid filter name'));
        } else {
            const criteria = {
                search: this.searchBuilder.optimizeCriteria(),
                sort: this.searchBuilder.buildSortCriteria(),
            };
            this.store$.dispatch(
                SaveSearchCriteria({
                    criteria: JSON.stringify(criteria),
                    filterName: this.saveFilterName,
                    serviceId: this.serviceId,
                    id: this.searchFilter?.id,
                })
            );
        }
    };

    public applyDefaultSearchFilter = () => {
        if (typeof this.selectedSearchFilter === 'object') {
            const criteria = JSON.parse((this.selectedSearchFilter as any).criteria);
            const filterCriteria = criteria?.search || criteria;
            const sort = criteria.sort;
            this.applySearch(
                {
                    entityId: this.appMetaData?.getPrimaryEntity()?.uid,
                    filterCriteria,
                    sort,
                    restApiServiceName: this.appMetaData?.restAPIName,
                    size: undefined,
                },
                {
                    filterName: this.selectedSearchFilter as any,
                    status: undefined,
                    timeFilter: undefined,
                    type: 'searchFilters',
                }
            );
        } else {
            const searchFilter = this.defaultSearchFilters?.filterCriterias[this.selectedSearchFilter];
            this.applySearch(
                {
                    entityId: this.appMetaData?.getPrimaryEntity()?.uid,
                    filterCriteria: (searchFilter?.search || searchFilter) as FilterCriteria,
                    restApiServiceName: this.appMetaData?.restAPIName,
                    size: undefined,
                },
                {
                    filterName: this.selectedSearchFilter,
                    status: undefined,
                    timeFilter: undefined,
                    type: 'searchFilters',
                }
            );
        }
    };

    public applySelectedProcessFilter = () => {
        let outputStatus = this.selectedProcessFilter;
        if (status === 'process cancelled') {
            outputStatus = 'request cancelled';
        }
        const statusData = {
            entityId: this.appMetaData?.getPrimaryEntity().uid,
            fields: [
                {
                    id: 'instanceStatus.raw',
                    operator: 'EQ',
                    value: outputStatus,
                    restrictionType: 'SingleRestrictionMetadata',
                    searchable: true,
                },
            ],
        };
        const criteria = this.generateQuery(statusData.entityId, statusData.fields as any);
        this.applySearch(criteria as any, {
            filterName: undefined,
            status: this.selectedProcessFilter,
            timeFilter: undefined,
            type: this.selectedOutlookFilter,
        });
    };

    public startNewSearch = (queryType) => {
        const criteria = this.searchBuilder.optimizeCriteria();
        this.applySearch(
            {
                entityId: this.appMetaData.getPrimaryEntity().uid,
                filterCriteria: criteria,
                restApiServiceName: this.appMetaData.restAPIName,
                size: undefined,
                sort: this.searchBuilder.sortCriteria,
            },
            {
                filterName: undefined,
                status: undefined,
                timeFilter: undefined,
                type: this.selectedOutlookFilter,
            }
        );
    };

    public resetFilterCriteria = () => {
        this.searchBuilder.setCriterias();
    };

    public searchByTimeRange = () => {
        const defaultStartTime = '12:00 AM';
        const defaultEndTime = '11:59 PM';
        const defaultStartTimeString = this.timeFilter?.startTime?.length > 0 ? undefined : 'startTime';
        const defaultEndTimeString = this.timeFilter?.endTime?.length > 0 ? undefined : 'endTime';
        const timeZoneOffsetInMinutes = CommonUtilsService.offsetTime || 0;
        const timeZoneInMilliseconds = this._commonUtils.convertMinutesToMilliseconds(timeZoneOffsetInMinutes);
        const convertedStartTime =
            (this._commonUtils.getEpochDateFormat(
                this.timeFilter?.startTime ?? defaultStartTime,
                this.timeFilter?.startDate,
                'IE',
                false,
                defaultStartTimeString
            ) as number) + timeZoneInMilliseconds;
        const convertedEndTime =
            (this._commonUtils.getEpochDateFormat(
                this.timeFilter?.endTime ?? defaultEndTime,
                this.timeFilter?.endDate,
                'IE',
                false,
                defaultEndTimeString
            ) as number) + timeZoneInMilliseconds;
        const newConvertedStartTime = convertedStartTime - new Date().getTimezoneOffset() * 1000 * 60;
        const newConvertedEndTime = convertedEndTime - new Date().getTimezoneOffset() * 1000 * 60;
        if (newConvertedEndTime < newConvertedStartTime) {
            this._libUtils.alertError(translate('Please enter valid time range'));
            return;
        }
        const fields = [
            {
                id: 'createdOn',
                operator: 'GTE',
                restrictionType: 'SingleRestrictionMetadata',
                value: newConvertedStartTime,
                searchable: true,
            },
            {
                id: 'createdOn',
                operator: 'LTE',
                restrictionType: 'SingleRestrictionMetadata',
                value: newConvertedEndTime,
                searchable: true,
            },
        ];
        const criteria = this.generateQuery(this.appMetaData.getPrimaryEntity().uid, fields as any);
        const criteriaRelations = {
            restrictionType: 'JoinedRestrictionMetadata',
        };
        criteria.filterCriteria['criteriaRelations'] = criteriaRelations;
        this.applySearch(criteria as SearchCriteria, {
            filterName: undefined,
            timeFilter: this.timeFilter,
            status: undefined,
            type: this.selectedOutlookFilter,
        });
    };

    private generateQuery = (entityId: string, fields: RecordField[]) => {
        const criteria: {
            restApiServiceName: string;
            entityId: string;
            filterCriteria: {
                serviceId: string;
                entityFilterCriterias: {
                    entityUid: string;
                    criteriaDefinitions: {
                        criteriaName: string;
                        type: string;
                        restriction: {
                            condition: string;
                            rules: {
                                fieldId: string;
                                operator: string;
                                value: string | number | string[];
                                restrictionType: 'SingleRestrictionMetadata';
                            }[];
                            restrictionType: 'JoinedRestrictionMetadata';
                        };
                    }[];
                }[];
            };
        } = {
            restApiServiceName: this.appMetaData.restAPIName,
            entityId,
            filterCriteria: {
                serviceId: this.serviceId,
                entityFilterCriterias: [],
            },
        };
        this.addFilterCriteria(criteria.filterCriteria.entityFilterCriterias, entityId, fields);
        return criteria;
    };

    private addFilterCriteria = (
        criterias: {
            entityUid: string;
            criteriaDefinitions: {
                criteriaName: string;
                type: string;
                restriction: {
                    condition: string;
                    rules: {
                        fieldId: string;
                        operator: string;
                        value: string | number | string[];
                        restrictionType: 'SingleRestrictionMetadata';
                    }[];
                    restrictionType: 'JoinedRestrictionMetadata';
                };
            }[];
        }[],
        entityId: string,
        fields: RecordField[]
    ) => {
        const critera: any = {
            entityUid: entityId,
            criteriaDefinitions: [],
        };
        const condition = 'and';
        this.addCriteriaLevel(critera.criteriaDefinitions, entityId, condition, fields);
        criterias.push(critera);
    };

    private addCriteriaLevel = (
        criteriaDefinitions: {
            criteriaName: string;
            type: string;
            restriction: {
                condition: string;
                rules: {
                    fieldId: string;
                    operator: string;
                    value: string | number | string[];
                    restrictionType: 'SingleRestrictionMetadata';
                }[];
                restrictionType: 'JoinedRestrictionMetadata';
            };
        }[],
        entityId: string,
        ruleLayerType,
        fields
    ) => {
        const definition: any = {
            criteriaName: entityId,
            type: 'CriteriaDefinition',
        };
        this.checkRuleRestrictedType(definition, ruleLayerType, fields);
        criteriaDefinitions.push(definition);
    };

    private checkRuleRestrictedType = (
        criteriaDefinition: {
            criteriaName: string;
            type: string;
            restriction: {
                condition: string;
                rules: {
                    fieldId: string;
                    operator: string;
                    value: string | number | string[];
                    restrictionType: 'SingleRestrictionMetadata';
                }[];
                restrictionType: 'JoinedRestrictionMetadata';
            };
        },
        ruleCategory: 'terms' | 'must' | 'bool',
        fields: RecordField[],
        isFreeTextSearch?: boolean,
        operatorType?: string
    ) => {
        if (!criteriaDefinition.restriction) {
            criteriaDefinition.restriction = {
                condition: this.getRestrictionCondition(ruleCategory),
                rules: [],
                restrictionType: 'JoinedRestrictionMetadata',
            };
        }
        fields.forEach((field: RecordField) => {
            field.searchable &&
                this.pushRule(
                    criteriaDefinition.restriction.rules,
                    field.id,
                    (operatorType = field.operator ?? operatorType),
                    field.value,
                    isFreeTextSearch,
                    field.datatype
                );
        });
    };

    private getRestrictionCondition = (ruleCategory: 'terms' | 'must' | 'bool' | 'and' | 'or') => {
        let category: 'AND' | 'OR';
        switch (ruleCategory) {
            case 'bool':
            case 'terms':
            case 'or':
                category = 'OR';
                break;
            case 'must':
            case 'and':
                category = 'AND';
                break;
            default:
                throw new Error('Rule Category not found');
                break;
        }
        return category;
    };

    private pushRule = (
        rules: {
            fieldId: string;
            operator: string;
            value: RecordField['value'];
            restrictionType: 'SingleRestrictionMetadata';
        }[],
        fieldId: string,
        operatorType: string,
        value: RecordField['value'],
        isFreeTextSearch?: boolean,
        fieldDataType?: string
    ) => {
        if (!operatorType) {
            switch (typeof value) {
                case 'object':
                    operatorType = Array.isArray(value) ? 'IN' : 'EQ';
                    break;
                default:
                    operatorType = 'EQ';
                    break;
            }
        }
        if (isFreeTextSearch && fieldId !== 'instanceState.raw') {
            if (fieldDataType === 'INT' || fieldDataType === 'number' || fieldDataType === 'DOUBLE') {
                const transformedValue = +value;
                const maxNumber = Math.pow(2, 32) / 2;
                if (
                    typeof transformedValue !== 'number' ||
                    isNaN(transformedValue) ||
                    !(transformedValue >= 0 - maxNumber && transformedValue <= maxNumber - 1)
                ) {
                    return;
                }
                value = transformedValue;
                operatorType = 'EQ';
            } else {
                operatorType = 'CONTAINS';
                if (typeof value === 'string') {
                    value = value.trim();
                }
                value = '*' + value + '*';
            }
        }
        rules.push({
            fieldId,
            operator: operatorType,
            value,
            restrictionType: 'SingleRestrictionMetadata',
        });
    };

    public clearAllFilters = (fromAppChange?) => {
        this.clearFilterDate();
        this.clearSearchFilters();
        this.clearStatusFilters();
        this.resetFilterCriteria();
        this.applySearch(undefined, undefined);
    };

    private clearStatusFilters = () => {
        this.selectedProcessFilter = undefined;
    };

    private clearFilterDate = () => {
        this.timeFilter = {
            startTime: undefined,
            startDate: undefined,
            endTime: undefined,
            endDate: undefined,
        };
    };

    private clearSearchFilters = () => {
        this.selectedSearchFilter = undefined;
    };

    public createDownloadBulkRequest = () => {
        const criteria = {
            restApiServiceName: undefined,
        };
        if (this.downloadReportFilter?.criteria) {
            const filterCriteria =
                typeof this.downloadReportFilter.criteria === 'string'
                    ? JSON.parse(this.downloadReportFilter.criteria)
                    : this.downloadReportFilter.criteria;
            criteria['filterCriteria'] = filterCriteria?.search || filterCriteria;
            const outPutSources = this.searchBuilder.getOutputSources(this.appMetaData.entities, criteria['filterCriteria']);
            criteria['filterCriteria'].outputEntities = outPutSources;
        } else {
            criteria['filterCriteria'] = this.searchBuilder.optimizeCriteria(); /* ngrx chunk */
        }
        this.store$.dispatch(
            CreateBulkReportsDownloadRequest({
                serviceId: this.serviceId,
                transformationNames: this.bulkTransformationsList,
                criteria: CommonUtilsService.cloneObject(criteria),
            })
        );
        this.loadingBulkReports = true;
    };

    public initiateDownloadReport = (event) => {
        const criteria = this.searchBuilder.optimizeCriteria();
        const primaryEntityId = this.appMetaData?.getPrimaryEntity()?.uid;
        if (criteria?.requestId) {
            this.searchBuilder.pushRule(
                {
                    fieldId: 'requestId',
                    operator: 'EQ',
                    value: criteria.requestId,
                    restrictionType: 'SingleRestrictionMetadata',
                },
                criteria,
                primaryEntityId
            );
        }
        const payload: any = {
            assetName: this.appMetaData.name,
            restApiServiceName: undefined,
            reportName: this.selectedReportTransformation.name,
        };
        if (!this.selectedDefaultFilter) {
            if (this.downloadReportFilter?.criteria) {
                let downloadCriteria: any = this.downloadReportFilter.criteria;
                if (typeof downloadCriteria === 'string') {
                    downloadCriteria = JSON.parse(downloadCriteria);
                }
                const searchCriteria = downloadCriteria?.search || (downloadCriteria.entityFilterCriterias && downloadCriteria);
                const sort = downloadCriteria?.sort;
                payload['filterCriteria'] = searchCriteria;
                payload['sort'] = sort;
            } else {
                payload['filterCriteria'] = criteria;
            }
            payload['filterCriteria'].outputEntities = this.searchBuilder.getOutputSources(
                this.appMetaData.entities,
                payload['filterCriteria']
            );
        } else {
            payload['defaultFilterName'] = this.selectedDefaultFilter;
        }
        if (this.checkCompleteInstanceReport) {
            payload['generateCompleteInstanceReport'] = true;
        }
        this.store$.dispatch(
            CreateReportRequest({
                serviceId: this.serviceId,
                reportName: CommonUtilsService.cloneObject(payload.reportName),
                repositoryId: this.downloadErrorRepositoryId,
                defaultFilterName: CommonUtilsService.cloneObject(payload['defaultFilterName']),
                filterCriteria: CommonUtilsService.cloneObject(payload['filterCriteria']),
                generateCompleteInstanceReport: this.checkCompleteInstanceReport,
            })
        );
        this.loadingBulkReports = true;
    };

    public getProcessStatuses = (isSelectOpen: boolean) => {
        if (isSelectOpen && !this.isGetFilingInstancesCalled) {
            this.store$.dispatch(
                GetFilingInstances({ serviceId: this.serviceId, noAlerts: true, timelineContextNotRequired: !!this.otpApp })
            );
            this.isGetFilingInstancesCalled = true;
        }
    };

    private subscribeToBulkReportNotifications = (requestId: string) => {
        this.store$.dispatch(AlertSuccess({ message: translate('Download report request initiated') }));
        this.bulkReportNotificationsSubscription?.unsubscribe();
        this.bulkReportNotificationsSubscription = this.store$
            .select(getNotifications$(requestId))
            .pipe(takeUntil(this.unSubscribe), delay(1200))
            .subscribe((logs) => {
                if (!this.loadingBulkReports) {
                    return;
                }
                const event = logs?.find((log) =>
                    [
                        'BULK_REPORTS_DOWNLOAD_COMPLETED',
                        'BULK_REPORTS_DOWNLOAD_TERMINATED',
                        'OUTBOUND_TRANSFORMATION_COMPLETED',
                        'REQUEST_TERMINATED',
                    ].includes(log.eventType)
                );
                switch (event?.eventType) {
                    case 'BULK_REPORTS_DOWNLOAD_COMPLETED':
                    case 'OUTBOUND_TRANSFORMATION_COMPLETED':
                        this.store$.dispatch(AlertSuccess({ message: `${event?.payload?.reportName || 'Report'} downloaded` }));
                        this.loadingBulkReports = false;
                        this.bulkReportNotificationsSubscription?.unsubscribe();
                        break;
                    case 'BULK_REPORTS_DOWNLOAD_TERMINATED':
                    case 'REQUEST_TERMINATED':
                        this.store$.dispatch(AlertError({ message: event?.payload?.errorStatus || event?.payload?.errorMessage }));
                        this.loadingBulkReports = false;
                        break;
                }
            });
    };

    private initiateSubscriptions = () => {
        combineLatest([
            this.store$.select(getSearchAppFilters$(this.serviceId)),
            this.store$.select(getSearchDefaultFilters$(this.serviceId)),
        ])
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(([savedFilters, defaultFilters]) => {
                this.searchFilters = [];
                if (defaultFilters?.['default-filters']?.length > 0) {
                    this.searchFilters.push(defaultFilters);
                }
                this.searchFilters.push(...(savedFilters || []));
                this.defaultSearchFilters = defaultFilters as any;
            });
        this.store$
            .select(getOutboundTransformationsMap$(this.serviceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.reportMetadata = event;
            });
        this.store$
            .select(getFilingProcessStatuses$(this.serviceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                if (this.isGetFilingInstancesCalled) {
                    this.processStatuses = event;
                }
            });
        this.store$
            .select(getOutboundChains$(this.serviceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.downloadReportTransformations = event;
            });
        this.store$
            .select(getAppMetaData$(this.serviceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.appMetaData = event;
            });
        this.actions$
            .pipe(
                ofType(SetBulkReportsDownloadRequest),
                takeUntil(this.unSubscribe),
                filter((action) => action.serviceId === this.serviceId),
                delay(100)
            )
            .subscribe((res) => {
                this.loadingBulkReports = true;
                const requestId = res?.requestId;
                this.subscribeToBulkReportNotifications(requestId);
            });
        this.actions$
            .pipe(
                ofType(DownloadReportRequest),
                takeUntil(this.unSubscribe),
                delay(100),
                filter((action) => action.serviceId === this.serviceId)
            )
            .subscribe((res) => {
                this.loadingBulkReports = true;
                const requestId = res?.requestId;
                this.subscribeToBulkReportNotifications(requestId);
            });
        this.actions$
            .pipe(
                ofType(SavedSearchCriteria),
                takeUntil(this.unSubscribe),
                delay(100),
                filter((action) => action.serviceId === this.serviceId)
            )
            .subscribe((res) => {
                this.selectedSearchFilter = res?.criteria as any;
                this.applyDefaultSearchFilter();
            });
        this.actions$
            .pipe(ofType(LoadingBulkReportsError), takeUntil(this.unSubscribe), delay(100))
            .subscribe(() => (this.loadingBulkReports = false));
    };

    ngOnInit(): void {
        this.hideStatusFilters &&
            (this.outlookSearchFilters = this.outlookSearchFilters.filter((filter) => filter.name !== 'processStatus'));
        this.processStatuses = undefined;
        this.selectedOutlookFilter = this.selectedOutlookFilter || CommonUtilsService.cloneObject(this.outlookSearchFilters[0])?.name;
        if (this.selectedOutlookFilter === 'processStatus') {
            this.getProcessStatuses(true);
        }
        this.initiateSubscriptions();
        this.store$.dispatch(GetAppSearchFilters({ serviceId: this.serviceId }));
        this.store$.dispatch(GetOutboundChains({ serviceId: this.serviceId }));
        this.store$.dispatch(GetAllTransformations({ serviceId: this.serviceId, forceFetch: false }));
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.processStatuses = undefined;
    }
}
