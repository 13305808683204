import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: true,
})
export class OrderByPipe implements PipeTransform {
    transform<T>(array: T[], field: keyof T): T[] | undefined {
        if (!Array.isArray(array)) {
            return undefined;
        }
        return array.sort((a, b) => {
            const aValue = (a[field] ?? '').toString().toLowerCase();
            const bValue = (b[field] ?? '').toString().toLowerCase();

            return aValue.localeCompare(bValue);
        });
    }
}
