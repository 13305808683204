export class Permission {
    actionName: string;

    displayName: string;

    uuid: string;

    id: string;

    objectId: string;

    selected?: boolean;

    permissionType: string;

    category: string;

    idm?: boolean;

    constructor(permissionObject) {
        this.actionName = permissionObject?.actionName;
        this.displayName = permissionObject?.displayName;
        this.uuid = permissionObject?.uuid;
        this.id = permissionObject?.id;
        this.objectId = permissionObject?.objectId;
        this.permissionType = permissionObject?.permissionType;
        this.category = 'CUSTOMER';
        this.idm = permissionObject?.idm;
    }
}
