import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
    @Input() title: any;
    @Input() message: any;
    @Input() btnOkText: any;
    @Input() btnCancelText: any;

    constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<ConfirmationComponent>) {}

    ngOnInit() {}

    public decline() {
        this.dialogRef.close(false);
    }

    public accept() {
        this.dialogRef.close(true);
    }

    public dismiss() {
        this.dialog.closeAll();
    }
}
