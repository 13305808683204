<div class="manageroles" [ngClass]="{'bundleScroll': bundleId}">
    <div class="rolesMainDiv">
        <mat-drawer-container class="drawerContainer" autosize>
            <mat-drawer #rolesDrawer class="roleMatDrawer manageRoleMatDrawer" mode="side" disableClose position='end'>
                <div class="rolecls">
                    <div class="rolesWidget userMainTableConatiner" [hidden]="!isViewRole && !updateRole">
                        <div class="settingsHeader" *transloco="let translate;">
                            <span *ngIf="isAddRole && !isViewRole" class="settingsNameText">{{translate('Create Role')}}</span>
                            <span *ngIf="updateRole && !isViewRole && !isAddRole" class="settingsNameText">{{translate('Update Role')}}: {{rolesObject?.roleName}}</span>
                            <span *ngIf="isViewRole && !updateRole && !isAddRole" class="settingsNameText">{{translate('Role Details')}}: {{rolesObject?.roleName}}</span>
                            <div class="settingsDataActionsContainer">
                                <mat-icon title="{{translate('Close')}}" class="closeIcon" aria-hidden="true" (click)="cancelView()">cancel</mat-icon>
                            </div>
                        </div>
                        <perfect-scrollbar class="materialStepperDiv position-relative" [config]="{suppressScrollY: bundleId ? true : false}">
                            <mat-horizontal-stepper labelPosition="bottom" #rolesStepper linear>
                                <ng-container *transloco="let translate;">
                                    <ng-template matStepperIcon="edit" let-index="index">
                                    </ng-template>
                                    <mat-step [label]="roleLabel">
                                        <div class="addRoleContainer">
                                            <role-fields [rolesObject]="rolesObject" [errors]="errors"></role-fields>
                                        </div>
                                        <div class="floatRight" [hidden]="bundleId">
                                            <div class="displyInlineBlock cancelButtonDiv">
                                                <button mat-button mat-raised-button class="cancelButton" (click)="cancelView()">{{translate('Cancel')}}</button>
                                            </div>
                                            <div class="displyInlineBlock buttonsDiv">
                                                <button mat-button mat-raised-button class="nextButton" (click)="validateRoleDetails()">{{translate('Next: Assign Permissions')}}</button>
                                            </div>
                                        </div>
                                    </mat-step>
                                    <mat-step>
                                        <ng-template matStepLabel>{{translate('Assign Permissions')}}</ng-template>
                                        <div class="rolePermissionsDiv" *ngIf="totalPermissions">
                                            <role-permissions [rolePermissionsData]="totalPermissions" [isCheckboxNeeded]="true" [isNextClicked]="isNextClicked"></role-permissions>
                                        </div>
                                        <div [hidden]="bundleId">
                                            <div class="displyInlineBlock cancelButtonDiv">
                                                <button mat-button matStepperPrevious mat-raised-button class="nextButton">{{translate('Back')}}</button>
                                            </div>
                                            <div class="displyInlineBlock floatRight buttonsDiv">
                                                <button mat-button mat-raised-button class="nextButton" (click)="validateRolePermissions()">{{translate('Next: Review')}}</button>
                                            </div>
                                            <div class="displyInlineBlock cancelButtonDiv floatRight">
                                                <button mat-button mat-raised-button class="cancelButton" (click)="cancelView()">{{translate('Cancel')}}</button>
                                            </div>
                                        </div>
                                    </mat-step>
                                    <mat-step>
                                        <ng-template matStepLabel>{{translate('Review')}}</ng-template>
                                        <div class="roleReviewContainer">
                                            <div class="roleReviewHeader" *ngIf="updateRole && !isViewRole">
                                                <span class="displayBlock reviewCls">{{translate('Review Role Details')}}</span>
                                                <span class="displayBlock reviewNoteCls">
                                                    {{translate('Review your choices. After your create the role, you cannot remove it. In case of any changes you need to create another only.')}}'
                                                </span>
                                            </div>
                                            <div class="reviewRolerDetailsContainer">
                                                <div class="reviewRoleDetailsSection">
                                                    <ng-container *ngIf="isViewRole && rolesObject">
                                                        <div class="entityFieldContainer col-xs-3 col-3 wordBreak">
                                                            <div class="fieldInReadOnly">
                                                                <div class="readOnlyFieldValues">
                                                                    <div class="readOnlyLabel userViewLabel" title="Role Name">{{translate('Role Name')}} : </div>
                                                                    <div class="readOnlyValue" title="{{rolesObject.roleName}}"> {{rolesObject.roleName}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="entityFieldContainer col-xs-3 col-3 wordBreak">
                                                            <div class="fieldInReadOnly">
                                                                <div class="readOnlyFieldValues">
                                                                    <div class="readOnlyLabel userViewLabel" title="Display Name ">{{translate('Display Name')}} : </div>
                                                                    <div class="readOnlyValue" title="{{rolesObject.displayName}}"> {{rolesObject.displayName}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="entityFieldContainer col-xs-3 col-3 wordBreak">
                                                            <div class="fieldInReadOnly">
                                                                <div class="readOnlyFieldValues">
                                                                    <div class="readOnlyLabel userViewLabel" title="Description">{{translate('Description')}} : </div>
                                                                    <div class="readOnlyValue" title="{{rolesObject.description}}"> {{rolesObject.description}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="rolePermission" [hidden]="!totalReviewPermissions.value || totalReviewPermissions.value?.length === 0">
                                                    <div class="mt-4">
                                                        <role-permissions [rolePermissionsData]="totalReviewPermissions" [isCheckboxNeeded]="false">
                                                        </role-permissions>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="displyInlineBlock buttonsDiv" [hidden]="bundleId">
                                                <button mat-button matStepperPrevious mat-raised-button class="nextButton" *ngIf="!isViewRole">{{translate('Back')}}</button>
                                            </div>
                                            <div class="displyInlineBlock floatRight buttonsDiv" [hidden]="bundleId">
                                                <button mat-button mat-raised-button class="nextButton" (click)="submitData()" *ngIf="isAddRole && !isViewRole">{{translate('Create Role')}}</button>
                                                <button mat-button mat-raised-button class="nextButton" (click)="submitData()" *ngIf="updateRole && !isViewRole && !isAddRole">{{translate('Update Role')}}</button>
                                                <button mat-button mat-raised-button class="nextButton" (click)=" manageRoles(rolesObject,'edit','view')" *ngIf="isViewRole && !updateRole && rolesObject.roleName !== 'CUSTOMER_ADMIN' || rolesObject.roleName === 'PROVIDER_ADMIN'">{{translate('Edit Role')}}</button>
                                            </div>
                                            <div [hidden]="bundleId" class="displyInlineBlock buttonsDiv cancelButtonDiv floatRight">
                                                <button mat-button mat-raised-button class="cancelButton" (click)="cancelView()">{{translate('Cancel')}}</button>
                                            </div>
                                        </div>
                                    </mat-step>
                                </ng-container>
                            </mat-horizontal-stepper>
                        </perfect-scrollbar>
                    </div>
                    <ng-container *ngIf="copyRoleBol">
                        <div class="copyRoleFieldsContainer userMainTableConatiner" *transloco="let translate;">
                            <div class="settingsHeader">
                                <span class="settingsNameText">{{translate('Copy Role')}} : {{selectedRoleName}}</span>
                                <div class="settingsDataActionsContainer">
                                    <mat-icon title="{{translate('Close')}}" class="closeIcon" aria-hidden="true" (click)="cancelView()">cancel</mat-icon>
                                </div>
                            </div>
                            <div class="copyRoleDiv" style="height: calc(100vh - 118px);" [perfectScrollbar]>
                                <div *ngIf="locations && locations.length > 0" class="copyRoleInformation">
                                    <span>{{translate('Choose all the locations where you want to copy the role.')}}</span>
                                </div>
                                <div class="listOfOrgDiv">
                                    <div *ngIf="locations && locations.length > 0">
                                        <div class="selectLocDiv">
                                            <div class="displyInlineBlock">
                                                <span class="selectLocSpan">{{translate('Select Locations')}}:</span>
                                            </div>
                                            <div class="displyInlineBlock searchLocation">
                                                <mat-form-field>
                                                    <input matInput placeholder="{{translate('Search Location')}}" type="text" name="searchLocation" [(ngModel)]="searchLocation" (ngModelChange)="searchLocation = $event" (keyup)='searchRelatedLocations()'>
                                                    <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchLocations()'>
                                                        <mat-icon class='locationManagementIcons' *ngIf='searchLocation'>close </mat-icon>
                                                    </button>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="selectAllDiv" *ngIf="!noRelatedLocations">
                                            <mat-checkbox [(ngModel)]="allLocationsSelected" name="'selectAllLoc'" (change)="selectAllLoctions($event)" id="selectAllPartnerLoc">
                                            </mat-checkbox>
                                            <span>{{translate('Select All')}}</span>
                                        </div>
                                        <div class="row copyRoleSelectDropdwn">
                                            <div *ngFor="let location of locations | FilterPipe: 'name': searchLocation" class="col-4">
                                                <div class="form-check searchLocationsList">
                                                    <span class="form-check-label">
                                                        <ul>
                                                            <li class="rolePermissionClass">
                                                                <mat-checkbox class="selectbox" [(ngModel)]="location.selected" name="{{location.id}}" id="{{location.id}}" (change)="checkLocList()">{{location.name}}</mat-checkbox>
                                                            </li>
                                                        </ul>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf='searchLocation && noRelatedLocations'>
                                        <div class="d-flex justify-content-center noUsersFound">
                                            <span>{{translate('No locations found')}}</span>
                                        </div>
                                    </ng-container>
                                    <div class="noLocation" *ngIf="locations?.length === 0">
                                        <span>{{translate('No locations available')}}</span>
                                    </div>
                                    <div class="footerRole">
                                        <div class="displyInlineBlock floatRight buttonsDiv">
                                            <button mat-button mat-raised-button class="nextButton" (click)="SubmitCopyRole()" [disabled]="!formValid">{{translate('Copy Role')}}</button>
                                        </div>
                                        <div class="displyInlineBlock buttonsDiv cancelButtonDiv floatRight">
                                            <button mat-button mat-raised-button class="cancelButton" (click)="cancelCopyRole()">{{translate('Cancel')}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="assignUsersBol">
                        <app-users-list [selectedRoleName]="selectedRoleName" [errors]="{org:[], user:[]}" [partnerOrganizations]="partnerOrganizations" [users]="users" [selectedPartnerOrg]="selectedPartnerOrg" [allUsersSelected]="allUsersSelected" [formValid]="formValid" [noAssignUsers]="noAssignUsers" [searchUser]="searchUser" (searchUserChange)="searchUser = $event" (cancelView)="cancelView()" (selectAllUsers)="selectAllUsers($event)" (clearSearchUsers)="clearSearchUsers()" (assignUsersToLocations)="assignUsersToLocations()" (cancelAssignUsers)="cancelAssignUsers()" (checkUsersList)="checkUsersList()" (searchAssignUsers)="searchAssignUsers()" (selectedPartnerOrgChange)="getOrgUsers($event)"></app-users-list>
                    </ng-container>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <div class="rolesTableMainDiv">
                    <div class="rolesTableContainer userMainTableConatiner">
                        <div class="settingsHeader" *transloco="let translate;">
                            <span class="settingsNameText">{{translate('Manage Roles')}}</span>
                            <div class="settingsDataActionsContainer d-flex align-items-center">
                                <div class="outlookSearchContainer">
                                    <div class="outLookSearchHolder">
                                        <div class="align-items-center d-flex mainSearch">
                                            <div class="mainSearchSearcher searchBorder cursorPointer d-flex align-items-center justify-content-between">
                                                <span class="material-icons searchIcon">search</span>
                                                <input matInput type="text" [placeholder]="translate('Search')" [(ngModel)]="roleSearchValue" (keyup)='searchRole(roleSearchValue)'>
                                                <span class="material-icons searchIcon pointer" *ngIf='roleSearchValue' (click)='clearSearch()'>close</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-icon title="{{translate('Add Role')}}" class="addIcon addIconColor" aria-hidden="true" (click)="manageRoles(undefined,'create-new')">add_circle</mat-icon>
                            </div>
                        </div>
                        <div class="row m-0 w-100 rolesContainer">
                            <div class="col-12 ps position-relative" style="height: calc(100vh - 133px);" [perfectScrollbar]>
                                <div class="roleTable noMargin">
                                    <role-table [roles]="roleTable" [roleTableColumns]="roleTableColumns" [hidePaginator]="false" [hideTableColumns]="hideTableColumns" [primaryColumns]="primaryColumns" (copyOfSelectedRole)="copyRole($event)" (deleteOfSelectedROle)="deleteRole($event)" (selectedRecord)="manageRoles($event,'edit')" [tableNotAvailable]='true' [bundleId]="bundleId" (assignUsersToSelectedRole)="assignUsers($event)" [needRoleActions]="true" [defaultPaginator]="true" (viewRoleRecord)="manageRoles($event,'view')"></role-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
