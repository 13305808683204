import { Directive, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';
import { ApiService } from 'taxilla-library';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[AppProcessRequest]',
})
export class AppProcessRequestDirective implements OnInit, OnChanges {
    @Input() app: AssetService;
    @Input() bridge: AssetService;
    @Input() getDirectLink?: boolean;
    @Input() appId: string;
    @Input() bridgeId: string;
    @Input() assetMetaData: AssetData;
    @Input() assetInstance;
    @Input() requestId: string;
    @Input() report: BridgeNode;
    @Input() processInSameTab: boolean;
    @Input() appRestApiName: string;
    @Input() bridgeRestApiName: string;

    pollForAssetMetaData: any;

    constructor(
        private _api: ApiService,
        private _utils: UtilsService,
        protected _router: Router,
        private _commonUtils: CommonUtilsService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes.appId || changes.bridgeId) {
                this.startComponent();
            }
        }
    }

    @HostListener('click', ['$event'])
    onAppSelection = async (event?: Event) => {
        if (this.processInSameTab) {
            if (this.app.assetType === 'BRIDGE_ASSET') {
                return;
            }
            event && event.stopPropagation();
            await this.getAppTags();
            this.checkRouteBasedOnTag();
        } else if (this.getDirectLink && event) {
            if (!environment['enreport-ui']) {
                event && event.stopPropagation();
            }
        } else {
            if (this.app.assetType === 'BRIDGE_ASSET') {
                return;
            }
            event && event.stopPropagation();
            await this.getAppTags();
            this.checkRouteBasedOnTag();
        }
    };

    private getAppTags = (): Promise<AssetService['tags']> => {
        return new Promise((resolve, reject) => {
            this.waitTillLoadingCompleted(() => {
                if (this.report) {
                    resolve(undefined);
                } else if (this.app && this.app.tags) {
                    resolve(this.app.tags);
                } else if (this.app?.assetType === 'RECON') {
                    this.app.tags = [
                        {
                            tagKey: 'uiType',
                            tagValue: 'RECON',
                        },
                    ];
                    resolve(this.app.tags);
                } else {
                    const serviceId = this.bridge ? this.bridge.serviceId : this.app.serviceId;
                    this.app['loading'] && (this.app['loading']['assetMetaData'] = true);
                    this._api.assets.getAssetTags(
                        {
                            serviceId,
                            tagKey: 'uitype',
                            noAlerts: true,
                        },
                        {
                            successCallback: (response: {
                                taggedMetadatas: {
                                    metadataId: string;
                                    metadataName: string;
                                    metadataType: string;
                                    repositoryId: string;
                                    serviceId: string;
                                    tagKey: string;
                                    tagValue: string;
                                }[];
                            }) => {
                                setTimeout(() => {
                                    this.app['loading'] && (this.app['loading']['assetMetaData'] = false);
                                }, 10);
                                const tags: AssetService['tags'] = [];
                                response.taggedMetadatas.forEach((tagObject) => {
                                    tags.push(tagObject);
                                });
                                this.app.tags = tags;
                                resolve(tags);
                            },
                            failureCallback: (response) => {
                                this.app['loading'] && (this.app['loading']['assetMetaData'] = false);
                                this.app['requestLink'] = undefined;
                                this._utils.alertError(response && response.msg);
                            },
                        }
                    );
                }
            });
        });
    };

    waitTillLoadingCompleted = (callback) => {
        if (this.app['loading'] && this.app['loading']['assetMetaData']) {
            setTimeout(() => {
                this.waitTillLoadingCompleted(callback);
            }, 100);
        } else {
            callback();
        }
    };

    private checkRouteBasedOnTag = () => {
        const assetTags = this.app.tags || [];
        const tagValues = assetTags.reduce((list, tag) => {
            list.push(tag.tagValue);
            return list;
        }, []);
        this.setWindowRoute(tagValues);
    };

    private setWindowRoute = (tags: string[]) => {
        let found = false;
        const href = window.location.href;
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        if (this.app.assetType === 'RECON') {
            this.routeToUI('enreconcile');
        } else if (tags?.length > 0 && href.indexOf(enReport) === -1 && href.indexOf(enInvoice) === -1 && href.indexOf(enCollab) === -1) {
            tags.forEach((tag) => {
                if (found) {
                    return;
                }
                if (environment.restrictedProcessingUIs?.indexOf(tag.toLowerCase()) >= 0) {
                    tag = environment.defaultProcessingUI ? environment.defaultProcessingUI : 'enreport';
                }
                switch (tag.toLowerCase()) {
                    case 'eninvoice':
                        found = true;
                        this.routeToUI('eninvoice');
                        break;
                    case 'enreport':
                        found = true;
                        this.routeToUI();
                        break;
                    case 'enCollab':
                        found = true;
                        this.routeToUI('encollab');
                        break;
                }
            });
            if (!found) {
                this.routeToUI();
            }
        } else {
            this.routeToUI();
        }
    };

    private routeToUI = (ui?: 'eninvoice' | 'enreport' | 'encollab' | 'enreconcile') => {
        const href = window.location.href;
        const enInvoice = environment['eninvoice-ui'];
        const enReport = environment['enreport-ui'];
        const enCollab = environment['encollab-ui'];
        ui =
            ui ||
            (href.indexOf(enInvoice) > -1 && 'eninvoice') ||
            (href.indexOf(enReport) > -1 && 'enreport') ||
            (href.indexOf(enCollab) > -1 && 'encollab') ||
            (environment.defaultProcessingUI as any) ||
            'enreport';
        const routes: string[] = [ui];
        let requestId = '';
        if (this.assetInstance && this.assetInstance.assetRequests && this.assetInstance.assetRequests[0]) {
            requestId = this.assetInstance.assetRequests[0].requestId;
        } else {
            requestId = this.requestId;
        }
        routes.push('organizations', this._commonUtils.getFromStorage('currentOrganizationId'));
        if (this.bridgeRestApiName || this.bridge) {
            routes.push('packages', this.bridgeRestApiName || this.bridge.restApiName);
        }
        if (this.appRestApiName || this.app) {
            routes.push('apps', this.appRestApiName || this.app.restApiName);
        }
        if (this.report) {
            routes.push('reports', this.report.name);
        }
        if (requestId && ui !== 'eninvoice' && ui !== 'encollab') {
            routes.push('processes', 'inbound-transmissions', requestId, 'instances', this.assetInstance.assetDataId);
        } else {
            if (this.report) {
                /**
                 * Do Nothing
                 */
            } else {
                routes.push('processes', this.assetInstance.assetDataId);
            }
        }
        const reroute = '/' + routes.join('/');
        if (this.getDirectLink && !this.processInSameTab) {
            this.assetInstance['requestLink'] = reroute;
        } else {
            this._router.navigate(routes);
        }
    };

    private initiateController = () => {
        this.app['loading'] = this.app['loading'] || {};
        if (this.app['loading'].assetMetaData) {
            this.pollForAssetMetaData = setTimeout(this.initiateController, 1000);
        } else {
            clearTimeout(this.pollForAssetMetaData);
            setTimeout(this.onAppSelection, 100);
        }
    };

    private startComponent = () => {
        if (this.getDirectLink && !this.processInSameTab) {
            if (this.app && !(this.app as Object).hasOwnProperty('requestLink')) {
                this.initiateController();
            }
        }
    };

    ngOnInit() {
        this.startComponent();
    }
}
