import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BridgeService } from '../bridge/bridge.service';
import { StoreService } from '../store/store.service';

@Injectable({
    providedIn: 'root',
})
export class IdentityprovidersService {
    constructor(private _bridge: BridgeService, private _store: StoreService) {}

    /**
     * Method to fetch identity providers
     * @param callbacks contains success and failure calls
     */
    getIdentityProviders = () => {
        return new Observable((observer) => {
            this._bridge.getIdentityProviders(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to create identity providers
     * @param data contains payload
     */
    createIdentityProvider = (data: any) => {
        return new Observable((observer) => {
            this._bridge.createIdentityProvider(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to update identity providers
     * @param data contains payload and provider id
     */
    updateIdentityProvider = (data: { payload: any; providerId: string }) => {
        return new Observable((observer) => {
            this._bridge.updateIdentityProvider(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to delete identity providers
     * @param id
     */
    deleteIdentityProvider = (id: string) => {
        return new Observable((observer) => {
            this._bridge.deleteIdentityProvider(
                id,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
