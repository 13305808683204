import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoDynamicParamsComponent } from '@animations/demo-dynamic-params/demo-dynamic-params.component';
import { DemoMainComponent } from '@animations/demo-main/demo-main.component';
import { DemoOnEnterOnLeaveComponent } from '@animations/demo-on-enter-on-leave/demo-on-enter-on-leave.component';
import { ExperimentsComponent } from '@animations/experiments/experiments.component';
import { ResetPasswordComponent, SetPasswordComponent } from '@authentication/components';
import { CanActivateRouteService } from '@encomply/core/services/canactivateroute/canactivateroute.service';

import { RedirectComponent } from './components/redirect/redirect.component';

export const ROUTES: Routes = [
    {
        path: 'auth',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@authentication/app.module')).AppModule,
        data: {
            secured: false,
        },
    },
    {
        path: 'redirect',
        pathMatch: 'full',
        component: RedirectComponent,
        data: {
            secured: false,
            name: 'Success Redirect',
        },
    },
    {
        path: 'users/activate',
        component: SetPasswordComponent,
        pathMatch: 'full',
        runGuardsAndResolvers: 'always',
        canActivate: [CanActivateRouteService],
        data: {
            secured: false,
            name: 'Set Password',
            skipSession: true,
        },
    },
    {
        path: 'users/changepassword',
        component: ResetPasswordComponent,
        pathMatch: 'full',
        canActivate: [CanActivateRouteService],
        runGuardsAndResolvers: 'always',
        data: {
            secured: false,
            name: 'Reset Password',
            skipSession: true,
        },
    },
    {
        path: 'enreport',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@enreport/app.module')).AppModule,
        data: {
            secured: true,
        },
    },
    {
        path: 'collaboration',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@eninvoice-guest/guest.module')).GuestModule,
        data: {
            secured: false,
        },
    },
    {
        path: 'eninvoice',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@eninvoice/app.module')).AppModule,
        data: {
            secured: true,
        },
    },
    {
        path: 'encollab',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@encollab/app.module')).AppModule,
        data: {
            secured: true,
        },
    },
    {
        path: 'enreconcile',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@enreconcile/app.module')).AppModule,
        data: {
            secured: true,
        },
    },
    {
        path: 'gst-filing',
        pathMatch: 'prefix',
        loadChildren: async () => (await import('@gst-filing/app.module')).AppModule,
        data: {
            secured: true,
        },
    },
    {
        path: 'animations',
        pathMatch: 'prefix',
        children: [
            {
                path: 'main',
                component: DemoMainComponent,
                data: {
                    secured: false,
                    name: 'Main',
                    skipSession: true,
                },
            },
            {
                path: 'enter-leave',
                component: DemoOnEnterOnLeaveComponent,
                data: {
                    secured: false,
                    name: 'Enter/Leave',
                    skipSession: true,
                },
            },
            {
                path: 'dynamic-params',
                component: DemoDynamicParamsComponent,
                data: {
                    secured: false,
                    name: 'Dynamic Params',
                    skipSession: true,
                },
            },
            {
                path: 'experiments',
                component: ExperimentsComponent,
                data: {
                    secured: false,
                    name: 'Experiments',
                    skipSession: true,
                },
            },
        ],
    },
    {
        path: '',
        loadChildren: async () => (await import('@encomply/app.module')).AppModule,
        data: {
            secured: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
