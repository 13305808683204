import { ReconciliationSource } from '../reconciliation-source.class';
import { ReconciliationRecordField } from './reconciliation-record-field.class';

export class ReconciliationRecord {
    id: string;

    name: string;

    fields: ReconciliationRecordField[];

    score: number;

    errors: {
        match: boolean;
        message: string;
        property: string;
    }[];

    constructor(
        id: string,
        data: { [property: string]: any },
        source: ReconciliationSource,
        score: number,
        messages: {
            match: boolean;
            message: string;
            property: string;
        }[]
    ) {
        this.id = id;
        this.name = source?.name;
        this.fields = [];
        this.score = score;
        this.errors = messages || [];
        Object.keys(data || {}).forEach((key) => {
            const field = source.fields.find((sourceField) => sourceField.id === key);
            this.fields.push(new ReconciliationRecordField(data[key], field));
        });
    }
}
