<mat-form-field>
    <input type="text" matInput [formControl]="control" placeholder="{{placeholder}}" name="{{name}}" matTooltip="{{title}}" id="{{id}}" class="{{classList && classList.join(' ')}}" [matAutocomplete]="auto" [errorStateMatcher]="matcher" debounce="{{debounceAfter || 1000}}" (eventHandler)="updateModelAndEmitDebounceEvent($event)" (click)="panelOpen = true">
    <button *ngIf="showClearText" mat-button matSuffix mat-icon-button aria-label="clear" (click)="control.setValue('')">
        <mat-icon class='locationManagementIcons' *ngIf='control?.value?.length > 0'>
            close
        </mat-icon>
    </button>
    <mat-hint align="start" *ngIf="lookupHint">
        <strong>
            <span>* </span>Master Search</strong>
    </mat-hint>
    <mat-hint align="end" *ngIf="maxLength">{{control.value && control.value.length || 0}} / {{maxLength}}</mat-hint>
    <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
        <strong>
            <span>* </span>Required</strong>
    </mat-hint>
    <mat-error *ngIf="control.hasError('errors')" title="{{errors}}">
        {{errors}}
    </mat-error>
</mat-form-field>
<mat-autocomplete class="autocomplete" #auto="matAutocomplete" (optionSelected)="selectedOption($event)">
    <ng-container>
        <mat-option *ngFor="let option of filteredOptions" [value]="option.value" title="{{option.displayName}}">
            {{option.displayName}}
        </mat-option>
    </ng-container>
</mat-autocomplete>
