import { Organization, User } from 'taxilla-library';

import { ClientDetails } from '../../interface/client-details.interface';

export const authInitialState: AuthInitialState = {
    client: {
        clientDetails: undefined,
        errorResponse: undefined,
    },
    token: {
        details: undefined,
    },
    applicationProperties: {
        'analytics.service.types': undefined,
        'default.provider.id': undefined,
        'digio.url': undefined,
        'logo.path': undefined,
        'macre.ui.url': undefined,
        'multiple.providers.enabled': undefined,
        'password.regex': undefined,
        'taxilla.build.version': undefined,
        'taxilla.js.minified': undefined,
        'taxilla.session.max.inactive.interval': undefined,
        'taxilla.version': undefined,
        'two.factor.authentication': undefined,
        'foureyecheck.enabled': undefined,
    },
    is4ecEnabled: false,
};

export const resetPasswordInitialState: ResetPasswordInitialState = {
    security: {
        secretQuestions: [],
    },
    captcha: {
        captcha: undefined,
        plain: undefined,
        forgotPswd: undefined,
        forgotUsername: undefined,
    },
    context: {
        organization: undefined,
        user: undefined,
        settings: undefined,
    },
};
// Interfaces
export interface ResetPasswordInitialState {
    security: {
        secretQuestions: string[];
    };
    captcha: {
        captcha: string;
        plain?: boolean;
        forgotPswd?: boolean;
        forgotUsername?: boolean;
    };
    context: {
        organization: Organization;
        settings: any;
        user: User;
    };
}

export interface AuthInitialState {
    client?: {
        clientDetails: ClientDetails;
        errorResponse: { msg?: string; text?: string };
    };
    token?: {
        details?: {
            access_token?: string;
            expires_in?: number;
            orgId?: string;
            refresh_token?: string;
            resourceOwnerId?: string;
            scope?: [];
            token_type?: string;
        };
    };
    applicationProperties: {
        'analytics.service.types': string;
        'default.provider.id': string;
        'digio.url': string;
        'logo.path': string;
        'macre.ui.url': string;
        'multiple.providers.enabled': boolean;
        'password.regex': string;
        'taxilla.build.version': string;
        'taxilla.js.minified': string;
        'taxilla.session.max.inactive.interval': string;
        'taxilla.version': string;
        'two.factor.authentication': string;
        'foureyecheck.enabled': boolean;
    };
    is4ecEnabled: boolean;
}
