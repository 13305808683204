import * as CryptoJS from 'crypto-js';

export class AesUtil {
    keySize: any;
    iterationCount: any;

    constructor(keySize: number, iterationCount: any) {
        this.keySize = keySize / 32;
        this.iterationCount = iterationCount;
    }

    generateKey = (salt: string, passPhrase: string) => {
        const key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
            keySize: this.keySize,
            iterations: this.iterationCount,
        });
        return key;
    };

    encrypt = (salt: string, iv: string, passPhrase: string, plainText: string) => {
        const key = this.generateKey(salt, passPhrase);
        const encrypted = CryptoJS.AES.encrypt(plainText, key, {
            iv: CryptoJS.enc.Hex.parse(iv),
        });
        return (encrypted.ciphertext as any).toString(CryptoJS.enc.Base64);
    };
}
