import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { UtilsService } from 'taxilla-library';

@Component({
    selector: 'app-help-document-view',
    templateUrl: './help-document-view.component.html',
    styleUrls: ['./help-document-view.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class HelpDocumentViewComponent implements OnInit {
    noDocumentsList = false;
    isIE: boolean;

    @Input() documentList;

    @Output() downloadHelpDocument = new EventEmitter();
    @Output() viewHelpDocument = new EventEmitter();

    docSearch: string;

    constructor(private utils: UtilsService) {}

    ngOnInit(): void {
        this.isIE = this.utils.isIEBrowser();
    }

    docListTrakBy = (index) => index;

    clearSearch = () => (this.docSearch = '');

    searchHelpDocuments = () => {
        let matOptionsCount = 0;
        matOptionsCount = document.querySelectorAll('.helpdocumentReports').length;
        if (matOptionsCount === 0) {
            this.noDocumentsList = true;
        } else {
            this.noDocumentsList = false;
        }
    };
}
