import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, from, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import * as DashboardActionType from '../actions';
import { getAnalyticsDashboards$, getCurrentOrganizationId$ } from '../selectors';

@Injectable()
export class DashboardEffects {
    constructor(private actions$: Actions, private store: Store, private _api: ApiService) {}

    public getAllDashboards = createEffect(() => {
        return this.actions$.pipe(
            ofType(DashboardActionType.GetDashboards),
            withLatestFrom(this.store.select(getAnalyticsDashboards$), this.store.select(getCurrentOrganizationId$)),
            mergeMap(([action, analyticDashboards, currentOrgId]) => {
                if (analyticDashboards?.length > 0) {
                    return [];
                }
                this.store.dispatch(DashboardActionType.SetDashboardLoader({ isDashboardsLoading: true }));
                return from(this._api.analytics.getDashboards(action.noAlerts)).pipe(
                    map((res: any) => {
                        this.store.dispatch(DashboardActionType.SetDashboardLoader({ isDashboardsLoading: false }));
                        res?.response?.dashboards?.forEach((dashbaord) => {
                            const name: string = dashbaord?.displayName || dashbaord?.serviceName;
                            dashbaord.displayName = name[0].toUpperCase() + name.substring(1);
                        });
                        const dashboards = res?.response?.dashboards?.sort((a, b) => {
                            const aName = a.displayName;
                            const bName = b.displayName;
                            if (aName < bName) {
                                return -1;
                            } else if (aName > bName) {
                                return 1;
                            }
                            return 0;
                        });
                        return DashboardActionType.SetDashboards({
                            analyticDashboards: dashboards,
                            currentOrganizationId: currentOrgId,
                        });
                    }),
                    catchError((errorResponse) => {
                        this.store.dispatch(DashboardActionType.SetDashboardLoader({ isDashboardsLoading: false }));
                        return of(DashboardActionType.AlertError({ message: errorResponse }));
                    })
                );
            })
        );
    });
}
