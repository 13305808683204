import { createAction, props } from '@ngrx/store';

export enum TokenActions {
    GET_ACTIVE_TOKENS = '[GET_ACTIVE_TOKENS] Get Active Tokens',
    GET_ACTIVE_TOKENS_SUCCESS = '[GET_ACTIVE_TOKENS_SUCCESS] Active Tokens Successfull',
    GET_ACTIVE_TOKENS_FAILED = '[GET_ACTIVE_TOKENS_FAILED] Active Tokens Failure',
    INVALIDATE_TOKENS = '[INVALIDATE_TOKENS] Invalidate Tokens',
    INVALIDATE_TOKENS_SUCCESS = '[INVALIDATE_TOKENS_SUCCESS] Invalidate Tokens Successfull',
    CLEAR_INVALIDATE_TOKENS = '[CLEAR_INVALIDATE_TOKENS] Clear Invalidated Tokens Done',
    INVALIDATE_TOKENS_FAILED = '[INVALIDATE_TOKENS_FAILED] Invalidate Tokens Failure',
    GET_TOKENS_COUNT = '[GET_TOKENS_COUNT] Get Tokens Count',
    GET_TOKENS_COUNT_SUCCESS = '[GET_TOKENS_COUNT_SUCCESS] Tokens Count Successfull',
    GET_TOKENS_COUNT_FAILED = '[GET_TOKENS_COUNT_FAILED] Tokens Count Failure',
}

export const GetActiveTokens = createAction(TokenActions.GET_ACTIVE_TOKENS, props<{ payload: { size: number; page: number } }>());

export const ActiveTokensSuccess = createAction(TokenActions.GET_ACTIVE_TOKENS_SUCCESS, props<{ activeTokens: any[]; count: number }>());

export const ActiveTokensFailed = createAction(TokenActions.GET_ACTIVE_TOKENS_FAILED, props<{ msg: string }>());

export const InvalidateTokens = createAction(TokenActions.INVALIDATE_TOKENS, props<{ payload: { tokenIds: number[] } }>());

export const ClearInvalidatedToken = createAction(TokenActions.CLEAR_INVALIDATE_TOKENS);

export const InvalidateTokensSuccess = createAction(TokenActions.INVALIDATE_TOKENS_SUCCESS, props<{ msg: string }>());

export const InvalidateTokensFailed = createAction(TokenActions.INVALIDATE_TOKENS_FAILED, props<{ msg: string }>());
