import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AssetService, MastersTableMetaDataInterface, Organization, ReconciliationConfiguration, User } from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class RootScope {
    session = new BehaviorSubject(false);
    userPermissions: BehaviorSubject<any> = new BehaviorSubject(undefined);
    user: BehaviorSubject<User> = new BehaviorSubject(undefined);
    allSubscribedApps: BehaviorSubject<AssetService[]> = new BehaviorSubject(undefined);
    currentOrganizationId: BehaviorSubject<string> = new BehaviorSubject('');
    currentOrganization: BehaviorSubject<Organization> = new BehaviorSubject(undefined);
    current: {
        app: AssetService;
        appPermissions: any;
        mainPermissions: any;
        instanceId: BehaviorSubject<string>;
        inboundTransmissionId: BehaviorSubject<string>;
        master: BehaviorSubject<any[]>;
    } = {
        app: undefined,
        appPermissions: undefined,
        mainPermissions: undefined,
        instanceId: new BehaviorSubject(undefined),
        inboundTransmissionId: new BehaviorSubject(undefined),
        master: new BehaviorSubject(undefined),
    };
    isAdmin = false;
    rootOrganization: BehaviorSubject<Organization> = new BehaviorSubject(undefined);
    applicationInitialized = new BehaviorSubject(false);
    selectedAppAPI: BehaviorSubject<string> = new BehaviorSubject(undefined);
    selectedApp: BehaviorSubject<AssetService> = new BehaviorSubject(undefined);
    loadingCall = {};
    mastersTables: BehaviorSubject<{
        property?: MastersTableMetaDataInterface;
    }> = new BehaviorSubject(undefined);
    orientation: string;
    device: string;
    inNewProcessCreation = new BehaviorSubject(undefined);
    reconciliationConfiguration: BehaviorSubject<ReconciliationConfiguration> = new BehaviorSubject(undefined);
    constructor() {}
}
