import { Injectable } from '@angular/core';
import { environment } from '@env';
import { response } from 'express';

import { MasterRecordsResponse } from '../../interface/master-records-response.interface';
import { AppTemplate } from '../../models/app-template.class';
import { AssetService } from '../../models/assetservice.class';
import { clientData } from '../../models/clientData.class';
import { CollabConfiguration } from '../../models/collab-configuration.class';
import { AppNewIntegrationRecordPayload } from '../../models/integrations/appnewintegrationpayload.class';
import { AuthorizationSystem } from '../../models/integrations/authorization-system.class';
import { IntegrationRecordPayload } from '../../models/integrations/integrationrecordpayload.class';
import { Organization } from '../../models/organization.class';
import { Password } from '../../models/password.class';
import { RequestBusinessKeysPayload } from '../../models/payload/businesskeys.class';
import { RecordPayload } from '../../models/payload/recordpayload.class';
import { Permission } from '../../models/permission.class';
import { Role } from '../../models/role.class';
import { RoleUser } from '../../models/roleuser.class';
import { User } from '../../models/user.class';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { CommunicationsService } from '../communications/communications.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

/**
 * Here is where we write the low end api calls
 */
@Injectable({
    providedIn: 'root',
})
export class BridgeService {
    isOnIE: boolean;

    constructor(
        private _comm: CommunicationsService,
        private _utils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private _store: StoreService
    ) {
        this.isOnIE = this._commonUtils.isBrowserIE();
    }

    /**
     * Method to check if any organization exists with the given email id
     * @param emailId Email id
     * @param s Success callback
     * @param f Failure callback
     */
    public checkIfEmailExists = (emailId: string, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_api + '/partners/search?email=' + emailId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get workflow
     * @param data Contains instanceId and assetId
     * @param s Success callback
     * @param f Failure callback
     */

    public getWorkflow = (
        data: {
            serviceId?: string;
            instanceId: string;
            noAlerts?: boolean;
            restApiName?: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        if (!data.instanceId || data.instanceId.length === 0) {
            return;
        }
        let slug;
        if (data.serviceId && !data.restApiName) {
            slug = environment.taxilla_api + '/services/' + data.serviceId + '/instances/' + data.instanceId + '/workflow';
        } else if (data.restApiName) {
            slug = environment.taxilla_api + '/internal/process/v1/' + data.restApiName + '/' + data.instanceId + '/workflow';
        }
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get workflow
     * @param data Contains instanceId and assetId
     * @param s Success callback
     * @param f Failure callback
     */
    public getWorkflowStages = (
        data: { assetName: string; assetVersion: string; assetUid: string },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug =
            environment.taxilla_api +
            '/workflow/metadata?assetName=' +
            data.assetName +
            '&version=' +
            data.assetVersion +
            '&assetId=' +
            data.assetUid;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to update workflow
     * @param data Contains instanceId and assetId
     * @param s Success callback
     * @param f Failure callback
     */
    public updateWorkflow = (
        data: { assetName: string; assetVersion: string; assetId: string; taskDetails: {} },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const payload = {
            assetId: data.assetId,
            assetName: data.assetName,
            assetVersion: data.assetVersion,
            taskDetails: data.taskDetails,
        };
        const slug = environment.taxilla_api + '/workflow/metadata';
        this._utils.showLoading();
        this._comm.put(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to execute workflow
     * @param payload Payload
     * @param s Success callback
     * @param f Failure callback
     */
    public executeWorkFlow = (
        data: {
            restApiName?: string;
            payload: any[];
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        let slug;
        slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/workflow';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to save records
     * @param payload Record to save
     */
    public saveRecords = (data: {
        payload: RecordPayload[];
        restApiName: string;
        assetDataId: string;
        entityId: string;
        noAlerts?: boolean;
    }) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/' + data.assetDataId + '/' + data.entityId + '/records';
        this._utils.showLoading();
        return new Promise<any>((resolve, reject) => {
            this._comm.post(
                slug,
                data.payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to save records
     * @param payload Record to save
     */
    public updateRecordsViaPromise = (data: {
        payload: RecordPayload[];
        restApiName: string;
        assetDataId: string;
        entityId: string;
        noAlerts?: boolean;
    }) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/' + data.assetDataId + '/' + data.entityId + '/records';
        this._utils.showLoading();
        return new Promise<any>((resolve, reject) => {
            this._comm.put(
                slug,
                data.payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to submit record, it can post or put
     * @param payload Record to submit
     * @param s Success callback
     * @param f Failure callback
     */
    public submitRecord = (
        data: {
            payload: RecordPayload;
            restApiName: string;
            assetDataId: string;
            entityId: string;
            entityRecordId: string;
            parentId: string;
        },
        s: (response: {
            msg: string;
            response: {
                id: string;
                parentRecordId: string;
                fields: { id: string; value: string }[];
                grids: [];
                errors: string[];
                warnings: string[];
            };
        }) => void,
        f
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/' + data.assetDataId + '/' + data.entityId + '/records';
        let method: CommunicationsService['put'] | CommunicationsService['post'];
        if (data.entityRecordId && data.entityRecordId.length > 0) {
            slug += '/' + encodeURIComponent(this._utils.getRecordId(data.payload.id));
            delete data.payload.id;
            delete data.payload.parentRecordId;
            method = this._comm.put;
        } else {
            method = this._comm.post;
        }
        // data.parentId && (slug += '?parentEntityRecordId=' + encodeURIComponent(data.parentId));
        this._utils.showLoading();
        method(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to update records
     * @param payload Record to submit
     * @param s Success callback
     * @param f Failure callback
     */
    public updateRecords = (
        data: {
            payload: RecordPayload[];
            assetDataId: string;
            entityId: string;
            restApiName: string;
        },
        s: (response: {
            msg: string;
            response: {
                records: {
                    id: string;
                    parentRecordId: string;
                    fields: { id: string; value: string }[];
                    grids: [];
                    errors: string[];
                    warnings: string[];
                }[];
            };
        }) => void,
        f
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/' + data.assetDataId + '/' + data.entityId + '/records';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get record
     * @param data contains instanceId, entityId, recordId
     * @param s Success callback
     * @param f Failure callback
     */
    public getRecord = (
        data: {
            instanceId: string;
            entityId: string;
            recordId: string;
            parentRecordId?: string;
            restApiName: string;
        },
        s: (res: {
            response: {
                id: string;
                fields: {
                    id: string;
                    value: string;
                    errors: string[];
                    warnings: string[];
                }[];
                grids: {
                    id: string;
                    cells: {
                        rowIndex: number;
                        columnIndex: number;
                        value: string;
                        errors: string[];
                        warnings: string[];
                    }[];
                    errors: string[];
                    warnings: string[];
                }[];
                errors: string[];
                warnings: string[];
            };
        }) => void,
        f: (...args: any[]) => void
    ) => {
        const recordId = this._utils.getRecordId(data.recordId);
        let slug =
            environment.taxilla_api +
            '/process/v1/' +
            data.restApiName +
            '/' +
            data.instanceId +
            '/' +
            data.entityId +
            '/' +
            encodeURIComponent(recordId);
        if (data.parentRecordId) {
            slug += '?parentEntityRecordId=' + encodeURIComponent(data.parentRecordId);
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get entity record
     * @param data contains instanceId, entityId, size, pagingState
     * @param s Success callback
     * @param f Failure callback
     */
    public getEntityRecords = (
        data: {
            restApiName: string;
            instanceId: string;
            entityId: string;
            size?: number;
            pagingState?: any;
            parentRecordId?: string;
            noAlerts?: boolean;
        },
        s: (response: {
            success: boolean;
            response: {
                records: {
                    id: string;
                    fields: { id: string; value: string; errors: string[]; warnings: string[] }[];
                    errors: string[];
                    warnings: string[];
                }[];
                pagingState: string;
            };
        }) => void,
        f
    ) => {
        if (!data.instanceId || data.instanceId.length === 0 || !data.entityId || data.entityId.length === 0) {
            return;
        }
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/' + data.instanceId + '/' + data.entityId + '/records';
        if (data.parentRecordId) {
            slug += '?parentRecordId=' + encodeURIComponent(data.parentRecordId);
        }
        if (data.size) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'fetchSize=' + data.size;
        }
        if (data.pagingState) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'pagingState=' + data.pagingState;
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * check instance status
     * @param data contains instanceId
     * @param s Success callback
     * @param f Failure callback
     */
    public checkInstanceStatus = (
        data: {
            instanceId: string;
            serviceId?: string;
            restApiName?: string;
            noAlerts?: boolean;
        },
        s,
        f
    ) => {
        if (!data.instanceId || data.instanceId.length === 0) {
            return;
        }
        let slug;
        if (data.serviceId && !data.restApiName) {
            slug = environment.taxilla_api + '/services/' + data.serviceId + '/instances/' + data.instanceId + '/statuses';
        } else if (data.restApiName) {
            slug = environment.taxilla_api + '/internal/process/' + data.restApiName + '/' + data.instanceId + '/validation-status';
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to submit login details
     * @param data contains userid and password
     * @param s Success callback
     * @param f Failure callback
     */
    public submitLogin = (data: any, s: (response) => void, f: (response) => void) => {
        const hideDomain =
            data.hideDomain ||
            this._commonUtils.isBrowserIE() ||
            [
                'dbcloud',
                'dbtest',
                'dbuat',
                'dbprod',
                'dbdr',
                'kpmg2aprod',
                'kpmgprod',
                'kpmguat',
                'adityabirlauat',
                'adityabirlaprod',
            ].indexOf(environment.envName) > 0 ||
            environment.onLocalhost;
        const slug = environment.taxilla_user_identity_api + (hideDomain ? '/login' : '/v1/identity-provider/oidc/login');
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to setting password
     * @param data contains org ID, user ID, username, authenticationKey
     * @param s Success callback
     * @param f Failure callback
     */
    public setPassword = (
        data: {
            orgId: string;
            payload: Password;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/account-setup';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to fetch reports meta data
     * @param assetMetaUId Asset meta id
     * @param s Success callback
     * @param f Failure callback
     */
    public getAnalyticsMetaData = (assetMetaUId: string, s: (response) => void, f: (response) => void, noAlerts: boolean) => {
        const slug = environment.taxilla_api + '/asset/report/analyticsNames?assetId=' + assetMetaUId;
        !noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to forgot password
     * @param data user ID
     * @param s Success callback
     * @param f Failure callback
     */
    public forgotPassword = (data: { userId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/pre-login/reset-password';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to forgot username
     * @param data organization code, email
     * @param s Success callback
     * @param f Failure callback
     */
    public forgotUsername = (data: { orgId: string; email: string; captcha: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/pre-login/username';
        this._utils.showLoading();
        data['orgLoginId'] = data.orgId;
        delete data.orgId;
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to fetch instance reports
     * @param instanceId Instance id of the reports to fetch
     * @param s Success callback
     * @param f Failure callback
     */
    public getAnalyticReports = (
        {
            instanceId,
            serviceId,
            restApiName,
            noAlerts,
        }: { serviceId: string; instanceId: string; restApiName?: string; noAlerts: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug;
        if (restApiName) {
            slug =
                environment.taxilla_api + '/internal/process/' + restApiName + '/' + instanceId + '/reports?executionPlan=ON_DATA_CHANGE';
        } else {
            slug = environment.taxilla_api + '/services/' + serviceId + '/instances/' + instanceId + '/reports?category=ANALYTICS';
        }
        !noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to reset password
     * @param data contains key,password,confirm password
     * @param s Success callback
     * @param f Failure callback
     */
    public resetPassword = (
        data: { key: string; password: string; confirmPassword: string; secretQuestionAndAnswersCommand: any; captcha: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/pre-login/change-password';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to fetch Captcha
     * @param s Success callback
     * @param f Failure callback
     */
    public fetchCaptcha = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/captcha';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to register
     * @param data contains orgnization details, user details and captcha
     * @param s Success callback
     * @param f Failure callback
     */
    public register = (
        data: {
            org: Organization;
            captcha: string;
            invitationId: string;
            user: User;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug: string;
        if (data.invitationId) {
            slug = `${environment.taxilla_user_identity_api}/v1/partners`;
        } else {
            slug = `${environment.taxilla_user_identity_api}/organizations/register`;
        }
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to check whether gstin is nic registred or not
     * @param data contains gstin value
     * @param s Success callback
     * @param f Failure callback
     */
    public gstnValidateFunction = (data: { gstin: string; url: string }, s: (response) => void, f: (response) => void) => {
        const slug = data.url + '/searchgstin?gstin=' + data.gstin;
        const customHeaders = {
            noUrlPrefix: true,
        };
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            '',
            ' ',
            customHeaders
        );
    };

    /**
     * Method to fetch instance reports
     * @param payload Report payload
     * @param s Success callback
     * @param f Failure callback
     */
    public generateReport = ({ payload, serviceId, restApiName, noAlerts }, s: (response) => void, f: (response) => void) => {
        let slug = environment.taxilla_api + '/services/' + serviceId + '/instances/' + payload.assetDetail.assetDataId + '/reports';
        if (restApiName) {
            slug =
                environment.taxilla_api +
                '/process/v1/' +
                restApiName +
                '/' +
                payload.assetDetail.assetDataId +
                '/reports/' +
                payload.chainNames[0];
            payload = undefined;
        }
        !noAlerts && this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                !noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to process instance
     * @param data contains instanceId and payload
     * @param s Success callback method
     * @param f Failure callback method
     */
    public processInstance = (
        data: {
            instanceId: string;
            payload: {
                requestId: string;
                assetId: string;
                assetDataId: string;
                assetDataIds: string[];
                serviceSpecId: string;
                locationId: string;
                reprocessing: boolean;
                hasWorkflow: boolean;
                taskId: null;
                taskName: null;
            };
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/internal/process/instances';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to get users
     * @param data containd organization id
     * @param s Success callback
     * @param f Failure callback
     */
    public getUsers = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + `/v1/users`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items || []);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to activate a user
     * @param data containd user object
     * @param s Success callback
     * @param f Failure Callback
     */
    public activateUser = (data: User, s, f) => {
        const slug = environment.taxilla_user_identity_api + `/v1/users/${data.id}?action=ACTIVATE`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to add a new user
     * @param data contains roles, role ids and user objects
     * @param s Success callback
     * @param f Failure Callback
     */
    public addUsers = (data: RoleUser, s, f) => {
        const payload = CommonUtilsService.cloneObject(data);
        const slug = environment.taxilla_user_identity_api + '/v1/users';
        delete payload['editUser'];
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to update the existing user
     * @param data contains roles, role ids and user object
     * @param s Success callback
     * @param f Failure callback
     */

    public updateUserDetails = (data: RoleUser, s, f) => {
        const payload = CommonUtilsService.cloneObject(data);
        let slug = environment.taxilla_user_identity_api + '/users/' + payload.user.id;
        if (payload['editUser']) {
            slug += `?action=EDIT-USER`;
            delete payload['editUser'];
        }
        this._utils.showLoading();
        this._comm.put(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to deactivate a user
     * @param data contains user object
     * @param s Success callback
     * @param f Failure Callback
     */
    public deactivateUser = (data: User, s, f) => {
        const slug = environment.taxilla_user_identity_api + `/v1/users/${data.id}?action=DEACTIVATE`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to check if user id already exists
     * @param data contains  user id
     * @param s Success callback
     * @param f Failure callback
     */
    public validateUserId = (
        data: {
            userId: string;
        },
        s,
        f
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/userid-exist/' + data.userId;
        const id = this._utils.showLoading('Checking User Id');
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading(id);
                s(res);
            },
            (res) => {
                this._utils.hideLoading(id);
                f(res);
            }
        );
    };
    /**
     * Method to check if email id already exists
     * @param data contains organization id , email id
     * @param s Success callback
     * @param f Failure callback
     */
    public validateEmailId = (email: string, s, f) => {
        const slug = environment.taxilla_user_identity_api + '/v1/users/email-exist?emailId=' + email;
        // const id = this._utils.showLoading('Checking Email Id');
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to get roles before adding a user
     * @param data contains user object, roles object and role ids object
     * @param s Success callback
     * @param f Failure callback
     */
    public getUserRoles = (id: string, s, f) => {
        const slug = environment.taxilla_user_identity_api + '/v1/roles/user/' + id;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to switch to selected Organization
     * @param orgId Organization ID
     * @param s Success callback method
     * @param f Failure callback method
     */
    public switch = (orgId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/switch/' + encodeURIComponent(orgId);
        this._utils.showLoading();
        this._comm.post(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to search locations with organization Id
     * @param data Contains organization ID,
     * @param s Success callback method
     * @param f Failure calback method
     */
    public searchOrganizations = (
        data: {
            organizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_user_identity_api + '/v1/organizations';
        if (data.organizationId) {
            slug += '?search=parent.id:' + data.organizationId;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get master table meta data
     * @param data Contains table name, organization ID,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getMastersMetadata = (
        data: { itemName: string; organizationId: string; noAlerts: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/masters/metadata?masterName=' + data.itemName;
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get master table language data
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getMasterLanguageTemplate = (data: { masterId: string; noAlerts: boolean }) => {
        return new Promise((resolve, reject) => {
            const slug = `${environment.taxilla_api}/ui-template/${data.masterId}`;
            !data.noAlerts && this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    public getMasterLanguageTemplateObservable = (
        data: {
            tableId: string;
            organizationId: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_api}/ui-template/${data.tableId}`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get Masters Records
     * @param data which contains mastername, pagingstate and size
     * @param s success callback
     * @param f failure callback
     */
    public getMastersRecords = (
        data: {
            size?: number;
            restApiName: string;
            pagingState: any;
            noAlerts?: boolean;
        },
        s: (response: MasterRecordsResponse) => void,
        f: (response) => void
    ) => {
        let slug =
            environment.taxilla_api + '/masters/v1/' + data.restApiName + '?fetchSize=' + data.size + '&pagingState=' + data.pagingState;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res.response);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get locations
     * @param data contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getLocations = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/locations';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get asset meta data
     * @param service Asset service data
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getAssetMetadata = (assetMetaUId: string, s: (response) => void, f: (response) => void, custom?: any) => {
        const slug = environment.taxilla_api + '/metadata/assets/' + assetMetaUId;
        if (!custom || !custom.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!custom || !custom.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!custom || !custom.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get asset meta data
     * @param service Asset service data
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getAssetReconciliationConfiguration = (
        { restApiName, version, noAlerts }: { restApiName: string; version?: number; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.recon_api + `/metadata/${restApiName}`;
        version && (slug += `?version=${version}`);
        if (!noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get asset meta data language template
     * @param service Asset service data
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getAssetMetadataLanguageTemplate = (assetMetaUId: string, s: (response) => void, f: (response) => void, custom?: any) => {
        const slug = environment.taxilla_api + '/ui-template/' + assetMetaUId;
        if (!custom || !custom.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!custom || !custom.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!custom || !custom.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get asset Description
     * @param data contains assetMetaUId
     * @param s Success callback
     * @param f Failure callback
     */
    public getAssetDescription = (
        data: { assetMetaUId: string; assetType: string; id?: string; noLoading?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.taxilla_api}/asset/metadata/description?metaId=${data.assetMetaUId || data.id}${
            data.assetType ? '&type=' + data.assetType : ''
        }`;
        if (!data.noLoading) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noLoading) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noLoading) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get asset workflow stages data
     * @param service assetDataId, assetId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getAssetWorkflowSatages = (
        data: { serviceId: string; assetDataId: string; assetId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/services/' +
            data.serviceId +
            '/instances/' +
            data.assetDataId +
            '/workflow?assetId=' +
            data.assetId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get fetchEntities
     * @param service fetchEntities
     * @param s Success callback method
     * @param f Failure callback method
     */
    public fetchEntities = (assetId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/metadata/assets/' + assetId + '/entities';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to get Reports
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getReports = (assetId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/asset/report/' + assetId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Edit Masters Records
     * @param payload contains columnCValues, tableId, unitId
     * @param s success callback
     * @param f failure callback
     */
    public updateMasterRecord = (payload, s: (response) => void, f: (response) => void) => {
        const data = payload.data;
        const slug = environment.taxilla_api + `/masters/v1/${payload.restApiName}`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Add Masters Records
     * @param data contains columnCValues, tableId, unitId
     * @param s success callback
     * @param f failure callback
     */
    public addMasterRecord = (payload, s: (response) => void, f: (response) => void) => {
        const data = payload.data;
        const slug = environment.taxilla_api + `/masters/v1/${payload.restApiName}`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to add location
     * @param data contains location details
     * @param s Success callback
     * @param f Failure callback
     */
    public addLocation = (data: { org: Organization }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/organizations';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get user permissions
     * @param data contains user object
     * @param s success callback
     * @param f failure callback
     */
    public getUserPermissions = (
        user: {
            actionName: string;
            category: string;
            createdDate: string;
            displayName: string;
            id: number;
            objectId: string;
            permissionType: string;
            pageNum?: number;
            pageSize?: number;
            categories?: string[];
            objectIds?: string[];
            hideAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + `/users/${user.id}/permissions`;
        if (user.pageNum && user.pageSize) {
            slug += `?pageNo=${user.pageNum}&pageSize=${user.pageSize}`;
        }
        if (user.categories?.length > 0) {
            const categoriesToPush = [];
            user.categories.forEach((id) => categoriesToPush.push(encodeURIComponent(id)));
            slug += `&objectIds=${categoriesToPush.join(',')}`;
        } else if (user.objectIds?.length > 0) {
            slug += `&objectIds=${user.objectIds.join(',')}`;
        }
        if (!user.hideAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!user.hideAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!user.hideAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get user permissions from IDM
     * @param data contains user object
     * @param s success callback
     * @param f failure callback
     */
    public getUserIDMPermissions = (
        user: {
            actionName: string;
            category: string;
            createdDate: string;
            displayName: string;
            id: number;
            objectId: string;
            permissionType: string;
            pageNum?: number;
            pageSize?: number;
            categories?: string[];
            objectIds?: string[];
            hideAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_user_identity_api + `/v1/permissions/users/${user.id}`;
        if (user.pageNum && user.pageSize) {
            slug += `?pageNo=${user.pageNum}&pageSize=${user.pageSize}`;
        }
        if (user.categories?.length > 0) {
            const categoriesToPush = [];
            user.categories.forEach((id) => categoriesToPush.push(encodeURIComponent(id)));
            slug += `&objectIds=${categoriesToPush.join(',')}`;
        } else if (user.objectIds?.length > 0) {
            slug += `&objectIds=${user.objectIds.join(',')}`;
        }
        if (!user.hideAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!user.hideAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!user.hideAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Fetch lookup values
     * @param payload lookup payload array
     * @param s Success callback method
     * @param f Failure callback method
     */
    public fetchLookups = (
        payload: {
            assetId: AssetService['assetMetaUId'];
            entityId: string;
            fieldId: string;
            lookupId: string;
            lookupValueFieldId: string;
            lookupDisplayFieldId: string;
            conditionalFieldVsValue?: {
                conditionalvalue: string;
            };
        }[],
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/asset/metadata/lookup-data';
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Search lookup values
     * @param payload lookup payload array
     * @param s Success callback method
     * @param f Failure callback method
     */
    public searchLookups = (
        payload: {
            lookupDetails: {
                assetId: string;
                entityId: string;
                fieldId: string;
                lookupId: string;
                lookupValueFieldId: string;
                lookupDisplayFieldId: string;
                order: string;
            }[];
            lookupSearchDetails: {
                lookupId: string;
                searchString: string;
                large: boolean;
                page: number;
            }[];
        },
        s,
        f
    ) => {
        const slug = environment.taxilla_api + '/asset/metadata/lookup-data/search';
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to search master records
     * @param data Contains unit ID, payload
     * @param s Success callback method
     * @param f Failure callback method
     */
    public searchMasterRecords = (
        data: {
            organizationId: string;
            payload: {
                queryString: string;
                size: number;
                from: number;
                masterId: string;
                unitId: string;
                sort: {};
                searchParent?: boolean;
            };
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_search_api + '/organizations/' + data.organizationId + '/masters/_search';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get count of search master records
     * @param data Contains unit ID, payload
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getSearchMasterRecordsCount = (
        data: {
            organizationId: string;
            payload: {
                queryString: string;
                masterId: string;
                unitId: string;
                sort: {};
            };
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_search_api + '/organizations/' + data.organizationId + '/masters/_count';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get instances
     * @param data
     * @param s
     * @param f
     */
    public getInstances = (
        data: {
            requestId?: string;
            status?: string;
            fetchSize?: number;
            previousPagingState?: string;
            nextPagingState?: string;
            noAlerts?: boolean;
            restApiName: string;
            loadAssetRequests?: boolean;
            timeRange?: any[];
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        let slug = environment.taxilla_api + '/internal/process/' + data.restApiName + '/instances';
        data.fetchSize = data.fetchSize || 10;
        if (data.requestId || data.status || data.fetchSize || data.previousPagingState || data.nextPagingState || data.loadAssetRequests) {
            slug += '?';
            if (data.requestId) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'requestId=' + data.requestId;
            }
            if (data.status) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'instanceStatus=' + data.status;
            }
            if (data.previousPagingState) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'previousPagingState=' + data.previousPagingState;
            }
            if (data.nextPagingState) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'nextPagingState=' + data.nextPagingState;
            }
            if (data.loadAssetRequests) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'loadAssetRequests=' + data.loadAssetRequests;
            }
            if (data.timeRange) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'forDate=' + data.timeRange?.join(',');
            }
            slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'fetchSize=' + data.fetchSize;
        }
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get instances
     * @param data Contains serviceId and instanceId
     * @param s Success callback method
     * @param f Failure Callback method
     */
    public getInstance = (
        data: { instanceId: string; serviceId?: string; noAlerts?: boolean; restApiName?: string },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        let slug;
        if (data.serviceId && !data.restApiName) {
            slug = environment.taxilla_api + '/services/' + data.serviceId + '/instances/' + data.instanceId;
        } else if (data.restApiName) {
            slug = environment.taxilla_api + '/internal/process/' + data.restApiName + '/' + data.instanceId;
        }
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get Instance by sending request id and status (optional) of the instance
     * @param data Contains requestId, instance status (default: 'Pending'),
     * @param s Success callback method
     * @param f Failure Callback method
     */
    public getInstanceByRequestId = (
        data: {
            requestId: string;
            status?: string;
            fetchSize?: number;
            previousPagingState?: string;
            nextPagingState?: string;
            noAlerts?: boolean;
            restApiName?: string;
            serviceId?: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        let payload;
        if (data.serviceId && !data.restApiName) {
            payload = {
                requestId: data.requestId,
                instanceStatus: data.status || 'Pending',
                fetchSize: data.fetchSize || 10,
                previousPagingState: data.previousPagingState || null,
                nextPagingState: data.nextPagingState || null,
                loadAssetRequests: true,
                serviceId: data.serviceId,
            };
            const slug = environment.taxilla_api + '/instances/next-instances';
            if (!data.noAlerts) {
                this._utils.showLoading();
            }
            this._comm.post(
                slug,
                payload,
                (res) => {
                    if (!data.noAlerts) {
                        this._utils.hideLoading();
                    }
                    s(res);
                },
                (res) => {
                    if (!data.noAlerts) {
                        this._utils.hideLoading();
                    }
                    f(res);
                }
            );
        } else if (data.restApiName) {
            payload = {
                requestId: data.requestId,
                instanceStatus: data.status || 'Pending',
                fetchSize: data.fetchSize || 10,
                previousPagingState: data.previousPagingState || null,
                nextPagingState: data.nextPagingState || null,
                loadAssetRequests: true,
                restApiName: data.restApiName,
            };
            this.getInstances(payload, s, f);
        }
    };

    /**
     * Method to update organization
     * @param data contains organization details
     * @param s Success callback
     * @param f Failure callback
     */
    public updateOrganizationDetails = (data: { org: Organization }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/' + data.org.id;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.org,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to upload organization logo
     * @param data contains organization logo details
     * @param s Success callback
     * @param f Failure callback
     */
    public uploadOrganizationLogo = (data: FormData, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/artifacts/organizations/logos';
        this._utils.showLoading();
        const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete organization logo
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteOrganizationLogo = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/artifacts/organizations/logos';
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get organization log
     * @param s Success callback
     * @param f Failure callback
     */
    public getOrganizationLogo = (enableHierarchy: boolean, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/artifacts/organizations/logos?enableHierarchy=' + enableHierarchy;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response || '');
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get organization details
     * @param orgId contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getOrganizationDetails = (orgId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/' + orgId + '?needDecryption=true';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get organization details
     * @param organizationId contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getParentOrgDetails = (
        data: {
            organizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations?search=parent.id:' + data.organizationId;
        this._utils.showLoading(undefined, 'getParentOrgDetails');
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading(undefined, 'getParentOrgDetails');
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading(undefined, 'getParentOrgDetails');
                f(res);
            }
        );
    };

    /**
     * Method to get all organizations
     * @param s Success callback
     * @param f Failure callback
     */
    public getOrganizations = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/associated-orgs';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to create a new request
     * @param data Contains service restAPIName, payload as form data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public createNewRequest = (
        data: {
            serviceId: string;
            appRestApiName: string;
            payload: FormData;
            bridgeRestApiName?: string;
            reportName?: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api;
        if (data.reportName) {
            if (data.bridgeRestApiName) {
                slug += '/process/bridges/' + data.bridgeRestApiName + '/targets/' + encodeURIComponent(data.reportName);
            }
        } else {
            slug += '/process/v1/' + data.appRestApiName + '?sourceType=PSG';
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to create a new request
     * @param data Contains service restAPIName, payload as form data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public createNewReconciliation = (
        { restApiName, payload }: { restApiName: string; payload: FormData },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.recon_api}/process/${restApiName}`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to create a new recon template
     * @param data Contains service restAPIName, payload as form data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public createNewReconTemplate = (
        { restApiName, payload, selectedTemplateId }: { restApiName: string; payload: FormData; selectedTemplateId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.recon_api}/template/${restApiName}${selectedTemplateId ? '/' + selectedTemplateId : ''}`;
        const method = selectedTemplateId ? this._comm.put : this._comm.post;
        this._utils.showLoading();
        method(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to get all recon templates
     * @param data Contains service restAPIName, payload as form data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getAllReconTemplates = (data: { restApiName: string; noAlerts?: boolean }, s: (response) => void, f: (response) => void) => {
        const slug = `${environment.recon_api}/template/${data.restApiName}`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete recon template
     * @param data contains template Id
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteReconTemplate = (data: { restApiName: string; templateId: string }, s: (response) => void, f: (response) => void) => {
        const slug = `${environment.recon_api}/template/${data.restApiName}/${data.templateId}`;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to create instance from bridge
     * @param data Contains bridge, service data, bridge data, if its not first asset in bridge, payload,
     * @param s Success callback method
     * @param f Failure callback method
     */

    public createInstanceFromBridge = (
        data: {
            bridge: AssetService;
            service: AssetService;
            isNotFirstAssetInBridge?: boolean;
            payload: FormData;
            isAssetToAssetTransformation: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.service.restApiName;
        if (data.isNotFirstAssetInBridge && data.isAssetToAssetTransformation) {
            slug += '?override=false&sourceType=ASSETTOASSET&bridgeRestApiName=' + data.bridge.restApiName;
        } else {
            slug += '?sourceType=PSG&bridgeRestApiName=' + data.bridge.restApiName;
        }
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to fetch instances
     * @param data contains entity ID, query, assetname, restapiname,size,searchafter,sortablefields
     * @param s Success callback
     * @param f Failure callback
     */
    public searchEntityRecords = (
        data: {
            assetName?: string;
            query: any;
            searchAfter: any;
            searchEntityId: string;
            restApiServiceName?: string;
            size?: number;
            noAlerts?: boolean;
            sortableFields?: {};
            resultsOnPrimaryEntity?: boolean;
            responseEntityCriteria?: { entityId?: string; queryString?: string; resultNeeded?: boolean; sort?: Map<string, any> };
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_search_api + '/entities/' + data.searchEntityId + '/records/_search';
        let payload;
        if (typeof data.query === 'string') {
            payload = {
                entityCriterias: [
                    {
                        entityId: data.searchEntityId,
                        sort: data.sortableFields || {},
                        queryString: data.query,
                        resultNeeded: data.resultsOnPrimaryEntity !== undefined ? data.resultsOnPrimaryEntity : true,
                    },
                ],
                assetName: data.assetName,
                restApiServiceName: data.restApiServiceName,
                size: data.size || 100,
            };
        } else {
            payload = CommonUtilsService.cloneObject(data.query);
            payload.size = data.size || payload.size;
        }
        if (data.searchAfter) {
            payload['searchAfter'] = data.searchAfter;
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Post method to create a new request
     * @param data Contains bridge data, service data, request business keys payload
     * @param s Success callback method
     * @param f Failure callback method
     */
    public submitNewRequestBusinessKeys = (
        data: { bridge: AssetService; service: AssetService; payload: RequestBusinessKeysPayload },
        s: (response) => void,
        f: (response) => void
    ) => {
        const bridgeAssetId = data.bridge && data.bridge.assetMetaUId;
        const serviceAssetId = data.service.assetMetaUId;
        const slug =
            environment.taxilla_api +
            '/asset/business-keys?assetId=' +
            (bridgeAssetId && bridgeAssetId.length > 0 ? bridgeAssetId : serviceAssetId);
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get transformations
     * @param data contains asset ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getTransformations = (data: { assetMetaUId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/metadata/assets/' + data.assetMetaUId + '/transformations';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get transformations
     * @param data contains asset ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getReconciliationTransformations = (
        data: { assetId: string; sourceId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + `/metadata/recon/${data.assetId}/inbound-transformations`;
        if (data.sourceId) {
            slug += `?source=${data.sourceId}`;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get all map transformations
     * @param data contains asset ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getAllMapTransformations = (
        data: { assetMetaUId: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/metadata/assets/' + data.assetMetaUId + '/transformations';
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get all transformations
     * @param data contains asset ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getAllTransformations = (
        data: { assetMetaUId: string; assetName: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api + '/asset/metadata/all-transformations?assetName=' + data.assetName + '&assetId=' + data.assetMetaUId;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get active inbound / outbound transformation names
     * @param data contains type, asset rest api name
     * @param s Success callback
     * @param f Failure callback
     */
    public getActiveTransformationsByType = (
        data: { transformationType: 'INBOUND' | 'OUTBOUND'; assetRestAPIName: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/services/' + data.assetRestAPIName + '/active-inbound-transformations';
        if (data.transformationType === 'OUTBOUND') {
            slug = environment.taxilla_api + '/services/' + data.assetRestAPIName + '/active-outbound-transformations';
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to update active inbound / outbound transformation names
     * @param data contains type, active transformation names,asset rest api name
     * @param s Success callback
     * @param f Failure callback
     */
    public updateActiveTransformationsByType = (
        data: {
            transformationType: 'INBOUND' | 'OUTBOUND';
            activeTrNames: string[];
            assetRestAPIName: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/services/' + data.assetRestAPIName + '/active-inbound-transformations';
        if (data.transformationType === 'OUTBOUND') {
            slug = environment.taxilla_api + '/services/' + data.assetRestAPIName + '/active-outbound-transformations';
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.put(
            slug,
            { transformations: data.activeTrNames },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get asset filing attributes
     * @param data Contains bridge data (optional), service data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getFilingAttributes = (data: { bridge?: AssetService; service: AssetService }, s: (response) => void, f: (response) => void) => {
        let slug = environment.taxilla_api + '';
        const bridge = data.bridge;
        const app = data.service;
        if (bridge && bridge.restApiName && bridge.restApiName.length > 0) {
            slug += `/bridges/${bridge.restApiName}/assets/${app.restApiName}/filingattributes?assetId=${app.assetMetaUId ?? app.id}`;
        } else {
            slug += '/services/' + this._utils.getServiceId(app, bridge) + '/filingattributes';
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get asset filing attributes
     * @param data Contains bridge data (optional), service data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getReportFilingAttributes = (
        { serviceId, fileName }: { serviceId: string; fileName: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_api}/asset/metadata/filtering-attributes?serviceSpecificationId=${serviceId}&fileDataName=${fileName}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get asset filtering attributes
     * @param data Contains bridge data (optional), service data, repositoryId, transformation name,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getFilteringAttributes = (
        data: {
            bridge?: AssetService;
            service: AssetService;
            repositoryId: string;
            transformationName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/asset-to-asset/metadata?metaId=' +
            data.service.assetMetaUId +
            '&repositoryId=' +
            data.repositoryId +
            '&transformationName=' +
            data.transformationName;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get filed request attributes
     * @param data Contains bridge data (optional), service data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getRequestFilingAttributes = (
        data: {
            restApiName: string;
            requestId: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api + '/internal/process/' + data.restApiName + '/requests/' + data.requestId + '/request-attributes';
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Get filed request attributes
     * @param data Contains bridge data (optional), service data,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getReportAttributes = (
        data: { requestId?: string; serviceId: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '';
        slug += '/pre-capture/attributes?serviceId=' + data.serviceId;
        if (data.requestId) {
            slug += '&requestId=' + data.requestId;
        }
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Get request level errors
     * @param data Contains requestId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getRequestLevelErrors = (data: { serviceId: string; requestId: string }, s: (response) => void, f: (response) => void) => {
        let slug = environment.taxilla_api + '';
        slug += '/services/' + data.serviceId + '/requests/' + data.requestId + '/validations';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get request index data
     * @param data Contains requestId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getRequestIndexData = (
        data: { orgId: string; serviceId: string; requestId: any },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_search_api + '';
        slug += '/organizations/' + data.orgId + '/services/' + data.serviceId + '/requests/' + data.requestId + '/_index';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get request index status
     * @param data Contains requestId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getRequestIndexStatus = (data: { serviceId: string; requestId: any }, s: (response) => void, f: (response) => void) => {
        let slug = environment.taxilla_search_api + '';
        slug += '/services/' + data.serviceId + '/request/' + data.requestId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Download new uploaded files
     * @param data Contains requestId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public downloadNewUploadedFiles = (
        data: { resourceId: string; restApiName: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '';
        slug += '/process/v1/' + data.restApiName + '/' + data.resourceId + '/datauploads';
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to save search configuration
     * @param data contains object ID, criteria, filter name, id
     * @param s Success callback
     * @param f Failure callback
     */
    public saveSearchConfiguration = (
        data: { objectId: string; criteria: string; filterName: string; id?: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/search-filter';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to save search configuration
     * @param data contains object ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getSearchConfiguration = (objectId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/search-filter?objectId=' + objectId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete search configuration
     * @param data contains filter ID
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteSearchConfiguration = (data: { id: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/search-filter/' + data.id;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Fetching subscribed to services
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public searchSubscribedServicesByOrganizationId = (
        data: {
            organizationId: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/services?orgId=' + data.organizationId;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get default-search filters
     * @param data contains asset ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getDefaultSearchFilters = (assetId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_search_api + '/asset/' + assetId + '/default-filters';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get instance entity records based on default-search filters
     * @param data contains assetName, defaultFilterName, entityId, restApiServiceName, from, size
     * @param s Success callback
     * @param f Failure callback
     */
    public fetchInstancesBasedOnDefaultFilters = (
        data: {
            assetName: string;
            defaultFilterName: string;
            entityId: string;
            restApiServiceName: string;
            from?: number;
            size?: number;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_search_api + '/entities/' + data.entityId + '/records/_search';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Fetching subscribed to services
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public getSubscribedServices = (data: { noAlerts?: boolean }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/services';
        !data?.noAlerts && this._utils.showLoading(undefined, 'subscribedServices');
        this._comm.get(
            slug,
            (res) => {
                !data?.noAlerts && this._utils.hideLoading(undefined, 'subscribedServices');
                s(res);
            },
            (res) => {
                !data?.noAlerts && this._utils.hideLoading(undefined, 'subscribedServices');
                f(res);
            }
        );
    };

    /** Fetching enreconcile services
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public getReconciliationServices = (data: { noAlerts?: boolean }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/services?type=RECON';
        !data.noAlerts && this._utils.showLoading(undefined, 'subscribedServices');
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading(undefined, 'subscribedServices');
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading(undefined, 'subscribedServices');
                f(res);
            }
        );
    };

    /** Fetching collaborated services
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public getCollaboratedApps = (data: { noAlerts?: boolean }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/collaboration/v1/services';
        this._utils.showLoading(undefined, 'collaboratedServices');
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading(undefined, 'collaboratedServices');
                s(res);
            },
            (res) => {
                this._utils.hideLoading(undefined, 'collaboratedServices');
                f(res);
            }
        );
    };

    /**
     * Subscribing to widgets
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public fetchSubscribedWidgets = (
        data: {
            organizationId: any;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/subscriptions/_search';
        const payload = {
            orgIds: [data.organizationId],
            itemIds: [],
            subscriptionType: 'SUBSCRIPTION_APPROVAL',
            page: 1,
            size: 10000,
            status: null,
        };
        this._utils.showLoading(undefined, 'widgetSubscriptionsSearch');
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading(undefined, 'widgetSubscriptionsSearch');
                s(res);
            },
            (res) => {
                this._utils.hideLoading(undefined, 'widgetSubscriptionsSearch');
                f(res);
            }
        );
    };

    /**
     * Subscribing to assets
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public fetchSubscribedAssets = (
        data: {
            pageIndex: number;
            size: number;
            organizationId: any;
            subscriptionType?: string;
            status?: string;
            orgIdArray?: any[];
            itemIdArray?: any[];
            noVariableLoading?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/subscriptions/_search';
        const payload = {
            orgIds: data.orgIdArray ? data.orgIdArray : [],
            itemIds: data.itemIdArray ? data.itemIdArray : [],
            subscriptionType: data.subscriptionType || 'MANAGE_SUBSCRIPTION',
            page: data.pageIndex,
            size: data.size,
            status: data.status ? data.status : null,
        };
        if (!data.noVariableLoading) {
            this._utils.showLoading(undefined, 'subscriptionsSearch');
        } else {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            payload,
            (res) => {
                if (!data.noVariableLoading) {
                    this._utils.hideLoading(undefined, 'subscriptionsSearch');
                } else {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noVariableLoading) {
                    this._utils.hideLoading(undefined, 'subscriptionsSearch');
                } else {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Fetching all available widgets
     * @param s success callback
     * @param f failure callback
     */
    public getAllOrganizations = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations?search=&projections=id,name,orgLoginId';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Fetching all available widgets
     * @param s success callback
     * @param f failure callback
     */
    public searchAllOrganizations = (data: { [property: string]: string | boolean }, s: (response) => void, f: (response) => void) => {
        let slug = environment.taxilla_user_identity_api + '/v1/organizations?search=';
        Object.keys(data).forEach((key) => {
            slug += `${key}:${data[key]},`;
        });
        slug = slug.slice(0, -1); // removing comma from end
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Fetching all available widgets
     * @param s success callback
     * @param f failure callback
     */
    public getAllAssets = (data: { id: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/organizations/' + data.id + '/subscriptions/items?orgId=' + data.id;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Counts of the assets
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public subscribedAssetsCount = (
        data: {
            organizationId: any;
            subscriptionType: string;
            status?: string;
            orgIdArray?: any[];
            itemIdArray?: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/subscriptions/_count';
        const payload = {
            orgIds: data.orgIdArray ? data.orgIdArray : [],
            itemIds: data.itemIdArray ? data.itemIdArray : [],
            subscriptionType: data.subscriptionType,
            status: data.status ? data.status : null,
        };
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Fetching assets
     * @param s success callback
     * @param f failure callback
     */
    public getAssets = (
        data: {
            type: string;
            pageIndex: number;
            size: number;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/items/search?criterias=type:' +
            encodeURIComponent(data.type) +
            '&page=' +
            data.pageIndex +
            '&size=' +
            data.size +
            '&projectionList=';
        this._utils.showLoading(undefined, 'allAssetsSearch');
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading(undefined, 'allAssetsSearch');
                s(res);
            },
            (res) => {
                this._utils.hideLoading(undefined, 'allAssetsSearch');
                f(res);
            }
        );
    };

    /**
     * Subscribing to services
     * @param data contains organizationId and payload
     * @param s success callback
     * @param f failure callback
     */
    public subscribeToServices = (
        data: {
            organizationId: any;
            payload: {
                orgIds: any[];
                itemIds: any[];
            };
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/subscriptions';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Subscribing to services
     * @param data contains organizationId and payload
     * @param s success callback
     * @param f failure callback
     */
    public updateSubscribedServices = (
        data: {
            organizationId: any;
            payload: {
                subscriptionIds: any[];
                status: string;
            };
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/subscriptions';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Fetching all available services
     * @param s success callback
     * @param f failure callback
     */
    public getServiceDetails = (assetMetaUId: string) => {
        return new Promise<any>((resolve, reject) => {
            const slug = environment.taxilla_api + '/metadata/assets/' + assetMetaUId + '/details';
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get services underlying bridge
     * @param data contains assetservice properties
     * @param s success callback
     * @param f failure callback
     */
    public fetchServicesUnderlyingBridge = (assetMetaUId: string, custom?: { noAlerts: boolean }) => {
        return new Promise<any>((resolve, reject) => {
            const slug = `${environment.taxilla_api}/services/bridge-nodes?bridgeId=${assetMetaUId}`;
            if (!custom || !custom.noAlerts) {
                this._utils.showLoading();
            }
            this._comm.get(
                slug,
                (res) => {
                    if (!custom || !custom.noAlerts) {
                        this._utils.hideLoading();
                    }
                    resolve(res?.response?.bridgeNodes || {});
                },
                (res) => {
                    if (!custom || !custom.noAlerts) {
                        this._utils.hideLoading();
                    }
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get services underlying bridge using serviceId
     * @param data contains assetservice properties
     * @param s success callback
     * @param f failure callback
     */
    public fetchAppsUnderlyingBridge = (
        data: {
            serviceId: string;
            showLoader?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/services?serviceId=' + data.serviceId;
        !!data.showLoader && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !!data.showLoader && this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to index the app
     * @param data contains assetservice properties
     * @param s success callback
     * @param f failure callback
     */
    public indexAppOrMasterData = (
        data: {
            orgId: string;
            restApiName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_search_api + '/organization/' + data.orgId + '/index/' + data.restApiName;
        this._utils.showLoading();
        this._comm.post(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get services underlying bridge
     * @param data contains assetservice properties
     * @param s success callback
     * @param f failure callback
     */
    public getBridgeNodes = (data: { assetMetaUId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/metadata/bridges/' + data.assetMetaUId + '/nodes';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Getting all Roles
     * @param data organizationId
     * @param s success callback
     * @param f failure callback
     */
    public getRoles = (organizationId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/roles?search=organizationId:' + organizationId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Getting all permissions
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public getAllPermissions = (data: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/permissions?locationId=' + data;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Getting all IDM permissions
     * @param data contains organizationId
     * @param s success callback
     * @param f failure callback
     */
    public getAllIDMPermissions = (data: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/permissions/' + data;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Getting Role Permissions with Role Id
     * @param data contains role object
     * @param s success callback
     * @param f failure callback
     */
    public getMaproRolePermissions = (data: Role, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/v1/roles/' + data.id + '/permissions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Getting Role Permissions with Role Id
     * @param data contains role object
     * @param s success callback
     * @param f failure callback
     */
    public getIDMRolePermissions = (data: Role, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/roles/' + data.id + '/permissions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Getting assetMetaData Permissions
     * @param data contains serviceId
     * @param s success callback
     * @param f failure callback
     */
    public getAssetMetaDataPermissions = (
        data: { serviceId: string; assetType: string; assetName: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '';
        if (data.assetType === 'BRIDGE_ASSET') {
            slug += '/permissions/asset?serviceId=' + data.serviceId + '&participantName=' + data.assetName;
        } else {
            slug += '/permissions/asset?serviceId=' + data.serviceId;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Updating Mapro Roles
     * @param data contains role and permissions objects
     * @param s success callback
     * @param f failure callback
     */
    public updateMaproRole = (
        data: {
            role: Role;
            permissions: Permission[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/roles/' + data.role.id;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Updating IDM Roles
     * @param data contains role and permissions objects
     * @param s success callback
     * @param f failure callback
     */
    public updateIDMRole = (
        data: {
            role: Role;
            permissions: Permission[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/roles/' + data.role.id;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.role,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method for role users
     * @param data contains roleName and organizationId
     * @param s success callback
     * @param f failure callback
     */
    public roleUsers = (roleId, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/role-users?search=role.id:' + roleId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get organization hierarchy
     * @param data contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getOrganizationHierarchy = (
        data: {
            organizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/' + data.organizationId + '/hierarchy';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get organization child hierarchy
     * @param data contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getOrganizationChildHierarchy = (organizationId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/' + organizationId + '/child-hierarchy';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Report Processes
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getReportProcesses = (
        data: {
            serviceId: string;
            reportName?: string;
            size?: number;
            previousPagingState?: string;
            requestState?: string;
            noAlerts?: boolean;
            restApiName?: string;
            timeRange?: any[];
            status?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        this.getRequests(data, s, f);
    };

    /**
     * Method to get Report request
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getReportRequest = (
        data: {
            serviceId: string;
            reportName?: string;
            size?: number;
            previousPagingState?: string;
            requestState?: string;
            noAlerts?: boolean;
            fetchFileDetails?: boolean;
            restApiName?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        this.getRequests(data, s, f);
    };

    /**
     * Method to get Requests
     * @param data contains service ID, restApiName
     * @param s Success callback
     * @param f Failure callback
     *
     */
    public getRequests = async (
        data: {
            serviceId: string;
            reportName?: string;
            size?: number;
            previousPagingState?: string;
            requestState?: string;
            noAlerts?: boolean;
            fetchFileDetails?: boolean;
            restApiName?: string;
            timeRange?: any[];
            status?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug;
        let payload;
        data.size = data.size || 10;
        if (data.serviceId && !data.restApiName) {
            slug = environment.taxilla_api + '/requests/next-requests';
            payload = {
                serviceId: data.serviceId,
                fetchSize: data.size,
                assetName: data.reportName || '-',
                previousPagingState: data.previousPagingState || null,
                nextPagingState: null,
                fetchFileDetails: data.fetchFileDetails || false,
            };
            if (data.requestState) {
                payload['requestState'] = data.requestState;
            }
            if (data.timeRange) {
                payload['forDate'] = data.timeRange;
            }
            if (!data.noAlerts) {
                this._utils.showLoading();
            }
            this._comm.post(
                slug,
                payload,
                (res) => {
                    if (!data.noAlerts) {
                        this._utils.hideLoading();
                    }
                    s(res);
                },
                (res) => {
                    if (!data.noAlerts) {
                        this._utils.hideLoading();
                    }
                    f(res);
                }
            );
        } else if (data.restApiName) {
            // slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/requests';
            // TAX-5394 Pre-capture attributes are not dipslaying at inbound transimmison level
            const wrapper = await this.getWrapperAPITags(data.serviceId);
            slug = wrapper
                ? wrapper + '/process/' + data.restApiName + '/requests'
                : environment.taxilla_api + '/internal/process/' + data.restApiName + '/requests';
            if (data.size || data.reportName || data.previousPagingState || data.fetchFileDetails || data.requestState) {
                slug += '?';
                if (data.reportName && !wrapper) {
                    slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'assetName=' + data.reportName;
                }
                if (data.previousPagingState) {
                    if (wrapper) {
                        slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'page=' + encodeURIComponent(data.previousPagingState);
                    } else {
                        slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'previousPagingState=' + encodeURIComponent(data.previousPagingState);
                    }
                }
                if (data.fetchFileDetails && !wrapper) {
                    slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'fetchFileDetails=' + data.fetchFileDetails;
                }
                if (data.timeRange && !wrapper) {
                    slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'forDate=' + data.timeRange?.join(',');
                }
                if (data.requestState) {
                    if (wrapper) {
                        slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'status=' + data.requestState;
                    } else {
                        slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'requestState=' + data.requestState;
                    }
                }
                if (wrapper) {
                    slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'size=' + data.size;
                } else {
                    slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'fetchSize=' + data.size;
                }
                if (data.status) {
                    if (wrapper) {
                        slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'requestStatus=' + data.status;
                    } else {
                        slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'requestState=' + data.status;
                    }
                }
            }
            if (!data.noAlerts) {
                this._utils.showLoading();
            }
            this._comm.get(
                slug,
                (res) => {
                    if (!data.noAlerts) {
                        this._utils.hideLoading();
                    }
                    s(res);
                },
                (res) => {
                    if (!data.noAlerts) {
                        this._utils.hideLoading();
                    }
                    f(res);
                }
            );
        }
    };

    /**
     * Method to get reconilation requests
     * @param param0
     * @returns
     */

    public getReconciliationRequests = ({
        restApiName,
        fromDate,
        toDate,
        pagingState,
        size,
        noAlerts,
    }: {
        restApiName: string;
        fromDate: string;
        toDate: string;
        pagingState: string;
        size: number;
        noAlerts?: boolean;
    }) => {
        return new Promise<any>((resolve, reject) => {
            let slug = environment.recon_api + `/process/${restApiName}?pageSize=${size || 20}`;
            fromDate?.length > 0 && (slug += `&from=${fromDate || ''}`);
            toDate?.length > 0 && (slug += `&to=${toDate || ''}`);
            pagingState?.length > 0 && (slug += `&pagingState=${pagingState || ''}`);
            !noAlerts && this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    !noAlerts && this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    !noAlerts && this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };
    /**
     * Method to refresh wrapper request
     * @param param0
     * @returns
     */
    public refreshWrapperRequest = async ({
        serviceId,
        requestId,
        restAPIName,
    }: {
        serviceId: string;
        requestId: string;
        restAPIName: string;
    }) => {
        const wrapper = await this.getWrapperAPITags(serviceId);
        const slug = wrapper + '/process/' + restAPIName + '/requests/' + requestId;
        return new Promise((resolve, reject) => {
            this._utils.showLoading();
            this._comm.put(
                slug,
                undefined,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to get master requests
     * @param data contains restapiname,size and previousPagingState
     * @param s Success callback
     * @param f Failure callback
     */
    public getMasterRequests = (
        data: {
            restApiName: string;
            size: number;
            previousPagingState: any;
            timeRange?: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/requests';
        if (data.size || data.previousPagingState) {
            slug += '?';
            if (data.previousPagingState) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'previousPagingState=' + encodeURIComponent(data.previousPagingState);
            }
            if (data.timeRange) {
                slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'forDate=' + data.timeRange?.join(',');
            }
            slug += (slug.indexOf('=') >= 0 ? '&' : '') + 'fetchSize=' + data.size;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
            }
        );
    };

    /**
     * Method to get master record change logs
     * @param data contains contains orgId, masterRestApiname, primarykeyvalues
     * @param s Success callback
     * @param f Failure callback
     */
    public getMasterRecordChangeLogs = (
        data: {
            orgId: string;
            masterRestApiName: string;
            keyValuePairs: any;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/organizations/' + data.orgId + '/masters/' + data.masterRestApiName + '/change-log?';
        Object.keys(data.keyValuePairs).forEach((key, index) => {
            if (index > 0) {
                slug += '&';
            }
            slug += `${key}=${data.keyValuePairs[key].value}`;
        });
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
            }
        );
    };

    /**
     * Method to get setup attribute change logs
     * @param data contains contains restapiname
     * @param s Success callback
     * @param f Failure callback
     */
    public getSetupAttributeChangeLogs = (
        data: {
            restApiName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/' + data.restApiName + '/setupattributes/change-log';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
            }
        );
    };

    /**
     * Method to process tranformation
     * @param data contains bridgeRestAPIName, restapiname, transformation name ,payload, override
     * @param s Success callback
     * @param f Failure callback
     */
    public processTransformation = (
        data: {
            bridgeRestAPIName: string;
            restApiName: string;
            transformationName: string;
            payload: FormData;
            override: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName;
        slug += '?sourceType=ASSETTOASSET';
        if (data.bridgeRestAPIName !== undefined) {
            slug += '&bridgeRestApiName=' + data.bridgeRestAPIName;
        }
        slug += '&transformationName=' + data.transformationName;
        if (data.override !== undefined) {
            slug += '&override=' + data.override;
        }

        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to  current report instance
     * @param data contains request ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getcurrentReportInstance = (
        data: { requestId: string; serviceId?: string; restApiName?: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        let payload;
        if (data.serviceId && !data.restApiName) {
            const slug = environment.taxilla_api + '/instances/next-instances';
            payload = {
                serviceId: data.serviceId,
                requestId: data.requestId,
                instanceStatus: 'Reports Generation successful',
                fetchSize: 10,
                previousPagingState: null,
                nextPagingState: null,
            };
            this._utils.showLoading();
            this._comm.post(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    s(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    f(res);
                }
            );
        } else if (data.restApiName) {
            payload = {
                restApiName: data.restApiName,
                requestId: data.requestId,
                instanceStatus: 'Reports Generation successful',
                fetchSize: 10,
                previousPagingState: null,
                nextPagingState: null,
            };
            this.getInstances(payload, s, f);
        }
    };

    /**
     * Method to exchange eway bill with partner organization
     * @param payload Exchange data
     * @param s Success callback
     * @param f Failure callback
     */
    public sendToPartnerOrg = (
        payload: {
            ownerOrgId: string;
            assetId: string;
            instanseId: string;
            partnerMailId: string;
            association: string;
            reverseAssociation: string;
            partnerOrgId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.eninvoice_api + '/mailtopartner';
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                noUrlPrefix: true,
            }
        );
    };

    /**
     * Method to search error reports
     * @param data contains entityCriterias, assetName, restApiName, size and from
     * @param s success callback
     * @param f failure callback
     */
    public searchErrorReports = (
        data: {
            defaultFilterName?: string;
            entityCriterias: any[];
            assetName: string;
            restApiServiceName: string;
            size: number;
            from: number;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_search_api + '/requests/_search';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to search instances
     * @param data contains assetName, entityCriterias, restApiServiceName, serviceId, size, sort
     * @param s success callback
     * @param f failure callback
     */
    public searchInstances = (
        data: {
            defaultFilterName?: string;
            entityCriterias: any[];
            size: number;
            loadAssetRequests: boolean;
            searchAfter: any;
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_search_api + '/instances/_search';
        let payload: any = Object.assign({}, data);
        delete payload.noAlerts;
        if (data.entityCriterias) {
            if (data.size) {
                data.entityCriterias['size'] = data.size;
            }
            if (data.searchAfter) {
                data.entityCriterias['searchAfter'] = data.searchAfter;
            }
            if (data.loadAssetRequests) {
                data.entityCriterias['loadRequests'] = data.loadAssetRequests;
            }
            payload = data.entityCriterias;
        }
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            payload,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get transformation chains request
     * @param assetMetaUid contains Asset MetaUId
     * @param s success callback
     * @param f failure callback
     */
    public getTransformationChains = (assetMetaUid: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/metadata/assets/' + assetMetaUid + '/transformation-chains/across-requests';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to download Error Report
     * @param data contains entityCriterias, assetName, restApiName, service Id, report Id
     * @param s success callback
     * @param f failure callback
     */
    public downloadErrorReport = (
        data: {
            defaultFilterName?: string;
            filterCriteria?: any;
            assetName: string;
            restApiServiceName: string;
            serviceId: string;
            reportName: string;
            repositoryId: string;
            generateCompleteInstanceReport: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/requests/_download';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to send Email value
     * @param data contains Organization Id
     * @param s success callback
     * @param f failure callback
     */
    public sendEmailvalue = (
        data: {
            orgId: Organization['id'];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.eninvoice_api + '/errorreport';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                noUrlPrefix: true,
            }
        );
    };

    /**
     * Method to download JSON data
     * @param data contains organization Id, asset Id, list of instance Ids
     * @param s success callback
     * @param f failure callback
     */
    public bulkDownloadEwbJSON = (
        data: {
            orgId: Organization['id'];
            assetId: string;
            instanceIds: any[];
            version?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.eninvoice_api + '/downloadjsonreport';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            undefined,
            { noUrlPrefix: true }
        );
    };

    /**
     * Method to copy role
     * @param data contains roleid ,locationids
     * @param s Success callback
     * @param f Failure callback
     */
    public copyRole = (
        data: {
            roleId: string;
            locationIds: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = environment.taxilla_api + '/roles/' + data.roleId + '/copy?locationIds=' + data.locationIds;
        this._utils.showLoading();
        this._comm.put(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method for delete role
     * @param data contains role Id
     * @param s success callback
     * @param f failure callback
     */
    public deleteRole = (roleId: string, s, f) => {
        const url = environment.taxilla_user_identity_api + '/v1/roles/' + roleId;
        this._utils.showLoading();
        this._comm.delete(
            url,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to  search Instances
     * @param data name and restApiName, search criteria,
     * @param s Success callback
     * @param f Failure callback
     */
    public searchInstance = (
        data: { name: string; restApiName: string; criteria: string | { [property: string]: any } },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/bridges/' + data.name + '/assets/' + data.restApiName + '/instances';
        if (typeof data.criteria === 'string') {
            slug += '?' + data.criteria;
        } else if (data.criteria) {
            let criteria = '';
            const keys = Object.keys(data.criteria);
            for (let i = 0; i < keys.length; i++) {
                i === 0 ? (criteria += '?') : (criteria += '&');
                criteria += keys[i] + '=' + data.criteria[keys[i]];
            }
            slug += criteria;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to getErrorInRecords
     * @param data criteria,
     * @param s Success callback
     * @param f Failure callback
     */
    public getErrorInRecords = (
        data: { slug: string; criteria: { [property: string]: any } },
        s: (response) => void,
        f: (respond) => void
    ) => {
        let slug = data.slug;
        if (data.criteria.queryParams) {
            slug += '?';
            const keys = Object.keys(data.criteria.queryParams);
            for (let i = 0; i < keys.length; i++) {
                slug += keys[i] + '=' + data.criteria.queryParams[keys[i]];
                if (keys[i + 1]) {
                    slug += '&';
                }
            }
        } else if (data.criteria.fetchSize) {
            let criteria = '';
            const keys = Object.keys(data.criteria);
            for (let i = 0; i < keys.length; i++) {
                i === 0 ? (criteria += '?') : (criteria += '&');
                criteria += keys[i] + '=' + data.criteria[keys[i]];
            }
            slug += criteria;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get users without having user management permission
     * @param data contains organization Id
     * @param s Success callback
     * @param f Failure callback
     */
    public getAllUsers = (
        data: {
            organizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_user_identity_api + '/users?search=organizationId:' + data.organizationId + ',accountSetupCompleted:true';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get current-user
     * @param s Success callback
     * @param f Failure callback
     */
    public getCurrentUserDetails = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/current-user';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res.response[0]);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get digital signature
     * @param data
     * @param s Success callback
     * @param f Failure callBack
     */
    public getDigitalSignatureDetails = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/artifacts/organizations/digital-signatures';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get current-user
     * @param s Success callback
     * @param f Failure callback
     */
    public getSupportedRegions = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/supported-regions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get user details
     * @param data contains user Id
     * @param s Success callback
     * @param f Failure callback
     */
    public getUserDetailsById = (userId: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/users/' + userId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get users
     * @param data contains organization Id
     * @param s Success callback
     * @param f Failure callback
     */
    public getOrgUsers = (
        data: {
            organizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/users?search=organizationId:' + data.organizationId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method reset user password
     * @param data contains user Id
     * @param s Success callback
     * @param f Failure callback
     */
    public reserUserPassword = (
        data: {
            userId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + `/v1/users/${data.userId}?action=RESET-PASSWORD`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to unlock password
     * @param data contains userId
     * @param s Success callback
     * @param f Failure callback
     */
    public unlockPassword = (
        data: {
            userId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/users/' + data.userId + '?action=UNLOCK';
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get users
     * @param data contains organization Id
     * @param s Success callback
     * @param f Failure callback
     */
    public getUsersByPagination = (
        data: {
            organizationId: string;
            pageNumber: number;
            pageSize: number;
            criterias?: {};
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_user_identity_api + '';
        if (data.criterias) {
            let criteriaString = '';
            for (const key in data.criterias) {
                if (data.criterias.hasOwnProperty(key) && data.criterias[key]?.length > 0) {
                    const element = data.criterias[key];
                    criteriaString += key + '~' + element + ',';
                }
            }
            slug += `/v1/users?search=${criteriaString}organizationId:${data.organizationId}&page=${data.pageNumber}&size=${data.pageSize}&serviceUser=false&projections=`;
        } else {
            slug += `/v1/users?search=organizationId:${data.organizationId}​​​​&page=${data.pageNumber}​​​​​​&size=${data.pageSize}&serviceUser=false&projections=`;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get users count
     * @param data contains organization Id
     * @param s Success callback
     * @param f Failure callback
     */
    public getUsersCount = (
        data: {
            organizationId: string;
            criterias?: {};
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_user_identity_api + '';
        if (data.criterias) {
            let criteriaString = '';
            for (const key in data.criterias) {
                if (data.criterias.hasOwnProperty(key)) {
                    const element = data.criterias[key];
                    criteriaString += key + '~' + element + ',';
                }
            }
            slug += '/users/count?search=' + criteriaString + 'organizationId:' + data.organizationId;
        } else {
            slug += '/users/count?search=organizationId:' + data.organizationId;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get session details
     * @param data Contains no alerts,
     * @param s Success callback
     * @param f Failure callback
     */
    public getLoggedInSessionDetails = ({ noAlerts }, s, f, p?, data?, custom?) => {
        const slug = environment.taxilla_user_identity_api + '/v1/session/info';
        if (!noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!noAlerts) {
                    this._utils.hideLoading();
                }
                s(res?.response);
            },
            (res) => {
                if (!noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            },
            p,
            data,
            custom
        );
    };

    /*
     * Method to assign users
     * @param data contains roleid,newuserids and olduserids
     * @param s Success callback
     * @param f Failure callback
     */
    public assignUsers = (
        data: {
            roleId: string;
            action: 'ASSIGN' | 'UNASSIGN';
            ids: string[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const payload = { ids: data.ids };
        const slug = environment.taxilla_user_identity_api + `/v1/roles/${data.roleId}/users?action=${data.action}`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get masters Inbound mappings
     * @param data Contains organizationId, master table id
     * @param s Success callback
     * @param f Failure callback
     */
    public getMastersInboundMappings = (data: { organizationId: string; masterTableId: string }, s, f) => {
        const slug =
            environment.taxilla_api +
            '/organizations/' +
            data.organizationId +
            '/masters/metadata/inbound-mappings?masterName=' +
            data.masterTableId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get masters Inbound mappings
     * @param data Contains organizationId, master table id
     * @param s Success callback
     * @param f Failure callback
     */
    public getParentPrimaryKeys = (data: { organizationId: string; tableId: string }, s, f) => {
        const slug =
            environment.taxilla_api + '/organizations/' + data.organizationId + '/masters/parentPrimaryKeys?parentTableId=' + data.tableId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Error and warning Records based on filterType
     * @param data contains instanceId, entityId, assetId, filterType, size
     * @param s Success callback
     * @param f Failure callback
     */
    public getRecordsBasedOnFilterType = (
        data: {
            instanceId: string;
            entityId: string;
            filterType: string;
            size: number;
            pagingState: string;
            restApiName: string;
            parentRecordId: string;
        },
        s: (response: {
            response: {
                records: {
                    errors: string[];
                    warnings: string[];
                    grids: string[];
                    id: string;
                    fields: {
                        id: string;
                        value: string;
                        errors: string[];
                        warnings: string[];
                    }[];
                }[];
            };
        }) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_api}/process/v1/${data.restApiName}/${data.instanceId}/${data.entityId}/records?filterType=${
            data.filterType
        }&fetchSize=${data.size}&pagingState=${data.pagingState !== undefined ? data.pagingState : ''}&parentRecordId=${
            data.parentRecordId !== undefined ? encodeURIComponent(data.parentRecordId) : ''
        }`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get countries
     * @param s Success callback
     * @param f Failure callback
     */
    public getCountries = (s: (...args: any[]) => void, f: (...args: any[]) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/countries';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response || {});
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get states
     * @param data Contains country id
     * @param s Success callback
     * @param f Failure callback
     */
    public getStates = (data: { countryId: number }, s, f) => {
        const slug = environment.taxilla_user_identity_api + '/v1/countries/' + data.countryId + '/states';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response || {});
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Cities
     * @param data Contains state id
     * @param s Success callback
     * @param f Failure callback
     */
    public getCities = (data: { stateId: number }, s, f) => {
        const slug = environment.taxilla_user_identity_api + '/v1/states/' + data.stateId + '/cities';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Clients
     * @param data Contains org id
     * @param s Success callback
     * @param f Failure callback
     */
    public getClients = (
        data: { organizationId: string; pageNumber: number; pageSize: number; fetchAllProperties?: boolean; criterias?: {} },
        s,
        f
    ) => {
        let criteriaString = '';
        let slug = environment.taxilla_user_identity_api + '';
        if (data.criterias) {
            for (const key in data.criterias) {
                if (data.criterias.hasOwnProperty(key)) {
                    const element = data.criterias[key];
                    criteriaString += key + '~' + element + ',';
                }
            }
        }
        if (criteriaString) {
            slug +=
                '/v1/clients?search=' +
                criteriaString +
                'organizationId~' +
                data.organizationId +
                '&page=' +
                data.pageNumber +
                '&size=' +
                data.pageSize;
        } else {
            slug +=
                '/v1/clients?search=' +
                criteriaString +
                ',organizationId~' +
                data.organizationId +
                '&page=' +
                data.pageNumber +
                '&size=' +
                data.pageSize;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res, count) => {
                this._utils.hideLoading();
                s(res?.response?.items, res?.response?.totalItems);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Client details
     * @param data Contains org id, id
     * @param s Success callback
     * @param f Failure callback
     */
    public getFullDetailsOfClient = (data: { organizationId: string; id: string; fetchAllProperties?: boolean; criterias?: {} }, s, f) => {
        let criteriaString = '';
        let slug = environment.taxilla_user_identity_api + '';
        if (data.criterias) {
            for (const key in data.criterias) {
                if (data.criterias.hasOwnProperty(key)) {
                    const element = data.criterias[key];
                    criteriaString += key + '~' + element + ',';
                }
            }
        }

        if (criteriaString) {
            slug +=
                '/v1/clients?search=' +
                criteriaString +
                'organizationId~' +
                data.organizationId +
                ',id~' +
                data.id +
                '&fetchAllProperties=' +
                data.fetchAllProperties +
                '&needDecryption=true';
        } else {
            slug +=
                '/v1/clients?search=' +
                criteriaString +
                ',organizationId~' +
                data.organizationId +
                ',id~' +
                data.id +
                '&fetchAllProperties=' +
                data.fetchAllProperties +
                '&needDecryption=true';
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to add Client
     * @param data Contains org id
     * @param s Success callback
     * @param f Failure callback
     */
    public addClient = (data: any[], s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/clients';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to update client
     * @param s Success callback
     * @param f Failure callback
     */
    public updateClient = (data: clientData, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_user_identity_api + '/v1/clients/' + data.id;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete client
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteClient = (data: { clientId: string }, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_user_identity_api + '/v1/clients/' + data.clientId;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res?.msg);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to update ChangePassword
     * @param data Contains oldPassword, newPassword, confirmPassword values
     * @param s Success callback
     * @param f Failure callback
     */
    public updateChangePassword = (
        data: {
            oldPassword: string;
            newPassword: string;
            confirmPassword: string;
        },
        s,
        f
    ) => {
        const user = this._commonUtils.getFromStorage('user');
        const slug = environment.taxilla_user_identity_api + `/v1/users/${user.id}?action=CHANGE-PASSWORD`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to saveServiceAssetSetupAttr
     * @param data contains saveServiceAssetSetupAttr details
     * @param s Success callback
     * @param f Failure callback
     */
    public saveServiceAssetSetupAttr = (
        data: {
            serviceId: string;
            assetId: string;
            assetName: string;
            fields: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/services/' +
            data.serviceId +
            '/setupattributes?assetId=' +
            data.assetId +
            '&assetName=' +
            data.assetName;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.fields,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to getServiceSetUpAttributes
     * @param data contains getServiceSetUpAttributes details
     * @param s Success callback
     * @param f Failure callback
     */
    public getServiceSetUpAttributes = (
        data: {
            serviceId: string;
            assetId: string;
            assetName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/services/' +
            data.serviceId +
            '/setupattributes?assetId=' +
            data.assetId +
            '&assetName=' +
            data.assetName;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete record
     * @param payload Payload
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteRecord = (
        data: {
            instanceId: string;
            entityId: string;
            assetId: string;
            restApiName: string;
            payload: { id: string; parentRecordId: string }[];
            noAlerts?: boolean;
        },
        s: (response: { msg: string; response: { deletionStatus: { recordId: string; deleted: boolean }[] } }) => void,
        f: (response) => void
    ): void => {
        const slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/' + data.instanceId + '/' + data.entityId + '/records';
        const payload = [];
        data.payload.forEach((item) => payload.push(item.id));
        !data.noAlerts && this._utils.showLoading();
        this._comm.delete(
            slug,
            payload,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get asset permissions
     * @param data Payload
     * @param s Success callback
     * @param f Failure callback
     */
    public getAssetWithVersion = (data: { serviceId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/asset/metadata/versions?serviceId=' + data.serviceId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res: any) => {
                this._utils.hideLoading();
                s(res);
            },
            (res: any) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to fetch tag based metadata-types (assets, bridges, widgets)
     */
    public getTagBasedMetadataType = (
        data: {
            orgId: string;
            tagkey: string;
            tagValue: string;
            metadataType: string;
            pageSize: number;
            pagingState: string;
            noAlerts?: boolean;
        },
        s: (...args: any[]) => void,
        f: (...args: any[]) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/tags/' +
            data.orgId +
            '/' +
            data.tagkey +
            '/' +
            data.tagValue +
            '/' +
            data.metadataType +
            '?fetchSize=' +
            data.pageSize +
            '&pagingState=' +
            data.pagingState;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res: any) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res: any) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /* Method to get audit trail details
     * @param data contains assetid and assetdataid
     * @param s Success callback
     * @param f Failure callback
     */
    public fetchAuditTrails = (
        data: { assetDataId: string; auditType: string; pagingState: string; size: number },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.taxilla_api}/audit-trail?auditId=${data.assetDataId}&auditType=${data.auditType}&fetchSize=${
            data.size || 10
        }`;
        if (data?.pagingState?.length > 0) {
            slug += '&pagingState=' + data.pagingState;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to download audit trail zip file
     * @param auditData which contains orgId, orgConfigName and method
     * @param s for success function
     * @param f for failure function
     */
    downloadIntegrationAuditTrail = (auditData: {
        orgId: string;
        orgConfigName: string;
        method: string;
        locationId?: string;
        serviceId?: string;
        type?: string;
        appConfigName?: string;
    }) => {
        let slug: string;
        const { orgId, orgConfigName, method, locationId, serviceId, type, appConfigName } = auditData;
        if (!appConfigName) {
            slug = `${environment.integration_api}/misc/v1/audit-trail?orgId=${orgId}&orgConfigName=${orgConfigName}&method=${method}`;
        } else {
            slug = `${environment.integration_api}/misc/v1/audit-trail?orgId=${orgId}&orgConfigName=${orgConfigName}&method=${method}&locationId=${locationId}&serviceId=${serviceId}&type=${type}&appConfigName=${appConfigName}`;
        }
        this._utils.openUrlInNewTab(slug);
    };

    /**
     * Method to download audit trail file
     * @param data contains assetid, taskid and assetdataid
     * @param s Success callback
     * @param f Failure callback
     */
    public downloadAuditLogs = (
        data: { restApiName: string; instanceId: string; taskId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/' + data.restApiName + '/' + data.instanceId + '/audit-report?taskId=' + data.taskId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get generated audit reports
     * @param data contains assetid, instanceid
     * @param s Success callback
     * @param f Failure callback
     */
    public getGeneratedAuditReports = (data: { assetId: string; instanceId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/asset/' + data.assetId + '/audit-report/' + data.instanceId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get audit report metadata
     * @param data contains assetid
     * @param s Success callback
     * @param f Failure callback
     */
    public getAuditReportMetadata = (data: { assetId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/metadata/assets/' + data.assetId + '/audit/transformations';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to generate audit report
     * @param s Success callback
     * @param f Failure callback
     */
    public generateAuditReport = (
        data: { assetId: string; instanceId: string; chainName: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api + '/asset/' + data.assetId + '/audit-report/' + data.instanceId + '?chainName=' + data.chainName;
        this._utils.showLoading();
        this._comm.post(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get audit trail changes
     * @param data contains assetid, taskid and assetdataid
     * @param s Success callback
     * @param f Failure callback
     */
    public getAuditChanges = (
        data: { assetDataId: string; timeStamp: number; parentRecordId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/asset/audit/entities?assetDataId=' + data.assetDataId;
        if (data.parentRecordId?.length > 0) {
            slug += '&parentEntityRecordId=' + encodeURIComponent(data.parentRecordId);
        }
        if (data.timeStamp > 0) {
            slug += '&timeStamp=' + data.timeStamp;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get audit trail changes
     * @param data contains assetid, taskid and assetdataid
     * @param s Success callback
     * @param f Failure callback
     */
    public getEntityRecordsByAuditLog = (
        data: {
            assetDataId: string;
            entityId: string;
            assetId: string;
            parentEntityRecordId: string;
            auditType: string;
            timeStamp: number;
            pagingState: string;
            size: number;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug =
            environment.taxilla_api +
            '/asset/audit/' +
            encodeURIComponent(data.assetDataId) +
            '/' +
            data.entityId +
            '/records?assetId=' +
            data.assetId +
            '&assetDataId=' +
            encodeURIComponent(data.assetDataId) +
            '&entityId=' +
            data.entityId +
            '&timeStamp=' +
            data.timeStamp +
            '&fetchSize=' +
            (data.size || 10);
        if (data.parentEntityRecordId?.length > 0) {
            slug += '&parentEntityRecordId=' + encodeURIComponent(data.parentEntityRecordId);
        }
        if (data.pagingState?.length > 0) {
            slug += '&pagingState=' + data.pagingState;
        }
        // s({
        //     'response': {
        //         'entityRecords': [
        // {
        //     'id': '32917450-e85c-11ea-9e16-f7d77a59be2c#-',
        //     'modified': false,
        //     'fields': {
        //         'bone': {
        //             'id': 'bone',
        //             'valueDifference': {
        //                 'leftValue': '10-2018',
        //                 'rightValue': '11-2018'
        //             },
        //             'modified': false,
        //             'message': {
        //                 'errors': [],
        //                 'warnings': []
        //             }
        //         },
        //         'btwo': {
        //             'id': 'btwo',
        //             'valueDifference': {
        //                 'leftValue': '27AABCM0839M1Z9',
        //                 'rightValue': '1127AABCM0839M1A5'
        //             },
        //             'modified': false,
        //             'message': {
        //                 'errors': [],
        //                 'warnings': []
        //             }
        //         },
        //         'fstring': {
        //             'id': 'fstring',
        //             'valueDifference': {
        //                 'leftValue': '27AABCM0839M1Z9',
        //                 'rightValue': '1127AABCM0839M1A5'
        //             },
        //             'modified': false,
        //             'message': {
        //                 'errors': [],
        //                 'warnings': []
        //             }
        //         },
        //         'fint': {
        //             'id': 'fint',
        //             'valueDifference': {
        //                 'leftValue': '27AABCM0839M1Z9',
        //                 'rightValue': '1127AABCM0839M1A5'
        //             },
        //             'modified': false,
        //             'message': {
        //                 'errors': [],
        //                 'warnings': []
        //             }
        //         },
        //         'yesno': {
        //             'id': 'yesno',
        //             'valueDifference': {
        //                 'leftValue': false,
        //                 'rightValue': true
        //             },
        //             'modified': false,
        //             'message': {
        //                 'errors': [],
        //                 'warnings': []
        //             }
        //         },
        //         'fdate': {
        //             'id': 'fdate',
        //             'valueDifference': {
        //                 'leftValue': '17/12/2020',
        //                 'rightValue': '18/12/2020',
        //             },
        //             'modified': false,
        //             'message': {
        //                 'errors': [],
        //                 'warnings': []
        //             }
        //         },
        //     },
        //     'grids': {},
        //     'message': {
        //         'errors': [],
        //         'warnings': []
        //     },
        //     'active': false
        // },
        //             {
        //                 'id': '32917450-e85c-11ea-9e16-f7d77a59be2c#-',
        //                 'modified': false,
        //                 'fields': {
        //                     'fone': {
        //                         'id': 'fone',
        //                         'valueDifference': {
        //                             'leftValue': '10-2018',
        //                             'rightValue': '11-2018'
        //                         },
        //                         'modified': false,
        //                         'message': {
        //                             'errors': [],
        //                             'warnings': []
        //                         }
        //                     },
        //                     'ftwo': {
        //                         'id': 'ftwo',
        //                         'valueDifference': {
        //                             'leftValue': '27AABCM0839M1Z9',
        //                             'rightValue': '1127AABCM0839M1A5'
        //                         },
        //                         'modified': false,
        //                         'message': {
        //                             'errors': [],
        //                             'warnings': []
        //                         }
        //                     },
        //                     'fconf': {
        //                         'id': 'fconf',
        //                         'valueDifference': {
        //                             'leftValue': '27AABCM0839M1Z9',
        //                             'rightValue': '1127AABCM0839M1A5'
        //                         },
        //                         'modified': false,
        //                         'message': {
        //                             'errors': [],
        //                             'warnings': []
        //                         }
        //                     },
        //                 },
        //                 'grids': {},
        //                 'message': {
        //                     'errors': [],
        //                     'warnings': []
        //                 },
        //                 'active': true
        //             },
        //             {
        //                 'id': '32917450-e85c-11ea-9e16-f7d77a59be2d#-',
        //                 'modified': false,
        //                 'fields': {
        //                     'fone': {
        //                         'id': 'fone',
        //                         'valueDifference': {
        //                             'leftValue': '10-2018',
        //                             'rightValue': '11-2018'
        //                         },
        //                         'modified': false,
        //                         'message': {
        //                             'errors': [],
        //                             'warnings': []
        //                         }
        //                     },
        //                     'ftwo': {
        //                         'id': 'ftwo',
        //                         'valueDifference': {
        //                             'leftValue': '27AABCM0839M1Z9',
        //                             'rightValue': '1127AABCM0839M1A5'
        //                         },
        //                         'modified': false,
        //                         'message': {
        //                             'errors': [],
        //                             'warnings': []
        //                         }
        //                     },
        //                     'fconf': {
        //                         'id': 'fconf',
        //                         'valueDifference': {
        //                             'leftValue': '27AABCM0839M1Z9',
        //                             'rightValue': '1127AABCM0839M1A5'
        //                         },
        //                         'modified': false,
        //                         'message': {
        //                             'errors': [],
        //                             'warnings': []
        //                         }
        //                     },
        //                 },
        //                 'grids': {},
        //                 'message': {
        //                     'errors': [],
        //                     'warnings': []
        //                 },
        //                 'active': false
        //             }
        //         ],
        //         'message': {
        //             'errors': [],
        //             'warnings': []
        //         },
        //         'searchAfter': []
        //     }
        // });
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get audit change log
     * @param data contains assetId, taskId, assetdataId, entityId, recordId, parentRecordId
     * @param s Success callback
     * @param f Failure callback
     */
    public getAuditRecordChangeLog = (
        data: { assetId: string; assetDataId: string; entityId: string; recordId: string; parentRecordId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/asset/audit/change-log?assetId=' +
            data.assetId +
            '&assetDataId=' +
            data.assetDataId +
            '&entityId=' +
            data.entityId +
            '&entityRecordId=' +
            encodeURIComponent(data.recordId) +
            '&parentEntityRecordId=' +
            encodeURIComponent(data.parentRecordId || '');
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get record change log
     * @param data contains assetId, assetdataId, entityId, recordId, parentRecordId
     * @param s Success callback
     * @param f Failure callback
     */
    public getRecordChangeLog = (data: any, s: (response) => void, f: (response) => void) => {
        let slug =
            environment.taxilla_api +
            '/asset/audit/change-log?assetId=' +
            data.assetId +
            '&assetDataId=' +
            data.assetDataId +
            '&entityId=' +
            data.entityId +
            '&entityRecordId=' +
            encodeURIComponent(data.recordId);
        if (data.parentRecordId?.length > 0 && data.parentRecordId !== '-') {
            slug += '&parentEntityRecordId=' + encodeURIComponent(data.parentRecordId);
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to check if any organization exist with the given name
     * @param name OrganizationName
     * @param s Success callback
     * @param f Failure callback
     */
    public isOrganizationExists = (orgLoginId: string, s: (response) => void, f: (response) => void): void => {
        // const slug = environment.taxilla_user_identity_api + '/registration/' + name;
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/exists-with-login-id/' + orgLoginId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get generated repprts
     * @param data contains assetId, assetDataId
     * @param s Success callback
     * @param f Failure callback
     */
    public getGeneratedReports = (
        data: { assetDataId: string; restApiName?: string; assetMetaUId?: string; requestId?: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        this.fetchReports(data, s, f);
    };

    /**
     * Method to fetch instance reports
     * @param data Contains assetMetaUId and assetDataId
     * @param s Success callback
     * @param f Failure callback
     */
    public fetchReports = (
        data: { assetDataId: string; restApiName?: string; assetMetaUId?: string; requestId?: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug;
        if (data.restApiName) {
            slug =
                environment.taxilla_api +
                '/internal/process/' +
                data.restApiName +
                '/' +
                data.assetDataId +
                '/reports?executionPlan=ON_STAGE_EVENT';
        } else {
            slug =
                environment.taxilla_api +
                '/asset/reports?assetId=' +
                data.assetMetaUId +
                '&assetDataId=' +
                data.assetDataId +
                '&associatedReports=&category=TARGET';
            if (data.requestId) {
                slug += '&requestId=' + data.requestId;
            }
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get context details with autorization key
     * @param data Contains auth key
     * @param s Success callback
     * @param f Failure callback
     */
    public getContextDetailsWithAutorizationKey = (data: { key: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/pre-login/auth-key/' + data.key;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to logout
     * @param s Success callback
     * @param f Failure callback
     */
    public logout = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/logout';
        this._utils.showLoading();
        this._comm.post(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get integration records for a particular configuration
     * @param data Contains config name, isMaster boolean data
     * @param s Success callback
     * @param f Failure callback
     */
    public getIntegrationConfigurations = (
        data: {
            config: string;
            dontFetchUsingMasters: boolean;
            isMaster: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const mastersSetting = data.dontFetchUsingMasters ? '' : `?masterConfigs=${data.isMaster}`;
        let slug = `${environment.integration_api}/v1/config/org/references/${data.config.toUpperCase()}`;
        if (data.isMaster === undefined) {
            slug = `${environment.integration_api}/v1/config/org?method=${data.config.toUpperCase()}&masterConfigs=true`;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res: any) => {
                this._utils.hideLoading();
                s(res);
            },
            (res: any) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get integration records for a particular configuration
     * @param id ID of the configuration
     * @param s Success callback
     * @param f Failure callback
     */
    public getIntegrationConfigurationById = (id: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + `/IntegrationConfiguration/config/${id}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res && res.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get all master tables
     * @param data Contains currentOrganization Id
     * @param s Success callback
     * @param f Failure callback
     */
    public getAllMasters = (
        data: { currentOrganizationId: string; hideAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.currentOrganizationId + '/masters/all';
        !data.hideAlerts && this._utils.showLoading(undefined, 'getMasters');
        this._comm.get(
            slug,
            (res) => {
                !data.hideAlerts && this._utils.hideLoading(undefined, 'getMasters');
                s(res);
            },
            (res) => {
                !data.hideAlerts && this._utils.hideLoading(undefined, 'getMasters');
                f(res);
            }
        );
    };

    /**
     * Method to save integration record
     */
    public saveIntegrationRecord = (payload: IntegrationRecordPayload, s: (response: any) => void, f: (...args: any[]) => void) => {
        const slug = `${environment.integration_api}/v1/config/org`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to validate integration record
     */
    public validateIntegrationRecord = (payload: IntegrationRecordPayload, s: (response: any) => void, f: (...args: any[]) => void) => {
        const slug = `${environment.integration_api}/v1/oauth2/authorization/url`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to save integration record
     */
    public testIntegrationRecordConnection = (
        payload: {
            configuredData: string;
            appId: string;
            name: string;
            organizationId: string;
            type: string;
        },
        s: (response: any) => void,
        f: (...args: any[]) => void
    ) => {
        const slug = `${environment.integration_api}/v1/config/org/test`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res && res.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete integration record
     */
    public deleteIntegrationRecord = (
        payload: {
            name: string;
            organizationId: string;
            type: string;
        },
        s: (response: any) => void,
        f: (...args: any[]) => void
    ) => {
        const slug = `${environment.integration_api}/v1/config/org/${
            payload.organizationId
        }/delete?method=${payload.type.toLocaleUpperCase()}&configName=${payload.name}`;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get master OutboundMappings
     * @param data Contains organizationId, master table id
     * @param s Success callback
     * @param f Failure callback
     */
    public getOutboundMappings = (
        data: {
            organizationId: string;
            tableId: string;
        },
        s,
        f
    ) => {
        const slug =
            environment.taxilla_api +
            '/organizations/' +
            data.organizationId +
            '/masters/metadata/outbound-mappings?masterName=' +
            data.tableId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get master report details
     * @param data Contains organizationId, master table id
     * @param s Success callback
     * @param f Failure callback
     */
    public getReportDetails = (
        data: {
            organizationId: string;
            tableId: string;
        },
        s,
        f
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/masters/report/details/' + data.tableId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Generate reports of Masters
     * @param data contains restApiName transformationName
     * @param s success callback
     * @param f failure callback
     */
    public generateMasterReport = (
        data: {
            transformationName: string;
            restApiName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/masters/v1/' + data.restApiName + '/reports/' + data.transformationName;
        this._utils.showLoading();
        this._comm.post(
            slug,
            {},
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get partners
     * @param data contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getPartners = (organizationId: string, s: (response) => void, f: (response) => void) => {
        const slug =
            environment.taxilla_user_identity_api + '/v1/org-associations?search=organizationId:' + organizationId + ',status:APPROVED';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get master uploaded files
     * @param data Contains organizationId, master table id
     * @param s Success callback
     * @param f Failure callback
     */
    public getMasterUploadFiles = (
        data: {
            organizationId: string;
            masterTableId: string;
        },
        s,
        f
    ) => {
        const slug =
            environment.taxilla_api +
            '/organizations/' +
            data.organizationId +
            '/masters/uploadedFileDetails?masterId=' +
            data.masterTableId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get partners
     * @param data contains page, size, invitationType, searchString, actionType, status
     * @param s Success callback
     * @param f Failure callback
     */
    public getAllSentInvitations = (
        data: {
            searchString: string;
            actionType: string;
            status: string;
        },
        s: (response) => any,
        f: (response) => any
    ) => {
        const slug = `${environment.taxilla_user_identity_api}/v1/partners/invitations?action=${data.actionType}&status=${data.status}&search=${data.searchString}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Manage partners
     * @param organizationId contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getReceivedPartners = (
        data: {
            organizationId: string;
            status: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_user_identity_api}/v1/partners/invitations?action=RECEIVED&status=${data.status}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Manage partners
     * @param organizationId contains organization ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getPartnersInvitedByOrg = (
        data: {
            organizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/collaboration/v1/partners';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get partner associations
     * @param s Success callback
     * @param f Failure callback
     */
    public getPartnerOrgs = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/org-associations?search=';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * send request for partner invitation
     * @param data contains association Array
     * @param s success callback
     * @param f failure callback
     */
    public sendPartnerInvitation = (
        data: {
            invitationArray: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/partners/invitations';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.invitationArray,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * check Masters Records
     * @param data contains masterDataRow, masterSearchDetail, organizationId
     * @param s success callback
     * @param f failure callback
     */
    public checkMasterRecord = (
        data: {
            masterDataRow: {
                columnValues: {
                    [property: string]: string;
                };
                tableId: string;
                unitId: string;
            };
            masterSearchDetail: {
                searchString: string;
                pageNo: number;
            };
            organizationId: string;
        },
        s: (response: MasterRecordsResponse) => void,
        f: (response) => void
    ) => {
        const dataToSend = {
            masterDataRow: data.masterDataRow,
            masterSearchDetail: data.masterSearchDetail,
        };
        const slug = environment.taxilla_api + '/organizations/' + data.organizationId + '/masters/records';
        this._utils.showLoading();
        this._comm.post(
            slug,
            dataToSend,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get transformations by asset version
     * @param data Contains assetName, assetVersionUid
     * @param s Success callback
     * @param f Failure callback
     */
    public getTransformationsByAssetVersion = (
        data: {
            assetName: string;
            assetVersionUid: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/asset/metadata/transformations-by-version?assetName=' +
            data.assetName +
            '&assetVersionUID=' +
            data.assetVersionUid;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get inbox messages
     * @param data Contains message type,fetch size,next paging stage, previous paging stage
     * @param s Success callback
     * @param f Failure callback
     */
    public getInboxMessages = (
        data: {
            messageType: string;
            fetchSize: number;
            nextPagingState: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.notifications_api}/v1/workqueue/messages?fetchSize=${data.fetchSize || 20}&pagingState=${
            (data.nextPagingState && encodeURIComponent(data.nextPagingState)) || ''
        }&eventType=${data.messageType}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get notifications
     * @param s Success callback
     * @param f Failure callback
     */
    public getNotifications = (s: (response) => void, f: (response) => void) => {
        const slug = `${environment.notifications_api}/v1/configs`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to edit Event Subscriptions
     * @param data Contains event array of objects
     *   [{eventType: "TASK_FAILED",isEmailEnabled: false,isWorkQueueEnabled: true  }]
     * @param s Success callback
     * @param f Failure callback
     */
    public updateNotification = (data: any, s: (response) => void, f: (response) => void) => {
        const slug = `${environment.notifications_api}/v1/configs`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to make record as readed
     * @param data Contains message type, message id
     * @param s Success callback
     * @param f Failure callback
     */
    public markAsRead = (
        {
            noAlerts,
            messageId,
        }: {
            noAlerts?: boolean;
            messageId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.notifications_api}/v1/workqueue/messages/${messageId}?action=MARK_AS_READ`;
        !noAlerts && this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                !noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to get count of active action messages
     * @param s Success callback
     * @param f Failure callback
     */
    public getCountOfActiveMessages = (s: (response) => void, f: (response) => void) => {
        const slug = `${environment.notifications_api}/v1/workqueue/messages?fetchSize=0`;
        this._utils.showLoading(undefined, 'activeMessagesCount');
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading(undefined, 'activeMessagesCount');
                s(res);
            },
            (res) => {
                this._utils.hideLoading(undefined, 'activeMessagesCount');
                f(res);
            }
        );
    };

    /**
     * method to getEventTypes types
     * @param s Success callback
     * @param f Failure callback
     */
    public getEventTypes = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/asset/event-types';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to getAllEvents
     * @param data contains eventType, fetchSize, monthYear
     * @param s Success callback
     * @param f Failure callback
     */
    public getAllEvents = (
        data: {
            payload: {
                eventType?: string;
                fetchSize?: number;
                monthYear?: string;
                pagingState?: string;
                resourceId?: string;
                noAlerts?: boolean;
            };
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/asset/event-log';
        !data.noAlerts && this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to get all flexFields
     * @param s Success callback
     * @param f Failure callback
     */
    public getFlexFields = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/flex-field-definitions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to getPlatformVersion
     * @param s Success callback
     * @param f Failure callback
     *
     * getPlatformVersion = (s: (response) => void, f: (response) => void) => {
     *     const slug = environment.taxilla_api + '/platform-version';
     *     // this._utils.showLoading();
     *     this._comm.get(slug, (res) => {
     *         // this._utils.hideLoading();
     *         s(res);
     *     }, (res) => {
     *         // this._utils.hideLoading();
     *         f(res);
     *     });
     * }
     */

    /**
     * method to transformation chains
     * @parma <T> Contains assetName and assetId
     * @param s Success callback
     * @param f Failure callback
     */
    public getChains = ({ assetName, assetId }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/asset/metadata/chains?assetName=' + assetName + '&assetId=' + assetId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to asset to asset chains
     * @parma <T> Contains assetName and assetId
     * @param s Success callback
     * @param f Failure callback
     */
    public getAssetToAssetChains = (
        data: { assetName: string; assetId: string; noAlerts: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api + '/asset/metadata/asset-to-asset-chains?assetName=' + data.assetName + '&assetId=' + data.assetId;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to transformation names
     * @parma <T> Contains chainName, assetName and assetId
     * @param s Success callback
     * @param f Failure callback
     */
    public getTransformationNamesInChain = (
        { chainName, assetName, assetId, repositoryId }: { chainName: string; assetName: string; assetId: string; repositoryId?: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        repositoryId = repositoryId ? repositoryId + '/' : '';
        const slug =
            environment.taxilla_api +
            '/asset/metadata/' +
            repositoryId +
            chainName +
            '/transformations?assetName=' +
            assetName +
            '&assetId=' +
            assetId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to source names
     * @param T Contains transformationName, assetName, repository id and assetId
     * @param s Success callback
     * @param f Failure callback
     */
    public getSourceNamesInChain = (
        {
            transformationName,
            assetName,
            assetId,
            repositoryId,
        }: { transformationName: string; assetName: string; assetId: string; repositoryId?: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        repositoryId = repositoryId ? repositoryId + '/' : '';
        const slug =
            environment.taxilla_api +
            '/asset/metadata/' +
            repositoryId +
            transformationName +
            '/sources?assetName=' +
            assetName +
            '&assetId=' +
            assetId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to post configurations
     * @param payload Contains payload
     * @param s Success callback
     * @param f Failure callback
     */
    public saveResourceConfiguration = (payload: any, s: (response) => void, f: (response) => void) => {
        const slug = `${environment.integration_api}/v1/config/app`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to post configurations
     * @param payload Contains payload
     * @param s Success callback
     * @param f Failure callback
     */
    public saveMasterScheduler = (payload: any, s: (response) => void, f: (response) => void) => {
        const slug = `${environment.integration_api}/v1/masters-scheduler-configurations`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to get integration source configurations
     * @parma data Contains integration type and asset id
     * @param s Success callback
     * @param f Failure callback
     */
    public getAppResourceIntegrations = (
        { integrationType, serviceId }: { integrationType: string; serviceId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/config/app/${serviceId}?integrationType=${integrationType}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to get integration source configurations
     * @parma data Contains integration type and asset id
     * @param s Success callback
     * @param f Failure callback
     */
    public getAppMasterSchedulerIntegrations = (
        { integrationType, serviceId }: { integrationType: string; serviceId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/masters-scheduler-integrations/${serviceId}?type=${integrationType}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to delete integration source configurations
     * @parma data Contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteAppIntegrationConfiguration = (
        {
            locationId,
            serviceId,
            integrationType,
            configName,
        }: { locationId: string; serviceId: string; integrationType: string; configName: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/config/app/${locationId}/${serviceId}?configName=${configName}&integrationType=${integrationType}`;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to delete integration source configurations
     * @parma data Contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteAppMasterSchedulerConfiguration = (
        payload: AppNewIntegrationRecordPayload,
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/masters-scheduler-integrations`;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to delete integration source configurations
     * @parma data contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    public runAppIntegrationConfiguration = (
        {
            locationId,
            serviceId,
            configName,
            api,
            isEninvoiceApp,
        }: { locationId: string; serviceId: string; configName: string; api?: string; isEninvoiceApp?: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug: string;
        if (api === 'masterinbound') {
            slug = `${environment.integration_api}/v1/masters-scheduler-integrations/${locationId}/${serviceId}/${configName}/syncNow`;
        } else {
            slug = `${environment.integration_api}/v1/config/app/${locationId}/${serviceId}/${configName}/execute`;
        }
        this._utils.showLoading();
        this._comm.post(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to test integration source configurations
     * @parma data contains config data
     * @param s Success callback
     * @param f Failure callback
     */
    public testAppIntegrationConfiguration = (
        data: {
            chainName: string;
            configName: string;
            configuredData: string;
            createdBy: string;
            createdOn: string;
            id: string;
            integrationMethod: string;
            integrationType: string;
            locationId: string;
            resourceName: string;
            schedulerExpression: string;
            serviceId: string;
            transformationName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/config/app/test`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get authorization systems for RESTAPI integration
     */
    public getIntegrationsConfig = (s: (response) => void, f: (response) => void) => {
        const slug = `${environment.integration_api}/v1/common/config`;
        this._comm.get(
            slug,
            (res) => {
                s(res || []);
            },
            (res) => {
                f(res);
            }
        );
    };

    /**
     * Method to get authorization systems for RESTAPI integration
     */
    public getAuthorizationSystems = (s: (response) => void, f: (response) => void) => {
        const slug = `${environment.integration_api}/v1/oauth2/code/config`;
        this._comm.get(
            slug,
            (res) => {
                s(res || []);
            },
            (res) => {
                f(res);
            }
        );
    };

    /**
     * Method to create or update authorization systems
     */
    public submitAuthorizationSystems = (
        data: { authorizationSystem: AuthorizationSystem; update: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.integration_api}/v1/oauth2/code/config/${data.update ? 'update' : 'save'}`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.authorizationSystem,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get security questions
     * @param s Success callback
     * @param f Failure callback
     */
    public getSecurityQuestions = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/multi-factor-auth/secret-questions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response || {});
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get random security questions
     * @param s Success callback
     * @param f Failure callback
     */
    public getRandomSecurityQuestions = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/multi-factor-auth/secret-questions/random';

        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response || {});
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to evaluate security questions
     * @param s Success callback
     * @param f Failure callback
     */
    public evaluateSecurityQuestions = (data: any, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/multi-factor-auth/secret-questions/evaluate';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to get Countries, States, Locations
     * @param s Success callback
     * @param f Failure callback
     */
    public getLocationsCodes = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/location-codes';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response || {});
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to validate filing attributes
     * @param s Success callback
     * @param f Failure callback
     */
    public validateFilingAttributes = (
        data: {
            payload: any;
            restApiName: string;
            bridgeRestApiName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/version?';
        data.payload.entityAttributes?.forEach((attribute, index) => {
            if (index > 0) {
                slug += '&';
            }
            slug += attribute.fullId + '=' + (attribute.value !== undefined ? attribute.value : attribute.values);
        });
        if (data.bridgeRestApiName) {
            slug += '&bridgeRestApiName=' + data.bridgeRestApiName;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get default widgets
     * @param data
     * @param s
     * @param f
     */
    public getDefaultWidgets = (
        data: {
            showLoader: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/dashboard/widgets/default';
        !!data.showLoader && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !!data.showLoader && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !!data.showLoader && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get widget data
     * @param data
     * @param s
     * @param f
     */
    public getWidgetData = (
        data: { widgetId: string; refresh: boolean; userName: string; showLoader: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/dashboard/widget/response?widgetId=' +
            data.widgetId +
            '&refresh=' +
            data.refresh +
            '&userId=' +
            data.userName;
        if (data.showLoader) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (data.showLoader) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (data.showLoader) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get widget coordinates
     * @param data
     * @param s
     * @param f
     */

    public getWidgetCoordinates = (
        data: { unitId: string; widgetId: string; showLoader: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/dashboard/' + data.unitId + '/widget/' + data.widgetId + '/coordinates';
        if (data.showLoader) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (data.showLoader) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (data.showLoader) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to update widget coordinates
     * @param data
     * @param s
     * @param f
     */

    public updateWidgetCoordinates = (
        data: { unitId: string; widgetId: string; coordinates: any[]; noAlerts: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/dashboard/' + data.unitId + '/widget/' + data.widgetId + '/coordinates';
        const requestData = {
            method: 'raw',
            raw: data.coordinates,
        };
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            data.coordinates,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };
    /**
     * Method to delete widget coordinates
     * @param data
     * @param s
     * @param f
     */

    public deleteWidgetCoordinates = (
        data: { unitId: string; widgetId: string; showLoader: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/dashboard/' + data.unitId + '/widget/' + data.widgetId + '/coordinates';
        if (data.showLoader) {
            this._utils.showLoading();
        }
        this._comm.delete(
            slug,
            null,
            (res) => {
                if (data.showLoader) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (data.showLoader) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get widget mange permissions
     * @param s
     * @param f
     */

    public widgetManagePermissions = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/permissions?actions=MANAGE_SUBSCRIPTION';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to get widget organizations
     * @param data
     * @param s
     * @param f
     */

    public getWidgetOrganizations = (data: { orgId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations?search=parent.id:' + data.orgId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Widget Subscription
     * @param data
     * @param s
     * @param f
     */

    public getWidgetSubscriptions = (
        data: { orgIds: any[]; itemIds: any[]; subscriptionType: string; page: any; size: any; status: any },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.orgIds + '/subscriptions/_search';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete widget
     * @param data contains Widget ID, current org id
     * @param s Success callback
     * @param f Failure callback
     */
    public removeWidget = (data: { widgetId: string; orgId: string }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/dashboard/' + data.orgId + '/widget/' + data.widgetId;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to get bridge source nodes
     * @param s Success callback
     * @param f Failure callback
     */
    public getBridgeSourceNodes = (
        data: {
            service: AssetService;
            bridge: AssetService;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/services/source-nodes?bridgeId=' +
            data.bridge.assetMetaUId +
            '&assetId=' +
            data.service.assetMetaUId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to delete attachment
     */
    public deleteFieldAttachment = (
        {
            fieldId,
            fileUri,
            assetDataId,
            assetId,
            entityId,
            recordId,
            parentRecordId,
        }: {
            fieldId: string;
            fileUri: string;
            assetDataId: string;
            assetId: string;
            entityId: string;
            recordId: string;
            parentRecordId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api +
            '/asset/attachments?fileId=' +
            fieldId +
            '&fileUri=' +
            fileUri +
            '&assetDataId=' +
            assetDataId +
            '&assetId=' +
            assetId +
            '&entityId=' +
            entityId +
            '&entityRecordId=' +
            encodeURIComponent(recordId) +
            '&parentRecordId=' +
            ((parentRecordId && encodeURIComponent(parentRecordId)) || '');
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get application properties
     * @param s Success callback
     * @param f Failure callback
     */
    public getApplicationProperties = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/application-properties';
        this._comm.get(
            slug,
            (res) => {
                s(res);
            },
            (res) => {
                f(res);
            }
        );
    };
    /**
     * Method to get application properties from integration
     * @param s Success callback
     * @param f Failure callback
     */
    public getApplicationPropertiesFromIntegration = (s: (response) => void, f: (response) => void) => {
        const slug = environment.integration_api + '/misc/properties';
        this._comm.get(
            slug,
            (res) => {
                s(res);
            },
            (res) => {
                f(res);
            }
        );
    };

    /**
     * Method to get application misc properties
     * @param s Success callback
     * @param f Failure callback
     */
    public getApplicationMiscProps = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/properties';
        this._comm.get(
            slug,
            (res) => {
                s(res?.response || {});
            },
            (res) => {
                f(res);
            }
        );
    };

    /**
     * Method to get provider
     * @param s Success callback
     * @param f Failure callback
     */
    public getProvider = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/organizations/provider?url=' + document.domain;
        this._comm.get(
            slug,
            (res) => {
                s(res);
            },
            (res) => {
                f(res);
            }
        );
    };

    /** Method to get an Integration Status
     * @param data Contains requestId, assetId,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getIntegrationStatus = (
        data: { requestId: string; restApiName: string; orgId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/outbound/requests/${data.requestId}/status?restApiName=${data.restApiName}&orgId=${data.orgId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Integration Refresh Instance Status
     * @param data Contains requestId, instanceId, serviceId data,
     * @param s Success callback
     * @param f Failure callback
     */
    public getIntegrationRefreshStatusForInstance = (
        data: { requestId: string; instanceId: string; restApiName: string; orgId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.integration_api}/v1/instance/retry-integrations/${data.instanceId}?requestId=${data.requestId}&restApiName=${data.restApiName}&orgId=${data.orgId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Integration Refresh Request Status
     * @param data Contains requestId, orgId data,
     * @param s Success callback
     * @param f Failure callback
     */
    public getIntegrationRefreshStatusForRequest = (
        data: { restApiName: string; requestId: string; orgId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/requests/retry-integrations/${data.requestId}?restApiName=${data.restApiName}&orgId=${data.orgId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to get an Integration Status for instance
     * @param data Contains requestId, assetId,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getIntegrationStatusForInstance = (
        data: { restApiName: string; instanceId: string; orgId: string },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.integration_api}/v1/outbound/instances/${data.instanceId}/status?restApiName=${data.restApiName}&orgId=${data.orgId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get a file
     * @param url: URL of the file to download
     * @param s: Success callback method
     * @param f: Failure callback method
     */
    public getFile = (url: string, s: (res: Blob) => void, f: (...args: any[]) => void) => {
        this._utils.showLoading();
        this._comm.get(
            url,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            null,
            {
                responseType: 'blob',
                noUrlPrefix: true,
            }
        );
    };
    /**
     * Method to get All Widget list
     * @param data
     * @param s
     * @param f
     */

    public getAllWidgetsList = (data: { widgetIds: any[] }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/dashboard/widgets/non-default?existingWidgetIds=' + data.widgetIds;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to save WidgetSettings
     * @param data
     * @param s
     * @param f
     */

    public saveWidgetSettings = (data: {}, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/dashboard/widget/settings';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get asset tags
     */
    public getAssetTags = (
        { serviceId, tagKey, noAlerts }: { serviceId: string; tagKey?: string; noAlerts?: boolean },
        s: (response: {
            response: {
                taggedMetadatas: {
                    metadataId: string;
                    metadataName: string;
                    metadataType: string;
                    repositoryId: string;
                    serviceId: string;
                    tagKey: string;
                    tagValue: string;
                }[];
            };
        }) => void,
        f: (response) => void
    ) => {
        let slug = '';
        if (tagKey) {
            slug = environment.taxilla_api + '/tags/service/' + serviceId + '/' + tagKey;
        } else {
            slug = environment.taxilla_api + '/tags/service/' + serviceId;
        }
        if (!noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * method to delete master records
     */
    public deleteMastersRecords = (
        data: {
            masterRestApiName: string;
            masterDataRowKeys: any[];
        },
        s,
        f
    ) => {
        const url = environment.taxilla_api + '/masters/v1/' + data.masterRestApiName;
        this._utils.showLoading();
        this._comm.delete(
            url,
            data.masterDataRowKeys,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get requests based on new macre search modal
     * @param data contains restApiServiceName, entityId, filterCriteria
     * @param s Success callback
     * @param f Failure callback
     */
    public searchRequests = (
        data: {
            entityCriterias: any[];
            size: number;
            searchAfter?: any;
            from: number;
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_search_api + '/requests/_search';
        if (data.size) {
            data.entityCriterias['size'] = data.size;
        }
        if (data.searchAfter) {
            data.entityCriterias['searchAfter'] = data.searchAfter;
        }
        if (data.from) {
            data.entityCriterias['from'] = data.from;
        }
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            data.entityCriterias,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get partners
     * @param data contains criteria, page, size
     * @param s Success callback
     * @param f Failure callback
     */
    public getRelatedTenants = (
        data: {
            orgId: string;
            serviceId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + data.orgId + '/subscriptions/' + data.serviceId + '/related-orgs';
        // Making this call as non-blocking on page
        // this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                // this._utils.hideLoading();
                s(res);
            },
            (res) => {
                // this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get error report
     * @param data contains criteria, page, size
     * @param s Success callback
     * @param f Failure callback
     */
    public generateErrorReport = (
        data: {
            assetId: string;
            requestId: string;
            repositoryId: string;
            transformation: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_api}/asset/reports?assetId=${data.assetId}&assetDataId=${data.requestId}&associatedReports=${data.repositoryId}/${data.transformation}&requestId=${data.requestId}&category=TARGET`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Request State
     * @param data contains requestId
     * @param s Success callback
     * @param f Failure callback
     */
    public getRequestWithRequestId = (
        data: {
            assetRestApiName: string;
            requestId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_api}/process/v1/${data.assetRestApiName}/requests/${data.requestId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to get requests by instanceIds
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getRequestsByInstanceId = (
        data: { restApiName: string; assetDataIds: string[]; noAlerts: boolean },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/internal/process/' + data.restApiName + '/requests';
        const payload = new FormData();
        payload.append('assetDataIds', this._utils.convertJSONtoBlob(data.assetDataIds || []));
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            data.assetDataIds,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to update request
     * @param data contains requestId, instanceId, bridgeId, assetId,bridgeRestApiName
     * @param s Success callback
     * @param f Failure callback
     */
    public updateCurrentRequest = (
        data: {
            instanceId: string;
            requestId?: string;
            bridgeRestApiName?: string;
            assetRestApiname: string;
            chainName?: string;
            autoExecuteRules?: boolean;
            payload?: FormData;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug =
            environment.taxilla_api + '/process/v1/' + data.assetRestApiname + '?sourceType=ASSETTOASSET&instanceId=' + data.instanceId;
        if (data.requestId || data.bridgeRestApiName || data.chainName) {
            if (data.requestId) {
                slug += '&requestId=' + data.requestId;
            }
            if (data.bridgeRestApiName) {
                slug += '&bridgeRestApiName=' + data.bridgeRestApiName;
            }
            if (data.chainName) {
                slug += '&transformationName=' + data.chainName;
            }
            if (data.autoExecuteRules) {
                slug += '&autoExecuteRules=' + data.autoExecuteRules;
            }
        }
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get instance by instanceIds
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getIncompleteInstances = (
        data: { restApiName: string; assetDataIds: string[] },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/internal/process/' + data.restApiName + '/instances';
        const payload = new FormData();
        payload.append('assetDataIds', this._utils.convertJSONtoBlob(data.assetDataIds || []));
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.assetDataIds,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get pending instances
     * @param data contains service ID
     * @param s Success callback
     * @param f Failure callback
     */
    public getPendingInstances = (
        data: {
            payload: {
                orgId: string;
                unitId: string;
                bridgeRestApiName: string;
                sourceAssetRestApiName: string;
                targetAssetRestApiName?: string;
                targetReportName?: string;
                filteringAttributes: {};
                queryOptions: {};
            };
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/instances/pending';
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get master records
     */
    public getPrimaryValuesInMasterTable = (
        payload: {
            masterDataRow: {
                tableId: string;
                unitId: string;
                columnValues: {
                    [property: string]: {
                        newValue: string;
                    };
                };
            };
            masterSearchDetail: {
                searchString: string;
                pageNo: number;
            };
            hideLoader?: boolean;
        },
        s: (response: MasterRecordsResponse) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + payload.masterDataRow.unitId + '/masters/primary-values';
        !payload.hideLoader && this._utils.showLoading();
        const requestData = { masterDataRow: payload.masterDataRow, masterSearchDetail: payload.masterSearchDetail };
        this._comm.post(
            slug,
            requestData,
            (res) => {
                !payload.hideLoader && this._utils.hideLoading();
                let newRes = CommonUtilsService.cloneObject(res);
                let response = {
                    records: newRes.response.masterRecords,
                };
                s(response);
            },
            (res) => {
                !payload.hideLoader && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get master record with primary value
     */
    public getMasterRecordWithprimaryValue = (
        payload: {
            masterDataRow: {
                tableId: string;
                unitId: string;
                columnValues: {
                    [property: string]: {
                        newValue: string;
                    };
                };
            };
            masterSearchDetail: {
                searchString: string;
                pageNo: number;
            };
        },
        s: (response: any) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/organizations/' + payload.masterDataRow.unitId + '/masters/records/asset';
        this._utils.showLoading();
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                let newRes = CommonUtilsService.cloneObject(res);
                let response = {
                    records: newRes.response.masterRecords,
                };
                s(response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get has 4Ec  approval access
     * @param s Success callback
     * @param f Failure callback
     */
    public has4EcApprovalAccess = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/permissions/has4ECAccess';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get processes based on statuses
     * @param data Contains serviceId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getStatusBasedProcesses = (
        data: {
            serviceId: string;
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_search_api}/services/${data.serviceId}/statuses`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get processes based on statuses with requestId
     * @param data Contains serviceId and requestId
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getStatusIdBasedProcesses = (
        data: {
            serviceId: string;
            requestId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = `${environment.taxilla_search_api}/services/${data.serviceId}/statuses?requestId=${data.requestId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to generate processConsignorReport
     * @param data Contains service restAPIName, payload as form data, transformation,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public processConsignorReport = (
        data: {
            appRestApiName: string;
            payload: FormData;
            transformationName: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.taxilla_api}/process/v1/${data.appRestApiName}?transformationName=${data.transformationName}`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to generate Email Notifications
     * @param data Contains groupId, eventType, subscriptionId, fromdate, fetchsize, pagingstate, status(sent or bounced), and emailId.
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getEmailNotifications = (
        data: {
            groupId: string;
            eventType: string;
            subscriptionId: string;
            size: number;
            pageState: any;
            fromDate: string;
            status: string;
            mailId: string;
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.notifications_api + `/v1/email/deliveries`;
        slug += `?groupId=${data.groupId || ''}`;
        slug += `&eventType=${data.eventType || ''}`;
        slug += `&subscriptionId=${data.subscriptionId || ''}`;
        slug += `&fetchSize=${data.size || ''}`;
        slug += `&pagingState=${(data.pageState && encodeURIComponent(data.pageState)) || ''}`;
        slug += data.fromDate ? `&fromdate=${data.fromDate || ''}` : '';
        slug += `&status=${data.status || ''}`;
        slug += `&emailId=${data.mailId || ''}`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to get EmailContent
     * @param data Contains messageId
     * @param s Success callback method
     * @param f Failure callback method
     */

    public getEmailContent = (
        data: {
            messageId: string;
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.notifications_api + `/v1/email/content`;
        slug += `?messageId=${data.messageId || ''}`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to ResendMail
     * @param data Contains  orgId,toEmails,ccEmails,bccEmails,subject,messageContent,attachmentsList
     * @param s Success callback method
     * @param f Failure callback method
     */
    public resendMail = (
        data: {
            orgId: string;
            toEmails: any[];
            ccEmails: any[];
            bccEmails: any[];
            subject: string;
            messageContent: string;
            attachmentList: any[];
            contextProperties: any;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.notifications_api + '/v1/email';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to exchangePartner
     * @param data Contains  ownerOrgId, assetId, instanseId, association, reverseAssociation, partnerOrgId,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public exchangePartner = (
        data: {
            ownerOrgId: string;
            assetId: string;
            instanseId: string;
            association: string;
            reverseAssociation: string;
            partnerOrgId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.eninvoice_api + '/exchangeewaybilltopartner';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                noUrlPrefix: true,
            }
        );
    };
    /**
     * Method to get keys
     */
    public getTagKeys = (
        data: {
            metadataTypes?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = '';
        if (data.metadataTypes) {
            slug = environment.taxilla_api + '/tags/keys?metadataTypes=' + data.metadataTypes;
        } else {
            slug = environment.taxilla_api + '/tags/keys';
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to bulkDownloadEwb
     * @param data Contains orgId, assetId, instanceIds,
     * @param s Success callback method
     * @param f Failure callback method
     */
    public bulkDownloadEwb = (
        data: {
            orgId: string;
            assetId: string;
            instanceIds: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.eninvoice_api + '/downloadewaybills';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            undefined,
            { noUrlPrefix: true }
        );
    };

    /**
     * Method to get key values
     * @param data Contains key
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getTagValuesByKey = (
        data: {
            key: string;
            metadataTypes?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = '';
        if (data.metadataTypes) {
            slug = environment.taxilla_api + '/tags/' + data.key + '/values?metadataTypes=' + data.metadataTypes;
        } else {
            slug = environment.taxilla_api + '/tags/' + data.key + '/values';
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get chain displaynames
     * @param data restApiname
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getChainDisplayNames = (
        data: {
            restApiName: string;
            assetId?: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = '';
        if (data.assetId) {
            slug =
                environment.taxilla_api +
                '/internal/metadata/chain_displaynames?restApiName=' +
                data.restApiName +
                '&metadataId=' +
                data.assetId;
        } else {
            slug = environment.taxilla_api + '/internal/metadata/chain_displaynames?restApiName=' + data.restApiName;
        }
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to getConnections
     * @param data Contains association, partnerOrganizationName, currentOrganizationId, status, page, size
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getConnections = (
        data: {
            association: string;
            currentOrganizationId: string;
            partnerOrganizationName?: string;
            status?: string;
            page?: number;
            size?: number;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = '/partners/search?criterias=organizationId:' + data.currentOrganizationId + ',association:' + data.association;
        if (data.partnerOrganizationName) {
            slug += ',partnerOrganization.name~' + data.partnerOrganizationName;
        }
        if (data.status) {
            slug += ',status:' + data.status;
        }
        if (data.page) {
            slug += '&page=' + data.page + '&size=' + data.size;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get services based on tag values
     * @param data Contains query params
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getServicesByTagValues = (
        data: {
            serviceTypes: string[];
            key: string;
            subscriptionStatus: string;
            size?: number;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let queryString = '';
        if (data.serviceTypes && data.serviceTypes.length > 0) {
            let serviceTypes = '';
            data.serviceTypes.forEach((type) => {
                serviceTypes += 'serviceType=' + type + '&';
            });
            queryString =
                serviceTypes + 'key=' + data.key + '&subscriptionStatus=' + data.subscriptionStatus + '&fetchSize=' + (data.size || 100);
        } else {
            queryString =
                'serviceType=ASSET&serviceType=BRIDGEASSET&serviceType=RECON&key=' +
                data.key +
                '&subscriptionStatus=' +
                data.subscriptionStatus +
                '&fetchSize=100';
        }
        queryString.substring(0, queryString.length - 1);
        const slug = environment.taxilla_api + '/tags/services?' + queryString;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /** Method to sendMail
     * @param data Contains association, partnerOrganizationName, currentOrganizationId, status, page, size
     * @param s Success callback method
     * @param f Failure callback method
     */
    public sendMail = (
        data: {
            payload: {
                orgId: string;
                assetId: string;
                instanseId: string;
                firstName: string;
                lastName: string;
                contactNo: string;
                mailId: string;
            };
            mailTo: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.eninvoice_api + '/' + data.mailTo;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                noUrlPrefix: true,
            }
        );
    };

    /** Method to getOrgNamesByIds
     * @param data Contains orgIds
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getOrgNamesByIds = (
        data: {
            orgIds: string[];
            noAlerts: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/organizations/org-names';
        !data.noAlerts && this._utils.showLoading();
        this._comm.post(
            slug,
            data.orgIds,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to getPlatformVersion
     * @param s Success callback
     * @param f Failure callback
     */
    public getVersionJson = (
        data: {
            url: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        if (data?.url) {
            const slug = data.url + '/version.json?t=' + new Date().getTime();
            // this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    // this._utils.hideLoading();
                    s(res);
                },
                (res) => {
                    // this._utils.hideLoading();
                    f(res);
                }
            );
        }
    };

    /**
     * method to cancel request
     * @param s Success callback
     * @param f Failure callback
     */
    public cancelRequest = (
        data: {
            restApiName: string;
            requestId: string;
            comment?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/requests/' + data.requestId + '/cancel';
        if (data.comment) {
            slug = slug + '?comment=' + data.comment;
        }
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to cancel request
     * @param s Success callback
     * @param f Failure callback
     */
    public cancelInstance = (
        data: {
            restApiName: string;
            payload: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/instances/cancel';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to getHelpDocument
     * @param s Success callback
     * @param f Failure callback
     */
    public getHelpDocument = (
        data: {
            serviceId: string;
            noLoader?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/user-manuals/' + data.serviceId;
        !data.noLoader && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noLoader && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noLoader && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to getHelpDocument data
     * @param s Success callback
     * @param f Failure callback
     */
    public getHelpDocumentUrl = (
        document: {
            fileUrl: string;
            fileName: string;
            isPdfReport?: boolean;
        },
        s: (response) => void,
        f: (response) => void,

        acceptHeader?
    ) => {
        // const slug = `${environment.taxilla_api}/process/v1/files?fileUrl=${encodeURIComponent(document.fileUrl)}&fileName=${document.fileName}.pdf&inline=true`;
        const slug =
            environment.taxilla_api +
            '/process/v1/files?fileUrl=' +
            encodeURIComponent(document.fileUrl) +
            '&fileName=' +
            encodeURIComponent(document.fileName) +
            (document.isPdfReport ? '' : '.pdf') +
            '&inline=true';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            null,
            {
                headers: {
                    Accept: acceptHeader ?? 'application/pdf',
                },
                responseType: acceptHeader?.startsWith('text') ? 'text' : 'blob',
            }
        );
    };

    /**
     * method to getAllActiveTokens
     * @param s Success callback
     * @param f Failure callback
     */
    public getActiveTokens = (
        data: {
            size: number;
            page: number;
        },
        s: (response, count) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/tokens?size=' + data.size + '&page=' + data.page;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res, count) => {
                this._utils.hideLoading();
                s(res?.response?.items, res?.response?.totalItems);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * method to invalidateTokens
     * @param paylaod contains tokenIds
     * @param s Success callback
     * @param f Failure callback
     */
    public invalidateTokens = (data: { tokenIds: number[] }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/tokens/invalidate';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.tokenIds,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Create Roles
     * @param data contains role and permissions object
     * @param s success callback
     * @param f failure callback
     */
    public createRole = (data, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/roles';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get 4Ec requests
     * @param s Success callback
     * @param f Failure callback
     */
    public get4ECRequests = (
        data: {
            status: string;
            orgId: string;
            type?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.taxilla_user_identity_api + '/v1/ec-requests';
        if (data.status) {
            slug = slug + '?search=status:' + data.status + ',organizationId:' + data.orgId;
        }
        if (data.type && data.type !== undefined) {
            slug = slug + ',type:' + data.type;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to Approve 4EC requests
     * @param data contains payload, orgId
     * @param s Success callback
     * @param f Failure callback
     */
    public approve4EcRequests = (
        data: {
            id: string;
            type: string;
            status: string;
            organizationId: string;
            comment: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/ec-requests/' + data.id;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get 4EC request Deatils
     * @param data contains id
     * @param s Success callback
     * @param f Failure callback
     */
    public get4EcRequestDetails = (
        data: {
            id: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/ec-requests/' + data.id;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to save 4Ec User Deatils
     * @param data contains id, roles
     * @param s Success callback
     * @param f Failure callback
     */

    public save4EcUserDetails = (
        data: {
            id: string;
            requestId: string;
            persistedId: string;
            organizationId: string;
            userId: string;
            firstName: string;
            lastName: string;
            email: string;
            emailNotification: boolean;
            roles: any[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/ec-requests/user/' + data.id;
        this._utils.showLoading();
        delete data.id;
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Updating Roles Data
     * @param data contains role and permissions objects and requestId
     * @param s success callback
     * @param f failure callback
     */
    public updateRolesData = (
        data: {
            requestId: string;
            roleId: string;
            role: Role;
            permissions: Permission[];
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/transientRole/' + data.requestId + '/' + data.roleId;
        delete data.requestId;
        delete data.roleId;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * get Request Role Permissions
     * @param data contains roleId
     * @param s success callback
     * @param f failure callback
     */

    public getRequestRolePermissions = (
        data: {
            requestId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/transientRole/' + data.requestId + '/permissions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * get Request Role Permissions
     * @param data contains roleId
     * @param s success callback
     * @param f failure callback
     */

    public getRequestRolePermissionsNew = (
        data: {
            requestId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/v1/role-requests/' + data.requestId + '/permissions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * get get4ecPermisionChanges
     * @param data contains roleId
     * @param s success callback
     * @param f failure callback
     */
    public get4ecPermisionChanges = (
        data: {
            requestId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/transientRole/' + data.requestId + '/permissionChanges';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Update Role with out permissions
     * @param data contains role object
     * @param s success callback
     * @param f failure callback
     */
    public updateRoleWithoutPermissions = (role: Role, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/roles';
        this._utils.showLoading();
        this._comm.put(
            slug,
            role,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to save 4Ec Role Deatils
     * @param data contains rolesObject
     * @param s Success callback
     * @param f Failure callback
     */

    public save4EcRoleDeatils = (
        data: {
            id: string;
            requestId: string;
            persistedId: string;
            organizationId: string;
            roleName: string;
            displayName: string;
            description: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/ec-requests/role/' + data.id;
        this._utils.showLoading();
        delete data.id;
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Updating approve4ecRoles
     * @param data contains requestId, roleId
     * @param s success callback
     * @param f failure callback
     */
    public approve4ecRoles = (
        data: {
            requestId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/transientRole/approve/' + data.requestId;
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * get subscribed events
     * @param data contains orgId, eventType, fetchSize, pagingState
     * @param s success callback
     * @param f failure callback
     */
    public getSubscribedEvents = (
        data: {
            orgId: string;
            eventType?: string;
            groupId?: string;
            fetchSize: number;
            pagingState: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.notifications_api + '/v1/email/subscription';
        if (data.orgId) {
            slug += '?orgId=' + data.orgId;
        }
        if (data.eventType) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'eventType=' + data.eventType;
        }
        if (data.groupId) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'groupId=' + data.groupId;
        }
        if (data.fetchSize) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'fetchSize=' + data.fetchSize;
        }
        if (data.pagingState) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'pagingState=' + data.pagingState;
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get event definations
     * @param s Success callback
     * @param f Failure callback
     */
    public getEventDefinitions = (s: (response) => void, f: (response) => void): void => {
        const slug = environment.notifications_api + '/v1/event-definitions';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
    * Method to add Event Subscriptions
    * @param data Contains event object
    *   {
            "orgId":"fcbTBDHmUzhbSVcA",
            "eventType":"report_generated",
            "subscriptionId":"random_subscription_15",
            "criterion":"{\"criterionType\":\"lt\", \"property\":\"field1\", \"value\":\"10\" }",
            "toEmails":[
                "ssaraswati@taxilla.com"
            ],
            "ccEmails":[
                "nkpokuri@taxilla.com"
            ],
            "bccEmails":[
                "ppeechara@taxilla.com"
            ],
            "subjectTemplate":"A Glorified Subject",
            "contentTemplate":"An ordinary content",
            "attachment":"attachment_field"
        }
    * @param s Success callback
    * @param f Failure callback
    */
    public createEventSubscriptions = (data: any, s: (response) => void, f: (response) => void) => {
        const slug = environment.notifications_api + '/v1/email/subscription';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
    * Method to edit Event Subscriptions
    * @param data Contains event object
    *   {
            "orgId":"fcbTBDHmUzhbSVcA",
            "eventType":"report_generated",
            "subscriptionId":"random_subscription_15",
            "criterion":"{\"criterionType\":\"lt\", \"property\":\"field1\", \"value\":\"10\" }",
            "toEmails":[
                "ssaraswati@taxilla.com"
            ],
            "ccEmails":[
                "nkpokuri@taxilla.com"
            ],
            "bccEmails":[
                "ppeechara@taxilla.com"
            ],
            "subjectTemplate":"A Glorified Subject",
            "contentTemplate":"An ordinary content",
            "attachment":"attachment_field"
        }
    * @param s Success callback
    * @param f Failure callback
    */
    public editEventSubscriptions = (data: any, s: (response) => void, f: (response) => void) => {
        const slug = environment.notifications_api + '/v1/email/subscription';
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
    /**
     * Method to delete event subscription
     * @param data contains orgId, eventType, subscriptionId
     * @param s for success callback
     * @param f for failure callback
     */
    public deleteEventSubscription = (
        data: {
            orgId: string;
            eventType: string;
            subscriptionId: string;
            groupId?: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = `${environment.notifications_api}/v1/email/subscription?orgId=${data.orgId}&eventType=${data.eventType}&subscriptionId=${
            data.subscriptionId
        }&groupId=${data.groupId || '-'}`;
        if (data.groupId) {
            slug += slug.indexOf('?') > -1 ? '&' : '?';
            slug += 'groupId=' + data.groupId;
        }
        this._utils.showLoading();
        this._comm.delete(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get recently accessed services
     * @param s Success callback
     * @param f Failure callback
     */
    public getRecentlyAccessedServices = (s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_api + '/recently-accessed-services';
        this._comm.get(
            slug,
            (res) => {
                s(res);
            },
            (res) => {
                f(res);
            }
        );
    };

    /**
     * Method to get request info
     * @param data contains restApiName and requestId
     * @param s Success callback
     * @param f Failure callback
     */
    public getRequestInfo = (data: { requestId: string; restApiName: string }, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_search_api + '/services/' + data.restApiName + '/request-info?requestId=' + data.requestId;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get activate locations
     * @param data contains orgId
     * @param s Success callback
     * @param f Failure callback
     */
    public activateLocations = (
        data: {
            orgId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + `/v1/locations/${data.orgId}?action=ACTIVATE`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /*
     * Method to get deactivate locations
     * @param data contains orgId
     * @param s Success callback
     * @param f Failure callback
     */
    public deactivateLocations = (
        data: {
            orgId: string;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + `/v1/locations/${data.orgId}?action=DEACTIVATE`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to create download request
     * @param s Success callback
     * @param f Failure callback
     */
    public createDownloadRequest = (
        data: {
            restApiName: string;
            payload: {
                transformations: string[];
                instanceIds?: any[];
                searchCriteria?: any;
            };
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        const slug = environment.taxilla_api + '/process/v1/' + data.restApiName + '/reports/bulk-download-requests';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get request info
     * @param data contains restApiName and requestId
     * @param s Success callback
     * @param f Failure callback
     */
    public getCollaborationInfo = (data: { collaborationId: string }, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_api + `/collaboration/v1/requests/${encodeURIComponent(data.collaborationId)}/details`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get instance status
     * @param data contains instanceId, rest Api name
     * @param s Success callback
     * @param f Failure callback
     */
    public getInstanceStatus = (
        data: {
            instanceId: string;
            restApiName: string;
            noAlerts?: boolean;
        },
        s: (response: {
            instanceInfo: {
                errors: string[];
                warnings: string[];
            };
        }) => void,
        f
    ) => {
        if (!data.instanceId || !data.restApiName) {
            return;
        }
        const slug = environment.taxilla_api + '/internal/process/' + data.restApiName + '/instances/' + data.instanceId + '/status';
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Get identity providers
     * @param s Success callback
     * @param f Failure callback
     */
    public getIdentityProviders = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/identity-provider';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response?.items || []);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to create identity prvider
     * @param data Contains payload
     * @param s Success callback
     * @param f Failure callback
     */
    public createIdentityProvider = (data: any, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_user_identity_api + '/v1/identity-provider';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to update identity prvider
     * @param data Contains payload
     * @param s Success callback
     * @param f Failure callback
     */
    public updateIdentityProvider = (data: { payload: any; providerId: string }, s: (response) => void, f: (response) => void): void => {
        const slug = environment.taxilla_user_identity_api + '/v1/identity-provider/' + data.providerId;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete identity provider
     * @param data contains provider ID
     * @param s Success callback
     * @param f Failure callback
     */
    public deleteIdentityProvider = (id: string, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/identity-provider/' + id;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to authorize code
     */
    public authorizeCode = (
        data: {
            stateId: string;
            clientId: string;
            code: string;
            redirectUri: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug =
            environment.oauth_api +
            `/v1/authorize?response_type=code&state=${data.stateId}&client_id=${data.clientId}&code_challenge=${data.code}&code_challenge_method=sha256&redirect_uri=${data.redirectUri}&scope=openid`;
        this._commonUtils.redirectToPage(slug, true);
    };

    /**
     * Method to authorize code
     */
    public validateCode = (
        data: {
            clientId: string;
            authCode: string;
            code: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.oauth_api + `/v1/token`;
        this._utils.showLoading();
        const payload = `client_id=${data.clientId}&code=${data.authCode}&code_verifier=${data.code}&grant_type=authorization_code`;
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to authorize code
     */
    public authenticateToken = (token: string, s: (response: any) => void, f: (response: any) => void) => {
        const slug = environment.taxilla_user_identity_api + `/v1/internal-idp/authenticate`;
        this._utils.showLoading();
        const payload = `token=${token}`;
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res?.msg);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to get client by domain
     */
    public getClientDetailsByDomain = (
        data: {
            domain: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + `/v1/identity-provider-discovery/${data.domain}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to authorize code
     */
    public authorizeExternalIP = (
        data: {
            authURL: string;
            stateId: string;
            clientId: string;
            code: string;
            redirectUri: string;
            type: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug =
            data.authURL +
            `?response_type=code&state=${data.stateId}&client_id=${data.clientId}&code_challenge=${
                data.code
            }&code_challenge_method=S256&redirect_uri=${data.redirectUri}&scope=${encodeURIComponent('openid profile email')}`;
        this._commonUtils.redirectToPage(slug, true);
    };

    /**
     * Method to authorize code
     */
    public validateCodeExternally = (
        data: {
            domain: string;
            redirectURL: string;
            authCode: string;
            code: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/external-idp/authenticate';
        this._utils.showLoading();
        const payload = `redirect_uri=${data.redirectURL}&code=${data.authCode}&code_verifier=${data.code}&domain=${data.domain}`;
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to get collaboration configuration given an assetID
     */
    public getCollabConfig = (
        data: {
            assetId: string;
            instanceId?: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        let slug = environment.taxilla_api + `/collaboration/v1/scheme/${data.assetId}`;
        if (data.instanceId) {
            slug += '?considerHierarchy=true';
        }
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to create collaboration configuration
     */
    public createCollabConfig = (
        data: {
            assetId: string;
            config: CollabConfiguration;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_api + `/collaboration/v1/scheme/${data.assetId}`;
        this._utils.showLoading();
        this._comm.post(
            slug,
            data.config,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete collaboration configuration
     */
    public deleteCollabConfig = (
        data: {
            assetId: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_api + `/collaboration/v1/scheme/${data.assetId}`;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to create collaborators list
     */
    public getCollaboratorsList = (
        data: {
            assetId: string;
            instanceId: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_api + `/collaboration/v1/collaborators?assetId=${data.assetId}&instanceId=${data.instanceId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to create partner request
     */
    public createPartnerRequest = (
        data: {
            instanceId: string;
            assetId: string;
            partnerId?: string;
            emails?: any[];
            inviteUsingPartnerFields?: boolean;
            partnerFunction: string;
            comments: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_api + '/collaboration/v1/requests';
        this._utils.showLoading();
        this._comm.post(
            slug,
            data,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to approve collaborate action
     */
    public approveCollaborationRequest = (
        data: {
            collaborationId: string;
            payload: { unitId: string };
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_api + `/collaboration/v1/requests/${data.collaborationId}/approve`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            data.payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get all downtime windows
     */
    public getDowntimeWindows = (s: (response: any) => void, f: (response: any) => void) => {
        const slug = environment.taxilla_api + `/downtime-schedules`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get computation rules for an asset
     */
    public getAssetComputationalRules = (
        data: { assetId: string; entityId: string; noAlerts?: boolean },
        s: (response: { success: boolean; response: { [property: string]: string } }) => void,
        f: (response) => void
    ) => {
        const slug =
            environment.taxilla_api + `/metadata/${data.assetId}/predefined-rules${data.entityId ? `?entityId=${data.entityId}` : ''}`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to approvePartner
     * @param data contains partnerOrganizationId
     * @param s Success callback
     * @param f Failure callback
     */
    public approvePartner = (
        data: {
            partnerOrganizationId: string;
            type: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = `${environment.taxilla_user_identity_api}/v1/partners/invitations/${data.partnerOrganizationId}?action=${data.type}`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to rejectPartner
     * @param data contains partnerOrganizationId
     * @param s Success callback
     * @param f Failure callback
     */
    public rejectPartner = (
        data: {
            partnerOrganizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = `${environment.taxilla_user_identity_api}/v1/partners/invitations/${data.partnerOrganizationId}?action=REJECT`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to delete partner invitation
     * @param data contains partnerOrganizationId
     * @param s Success callback
     * @param f Failure callback
     */
    public deletePartnerInvitation = (
        data: {
            partnerOrganizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = `${environment.taxilla_user_identity_api}/v1/partners/invitations/${data.partnerOrganizationId}`;
        this._utils.showLoading();
        this._comm.delete(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to resend partner invitation
     * @param data contains partnerOrganizationId
     * @param s Success callback
     * @param f Failure callback
     */
    resendPartnerInvitation = (
        data: {
            partnerOrganizationId: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = `${environment.taxilla_user_identity_api}/v1/partners/invitations/${data.partnerOrganizationId}?action=RESEND`;
        this._utils.showLoading();
        this._comm.put(
            slug,
            null,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to updatePartner
     * @param data contains partnerOrganizationId, partnerExternalId
     * @param s Success callback
     * @param f Failure callback
     */
    public updatePartner = (
        data: {
            partnerOrganizationId: string;
            partnerExternalId: string;
        },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const payload = {
            partnerExternalId: data.partnerExternalId,
        };
        const slug = environment.taxilla_user_identity_api + '/v1/org-associations/' + data.partnerOrganizationId;
        this._utils.showLoading();
        this._comm.put(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get count of providerOrganizations
     */
    public getCountOfProviderOrganizations = (s: (response: any) => void, f: (response: any) => void) => {
        const slug = environment.taxilla_user_identity_api + `/organizations/count?search=organizationTypes:PROVIDER`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get provider organization for drop down
     * @param s Success callback
     * @param f Failure callback
     */
    public providerOrganizationsDropdownList = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/organizations/provider-organizations';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get wrapper API tags
     */
    public getWrapperAPITags = (serviceId: string): Promise<string> => {
        const tagKey = 'wrapperapi';
        return new Promise(async (resolve) => {
            await this._store.privateScope
                .fetchValues(
                    () => {
                        return new Promise((resolve, reject) => {
                            this.getAssetTags(
                                {
                                    serviceId: serviceId,
                                    tagKey: tagKey,
                                    noAlerts: false,
                                },
                                (response) => {
                                    resolve(response?.response?.taggedMetadatas || []);
                                },
                                (response) => {
                                    resolve([]);
                                }
                            );
                        });
                    },
                    'appTags',
                    serviceId,
                    tagKey
                )
                .then((response) => {
                    const wrapperTag = response?.appTags?.[serviceId]?.[tagKey]?.[0]?.tagValue;
                    const wrapperAPI = wrapperTag && environment[wrapperTag];
                    if (!environment.wrappers) {
                        environment.wrappers = [];
                    }
                    wrapperAPI && environment.wrappers.indexOf(wrapperAPI) === -1 && environment.wrappers.push(wrapperAPI);
                    resolve(wrapperAPI);
                });
        });
    };

    /*
     * Method to add Provider organizatin
     */
    public getAggregation = (data: any, s: (response: any) => void, f: (response: any) => void, custom?: { noAlerts: boolean }) => {
        const slug = environment.taxilla_search_api + `/services/aggregation`;
        if (!custom || !custom.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.post(
            slug,
            data,
            (res) => {
                if (!custom || !custom.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!custom || !custom.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get Google Authenticator secret key
     * @param s Success callback
     * @param f Failure callback
     */
    public getGoogleAuthenticatorSecretKey = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/google/secret-key-registration';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Google Authenticator QR Code
     * @param s Success callback
     * @param f Failure callback
     */
    public getGoogleAuthenticatorQRCode = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/google/qr-code-registration';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to set Google Authenticator secret key
     * @param s Success callback
     * @param f Failure callback
     */
    public setGoogleAuthenticatorSecretKey = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/users/generate-assign-secret-key';
        this._utils.showLoading();
        this._comm.put(
            slug,
            undefined,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to set Google Authenticator secret key
     * @param s Success callback
     * @param f Failure callback
     */
    public submitGoogleAuthentication = (data: { OTP: string }, isFormSubmit: boolean, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/multi-factor-auth/google-auth-otp/evaluate';
        this._utils.showLoading();
        if (isFormSubmit) {
            this._utils.submitForm(slug, data);
            return;
        }
        const payload = `OTP=${data.OTP}`;
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to generate Email OTP
     * @param s Success callback
     * @param f Failure callback
     */
    public generateEmailOTP = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/multi-factor-auth/email-otp/generate';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res?.response);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to evaluate email OTP
     * @param s Success callback
     * @param f Failure callback
     */
    public evaluateEmailOTP = (data: { value: string }, isFormSubmit: boolean, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/multi-factor-auth/email-otp/evaluate';
        this._utils.showLoading();
        if (isFormSubmit) {
            this._utils.submitForm(slug, data);
            return;
        }
        const payload = `emailOtp=${data.value}`;
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to Create & Update bundle
     */
    public updateBundle = (payload: { name: string; description: string; id: string }) => {
        return new Promise((resolve, reject) => {
            const slug = `${environment.bundle_api}/v1/bundles${payload.id ? `/${payload.id}` : ''}`;
            this._utils.showLoading();
            const method = payload.id ? this._comm.put : this._comm.post;
            delete payload.id;
            method(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to get bundles
     */
    public getBundles = ({ pageNumber, pageSize }: { pageNumber: number; pageSize: number }) => {
        return new Promise<any[]>((resolve, reject) => {
            const slug = `${environment.bundle_api}/v1/bundles?name=&page=${pageNumber}&size=${pageSize}`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response?.bundles || []);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to Delete bundle
     */
    public deleteBundle = (bundleId: string) => {
        return new Promise<string>((resolve, reject) => {
            const slug = `${environment.bundle_api}/v1/bundles/${bundleId}`;
            this._utils.showLoading();
            this._comm.delete(
                slug,
                undefined,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.msg);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to get assignable subscriptions
     */
    public getAssignableSubscriptions = (bundleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/assignable-subscriptions`;
        return new Promise<{
            success: boolean;
            response: {
                subscriptions: {
                    id?: string;
                    createdDate?: string;
                    lastModifiedDate?: string;
                    name?: string;
                    restApiName?: string;
                    serviceId?: string;
                    assetMetaUId?: string;
                    assetType?: string;
                    filableReport?: boolean;
                }[];
            };
        }>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get bundle subscriptions
     */
    public getBundleSubscriptions = (bundleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/subscriptions`;
        return new Promise<string[]>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response?.subscriptions || []);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to Update bundle subscriptions
     */
    public updateBundleSubscriptions = (bundleId: string, payload: { serviceIds: string[] }) => {
        return new Promise((resolve, reject) => {
            const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/subscriptions`;
            this._utils.showLoading();
            this._comm.put(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get bundle subscriptions
     */
    public getBundleCollaboration = (bundleId: string, assetId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/collaboration-schemes/${assetId}`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /*
     * Method to Reconciliation Request Info
     * @param data contains restApiName and requestId
     * @param s Success callback
     * @param f Failure callback
     */
    public getReconciliationRequestInfo = (
        data: { requestId: string; restApiName: string },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = environment.recon_api + `/process/${data.restApiName}/${data.requestId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to Reconciliation State
     * @param data contains restApiName and requestId
     * @param s Success callback
     * @param f Failure callback
     */
    public getReconciliationState = (
        data: { requestId: string; restApiName: string },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = environment.recon_api + `/process/${data.restApiName}/state?id=${data.requestId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to load reconciliation log data
     * @param data contains restApiName and requestId
     * @param s Success callback
     * @param f Failure callback
     */
    public getReconciliationLogs = (
        data: { requestId: string; restApiName: string; noAlerts?: boolean },
        s: (response) => void,
        f: (response) => void
    ): void => {
        const slug = environment.recon_api + `/process/${data.restApiName}/state/all?id=${data.requestId}`;
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get reconciliation processes
     */
    public getReconciliationProcesses = (
        data: {
            requestId?: string;
            pageSize?: number;
            pagingState?: string;
            noAlerts?: boolean;
            restApiName: string;
            groupId?: string;
            sourceId?: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        let slug =
            environment.recon_api +
            `/result/${data.restApiName}?id=${data.requestId}&group=${data.groupId}&pageSize=${data.pageSize || 20}`;
        data.pagingState?.length > 0 && (slug += `&pagingState=${encodeURIComponent(data.pagingState)}`);
        data.sourceId?.length > 0 && (slug += `&source=${data.sourceId}`);
        if (!data.noAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.noAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get ReconOverrideConfigurations
     * @param restApiName
     * @returns
     */
    public getReconOverrideConfigurations = (restApiName) => {
        return new Promise<{ allowOverride: boolean }[]>((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/manual-override`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to Update bundle subscriptions
     */
    public updateBundleCollaboration = (bundleId: string, assetId: string, payload: CollabConfiguration) => {
        return new Promise<any>((resolve, reject) => {
            const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/collaboration-schemes/${assetId}`;
            this._utils.showLoading();
            this._comm.put(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };
    /**
     * Method to submit ReconOverrideConfigurations
     * @param restApiName
     * @param payload
     * @returns
     */

    public submitReconOverrideConfigurations = (restApiName: string, payload: { allowOverride: boolean }[]) => {
        return new Promise<{
            message: string;
        }>((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/manual-override`;
            this._utils.showLoading();
            this._comm.post(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to delete bundle subscriptions
     */
    public deleteBundleCollaboration = (bundleId: string, assetId: string) => {
        return new Promise((resolve, reject) => {
            const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/collaboration-schemes/${assetId}`;
            this._utils.showLoading();
            this._comm.delete(
                slug,
                undefined,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get bundle roles
     */
    public getBundleRoles = (bundleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/roles`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get bundle role permissions
     */
    public getBundlePermissions = (bundleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/assignable-permissions`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get Recorn OutboundReportConfigurations
     * @param restApiName
     * @returns
     */

    public getReconOutboundReportConfigurations = (restApiName) => {
        return new Promise<
            {
                organization: string;
                reconId: string;
                reportEndpoint: string;
                reportDisplayName: string;
                autogenerate: boolean;
                module: string;
            }[]
        >((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/report`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to create bundle role
     */
    public createBundleRole = (bundleId: string, payload: any) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/roles`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.post(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };
    /**
     * Method to submit ReconOutboundReportConfigurations
     * @param restApiName
     * @param payload
     * @returns
     */

    public submitReconOutboundReportConfigurations = (
        restApiName: string,
        payload: {
            reportEndpoint: string;
            reportDisplayName: string;
            autogenerate: boolean;
        }[]
    ) => {
        return new Promise<{
            message: string;
        }>((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/report`;
            this._utils.showLoading();
            this._comm.post(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to create bundle role
     */
    public updateBundleRole = (bundleId: string, roleId: string, payload: any) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/roles/${roleId}`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.put(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to delete bundle role
     */
    public deleteBundleRole = (bundleId: string, roleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/roles/${roleId}`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.delete(
                slug,
                undefined,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get bundle role permissions
     */
    public getBundleRolePermissions = (bundleId: string, roleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/roles/${roleId}/permissions`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };
    /**
     * Method to get ReconOutboundAppConfigurations
     * @param restApiName
     * @returns
     */

    public getReconOutboundAppConfigurations = (restApiName) => {
        return new Promise<
            {
                organization: string;
                reconId: string;
                autogenerate: boolean;
                module: string;
                assetEndpoint: string;
                transformationEndpoint: string;
                assetDisplayName: string;
                transformationSourceName: string;
                requestAttributes: { [property: string]: any };
            }[]
        >((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/asset`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to update bundle role permissions
     */
    public updateBundleRolePermissions = (bundleId: string, roleId: string, payload: { permissions: { uuid: string }[] }) => {
        let data = {
            permissions: [],
            rolePermissions: payload,
        };
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/roles/${roleId}/permissions`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.put(
                slug,
                data,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };
    /**
     * Method to submit Recon OutboundAppConfigurations
     * @param restApiName
     * @param payload
     * @returns
     */

    public submitReconOutboundAppConfigurations = (
        restApiName: string,
        payload: {
            assetEndpoint: string;
            transformationEndpoint: string;
            assetDisplayName: string;
            requestAttributes: { [property: string]: any };
            autogenerate: boolean;
            transformationSourceName: string;
        }[]
    ) => {
        return new Promise<{
            message: string;
        }>((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/asset`;
            this._utils.showLoading();
            this._comm.post(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to get bundle Invitations
     */
    public getBundleInvitations = (bundleId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/invitations`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response?.invitations);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error?.msg);
                }
            );
        });
    };

    /**
     * Method to get Recorn RequestGroupCount
     * @param restApiName
     * @param requestId
     * @param group
     * @param sourceId
     * @returns
     */
    public getReconRequestGroupCount = (restApiName: string, requestId: string, group: string, sourceId?: string) => {
        return new Promise<number>((resolve, reject) => {
            const slug = `${environment.recon_api}/result/${restApiName}/count?id=${requestId}&group=${group}&source=${sourceId || ''}`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to create bundle Invitations
     */
    public createBundleInvitations = (bundleId: string, payload: { partnerRef: string; type: string }[]) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/invitations`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.put(
                slug,
                payload,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to delete bundle invitations
     */
    public deleteBundleInvitations = (bundleId: string, invitationId: string) => {
        const slug = `${environment.bundle_api}/v1/bundles/${bundleId}/invitations/${invitationId}`;
        return new Promise<any>((resolve, reject) => {
            this._utils.showLoading();
            this._comm.delete(
                slug,
                undefined,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.msg);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to get bridge metadata
     * @param data contains assetservice properties
     * @param s success callback
     * @param f failure callback
     */
    public getBridgeMetaData = (data: { assetMetaUId: string; hideAlerts?: boolean }, s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_api + '/metadata/bridges/' + data.assetMetaUId;
        if (!data.hideAlerts) {
            this._utils.showLoading();
        }
        this._comm.get(
            slug,
            (res) => {
                if (!data.hideAlerts) {
                    this._utils.hideLoading();
                }
                s(res);
            },
            (res) => {
                if (!data.hideAlerts) {
                    this._utils.hideLoading();
                }
                f(res);
            }
        );
    };

    /**
     * Method to get ReconciliationReports
     * @param restApiName
     * @returns
     */

    public getReconciliationReports = (restApiName: string) => {
        return new Promise<{ allowOverride: boolean }[]>((resolve, reject) => {
            const slug = `${environment.recon_api}/report/${restApiName}/published`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };
    /**
     * Method to get GeneratedReconciliationReports
     * @param restApiName
     * @param requestId
     * @returns
     */

    public getGeneratedReconciliationReports = (restApiName: string, requestId: string) => {
        return new Promise<
            {
                configId: string;
                createdOn: string;
                failureReason: string;
                organization: string;
                reportDisplayName: string;
                reportName: string;
                reportUri: string;
                reportWebEndpoint: string;
                requestId: string;
                requestWeek: string;
                status: string;
            }[]
        >((resolve, reject) => {
            const slug = `${environment.recon_api}/report/${restApiName}?id=${requestId}`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to delete ReconOutboundAppConfiguration
     * @param restApiName
     * @param configAssetEndPoint
     * @param organizationId
     * @returns
     */

    public deleteReconOutboundAppConfiguration = (restApiName: string, configAssetEndPoint: string, organizationId: string) => {
        return new Promise<{
            message: string;
        }>((resolve, reject) => {
            const slug = `${environment.recon_api}/setting/${restApiName}/asset?assetEndpoint=${configAssetEndPoint}&organization=${organizationId}`;
            this._utils.showLoading();
            this._comm.delete(
                slug,
                undefined,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to get getOrganizationFieldIdVsNameMap
     * @returns
     */
    public getOrganizationFieldIdVsNameMap = () => {
        return new Promise((resolve, reject) => {
            const slug = `${environment.taxilla_api}/v1/setup-mapping-attributes`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (error) => {
                    this._utils.hideLoading();
                    reject(error);
                }
            );
        });
    };

    /**
     * Method to get user misc properties
     * @param s Success callback
     * @param f Failure callback
     */
    public getUserMiscProps = (s: (response) => void, f: (response) => void) => {
        const slug = environment.taxilla_user_identity_api + '/v1/settings';
        this._comm.get(
            slug,
            (res) => {
                s(res?.response);
            },
            (res) => {
                f(res);
            }
        );
    };

    /**
     * Method to subscriptions by tag key
     * @param data Contains key
     * @param s Success callback method
     * @param f Failure callback method
     */
    public getSubscriptionsByTagKey = (data: { key: string; metadataTypes?: string[] }) => {
        return new Promise((resolve, reject) => {
            const services = `?` + (data.metadataTypes ? 'serviceType=' + data.metadataTypes.join('&serviceType=') : '');
            const slug = `${environment.taxilla_api}/tags/services${services}&key=${data.key}&subscriptionStatus=APPROVED&fetchSize=100`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to authorize code
     */
    public submitLDAPDetails = (
        data: {
            attribute: string;
            password: string;
            identityProviderId: string;
        },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = environment.taxilla_user_identity_api + '/v1/identity-provider/ldap/login';
        this._utils.showLoading();
        const payload = `attribute=${data.attribute}&password=${data.password}&identityProviderId=${data['identityProviderId']}`;
        this._comm.post(
            slug,
            payload,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            },
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    };

    /**
     * Method to get Dashboards
     * @returns
     */

    public getDashboards = (noAlerts) => {
        return new Promise((resolve, reject) => {
            !noAlerts && this._utils.showLoading();
            const slug = `${environment.analytics_api}/v1/dashboards`;
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get getQuickSightDashboardURL
     * @param data
     * @returns
     */

    public getQuickSightDashboardURL = (data: { serviceId: string; noAlerts?: boolean }) => {
        return new Promise((resolve, reject) => {
            !data.noAlerts && this._utils.showLoading();
            const slug = `${environment.analytics_api}/v1/dashboards/${data.serviceId}/url`;
            this._comm.get(
                slug,
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     *refresh Quicksight Dashboard
     * @param data
     * @returns
     */

    public refreshQuicksightDashboard = (data: { widgetId: string; noAlerts?: boolean }) => {
        return new Promise((resolve, reject) => {
            !data.noAlerts && this._utils.showLoading();
            const slug = `${environment.analytics_api}/v1/dashboards/${data.widgetId}?action=REFRESH`;
            this._comm.put(
                slug,
                {},
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };
    /**
     * Method to get quickSight Dashboard Status
     * @param data
     * @returns
     */

    public quickSightDashboardStatus = (data: { widgetId: string; noAlerts?: boolean }) => {
        return new Promise((resolve, reject) => {
            const slug = `${environment.analytics_api}/v1/dashboards/${data.widgetId}/status`;
            !data.noAlerts && this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    resolve(res);
                },
                (res) => {
                    !data.noAlerts && this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get analytics search criteria of charts.
     * @param data contains chainRestApiName, sourceName and search query params
     * @returns Promise<search criteria>
     */
    public getAnalyticsSearchCriteria = (data: {
        chainRestApiName: string;
        sourceName: string;
        searchArguments: { [property: string]: string };
        serviceId: string;
    }) => {
        return new Promise((resolve, reject) => {
            let sourceString = '',
                paramsString = '',
                queryStart = '?';
            if (data.sourceName?.length > 0) {
                sourceString = `?sourceName=${data.sourceName}`;
                queryStart = '&';
            }
            paramsString = Object.keys(data.searchArguments || {}).reduce((paramString, paramKey, index) => {
                paramString += `${queryStart}${paramKey}=${data.searchArguments[paramKey]}`;
                queryStart = '&';
                return paramString;
            }, '');
            const slug = data.serviceId
                ? `${environment.analytics_api}/v1/dashboards/${data.serviceId}/chain-criteria/${data.chainRestApiName}${sourceString}${paramsString}`
                : `${environment.analytics_api}/v1/chain-criteria/${data.chainRestApiName}${sourceString}${paramsString}`;
            this._utils.showLoading();
            this._comm.get(
                slug,
                (res) => {
                    this._utils.hideLoading();
                    resolve(res?.response);
                },
                (res) => {
                    this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to getAllEvents
     * @param data contains eventType, fetchSize, monthYear
     * @param s Success callback
     * @param f Failure callback
     */
    public getNotificationEvents = (
        data: {
            eventType?: string;
            fetchSize?: number;
            pagingState?: string;
            resourceId?: string;
            noAlerts?: boolean;
        },
        s: (response) => void,
        f: (response) => void
    ) => {
        let slug = environment.notifications_api + `/v1/event-log?resourceId=${data.resourceId}`;
        slug += data.fetchSize ? `&fetchSize=${data.fetchSize || ''}` : '';
        slug += data.eventType ? `&eventType=${data.eventType || ''}` : '';
        slug += data.pagingState ? `&pagingState=${data.pagingState || ''}` : '';
        !data.noAlerts && this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                s(res);
            },
            (res) => {
                !data.noAlerts && this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get app template
     */
    public getAppTemplate = (serviceId: string, noAlerts?: boolean) => {
        let slug = environment.taxilla_api + `/services/${serviceId}/ui-template`;
        !noAlerts && this._utils.showLoading();
        return new Promise<any>((resolve, reject) => {
            this._comm.get(
                slug,
                (res) => {
                    !noAlerts && this._utils.hideLoading();
                    resolve(res?.response);
                },
                (res) => {
                    !noAlerts && this._utils.hideLoading();
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to submit app template
     */
    public submitAppTemplate = (serviceId: string, template: AppTemplate) => {
        let slug = environment.taxilla_api + `/services/${serviceId}/ui-template`;
        return new Promise<any>((resolve, reject) => {
            this._comm.put(
                slug,
                template,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to delete app template
     */
    public deleteAppTemplate = (serviceId: string) => {
        let slug = environment.taxilla_api + `/services/${serviceId}/ui-template`;
        return new Promise<any>((resolve, reject) => {
            this._comm.delete(
                slug,
                undefined,
                (res) => {
                    resolve(res?.response);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get process logs
     */
    public getProcessLogs = (data: any, s: (response: any) => void, f: (response: any) => void) => {
        let slug = environment.notifications_api + `/v1/event-log?resourceId=${data.resourceId}`;
        slug += data.fetchSize ? `&fetchSize=${data.fetchSize || ''}` : '';
        slug += data.pagingState ? `&pagingState=${data.pagingState || ''}` : '';
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res) => {
                this._utils.hideLoading();
                s(res);
            },
            (res) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };

    /**
     * Method to get Roles By OrganizationId
     * @param data Payload
     * @param s Success callback
     * @param f Failure callback
     */
    public getRolesByOrganizationId = (
        data: { locationId: string; userId: string },
        s: (response: any) => void,
        f: (response: any) => void
    ) => {
        const slug = `${environment.taxilla_user_identity_api}/v1/roles/location/${data?.locationId}/user/${data?.userId}`;
        this._utils.showLoading();
        this._comm.get(
            slug,
            (res: any) => {
                this._utils.hideLoading();
                s(res);
            },
            (res: any) => {
                this._utils.hideLoading();
                f(res);
            }
        );
    };
}

// URL: https://dev.taxilla.com/notification/v1/
