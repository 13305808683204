<ng-container *ngIf="entity">
    <ng-container *ngIf="(readOnly && isRecordDefined) || !readOnly">
        <div class="recordGroupContainer row">
            <!-- This container is for business keys -->
            <ng-container *ngFor="let field of record.fields; trackBy:trackByFieldId">
                <ng-container *ngIf="field.isBusinessKey && field.show">
                    <ng-container *ngTemplateOutlet="recordInput; context: {field: field, enableField: (!record.readOnly && !field.disabled), record: record}"> </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="recordGroupContainer" *ngIf="!showOnlyBusinessKeyFields">
            <!-- This container is for non business keys -->
            <ng-container [ngSwitch]="readOnly">
                <ng-container *ngSwitchCase="true">
                    <ng-container *ngTemplateOutlet="(readOnlyGroups && readOnlyGroups.length > 0) ? recordFieldGroups : recordFieldsNonGrouped; context: { record: record, groups: readOnlyGroups }"> </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <ng-container *ngTemplateOutlet="(groups && groups.length > 0) ? recordFieldGroups : recordFieldsNonGrouped; context: { record: record, groups: groups }"> </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="readOnly && !isRecordDefined && !showOnlyBusinessKeyFields">
        <div class="recordNotCreated">
            <div class="recordNotCreatedText">
                No data found.
                <button *ngIf="!inAuditTrailMode && hasEditPermission" mat-button [color]="'default'" class="addDataButton" (click)="editRecord.emit()">Add Data</button>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #recordFieldGroups let-record="record" let-groups="groups">
    <ng-container *ngFor="let group of groups; trackBy: trackByGroup">
        <div class="groupedFields">
            <div class="groupedFieldsHeader" *ngIf="group && group.length > 0">
                <span>
                    {{group}}
                </span>
            </div>
            <div class="groupedFieldsBody mt-2 row">
                <ng-container *ngFor="let field of record.fields | FieldsGroup: group; trackBy: trackByFieldId">
                    <ng-container [ngSwitch]="field.inAudit">
                        <ng-container *ngSwitchCase="true">
                            <ng-container *ngIf="!field.isBusinessKey">
                                <ng-container *ngTemplateOutlet="recordInput; context: { field: field, enableField: (!record.readOnly && !field.disabled), record: record }"> </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <ng-container *ngIf="!field.isBusinessKey && ((readOnly && (field.datatype === 'GRID' || field.originalValue !== undefined)) || !readOnly)">
                                <ng-container *ngTemplateOutlet="recordInput; context: { field: field, enableField: (!record.readOnly && !field.disabled), record: record }"> </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #recordFieldsNonGrouped let-record="record">
    <div class="row">
        <ng-container *ngFor="let field of record.fields; trackBy: trackByFieldId">
            <ng-container *ngIf="!field.isBusinessKey && ((readOnly && (field.datatype === 'GRID' || field.originalValue !== undefined)) || !readOnly)">
                <ng-container *ngTemplateOutlet="recordInput; context: { field: field, enableField: (!record.readOnly && !field.disabled), record: record }"> </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #recordInput let-field="field" let-enableField="enableField" let-record="record">
    <div class="entityFieldContainer col-xs-3 col-3 wordBreak" *ngIf="field.show" [class.highlightField]="fieldsToHighlight.indexOf(field.id) > (-1)">
        <ng-container [ngSwitch]="readOnly">
            <ng-container *ngSwitchCase="true">
                <div class="fieldInReadOnly">
                    <div class="readOnlyFieldValues">
                        <div class="readOnlyLabel" [title]="field.displayName || field.name">
                            {{field.displayName || field.name}}
                            <mat-icon class="title_icon" *ngIf="field.isBusinessKey">
                                vpn_key
                            </mat-icon>
                            :
                        </div>
                        <ng-container [ngSwitch]="field.inAudit">
                            <ng-container *ngSwitchCase="true">
                                <div class="auditValueContainer" [class.recordDeleted]="!record.active">
                                    <div class="oldValueContainer" [hidden]="field.oldValue === ''">
                                        <ng-container *ngTemplateOutlet="readOnlyValueTemplate; context: { field: field, value: field.oldValue }"> </ng-container>
                                    </div>
                                    <div class="newValueContainer">
                                        <ng-container *ngTemplateOutlet="readOnlyValueTemplate; context: { field: field, value: field.newValue }"> </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <ng-container *ngTemplateOutlet="readOnlyValueTemplate; context: { field: field, value: field.originalValue }"> </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #readOnlyValueTemplate let-field="field" let-value="value">
    <ng-container [ngSwitch]="field.datatype">
        <ng-container *ngSwitchCase="'ATTACHMENT'">
            <div class="readOnlyValue" *ngIf="value">
                <ng-container [ngSwitch]="value !== undefined">
                    <ng-container *ngSwitchCase="true">
                        <span class="readOnlyAttachmentIcon" *transloco="let translate">
                            <mat-icon [title]="translate('Download files')" (click)="openAttachModal(field)">attach_file</mat-icon>
                        </span>
                        <ng-container *ngFor="let file of value; let i = index; trackBy: trackByFile">
                            <span class="fieldFileName" [title]="file.substring(file.lastIndexOf('/') + 1)">
                                <ng-container [ngSwitch]="file.indexOf('http://') > (-1) || file.indexOf('https://') > (-1)">
                                    <a *ngSwitchCase="true" [href]="file" target="_blank">
                                        {{ file.substring(file.lastIndexOf('/') + 1) }}
                                    </a>
                                    <a *ngSwitchDefault [href]="attachmentUri + file" target="_blank">
                                        {{ file.substring(file.lastIndexOf('/') + 1) }}
                                    </a>
                                </ng-container>
                            </span>
                            <ng-container *ngIf="value[i+1]">
                                ,
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        '--'
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'DATE'">
            <div class="readOnlyValue" [title]="value !== undefined ? value : '--'">
                {{value !== undefined ? value : '--'}}
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'GRID'">
            <grid-field [field]="field" [readOnly]="true" [hideDisplayNameData]="true"></grid-field>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ng-container [ngSwitch]="value !== undefined && field.uiTypeMetadata">
                <ng-container *ngSwitchCase="'PASSWORD'">
                    <div class="readOnlyValue readOnlyPasswordValue">
                        *******
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div class="readOnlyValue" [title]="value !== undefined ? value : '--'">
                        {{value !== undefined ? value : '--'}}
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>
