import { Organization } from './organization.class';

export class Connection {
    id: string;

    createdByUserId: string;

    createdDate: string;

    lastModifiedDate: string;

    organizationId: string;

    partnerId: string;

    partnerOrgId: string;

    enabled: boolean;

    status: string;

    systemDefined: boolean;

    association: string;

    requestedBy: string;

    orgName: string;

    stateName: string;

    cityName: string;

    partnerOrganization: Organization;

    constructor(connection) {
        this.id = connection.id;
        this.createdByUserId = connection.createdByUserId;
        this.createdDate = connection.createdDate;
        this.lastModifiedDate = connection.lastModifiedDate;
        this.organizationId = connection.organizationId;
        this.partnerId = connection.partnerId;
        this.partnerOrgId = connection.partnerOrgId;
        this.enabled = connection.enabled;
        this.status = connection.status;
        this.systemDefined = connection.systemDefined;
        this.association = connection.association;
        this.requestedBy = connection.requestedBy;
        this.partnerOrganization = new Organization(connection.partnerOrganization || {});
        if (connection.partnerOrganization) {
            if (connection.partnerOrganization.parent) {
                this.orgName = connection.partnerOrganization.parent.name;
                this.cityName = '-';
                this.stateName = connection.partnerOrganization.name;
            }
            if (connection.partnerOrganization.parent && connection.partnerOrganization.parent.parent) {
                this.orgName = connection.partnerOrganization.parent.parent.name;
                this.cityName = connection.partnerOrganization.name;
                this.stateName = connection.partnerOrganization.parent.name;
            }
            if (connection.partnerOrganization.parent === undefined) {
                this.orgName = connection.partnerOrganization.name;
                this.cityName = '-';
                this.stateName = '-';
            }
        }
    }
}
