import { NgModule } from '@angular/core';
import { environment } from '@env';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { Effects } from './effects';
import {
    ACTIVE_TOKENS_REDUCER,
    ACTIVE_TOKENS_REDUCER_KEY,
    APPS_REDUCER,
    APPS_REDUCER_KEY,
    AUTH_REDUCER,
    AUTH_REDUCER_KEY,
    AUTHORIZATION_SYSTEMS_REDUCER,
    AUTHORIZATION_SYSTEMS_REDUCER_REDUCER_KEY,
    BUNDLES_REDUCER,
    BUNDLES_REDUCER_KEY,
    DASHBOARD_REDUCER,
    DASHBOARD_REDUCER_KEY,
    GST_FILING_REDUCER,
    GST_FILING_REDUCER_KEY,
    IDENTITY_PROVIDERS_REDUCER,
    IDENTITY_PROVIDERS_REDUCER_KEY,
    INSTANCES_REDUCER,
    INSTANCES_REDUCER_KEY,
    INTEGRATIONS_REDUCER,
    INTEGRATIONS_REDUCER_KEY,
    LOCATIONS_REDUCER,
    LOCATIONS_REDUCER_KEY,
    MANAGE_APPS_REDUCER,
    MANAGE_APPS_REDUCER_KEY,
    MASTERS_REDUCER,
    MASTERS_REDUCER_KEY,
    NOTIFICATIONS_REDUCER,
    NOTIFICATIONS_REDUCER_KEY,
    PARTNER_REDUCER,
    PARTNER_REDUCER_KEY,
    PERMISSIONS_REDUCER,
    PERMISSIONS_REDUCER_KEY,
    RECORDS_REDUCER,
    RECORDS_REDUCER_KEY,
    REPORTS_REDUCER,
    REPORTS_REDUCER_KEY,
    REQUESTS_REDUCER,
    REQUESTS_REDUCER_KEY,
    RESET_PASSWORD_REDUCER,
    RESET_PASSWORD_REDUCER_KEY,
    ROLES_REDUCER,
    ROLES_REDUCER_KEY,
    SEARCH_CRITERIA_REDUCER,
    SEARCH_CRITERIA_REDUCER_KEY,
    SEARCH_REDUCER,
    SEARCH_REDUCER_KEY,
    SESSION_REDUCER,
    SESSION_REDUCER_KEY,
    SHARED_REDUCER,
    SHARED_REDUCER_KEY,
    TAGS_REDUCER,
    TAGS_REDUCER_KEY,
    TRANSFORMATIONS_REDUCER,
    TRANSFORMATIONS_REDUCER_KEY,
    USERS_REDUCER,
    USERS_REDUCER_KEY,
    WIDGET_REDUCER,
    WIDGET_REDUCER_KEY,
    WORKFLOW_REDUCER,
    WORKFLOW_REDUCER_KEY,
} from './reducers';

const REDUCERS = {
    [ACTIVE_TOKENS_REDUCER_KEY]: ACTIVE_TOKENS_REDUCER,
    [APPS_REDUCER_KEY]: APPS_REDUCER,
    [AUTH_REDUCER_KEY]: AUTH_REDUCER,
    [AUTHORIZATION_SYSTEMS_REDUCER_REDUCER_KEY]: AUTHORIZATION_SYSTEMS_REDUCER,
    [BUNDLES_REDUCER_KEY]: BUNDLES_REDUCER,
    [DASHBOARD_REDUCER_KEY]: DASHBOARD_REDUCER,
    [GST_FILING_REDUCER_KEY]: GST_FILING_REDUCER,
    [INSTANCES_REDUCER_KEY]: INSTANCES_REDUCER,
    [INTEGRATIONS_REDUCER_KEY]: INTEGRATIONS_REDUCER,
    [LOCATIONS_REDUCER_KEY]: LOCATIONS_REDUCER,
    [MANAGE_APPS_REDUCER_KEY]: MANAGE_APPS_REDUCER,
    [NOTIFICATIONS_REDUCER_KEY]: NOTIFICATIONS_REDUCER,
    [PARTNER_REDUCER_KEY]: PARTNER_REDUCER,
    [PERMISSIONS_REDUCER_KEY]: PERMISSIONS_REDUCER,
    [RECORDS_REDUCER_KEY]: RECORDS_REDUCER,
    [REQUESTS_REDUCER_KEY]: REQUESTS_REDUCER,
    [REPORTS_REDUCER_KEY]: REPORTS_REDUCER,
    [RESET_PASSWORD_REDUCER_KEY]: RESET_PASSWORD_REDUCER,
    [ROLES_REDUCER_KEY]: ROLES_REDUCER,
    [SEARCH_CRITERIA_REDUCER_KEY]: SEARCH_CRITERIA_REDUCER,
    [SESSION_REDUCER_KEY]: SESSION_REDUCER,
    [SHARED_REDUCER_KEY]: SHARED_REDUCER,
    [TAGS_REDUCER_KEY]: TAGS_REDUCER,
    [TRANSFORMATIONS_REDUCER_KEY]: TRANSFORMATIONS_REDUCER,
    [USERS_REDUCER_KEY]: USERS_REDUCER,
    [WIDGET_REDUCER_KEY]: WIDGET_REDUCER,
    [WORKFLOW_REDUCER_KEY]: WORKFLOW_REDUCER,
    [MASTERS_REDUCER_KEY]: MASTERS_REDUCER,
    [IDENTITY_PROVIDERS_REDUCER_KEY]: IDENTITY_PROVIDERS_REDUCER,
    [SEARCH_REDUCER_KEY]: SEARCH_REDUCER,
};

@NgModule({
    imports: [
        StoreModule.forRoot({ ...REDUCERS }),
        EffectsModule.forRoot(Effects),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ],
})
export class NgRXStoreModule {}
