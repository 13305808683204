import { createReducer, on } from '@ngrx/store';

import {
    ClearInstanceData,
    RemoveInstances,
    SelectInstances,
    SessionLogoutSuccessfull,
    SetCurrentOrganization,
    SetInstance,
    SetInstanceEntityErrors,
    SetInstancesByProcessStatus,
    SetInstancesLoading,
    SetInstanceState,
    SetProcessStatuses,
    SetSearchInstances,
    SetSelectedProcessStatus,
} from '../actions';
import { instancesState } from '../states';

export const INSTANCES_REDUCER = createReducer(
    instancesState,
    on(SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organization.id]: {
                    ...(state.organizationsMap?.[action.organization.id] || {}),
                },
            },
        };
    }),
    on(SetInstance, (state, action) => {
        return {
            ...state,
            instances: {
                ...state.instances,
                [action.instanceId]: {
                    ...state.instances?.[action.instanceId],
                    ...action.instance,
                },
            },
        };
    }),
    on(SetSearchInstances, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        searchedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.searchedInstances,
                            [action.criteria]: action.instances.reduce((instanceIds, instance) => {
                                instanceIds.push(instance.assetDataId);
                                return instanceIds;
                            }, []),
                        },
                    },
                },
            },
            instances: {
                ...state.instances,
                ...action.instances.reduce((instancesMap, instance) => {
                    instancesMap[instance.assetDataId] = instance;
                    return instancesMap;
                }, {}),
            },
        };
    }),
    on(SelectInstances, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        selectedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.selectedInstances,
                            [action.criteria]: [...new Set(action.instanceIds)],
                        },
                    },
                },
            },
        };
    }),
    on(RemoveInstances, (state, action) => {
        const searchedInstancesMap = state.organizationsMap[action.organizationId]?.[action.serviceId] || { searchedInstances: {} };
        const instanceIds = searchedInstancesMap?.searchedInstances?.[action.criteria];
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        searchedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.searchedInstances,
                            [action.criteria]: instanceIds.filter((id) => !action.instanceIds.includes(id)),
                        },
                    },
                },
            },
        };
    }),
    on(SetInstanceState, (state, action) => {
        return {
            ...state,
            instancesState: {
                ...state.instancesState,
                [action.instanceId]: action.state,
            },
        };
    }),
    on(SetInstanceEntityErrors, (state, action) => {
        return {
            ...state,
            instanceEntitiesState: {
                ...state.instanceEntitiesState,
                [action.instanceId]: action.state,
            },
        };
    }),
    on(ClearInstanceData, (state, action) => {
        return {
            ...state,
            instanceEntitiesState: {
                ...state.instanceEntitiesState,
                [action.instanceId]: undefined,
            },
            instancesState: {
                ...state.instancesState,
                [action.instanceId]: undefined,
            },
        };
    }),
    on(SetProcessStatuses, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        statuses: action.statuses,
                    },
                },
            },
        };
    }),
    on(SetSelectedProcessStatus, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        selectedStatus: action.processStatus,
                    },
                },
            },
        };
    }),
    on(SetInstancesByProcessStatus, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        instancesByStatus: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.instancesByStatus,
                            [action.criteria]: {
                                ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.instancesByStatus?.[action.criteria],
                                ...{ [action.processStatus]: action.instances },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetInstancesLoading, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        loading: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.loading,
                            [action.category]: action.loading,
                        },
                    },
                },
            },
        };
    }),
    on(SessionLogoutSuccessfull, (state, action) => {
        return {
            instances: {},
            organizationsMap: {},
            instanceEntitiesState: {},
            instancesState: {},
        };
    })
);

export const INSTANCES_REDUCER_KEY = 'instances';
