import { createAction, props } from '@ngrx/store';

import { FieldDefinition } from '../../models/field-definition.class';
import { Organization } from '../../models/organization.class';

export enum LocationActionTypes {
    GET_ASSOCIATED_LOCATIONS = '[LOCATIONS] Get Associated',
    SET_ASSOCIATED_LOCATIONS = '[LOCATIONS] Set Associated',
    GET_LOCATION = '[LOCATIONS] Get',
    SET_LOCATION = '[LOCATIONS] Set',
    UPDATE_LOCATION = '[LOCATIONS] Update',
    UPDATED_LOCATION = '[LOCATIONS] Updated',
    UPDATE_LOCATION_LOGO = '[LOCATIONS] Update organization logo',
    DELETE_LOCATION_LOGO = '[LOCATIONS] Delete organization logo',
    GET_LOCATION_LOGO = '[LOCATIONS] Get organization logo',
    GET_CURRENT_LOCATION_LOGO = '[LOCATIONS] Get Current organization logo',
    SET_LOCATION_LOGO = '[LOCATIONS] Set organization logo',
    TOGGLE_LOCATION = '[LOCATIONS] Location Toggle',
    GET_FLEX_FIELDS = '[LOCATIONS] Get Flex Fields',
    SET_FLEX_FIELDS = '[LOCATIONS] Set Flex Fields',
    GET_CURRENT_LOCATION = '[LOCATIONS] Get Current Organization',
    GET_LOCATIONS_CODES = '[LOCATIONS] Get Locations Codes',
    SET_LOCATIONS_CODES = '[LOCATIONS] Set Locations Codes',
    GET_ORGANIZATION_LOCATIONS = '[LOCATIONS] Get Organization Locations',
    SET_ORGANIZATION_LOCATIONS = '[LOCATIONS] Set Organization Locations',
    SET_ORG_DETAILS_MESSAGE = '[LOCATIONS] Set Org Details Message',
    CLEAR_ORG_DETAILS_MESSAGE = '[LOCATIONS] Cleaar Org Details Message',
    GET_ORGANIZATION_CHILD_HIERARCHY = '[LOCATIONS] Get Organization Child Hierarchy',
    SET_ORGANIZATION_CHILD_HIERARCHY = '[LOCATIONS] Set Organization Child Hierarchy',
    SET_IN_ASSOCIATIONS_TREE = '[LOCATIONS] Set Organization In Associations Tree',
    UPDATE_IN_ASSOCIATIONS_TREE = '[LOCATIONS] Update Organization In Associations Tree',
}

export const GetLocation = createAction(
    LocationActionTypes.GET_LOCATION,
    props<{
        organizationId: string;
        update: boolean;
        fromComponent?: string;
        setAsCurrentOrganization?: boolean;
    }>()
);

export const SetLocation = createAction(
    LocationActionTypes.SET_LOCATION,
    props<{
        organizationId: string;
        organization: Organization;
        parentId: string;
    }>()
);

export const SetLocationInAssociationsTree = createAction(
    LocationActionTypes.SET_IN_ASSOCIATIONS_TREE,
    props<{
        organizationId: string;
        organization: Organization;
        parentId: string;
    }>()
);

export const UpdateLocationInAssociationsTree = createAction(
    LocationActionTypes.UPDATE_IN_ASSOCIATIONS_TREE,
    props<{
        organizationId: string;
        organizations: Organization[];
        parentId: string;
    }>()
);

export const UpdateLocation = createAction(
    LocationActionTypes.UPDATE_LOCATION,
    props<{
        payload: Organization;
        update: boolean;
    }>()
);

export const UpdatedLocation = createAction(
    LocationActionTypes.UPDATED_LOCATION,
    props<{
        organizationId: string;
        organization: Organization;
        updated: boolean;
        parentId: string;
    }>()
);

export const GetCurrentOrganization = createAction(LocationActionTypes.GET_CURRENT_LOCATION);

export const GetLocationsCodes = createAction(LocationActionTypes.GET_LOCATIONS_CODES);

export const SetLocationsCodes = createAction(
    LocationActionTypes.SET_LOCATIONS_CODES,
    props<{
        organizationId: string;
        locationCodes: {};
    }>()
);

export const UpdateLocationLogo = createAction(
    LocationActionTypes.UPDATE_LOCATION_LOGO,
    props<{
        payload: FormData;
    }>()
);

export const DeleteLocationLogo = createAction(LocationActionTypes.DELETE_LOCATION_LOGO);

export const GetLocationLogo = createAction(
    LocationActionTypes.GET_LOCATION_LOGO,
    props<{
        enableHierarchy: boolean;
        forFetch?: boolean;
    }>()
);

export const SetLocationLogo = createAction(
    LocationActionTypes.SET_LOCATION_LOGO,
    props<{
        currentOrgId: string;
        logoData: string;
        locationHierarchy: boolean;
    }>()
);

export const ToggleLocation = createAction(
    LocationActionTypes.TOGGLE_LOCATION,
    props<{
        id: string;
        enabled: boolean;
    }>()
);

export const GetFlexFields = createAction(LocationActionTypes.GET_FLEX_FIELDS);

export const SetFlexFields = createAction(
    LocationActionTypes.SET_FLEX_FIELDS,
    props<{
        flexFields: FieldDefinition[];
    }>()
);

export const GetAssociatedLocations = createAction(LocationActionTypes.GET_ASSOCIATED_LOCATIONS);

export const SetAssociatedLocations = createAction(
    LocationActionTypes.SET_ASSOCIATED_LOCATIONS,
    props<{
        organizations: Organization[];
    }>()
);

export const GetOrganizationLocations = createAction(LocationActionTypes.GET_ORGANIZATION_LOCATIONS);

export const SetOrganizationLocations = createAction(
    LocationActionTypes.SET_ORGANIZATION_LOCATIONS,
    props<{
        organizations: Organization[];
        organizationId: string;
    }>()
);

export const SetOrgDetailsMessage = createAction(
    LocationActionTypes.SET_ORG_DETAILS_MESSAGE,
    props<{
        msgType: string;
        msg: string;
        isSuccess: boolean;
    }>()
);

export const ClearOrgDetailsMessage = createAction(LocationActionTypes.CLEAR_ORG_DETAILS_MESSAGE);

export const GetOrganizationChildHierarchy = createAction(LocationActionTypes.GET_ORGANIZATION_CHILD_HIERARCHY);

export const SetOrganizationChildHierarchy = createAction(
    LocationActionTypes.SET_ORGANIZATION_CHILD_HIERARCHY,
    props<{
        orgId: string;
        childHierarchy: any;
    }>()
);
