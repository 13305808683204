<ng-container *ngIf="showDowntimeMessage && currentDowntime">
    <div class="widgetDowntimeMsg" *ngIf="widget && widget.name; else appDowntime">
        <span class="material-icons downtimeIcon outside">schedule</span>
        <ng-container *ngTemplateOutlet="appDowntime"></ng-container>
    </div>
    <ng-template #appDowntime>
        <div class="dowmtimeMessage">
            <span class="material-icons closeImpMsg" matTooltip="Close" (click)="showDowntimeMessage = false;">close</span>
            <div class="title">
                <span class="material-icons downtimeIcon" matTooltip="Downtime Schedule">schedule</span>
                Downtime from {{startTime}} to {{endTime}}
            </div>
            <div class="msgBody">
                {{currentDowntime.message}}
            </div>
        </div>
    </ng-template>
</ng-container>
