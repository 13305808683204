import { createAction, props } from '@ngrx/store';

import { Dashboard } from '../../interface/dashboard.interface';

export enum DashboardActionType {
    DASHBAORD_SELECT = '[DASHBOARD] Select',
    GET_DASHBOARDS = '[DASHBOARD] Get Dashboards',
    SET_DASHBOARDS = '[DASHBOARD] Set Dashboards',
    SET_DASHBOARD_LOADER = '[DASHBOARD] Set Dashboard Loader',
}

export const SelectDashboard = createAction(
    DashboardActionType.DASHBAORD_SELECT,
    props<{
        dashboard: Dashboard;
    }>()
);

export const GetDashboards = createAction(
    DashboardActionType.GET_DASHBOARDS,
    props<{
        noAlerts?: boolean;
    }>()
);

export const SetDashboards = createAction(
    DashboardActionType.SET_DASHBOARDS,
    props<{
        analyticDashboards: [];
        currentOrganizationId: string;
    }>()
);

export const SetDashboardLoader = createAction(
    DashboardActionType.SET_DASHBOARD_LOADER,
    props<{
        isDashboardsLoading: boolean;
    }>()
);
