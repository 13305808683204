import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDENTITY_PROVIDERS_REDUCER_KEY } from '../reducers';
import { IdentityProvidersState } from '../states';
import { getCurrentOrganizationId$ } from './session.selector';

const identityProvidersState = createFeatureSelector<IdentityProvidersState>(IDENTITY_PROVIDERS_REDUCER_KEY);

export const getOrganizationMap$ = createSelector(
    identityProvidersState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.organizationsMap?.[organizationId]
);

export const getIdentityProviders$ = createSelector(getOrganizationMap$, (orgMap) => orgMap?.identityProviders || []);
