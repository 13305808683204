import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { translate } from '@ngneat/transloco';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Subject, Subscription } from 'rxjs';
import { CommonUtilsService, PaginationInterface, TableRecordAction, UtilsService } from 'taxilla-library';

@Component({
    selector: 'encomply-material-table',
    templateUrl: './encomply-table.component.html',
    styleUrls: ['./encomply-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class EnComplyMaterialTableComponent implements OnInit, OnChanges, DoCheck, OnDestroy, AfterViewInit {
    @Input() data;
    @Input() drawer; // If we have sliding available then drawer should be passed.
    @Input() allColumns: any[];
    @Input() primaryColumns;
    @Input() columnsWithcheckBox; // FOR COLUMNS ALONG WITH CHECKBOX
    @Input() columnsWithcheckBoxDetails;
    @Input() selectedColumns;
    @Input() showRowExpansion: boolean;
    @Input() workflow = false;
    @Input() noAction: boolean; // If we don't want action available then noAction should be passed
    @Input() noRecordMsg: string;
    @Input() wordWrap = false;
    @Input() passwordColumns?: any[]; // This is to identify the columns of type password
    @Input() tableNotAvailable = false;
    @Input() pagination: PaginationInterface;
    @Input() hidePaginator;
    @Input() defaultPaginator;
    @Input() hideTableColumns;
    @Input() columnSearchRequired = false;
    @Input() inputBoxColumns; // IN CASE IF INPUT IS NEED INSIDE THE TD OF THE TR
    @Input() inputInTableColumns = false;
    @Input() adminUserId?: string;
    @Input() deleteActionEvent;
    @Input() recordActions: TableRecordAction[];
    @Input() noLog = false;
    @Input() headerNamesToBeShown;
    @Input() columnselectSearchRequired = false;
    @Input() allOrg;
    @Input() allOrgAssets;
    @Input() disablePrevious = false;
    @Input() disableNext = false;
    @Input() newCustomPaginator = false;
    @Input() customPageIndex;
    @Input() pageIndexSelected = 1;
    @Input() customSize = [10, 20, 50, 100];
    @Input() sizeSelected = 10;
    @Input() emptyTableData = false;
    @Input() fromIntegrationsSetting = false;
    @Input() selectedRecordsCount: number;
    @Input() supressScroll = false;
    @Input() showFirstLastButtons = true;
    @Input() translationEnabled: boolean;
    @Input() selectedRecord;
    @Input() dontChangeImmediately: boolean;
    @Input() dragEnabled: boolean = false;
    @Input() dragConditionProperty: string;

    @Output() fetchItemsInPage = new EventEmitter();
    @Output() refreshItemsWithNewPageSize = new EventEmitter();
    @Output() fetchItemsInPageWithPageIndex = new EventEmitter();
    @Output() onSelectCheckbox = new EventEmitter();
    @Output() onSingleClickEvent = new EventEmitter();
    @Output() onDoubleClickEvent = new EventEmitter();
    @Output() onSlideToggle = new EventEmitter();
    @Output() checkMasterRecord = new EventEmitter();
    @Output() checkedData = new EventEmitter(); // THIS IS TO SEND THE CURRENT OBJECT WHICH IS CHECKED
    @Output() onDownloadToggle = new EventEmitter();
    @Output() onCheckSumToggle = new EventEmitter();
    @Output() copyRequestId = new EventEmitter();
    @Output() selectionEventEmitter = new EventEmitter();
    @Output() onSearchEvent = new EventEmitter();
    @Output() onReorderRows = new EventEmitter();

    @ViewChild('selectedOrgMultipleList') subscribeOrg: MatSelectionList;
    @ViewChild('selectedOrgAssetMultipleList') subscribeAsset: MatSelectionList;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('defaultMatPaginator', { static: false }) defaultMatPaginator: MatPaginator;
    @ViewChild(PerfectScrollbarComponent) private scrollRef: PerfectScrollbarComponent;

    selectedOrgs = new UntypedFormControl();
    selectedAsset = new UntypedFormControl();
    searchColumnControl = new UntypedFormControl();
    clonedColumns: string[];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);
    expandedElement;
    selectAll;
    searchColumns: any;
    actionColumns = ['Table Actions', 'log', 'checkbox'];
    throwAtLast = ['Table Actions', 'log'];
    showLog = true;
    noRecordsFound = false;
    disbaledData = false;
    searchColumnValue = '';
    selectedStatus = '';
    searchOrgString = '';
    searchAssetOrgString = '';
    selectedSearchColumn = '';
    enteredSearchValue = '';
    selectedOrgsArray = [];
    selectedAssetsArray = [];
    currentAllColumnsString: string;
    searchSubscription: Subscription;
    newOrgAdded: Subscription;
    dataSourceLoading = [
        { position: 111, name: 'Hydrogen', weight: 1.0079, symbol: 'Hhh' },
        { position: 222, name: 'Hydrogen', weight: 4.0026, symbol: 'Hhh' },
    ];
    displayedColumnsWhileLoading: string[] = ['name', 'weight', 'symbol', 'position'];
    columnsToDisplayWhileLoading: string[] = this.displayedColumnsWhileLoading.slice();
    lastPageIsDisabled: boolean;
    rowsData: { [property: string]: { [property: string]: { [property: string]: string | boolean } } } = {};
    columnsData: { [property: string]: { [property: string]: string | boolean } } = {};
    canShowTableData: boolean;
    unSubscribe = new Subject<void>();
    defaultMatPaginatorLength = 0;
    defaultMatPaginatorSubscription: Subscription;
    defaultPaginatorSet: boolean;
    showingLoadingData: boolean;

    constructor(public _utils: Utils, public R: RootScope, private _libUtils: UtilsService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.selectedColumns?.currentValue) {
            this.R.selectedColumnsOftable = changes.selectedColumns.currentValue;
        }
        if (changes.emptyTableData) {
            this.showingLoadingData = this.showLoaderData();
        }
    }

    ngDoCheck() {
        if (JSON.stringify(this.data) !== JSON.stringify(this.dataSource.data)) {
            (this.data || []).forEach((data, index) => {
                if (!data.id) {
                    data.id = index;
                }
            });
            this.dataSource.data = Object.assign([], this.data).slice(0);
            this.buildSelectionModal();
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.pushSpecialColumns();
            this.dataSource.filterPredicate = (data, filter) => {
                return this.selectedColumns.some((ele) => {
                    return (
                        ele !== 'Table Actions' &&
                        ele !== 'log' &&
                        ele !== 'checkbox' &&
                        ele !== 'enabled' &&
                        data[ele] &&
                        data[ele].toLocaleLowerCase().indexOf(filter) !== -1
                    );
                });
            };
            this.lastPageIsDisabled = this.isLastPageDisabled();
            this.rowsData = {};
            this.dataSource.data.forEach((element) => {
                this.rowsData[element.id] = {};
                const cols = [...Object.keys(element), ...this.allColumns];
                cols.forEach((column) => {
                    this.rowsData[element.id][column] = {};
                    this.rowsData[element.id][column].isDeltaCapturePresent = this.isDataCapturePresent(column, element);
                    this.rowsData[element.id][column].isReportsPresent = this.isReportsPresent(column, element);
                    if (!this.rowsData[element.id][column].isDeltaCapturePresent && !this.rowsData[element.id][column].isReportsPresent) {
                        this.rowsData[element.id][column].parsedText = this.parsedText(column);
                    }
                });
            });
            this.setPaginationLength();
            if (this.defaultPaginator) {
                this.defaultMatPaginatorLength = this.data?.length || 0;
            }
            this.setSelectedRecordsCount();
        }
        if (JSON.stringify(this.allColumns) !== this.currentAllColumnsString) {
            this.pushSpecialColumns();
            this.clonedColumns = this.selectedColumns.slice(0);
            this.currentAllColumnsString = JSON.stringify(this.allColumns.slice(0));
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.columnsData = {};
            this.allColumns.forEach((column) => {
                this.columnsData[column] = {
                    hasInput: this.isThisInput(column),
                    isDeltaCapturePresent: this.isDataCapturePresent(column),
                    isReportsPresent: this.isReportsPresent(column),
                    isPassword: column === 'Password' || this.isThisPassword(column),
                };
                this.columnsData[column].hasCheckbox = this.isColumnsWithcheckBox(column);
            });
            this.selectAll = this.checkFiltersSelected();
        }
        this.canShowTableData = this.showTableData();
        this.showingLoadingData = this.showLoaderData();
    }

    buildSelectionModal = () => {
        this.selection.clear();
        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].selected) {
                this.selection.select(this.dataSource.data[i]);
            } else {
                this.selection.deselect(this.dataSource.data[i]);
            }
        }
        // this.onSelectCheckbox.emit(this.selection.selected);
    };

    checkboxSelect = (checked: boolean, column) => {
        if (checked) {
            const index = this.allColumns.indexOf(column);
            if (index !== -1) {
                this.clonedColumns.splice(index, 0, column);
            } else if (this.clonedColumns.indexOf(column) === -1) {
                this.clonedColumns.push(column);
            }
        } else {
            const index = this.clonedColumns.indexOf(column);
            this.clonedColumns.splice(index, 1);
        }
        if (this.actionColumns.length !== 0) {
            this.actionColumns.forEach((eachAction) => {
                const actionIndex = this.clonedColumns.indexOf(eachAction);
                if (actionIndex !== -1) {
                    this.clonedColumns.splice(actionIndex, 1);
                }
            });
        }
        this.selectAll = this.checkFiltersSelected();
    };

    cloneSubmit = () => {
        const actionColumns = this.actionColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        const newColumns = this.clonedColumns.filter((column) => {
            if (actionColumns.indexOf(column) === -1 || column === 'checkbox') {
                return column;
            }
        });
        const tempColumns = newColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        if (tempColumns && tempColumns.length > 0) {
            // update condition for row record checkbox
            if (tempColumns.length === 1 && tempColumns[0] === 'checkbox') {
                this._libUtils.alertError(translate('Select atleast one column name'));
            } else {
                this.selectedColumns = tempColumns;
                this.R.selectedColumnsOftable = this.selectedColumns;
                this.pushSpecialColumns('fromCloneSubmit');
            }
        } else {
            this._libUtils.alertError(translate('Select atleast one column name'));
        }
    };

    pushSpecialColumns = (option?) => {
        this.throwAtLast
            .filter((columnName) => this.selectedColumns.indexOf(columnName) === -1)
            .forEach((columnName) => {
                if (columnName === 'Table Actions') {
                    if (!this.noAction) {
                        this.selectedColumns.push(columnName);
                    }
                } else if (columnName === 'log') {
                    if (!this.noLog) {
                        this.selectedColumns.push(columnName);
                    }
                } else {
                    this.selectedColumns.push(columnName);
                }
            });
        if (
            option &&
            option === 'fromCloneSubmit' &&
            this.allColumns.indexOf('checkbox') > -1 &&
            this.selectedColumns.indexOf('checkbox') === -1
        ) {
            this.selectedColumns.unshift('checkbox');
        }
    };

    selectAllFilters = (selectedAll) => {
        const oldColumns = this.clonedColumns.slice(0);
        const actionColumns = this.actionColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        this.clonedColumns.splice(0);
        oldColumns
            .filter((column) => actionColumns.indexOf(column) > -1)
            .forEach((column) => {
                this.clonedColumns.indexOf(column) === -1 && this.clonedColumns.push(column);
            });
        if (selectedAll) {
            this.allColumns
                .filter(
                    (column) =>
                        this.actionColumns.indexOf(column) === -1 &&
                        !(this.hideTableColumns && this.hideTableColumns.indexOf(column) > -1) &&
                        this.clonedColumns.indexOf(column) === -1
                )
                .forEach((column) => {
                    this.clonedColumns.push(column);
                });
        }
        this.selectAll = this.checkFiltersSelected();
    };

    onSingleClick = (record) => {
        this.onSingleClickEvent.emit(record);
    };

    onDoubleClick = (record) => {
        const recordClone = CommonUtilsService.cloneObject(record);
        recordClone['drawer'] = this.drawer;
        this.onDoubleClickEvent.emit(recordClone);
    };

    private isLastPageDisabled = (): boolean => {
        if (this.hidePaginator === true) {
            return true;
        }
        let disabled = true;
        if (this.pagination && this.pagination.page) {
            const keys = Object.keys(this.pagination.page);
            const lastValue = this.pagination.page[keys[keys.length - 1]];
            disabled = lastValue && lastValue !== undefined && lastValue.length > 0;
        }
        return disabled;
    };

    checkColumnsCount = () => {
        if (this.allColumns && this.actionColumns) {
            const filteredColumns = this.allColumns && this.allColumns.filter((column) => this.actionColumns.indexOf(column) === -1);
            return filteredColumns && filteredColumns.length >= 5;
        }
        return true;
    };

    checkRecord = (element) => {
        this.checkMasterRecord.emit(element);
    };

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.getSelectedRecords();
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
        this.onSelectCheckbox.emit(this.selection.selected);
        this.setSelectedRecordsCount();
    }

    setSelectedRecordsCount = () => (this.selectedRecordsCount = this.getSelectedRecords());

    getSelectedRecords = (): number => this.selection.selected.length;

    customMasterToggle = (event, column) => {
        if (column) {
            for (let i = 0; i < this.columnsWithcheckBoxDetails.length; i++) {
                if (column === this.columnsWithcheckBoxDetails[i].name) {
                    for (let j = 0; j < this.data.length; j++) {
                        if (
                            this.data[j].skip[this.columnsWithcheckBoxDetails[i].disableValue] !== undefined &&
                            this.data[j].skip[this.columnsWithcheckBoxDetails[i].disableValue] === false
                        ) {
                            continue;
                        } else {
                            if (this.columnsWithcheckBoxDetails[i]['displayBasedOn'] !== undefined) {
                                this.data[j][this.columnsWithcheckBoxDetails[i].checkValue] = this.data[j][
                                    this.columnsWithcheckBoxDetails[i]['displayBasedOn']
                                ]
                                    ? event
                                    : this.data[j][this.columnsWithcheckBoxDetails[i].checkValue];
                            } else {
                                this.data[j][this.columnsWithcheckBoxDetails[i].checkValue] = event;
                            }
                        }
                    }
                }
            }
        }
    };

    customerMasterCheck = (column) => {
        if (column && this.data.length > 0) {
            for (let i = 0; i < this.columnsWithcheckBoxDetails.length; i++) {
                if (column === this.columnsWithcheckBoxDetails[i].name) {
                    for (let j = 0; j < this.data.length; j++) {
                        if (
                            this.data[j].skip[this.columnsWithcheckBoxDetails[i].disableValue] !== undefined &&
                            this.data[j].skip[this.columnsWithcheckBoxDetails[i].disableValue] === false
                        ) {
                            continue;
                        } else if (this.columnsWithcheckBoxDetails[i]['displayBasedOn'] !== undefined) {
                            if (this.data[j][this.columnsWithcheckBoxDetails[i]['displayBasedOn']]) {
                                if (this.data[j][this.columnsWithcheckBoxDetails[i].checkValue] === false) {
                                    return false;
                                }
                            } else {
                                continue;
                            }
                        } else if (this.data[j][this.columnsWithcheckBoxDetails[i].checkValue] === false) {
                            return false;
                        }
                    }
                }
            }
            return true;
        } else {
            return false;
        }
    };

    onCheckboxSelection = (element, column?) => {
        this.selection.toggle(element);
        this.checkedData.emit(element); // THIS IS TO SEND THE CURRENT OBJECT WHICH IS CHECKED
        this.onSelectCheckbox.emit(this.selection.selected);
        this.selectAll = this.checkFiltersSelected();
        this.setSelectedRecordsCount();
    };

    changeStatus = (event, inputSource) => {
        this.onSlideToggle.emit({ element: event, input: inputSource });
    };

    isTheValueChecked = (element, column) => {
        if (this.columnsWithcheckBoxDetails) {
            for (let i = 0; i < this.columnsWithcheckBoxDetails.length; i++) {
                if (column === this.columnsWithcheckBoxDetails[i].name) {
                    return element[this.columnsWithcheckBoxDetails[i].checkValue];
                }
            }
        } else {
            return false;
        }
    };

    changeCheckedValue = (element, column) => {
        this.disbaledData = true;
        if (this.columnsWithcheckBoxDetails) {
            for (let i = 0; i < this.columnsWithcheckBoxDetails.length; i++) {
                if (column === this.columnsWithcheckBoxDetails[i].name) {
                    element[this.columnsWithcheckBoxDetails[i].checkValue] = !element[this.columnsWithcheckBoxDetails[i].checkValue];
                }
            }
        }
    };
    downloadFile = (element) => {
        this.onDownloadToggle.emit(element);
    };

    copyCheckSum = (element) => {
        this.onCheckSumToggle.emit(element);
    };

    copyElementId = (element) => {
        this.copyRequestId.emit(element);
    };

    // global search on the filter
    searchLocation = (value) => {
        this.dataSource.filter = value.trim().toLocaleLowerCase();
        if (value.length > 0 && this.dataSource.filteredData.length < 1) {
            this.noRecordsFound = true;
        } else {
            this.noRecordsFound = false;
        }
    };

    // updating the table data on new changes.
    updateDatasource = (data?) => {
        this.dataSource.filter = '';
        this.dataSource.data = Object.assign([], data);
        this.buildSelectionModal();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.pushSpecialColumns();
    };

    // TO CHECK IF THE COLUMN HAS INPUT
    private isThisInput = (column) => {
        const found =
            this.inputBoxColumns && this.inputBoxColumns.length > 0 && this.inputBoxColumns.find((inputColumn) => column === inputColumn);
        return found;
    };
    // TO CHECK IF THE COLUMN HAS CHECKBOX
    private isColumnsWithcheckBox = (column) => {
        if (this.columnsWithcheckBox && this.columnsWithcheckBox.length > 0) {
            for (let i = 0; i < this.columnsWithcheckBox.length; i++) {
                if (column === this.columnsWithcheckBox[i]) {
                    return true;
                }
            }
        }
        return false;
    };

    // To check if the checkbox in the column can be rendered or not
    canRenderCheckbox = (element, column) => {
        if (this.columnsWithcheckBoxDetails) {
            for (let i = 0; i < this.columnsWithcheckBoxDetails.length; i++) {
                if (column === this.columnsWithcheckBoxDetails[i].name) {
                    if (this.columnsWithcheckBoxDetails[i]['displayBasedOn'] !== undefined) {
                        return element[this.columnsWithcheckBoxDetails[i]['displayBasedOn']];
                    } else {
                        return true;
                    }
                }
            }
        } else {
            return true;
        }
    };

    private isDataCapturePresent = (column, element?) => {
        if (this.workflow) {
            if (column === 'entity' && !element) {
                return true;
            } else if (column === 'entity' && element) {
                if (element.category === 'DATA_CAPTURE') {
                    return true;
                }
            }
        }
        return false;
    };

    private isReportsPresent = (column, element?) => {
        if (this.workflow) {
            if (column === 'report' && !element) {
                return true;
            } else if (column === 'report' && element) {
                if (element.category === 'REPORTS_GENERATION') {
                    return true;
                }
            }
        }
        return false;
    };

    private isThisPassword = (column) => {
        if (this.passwordColumns && column) {
            return this.passwordColumns.indexOf(column) >= 0;
        }
        return false;
    };

    addEntity = (element) => {
        element['modalType'] = 'entity';
        this.R.addEntity.next(element);
    };
    addReport = (element) => {
        element['modalType'] = 'report';
        this.R.addReport.next(element);
    };

    checkForDisableValue = (element, column) => {
        if (this.columnsWithcheckBoxDetails) {
            for (let i = 0; i < this.columnsWithcheckBoxDetails.length; i++) {
                if (column === this.columnsWithcheckBoxDetails[i].name) {
                    if (element.skip[this.columnsWithcheckBoxDetails[i].disableValue] !== undefined) {
                        return !element.skip[this.columnsWithcheckBoxDetails[i].disableValue];
                    }
                }
            }
        } else {
            return false;
        }
    };

    checkFiltersSelected = () => {
        const leftOutColumns = this.allColumns
            .filter((column) => (this.hideTableColumns ? this.hideTableColumns.indexOf(column) === -1 : true))
            .filter((column) => this.clonedColumns && this.clonedColumns.indexOf(column) === -1);
        return leftOutColumns && leftOutColumns.length === 0;
    };

    // Method to get data from searchInput and emit
    searchInputModelChange = (column) => {
        this.enteredSearchValue = this.searchColumnControl.value ? this.searchColumnControl.value : '';
        this.selectedSearchColumn = column;
        const data = {
            columnName: column,
            value: this.enteredSearchValue,
        };
        this.onSearchEvent.emit(data);
        if (!this.enteredSearchValue) {
            this.searchColumnControl = new UntypedFormControl();
            this.enteredSearchValue = '';
            this.selectedSearchColumn = '';
        }
    };

    clearSearchOrg = (column) => {
        this.searchColumnControl = new UntypedFormControl();
        this.searchInputModelChange(column);
    };

    setTransformProperty = () => {
        const element = document.querySelector('.subsctiptionSelectPanel')?.parentElement?.parentElement as HTMLElement;
        if (element) {
            element.style.transform = 'translateX(-40px) translateY(-13px)';
        }
    };

    organizationSelected = (org, event, type?) => {
        let orgArray = [];
        let appArray = [];
        if (org && org.id) {
            if (type === 'organization') {
                const duplicateOrg = this.selectedOrgsArray.find((obj) => obj === org.id);
                if (duplicateOrg && duplicateOrg.length > 0) {
                    this.selectedOrgsArray.filter((obj, index) => {
                        if (obj === org.id) {
                            this.selectedOrgsArray.splice(index, 1);
                        }
                    });
                } else {
                    this.selectedOrgsArray.push(org.id);
                }
            } else if (type === 'asset') {
                const duplicateOrg = this.selectedAssetsArray.find((obj) => obj === org.id);
                if (duplicateOrg && duplicateOrg.length > 0) {
                    this.selectedAssetsArray.filter((obj, index) => {
                        if (obj === org.id) {
                            this.selectedAssetsArray.splice(index, 1);
                        }
                    });
                } else {
                    this.selectedAssetsArray.push(org.id);
                }
            }
        }
        if (this.selectedOrgs && this.selectedOrgs.value && this.selectedOrgs.value.length !== this.selectedOrgsArray.length) {
            this.selectedOrgs.setValue(this.selectedOrgsArray);
        }
        if (this.selectedAsset && this.selectedAsset.value && this.selectedAsset.value.length !== this.selectedAssetsArray.length) {
            this.selectedAsset.setValue(this.selectedAssetsArray);
        }
        if (this.selectedOrgs && this.selectedOrgs.value && Array.isArray(this.selectedOrgs.value)) {
            orgArray = this.selectedOrgs.value.filter((org) => {
                return org && org.length;
            });
        }
        if (this.selectedAsset && this.selectedAsset.value && Array.isArray(this.selectedAsset.value)) {
            appArray = this.selectedAsset.value.filter((app) => {
                return app && app.length;
            });
        }
        const data = {
            orgIds: orgArray,
            itemIds: appArray,
        };
        this.selectionEventEmitter.emit(data);
        this.setSelectedCountToDefault();
    };

    setSelectedCountToDefault = () => {
        this.selectedRecordsCount = 0;
    };

    isSelectOrgPresent = () => {
        let orgArray = [];
        if (this.selectedOrgs && this.selectedOrgs.value && Array.isArray(this.selectedOrgs.value)) {
            orgArray = this.selectedOrgs.value.filter((org) => {
                return org && org.length;
            });
        }
        if (orgArray.length > 0) {
            return true;
        }
        return false;
    };

    isSelectOrgAssetPresent = () => {
        let appArray = [];
        if (this.selectedAsset && this.selectedAsset.value && Array.isArray(this.selectedAsset.value)) {
            appArray = this.selectedAsset.value.filter((app) => {
                return app && app.length;
            });
        }
        if (appArray.length > 0) {
            return true;
        }
        return false;
    };

    updateModel = (column, text) => {
        if (this.selectedSearchColumn === column) {
            return text;
        }
    };

    deselectAllOrg = () => {
        this.subscribeOrg.writeValue(null);
        this.selectedOrgsArray = [];
        this.selectedOrgs.reset();
        const data = {
            orgIds: [],
            itemIds: [],
        };
        this.selectionEventEmitter.emit(data);
    };
    deselectAllOrgAssets = () => {
        this.subscribeAsset.writeValue(null);
        this.selectedAssetsArray = [];
        this.selectedAsset.reset();
        const data = {
            orgIds: [],
            itemIds: [],
        };
        this.selectionEventEmitter.emit(data);
    };

    parsedText = (text) => {
        const dom = new DOMParser().parseFromString('<!doctype html><body>' + text, 'text/html');
        const decodedString = dom.body.textContent;
        return decodedString;
    };

    trackByFn(index, item): number {
        return index;
    }

    getPreviousRecords = () => {
        this.fetchItemsInPage.emit('previous');
        this.setScrollToTop();
    };

    getNextRecords = () => {
        this.fetchItemsInPage.emit('next');
        this.setScrollToTop();
    };

    setScrollToTop = () => {
        this.scrollRef.directiveRef.scrollToTop();
        this.setSelectedCountToDefault();
    };

    addSpaceToTitle = (data) => {
        let titleText;
        const title = this._utils.stripHtml(data);
        if (Array.isArray(title)) {
            // some times we are getting array in title
            titleText = title.join(' ');
            return titleText;
        } else {
            if (title?.toString().indexOf(' ') === -1 && title?.length > 40) {
                titleText = title.match(/.{1,40}/g).join(' ');
                return titleText;
            } else {
                return title;
            }
        }
    };

    allOrgTrackBy = (item, index) => index;

    trackTableRecordData = (index, data) => {
        return data.recordId;
    };

    ngOnInit() {
        if (this.noAction === undefined) {
            this.noAction = false;
        }
        if (this.noLog === undefined) {
            this.noLog = false;
        }
        if (this.dragEnabled === undefined) {
            this.dragEnabled = false;
        }
        this.pushSpecialColumns();

        this.selectedColumns &&
            this.selectedColumns.indexOf('log') === -1 &&
            this.checkColumnsCount() &&
            !this.noLog &&
            this.selectedColumns.push('log');
        this.clonedColumns = this.selectedColumns.slice(0);
        // this subject should be used to have the access to search.
        this.searchSubscription = this.R.searchLocation.subscribe((data) => {
            this.searchLocation(data);
        });
        this.R.selectedColumnsOftable = this.selectedColumns;
        this.selectAll = this.checkFiltersSelected();
    }

    private showTableData = () => {
        if (this.fromIntegrationsSetting) {
            if ((this.dataSource && this.dataSource.data.length !== 0) || this.emptyTableData) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    showLoaderData = () => {
        if (this.fromIntegrationsSetting) {
            if (this.dataSource && this.dataSource.data.length === 0 && !this.emptyTableData) {
                return true;
            }
        }
        return false;
    };

    checkRecordActions = (action, element) => {
        if (!action.conditionalDisplay) {
            return true;
        } else {
            if (action.byFieldValue && action.conditionType === 'NOT_EQUALS') {
                let count = 0;
                action.conditionalFields.forEach((field) => {
                    if (element[field.fieldId] === field.value) {
                        count = count + 1;
                    }
                });
                if (count) {
                    return false;
                } else {
                    return true;
                }
            }
            if (action.byFieldValue && action.conditionType === 'EQUALS') {
                let count = 0;
                action.conditionalFields.forEach((field) => {
                    if (element[field.fieldId] !== field.value) {
                        count = count + 1;
                    }
                });
                if (count) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    };

    searchColumn = (column) => {
        const convertedColumn = this.getCamelWord(column);
        if (convertedColumn.toLowerCase().indexOf(this.searchColumns.toLowerCase()) > -1) {
            return true;
        } else {
            return false;
        }
    };

    getCamelWord = (column) => {
        let camelCaseString = column.replace(/([a-z])([A-Z])/g, '$1 $2');
        camelCaseString = camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1);
        return camelCaseString;
    };

    setPaginationLength = () => {
        if (!this.defaultMatPaginator) {
            this.dataSource.paginator = this.defaultMatPaginator;
            this.defaultPaginatorSet = true;
        }
    };

    dropRow(event: CdkDragDrop<any[]>) {
        if (event?.previousIndex !== event?.currentIndex) {
            moveItemInArray(this.dataSource?.data, event?.previousIndex, event?.currentIndex);
            this.onReorderRows.emit(this.dataSource?.data);
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        if (this.defaultPaginator) {
            this.setPaginationLength();
        }
        this.showingLoadingData = this.showLoaderData();
        this.paginator?._intl && (this.paginator._intl.itemsPerPageLabel = translate('Items per page'));
        this.paginator?._intl && (this.paginator._intl.nextPageLabel = translate('Next Page'));
        this.paginator?._intl && (this.paginator._intl.lastPageLabel = translate('Last Page'));
        this.paginator?._intl && (this.paginator._intl.firstPageLabel = translate('First Page'));
        this.paginator?._intl && (this.paginator._intl.previousPageLabel = translate('Previous Page'));
    }

    ngOnDestroy() {
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.newOrgAdded && this.newOrgAdded.unsubscribe();
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
