<ng-container *transloco="let translate;">
    <div class="collaborationContainer" *ngIf="collaborationAddList">
        <div class="row">
            <div class="col-6">
                <material-select [options]="partnerFunctions" [noSelectIfValidOptionSelected]="true" [placeholder]="translate('Select Partner Function')" [model]="partnerFunction" (optionData)="partnerFunction = $event; onPartnerFunctionChange($event);" [hideSelectIfValueExists]="true">
                </material-select>
            </div>
            <div class="col-6" *ngIf="partnerFunction">
                <material-select [options]="collaborationModes" [noSelectIfValidOptionSelected]="true" [placeholder]="translate('Select Mode')" [model]="collaborationMode" (optionData)="collaborationMode = $event; onCollaborationModeChange($event);" [optionDisplayNameProperty]='"name"' [optionValueProperty]='"id"' [hideSelectIfValueExists]="true">
                </material-select>
            </div>

        </div>
        <div class="row">
            <div class="col-6" *ngIf="collaborationMode === 1">
                <material-textarea class="workflowTextarea" [placeholder]="translate('Leave a comment')" [model]="comments" (modelChange)="comments = $event" [name]="'Comments'"></material-textarea>
            </div>
            <ng-container *ngIf="collaborationMode === 2">
                <div class="col-6">
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let email of toEmails" (removed)="removeMails(email)">
                                {{email}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input type="text" [placeholder]="translate('Enter email ids to invite')" [matChipInputFor]="chipList" focused (matChipInputTokenEnd)="addMails($event)" [title]="translate('Type and hit Enter key or Comma to add multiple values')" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" [required]="required">
                        </mat-chip-list>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <material-textarea class="workflowTextarea" [placeholder]="translate('Leave a comment')" [model]="comments" (modelChange)="comments = $event" [name]="'Comments'"></material-textarea>
                </div>
            </ng-container>
            <ng-container *ngIf="collaborationMode === 3">
                <div class="col-6">
                    <material-select [options]="partnerOrganizations" [noSelectIfValidOptionSelected]="true" [placeholder]="translate('Select Partner')" [model]="partnerId" (modelChange)="onPartnerOrgChange($event);" [optionDisplayNameProperty]='"displayTenantName"' [optionValueProperty]='"id"' [hideSelectIfValueExists]="true">
                    </material-select>
                </div>
                <div class="col-6">
                    <material-textarea class="workflowTextarea" [placeholder]="translate('Leave a comment')" [model]="comments" (modelChange)="comments = $event" [name]="'Comments'"></material-textarea>
                </div>
            </ng-container>
        </div>
        <div class="d-flex justify-content-center my-3">
            <material-button class="mx-3" [label]="translate('Cancel')" [color]="'default'" (onTrigger)="CancelCollaboratorForm()"></material-button>
            <material-button [disabled]="!partnerFunction || !collaborationMode || !comments || (collaborationMode === 2 && !toEmails.length) || (collaborationMode === 3 && !partnerId)" [label]="translate('Add')" [color]="'primary'" (onTrigger)="sharePartnerRequest()"></material-button>
        </div>
    </div>

    <div class="recordDataMainContainer collaboratorsListDiv" *ngIf="collaborationPartnerList">
        <div class="collaborationList">
            <ng-container *ngIf="listOfCollaborators?.length; else noCollaboratorsList">
                <div class="collaborator" *ngFor="let collaborator of listOfCollaborators">
                    <div class="row mb-2 d-flex justify-content-center">
                        <div class="col-4">
                            <mat-icon class="material-icons" *ngIf="collaborator.collaboratorType === 'USER'">person</mat-icon>
                            <mat-icon class="material-icons" *ngIf="collaborator.collaboratorType === 'ORGANIZATION'">location_city</mat-icon>
                            <span class="readOnlyValue collaboratorPartner">{{collaborator.partnerName}}</span>
                        </div>
                        <div class="col-4">
                            <span class="readOnlyLabel">{{translate('Partner Function')}}:</span>
                            <span class="readOnlyValue">{{collaborator.partnerFunction}}</span>
                        </div>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>

    <ng-template #noCollaboratorsList>
        <div class="noMasterRecordContainer">
            <div class="noMasterRecordDiv d-flex justify-content-center align-items-center">
                <span>{{translate('No collaborators available')}}</span>
            </div>
        </div>
    </ng-template>
</ng-container>
