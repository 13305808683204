import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'material-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class MaterialAccordionComponent implements OnInit {
    @Input() showFooter: boolean;
    @Input() expandAccordion?: boolean;
    @Input() showContentOnlyOnExpand?: boolean;
    @Input() disabled?: boolean;
    @Input() hideToggle?: boolean;

    @Output() openAccrodion = new EventEmitter();

    showAccordionBody = true;

    constructor() {}

    openMatAccordion = (event) => {
        this.openAccrodion.emit(event);
    };

    ngOnInit() {
        if (this.showContentOnlyOnExpand === true) {
            this.showAccordionBody = false;
        }
    }
}
