import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { environment } from '@env';
import { translate, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    FetchCaptcha,
    fetchCaptchaSuccessState,
    GetApplicationProperties,
    getApplicationProperties$,
    GetProvider,
    GetProviderCallback,
    GetProviderOrganizations,
    GetProviderOrganizationsSuccess,
    Organization,
    User,
    UserRegistration,
    UserRegistrationFailed,
    UserRegistrationSuccess,
    UtilsService,
} from 'taxilla-library';

import { EulaComponent } from '../eula/eula.component';
import { PartnerEulaComponent } from '../partnereula/partnereula.component';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class SignupComponent implements OnInit, OnDestroy {
    organization: { [property: string]: string | boolean } = {};
    errors: { [property: string]: string[] } = {};
    user: { [property: string]: string | boolean } = {};
    captchaUrl: string;
    captcha: string;
    termsValidationMsg: boolean;
    partnerTermsValidationMsg: boolean;
    organizationTypes = [
        {
            value: 'Partner',
            displayName: 'Partner',
        },
        {
            value: 'ksa-wrapper',
            displayName: 'Ksa-wrapper',
        },
    ];
    providerName: string | number | boolean;
    providerId: string | number | boolean;
    providerOrganizations: any = [];
    defaultProviderId: string | number | boolean;
    loadComponent: boolean;
    invitationId: any;
    unsubscribe = new Subject<void>();

    constructor(
        private _router: Router,
        private _utils: Utils,
        private _libUtils: UtilsService,
        public R: RootScope,
        private dialog: MatDialog,
        private _route: ActivatedRoute,
        private _store: Store,
        private _actions: Actions
    ) {}

    startComponent = () => {
        const queryParams = this._route.queryParams['value'];
        this.invitationId = queryParams?.['invitationId'];
        this.organization = {};
        if (environment.specialUI === 'KSA') {
            this.organization.organizationType = 'ksa-wrapper';
        }
        this.user = {};
        this.user.email = queryParams?.['email'];
        this.captcha = undefined;
        this.getCaptcha();
    };

    protected getApplicationProperties = () => {
        this._store.dispatch(GetApplicationProperties());
    };

    protected getProvider = () => {
        this._store.dispatch(GetProvider());
    };

    getCaptcha = () => {
        this.captcha = undefined;
        const payload = {
            plain: true,
        };
        this._store.dispatch(FetchCaptcha({ payload }));
    };

    canHidePartnerTerms = () => {
        return (
            !this.organization.organizationType ||
            (this.organization.organizationType && (this.organization.organizationType as string).toLowerCase().indexOf('partner') === -1)
        );
    };

    signup = () => {
        this.errors = {};
        this.termsValidationMsg = false;
        if (this.checkSignupForm()) {
            let organization: any = {};
            let user: any = {};
            if (this.invitationId) {
                organization = {};
                user = {};
            } else {
                organization = new Organization({
                    providerOrganizationId: this.organization.providerOrganizationId || this.providerId || this.defaultProviderId || '111',
                });
                user = new User({});
            }
            organization.name = this.organization.name as string;
            organization.nickName = this.organization.orgBusiness as string;
            organization.type = this.organization.organizationType as string;
            organization.orgLoginId = this._utils.getOrgLoginId(this.organization.name as string);
            user.email = this.user.email as string;
            user.userId = this.user.userId as string;
            user.firstName = this.user.firstName as string;
            user.lastName = this.user.lastName as string;
            user.designation = this.user.designation as string;
            this._store.dispatch(
                UserRegistration({
                    payload: {
                        captcha: this.captcha,
                        invitationId: this.invitationId,
                        org: organization,
                        user,
                    },
                })
            );
        } else {
            this._libUtils.alertError(translate('Enter valid details to proceed'));
        }
    };

    checkSignupForm = () => {
        let count = 0;

        this.checkOrganizationName();

        this.checkOrganizationBusiness();

        this.checkUserId();

        this.checkFirstName();

        this.checkLastName();

        this.checkUserEmail();

        this.checkDesignation();

        !this.invitationId && this.checkCaptcha();
        !this.invitationId && this.checkProviderOrganizations();

        for (const key in this.errors) {
            if (this.errors[key] && this.errors[key].length > 0) {
                count++;
            }
        }

        if (this.user.accepted === false || this.user.accepted === undefined) {
            this.termsValidationMsg = true;
            count++;
        }

        if (!this.canHidePartnerTerms() && (this.organization.partnerTerms === false || this.organization.partnerTerms === undefined)) {
            this.partnerTermsValidationMsg = true;
            count++;
        }

        return count === 0;
    };

    showTermsAndConditions = () => {
        const dialogRef = this.dialog.open(EulaComponent, {
            panelClass: ['eula', 'matDialogContainer'],
            disableClose: true,
        });
        dialogRef.componentInstance.acceptEula = () => {
            this.user.accepted = true;
            this.termsValidationMsg = false;
        };
    };

    showPartnerTermsAndConditions = () => {
        const dialogRef = this.dialog.open(PartnerEulaComponent, {
            panelClass: ['eula', 'matDialogContainer'],
            disableClose: true,
        });
        dialogRef.componentInstance.acceptEula = () => {
            this.organization.partnerTerms = true;
            this.partnerTermsValidationMsg = false;
        };
    };

    checkUserId = () => {
        this.errors.userId = [];
        if (!this.user.userId || (this.user.userId as string).trim() === '') {
            this.errors.userId = [translate('Enter user id')];
        } else if (!this._utils.checkUserId(this.user.userId)) {
            this.errors.userId = [translate('Enter alphabets, digits, dot(.), underscore(_), hyphen(-) and at(@) only')];
        } else if ((this.user.userId as string).length <= 5) {
            this.errors.userId = [translate('Min length allowed is 6')];
        } else if (this.user.userId && (this.user.userId as string).length > 100) {
            this.errors.userId = [translate('Max length allowed is 100')];
        }
    };

    checkOrganizationName = () => {
        this.errors.organizationName = [];
        if (this.organization.name === undefined) {
            this.organization.name = '';
        }
        this.organization.name = (this.organization.name as string).trim();
        if (!this.organization.name || (this.organization.name as string).trim() === '') {
            this.errors.organizationName = [translate('Enter organization name')];
        } else if (this.organization.name && (this.organization.name as string).length > 100) {
            this.errors.organizationName = [translate('Maximum number of characters allowed is 100')];
        } else if (this._utils.invalidChars(this.organization.name)) {
            this.errors.organizationName = [translate('Enter a valid organization name')];
        } else if (!this._utils.acceptOrgName(this.organization.name)) {
            this.errors.organizationName = [translate('Organization name must be alphabets numbers and spaces')];
        }
    };

    checkOrganizationCode = () => {
        this.errors.organizationCode = [];
        if (!this.organization.organizationCode || (this.organization.organizationCode as string).trim() === '') {
            this.errors.organizationCode = [translate('Enter organization code')];
        } else if (this._utils.invalidChars(this.organization.organizationCode)) {
            this.errors.organizationCode = [translate('Enter a valid organization code')];
        }
    };

    checkOrganizationBusiness = () => {
        this.errors.orgBusiness = [];
        if (this.organization.orgBusiness && (this.organization.orgBusiness as string).length > 0) {
            if (!this.organization.orgBusiness || (this.organization.orgBusiness as string).trim() === '') {
                this.errors.orgBusiness = [translate('Enter organization business')];
            } else if (this._utils.invalidChars(this.organization.orgBusiness)) {
                this.errors.orgBusiness = [translate('Enter a valid organization business')];
            } else if (this.organization.orgBusiness && (this.organization.orgBusiness as string).length > 100) {
                this.errors.orgBusiness = [translate('Maximum number of characters allowed is 100')];
            }
        }
    };

    checkFirstName = () => {
        this.errors.firstName = [];
        if (!this.user.firstName || (this.user.firstName as string).trim() === '') {
            this.errors.firstName = [translate('Enter user first name')];
        } else if (!this._utils.acceptAlphabets(this.user.firstName)) {
            this.errors.firstName = [translate('Enter alphabets and spaces only')];
        } else if (this._utils.invalidChars(this.user.firstName)) {
            this.errors.firstName = [translate('Enter alphabets and spaces only')];
        } else if (this.user.firstName['length'] > 50) {
            this.errors.firstName = [translate('Enter max 50 characters')];
        }
    };

    checkLastName = () => {
        this.errors.lastName = [];
        if (!this.user.lastName || (this.user.lastName as string).trim() === '') {
            this.errors.lastName = [translate('Enter user last name')];
        } else if (!this._utils.acceptAlphabets(this.user.lastName)) {
            this.errors.lastName = [translate('Enter alphabets and spaces only')];
        } else if (this._utils.invalidChars(this.user.lastName)) {
            this.errors.lastName = [translate('Enter alphabets and spaces only')];
        } else if (this.user.lastName['length'] > 50) {
            this.errors.lastName = [translate('Enter max 50 characters')];
        }
    };

    checkUserEmail = () => {
        this.errors.email = [];
        if (!this.user.email || (this.user.email as string).trim() === '') {
            this.errors.email = [translate('Enter user email')];
        } else if (this._utils.invalidChars(this.user.email)) {
            this.errors.email = [translate('Enter a valid user email')];
        } else if (!this._libUtils.acceptEmail(this.user.email)) {
            this.errors.email = [translate('Enter a valid user email')];
        }
    };

    checkDesignation = () => {
        this.errors.designation = [];
        if (this.user.designation && (this.user.designation as string).length > 0) {
            if (!this.user.designation || (this.user.designation as string).trim() === '') {
                this.errors.designation = [translate('Enter user designation')];
            } else if (this._utils.invalidChars(this.user.designation)) {
                this.errors.designation = [translate('Enter a valid user designation')];
            } else if (this.user.designation['length'] > 50) {
                this.errors.designation = [translate('Enter max 50 characters')];
            }
        }
    };

    checkCaptcha = () => {
        this.errors.captcha = [];
        if (!this.captcha || this.captcha.trim() === '') {
            this.errors.captcha = [translate('error_validCaptcha')];
        }
    };

    applyOrganizationTypes = () => {
        const specialUI = environment.specialUI;
        this.organizationTypes.splice(0);
        switch (specialUI) {
            case 'KSA':
                this.organizationTypes.push({
                    displayName: 'KSA',
                    value: 'ksa-wrapper',
                });
                break;
            default:
                this.organizationTypes.push({
                    value: 'Partner',
                    displayName: 'Partner',
                });
                break;
        }
    };

    getProviderOrganizations = () => {
        this._store.dispatch(GetProviderOrganizations());
    };

    checkProviderOrganizations = () => {
        this.errors.providerOrganizations = [];
        if (!this.organization.providerOrganizationId || this.organization.providerOrganizationId === '') {
            this.errors.providerOrganizations = [translate('Select provider organization')];
        }
    };

    inItStoreSubs = (): void => {
        this._store
            .select(getApplicationProperties$)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe({
                next: (res) => {
                    if (res['taxilla.version']) {
                        this.getProvider();
                    }
                    this.defaultProviderId = res?.['default.provider.id'];
                },
            });
        this._store
            .select(fetchCaptchaSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe({
                next: (res) => {
                    this.captchaUrl = res.captcha;
                    this.loadComponent = true;
                },
            });

        this._actions.pipe(ofType(GetProviderOrganizationsSuccess), takeUntil(this.unsubscribe)).subscribe((res) => {
            this.providerOrganizations = res.result;
        });
        this._actions.pipe(ofType(GetProviderCallback), takeUntil(this.unsubscribe)).subscribe((response) => {
            this.providerName = response?.result?.providerName;
            this.providerId = response?.result?.providerId;
        });

        this._actions.pipe(ofType(UserRegistrationSuccess), takeUntil(this.unsubscribe)).subscribe((res) => {
            this._libUtils.alertSuccess(translate('Organization created successfully. Please check your inbox for further instructions'));
            setTimeout(() => {
                this._router.navigate(['']);
            }, 2000);
        });
        this._actions.pipe(ofType(UserRegistrationFailed), takeUntil(this.unsubscribe)).subscribe((res: any) => {
            const response = res;
            if (response && response.response && Object.keys(response.response).length > 0) {
                const messages = [];
                for (const key in response.response) {
                    if (key !== 'user.userId' && key !== 'org.name') {
                        response.response[key].forEach((message) => messages.push(message));
                    }
                    if (key === 'org.name') {
                        response.response[key].forEach((message) => messages.push('Organization Name ' + message));
                    }
                }
                this._libUtils.alertError(messages && messages.toString());
            } else {
                this._libUtils.alertError((response && response.msg) || translate('Failed to register'));
            }
        });
    };

    ngOnInit(): void {
        if (environment.hideSignUpPage) {
            this._router.navigate(['']);
        }
        this.getApplicationProperties();
        this.getProviderOrganizations();
        this.applyOrganizationTypes();
        this.inItStoreSubs();
        this.startComponent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
