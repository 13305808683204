<div class="errorWallContainer" *transloco="let translate;">
    <div class="errorSymbol">
        <span class="material-icons-outlined gear1">
            settings
        </span>
        <span class="material-icons-outlined gear2">
            settings
        </span>
        <span class="material-icons-outlined gear3">
            settings
        </span>
        <div class="errorText">
            {{error?.length > 0 ? error + '.' : translate('Unexpected error') + ','}} <span class="errorRedirect" (click)="redirectToLogin()">{{translate('Try again')}}</span>.
        </div>
    </div>
</div>
