import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { getCurrentOrganization$, GetMasters, getMasters$, MasterTables, SetMasterTable } from 'taxilla-library';

@Component({
    selector: 'app-master-management',
    templateUrl: './master-management.component.html',
    styleUrls: ['./master-management.component.scss'],
})
export class MasterManagementComponent implements OnInit, OnDestroy {
    masterDetails = false;
    allMasterArray: MasterTables[] = [];
    noDisccription = translate('There is no description/information related to Master');
    searchMasterName = undefined;
    noMasterPresent = false;
    public currentOrganizationId: string;
    private unSubscribe = new Subject<void>();
    masterId: BehaviorSubject<string> = new BehaviorSubject(undefined);

    constructor(public R: RootScope, private _utils: Utils, private route: ActivatedRoute, private store$: Store) {}

    private getAllMasters = () => {
        this.store$.dispatch(GetMasters());
    };

    public navigatTo = (master) => {
        const path =
            '/organizations/' +
            this.currentOrganizationId +
            '/settings/masterManagement/' +
            master.itemId +
            '/' +
            master.restApiServiceName;
        this._utils.setRoute(path);
    };

    public searchMaster = () => {
        let matOptionsCount = 0;
        matOptionsCount = document.querySelectorAll('.masterContainerDiv').length;
        if (matOptionsCount === 0) {
            this.noMasterPresent = true;
        } else {
            this.noMasterPresent = false;
        }
        if (this.searchMasterName === '') {
            this.noMasterPresent = false;
            this.searchMasterName = undefined;
        }
    };

    private initiateSubscriptions = () => {
        this.store$
            .select(getCurrentOrganization$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((org) => org !== undefined)
            )
            .subscribe((org) => {
                this.currentOrganizationId = org.id;
                this.getAllMasters();
            });
        this.store$
            .select(getMasters$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((response) => {
                this.allMasterArray = response || [];
            });
    };

    ngOnInit() {
        this.initiateSubscriptions();
        this.route.params.pipe(takeUntil(this.unSubscribe)).subscribe((event) => {
            const masterId = event.masterName;
            if (masterId) {
                this.masterDetails = true;
                this.masterId.next(masterId);
                this.store$.dispatch(
                    SetMasterTable({
                        tableId: event.masterName,
                        restApiName: event.restApiName,
                    })
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }

    translateMsg = (msg: string): string => translate('' + msg);
}
