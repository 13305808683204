export class EntityBusinessKeys {
    fields: Array<string>;

    mergeRecordsEnabled: boolean;

    constructor(data) {
        this.fields = Array.isArray(data) ? data : data?.fields || [];
        this.mergeRecordsEnabled = data && data.mergeRecordsEnabled;
    }
}
