<div class="fieldAttachmentModal" *ngIf="field" appFileDragDetector>
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom" *transloco="let translate">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('Upload File')}}</h4>
        <span class="material-icons mb-1" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody">
        <div *transloco="let translate">
            <div class="fieldAttachmentRow" [hidden]="inReadOnlyMode">
                <span class="field-display-name">
                    {{field.displayName}} :
                </span>
                <span class="file-upload-item">
                    <fileUpload [data]="fieldAttachmentData" [clearFiles]="clearFiles" (newValue)="onNewValue($event)" (fileName)="fileName = $event" [supportedFormats]="field.attachment && field.attachment.supportedTypes" (isFileTypeSupported)="onFileTypeSupportCheck($event)" [isDisabled]="maxFileCountReached"></fileUpload>
                </span>
                <span class="upload-btn">
                    <material-button [disabled]="!files || files.length === 0 || !isFileTypeSupported || !isMaxFileSizeAllowed || maxFileCountReached" [label]="translate('Upload')" (onTrigger)="startUpload()"></material-button>
                </span>
            </div>
            <div [hidden]="inReadOnlyMode">
                <span>{{translate('Maximum allowed files count')}}:<strong>{{field.attachment && field.attachment.maxFilesCount}}</strong><span class="alreadyReached" *ngIf="maxFileCountReached"> ({{translate('Reached')}})</span></span>
            </div>
            <div class="fieldAttachedFileName row" [hidden]="inReadOnlyMode || !fileName">
                <span class="col-xs-5 fileNameLabel">
                    {{translate('File Name')}} :
                </span>
                <span class="col-xs-7 fileNameValue" title="{{fileName}}">
                    {{fileName}}
                </span>
            </div>
            <div class="fieldAttachedFileName row" [hidden]="!inReadOnlyMode">
                <span class="col-xs-5 fileNameLabel">
                    {{translate('Field Name')}} :
                </span>
                <span class="col-xs-7 fileNameValue" title="{{field.displayName}}">
                    {{field.displayName}}
                </span>
            </div>
            <div class="fileTypeSupport" *ngIf="!isFileTypeSupported">
                <span class="col-xs-12">{{translate('Please select')}} {{field.attachment && field.attachment.supportedTypes}} {{translate('file format to upload')}}</span>
            </div>
            <div class="fileTypeSupport maxFileSizeAllowed" *ngIf="!isMaxFileSizeAllowed">
                <span class="col-xs-12">{{translate('Please upload a file of size less than')}} {{field.attachment && field.attachment.maxSize}} {{translate('MB')}}</span>
            </div>
            <div class="uploadedFilesListContainer">
                <material-table [allTableColumns]="allColumns" [selectedColumns]="selectedColumns" [data]="tableData" [recordActions]="recordActions"></material-table>
            </div>
        </div>
    </mat-dialog-content>
</div>
