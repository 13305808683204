import { FilingAttributeField } from './filingattributefield.class';

export class FilingAttributeFieldPayload {
    dataType: string;

    label: string;

    fullId: string;

    keyId: string;

    outputFormat?: string;

    value: string;

    isPrecaptured: boolean;

    isBusinessKey: boolean;

    values?: [];

    constructor(field: FilingAttributeField) {
        this.dataType = field?.dataType;
        this.label = field?.name;
        this.fullId = field?.fullId;
        this.keyId = field && field.entityId + '^' + field.fullId;
        this.isBusinessKey = field?.isBusinessKey;
        this.value = field?.value;
        this.outputFormat = field?.outputFormat;
        this.isPrecaptured = true;
    }
}
