<app-table-virtual-scroll-viewport [style.height]="height || 'calc(100vh - 216px)'">
    <table aria-describedby="virtual table" mat-table [dataSource]="virtualDataSource" multiTemplateDataRows class="pAdjust mat-elevation-z2 matTableContainer" [trackBy]="trackRecord">
        <ng-container matColumnDef="{{columnId}}" *ngFor="let columnId of selectedColumns;">
            <ng-container [ngSwitch]="columnKeyPair[columnId]?.type">
                <ng-container *ngSwitchCase="'text'">
                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader" title="{{columnKeyPair[columnId]?.name}}">
                        {{columnKeyPair[columnId]?.name | pascalToCamel}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="matTableCell" [title]="element[columnId] || '--'" (click)="copyToInboundRequestId(element)">
                        <span [class.dataTypeTextAlign]="['INT','LONG','FLOAT','DOUBLE','DECIMAL'].includes(columnKeyPair[columnId]?.inputType)">{{element[columnId]}}</span>
                    </td>
                </ng-container>
                <ng-container *ngSwitchCase="'SELECTRECORD'">
                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                        <ng-container *ngIf="columnKeyPair[columnId].showCheckboxInHeader">
                            <mat-checkbox matBadge="{{(selectedRecordsCount > 0 && !columnKeyPair[columnId].hideCheckboxBadge) ? selectedRecordsCount : null}}" matBadgeOverlap="false" class="selectAllBtn" [(ngModel)]="selectAllRecords" [disabled]="hiddenRowCheckbox" (ngModelChange)='toggleRecordsSelection(selectAllRecords)'></mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="!columnKeyPair[columnId].showCheckboxInHeader">
                            {{columnKeyPair[columnId]?.name}}
                        </ng-container>
                    </th>
                    <td mat-cell *matCellDef="let element" class="matTableCell">
                        <mat-checkbox class="recordSelectCheckboxContainer" [disabled]="hiddenRowCheckbox" [(ngModel)]="element.selected" (ngModelChange)="onRecordSelectionChange(element)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">

                </ng-container>
                <ng-container *ngSwitchCase="'group-select'">

                </ng-container>
                <ng-container *ngSwitchCase="'ACCORDIAN'">
                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                        {{columnKeyPair[columnId]?.name}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="matTableCell">
                        <div class="d-flex" *ngIf="element['accordian'] === false && element['fileName']">
                            <span class="accordian_body_text" (click)="onElementClick(element, element[columnId] )" title="{{element[columnId]}}">{{element[columnId]}}</span>
                        </div>
                        <mat-accordion class="tableColumnAccordian" *ngIf="element['accordian'] === true">
                            <mat-expansion-panel hideToggle #source>
                                <mat-expansion-panel-header>
                                    <div class="col-md-9 p-0">
                                        <mat-panel-title> <span class="accordian_body_text" (click)="$event.stopPropagation();onElementClick(element, element[columnId][0])">{{element[columnId][0]}}</span>
                                        </mat-panel-title>
                                    </div>
                                    <mat-icon class="title_icon" *ngIf="!source.expanded">add_circle_outline</mat-icon>
                                    <mat-icon class="title_icon" *ngIf="source.expanded">remove_circle_outline</mat-icon>
                                </mat-expansion-panel-header>
                                <div class="d-flex" *ngFor="let accordian of element[columnId] | slice : 1" class="accordian_body">
                                    <span class="accordian_body_text" (click)="onElementClick(element, accordian )">{{accordian}}</span>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </td>
                </ng-container>

                <ng-container *ngSwitchCase="'file'">
                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">{{columnKeyPair[columnId]?.name}}</th>
                    <td mat-cell *matCellDef="let element" class="matTableCell" (click)="$event.stopPropagation()">
                        <span class="tableFilesUploadContainer">
                            <fileUpload [data]="attachmentData" [clearFiles]="clearFiles" (newValue)="fileChanged($event, element)" (fileName)="changedFileName($event, element)"></fileUpload>
                        </span>
                        <span class="tableFilesName" [title]="element.fileName || '--'" title="{{element.fileName}}">{{element.fileName}}</span>
                    </td>
                </ng-container>
                <ng-container *ngSwitchCase="'link'">
                    <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">{{columnKeyPair[columnId]?.name}}</th>
                    <td mat-cell *matCellDef="let element" class="matTableCell" (click)="$event.stopPropagation()">
                        <ng-container [ngSwitch]="element[columnId] && (element[columnId].indexOf('http://') > (-1) || element[columnId].indexOf('https://') > (-1))">
                            <ng-container *ngSwitchCase="true">
                                <span class="downloadLink" *transloco="let translate">
                                    <a [href]="element[columnId]" target="_blank" rel="noopener">{{translate('Download')}}</a>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <span class="downloadLink" (click)="downloadSourceTemplateUrl(element)" *transloco="let translate">{{translate('Download')}}</span>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-container [ngSwitch]="columnTypePair[columnId]">
                        <ng-container *ngSwitchCase="'ALL_COLUMNS_LIST'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader logMenuIcon">
                                <span class="material-icons logIcon" [matMenuTriggerFor]="logMenu">menu</span>
                                <mat-menu #logMenu="matMenu" [class]="'tableLogMenu'" xPosition="after" yPosition="below">
                                    <div class="logMenu" stop-propagation *transloco="let translate;">
                                        <div class="logFilter" stop-propagation>
                                            <mat-form-field>
                                                <input matInput class="filterInput" autocomplete="off" placeholder="{{translate('Filter Table Columns')}}" [(ngModel)]="searchColumns" />
                                                <button mat-button *ngIf="searchColumns" matSuffix mat-icon-button aria-label="Clear" (click)="searchColumns = ''">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-around align-items-center checkStyle" stop-propagation>
                                                <mat-checkbox class="selectAllBtn" [(ngModel)]="selectAll" (ngModelChange)='selectAllFilters(selectAll)' id="selectAllLabel">{{translate('Select All')}}
                                                </mat-checkbox>
                                            </div>
                                            <material-button [disabled]="clonedColumns.length === 0" [label]="translate('Apply')" (onTrigger)="cloneSubmit()">
                                            </material-button>
                                        </div>
                                        <div class="logListItems" *ngFor="let column of allColumns; let i = index; trackBy: trackByAllColumnsMethod" stop-propagation [hidden]="!((!searchColumns || searchColumns.length === 0 || (searchColumns.length > 0 && column.name.toLowerCase().indexOf(searchColumns.toLowerCase()) > (-1))) && actionColumns.indexOf(column.id) === (-1) && !(hideTableColumns && hideTableColumns.indexOf(column.id) > (-1))) || column.hideInAllColumnsList">
                                            <div class="d-flex align-items-center tempList">
                                                <div class="actionCheckBox mCheckbox">
                                                    <mat-checkbox [hidden]="column.type === 'SELECTRECORD' || column.name === 'dynamicActions'" [checked]="clonedColumns.indexOf(column.id) > (-1)" (change)="checkboxSelect($event.checked, column)">
                                                        {{column.name}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-menu>
                                <ng-container *ngIf='columnSearchRequired'>
                                    <div class="searchHiddenDiv">

                                    </div>
                                </ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell"> </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ACTIONS'">
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader alignCenter">
                                <div *transloco="let translate;">
                                    {{translate('Actions')}}
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell alignCenter">
                                <div class="actionsMenu tableActionsMenu">
                                    <ng-container *ngFor="let action of recordActions; trackBy: trackByActionsMethod">
                                        <span [hidden]='!(actionVisibility[element.recordId][action.displayName])'>
                                            <ng-container *ngIf="(!action.conditionalDisplay || (action.byFieldValue && ((action.conditionType === 'NOT_EQUALS' && element[action.conditionalFieldId] !== action.conditionalFieldValue) && !(action.isArray && action.conditionalFieldValue.indexOf(element[action.conditionalFieldId]) > (-1)) || (action.conditionType === 'EQUALS' && element[action.conditionalFieldId] === action.conditionalFieldValue))))">
                                                <button mat-icon-button class="actionButtons" (click)="action.onActionTrigger && action.onActionTrigger(element, action)" title="{{action.displayName}}">
                                                    <span class="material-icons matTableActionIcon {{action.iconClass}}" [ngClass]="{'displayActionColor': action.iconName === 'warning'}" [hidden]="action.iconName === undefined">
                                                        {{action.iconName}}
                                                    </span>
                                                </button>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="!recordActions || recordActions.length === 0">
                                    <div *transloco="let translate;">
                                        <button mat-icon-button>{{translate('No Actions Found')}}</button>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                                {{columnKeyPair[columnId]?.name}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="matTableCell">{{element[columnId]}}</td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td class="expandedTableRow" mat-cell *matCellDef="let element" [attr.colspan]="selectedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="loading">
            <td mat-footer-cell class="matTableMessages tdFull" *matFooterCellDef colspan="100%">
                <div class="loaderBlock isLoading"> </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="noData">
            <div *transloco="let translate;">
                <td mat-footer-cell class="matTableMessages" *matFooterCellDef colspan="100%">
                    {{noRecordMsg || translate('No records Found')}}
                </td>
            </div>
        </ng-container>
        <ng-container matColumnDef="noRecords">
            <div *transloco="let translate;">
                <td mat-footer-cell class="matTableMessages noRecordMessage" *matFooterCellDef colspan="100%">{{translate('No records found')}}</td>
            </div>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="selectedColumns; sticky: true" [ngClass]="{'tableHeaderPadding': columnSearchRequired}"></tr>
        <tr mat-footer-row *matFooterRowDef='["loading"]' [ngClass]="{'hide': (dataSource !== null && !loading)}" [class.loadingTable]="loading"></tr>
        <tr mat-footer-row *matFooterRowDef='["noData"]' [ngClass]="{'hide': !(dataSource !== null && dataSource.data.length === 0 && !loading)}"></tr>
        <tr mat-footer-row *matFooterRowDef='["noRecords"]' [ngClass]="{'hide': !noRecordsFound}"></tr>
        <ng-template let-element matRowDef cdkVirtualFor [matRowDefColumns]="selectedColumns" [cdkVirtualForOf]="data.value">
            <tr mat-row class="example-element-row" [class.example-expanded-row]="expandedElement === element" [class.disabled]="element.disabled" [class.matTableError]="element.errorsCount && element.errorsCount > 0" [class.matTableWarning]="element.warningsCount && element.warningsCount > 0" [class.badScore]="element.score !== undefined && element.score !== 100" (click)="onSingleClick(element)" (dblclick)="onDoubleClick(element)"></tr>
        </ng-template>
    </table>
</app-table-virtual-scroll-viewport>
<ng-container *ngIf="hidePaginator !== true && pagination?.value?.count > 0">
    <material-paginator [paginationSubject]="pagination" (fetchItemsInPage)="fetchItemsInPage.emit($event)" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize.emit($event)">
    </material-paginator>
</ng-container>
