import { User } from './user.class';

export class RoleUser {
    roles? = [];

    roleIds?: any[];

    user?: User;

    constructor(roleUserObject) {
        this.roleIds = [];
        if (roleUserObject) {
            this.user = new User(roleUserObject.user || {});
            if (roleUserObject.roles) {
                this.roles = roleUserObject.roles;
                for (let i = 0; i < roleUserObject.roles.length; i++) {
                    if (roleUserObject.roles[i].selected) {
                        this.roleIds.push(roleUserObject.roles[i].id);
                    }
                }
            }
        }
    }
}
