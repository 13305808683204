import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'encomply-material-input-autocomplete',
    templateUrl: './encomply-inputautocomplete.component.html',
    styleUrls: ['./encomply-inputautocomplete.component.css'],
})
export class EnComplyMaterialInputAutoCompleteComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() placeholder;
    @Input() name;
    @Input() title;
    @Input() model;
    @Input() id;
    @Input() classList;
    @Input() debounceAfter: number;
    @Input() options: any[];
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() lookupHint: string;
    @Input() hidePaginator: boolean;
    @Input() tableData;
    @Input() columns;
    @Input() dataPresentation: string;
    @Input() disabled: boolean;
    @Input() panelWidth: string | number;
    @Input() maxLength: number;
    @Input() allColumns;
    @Input() totalOptions: BehaviorSubject<any[]>;
    @Input() showClearText: boolean;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() selectedLookupRecord = new EventEmitter();

    panelOpen: boolean;
    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;
    MastersService: any;
    masterMetaData: any;
    noRecordsText: string;
    noRecordsIcon: string;
    optionsSubscription: Subscription;
    filteredOptions = [];

    constructor() {}

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({
                errors: this.errors,
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.model) {
            this.control.setValue(this.model);
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    modelChanged = (event) => {
        this.model = event;
        this.modelChange.next(this.model);
    };

    updateModelAndEmitDebounceEvent = (event) => {
        this.modelChange.emit(this.model);
        this.debounceEventHandler.emit(event);
    };

    selectedOption = (event) => {
        if (this.options.length) {
            this.debounceEventHandler.emit(event);
        }
    };

    onRecordSelect = (event) => {
        this.selectedLookupRecord.emit(event);
    };

    filterOptions = (value: string): string[] => {
        if (value) {
            const filterValue = value.toLowerCase();
            return this.options.filter((option) => option.displayName.toLowerCase().includes(filterValue));
        } else {
            return this.options;
        }
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => {
            this.modelChanged(event);
            this.filteredOptions = this.filterOptions(event);
        });
        this.optionsSubscription = this.totalOptions.subscribe((options) => {
            this.filteredOptions = options;
        });
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
