import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationsSubSection,
    IntegrationSubSectionRecord,
    IntegrationTabStructure,
} from '../../interface/integrationmodal.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

class RecordData {
    fields?: IntegrationModal['fields'];

    subSections?: IntegrationModal['subSections'];

    constructor(record: AppIntegrationRecord, metaData: IntegrationModal) {
        const checkAndAssignValue = (
            objectToAssign: any,
            property: string,
            value: any,
            decodingType: IntegrationConfigField['decodingType'],
            isArray?: boolean
        ) => {
            if (value !== undefined) {
                if (isArray) {
                    value = Array.isArray(value) ? value : [value];
                } else {
                    value = Array.isArray(value) ? value[0] : value;
                }
            }
            if (decodingType) {
                switch (decodingType) {
                    case 'atob':
                        try {
                            objectToAssign[property] = window.atob(value);
                        } catch (error) {
                            objectToAssign[property] = value;
                        }
                        break;
                    case 'DATE':
                        objectToAssign[property] = CommonUtilsService.transformDateToLocale(
                            value,
                            'YYYY-MM-DDTHH:MM:SSZ',
                            'DD-MM-YYYY HH:MM:SS AM',
                            true
                        );
                        break;
                    default:
                        objectToAssign[property] = value;
                        break;
                }
            } else {
                objectToAssign[property] = value;
            }
        };

        const setFieldValue = (section: IntegrationsSubSection, fieldId: string, fieldValue: any) => {
            const fieldObj = section.fields.find((field) => field.id === fieldId);
            if (fieldId === 'frequency' && fieldObj) {
                const option = fieldObj.options.find((option) => option.value === fieldValue);
                if (!option) {
                    fieldObj.options.unshift({
                        displayName:
                            typeof fieldValue === 'string'
                                ? fieldValue.endsWith('h')
                                    ? fieldValue.substring(0, fieldValue.length - 1) + ' Hours'
                                    : fieldValue.endsWith('m')
                                    ? fieldValue.substring(0, fieldValue.length - 1) + ' Minutes'
                                    : fieldValue
                                : fieldValue,
                        id: fieldValue,
                        pushValue: '*',
                        value: fieldValue,
                    });
                }
            }
            fieldObj.value = fieldValue;
        };

        const setPanaelValue = (field) => {
            const keys = Object.keys(field.value);
            keys.forEach((key) => {
                field.options.forEach((each) => {
                    if (each.name === key) {
                        each.value = field.value[key];
                    }
                });
            });
        };

        const isDataTypeObject = (value: any) => {
            try {
                return typeof JSON.parse(value) === 'object';
            } catch (e) {
                return false;
            }
        };

        const loopThoughObjet = (key: string, data: object, isFieldRequired?: boolean, subRecordObject?: IntegrationSubSectionRecord) => {
            let field: IntegrationConfigField;
            if (!isFieldRequired) {
                let fieldsArray;
                if (subRecordObject) {
                    fieldsArray = subRecordObject.fields;
                } else {
                    fieldsArray = this.fields;
                }
                field = fieldsArray.find((metaDataField) => metaDataField.id === key || metaDataField.fieldIdInSubObject === key);
            }
            if (field) {
                checkAndAssignValue(field, 'value', data[key] || field.defaultValue, field.decodingType, field.array);
                if ((field.array && Array.isArray(field.value)) || field.valueAsArrayInPayload) {
                    let values = [];
                    if (Array.isArray(field.value)) {
                        values = ((field.value as string[]) && field.value[0] && field.value[0].length > 0 && field.value) || [''];
                    } else {
                        values = [field.value];
                    }
                    field.values = [];
                    if (!Array.isArray(values)) {
                        values = field.value.split(',');
                    }
                    values &&
                        values.forEach((value) => {
                            const valueObj: IntegrationConfigField['values'][0] = {};
                            checkAndAssignValue(valueObj, 'value', value, field.decodingType);
                            field.values.push(valueObj);
                        });
                }
                if (typeof field.value === 'object' && field.type === 'TRANSFORMATION_PANEL') {
                    setPanaelValue(field);
                }
            } else if (!field && typeof data[key] === 'object' && !Array.isArray(data[key])) {
                // tslint:disable-next-line:forin
                let objectToLoop = data[key];
                if (typeof objectToLoop === 'string') {
                    objectToLoop = JSON.parse(objectToLoop);
                }
                for (const subKey in objectToLoop) {
                    let noField = false;
                    if (key === 'authentication') {
                        noField = true;
                    }
                    loopThoughObjet(subKey, objectToLoop, noField, subRecordObject);
                }
            } else if (
                !field &&
                ((subRecordObject && subRecordObject.childSubSections && subRecordObject.childSubSections.length > 0) || this.subSections)
            ) {
                let subSections;
                if (subRecordObject && subRecordObject.childSubSections && subRecordObject.childSubSections.length > 0) {
                    subSections = subRecordObject.childSubSections;
                } else {
                    subSections = this.subSections;
                }
                subSections.forEach((subFieldObject) => {
                    let subField: IntegrationConfigField;
                    if (subFieldObject.haveTabs) {
                        for (let i = 0; i < subFieldObject.tabs.length; i++) {
                            const tab = subFieldObject.tabs[i];
                            if (data[key] instanceof Array) {
                                if (tab.id === key) {
                                    if (data[key].length > 0) {
                                        tab.records = [];
                                        data[key].forEach((subData) => {
                                            const newRecord: IntegrationTabStructure['records'][0] = {
                                                fields: [],
                                                recordActions: [],
                                            };
                                            // tslint:disable-next-line:forin
                                            for (const dataKey in subData) {
                                                let metaDataField = tab.fields.find((tabField) => tabField.id === dataKey);
                                                if (metaDataField) {
                                                    metaDataField = CommonUtilsService.cloneObject(metaDataField);
                                                    if (
                                                        metaDataField.valueAsArrayInPayload &&
                                                        Array.isArray(subData[dataKey]) &&
                                                        !metaDataField.array
                                                    ) {
                                                        subData[dataKey] = subData[dataKey][0];
                                                    } else if (subData[dataKey] && Array.isArray(subData[dataKey])) {
                                                        for (let index = 0; index < subData[dataKey].length; index++) {
                                                            if (subData[dataKey][index] === null || subData[dataKey][index] === undefined) {
                                                                subData[dataKey][index] =
                                                                    metaDataField.defaultValue !== undefined
                                                                        ? metaDataField.defaultValue
                                                                        : '';
                                                            }
                                                        }
                                                    }
                                                    checkAndAssignValue(
                                                        metaDataField,
                                                        'value',
                                                        subData[dataKey] !== undefined ? subData[dataKey] : metaDataField.defaultValue,
                                                        metaDataField.decodingType
                                                    );
                                                    newRecord['fields'].push(metaDataField);
                                                } else {
                                                    let metaDataSubField = tab.recordActions.find(
                                                        (tabSubField) => tabSubField.id === dataKey
                                                    );
                                                    if (metaDataSubField) {
                                                        metaDataSubField = CommonUtilsService.cloneObject(metaDataSubField);
                                                        checkAndAssignValue(
                                                            metaDataSubField,
                                                            'value',
                                                            subData[dataKey] !== undefined
                                                                ? subData[dataKey]
                                                                : metaDataSubField.defaultValue,
                                                            metaDataSubField.decodingType
                                                        );
                                                        newRecord['recordActions'].push(metaDataSubField);
                                                    }
                                                }
                                            }
                                            tab.records.push(newRecord);
                                        });
                                    } else {
                                        tab.records = tab.records || [];
                                    }
                                }
                            } else {
                                if (data && data['grantType']) {
                                    for (let i = 0; i < tab.fields.length; i++) {
                                        if (!tab.fields[i].conditionalDisplay) {
                                            if (tab.fields[i].id === key) {
                                                subField = tab.fields[i];
                                                break;
                                            }
                                        } else {
                                            if (tab.fields[i].id === key && key === 'grantType') {
                                                subField = tab.fields[i];
                                                break;
                                            }
                                            if (tab.fields[i].id === key && checkForGrantType(tab.fields[i], data['grantType'])) {
                                                subField = tab.fields[i];
                                                break;
                                            }
                                            // return ((metaDataField.id === key) && checkForGrantType(metaDataField, data['grantType']))
                                        }
                                    }
                                } else {
                                    subField = tab.fields.find((metaDataField) => metaDataField.id === key);
                                }
                                // subField = tab.fields.find(metaDataField => metaDataField.id === key);
                                if (subField) {
                                    break;
                                }
                            }
                        }
                    } else if (subFieldObject.structure === 'RECORDS_FORM' && subFieldObject.array && Array.isArray(data[key])) {
                        subFieldObject.records = [];
                        data[key].forEach((configData: AppIntegrationRecord['configs'][0]) => {
                            createSubSectionRecord(subFieldObject.records, subFieldObject, configData);
                        });
                    } else {
                        if (subFieldObject.structure === 'FORM') {
                            const formSubField = subFieldObject.fields.find((formSubFieldObj) => formSubFieldObj.id === key);
                            if (formSubField?.type === 'ORGANIZATIONS_CHECKBOX') {
                                const values = [];
                                data[formSubField.id]?.forEach((valueObject) => {
                                    values.push(valueObject[formSubField.fieldIdInPayloadAs]);
                                });
                                checkAndAssignValue(formSubField, 'value', [values], formSubField.decodingType);
                            } else if (formSubField) {
                                checkAndAssignValue(formSubField, 'value', data[formSubField.id], formSubField.decodingType);
                            }
                        } else if (subFieldObject.structure === 'FLEX_FORM') {
                            if (
                                (subFieldObject.id === 'schedulerExpression' || subFieldObject.id === 'cronExpression') &&
                                (key === 'schedulerExpression' || key === 'cronExpression')
                            ) {
                                const formSubField = subFieldObject.fields.find(
                                    (formSubFieldObj) => formSubFieldObj.id === 'schedulerExpression'
                                );
                                const value = data[formSubField.id];
                                const valueToPass = value
                                    ?.split(' ')
                                    ?.filter((item, index) => index > 0 && index < 6)
                                    .join(' ');
                                formSubField && checkAndAssignValue(formSubField, 'value', valueToPass, formSubField.decodingType);
                                // const values = data[key].split(' ');
                                // if (values.length === 5) {
                                //     (values as string[]).splice(4, 0, '*');
                                // }
                                // let starsCount = 0;
                                // values && values.forEach(value => value.indexOf('*') > (-1) && (starsCount++));
                                // for (let i = values.length - 1; i > 0; i--) {
                                //     switch (i) {
                                //         case 5:
                                //             if (values[i].indexOf('?') > (-1)) {
                                //                 setFieldValue(subFieldObject, 'frequency', 'year');
                                //             } else if (values[i].indexOf('?') > (-1)) {
                                //                 setFieldValue(subFieldObject, 'frequency', 'month');
                                //             } else {
                                //                 setFieldValue(subFieldObject, 'day', values[i]);
                                //             }
                                //             break;
                                //         case 4:
                                //             if (values[i].indexOf('*') > (-1)) {
                                //                 setFieldValue(subFieldObject, 'frequency', 'month');
                                //             } else {
                                //                 setFieldValue(subFieldObject, 'month', values[i]);
                                //             }
                                //             break;
                                //         case 3:
                                //             if (values[i].indexOf('*') > (-1) && starsCount > 2) {
                                //                 setFieldValue(subFieldObject, 'frequency', 'day');
                                //             } else if ((values[i].indexOf('*') > (-1) || values[3] === '?') && (starsCount === 2 || starsCount === 1)) {
                                //                 if (values[5] === '?') {
                                //                     setFieldValue(subFieldObject, 'frequency', 'day');
                                //                 } else {
                                //                     setFieldValue(subFieldObject, 'frequency', 'week');
                                //                 }
                                //             } else {
                                //                 setFieldValue(subFieldObject, 'date', values[i]);
                                //             }
                                //             break;
                                //         case 2:
                                //             if (values[i].indexOf('*') > (-1) && starsCount >= 3) {
                                //                 if (values[i].indexOf('/') > (-1)) {
                                //                     setFieldValue(subFieldObject, 'frequency', values[i].substring(values[i].indexOf('/') + 1) + 'h');
                                //                 } else {
                                //                     setFieldValue(subFieldObject, 'frequency', 'hour');
                                //                 }
                                //             } else {
                                //                 setFieldValue(subFieldObject, 'hour', values[i]);
                                //             }
                                //             break;
                                //         case 1:
                                //             if (values[i].indexOf('*') > (-1) && starsCount >= 3) {
                                //                 if (values[i].indexOf('/') > (-1)) {
                                //                     setFieldValue(subFieldObject, 'frequency', values[i].substring(values[i].indexOf('/') + 1) + 'm');
                                //                 }
                                //             } else {
                                //                 setFieldValue(subFieldObject, 'minute', values[i]);
                                //             }
                                //             break;
                                //     }
                                // }
                            } else {
                                /** Do Nothing for now */
                            }
                        } else {
                            // console.log('Not found: ', subFieldObject);
                        }
                    }
                    if (subField) {
                        checkAndAssignValue(
                            subField,
                            'value',
                            data[key] || (subField && subField.defaultValue),
                            (subField && subField.decodingType) || undefined
                        );
                    }
                });
            }
        };

        const checkForGrantType = (field, type) => {
            if (field && field.conditionalDisplay) {
                for (let i = 0; i < field.conditionalDisplay.length; i++) {
                    if (field.conditionalDisplay[i].conditions && field.conditionalDisplay[i].conditions.length > 0) {
                        for (let j = 0; j < field.conditionalDisplay[i].conditions.length; j++) {
                            if (
                                field.conditionalDisplay[i].conditions[j].fieldId === 'grantType' &&
                                field.conditionalDisplay[i].conditions[j].requiredValue === type
                            ) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        };

        const assignFieldValue = (field: IntegrationConfigField) => {
            field.value = field.value !== undefined ? field.value : field.defaultValue;
            if (field.array) {
                let values = field.value !== undefined && (field.value as string[]).length > 0 ? (field.value as string[]) : [''];
                field.values = [];
                if (!Array.isArray(values)) {
                    values = field.value.split(',');
                }
                if (Array.isArray(values)) {
                    values &&
                        values.forEach((value) => {
                            field.values.push({ value });
                        });
                }
            }
        };

        const assignSubSectionTabRecord = (tab: IntegrationTabStructure) => {
            if (tab.array) {
                tab.records = tab.records || [];
                if (tab.fieldsType === 'UNIQUE-SBS') {
                    tab.fields.forEach((field) => {
                        const index = tab.records.findIndex((tabRecord) => tabRecord.fields[0].id === field.id);
                        if (index === -1) {
                            tab.records.push({
                                fields: [CommonUtilsService.cloneObject(field)],
                                recordActions: [],
                            });
                        }
                    });
                } else if (tab.records.length === 0) {
                    tab.records.push({
                        fields: [],
                        recordActions: [],
                    });
                }
                tab.records.forEach((tabRecord) => {
                    if (!tabRecord.fields || tabRecord.fields.length === 0) {
                        tab.fields.forEach((tabField) => {
                            const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                            newTabField.value = newTabField.defaultValue;
                            tabRecord.fields.push(newTabField);
                        });
                    } else if (tabRecord.fields.length !== tab.fields.length && tab.fieldsType !== 'UNIQUE-SBS') {
                        tab.fields
                            .filter((action) => tabRecord.fields.findIndex((recordAction) => recordAction.id === action.id) === -1)
                            .forEach((action) => {
                                const cloneField = CommonUtilsService.cloneObject(action);
                                cloneField.value = action.defaultValue;
                                tabRecord.fields.push(cloneField);
                            });
                    }
                    if (!tabRecord.recordActions || tabRecord.recordActions.length === 0) {
                        tab.recordActions.forEach((tabField) => {
                            const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                            newTabField.value = newTabField.defaultValue;
                            tabRecord.recordActions.push(newTabField);
                        });
                    } else if (tabRecord.recordActions.length !== tab.recordActions.length) {
                        tab.recordActions
                            .filter((action) => tabRecord.recordActions.findIndex((recordAction) => recordAction.id === action.id) === -1)
                            .forEach((action) => {
                                const cloneAction = CommonUtilsService.cloneObject(action);
                                cloneAction.value = action.defaultValue;
                                tabRecord.recordActions.push(cloneAction);
                            });
                    }
                });
            }
        };

        const createSubSectionRecord = (
            records,
            subSectionObject: IntegrationsSubSection,
            recordData?: AppIntegrationRecord['configs'][0]
        ) => {
            const recordObject: IntegrationsSubSection['records'][0] = {
                fields: CommonUtilsService.cloneObject(subSectionObject.fields),
            };
            if (subSectionObject.hasChildSubSectons) {
                recordObject.childSubSections = [];
                recordObject.childSubSections = CommonUtilsService.cloneObject(subSectionObject.childSubSections);
                // tslint:disable-next-line: no-use-before-declare
                assignSubSections(recordObject.childSubSections);
            }
            const recordKeys = (recordData && Object.keys(recordData)) || [];
            if (recordData && recordKeys.length > 0) {
                if (typeof recordData.configuredData === 'string') {
                    recordData.configuredData = JSON.parse(recordData.configuredData);
                }
                recordKeys.forEach((key) => {
                    loopThoughObjet(key, recordData, undefined, recordObject);
                });
            }
            records.push(recordObject);
        };

        const assignSubSections = (subSections: IntegrationsSubSection[]) => {
            subSections &&
                subSections.forEach((subSectionObject) => {
                    if (subSectionObject.haveTabs && subSectionObject.tabs.length > 0) {
                        subSectionObject.tabs.forEach(assignSubSectionTabRecord);
                    } else if (subSectionObject.array) {
                        if (!subSectionObject.records || subSectionObject.records.length === 0) {
                            subSectionObject.records = [];
                            createSubSectionRecord(subSectionObject.records, subSectionObject);
                        }
                    }
                });
        };

        for (const key in metaData) {
            if (metaData[key] instanceof Array) {
                // this[key] = metaData[key].slice(0);
                this[key] = CommonUtilsService.cloneObject(metaData[key]);
            } else if (metaData[key] instanceof Object) {
                this[key] = CommonUtilsService.cloneObject(metaData[key]);
            } else {
                this[key] = metaData[key];
            }
        }

        const originalRecordDataKeys = (record && Object.keys(record)) || [];
        if (originalRecordDataKeys?.length > 0) {
            originalRecordDataKeys.forEach((key) => {
                loopThoughObjet(key, record);
            });
        }
        this.fields?.forEach(assignFieldValue);
        assignSubSections(this.subSections);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class AppIntegrationRecord {
    chainName: string;

    configName: string;

    configs: {
        chainName: string;
        configName: string;
        configuredData: string;
        createdBy: string;
        createdOn: string;
        id: string;
        integrationMethod: string;
        integrationType: string;
        locationId: string;
        resourceName: string;
        schedulerExpression: string;
        serviceId: string;
        transformationName: string;
    }[];

    integrationType: string;

    locationId: string;

    schedulerExpression: string;

    serviceId: string;

    transformationName: string;

    appId?: string;

    tableValues?: {
        [property: string]: {
            displayName: string;
            id: string;
            value: any;
        };
    };

    recordData?: IntegrationModal;

    originalData?: {
        chainName: string;
        configName: string;
        configs: any[];
        integrationType: string;
        locationId: string;
        schedulerExpression: string;
        serviceId: string;
        transformationName: string;
        id?: string;
        createdOn?: string;
    };

    isValid?: boolean;

    constructor(record: AppIntegrationRecord, metaData: IntegrationModal) {
        this.chainName = record?.chainName;
        this.configName = record?.configName;
        this.transformationName = record?.transformationName;
        this.appId = record?.appId;
        this.originalData =
            (record?.originalData && CommonUtilsService.cloneObject(record.originalData)) || CommonUtilsService.cloneObject(record);
        this.isValid = record?.isValid !== undefined ? record.isValid : false;
        const originalData = CommonUtilsService.cloneObject(this.originalData as any);
        originalData?.configs?.forEach((config) => {
            delete config['integrationSettings'];
            delete config['method'];
        });
        this.recordData = new RecordData(originalData, metaData);
        this.tableValues = {};
        this.recordData.tableFieldIds &&
            this.recordData.tableFieldIds
                .filter((fieldId) => ['Actions'].indexOf(fieldId) === -1)
                .forEach((fieldId) => {
                    const field = this.recordData.fields.find((dataField) => dataField.id === fieldId);
                    if (field) {
                        this.tableValues[fieldId] = {
                            displayName: field.displayName,
                            id: fieldId,
                            value: field.value,
                        };
                    } else if (!field && this.recordData.subSections) {
                        this.recordData.subSections.forEach((subFieldObject) => {
                            let subField: IntegrationConfigField;
                            if (subFieldObject.haveTabs) {
                                for (let i = 0; i < subFieldObject.tabs.length; i++) {
                                    const tab = subFieldObject.tabs[i];
                                    subField = tab.fields.find((metaDataField) => metaDataField.id === fieldId);
                                    if (subField) {
                                        break;
                                    }
                                }
                            }
                            if (subField) {
                                this.tableValues[fieldId] = {
                                    displayName: subField.displayName,
                                    id: fieldId,
                                    value: subField.value,
                                };
                            }
                        });
                    }
                });

        // Manually changing the values of trigger now, as it doesnt exist on payload
        const triggerJobField = this.getRecordField('triggerJob');
        const schedulerExpression = this.originalData?.schedulerExpression;
        if (triggerJobField) {
            if (!schedulerExpression || schedulerExpression === '') {
                triggerJobField.value = triggerJobField.defaultValue;
            } else {
                triggerJobField.value = true;
            }
        }
    }

    // tslint:disable-next-line: whitespace
    addNewTabRecord? = (tab: IntegrationTabStructure) => {
        if (!tab.records) {
            tab.records = [];
        }
        if (tab.array) {
            const newRecord: IntegrationTabStructure['records'][0] = {
                fields: [],
                recordActions: [],
            };
            tab.fields.forEach((tabField) => {
                const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                newTabField.value = newTabField.defaultValue;
                newRecord.fields.push(newTabField);
            });
            tab.recordActions.forEach((tabField) => {
                const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                newTabField.value = newTabField.defaultValue;
                newRecord.recordActions.push(newTabField);
            });
            tab.records.push(newRecord);
        }
    };

    addSubSectionRecord = (subSection: IntegrationsSubSection) => {
        const recordObject: IntegrationsSubSection['records'][0] = {
            fields: CommonUtilsService.cloneObject(subSection.fields),
        };
        if (subSection.hasChildSubSectons) {
            recordObject.childSubSections = [];
            recordObject.childSubSections = CommonUtilsService.cloneObject(subSection.childSubSections);
            // tslint:disable-next-line: no-use-before-declare
            this.assignSubSections(recordObject.childSubSections);
        }
        subSection.records.push(recordObject);
    };

    private assignSubSections = (subSections: IntegrationsSubSection[]) => {
        subSections &&
            subSections.forEach((subSectionObject) => {
                if (subSectionObject.haveTabs && subSectionObject.tabs.length > 0) {
                    subSectionObject.tabs.forEach(this.assignSubSectionTabRecord);
                } else if (subSectionObject.array) {
                    subSectionObject.records = [];
                    this.addSubSectionRecord(subSectionObject);
                }
            });
    };

    private assignSubSectionTabRecord = (tab: IntegrationTabStructure) => {
        if (tab.array) {
            tab.records = tab.records || [];
            if (tab.records.length === 0) {
                tab.records.push({
                    fields: [],
                    recordActions: [],
                });
            }
            tab.records.forEach((tabRecord) => {
                if (!tabRecord.fields || tabRecord.fields.length === 0) {
                    tab.fields.forEach((tabField) => {
                        const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                        newTabField.value = newTabField.defaultValue;
                        tabRecord.fields.push(newTabField);
                    });
                } else if (tabRecord.fields.length !== tab.fields.length) {
                    tab.fields
                        .filter((action) => tabRecord.fields.findIndex((recordAction) => recordAction.id === action.id) === -1)
                        .forEach((action) => {
                            const cloneField = CommonUtilsService.cloneObject(action);
                            cloneField.value = action.defaultValue;
                            tabRecord.fields.push(cloneField);
                        });
                }
                if (!tabRecord.recordActions || tabRecord.recordActions.length === 0) {
                    tab.recordActions.forEach((tabField) => {
                        const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                        newTabField.value = newTabField.defaultValue;
                        tabRecord.recordActions.push(newTabField);
                    });
                } else if (tabRecord.recordActions.length !== tab.recordActions.length) {
                    tab.recordActions
                        .filter((action) => tabRecord.recordActions.findIndex((recordAction) => recordAction.id === action.id) === -1)
                        .forEach((action) => {
                            const cloneAction = CommonUtilsService.cloneObject(action);
                            cloneAction.value = action.defaultValue;
                            tabRecord.recordActions.push(cloneAction);
                        });
                }
            });
        }
    };

    // tslint:disable-next-line: whitespace
    getRecordField? = (
        fieldId: string,
        tab?: IntegrationTabStructure,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ): IntegrationConfigField => {
        return this.getRecordFieldByProperty('id', fieldId, tab, subSectionRecord) as IntegrationConfigField;
    };

    // tslint:disable-next-line: whitespace
    getRecordTab? = (tabId: string): IntegrationTabStructure => {
        return this.getRecordTabByProperty('id', tabId) as IntegrationTabStructure;
    };

    // tslint:disable-next-line: whitespace
    setRecordFieldValue? = (fieldId: string, fieldProperty: string, value: any, tab?: IntegrationTabStructure) => {
        const field = this.getRecordField(fieldId, tab);
        field && (field[fieldProperty] = value);
    };

    // tslint:disable-next-line: whitespace
    getRecordFieldByProperty? = (
        propertyName: string,
        propertyValue: any,
        tab?: IntegrationTabStructure,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ): IntegrationConfigField | IntegrationConfigField[] => {
        let field: IntegrationConfigField | IntegrationConfigField[];
        if (subSectionRecord) {
            field = subSectionRecord.fields && subSectionRecord.fields.find((recordField) => recordField[propertyName] === propertyValue);
            if (!field) {
                if (subSectionRecord.childSubSections && subSectionRecord.childSubSections.length > 0) {
                    for (let i = 0; i < subSectionRecord.childSubSections.length; i++) {
                        if (field) {
                            break;
                        }
                        if (subSectionRecord.childSubSections[i].haveTabs) {
                            for (let j = 0; j < subSectionRecord.childSubSections[i].tabs.length; j++) {
                                if (field) {
                                    break;
                                }
                                field = this.getRecordFieldByProperty(
                                    propertyName,
                                    propertyValue,
                                    subSectionRecord.childSubSections[i].tabs[j]
                                );
                            }
                        } else {
                            field =
                                subSectionRecord.childSubSections[i].fields &&
                                subSectionRecord.childSubSections[i].fields.find(
                                    (recordField) => recordField[propertyName] === propertyValue
                                );
                        }
                    }
                }
            }
            return field;
        } else if (tab) {
            field = tab.fields && tab.fields.find((recordField) => recordField[propertyName] === propertyValue);
            if (!field) {
                field = tab.recordActions && tab.recordActions.find((recordField) => recordField[propertyName] === propertyValue);
            }
            if (field) {
                return field;
            }
        } else {
            field = this.recordData?.fields?.find((recordField) => recordField[propertyName] === propertyValue);
            if (!field) {
                this.recordData?.subSections?.forEach((section) => {
                    if (!field) {
                        if (section.haveTabs) {
                            section.tabs.forEach((sectionTab) => {
                                field = this.getRecordFieldByProperty(propertyName, propertyValue, sectionTab);
                            });
                        } else if (section.array) {
                            const fields = [];
                            section.records &&
                                section.records.forEach((sectionRecord) => {
                                    const recordField = this.getRecordFieldByProperty(
                                        propertyName,
                                        propertyValue,
                                        undefined,
                                        sectionRecord
                                    );
                                    recordField && fields.push(recordField);
                                });
                            if (fields && fields.length > 0) {
                                field = fields;
                            }
                        } else {
                            field = section.fields && section.fields.find((sectionField) => sectionField[propertyName] === propertyValue);
                        }
                    }
                });
            }
            if (!field) {
                if (this.originalData?.[propertyValue]) {
                    return { value: this.originalData[propertyValue] } as any;
                }
            }
            if (field) {
                return field;
            }
        }
    };

    // tslint:disable-next-line: whitespace
    getRecordTabByProperty? = (propertyName: string, propertyValue: any): IntegrationTabStructure => {
        let tab: IntegrationTabStructure;
        this.recordData.subSections &&
            this.recordData.subSections.forEach((section) => {
                if (section.haveTabs) {
                    section.tabs.forEach((sectionTab) => {
                        if (sectionTab[propertyName] === propertyValue) {
                            tab = sectionTab;
                        }
                    });
                }
                if (!tab && section.childSubSections) {
                    section.childSubSections.forEach((subSection) => {
                        subSection?.tabs.forEach((sectionTab) => {
                            if (sectionTab[propertyName] === propertyValue) {
                                tab = sectionTab;
                            }
                        });
                    });
                }
            });
        if (tab) {
            return tab;
        }
    };

    // tslint:disable-next-line: whitespace
    getRecordFieldValyeByProperty? = (propertyName: string, propertyValue: any): string | boolean | string[] => {
        const field = this.getRecordFieldByProperty(propertyName, propertyValue);
        if (!Array.isArray(field)) {
            return field && field.value;
        }
    };

    // tslint:disable-next-line: whitespace
    getMasterField? = (): IntegrationConfigField => {
        const field = this.recordData.fields.find((recordField) => recordField.isMasterField);
        if (field) {
            return field;
        }
    };

    // tslint:disable-next-line: whitespace
    canHideField? = (
        field: IntegrationConfigField,
        tab?: IntegrationTabStructure,
        subSectionRecord?: IntegrationsSubSection['records'][0]
    ) => {
        const masterField = this.getMasterField();
        const masterValue = masterField && masterField.value;
        let satisfied = false;
        if (field.conditionalDisplay) {
            let satisfiedConditions;
            field.conditionalDisplay &&
                field.conditionalDisplay.forEach((conditionalDisplay) => {
                    switch (conditionalDisplay.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab, subSectionRecord);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDisplay.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab, subSectionRecord);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
            if (!satisfied) {
                if (!tab && masterField) {
                    if (masterValue === false) {
                        field.value = field.defaultValue;
                    } else if (masterValue === true) {
                        field.value = undefined;
                    }
                } else if (!tab && !masterField) {
                    field.value = field.defaultValue;
                } else if (tab) {
                    if (tab.array) {
                        /** @todo Do nothing for now */
                    } else {
                        field.value = field.value || field.defaultValue || undefined;
                    }
                }
            }
            field['hide'] = !satisfied;
            return !satisfied;
        }
        return satisfied;
    };

    // tslint:disable-next-line: whitespace
    canAssignDefaultValue? = (field: IntegrationConfigField, tab?: IntegrationTabStructure['records'][0]) => {
        let satisfied = false;
        if (field.defaultValueIf) {
            let satisfiedConditions;
            field.defaultValueIf &&
                field.defaultValueIf.forEach((conditionalDefault) => {
                    switch (conditionalDefault.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDefault.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab as any);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDefault.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDefault.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
        }
        return satisfied;
    };

    // tslint:disable-next-line: whitespace
    canDisableField? = (field: IntegrationConfigField, tab?: IntegrationTabStructure) => {
        const masterField = this.getMasterField();
        const masterValue = masterField && masterField.value;
        let satisfied = false;
        if (field.conditionalDisable) {
            let satisfiedConditions;
            field.conditionalDisable &&
                field.conditionalDisable.forEach((conditionalDisable) => {
                    switch (conditionalDisable.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDisable.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDisable.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDisable.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
        }
        return satisfied;
    };

    canHideSubSection = (subSection: IntegrationsSubSection, subSectionRecord?: IntegrationsSubSection['records'][0]) => {
        let satisfied = false;
        if (subSection.conditionalDisplay) {
            let satisfiedConditions;
            subSection.conditionalDisplay &&
                subSection.conditionalDisplay.forEach((conditionalDisplay) => {
                    switch (conditionalDisplay.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, undefined, subSectionRecord);
                                return condition.valueExists
                                    ? requiredField.value !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredField.value
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDisplay.conditions.length;
                            break;

                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, undefined, subSectionRecord);
                                let requiredValue = requiredField?.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
            if (!satisfied) {
                if (subSection.haveTabs) {
                    subSection.tabs.forEach((tab) => {
                        if (tab.array && tab.records) {
                            tab.records.forEach((tabRecord) => {
                                tabRecord.fields.forEach((tabRecordField) => {
                                    tabRecordField.value =
                                        tabRecordField.defaultValue !== undefined ? tabRecordField.defaultValue : undefined;
                                });
                                tabRecord.recordActions &&
                                    tabRecord.recordActions.forEach((tabActionField) => {
                                        tabActionField.value =
                                            tabActionField.defaultValue !== undefined ? tabActionField.defaultValue : undefined;
                                    });
                            });
                        } else if (tab.fields && tab.fields.length > 0) {
                            tab.fields.forEach((tabRecordField) => {
                                tabRecordField.value = tabRecordField.defaultValue !== undefined ? tabRecordField.defaultValue : undefined;
                            });
                            tab.recordActions &&
                                tab.recordActions.forEach((tabActionField) => {
                                    tabActionField.value =
                                        tabActionField.defaultValue !== undefined ? tabActionField.defaultValue : undefined;
                                });
                        }
                    });
                } else if (subSection.fields) {
                    subSection.fields.forEach((subSectionField) => {
                        subSectionField.value = subSectionField.defaultValue !== undefined ? subSectionField.defaultValue : undefined;
                    });
                }
            }
            return !satisfied;
        }
        return satisfied;
    };

    isConditionalArray = (field: IntegrationConfigField) => {
        let satisfied = false;
        let satisfiedConditions: { fieldId: string; requiredValue: boolean; array: boolean }[];
        field.conditionalArray &&
            field.conditionalArray.forEach((conditions) => {
                if (satisfied) {
                    return;
                }
                switch (conditions.type) {
                    case 'ALL_MUST_SATISFY':
                        satisfiedConditions = conditions.conditions.filter((condition) => {
                            const requiredField = this.getRecordField(condition.fieldId, undefined);
                            let requiredValue = requiredField && requiredField.value;
                            if (requiredField === undefined) {
                                requiredValue = this[condition.fieldId];
                            }
                            return condition.requiredValue !== undefined ? condition.requiredValue === requiredValue : false;
                        });
                        satisfied = satisfied || satisfiedConditions.length === conditions.conditions.length;
                        break;
                    case 'ANY_SATISFY':
                        satisfiedConditions = conditions.conditions.filter((condition) => {
                            const requiredField = this.getRecordField(condition.fieldId);
                            let requiredValue = requiredField && requiredField.value;
                            if (requiredField === undefined) {
                                requiredValue = this[condition.fieldId];
                            }
                            return condition.requiredValue !== undefined ? condition.requiredValue === requiredValue : false;
                        });
                        satisfied = satisfied || satisfiedConditions.length > 0;
                        break;
                    default:
                        break;
                }
            });
        return satisfied && satisfiedConditions && satisfiedConditions[0] && satisfiedConditions[0].array;
    };
}
