import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AssetService } from '../../models/assetservice.class';
import { MasterRecord } from '../../models/masters/masterrecord.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { MastersService } from '../../services/masters/masters.service';
import { FieldLevelFileAttachmentComponent } from '../field-level-file-attachment/field-level-file-attachment.component';

@Component({
    selector: 'app-field-input',
    templateUrl: './field-input-template.component.html',
    styleUrls: ['./field-input-template.component.scss'],
})
export class FieldInputTemplateComponent implements OnInit {
    @Input() field: RecordField;
    @Input() record: Record;
    @Input() enableField: boolean;
    @Input() instanceId: string;
    @Input() classList: string[];
    @Input() service: AssetService;
    @Input() bridge: AssetService;
    @Input() masterEntityFieldValueMap: { [property: string]: string };
    @Input() fieldsMap: BehaviorSubject<{
        [property: string]: { keyColumns: string[]; valuesMap: { id: string; masterColumn: string; value: any }[] };
    }>;
    @Input() recordUpdateDisabled: boolean;
    @Input() currentOrganizationId: string;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() recordUpdated = new EventEmitter();
    @Output() getRecord = new EventEmitter();

    unSubscribe = new Subject<void>();

    constructor(private dialog: MatDialog, private _masters: MastersService) {}

    getConditionValue = () => {
        let value;
        if (
            this.field.lookupInformation &&
            this.field.lookupInformation.lookupCondition &&
            this.field.lookupInformation.lookupCondition.length > 0
        ) {
            const conditionalFieldId = this.field.lookupInformation.lookupCondition.substring(
                this.field.lookupInformation.lookupCondition.indexOf(':') + 1
            );
            value = this.record.getFieldValue(conditionalFieldId);
        }
        return value;
    };

    fieldChanged = () => {
        const field = this.field;
        this.clearDependentValues(field.entityUid, field.id);
        const { canGetMasterRecord, values, masterTableId } = this.setDependents(false);
        if (canGetMasterRecord) {
            const masterMetaData = this.record.masters.getTable(masterTableId);
            this._masters.getMasterRecordWithprimaryValue(
                {
                    masterDataRow: {
                        columnValues: values,
                        tableId: masterTableId,
                        unitId: this.currentOrganizationId,
                    },
                    masterSearchDetail: {
                        pageNo: 0,
                        searchString: '',
                    },
                },
                {
                    successCallback: (response) => {
                        const record = new MasterRecord(response?.[0], masterMetaData as any);
                        this.record.setMasterValues(record);
                    },
                    failureCallback: (res) => {
                        const record = res?.response?.records?.masterRecords?.[0];
                        const masterRecord = record && new MasterRecord(record, masterMetaData);
                        masterRecord && this.record.setMasterValues(masterRecord);
                    },
                }
            );
        }
    };

    setDependents = (initialRender: boolean) => {
        const field = this.field;
        const map = this.record.buildMasterEntityFieldValueMap(
            CommonUtilsService.cloneObject(this.fieldsMap.value),
            this.masterEntityFieldValueMap,
            initialRender
        );
        const masterTableId = field.masterData.tableUid;
        const changedFields = map[masterTableId];
        const values = {};
        this.field.masterData.columnRefMetadatas.forEach((reference: { fieldUid: string; entityUid: string }) => {
            const tableMapId = Object.keys(map).find((tableId) => map[tableId].keyColumns.indexOf(reference.fieldUid) > -1);
            const tableMap = map[tableMapId];
            const fieldValueMap = tableMap.valuesMap.find((fieldInMap) => fieldInMap.id === reference.fieldUid);
            if (fieldValueMap) {
                values[fieldValueMap.masterColumn] = {
                    newValue: fieldValueMap.value,
                };
            }
        });
        if (JSON.stringify(map) !== JSON.stringify(this.fieldsMap.value)) {
            const currentTableId = this.field.masterData.tableUid;
            const tableMap = map[currentTableId];
            const valueMapIndex = tableMap.keyColumns.indexOf(this.field.id);
            !initialRender &&
                tableMap.keyColumns
                    .filter((id, index) => index > valueMapIndex)
                    .forEach((id) => {
                        const fieldWithKey = this.record.getFieldByFieldId(id);
                        if (fieldWithKey) {
                            if (fieldWithKey.id === field.id) {
                                this.record.setFieldValue(id, undefined, true);
                            } else if (fieldWithKey.masterData) {
                                if (
                                    fieldWithKey.masterData.tableUid === masterTableId &&
                                    fieldWithKey.masterData.columnName !== field.masterData.columnName
                                ) {
                                    this.record.setFieldValue(id, undefined, true);
                                }
                            }
                        }
                    });
            tableMap.valuesMap
                .filter((id, index) => index > valueMapIndex)
                .forEach((valueMap) => {
                    valueMap.value = initialRender ? valueMap.value : undefined;
                });
            if (this.field.value !== undefined) {
                if (valueMapIndex === -1) {
                    if (this.field.masterField.primaryKey) {
                        tableMap.keyColumns.push(this.field.id);
                    }
                    tableMap.valuesMap.push({
                        id: this.field.id,
                        entityId: field.entityUid,
                        masterColumn: this.field.masterData.columnName,
                        value: this.field.value,
                        references: this.field.masterData.columnRefMetadatas,
                    });
                } else {
                    tableMap.valuesMap[valueMapIndex] = {
                        id: this.field.id,
                        entityId: this.field.entityUid,
                        masterColumn: this.field.masterData.columnName,
                        value: this.field.value,
                        references: this.field.masterData.columnRefMetadatas,
                    };
                }
            }
            this.fieldsMap.next(map);
        }
        let canGetMasterRecord = true;
        changedFields.valuesMap
            .filter((valueMap) => valueMap.id === field.id || valueMap.masterColumn !== field.masterData.columnName)
            .forEach((valueMap) => {
                const primaryKeyValue = values[valueMap.masterColumn]?.newValue || valueMap.value;
                values[valueMap.masterColumn] = {
                    newValue: primaryKeyValue,
                };
                canGetMasterRecord = canGetMasterRecord && primaryKeyValue !== undefined && primaryKeyValue.toLowerCase() !== 'na';
            });
        return {
            canGetMasterRecord,
            values,
            masterTableId,
        };
    };

    clearDependentValues = (entityUid: string, fieldId: string) => {
        this.record.fields
            .filter((field) => field.hasMasterLookup)
            .filter((field) => field.masterData.columnRefMetadatas && field.masterData.columnRefMetadatas.length > 0)
            .forEach((field) => {
                const masterData = field.masterData;
                const references = masterData.columnRefMetadatas;
                const referenceFound = references.find((reference) => reference.entityUid === entityUid && reference.fieldUid === fieldId);
                if (referenceFound) {
                    field.value = undefined;
                    this.clearDependentValues(field.entityUid, field.id);
                }
            });
    };

    openAttachModal = () => {
        const dialogRef = this.dialog.open(FieldLevelFileAttachmentComponent, {
            panelClass: ['fieldFileUploadModal', 'matDialogContainer'],
            disableClose: true,
        });
        dialogRef.componentInstance.record = this.record;
        dialogRef.componentInstance.field = this.field;
        dialogRef.componentInstance.instanceId = this.instanceId;
        dialogRef.componentInstance.recordUpdateDisabled = this.recordUpdateDisabled;
        dialogRef.componentInstance.filesListUpdated = new BehaviorSubject(undefined);
        dialogRef.componentInstance.getNewRecord = new BehaviorSubject(undefined);
        dialogRef.componentInstance.generateUploadedFiles();
        dialogRef.componentInstance.filesListUpdated.pipe(takeUntil(this.unSubscribe)).subscribe((value) => {
            if (value !== undefined) {
                this.field.value = value;
                this.recordUpdated.emit();
            }
        });
        dialogRef.componentInstance.getNewRecord.pipe(takeUntil(this.unSubscribe)).subscribe((value) => {
            if (value !== undefined) {
                this.getRecord.emit();
            }
        });
    };

    valueChanged = (event: any) => {
        this.field.value = event;
        this.modelChange.emit(event);
    };

    ngOnInit() {
        if (this.field && this.field.hasMasterLookup) {
            this.setDependents(true);
        }
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
