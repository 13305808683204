import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { IntegrationConfigField, IntegrationsSubSection, IntegrationTabStructure } from '../../interface/integrationmodal.interface';
import { IntegrationRecord } from '../../models/integrations/integrationrecord.class';
import { IntegrationRecordPayload } from '../../models/integrations/integrationrecordpayload.class';
import { MasterField } from '../../models/masters/masterfield.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { IntegrationsUtilsService } from '../../services/integrations-utils/integrations-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import {
    AlertError,
    DeleteIntegrationConfiguration,
    SaveIntegrationConfiguration,
    TestIntegrationConfiguration,
    ValidateIntegrationConfiguration,
    ValidateIntegrationConfigurationSuccess,
} from '../../store/actions';

@Component({
    selector: 'app-integrationfields',
    templateUrl: './integrationfields.component.html',
})
export class IntegrationFieldsComponent implements OnInit, OnDestroy {
    @Input() editRecord: IntegrationRecord;
    @Input() masterTables: MasterTables[];
    @Input() subTenants: any[];

    @Output() cancelRecord = new EventEmitter();

    masterTableColumns: MasterField[] = [];
    unSubscribe = new Subject<void>();
    showValidateButton: boolean = true;

    constructor(
        public _integrationUtils: IntegrationsUtilsService,
        private _taxilla: ApiService,
        private _libUtils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private store$: Store,
        private actions$: Actions
    ) {}

    public hideField = this._integrationUtils.hideField;

    public getFieldType = this._integrationUtils.getFieldType;

    public hideSubSection = this._integrationUtils.hideSubSection;

    public getMasterColumns = (masterId) => this._integrationUtils.getMasterColumns(masterId, this.masterTableColumns);

    checkValidateButton = (event) => {
        this.showValidateButton = event;
    };

    public testConnection = () => {
        if (this.validateRecord()) {
            const payload = new IntegrationRecordPayload(this.editRecord);
            payload.appId = 'taxilla';
            this.store$.dispatch(
                TestIntegrationConfiguration({
                    payload: payload as { configuredData: string; appId: string; name: string; organizationId: string; type: string },
                })
            );
        } else {
            this.store$.dispatch(
                AlertError({
                    message: this.translateMsg('Please fix errors to proceed'),
                })
            );
        }
    };

    private validateRecord = (hideError?: boolean): boolean => {
        const result = this._integrationUtils.isFormValid(this.editRecord);
        if (!result && !hideError) {
            this._libUtils.alertError(this.translateMsg('Please fix errors to proceed'));
        }
        return result;
    };

    public cancelConfig = () => {
        this.cancelRecord.emit();
    };
    public deleteConfig = () => {
        this.store$.dispatch(
            DeleteIntegrationConfiguration({
                record: CommonUtilsService.cloneObject(this.editRecord),
            })
        );
    };

    validate = () => {
        if (this.validateRecord()) {
            const payload = new IntegrationRecordPayload(this.editRecord);
            payload.organizationId = payload.organizationId || this._commonUtils.getFromStorage('currentOrganizationId');
            this.store$.dispatch(
                ValidateIntegrationConfiguration({
                    payload,
                })
            );
        } else {
            this._libUtils.alertError(this.translateMsg('Please fix errors to proceed'));
        }
    };

    public submitConfigurationDetails = (drawer?) => {
        if (this.validateRecord()) {
            const payload = new IntegrationRecordPayload(this.editRecord);
            payload.organizationId = payload.organizationId || this._commonUtils.getFromStorage('currentOrganizationId');
            this.store$.dispatch(
                SaveIntegrationConfiguration({
                    payload,
                })
            );
        } else {
            this._libUtils.alertError(this.translateMsg('Please fix errors to proceed'));
        }
    };

    public checkIfTestConnectionAvailable = () => {
        if (this.editRecord) {
            const masterValue = this.editRecord.getRecordFieldValyeByProperty('id', 'useMaster');
            const recordType = this.editRecord.type && this.editRecord.type.toLowerCase();
            const isWebService = recordType === 'webservice';
            const isEmailService = recordType === 'email';
            const isRESTAPI = recordType === 'restapi';
            return isEmailService || isWebService || masterValue || isRESTAPI ? false : true;
        }
        return true;
    };

    private removeErrorsFromFields = (fields: IntegrationConfigField[]) => {
        fields && fields.forEach((field) => (field.errors = []));
    };

    private removeErrorsFromRecords = (records) => {
        if (records && records.length > 0) {
            records.fields && this.removeErrorsFromFields(records.fields);
            if (records.childSubsections && records.childSubsections.length > 0) {
                this.removeErrorsFromSubSections(records.childSubsections);
            }
        }
    };

    private removeErrorsFromSubSectionTabRecords = (tabs: IntegrationTabStructure[]) => {
        tabs &&
            tabs.forEach((tab) => {
                if (tab.array) {
                    this.removeErrorsFromRecords(tab.records);
                } else if (tab.fields) {
                    this.removeErrorsFromFields(tab.fields);
                }
            });
    };

    private removeErrorsFromSubSections = (subSections: IntegrationsSubSection[]) => {
        subSections &&
            subSections.forEach((subSection) => {
                if (subSection.haveTabs) {
                    this.removeErrorsFromSubSectionTabRecords(subSection.tabs);
                } else if (subSection.array) {
                    this.removeErrorsFromRecords(subSection.records);
                } else if (subSection.fields) {
                    this.removeErrorsFromFields(subSection.fields);
                }
            });
    };

    private removeErrorsFromRecord = () => {
        this.removeErrorsFromFields(this.editRecord.recordData.fields);
        this.removeErrorsFromSubSections(this.editRecord.recordData.subSections);
    };

    private translateMsg = (msg: string): string => translate('' + msg);

    ngOnInit() {
        if (this.editRecord) {
            this.validateRecord(true);
            this.removeErrorsFromRecord();
        }

        this.actions$.pipe(ofType(ValidateIntegrationConfigurationSuccess), takeUntil(this.unSubscribe)).subscribe((data) => {
            if (data?.redirectURL.length > 0) {
                window.open(data.redirectURL, '_blank');
                this.cancelConfig();
            }
        });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
