<ng-container *transloco="let translateMsg;">
    <span class="searchFilters" (click)="onDateSearchFocus()">{{translateMsg('Filter by date')}}
        <span class="material-icons filter-icon-alignment">
            filter_alt
        </span>
    </span>

    <!-- Search slider -->
    <div class="mainSearchFilters mat-elevation-z8" [class.filterVisible]="isDateSearch" stop-propagation *ngIf="!isModal">
        <div class="srchHeading">
            <span class="srchHeadingLabel">{{translateMsg('Filter by date')}}</span>
            <div class="closeOutlookSearch">
                <span class="material-icons cursorPointer" (click)="isDateSearch = false;">close</span>
            </div>
        </div>
        <div class="selectedFilterContainer">
            <ng-container *ngTemplateOutlet="dateFilterFields"></ng-container>
        </div>
        <div class="align-items-center d-flex justify-content-between actionBtnsSection">
            <material-button class="noOutline submitColor mr-auto" [label]="translateMsg('Apply')" [disabled]="!timeFilter.endDate ||!timeFilter.startDate" (onTrigger)="searchByTimeRange()"></material-button>
            <button mat-button (click)="clearSearchCriteria()" class="mx-2 ml-auto mat-elevation-z1 inSideSearch">{{translateMsg('Clear Filter')}}</button>
        </div>
    </div>

    <ng-template #dateFilterFields>
        <div class="row">
            <div class="col-6">
                <material-input-date [placeholder]="translateMsg('From Date')" [id]="'processStartingDate'" [model]="timeFilter.startDate" [inputOnlyDisabled]="true" (modelChange)="timeFilter.startDate = $event;"></material-input-date>
            </div>
            <div class="col-6 timePickerContainer">
                <mat-form-field>
                    <ng-container *ngIf="isIE && timeFilter.startTime;else noTime">
                        <input matInput [placeholder]="translateMsg('Start Time')" [(ngModel)]="timeFilter.startTime" readonly />
                    </ng-container>
                    <ng-template #noTime>
                        <input matInput [ngxTimepicker]="StartTimePick" [placeholder]="translateMsg('Start Time')" [(ngModel)]="timeFilter.startTime" readonly />
                    </ng-template>
                    <button mat-button matSuffix mat-icon-button tabindex="-1">
                        <ngx-material-timepicker-toggle [for]="StartTimePick"></ngx-material-timepicker-toggle>
                    </button>
                    <ngx-material-timepicker #StartTimePick [theme]="darkTheme" (timeChanged)="timeFilter.startTime = $event;"></ngx-material-timepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <material-input-date [placeholder]="translateMsg('To Date')" [id]="'processEndDate'" [model]="timeFilter.endDate" [inputOnlyDisabled]="true" (modelChange)="timeFilter.endDate = $event;"></material-input-date>
            </div>
            <div class="col-6 timePickerContainer">
                <mat-form-field>
                    <ng-container *ngIf="isIE && timeFilter.endTime;else noEndTime">
                        <input matInput [placeholder]="translateMsg('End Time')" [(ngModel)]="timeFilter.endTime" readonly />
                    </ng-container>
                    <ng-template #noEndTime>
                        <input matInput [ngxTimepicker]="endTimePick" [placeholder]="translateMsg('End Time')" [(ngModel)]="timeFilter.endTime" readonly />
                    </ng-template>
                    <button mat-button matSuffix mat-icon-button tabindex="-1">
                        <ngx-material-timepicker-toggle [for]="endTimePick"></ngx-material-timepicker-toggle>
                    </button>
                    <ngx-material-timepicker #endTimePick [theme]="darkTheme" (timeChanged)="timeFilter.endTime = $event;"></ngx-material-timepicker>
                </mat-form-field>
            </div>
        </div>
    </ng-template>

    <ng-template #timeFilterModal>
        <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
            <h4 class="matDialogTitle" mat-dialog-title>{{translateMsg('Filter by date')}}</h4>
            <span class="material-icons mx-2" mat-dialog-close (click)="closePopup()">close</span>
        </div>
        <mat-dialog-content class="mat-typography">
            <div>
                <ng-container *ngTemplateOutlet="dateFilterFields"></ng-container>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <material-button class="noOutline submitColor mr-auto" [label]="translateMsg('Apply')" [disabled]="!timeFilter.endDate ||!timeFilter.startDate" (onTrigger)="searchByTimeRange()"></material-button>
            <button mat-button (click)="clearSearchCriteria()" class="mx-2 ml-auto mat-elevation-z1 inSideSearch">{{translateMsg('Clear Filter')}}</button>
        </mat-dialog-actions>
    </ng-template>
</ng-container>
