import { ReconciliationSourceField } from '../reconciliation-source-field.class';

export class ReconciliationRecordField {
    id: string;

    value: any;

    field: ReconciliationSourceField;

    constructor(value: any, field: ReconciliationSourceField) {
        this.id = field?.id;
        this.value = value;
        this.field = field;
    }
}
