import { createAction, props } from '@ngrx/store';

import { RecordPayload } from '../../models/payload/recordpayload.class';
import { Record } from '../../models/record/record.class';

enum recordTypes {
    GET_RECORDS = '[RECORDS] Get Record(s)',
    SET_RECORDS = '[RECORDS] Set Record(s)',
    CLEAR_RECORDS = '[RECORDS] Clear Record(s)',
    SEARCH_RECORDS = '[RECORDS] Search Record(s)',
    SAVE_RECORDS = '[RECORDS] Save Record(s)',
    UPDATE_RECORDS = '[RECORDS] Update Record(s)',
    DELETE_RECORDS = '[RECORDS] Delete Record(s)',
    DELETED_RECORDS = '[RECORDS] Deleted Record(s)',
    SET_LOADING = '[RECORDS] Set Loading',
    SET_RECORDS_PAGINATION = '[RECORDS] Set Records Pagination',
    CLEAR_RECORDS_PAGINATION = '[RECORDS] Clear Records Pagination',
    SET_SEARCH_RECORDS = '[RECORDS] Set Search Record(s)',
    SET_RECORDS_SEARCH_CRITERIA = '[RECORDS] Set Record(s) Search Criteria',
    CLEAR_SEARCH_CRITERIA = '[RECORDS] Clear Search Criteria',
}

export const GetRecords = createAction(
    recordTypes.GET_RECORDS,
    props<{
        serviceId: string;
        instanceId: string;
        entityUid: string;
        parentRecordId?: string;
        noAlerts?: boolean;
    }>()
);

export const SetRecords = createAction(
    recordTypes.SET_RECORDS,
    props<{
        organizationId: string;
        serviceId: string;
        instanceId: string;
        parentRecordId: string;
        entityUid: string;
        records: Record[];
        stringifiedCriteria?: string;
        deleteFromSource?: boolean;
    }>()
);

export const ClearRecords = createAction(
    recordTypes.CLEAR_RECORDS,
    props<{
        organizationId: string;
        serviceId: string;
        instanceId: string;
    }>()
);

export const SetSearchRecords = createAction(
    recordTypes.SET_SEARCH_RECORDS,
    props<{
        organizationId: string;
        serviceId: string;
        instanceId: string;
        parentRecordId: string;
        entityUid: string;
        records: Record[];
        stringifiedCriteria?: string;
        reconSpecific?: 'source1' | 'source2' | 'grouped';
        associationKey?: string;
        deleteFromSource?: boolean;
    }>()
);

export const SearchRecords = createAction(
    recordTypes.SEARCH_RECORDS,
    props<{
        serviceId: string;
        instanceId: string;
        entityUid: string;
        parentRecordId?: string;
        criteria?: any;
        noAlerts?: boolean;
        reconSpecific?: 'source1' | 'source2' | 'grouped';
        associationKey?: string;
    }>()
);

export const SetRecordsSearchCriteria = createAction(
    recordTypes.SET_RECORDS_SEARCH_CRITERIA,
    props<{
        organizationId: string;
        serviceId: string;
        instanceId: string;
        entityUid: string;
        parentRecordId?: string;
        criteria: string;
        reconSource?: 'source1' | 'source2' | 'grouped';
    }>()
);

export const ClearSearchCriteria = createAction(
    recordTypes.CLEAR_SEARCH_CRITERIA,
    props<{
        organizationId?: string;
        serviceId: string;
        instanceId: string;
        entityUid: string;
        parentRecordId?: string;
        reconSpecific?: 'source1' | 'source2' | 'grouped';
    }>()
);

export const SaveRecords = createAction(
    recordTypes.SAVE_RECORDS,
    props<{
        serviceId: string;
        instanceId: string;
        parentRecordId: string;
        entityUid: string;
        recordsPayload: RecordPayload[];
        noMessages?: boolean;
        deleteFromSource?: boolean;
    }>()
);

export const UpdateRecord = createAction(
    recordTypes.UPDATE_RECORDS,
    props<{
        serviceId: string;
        instanceId: string;
        parentRecordId: string;
        entityUid: string;
        recordsPayload: RecordPayload[];
        records?: Record[];
    }>()
);

export const SetRecordsLoading = createAction(
    recordTypes.SET_LOADING,
    props<{
        organizationId: string;
        serviceId: string;
        instanceId: string;
        parentRecordId: string;
        entityUid: string;
        loading: boolean;
    }>()
);

export const SetRecordsPagination = createAction(
    recordTypes.SET_RECORDS_PAGINATION,
    props<{
        organizationId?: string;
        serviceId: string;
        instanceId: string;
        entityUid: string;
        parentRecordId?: string;
        pageSize?: number;
        pageIndex?: number;
        cursor?: string;
        reconSpecific?: 'source1' | 'source2' | 'grouped';
        // associationKey?: string;
        noAlerts: boolean;
    }>()
);

export const ClearRecordsPagination = createAction(
    recordTypes.CLEAR_RECORDS_PAGINATION,
    props<{
        organizationId?: string;
        serviceId: string;
        instanceId: string;
        entityUid: string;
        parentRecordId?: string;
        reconSpecific?: 'source1' | 'source2' | 'grouped';
    }>()
);

export const DeleteRecords = createAction(
    recordTypes.DELETE_RECORDS,
    props<{
        serviceId: string;
        instanceId: string;
        entityId: string;
        parentRecordId?: string;
        payload: { id: string; parentRecordId: string }[];
        noAlerts?: boolean;
        noMessages?: boolean;
        deleteFromSource?: boolean;
        reconSpecific?: boolean;
    }>()
);

export const DeletedRecords = createAction(
    recordTypes.DELETED_RECORDS,
    props<{
        organizationId: string;
        serviceId: string;
        instanceId: string;
        parentRecordId: string;
        entityUid: string;
        recordIds: string[];
        deleteFromSource?: boolean;
        reconSpecific?: boolean;
    }>()
);
