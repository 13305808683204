import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@authentication/services/login.service';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ApiService,
    AuthGetClientDetails,
    ClearApplicationMiscProperties,
    ClientDetails,
    CommonUtilsService,
    FetchCaptcha,
    fetchCaptchaSuccessState,
    GetApplicationMiscProperties,
    getApplicationMiscPropertiesState,
    getClientDetailsFailedState,
    getClientDetailsState,
    LdapLoginFailed,
    LdapLoginSuccess,
    SecurityQuestion,
    SubmitLDAPDetails,
    UtilsService,
} from 'taxilla-library';

import { RootScopeService } from '../../services/rootscope.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    @ViewChild('drawer', { read: MatDrawer, static: false }) drawer: MatDrawer;

    login = {
        userId: '',
        password: '',
        domain: '',
    };
    errors = {
        userId: [],
        password: [],
        domain: [],
    };
    forgot = {
        userId: '',
        captcha: '',
    };
    forgotPaswdErrors = {
        userId: [],
        captcha: [],
    };
    forgotUsername = {
        orgId: '',
        email: '',
        captcha: '',
    };
    forgotUsernameErrors = {
        orgId: [],
        email: [],
        captcha: [],
    };
    isForgotPaswd = false;
    isForgotUserId = false;
    securityAuthenticate = false;
    sessionLoggedInToken: any;
    randomQuestions = [];
    randomQuestionsStatus = {
        loading: false,
        randomQuestions: false,
        noQuestionsFound: true,
    };
    // loggedInUserId: any;
    lastLoggedInTime: any;
    forgotPswdCaptchaUrl: string;
    forgotUsernameCaptchaUrl: string;
    fallbackDrawer: any;
    navigateTimeout: any;
    dynamicPassPhrase: string;
    authCode: string;
    hideLoginForm = true;
    hideDomain: boolean;
    googleCode: string;
    emailOTP: string;
    emailOTPErrors: string[];
    googleCodeErrors: string[];
    twoFactorType: any;
    unsubscribe = new Subject<void>();
    miscPropsSubscription: Subscription;
    public inLdapMode = this._commonUtils.getFromStorage('authenticationType') === 'LDAP';
    public userIdIdentifier = this._commonUtils.getFromStorage('userididentifier');
    public hideSignUpPage = environment.hideSignUpPage;
    secretSeedToken: string | undefined;
    resendDisabled: boolean;
    buttonText: string = 'Resend OTP';
    timerSubscription: Subscription;
    otpTimerSubcription: Subscription;
    resendRemainingSeconds: number = 60;
    otpExpiryTimer: number;

    constructor(
        private _taxilla: ApiService,
        private _router: Router,
        private _utils: UtilsService,
        private _activatedRoute: ActivatedRoute,
        public _commonUtils: CommonUtilsService,
        public R: RootScopeService,
        private _loginService: LoginService,
        private ngRxStore: Store,
        private actions$: Actions,
        private _broadcaster: BroadcasterService
    ) {
        const showDomainFromStorage = this._commonUtils.getFromStorage('showDomain', true);
        this.hideDomain =
            this.R.onLocalHost ||
            this._commonUtils.isBrowserIE() ||
            [
                'dbcloud',
                'dbtest',
                'dbuat',
                'dbprod',
                'dbdr',
                'kpmg2aprod',
                'kpmgprod',
                'kpmguat',
                'adityabirlauat',
                'adityabirlaprod',
            ].indexOf(R.PLATFORM) > 0 ||
            !showDomainFromStorage;
    }

    /**
     * to submit login details
     */
    submitLoginDetails = async () => {
        const session = await this._loginService.checkSession();
        const drawer = this.drawer;
        let payload = '';
        let username = '';
        let password = '';
        if (session) {
            if (this.authCode?.length > 0) {
                const url = environment.taxilla_user_identity_api + '/v1/auto-login';
                this._utils.submitForm(url, { auth_code: this.authCode });
            } else {
                this._loginService.redirect();
            }
            return;
        } else {
            await this.getApplicationMiscProps();
            username = this._utils.encryptString(this.login.userId, this.dynamicPassPhrase);
            password = this._utils.encryptString(this.login.password, this.dynamicPassPhrase);
            payload = `username=${username}&password=${password}`;
            if (this.authCode?.length > 0) {
                payload += `&auth_code=${this.authCode}`;
            }
        }
        const data = {
            payload,
            hideDomain: this.hideDomain,
        };
        this.clearStorage();
        this._taxilla.session.submitLoginDetails(data, {
            successCallback: (res) => {
                const url: string = res?.['redirect-uri'];
                this.twoFactorType = res?.['twoFAType'];
                if (url?.length > 0) {
                    this._commonUtils.redirectToPage(url);
                } else {
                    if (drawer) {
                        this.securityAuthenticate = true;
                        this.isForgotPaswd = false;
                        this.isForgotUserId = false;
                        if (this.twoFactorType === 'SECRET_QUESTION') {
                            this.getRandomQuestions();
                        }
                        if (this.twoFactorType === 'EMAIL_OTP') {
                            this.sendOTP();
                        }
                        drawer.toggle();
                        this.hideLoginForm = false;
                    }
                }
            },
            failureCallback: (res) => {
                this.hideLoginForm = false;
                this._utils.alertError((res && res.msg) || translate('failedToLogin'));
            },
        });
    };

    private clearStorage = () => {
        const clientId = this._commonUtils.getFromStorage('clientId');
        const successRedirect = this._commonUtils.getFromStorage('successRedirect');
        const redirectURL = this._commonUtils.getFromStorage('redirectURL');
        const stateId = this._commonUtils.getFromStorage('stateId');
        const code = this._commonUtils.getFromStorage('code');
        const encodedCode = this._commonUtils.getFromStorage('encodedCode');
        const returnPage = this._commonUtils.getFromStorage('returnPage');
        const authType = this._commonUtils.getFromStorage('authenticationType');
        const authId = this._commonUtils.getFromStorage('authenticationId');
        const passwordPolicyRegex = this._commonUtils.getFromStorage('passwordPolicyRegex');
        const passwordPolicyMsg = this._commonUtils.getFromStorage('passwordPolicyMsg');
        const mmfaQuestions = this._commonUtils.getFromStorage('mmfaQuestions');
        const uniqueIdentifier = this._commonUtils.getFromStorage('uniqueIdentifier');
        this._commonUtils.clearStorage();
        this._commonUtils.setInStorage('clientId', clientId);
        this._commonUtils.setInStorage('successRedirect', successRedirect);
        this._commonUtils.setInStorage('redirectURL', redirectURL);
        this._commonUtils.setInStorage('stateId', stateId);
        this._commonUtils.setInStorage('code', code);
        this._commonUtils.setInStorage('encodedCode', encodedCode);
        this._commonUtils.setInStorage('authenticationType', authType);
        this._commonUtils.setInStorage('authenticationId', authId);
        this._commonUtils.setInStorage('userIdIdentifier', uniqueIdentifier);
        returnPage && this._commonUtils.setInStorage('returnPage', returnPage);
        passwordPolicyRegex && this._commonUtils.setInStorage('passwordPolicyRegex', passwordPolicyRegex);
        passwordPolicyMsg && this._commonUtils.setInStorage('passwordPolicyMsg', passwordPolicyMsg);
        this._commonUtils.setInStorage('mmfaQuestions', mmfaQuestions);
    };

    /**
     * navigation to forgot password page
     */
    navigateToForgotPassword = () => {
        const drawer = this.drawer;
        this.fallbackDrawer = drawer;
        this.isForgotPaswd = true;
        this.isForgotUserId = false;
        this.securityAuthenticate = false;
        drawer.toggle();
        // tslint:disable-next-line:forin
        for (const key in this.errors) {
            this.errors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotPaswdErrors) {
            this.forgotPaswdErrors[key] = [];
        }
        this.forgot.userId = this.login.userId;
        this.getForgotPswdCaptcha();
    };

    /**
     * Validations
     */
    private validateLoginDetails = () => {
        let count = 0;
        if (this.login.userId === undefined || (this.login.userId && this.login.userId.length < 0) || this.login.userId === '') {
            this.errors.userId.push(translate('error_userIdRequired'));
            count++;
        } else {
            this.errors.userId = [];
        }
        if (this.login.password === undefined || (this.login.password && this.login.password.length < 0) || this.login.password === '') {
            this.errors.password.push(translate('error_passwordRequired'));
            count++;
        } else {
            this.errors.password = [];
        }
        return count === 0;
    };

    checkKeyCode = (event, status, section?: 'IP' | 'Login' | 'google' | 'questions' | 'email_OTP') => {
        if (section?.length > 0) {
            switch (section) {
                case 'IP':
                    if (this.login.domain?.length > 0) {
                        this.login.userId = '';
                        this.login.password = '';
                        this.errors.userId = [];
                        this.errors.password = [];
                    }
                    break;
                case 'Login':
                    if (this.login.userId?.length > 0 || this.login.password?.length > 0) {
                        this.login.domain = '';
                    }
                    break;
                case 'google':
                    if (this.googleCode?.length > 0) {
                        this.emailOTPErrors = [];
                        this.randomQuestions.forEach((question) => {
                            question.value = '';
                            question.errors = [];
                        });
                    }
                    break;
                case 'questions':
                    this.googleCode = '';
                    this.googleCodeErrors = [];
                    this.emailOTPErrors = [];
                    break;
                case 'email_OTP':
                    this.googleCode = '';
                    this.googleCodeErrors = [];
                    this.randomQuestions.forEach((question) => {
                        question.value = '';
                        question.errors = [];
                    });
                    break;
            }
        }
        if (event.keyCode === 13) {
            if (status === 'security') {
                this.authenticateDetails();
            } else {
                this.submitDetails();
            }
        }
    };

    submitDetails = () => {
        if (this.inLdapMode) {
            if (this.validateLoginDetails()) {
                this.submitLDAPDetails();
            }
            return;
        }
        if (this.login.domain?.length > 0 && this.login.userId?.length > 0 && this.login.password?.length > 0) {
            this._utils.alertError(translate('domainCredential'));
        } else if (this.login.domain?.length > 0) {
            this.externalAuthentication(this.login.domain);
        } else {
            if (this.validateLoginDetails()) {
                this._commonUtils.deleteCookie('domain');
                this.submitLoginDetails();
            } else {
                this.hideLoginForm = false;
            }
        }
    };

    private submitLDAPDetails = () => {
        this.ngRxStore.dispatch(
            SubmitLDAPDetails({
                payload: {
                    attribute: this.login.userId,
                    password: this.login.password,
                    identityProviderId: this._commonUtils.getFromStorage('authenticationId'),
                },
            })
        );
    };

    private externalAuthentication = async (domain: string) => {
        this._commonUtils.setCookie('domain', domain);
        const session = this._commonUtils.getFromStorage('session');
        if (!session) {
            this.getClientDetails(domain);
        } else {
            const session = await this._loginService.checkSession();
            if (session) {
                this._loginService.redirect();
            }
        }
    };

    private getClientDetails = (domain: string) => {
        this.ngRxStore.dispatch(
            AuthGetClientDetails({
                payload: {
                    domain,
                },
            })
        );
    };

    private authorizeExternalIP = (IDPCredentials: ClientDetails) => {
        const idpType = this._commonUtils.getFromStorage('authenticationType');
        let stateId = this._commonUtils.getFromStorage('stateId');
        if (!stateId) {
            stateId = this._utils.guid(16);
            this._commonUtils.setInStorage('stateId', stateId);
        }
        if (this.inLdapMode) {
            return;
        } else if (idpType === 'SAML' && IDPCredentials.config.bindingType === 'POST') {
            this._loginService.setSAMLRoute(IDPCredentials.config.singleSignOnUrl, {
                SAMLRequest: IDPCredentials.config.encodedSamlRequest,
            });
            return;
        }
        const code = this._utils.guid(43);
        this._commonUtils.setInStorage('code', code);
        const encodedCode = this._utils.encryptWithSHA256(code, 'BASE64');
        this._commonUtils.setInStorage('encodedCode', encodedCode);
        this._taxilla.session.authorizeExternalIP(
            {
                authURL: this._commonUtils.getFromStorage('authenticationURL'),
                clientId: this._commonUtils.getFromStorage('clientId'),
                code: encodedCode as any,
                stateId,
                redirectUri: this._commonUtils.getFromStorage('redirectURL'),
                type: this._commonUtils.getFromStorage('authenticationType'),
            },
            {
                successCallback: (res) => {},
                failureCallback: (res) => {
                    if (!res || res?.msg || res?.text) {
                        this.hideLoginForm = false;
                        if (res?.msg) {
                            this._utils.alertError(res?.msg);
                        }
                    } else {
                        this._router.navigate(['auth', 'error']);
                    }
                },
            }
        );
    };

    /**
     * sumbit email details
     */
    submitForgotPasswordDetails = () => {
        const data = CommonUtilsService.cloneObject(this.forgot);
        if (this.validateForgotPasswordDetails()) {
            this._taxilla.session.forgotPassword(data, {
                successCallback: (res) => {
                    this._utils.alertSuccess((res && res.msg) || translate('success_resetPasswordSuccess'));
                    this.redirectToLoginDrawer();
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || translate('error_submit'));
                },
            });
        }
    };

    /**
     * navigation to login page
     */
    navigateTologin = () => {
        const drawer = this.drawer;
        this.isForgotPaswd = false;
        this.isForgotUserId = false;
        this.securityAuthenticate = false;
        this.forgotUsername = {
            orgId: '',
            email: '',
            captcha: '',
        };
        drawer.toggle();
        // tslint:disable-next-line:forin
        for (const key in this.errors) {
            this.errors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotPaswdErrors) {
            this.forgotPaswdErrors[key] = [];
        }
    };

    getForgotPswdCaptcha = () => {
        this.forgot.captcha = '';
        const payload = {
            forgotPswd: true,
        };
        this.ngRxStore.dispatch(FetchCaptcha({ payload }));
        // this._taxilla.session.fetchCaptcha({
        //     successCallback: (res) => {
        //         this.forgotPswdCaptchaUrl = res?.captcha;
        //         callback && callback();
        //     },
        //     failureCallback: (response) => {
        //         this._utils.alertError((response && response.msg) || translate('error_failedToGetCaptcha'));
        //     },
        // });
    };

    /**
     * Validations
     */
    validateForgotPasswordDetails = () => {
        let count = 0;
        if (this.forgot.userId === undefined || (this.forgot.userId && this.forgot.userId.length < 0) || this.forgot.userId === '') {
            this.forgotPaswdErrors.userId.push(translate('error_userIdRequired'));
            count++;
        } else {
            this.errors.userId = [];
        }
        const isCaptchaValid = this.checkForgotPswdCaptcha();
        return count === 0 && isCaptchaValid;
    };

    checkForgotPswdCaptcha = () => {
        let count = 0;
        this.forgotPaswdErrors.captcha = [];
        if (!this.forgot.captcha || this.forgot.captcha.trim() === '') {
            this.forgotPaswdErrors.captcha = [translate('error_validCaptcha')];
            count++;
        }
        return count === 0;
    };

    getForgotUsernameCaptcha = () => {
        this.forgotUsername.captcha = '';
        const payload = {
            forgotUsername: true,
        };
        this.ngRxStore.dispatch(FetchCaptcha({ payload }));
        // this._taxilla.session.fetchCaptcha({
        //     successCallback: (res) => {
        //         this.forgotUsernameCaptchaUrl = res?.captcha;
        //         callback && callback();
        //     },
        //     failureCallback: (response) => {
        //         this._utils.alertError((response && response.msg) || translate('error_failedToGetCaptcha'));
        //     },
        // });
    };

    navigateToForgotUserName = (drawer) => {
        this.fallbackDrawer = drawer;
        this.isForgotPaswd = false;
        this.isForgotUserId = true;
        this.securityAuthenticate = false;
        // tslint:disable-next-line:forin
        for (const key in this.errors) {
            this.errors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotPaswdErrors) {
            this.forgotPaswdErrors[key] = [];
        }
        // tslint:disable-next-line:forin
        for (const key in this.forgotUsernameErrors) {
            this.forgotUsernameErrors[key] = [];
        }
        drawer.toggle();
        this.getForgotUsernameCaptcha();
    };

    submitForgotUserNameDetails = () => {
        const data = CommonUtilsService.cloneObject(this.forgotUsername);
        if (this.validateForgotUserNameDetails()) {
            this._taxilla.session.forgotUsername(data, {
                successCallback: (res) => {
                    if (res?.msg) {
                        this._utils.alertSuccess((res && res.msg) || translate('success_resetUserNameSuccess'));
                    }
                    this.redirectToLoginDrawer();
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || translate('error_submit'));
                },
            });
        }
    };

    private validateForgotUserNameDetails = () => {
        let count = 0;
        if (
            this.forgotUsername.orgId === undefined ||
            (this.forgotUsername.orgId && this.forgotUsername.orgId.length < 0) ||
            this.forgotUsername.orgId === ''
        ) {
            this.forgotUsernameErrors.orgId.push(translate('error_organizationCodeRequired'));
            count++;
        } else if (!this._utils.checkOrgCode(this.forgotUsername.orgId)) {
            this.forgotUsernameErrors.orgId.push(translate('error_organizationCode'));
            count++;
        } else {
            this.forgotUsernameErrors.orgId = [];
        }
        if (
            this.forgotUsername.email === undefined ||
            (this.forgotUsername.email && this.forgotUsername.email.length < 0) ||
            this.forgotUsername.email === ''
        ) {
            this.forgotUsernameErrors.email.push(translate('error_emailRequired'));
            count++;
        } else if (this._utils.invalidChars(this.forgotUsername.email)) {
            this.forgotUsernameErrors.email.push(translate('error_email'));
            count++;
        } else if (!this._utils.acceptEmail(this.forgotUsername.email)) {
            this.forgotUsernameErrors.email.push(translate('error_email'));
            count++;
        } else {
            this.forgotUsernameErrors.email = [];
        }
        const isUsernameCaptchaValid = this.checkForgotUsernameCaptcha();
        return count === 0 && isUsernameCaptchaValid;
    };

    checkForgotUsernameCaptcha = () => {
        let count = 0;
        this.forgotUsernameErrors.captcha = [];
        if (!this.forgotUsername.captcha || this.forgotUsername.captcha.trim() === '') {
            this.forgotUsernameErrors.captcha = [translate('error_validCaptcha')];
            count++;
        }
        return count === 0;
    };

    getRandomQuestions = () => {
        this.randomQuestions = [];
        this.randomQuestionsStatus.loading = true;
        this.randomQuestionsStatus.randomQuestions = false;
        this.randomQuestionsStatus.noQuestionsFound = false;
        this._taxilla.session.getRandomSecurityQuestions({
            successCallback: (res) => {
                this.randomQuestions = [];
                const questions = res;
                if (Object.keys(res).length > 0) {
                    this.randomQuestionsStatus.loading = false;
                    this.randomQuestionsStatus.randomQuestions = true;
                    this.randomQuestionsStatus.noQuestionsFound = false;
                    Object.keys(questions).forEach((key) => {
                        this.randomQuestions.push(new SecurityQuestion(key, questions[key]));
                    });
                } else {
                    setTruthyOrFalsy();
                }
            },
            failureCallback: () => {
                setTruthyOrFalsy();
            },
        });
        function setTruthyOrFalsy() {
            this.randomQuestionsStatus.loading = false;
            this.randomQuestionsStatus.randomQuestions = false;
            this.randomQuestionsStatus.noQuestionsFound = false;
        }
    };

    authenticateDetails = async () => {
        const session = await this._loginService.checkSession();
        if (session) {
            if (this.authCode?.length > 0) {
                const url = environment.taxilla_user_identity_api + '/v1/auto-login';
                this._utils.submitForm(url, { auth_code: this.authCode });
            } else {
                this._loginService.redirect();
            }
            return;
        }
        if (this.validateSecurityQuestions()) {
            const dontSendAsBrowserAddress =
                this.R.onLocalHost ||
                this._commonUtils.isBrowserIE() ||
                [
                    'dbcloud',
                    'dbtest',
                    'dbuat',
                    'dbprod',
                    'dbdr',
                    'kpmg2aprod',
                    'kpmgprod',
                    'kpmguat',
                    'adityabirlauat',
                    'adityabirlaprod',
                ].indexOf(this.R.PLATFORM) > 0;
            if (this.twoFactorType === 'EMAIL_OTP') {
                this.otpExpiryTimer > 0
                    ? this.submitEmailAuthenticationCode()
                    : this._utils.alertError(translate('OTP has expired, Please generate a new OTP and try again'));
            } else if (this.googleCode?.length > 0) {
                this.submitGoogleAuthenticationCode(!dontSendAsBrowserAddress);
            } else if (dontSendAsBrowserAddress) {
                this.sendDetailsAsXHR();
            } else {
                this.sendDetailsAsFormSubmit();
            }
        }
    };

    private submitGoogleAuthenticationCode = (isFormSubmit?: boolean) => {
        this._taxilla.session.submitGoogleAuthentication({ OTP: this.googleCode }, isFormSubmit).then((res) => {
            const url: string = res?.['redirect-uri'];
            if (this.hideDomain) {
                this._router.navigate(['auth', 'validate']);
            } else {
                this._commonUtils.redirectToPage(url);
            }
        });
    };

    private submitEmailAuthenticationCode = (isFormSubmit?: boolean) => {
        this._taxilla.session.evaluateEmailOTP({ value: this.emailOTP }, isFormSubmit, {
            successCallback: (res) => {
                const url: string = res?.['redirect-uri'];
                if (this.hideDomain) {
                    this._router.navigate(['auth', 'validate']);
                } else {
                    this._commonUtils.redirectToPage(url);
                }
            },
            failureCallback: (res) => {
                if (typeof res === 'string' || res?.text?.length > 0) {
                    return;
                } else if (res === null) {
                    this._router.navigate(['auth', 'validate']);
                    return;
                }
                if (this.drawer) {
                    this.drawer.toggle();
                    this.emailOTP = '';
                    this.isForgotPaswd = false;
                    this.isForgotUserId = false;
                    this.securityAuthenticate = false;
                    this.timerSubscription.unsubscribe();
                    this.otpTimerSubcription.unsubscribe();
                }
            },
        });
    };
    public sendOTP = () => {
        this._taxilla.session.generateEmailOTP().then((res) => {
            this._utils.alertSuccess(res);
            this.timerForResendOTP();
            this.timerForOtpExpiry();
        });
    };

    private timerForOtpExpiry = () => {
        this.otpExpiryTimer = 600;
        const timer = interval(1000).subscribe(() => {
            this.otpExpiryTimer--;
            if (this.otpExpiryTimer === 0) {
                this.otpTimerSubcription.unsubscribe();
            }
        });
        this.otpTimerSubcription = timer;
    };

    private timerForResendOTP = () => {
        this.resendDisabled = true;
        this.resendRemainingSeconds = 60;
        const timer = interval(1000).subscribe(() => {
            this.resendRemainingSeconds--;
            if (this.resendRemainingSeconds === 0) {
                this.timerSubscription.unsubscribe();
                this.resendDisabled = false;
                this.buttonText = 'Resend OTP';
                this.resendRemainingSeconds = 60;
            } else {
                this.buttonText = `Resend OTP in ${this.resendRemainingSeconds} sec`;
            }
        });
        this.timerSubscription = timer;
    };

    private sendDetailsAsFormSubmit = () => {
        const action = environment.taxilla_user_identity_api + '/secret-questions/evaluate';
        const payload = this.getRandomQuestionsPayloadObj();
        this._utils.submitForm(action, payload);
    };

    private sendDetailsAsXHR = () => {
        const drawer = this.drawer;
        const payload = this.getRandomQuestionsPayloadObj();
        let formPayload = '';
        Object.keys(payload).forEach((key, index) => {
            if (index !== 0) {
                formPayload += '&';
            }
            formPayload += `${key}=${payload[key]}`;
        });
        this._taxilla.session.evaluateSecurityQuestions(formPayload, {
            successCallback: (res) => {
                const url: string = res?.['redirect-uri'];
                if (this.hideDomain) {
                    this._router.navigate(['auth', 'validate']);
                } else {
                    this._commonUtils.redirectToPage(url);
                }
            },
            failureCallback: (res) => {
                if (typeof res === 'string' || res?.text?.length > 0) {
                    return;
                } else if (res === null) {
                    this._router.navigate(['auth', 'validate']);
                    return;
                }
                this._utils.alertError(res?.msg || translate('error_invalidSequrity'));
                if (drawer) {
                    drawer.toggle();
                    this.isForgotPaswd = false;
                    this.isForgotUserId = false;
                    this.securityAuthenticate = false;
                }
            },
        });
    };

    private validateSecurityQuestions = () => {
        let count = 0;
        this.googleCodeErrors = [];
        this.emailOTPErrors = [];
        this.randomQuestions.forEach((question) => {
            question.errors = [];
        });
        if (this.twoFactorType === 'GOOGLE_AUTHENTICATOR_OTP') {
            if (!this.googleCode || this.googleCode.trim().length === 0) {
                count++;
                this.googleCodeErrors.push(translate('Enter valid Authenticator code'));
            } else if (this.googleCode?.length > 0) {
                if (!this._utils.checkInteger(this.googleCode)) {
                    count++;
                    this.googleCodeErrors.push(translate('Enter valid Authenticator code'));
                }
                return count === 0;
            }
        } else if (this.twoFactorType === 'EMAIL_OTP') {
            if (!this.emailOTP || this.emailOTP.trim().length === 0) {
                count++;
                this.emailOTPErrors.push(translate('Please enter valid OTP'));
            } else if (this.emailOTP?.length > 0) {
                if (this.emailOTP?.length !== 6) {
                    count++;
                    this.emailOTPErrors.push(translate('OTP should contain 6 digits'));
                }
                if (!this._utils.checkInteger(this.emailOTP)) {
                    count++;
                    this.emailOTPErrors.push(translate('Please enter valid OTP'));
                }
                return count === 0;
            }
        } else {
            this.randomQuestions.forEach((question) => {
                if (!question.value) {
                    question.errors = [translate('provideValue')];
                    count = count + 1;
                }
            });
        }
        return count === 0;
    };

    getRandomQuestionsPayloadObj = () => {
        const questionsPayload = {};
        if (this.randomQuestions?.length) {
            for (let i = 0; i < this.randomQuestions.length; i++) {
                const randomQuestion = this.randomQuestions[i];
                if (randomQuestion) {
                    if (this.secretSeedToken) {
                        const encryptedAnswer = this._utils.encryptString(randomQuestion.value, this.secretSeedToken);
                        questionsPayload[`enteredAnswer${i + 1}`] = encryptedAnswer;
                    } else {
                        const plainAnswer = randomQuestion.value;
                        questionsPayload[`enteredAnswer${i + 1}`] = plainAnswer;
                    }
                    questionsPayload[`randomQuestionId${i + 1}`] = randomQuestion.id;
                }
            }
        }
        return questionsPayload;
    };

    redirectToLoginDrawer = () => {
        if (this.navigateTimeout) {
            clearTimeout(this.navigateTimeout);
        }
        setTimeout(() => {
            this.navigateTologin();
        }, 3000);
    };

    protected getApplicationMiscProps = async () => {
        return new Promise<void>((resolve) => {
            this.ngRxStore.dispatch(ClearApplicationMiscProperties());
            this.ngRxStore.dispatch(GetApplicationMiscProperties());
            this.miscPropsSubscription?.unsubscribe();
            this.miscPropsSubscription = this.ngRxStore
                .select(getApplicationMiscPropertiesState)
                .pipe(
                    takeUntil(this.unsubscribe),
                    filter((data) => data?.['seed-token'] !== undefined)
                )
                .subscribe((response) => {
                    this._commonUtils.setInStorage('mmfaQuestions', response?.['min-mfa-answers']);
                    this.dynamicPassPhrase = response?.['seed-token'];
                    resolve();
                });
        });
    };

    private checkCode = async () => {
        const params = this._activatedRoute.queryParams['value'];
        const stateInLocalStorage = this._commonUtils.getFromStorage('stateId');
        const stateId = params?.state;
        if (stateId === undefined) {
            if (!stateInLocalStorage || stateInLocalStorage === undefined || stateInLocalStorage === 'undefined') {
                this._commonUtils.setInStorage('stateId', this._utils.guid(16));
            }
        }
        this.authCode = params?.auth_code || this._commonUtils.getFromStorage('authCode');
        if (!this.authCode || this.authCode?.length === 0) {
            if (
                !(
                    this.R.onLocalHost ||
                    this._commonUtils.isBrowserIE() ||
                    [
                        'dbcloud',
                        'dbtest',
                        'dbuat',
                        'dbprod',
                        'dbdr',
                        'kpmg2aprod',
                        'kpmgprod',
                        'kpmguat',
                        'adityabirlauat',
                        'adityabirlaprod',
                    ].indexOf(this.R.PLATFORM) > 0 ||
                    this.inLdapMode
                )
            ) {
                this._router.navigate(['']);
                return;
            }
        }
        this._commonUtils.setInStorage('authCode', this.authCode);
        const password = this._commonUtils.getFromStorage('password', true);
        const userId = this._commonUtils.getFromStorage('userId', true);
        this.login = {
            password: password && atob(password),
            userId: userId && atob(userId),
            domain: this._commonUtils.getCookie('domain') || '',
        };
        this._commonUtils.removeFromStorage('userId', true);
        this._commonUtils.removeFromStorage('password', true);
        const session = await this._loginService.checkSession();
        if (session) {
            if (this.authCode?.length > 0) {
            } else {
                this._loginService.redirect();
                return;
            }
        }
        if (this.login.userId?.length > 0 && this.login.password?.length > 0) {
            this.submitDetails();
            return;
        }
        this.hideLoginForm = false;
    };

    getGoogleAuthenticationDetails = () => {};

    private initiateSubscriptions = () => {
        this.ngRxStore
            .select(getClientDetailsState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((response) => {
                this._commonUtils.setCookie('domain', response?.originKey);
                this._commonUtils.setInStorage('clientSecret', response?.config?.relyingPartySecret);
                this._commonUtils.setInStorage('clientId', response?.config?.relyingPartyId);
                this._commonUtils.setInStorage('authenticationURL', response?.config?.authUrl || response?.config?.endPointUrl);
                this._commonUtils.setInStorage('tokenURL', response?.config?.tokenUrl);
                this._commonUtils.setInStorage('keyURL', response?.config?.tokenKeyUrl);
                this._commonUtils.setInStorage('authenticationType', response?.idpType);
                this._commonUtils.setInStorage('authenticationId', response?.id);
                this._commonUtils.setInStorage('userIdIdentifier', response?.config?.uniqueIdentifier);
                this.authorizeExternalIP(response);
            });
        this.ngRxStore
            .select(getClientDetailsFailedState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                if (!res || res?.msg || res?.text) {
                    this._commonUtils.deleteCookie('domain');
                    this.hideLoginForm = false;
                    if (res?.msg) {
                        this._utils.alertError(res?.msg);
                    }
                } else {
                    this._router.navigate(['auth', 'error']);
                }
            });
        this.actions$.pipe(ofType(LdapLoginSuccess), takeUntil(this.unsubscribe)).subscribe((data) => {
            this._router.navigate(['auth', 'validate']);
        });
        this.actions$.pipe(ofType(LdapLoginFailed), takeUntil(this.unsubscribe)).subscribe((data) => {
            this._utils.alertError(data?.msg);
        });
        this.ngRxStore
            .select(fetchCaptchaSuccessState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe({
                next: (res) => {
                    this.forgotPswdCaptchaUrl = res?.forgotPswd && res?.captcha;
                    this.forgotUsernameCaptchaUrl = res?.forgotUsername && res?.captcha;
                },
            });
    };

    ngOnInit(): void {
        this.initiateSubscriptions();
        this.checkCode();
        this._broadcaster
            .on('secretSeedToken')
            .pipe(
                takeUntil(this.unsubscribe),
                filter((token: string) => token !== undefined)
            )
            .subscribe({
                next: (token: string) => (this.secretSeedToken = token ?? undefined),
                error: (err) => console.error(err),
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.miscPropsSubscription?.unsubscribe();
    }
}
