import { Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PaginationInterface } from '../../interface/pagination.interface';
import { RecordsPageData } from '../../interface/recordspagedata.interface';
import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { ViewRecordPagination } from '../../interface/viewrecordpagination.interface';
import { ApiService } from '../../services/api/api.service';
import { CommonNotificationsService } from '../../services/commonnotifications/common-notifications.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-common-notifications',
    templateUrl: './common-notifications.component.html',
    styleUrls: ['./common-notifications.component.scss'],
})
export class CommonNotificationsComponent implements OnInit {
    @Input() notificationsState: BehaviorSubject<{
        eventTypesList: { id: string; displayName: string }[];
        resourceId: string;
        recordDate: any;
        size: number;
    }>;
    searchMonth: any;
    searchYear: any;

    formData = {
        eventType: '',
        currentMonth: '',
        currentYear: '',
    };
    activityYears = [];
    activityMonths = [];
    eventData = [];
    yearMonth = '';
    allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([]);
    selectedColumns: string[] = [];
    tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    tableColumns = [
        {
            name: 'displayEventType',
            displayName: translate('Event Type') as string,
            show: true,
        },
        {
            name: 'displayDetail',
            displayName: translate('Details') as string,
            show: true,
        },
        {
            name: 'createdOn',
            displayName: translate('Created Time') as string,
            show: true,
        },
    ];
    pagination: BehaviorSubject<ViewRecordPagination> = new BehaviorSubject(undefined);
    pages: BehaviorSubject<RecordsPageData[]> = new BehaviorSubject([]);
    paginationData: BehaviorSubject<PaginationInterface> = new BehaviorSubject({
        count: 0,
        page: 0,
        pageIndex: 0,
    });
    unSubscribe = new Subject<void>();
    resourceId: string;
    splittingDateTime: any;
    eventTypesList: { id: string; displayName: string }[] = [];
    sourcePage: any;
    allAssetsName: string;

    constructor(public _utils: UtilsService, private _notifications: CommonNotificationsService, private _api: ApiService) {}

    initiateComponent = () => {
        this.formData = {
            currentMonth: '',
            currentYear: '',
            eventType: '',
        };
    };

    loadEventsDetails = (data: {
        eventTypesList: { id: string; displayName: string }[];
        resourceId: string;
        size: number;
        recordDate?: any;
        sourcePage?: any;
    }) => {
        this.resourceId = data.resourceId;
        if (data.recordDate) {
            this.splittingDateTime = data.recordDate;
        }
        this.eventTypesList = data.eventTypesList;
        this.sourcePage = data.sourcePage;
        const pagination: ViewRecordPagination = {
            currentPageIndex: 0,
            lowerLimit: 0,
            pageSize: 20,
            upperLimit: 20,
            viewIndex: undefined,
            searchCriteria: undefined,
        };
        this.pagination.next(pagination);
    };

    getDropdownDetails = (pagination?) => {
        let cdate;
        let cmonth;
        let cYear;
        this.activityYears = this._notifications.activityYears();
        this.activityMonths = this._notifications.activityMonths;
        if (!this.hasFormData()) {
            if (this.splittingDateTime) {
                cdate = this.splittingDateTime;
                cmonth = cdate.split(' ')[1];
                cYear = parseInt(cdate.split(' ')[2], undefined);
                this.getCurrentMonth(this.activityMonths, cmonth);
                this.getCurrentYear(this.activityYears, cYear);
            } else {
                cdate = new Date();
                cmonth = cdate.getMonth();
                cYear = cdate.getFullYear();
                this.formData.currentMonth = cmonth + 1;
                this.formData.currentYear = cYear;
            }
        }

        this.yearMonth = this.formData.currentMonth + '/' + this.formData.currentYear;
        this.getAllEvents(this.formData, pagination);
    };

    hasFormData = () => {
        if (this.formData.currentMonth != '' && this.formData.currentYear != '') {
            return true;
        }
        return false;
    };
    getCurrentMonth = (months, cmnth) => {
        months.forEach((key, value) => {
            if (key.name === cmnth) {
                this.formData.currentMonth = key.id;
            }
        });
    };

    getCurrentYear = (years, cyr) => {
        years.forEach((key, value) => {
            if (key.displayName === cyr) {
                this.formData.currentYear = key.id;
            }
        });
    };

    getAllEvents = (eventDetails, paginationData?: ViewRecordPagination) => {
        if (paginationData === undefined) {
            const pagination: ViewRecordPagination = {
                currentPageIndex: 0,
                lowerLimit: 0,
                pageSize: 20,
                upperLimit: 20,
                viewIndex: undefined,
                searchCriteria: undefined,
            };
            paginationData = pagination;
        }
        const pageData = this.pages.value && this.pages.value.find((page) => page.pageIndex === paginationData.currentPageIndex - 1);
        this.yearMonth = eventDetails.currentMonth + '/' + eventDetails.currentYear;
        const obj_to_send = {
            eventType: eventDetails !== undefined ? eventDetails.eventType : '',
            resourceId: this.resourceId,
            fetchSize: paginationData.pageSize,
            monthYear: this.yearMonth.toString(),
            pagingState: pageData && pageData.pagingState,
        };
        if (obj_to_send['eventType'] === '') {
            delete obj_to_send['eventType'];
        }
        this._api.common.getAllEvents(
            { payload: obj_to_send },
            {
                successCallback: async (res) => {
                    const tableData = await this.updateData(res['event-logs']['eventLogs']);
                    this.convertTimeAndAppend(tableData);
                    const page: RecordsPageData = {
                        pageIndex: paginationData.currentPageIndex,
                        pagingState: res['event-logs'].pagingState || undefined,
                        viewIndex: undefined,
                    };
                    const pages = this.pages.value || [];
                    const previousIndex = pages.findIndex((pageRecord) => pageRecord.pageIndex === paginationData.currentPageIndex);
                    if (previousIndex === -1) {
                        pages.push(page);
                    } else {
                        pages.splice(previousIndex, 1, page);
                    }
                    this.pages.next(pages);
                },
                failureCallback: (res) => {
                    this._utils.alertError((res && res.msg) || this.translateMsg('Failed to get notifications'));
                },
            }
        );
    };

    updateData = async (tableData) => {
        const eType = this.sourcePage === 'masters' ? 'masterEvents' : null;
        const promises = [];
        tableData.forEach(async (event) => {
            promises.push(this.getEventDetails(event, eType));
        });
        await Promise.all(promises);
        return tableData;
    };

    getEventDetails = async (event, eType) => {
        event['displayEventType'] = await this._notifications.getEventDetails(event, 'type');
        event['displayDetail'] = await this._notifications.getEventDetails(event, eType);
        if (event.eventType === 'ORG_UPDATED') {
            event.eventType = 'Org Details Updated';
        }
    };

    convertTimeAndAppend = (tableData) => {
        tableData.filter((obj) => {
            if (obj && obj.createdOn) {
                obj.createdOn = this._utils.transformDateToLocale(obj.createdOn, 'YYYY-MM-DDTHH:MM:SSZ', 'ddMMMyyyy HH:MM:SS AM', false);
            }
        });
        this.eventData = tableData;
        this.createTableColumns();
    };

    createTableColumns = () => {
        const columns: TableColumn[] = [];
        this.selectedColumns = [];
        this.tableColumns &&
            this.tableColumns.forEach((column) => {
                columns.push({
                    icon: undefined,
                    id: column.name,
                    name: column.displayName,
                    options: undefined,
                    type: 'text',
                    hide: !column.show,
                });
            });
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].hide === false) {
                this.selectedColumns.push(columns[i].id);
            }
        }
        this.allTableColumns.next(columns);
        this.updateTableData(this.eventData);
    };

    updateTableData = (eventData) => {
        const tableData: TableData[] = [];
        eventData &&
            eventData.forEach((record) => {
                const allColumns = this.allTableColumns.value;
                const recordData: TableData = {
                    recordId: record.name,
                };
                allColumns.forEach((column) => {
                    const fieldValue = this.getFieldValue(column.id, this.tableColumns, record);
                    recordData[column.id] = fieldValue;
                });
                tableData.push(recordData);
            });
        this.tableData.next(tableData);
    };

    getFieldValue = (displayValue, Columns, events) => {
        let fieldValue: any;
        for (let i = 0; i < Columns.length; i++) {
            if (displayValue === Columns[i].name) {
                if (displayValue === 'eventType') {
                    fieldValue = this._utils.convertEventTypesToNewNames(this._utils.convertEnumsToNormalWords(events[Columns[i].name]));
                    break;
                } else {
                    fieldValue = events[Columns[i].name];
                    break;
                }
            }
        }
        return fieldValue;
    };

    protected updatePaginationData = (data: ViewRecordPagination) => {
        const eventsData = this.eventData || [];
        const recordsSize = eventsData.length;
        const pages = this.pages.value;
        const page = pages.find((pageRecord) => pageRecord.pageIndex === (data && data.currentPageIndex));
        const previousPaginationData: PaginationInterface = CommonUtilsService.cloneObject(this.paginationData.value);
        let count = 0;
        if (pages && pages.length > 0) {
            if (page && page.pageIndex + 1 < pages.length) {
                count = previousPaginationData.count;
            } else {
                count = recordsSize + ((data && data.currentPageIndex) || 0) * ((data && data.pageSize) || 0);
                if (page && page.pagingState) {
                    count = count + 1;
                }
            }
        }
        const nextPageData = {
            count,
            page: ((data && data.currentPageIndex) || -1) + 1,
            pageIndex: data && data.currentPageIndex,
            size: data && data.pageSize,
            searchAfter: page && page.pagingState,
        };
        this.paginationData.next(nextPageData);
    };

    trackEventType(index: number, eventType: any): any {
        return eventType.id;
    }

    trackActivityYears(index: number, year: any): any {
        return year.id;
    }

    trackActivityMonths(index: number, month: any): any {
        return month.id;
    }

    refreshNotifications = () => {
        this.getAllEvents(this.formData);
        const pagination: ViewRecordPagination = CommonUtilsService.cloneObject(this.pagination.value);
        pagination.pageSize = 20;
        this.pagination.next(pagination);
    };

    refreshItemsWithNewPageSize = (data: number) => {
        const pagination: ViewRecordPagination = CommonUtilsService.cloneObject(this.pagination.value);
        pagination.pageSize = data;
        pagination.currentPageIndex = 0;
        this.pagination.next(pagination);
    };

    fetchItemsInPage = (data: number) => {
        const pagination: ViewRecordPagination = CommonUtilsService.cloneObject(this.pagination.value);
        pagination.currentPageIndex = data;
        this.pagination.next(pagination);
    };

    ngOnInit(): void {
        this.initiateComponent();

        this.pagination
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.getDropdownDetails(data);
            });

        this.pages
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.updatePaginationData(this.pagination.value);
            });
        this.notificationsState
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.initiateComponent();
                this.loadEventsDetails(data);
            });
    }

    translateMsg = (msg: string): string => translate(msg);
}
