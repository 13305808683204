import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[debounce]',
})
export class DebounceDirective implements OnInit {
    @Input() debounce: any;
    @Output('eventHandler') onKeyUpEventHandler = new EventEmitter();

    constructor(public el: ElementRef) {}

    ngOnInit() {
        if (this.el && this.el.nativeElement) {
            observableFromEvent(this.el.nativeElement, 'keyup')
                .pipe(debounceTime(this.debounce))
                .subscribe((value) => {
                    this.onKeyUpEventHandler.emit(value);
                });
        }
    }
}
