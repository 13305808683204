import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subscription } from 'rxjs';

import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'fileUpload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit, OnDestroy {
    @Input() data;
    @Input() clearFiles: BehaviorSubject<any>;
    @Input() supportedFormats: string[] = [];
    @Input() isDisabled = false;
    @Input() color?: string;
    @Input() appendedFileName?: string;

    @Output() newValue = new EventEmitter();
    @Output() fileName = new EventEmitter();
    @Output() isFileTypeSupported? = new EventEmitter();

    id = this._utils.guid();
    files: unknown;
    clearFilesSubscription: Subscription;
    fileHovered: boolean;
    isFileSelected: boolean;

    constructor(private _utils: UtilsService, private fileUpload: FileUpload) {}

    public onInputChange = (event: { target: any }) => {
        const input: HTMLInputElement = event.target;
        this.isFileSelected = input.files.length > 0;
        const files = event.target.files || [];
        const newFiles = this._utils.changeFilesContentType(files);
        if (!newFiles || newFiles.length === 0) {
            return;
        }
        let isSupported = true;
        this.newValue.emit(newFiles);
        for (let i = 0; i < newFiles.length; i++) {
            if (i === 0) {
                this.fileName.emit(newFiles[i].name);
            }
        }
        if (this.supportedFormats?.length) {
            for (let i = 0; i < newFiles.length; i++) {
                const fileExt = newFiles[i].name.substring(newFiles[i].name.lastIndexOf('.'), newFiles[i].name.length).toLowerCase();
                isSupported = isSupported && (this.supportedFormats.indexOf(fileExt) >= 0 || ['.zip'].indexOf(fileExt) > -1);
            }
        }
        this.isFileTypeSupported.emit(isSupported);
        const slug = this.data.url;
        const data = {
            filename: this.data.filename,
        };

        if (!this.data.return) {
            this.fileUpload.upload(
                newFiles,
                data,
                slug,
                (progress: unknown) => console.log(progress),
                () => {
                    this._utils.alertSuccess(this.translateMsg('File has bean uploaded'));
                    setTimeout(() => this._utils.hideLoading(), 3000);
                },
                (res) => {
                    this._utils.alertError(res?.msg || this.translateMsg('Failed to upload file'));
                    this._utils.hideLoading();
                }
            );
        }
    };

    openFileSelection = () => {
        (document.querySelector(`[id='${this.id}']`) as HTMLElement).click();
    };

    onFilesDropEvent = (files: File[]) => {
        this.onInputChange({
            target: {
                files,
            },
        });
    };

    ngOnInit() {
        this.clearFilesSubscription = this.clearFiles?.subscribe(() => {
            this.files = '';
        });
    }

    ngOnDestroy(): void {
        this.clearFilesSubscription && this.clearFilesSubscription.unsubscribe();
    }

    translateMsg = (msg: string): string => translate(msg);
}
