import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { TableRecordAction } from '../../interface/table-record-action.interface';
import { RecordPayload } from '../../models/payload/recordpayload.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { RecordsService } from '../../services/records/records.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-field-level-file-attachment',
    templateUrl: './field-level-file-attachment.component.html',
    styleUrls: ['./field-level-file-attachment.component.scss'],
})
export class FieldLevelFileAttachmentComponent implements OnInit, AfterViewInit {
    field: RecordField;
    record: Record;
    instanceId: string;
    files: FileList;
    fileName: File['name'];
    clearFiles = new BehaviorSubject(0);
    isFileTypeSupported = true;
    isMaxFileSizeAllowed = true;
    maxFileCountReached = false;
    fieldAttachmentData = {
        filename: '',
        url: '',
        title: translate('Browse'),
        return: 'true',
    };
    uploadedFiles: any[];
    allColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([
        {
            name: translate('File Name'),
            type: 'text',
            icon: undefined,
            id: 'fileName',
            hideInAllColumnsList: false,
            hide: false,
            showCheckboxInHeader: false,
        },
        {
            name: translate('Link'),
            type: 'link',
            icon: undefined,
            id: 'url',
            hideInAllColumnsList: false,
            hide: false,
            showCheckboxInHeader: false,
        },
    ]);
    recordActions: TableRecordAction[] = [];
    selectedColumns = ['fileName', 'url'];
    tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    filesListUpdated: BehaviorSubject<any>;
    getNewRecord: BehaviorSubject<boolean>;
    recordUpdateDisabled: boolean;
    inReadOnlyMode: boolean;
    service: any;
    uploading: boolean;
    public recordIds: string[];
    public parentRecordId: string;

    constructor(
        private dialog: MatDialog,
        protected _fileUploadService: FileUpload,
        protected _utils: UtilsService,
        private _records: RecordsService
    ) {}

    public startUpload = () => {
        if (this.uploading) {
            return;
        }
        this.uploading = true;
        const maxUpload = this.field.attachment && this.field.attachment.maxFilesCount;
        const uploadedCount = this.uploadedFiles.length;
        if (maxUpload !== undefined && maxUpload <= uploadedCount) {
            return this._utils.alertError(translate('Maximum file upload count reached'));
        } else if (!this.files || this.files.length === 0) {
            return this._utils.alertError(translate('Select a file to upload'));
        }
        const promises = [];
        const parentRecordId = this.parentRecordId || this.record.parentId || '';
        if (this.recordIds) {
            this.recordIds?.forEach((id) => {
                promises.push(this.uploadFiles(id, parentRecordId));
            });
        } else {
            const recordId = this.record.id ? this.record.id : '';
            promises.push(this.uploadFiles(recordId, parentRecordId));
        }
        Promise.all(promises).then((recordsResponse) => {
            this._utils.hideLoading();
            const response = recordsResponse[0];
            if (!this.record.id) {
                this._utils.alertSuccess(translate('Record created. File has been uploaded'));
                this.pushValuesToRecordField(response && response.records);
                this.getNewRecord?.next(true);
            } else {
                this._utils.alertSuccess(translate('File has been uploaded'));
                this.pushValuesToRecordField(response && response.records);
            }
            this.files = undefined;
            this.fileName = '';
            this.uploading = false;
        });
    };

    private uploadFiles = (recordId: string, parentRecordId: string) => {
        return new Promise((resolve, reject) => {
            const url = `${environment.taxilla_api}/asset/attachments?instanceId=${this.instanceId}&entityRecordId=${encodeURIComponent(
                recordId
            )}&parentEntityRecordId=${encodeURIComponent(parentRecordId)}`;
            const service = this.service;
            this.record.id = recordId;
            this.record.assetDataId = this.instanceId;
            const recordpayload = new RecordPayload(this.record, true);
            recordpayload.fields = recordpayload.fields.filter((field) => field.id === this.field.id);
            const fieldToPush = recordpayload.fields.find((field) => field.id === this.field.id);
            if (!fieldToPush) {
                recordpayload.fields.push({ id: this.field.id, value: this.field.value });
            } else {
                fieldToPush.value = fieldToPush.value || [];
            }
            this._fileUploadService.upload(
                this.files,
                {
                    filename: 'file',
                    entityRecordInfoVO: this._utils.convertJSONtoBlob(recordpayload),
                    attachmentDetail: this._utils.convertJSONtoBlob({
                        assetId: service.assetMetaUId,
                        entityId: this.field.entityUid,
                        fieldId: this.field.id,
                        fileName: this.fileName,
                    }),
                },
                url,
                undefined,
                (response) => {
                    resolve(response);
                },
                (response) => {
                    this._utils.hideLoading();
                    this._utils.alertError((response && response.msg) || translate('Failed to upload the file'));
                    reject(response);
                }
            );
        });
    };

    onNewValue = (data) => {
        this.files = data;
        if (this.files && this.field && this.field.attachment) {
            if (this.files[0].size > this.field.attachment.maxSize * 1024 * 1024) {
                this.isMaxFileSizeAllowed = false;
            } else {
                this.isMaxFileSizeAllowed = true;
            }
        }
    };

    onFileTypeSupportCheck = (data: boolean) => {
        this.isFileTypeSupported = data;
    };

    pushValuesToRecordField = ({ fileUris, recordId }: { fileUris: string[]; recordId: string }) => {
        if (!this.record.id) {
            this.record.id = recordId;
        }
        this.field.value = fileUris;
        this.field.updateFiles();
        this.generateUploadedFiles();
    };

    closeModal = () => {
        this.dialog.closeAll();
    };

    generateUploadedFiles = () => {
        this.recordActions.splice(0);
        !this.inReadOnlyMode &&
            !this.recordUpdateDisabled &&
            this.recordActions.push({
                displayName: translate('Delete'),
                iconName: 'delete',
                onActionTrigger: this.deleteFile,
            });
        this.uploadedFiles = [];
        this.field &&
            this.field.value &&
            Array.isArray(this.field.value) &&
            this.field.value.forEach((uploadedFileRecordUri) => {
                const name = uploadedFileRecordUri.substring(uploadedFileRecordUri.lastIndexOf('/') + 1, uploadedFileRecordUri.length);
                this.uploadedFiles.push({
                    name,
                    fileUrl: environment.taxilla_api + '/asset/attachments?fileUri=' + uploadedFileRecordUri,
                    originalUrl: uploadedFileRecordUri,
                });
            });
        if (this.field && this.field.attachment && this.field.attachment.maxFilesCount) {
            if (this.uploadedFiles.length >= this.field.attachment.maxFilesCount) {
                this.maxFileCountReached = true;
            } else {
                this.maxFileCountReached = false;
            }
        }
        this.transformData();
    };

    deleteFile = (file: { recordId: string; fileName: string; url: string; originalUrl: string }, index: number) => {
        this._records.deleteFieldAttachment(
            {
                assetDataId: this.instanceId,
                assetId: this.service.assetMetaUId || this.service.id,
                entityId: this.field.entityUid,
                fieldId: this.field.id,
                fileUri: file.originalUrl,
                parentRecordId: this.parentRecordId,
                recordId: this.record.id,
            },
            {
                successCallback: (response) => {
                    (this.field.value as string[]).splice(index, 1);
                    this.pushValuesToRecordField({
                        fileUris: this.field.value as any,
                        recordId: this.record.id,
                    });
                    // this.generateUploadedFiles();
                    // this.filesListUpdated && this.filesListUpdated.next(this.field.value);
                },
                failureCallback: (response) => {
                    this._utils.alertError(response?.msg || translate('Failed to delete attachment'));
                },
            }
        );
    };

    transformData = () => {
        const tableData: TableData[] = [];
        this.uploadedFiles.forEach((file, index) => {
            tableData.push({
                recordId: index.toString(),
                fileName: file.name,
                url: file.fileUrl,
                originalUrl: file.originalUrl,
            });
        });
        this.tableData.next(tableData);
        if (this.tableData?.value?.length === this.field?.attachment?.maxFilesCount) {
            this.maxFileCountReached = true;
        }
    };

    ngOnInit() {}

    ngAfterViewInit(): void {}
}
