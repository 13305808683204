import { PermissionsObject } from '../../interface/permissions.interface';

export const permissionsState: PermissionsState = {
    organizationsMap: {},
};

export interface PermissionsState {
    organizationsMap: {
        // organizationId vs permissions map
        [property: string]: {
            sessionUserPermissions: PermissionsObject;

            //all role permissions
            allRolePermissions: any;

            // encomply settings role permissions
            rolePermissions: {
                // roleid vs permissions
                [property: string]: any;
            };

            // bundle role permissions
            bundlesMap: {
                // bundleId vs details
                [property: string]: {
                    bundlePermissions: any;
                    bundleRolePermissions: {
                        // roleId vs Permissions
                        [property: string]: string[];
                    };
                };
            };
        };
    };
}
