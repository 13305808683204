<ng-container *ngIf="selectedColumns?.length">
    <div class="mat-elevation-z8 matTableContainer" *transloco="let translate">
        <ng-container *ngIf='canShowTableData'>
            <table aria-describedby="encomply table" #encomplyTable mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 pAdjust" matSort [trackBy]="trackTableRecordData" cdkDropList
                   (cdkDropListDropped)="dropRow($event)">
                <ng-container matColumnDef="{{column}}" [sticky]="i < 1" [stickyEnd]="i > selectedColumns.length -3" *ngFor="let column of selectedColumns; let i = index;">
                    <ng-container *ngIf="column !== 'drag' && column !== 'log' && column !== 'checkbox' && (column !== 'Active' && column !== 'active' && column !== 'نشط') && column !== 'enabled' && (column !== 'Table Actions' && column !== 'الإجراءات') && !(columnsData[column]?.hasInput) && !(columnsData[column]?.hasCheckbox) && column !== 'fileName' && column !== 'checkSum'">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                            <div mat-sort-header *ngIf="!translationEnabled && headerNamesToBeShown" title="{{column | pascalToCamel:headerNamesToBeShown}}">
                                {{column | pascalToCamel:headerNamesToBeShown}}
                            </div>
                            <div mat-sort-header *ngIf="translationEnabled || !headerNamesToBeShown" title="{{translate(column | pascalToCamel:headerNamesToBeShown)}}">
                                {{translate(column | pascalToCamel:headerNamesToBeShown)}}
                            </div>
                            <div *ngIf='columnSearchRequired' class="columnSearchRequiredClass positionRelative uniqueSearch">
                                <mat-form-field>
                                    <input matInput autocomplete="off" [placeholder]="translate('Search')" [formControl]="searchColumnControl" floatLabel="never" debounce="500" (eventHandler)="searchInputModelChange(column)" />
                                    <button mat-button matSuffix mat-icon-button aria-label="Search">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div *ngIf='columnselectSearchRequired' class="tableColumnSelectSearch">
                                <ng-container *ngIf='column === "OrgName"'>
                                    <mat-form-field appRemoveAriaOwns>
                                        <mat-label>{{translate('Select')}}</mat-label>
                                        <mat-select #selectedOrgMultipleList (keydown)="$event.stopPropagation()" multiple class="tableColumnSelect" [formControl]="selectedOrgs" panelClass="subsctiptionSelectPanel" (openedChange)="setTransformProperty()">
                                            <mat-option class="optionWithCheckBox matOptionWithCrossIcon">
                                                <material-button class='noOutline rejectColor clearSelectedListBtn' [label]="translate('Clear')" color="primary" (click)='deselectAllOrg();$event.stopPropagation()' *ngIf='isSelectOrgPresent()'></material-button>
                                                <input class="inputInsideSelectInput" [ngClass]="{'inputWithClearBtn': isSelectOrgPresent()}" matInput [placeholder]="translate('Search')" [(ngModel)]="searchOrgString" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                                <button *ngIf="searchOrgString.length > 0" class='inputInsideSelectInputIcon' mat-button matSuffix mat-icon-button aria-label="Clear" (click)='$event.stopPropagation();searchOrgString ="";' (keydown)="$event.stopPropagation()">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-option>
                                            <ng-container *ngIf="(allOrg | FilterPipe: 'name' : searchOrgString : null : 'orgLoginId') as result">
                                                <label class="noSearchResults" *ngIf="!result.length">{{translate('No organizations found')}}</label>
                                                <cdk-virtual-scroll-viewport itemSize="20" style="height: 212px;">
                                                    <mat-option *cdkVirtualFor="let org of result; trackBy: allOrgTrackBy" [value]="org.id" (click)="organizationSelected(org, $event , 'organization')" title="{{org.name}} ({{org.orgLoginId}})">
                                                        {{org.name}} ({{org.orgLoginId}})
                                                    </mat-option>
                                                </cdk-virtual-scroll-viewport>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngIf="column === 'serviceName' || column === 'displayName'">
                                    <mat-form-field appRemoveAriaOwns>
                                        <mat-label>{{translate('Select')}}</mat-label>
                                        <mat-select #selectedOrgAssetMultipleList (keydown)="$event.stopPropagation()" multiple [formControl]="selectedAsset" panelClass="subsctiptionSelectPanel" (openedChange)="setTransformProperty()">
                                            <mat-option class="optionWithCheckBox matOptionWithCrossIcon">
                                                <material-button *ngIf='isSelectOrgAssetPresent()' class='noOutline rejectColor clearSelectedListBtn' [label]="translate('Clear')" color="primary" (click)='deselectAllOrgAssets();$event.stopPropagation()'></material-button>
                                                <input class="inputInsideSelectInput" [ngClass]="{'inputWithClearBtn': isSelectOrgAssetPresent()}" matInput [placeholder]="translate('Search')" [(ngModel)]="searchAssetOrgString" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                                <button *ngIf="searchAssetOrgString.length > 0" class='inputInsideSelectInputIcon' mat-button matSuffix mat-icon-button aria-label="Clear" (click)='$event.stopPropagation();searchAssetOrgString ="";' (keydown)="$event.stopPropagation()">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-option>
                                            <ng-container *ngIf="(allOrgAssets | FilterPipe : 'displayName': searchAssetOrgString: null: 'itemName') as result">
                                                <label class="noSearchResults" *ngIf="!result.length">{{translate('No apps found')}}</label>
                                                <cdk-virtual-scroll-viewport itemSize="20" style="height: 212px;">
                                                    <mat-option *cdkVirtualFor="let app of result; trackBy: allOrgTrackBy" [value]="app.id" (click)="organizationSelected(app, $event, 'asset')">
                                                        {{app.displayName || app.itemName}}
                                                    </mat-option>
                                                </cdk-virtual-scroll-viewport>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </th>
                        <ng-container *ngIf='columnsData[column]?.isDataCapturePresent'>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <ng-container *ngIf='rowsData[element.id][column].isDataCapturePresent'>
                                    <mat-icon [title]="translate('Add Entity')" (click)="addEntity(element)">add_circle</mat-icon>
                                    <span *ngFor="let candidateEntity of element['associatedEntities']">
                                        <div>
                                            {{candidateEntity}}
                                        </div>
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngIf='columnsData[column]?.isPassword'>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <span *ngIf="element[column]">*****</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf='column === "status"'>
                            <td mat-cell *matCellDef="let element" class="matTableCell" [ngClass]="{'widgetStatusApprove': element[column] === 'APPROVED', 'widgetStatusPending': element[column] === 'PENDING', 'widgetStatusDeactivate': element[column] === 'DEACTIVATED'}">{{element[column].charAt(0).toUpperCase() + element[column].slice(1).toLowerCase()}}</td>
                        </ng-container>
                        <ng-container *ngIf='columnsData[column] && columnsData[column].isReportsPresent'>
                            <td mat-cell *matCellDef="let element" class="matTableCell">
                                <ng-container *ngIf='rowsData[element.id][column] && rowsData[element.id][column].isReportsPresent'>
                                    <mat-icon [title]="translate('Manage Report')" (click)="addReport(element)">add_circle
                                    </mat-icon>
                                    <ul *ngFor="let candidateEntity of element['associatedReports']" class='addReportUl'>
                                        <li *ngIf="candidateEntity.split('/')[1] !== undefined">
                                            {{candidateEntity.split('/')[1]}}
                                        </li>
                                        <li *ngIf="candidateEntity.split('/')[1] === undefined">{{candidateEntity}}</li>
                                    </ul>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngIf='!columnsData[column]?.isDataCapturePresent && !columnsData[column]?.isReportsPresent && !columnsData[column]?.isPassword'>
                            <td mat-cell *matCellDef="let element" class="matTableCell" [ngClass]='wordWrap ? "tableWrap" : "tabelCellData"' (click)="copyElementId(element)">
                                <span [ngClass]='wordWrap ? "tableWrap" : "tabelCellData"' [title]='addSpaceToTitle(element[column])' [innerHTML]="element[column]">{{element[column]}}</span>
                            </td>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="inputInTableColumns && columnsData[column] && columnsData[column].hasInput">
                        <th scope="col" mat-sort-header mat-header-cell *matHeaderCellDef class="matTableHeader">
                            {{column | pascalToCamel}}
                        </th>
                        <td mat-cell *matCellDef="let element" class="matTableCell">
                            <span class="tableInputClass">
                                <material-input [autofocus]="false" [model]='element[column]' (modelChange)="element[column] = $event">
                                </material-input>
                            </span>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="dragEnabled && column === 'drag'">
                        <th class="w-[5%]" scope="col" mat-header-cell *matHeaderCellDef>
                            <mat-icon title="Reorder Rows">reorder</mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="dragConditionProperty !== undefined && element[dragConditionProperty]">reorder</mat-icon>
                        </td>
                    </ng-container>
                    <!-- FOR COLUMNS WITH CHECKBOX -->
                    <ng-container *ngIf="columnsData[column] && columnsData[column].hasCheckbox">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="columnsWithCheckbox">
                            <mat-checkbox (change)="$event ? customMasterToggle($event.checked, column) : null" [checked]="customerMasterCheck(column)">
                            </mat-checkbox> {{column}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? changeCheckedValue(element,column) : null" [checked]="isTheValueChecked(element,column)" [disabled]='checkForDisableValue(element, column)' *ngIf="canRenderCheckbox(element, column)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column === 'checkbox'">
                        <th scope="col" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox matBadge="{{selectedRecordsCount > 0 ? selectedRecordsCount : null}}" matBadgeOverlap="false" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? onCheckboxSelection(element) : null" [checked]="selection.isSelected(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column === 'Active' ||column === 'active' || column === 'نشط' || column === 'enabled'">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                            <div>
                                {{translate('Active')}}
                            </div>
                            <ng-container *ngIf='columnSearchRequired'>
                                <div class="searchHiddenDiv">

                                </div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element" class="matTableCell" (click)="$event.stopPropagation()">
                            <material-slidetoggle [model]="element[column]" (changeToggle)="changeStatus(element,$event?.inputSource)" [dontChangeImmediately]="dontChangeImmediately" [disabled]="adminUserId && element['User ID'] === adminUserId">
                            </material-slidetoggle>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column === 'fileName'">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader"> {{column | pascalToCamel:headerNamesToBeShown}}</th>
                        <td mat-cell *matCellDef="let element" [title]="element.fileName" class="matTableCell" (click)="$event.stopPropagation()">
                            <span class="accordian_body_text" (click)="downloadFile(element)" [title]="element.fileName">{{element.fileName}}</span>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column === 'checkSum'">
                        <th scope="col" *matHeaderCellDef class="matTableHeader">{{translate('Check Sum')}}</th>
                        <td mat-cell *matCellDef="let element" class="matTableCell" (click)="$event.stopPropagation()">
                            <mat-form-field class="checkSum">
                                <input matInput [placeholder]="translate('Check Sum')" [(ngModel)]="element.checkSum" [value]="element.checkSum">
                            </mat-form-field>
                            <mat-icon class="checkSumIcon" [title]="translate('Copy to clipboard')" (click)="copyCheckSum(element)">file_copy</mat-icon>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column !== 'recordId' && actionColumns.indexOf(column) === (-1)">
                        <th scope="col" mat-sort-header mat-header-cell *matHeaderCellDef class="matTableHeader">
                            {{column | pascalToCamel}}
                        </th>
                        <td mat-cell *matCellDef="let element" class="matTableCell" [ngClass]='wordWrap ? "tableWrap" : "tabelCellData"'><span [ngClass]='wordWrap ? "tableWrap" : "tabelCellData"'>{{element[column]}}</span></td>
                    </ng-container>
                    <ng-container *ngIf="(column === 'Table Actions' || column === 'الإجراءات') && noAction === false">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                            <div>
                                {{translate('Actions')}}
                            </div>
                            <ng-container *ngIf='columnSearchRequired'>
                                <div class="searchHiddenDiv">

                                </div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element" class="matTableCell">
                            <span class="material-icons logIcon" [matMenuTriggerFor]="actionsMenu" [title]="translate('Actions Menu')">more_horiz</span>
                            <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false" [class]="'tableLogMenu'" xPosition="after">
                                <ng-container *ngFor="let action of recordActions">
                                    <ng-container *ngIf="checkRecordActions(action, element)">
                                        <button mat-menu-item (click)="action.onActionTrigger(element,drawer)">
                                            <span class="material-icons matTableActionIcon" *ngIf="action.iconName !== undefined">
                                                {{action.iconName}}
                                            </span>
                                            {{action.displayName}}
                                        </button>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!recordActions || recordActions.length === 0">
                                    <button mat-menu-item>{{translate('No Actions Found')}}</button>
                                </ng-container>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column === 'checkbox'">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader"></th>
                        <td mat-cell *matCellDef="let element" class="matTableCell" (click)="$event.stopPropagation()">
                            <mat-checkbox [(ngModel)]="element.selected" (change)="checkMasterRecord.emit(element)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="column === 'log'">
                        <th scope="col" mat-header-cell *matHeaderCellDef class="matTableHeader">
                            <span class="material-icons logIcon" [matMenuTriggerFor]="logMenu" [title]="translate('Filter Columns')">menu</span>
                            <mat-menu #logMenu="matMenu" [overlapTrigger]="false" [class]="'tableLogMenu'" xPosition="after" yPosition="below">
                                <div class="logMenu" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                    <div class="logFilter" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                        <mat-form-field>
                                            <input matInput class="filterInput" autocomplete="off" [placeholder]="translate('Filter Table Columns')" [(ngModel)]="searchColumns" />
                                            <button mat-button *ngIf="searchColumns" matSuffix mat-icon-button aria-label="Clear" (click)="searchColumns = ''">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex justify-content-around align-items-center checkStyle" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                            <mat-checkbox class="selectAllBtn" [(ngModel)]="selectAll" (ngModelChange)='selectAllFilters(selectAll)' id="selectAllLabel">{{translate('Select All')}}
                                            </mat-checkbox>
                                        </div>
                                        <material-button [disabled]="clonedColumns.length === 0" [label]="translate('Apply')" (onTrigger)="cloneSubmit()">
                                        </material-button>
                                    </div>
                                    <div class="logListItems" *ngFor="let column of allColumns; let i = index;" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                                        <ng-container *ngIf="(!searchColumns || searchColumns.length === 0 || (searchColumns.length > 0 && searchColumn(column))) && actionColumns.indexOf(column) === (-1) && !(hideTableColumns && hideTableColumns.indexOf(column) > (-1))">
                                            <div class="d-flex align-items-center tempList">
                                                <div class="actionCheckBox mCheckbox">
                                                    <mat-checkbox [checked]="clonedColumns.indexOf(column) > (-1)" (change)="checkboxSelect($event.checked, column)">
                                                        {{translate(column | pascalToCamel:headerNamesToBeShown)}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-menu>
                            <ng-container *ngIf='columnSearchRequired'>
                                <div class="searchHiddenDiv">

                                </div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element" class="matTableCell">{{column && element[column]}}</td>
                    </ng-container>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="selectedColumns.length">
                        <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="loading">
                    <td mat-footer-cell class="matTableMessages" *matFooterCellDef colspan="100%">{{translate('Loading Data...')}} </td>
                </ng-container>
                <ng-container matColumnDef="noData">
                    <td mat-footer-cell class="matTableMessages" *matFooterCellDef colspan="100%">
                        {{noRecordMsg || translate('No Records Found')}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="noRecords">
                    <td mat-footer-cell class="matTableMessages noRecordMessage" *matFooterCellDef colspan="100%"> {{translate('No Records Found')}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="selectedColumns; sticky: true" [ngClass]="{'tableHeaderPadding': columnSearchRequired}"></tr>
                <tr mat-row *matRowDef="let element; columns: selectedColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" [class.matTableError]="element.errorsCount && element.errorsCount > 0" [class.matTableWarning]="element.warningsCount && element.warningsCount > 0" [class.selectedRow]="selectedRecord?.id === element?.id" [class.rowCanDraggable]="dragEnabled && (dragConditionProperty !== undefined && element[dragConditionProperty])" (click)="onSingleClick(element)" (dblclick)="onDoubleClick(element)" cdkDrag [cdkDragDisabled]="!dragEnabled || !(dragConditionProperty !== undefined && element[dragConditionProperty])"> </tr>
                <div *cdkDragPlaceholder></div>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <tr mat-footer-row *matFooterRowDef='["loading"]' [ngClass]="{'taxHide': dataSource!=null}"></tr>
                <tr mat-footer-row *matFooterRowDef='["noData"]' [ngClass]="{'taxHide': !(dataSource!=null && dataSource.data.length === 0) || noRecordsFound}"></tr>
                <tr mat-footer-row *matFooterRowDef='["noRecords"]' [ngClass]="{'taxHide': !noRecordsFound}"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf='showingLoadingData'>
            <table aria-describedby="encomply table" mat-table [dataSource]="dataSourceLoading" class="mat-elevation-z8">
                <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumnsWhileLoading; let i = index;">
                    <th scope="col" mat-header-cell *matHeaderCellDef class="paddingleft15px"> <span class="loaderBlock isLoading">{{column}}</span></th>
                    <td mat-cell *matCellDef="let element" class="paddingleft15px"> <span class="loaderBlock isLoading">{{element[column]}}</span></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWhileLoading"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplayWhileLoading;"></tr>
            </table>
        </ng-container>
    </div>
    <ng-container *ngIf="hidePaginator !== true && pagination && (data.length > 0 || pagination?.count > 0 || pagination.pageIndex > 0)">
        <encomply-material-paginator [pagination]="pagination" [showFirstLastButtons]="showFirstLastButtons" (fetchItemsInPage)="fetchItemsInPage.emit($event)" (refreshItemsWithNewPageSize)="refreshItemsWithNewPageSize.emit($event)">
        </encomply-material-paginator>
    </ng-container>
    <ng-container *ngIf="defaultPaginator">
        <mat-paginator class="sticky" [hidden]="data.length === 0 && pagination?.pageIndex === 0" #defaultMatPaginator [pageSizeOptions]="[5, 10, 20]" [length]="data.length" [pageSize]='10' showFirstLastButtons="true"></mat-paginator>
    </ng-container>
    <ng-container *ngIf="(data.length > 0 || pagination?.count > 0 || pagination?.pageIndex > 0) && newCustomPaginator">
        <div class="newCustomPagination" *transloco="let translate">
            <ul class="newCustomPaginationUl">
                <li class="newCustomPaginationLi newCustomPaginationPageIndex">
                    <mat-form-field appRemoveAriaOwns>
                        <mat-label>{{translate('Page Index')}}</mat-label>
                        <mat-select [(value)]="pageIndexSelected" [disabled]='customPageIndex.length < 2'>
                            <mat-option *ngFor="let index of customPageIndex" [value]='index' (click)="fetchItemsInPageWithPageIndex.emit(index)">{{index}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
                <li class="newCustomPaginationLi newCustomPaginationPageIndex">
                    <mat-form-field appRemoveAriaOwns>
                        <mat-label>{{translate('Page Size')}}</mat-label>
                        <mat-select [(value)]="sizeSelected">
                            <mat-option *ngFor="let size of customSize" [value]='size' (click)="refreshItemsWithNewPageSize.emit(size)">{{size}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </li>
                <li class="newCustomPaginationLi newCustomPaginationArrows">
                    <mat-icon class="leftArrow" [ngClass]="{'disable': disablePrevious}" title="{{translate('previous')}}" (click)="getPreviousRecords()">chevron_left</mat-icon>
                    <mat-icon class="rightArrow" title="{{translate('next')}}" [ngClass]="{'disable': disableNext}" (click)="getNextRecords()">chevron_right</mat-icon>
                </li>
            </ul>
        </div>
    </ng-container>
</ng-container>
