import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Search } from 'taxilla-library';

@Component({
    selector: 'app-custom-search',
    templateUrl: './custom-search.component.html',
    styleUrls: ['./custom-search.component.scss'],
})
export class CustomSearchComponent {
    @Input() public search: Search;
    @Input() public searchType: 'SEARCH' | 'RELATION';
    @Input() public saveFilterName: string;
    @Input() public checkMultiple: boolean;
    @Input() public selectedOutlookFilter: any;
    @Input() public selectedTab: number;
    @Input() public relationClicked: boolean;
    @Input() public isRelationConfig: boolean;
    @Input() public isCustomSearchDownloadReport: boolean;
    @Input() public selectedDefaultFilter: string;
    @Input() public reportMetadata: any[];
    @Input() public bulkTransformationsList: string[];
    @Input() public downloadReportTransformations: any[];
    @Input() public origin: string;

    @Output() public selectedTabChange = new EventEmitter();
    @Output() public fetchReportTransformations = new EventEmitter();
    @Output() public checkCompleteInstanceReport = new EventEmitter();
    @Output() public bulkTransformationsListChange = new EventEmitter();
    @Output() public selectedReportTransformationChange = new EventEmitter();
    @Output() public downloadErrorRepositoryIdChange = new EventEmitter();
    @Output() public createDownloadBulkRequest = new EventEmitter();
    @Output() public initiateDownloadReport = new EventEmitter();
    @Output() public saveFilterNameChange = new EventEmitter();
    @Output() public saveAndSearchWithFilter = new EventEmitter();

    public isCompleteInstanceReport: boolean;
    public selectedReportTransformation: any;

    constructor() {}

    public trackByDownloadReportTransformations = (i: number) => i;

    public translateMsg = (msg: string): string => translate('' + msg);
}
