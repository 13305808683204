import { createReducer, on } from '@ngrx/store';

import { MastersMetaData } from '../../models/masters/mastersmetadata.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import * as MasterActionTypes from '../actions/master-management.actions';
import { mastersInitialState } from '../states/master-management.state';

export const MASTERS_REDUCER_KEY = 'Masters';

export const MASTERS_REDUCER = createReducer(
    mastersInitialState,
    on(MasterActionTypes.GetMastersSuccess, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    masters: action?.masters,
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterTable, (state, action) => {
        return {
            ...state,
            selectedTableId: action.tableId,
            selectedRestApiName: action.restApiName,
        };
    }),
    on(MasterActionTypes.setRecordsLoading, (state, action) => {
        return {
            ...state,
            isRecordsLoading: action.loading,
        };
    }),
    on(MasterActionTypes.SetMasterMetadata, (state, action) => {
        const tableId = action?.metaData?.masterMetadata?.uid;
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    metaData: {
                        ...state?.organizationsMap?.[action.organizationId].metaData,
                        [tableId]: {
                            ...state?.organizationsMap?.[action.organizationId]?.metaData?.[tableId],
                            ...action.metaData,
                        },
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterLanguageTemplate, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    languageTemplate: {
                        ...state?.organizationsMap?.[action?.organizationId].languageTemplate,
                        [action.restApiName]: {
                            ...state?.organizationsMap?.[action.organizationId].languageTemplate?.[action.restApiName],
                            ...action?.masterLanguageTemplate,
                        },
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetCustomFilters, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    searchFilters: {
                        ...state?.organizationsMap?.[action?.organizationId]?.searchFilters,
                        [action.restApiName]: action?.searchFilters,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterRecordPagination, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    pagination: {
                        ...state?.organizationsMap?.[action?.organizationId]?.pagination,
                        [action.restApiName]: {
                            ...state?.organizationsMap?.[action.organizationId]?.pagination?.[action.restApiName],
                            ...{
                                pageIndex: action.pageIndex,
                                pageSize: action.pageSize,
                                pageState: {
                                    ...state.organizationsMap?.[action.organizationId]?.pagination?.[action.restApiName]?.pageState,
                                    ...(action.isUpdate ? { [action.pageIndex]: action.pageState } : {}),
                                },
                                search: {
                                    ...state.organizationsMap?.[action.organizationId]?.pagination?.[action.restApiName]?.search,
                                    ...action.search,
                                },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterRecords, (state, action) => {
        let allMasterRecords = action?.isClearSearch
            ? []
            : [...new Set([...(state.organizationsMap?.[action.organizationId]?.records?.[action.restApiName] || []), ...action.records])];
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    records: {
                        ...state?.organizationsMap?.[action?.organizationId]?.records,
                        [action.restApiName]: allMasterRecords,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.DeleteParentRelatedChildRecords, (state, action) => {
        const metaDatas = state?.organizationsMap?.[action?.orgId]?.metaData;
        const records = CommonUtilsService.cloneObject(state?.organizationsMap?.[action?.orgId]?.records);
        const pagination = CommonUtilsService.cloneObject(state?.organizationsMap?.[action?.orgId]?.pagination);
        Object.values(metaDatas)?.forEach((data: MastersMetaData) => {
            if (data?.masterMetadata?.parentTableId === action?.tableId) {
                delete records[data?.masterMetadata?.restAPIName];
                delete pagination[data?.masterMetadata?.restAPIName];
            }
        });
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.orgId]: {
                    ...state?.organizationsMap?.[action?.orgId],
                    records: records,
                    pagination: pagination,
                },
            },
        };
    }),
    on(MasterActionTypes.UpdateMasterRecordSuccess, (state, action) => {
        let storeRecords = CommonUtilsService.cloneObject(state.organizationsMap?.[action.organizationId]?.records?.[action.restApiName]);
        let index = storeRecords.findIndex((record) => record.recordId === action?.record?.recordId);
        storeRecords?.splice(index, 1, action?.record);

        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    records: {
                        ...state?.organizationsMap?.[action?.organizationId]?.records,
                        [action.restApiName]: storeRecords,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetSearchedRecordsCount, (state, action) => {
        return {
            ...state,
            masterSearchCount: action.searchRecordsCount,
        };
    }),
    on(MasterActionTypes.SetParentTableId, (state, action) => {
        return {
            ...state,
            parentTableId: action.parentTableId,
        };
    }),
    on(MasterActionTypes.SetParentTableRestApiName, (state, action) => {
        return {
            ...state,
            parentTableRestApiName: action.parentTableRestApiName,
        };
    }),
    on(MasterActionTypes.SetParentPrimaryKeys, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    parentPrimaryKeys: {
                        ...state?.organizationsMap?.[action?.organizationId]?.parentPrimaryKeys,
                        [action.tableId]: action?.primaryKeys,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMastersInboundMappings, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    inboundMappings: {
                        ...state?.organizationsMap?.[action?.organizationId]?.inboundMappings,
                        [action.tableId]: action?.inboundMappings,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterChainDisplayNames, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    chainDisplayNames: {
                        ...state?.organizationsMap?.[action?.organizationId]?.chainDisplayNames,
                        [action.restAPIName]: action?.chainDisplayNames,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterRequestsPagination, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    requestsPagination: {
                        ...state?.organizationsMap?.[action?.organizationId]?.requestsPagination,
                        [action.restApiName]: {
                            ...state?.organizationsMap?.[action.organizationId]?.requestsPagination?.[action.restApiName],
                            ...{
                                pageIndex: action.pageIndex,
                                pageSize: action.pageSize,
                                pageState: {
                                    ...state.organizationsMap?.[action.organizationId]?.requestsPagination?.[action.restApiName]?.pageState,
                                    ...(action.isUpdate ? { [action.pageIndex]: action.pageState } : {}),
                                },
                                timeRange: {
                                    ...state.organizationsMap?.[action.organizationId]?.requestsPagination?.[action.restApiName]?.timeRange,
                                    ...action.timeRange,
                                },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterRequests, (state, action) => {
        let allMasterRequests = action?.isClearSearch
            ? []
            : [...(state.organizationsMap?.[action.organizationId]?.requests?.[action.restApiName] || []), ...action.requests];
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    requests: {
                        ...state?.organizationsMap?.[action?.organizationId]?.requests,
                        [action?.restApiName]: allMasterRequests,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMastersOutboundMappings, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    outboundMappings: {
                        ...state?.organizationsMap?.[action?.organizationId]?.outboundMappings,
                        [action?.tableId]: action?.outboundMappings,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetReportDetails, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    reportDetails: {
                        ...state?.organizationsMap?.[action?.organizationId]?.outboundMappings,
                        [action?.tableId]: action?.reportDetails,
                    },
                },
            },
        };
    }),
    on(MasterActionTypes.SetMasterUploadFiles, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    uploadedFiles: {
                        ...state?.organizationsMap?.[action?.organizationId]?.uploadedFiles,
                        [action?.tableId]: action?.uploadedFiles,
                    },
                },
            },
        };
    })
);
