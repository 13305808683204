import { createReducer, on } from '@ngrx/store';

import {
    GetAllOrganizationsSuccess,
    SessionLogoutSuccessfull,
    SetCurrentOrganization,
    SetInvitedPartners,
    SetOrganizationPartners,
    SetPartnerOrgs,
    SetReceivedInvitations,
} from '../actions';
import { partnerInitialState } from '../states/partner.state';

export const PARTNER_REDUCER = createReducer(
    partnerInitialState,
    on(SetCurrentOrganization, (state, action) => {
        const organizationId = action.organization?.id;
        if (!state.partnersDetails[organizationId]) {
            return {
                ...state,
                currentOrganizationId: organizationId,
                partnersDetails: {
                    ...state.partnersDetails,
                    [organizationId]: {
                        sentPartnerInvitations: [],
                        receivedInvitations: [],
                    },
                },
            };
        }
        return {
            ...state,
            currentOrganizationId: organizationId,
        };
    }),
    on(SetInvitedPartners, (state, action) => {
        return {
            ...state,
            partnersDetails: {
                ...state.partnersDetails,
                [action.organizationId]: {
                    sentPartnerInvitations: action.sentPartners,
                    receivedInvitations: state.partnersDetails[action.organizationId].receivedInvitations,
                },
            },
        };
    }),
    on(SetReceivedInvitations, (state, action) => {
        return {
            ...state,
            partnersDetails: {
                ...state.partnersDetails,
                [action.organizationId]: {
                    receivedInvitations: action.receivedInvitations,
                    sentPartnerInvitations: state.partnersDetails[action.organizationId].sentPartnerInvitations,
                },
            },
        };
    }),
    on(GetAllOrganizationsSuccess, (state, action) => {
        return {
            ...state,
            allOrganizationsList: action.allOrganizationsList,
        };
    }),
    on(SetPartnerOrgs, (state, action) => {
        return {
            ...state,
            partnerOrgs: action.partnerOrgs,
        };
    }),
    on(SetOrganizationPartners, (state, action) => {
        return {
            ...state,
            organizationPartners: {
                ...state.organizationPartners,
                [action.organizationId]: action.partners,
            },
        };
    }),
    on(SessionLogoutSuccessfull, (state, action) => {
        return {
            allOrganizationsList: [],
            currentOrganizationId: '',
            partnerOrgs: [],
            partnersDetails: {},
            organizationPartners: {},
        };
    })
);

export const PARTNER_REDUCER_KEY = 'partners';
