import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ACTIVE_TOKENS_REDUCER_KEY } from '../reducers/tokens.reducer';

import type { ActiveTokensInitialState } from '../states/tokens.state';

export const activeTokensState = createFeatureSelector<ActiveTokensInitialState>(ACTIVE_TOKENS_REDUCER_KEY);
/**
 * Get Active Tokens
 */
export const ActiveTokens = createSelector(activeTokensState, (state) => state?.tokens.activeTokens);
export const getActiveTokensFailedState$ = createSelector(activeTokensState, (state) => state?.tokens.error);
/**
 * Get Tokens count
 */
export const TokensCount = createSelector(activeTokensState, (state) => state?.tokens.count);
export const TokensCountFailedState = createSelector(activeTokensState, (state) => state?.tokens.error);
/**
 * Invalidate Tokens
 */
export const InvalidateTokensSuccessState = createSelector(activeTokensState, (state) => state?.invalidate.success);
export const InvalidateTokensFailedState = createSelector(activeTokensState, (state) => state?.invalidate.error);
