export const tagsState: TagsState = {
    organizationsMap: {},
};

export interface TagsState {
    organizationsMap: {
        [property: string]: {
            subscriptionStatus: string;
            tagKeys: string[];
            tagValues: {
                [property: string]: string[];
            };
            tagKeyValuesVsAppDetails: {
                [property: string]: {
                    metadataId: string;
                    metadataName: string;
                    metadataType: string;
                    repositoryId: string;
                    serviceId: string;
                    restApiName: string;
                    tagKey: string;
                    tagValue: string;
                }[];
            };
            currentTagKey: string;
            currentTagValue: string;
            serviceIdVsTags: {
                [serviceId: string]: { [property: string]: string };
            };
        };
    };
}
