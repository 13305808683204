import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
    MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-customsnackbar',
    templateUrl: './customsnackbar.component.html',
    styleUrls: ['./customsnackbar.component.css'],
})
export class CustomsnackbarComponent {
    constructor(public snackBarRef: MatSnackBarRef<CustomsnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
