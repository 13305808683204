import { createAction, props } from '@ngrx/store';

export enum IdentityProvidersActions {
    GET_IDENTITY_PROVIDERS = '[IDENTITY_PROVIDERS] get identity providers',
    SET_IDENTITY_PROVIDERS = '[IDENTITY_PROVIDERS] set identity providers',
    CREATE_IDENTITY_PROVIDER = '[IDENTITY_PROVIDERS] create identity provider',
    UPDATE_IDENTITY_PROVIDER = '[IDENTITY_PROVIDERS] update identity provider',
    DELETE_IDENTITY_PROVIDER = '[IDENTITY_PROVIDERS] delete identity provider',
    SET_IDENTITY_PROVIDER = '[IDENTITY_PROVIDERS] set identity provider',
    IDENTITY_PROVIDER_CLOSE_DRAWER = '[IDENTITY_PROVIDERS] identity provider close drawer',
}

export const GetIdentityProviders = createAction(IdentityProvidersActions.GET_IDENTITY_PROVIDERS);

export const SetIdentityProviders = createAction(
    IdentityProvidersActions.SET_IDENTITY_PROVIDERS,
    props<{
        identityProviders: any[];
    }>()
);

export const CreateIdentityProvider = createAction(
    IdentityProvidersActions.CREATE_IDENTITY_PROVIDER,
    props<{
        payload: any;
    }>()
);

export const UpdateIdentityProvider = createAction(
    IdentityProvidersActions.UPDATE_IDENTITY_PROVIDER,
    props<{
        data: {
            providerId: string;
            payload: any;
        };
    }>()
);

export const SetIdentityProvider = createAction(
    IdentityProvidersActions.SET_IDENTITY_PROVIDER,
    props<{
        identityProvider: any;
        actionType: string;
    }>()
);

export const DeleteIdentityProvider = createAction(
    IdentityProvidersActions.DELETE_IDENTITY_PROVIDER,
    props<{
        id: string;
    }>()
);

export const IdentityProviderCloseDrawer = createAction(IdentityProvidersActions.IDENTITY_PROVIDER_CLOSE_DRAWER);
