import { createReducer, on } from '@ngrx/store';

import { SelectDashboard, SetDashboardLoader, SetDashboards } from '../actions';
import * as sessionActions from '../actions/session.actions';
import { dashboardState } from '../states';

export const DASHBOARD_REDUCER = createReducer(
    dashboardState,
    on(sessionActions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(SelectDashboard, (state, action) => {
        return {
            ...state,
            selectedDashboard: action.dashboard,
        };
    }),
    on(SetDashboards, (state, action) => {
        return {
            ...state,
            analyticDashboards: {
                ...state.analyticDashboards,
                [action.currentOrganizationId]: {
                    dashboards: action.analyticDashboards,
                },
            },
        };
    }),
    on(SetDashboardLoader, (state, action) => {
        return {
            ...state,
            isDashboardsLoading: action.isDashboardsLoading,
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, (state, action) => {
        return {
            currentOrganizationId: '',
            selectedDashboard: {
                chains: undefined,
                description: undefined,
                displayName: undefined,
                repositoryId: undefined,
                serviceId: undefined,
                serviceName: undefined,
                selected: undefined,
            },
            analyticDashboards: undefined,
            isDashboardsLoading: undefined,
        };
    })
);

export const DASHBOARD_REDUCER_KEY = 'dashboard';
