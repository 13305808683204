import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';
import { Subject } from 'rxjs';

import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-savecustomfilter',
    templateUrl: './savecustomfilter.component.html',
    styleUrls: ['./savecustomfilter.component.scss'],
})
export class SavecustomfilterComponent {
    filterName: string = '';
    title: any;
    onSaveFilter: any;
    newFilterName: string = '';
    private unSubscribe = new Subject<void>();

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _libUtils: UtilsService) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    onModelChange = (data) => {
        this.newFilterName = data;
    };

    saveFilter = () => {
        if (!this.newFilterName || this.newFilterName.trim().length === 0) {
            this._libUtils.alertError('Filter name is required');
        } else {
            this.data.onSaveFilter(this.newFilterName);
        }
    };

    translateMsg = (msg: string): string => translate(msg);

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
