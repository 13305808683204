<ng-container *transloco="let translate;">
    <angular-editor [dir]="dir" [formControl]="control" [placeholder]="placeholder" name="name" title="{{title}}" id="{{id}}" class="{{id}}" [config]="editorConfig" (keypress)="errors && errors.splice(0,1);">
        <ng-template *ngIf="enableEncryptDecrypt" #customButtons>
            <ae-toolbar-set class="inline-block float-right mt-px">
                <ae-button iconClass="fa fa-font" class="showHideActions" [ngClass]="{'active': showToolbar}" title="Show/Hide Actions" (buttonClick)="showFormatToolbar()">
                </ae-button>
            </ae-toolbar-set>
        </ng-template>
    </angular-editor>
    <mat-hint align="end" *ngIf="maxLength">{{control.value && control.value.length || 0}} / {{maxLength}}</mat-hint>
    <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
        <strong><span>* </span>{{translate('Required')}}</strong>
    </mat-hint>
    <mat-hint align="start" *ngIf="!required && !(errors && errors.length > 0) && hint && hint.length > 0">
        {{hint}}
    </mat-hint>
    <mat-error *ngIf="errors && errors.length > 0" title="{{errors}}">
        {{errors}}
    </mat-error>
</ng-container>
