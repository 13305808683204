<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 class="matDialogTitle" mat-dialog-title>{{data.title}}</h4>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography">
    <material-input [autofocus]="false" [name]="'filterName'" [id]="'filterName'" [title]="translateMsg('Name your view filter')" [model]="filterName" [required]="true" [placeholder]="translateMsg('Name your view filter')" (modelChange)="onModelChange($event)"></material-input>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class='mx-2 noOutline' mat-raised-button mat-dialog-close>Cancel</button>
    <material-button class="noOutline submitColor" [label]="translateMsg('Save & Search')" (onTrigger)="saveFilter()" [disabled]="!newFilterName"></material-button>
</mat-dialog-actions>
