import { Injectable } from '@angular/core';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { Connection } from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class UsersListService {
    constructor(private _taxilla: TaxillaApiService) {}

    getPartnerOrgs = (data: {
        organizationId: string;
        organization: {
            name: string;
            id: string;
        };
    }) => {
        const partnerOrgs: any[] = [];
        this._taxilla.locations.getPartners(data.organizationId).subscribe((res: { partnerOrgs: string | any[] }) => {
            const partOrgArray: any[] = [];
            for (let i = 0; i < res.partnerOrgs.length; i++) {
                const partnerOrg = res.partnerOrgs[i];
                partOrgArray.push(new Connection(partnerOrg));
            }
            for (let i = 0; i < partOrgArray.length; i++) {
                const orgArray = partOrgArray[i];
                const obj = {};
                obj['displayName'] = orgArray.partnerOrganization.name;
                obj['value'] = orgArray.partnerOrganization.id;
                partnerOrgs.push(obj);
            }
            const orgObj = {
                displayName: data.organization.name,
                value: data.organization.id,
            };
            partnerOrgs.push(orgObj);
        });
        return partnerOrgs;
    };

    getOrgUsers = (organizationId: any) => {
        const users: any[] = [];
        const data = {
            organizationId,
        };
        return new Promise<any[]>((resolve) => {
            this._taxilla.user.getOrgUsers(data, {
                successCallback: (res) => {
                    for (let i = 0; i < res.length; i++) {
                        const user = res[i];
                        users.push(user);
                    }
                    resolve(users);
                },
            });
        });
    };

    getRoleUsers = (role) => {
        const oldUserIdsArray = [];
        this._taxilla.roles.roleUsers(role.id).subscribe((res) => {
            for (let i = 0; i < res.length; i++) {
                const user = res[i];
                oldUserIdsArray.push(user.userId);
            }
        });
        return oldUserIdsArray;
    };

    searchAssignUsers = (element) => {
        let noAssignUsers: boolean;
        let matOptionsCount = 0;
        matOptionsCount = element.nativeElement.querySelectorAll('.assignUsersList').length;
        if (matOptionsCount === 0) {
            noAssignUsers = true;
        } else {
            noAssignUsers = false;
        }
        return noAssignUsers;
    };

    checkUsersList = (users) => {
        let allUsersSelected: boolean;
        let count = 0;
        for (let i = 0; i < users?.length; i++) {
            const user = users[i];
            if (user.selected === undefined || !user.selected) {
                count = count + 1;
            }
        }
        if (count) {
            allUsersSelected = false;
        } else {
            allUsersSelected = true;
        }
        return allUsersSelected;
    };

    markChecked = (
        data: {
            $event: any;
            user: {
                userId: string;
            };
        },
        users
    ) => {
        if (data.$event?.checked) {
            users.forEach((user) => {
                if (user.userId === data.user.userId) {
                    user.selected = true;
                } else {
                    user.selected = false;
                }
            });
        } else {
            users.forEach((user) => {
                if (user.userId === data.user.userId) {
                    user.selected = false;
                } else {
                    user.selected = true;
                }
            });
        }
        return users;
    };

    selectAllUsers = (event: { checked: boolean }, users: any[]) => {
        if (event?.checked) {
            users.forEach((user) => {
                user.selected = true;
            });
        } else {
            users.forEach((user) => {
                user.selected = false;
            });
        }
        return users;
    };

    checkFormValid = (allData) => {
        let flag = false;
        if (allData.length !== 0) {
            allData.forEach((each) => {
                if (each.selected !== undefined && each.selected && !flag) {
                    flag = true;
                }
            });
        }
        return flag;
    };
}
