import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AssetService, BridgeNode } from 'taxilla-library';

import { AppNewProcessDirective } from '../../directives/app-new-process/app-new-process.directive';
import { AppProcessesSelectionDirective } from '../../directives/app-processes/app-processes.directive';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Component({
    selector: 'app-header-shortcuts',
    templateUrl: './header-shortcuts.component.html',
    styleUrls: ['./header-shortcuts.component.scss'],
    providers: [AppNewProcessDirective, AppProcessesSelectionDirective],
})
export class HeaderShortcutsComponent implements OnInit {
    @Input() mainApp: AssetService;
    @Input() subApp: AssetService;
    @Input() report: BridgeNode;
    @Input() uiType: string;
    @Input() appsMenuTrigger: MatMenuTrigger;
    @Input() showSettingIcon: boolean = false;
    @Input() restrictedMenuItems: string[] = [];

    baseRoute: string = '';
    appsBaseRoute: string = '';
    shortCutItems = [];
    bridge: AssetService;
    app: AssetService;
    appBridgePermissions: any;
    appPermissions: any;
    reportPermissions: any;
    mainPermissions: any;
    currentOrg: any = {};
    hasAppsMasterScheduler: boolean;
    public isNewUI: boolean = this._commonUtils.getCookie('new-ui') === 'true';

    constructor(
        private _taxilla: PermissionsService,
        private _allProcesses: AppProcessesSelectionDirective,
        private _processNow: AppNewProcessDirective,
        private _commonUtils: CommonUtilsService
    ) {}

    getLocalStorageItem = (item: string) => {
        const storageItem = localStorage.getItem(item.toLocaleLowerCase());
        return JSON.parse(storageItem);
    };

    openShortcutsMenu = async () => {
        this.app = this.mainApp;
        this.bridge = this.subApp;
        this.shortCutItems = [];
        await this.getAppPermissions();
        this.setNavigationMenu();
    };

    selectionEvent = (triggerOrigin: string) => {
        if (this.uiType) {
            this._commonUtils.setInStorage('categoryFilterKey', 'platform');
            this._commonUtils.setInStorage('categoryFilterValue', this.uiType);
        }
        if (triggerOrigin === 'All Processes') {
            this._allProcesses.app = <any>this.app;
            this._allProcesses.bridge = this.bridge;
            this._allProcesses.report = this.report;
            this._allProcesses.uiType = this.uiType;
            this._allProcesses.onAppSelection();
        }
        if (triggerOrigin === 'Process Now') {
            this._processNow.app = <any>this.app;
            this._processNow.bridge = this.bridge;
            this._processNow.report = this.report;
            this._processNow.uiType = this.uiType;
            this._processNow.onAppSelection();
        }
        (this.appsMenuTrigger as any)?._menuOpen && this.appsMenuTrigger.toggleMenu();
    };

    getAppPermissions = async () => {
        if (this.bridge) {
            const userPermissions = await this._taxilla.getBridgePermissions(this.bridge.serviceId, true);
            const assetsInBridgePermissions = userPermissions.BRIDGE_ASSET?.[this.bridge.serviceId];
            this.appBridgePermissions = assetsInBridgePermissions;
            this.appPermissions = this.app && assetsInBridgePermissions[this.app?.displayName || this.app.name];
            this.reportPermissions = this.report && assetsInBridgePermissions[this.report.name];
        } else if (this.app) {
            const userPermissions = await this._taxilla.getAppPermissions(this.app.serviceId, true);
            const appPermissions = userPermissions.ASSET?.[this.app.serviceId];
            this.appPermissions = appPermissions;
            this.reportPermissions = this.report && appPermissions[this.report.name];
        }
        if (this.bridge) {
            this.mainPermissions = this.appBridgePermissions;
        }
        if (this.app) {
            this.mainPermissions = this.appPermissions;
        }
        if (this.report) {
            this.mainPermissions = this.reportPermissions;
        }
    };

    setNavigationMenu = () => {
        const appPermissions = this.mainPermissions;
        this.buildShortCutRouteConfig();
        this.pushPermissionedItems(appPermissions?.permissions || {});
    };

    pushPermissionedItems = (permissions) => {
        const shortCutItems = [];
        if ((this.app?.displayName || this.app?.name) && !this.restrictedMenuItems?.includes('HOME')) {
            pushShortcuts({
                itemName: this.report ? 'Home' : 'App Home',
                icon: 'home',
                navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/home`,
            });
        }
        if (permissions['PROCESSES'] !== undefined && !this.restrictedMenuItems?.includes('PROCESSES')) {
            pushShortcuts({
                itemName: 'All Processes',
                icon: 'clear_all',
            });
        }
        if (permissions['NEW_PROCESS'] !== undefined && !this.restrictedMenuItems?.includes('NEW_PROCESS')) {
            pushShortcuts({
                itemName: 'Process Now',
                icon: 'assistant_direction',
            });
        }
        if (this.app?.displayName || this.app?.name) {
            pushShortcuts({
                itemName: 'Configure App',
                icon: 'display_settings',
                navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/configure-app`,
            });
        }
        if (!this.report?.name?.length) {
            pushShortcuts({
                itemName: 'Configure Transformations',
                icon: 'swipe',
                navigateTo: `${this.appsBaseRoute}/${this.mainApp.restApiName}/configure-transformations`,
            });
        }
        if (permissions?.INTEGRATION_METHOD) {
            if (this.report) {
                pushShortcuts(
                    {
                        itemName: 'Outbound Integrations',
                        icon: 'cloud_upload',
                        navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/outbound-integrations`,
                    },
                    {
                        itemName: 'All Processes',
                        icon: 'clear_all',
                    },
                    {
                        itemName: 'Process Now',
                        icon: 'assistant_direction',
                    }
                );
            } else {
                pushShortcuts(
                    {
                        itemName: 'Setup Attributes',
                        icon: 'av_timer',
                        navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/setupattributes`,
                    },
                    {
                        itemName: 'Inbound Integrations',
                        icon: 'cloud_download',
                        navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/inbound-integrations`,
                    },
                    {
                        itemName: 'Outbound Integrations',
                        icon: 'cloud_upload',
                        navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/outbound-integrations`,
                    }
                );
            }
            if (this.hasAppsMasterScheduler) {
                pushShortcuts({
                    itemName: 'Masters Scheduler',
                    icon: 'cloud_download',
                    navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/masters-scheduler`,
                });
            }
        }
        if (permissions?.MANAGE_WORKFLOW) {
            pushShortcuts({
                itemName: 'Workflow',
                icon: 'dashboard',
                navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/manage_workflow`,
            });
        }
        const hasVdmPermission = permissions?.VDM;
        const hasAccess: boolean = this.currentOrg?.vdmAccess;
        if (!this.report && !!hasVdmPermission && hasAccess) {
            pushShortcuts({
                itemName: 'Transformations',
                icon: 'settings_input_component',
                navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/transformations`,
            });
        }
        pushShortcuts({
            itemName: 'Rest API Explorer',
            icon: 'api',
            navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/restapiexplorer`,
        });
        if (!this.app?.nextNodes) {
            pushShortcuts({
                itemName: 'Collaboration Configuration',
                icon: 'people',
                navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/collab-configuration`,
            });
        }
        pushShortcuts({
            itemName: 'Notifications',
            icon: 'mail',
            navigateTo: `${this.appsBaseRoute}/${this.report?.name ?? this.mainApp.restApiName}/notifications`,
        });
        function pushShortcuts(...shortCutItem: { itemName: string; icon: string; navigateTo?: string }[]) {
            shortCutItems.push(...shortCutItem);
        }
        this.shortCutItems = shortCutItems;
    };

    buildShortCutRouteConfig = () => {
        const organizationId = this._commonUtils.getFromStorage('currentOrganizationId');
        this.baseRoute = `/organizations/${organizationId}`;
        let rootRoute: string = `apps`;
        if (this.mainApp?.componentType) {
            switch ((this.report ?? this.mainApp)?.componentType) {
                case 'ASSET':
                    rootRoute = `packages/${this.subApp.restApiName}/apps`;
                    break;
                case 'FILE':
                    rootRoute = `packages/${this.subApp.restApiName}/apps/${this.mainApp.restApiName}/reports`;
                    break;
                default:
                    break;
            }
        }
        this.appsBaseRoute = `${this.baseRoute}/${rootRoute}`;
    };

    MenuTrackby = (_index: number, item: any) => {
        return item.id;
    };

    public selectedItem = () => {
        if (this.isNewUI) {
            this._commonUtils.setInStorage('app-home-redirect-url', window.location.pathname);
        }
        (this.appsMenuTrigger as any)?._menuOpen && this.appsMenuTrigger.toggleMenu();
    };

    ngOnInit(): void {
        this.currentOrg = this.getLocalStorageItem('currentOrganization');
        this.hasAppsMasterScheduler = this.getLocalStorageItem('hasAppsMasterScheduler');
    }
}
