import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IntegrationsSubSection, IntegrationTabStructure } from '../../interface/integrationmodal.interface';
import { IntegrationRecord } from '../../models/integrations/integrationrecord.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { IntegrationsUtilsService } from '../../services/integrations-utils/integrations-utils.service';

@Component({
    selector: 'app-integrations-postman-structure',
    templateUrl: './integrations-postman-structure.component.html',
    styleUrls: ['./integrations-postman-structure.component.scss'],
})
export class IntegrationsPostmanStructureComponent implements OnInit {
    @Input() subSection: IntegrationsSubSection;
    @Input() record: IntegrationRecord;
    @Input() hideField: (...args: any[]) => void;
    @Input() subSectionRecord: IntegrationsSubSection['records'][0];
    @Input() masterTableColumns: any;
    @Input() masterTables: MasterTables[];
    @Input() subTenants: any[];
    @Output() checkValidateButton = new EventEmitter();

    setAsActiveTab = this._integrationUtils.setAsActiveTab;

    isTabDisabled = this._integrationUtils.isTabDisabled;

    addNewTabRecord = (currentTab: IntegrationTabStructure) => this._integrationUtils.addNewTabRecord(currentTab, this.record);

    deleteTabRecord = (currentTab: IntegrationTabStructure, index: number) =>
        this._integrationUtils.deleteTabRecord(currentTab, index, this.record);

    constructor(private _integrationUtils: IntegrationsUtilsService) {}

    ngOnInit() {}
}
