import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { response } from 'express';
import { Observable } from 'rxjs';
import { AlertError, CollaboratePartners } from 'taxilla-library';

import { Organization } from '../../models/organization.class';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(
        private _utils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private _bridge: BridgeService,
        private _store: StoreService,
        private store$: Store
    ) {}

    /**
     * Method to switch to organization
     * @param data Contains org ID,
     * @param callbacks Contains success callback method , failure callback method
     */
    switchToOrganization = (data: { orgId: string; firstEvent: boolean }) => {
        if (!data.orgId || data.firstEvent) {
            return;
        }
        return new Observable((observer) => {
            this._bridge.switch(
                data.orgId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    const msg = (res && res.msg) || 'Failed to checkout selected organization';
                    observer.error(msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to search locations
     * @param data Contains Organization id,
     * @param callbacks Contains success callback method, failure callback method
     */

    searchOrganizationsWithOrgId = (data: { organizationId: string }) => {
        return new Observable<any>((observer) => {
            this._bridge.searchOrganizations(
                data,
                (response) => {
                    observer.next(response);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get locations
     * @param data Contains Organization ID,
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getLocations = (callbacks?: {
        successCallback: (organizations: Organization[]) => void;
        failureCallback: (...args: any[]) => void;
    }) => {
        this._bridge.getLocations(
            (res) => {
                const response = (res && res.response) || [];
                const organizations = [];
                if (Array.isArray(response)) {
                    response.forEach((org) => {
                        organizations.push(new Organization(org));
                    });
                } else {
                    organizations.push(new Organization(response));
                }
                callbacks.successCallback(organizations);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to add location
     * @param data Contains Location details
     * @param callbacks Contains Success callback method, Failure callback method
     * */

    addLocation = (data: { org: Organization }) => {
        return new Observable<Organization | string>((observer) => {
            this._bridge.addLocation(
                data,
                (response) => {
                    observer.next(response);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to update organization
     * @param data Contains organization details,
     * @param callbacks Contains Success callback method, Failure callback method
     */

    updateOrganizationDetails = (data: { org: Organization }) => {
        return new Observable<Organization | string>((observer) => {
            this._bridge.updateOrganizationDetails(
                data,
                (response) => {
                    observer.next(response);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to upload organization logo
     * @param data Contains upload organization logo details,
     * @param callbacks Contains Success callback method, Failure callback method
     */

    uploadOrganizationLogo = (data: FormData) => {
        return new Observable<string>((observer) => {
            this._bridge.uploadOrganizationLogo(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to delete organization logo
     * @param callbacks Contains Success callback method, Failure callback method
     */

    deleteOrganizationLogo = () => {
        return new Observable<string>((observer) => {
            this._bridge.deleteOrganizationLogo(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get organization logo
     * @param callbacks Contains Success callback method, Failure callback method
     */

    getOrganizationLogo = (enableHierarchy: boolean) => {
        return new Observable<string>((observer) => {
            this._bridge.getOrganizationLogo(
                enableHierarchy,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get organization details
     * @param data Contains organization Id
     * @param callbacks Contains Success callback method, Failure callback method
     */

    getOrganizationDetailsData = (data: string) => {
        return new Observable<any>((observer) => {
            this._bridge.getOrganizationDetails(
                data,
                (response) => {
                    observer.next(response);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get all organizations
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getOrganizations = async (
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        },
        byPass?: boolean
    ) => {
        byPass && this._store.publicScope.clearValue('associatedOrgs');
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve, reject) => {
                this._bridge.getOrganizations(
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        callbacks.failureCallback(res);
                    }
                );
            });
        }, 'associatedOrgs');
        callbacks.successCallback(response?.associatedOrgs);
    };

    /**
     * Method to get all organizations
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getOrganizationsPromise = () => {
        return new Observable<Organization[]>((observer) => {
            this._bridge.getOrganizations(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get organization hierarchy
     * @param data Contains organization Id
     * @param callbacks Contains success callback method, failure callback method
     */
    getOrganizationHierarchy = (
        data: {
            organizationId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getOrganizationHierarchy(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get orgnization child hierarchy
     * @param data Contains organization Id
     * @param callbacks Contains success callback method, failure callback method
     */
    getOrganizationChildHierarchy = (organizationId: string) => {
        return new Observable<any>((observer) => {
            this._bridge.getOrganizationChildHierarchy(
                organizationId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get parent organization details
     * @param data Contains organization Id
     * @param callbacks Contains success callback method, failure callback method
     */
    getParentOrgDetails = (
        data: {
            organizationId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getParentOrgDetails(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get partners
     * @param data Contains organization Id
     * @param callbacks Contains success callback method, failure callback method
     */
    getPartners = (organizationId: string) => {
        return new Observable<any>((observer) => {
            this._bridge.getPartners(
                organizationId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get All partners
     * @param data Contains page, size, invitationType, searchString, actionType, status
     * @param callbacks Contains success callback method, failure callback method
     */
    getAllSentInvitations = (data: { searchString: string; actionType: string; status: string }) => {
        return new Observable<any>((observer) => {
            this._bridge.getAllSentInvitations(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to getManagePartners
     * @param data Contains partnerOrganizationId
     * @param callbacks Contains success callback method, failure callback method
     */
    public getReceivedPartners = (data: { organizationId: string; status: string }) => {
        return new Observable<any>((observer) => {
            this._bridge.getReceivedPartners(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to getPartnersInvitedByOrg
     * @param data Contains partnerOrganizationId
     * @param callbacks Contains success callback method, failure callback method
     */
    getPartnersInvitedByOrg = (
        data: {
            organizationId: string;
        },
        callbacks?: {
            successCallback: (response: CollaboratePartners) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getPartnersInvitedByOrg(
            data,
            (res) => {
                callbacks.successCallback(res?.orgNodes);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to getAssociations
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getPartnerOrgs = () => {
        return new Observable<any>((observer) => {
            this._bridge.getPartnerOrgs(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to send partner invitation
     * @param callbacks Contains Success callback method, Failure callback method
     */
    public sendPartnerInvitation = (data: any) => {
        return new Observable<any>((observer) => {
            this._bridge.sendPartnerInvitation(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get all flex-fields.
     * @param callbacks Contains success callback method,
     */
    public getFlexFields = async () => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve, reject) => {
                this._bridge.getFlexFields(
                    (res) => {
                        resolve(res?.response?.flexFieldDefinitions);
                    },
                    (res) => {
                        this.store$.dispatch(
                            AlertError({
                                message: res?.msg || translate('Failed to get flexfields'),
                            })
                        );
                    }
                );
            });
        }, 'flexFields');
        return response?.flexFields || [];
    };

    /**
     * Method to get all locations codes
     * @param callbacks Contains success callback method
     */
    getLocationsCodes = async (callbacks?: { successCallback: (res) => void; failureCallback: (res) => void }) => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve, reject) => {
                this._bridge.getLocationsCodes(
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        callbacks.failureCallback(res);
                    }
                );
            });
        }, 'locationCodes');
        callbacks.successCallback(response?.locationCodes);
    };

    getLocationsCodesData = () => {
        return new Observable<any>((observer) => {
            this._bridge.getLocationsCodes(
                (response) => {
                    observer.next(response);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get all related tenants subscribed to a given app
     * @param data Contains orgId, serviceId
     * @param callbacks Contains success callback method, failure callback method
     */
    getRelatedTenants = (
        data: {
            orgId: string;
            serviceId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getRelatedTenants(
            data,
            (res) => {
                if (res?.response?.length > 0) {
                    res.response = res.response.slice(0).filter((item) => item.enabled);
                }
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get OrgNames By Ids
     */
    getOrgNamesByIds = async (data: { orgIds: string[]; noAlerts: boolean }): Promise<{ [property: string]: string }> => {
        const clonedOrgIdsObject = CommonUtilsService.cloneObject(data);
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve, reject) => {
                    this._bridge.getOrgNamesByIds(
                        clonedOrgIdsObject,
                        (res) => {
                            resolve(res?.orgIdVsOrgName);
                        },
                        (res) => {
                            this._utils.alertError(res?.msg || 'Failed to get OrgId vs orgNames');
                            resolve(undefined);
                        }
                    );
                });
            },
            'orgIdVsNames',
            clonedOrgIdsObject.orgIds
        );
        return Object.keys(response?.orgIdVsNames)
            .filter((id) => data.orgIds.includes(id))
            .reduce((orgNames, id) => {
                orgNames[id] = response?.orgIdVsNames[id];
                return orgNames;
            }, {});
    };

    /**
     * method to get activate locations
     * @param data Contains orgId
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */

    activateLocations = (data: { orgId: string }) => {
        return new Observable<Organization | string>((observer) => {
            this._bridge.activateLocations(
                data,
                (res) => {
                    observer.next(res?.response?.msg);
                    observer.complete();
                },
                (response) => {
                    observer.error(response?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get deactivate locations
     * @param data Contains orgId
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    deactivateLocations = (data: { orgId: string }) => {
        return new Observable<Organization | string>((observer) => {
            this._bridge.deactivateLocations(
                data,
                (res) => {
                    observer.next(res?.response?.msg);
                    observer.complete();
                },
                (response) => {
                    observer.error(response?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to approvePartner of organization
     * @param data Contains partnerOrganizationId
     * @param callbacks Contains success callback method,
     */
    approvePartner = (data) => {
        return new Observable<any>((observer) => {
            this._bridge.approvePartner(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to rejectPartner of partners
     * @param data Contains partnerOrganizationId
     * @param callbacks Contains success callback method,
     */
    rejectPartner = (data: any) => {
        return new Observable<any>((observer) => {
            this._bridge.rejectPartner(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to Delete partner invitation
     * @param data Contains partnerOrganizationId
     * @param callbacks Contains success callback method,
     */
    deletePartnerInvitation = (data) => {
        return new Observable<any>((observer) => {
            this._bridge.deletePartnerInvitation(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete;
                },
                (res) => {
                    observer.error(res);
                    observer.complete;
                }
            );
        });
    };

    /**
     * Method to resend partner invitation
     * @param data Contains partnerOrganizationId
     * @param callbacks Contains success callback method,
     */
    resendPartnerInvitation = (data: { partnerOrganizationId: string }) => {
        return new Observable<any>((observer) => {
            this._bridge.resendPartnerInvitation(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to updatePartner of partners
     * @param data Contains partnerOrganizationId, partnerExternalId
     * @param callbacks Contains success callback method,
     */
    updatePartner = (data: { partnerOrganizationId: string; partnerExternalId: string }) => {
        return new Observable<any>((observer) => {
            this._bridge.updatePartner(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get count of provider organizations
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getCountOfProviderOrganizations = (callbacks?: { successCallback: (res) => void; failureCallback: (res) => void }) => {
        this._bridge.getCountOfProviderOrganizations(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    getOrganizationFieldIdVsNameMap = async () => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve) => {
                this._bridge
                    .getOrganizationFieldIdVsNameMap()
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((res) => {
                        this._utils.alertError(res?.msg || 'Failed to get map');
                    });
            });
        }, 'organizationNamesMap');
        return response?.['organizationNamesMap'];
    };

    /**
     * method to get list of provider organizations for dropdown
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    providerOrganizationsDropdownList = () => {
        return new Observable<any>((observer) => {
            this._bridge.providerOrganizationsDropdownList(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };
}
