import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Chain } from '../../models/chain.interface';
import { TransformationInterface } from '../../models/transformation.interface';
import { BridgeService } from '../bridge/bridge.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class TransformationsService {
    constructor(private _bridge: BridgeService, private _utils: UtilsService) {}

    /**
     * Method to get transformations by asset version
     * @param data Contains assetName, assetVersionUid
     * @param s Success callback
     * @param f Failure callback
     */
    public getTransformationsByAssetVersion = (
        data: {
            assetName: string;
            assetVersionUid: string;
        },
        callbacks: {
            successCallback: (res: {
                allTransformations: {
                    INBOUND: TransformationInterface[];
                    OUTBOUND: TransformationInterface[];
                };
                assetToAssetChains: string[];
                organizations: { [property: string]: string };
            }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getTransformationsByAssetVersion(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to get transformations');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    /**
     * Method to get Chains by assetName and asset Id
     * @param data Contains assetName, asset Id
     * @param s Success callback
     * @param f Failure callback
     */
    getChains = (data: { assetName: string; assetId: string }) => {
        return new Observable((observer) => {
            this._bridge.getChains(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get Chains by assetName and asset Id
     * @param data Contains assetName, asset Id
     * @param s Success callback
     * @param f Failure callback
     */
    getIntegrationChains = (
        data: { assetName: string; assetId: string },
        callbacks: {
            successCallback: (res: {
                chains: {
                    INBOUND: Chain[];
                    OUTBOUND: Chain[];
                };
            }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getChains(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to get chains');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    /**
     * Method to get Asset to asset chains by assetName and asset Id
     * @param data Contains assetName, asset Id
     * @param s Success callback
     * @param f Failure callback
     */
    getAssetToAssetChains = (data: { assetName: string; assetId: string; noAlerts: boolean }) => {
        return new Observable((observer) => {
            this._bridge.getAssetToAssetChains(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get transformation names by chain name, asset name, asset id,
     * @param data Contains chainName, assetName, assetVersionUid
     * @param s Success callback
     * @param f Failure callback
     */
    getTransformationNamesInChain = (data: { chainName: string; assetName: string; assetId: string; repositoryId?: string }) => {
        return new Observable((observer) => {
            this._bridge.getTransformationNamesInChain(
                data,
                (res) => {
                    observer.next((res && res.response && res.response.transformations) || []);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get transformation names by chain name, asset name, asset id,
     * @param data Contains chainName, assetName, assetVersionUid
     * @param s Success callback
     * @param f Failure callback
     */
    getSourceNamesInChain = (
        data: { transformationName: string; assetName: string; assetId: string; repositoryId: string },
        callbacks: {
            successCallback: (res: string[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getSourceNamesInChain(
            data,
            (res) => {
                callbacks.successCallback((res && res.response && res.response.sources) || []);
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to get sources');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    /**
     * Method to save source configurations
     * @param data Contains chainName, assetName, assetVersionUid
     * @param s Success callback
     * @param f Failure callback
     */
    saveResourceConfiguration = (
        payload,
        callbacks: { successCallback: (...args: any[]) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.saveResourceConfiguration(payload, callbacks.successCallback, (res) => {
            if (callbacks.failureCallback) {
                callbacks.failureCallback(res);
            } else {
                this._utils.alertError(res && res.msg);
            }
        });
    };

    /**
     * Method to save source configurations
     * @param data Contains chainName, assetName, assetVersionUid
     * @param s Success callback
     * @param f Failure callback
     */
    saveMasterScheduler = (
        payload,
        callbacks: { successCallback: (...args: any[]) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.saveMasterScheduler(payload, callbacks.successCallback, (res) => {
            if (callbacks.failureCallback) {
                callbacks.failureCallback(res);
            } else {
                this._utils.alertError(res && res.msg);
            }
        });
    };

    /**
     * Method to process transformations
     * @param data Contains bridgeRestAPIName, restapiname, transformation name ,payload, override
     * @param s Success callback
     * @param f Failure callback
     */
    processTransformations = (
        data: {
            bridgeRestAPIName: string;
            restApiName: string;
            transformationName: string;
            payload: FormData;
            override: boolean;
        },
        callbacks: { successCallback: (...args: any[]) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.processTransformation(
            data,
            (response) => {
                callbacks.successCallback(response);
            },
            (res) => {
                if (res && res.response && res.response.REQUEST_EXISTS && res.response.REQUEST_EXISTS.length > 0) {
                    callbacks.successCallback(res);
                } else if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res && res.msg);
                }
            }
        );
    };
}
