import { createReducer, on } from '@ngrx/store';

import * as authActions from '../actions/auth.actions';
import * as sessionActions from '../actions/session.actions';
import { authInitialState, resetPasswordInitialState } from '../states';

export const AUTH_REDUCER = createReducer(
    authInitialState,
    on(authActions.AuthGetClientDetails, (state, action) => {
        return {
            ...state,
            client: {
                identityProvider: action.payload?.domain,
                clientDetails: undefined,
                errorResponse: undefined,
            },
        };
    }),
    on(authActions.ClientDetailsSuccess, (state, action) => {
        return {
            ...state,
            client: {
                ...state.client,
                clientDetails: action,
                errorResponse: undefined,
            },
        };
    }),
    on(authActions.ClientDetailsFailed, (state, action) => {
        return {
            ...state,
            client: {
                identityProvider: undefined,
                clientDetails: undefined,
                errorResponse: action,
            },
        };
    }),
    on(authActions.InternalAuthTokenValidation, (state) => {
        return {
            ...state,
            token: {
                details: undefined,
                errorResponse: undefined,
            },
        };
    }),
    on(authActions.InternalAuthTokenValidationSuccess, (state, action) => {
        return {
            ...state,
            token: {
                details: action,
                errorResponse: undefined,
            },
        };
    }),
    on(authActions.InternalAccessTokenValidation, (state) => {
        return {
            ...state,
        };
    }),
    on(authActions.AuthCodeExternalValidation, (state, action) => {
        return {
            ...state,
            externalAuthCodeValidation: {
                payload: action.payload,
                successMessage: undefined,
                failureMessage: undefined,
            },
        };
    }),
    on(authActions.SetApplicationProperties, (state, action) => {
        return {
            ...state,
            applicationProperties: action.result,
        };
    }),
    on(authActions.Set4ECPermission, (state, action) => {
        return {
            ...state,
            is4ecEnabled: action.hasAccess,
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => {
        return {
            client: {
                clientDetails: undefined,
                errorResponse: undefined,
            },
            token: {
                details: undefined,
            },
            applicationProperties: {
                'analytics.service.types': undefined,
                'default.provider.id': undefined,
                'digio.url': undefined,
                'logo.path': undefined,
                'macre.ui.url': undefined,
                'multiple.providers.enabled': undefined,
                'password.regex': undefined,
                'taxilla.build.version': undefined,
                'taxilla.js.minified': undefined,
                'taxilla.session.max.inactive.interval': undefined,
                'taxilla.version': undefined,
                'two.factor.authentication': undefined,
                'foureyecheck.enabled': undefined,
            },
            is4ecEnabled: false,
        };
    })
);

/**
 * Reset password reducer
 */
export const RESET_PASSWORD_REDUCER = createReducer(
    resetPasswordInitialState,
    on(authActions.GetSecurityQuestionsSuccess, (state, action) => {
        return {
            ...state,
            security: {
                secretQuestions: action.secretQuestions,
            },
        };
    }),
    on(authActions.FetchCaptchaSuccess, (state, action) => {
        return {
            ...state,
            captcha: {
                captcha: action.captcha,
                errorResponse: undefined,
                plain: action.plain,
                forgotPswd: action.forgotPswd,
                forgotUsername: action.forgotUsername,
            },
        };
    }),
    on(authActions.GetContextDetailWithKeySuccess, (state, action) => {
        return {
            ...state,
            context: {
                organization: action.organization,
                settings: action.settings,
                user: action.user,
            },
        };
    })
);

export const AUTH_REDUCER_KEY = 'auth';
export const RESET_PASSWORD_REDUCER_KEY = 'reset';
