import { Rule } from '../rule.class';
import { MasterField } from './masterfield.class';

export class TableDefinition {
    clusteringKey: any[];

    columnDefinitions: MasterField[];

    id: any[];

    indexKeys: any[];

    name: string;

    partitionKey: any[];

    rules: Rule[];

    constructor(data, keyColumns: string[], rules: Rule[]) {
        this.clusteringKey = data?.clusteringKey;
        this.id = data?.id;
        this.indexKeys = data?.indexKeys;
        this.name = data?.name;
        this.partitionKey = data?.partitionKey;
        this.columnDefinitions = [];
        this.rules = rules;
        if (data?.columnDefinitions && data.columnDefinitions.length > 0) {
            data.columnDefinitions.forEach((element) => {
                this.columnDefinitions.push(new MasterField(element, keyColumns.indexOf(element.id) > -1, this.rules));
            });
        }
    }

    getColumn = (columnId: string) => {
        return this.columnDefinitions.find((definition) => definition.id === columnId);
    };
}
