import { MastersMetaData } from './mastersmetadata.class';

export class Masters {
    constructor() {}

    setMasterTable = (key: string, response: any) => {
        this[key] = new MastersMetaData(response);
    };

    getTable = (tableId: string): MastersMetaData => {
        const tables = this;
        for (const key in tables) {
            if (key === tableId) {
                return <any>tables[key];
            }
        }
        return undefined;
    };

    getTableColumnNames = (tableId: string): Array<String> => {
        const table = this.getTable(tableId);
        return table.getAllColumnNames() || [];
    };

    getAllTables = () => {
        const tables = [];
        for (const key in this) {
            if (typeof this[key] !== 'function') {
                tables.push(this[key]);
            }
        }
        return tables;
    };
}
