import { MasterField } from './masterfield.class';
import { MasterLanguageTemplate, MasterMetaData } from './mastermetadata.class';

export class MastersMetaData {
    masterMetadata: MasterMetaData;

    permissions;

    masterItem: {
        createdDate?: string;
        id?: string;
        itemId?: string;
        itemName?: string;
        displayName?: string;
        organizationId?: string;
        providerId?: string;
        restApiServiceName?: string;
        serviceId?: string;
        type?: string;
        tags?: [];
    };

    constructor(data) {
        this.masterMetadata = new MasterMetaData(data?.masterMetadata || {});
        this.permissions = data?.permissions;
        this.masterItem = data?.masterItem || {};
        data?.languageData && this.applyLanguageTemplate(data.languageData);
    }

    getColumn = (columnId: string): MasterField => {
        const tableDefinition =
            this.masterMetadata.tableDefinition &&
            this.masterMetadata.tableDefinition.columnDefinitions &&
            this.masterMetadata.tableDefinition;
        return tableDefinition.getColumn(columnId);
    };

    getPrimaryColumns = () => {
        return this.masterMetadata.keyColumns || [];
    };

    getAllColumnNames = (): string[] => {
        const columns = [];
        const columnsData = this.masterMetadata.tableDefinition.columnDefinitions;
        columnsData?.forEach((element) => {
            columns.push(element.displayName || element.name);
        });
        return columns;
    };

    getAllColumnIds = (): string[] => {
        const columns = [];
        const columnsData = this.masterMetadata.tableDefinition.columnDefinitions;
        if (columnsData) {
            columnsData.forEach((element) => {
                columns.push(element.id);
            });
        }
        return columns;
    };

    getSearchableFields = (): string[] => {
        const fields = [];
        const columns = this.masterMetadata.tableDefinition.columnDefinitions;
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].searchable) {
                fields.push(columns[i].id);
            }
        }
        return fields;
    };

    getAllColumns = (): MasterField[] => {
        const columns = [];
        const columnsData = this.masterMetadata.tableDefinition.columnDefinitions;
        columnsData &&
            columnsData.forEach((element) => {
                columns.push(element);
            });
        return columns;
    };

    getAllColumnIdVsName = (): {
        name: string;
        displayName: string;
    }[] => {
        const columns = [];
        const columnsData = this.masterMetadata.tableDefinition.columnDefinitions;
        columnsData &&
            columnsData.forEach((column) => {
                const temp = {
                    name: column.id,
                    displayName: column.name,
                };
                columns.push(temp);
            });
        return columns;
    };

    public applyLanguageTemplate = (data: MasterLanguageTemplate) => this.masterMetadata.applyLanguageAlterations(data);
}
