import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CommonUtilsService } from 'taxilla-library';

import { AssetService } from '../../models/assetservice.class';
import { SHARED_REDUCER_KEY } from '../reducers';
import { getSubscribedAppsMap$ } from './apps.selector';

import type { SharedState } from '../states';
const currentState = createFeatureSelector<SharedState>(SHARED_REDUCER_KEY);

export const getLogoHref$ = createSelector(currentState, (state) => state.logoHref);

export const getsupportedRegions$ = createSelector(currentState, (state) => state.supportedRegions);

export const getLeftNavSearchText$ = createSelector(currentState, (state) => state.searchText);

export const getSelectedCategory$ = createSelector(currentState, (state) => state.selectedCategory);

export const getGroupsMap$ = createSelector(currentState, (state) => state.groupsMap);

export const getLeftNavMap$ = createSelector(currentState, (state) => state.leftNavMap);

export const getLeftNavCategory$ = createSelector(
    getLeftNavMap$,
    getSelectedCategory$,
    getLeftNavSearchText$,
    getSubscribedAppsMap$,
    (leftNav, category, searchText, appsMap) =>
        searchText?.length > 0
            ? filterNavMap(JSON.parse(JSON.stringify(leftNav?.find((item) => item.id === category))), searchText?.toLowerCase(), appsMap)
            : leftNav?.find((item) => item.id === category)
);

export const getLeftNavCategoryWithSearch$ = (selectedLeftNav: SharedState['leftNavMap'][0]) =>
    createSelector(getLeftNavSearchText$, getSubscribedAppsMap$, (searchText, appsMap) => {
        let selectedCategory = CommonUtilsService.cloneObject(selectedLeftNav);
        return searchText?.length > 0 ? filterNavMap(selectedCategory, searchText?.toLowerCase(), appsMap) : selectedCategory;
    });

const filterNavMap = (navItem: SharedState['leftNavMap'][0], searchText: string, appsMap: { [property: string]: AssetService }) => {
    navItem.apps = navItem.apps && filterApps(navItem.apps, searchText, appsMap);
    navItem.groups = navItem.groups && filterGroups(navItem.groups, searchText);
    navItem.packages = navItem.packages && (filterPackages(navItem.packages as any, searchText, appsMap) as any);
    return navItem;
};

const filterApps = (navApps: SharedState['leftNavMap']['0']['apps'], searchText: string, appsMap: { [property: string]: AssetService }) => {
    return navApps?.filter((app) => appsMap?.[app.serviceId]?.displayName?.toLowerCase()?.match(searchText));
};

const filterGroups = (navGroups: SharedState['leftNavMap']['0']['groups'], searchText: string) => {
    return navGroups?.filter((group) => group.id?.toLowerCase()?.match(searchText));
};

const filterPackages = (
    navPackages: SharedState['leftNavMap']['0']['packages'][0],
    searchText: string,
    appsMap: { [property: string]: AssetService }
) => {
    return navPackages?.filter((packageObject) => {
        if (packageObject.id?.toLowerCase()?.match(searchText)) {
            return true;
        }
        packageObject.apps = packageObject.apps && (filterApps(packageObject.apps as any, searchText, appsMap) as any);
        packageObject.groups = packageObject.groups && (filterGroups(packageObject.groups as any, searchText) as any);
        return packageObject.apps?.length > 0 || packageObject.groups?.length > 0;
    });
};
