<ng-container *ngIf="main">
    <button *ngIf="!isNewUI" (mouseover)="openSettingsMenu()" [matMenuTriggerFor]="settingsMenu" mat-button class="nav-item settingsGearIcon" title="Settings" #matSettingsMenuTrigger="matMenuTrigger">
        <img *ngIf="!isSettingsSelected" src="/assets/images/gear-grey.png" alt="">
        <img *ngIf="isSettingsSelected" src="/assets/images/gear-green.png" alt="">
        <span class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
        <span class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
    </button>
    <button *ngIf="isNewUI" mat-button class="header-button settingsGearIcon" (mouseover)="openSettingsMenu()" [matMenuTriggerFor]="settingsMenu" title="Settings" #matSettingsMenuTrigger="matMenuTrigger" disableRipple>
        <img src="/assets/images/new-ui/settings.png" alt="settings-icon">
        <span class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
    </button>
</ng-container>

<ng-container *ngIf="!main">
    <button *transloco="let translate;" (mouseenter)="openSettingsMenu(menu)" (mouseleave)="closeSettingsMenu()" mat-menu-item (click)="setRoute(menu)" class="headerSettingItem settingsGearIcon" [class.activeSetting]="menu.selected" [matMenuTriggerFor]="settingsMenu" #matSettingsMenuTrigger="matMenuTrigger" [title]="!menu.noTranslation ? translate(menu.name) : menu.name">
        <mat-icon [hidden]="!menu.icon">{{menu.icon}}</mat-icon>
        <span>{{!menu.noTranslation ? translate(menu.name) : menu.name}}</span>
    </button>
</ng-container>

<mat-menu #settingsMenu="matMenu" class="settingsMenuContainer {{main ? '' : 'noTopBorder'}}" [overlapTrigger]="false">
    <span (mouseleave)="closeSettingsMenu()">
        <div [hidden]="!menu.childrenLoading" class="showingLoaderIcon">
            <img *ngIf="!isIE" src="/assets/images/loader.svg" alt="Loading...">
            <mat-spinner *ngIf="isIE"></mat-spinner>
        </div>
        <div *transloco="let translate;">
            <ng-container *ngFor="let item of menu.children">
                <ng-container *ngIf="item.hasChildren">
                    <app-settings-nested-menu [menu]="item" (childMenuClosedEvent)="childMenuClosedEventListener()"></app-settings-nested-menu>
                </ng-container>
                <ng-container *ngIf="!item.hasChildren">
                    <button (mouseenter)="openSettingsMenu(item)" mat-menu-item (click)="setRoute(item)" class="headerSettingItem" [class.activeSetting]="item.selected" [title]="!item.noTranslation ? translate(item.name) : item.name">
                        <mat-icon [hidden]="!item.icon">{{item.icon}}</mat-icon>
                        <span>{{!item.noTranslation ? translate(item.name) : item.name}}</span>
                    </button>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="menu.children?.length === 0 && !menu.childrenLoading">
                <button mat-menu-item class="headerSettingItem">
                    <span>{{translate('No Records Found')}}</span>
                </button>
            </ng-container>
        </div>
    </span>
</mat-menu>
