<div class="setPasswordPage">
    <div class="setPasswordContainer" *transloco="let translate;">
        <mat-card>
            <div class="setPasswordMainContainer">
                <div class="row">
                    <div class="col-4 setPasswordInfoContainer">
                        <div class="setPasswordWelcomeContainer">
                            <div class="setPasswordSubWelcomeContainer">
                                <div class="signupSubInfoTitle">
                                    <mat-card-title class="text-center">
                                        {{translate('Welcome to')}}
                                    </mat-card-title>
                                </div>
                                <div class="signupSubInfoLogo">
                                    <img src="assets/images/{{R.LOGO_HREF}}logo.png" alt="logo">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 setPasswordDetailsContainer">
                        <div class="setPasswordCapturedDetails">
                            <div class="setPasswordTitle">
                                <mat-card-title>{{translate('Reset Password')}}</mat-card-title>
                            </div>
                            <div class="setPassword_detailBlock row">
                                <label class="col-5">{{translate ('Organization Name')}}:</label>
                                <span class="col-7">{{organization && organization.name}}</span>
                            </div>
                            <div class="setPassword_detailBlock row">
                                <label class="col-5">{{translate('Organization Code')}} :</label>
                                <span class="col-7">{{organization && organization.orgLoginId}}</span>
                            </div>
                            <div class="setPassword_detailBlock row">
                                <label class="col-5">{{translate('User ID')}} :</label>
                                <span class="col-7">{{user && user.userId}}</span>
                            </div>
                        </div>
                        <div class="setPasswordNonCapturedDetails">
                            <div class="row">
                                <div class="col-6">
                                    <material-input-password [description]="settings?.passwordPolicyMsg || passwordPolicyTitle" [autofocus]="true" [placeholder]="translate('Password')" [errors]="errors.password" [model]="passwords.password" [classList]="[]" [debounceAfter]="1000" (modelChange)="passwords.password = $event">
                                    </material-input-password>
                                </div>
                                <div class="col-6">
                                    <material-input-password [autofocus]="false" [placeholder]="translate('Confirm Password')" [errors]="errors.confirmPassword" [model]="passwords.confirmPassword" [classList]="[]" [debounceAfter]="1000" (modelChange)="passwords.confirmPassword = $event">
                                    </material-input-password>
                                </div>
                            </div>
                        </div>
                        <div class="securityQuestionsDiv">
                            <div>
                                <span>{{translate('Please select and answer the below questions for security reasons')}}:</span>
                            </div>
                            <div *ngFor="let question of securityQuestionsStructure; let i = index">
                                <div class="row">
                                    <div class="col-6">
                                        <material-select [options]="secretQuestions" [placeholder]="translate(question.displayName)" [name]="question.displayName" [optionDisplayNameProperty]="'value'" [optionValueProperty]="'value'" [required]="true" [model]="question.key" (modelChange)="question.key = $event;checKQuestions($event,question)" [errors]="question.errors.key" [title]="question.key"> </material-select>
                                    </div>
                                    <div class="col-5">
                                        <material-input-password [autofocus]="false" [placeholder]="translate('Answer')" [model]="question.value" (modelChange)="question.value = $event" [name]="'qstionName_' + i" [id]="'qstionId_' + i" [required]="true" [errors]="question.errors.value"></material-input-password>
                                    </div>
                                    <div class="col-1 authQuestionsAction">
                                        <button mat-mini-fab color="warn" [title]="translate('Remove')" [disabled]="securityQuestionsStructure.length === mmfaQuestions" (click)="securityQuestionsStructure.splice(i, 1)">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row" *ngIf="securityQuestionsStructure.length <= 4">
                                    <div class="col-6">

                                    </div>
                                    <div class="col-5">

                                    </div>
                                    <div class="col-1 authQuestionsAction">
                                        <button mat-mini-fab color="primary" [title]="translate('Add')" [disabled]="securityQuestionsStructure.length >= 4" (click)="pushQuestions(1)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row signupCaptcha">
                            <label class="col-6 signupDetailLabel signupCaptchaLabel">
                                <div class="signupCaptchaImg">
                                    <img *ngIf="captchaUrl && captchaUrl.length > 0" src="data:image/jpeg;base64,{{captchaUrl}}" class="" alt="captcha">
                                </div>
                                <mat-icon class="material-icons cursorPointer" [title]="translate('Refresh Captcha')" (click)="getCaptcha()">refresh</mat-icon>
                            </label>
                            <div class="signupDetailValue col-6 signupCaptchaInput">
                                <material-input [autofocus]="false" [placeholder]="translate('Captcha')" [required]="true" [errors]="errors.captcha" [model]="captcha" [classList]="[]" [debounceAfter]="1000" (modelChange)="captcha = $event" (debounceEventHandler)="checkCaptcha()">
                                </material-input>
                            </div>
                        </div>
                        <div class="setPasswordSubmitContainer">
                            <div class="setPassword text-center">
                                <material-button [label]="translate('Confirm & Proceed')" (onTrigger)="setPassword()"></material-button>
                            </div>
                            <div class="signupRedirectToLogin">
                                <span class="btn-link" [routerLink]="'/'">{{translate('Want to login?')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
