import { createReducer, on } from '@ngrx/store';

import { ClearInstanceData, LoadingReports, SessionLogoutSuccessfull, SetReports } from '../actions';
import { reportsState } from '../states';

export const REPORTS_REDUCER = createReducer(
    reportsState,
    on(SetReports, (state, action) => {
        return {
            ...state,
            instanceIdVsReports: {
                ...state.instanceIdVsReports,
                [action.instanceId]: action?.reports,
            },
        };
    }),
    on(ClearInstanceData, (state, action) => {
        return {
            ...state,
            instanceIdVsReports: {
                ...state.instanceIdVsReports,
                [action.instanceId]: undefined,
            },
        };
    }),
    on(LoadingReports, (state, action) => {
        return {
            ...state,
            loadingReports: {
                ...state.loadingReports,
                [action.instanceId]: action.loading,
            },
        };
    }),
    on(SessionLogoutSuccessfull, (state, action) => {
        return {
            instanceIdVsReports: {},
            loadingReports: {},
        };
    })
);

export const REPORTS_REDUCER_KEY = 'reports';
