<div class="roleFieldsContainer" *transloco="let translate;">
    <div class="row roleFieldsContainerRow">
        <div class="col-3">
            <material-input [autofocus]="false" [placeholder]="translate('Role Name')" [model]="rolesObject.roleName" (modelChange)="rolesObject.roleName = $event" [name]="'roleName'" [id]="'roleName'" [required]="true" [errors]="errors.roleName"></material-input>
        </div>
        <div class="col-3">
            <material-input [autofocus]="false" [placeholder]="translate('Display Name')" [model]="rolesObject.displayName" (modelChange)="rolesObject.displayName = $event" [name]="'displayName'" [id]="'displayName'" [required]="true" [errors]="errors.displayName"></material-input>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <material-input [autofocus]="false" [placeholder]="translate('Description')" [model]="rolesObject.description" (modelChange)="rolesObject.description= $event" [name]="'description'" [id]="'description'" [required]="true" [errors]="errors.description"></material-input>
        </div>
    </div>
</div>
