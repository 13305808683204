import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

export class MasterRequest {
    bulkReportDownload: boolean;

    bulkUpload: boolean;

    createdBy: string;

    createdDate: string;

    doneForNow: boolean;

    erroneous: boolean;

    fileName: string;

    requestCompleted: boolean;

    requestId: string;

    requestState: string;

    sourceType: string;

    transformationName: string;

    userName: string;

    downloadable?: boolean;

    constructor(request) {
        if (request) {
            this.bulkReportDownload = request.bulkReportDownload;
            this.bulkUpload = request.bulkUpload;
            this.createdBy = request.createdBy;
            this.createdDate = CommonUtilsService.transformDateToLocale(
                request.createdDate,
                'YYYY-MM-DDTHH:MM:SSZ',
                'ddMMMyyyy HH:MM:SS AM',
                false
            );
            this.doneForNow = request.doneForNow;
            this.erroneous = request.erroneous;
            this.fileName = request.fileName;
            this.requestCompleted = request.requestCompleted;
            this.requestId = request.requestId;
            this.requestState = this.getRequestState(request.requestState);
            this.sourceType = request.sourceType;
            this.transformationName = request.transformationName;
            this.userName = request.userName;
            this.downloadable = true;
        }
    }

    getRequestState = (state) => {
        let fieldValue;
        switch (state) {
            case 'IN_PROGRESS':
                fieldValue = 'In-Progress';
                break;
            case 'REQUEST_COMPLETED':
                fieldValue = 'Completed';
                break;
            case 'TERMINATED':
                fieldValue = 'Terminated';
                break;
            default:
                fieldValue = state;
                break;
        }
        return fieldValue;
    };
}
