import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[debounce]',
})
export class DebounceDirective implements OnInit, OnDestroy {
    @Input() debounce: any;

    @Output('eventHandler') onKeyUpEventHandler = new EventEmitter();

    unSubscribe = new Subject<void>();

    constructor(public el: ElementRef) {}

    ngOnInit() {
        if (this.el && this.el.nativeElement) {
            observableFromEvent(this.el.nativeElement, 'keyup')
                .pipe(debounceTime(parseInt(this.debounce, undefined)))
                .pipe(takeUntil(this.unSubscribe))
                .pipe(debounceTime(parseInt(this.debounce, undefined)))
                .subscribe((value) => {
                    this.onKeyUpEventHandler.emit(value);
                });
        }
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
