import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';

import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ClearProcessLogs, GetProcessLogs } from '../../store/actions/notifications-event.actions';
import { getNotifications$ } from '../../store/selectors';

@Component({
    selector: 'app-process-log',
    templateUrl: './process-log.component.html',
    styleUrls: ['./process-log.component.scss'],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ],
})
export class ProcessLogComponent implements OnInit, OnDestroy {
    @Input() resourceId: string;
    allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([]);
    selectedColumns: string[] = [];
    tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    tableColumns = [
        {
            name: 'Header',
            displayName: translate('Header') as string,
            show: true,
        },
        {
            name: 'Message',
            displayName: translate('Message') as string,
            show: true,
        },
        {
            name: 'createdOn',
            displayName: translate('Created Time') as string,
            show: true,
        },
    ];
    processLogsData = [];
    unSubscribe = new Subject<void>();
    isPopup: boolean = false;

    constructor(public _utils: UtilsService, private store$: Store) {}

    translateMsg = (msg: string): string => translate(msg);

    convertTimeAndAppend = (tableData) => {
        tableData.forEach((obj) => {
            if (obj && obj.createdOn) {
                obj.createdOn = this._utils.transformDateToLocale(obj.createdOn, 'YYYY-MM-DDTHH:MM:SSZ', 'ddMMMyyyy HH:MM:SS AM', false);
            }
        });
        this.processLogsData = tableData;
        this.createTableColumns();
    };

    createTableColumns = () => {
        const columns: TableColumn[] = [];
        this.selectedColumns = [];
        this.tableColumns &&
            this.tableColumns.forEach((column) => {
                columns.push({
                    icon: undefined,
                    id: column.name,
                    name: column.displayName,
                    options: undefined,
                    type: 'text',
                    hide: !column.show,
                });
            });
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].hide === false) {
                this.selectedColumns.push(columns[i].id);
            }
        }
        this.allTableColumns.next(columns);
        this.updateTableData();
    };

    updateTableData = () => {
        const tableData: TableData[] = [];
        this.processLogsData &&
            this.processLogsData.forEach((record) => {
                const recordData: TableData = {
                    recordId: record.eventId,
                };
                let keyValue = Object.entries(record.payload)[0];
                recordData['Header'] = keyValue[0] as any;
                recordData['Message'] = keyValue[1] as any;
                recordData['createdOn'] = record.createdOn;
                tableData.push(recordData);
            });
        this.tableData.next(tableData);
    };

    refreshNotifications = () => {
        this.store$.dispatch(ClearProcessLogs({ resourceId: this.resourceId, fetchSize: 1000, pagingState: undefined }));
        this.store$.dispatch(GetProcessLogs({ resourceId: this.resourceId, fetchSize: 1000, pagingState: undefined }));
    };

    ngOnInit(): void {
        this.store$.dispatch(GetProcessLogs({ resourceId: this.resourceId, fetchSize: 1000, pagingState: undefined }));

        this.store$
            .select(getNotifications$(this.resourceId))
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((response) => {
                const tableData = CommonUtilsService.cloneObject(response);
                this.convertTimeAndAppend(tableData);
            });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
