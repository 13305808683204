<div class="groups mb-3" *transloco="let translate">
    <div class="headerDiv" [class.mat-elevation-z8]="activeCategory">
        <h1 class="transactions">{{activeCategory?.length > 0 ? translate(activeCategory | pascalToCamel) : ''}}</h1>
        <strong class="categoryName" *ngIf="selectedLeftNav">{{selectedLeftNav.name}}</strong>
        <div class="assetSearch">
            <mat-form-field *transloco="let translate">
                <input matInput placeholder="Search {{((selectedLeftNav?.name ? selectedLeftNav?.name : activeCategory || 'Transactions')) | pascalToCamel}}" type="text" [(ngModel)]="filterAppName" (keyup)="searchPackage($event)">
                <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchApp()'>
                    <mat-icon *ngIf='filterAppName'>
                        close </mat-icon>
                    <mat-icon *ngIf='!filterAppName'>
                        search </mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="others">
        <div class="otherpackages mat-elevation-z8" *ngFor="let package of packages; trackBy: trackById">
            <app-left-nav-apps-template (bridgeDialogOpen)="bridgeDialogOpen.emit($event)" (closeMenu)="closeMatmenu()" [package]="package"></app-left-nav-apps-template>
        </div>
        <div class="otherpackages mat-elevation-z8" *ngIf="groups?.length > 0">
            <ng-container *ngFor="let group of groups; trackBy: trackById">
                <app-left-nav-apps-template (bridgeDialogOpen)="bridgeDialogOpen.emit($event)" (closeMenu)="closeMatmenu()" [groupId]="group.id"></app-left-nav-apps-template>
            </ng-container>
        </div>
        <div class="otherpackages mat-elevation-z8" *ngIf="apps?.length > 0">
            <div class="package">
                <mat-icon class="material-symbols-outlined packageIcon"> web_stories </mat-icon>
                <span class="packageName">{{translate('category_' + (activeCategory|| selectedLeftNav.id))}}</span>
            </div>
            <ng-container *ngFor="let app of apps; trackBy: trackById">
                <app-left-nav-apps-template (bridgeDialogOpen)="bridgeDialogOpen.emit($event)" (closeMenu)="closeMatmenu()" [serviceId]="app.serviceId"></app-left-nav-apps-template>
            </ng-container>
        </div>
        <ng-container *ngIf="packages?.length === 0 && groups?.length === 0 && apps?.length === 0 && filterAppName.length === 0">
            <div class="noTransactionsFoundContainer">
                <div class="noTransactionsFound">
                    {{translate('No Apps Subscribed.')}}
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="packages?.length === 0 && groups?.length === 0 && apps?.length === 0 && filterAppName.length > 0">
            <div class="noTransactionsFoundContainer">
                <div class="noTransactionsFound">
                    {{translate('No Apps Found.')}}
                </div>
            </div>
        </ng-container>
    </div>
</div>
