import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationTabStructure,
    IntergrationFieldType,
} from '../../interface/integrationmodal.interface';
import { IntegrationRecord } from './integrationrecord.class';

export class IntegrationRecordPayload {
    configuredData: string;

    name: string;

    organizationId: string;

    type: string;

    id: string;

    appId?: string;

    active?: boolean;

    constructor(record: IntegrationRecord) {
        const masterField = record.getMasterField();
        const masterInUse = masterField && masterField.value;

        const setFieldValue = (payloadObject: any, fieldId: string, value: any, encodingType: string, valueAsArrayInPayload: boolean) => {
            if (encodingType && !masterInUse) {
                switch (encodingType) {
                    case 'btoa':
                        payloadObject[fieldId] = window.btoa(value);
                        break;
                    default:
                        payloadObject[fieldId] = value !== undefined ? value : '';
                        break;
                }
            } else {
                value = valueAsArrayInPayload ? (!Array.isArray(value) ? [value] : value) : value;
                payloadObject[fieldId] = value !== undefined ? value : '';
            }
        };

        const getFieldType = (
            field: IntegrationModal['fields'][0],
            tabRecord?: IntegrationTabStructure['records'][0]
        ): IntegrationConfigField['type'] => {
            let fieldType: IntegrationConfigField['type'] = 'TEXT';
            if (field.inputTypeConditonal && Array.isArray(field.type)) {
                for (let i = 0; i < field.type.length; i++) {
                    const fieldTypeObj = (field.type as IntergrationFieldType[])[i];
                    let requiredField;
                    if (tabRecord) {
                        requiredField = tabRecord.fields.find((recordField) => recordField.id === fieldTypeObj.fieldId);
                        if (!requiredField) {
                            requiredField = tabRecord.recordActions.find((recordAction) => recordAction.id === fieldTypeObj.fieldId);
                        }
                    } else {
                        requiredField = record.getRecordField(fieldTypeObj.fieldId);
                    }
                    if (fieldTypeObj.requiredValue !== undefined) {
                        if (fieldTypeObj.requiredValue === requiredField.value) {
                            fieldType = fieldTypeObj.type;
                        }
                    }
                }
            } else {
                fieldType = field.type;
            }
            if (field.array && (!field.values || field.values.length === 0)) {
                field.values = [{ value: '' }];
            }
            return fieldType;
        };

        const getDefaultValue = (
            field: IntegrationConfigField,
            tabRecord: IntegrationTabStructure['records'][0]
        ): IntegrationConfigField['type'] => {
            let value: any;
            const fieldType = getFieldType(field, tabRecord);
            switch (fieldType) {
                case 'TEXT':
                    value = '';
                    break;
                case 'DROPDOWN':
                    value = '';
                    break;
                case 'CHECKBOX':
                    value = false;
                    break;
                case 'INPUT_RADIO':
                    value = false;
                    break;
                case 'TEXTAREA':
                    value = '';
                    break;
                default:
                    value = '';
                    break;
            }
            return value;
        };

        this.name = record.recordData.fields.find((field) => field.id === 'name').value.trim() as string;
        this.organizationId = record.organizationId;
        this.type = record.type;
        const payload = {};
        this.id = record.id;
        this.active = record.active;
        record.recordData.fields
            .filter((field) => ['name'].indexOf(field.id) === -1)
            .forEach((field) => {
                if (field.array || field.conditionalArray) {
                    const values = [];
                    if (!field.values || field.values.length === 0) {
                        field.values = [
                            {
                                value: field.value,
                            },
                        ];
                    }
                    field.values.forEach((valueObject) => {
                        if (
                            (typeof (valueObject.value as any) === 'string' && (valueObject.value as any).length > 0) ||
                            typeof (valueObject.value as any) === 'boolean'
                        ) {
                            values.push(valueObject.value);
                        }
                    });
                    field.value = values;
                }
                if (field.value !== undefined) {
                    setFieldValue(payload, field.id, field.value, field.encodingType, field.valueAsArrayInPayload);
                }
            });
        record.recordData.subSections &&
            record.recordData.subSections.forEach((subSection) => {
                subSection.tabs &&
                    subSection.tabs.forEach((tab) => {
                        if (tab.array) {
                            payload[tab.id] = [];
                            tab.records.forEach((tabRecord) => {
                                const tabRecordPayload = {};
                                tabRecord.fields.forEach((field) => {
                                    let value = field.value;
                                    if (value === null || value === undefined) {
                                        value = getDefaultValue(field, tabRecord);
                                    }
                                    setFieldValue(tabRecordPayload, field.id, value, field.encodingType, field.valueAsArrayInPayload);
                                });
                                tabRecord.recordActions.forEach((field) => {
                                    setFieldValue(tabRecordPayload, field.id, field.value, field.encodingType, field.valueAsArrayInPayload);
                                });
                                Object.keys(tabRecordPayload).length > 0 && payload[tab.id].push(tabRecordPayload);
                            });
                        } else {
                            if (tab.noSubObjectForFields) {
                                if (tab.fields.length === 1) {
                                    tab.fields.forEach((field) => {
                                        if (field.value !== undefined) {
                                            setFieldValue(payload, tab.id, field.value, field.encodingType, field.valueAsArrayInPayload);
                                        }
                                    });
                                } else {
                                    console.log('More fields are present');
                                }
                            } else {
                                payload[tab.id] = {};
                                tab.fields.forEach((field) => {
                                    if (field.value !== undefined && field.value !== '') {
                                        setFieldValue(
                                            payload[tab.id],
                                            field.id,
                                            field.value,
                                            field.encodingType,
                                            field.valueAsArrayInPayload
                                        );
                                    }
                                });
                                if (Object.keys(payload[tab.id]).length === 0) {
                                    delete payload[tab.id];
                                }
                            }
                        }
                    });
            });
        delete payload['createdDate'];
        delete payload['createdByUserName'];
        delete payload['modifiedByUserName'];
        delete payload['lastModifiedDate'];
        this.configuredData = JSON.stringify(payload) || '';
    }
}
