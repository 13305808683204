import { Injectable } from '@angular/core';
import { TaxillaApiService } from '@encomply/core/services/taxillaapi/taxillaapi.service';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Chain } from '../../models/chain.interface';
import { Transformation } from '../../models/transformation';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import {
    AlertError,
    AlertSuccess,
    GetActiveTransformationsByType,
    GetAllTransformations,
    GetInboundDetailedTransformations,
    getIntegrationsChains,
    GetMasterSchedulerTransformations,
    GetOutboundChains,
    GetTransformationNamesInChain,
    SetActiveTransformationsByType,
    SetChainDisplayNames,
    SetInboundDetailedTransformations,
    SetIntegrationsChains,
    SetMasterSchedulerTransformations,
    SetOutboundChains,
    SetTransformationNamesInChain,
    SetTransformations,
    SetTransformationsLoading,
    SetUpdatedTransformations,
    UpdateActiveTransformationsByType,
} from '../actions';
import {
    getAllTransformationsServicesMap$,
    getCurrentLocationIdsHierarchy$,
    getCurrentOrganizationId$,
    getOutboundIntegrationsChains$,
    getSubscribedAppsMap$,
} from '../selectors';

@Injectable()
export class TransformationEffects {
    constructor(private actions$: Actions, private _api: ApiService, private _taxilla: TaxillaApiService, private store$: Store) {}

    private getInboundDetailedTransformations = createEffect(() =>
        this.actions$.pipe(
            ofType(GetInboundDetailedTransformations),
            delay(10),
            withLatestFrom(
                this.store$.select(getAllTransformationsServicesMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$)
            ),
            mergeMap(([action, transformationServicesMap, organizationId, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                const serviceMap = transformationServicesMap?.[action.serviceId];
                const loading = serviceMap?.loading?.inbound;
                if (Object.keys(serviceMap?.transformations?.inboundDetailed || {})?.length > 0 && !action.forceFetch) {
                    return [];
                }
                if ((!action.assetMetaUid && !app?.assetMetaUId) || loading) {
                    return [];
                }
                this.store$.dispatch(
                    SetTransformationsLoading({
                        category: 'inbound',
                        loading: true,
                        organizationId: organizationId,
                        serviceId: action?.serviceId,
                    })
                );
                return from(
                    this._api.assets.getAllMapTransformations({
                        assetMetaUId: action.assetMetaUid || app?.assetMetaUId,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetTransformationsLoading({
                                category: 'inbound',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        const transformations: any = res.transformations;
                        this.mapTransformationSources(transformations, res?.transformationSources, res?.chainNameVsChainDisplayName);
                        const relativeOrganizations = res.organizations;
                        return SetInboundDetailedTransformations({
                            serviceId: action.serviceId,
                            organizationId,
                            inbound: res.transformations,
                            relativeOrganizations,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(
                            SetTransformationsLoading({
                                category: 'inbound',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return of(AlertError({ message: e }));
                    })
                );
            })
        )
    );

    private mapTransformationSources = (orgMap, transformationSources, chainNames: { [property: string]: string }) => {
        Object.keys(orgMap || {}).forEach((orgId) => {
            Object.keys(orgMap[orgId]).forEach((chainName) => {
                const transformation = orgMap?.[orgId]?.[chainName];
                const transformationName = transformation?.name;
                transformation.id = UtilsService.guid();
                transformation.chainName = chainName;
                transformation.chainDisplayName = chainNames?.[chainName];
                transformation.displayName = transformation.displayName || transformation.name;
                transformation.isAssetToAsset =
                    transformation.sourceParts?.find((sourcePart) => sourcePart.sourceType === 'ASSET') &&
                    transformation.assetToAssetMetadata !== undefined;
                transformation.isSearchAsset =
                    transformation.sourceParts?.find((sourcePart) => sourcePart.sourceType === 'ASSET') &&
                    transformation.assetToAssetMetadata === undefined;
                transformation.isFileUpload = !!transformation.sourceParts?.find(
                    (sourcePart) => sourcePart.sourceType === 'FILE_UPLOAD' || sourcePart.sourceType !== 'ASSET'
                );
                const sourceDetails = transformationSources?.[orgId]?.[transformationName];
                if (!sourceDetails) {
                    return;
                }
                orgMap[orgId][chainName]?.sourceParts?.forEach((sourcePart) => {
                    sourcePart.appDetails = sourceDetails[sourcePart.name];
                });
            });
        });
    };

    private getChainNames = createEffect(() =>
        this.actions$.pipe(
            ofType(GetInboundDetailedTransformations),
            delay(10),
            withLatestFrom(this.store$.select(getAllTransformationsServicesMap$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, transformationServicesMap, organizationId]) => {
                const serviceMap = transformationServicesMap?.[action.serviceId];
                const loading = serviceMap?.loading?.chainNames;
                if (serviceMap?.chainDisplayNames && !action.forceFetch) {
                    return [];
                }
                if (loading) {
                    return [];
                }
                this.store$.dispatch(
                    SetTransformationsLoading({
                        category: 'chainNames',
                        loading: true,
                        organizationId: organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.assets.getChainDisplayNames({
                        restApiName: action.restApiName,
                        assetId: action.assetMetaUid,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetTransformationsLoading({
                                category: 'chainNames',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return SetChainDisplayNames({
                            serviceId: action.serviceId,
                            organizationId,
                            chainVsDisplaynames: res?.chainNameVsChainDisplayName,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(
                            SetTransformationsLoading({
                                category: 'chainNames',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return of(AlertError({ message: e }));
                    })
                );
            })
        )
    );

    private setChainNames = createEffect(() =>
        this.actions$.pipe(
            ofType(SetChainDisplayNames, SetInboundDetailedTransformations),
            delay(10),
            withLatestFrom(this.store$.select(getAllTransformationsServicesMap$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, transformationsServicesMap, organizationId]) => {
                const service = transformationsServicesMap?.[action.serviceId];
                if (Object.keys(service?.chainDisplayNames || {})?.length > 0 || !service?.chainDisplayNames) {
                    return [];
                }
                const chainNames = service.chainDisplayNames;
                const newMap = CommonUtilsService.cloneObject(service);
                const inBoundTransformations = newMap.transformations?.inBoundTransformations;
                const { relativeOrganizations } = service;
                inBoundTransformations?.transformations?.forEach((transformation) => {
                    transformation.displayName = transformation.displayName || chainNames[transformation.id || transformation.name];
                });
                return of(
                    SetUpdatedTransformations({
                        serviceId: action.serviceId,
                        organizationId,
                        inbound: inBoundTransformations,
                        relativeOrganizations,
                    })
                );
            })
        )
    );

    private getAllTransformations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAllTransformations),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAllTransformationsServicesMap$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([action, appsMap, services, organizationId]) => {
                const app = appsMap?.[action.serviceId];
                const transformations = services?.[action.serviceId]?.transformations;
                const loading = services?.[action.serviceId]?.loading?.outbound;
                if (((transformations?.outbound?.length > 0 && transformations?.inbound?.length > 0) || loading) && !action.forceFetch) {
                    return [];
                }
                this.store$.dispatch(
                    SetTransformationsLoading({
                        category: 'outbound',
                        loading: true,
                        organizationId: organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.assets.getAllTransformations({
                        assetMetaUId: app?.assetMetaUId,
                        assetName: app?.name,
                        noAlerts: action?.noAlerts,
                    })
                ).pipe(
                    map((res: any) => {
                        this.store$.dispatch(
                            SetTransformationsLoading({
                                category: 'outbound',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        let allTransformations: { INBOUND: Transformation[]; OUTBOUND: Transformation[] } = {
                            INBOUND: res?.response?.allTransformations?.['INBOUND'] as Transformation[] | [],
                            OUTBOUND: res?.response?.allTransformations?.['OUTBOUND'] as Transformation[] | [],
                        };
                        return SetTransformations({
                            serviceId: action.serviceId,
                            organizationId,
                            transformations: allTransformations,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(
                            SetTransformationsLoading({
                                category: 'outbound',
                                loading: false,
                                organizationId: organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return of(AlertError({ message: e.msg }));
                    })
                );
            })
        )
    );

    private getActiveTransformationsByType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetActiveTransformationsByType),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAllTransformationsServicesMap$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([action, appsMap, services, organizationId]) => {
                const app = appsMap?.[action.serviceId];
                const transformations = services?.[action.serviceId]?.transformations;
                if (
                    ((action.transformationType === 'INBOUND' && transformations?.activeTransformations?.inbound?.length > 0) ||
                        (action.transformationType === 'OUTBOUND' && transformations?.activeTransformations?.outbound?.length > 0)) &&
                    !action.forceFetch
                ) {
                    return [];
                }
                return from(
                    this._api.assets.getActiveTransformationsByType({
                        transformationType: action.transformationType,
                        assetRestAPIName: app?.restApiName,
                        noAlerts: action?.noAlerts,
                    })
                ).pipe(
                    map((res: any) => {
                        return SetActiveTransformationsByType({
                            serviceId: action.serviceId,
                            organizationId,
                            transformationNames: (res.response.transformations as string[]) || [],
                            transformationType: action.transformationType,
                        });
                    }),
                    catchError((e) => {
                        return of(AlertError({ message: e.msg }));
                    })
                );
            })
        )
    );

    private updateActiveTransformationsByType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateActiveTransformationsByType),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAllTransformationsServicesMap$)
            ),
            mergeMap(([action, appsMap, organizationId, transformationMap]) => {
                const app = appsMap?.[action.serviceId];
                const active =
                    transformationMap?.[action.serviceId]?.transformations?.activeTransformations?.[
                        action.transformationType?.toLowerCase()
                    ];
                if (JSON.stringify(active) === JSON.stringify(action.activeTrNames)) {
                    return [];
                }
                return from(
                    this._api.assets.updateActiveTransformationsByType({
                        transformationType: action.transformationType,
                        activeTrNames: action.activeTrNames,
                        assetRestAPIName: app?.restApiName,
                        noAlerts: action?.noAlerts,
                    })
                ).pipe(
                    map((res: any) => {
                        this.store$.dispatch(
                            AlertSuccess({
                                message: action.message
                                    ? translate(action.message)
                                    : translate('Transformation activated / deactivated successfully'),
                            })
                        );
                        CommonUtilsService.removeFromStorage('undefined_transformations');
                        this.store$.dispatch(
                            GetInboundDetailedTransformations({
                                serviceId: action.serviceId,
                                assetMetaUid: app?.assetMetaUId,
                                restApiName: app?.assetMetaUId,
                                forceFetch: true,
                                noAlerts: true,
                            })
                        );
                        return SetActiveTransformationsByType({
                            serviceId: action.serviceId,
                            organizationId,
                            transformationNames: action.activeTrNames,
                            transformationType: action.transformationType,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e.msg })))
                );
            })
        )
    );

    private getMasterSchedulerTransformations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetMasterSchedulerTransformations),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                return from(
                    this._taxilla.transformations.getAssetToAssetChains({
                        assetId: action?.assetId,
                        assetName: action?.assetName,
                        noAlerts: action?.noAlerts,
                    })
                ).pipe(
                    map((res: any) => {
                        let allTransformations: { INBOUND: Chain[]; OUTBOUND: Chain[] } = {
                            INBOUND: res?.response?.chains?.['INBOUND'] as Chain[] | [],
                            OUTBOUND: res?.response?.chains?.['OUTBOUND'] as Chain[] | [],
                        };
                        return SetMasterSchedulerTransformations({
                            serviceId: action?.serviceId,
                            organizationId,
                            transformations: allTransformations,
                        });
                    }),
                    catchError((e) => {
                        return of(AlertError({ message: e.msg }));
                    })
                );
            })
        )
    );

    private getIntegrationsChains$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getIntegrationsChains),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getOutboundIntegrationsChains$)),
            mergeMap(([action, organizationId, chains]) => {
                if (chains.length > 0) {
                    return [];
                }
                return this._taxilla.transformations.getChains(action).pipe(
                    map((res: any) => {
                        let chains: { INBOUND: Chain[]; OUTBOUND: Chain[] } = {
                            INBOUND: res?.response?.chains?.['INBOUND'] as Chain[] | [],
                            OUTBOUND: res?.response?.chains?.['OUTBOUND'] as Chain[] | [],
                        };

                        return SetIntegrationsChains({
                            serviceId: action.serviceId,
                            organizationId,
                            chains,
                        });
                    })
                );
            })
        )
    );

    private getOutboundChains$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetOutboundChains),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getCurrentLocationIdsHierarchy$),
                this.store$.select(getAllTransformationsServicesMap$)
            ),
            mergeMap(([action, organizationId, appsMap, providerIds, transformationsMap]) => {
                const app = appsMap?.[action.serviceId];
                const chains = transformationsMap?.[action.serviceId]?.transformations?.chains;
                if (chains?.length > 0) {
                    return [];
                }
                return this._taxilla.instances.getTransformationChains(app?.assetMetaUId).pipe(
                    map((res: any) => {
                        const organizations = res.organizations;
                        const repoIdVsTransformations = res.repoIdVsTransformations;
                        if (Object.keys(res.repoIdVsTransformations).length === 0) {
                            return AlertError({ message: translate('No Excel/CSV transformations exists for the asset') });
                        }
                        let transformationsData = [];
                        for (const orgId in repoIdVsTransformations) {
                            if (repoIdVsTransformations.hasOwnProperty(orgId)) {
                                const newTransformationsGroup = {
                                    orgId: '',
                                    orgName: '',
                                    values: [],
                                };
                                newTransformationsGroup.orgId = orgId;
                                newTransformationsGroup.orgName = organizations[orgId];
                                newTransformationsGroup.values = repoIdVsTransformations[orgId];
                                transformationsData.push(newTransformationsGroup);
                            }
                        }
                        const chains = this.changeTransformationsHierarchy(providerIds, transformationsData);
                        return SetOutboundChains({
                            serviceId: action.serviceId,
                            chains,
                            organizationId,
                        });
                    }),
                    catchError((e) => [])
                );
            })
        )
    );

    private getTransformationNamesInChain = createEffect(() =>
        this.actions$.pipe(
            ofType(GetTransformationNamesInChain),
            withLatestFrom(this.store$.select(getAllTransformationsServicesMap$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, transformationsMap, orgId]) => {
                const transformationNames = transformationsMap?.[action.serviceId]?.transformationsByChainName?.[action.chainName];
                if (transformationNames?.length > 0) {
                    return [];
                }
                return from(
                    this._taxilla.transformations.getTransformationNamesInChain({
                        repositoryId: action.repositoryId,
                        chainName: action.chainName,
                        assetName: action.assetName,
                        assetId: action.assetId,
                    })
                ).pipe(
                    map((res: any) => {
                        return SetTransformationNamesInChain({
                            organizationId: orgId,
                            serviceId: action.serviceId,
                            chainName: action.chainName,
                            transformations: res,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e.msg })))
                );
            })
        )
    );

    private changeTransformationsHierarchy = (providerIds: string[], transformations) => {
        const downloadReportTransformations = [];
        providerIds.forEach((orgId) => {
            transformations.find((object, index) => {
                if (object.orgId === orgId) {
                    downloadReportTransformations.push(transformations[index]);
                }
            });
        });
        return downloadReportTransformations;
    };
}
