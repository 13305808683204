import { Injectable } from '@angular/core';

import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';
import { Field } from '../../models/field.class';
import { LookupValues } from '../../models/lookupvalues.class';
import { RecordField } from '../../models/record/recordfield.class';
import { BridgeService } from '../bridge/bridge.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class LookupsService {
    private _storage = new LookupStorage();

    constructor(private _bridge: BridgeService, private _utils: UtilsService) {}

    /**
     *  Method for fetching lookupvalues by entity field
     * @param data Contains service, entity, field, conditional Value
     * @param callbacks Contains success callback method,
     */
    fetchLookupValuesByEntityField = (
        data: { service: AssetService; entity?: Entity; field?: Field; conditionalValue?: string },
        callbacks: { successCallback: (lookupResponse: LookupValues) => void }
    ) => {
        const fetchLoops = [];
        const lookupReference = data.field.lookupInformationMetadata || (data.field as any).lookupInformation;
        const lookupInfo = lookupReference;
        const conditionRefernce =
            (lookupInfo &&
                lookupInfo.lookupCondition &&
                lookupInfo.lookupCondition.substring(0, lookupInfo.lookupCondition.indexOf('='))) ||
            'conditionalvalue';
        const lookupData = {
            assetId: data.service.assetMetaUId ?? data.service.id,
            entityId: data.entity.uid,
            fieldId: data.field.uid,
            lookupId: lookupReference.id,
            lookupValueFieldId: lookupReference.valueField,
            lookupDisplayFieldId: lookupReference.displayField,
            conditionalFieldVsValue: {
                [conditionRefernce]: data.conditionalValue,
            },
        };
        fetchLoops.push(lookupData);
        this.prepareLookupPayload(fetchLoops, data.field.uid, callbacks);
    };

    /**
     *  Method for fetching lookupvalues by record field
     * @param data Contains service, field, conditional Value
     * @param callbacks Contains success callback method,
     */
    fetchLookupValuesByRecordField = (
        data: { service: AssetService; field?: RecordField; conditionalValue?: string; gridCellIndex?: string },
        callbacks: { successCallback: (lookupResponse: LookupValues) => void }
    ) => {
        const fetchLoops = [];
        const lookupReference = (data.field as any).lookupInformationMetadata || data.field.lookupInformation;
        const lookupInfo: {
            displayField: string;
            globalLookup: boolean;
            id: string;
            lookupCondition: string;
            name: string;
            valueField: string;
        } = lookupReference;
        const conditionRefernce =
            (lookupInfo &&
                lookupInfo.lookupCondition &&
                lookupInfo.lookupCondition.substring(0, lookupInfo.lookupCondition.indexOf('='))) ||
            'conditionalvalue';
        const lookupData = {
            assetId: data.service.assetMetaUId ?? data.service.id,
            entityId: data.field.entityUid,
            fieldId: data.field.id,
            lookupId: data.field.lookupInformation.id,
            lookupValueFieldId: data.field.lookupInformation.valueField,
            lookupDisplayFieldId: data.field.lookupInformation.displayField,
            conditionalFieldVsValue: {
                [conditionRefernce]: data.conditionalValue,
            },
        };
        if (data.gridCellIndex !== undefined) {
            lookupData['gridCellIndex'] = data.gridCellIndex;
        }
        fetchLoops.push(lookupData);
        this.prepareLookupPayload(fetchLoops, data.field.id, callbacks);
    };

    /**
     *  Method for fetching lookupvalues
     * @param data Contains service, field, conditional Value
     * @param callbacks Contains success callback method,
     */
    fetchLookupValuesByField = (
        data: {
            service: AssetService;
            entity?: Entity;
            field?: { [property: string]: any };
            conditionalValue?: string;
            gridCellIndex?: string;
        },
        callbacks: { successCallback: (lookupResponse: LookupValues) => void }
    ) => {
        const fetchLoops = [];
        const lookupInfo: {
            displayField: string;
            globalLookup: boolean;
            id: string;
            lookupCondition: string;
            name: string;
            valueField: string;
        } = data.field.lookupInformationMetadata || data.field.lookupInformation || data.field.lookupConditionExpressions?.[0];
        const conditionRefernce =
            lookupInfo?.lookupCondition?.substring(0, lookupInfo.lookupCondition.indexOf('=')) ||
            lookupInfo?.['searchFieldInLookup'] ||
            'conditionalvalue';
        const lookupData = {
            assetId: data.service.assetMetaUId ?? data.service.id,
            entityId: data.field.entityId,
            fieldId: data.field.id,
            lookupId: data.field.lookupId,
            lookupValueFieldId: data.field.lookupValueField,
            lookupDisplayFieldId: data.field.lookupDisplayField,
            conditionalFieldVsValue: {
                [conditionRefernce]: data.conditionalValue,
            },
        };
        if (data.gridCellIndex !== undefined) {
            lookupData['gridCellIndex'] = data.gridCellIndex;
        }
        fetchLoops.push(lookupData);
        this.prepareLookupPayload(fetchLoops, data.field.id, callbacks);
    };

    /**
     * Final method to prepare to fetch lookups
     * @param fetchLoops Contains assetId, entityId, fieldId, lookupId, lookupValueFieldId, lookupDisplayFieldId, lookupDisplayFieldId, conditionalFieldVsValue
     * @param fieldId Field ID
     */
    private prepareLookupPayload = (
        fetchLoops: {
            assetId: AssetService['assetMetaUId'];
            entityId: string;
            fieldId: string;
            lookupId: string;
            lookupValueFieldId: string;
            lookupDisplayFieldId: string;
            conditionalFieldVsValue?: {
                conditionalvalue: string;
            };
        }[],
        fieldId: string,
        callbacks: { successCallback: (response: LookupValues) => void }
    ) => {
        this.fetchMultipleLookupValues(
            {
                lookupsData: fetchLoops,
            },
            {
                successCallback: (response) => {
                    const lookupResponse = response && response[fieldId];
                    callbacks.successCallback(lookupResponse);
                },
            }
        );
    };

    /**
     * Method to fetch multiple lookup values
     * @param data Contains List of lookupData
     * @param callbacks Contains success callback method
     */
    fetchMultipleLookupValues = (
        data: {
            lookupsData: {
                assetId: AssetService['assetMetaUId'];
                entityId: string;
                fieldId: string;
                lookupId: string;
                lookupValueFieldId: string;
                lookupDisplayFieldId: string;
                conditionalFieldVsValue?: {
                    conditionalvalue: string;
                };
            }[];
        },
        callbacks: {
            successCallback: (response: { [property: string]: LookupValues }) => void;
        }
    ) => {
        const payload = [];
        const response = {};
        data.lookupsData
            .filter((lookupData) => {
                const values = this._storage.get(
                    lookupData.assetId,
                    lookupData.entityId,
                    lookupData.fieldId,
                    lookupData.lookupId,
                    lookupData.lookupValueFieldId,
                    lookupData.lookupDisplayFieldId,
                    lookupData.conditionalFieldVsValue
                );
                values && values.fieldId && (response[lookupData.fieldId] = values);
                return !values;
            })
            .forEach((lookupData) => {
                payload.push(lookupData);
            });
        if (payload.length > 0) {
            this._bridge.fetchLookups(
                payload,
                (res) => {
                    this.transformLookupResponse(data, response, res, callbacks);
                },
                (res) => {
                    this._utils.alertError((res && res.msg) || 'Failed to get lookups');
                }
            );
        } else {
            callbacks.successCallback(response);
        }
    };

    /**
     *  Method for fetching lookupvalues by record field
     * @param data Contains service, field, conditional Value
     * @param callbacks Contains success callback method,
     */
    searchLookupValuesByRecordField = (
        data: {
            service: AssetService;
            field?: RecordField;
            conditionalValue?: string;
            lookupSearchDetails?: {
                lookupId: string;
                searchString: string;
                large: boolean;
                page: number;
            }[];
        },
        callbacks: {
            successCallback: (response: { [property: string]: LookupValues }) => void;
        }
    ) => {
        const fetchLoops = [];
        const lookupReference = (data.field as any).lookupInformationMetadata || data.field.lookupInformation;
        const lookupInfo: {
            displayField: string;
            globalLookup: boolean;
            id: string;
            lookupCondition: string;
            name: string;
            valueField: string;
        } = lookupReference;
        const conditionRefernce =
            (lookupInfo &&
                lookupInfo.lookupCondition &&
                lookupInfo.lookupCondition.substring(0, lookupInfo.lookupCondition.indexOf('='))) ||
            'conditionalvalue';
        const lookupData = {
            assetId: data.service.assetMetaUId ?? data.service.id,
            entityId: data.field.entityUid,
            fieldId: data.field.id,
            lookupId: data.field.lookupInformation.id,
            lookupValueFieldId: data.field.lookupInformation.valueField,
            lookupDisplayFieldId: data.field.lookupInformation.displayField,
            conditionalFieldVsValue: {
                [conditionRefernce]: data.conditionalValue,
            },
        };
        fetchLoops.push(lookupData);
        if (fetchLoops.length > 0) {
            this._bridge.searchLookups(
                {
                    lookupDetails: fetchLoops,
                    lookupSearchDetails: data.lookupSearchDetails,
                },
                (res) => {
                    this.transformLookupResponse({ lookupsData: fetchLoops }, undefined, res, callbacks);
                },
                (res) => {
                    this._utils.alertError((res && res.msg) || 'Failed to get lookups');
                }
            );
        }
    };

    private transformLookupResponse = (
        data: {
            lookupsData: {
                assetId: AssetService['assetMetaUId'];
                entityId: string;
                fieldId: string;
                lookupId: string;
                lookupValueFieldId: string;
                lookupDisplayFieldId: string;
                conditionalFieldVsValue?: {
                    conditionalvalue: string;
                };
            }[];
        },
        storedResponse,
        serverResponse,
        callbacks: {
            successCallback: (response: { [property: string]: LookupValues }) => void;
        }
    ) => {
        storedResponse = storedResponse || {};
        // tslint:disable-next-line:prefer-const
        let responseData = serverResponse.lookupData;
        const lookupIds = Object.keys(responseData);
        for (let i = 0; i < lookupIds.length; i++) {
            const lookup = responseData[lookupIds[i]];
            if (lookup.displayVsValueArray && lookup.displayVsValueArray.length > 0) {
                lookup.displayVsValue = {};
                lookup.displayVsValueArray.forEach((lookupObject) => {
                    const lookupObjectKeys = Object.keys(lookupObject);
                    for (let j = 0; j < lookupObjectKeys.length; j++) {
                        lookup.displayVsValue[lookupObjectKeys[j]] = lookupObject[lookupObjectKeys[j]];
                    }
                });
            }
        }
        for (let i = 0; i < lookupIds.length; i++) {
            const lookupResponse = responseData[lookupIds[i]];
            data.lookupsData.forEach((lookupData) => {
                if (lookupData.lookupId === lookupResponse.lookupId && lookupData.fieldId === lookupResponse.fieldId) {
                    this._storage.set(
                        lookupData.assetId,
                        lookupData.entityId,
                        lookupData.fieldId,
                        lookupData.lookupId,
                        lookupData.lookupValueFieldId,
                        lookupData.lookupDisplayFieldId,
                        lookupData.conditionalFieldVsValue,
                        lookupResponse
                    );
                    storedResponse[lookupResponse.fieldId] = lookupResponse;
                }
            });
        }
        callbacks.successCallback(storedResponse);
    };
}

class LookupStorage {
    [property: string]:
        | ((...args: any[]) => void)
        | {
              [property: string]: {
                  [property: string]: {
                      lookupId: string;
                      lookupValueFieldId: string;
                      lookupDisplayFieldId: string;
                      conditionalFieldVsValue: string;
                      values: LookupValues;
                  };
              };
          };

    constructor() {}

    set = (
        assetId: string,
        entityId: string,
        fieldId: string,
        lookupId: string,
        lookupValueFieldId: string,
        lookupDisplayFieldId: string,
        conditionalFieldVsValue: any,
        lookupValues: LookupValues
    ) => {
        const assetInstance = this.setAssetInstance(assetId);
        const entityData = this.setEntityInstance(assetInstance, entityId);
        const fieldData = this.setFieldInstance(entityData, fieldId);
        this.setLookupValues(
            fieldData,
            {
                conditionalFieldVsValue,
                lookupDisplayFieldId,
                lookupId,
                lookupValueFieldId,
            },
            lookupValues
        );
    };

    get = (
        assetId: string,
        entityId: string,
        fieldId: string,
        lookupId: string,
        lookupValueFieldId: string,
        lookupDisplayFieldId: string,
        conditionalFieldVsValue: any
    ): LookupValues => {
        const assetInstance = this.getAssetIdValues(assetId);
        const entityData = assetInstance && this.getEntityInstance(assetInstance, entityId);
        const fieldData = entityData && this.getFieldInstance(entityData, fieldId);
        const values =
            fieldData &&
            this.getLookupValues(fieldData, {
                conditionalFieldVsValue,
                lookupDisplayFieldId,
                lookupId,
                lookupValueFieldId,
            });
        return values;
    };

    private setAssetInstance = (assetId: string) => {
        let assetInstance = this.getAssetIdValues(assetId);
        if (!assetInstance) {
            assetInstance = this[assetId] = {};
        }
        return assetInstance;
    };

    private getAssetIdValues = (assetId) => {
        return this[assetId];
    };

    private setEntityInstance = (assetInstance, entityId: string) => {
        let entityInstance = this.getEntityInstance(assetInstance, entityId);
        if (!entityInstance) {
            entityInstance = assetInstance[entityId] = {};
        }
        return entityInstance;
    };

    private getEntityInstance = (assetInstance, entityId) => {
        return assetInstance[entityId];
    };

    private setFieldInstance = (entityInstance, fieldId: string) => {
        let fieldInstance = this.getFieldInstance(entityInstance, fieldId);
        if (!fieldInstance) {
            fieldInstance = entityInstance[fieldId] = [];
        }
        return fieldInstance;
    };

    private getFieldInstance = (assetInstance, entityId) => {
        return assetInstance[entityId];
    };

    private setLookupValues = (
        fieldInstance: any[],
        data: {
            lookupId: string;
            lookupValueFieldId: string;
            lookupDisplayFieldId: string;
            conditionalFieldVsValue?: any;
        },
        lookupValues: LookupValues
    ) => {
        fieldInstance.push({
            lookupId: data.lookupId,
            lookupValueFieldId: data.lookupValueFieldId,
            lookupDisplayFieldId: data.lookupDisplayFieldId,
            conditionalFieldVsValue: data.conditionalFieldVsValue,
            values: lookupValues,
        });
    };

    private getLookupValues = (
        fieldInstance: any[],
        data: {
            lookupId: string;
            lookupValueFieldId: string;
            lookupDisplayFieldId: string;
            conditionalFieldVsValue?: any;
        }
    ): LookupValues => {
        const lookupInstance = fieldInstance.find((lookup) => {
            const storedConditionCheck =
                data.conditionalFieldVsValue &&
                Object.keys(data.conditionalFieldVsValue).length > 0 &&
                JSON.stringify(data.conditionalFieldVsValue);
            const incommingConditionCheck =
                lookup.conditionalFieldVsValue &&
                Object.keys(lookup.conditionalFieldVsValue).length > 0 &&
                JSON.stringify(lookup.conditionalFieldVsValue);
            const conditionalEquality = storedConditionCheck !== '{}' && storedConditionCheck === incommingConditionCheck;

            return (
                lookup.lookupId === data.lookupId &&
                lookup.lookupValueFieldId === data.lookupValueFieldId &&
                lookup.lookupDisplayFieldId === data.lookupDisplayFieldId &&
                conditionalEquality
            );
        });
        return lookupInstance && lookupInstance.values;
    };
}
