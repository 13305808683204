<ng-container *transloco="let translate;">
    <button *ngIf="!isNewUI" mat-button class="settingsCurtainMatMenuButton nav-item" [matMenuTriggerFor]="userDetailsMenu">
        <span class="material-icons personIcon">person</span>
        <span class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
        <span class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
    </button>
    <button *ngIf="isNewUI" mat-button class="header-button" [matMenuTriggerFor]="userDetailsMenu" disableRipple>
        <img src="/assets/images/new-ui/user.png" alt="{{translate('user icon')}}" title="{{translate('Profile')}}">
    </button>
</ng-container>
<mat-menu #userDetailsMenu="matMenu" [overlapTrigger]="false" class="settingsCurtainMenu">
    <mat-card>
        <mat-card-content *transloco="let translate">
            <div class="userNameContainer row">
                <div class="col-12 itemContainer">
                    <div class="ccol-3 dateFont">{{translate('User')}}</div>
                    <div class="ccol-9">
                        <span class="displayBlock postloginUserName" title="{{(loggedInUser?.firstName || '') + ' ' + (loggedInUser?.lastName || '')}}">{{(loggedInUser?.firstName || '') + ' ' + (loggedInUser?.lastName || '')}}</span>
                    </div>
                </div>
                <div class="col-12 itemContainer">
                    <div class="ccol-3 dateFont">{{translate('Organization')}}</div>
                    <div class="ccol-9 rootOrgId" (click)="switchToLoggedInOrganization()" title="{{loggedInOrgName}} ({{loggedInOrgId}})">
                        <span class="rootOrg">{{loggedInOrgName}}</span>
                        <span class="organizationId">({{loggedInOrgId}})</span>
                    </div>
                </div>
                <div class="col-12 itemContainer">
                    <div class="ccol-3 dateFont">{{translate('Last Login')}}</div>
                    <div class="ccol-9 lastLogin" title="{{lastLogin}}">{{lastLogin}}</div>
                </div>
                <div class="col-12 itemContainer customHeight">
                    <div class="ccol-3 dateFont">{{translate('Regions')}}</div>
                    <div class="ccol-9 customWidth" title="{{selectedRegion?.code}}">
                        <button class="!w-auto !px-2.5 shadow-md !rounded-lg" mat-icon-button [matMenuTriggerFor]="regionsMenu" #matRegionsMenuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                            <div class="text-ellipsis overflow-hidden">
                                <img class="w-6 mr-2" src="/assets/images/regions/{{selectedRegion?.code}}.png" alt="{{translate('Selected Region')}}">
                                <span class="mr-2">{{selectedRegion?.name}}</span>
                                <span *ngIf="!matRegionsMenuTrigger.menuOpen" class="material-icons align-middle">keyboard_arrow_down</span>
                                <span *ngIf="matRegionsMenuTrigger.menuOpen" class="material-icons align-middle">keyboard_arrow_up</span>
                            </div>
                        </button>
                        <mat-menu #regionsMenu="matMenu">
                            <ng-container *ngFor="let region of availableRegions">
                                <button mat-menu-item (click)="setRegion(region.code)" [title]="region.name">
                                    <img class="w-6 mr-2" src="/assets/images/regions/{{region.code}}.png" alt="{{translate('Region Name')}}">
                                    <span>{{region.name}}</span>
                                </button>
                            </ng-container>
                        </mat-menu>
                    </div>
                </div>
                <div class="col-12 itemContainer customHeight">
                    <div class="ccol-3 dateFont">{{translate('Language')}}</div>
                    <div class="ccol-9 customWidth" title="{{currentLanguage}}">
                        <button class="!w-auto !px-2.5 shadow-md !rounded-lg " mat-icon-button [matMenuTriggerFor]="languagesMenu" #matLanguagesMenuTrigger="matMenuTrigger" (click)="$event.stopPropagation()">
                            <div class="text-ellipsis overflow-hidden">
                                <img class="w-6 mr-2" src="/assets/images/languages/{{selectedLanguage?.id}}.png" alt="{{translate('Selected Language')}}">
                                <span class="mr-2">{{selectedLanguage?.label}}</span>
                                <span *ngIf="!matLanguagesMenuTrigger.menuOpen" class="material-icons align-middle">keyboard_arrow_down</span>
                                <span *ngIf="matLanguagesMenuTrigger.menuOpen" class="material-icons align-middle">keyboard_arrow_up</span>
                            </div>
                        </button>
                        <mat-menu #languagesMenu="matMenu">
                            <ng-container *ngFor="let language of availableLanguages">
                                <button mat-menu-item (click)="setLanguage(language.id)" [title]="language.label">
                                    <img class=" w-6 mr-2" src="/assets/images/languages/{{language.id}}.png" alt="{{translate('Language Label')}}">
                                    <span>{{language.label}}</span>
                                </button>
                            </ng-container>
                        </mat-menu>
                    </div>
                </div>
                <div class="col-12 itemContainer customHeight">
                    <div class="ccol-3 dateFont">{{translate('Edit Profile')}}</div>
                    <div class="ccol-9" title="{{translate('Edit Profile')}}">
                        <span class="material-symbols-outlined icon" (click)='openEditProfileModal()'>
                            edit_square
                        </span>
                    </div>
                </div>
                <div class="col-12 itemContainer customHeight">
                    <div class="ccol-3 dateFont">{{translate('Digital Signature')}}</div>
                    <div class="ccol-9" title="{{translate('Digital Signature')}}">
                        <span class="material-symbols-outlined icon" (click)="openDigitalSignatureModal()">
                            verified
                        </span>
                    </div>
                </div>
                <div class="col-12 itemContainer customHeight">
                    <div class="ccol-3 dateFont">{{translate('Change Password')}}</div>
                    <div class="ccol-9" title="{{translate('Change Password')}}">
                        <span class="material-symbols-outlined icon" (click)="openChangePasswordModal()">
                            password
                        </span>
                    </div>
                </div>
            </div>
            <div class="signOutButton" (click)="onLogout()" title="{{translate('Sign Out')}}">
                <button mat-raised-button color="primary"> {{translate('Sign Out')}} </button>
            </div>
        </mat-card-content>
    </mat-card>
</mat-menu>
