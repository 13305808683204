<ng-container *transloco="let translate;">
    <div class="row accessApiRowContainer">
        <div class="col-8 accessApiInputFieldContainer">
            <material-select [options]="eventTypes" [placeholder]="translate('Select Event Type')" [name]="'Select Event Type'" [title]="translate('Select Event Type')" [required]="true" [model]="event.eventType" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'eventType'" (modelChange)="onEventTypeChange($event, 'modelChange'); event.eventType = $event" [errors]="eventErrors.eventType" [disabled]="isEdit"></material-select>
        </div>
    </div>
    <div class="row accessApiRowContainer">
        <div class="col-8 accessApiInputFieldContainer">
            <material-input [placeholder]="translate('Name of Subscription')" [model]='event?.subscriptionId' (modelChange)='event.subscriptionId = $event;' [required]='true' [errors]="eventErrors.subscriptionId" [disabled]="isEdit"></material-input>
        </div>
    </div>
    <div class="row accessApiRowContainer includeHierarchyCntr" *ngIf="event.eventType">
        <div class="accessApiInputFieldContainer labelText">
            {{translate('Criteria Type:')}}
        </div>
        <div class="accessApiInputFieldContainer">
            <mat-radio-group name="searchType" class="radioGroup" [(ngModel)]="searchType" (change)="onSearchTypeChanged()">
                <mat-radio-button class="radioButton" [value]="'single'">
                    {{translate('Single Field')}}
                </mat-radio-button>
                <mat-radio-button class="radioButton" [value]="'multiple'">
                    {{translate('Multiple Fields')}}
                </mat-radio-button>
                <mat-radio-button class="radioButton" [value]="'custom'">
                    {{translate('Custom Fields')}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row accessApiRowContainer criteriaContainer" *ngIf="event.eventType && searchType==='single'">
        <div class=" col-3 accessApiInputFieldContainer">
            <material-select [options]="singleQuery.fields" [placeholder]="translate('Field')" [name]="'Field'" [title]="translate('Field')" [required]="true" [model]="singleQuery.field" (modelChange)="onSingleQueryFieldChanged($event);singleQuery.field = $event"></material-select>
        </div>
        <div class=" col-2 accessApiInputFieldContainer" *ngIf="singleQuery.field">
            <material-select [options]="singleQuery.operators[singleQuery.field]" [placeholder]="translate('Operator')" [name]="'Operator'" [title]="translate('Operator')" [required]="true" [model]="singleQuery.operator" (modelChange)="onSingleQueryOperatorChanged($event);singleQuery.operator = $event"></material-select>
        </div>
        <div class=" col-3 accessApiInputFieldContainer" *ngIf="singleQuery.field">
            <material-input [placeholder]="translate('Value')" [model]='singleQuery.value' (modelChange)='singleQuery.value = $event;' [required]='true'></material-input>
        </div>
        <div class="mat-form-field-subscript-wrapper error">
            <mat-error *ngIf="eventErrors.criterion.length" matTooltip="{{eventErrors.criterion}}">
                {{eventErrors.criterion}}
            </mat-error>
        </div>
    </div>
    <div class="row accessApiRowContainer criteriaContainer" *ngIf="event.eventType && searchType==='multiple'">
        <div class="accessApiInputFieldContainer">
            <app-elasticquerybuilder [query]="query" [config]="config" [allowRuleset]="true" [allowCollapse]="true"></app-elasticquerybuilder>
        </div>
        <div class="mat-form-field-subscript-wrapper error">
            <mat-error *ngIf="eventErrors.criterion.length" matTooltip="{{eventErrors.criterion}}">
                {{eventErrors.criterion}}
            </mat-error>
        </div>
    </div>
    <div class="row accessApiRowContainer criteriaContainer" *ngIf="event.eventType && searchType==='custom'">
        <div class="align-items-center d-flex justify-content-between px-4 w-100">
            <div class="d-flex justify-content-start">
                <mat-radio-group [(ngModel)]="customFieldsCondition" class="d-block" *ngIf="customQuery?.length > 1">
                    <mat-radio-button class="customRadio" value="and" color="primary">AND</mat-radio-button>
                    <mat-radio-button class="customRadio" value="or" color="primary">OR</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="d-flex justify-content-end">
                <mat-icon title="{{translate('Add Custom Rule')}}" class="addIcon addIconColor ml-3" aria-hidden="true" (click)="addCustomRule()">add_circle</mat-icon>
            </div>
        </div>
        <div class="col-12">
            <div class="col-9 row accessApiRowContainer" *ngFor="let cquery of customQuery; let i = index; trackBy: trackByIndexMethod">
                <div class=" col-3 accessApiInputFieldContainer">
                    <material-input [placeholder]="translate('Field')" [model]='cquery.field' (modelChange)='cquery.field = $event;' [required]='true'></material-input>
                </div>
                <div class="col-2 accessApiInputFieldContainer">
                    <material-select [options]="cquery.operators" [placeholder]="translate('Operator')" [name]="'Operator'" [title]="translate('Operator')" [required]="true" [model]="cquery.operator" (modelChange)="cquery.operator = $event"></material-select>
                </div>
                <div class="col-3 accessApiInputFieldContainer">
                    <material-input [placeholder]="translate('Value')" [model]='cquery.value' (modelChange)='cquery.value = $event;' [required]='true'></material-input>
                </div>
                <div class="col-1 removeIconDiv">
                    <mat-icon title="{{translate('Remove Rule')}}" class="addIcon removeIconColor ml-3" *ngIf="customQuery.length > 1" aria-hidden="true" (click)="removeCustomRule(i)">remove</mat-icon>
                </div>
            </div>
            <div class="velocityTemplatePlaceholders" *ngIf="nestedPayloadFields.length">
                <div class="accessApiInputFieldContainer">
                    <span>
                        {{translate('Nested properties')}}
                    </span>
                </div>
                <div class="copyPlaceholder" *ngFor="let field of nestedPayloadFields">
                    <span>{{field}}</span>
                    <mat-icon class="copyIcon ml-3" title="{{translate('Click to copy placeholder')}}" aria-hidden="true" (click)="copyPlaceholder(field, true)">content_copy</mat-icon>
                </div>
            </div>
        </div>
        <div class="mat-form-field-subscript-wrapper error">
            <mat-error *ngIf="eventErrors.criterion.length" matTooltip="{{eventErrors.criterion}}">
                {{eventErrors.criterion}}
            </mat-error>
        </div>
    </div>
    <div class="row accessApiRowContainer">
        <div class="col-8 accessApiInputFieldContainer">
            <material-select [options]="channels" [placeholder]="translate('Select Notification Channel')" [name]="'Select Notification Channel'" [title]="translate('Select Notification Channel')" [required]="true" [model]="channel" (modelChange)="onChannelChange($event); channel = $event" [errors]="eventErrors.channel"></material-select>
        </div>
    </div>
    <div class="emailTemplate" *ngIf="channel==='Email'">
        <div class="row accessApiRowContainer">
            <div class="col-8 accessApiInputFieldContainer">
                <ng-template [ngTemplateOutlet]="emailChipList" [ngTemplateOutletContext]="{ emails: event.toEmails, placeHolder: translate('To Emails'), required: true, origin: 'toEmails'}"></ng-template>
                <div class="mat-form-field-subscript-wrapper error">
                    <mat-error *ngIf="eventErrors.toEmails.length" matTooltip="{{eventErrors.toEmails}}">
                        {{eventErrors.toEmails}}
                    </mat-error>
                </div>
                <div class="mat-form-field-subscript-wrapper">
                    <div class="mat-form-field-hint-wrapper">
                        <mat-hint align="start" *ngIf="!eventErrors.toEmails.length">
                            <strong>
                                <span>* </span>{{translate('Required')}}</strong>
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <div class="row accessApiRowContainer">
            <div class="col-8 accessApiInputFieldContainer">
                <ng-template [ngTemplateOutlet]="emailChipList" [ngTemplateOutletContext]="{ emails: event.ccEmails, placeHolder: translate('CC Emails'), required: false, origin: 'ccEmails' }"></ng-template>
                <div class="mat-form-field-subscript-wrapper error">
                    <mat-error *ngIf="eventErrors.ccEmails.length" matTooltip="{{eventErrors.ccEmails}}">
                        {{eventErrors.ccEmails}}
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="row accessApiRowContainer">
            <div class="col-8 accessApiInputFieldContainer">
                <ng-template [ngTemplateOutlet]="emailChipList" [ngTemplateOutletContext]="{ emails: event.bccEmails, placeHolder: translate('BCC Emails'), required: false, origin: 'bccEmails'}"></ng-template>
                <mat-error *ngIf="eventErrors.bccEmails.length" matTooltip="{{eventErrors.bccEmails}}">
                    {{eventErrors.bccEmails}}
                </mat-error>
            </div>
        </div>
        <div class="row accessApiRowContainer">
            <div class="col-8 accessApiInputFieldContainer">
                <material-input [placeholder]="translate('Subject')" [model]='event.subjectTemplate' (modelChange)='event.subjectTemplate = $event;' [required]='true' [errors]="eventErrors.subjectTemplate"></material-input>
            </div>
        </div>
        <div class="row accessApiRowContainer">
            <div class="accessApiInputFieldContainer angularEditor col-8 p-3 shadow-sm mt-1">
                <ng-container *ngIf="isIE;else notIE">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{translate('Content')}}</mat-label>
                        <textarea matInput placeholder="{{translate('Content')}}" style="height: 200px; -ms-overflow-style: auto; overflow: auto;" [(ngModel)]="event.contentTemplate" (ngModelChange)="event.contentTemplate = $event" required></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="eventErrors.contentTemplate">
                        {{eventErrors.contentTemplate}}
                    </mat-error>
                    <mat-hint>
                        <strong>
                            <span>* </span>{{translate('Required')}}
                        </strong>
                    </mat-hint>
                </ng-container>
                <ng-template #notIE>
                    <app-rich-text-editor [enableEncryptDecrypt]="false" [placeholder]="translate('Content')" [model]="event.contentTemplate" [required]="true" [errors]="eventErrors.contentTemplate" [editable]="true" (modelChange)="event.contentTemplate = $event"></app-rich-text-editor>
                </ng-template>
            </div>
        </div>
        <div class="row accessApiRowContainer">
            <div class="col-8 accessApiInputFieldContainer">
                <material-input [placeholder]="translate('Attachment Field')" [model]='event.attachment' (modelChange)='event.attachment = $event;' [required]='false'></material-input>
            </div>
        </div>
        <div class="col-4 velocityTemplatePlaceholders" *ngIf="payloadFields.length || nestedPayloadFields.length">
            <ng-container *ngIf="payloadFields.length">
                <div class="placeholder-wrapper">
                    <div class="accessApiInputFieldContainer ignore">
                        <span>
                            {{translate('You can use the following dynamic placeholders in subject and mail content.')}}
                        </span>
                    </div>
                    <div class="placeholders-container">
                        <div class="copyPlaceholder" *ngFor="let field of payloadFields">
                            <span>{{field}}</span>
                            <mat-icon class="copyIcon ml-3" title="{{translate('Click to copy placeholder')}}" aria-hidden="true" (click)="copyPlaceholder(field)">content_copy</mat-icon>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="nestedPayloadFields.length">
                <div class="placeholder-wrapper">
                    <div class="accessApiInputFieldContainer">
                        <span>
                            {{translate('Nested placeholders')}}
                        </span>
                    </div>
                    <div class="placeholders-container">
                        <div class="copyPlaceholder" *ngFor="let field of nestedPayloadFields">
                            <span>{{field}}</span>
                            <mat-icon class="copyIcon ml-3" title="{{translate('Click to copy placeholder')}}" aria-hidden="true" (click)="copyPlaceholder(field)">content_copy</mat-icon>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
    <div class="row accessApiRowContainer includeHierarchyCntr" *ngIf="event.eventType">
        <div class="accessApiInputFieldContainer labelText">
            {{translate('Apply to Sub Locations:')}}
        </div>
        <div class="accessApiInputFieldContainer">
            <mat-radio-group class="radioGroup" [(ngModel)]="event.includeHierarchy" name="includeHierarchy">
                <mat-radio-button class="radioButton" [value]="true">
                    {{translate('True')}}
                </mat-radio-button>
                <mat-radio-button class="radioButton" [value]="false">
                    {{translate('False')}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="accessApiActionButtons pb-3" [hidden]="isAdvancedEdit">
        <material-button class='noOutline cancelButton' [label]="translate('Cancel')" color="default" (onTrigger)="closeDrawer()"></material-button>
        <material-button class='noOutline submitColor' [label]="translate('Create')" color="primary" *ngIf="!isEdit" (onTrigger)="createSubscription()"></material-button>
        <material-button class='noOutline submitColor' [label]="translate('Save')" color="primary" *ngIf="isEdit" (onTrigger)="createSubscription()"></material-button>
    </div>
</ng-container>

<ng-template #emailChipList let-emails="emails" let-placeHolder="placeHolder" let-required="required" let-origin="origin">
    <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let email of emails" (removed)="removeMails(email, origin)">
                {{email}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input type="text" [placeholder]="placeHolder" [matChipInputFor]="chipList" focused (matChipInputTokenEnd)="addMails($event, origin)" title="Type and hit Enter key or Comma to add multiple values" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true" [required]="required">
        </mat-chip-list>
    </mat-form-field>
</ng-template>
