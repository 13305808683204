<div class="mat-search-query-builder" *transloco="let translate;">
    <query-builder [(ngModel)]='query' [config]='config' [allowRuleset]='allowRuleset' [allowCollapse]='allowCollapse' [parentChangeCallback]="onParentChange" [operatorMap]="customOperatorsMap" [emptyMessage]="translate('A ruleset cannot be empty. Please add a rule or remove it all together.')">
        <ng-container *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
            <button type="button" mat-icon-button color="primary" (click)="addRule(); openPanel();" class="queryButton">
                <mat-icon>add</mat-icon>
            </button>
            <button type="button" mat-icon-button color="primary" *ngIf="addRuleSet" (click)="addRuleSet(); openPanel();" class="queryButton">
                <mat-icon>add_circle_outline</mat-icon>
            </button>
            <button type="button" mat-icon-button color="warn" *ngIf="removeRuleSet" (click)="removeRuleSet()" class="queryButton">
                <mat-icon>remove_circle_outline</mat-icon>
            </button>
        </ng-container>
        <ng-container *queryArrowIcon>
            <mat-icon ngClass="mat-arrow-icon">chevron_right</mat-icon>
        </ng-container>
        <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
            <button type="button" mat-icon-button color="warn" (click)="removeRule(rule)" class="queryButton">
                <mat-icon>remove</mat-icon>
            </button>
        </ng-container>
        <ng-container *querySwitchGroup="let ruleset; let onChange=onChange">
            <mat-radio-group class="qbCondition" *ngIf="ruleset" [(ngModel)]="ruleset.condition" (ngModelChange)="onChange($event)">
                <mat-radio-button [style.padding.px]="10" value="and" color="primary">AND</mat-radio-button>
                <mat-radio-button [style.padding.px]="10" value="or" color="primary">OR</mat-radio-button>
            </mat-radio-group>
        </ng-container>
        <ng-container *queryEntity="let rule; let entities=entities; let onChange=onChange">
            <mat-form-field appRemoveAriaOwns>
                <mat-select placeholder="Please Select One" [(ngModel)]="rule.entity" (ngModelChange)="onChange($event, rule)">
                    <mat-option *ngFor="let entity of entities; trackBy: trackByEntityMethod" [value]="entity.value">
                        {{entity.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *queryField="let rule; let fields=fields; let onChange=onChange; let getFields = getFields">
            <mat-form-field appRemoveAriaOwns>
                <mat-select placeholder="Please Select One" [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)">
                    <mat-option *ngFor="let field of getFields(rule.entity); trackBy: trackByFieldMethod" [value]="field.value">
                        {{ field.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *queryOperator="let rule; let operators=operators; let onChange=onChange">
            <mat-form-field class="qbOperator" [style.width.px]="100" appRemoveAriaOwns>
                <mat-select [(ngModel)]="rule.operator" (ngModelChange)="onOperatorChange($event, rule);">
                    <mat-option *ngFor="let value of operators; trackBy: trackByOperatorsMethod" [value]="value">{{ value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
            <mat-form-field *ngIf="rule.operator !== 'IN'" appRemoveAriaOwns>
                <mat-label>Select a value</mat-label>
                <mat-select [(ngModel)]="rule.value" (ngModelChange)="onChange()">
                    <mat-option *ngFor="let opt of options; trackBy: trackByOptionMethod" [value]="opt.value">{{ opt.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="rule.operator === 'IN'" appRemoveAriaOwns>
                <mat-label>Select value(s)...</mat-label>
                <mat-select [(ngModel)]="rule.value" multiple (ngModelChange)="onChange()">
                    <mat-option *ngFor="let opt of options; trackBy: trackByOptionMethod" [value]="opt.value">{{ opt.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; let field=field; let options=options; type: 'boolean'; let onChange=onChange">
            <mat-form-field appRemoveAriaOwns>
                <mat-select placeholder="Please Select One" [(ngModel)]="rule.value" (ngModelChange)="onChange()">
                    <mat-option *ngFor="let opt of options; trackBy: trackByOptionMethod" [value]="opt.value">
                        {{ opt.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; type: 'date'; let onChange=onChange">
            <mat-form-field>
                <input placeholder="Enter input" matInput [matDatepicker]="picker" [(ngModel)]="rule.value" (ngModelChange)="onChange()" placeholder="dd/mm/yyyy">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; let options=options; type: 'multiselect'; let onChange=onChange">
            <mat-form-field [style.width.px]="300" appRemoveAriaOwns>
                <mat-select placeholder="Please Select One" [(ngModel)]="rule.value" multiple (ngModelChange)="onChange()">
                    <mat-option *ngFor="let opt of options" [value]="opt.value">
                        {{ opt.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; let field=field; type: 'number'; let onChange=onChange">
            <mat-form-field *ngIf="rule.operator !== 'IN'" [style.width.px]="200">
                <input matInput [(ngModel)]="rule.value" type="number" placeholder="Enter Value" (ngModelChange)="onChange()">
            </mat-form-field>
            <mat-form-field *ngIf="rule.operator === 'IN'">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let tagValue of rule.value" (removed)="removeTag(tagValue, rule)">
                        {{tagValue}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input type="number" placeholder="Enter value(s)..." [matChipInputFor]="chipList" focused (matChipInputTokenEnd)="addNewTag($event, rule)" title="Type and hit Enter key or Comma to add multiple values" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur">
                </mat-chip-list>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; let field=field; type: 'string'; let onChange=onChange">
            <mat-form-field *ngIf="rule.operator !== 'IN'">
                <input matInput [(ngModel)]="rule.value" (ngModelChange)="onChange()" placeholder="Enter value">
            </mat-form-field>
            <mat-form-field *ngIf="rule.operator === 'IN'">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let tagValue of rule.value" (removed)="removeTag(tagValue, rule)">
                        {{tagValue}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input type="text" placeholder="Enter value(s)..." [matChipInputFor]="chipList" focused (matChipInputTokenEnd)="addNewTag($event, rule)" title="Type and hit Enter key or Comma to add multiple values" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur">
                </mat-chip-list>
            </mat-form-field>
        </ng-container>
        <ng-container *queryInput="let rule; let field=field; type: 'textarea'; let onChange=onChange">
            <mat-form-field>
                <textarea placeholder="Enter input" matInput [(ngModel)]="rule.value" (ngModelChange)="onChange()"></textarea>
            </mat-form-field>
        </ng-container>
    </query-builder>
    <ng-container *ngIf="isRelationConfig">
        <material-button class="d-flex align-items-center justify-content-end" (onTrigger)="relationChanged.emit(query)" [label]="'Apply Relation'"></material-button>
    </ng-container>
</div>
