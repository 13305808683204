<div id="changeLogTemplateBodyContainer">
    <material-accordion [showFooter]="false" [showContentOnlyOnExpand]="true" [expandAccordion]="(index === 0) ? true : false" [disabled]="false" (openAccrodion)="fetchmore(index)" [ngClass]="{'selectedChangeLogData': index === selectedIndex}">
        <div class="materialAccordionHeader">
            <div class="changeLogModifiedByContainer">
                Updated by <strong>{{log.userName}}</strong> on <strong>{{log.logs[0]?.modifiedDate}}</strong>
            </div>
        </div>
        <div class="materialAccordionBody">
            <div class="changeLogDetailsModifiedContainer">
                <material-table [data]="tableData" [selectedColumns]="selectedColumns" [allTableColumns]="allTableColumns">
                </material-table>
            </div>
        </div>
    </material-accordion>
</div>
