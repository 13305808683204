import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { QueryBuilderConfig, Rule } from 'ng-query-builder';

import { Entity } from '../../models/entity.class';
import { Field } from '../../models/field.class';
import { RecordField } from '../../models/record/recordfield.class';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(private _utils: UtilsService, private _rootScope: RootScopeService, private _commUtils: CommonUtilsService) {}

    /* Fetching the search fields from the entity and adding them to the searchFields array. */
    buildFieldsFromEntity = (
        entity: Entity,
        origin?: string
    ): {
        searchConfig: QueryBuilderConfig;
        sortConfig: QueryBuilderConfig;
        searchFields: any[];
    } => {
        if (!entity) {
            return;
        }
        const searchFields = [];
        for (const fieldId in entity.fields) {
            if (entity.fields.hasOwnProperty(fieldId)) {
                const field = entity.fields[fieldId];
                if (origin && origin === 'eninvoice') {
                    if (
                        field.searchable &&
                        ['api_action', 'conslidate_ewaybill_generation', 'confirmed_by', 'receiver_name', 'attach_gnr'].indexOf(
                            field.uid
                        ) === -1
                    ) {
                        pushField(field);
                    }
                } else {
                    if (field.searchable) {
                        pushField(field);
                    }
                }
            }
        }
        if (entity.primary) {
            [
                {
                    displayName: translate('Transmission Id'),
                    id: 'requestId',
                    datatype: 'string',
                },
                {
                    displayName: translate('Instance State'),
                    id: 'instanceState.raw',
                    dataType: 'options',
                },
                {
                    displayName: translate('Workflow Status'),
                    id: 'instanceStatus.raw',
                    datatype: 'string',
                },
                {
                    displayName: translate('Validation Status'),
                    id: 'validationStatus',
                    datatype: 'options',
                },
            ].forEach((field) => {
                field['name'] = field.displayName;
                field['searchable'] = true;
                const recordField = new RecordField(field, field as any, undefined);
                searchFields.push(recordField);
            });
        }
        const { searchConfig, sortConfig } = this.fetchSearchFeilds(entity, searchFields);
        return { searchConfig, sortConfig, searchFields };
        function pushField(field: Field) {
            const newField = new RecordField({}, field, entity);
            if (newField.id === 'e_waybill_status') {
                newField.value = undefined;
            }
            searchFields.push(newField);
        }
    };

    fetchSearchFeilds = (
        selectedSearchEntity: Entity,
        searchFields: any[]
    ): { searchConfig: QueryBuilderConfig; sortConfig: QueryBuilderConfig } => {
        const searchConfig: QueryBuilderConfig = {
            fields: {},
        };
        const sortConfig: QueryBuilderConfig = {
            fields: {},
        };
        // this.setQueriesToDefault(); handle at component level
        const searchObj = {};
        const sortObj = {};
        searchFields.forEach((field) => {
            if (field.searchable) {
                this.getElasticSearchObject(field, searchObj);
            }
            if (field.sortable) {
                this.getElasticSortObject(field, sortObj);
            }
        });
        searchConfig['fields'] = Object.assign({}, searchObj);
        sortConfig['fields'] = Object.assign({}, sortObj);
        // this.isSortConfigEmpty = !Object.keys(sortConfig.fields).length; handle at component level
        if (selectedSearchEntity.primary) {
            searchConfig.fields['requestId'].type = 'string';
            searchConfig.fields['instanceState.raw'].type = 'category';
            searchConfig.fields['instanceState.raw'].options = this._rootScope.INSTANCE_STATES;
            searchConfig.fields['instanceState.raw'].nullable = false;
            this.pushWorkflowField('instanceStatus.raw', searchConfig);
        }
        searchConfig.fields['validationStatus'] = {
            name: translate('Validation Status'),
            nullable: false,
            type: 'category',
            options: this._rootScope.VALIDATION_STATUS,
        };
        searchConfig.fields['createdBy.raw'] = {
            name: translate('Created By / Updated By'),
            type: 'string',
        };
        // this._broadcaster.broadcast('queryConfigChange', data); /* handle at component level */
        return { searchConfig, sortConfig };
        // appendSavedCriteriaCallBack && appendSavedCriteriaCallBack(); handle at component level
    };

    pushWorkflowField = (fieldId: string, searchConfig: QueryBuilderConfig) => {
        // check if this.searchconfig being passed from component is updated or not
        searchConfig.fields[fieldId] = {
            name: 'Workflow Status',
            type: 'string',
            operators: ['EQ', 'IN'],
        };
    };

    getElasticSearchObject = (field: RecordField, initialObject) => {
        const dataType = this._utils.getDataType(field.datatype);
        const id = field.id;
        if (!id) {
            return;
        }
        initialObject[id] = {
            name: field.displayName,
            type: dataType,
            operators: this._utils.getSearchOperatorsPerDataType(dataType),
            nullable: false,
        };
        switch (dataType) {
            case 'number':
                initialObject[id]['minimum'] = 0;
                break;
            case 'category':
                initialObject[id]['choices'] = ['SUCCESS', 'ERROR'];
                break;
            case 'date':
                initialObject[id]['defaultValue'] = CommonUtilsService.transformDate(new Date() as any, 'DATE', 'dd/mm/yyyy');
                initialObject[id]['format'] = field.outputFormat || 'dd/mm/yyyy';
                break;
            case 'boolean':
                initialObject[id]['options'] = [
                    {
                        name: 'true',
                        value: 'true',
                    },
                    {
                        name: 'false',
                        value: 'false',
                    },
                ];
                break;
        }
    };

    getElasticSortObject = (field: RecordField, initialObject) => {
        if (!field || !field.id) {
            return;
        }
        initialObject[field.id] = {
            name: field.displayName,
            type: 'category',
            operators: ['='],
            nullable: false,
            options: [
                {
                    name: translate('Ascending'),
                    value: 'ASC',
                },
                {
                    name: translate('Descending'),
                    value: 'DESC',
                },
            ],
        };
    };

    isValidINRule = (restriction): { isValidINRule: boolean; hasInOperator: boolean } => {
        const inRules = restriction.rules.filter((rule) => rule.operator === 'IN');
        const hasInOperator = restriction.rules.length;
        const isValidINRule = inRules.every((rule) => rule.value.length);
        return { isValidINRule, hasInOperator };
    };

    updateSearchCriteria = (filterCriteria, selectedSearchEntity) => {
        const checkForExistance =
            filterCriteria.entityFilterCriterias?.length &&
            filterCriteria.entityFilterCriterias.find((criteria) => criteria.entityUid === selectedSearchEntity.uid);
        const checkForRelationExistance =
            filterCriteria.criteriaRelations?.rules?.length &&
            filterCriteria.criteriaRelations.rules.find((rule) => rule.value === selectedSearchEntity.uid);
        if (checkForRelationExistance) {
            const index = filterCriteria.criteriaRelations.rules.findIndex((rule) => rule.value === checkForRelationExistance.value);
            filterCriteria.criteriaRelations.rules.splice(index, 1);
        }
        if (checkForExistance) {
            const index = filterCriteria.entityFilterCriterias.findIndex((criteria) => criteria.entityUid === checkForExistance.entityUid);
            filterCriteria.entityFilterCriterias.splice(index, 1);
            this._utils.alertSuccess(`Search criteria has been Updated`);
        } else {
            this._utils.alertError('Search criteria cannot be empty');
            return;
        }
    };

    isValidCriteria = (restriction) => {
        let isValid = true;
        if (!restriction || !restriction.rules || !restriction.rules.length) {
            isValid = false;
        }
        restriction.rules.forEach((rule) => {
            if (rule.operator === 'is not null' || rule.operator === 'is null') {
                return isValid;
            } else {
                if (isValid && rule.rules) {
                    isValid = this.isValidCriteria(rule);
                } else {
                    isValid = isValid && rule.value !== undefined && rule.value ? true : false;
                }
            }
        });
        return isValid;
    };

    // Method to apply recurssion for rules which will manipulate the queryBuilder searchCriteria
    applyRecurssionToRules = (rule, searchConfig) => {
        if ('rules' in rule) {
            if (rule.collapsed !== 'null' && rule.collapsed !== 'undefined') {
                delete rule.collapsed;
            }
            rule.condition = rule.condition.toUpperCase();
            rule.rules.forEach((innerRule) => {
                if (innerRule.field && this.getDataTypeOfField(innerRule.field, searchConfig) === 'date') {
                    const localeFormat = this._commUtils.transformDateToLocale(innerRule.value, 'dd/mm/yyyy', 'mm/dd/yyyy', true);
                    innerRule.value = this._commUtils.transformDateToDefaultFormat(localeFormat);
                }
                if (innerRule.operator === 'CONTAINS') {
                    if (innerRule.value.toString().indexOf('*') !== 0) {
                        if (typeof innerRule.value === 'string') {
                            innerRule.value = innerRule.value.trim();
                        }
                        innerRule.value = '*' + innerRule.value + '*';
                    }
                } else if (innerRule.operator === 'is null') {
                    innerRule.operator = 'NOT_EXISTS';
                    delete innerRule.value;
                } else if (innerRule.operator === 'is not null') {
                    innerRule.operator = 'EXISTS';
                    delete innerRule.value;
                } else if (innerRule.operator === '!=') {
                    innerRule.operator = 'NOT_EQUALS';
                } else if (innerRule.operator === 'IN') {
                    if (innerRule.value.includes(',')) {
                        innerRule.value = innerRule.value.split(',');
                        innerRule.value.forEach((value, index) => {
                            innerRule.value[index] = value.trim();
                        });
                    } else {
                        if (typeof innerRule.value === 'string') {
                            innerRule.value = [innerRule.value];
                        }
                    }
                }
                this.applyRecurssionToRules(innerRule, searchConfig);
            });
            rule['restrictionType'] = 'JoinedRestrictionMetadata';
        } else {
            rule['fieldId'] = rule['field'];
            rule['restrictionType'] = 'SingleRestrictionMetadata';
            delete rule['field'];
            delete rule['entity'];
            if (rule.collapsed !== 'null' && rule.collapsed !== 'undefined') {
                delete rule.collapsed;
            }
            if (rule?.id) {
                delete rule.id;
            }
            if (rule?.type) {
                delete rule.type;
            }
            if (rule?.format) {
                delete rule.format;
            }
        }
    };

    /* The below code is creating a query object for the filter criteria. */
    buildAutoRelationsQuery = (filterCriteria) => {
        const relations = CommonUtilsService.cloneObject(filterCriteria.entityFilterCriterias);
        if (relations.length > 1) {
            const relationRule = {
                condition: 'and',
                rules: [],
            };
            relations.forEach((relation) => {
                const extractedRelation = {
                    field: 'Criteria',
                    operator: 'EQ',
                    value: relation.entityUid,
                    entity: undefined,
                };
                const checkForExistance = relationRule.rules.find((rule) => rule.value === relation.entityUid);
                if (!checkForExistance) {
                    relationRule.rules.push(extractedRelation);
                }
            });
            return relationRule;
        }
    };

    /**
     * Method to find the data type of fields based on the config object
     */
    getDataTypeOfField = (fieldName, searchConfig) => {
        return searchConfig?.fields[fieldName]?.type || 'string';
    };

    /**
     * Method to check whether the entity criteria is already present in the search query
     */
    checkForExistance = (entityFilterCriteria, filterCriteria, callBack) => {
        if (!filterCriteria.entityFilterCriterias.find((entityFilter) => entityFilter.entityUid === entityFilterCriteria.entityUid)) {
            callBack?.(entityFilterCriteria);
        } else {
            const entityIndex = filterCriteria.entityFilterCriterias.findIndex(
                (entityFilter) => entityFilter.entityUid === entityFilterCriteria.entityUid
            );
            callBack?.(entityIndex);
        }
    };

    /**
     * Method to get rules in selectedSearchEntity
     * @param filterCriteria is search Criteria
     * @param selectedSearchEntity is selected entity in custom search
     */
    getRulesOfSelectedEntity = (
        filterCriteria: any,
        selectedSearchEntity: {
            uid: string;
        }
    ) => {
        if (filterCriteria.entityFilterCriterias.length) {
            const selectedEntity: any = filterCriteria.entityFilterCriterias?.find(
                (entityFilterCriteria) => entityFilterCriteria.entityUid === selectedSearchEntity.uid
            );
            if (selectedEntity?.criteriaDefinitions.length) {
                const selectedEntityRestriction: any = selectedEntity?.criteriaDefinitions[0].restriction;
                return selectedEntityRestriction?.rules as any[];
            }
        }
    };

    /**
     * Method to trim down restrictions to make it compatible for rendering in UI using es query builder plugin
     */
    transformRestrictionForRendering = (restriction: any, searchConfig) => {
        if (!restriction || !restriction.rules || !restriction.rules.length) {
            return;
        }
        restriction.rules.forEach((rule) => {
            if (rule.operator) {
                // It is a plain rule
                rule['field'] = (rule.fieldId ?? rule.field) || rule.id;
                delete rule.fieldId;
                delete rule.restrictionType;
                // transform EXISTS as 'is not null' and  NOT_EXISTS as 'is null'
                if (rule.operator === 'EXISTS') {
                    // It is a plain rule
                    rule.operator = 'is not null';
                } else if (rule.operator === 'NOT_EXISTS') {
                    // It is a plain rule
                    rule.operator = 'is null';
                }
                if (this.getDataTypeBySearchConfig(rule.field, searchConfig) === 'date') {
                    rule.value = CommonUtilsService.transformDate(rule.value, 'dd-mm-yyyy', 'dd/mm/yyyy');
                }
            } else if (rule.condition) {
                // It is a rule-set (restriction)
                rule = this.transformRestrictionForRendering(rule, searchConfig);
            }
        });
        delete restriction.restrictionType;
        return restriction;
    };

    getDataTypeBySearchConfig = (fieldName, searchConfig) => {
        return (searchConfig && searchConfig.fields[fieldName] && searchConfig.fields[fieldName].type) || 'string';
    };

    /**
     * Method to convert booleans to small case while rendering back to search query
     */

    transformBooleanToLowerCase = (queryObj) => {
        if (!queryObj) {
            return;
        }
        if (queryObj['condition'] === 'AND') {
            queryObj['condition'] = 'and';
        } else if (queryObj['condition'] === 'OR') {
            queryObj['condition'] = 'or';
        }

        if (queryObj.rules && queryObj.rules.length) {
            for (let i = 0; i < queryObj.rules.length; i++) {
                queryObj.rules[i] = this.transformBooleanToLowerCase(queryObj.rules[i]);
            }
        }
        return queryObj;
    };

    /**
     *
     * @param restriction is an object that contains condition, restrictionType, rules
     * @param extras is an object that holds the extra information that is useful while rendering the search query
     */
    detatchServerExtras = (restriction, extras: { [property: string]: string }) => {
        switch (extras.operator) {
            case 'CONTAINS':
                if (restriction.rules?.length) {
                    restriction.rules.forEach((rule) => {
                        const isTruthy = rule.value?.includes('*');
                        if (isTruthy) {
                            rule.value = rule.value?.slice(1, -1);
                        }
                    });
                }
                break;
            default:
                break;
        }
    };

    /* This function is called when the user clicks on the "Show Filters" button.
    It finds the parent of the selected search entity and toggles the parent and child entities. */
    toggleParentAndChildEntities = (selectedSearchEntity: Entity, origin: string, uids: any[]) => {
        const orderUids = [...uids.filter((uid) => uid.length)];
        uids = orderUids[0];
        if (uids?.length > 1) {
            if (uids.includes(selectedSearchEntity.uid)) {
                if (origin === 'PROCESS') {
                    uids.length = 1;
                }
                uids.forEach((entityUid) => {
                    const element = document.querySelector(`.${entityUid}`) as HTMLElement;
                    if (element?.classList.contains('notClicked')) {
                        element?.click();
                    }
                });
            }
        }
    };

    toggle = async (entities: Entity[], selectedSearchEntity: Entity, origin: string) => {
        const entitiesContainingSubs = entities.filter((entity) => entity.entities.length);
        const uids = await this.findParentWithChildEntity(entitiesContainingSubs, selectedSearchEntity);
        if (uids?.length > 1) {
            this.toggleParentAndChildEntities(selectedSearchEntity, origin, uids);
        }
    };

    /* Find the parent entity of the selected search entity. */
    findParentWithChildEntity = async (entities, selectedSearchEntity) => {
        const uids = await Promise.all(
            entities.map(async (entity: Entity) => {
                return await this.getParentEntityWithChildEntity(entity, selectedSearchEntity.uid);
            })
        );
        return uids;
    };

    /**
     * Given an entity and a child entity uid, return the parent entity uid(s) of the child entity.
     * @param entity is the entity that is selected in the custom search
     * @param entityUid is the uid of the child entity
     * @returns the parent entity uid(s) of the child entity
     */
    getParentEntityWithChildEntity = (entity: Entity, entityUid: string) => {
        return new Promise((resolve) => {
            const uids = getUids(entity, entityUid);
            if (uids) {
                resolve(uids);
            } else {
                resolve([]);
            }
        });

        function getUids(entity: Entity, entityUid: string) {
            if (entity.uid === entityUid) {
                return [entity.uid];
            }
            if (entity.entities || Array.isArray(entity)) {
                const children = Array.isArray(entity) ? entity : entity.entities;
                for (const child of children) {
                    const result = getUids(child, entityUid);
                    if (result) {
                        if (entity.uid) {
                            result.unshift(entity.uid);
                        }
                        return result;
                    }
                }
            }
        }
    };

    removeUnnecessaryProperties = (searchCriteria: any) => {
        switch (typeof searchCriteria) {
            case 'string':
            case 'number':
            case 'boolean':
                break;
            case 'object':
                switch (Array.isArray(searchCriteria)) {
                    case true:
                        searchCriteria.forEach((criteria) => this.removeUnnecessaryProperties(criteria));
                        break;
                    case false:
                        Object.keys(searchCriteria).forEach((key) => {
                            if (key === 'collapsed') {
                                delete searchCriteria[key];
                            } else {
                                this.removeUnnecessaryProperties(searchCriteria[key]);
                            }
                        });
                        break;
                }
                break;
        }
    };

    /**
     * Method that sets searchQuery, sortQuery and relationQuery criterias to default state
     */
    setQueriesToDefault = (
        searchQuery: {
            condition: string;
            rules: any[];
        },
        sortQuery: {
            condition: string;
            rules: any[];
        },
        copySearchQuery: {
            condition: string;
            rules: any[];
        },
        copySortQuery: {
            condition: string;
            rules: any[];
        },
        relationQuery?: {
            condition: string;
            rules: any[];
        }
    ) => {
        searchQuery = {
            condition: 'and',
            rules: [],
        };
        sortQuery = {
            condition: 'and',
            rules: [],
        };
        copySearchQuery = {
            condition: 'AND',
            rules: [],
        };
        copySortQuery = {
            condition: 'AND',
            rules: [],
        };
        if (relationQuery) {
            relationQuery = {
                condition: 'and',
                rules: [],
            };
        }
        return { searchQuery, sortQuery, relationQuery, copySearchQuery, copySortQuery };
    };

    /* This is a recursive function that will add all the nested entities to the entityUidArray. */
    addNestedEntitiesInArray = (entities, entityUidArray) => {
        entities?.forEach((entity) => {
            entityUidArray.push(entity.uid);
            if (entity.entities?.length) {
                this.addNestedEntitiesInArray(entity.entities, entityUidArray);
            }
        });
    };

    buildSortCriteria = (copySortQuery: { rules: Rule[] }) => {
        const sort: {
            [property: string]: string;
        } = {} as const;
        copySortQuery?.rules?.forEach((rule: Rule) => {
            sort[rule.field] = rule.value;
        });
        return sort;
    };

    // isValidRule = (rule: any): boolean => {
    //     const validProps = ['field', 'operator', 'value'];
    //     let valid: boolean;
    //     if ('rules' in rule) {
    //         rule.rules.forEach((innerRule) => {
    //             this.isValidRule(innerRule);
    //         });
    //     } else {
    //         const keys = Object.keys(rule);
    //         valid = keys.every((key) => validProps.includes(key));
    //     }
    //     return valid;
    // };

    // buildValidRule = (rule: any): void => {
    //     const validProps = ['field', 'operator', 'value'];
    //     if ('rules' in rule) {
    //         rule.rules.forEach((innerRule) => {
    //             this.makeItValid(innerRule, validProps);
    //         });
    //     } else {
    //         this.makeItValid(rule, validProps);
    //     }
    // };

    // makeItValid = (rule: any, validProps: string[]): void => {
    //     const keys = Object.keys(rule);
    //     keys.forEach((key) => {
    //         if (!validProps.includes(key)) {
    //             delete rule[key];
    //         }
    //     });
    // };
}
