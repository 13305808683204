<div class="docsContainer" *transloco="let translate;">
    <ng-container *ngIf="documentList?.length; else hasNoDocs">
        <div class="documentsHeader">
            <div class="my-2 d-flex">
                <span class="documentHeaderText d-block text-left font-weight-bold ml-2">{{translate('Available Documents')}}</span>
            </div>
        </div>
        <div class="docsList" stop-propagation>
            <div class="docsSearch px-3">
                <mat-form-field>
                    <input matInput [placeholder]="translate('Search document')" type="text" [(ngModel)]="docSearch" (keyup)='searchHelpDocuments()'>
                    <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearch()'>
                        <mat-icon class='locationManagementIcons' *ngIf='docSearch'>close </mat-icon>
                        <mat-icon *ngIf='!docSearch' class="locationManagementIcons">search </mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <ng-container *ngIf="(documentList | FilterPipe: 'fileName' : docSearch) as result">
                <div class="docs">
                    <div class="helpdocumentReports d-flex justify-content-between align-items-center ml-0 mr-0" mat-menu-item *ngFor="let report of result; trackBy:docListTrakBy">
                        <span class="reportsText" stop-propagation>
                            {{report.fileName}}
                        </span>
                        <span *ngIf="report?.fileUrl" class="d-flex justify-content-between" stop-propagation>
                            <mat-icon class="documentDownloadIcon" title="{{translate('Download')}} {{report.fileName}}" (click)="downloadHelpDocument.emit(report)">cloud_download</mat-icon>
                            <ng-container *ngIf="!isIE">
                                <mat-icon class="documentDownloadIcon" title="{{translate('View')}} {{report.fileName}}" (click)="viewHelpDocument.emit(report)">visibility</mat-icon>
                            </ng-container>
                        </span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf='docSearch && noDocumentsList'>
                <div class="d-flex justify-content-center noRecords">
                    <span>No records found.</span>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #hasNoDocs>
        <div class="text-center noDocument" [hidden]="documentList === undefined">
            {{translate('Help document is not available for this service')}}
        </div>
        <div class="text-center noDocument" [hidden]="documentList !== undefined">
            {{translate('Loading Documents')}}...
        </div>
    </ng-template>
</div>
