export class FieldDefinition {
    readonly id: string;

    fieldName: string;

    fieldType: string | boolean;

    resourceType: string;

    minLength?: number;

    maxLength?: number;

    isMandatory?: boolean;

    isUnique?: boolean;

    appId?: string;

    displayName?: string;

    constructor(FieldDefinition: object) {
        this.id = FieldDefinition?.['id'];
        this.fieldName = FieldDefinition?.['fieldName'];
        this.displayName = FieldDefinition?.['displayName'];
        this.minLength = FieldDefinition?.['minLength'];
        this.maxLength = FieldDefinition?.['maxLength'];
        this.isMandatory = FieldDefinition?.['isMandatory'];
        this.isUnique = FieldDefinition?.['isUnique'];
        this.appId = FieldDefinition?.['appId'];
        if (FieldDefinition?.['fieldType'] && typeof FieldDefinition['fieldType'] === 'string') {
            this.fieldType = FieldDefinition['fieldType'];
        } else if (FieldDefinition?.['fieldType'] && typeof FieldDefinition['fieldType'] === 'boolean') {
            this.fieldType = FieldDefinition['fieldType'];
        } else {
            this.fieldType = 'STRING';
        }
        if (FieldDefinition?.['resourceType'] && typeof FieldDefinition['resourceType'] === 'string') {
            this.resourceType = FieldDefinition['resourceType'];
        } else {
            this.resourceType = 'ORGANIZATION';
        }
    }
}
