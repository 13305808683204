import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ExecutedWorkflowStage, ExecuteWorkflowStage, GetWorkflow, SetWorkflow, SetWorkflowLoading } from '../actions';
import {
    getAppsMetaDataMap$,
    getCurrentOrganizationId$,
    getServicesInstancesMap$,
    getSubscribedAppsMap$,
    getWorkflowInstances$,
} from '../selectors';

@Injectable()
export class WorkflowEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    private getWorkflow = createEffect(() =>
        this.actions$.pipe(
            ofType(GetWorkflow),
            delay(10),
            withLatestFrom(
                this.store$.select(getWorkflowInstances$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([action, instancesMap, services, organizationId]) => {
                const app = services?.[action.serviceId];
                if (!app?.serviceId) {
                    return of(GetWorkflow(action));
                }
                if (instancesMap?.[action.instanceId]) {
                    return [];
                }
                this.store$.dispatch(
                    SetWorkflowLoading({
                        loading: true,
                        instanceIds: [action.instanceId],
                    })
                );
                return from(
                    this._api.workflow.getAllWorkflowStages({
                        instanceId: action.instanceId,
                        noAlerts: action.noAlerts,
                        restApiName: app.restApiName,
                        serviceId: action.serviceId,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: [action.instanceId],
                            })
                        );
                        return SetWorkflow({
                            instanceId: action.instanceId,
                            workflows: res,
                        });
                    }),
                    catchError((e) =>
                        of(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: [action.instanceId],
                            })
                        )
                    )
                );
            })
        )
    );

    private executeWorkflow = createEffect(() =>
        this.actions$.pipe(
            ofType(ExecuteWorkflowStage),
            delay(10),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getServicesInstancesMap$),
                this.store$.select(getWorkflowInstances$),
                this.store$.select(getCurrentOrganizationId$)
            ),
            mergeMap(([action, apps, appsMetaData, serviceVsInstancesMap, workflowInstances, organizationId]) => {
                if (!action.instanceIds) {
                    return [];
                }
                const instance = serviceVsInstancesMap?.[action.instanceIds?.[0]];
                const requestId = instance?.assetRequests?.[0]?.requestId;
                const app = apps?.[action.serviceId];
                const stages = appsMetaData?.[action.serviceId]?.[app?.assetMetaUId]?.workflowMetadata?.stages;
                const selectedInstance = action.instanceIds?.find((instance) => workflowInstances?.[instance] !== undefined);
                const instanceWorkflow = workflowInstances?.[selectedInstance]?.find((stage) => stage.startTime && !stage.endTime);
                if (!app || !requestId || !stages || !instanceWorkflow) {
                    return [];
                }
                this.store$.dispatch(
                    SetWorkflowLoading({
                        loading: true,
                        instanceIds: action.instanceIds,
                    })
                );
                return from(
                    this._api.workflow.executeWorkflow({
                        apiAction: action.apiAction,
                        assetId: app.assetMetaUId,
                        cancel: false,
                        comment: action.comment,
                        formFields: CommonUtilsService.cloneObject(action.formFields),
                        instanceId: action.instanceIds,
                        noAlerts: false,
                        noWorkflows: stages.length <= 1,
                        restApiName: app.restApiName,
                        serviceId: app.serviceId,
                        workflow: instanceWorkflow,
                        actionVariables: action.actionVariables,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: action.instanceIds,
                            })
                        );
                        return ExecutedWorkflowStage({
                            instanceIds: action.instanceIds,
                            executeTimestamp: action.executeTimestamp,
                        });
                    }),
                    catchError(() =>
                        of(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: action.instanceIds,
                            })
                        )
                    )
                );
            })
        )
    );
}
