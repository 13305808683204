import { Injectable, OnInit } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';

import { CommonUtilsService } from '../commonutils/common-utils.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class StoreService implements OnInit {
    private scope: { [property: string]: any } = {
        public: {},
        private: {},
    };
    private loading: {
        [property: string]: {
            promiseObject: Promise<any>;
            resolveMethod: (...args: any[]) => void;
        };
    } = {};
    private organizationId: string;

    constructor(private _broadcaster: BroadcasterService, private _commonUtils: CommonUtilsService, private _utils: UtilsService) {
        this.setOrganizationId();
        this._broadcaster.on('addedLocation').subscribe((event) => this.pushToScope(this.publicScope.getScope(), event, 'associatedOrgs'));
    }

    public privateScope = {
        fetchValues: (serverMethod: () => Promise<any>, ...args: any[]) =>
            this.fetchValues(serverMethod, this.privateScope.getScope(), args),
        getScope: () => this.scope.private[this.organizationId],
        clearValue: (...args: string[]) => this.clearStoreValue(this.privateScope.getScope(), args),
    };

    public publicScope = {
        fetchValues: (serverMethod: () => Promise<any>, ...args: any[]) =>
            this.fetchValues(serverMethod, this.publicScope.getScope(), args),
        getScope: () => this.scope.public,
        clearValue: (...args: string[]) => this.clearStoreValue(this.publicScope.getScope(), args),
    };

    private pushToScope = (scope: any, value: any, ...args: string[]) => {
        let currentScope: any;
        args.forEach((arg, index) => {
            if (index === args.length - 1) {
                const lastScope = currentScope || scope;
                if (Array.isArray(lastScope[arg])) {
                    lastScope[arg].push(value);
                } else {
                    lastScope[arg] = value;
                }
            } else {
                currentScope = currentScope ? currentScope[arg] : scope[arg];
            }
        });
    };

    logout = () => {
        this.scope = { public: {}, private: {} };
        this.clearLoading();
    };

    clearLoading = () => {
        this.loading = {};
    };

    setOrganizationId = () => {
        this.organizationId = this._commonUtils.getFromStorage('currentorganizationid');
        this.createScope();
    };

    private createScope = () => {
        if (!this.scope) {
            this.scope = {
                public: {},
                private: {},
            };
        }
        if (this.organizationId && !this.scope.private[this.organizationId]) {
            this.scope.private[this.organizationId] = {};
        }
    };

    private checkPropertiesLoading = (properties?: string[]) => {
        return new Promise<void>((resolve) => {
            const promises = [];
            if (typeof properties === 'string') {
                promises.push(
                    new Promise<void>(async (resolveProperty) => {
                        const isPresent = this.loading[properties];
                        if (isPresent) {
                            await isPresent.promiseObject;
                        }
                        resolveProperty();
                    })
                );
            } else if (Array.isArray(properties)) {
                properties.forEach((id) => {
                    promises.push(
                        new Promise<void>(async (resolveProperty) => {
                            const isPresent = this.loading[id];
                            if (isPresent) {
                                await isPresent.promiseObject;
                            }
                            resolveProperty();
                        })
                    );
                });
            }
            Promise.all(promises).then(() => resolve());
        });
    };

    private checkIfLoading = (args: any[]) => {
        let found = false;
        args?.forEach((arg) => {
            if (typeof arg === 'string') {
                found = found || this.loading[arg] !== undefined;
            } else if (Array.isArray(arg)) {
                arg?.forEach((id: string) => {
                    found = found || this.loading[id] !== undefined;
                });
            }
        });
        if (!found) {
            return found;
        }
        return new Promise<void>((resolve) => {
            const promises = [];
            (args || []).forEach(async (arg) => {
                promises.push(this.checkPropertiesLoading(arg));
            });
            Promise.all(promises).then(() => {
                resolve();
            });
        });
    };

    private validateSubScope = (scope: any, args: any[]) => {
        let stringIndex = -1;
        let continueScope = true;
        let isStringArgs = true;
        args.forEach((arg: any, argIndex: number) => {
            if (!continueScope) {
                return;
            }
            stringIndex++;
            if (typeof arg === 'string') {
                isStringArgs = true;
                if (scope?.[arg]) {
                    scope = scope[arg];
                    if (!args[stringIndex + 1] || typeof args[stringIndex + 1] === 'string') {
                        delete args[stringIndex];
                    }
                } else {
                    continueScope = false;
                }
            } else if (Array.isArray(arg)) {
                isStringArgs = false;
                const argClone = arg?.slice(0);
                let index = 0;
                Array.isArray(argClone) &&
                    argClone.forEach((argValue) => {
                        const scopeValue = scope?.[argValue];
                        if (scopeValue) {
                            if (args[argIndex + 1]?.length > 0) {
                                this.validateSubScope(scopeValue, args.slice(0).splice(1));
                            } else {
                                arg.splice(index, 1);
                            }
                        } else {
                            index++;
                        }
                    });
            }
        });
        return { isStringArgs };
    };

    private checkSubScope = (scope: any, args: any[]) => {
        const argsClone = args.slice(0);
        const { isStringArgs } = this.validateSubScope(scope, args);
        // let previousArgEmpty = false;
        // args.reverse().forEach((arg, index, prevArgs) => {
        //     const prevArg = prevArgs[index - 1];
        //     if (index === 0) {
        //         if (Array.isArray(arg) ? arg.length === 0 : !arg || arg.length === 0) {
        //             previousArgEmpty = true;
        //         } else {
        //             previousArgEmpty = false;
        //         }
        //     } else if (index - 1 > -1 && previousArgEmpty) {
        //         if (Array.isArray(prevArg) ? prevArg.length === 0 : !prevArg || prevArg.length === 0) {
        //             if (Array.isArray(arg)) {
        //                 arg.splice(0);
        //             }
        //             previousArgEmpty = true;
        //         } else {
        //             previousArgEmpty = false;
        //         }
        //     }
        // });
        // args.reverse();
        let found = true;
        args.forEach((arg) => {
            found = found && (arg === undefined || arg?.length === 0);
        });
        if (!found && isStringArgs) {
            args.splice(0, args.length);
            argsClone.forEach((arg) => args.push(arg));
        } else if (!found && !isStringArgs) {
            const hasStringArgs = argsClone.find((arg) => typeof arg === 'string');
            if (hasStringArgs) {
                let hasArrayArgs = true;
                args.forEach((arg) => {
                    hasArrayArgs =
                        hasArrayArgs && (arg === undefined || typeof arg === 'string' || (typeof arg !== 'string' && arg?.length > 0));
                });
                if (!hasArrayArgs) {
                    found = true;
                }
            }
        }
        return found;
    };

    private checkStoredValues = (scope: any, args?: any[]) => {
        if (args?.length > 0) {
            const found = this.checkSubScope(scope, args);
            return found ? scope : undefined;
        }
        return scope;
    };

    private markLoadingStarted = (args: any[]) => {
        args.forEach((arg) => {
            if (typeof arg === 'string') {
                if (this.loading[arg]) {
                    return;
                }
                const promiseObject: {
                    promiseObject: Promise<any>;
                    resolveMethod: (...args: any[]) => void;
                } = {} as any;
                promiseObject.promiseObject = new Promise((resolve) => {
                    promiseObject.resolveMethod = resolve;
                });
                this.loading[arg] = promiseObject;
            } else if (Array.isArray(arg)) {
                this.markLoadingStarted(arg);
            }
        });
    };

    private storeValues = (values: { [property: string]: any }, args: any[], scope: any) => {
        args.forEach((arg, index: number) => {
            if (typeof arg === 'string') {
                const found = values && (Array.isArray(values) ? values.length > 0 : Object.keys(values).length > 0);
                if (!found) {
                    this.loading[arg]?.resolveMethod();
                    delete this.loading[arg];
                } else {
                    if (args[index + 1] !== undefined) {
                        scope = scope || {};
                        scope[arg] = scope[arg] || {};
                    } else {
                        scope[arg] = Array.isArray(values) ? [] : {};
                    }
                    scope = scope[arg];
                }
            } else if (arg?.length > 0) {
                arg?.forEach((id: any) => {
                    let found = false;
                    if (typeof values === 'object') {
                        if (Array.isArray(values)) {
                            scope[id] = scope[id] || [];
                            found = values?.length > 0;
                        } else {
                            Object.keys(values).forEach((valueObject: string) => {
                                found = found || values[valueObject][id] !== undefined;
                            });
                        }
                    }
                    if (!found) {
                        this.loading[id]?.resolveMethod();
                        delete this.loading[id];
                    }
                });
            }
        });
        values && this._utils.copyObjectToObject(scope, values);
    };

    private markLoadingCompleted = (args: any[]) => {
        args.forEach((arg) => {
            if (typeof arg === 'string') {
                this.loading[arg]?.resolveMethod?.();
                delete this.loading[arg];
            } else if (typeof arg === 'object' && Array.isArray(arg)) {
                arg.forEach((id) => {
                    this.loading[id]?.resolveMethod?.();
                    delete this.loading[id];
                });
            }
        });
    };

    private fetchValues = (serverMethod: () => Promise<any>, scope: any, args: string[]): Promise<any> => {
        const originalArgs = CommonUtilsService.cloneObject(args);
        return new Promise(async (resolve) => {
            const found = this.checkIfLoading(args);
            if (typeof found !== 'boolean') {
                await found;
                return resolve(await this.fetchValues(serverMethod, scope, originalArgs));
            }
            const values = this.checkStoredValues(scope, args.slice(0));
            if (values) {
                return resolve(CommonUtilsService.cloneObject(values));
            }
            this.markLoadingStarted(args);
            const response = await serverMethod();
            this.storeValues(response, CommonUtilsService.cloneObject(args), scope);
            this.markLoadingCompleted(originalArgs);
            resolve(CommonUtilsService.cloneObject(this.checkStoredValues(scope)));
        });
    };

    private clearStoreValue = (scope: any, args: string[]) => {
        this.markLoadingCompleted(args);
        let storeValue = scope;
        args.forEach((arg, index) => {
            if (args[index + 1]) {
                storeValue = storeValue[arg];
            } else {
                delete storeValue?.[arg];
            }
        });
    };

    ngOnInit() {}
}
