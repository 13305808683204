import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Role, TableRecordAction } from 'taxilla-library';

@Component({
    selector: 'role-table',
    templateUrl: './roletable.component.html',
})
export class RoleTableComponent implements OnInit, OnDestroy {
    @Input() roles: BehaviorSubject<Role[]>;
    @Input() hidePaginator;
    @Input() roleTableColumns;
    @Input() hideTableColumns;
    @Input() primaryColumns;
    @Input() tableNotAvailable;
    @Input() needRoleActions;
    @Input() noAction;
    @Input() defaultPaginator;
    @Input() bundleId: string;

    @Output() selectedRecord = new EventEmitter();
    @Output() checkboxSelection = new EventEmitter();
    @Output() copyOfSelectedRole = new EventEmitter();
    @Output() deleteOfSelectedROle = new EventEmitter();
    @Output() assignUsersToSelectedRole = new EventEmitter();
    @Output() viewRoleRecord = new EventEmitter();

    rolesSubscription: Subscription;
    data = [];
    columns = [];
    allColumns = [];
    selectedColumns = [];
    tableRecordActions: TableRecordAction[];
    public currentSearch: {
        [property: string]: any;
    } = {};
    selectedRoleIds = [];

    constructor() {}

    buildDataObjectForTable = () => {
        (!this.columns || this.columns.length === 0) && this.buildColumns();
    };

    buildColumns = () => {
        this.columns = [];
        for (let i = 0; i < this.roleTableColumns.length; i++) {
            const element = this.roleTableColumns[i];
            this.allColumns.push(element.displayName);
            if (element.show) {
                this.selectedColumns.push(element.displayName);
            }
        }
    };

    buildData = (roles: Role[]) => {
        if (this.needRoleActions && (!this.tableRecordActions || this.tableRecordActions.length === 0)) {
            this.tableRecordActions = [];
            this.tableRecordActions.push({
                displayName: translate('Edit Role'),
                iconName: 'edit',
                onActionTrigger: this.onRecordSelect,
            });
            this.tableRecordActions.push({
                displayName: translate('View Role'),
                iconName: 'remove_red_eye',
                onActionTrigger: this.onRecordView,
            });
            !this.bundleId &&
                this.tableRecordActions.push({
                    displayName: translate('Copy Role'),
                    iconName: 'file_copy',
                    onActionTrigger: this.copyRole,
                });
            this.tableRecordActions.push(<any>{
                displayName: translate('Delete Role'),
                iconName: 'delete_forever',
                onActionTrigger: this.deleteRole,
                conditionalDisplay: true,
                conditionType: 'NOT_EQUALS',
                byFieldValue: true,
                conditionalFields: [
                    {
                        fieldId: 'Role Name',
                        value: 'CUSTOMER_ADMIN',
                    },
                ],
            });
            !this.bundleId &&
                this.tableRecordActions.push({
                    displayName: translate('Assign Users'),
                    iconName: 'assignment_turned_in',
                    onActionTrigger: this.assignUsers,
                });
        }
        this.data = [];
        for (let i = 0; i < roles.length; i++) {
            const role: Role = roles[i];
            const record: any = {};
            const columns = [...Object.keys(role), ...this.allColumns];
            for (let j = 0; j < columns.length; j++) {
                const fieldValue = this.getFieldValue(columns[j], this.roleTableColumns, role);
                record[columns[j]] = fieldValue;
            }
            record.selected = record.selected || this.selectedRoleIds.includes(role.id);
            this.data.push(record);
        }
    };

    copyRole = (data?) => {
        const record: any = {};
        for (let j = 0; j < this.allColumns.length; j++) {
            const field = this.getField(this.allColumns[j], this.roleTableColumns);
            record[field] = data[this.allColumns[j]];
        }
        this.copyOfSelectedRole.emit(record);
    };

    assignUsers = (data?) => {
        const record: any = {};
        for (let j = 0; j < this.allColumns.length; j++) {
            const field = this.getField(this.allColumns[j], this.roleTableColumns);
            record[field] = data[this.allColumns[j]];
        }
        this.assignUsersToSelectedRole.emit(record);
    };

    deleteRole = (data?) => {
        const record: any = {};
        for (let j = 0; j < this.allColumns.length; j++) {
            const field = this.getField(this.allColumns[j], this.roleTableColumns);
            record[field] = data[this.allColumns[j]];
        }
        this.deleteOfSelectedROle.emit(record);
    };

    onRecordSelect = (data) => {
        const record: any = {};
        for (let j = 0; j < this.allColumns.length; j++) {
            const field = this.getField(this.allColumns[j], this.roleTableColumns);
            record[field] = data[this.allColumns[j]];
        }

        this.selectedRecord.emit(record);
    };

    onSelectCheckbox = (selectedRows) => {
        const selectedRoleIds = [],
            allRoleIds = [];
        selectedRows.forEach((role) => selectedRoleIds.push(role.ID || role.id));
        this.data.forEach((role) => allRoleIds.push(role.ID || role.id));
        this.selectedRoleIds = [
            ...new Set([
                ...this.selectedRoleIds.filter((id) => (allRoleIds.includes(id) ? selectedRoleIds.includes(id) : true)),
                ...selectedRoleIds,
            ]),
        ];
        const rolesToPush = this.roles.value.slice(0);
        rolesToPush.forEach((role) => (role['selected'] = this.selectedRoleIds.includes(role.id)));
        this.checkboxSelection.emit(rolesToPush);
    };

    getFieldValue = (displayValue, roleColumns, role) => {
        let fieldValue = '';
        for (let i = 0; i < roleColumns.length; i++) {
            if (displayValue === roleColumns[i].displayName) {
                fieldValue = role[roleColumns[i].name];
                break;
            }
        }
        return fieldValue;
    };

    getField = (displayValue, roleColumns) => {
        let field = '';
        for (let i = 0; i < roleColumns.length; i++) {
            if (displayValue === roleColumns[i].displayName) {
                field = roleColumns[i].name;
                break;
            }
        }
        return field;
    };

    onRecordView = (data) => {
        const record: any = {};
        for (let j = 0; j < this.allColumns.length; j++) {
            const field = this.getField(this.allColumns[j], this.roleTableColumns);
            record[field] = data[this.allColumns[j]];
        }
        this.viewRoleRecord.emit(record);
    };

    public onRolesSearchEvent = (data) => {
        const roles = this.roles.value;
        const columnId = this.roleTableColumns.find((column) => column.displayName === data.columnName)?.name;
        this.currentSearch = {
            ...this.currentSearch,
            [columnId]: data.value,
        };
        const roleName = this.currentSearch?.roleName?.length > 0 ? this.currentSearch.roleName.toLowerCase() : undefined;
        const displayName = this.currentSearch?.displayName?.length > 0 ? this.currentSearch.displayName.toLowerCase() : undefined;
        const description = this.currentSearch?.description?.length > 0 ? this.currentSearch.description.toLowerCase() : undefined;
        const filteredRoles = roles
            .filter((role) => !roleName || role.roleName.toLowerCase().includes(roleName))
            .filter((role) => !displayName || role.displayName.toLowerCase().includes(displayName))
            .filter((role) => !description || role.description.toLowerCase().includes(description));
        this.buildData(filteredRoles);
    };

    ngOnInit() {
        this.currentSearch = {};
        this.buildDataObjectForTable();
        this.rolesSubscription = this.roles.subscribe((roles) => {
            this.selectedRoleIds = [];
            roles.forEach((role) => role['selected'] && this.selectedRoleIds.push(role.id));
            this.buildData(roles);
        });
    }

    ngOnDestroy() {
        this.rolesSubscription && this.rolesSubscription.unsubscribe();
    }
}
