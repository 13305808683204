import { FieldDefinition } from './field-definition.class';

export class FlexFields {
    readonly id: string;

    readonly resourceId: string;

    readonly definitionId: string;

    readonly fieldDefinition: FieldDefinition;

    dateValue: string;

    userValue: string;

    [property: string]: any;

    createdDate?: string;

    lastModifiedDate?: string;

    value?: string;

    stringValue?: string;

    constructor(flexField: object) {
        this.id = flexField?.['id'];
        this.resourceId = flexField?.['resourceId'];
        this.definitionId = flexField?.['definitionId'];
        this.userValue = flexField?.['userValue'];
        this.fieldDefinition = new FieldDefinition(
            flexField?.['fieldDefinition'] || {
                fieldName: flexField?.['fieldName'],
            }
        );
        this.dateValue = flexField?.['dateValue'];
        this.createdDate = flexField?.['createdDate'];
        this.lastModifiedDate = flexField?.['lastModifiedDate'];
        this.value = flexField?.['value'];
        this.stringValue = flexField?.['stringValue'];
    }

    static getFlexValue = (flexFieldsArray, fieldName) => {
        for (let i = 0; i < flexFieldsArray.length; i++) {
            if (flexFieldsArray[i].fieldDefinition.fieldName === fieldName) {
                return flexFieldsArray[i].userValue;
            }
        }
    };

    static getFlexIndex = (flexFieldsArray, fieldName) => {
        for (let i = 0; i < flexFieldsArray.length; i++) {
            if (flexFieldsArray[i].fieldDefinition.fieldName === fieldName) {
                return i;
            }
        }
    };
}
