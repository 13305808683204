import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RootScopeService } from '@authentication/services/rootscope.service';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    AuthCodeExternalValidation,
    CommonUtilsService,
    ExternalCodeValidationFailed,
    ExternalCodeValidationSuccess,
    getInternalAuthTokenDetailsState,
    InternalAccessTokenValidation,
    InternalAccessTokenValidationSuccess,
    InternalAuthTokenValidation,
    StoreService,
    UtilsService,
} from 'taxilla-library';

@Component({
    selector: 'app-validate',
    templateUrl: './validate.component.html',
    styleUrls: ['./validate.component.scss'],
})
export class ValidateComponent implements OnInit {
    authCode: string;
    noRedirect: boolean;
    hideDomain: boolean;
    private unsubscribe = new Subject<void>();

    constructor(
        private _route: ActivatedRoute,
        private _commonUtils: CommonUtilsService,
        private _utils: UtilsService,
        private _router: Router,
        private R: RootScopeService,
        private ngRxStore: Store,
        private store: StoreService,
        private actions$: Actions
    ) {
        const showDomainFromStorage = this._commonUtils.getFromStorage('showDomain', true);
        this.hideDomain =
            this.R.onLocalHost ||
            this._commonUtils.isBrowserIE() ||
            [
                'dbcloud',
                'dbtest',
                'dbuat',
                'dbprod',
                'dbdr',
                'kpmg2aprod',
                'kpmgprod',
                'kpmguat',
                'adityabirlauat',
                'adityabirlaprod',
            ].indexOf(R.PLATFORM) > 0 ||
            !showDomainFromStorage;
    }

    private validateCode = () => {
        if (this.hideDomain) {
            this.startRedirection();
            return;
        }
        const domain = this._commonUtils.getCookie('domain');
        const params = this._route.queryParams['value'];
        this.authCode = params?.code;
        if (domain) {
            this.validateCodeExternally();
            return;
        }
        this.ngRxStore.dispatch(
            InternalAuthTokenValidation({
                payload: {
                    authCode: this.authCode,
                    clientId: this._commonUtils.getFromStorage('clientid'),
                    code: this._commonUtils.getFromStorage('code'),
                },
            })
        );
    };

    private validateCodeExternally = () => {
        const authType = this._commonUtils.getFromStorage('authenticationType');
        switch (authType) {
            case 'SAML':
            case 'LDAP':
                this.startRedirection();
                break;
            default:
                this.ngRxStore.dispatch(
                    AuthCodeExternalValidation({
                        payload: {
                            domain: this._commonUtils.getCookie('domain'),
                            redirectURL: `${window.location.origin}/auth/validate`,
                            authCode: this.authCode,
                            code: this._commonUtils.getFromStorage('code'),
                        },
                    })
                );
                break;
        }
    };

    private checkAccessToken = () => {
        this.ngRxStore.dispatch(
            InternalAccessTokenValidation({
                token: this._commonUtils.getFromStorage('access_token'),
            })
        );
    };

    private redirectToAuth = () => {
        this._router.navigate(['auth']);
    };

    private startRedirection = () => {
        this.store.clearLoading();
        const redirect = this._commonUtils.getFromStorage('successRedirect');
        if (redirect !== undefined && redirect !== 'undefined') {
            const separation = redirect?.indexOf('?') > -1 ? '&' : '?';
            const clientId = this._commonUtils.getFromStorage('clientId');
            const returnPage = `${redirect}${separation}${
                clientId ? 'clientId=' + encodeURIComponent(clientId) : ''
            }&stateId=${encodeURIComponent(this._commonUtils.getFromStorage('stateId'))}`;
            this._commonUtils.redirectToPage(returnPage);
        } else {
            this._router.navigate(['']);
        }
    };

    private initiateSubscriptions = () => {
        this.ngRxStore
            .select(getInternalAuthTokenDetailsState)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this._commonUtils.setInStorage('access_token', data.access_token);
                this.checkAccessToken();
            });
        this.actions$
            .pipe(
                ofType(InternalAccessTokenValidationSuccess, ExternalCodeValidationSuccess),
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe(() => {
                this.startRedirection();
            });
        this.actions$
            .pipe(
                ofType(ExternalCodeValidationFailed),
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this._commonUtils.removeFromStorage('clientSecret');
                this._commonUtils.removeFromStorage('clientId');
                this._commonUtils.removeFromStorage('authenticationURL');
                this._commonUtils.removeFromStorage('tokenURL');
                this._commonUtils.removeFromStorage('keyURL');
                this._commonUtils.deleteCookie('domain');
                setTimeout(() => {
                    this.redirectToAuth();
                }, 100);
            });
    };

    ngOnInit(): void {
        this.initiateSubscriptions();
        this.validateCode();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
