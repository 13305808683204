import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'encomply-snackbar-messages',
    templateUrl: './encomply-snackbar-messages.component.html',
    styleUrls: ['./encomply-snackbar-messages.component.scss'],
})
export class EnComplySnackbarMessagesComponent implements OnInit {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data) {}

    ngOnInit() {}
}
