import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WIDGET_REDUCER_KEY } from '../reducers';
import { WidgetState } from '../states';
import { getActiveLanguage } from './session.selector';

const widgetTranslateState = createFeatureSelector<WidgetState>(WIDGET_REDUCER_KEY);

export const getWidgetTranslations$ = createSelector(
    widgetTranslateState,
    getActiveLanguage,
    (state, language) => state.widgetTranslations?.[language]
);
