import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { AssetService, getCurrentLocationIdsHierarchy$, Organization, UtilsService } from 'taxilla-library';

import { AssetsService } from '../assets/assets.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';

@Injectable({
    providedIn: 'root',
})
export class TagsService {
    private taggedRestApiNames: string[] = [];
    private orgIds: string[] = [];

    constructor(
        private _assets: AssetsService,
        private _commonUtils: CommonUtilsService,
        private _subscriptions: SubscriptionsService,
        private store$: Store,
        private _utils: UtilsService
    ) {
        this.store$.select(getCurrentLocationIdsHierarchy$).subscribe((ids) => {
            this.orgIds = ids;
        });
    }

    public IsAnEnInvoiceApp = async (restApiName: string) => {
        const apps =
            restApiName && (await this.fetchTaggedSubscriptions(environment.eninvoiceAutoSubscribeTags, environment.eninvoiceTags));
        return apps?.find((app) => app.restApiName === restApiName);
    };

    public checkForTags = async (serviceId: string, dontGetPackageTags?: boolean): Promise<any> => {
        const uiTypeTags = this.getAssetTags(serviceId, 'uitype');
        const packageTags = !dontGetPackageTags && this.getAssetTags(serviceId, 'package');
        return Promise.all([uiTypeTags, packageTags]);
    };

    public fetchTaggedSubscriptions = (packageTags: string[], uitypeTags: string[], collaboratedApps?: boolean, noAlerts?: boolean) => {
        return new Promise<AssetService[]>(async (resolve) => {
            this.taggedRestApiNames = [];
            await this.fetchTaggedAssets(packageTags, uitypeTags, collaboratedApps, noAlerts);
            const currentOrganization = this._commonUtils.getFromStorage('currentOrganization');
            if (!currentOrganization || !currentOrganization.id) {
                return;
            }
            const data = {
                organizationId: currentOrganization.id,
                noAlerts,
            };
            this._subscriptions.searchSubscribedServicesByOrganizationId(data, {
                successCallback: async (res) => {
                    const ewbApps = [];
                    res.filter((item) => this.taggedRestApiNames.indexOf(item.restApiName) > -1).forEach((item) => {
                        ewbApps.push(item);
                    });
                    resolve(ewbApps);
                },
                failureCallback: (res) => {},
            });
        });
    };

    private fetchTaggedAssets = (packageTags: string[], uitypeTags: string[], collaboratedApps: boolean, noAlerts?: boolean) => {
        return new Promise<void>((resolve) => {
            const taggedEWBPromise = this.getAllTags('package', packageTags, undefined, noAlerts);
            const taggedEnInvoicePromise = this.getAllTags('uitype', uitypeTags, undefined, noAlerts);
            const collaboratedAppsPromise = collaboratedApps && this.fetchCollaborationApps(noAlerts);
            Promise.all([taggedEWBPromise, taggedEnInvoicePromise, collaboratedAppsPromise]).then(
                ([taggedEwbAssets, taggedEninvoiceApps, collabApps]) => {
                    this.taggedRestApiNames = [];
                    [...(taggedEwbAssets || []), ...(taggedEninvoiceApps || []), ...(collabApps || [])]
                        .filter((asset) => this.taggedRestApiNames.indexOf(asset.restApiName) === -1)
                        .forEach((asset) => {
                            this.taggedRestApiNames.push(asset.restApiName);
                        });
                    resolve();
                }
            );
        });
    };

    private fetchCollaborationApps = (noAlerts?: boolean): Promise<AssetService[]> => {
        return new Promise((resolve) => {
            this._subscriptions.getCollaboratedApps(
                {
                    noAlerts: noAlerts,
                },
                {
                    successCallback: (response) => {
                        resolve(response as any);
                    },
                }
            );
        });
    };

    private getAllTags = async (tagKey, tagValue, repoHeirarchyNotRequired?, noAlerts?: boolean): Promise<AssetService[]> => {
        return new Promise((resolve) => {
            if (!tagValue || tagValue.length === 0) {
                return resolve([]);
            }
            const orgIdsList = this.orgIds || [];
            if (orgIdsList.length === 0 && !repoHeirarchyNotRequired) {
                this.getAllParents(orgIdsList);
            }
            const promises = [];
            orgIdsList.forEach(async (orgId, index) => {
                promises[index] = this.getTaggedApps(orgId, tagKey, tagValue, noAlerts);
            });
            Promise.all(promises).then((...repos: AssetService[][][]) => {
                const allApps = [];
                const appIds = [];
                repos.forEach((repo) => {
                    (repo || []).forEach((apps) => {
                        (apps || [])
                            .filter((app) => appIds.indexOf(app.serviceId) === -1)
                            .forEach((app) => {
                                app.serviceId && appIds.push(app.serviceId);
                                allApps.push(app);
                            });
                    });
                });
                resolve(allApps);
            });
        });
    };

    public getTaggedApps = (orgId, tagKey, tagValue, noAlerts?: boolean, serviceTypes?: string[]) => {
        return new Promise<
            {
                metadataId: string;
                metadataName: string;
                metadataType: string;
                repositoryId: string;
                serviceId: string;
                restApiName: string;
                tagKey: string;
                tagValue: string;
            }[]
        >((resolveApps) => {
            const serviceTypesPromise = [];
            (serviceTypes ? serviceTypes : ['ASSET', 'BRIDGEASSET']).forEach((serviceType) => {
                serviceTypesPromise.push(this.getServicesByTag(orgId, serviceType, tagKey, tagValue, noAlerts));
            });
            !noAlerts && this._utils.showLoading();
            Promise.all(serviceTypesPromise).then((...groupList) => {
                const allTags = [];
                groupList.forEach((group) => {
                    group.forEach((tags) => {
                        tags && allTags.splice(allTags.length, 0, ...tags);
                    });
                });
                !noAlerts && this._utils.hideLoading();
                resolveApps(allTags);
            });
        });
    };

    private getServicesByTag = (
        orgId: string,
        appType: string,
        tagKey: string,
        tagValue: string,
        noAlerts?: boolean
    ): Promise<
        {
            metadataId: string;
            metadataName: string;
            metadataType: string;
            repositoryId: string;
            restApiName: string;
            serviceId: string;
            tagKey: string;
            tagValue: string;
        }[]
    > =>
        this._assets.getTagBasedMetadataType({
            orgId: orgId || '111',
            tagkey: tagKey,
            tagValue,
            metadataType: appType,
            pageSize: 100,
            pagingState: '',
            noAlerts: noAlerts,
        });

    private getAllParents = (list: string[], org?: Organization) => {
        org = org || this._commonUtils.getFromStorage('currentOrganization');
        org.id && list.indexOf(org.id) === -1 && list.push(org.id);
        org.providerOrganizationId && list.indexOf(org.providerOrganizationId) === -1 && list.push(org.providerOrganizationId);
        if (org?.parent) {
            this.getAllParents(list, org.parent);
        }
    };

    public getAssetTags = (serviceId: string, key?: string) => {
        return new Promise<string[]>((resolve) => {
            if (serviceId) {
                this._assets.getAssetTags(
                    {
                        serviceId,
                        tagKey: key,
                    },
                    {
                        successCallback: (res) => {
                            const tags = [];
                            if (res?.taggedMetadatas) {
                                res.taggedMetadatas.forEach((element) => {
                                    if (element.tagValue) {
                                        tags.push(element.tagValue);
                                    }
                                });
                            }
                            resolve(tags);
                        },
                    }
                );
            }
        });
    };

    public getAssetTagKeyValues = (serviceId: string, key?: string) => {
        return new Promise<{ [property: string]: string }>((resolve) => {
            if (serviceId) {
                this._assets.getAssetTags(
                    {
                        serviceId,
                        tagKey: key,
                    },
                    {
                        successCallback: (res) => {
                            const tags: { [property: string]: string } = {};
                            if (res?.taggedMetadatas) {
                                res.taggedMetadatas.forEach((element) => {
                                    tags[element.tagKey] = element.tagValue;
                                });
                            }
                            resolve(tags);
                        },
                    }
                );
            }
        });
    };
}
