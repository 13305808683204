import { Component, OnInit } from '@angular/core';

import { RootScopeService } from '../../services/rootscope.service';

@Component({
    selector: 'app-auth-success',
    templateUrl: './auth-success.component.html',
    styleUrls: ['./auth-success.component.scss'],
})
export class AuthSuccessComponent implements OnInit {
    constructor(public R: RootScopeService) {}

    ngOnInit(): void {}
}
