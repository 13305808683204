import { Direction } from '@angular/cdk/bidi';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { AssetProcessActionEntityConfiguration } from '../configurations/assetentityconfiguration';
import { Entity } from '../entity.class';
import { Field, GridConstraints } from '../field.class';
import { LookupInformationMetadata } from '../lookupinformationmetadata.class';
import { LookupValues } from '../lookupvalues.class';
import { MasterField } from '../masters/masterfield.class';
import { Rule } from '../rule.class';
import { GridCellData, GridData } from './gridfield.interface';
import { Message } from './message.class';

export class RecordField {
    id: string;

    message: Message;

    modified: undefined | boolean;

    value: string | number | string[] | boolean;

    oldValue?: string | number | string[] | boolean;

    newValue?: string | number | string[] | boolean;

    valueDifference: {
        leftValue?: string | number | string[] | boolean;
        rightValue?: string | number | string[] | boolean;
    };

    isBusinessKey: boolean;

    displayName: string;

    show?: boolean;

    isMandatory?: boolean;

    uiTypeMetadata?: string;

    lookupValues?: any;

    lookupInformation: LookupInformationMetadata;

    entityUid: string;

    order: number;

    fieldSection: string;

    datatype: string;

    gridConstraints?: GridConstraints;

    gridData: GridData;

    idGenerator: string;

    // UI related fields
    masterData: Field['masterData'];

    originalValue: string | number | string[] | boolean;

    hasMasterLookup: boolean;

    masterField: MasterField;

    disabled: boolean;

    outputFormat: string;

    rules: Rule[];

    preCaptureType?: string;

    description?: string;

    attachment?: {
        maxFilesCount?: number;
        maxSize?: number;
        supportedTypes?: string[];
    };

    masterSearchEnabled?: boolean;

    showInList?: boolean;

    readOnly: boolean;

    searchable: boolean;

    sortable: boolean;

    originalUiType?: string;

    hasLookup: boolean;

    conditionalMandatory?: AssetProcessActionEntityConfiguration['conditionalMandatory'][0];

    fileNames?: string[];

    searchableIndex?: string;

    operator?: any;

    uiType?: string;

    files?: string[];

    errors?: string[];

    warnings?: string[];

    inAudit?: boolean;

    isFromAddRecord?: boolean;

    dependentForFields?: string[];

    autoCalculate?: boolean;

    tagEntries?: {
        key: string;
        value: string;
    }[];

    // Needed for UI field value rendering
    textDirection: Direction = 'ltr';

    static getFieldOrder = (str: string) => {
        let numberStr: any = str && str.replace(/^\D+/g, '');
        numberStr = (numberStr && parseInt(numberStr, null)) || NaN;
        return numberStr;
    };

    constructor(data, entityField: Field, entity: Entity, isFromAddRecord?: boolean) {
        this.id = data?.id || entityField?.uid;
        if (data?.errors || data?.warnings) {
            this.message = new Message({
                errors: data.errors,
                warnings: data.warnings,
            });
        } else {
            this.message = new Message((data?.message !== undefined && data.message) || {});
        }
        this.modified = data?.modified;
        this.isFromAddRecord = isFromAddRecord;
        this.inAudit = data?.valueDifference && Object.keys(data.valueDifference).length > 0;
        this.assignValues(entityField, data);
        if (data?.originalValue !== undefined) {
            this.originalValue =
                entityField?.uiTypeMetadata === 'TEXTAREA'
                    ? this.assignTextAreaValue(data?.value)
                    : CommonUtilsService.cloneObject(data.originalValue);
        } else if (entityField?.datatype === 'BOOLEAN' && entityField?.uiTypeMetadata === 'TEXTFIELD') {
            switch (typeof data?.value) {
                case 'string':
                    switch (data.value) {
                        case 'Yes':
                        case 'true':
                            this.originalValue = true;
                            break;
                        case 'No':
                        case 'false':
                            this.originalValue = false;
                            break;
                    }
                    break;
                case 'boolean':
                    switch (data.value) {
                        case true:
                            this.originalValue = true;
                            break;
                        case false:
                            this.originalValue = false;
                            break;
                    }
                    break;
            }
        } else if (data?.value !== undefined) {
            if (data instanceof RecordField) {
                this.originalValue = data.originalValue;
            } else {
                this.originalValue =
                    entityField?.uiTypeMetadata === 'TEXTAREA'
                        ? this.assignTextAreaValue(data?.value)
                        : CommonUtilsService.cloneObject(data.value);
            }
        }
        this.textDirection = /[\u0600-\u06FF]/.test((this.originalValue as string) || '') ? 'rtl' : 'ltr';
        this.disabled = entityField?.isBusinessKey && data && data.value !== undefined;
        this.isBusinessKey = entityField?.isBusinessKey;
        this.displayName = entityField?.displayName || entityField?.name;
        this.show = entityField?.show;
        this.isMandatory = entityField?.mandatory;
        this.uiTypeMetadata = entityField?.uiTypeMetadata || 'TEXTFIELD';
        this.lookupValues = entityField?.hasLookup && entityField.lookupValues;
        this.lookupInformation = entityField?.lookupInformationMetadata;
        this.entityUid = entity && entity.uid;
        this.order = entityField && RecordField.getFieldOrder(entityField.searchableIndex);
        this.hasMasterLookup = entityField?.hasMasterLookup;
        this.masterField = entityField?.masterField;
        this.masterData = entityField?.masterData;
        this.outputFormat = entityField?.outputFormat;
        this.rules = entityField?.rules || [];
        this.fieldSection = entityField?.fieldSection;
        this.preCaptureType = entityField?.preCaptureType || data?.preCapture;
        this.attachment = entityField?.attachment;
        this.datatype = entityField?.datatype;
        this.description = entityField?.description;
        this.masterSearchEnabled = this.value !== undefined;
        this.gridConstraints = entityField?.gridConstraints;
        this.gridData = entityField?.datatype === 'GRID' && (data?.gridData || this.assignGridValues(data?.gridData || data));
        this.showInList = entityField?.showInList;
        this.readOnly = entityField?.readOnly;
        this.searchable = entityField?.searchable;
        this.sortable = entityField?.sortable;
        this.originalUiType = entityField?.uiTypeMetadata;
        this.hasLookup = entityField?.hasLookup;
        this.conditionalMandatory = data?.conditionalMandatory;
        this.searchableIndex = entityField?.searchableIndex;
        this.operator = data?.operator || (entityField && (entityField as any).operator);
        this.uiType = data?.uiType || entityField?.uiTypeMetadata;
        this.idGenerator = entityField?.idGenerator;
        if (this.attachment && this.value && Array.isArray(this.value) && this.value.length > 0) {
            this.updateFiles();
        }
        this.files = data?.files || [];
        this.valueDifference = data?.valueDifference;
        this.dependentForFields = entityField?.dependentForFields || [];
        this.autoCalculate = entityField?.autoCalculate;
        this.tagEntries = entityField?.tagEntries;
        this.gridData?.cells?.forEach((cell) => {
            this.message.errors = [...this.message.errors, ...cell.errors];
        });
    }

    assignGridValues = (data: GridData) => {
        if (this.gridData && data) {
            this.gridData.cells.forEach((cell) => {
                const cellData = data.cells.find(
                    (cellItem) => cellItem.columnIndex === cell.columnIndex && cellItem.rowIndex === cell.rowIndex
                );
                cell.value = cellData?.value;
                cell.errors = cellData?.errors || [];
                cell.warnings = cellData?.warnings || [];
            });
        } else {
            this.gridData = data || {
                id: this.id,
                cells: this.gridConstraints?.cellDetails?.reduce((cells, cell) => {
                    cells.push({
                        columnIndex: cell.columnIndex,
                        errors: [],
                        modified: false,
                        rowIndex: cell.rowIndex,
                        value: undefined,
                        warnings: [],
                        lookupInformationMetadata: cell.lookupInformationMetadata,
                        readOnly: cell.readOnly,
                    });
                    return cells;
                }, [] as GridCellData[]),
                hasCellErros: false,
                message: new Message({}),
                modified: false,
            };
        }
        this.gridData?.cells?.forEach((cell) => {
            this.message.errors = [...this.message.errors, ...cell?.errors];
            this.message.warnings = [...this.message.warnings, ...cell?.warnings];
        });
        this.gridData.hasCellErros = this.message.errors.length > 0;
        return this.gridData;
    };

    assignValues = (entityField: Field, data: any) => {
        if (this.inAudit) {
            this.assignAuditValues(entityField, data);
            data.value = data?.valueDifference?.rightValue;
            this.assignNormalValues(entityField, CommonUtilsService.cloneObject(data));
        } else {
            this.assignNormalValues(entityField, CommonUtilsService.cloneObject(data));
        }
    };

    assignNormalValues = (entityField: Field, data: any) => {
        if (entityField?.datatype === 'GRID') {
            this.assignGridValues(data);
        } else if (entityField?.datatype === 'BOOLEAN' && entityField?.uiTypeMetadata === 'TEXTFIELD') {
            switch (typeof data?.value) {
                case 'boolean':
                    this.value = data.value;
                    break;
                case 'string':
                    switch (data.value) {
                        case 'true':
                        case 'Yes':
                            this.value = true;
                            break;
                        case 'false':
                        case 'No':
                            this.value = false;
                            break;
                    }
                    break;
                default:
                    this.value = undefined;
                    break;
            }
        } else if (entityField?.uiTypeMetadata === 'TEXTAREA') {
            this.value = this.assignTextAreaValue(data?.value);
        } else {
            if (this.isFromAddRecord) {
                this.value = data?.value !== undefined ? data.value : entityField?.defaultValue;
            } else {
                this.value = data?.value;
            }
        }
    };

    assignAuditValues = (entityField: Field, data: any) => {
        if (entityField?.datatype === 'BOOLEAN' && entityField?.uiTypeMetadata === 'TEXTFIELD') {
            Object.keys(data?.valueDifference)
                .filter((key) => (entityField.isBusinessKey ? key !== 'leftValue' : true))
                .forEach((key) => {
                    const fieldKey = key === 'leftValue' ? 'oldValue' : 'newValue';
                    if (fieldKey === 'oldValue') {
                        this[fieldKey] = '';
                    } else {
                        switch (typeof data.valueDifference[key]) {
                            case 'boolean':
                                this[fieldKey] = data.valueDifference[key];
                                break;
                            case 'string':
                                switch (data.valueDifference[key]) {
                                    case 'true':
                                    case 'Yes':
                                        this[fieldKey] = true;
                                        break;
                                    case 'false':
                                    case 'No':
                                        this[fieldKey] = false;
                                        break;
                                }
                                break;
                            default:
                                this[fieldKey] = '';
                                break;
                        }
                    }
                });
        } else if (entityField?.datatype === 'DATE') {
            const alreadyTransformed = data instanceof RecordField;
            this.oldValue = entityField.isBusinessKey
                ? ''
                : CommonUtilsService.transformDateToLocale(
                      data?.valueDifference?.leftValue,
                      entityField.outputFormat as any,
                      'dd/mm/yyyy',
                      alreadyTransformed
                  );
            this.newValue = CommonUtilsService.transformDateToLocale(
                data?.valueDifference?.rightValue,
                entityField.outputFormat as any,
                'dd/mm/yyyy',
                alreadyTransformed
            );
        } else if (entityField?.uiTypeMetadata === 'TEXTAREA') {
            this.oldValue = this.assignTextAreaValue(data?.valueDifference?.leftValue);
            this.newValue = this.assignTextAreaValue(data?.valueDifference?.rightValue);
        } else {
            this.oldValue = entityField.isBusinessKey ? '' : data?.valueDifference?.leftValue;
            this.newValue = data?.valueDifference?.rightValue;
        }
    };

    assignTextAreaValue = (value: any) => {
        try {
            return value ? decodeURIComponent(value) : '';
        } catch (e) {
            return value;
        }
    };

    updateFiles = () => {
        const files = [];
        if (Array.isArray(this.value)) {
            this.value.forEach((fileName) => {
                const hasSlashes = fileName.lastIndexOf('/');
                if (hasSlashes) {
                    files.push(fileName.substring(fileName.lastIndexOf('/') + 1));
                } else {
                    files.push(fileName);
                }
            });
        }
        this.fileNames = files;
    };

    setLookupValues = (lookupResponse) => {
        this.lookupValues = new LookupValues(lookupResponse);
    };

    setValueInMasterMap = (map: { [property: string]: string }) => {
        if (map && this.entityUid && this.id) {
            map[this.entityUid + '^' + this.id] = this.value as any;
        }
    };

    checkMasterSearchEnabled = (
        map: { [property: string]: string },
        tablesMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        initialRender: boolean,
        tableMap?: {
            keyColumns: string[];
            valuesMap: {
                id: string;
                masterColumn: string;
                value: any;
                references: any[];
            }[];
        }
    ) => {
        let enabled = true;
        const references = this.masterData.columnRefMetadatas;
        references.forEach((reference) => {
            enabled = enabled && map && map[reference.entityUid + '^' + reference.fieldUid] !== undefined;
        });
        const currentIndexInPair = (tableMap || tablesMap[this.masterData.tableUid]).valuesMap.findIndex((pair) => pair.id === this.id);
        const matchedPair = (tableMap || tablesMap[this.masterData.tableUid]).valuesMap.filter((pair, index) => {
            return (
                ((currentIndexInPair > -1 && index <= currentIndexInPair) || currentIndexInPair === -1) &&
                ((pair.id !== this.id && pair.value !== undefined && typeof pair.value === 'string' && pair.value.toLowerCase() !== 'na') ||
                    pair.id === this.id)
            );
        });
        enabled = enabled && matchedPair.length > currentIndexInPair;
        if (!enabled && !initialRender) {
            this.value = undefined;
        }
        if (initialRender) {
            this.masterSearchEnabled = true;
        } else {
            this.masterSearchEnabled = enabled;
        }
    };

    public filterMapWithFieldReference = (masterRefMap: {
        [property: string]: {
            keyColumns: string[];
            valuesMap: {
                id: string;
                entityId: string;
                masterColumn: string;
                value: any;
                references: any[];
            }[];
        };
    }) => {
        const existingMap = CommonUtilsService.cloneObject(masterRefMap || {});
        const field = this;
        var newMap: {
            keyColumns: string[];
            valuesMap: { id: string; entityId: string; masterColumn: string; value: any; references: any[] }[];
        } = {
            keyColumns: [],
            valuesMap: [],
        };
        const currentTableMap = existingMap[field.masterData.tableUid];
        const currentEntityId = field.entityUid;
        if (!currentTableMap) {
            return newMap;
        } else {
            currentTableMap.valuesMap
                .filter((ref) => ref.entityId !== currentEntityId)
                .forEach((map) => {
                    this.pushToValuesMap(newMap.valuesMap, map);
                });
            currentTableMap.keyColumns.forEach((column) => {
                column === field.id && this.pushToKeyColumns(newMap.keyColumns, column);
            });
        }
        const currentFieldValueMap = currentTableMap.valuesMap.find((valueMap) => valueMap.id === field.id);
        if (field.hasMasterLookup) {
            // field.masterData.columnRefMetadatas.forEach((reference) => {
            //     this.pushToKeyColumns(newMap.keyColumns, reference.fieldUid);
            //     this.pushToValuesMap(newMap.valuesMap, this.getValueMapFromTablesMap(existingMap, reference.fieldUid));
            // });
            const referenceValueMap = currentTableMap.valuesMap
                .filter((valueMap) => !currentTableMap.keyColumns.includes(valueMap.id))
                .find((valueMap) => valueMap.references?.find((reference) => reference.fieldUid === field.id));
            referenceValueMap?.references.forEach((reference) => {
                this.pushToKeyColumns(newMap.keyColumns, reference.fieldUid);
                !newMap.valuesMap.find((map) => map.id === reference.fieldUid) &&
                    this.pushToValuesMap(newMap.valuesMap, this.getValueMapFromTablesMap(existingMap, reference.fieldUid));
            });
        } else if (currentFieldValueMap.references?.length > 0) {
            // currentFieldValueMap.references.forEach((reference) => {
            //     this.pushToKeyColumns(newMap.keyColumns, reference.fieldUid);
            //     this.pushToValuesMap(newMap.valuesMap, this.getValueMapFromTablesMap(existingMap, reference.fieldUid));
            // });
        }
        currentTableMap.valuesMap.forEach((valueMap) => {
            const hasReference = valueMap.references.some((ref) => ref.fieldUid === field.id);
            (!newMap.valuesMap.find((map) => map.id === valueMap.fieldUid) || valueMap.id === field.id) &&
                hasReference &&
                this.pushToValuesMap(newMap.valuesMap, {
                    ...valueMap,
                    value: valueMap.id === field.id ? field.value : valueMap.value,
                });
        });
        newMap.valuesMap.sort((a, b) => a.references.length - b.references.length);
        newMap.keyColumns.sort((a, b) => {
            const aIndex = newMap.valuesMap.findIndex((mapObject) => mapObject.id === a);
            const bIndex = newMap.valuesMap.findIndex((mapObject) => mapObject.id === b);
            return aIndex - bIndex;
        });
        return newMap;
    };

    public pushToKeyColumns = (keyColumns: string[], column) => {
        column && keyColumns.indexOf(column) === -1 && keyColumns.push(column);
    };

    public pushToValuesMap = (
        valuesMap: {
            id: string;
            entityId: string;
            masterColumn: string;
            value: any;
            references: any[];
        }[],
        valueMap: {
            id: string;
            entityId: string;
            masterColumn: string;
            value: any;
            references: any[];
        }
    ) => {
        if (!valueMap) {
            return;
        }
        const mapObject = valuesMap.find((map) => map.id === valueMap.id);
        if (!mapObject) {
            valuesMap.push(valueMap);
        } else if (mapObject.value !== valueMap.value) {
            mapObject.value = valueMap.value;
        }
    };

    public readonly getValueMapFromTablesMap = (
        tableMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        fieldId: string
    ) => {
        let referenceValueMap: {
            id: string;
            entityId: string;
            masterColumn: string;
            value: any;
            references: any[];
        };
        Object.keys(tableMap).forEach((tableId) => {
            const currentTableMap = tableMap[tableId];
            referenceValueMap = referenceValueMap || currentTableMap?.valuesMap?.find((valueMap) => valueMap.id === fieldId);
        });
        return referenceValueMap;
    };
}
