import { MastersResponseRecord } from '../../interface/master-records-response.interface';
import { MasterLanguageTemplate } from '../../models/masters/mastermetadata.class';
import { MastersMetaData } from '../../models/masters/mastersmetadata.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { SearchFilter } from '../../models/searchfilter.class';

export const mastersAdapter = {};
export const mastersInitialState: MastersInitialState = {
    organizationsMap: {},
    selectedTableId: undefined,
    selectedRestApiName: undefined,
    isRecordsLoading: undefined,
    masterSearchCount: undefined,
    parentTableId: undefined,
    parentTableRestApiName: undefined,
};
export interface MastersInitialState {
    organizationsMap: {
        // OrganizationId vs details
        [property: string]: {
            masters: MasterTables[];
            metaData: {
                // master table id vs metadata
                [property: string]: MastersMetaData;
            };
            languageTemplate: {
                // master table id vs template
                [property: string]: MasterLanguageTemplate;
            };
            searchFilters: {
                // masterRestApiName vs searchFilters
                [property: string]: SearchFilter[];
            };
            records: {
                // masterRestApiName vs masterRecords
                [property: string]: MastersResponseRecord[];
            };
            parentPrimaryKeys: {
                //masterTableId vs parentPrimaryKeys
                [property: string]: any[];
            };
            pagination: {
                // masterRestApiName vs pagination
                [property: string]: {
                    pageSize: number;
                    pageIndex: number;
                    pageState: {
                        // pageIndex vs pageState
                        [property: string]: string;
                    };
                    search?: {
                        queryString?: string;
                        sortQuery?: {};
                        searchParent?: boolean;
                        searchAfter: [];
                    };
                };
            };
            inboundMappings: {
                // masterId vs mappings
                [property: string]: any;
            };
            outboundMappings: {
                // masterId vs mappings
                [property: string]: any;
            };
            reportDetails: {
                // masterId vs details
                [property: string]: any;
            };
            chainDisplayNames: {
                // masterRestApiName vs chainDisplayNames
                [property: string]: any;
            };
            requests: {
                // masterRestApiName vs requests
                [property: string]: any;
            };
            uploadedFiles: {
                // masterId vs uploadedFiles
                [property: string]: any;
            };
            requestsPagination: {
                // masterRestApiName vs requestPagination
                [property: string]: {
                    pageSize: number;
                    pageIndex: number;
                    pageState: {
                        // pageIndex vs pageState
                        [property: string]: string;
                    };
                    timeRange?: any[];
                };
            };
        };
    };
    masterSearchCount: number;
    isRecordsLoading: boolean;
    selectedTableId: string;
    selectedRestApiName: string;
    parentTableId: string;
    parentTableRestApiName: string;
}
