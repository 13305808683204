import { Injectable } from '@angular/core';

import { CommonUtilsService } from '../commonutils/common-utils.service';

@Injectable({
    providedIn: 'root',
})
export class UiSchemaService {
    public schema = {
        enComply: {
            hideQuickLinks: true,
            showAnalytics: false,
        },
        header: {
            hideAppCategory: false,
        },
    };

    constructor(private _commonUtils: CommonUtilsService) {
        this.buildSchema();
    }

    private buildSchema = () => {
        const schema = this._commonUtils.getFromStorage('ui-schema');
        this.schema = {
            ...this.schema,
            ...(schema || {}),
        };
    };

    public setSchemaProperty = (ui: 'enComply' | 'header', property: string, value: boolean) => {
        this.schema = {
            ...this.schema,
            [ui]: {
                ...(this.schema[ui] || {}),
                [property]: value,
            },
        } as any;
        this.updateSchema();
    };

    private updateSchema = () => {
        this._commonUtils.setInStorage('ui-schema', this.schema);
    };
}
