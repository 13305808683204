import { EditSavedDefaultFilter, FilterCriteria } from 'taxilla-library';

import { Actions, SearchCriteriaActionTypes } from '../actions/searchCriteria.actions';

const initialState: FilterCriteria = {
    serviceId: undefined,
    entityFilterCriterias: [
        {
            entityUid: '',
            criteriaDefinitions: [
                {
                    criteriaName: '',
                    type: '',
                    restriction: {
                        restrictionType: '',
                        condition: '',
                        rules: [
                            {
                                restrictionType: '',
                                value: '',
                                operator: '',
                                fieldId: '',
                            },
                        ],
                    },
                },
            ],
        },
    ],
    criteriaRelations: {
        restrictionType: '',
        condition: '',
        rules: [
            {
                restrictionType: '',
                value: '',
                operator: '',
                fieldId: '',
            },
        ],
    },
};

export const SEARCH_CRITERIA_REDUCER = (state: FilterCriteria = initialState, action: Actions) => {
    switch (action.type) {
        case SearchCriteriaActionTypes.SEARCH_UPDATE_SERVICEID:
            return {
                ...state,
                ...action.payload,
            };
        case SearchCriteriaActionTypes.SEARCH_UPDATE:
            switch (action.payload.options.type) {
                case 'criteriaAdd':
                    return {
                        ...state,
                        // pushing new object into entityFilterCriterias Array by keeping the existing objects intact
                        entityFilterCriterias: [
                            ...state.entityFilterCriterias,
                            {
                                ...action.payload.newUpdate,
                            },
                        ],
                    };
                case 'criteriaUpdate':
                    return {
                        ...state,
                        // replacing new object into entityFilterCriterias Array by keeping the existing objects intact based on the dynamic index value
                        entityFilterCriterias: Object.assign([...state.entityFilterCriterias], {
                            [action.payload.options.extras.index]: action.payload.newUpdate,
                        }),
                    };
                case 'criteriaDelete':
                    const entityFilterCriterias = [...state.entityFilterCriterias];
                    entityFilterCriterias.splice(action.payload.options.extras.index, 1);
                    return {
                        ...state,
                        entityFilterCriterias,
                    };
                case 'relationsUpdate':
                    return {
                        ...state,
                        criteriaRelations: action.payload.newUpdate,
                    };
                case 'relationsAdd':
                    return {
                        ...state,
                        criteriaRelations: {
                            ...state.criteriaRelations,
                            ...action.payload.newUpdate,
                        },
                    };
                case 'relationsRuleAdd':
                    return {
                        ...state,
                        criteriaRelations: {
                            ...state.criteriaRelations,
                            rules: [
                                ...state.criteriaRelations.rules,
                                {
                                    ...action.payload.newUpdate,
                                },
                            ],
                        },
                    };
                default:
                    return {
                        ...state,
                        ...action.payload.newUpdate,
                    };
            }
        case SearchCriteriaActionTypes.SEARCH_CLEAR:
            const newState = {
                ...state,
            };
            if (newState.outputEntities?.length) {
                /* @to-do check whether search server accepts empty outputEntities: [] */
                delete newState.outputEntities;
            }
            return {
                ...newState,
                ...action.payload,
            };
        case SearchCriteriaActionTypes.EDIT_SEARCH_FILTER:
            // write your data manipulations necessary for edit filterCriteria as our goal is to handle the searchCriteria
            const filterCriteria = JSON.parse(action.payload.criteria);
            return {
                ...state,
                ...filterCriteria,
            };
        case SearchCriteriaActionTypes.EDIT_DEFAULT_FILTER:
            const filter = transformDownloadReportFilterCriteria(action.payload);
            return {
                ...state,
                ...filter,
            };
        default:
            return state;
    }
};

const transformDownloadReportFilterCriteria = (editDefaultFilterState: EditSavedDefaultFilter) => {
    const selectedDefaultFilter = editDefaultFilterState.defaultSearchFilters.filterCriterias[editDefaultFilterState.filter];
    return selectedDefaultFilter;
};
export const SEARCH_CRITERIA_REDUCER_KEY = 'searchCriteria';
