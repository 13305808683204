<div class="integrationfieldPadding">
    <div class="integrationRecordContainer">
        <ng-container *ngIf="editRecord && editRecord.recordData && editRecord.recordData.fields">
            <div class="integrationFieldsContainer row">
                <ng-container *ngFor="let field of editRecord.recordData.fields">
                    <ng-container *ngIf="!hideField(field, editRecord) && field.show !== false">
                        <div class="integrationRecordFieldContainer col-6">
                            <div class="integrationRecordField" [class.integrationArrayTextField]="(field.array || (field.conditionalArray && editRecord.isConditionalArray(field))) && getFieldType(field, editRecord) === 'TEXT'">
                                <ng-container *ngTemplateOutlet="integrationField; context: { record: editRecord, field: field, hide: hideField(field, editRecord) }"></ng-container>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="editRecord && editRecord.recordData &&editRecord.recordData.subSections">
            <ng-container *ngFor="let subSection of editRecord.recordData.subSections">
                <ng-container *ngTemplateOutlet="integrationSubSections; context: { record: editRecord, subSection: subSection }"></ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="noBorderTop">
    <div class="integrationRecordSubmitContainer" *transloco="let translate;">
        <material-button [label]="translate('Cancel')" [color]="'cancelBtn'" (onTrigger)="cancelConfig()"></material-button>
        <material-button [label]="translate('Delete')" *ngIf="editRecord?.id" [color]="'cancelBtn'" (onTrigger)="deleteConfig()"></material-button>
        <material-button *ngIf="checkIfTestConnectionAvailable()" [label]="translate('Test Connection')" [color]="'primary'" [disabled]="(!editRecord || !editRecord.isValid)" (onTrigger)="testConnection()"></material-button>
        <material-button *ngIf="(editRecord?.type === 'RESTAPI' || editRecord?.type === 'EMAIL') && showValidateButton" [label]="translate('Validate')" [color]="'primary'" (onTrigger)="validate()"></material-button>
        <material-button [label]="editRecord && editRecord.id ? translate('Update') : translate('Save')" (onTrigger)="submitConfigurationDetails()"></material-button>
    </div>
</div>

<ng-template #integrationField let-record="record" let-field="field" let-hide="hide">
    <app-integrations-field-template [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [field]="field" [masterTableColumns]="masterTableColumns" (getMasterColumns)="getMasterColumns($event)" [hide]="hide" (checkValidateButton)="checkValidateButton($event)"></app-integrations-field-template>
</ng-template>

<ng-template #integrationSubSections let-record="record" let-subSection="subSection">
    <ng-container *ngIf="!hideSubSection(subSection, record)">
        <ng-container [ngSwitch]="subSection.structure">
            <ng-container *ngSwitchCase="'POSTMAN'">
                <ng-container *ngTemplateOutlet="postManStructure; context: { record: record, subSection: subSection }"></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #postManStructure let-record="record" let-subSection="subSection">
    <app-integrations-postman-structure [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [subSection]="subSection" [hideField]="hideField" (checkValidateButton)="checkValidateButton($event)"></app-integrations-postman-structure>
</ng-template>
