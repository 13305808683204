import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appFileDragDetector]',
})
export class FileDragDetectorDirective {
    private clearDrag: NodeJS.Timeout;

    constructor() {}

    @HostListener('dragover', ['$event']) onDragOver = (event: Event) => {
        clearTimeout(this.clearDrag);
        event.preventDefault();
        event.stopPropagation();
        document.querySelector('body')?.classList.add('fileDragDetected');
        // $('body').addClass('fileDragDetected');
    };

    @HostListener('dragleave', ['$event']) onDragLeave = (event: DragEvent) => {
        clearTimeout(this.clearDrag);
        event.preventDefault();
        event.stopPropagation();
        if (!event['relatedTarget']) {
            document.querySelector('body')?.classList.remove('fileDragDetected');
            // $('body').removeClass('fileDragDetected');
        } else if (event.relatedTarget) {
            const targetClass = (event.relatedTarget as HTMLElement).getAttribute('class');
            if (targetClass?.includes('fileDropZoneText')) {
                this.clearDrag = setTimeout(() => {
                    document.querySelector('body')?.classList.remove('fileDragDetected');
                }, 3000);
            }
        }
    };

    @HostListener('drop', ['$event']) onDrop = (event: Event) => {
        clearTimeout(this.clearDrag);
        event.preventDefault();
        event.stopPropagation();
        document.querySelector('body')?.classList.remove('fileDragDetected');
        // $('body').removeClass('fileDragDetected');
    };
}
