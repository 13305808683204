import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Request } from '../../interface/request.interface';
import { ReconciliationRequest } from '../../models/reconciliation-request.class';
import { REQUESTS_REDUCER_KEY } from '../reducers';
import { RequestsState } from '../states';
import { getCurrentOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<RequestsState>(REQUESTS_REDUCER_KEY);

export const getRequests$ = createSelector(currentState, (state) => state.idVsRequest);

export const getRequest$ = (requestId: string) => createSelector(getRequests$, (requests) => requests?.[requestId]);

export const getRequestsOrgServicesMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizationsMap?.[organizationId]
);

export const getServiceRequestsMap$ = (serviceId: string) =>
    createSelector(getRequestsOrgServicesMap$, (requestsMap) => requestsMap?.[serviceId]);

export const getSearchedRequestIds$ = (serviceId: string, criteria: string) =>
    createSelector(getServiceRequestsMap$(serviceId), (requestsMap) => requestsMap?.searchedRequestIds?.[criteria]);

const getRequests = (serviceId: string, criteria: string) =>
    createSelector(getSearchedRequestIds$(serviceId, criteria), getRequests$, (requestIds, requestsObject) => {
        const requests = [];
        requestIds.forEach((id) => requests.push(requestsObject?.[id]));
        return requests as Request[] | ReconciliationRequest[];
    });

export const getSearchedRequests$ = (serviceId: string, criteria: string) =>
    createSelector(getRequests(serviceId, criteria), (requests) => {
        return requests as Request[];
    });

export const getReconRequests$ = (serviceId: string, criteria: string) =>
    createSelector(getRequests(serviceId, criteria), (requests) => {
        return requests as any as ReconciliationRequest[];
    });

export const getReconTemplates$ = (serviceId: string) =>
    createSelector(getServiceRequestsMap$(serviceId), (requestsMap) => requestsMap?.templates);

const getRequestsLoadingMap$ = (serviceId: string) =>
    createSelector(getServiceRequestsMap$(serviceId), (requestsMap) => requestsMap?.loading);

export const getRequestLoading$ = (serviceId: string) =>
    createSelector(getRequestsLoadingMap$(serviceId), (loadingMap) => loadingMap?.requests);

export const getReconTemplatesLoading$ = (serviceId: string) =>
    createSelector(getRequestsLoadingMap$(serviceId), (loadingMap) => loadingMap?.templates);

export const getUploadedFilesLoading$ = (serviceId: string) =>
    createSelector(getRequestsLoadingMap$(serviceId), (loadingMap) => loadingMap?.uploadedFiles);

export const getLastCreatedRequestId$ = createSelector(currentState, (state) => state?.lastCreatedRequestId);

export const getRequestsUploadedFilesMap$ = createSelector(currentState, (state) => state.idVsRequestUploadedFile);

export const getRequestUploadedFiles$ = (requestId: string) => createSelector(getRequestsUploadedFilesMap$, (map) => map?.[requestId]);
