import { BehaviorSubject } from 'rxjs';

import { CommonUtilsService } from '../services/commonutils/common-utils.service';
import { AssetService } from './assetservice.class';
import { Message } from './record/message.class';

export class Bundle {
    id: string;

    name: string;

    description: string;

    unitId: string;

    isEnabled: boolean;

    selectedServiceIds: string[];

    message: {
        name: Message;
        description: Message;
    };

    apps: BehaviorSubject<AssetService[]>;

    collaborationConfigs: {
        [property: string]: {
            unitId: string;
            assetId: string;
            serviceId: string;
            partnerAppSettings: {
                partnerFunction: string;
                partnerField: string;
                targetAssetId: string;
                targetServiceId: string;
                collaborationActions: { [property: string]: string[] };
                changeAllowedStages: string[];
                isAutoInvite: boolean;
                isEnabled: boolean;
                isRequiredApproval: boolean;
            }[];
            isAutoInvite: boolean;
            isEnabled: boolean;
            updatedBy: string;
            updatedOn: string;
        };
    };

    constructor(data?: any, apps?: AssetService[]) {
        this.id = data?.id;
        this.unitId = data?.unitId;
        this.name = data?.name || '';
        this.description = data?.description || '';
        this.isEnabled = true;
        this.selectedServiceIds = [];
        this.collaborationConfigs = {};
        this.message = {
            name: new Message(),
            description: new Message(),
        };
        this.apps = new BehaviorSubject([]);
        apps?.length > 0 && this.setApps(apps);
    }

    setApps = (apps?: AssetService[]) => {
        this.apps.next(CommonUtilsService.cloneObject(apps || []));
    };

    setSelectedApps = (serviceIds: string[]) => {
        this.apps.value.filter((app) => serviceIds.indexOf(app.serviceId) > -1).forEach((app) => (app['selected'] = true));
        this.selectedServiceIds = this.apps.value.reduce((serviceIds, app) => {
            if (app['selected']) {
                serviceIds.push(app.serviceId);
            }
            return serviceIds;
        }, []);
    };
}
