export class LookupInformationMetadata {
    displayField: string;

    globalLookup: string;

    id: string;

    lookupCondition: string;

    name: string;

    valueField: string;

    constructor(data: LookupInformationMetadata) {
        this.displayField = data && data.displayField;
        this.globalLookup = data && data.globalLookup;
        this.id = data && data.id;
        this.lookupCondition = data && data.lookupCondition;
        this.name = data && data.name;
        this.valueField = data && data.valueField;
    }
}
