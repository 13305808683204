<td class="table-body-cell record-selection-column p-0 text-center" *ngIf="eventType !=='view'">
    <input type="checkbox" [(ngModel)]="record.selected" name="selectAllButn" (ngModelChange)="onRecordSelectToggle.next()" class="form-checkbox accent-taxillaGreen text-taxillaGreen  w-6 hover:accent-taxillaGreen" checked>
</td>
<td class="table-body-cell expand-container cursor-pointer p-0 text-center" *ngIf="record?.entities?.length > 0">
    <button (click)="toggleShowChildEntities.emit()" class="bg-transparent hover:text-taxillaGreen focus:text-taxillaGreen">
        <span class="material-symbols-outlined align-middle !text-[20px]" [hidden]="showChildEntities">
            add_box
        </span>
        <span class="material-symbols-outlined align-middle !text-[20px]" [hidden]="!showChildEntities">
            remove
        </span>
    </button>
</td>
<ng-container *ngFor="let section of sections; let sectionIndex = index">
    <ng-container *ngFor="let field of section.fields; let fieldIndex = index">
        <td EntityRecordFieldTemplate [eventType]="eventType" [fieldId]="field.id" [recordId]="record.id" [autofocus]="sectionIndex === 0 && fieldIndex === 0" [record]="record" [masterTablesData]="record['parentMasterTablesData']" [masterRecordsResponse]="masterRecordsResponse" [parentLookupsData]="parentLookupsData" [lookupsResponse]="lookupsResponse" [service]="service" class="table-body-cell regular-column p-0" (openAttachment)="openAttachment($event)" (openGrid)="openGrid($event)" (editTextArea)="editTextArea($event)" (selectedMasterRecord)="selectedMasterRecord($event)" (selectedLookupValue)="selectedLookupValue($event)"></td>
    </ng-container>
</ng-container>
<td class="table-body-cell actions text-center p-0 bg-none" *ngIf="eventType !=='view'">
    <button *ngIf="record.message.errors.length > 0" [matTooltip]="record.message.errors[0]" [matTooltipClass]="['gridErrorsToolTip']" class="bg-transparent text-red hover:text-lightRed focus:text-lightRed" (click)="toggleErrors.emit()">
        <span class="material-symbols-outlined cursor-pointer align-middle !text-[20px]">
            info
        </span>
    </button>
    <button *ngIf="eventType !== 'view'" (click)="removeRecord.emit()" class="bg-transparent hover:text-taxillaGreen focus:text-taxillaGreen">
        <span class="material-symbols-outlined cursor-pointer align-middle !text-[20px]">
            delete
        </span>
    </button>
</td>
