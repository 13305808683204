<div id="mainRouterContainer" [class.onNewUI]="isNewUI" class="{{R.orientation}} {{R.device}} {{R.responsive}}">
    <div class="leftNavContainer" *ngIf="R.session.value && isNewUI">
        <app-left-nav-bar></app-left-nav-bar>
    </div>
    <div id="{{R.session.value ? 'postLoginContainer' : 'preLoginContainer'}}" [class.hasLeftNav]="isNewUI">
        <ng-container *ngIf="R.session.value">
            <ng-container *ngIf="!isNewUI">
                <app-taxilla-header [loading]="R.loadingCall"></app-taxilla-header>
            </ng-container>
            <ng-container *ngIf="isNewUI">
                <app-header [loading]="R.loadingCall"></app-header>
            </ng-container>
        </ng-container>
        <div class="routerOutlet" [hidden]="R.loggingIn || hideBody">
            <router-outlet></router-outlet>
        </div>
        <ng-container *ngIf="R.session.value && hasOrgId?.length > 0">
            <app-postloginfooter></app-postloginfooter>
        </ng-container>
    </div>
</div>
<loader></loader>
<div class="supportSystem" *ngIf="supportSystem">
    <ng-container *transloco="let translate;">
        <div class="btn-number">
            <a *ngIf="supportSystem.helpDeskNumber">
                <button mat-icon-button [title]="translate('Help Desk Number') + ' : ' + supportSystem.helpDeskNumber">
                    <span class="material-symbols-outlined">
                        call
                    </span>
                </button>
            </a>
        </div>
        <div class="btn-email">
            <a *ngIf="supportSystem.helpDeskEmail" href="mailto:{{supportSystem.helpDeskEmail}}" target="_blank" rel="noopener noreferrer">
                <button mat-icon-button [title]="translate('Help Desk Email') + ' : ' + supportSystem.helpDeskEmail">
                    <span class="material-symbols-outlined">
                        mail
                    </span>
                </button>
            </a>
        </div>
        <div class="btn-url">
            <a [href]="supportSystem.url" target="_blank">
                <button mat-icon-button [title]="translate('Open Support')">
                    <span class="material-symbols-outlined">
                        communication
                    </span>
                </button>
            </a>
        </div>
    </ng-container>
</div>
