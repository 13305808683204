import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'TruncateHTMLTags' })
export class TruncateHtmlTagsPipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        if (!value || !value.split) {
            return value;
        }
        let valueSplit = value.split('');
        let outputSplit = [];
        let tagFound = false;
        valueSplit.forEach((character) => {
            tagFound = ['<'].includes(character) || tagFound;
            if (tagFound && ['>'].includes(character)) {
                tagFound = false;
                outputSplit.push(' ');
                return;
            }
            if (tagFound) {
                return;
            }
            outputSplit.push(character);
        });
        return outputSplit.join('');
    }
}
