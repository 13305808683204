import { Injectable } from '@angular/core';

import { BridgeService } from '../bridge/bridge.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(
        private _bridge: BridgeService,
        private _utils: UtilsService,
        protected R: RootScopeService,
        private _store: StoreService
    ) {}

    /**
     * Method to get default widget data
     * @param callbacks Contains success callback method,
     */
    getDefaultWidgets = (
        data: { showLoader: boolean },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getDefaultWidgets(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.msg || 'Failed to get widgets');
                }
            }
        );
    };

    getWidgetData = (
        data: { widgetId: string; refresh: boolean; userName: string; showLoader: boolean },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getWidgetData(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get widgets');
                }
            }
        );
    };

    removeWidget = (
        data: { widgetId: string; orgId: string },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.removeWidget(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get widgets');
                }
            }
        );
    };

    widgetManagePermissions = async (callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }) => {
        const response = await this._store.privateScope.fetchValues(() => {
            return new Promise((resolve, reject) => {
                this._bridge.widgetManagePermissions(
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        if (callbacks.failureCallback) {
                            callbacks.failureCallback(res);
                        } else {
                            this._utils.alertError((res && res.msg) || 'Failed to get permissions');
                        }
                    }
                );
            });
        }, 'widgetPermissions');
        callbacks.successCallback(this._store.privateScope.getScope().widgetPermissions);
    };

    getWidgetOrganizations = (
        data: { orgId: string },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getWidgetOrganizations(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get widget subscriptions');
                }
            }
        );
    };

    getWidgetSubscriptions = (
        data: { orgIds: any[]; itemIds: any[]; subscriptionType: string; page: any; size: any; status: any },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getWidgetSubscriptions(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get widget subscriptions');
                }
            }
        );
    };

    getAllWidgetsList = (
        data: { widgetIds: any[] },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }
    ) => {
        this._bridge.getAllWidgetsList(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get widgets');
                }
            }
        );
    };

    saveWidgetSettings = (data: {}, callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void }) => {
        this._bridge.saveWidgetSettings(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to save settings');
                }
            }
        );
    };

    getAggregation = async (
        data: {
            filterCriteria: any;
            entityId: string;
            restApiServiceName: string;
        },
        callbacks: { successCallback: (response) => void; failureCallback?: (response: any) => void },
        custom?: { noAlerts: boolean; refreshData?: boolean }
    ) => {
        const stringifiedCriteria = JSON.stringify(data.filterCriteria);
        const storeArguments = ['aggregation', data.restApiServiceName, data.entityId, stringifiedCriteria];
        custom?.refreshData && this._store.privateScope.clearValue(...storeArguments);
        const response = await this._store.privateScope.fetchValues(() => {
            return new Promise((resolve, reject) => {
                this._bridge.getAggregation(
                    data,
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        if (callbacks.failureCallback) {
                            callbacks.failureCallback(res);
                        } else {
                            this._utils.alertError((res && res.msg) || 'Failed to get aggregations');
                        }
                    },
                    custom
                );
            });
        }, ...storeArguments);
        callbacks?.successCallback(response.aggregation?.[data.restApiServiceName]?.[data.entityId]?.[stringifiedCriteria]);
    };

    public getWidgetStatus = (id: string) => {
        return new Promise<boolean>((resolve) => {
            const date = new Date();
            this._bridge.getAllEvents(
                {
                    payload: {
                        eventType: undefined,
                        fetchSize: 20,
                        resourceId: id,
                        monthYear: `${date.getMonth() + 1}/${date.getFullYear()}`,
                    },
                    noAlerts: true,
                },
                async (response) => {
                    const logs = response?.['event-logs']?.eventLogs;
                    let result: boolean;
                    if (logs?.length > 0) {
                        result = await this.checkLogs(id, response?.['event-logs']?.eventLogs);
                    }
                    resolve(result);
                },
                (response) => {
                    resolve(false);
                }
            );
        });
    };

    private checkLogs = (
        id: string,
        logs: {
            appId: string;
            createdOn: string;
            details: string;
            eventType: string;
            headers: { category: '-'; version: 'v1' };
            monthYear: string;
            orgId: string;
            providerOrgId: string;
            resourceId: string;
            rootOrgId: string;
            type: string;
            userId: string;
        }[]
    ) => {
        return new Promise<boolean>(async (resolve) => {
            const neededStatuses = [
                'OUTBOUND_TRANSFORMATION_STARTED',
                'OUTBOUND_TRANSFORMATION_COMPLETED',
                'OUTBOUND_TRANSFORMATION_TERMINATED',
            ];
            const log = logs.find((log) => neededStatuses.indexOf(log.eventType) > -1);
            switch (log?.eventType) {
                case 'OUTBOUND_TRANSFORMATION_COMPLETED':
                case 'OUTBOUND_TRANSFORMATION_TERMINATED':
                    resolve(true);
                    break;
                case 'OUTBOUND_TRANSFORMATION_STARTED':
                    await this._utils.waitForCertainTime(1000);
                    const result = await this.getWidgetStatus(id);
                    resolve(result);
                    break;
            }
        });
    };
}
