import { Injectable } from '@angular/core';

import { CollabConfiguration } from '../../models/collab-configuration.class';
import { BridgeService } from '../bridge/bridge.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CollaborationService {
    constructor(private _bridge: BridgeService, private _utils: UtilsService, private _store: StoreService) {}

    getCollabConfig = async (
        data: {
            assetId: string;
            instanceId?: string;
        },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        const response: any = await this._store.privateScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getCollabConfig(
                        data,
                        (res) => {
                            resolve(res || {});
                        },
                        (res) => {
                            if (!callbacks.failureCallback) {
                                this._utils.alertError((res && res.msg) || 'Failed to get collaboration configuration');
                            } else {
                                callbacks.failureCallback(res);
                            }
                        }
                    );
                });
            },
            'collabConfig',
            data?.assetId
        );
        callbacks.successCallback(response?.['collabConfig']?.[data.assetId]);
    };

    createCollabConfig = (
        data: {
            assetId: string;
            config: CollabConfiguration;
        },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._store.privateScope.clearValue('collabConfig', data.assetId);
        this._bridge.createCollabConfig(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to save collaboration configuration');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    deleteCollabConfig = (
        data: {
            assetId: string;
        },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.deleteCollabConfig(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to delete collaboration configuration');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    getCollaboratorsList = (
        data: {
            assetId: string;
            instanceId: string;
        },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getCollaboratorsList(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to get collaborators list');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    createPartnerRequest = (
        data: {
            instanceId: string;
            assetId: string;
            partnerId?: string;
            emails?: any[];
            inviteUsingPartnerFields?: boolean;
            partnerFunction: string;
            comments: string;
        },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.createPartnerRequest(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to create partner request');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    approveCollaborationRequest = (
        data: {
            collaborationId: string;
            payload: { unitId: string };
        },
        callbacks: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.approveCollaborationRequest(
            data,
            (response) => {
                callbacks.successCallback?.(response);
            },
            (res) => {
                if (!callbacks.failureCallback) {
                    this._utils.alertError((res && res.msg) || 'Failed to approve request');
                } else {
                    callbacks.failureCallback(res);
                }
            }
        );
    };

    getBundleCollabConfig = (bundleId: string, assetId: string) => {
        const promise = this._bridge.getBundleCollaboration(bundleId, assetId);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get collaboration configuration');
        });
        return promise;
    };

    updateBundleCollaboration = (bundleId: string, assetId: string, payload: CollabConfiguration) => {
        this._store.privateScope.clearValue('collabConfig', assetId);
        const promise = this._bridge.updateBundleCollaboration(bundleId, assetId, payload);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to save collaboration configuration');
        });
        return promise;
    };

    deleteBundleCollabConfig = (bundleId: string, assetId: string) => {
        const promise = this._bridge.deleteBundleCollaboration(bundleId, assetId);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to delete collaboration configuration');
        });
        return promise;
    };
}
