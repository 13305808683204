<mat-form-field *transloco="let translate">
    <input type="{{dataType}}" autocomplete="off" matInput placeholder="{{placeholder}}" [autofocus]="autofocus" name="{{name}}" title="{{title}}" id="{{id}}" class="{{classList?.join(' ')}}" [formControl]="control" [errorStateMatcher]="matcher" debounce="{{debounceAfter || 1000}}" (eventHandler)="debounceEventHandler.emit($event)" (focus)="clickedInput()" (blur)="clickedOut()" (keypress)="startedEditing($event); errors?.splice(0,1);" (keyup)="startedEditing($event); keyUpEventHandler.emit($event)">
    <button mat-button [hidden]="dataType === 'text'" tabindex="-1" *ngIf="model?.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="dataType = 'text';" [title]="translate('show')" [disabled]="disabled">
        <img src="/assets/images/hide.png" class="passwordHelperIcons" alt="hide">
    </button>
    <button mat-button [hidden]="dataType === 'password'" tabindex="-1" *ngIf="model?.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="dataType = 'password';" [title]="translate('hide')" [disabled]="disabled">
        <img src="/assets/images/view.png" class="passwordHelperIcons" alt="view">
    </button>
    <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
        <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}">
            info
        </span>
    </button>
    <mat-hint align="end" *ngIf="maxLength">{{control.value && control.value.length || 0}} / {{maxLength}}</mat-hint>
    <mat-hint align="start" *ngIf="required && !(errors?.length > 0)">
        <strong><span>* </span>{{translate('required')}}</strong>
    </mat-hint>
    <mat-error *ngIf="control.hasError('errors')" [matTooltip]="errors">
        {{errors}}
    </mat-error>
</mat-form-field>
