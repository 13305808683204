import { createReducer, on } from '@ngrx/store';

import { SetAppSearchFilters, SetDefaultSearchFilters, SetFilingSearchCriteria } from '../actions/search.actions';
import * as sessionActions from '../actions/session.actions';
import { searchState } from '../states';

export const SEARCH_REDUCER = createReducer(
    searchState,
    on(SetDefaultSearchFilters, (state, action) => {
        return {
            ...state,
            organizations: {
                ...state.organizations,
                [action.organizationId]: {
                    ...state.organizations?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizations?.[action.organizationId]?.[action.serviceId],
                        defaultFilters: {
                            'default-filters': action.filters,
                            filterCriterias: action.criterias,
                        },
                    },
                },
            },
        };
    }),
    on(SetAppSearchFilters, (state, action) => {
        return {
            ...state,
            organizations: {
                ...state.organizations,
                [action.organizationId]: {
                    ...state.organizations?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizations?.[action.organizationId]?.[action.serviceId],
                        searchFilters: action.filters,
                    },
                },
            },
        };
    }),
    on(SetFilingSearchCriteria, (state, action) => {
        return {
            ...state,
            searchCriteria: {
                ...state.searchCriteria,
                [action.serviceId]: action.searchCriteria,
            },
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, (state, action) => {
        return searchState;
    })
);

export const SEARCH_REDUCER_KEY = 'search';
