<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 class="matDialogTitle" mat-dialog-title>{{title}}</h4>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography matDialogBody">
    <div class="widgetSourceTabs" *ngIf='showSourceSetting'>
        <mat-tab-group (selectedIndexChange)="selected.setValue($event); sourceChanged(sourceArrayOptions[$event], selected.value)" [selectedIndex]="selected && selected.value">
            <ng-container>
                <mat-tab *ngFor="let source of sourceArrayOptions; let i = index">
                    <ng-template mat-tab-label>
                        {{source.sourceName}}
                    </ng-template>
                    <div class="integrationTabContentContainer">
                        <ng-container *ngIf="selected.value !== undefined && selected.value === i">
                            <ng-container *ngTemplateOutlet="sourceDetails; context: { source: source}"></ng-container>
                        </ng-container>
                    </div>
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    </div>
    <div class="masterFilterSelection" *ngIf="isFromMaster">
        <mat-radio-group aria-labelledby="filter-selection" class="d-flex align-items-center" [(ngModel)]="selectedOutlookFilter" name="masterSearchGroup">
            <mat-radio-button class="mr-4" *ngFor="let filter of masterFilters" [value]="filter.name" [checked]="filter.checked">
                {{filter.displayName}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <ng-container *ngIf="selectedOutlookFilter === 'validationStatus'">
        <div class="d-flex align-items-center">
            <label class="mr-3">{{translateMsg('Validation Status')}}:</label>
            <mat-form-field class="col-4" appRemoveAriaOwns>
                <mat-select [(value)]="validationFilter" [placeholder]="translateMsg('Select Status')">
                    <mat-option *ngFor="let filter of validationFilters" [value]="filter.name">
                        {{filter.displayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="selectedOutlookFilter === 'searchFilters'">
        <div class="d-flex align-items-center">
            <label class="mr-3">{{translateMsg('Search Filters')}}:</label>
            <mat-form-field class="col-4" appRemoveAriaOwns>
                <mat-select panelClass="searchFilterDropdown" [(ngModel)]="defaultFilter" [placeholder]="translateMsg('Search Filter')" #matFilterSelect>
                    <ng-container *ngFor="let filter of searchFilters; let i = index;">
                        <div class="searchFilterOption">
                            <mat-option [value]="filter" selected="filter.id === defaultFilter.id" title="{{filter.filterName}}">
                                {{filter.filterName}}
                            </mat-option>
                            <mat-icon class="editFilter" [title]="translateMsg('Edit Filter')" (click)="editFilter(filter)">save_as</mat-icon>
                            <mat-icon [title]="translateMsg('Delete')" (click)="deleteSearchFilter(filter)">
                                delete</mat-icon>
                        </div>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="selectedOutlookFilter === 'customSearch'">
        <ng-container *ngIf="isFieldsAvailable; else noFields">
            <ng-container *ngTemplateOutlet="customSearch"></ng-container>
        </ng-container>
    </ng-container>

    <div class="row qbSection sourceQbSection" *ngIf='!initiateElasticSearch'>
        <div class="col-12">
            <span class="col-2">
                {{translateMsg('Source')}}:
            </span>
            <span class="col-4 displyInlineBlock">
                <mat-form-field appRemoveAriaOwns>
                    <mat-select [(ngModel)]="selectedSource" (selectionChange)="webServiceSourceChanged($event)">
                        <mat-option [value]="source.sourceName" *ngFor='let source of webServicesSourceSettings'>{{source.sourceName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
        </div>
        <div class="col-6">
            <span class="col-4">
                {{translateMsg('Url')}}:
            </span>
            <span class="col-8 displyInlineBlock">
                <mat-form-field>
                    <input matInput [(ngModel)]="selectedSource.integrationSettings.url" [disabled]='true' [title]="selectedSource.integrationSettings.url">
                </mat-form-field>
            </span>
        </div>
        <div class="col-6">
            <span class="col-4">
                {{translateMsg('Method')}}:
            </span>
            <span class="col-8 displyInlineBlock">
                <mat-form-field>
                    <input matInput [(ngModel)]="selectedSource.integrationSettings.method" [disabled]='true' [title]="selectedSource.integrationSettings.method">
                </mat-form-field>
            </span>
        </div>
    </div>

    <ng-container *ngIf="initiateElasticSearch && !isReferedCriteria && !isFromMaster">
        <ng-container *ngTemplateOutlet="customSearch"></ng-container>
    </ng-container>
    <ng-container *ngIf='!initiateElasticSearch && !isReferedCriteria'>
        <div class="sortQbSection" *ngIf='showSortCriteria'>
            <h4>{{translateMsg('Search Criteria')}}: </h4>
            <div class="row">
                <mat-tab-group class="paddingRightLeft15">
                    <mat-tab label="Authentication">
                        <div class="col-12 displayInlineFlex">
                            <div class="col-4 displyInlineBlock borderRightForAuthentication centerTheChildDiv">
                                <div class="col-4 displyInlineBlock">
                                    {{translateMsg('Type')}}
                                </div>
                                <div class="col-8 displyInlineBlock">
                                    <mat-form-field appRemoveAriaOwns>
                                        <mat-select [(ngModel)]="selectedAuth">
                                            <mat-option [value]="auth.value" *ngFor='let auth of authTypes'>{{auth.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-8 displyInlineBlock centerTheChildDiv">
                                <div class="authContent textAlignCenter colorGray" *ngIf='selectedAuth === "NoAuthSettings"'>
                                    {{translateMsg('This request does not use any authorization')}}
                                </div>
                                <div class="authContent width100" *ngIf='selectedAuth === "BasicAuthSettings"'>
                                    <div class="loginDetailSection displayInlineFlex">
                                        <div class="col-6">
                                            <material-input [autofocus]="false" [placeholder]="translateMsg('User ID')" [model]="login.userId" (modelChange)="login.userId = $event" [name]="'userId'" [id]="'userId'" [required]="true">
                                            </material-input>
                                        </div>
                                        <div class="col-6">
                                            <material-input-password [autofocus]="false" [placeholder]="translateMsg('Password')" [model]="login.password" (modelChange)="login.password = $event" [name]="'userPassword'" [id]="'userPassword'" [required]="true">
                                            </material-input-password>
                                        </div>
                                    </div>
                                </div>
                                <div class="authContent width100" *ngIf='selectedAuth === "OAuthSettings"'>
                                    <div class="auth2Section">
                                        <div class="col-12">
                                            <div class="width100 displayInlineFlex">
                                                <div class="col-6">
                                                    <mat-form-field appRemoveAriaOwns>
                                                        <mat-select [(ngModel)]="selectedNewToken" placeholder="translateMsg('Available Tokens')">
                                                            <mat-option [value]="'defaultToken'">
                                                                {{translateMsg('Select Token')}}
                                                            </mat-option>
                                                            <mat-option [value]="token.value" *ngFor='let token of newTokens'>{{token.name}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div *ngIf='(selectedNewToken !== ("" || "defaultToken")) && (updateNewTokens())'>
                                                <ng-container *ngTemplateOutlet="selectedNewTokenTokenRef"></ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="translateMsg('Head')">
                        <ng-container *ngIf='isHeadersPresent()'>
                            <ng-container *ngFor='let header of selectedSourceHeaders'>
                                <div class="col-2 displyInlineBlock">
                                    <div>
                                        {{header.key}}
                                    </div>
                                </div>
                                <div class="col-10 displyInlineBlock">
                                    <div class=" col-4">
                                        <mat-form-field>
                                            <input matInput [(ngModel)]="header.values" [disabled]='!header.dynamic' (ngModelChange)='updateValue(header)'>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </mat-tab>
                    <mat-tab [label]="translateMsg('Body')">
                        <div class="col-12 displayInlineFlex">
                            <div class="col-4 centerTheChildDiv">
                                <mat-form-field appRemoveAriaOwns>
                                    <mat-select [(ngModel)]="selectedBodyType" placeholder="translateMsg('Type')">
                                        <mat-option [value]="'defaultType'">
                                            {{translateMsg('Select Type')}}
                                        </mat-option>
                                        <mat-option [value]="type.value" *ngFor='let type of availableTypes'>{{type.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-8 centerTheChildDiv">
                                <textarea matInput placeholder="translateMsg('Enter Body')" [(ngModel)]='bodyContent'></textarea>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="translateMsg('Param')">
                        <ng-container *ngIf='isQueryPresent()'>
                            <ng-container *ngFor='let params of selectedSourceParams'>
                                <div class="col-2 displyInlineBlock">
                                    <div>
                                        {{params.key}}
                                    </div>
                                </div>
                                <div class="col-10 displyInlineBlock">
                                    <div class="col-4">
                                        <mat-form-field>
                                            <input matInput [(ngModel)]="params.values" [disabled]='!params.dynamic' (ngModelChange)='updateValue(params)'>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isReferedCriteria">
        <div class="referredEntityDiv">
            <span class="referredEntityHeader">{{translateMsg('Referred Entity Name')}} :</span>
            <span class="referredEntitySpan">{{ReferredCriteriaEntity && ReferredCriteriaEntity.referredCriteriaName}}</span>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center" class="matDialogFooter noBorder">
    <material-button *ngIf='widgetSettingsModal' class='noOutline masterSearchBtn' [label]="translateMsg('Cancel')" color="default" (onTrigger)="closeModal()"></material-button>
    <material-button *ngIf="!widgetSettingsModal && isFieldsAvailable && selectedOutlookFilter === 'customSearch'" class="noOutline submitColor masterSearchBtn" [label]="translateMsg('Search')" (onTrigger)="searchMasterEntities()"></material-button>
    <material-button *ngIf="!widgetSettingsModal && isFieldsAvailable && selectedOutlookFilter === 'customSearch'" class="noOutline submitColor masterSearchBtn" [label]="(selectedSource?.searchQuery && defaultFilter?.filterName) ? translateMsg('Update Filter') : translateMsg('Save Filter')" (onTrigger)="renderSaveFilterPopup()"></material-button>
    <material-button *ngIf="!widgetSettingsModal && selectedOutlookFilter !== 'customSearch'" class="noOutline submitColor masterSearchBtn" [label]="translateMsg('Apply Filter')" (onTrigger)="applySelectedFilter()"></material-button>
    <material-button *ngIf="!widgetSettingsModal" class="cancel masterSearchBtn" [label]="translateMsg('Clear Filter')" (onTrigger)="clearMasterSearch()"></material-button>
    <material-button *ngIf='widgetSettingsModal' class="noOutline submitColor masterSearchBtn" [label]="translateMsg('Save')" [disabled]='!searchQueryFound || isReferedCriteria' (onTrigger)="saveWidgetSettings()"></material-button>
</mat-dialog-actions>

<ng-template #sourceDetails let-source="source">
    <div class="row qbSection sourceQbSection">
        <div class="col-6">
            <span class="col-4">
                {{translateMsg('Entity')}}:
            </span>
            <span class="col-8 displyInlineBlock">
                <mat-form-field appRemoveAriaOwns>
                    <mat-select [(value)]="source.selectedEntity" (selectionChange)="entityChanged($event)">
                        <mat-option [value]="'defaultEntity'">
                            {{translateMsg('Select entity')}}
                        </mat-option>
                        <mat-option [value]='entity.key' *ngFor='let entity of entityArrayOptions'>
                            {{entity.name}}({{entity.key}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #passwordCredential>
    <div>
        <ng-container *ngFor='let auth of passwordCredentialData'>
            <div class="col-4 displyInlineBlock">
                <div>
                    {{auth.name}}
                </div>
            </div>
            <div class="col-8 displyInlineBlock">
                <ng-container *ngIf='!auth.selectBox'>
                    <ng-container *ngIf='auth.name !== "Password"'>
                        <mat-form-field>
                            <input matInput [(ngModel)]="auth.value" [placeholder]="translateMsg('Enter') + auth.name">
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf='auth.name === "Password"'>
                        <mat-form-field>
                            <input matInput [(ngModel)]="auth.value" [placeholder]="translateMsg('Enter') + auth.name" type="{{dataType}}">
                            <button [hidden]="dataType === 'text'" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="dataType = 'text'" [title]="translateMsg('show')" *ngIf='auth.value'>
                                <img src="/assets/images/hide.png" class="passwordHelperIcons" alt="hide">
                            </button>
                            <button [hidden]="dataType === 'password'" mat-button matSuffix mat-icon-button aria-label="Hide" (click)="dataType = 'password'" [title]="translateMsg('hide')" *ngIf='auth.value'>
                                <img src="/assets/images/view.png" class="passwordHelperIcons" alt="view">
                            </button>
                        </mat-form-field>
                    </ng-container>

                </ng-container>
                <ng-container *ngIf='auth.selectBox'>
                    <mat-form-field appRemoveAriaOwns>
                        <mat-select [(ngModel)]="auth.value" [placeholder]="translateMsg('Client Authentication')" (modelChange)="valueChanged($event, auth)">
                            <mat-option [value]="'defaultClientAuth'">
                                {{translateMsg('Select Client Authentication')}}
                            </mat-option>
                            <mat-option [value]="clientAuth.value" *ngFor='let clientAuth of auth.values'>{{clientAuth.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #authorizationCode>
    <div>
        <ng-container *ngFor='let auth of authorizationCodeData'>
            <div class="col-4 displyInlineBlock">
                <div>
                    {{auth.name}}
                </div>
            </div>
            <div class="col-8 displyInlineBlock">
                <ng-container *ngIf='!auth.values'>
                    <mat-form-field>
                        <input matInput [(ngModel)]="auth.value" [placeholder]="translateMsg('Enter') + auth.name">
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf='auth.values'>
                    <mat-form-field appRemoveAriaOwns>
                        <mat-select [(ngModel)]="selectedAuthClientAuth" [placeholder]="translateMsg('Client Authentication')">
                            <mat-option [value]="'defaultClientAuth'">
                                {{translateMsg('Select Client Authentication')}}
                            </mat-option>
                            <mat-option [value]="clientAuth.value" *ngFor='let clientAuth of auth.values'>{{clientAuth.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #implicit>
    <div>
        <ng-container *ngFor='let auth of implicitData'>
            <div class="col-4 displyInlineBlock">
                <div>
                    {{auth.name}}
                </div>
            </div>
            <div class="col-8 displyInlineBlock">
                <ng-container *ngIf='!auth.values'>
                    <mat-form-field>
                        <input matInput [(ngModel)]="auth.value" [placeholder]="translateMsg('Enter') + auth.name">
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf='auth.values'>
                    <mat-form-field appRemoveAriaOwns>
                        <mat-select [(ngModel)]="selectedImplicitClientAuth" [placeholder]="translateMsg('Client Authentication')">
                            <mat-option [value]="'defaultClientAuth'">
                                {{translateMsg('Select Client Authentication')}}
                            </mat-option>
                            <mat-option [value]="clientAuth.value" *ngFor='let clientAuth of auth.values'>{{clientAuth.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #clientCredential>
    <div>
        <ng-container *ngFor='let auth of clientCredentialData'>
            <div class="col-4 displyInlineBlock">
                <div>
                    {{auth.name}}
                </div>
            </div>
            <div class="col-8 displyInlineBlock">
                <ng-container *ngIf='!auth.values'>
                    <mat-form-field>
                        <input matInput [(ngModel)]="auth.value" [placeholder]="translateMsg('Enter') + auth.name">
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf='auth.values'>
                    <mat-form-field appRemoveAriaOwns>
                        <mat-select [(ngModel)]="selectedClientCredentialClientAuth" [placeholder]="translateMsg('Client Authentication')">
                            <mat-option [value]="'defaultClientAuth'">
                                {{translateMsg('Select Client Authentication')}}
                            </mat-option>
                            <mat-option [value]="clientAuth.value" *ngFor='let clientAuth of auth.values'>{{clientAuth.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #customSearch>
    <div class="qbSection">
        <h4>{{translateMsg('Search Criteria')}}: </h4>
        <ng-container *ngIf='searchQueryFound'>
            <app-lib-querybuilder [query]="selectedSource?.searchQuery" [isAddRuleVisible]="true" [config]="searchConfig" [allowRuleset]="allowRuleset" [allowCollapse]="allowCollapse" (queryChanged)="onSearchQueryChanged($event)"></app-lib-querybuilder>
        </ng-container>
        <div *ngIf='loadingSearchCriteria' class="loader">
            <img src="/assets/images/loader.svg" alt="{{translateMsg('Loading...')}}">
        </div>
        <div class="textAlignCenter" *ngIf='!searchQueryFound'>
            {{translateMsg('No search criteria found')}}
        </div>
    </div>
    <div class="qbSection sortQbSection" *ngIf="canShowSortQuery">
        <h4>{{translateMsg('Sort Criteria')}}: </h4>
        <app-lib-querybuilder [query]="sortQuery" [config]="sortConfig" [isAddRuleVisible]="true" [allowRuleset]="false" [allowCollapse]="false" (queryChanged)="onSortQueryChanged($event)"></app-lib-querybuilder>
    </div>
</ng-template>

<ng-template #noFields>
    <div class="centerDiv">
        <div class="noRecords">
            <div>{{translateMsg('There are no searchable fields defined for this master')}}.</div>
        </div>
    </div>
</ng-template>
