<div class="editProfileContainer" *transloco="let translate;" appFileDragDetector>
    <div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('Edit Profile')}}</h4>
        <span class="material-icons mb-2 masterCloseBtnIcon" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody">
        <form name="editProfileForm" autocomplete="off">
            <div class="row userModalRow">
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft">
                    <div class="col-12 zeroPaddingRight">
                        <material-input [autofocus]="false" [placeholder]="translate('Enter User ID')" [name]="'userId1'" [title]="translate('Enter User ID')" debounce="1000" [errors]="errors.userId" [model]="loggedInUser.user.userId" (modelChange)="loggedInUser.user.userId = $event;" [required]="true" [disabled]="loggedInUser.user.userId ? true : false" [classList]='loggedInUser.user.userId ? ["disabledColor"] : ""'>
                        </material-input>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft">
                    <div class="col-12 zeroPaddingRight">
                        <material-input [autofocus]="false" [placeholder]="translate('Enter Email')" [name]="'email2'" [title]="translate('Enter Email')" debounce="1000" [model]="loggedInUser.user.email" [errors]="errors.email" (modelChange)="loggedInUser.user.email = $event" [required]="true" [disabled]="true">
                        </material-input>
                    </div>
                </div>
            </div>
            <div class="row userModalRow">
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft">
                    <div class="col-12 zeroPaddingRight">
                        <material-input [autofocus]="false" [placeholder]="translate('Enter First Name')" [name]="'firstName3'" [title]="translate('Enter First Name')" debounce="1000" [errors]="errors.firstName" [model]="loggedInUser.user.firstName" (modelChange)="loggedInUser.user.firstName = $event" [required]='true'>
                        </material-input>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft">
                    <div class="col-12 zeroPaddingRight">
                        <material-input [autofocus]="false" [placeholder]="translate('Enter Last Name')" [name]="'lastName4'" [title]="translate('Enter Last Name')" debounce="1000" [errors]="errors.lastName" [model]="loggedInUser.user.lastName" (modelChange)="loggedInUser.user.lastName = $event;" [required]='true'>
                        </material-input>
                    </div>
                </div>
            </div>
            <div class="row userModalRow">
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft">
                    <div class="col-12 zeroPaddingRight">
                        <material-input [autofocus]="false" [placeholder]="translate('Enter Department')" [name]="'department6'" [title]="translate('Enter Department')" debounce="1000" [errors]="errors.department" [model]="loggedInUser.user.department" (modelChange)="loggedInUser.user.department = $event;">
                        </material-input>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft">
                    <div class="col-12 zeroPaddingRight">
                        <material-input [autofocus]="false" [placeholder]="translate('Enter Designation')" [name]="'designation7'" [title]="translate('Enter Designation')" debounce="1000" [errors]="errors.designation" [model]="loggedInUser.user.designation" (modelChange)="loggedInUser.user.designation = $event;">
                        </material-input>
                    </div>
                </div>
            </div>
            <div class="row userModalRow">
                <div class="col-6 d-flex justify-content-between align-items-center zeroPaddingLeft" *ngIf="canShowWorkQ">
                    <div class="col-12 zeroPaddingRight">
                        <material-checkbox [name]="'Email Notification'" [model]="loggedInUser.user.emailNotification" (modelChange)="loggedInUser.user.emailNotification = $event;">
                        </material-checkbox>
                        <span class="emailNotification">
                            <span>&nbsp;&nbsp;{{translate('Enable workqueue email notification')}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="authenticationTabContainer">
            <mat-tab-group (selectedIndexChange)="selected.setValue($event); changeAuthType()" [selectedIndex]="selected?.value">
                <mat-tab *ngFor="let tab of authTypes; let i = index">
                    <ng-template mat-tab-label>
                        {{translate(tab.name)}}
                    </ng-template>
                    <div class="authenticationTabContent">
                        <ng-container [ngSwitch]="tab.id">
                            <ng-container *ngSwitchCase="'SECRET_QUESTION'">
                                <div class="securityQuestionsDiv">
                                    <div *ngFor="let question of securityQuestionsStructure; let i = index">
                                        <div class="row mr-0 pdbtm15 questionSize">
                                            <div class="col-6">
                                                <material-select [options]="secretQuestions" [placeholder]="translate(question.displayName)" [name]="question.displayName" [optionDisplayNameProperty]="'value'" [optionValueProperty]="'value'" [required]="true" [model]="question.key" (modelChange)="question.encrypted = false; question.key = $event; checKQuestions($event,question)" [errors]="question.errors.key" [title]="question.key"> </material-select>
                                            </div>
                                            <div class="col-5">
                                                <material-input-password [autofocus]="false" [placeholder]="translate('Answer')" [encrypted]="question.encrypted" [model]="question.value" (modelChange)="question.value = $event" [name]="'qstionName_' + i" [id]="'qstionId_' + i" [required]="true" [errors]="question.errors.value" (encryptionOverrided)="question.encrypted = false"></material-input-password>
                                            </div>
                                            <div class="col-1 authQuestionsAction">
                                                <button mat-mini-fab [title]="translate('Remove')" class="removeButton" [disabled]="mmfaQuestions >= securityQuestionsStructure.length" (click)="securityQuestionsStructure.splice(i, 1)">
                                                    <mat-icon class="removeIcon">remove</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="justify-content-end mr-0 row" *ngIf="securityQuestionsStructure.length <= 4">
                                            <div class="col-1 authQuestionsAction">
                                                <button mat-mini-fab class="addButton" [title]="translate('Add')" [disabled]="securityQuestionsStructure.length >= 4" (click)="pushQuestions(1)">
                                                    <mat-icon class="addIcon">add</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'GOOGLE_AUTHENTICATOR_OTP'">
                                <div class="googleAuthenticatorContainer">
                                    <div class="secretKeyContainer row">
                                        <div class="col-4">
                                            {{translate('Secret Key')}}:
                                        </div>
                                        <div class="col-6" *ngIf="secretKey?.length > 0">
                                            {{secretKey}}
                                        </div>
                                        <div class="col-6" *ngIf="!secretKey || secretKey.length === 0">
                                            <material-button class='mx-2 noOutline' [label]="translate('Create')" color="primary" (onTrigger)="createGoogleSecretkey()"></material-button>
                                        </div>
                                    </div>
                                    <div class="QRCodeContainer row" *ngIf="secretKey?.length > 0">
                                        <div class="col-4">
                                            {{translate('QR Code')}}
                                        </div>
                                        <div class="col-6">
                                            <img src="data:image/jpeg;base64,{{qrCode}}" alt="QrCode" class="qrCodeImage">
                                        </div>
                                    </div>
                                    <div class="googleAuthenticatorNote">
                                        <strong>{{translate('Note')}} :</strong>
                                        {{translate('Scan the code with authenticator app and use the code from that app to pass the 2FA from next time. If you lose the QR Code, use the forgot password to reset the authentication')}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'EMAIL_OTP'">
                                <div class="emailOtpAuthenticatorNote">
                                    <strong>{{translate('Note')}} :</strong>
                                    {{translate('Above Email Id will be used for Email OTP Authentication')}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="matDialogFooter">
        <material-button class="mx-2 noOutline" [label]="translate('Cancel')" color="default" (onTrigger)="closeModal()"></material-button>
        <material-button class="noOutline submitColor" [disabled]="(selected.value === 1 && (!secretKey || !secretKey.length))" [label]="translate('Update')" color="primary" (onTrigger)="submitUser()"></material-button>
    </mat-dialog-actions>
</div>
