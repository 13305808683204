import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appAppDetectOutsideClick]',
})
export class AppDetectOutsideClickDirective {
    @Output() appAppDetectOutsideClick = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    public onClick(target) {
        const clickedInside =
            this.elementRef.nativeElement.contains(target) ||
            target.innerText === 'Filters' ||
            target.classList.contains('searchFilters') ||
            target.classList.contains('addToIgnoreList') ||
            target.innerText === 'Search' ||
            target.textContent === 'Searchclose';
        const timePickerConintainers = document.getElementsByClassName('timepicker-backdrop-overlay');
        let timePickerFound = false;
        if (timePickerConintainers?.length > 0) {
            timePickerFound = true;
        }
        const avoidedElements = [
            'cdk-overlay-backdrop',
            'mat-option-text',
            'notranslate',
            'deleteSaveFilter',
            'editSaveFilter',
            'mat-calendar-body-cell-content',
            'mat-calendar-period-button',
            'mat-calendar-previous-button',
            'mat-calendar-next-button',
            'mat-button-wrapper',
            'mat-calendar-arrow',
            'mat-option-multiple',
            'mat-pseudo-checkbox',
            'highlightSuggestedOption',
            'addToIgnoreList',
            'mat-optgroup-label',
            'mat-ripple',
            'q-right-align',
            'mat-focus-indicator',
            'timepicker__main-content',
            'clock-face',
            'clock-face__container',
            'clock-face__number',
            'timepicker__actions',
            'timepicker-button',
            'mat-calendar-controls',
            'mat-calendar-header',
            'mat-pseudo-checkbox',
            'mat-calendar-body-label',
            'mat-calendar-controls',
            'mat-calendar-table-header',
            'mat-calendar-table',
            'mat-datepicker-content',
        ]; /* elements class name that needs to be avoided when clicked on it */
        let elementFound: boolean;
        for (const avoidedElement of avoidedElements) {
            if (
                target?.classList?.contains(avoidedElement) ||
                target?.parentNode?.classList?.contains(avoidedElement) ||
                target?.parentElement?.classList?.contains(avoidedElement) ||
                target?.parentElement?.parentElement?.classList?.contains(avoidedElement) ||
                target?.parentElement?.parentElement?.parentElement?.classList?.contains(avoidedElement) ||
                target?.id?.includes('mat-calendar-button-')
            ) {
                elementFound = true;
                break;
            } else {
                elementFound = false;
            }
        }
        if (!clickedInside && !elementFound && !timePickerFound) {
            this.appAppDetectOutsideClick.emit();
        }
    }
}
