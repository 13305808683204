import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const manageAppsAdapters: { [property: string]: EntityAdapter<any> } = {
    organizations: createEntityAdapter<ManageAppsOrganizationEntity>(),
};

export const manageAppsState: ManageAppsState = {
    assetType: undefined,
    subscriptionStatus: 'ALL',
    currentOrganizationId: undefined,
    pageSize: 20,
    pageIndex: 0,
    searchText: undefined,
    filterByTags: {
        tagKey: undefined,
        tagValue: undefined,
        serviceIds: [],
        searchText: undefined,
    },
    currentBridgeId: undefined,
    organizations: manageAppsAdapters.organizations.getInitialState(),
    selectedServiceIds: [],
    selectedBundleId: undefined,
    assignableSubscriptionIds: {},
    bundleSubscribedAppIds: {},
    itemIdArray: [],
    orgIdArray: [],
    itemNames: [],
    getAppsByPagination: false,
    count: {},
};

export interface ManageAppsState {
    assetType: 'ASSET' | 'WIDGET' | 'BRIDGE_ASSET' | 'RECON' | 'DASHBOARD';
    subscriptionStatus: 'ALL' | Service['status'];
    currentOrganizationId: string;
    pageSize: number;
    pageIndex: number;
    searchText: string;
    filterByTags: {
        tagKey: string;
        tagValue: string;
        serviceIds: string[];
        searchText: string;
    };
    currentBridgeId: string;
    organizations: EntityState<ManageAppsOrganizationEntity>;
    selectedServiceIds: string[];
    selectedBundleId: string;
    assignableSubscriptionIds: {
        [property: string]: string[];
    };
    bundleSubscribedAppIds: {
        [property: string]: string[];
    };
    orgIdArray: string[];
    itemIdArray: string[];
    itemNames: string[];
    getAppsByPagination: boolean;
    count: {
        [property: string]: number;
    };
}

export interface ManageAppsOrganizationEntity {
    id: string;
    orgIdVsName?: { [property: string]: string };
    services: EntityState<Service>;
}

export interface Service {
    assetType: 'ASSET' | 'WIDGET' | 'BRIDGE_ASSET' | 'RECON' | 'DASHBOARD';
    userName: string;
    active: false;
    createdByUserId: string;
    createdDate: string;
    displayName: string;
    id: string;
    itemId: string;
    indexed: true;
    lastModifiedByUserId: string;
    lastModifiedDate: string;
    organizationId: string;
    serviceId: string;
    status: 'APPROVED' | 'PENDING' | 'PENDING_DEACTIVATE';
    description: string;
    itemName: string;
    providerId: string;
    restApiName: string;
    type: string;
    helpDocuments: { [property: string]: string }[];
    selected?: boolean;
    assetMetaUId?: string;
    bridgeNodes: {
        [property: string]: {
            componentType: 'ASSET' | 'FILE';
            displayName: string;
            id: string;
            level: number;
            name: string;
            nextNodes: string[];
            restApiName: string;
            version: string;
            helpDocuments: { [property: string]: string }[];
        };
    };
}
