<div *transloco="let translate;">
    <div class="homeAppProcessBodyContainer">
        <div class="homeAppProcessBodyMainContainer">
            <material-accordion [showFooter]="false" [showContentOnlyOnExpand]="true" [expandAccordion]="true" [disabled]="true">
                <div class="materialAccordionHeader">
                    <div class="appActionsContainer">
                        <div>
                            <span class="pointer">{{translate('Recent Process Info')}}</span>
                        </div>
                    </div>
                </div>
                <div class="materialAccordionHeaderDescription">
                    <div class="floatRight" *ngIf="loading && loading.assetMetaData">
                        <span class="appRedirectLinks loaderBlock isLoading">
                            <a href="#" target="_blank">{{translate('Process Now')}}</a>
                        </span>
                        <span class="appsLinks appLinkPipe"> | </span>
                        <span class="appRedirectLinks loaderBlock isLoading">
                            <a href="#" target="_blank">{{translate('All Processes')}}</a>
                        </span>
                        <span class="appsLinks appLinkPipe"> | </span>
                        <span class="appRedirectLinks loaderBlock isLoading">
                            <a href="#" target="_blank">{{translate('App Home')}}</a>
                        </span>
                    </div>
                    <div class="floatRight" *ngIf="!loading || !loading.assetMetaData">
                        <ng-container *ngIf="app.canCreateNewProcess">
                            <span class="appRedirectLinks loaderBlock" [class.isLoading]="loading && loading.assetMetaData" AppNewProcess [app]="app" [getDirectLink]="true" [appId]="app && app.id" [newProcessInSameTab]="newProcessInSameTab">
                                <a [routerLink]="app && [app['appNewProcessLink']]" [hidden]="newProcessInSameTab">{{translate('Process Now')}}</a>
                                <a [hidden]="!newProcessInSameTab">{{translate('Process Now')}}</a>
                            </span>
                            <span class="appsLinks appLinkPipe"> | </span>
                        </ng-container>
                        <ng-container *ngIf="app.canViewAllProcesses">
                            <ng-container *ngIf="app && app.name">
                                <span class="appRedirectLinks loaderBlock" [class.isLoading]="loading && loading.assetMetaData" AppProcessesSelection [app]="app" [getDirectLink]="true" [appId]="app && app.id" [allProcessesInSameTab]="allProcessesInSameTab">
                                    <a [routerLink]="app && [app['appProcessesLink']]" [hidden]="allProcessesInSameTab">{{translate('All Processes')}}</a>
                                    <a [hidden]="!allProcessesInSameTab">{{translate('All Processes')}}</a>
                                </span>
                            </ng-container>
                            <span class="appsLinks appLinkPipe"> | </span>
                        </ng-container>
                        <span class="appRedirectLinks loaderBlock" [class.isLoading]="loading && loading.assetMetaData" AppSelection [app]="app" [navigateToAppHomeOnly]="navigateToAppHomeOnly">{{translate('App Home')}}</span>
                    </div>
                </div>
                <div class="materialAccordionBody">
                    <app-card [app]="app" [assetData]="app.assetData" [request]="app.request" [workflowStages]="app.workflowStages" [filingAttributes]="app.filingAttributes" [loading]="loading" [instance]="app.instance" [processInSameTab]="processInSameTab"></app-card>
                </div>
            </material-accordion>
        </div>
    </div>
</div>
