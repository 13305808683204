import { Report } from '../report.interface';
import { ReportMetaData } from '../reportmetadata.interface';
import { ReportData } from './reportdata.class';

export class RecordReports {
    reports: Array<ReportData>;

    reportsMetaData: Array<ReportMetaData>;

    constructor(reportsMetaData: Array<ReportMetaData>, reports: { property?: Array<Report> } | Array<Report>, instanceId: string) {
        this.reports = [];
        this.reportsMetaData = reportsMetaData;
        reportsMetaData = reportsMetaData || [];
        reports = reports || [];
        for (let i = 0; i < reportsMetaData.length; i++) {
            this.pushReport(reportsMetaData[i], reports, instanceId);
        }
    }

    pushReport = (reportMetaData: ReportMetaData, reports: { property?: Array<Report> } | Array<Report>, instanceId) => {
        let report = {};
        if (Array.isArray(reports)) {
            for (let i = 0; i < reports.length; i++) {
                if (reports[i].chainName === reportMetaData.name) {
                    report = reports[i];
                    break;
                }
            }
        } else {
            for (const key in reports) {
                if (key === reportMetaData.name) {
                    report = reports[key] && reports[key][0];
                    break;
                }
            }
        }
        this.reports.push(new ReportData(reportMetaData, report, instanceId));
    };

    setReport = (report: Report, instanceId: string) => {
        if (!report) {
            return;
        }
        for (let i = 0; i < this.reports.length; i++) {
            if (this.reports[i].name === report.reportName) {
                this.reports[i] = new ReportData(this.reports[i], report[0], instanceId);
                break;
            }
        }
    };
}
