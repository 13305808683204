import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { GeneratedReport, GenerateReport, GenerateReportError, GetReports, LoadingReports, SetReports } from '../actions';
import { getReportsLoadingMap$, getReportsMap$, getSubscribedAppsMap$ } from '../selectors';

@Injectable()
export class ReportsEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    private getReports = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReports),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getReportsMap$),
                this.store$.select(getReportsLoadingMap$)
            ),
            mergeMap(([action, apps, reportsMap, reportsLoadingMap]) => {
                const reports = reportsMap?.[action.instanceId];
                const app = apps?.[action.serviceId];
                if (!app) {
                    return of(GetReports(action));
                }
                if ((reports?.length > 0 || reportsLoadingMap?.[action.instanceId]) && !action.forceFetch) {
                    return [];
                }
                this.store$.dispatch(LoadingReports({ instanceId: action.instanceId, loading: true }));
                return from(
                    this._api.commonReports.generateReports(
                        {},
                        [],
                        action.instanceId,
                        action.assetId || app.assetMetaUId,
                        app,
                        action.noAlerts
                    )
                ).pipe(
                    map((res: any[]) => {
                        res.forEach((resp) => {
                            resp?.chains?.sort((a, b) => {
                                const aName = a.displayName;
                                const bName = b.displayName;
                                if (aName < bName) {
                                    return -1;
                                } else if (aName > bName) {
                                    return 1;
                                }
                                return 0;
                            });
                        });
                        this.store$.dispatch(
                            SetReports({
                                instanceId: action.instanceId,
                                reports: res,
                            })
                        );
                        return LoadingReports({ instanceId: action.instanceId, loading: false });
                    }),
                    catchError((e) => of(LoadingReports({ instanceId: action.instanceId, loading: false })))
                );
            })
        )
    );

    private generateReport = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerateReport),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, apps]) => {
                const app = apps?.[action.serviceId];
                if (!app) {
                    return of(GenerateReport(action));
                }
                this.store$.dispatch(LoadingReports({ instanceId: action.instanceId, loading: true }));
                return from(
                    this._api.commonReports.generateReport({
                        assetId: app.assetMetaUId,
                        chainName: action.chainName,
                        currentService: app,
                        instanceId: action.instanceId,
                        orgNamesMap: {},
                        reports: [],
                        dontFetchReports: true,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res: any[]) => {
                        this.store$.dispatch(
                            GeneratedReport({
                                instanceId: action.instanceId,
                                serviceId: action.serviceId,
                                repositoryId: action.repositoryId,
                                chainName: action.chainName,
                                transformationName: action.transformationName,
                                timeStamp: action.timeStamp,
                            })
                        );
                        return LoadingReports({ instanceId: action.instanceId, loading: false });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(
                            GenerateReportError({ instanceId: action.instanceId, transformationName: action.transformationName })
                        );
                        return of(LoadingReports({ instanceId: action.instanceId, loading: false }));
                    })
                );
            })
        )
    );
}
