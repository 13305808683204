<div class="entityContainer py-2">
    <div class="entityContainer d-flex justify-between">
        <div class="entityNameContainer">
            {{entity?.displayName}}
        </div>
    </div>
    <div class="entityBodyContainer" *transloco="let translate;">
        <div class="table-data-container">
            <table aria-describedby="entity table" class="table-container table-auto relative w-full">
                <thead class="table-header bg-white" *ngIf="definedSectionsCount > 0">
                    <th scope="col" class="table-header-cell expand-container w-4 alternate-color-1" colspan="1" rowspan="2" *ngIf="eventType !=='view'">
                    </th>
                    <th scope="col" class="table-header-cell expand-container w-4 alternate-color-1" colspan="1" rowspan="2" *ngIf="entity?.entities?.length > 0"></th>
                    <ng-container *ngFor="let section of sections; let i = index">
                        <th scope="col" class="table-header-cell" [colSpan]="section.colSpan" [rowSpan]="section.rowSpan" [class.alternate-color-1]="((entity?.entities?.length > 0 && 1 || 0) +  i) % 2 === 0" [class.alternate-color-2]="((entity?.entities?.length > 0 && 1 || 0) +  i) % 2 === 1">
                            {{section.name}}
                        </th>
                    </ng-container>
                    <th scope="col" *ngIf="eventType !== 'view'" class="table-header-cell actions px-1" [class.alternate-color-1]="((entity?.entities?.length > 0 && 1 || 0) +  sections.length) % 2 === 0" [class.alternate-color-2]="((entity?.entities?.length > 0 && 1 || 0) +  sections.length) % 2 === 1"></th>
                </thead>
                <thead class="table-header sticky top-0 bg-white">
                    <th scope="col" class="table-header-cell expand-container alternate-color-1" *ngIf="eventType !=='view'">
                        <input type="checkbox" [(ngModel)]="selectAllRecordsButton" name="selectAllButn" (ngModelChange)="selectRecords()" class="form-checkbox text-taxillaGreen  w-6  accent-taxillaGreen hover:accent-taxillaGreen" checked>
                    </th>
                    <th scope="col" class="table-header-cell expand-container alternate-color-1" *ngIf="entity?.entities?.length > 0"></th>
                    <ng-container *ngFor="let section of sections; let i = index">
                        <ng-container *ngFor="let field of section.fields;">
                            <th scope="col" class=" table-header-cell regular-column px-1" [class.alternate-color-1]="((entity?.entities?.length > 0 && 1 || 0) +  i) % 2 === 0" [class.alternate-color-2]="((entity?.entities?.length > 0 && 1 || 0) +  i) % 2 === 1">
                                {{field.name}}
                                <ng-container *ngIf="field.isRequired">*</ng-container>
                            </th>
                        </ng-container>
                    </ng-container>
                    <th *ngIf="eventType !== 'view'" scope="col" class="table-header-cell actions px-1" [class.alternate-color-1]="((entity?.entities?.length > 0 && 1 || 0) +  sections.length) % 2 === 0" [class.alternate-color-2]="((entity?.entities?.length > 0 && 1 || 0) +  sections.length) % 2 === 1">
                        Actions
                    </th>
                </thead>
                <tbody class="table-body-container">
                    <ng-container *ngFor="let record of $any(entity.entityData); let i = index">
                        <tr [class.bg-[#ffebeb]]="record.message?.errors?.length > 0" [class.hover:bg-[#ffebeb]]="record.message?.errors?.length > 0" EntityRecordTemplate [eventType]="eventType" [sections]="sections" [record]="record" [showChildEntities]="showChildEntities[record.id || record.tempId]" [masterRecordsResponse]="masterRecordsResponse" [parentLookupsData]="parentLookupsData" [lookupsResponse]="lookupsResponse" [service]="service" (toggleShowChildEntities)="toggleChildEntities(record.id || record.tempId)" (toggleErrors)="toggleErrors(record.id || record.tempId)" (onRecordSelectToggle)="isSelectAllChecked()" (openAttachmentEvent)="openAttachmentEvent($event, record.id || record.tempId)" (openGridEvent)="openGridEvent($event, record.id || record.tempId)" (editTextAreaEvent)="editTextAreaEvent($event, record.id || record.tempId)" (removeRecord)="removeItems([record] , 'single')" [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}" (lookUpUpdated)="lookUpUpdated.emit($event)">
                        </tr>
                        <tr class="accordion" [class.expanded]="showRecordSpace[record.id || record.tempId]">
                            <td class="accordion-column px-4" [colSpan]="totalColumnsCount">
                                <ng-container *ngIf="showChildEntities[record.id || record.tempId]">
                                    <app-entities-template [instanceId]="instanceId" [eventType]="eventType" [entities]="record.entities" [eventType]="eventType" [parentMasterTablesData]="record['parentMasterTablesData']" [service]="service" [parentEntityUid]="entity.uid" [parentId]="record.id" [parentTempId]="record.tempId" [opened]="showChildEntities[record.id || record.tempId]" [masterRecordsResponse]="masterRecordsResponse" [parentLookupsData]="parentLookupsData" [lookupsResponse]="lookupsResponse" (lookUpUpdated)="lookUpUpdated.emit($event)"></app-entities-template>
                                </ng-container>
                                <ng-container *ngIf="showErrors[record.id || record.tempId] && record?.message?.errors?.length > 0">
                                    <ng-container *ngTemplateOutlet="errorsTemplate; context: { errors: record.message.errors}"></ng-container>
                                </ng-container>
                                <ng-container *ngIf="showAttachments[record.id]">
                                    <app-entity-record-field-attachment [fieldId]="showAttachments[record.id]" [eventType]="eventType" [instanceId]="instanceId" [parentId]="parentId" [record]="record" [service]="service"></app-entity-record-field-attachment>
                                </ng-container>
                                <ng-container *ngIf="showGrids[record.id || record.tempId]">
                                    <app-entity-record-field-grid [recordUpdateDisabled]="recordUpdateDisabled" [fieldId]="showGrids[record.id || record.tempId]" [record]="record" [eventType]="eventType"></app-entity-record-field-grid>
                                </ng-container>
                                <ng-container *ngIf="showTextAreas[record.id || record.tempId]">
                                    <app-rich-text-editor [id]="showTextAreas[record.id || record.tempId]" [enableEncryptDecrypt]="true" [placeholder]="textAreaField.displayName" [model]="decodeTextFieldValue(textAreaField.value)" [required]="textAreaField.isMandatory || textAreaField.isBusinessKey" [editable]="eventType !== 'view'" [errors]="textAreaField.message.errors" (modelChange)="textAreaField.value = $event"></app-rich-text-editor>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <div class="text-right py-2">
                <select class="position-relative mr-3 pageSize pr-1" *ngIf="entity.pagingState && entity?.entities?.length === 0" [(ngModel)]="selectedPageSize">
                    <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
                </select>
                <button *ngIf="entity.pagingState && entity?.entities?.length === 0"
                        [title]="translate('Load More')"
                        (click)="loadMore(entity, selectedPageSize)"
                        class="bg-transparent hover:text-taxillaGreen focus:text-taxillaGreen mr-2 pr-3">
                    <span class="cursor-pointer text-[12px]">
                        {{ translate('Load More') }}
                    </span>
                </button>
                <button [title]="translate('Remove')" *ngIf="eventType !=='view' && selectedRecordsLength > 0" (click)="removeMultipleItems()" class="bg-transparent  mr-2 pr-3" [ngClass]="{'hover:text-taxillaGreen focus:text-taxillaGreen' : selectedRecordsLength > 0}">
                    <span class="cursor-pointer text-[12px]">
                        {{translate('Remove')}}
                    </span>
                </button>
                <button *ngIf="eventType !=='view'" (click)="createNewRecord()" class="bg-transparent hover:text-taxillaGreen focus:text-taxillaGreen pr-3" [class.pointer-events-none]="!($any(entity?.entityData)?.length === 0 || (entity?.array && (!entity.maxElements || $any(entity?.entityData)?.length < entity.maxElements)))" [disabled]="!($any(entity?.entityData)?.length === 0 || (entity?.array && (!entity.maxElements || $any(entity?.entityData)?.length < entity.maxElements)))">
                    <span class="cursor-pointer text-[12px]">
                        {{translate('Create New')}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #errorsTemplate let-errors="errors">
    <div class="errorsHeader text-red" *transloco="let translate;">
        {{translate('Errors')}}
    </div>
    <div class="errorsBody">
        <div class="errorItem" *ngFor="let error of errors; let i = index;">
            {{i + 1}}. {{error}}
        </div>
    </div>
</ng-template>
