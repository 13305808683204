import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-eula',
    templateUrl: './eula.component.html',
    styleUrls: ['./eula.component.scss'],
})
export class EulaComponent implements OnInit {
    acceptEula: () => void;

    constructor(private dialog: MatDialog) {}

    closeBtnAction = () => {
        this.dialog.closeAll();
    };

    ngOnInit() {}
}
