import { Component, OnInit } from '@angular/core';
import { RootScope } from 'src/app/services/rootscope.service';
import { VersionCheckService } from 'src/app/services/version-check/version-check.service';
import { ApiService } from 'taxilla-library';

@Component({
    selector: 'app-postloginfooter',
    templateUrl: './postloginfooter.component.html',
    styleUrls: ['./postloginfooter.component.scss'],
})
export class PostLoginFooterComponent implements OnInit {
    currentDate = new Date();
    currentYear: number;
    platformVersion: any;

    constructor(public _taxilla: ApiService, public R: RootScope, public _versionCheckService: VersionCheckService) {
        this.currentYear = this.currentDate.getFullYear();
    }

    getPlatformVersion = (frequency = 1000 * 60 * 90) => {
        setInterval(() => {
            this.platformVersion = this._versionCheckService.version || '';
        }, frequency);
        this.platformVersion = this._versionCheckService.version || '';
    };

    ngOnInit() {
        setTimeout(() => {
            this.getPlatformVersion();
        }, 1000);
    }
}
