import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-angular-img',
    templateUrl: './angular-img.component.html',
    styleUrls: ['./angular-img.component.scss'],
})
export class AngularImgComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
