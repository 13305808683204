<div id="manageMaster" class="rightPaneDetailsCnt">
    <div class="masterContainer">
        <ng-container *ngIf="!masterDetails">
            <div class="userMainTableConatiner">
                <div class="settingsHeader masterDataSettings">
                    <span class="settingsNameText">{{translateMsg('Masters')}}</span>
                    <div class="settingsDataActionsContainer d-flex align-items-center">
                        <div class="outlookSearchContainer">
                            <div class="outLookSearchHolder">
                                <div class="align-items-center d-flex mainSearch masterMainSearch">
                                    <div class="mainSearchSearcher searchBorder cursorPointer d-flex align-items-center justify-content-between">
                                        <span class="material-icons searchIcon">search</span>
                                        <input matInput type="text" [placeholder]="translateMsg('Search')" [(ngModel)]="searchMasterName" (click)="searchMasterName = ''" (keyup)='searchMaster()'>
                                        <span class="material-icons searchIcon pointer" *ngIf='searchMasterName' (click)="searchMasterName = ''; searchMaster()">close</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-card class="masterCard ps position-relative w-100" style="height: calc(100vh - 118px);" [perfectScrollbar]>
                <div class="row">
                    <div class="col-12">
                        <ng-container *ngIf="allMasterArray.length !== 0">
                            <ng-container *ngFor="let master of allMasterArray | FilterPipe: 'displayName' : searchMasterName : null : 'itemName'">
                                <div class="col-4 masterTileContailer masterContainerDiv">
                                    <div class="masterTiles" (click)="navigatTo(master)">
                                        <p class="masterName">
                                            <span class="masterText" [title]="(master?.displayName || master?.itemName) | pascalToCamel">
                                                {{(master?.displayName || master?.itemName) | pascalToCamel}}
                                            </span>
                                        </p>
                                        <p class="infoContainer">
                                            <span title="{{master?.itemDescription || noDisccription}}">
                                                <mat-icon class="info" matListIcon>info</mat-icon>
                                            </span>
                                        </p>
                                        <div class="privateMaster" *ngIf="master?.organizationId === currentOrganizationId && master?.organizationId !== '111' && master?.providerId !== currentOrganizationId" [title]="translateMsg('Private Master')">
                                            <span class="material-icons">
                                                vpn_key
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="allMasterArray === undefined || allMasterArray.length === 0">
                            <ng-container *ngIf="R.loadingCall.getMasters  || R.loadingCall.getParentOrgDetails">
                                <ng-container *ngFor="let master of [1,2,3,4,5,6]">
                                    <div class="col-4 masterTileContailer">
                                        <div class="masterTiles">
                                            <p class="masterName">
                                                <span class="masterText loaderBlock isLoading">
                                                    {{translateMsg('Master Name')}}
                                                </span>
                                            </p>
                                            <p class="infoContainer">
                                                <span>
                                                    <mat-icon class="info" matListIcon>info</mat-icon>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!R.loadingCall.getMasters && !searchMasterName && !noMasterPresent">
                                <div class="centerDiv">
                                    <div class="noRecords">
                                        <div>{{translateMsg("You haven't subscribed to any apps which requires masters")}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf='searchMasterName && noMasterPresent'>
                            <div class="centerDiv">
                                <div class="noRecords">
                                    <div>{{translateMsg('There are no masters matching your search')}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </mat-card>
        </ng-container>
        <ng-container *ngIf="masterDetails">
            <app-common-masters [isFromEnComply]="true"></app-common-masters>
        </ng-container>
    </div>
</div>
