import { createReducer, on } from '@ngrx/store';

import { SetBundleId, SetBundleRoles, SetRoles, SetRoleUsersIds, SetSelectedRoleId, SetUserRoles } from '../actions';
import * as sessionActions from '../actions/session.actions';
import { RolesState, rolesState } from '../states';

export const ROLES_REDUCER = createReducer(
    rolesState,
    on(SetUserRoles, (state, action) => {
        return {
            ...state,
            assignedRolesMap: {
                ...state.assignedRolesMap,
                [action.organizationId]: {
                    ...state.assignedRolesMap?.[action.organizationId],
                    [action.userId]: action.roles.reduce((roles, role) => {
                        roles.push(role.id);
                        return roles;
                    }, [] as string[]),
                },
            },
            rolesMap: {
                ...state.rolesMap,
                ...action.roles.reduce((roles, role) => {
                    roles[role.id] = role;
                    return roles;
                }, {} as RolesState['rolesMap']),
            },
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, (state, action) => {
        return rolesState;
    }),

    on(SetRoles, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    roles: action?.roles,
                },
            },
        };
    }),

    on(SetBundleId, (state, action) => {
        return {
            ...state,
            selectedBundleId: action.bundleId,
        };
    }),

    on(SetSelectedRoleId, (state, action) => {
        return {
            ...state,
            selectedRoleId: action.roleId,
        };
    }),

    on(SetBundleRoles, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    bundleRoles: {
                        ...state?.organizationsMap?.[action?.organizationId]?.bundleRoles,
                        [action.bundleId]: action.roles,
                    },
                },
            },
        };
    }),
    on(SetRoleUsersIds, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state?.organizationsMap,
                [action.organizationId]: {
                    ...state?.organizationsMap?.[action?.organizationId],
                    roleUserIds: {
                        ...state?.organizationsMap?.[action?.organizationId]?.roleUserIds,
                        [action.roleId]: action.userIds,
                    },
                },
            },
        };
    })
);

export const ROLES_REDUCER_KEY = 'roles';
