<span *ngIf="field.show" class="gridLabelContainer" [class.fieldAttachmentContainer]="!readOnly">
    <ng-container *ngIf="!hideDisplayNameData">
        <span class="fieldAttachmentLabel requiredAttachment" *transloco="let translate">
            <div class="fieldAttachmentLabelText">
                {{field.displayName}}
            </div>
            <div class="fieldAttachmentRequiredText" [class.mat-error]="field.message.errors.length > 0 || field.message.warnings.length > 0" [class.mat-has-warning]="field.message.warnings.length > 0">
                <span class="material-symbols-outlined" *ngIf="field.message.errors.length > 0" [title]="field.message.errors?.[0]">
                    error
                </span>
                <span class="material-symbols-outlined" *ngIf="field.message.warnings.length > 0" [title]="field.message.warnings?.[0]">
                    warning
                </span>
                <span class="material-symbols-outlined" *ngIf="!(field.message.errors.length > 0 || field.message.warnings.length > 0) && (field.isMandatory || field.isBusinessKey)" [title]="'Required'">
                    priority_high
                </span>
            </div>
        </span>
    </ng-container>
    <span class="fieldAttachmentIcon" (click)="openGridModel()" *transloco="let translate">
        <mat-icon [title]="translate('Open Grid View')">grid_on</mat-icon>
    </span>
</span>
