import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PartnerActionType from '../actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
    getAllOrganizationList$,
    getCurrentOrganizationId$,
    getPartnerOrgs$,
    getSentPartners$,
    getReceivedInvitations$,
    getOrganizationPartners$,
} from '../selectors';
import * as sharedActions from '../actions/shared.actions';
import {
    AlertError,
    AlertSuccess,
    GetAllOrganizationsSuccess,
    SetInvitedPartners,
    SetReceivedInvitations,
    SetPartnerOrgs,
} from '../actions';
import { of } from 'rxjs';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ApiService } from '../../services/api/api.service';

@Injectable()
export class PartnerEffects {
    constructor(private actions$: Actions, private store$: Store, public _api: ApiService) {}

    private getAllPartners$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.GetAllPartners),
            withLatestFrom(this.store$.select(getSentPartners$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, sentPartners, organizationId]) => {
                if (sentPartners.length > 0) {
                    return of(
                        SetInvitedPartners({
                            organizationId,
                            sentPartners: CommonUtilsService.cloneObject(sentPartners),
                        })
                    );
                }
                return this._api.locations.getAllSentInvitations(action.payload).pipe(
                    map((res) => {
                        const partnerData = res.response.invitations;
                        partnerData.forEach((org) => {
                            if (org.status === undefined) {
                                org['status'] = 'PENDING';
                            }
                            org['Partner Name'] = org.partnerName || org.partnerRef;
                        });
                        const sentPartnersCopy = CommonUtilsService.cloneObject(partnerData);
                        return SetInvitedPartners({
                            organizationId,
                            sentPartners: sentPartnersCopy,
                        });
                    }),
                    catchError((errorResponse) => {
                        return of(AlertError({ message: errorResponse }));
                    })
                );
            })
        );
    });

    private getReceivedInvitations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.GetReceivedInvitations),
            withLatestFrom(this.store$.select(getReceivedInvitations$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, receivedInvitations, organizationId]) => {
                if (receivedInvitations.length > 0) {
                    return of(
                        SetReceivedInvitations({
                            organizationId,
                            receivedInvitations: CommonUtilsService.cloneObject(receivedInvitations),
                        })
                    );
                }
                return this._api.locations.getReceivedPartners(action.payload).pipe(
                    map((res) => {
                        const partnerData = res.response.invitations;
                        partnerData?.forEach((org) => {
                            if (org.status === undefined) {
                                org['status'] = 'PENDING';
                            }
                            org['Partner Name'] = org.organizationName;
                        });
                        return SetReceivedInvitations({
                            organizationId,
                            receivedInvitations: partnerData,
                        });
                    }),
                    catchError((errorResponse) => {
                        return of(AlertError({ message: errorResponse }));
                    })
                );
            })
        );
    });

    private sendPartnerInvitations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.SendPartnerInvitation),
            withLatestFrom(this.store$.select(getSentPartners$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, sentPartners, organizationId]) => {
                return this._api.locations.sendPartnerInvitation(action.data).pipe(
                    map((res) => {
                        this.store$.dispatch(AlertSuccess({ message: res.msg || '' }));
                        let sentPartnersCopy = CommonUtilsService.cloneObject(sentPartners);
                        res?.response?.invitations?.forEach((inv: any) => {
                            if (inv.status === undefined) {
                                inv['status'] = 'PENDING';
                            }
                            inv['Partner Name'] = inv.partnerName || inv.partnerRef;
                            sentPartnersCopy.push(inv);
                        });

                        this.store$.dispatch(SetInvitedPartners({ organizationId, sentPartners: sentPartnersCopy }));
                        return PartnerActionType.SendPartnerInvitationSuccess();
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private resendPartnerInvitation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.ResendPartnerInvitation),
            mergeMap((action) => {
                return this._api.locations.resendPartnerInvitation(action.data).pipe(
                    map((res) => {
                        return AlertSuccess({ message: res.msg || '' });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private PartnerInvitationAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.PartnerInvitationAction),
            withLatestFrom(this.store$.select(getReceivedInvitations$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, receivedInvitations, organizationId]) => {
                return this._api.locations.approvePartner(action.data).pipe(
                    map((res) => {
                        this.store$.dispatch(AlertSuccess({ message: res.msg || '' }));
                        let receivedInvitationsCopy = CommonUtilsService.cloneObject(receivedInvitations);
                        receivedInvitationsCopy.forEach((invitation) => {
                            if (action.data.type === 'APPROVE' && invitation.id === action.data.partnerOrganizationId) {
                                invitation.status = 'APPROVED';
                            } else if (action.data.type === 'REJECT' && invitation.id === action.data.partnerOrganizationId) {
                                invitation.status = 'REJECTED';
                            }
                        });
                        return SetReceivedInvitations({ organizationId, receivedInvitations: receivedInvitationsCopy });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private DeletePartnerInvitation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.DeletePartnerInvitation),
            withLatestFrom(this.store$.select(getSentPartners$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, sentPartners, organizationId]) => {
                return this._api.locations.deletePartnerInvitation(action.data).pipe(
                    map((res) => {
                        this.store$.dispatch(AlertSuccess({ message: res.msg || '' }));
                        let sentPartnersCopy = CommonUtilsService.cloneObject(
                            sentPartners.filter((invitation) => invitation.id !== action.data.partnerOrganizationId)
                        );

                        return SetInvitedPartners({ organizationId, sentPartners: sentPartnersCopy });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private GetAllOrganizations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.GetAllOrganizations),
            withLatestFrom(this.store$.select(getAllOrganizationList$)),
            mergeMap(([action, allOrganizationsList]) => {
                if (allOrganizationsList.length > 0) {
                    return of(
                        GetAllOrganizationsSuccess({
                            allOrganizationsList: CommonUtilsService.cloneObject(allOrganizationsList),
                        })
                    );
                }
                return this._api.subscriptions.searchAllOrganizations(action.data).pipe(
                    map((res) => {
                        return GetAllOrganizationsSuccess({ allOrganizationsList: res });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private GetPartnerOrgs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.GetPartnerOrgs),
            withLatestFrom(this.store$.select(getPartnerOrgs$)),
            mergeMap(([action, partnerOrgs]) => {
                if (partnerOrgs.length > 0) {
                    return of(
                        SetPartnerOrgs({
                            partnerOrgs: CommonUtilsService.cloneObject(partnerOrgs),
                        })
                    );
                }
                return this._api.locations.getPartnerOrgs().pipe(
                    map((res) => {
                        return SetPartnerOrgs({ partnerOrgs: res.partnerOrgs });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private UpdatePartnerExternalId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.UpdatePartnerExternalId),
            withLatestFrom(this.store$.select(getPartnerOrgs$)),
            mergeMap(([action, partnerOrgs]) => {
                return this._api.locations.updatePartner(action.data).pipe(
                    map((res) => {
                        this.store$.dispatch(AlertSuccess({ message: res.msg || '' }));
                        let partnerOrgsCopy = CommonUtilsService.cloneObject(partnerOrgs);
                        const selectedPartner = partnerOrgsCopy.find((org) => org.partnerOrgId === action.data.partnerOrganizationId);
                        selectedPartner.partnerExternalId = action.data.partnerExternalId;
                        return SetPartnerOrgs({ partnerOrgs: partnerOrgsCopy });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private GetOrganizationPartners$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PartnerActionType.GetOrganizationPartners),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getOrganizationPartners$)),
            mergeMap(([action, organizationId, orgPartners]) => {
                if (orgPartners?.length > 0) {
                    return of(
                        PartnerActionType.SetOrganizationPartners({
                            organizationId,
                            partners: CommonUtilsService.cloneObject(orgPartners),
                        })
                    );
                }
                return this._api.locations.getPartners(organizationId).pipe(
                    map((res) => {
                        return PartnerActionType.SetOrganizationPartners({
                            organizationId,
                            partners: res?.partnerOrgs,
                        });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });
}
