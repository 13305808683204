import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { translate, TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BridgeNode } from 'taxilla-library';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { CollabConfiguration } from '../../models/collab-configuration.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ConfirmationDialogService } from '../../services/confirmation/confirmation-dialog.service';
import { UtilsService } from '../../services/utils/utils.service';
import { getServicesByFilterWithoutPagination$ } from '../../store/index.store';

@Component({
    selector: 'lib-app-collaboration',
    templateUrl: './app-collab.component.html',
    styleUrls: ['./app-collab.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class AppCollaborationComponent implements OnInit, OnDestroy {
    @Input() selectedApp: BehaviorSubject<AssetService> = new BehaviorSubject(undefined);
    @Input() allSubscribedApps: BehaviorSubject<AssetService[]>;
    @Input() containerHeightReduction: string;
    @Input() showAllAppsList: boolean;
    @Input() showOnlySelected: boolean;
    @Input() hideHeader: boolean;
    @Input() bundleId: string;
    @Input() componentType: string = '';

    @Output() closeDrawer = new EventEmitter();
    @Output() deletedCollaborationConfig = new EventEmitter();

    public assetDetails;
    public collabConfig: CollabConfiguration = {
        isAutoInvite: false,
        isEnabled: false,
        partnerAppSettings: [],
        serviceId: undefined,
    };
    private selectedAssetName = '';
    public participantAssets = [];
    private selectedAssetVersion = '';
    public selectedBridgeAssetVersion = '';
    private selectedAssetid = '';
    public selectedVersion = '';
    public selectedBridgeAssetName = '';
    public taskDetails = [];
    public workflowLoadStatus = {
        loading: false,
    };
    public eventErrors = {
        isAutoInvite: [],
        isEnabled: [],
        partnerAppSettings: [
            {
                partnerFunction: [],
                partnerField: [],
                targetAssetId: [],
                collaborationActions: [],
                changeAllowedStages: [],
                isAutoInvite: [],
                isEnabled: [],
            },
        ],
    };
    public targetApp = [
        {
            name: '',
            versions: [],
            version: '',
        },
    ];
    public primaryEntityFields = [];
    public currentAppWorkflowStageData = {};
    private currentServiceMetaData: any;
    public isEdit: boolean;
    private unsubscribe = new Subject<void>();
    public filterAppName = '';
    public selectedBridge: BehaviorSubject<AssetService> = new BehaviorSubject(undefined);
    public services: AssetService[] = [];
    public isMenuItemVisible = [];

    constructor(
        public _utils: UtilsService,
        public _taxilla: ApiService,
        private _libUtils: UtilsService,
        private confirmationService: ConfirmationDialogService,
        private _translate: TranslocoService,
        private _commonUtils: CommonUtilsService,
        private store$: Store
    ) {}

    private getAssetWithVersion = () => {
        this.workflowLoadStatus.loading = true;
        const data = {
            serviceId: this.selectedApp.value.serviceId,
        };
        this._taxilla.assets.getAssetWithVersion(data, {
            successCallback: (res) => {
                this.workflowLoadStatus.loading = false;
                this.assetDetails = res.serviceMetadata;
                res.serviceMetadata.bridgeAssets.forEach((bridgeAsset) => {
                    bridgeAsset.participantAssets.forEach((pAsset) => {
                        for (const [key, value] of Object.entries(res.uidVsDisplayName)) {
                            if (key === pAsset.uid) {
                                pAsset['displayName'] = value;
                            }
                        }
                    });
                });
                if (res.serviceMetadata.assets.length < 1 && res.serviceMetadata.bridgeAssets.length < 0) {
                    this._libUtils.alertError(translate("This Asset doesn't have Version"));
                } else if (res.serviceMetadata.assets.length > 0) {
                    this.onChangeOfAssetVersion(res.serviceMetadata.assets[res.serviceMetadata.assets.length - 1]);
                    this.getCurrentServiceMetaData();
                }
            },
            failureCallback: (res) => {
                this.workflowLoadStatus.loading = false;
                this._libUtils.alertError(translate("This Asset doesn't have Version"));
            },
        });
    };

    public onChangeOfAssetVersion = (asset) => {
        this.getWorkflowStages(asset);
        this.getCollabConfig(asset);
    };

    public changeAutoInviteStatus = (settingIndex, status) => {
        if (settingIndex === 'global') {
            this.collabConfig.isAutoInvite = status;
        } else {
            this.collabConfig.partnerAppSettings[settingIndex].isAutoInvite = status;
        }
    };

    public changeEnabledStatus = (settingIndex, status) => {
        if (settingIndex === 'global') {
            this.collabConfig.isEnabled = status;
        } else {
            this.collabConfig.partnerAppSettings[settingIndex].isEnabled = status;
        }
    };

    public getWorkflowStages = (asset) => {
        this.selectedVersion = asset.version;
        this.selectedAssetName = asset.name;
        this.selectedBridgeAssetName = asset.name;
        this.selectedAssetVersion = asset.version;
        this.selectedAssetid = asset.uid;
        const data = {
            assetName: this.selectedAssetName,
            assetVersion: this.selectedAssetVersion,
            assetUid: this.selectedAssetid,
        };
        this._taxilla.workflow.getWorkflowStages(data, {
            successCallback: (res) => {
                this.taskDetails = res['taskDetails'];
                this.taskDetails.forEach((task) => {
                    this.currentAppWorkflowStageData[task.taskDefinitionKey] = task.supportedActions
                        ? Object.keys(task.supportedActions)
                        : [];
                });
            },
        });
    };

    private getCollabConfig = async (app: AssetData) => {
        if (this.bundleId) {
            const appCollabCOnfig = (await this._taxilla.collaboration.getBundleCollabConfig(this.bundleId, app.uid))?.response;
            this.isEdit = appCollabCOnfig?.id !== undefined;
            this.transformCollaborationConfiguration(appCollabCOnfig || new CollabConfiguration({}));
            return;
        }
        const data = {
            assetId: app.uid,
        };
        this._taxilla.collaboration.getCollabConfig(data, {
            successCallback: (res) => {
                if (res?.response) {
                    this.isEdit = !res.response?.partnerAppSettings?.[0]?.partnerFunction !== undefined;
                    this.transformCollaborationConfiguration(res?.response);
                } else {
                    this.createEmptyCollabConfig();
                }
            },
            failureCallback: (res) => {
                this._libUtils.alertError(res?.msg || translate('There was a problem getting collaboration configuration'));
            },
        });
    };

    private transformCollaborationConfiguration = (config) => {
        const collabConfig = CommonUtilsService.cloneObject(config);
        this.collabConfig = new CollabConfiguration(collabConfig);
        const collabLength = this.collabConfig.partnerAppSettings.length;
        if (collabLength) {
            this.targetApp.splice(0);
            for (let i = 0; i < collabLength; i++) {
                this.targetApp.push({
                    name: '',
                    versions: [],
                    version: '',
                });
            }
            this.collabConfig.partnerAppSettings.forEach((setting, index) => {
                const targetServiceId = setting.targetServiceId;
                const targetAssetId = setting.targetAssetId;
                const app = targetServiceId && this.getAppWithServiceId(targetServiceId);
                let versions = [];
                let version = '';
                let verApp;
                if (app) {
                    const data = {
                        serviceId: targetServiceId,
                    };
                    this._taxilla.assets.getAssetWithVersion(data, {
                        successCallback: (res) => {
                            if (res.serviceMetadata.assets.length < 1 && res.serviceMetadata.bridgeAssets.length < 0) {
                                // this._libUtils.alertError('This Asset doesn\'t have Version.');
                                versions = [];
                            } else if (res.serviceMetadata.assets.length > 0) {
                                versions = res.serviceMetadata.assets;
                                verApp = versions.find((vApp) => vApp.uid === targetAssetId);
                                version = verApp?.version;
                            }
                            this.targetApp[index]['name'] = app.displayName || app.name;
                            this.targetApp[index]['versions'] = versions;
                            this.targetApp[index]['version'] = verApp?.uid;
                            this.checkIfCollabActionsObjectExists();
                        },
                        failureCallback: (res) => {
                            versions = [];
                            this.targetApp[index]['name'] = app.displayName || app.name;
                            this.targetApp[index]['versions'] = versions;
                            this.targetApp[index]['version'] = version;
                        },
                    });
                } else {
                    this.checkIfCollabActionsObjectExists();
                }
            });
        }
    };

    private checkIfCollabActionsObjectExists = () => {
        this.collabConfig.partnerAppSettings.forEach((partner, index) => {
            Object.keys(partner.collaborationActions).length === 0 && this.addNewStageActions(index);
        });
    };

    public getParticipantAssets = (bridgeLink) => {
        if (bridgeLink === undefined || bridgeLink === '') {
            this.participantAssets = [];
        }
        this.selectedBridgeAssetVersion = bridgeLink.version;
        this.participantAssets = bridgeLink.participantAssets;
        this.selectedBridgeAssetName = this.participantAssets[0].name;
        const asset = this.participantAssets[0];
        this.onChangeOfAssetVersion(asset);
    };

    private getCurrentServiceMetaData = () => {
        const app = this.selectedApp.value;
        this._taxilla.assets.getAssetMetadata(
            {
                assetMetaUId: app.assetMetaUId || app.id,
                name: app.displayName || app.name,
            },
            {
                successCallback: (response) => {
                    this.currentServiceMetaData = new AssetData(response);
                    const primaryEntity = this.currentServiceMetaData.getPrimaryEntity();
                    this.primaryEntityFields = primaryEntity && primaryEntity.fields;
                },
            }
        );
    };

    private validateConfig = () => {
        let errorExists = false;
        this.collabConfig.partnerAppSettings.forEach((settings, i) => {
            if (this.eventErrors.partnerAppSettings[i]) {
                this.eventErrors.partnerAppSettings[i].partnerFunction = [];
            }
            if (!this._utils.checkValueExists(settings.partnerFunction)) {
                this.eventErrors.partnerAppSettings[i].partnerFunction.push('Partner function is required');
                this._libUtils.alertError(translate('Fix errors to proceed'));
                errorExists = true;
            }
        });
        return errorExists;
    };

    createConfig = () => {
        if (this.validateConfig()) {
            return;
        }
        this.collabConfig.partnerAppSettings.forEach((config) => {
            Object.keys(config.collaborationActions).forEach((key) => {
                if (key === undefined || key === 'undefined') {
                    delete config.collaborationActions[key];
                }
            });
        });
        const data = {
            assetId: this.selectedAssetid,
            config: this.collabConfig,
        };
        !data.config.serviceId && (data.config.serviceId = this.selectedApp?.value?.serviceId);
        if (this.bundleId) {
            this._taxilla.collaboration.updateBundleCollaboration(this.bundleId, this.selectedAssetid, this.collabConfig).then((res) => {
                this.isEdit = true;
                this._libUtils.alertSuccess(res?.msg || translate('Created collaboration configuration successfully'));
            });
            return;
        }
        this._taxilla.collaboration.createCollabConfig(data, {
            successCallback: (res) => {
                this.isEdit = true;
                this._libUtils.alertSuccess(res?.msg || translate('Created collaboration configuration successfully'));
                this.closeDrawer.emit(this.selectedApp.value);
            },
            failureCallback: (res) => {
                this._libUtils.alertError(res?.msg || translate('There was a problem creating collaboration configuration.'));
            },
        });
    };

    public deleteConfig = () => {
        const confirmConfig = {
            title: 'Confirmation',
            message: translate('Are you sure you want to delete configuration') as string,
            btnOkText: 'Ok',
            btnCancelText: 'Cancel',
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    if (this.bundleId) {
                        this._taxilla.collaboration.deleteBundleCollabConfig(this.bundleId, this.selectedAssetid).then((res) => {
                            this._libUtils.alertSuccess(translate('Collaboration configuration deleted successfully'));
                            this.createEmptyCollabConfig();
                        });
                        return;
                    }
                    const data = {
                        assetId: this.selectedAssetid,
                    };
                    this._taxilla.collaboration.deleteCollabConfig(data, {
                        successCallback: (res) => {
                            this._libUtils.alertSuccess(translate('Collaboration configuration deleted successfully'));
                            this.createEmptyCollabConfig();
                            this.deletedCollaborationConfig.emit(this.selectedApp.value);
                        },
                        failureCallback: (res) => {
                            this._libUtils.alertError(translate('Something went wrong'));
                        },
                    });
                }
            });
    };

    public fetchVersionsOfApp = (settingIndex, appName) => {
        const app = this.services?.find((app) => app.displayName === appName || app.name === appName);
        if (!app || typeof app === 'string') {
            this.targetApp[settingIndex].versions = [];
            this.collabConfig.partnerAppSettings[settingIndex].targetAssetId = '';
            this.collabConfig.partnerAppSettings[settingIndex].targetServiceId = '';
            return;
        }
        this.collabConfig.partnerAppSettings[settingIndex].targetServiceId = app['serviceId'];
        this.collabConfig.partnerAppSettings[settingIndex].targetAssetId = '';
        const data = {
            serviceId: app['serviceId'],
        };
        this._taxilla.assets.getAssetWithVersion(data, {
            successCallback: (res) => {
                if (res.serviceMetadata.assets.length === 0 && res.serviceMetadata.bridgeAssets.length === 0) {
                    this.targetApp[settingIndex].versions = [];
                    this.targetApp[settingIndex].version = '';
                    this._libUtils.alertError("This Asset doesn't have Version.");
                } else if (res.serviceMetadata.assets.length > 0) {
                    const versions = res.serviceMetadata.assets;
                    this.targetApp[settingIndex].versions = versions;
                    if (this.targetApp[settingIndex].version) {
                        const appWithVersion = versions.find((version) => version.uid === this.targetApp[settingIndex].version);
                        if (!appWithVersion) {
                            this.targetApp[settingIndex].version = versions[0].uid;
                            this.collabConfig.partnerAppSettings[settingIndex].targetAssetId = versions[0].uid;
                        }
                    } else {
                        this.targetApp[settingIndex].version = versions[0].uid;
                        this.collabConfig.partnerAppSettings[settingIndex].targetAssetId = versions[0].uid;
                    }
                }
            },
            failureCallback: (res) => {
                this.targetApp[settingIndex].versions = [];
                this.targetApp[settingIndex].version = '';
                this._libUtils.alertError("This Asset doesn't have Version.");
            },
        });
    };

    public onTargetAppVersionChange = (settingIndex, version) => {
        if (typeof version === 'string') {
            this.collabConfig.partnerAppSettings[settingIndex].targetAssetId = version;
        } else {
            this.collabConfig.partnerAppSettings[settingIndex].targetAssetId = version?.uid;
        }
    };

    public onCollabStageChange = (settingIndex, stageIndex, stageKey) => {
        const stageActionObj = this.collabConfig.partnerAppSettings[settingIndex].collaborationActions;
        const stages = Object.keys(stageActionObj);
        // if (stages[stageIndex].indexOf("temp") === 0) {
        delete stageActionObj[stages[stageIndex]];
        stageActionObj[stageKey] = [];
        // }
    };

    public onCollabStageActionChange = (settingIndex, stageIndex, action, stage) => {
        this.collabConfig.partnerAppSettings[settingIndex].collaborationActions[stage.key] = action;
    };

    private onChangeAllowedStageChange = (stage) => {
        this.collabConfig.partnerAppSettings[0].changeAllowedStages.push(stage);
    };

    public trackByIndexMethod(index: number, item: any): any {
        return index;
    }

    public addNewPartnerAppSetting = () => {
        const partnerAppSetting = {
            partnerFunction: '',
            partnerField: '',
            targetAssetId: '',
            targetServiceId: '',
            collaborationActions: {},
            changeAllowedStages: [],
            isAutoInvite: false,
            isEnabled: false,
        };
        this.collabConfig.partnerAppSettings.push(partnerAppSetting);
        const errorPartnerAppSettings = {
            partnerFunction: [],
            partnerField: [],
            targetAssetId: [],
            collaborationActions: [],
            changeAllowedStages: [],
            isAutoInvite: [],
            isEnabled: [],
        };
        this.eventErrors.partnerAppSettings.push(errorPartnerAppSettings);
        this.addNewStageActions(this.collabConfig.partnerAppSettings.length - 1);

        this.targetApp.push({
            name: '',
            versions: [],
            version: '',
        });
    };

    public removePartnerAppSetting = (index) => {
        this.collabConfig.partnerAppSettings.splice(index, 1);
        this.eventErrors.partnerAppSettings.splice(index, 1);
        this.targetApp.splice(index, 1);
    };

    public addNewStageActions = (settingIndex) => {
        const rand = this._libUtils.guid();
        this.collabConfig.partnerAppSettings[settingIndex].collaborationActions['temp' + rand] = [];
    };

    public removeStageActions = (settingIndex, stageKey) => {
        delete this.collabConfig.partnerAppSettings[settingIndex].collaborationActions[stageKey];
    };

    private createEmptyCollabConfig = () => {
        this.isEdit = false;
        this.collabConfig = new CollabConfiguration({});
        this.collabConfig.serviceId = this.selectedApp?.value?.serviceId;
        this.addNewStageActions(this.collabConfig.partnerAppSettings.length - 1);
        this.targetApp = [
            {
                name: '',
                versions: [],
                version: '',
            },
        ];
    };

    private getAppWithServiceId = (serviceId) => {
        const app = this.services.find((app) => app.serviceId === serviceId);
        return app;
    };

    public fetchChildApps = (app: AssetService) => {
        if (app?.assets?.length > 0) {
            return;
        }
        this._taxilla.subscriptions.servicesUnderlyingBridge(app?.assetMetaUId, {
            successCallback: (bridgeNodes) => {
                this.arrangeAssetsInBridgeAsset(app, bridgeNodes as any);
            },
            failureCallback: (res) => {
                this._libUtils.alertError((res && res.msg) || 'Failed to get bridge nodes');
            },
        });
    };

    private arrangeAssetsInBridgeAsset = (bridgeAsset: AssetService, bridgeNodes: Map<string, BridgeNode>) => {
        bridgeAsset.assets = [];
        const sortedBridges = this._utils.sortBridgeAssets(bridgeNodes);
        sortedBridges.forEach((node) => {
            let service = this.services.find((_service) => _service.assetMetaUId === node.id);
            if (service) {
            } else {
                service = new AssetService(node);
            }
            service.nextNodes = node.nextNodes;
            this.pushFilesToReports(service, sortedBridges);
            bridgeAsset.assets.push(new AssetService(service));
        });
    };

    private pushFilesToReports = (service: AssetService, nodes: BridgeNode[]) => {
        service.reports = service.reports || [];
        service.nextNodes.forEach((serviceNode) => {
            const nextNode = nodes.find((node) => node.id === serviceNode);
            nextNode?.componentType === 'FILE' && service.reports.push(nextNode);
        });
    };

    public setAsSelectedApp = (app: AssetService, bridge?: AssetService) => {
        this.selectedBridge.next(bridge);
        this.selectedApp.next(app);
        this.eventErrors.partnerAppSettings.forEach((settings) => {
            settings.partnerFunction = [];
        });
    };

    public clearSearchAppName = () => {
        this.filterAppName = '';
    };

    async ngOnInit() {
        this.assetDetails = {
            name: '',
            assets: [],
            bridgeAssets: [],
            type: '',
        };
        if (this.bundleId?.length > 0) {
            this.store$
                .select(getServicesByFilterWithoutPagination$)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((services) => {
                    const assetServices = services?.filter((appservices) => appservices.assetType === 'ASSET');
                    this.services = CommonUtilsService.cloneObject(assetServices);
                });
        } else {
            this.allSubscribedApps.pipe(takeUntil(this.unsubscribe)).subscribe((services) => {
                this.services = services?.filter((service) => service.assetType === 'ASSET');
            });
        }
        this.selectedApp.pipe(takeUntil(this.unsubscribe)).subscribe((app) => {
            if (app?.restApiName?.length > 0) {
                this.getAssetWithVersion();
            }
        });
        this.createEmptyCollabConfig();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
