<div *transloco="let translate;" appFileDragDetector>
    <div class=" d-flex justify-content-between align-items-center matDialogBorderBottom">
        <h4 class="matDialogTitle" mat-dialog-title>{{translate('Digital Signature')}}</h4>
        <span class="material-icons mb-2 masterCloseBtnIcon" mat-dialog-close>close</span>
    </div>
    <mat-dialog-content class="mat-typography matDialogBody">
        <div class="digitalSignDiv">
            <div class="row dscLabelRow">
                <div class="col-4 labelDiv">
                    <span>{{translate('Digital Signature')}} :</span>
                    <span class="userDigSigFileName" *ngIf="userDscFileName" title="{{userDscFileName}}">{{userDscFileName}}</span>
                </div>
                <div class="col-4 labelDiv">
                    <span>{{translate('Signature Image')}}:</span>
                    <span class="userDigSigFileName" *ngIf="userDscImgName" title="{{userDscImgName}}">{{userDscImgName}}</span>
                </div>
                <div class="col-4 labelDiv">
                    <material-input-password [autofocus]="false" autocomplete="off" (modelChange)="passPhrase = $event" [placeholder]="translate('Passphrase')" [disabled]="isPassphraseDisabled" [name]="translate('Passphrase')" [required]="true" [errors]="errors.passphrase"></material-input-password>
                </div>
            </div>
            <div class="row dscUploadBtnRow inlineDnd">
                <div class="col-4 dscBrowseBtn">
                    <div title="{{fileName}}">
                        <div class="btn" (click)="initBrowse()">
                            <fileUpload [color]="'default'" [data]="userDscUpload" [supportedFormats]="supportedFileTypes" (newValue)="newFileValue($event)" (fileName)="fileName = $event" (isFileTypeSupported)="onFileTypeSupportCheck($event)">
                            </fileUpload>
                        </div>
                        <mat-form-field class="uploadFileName">
                            <input matInput [(ngModel)]="fileName" [disabled]="true">
                        </mat-form-field>
                    </div>
                    <div></div>
                </div>
                <div class="col-4 dscBrowseBtn">
                    <div title="{{fileName2}}">
                        <div class="btn1" (click)="initBrowse()">
                            <fileUpload [color]="'default'" [data]="userDscUpload2" [supportedFormats]="supportedImageTypes" (newValue)="newImageValue($event)" (fileName)="fileName2 = $event" (isFileTypeSupported)="onImageTypeSupportCheck($event)">
                            </fileUpload>
                        </div>
                        <mat-form-field class="uploadFileName">
                            <input matInput [(ngModel)]="fileName2" [disabled]="true">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="errorMsg">
                <div class="fileTypeSupport" *ngIf="!isFileTypeSupported">
                    <span class="col-xs-12">{{translate('Please select')}} {{supportedFileTypes}} {{translate('file format to upload')}}</span>
                </div>
                <div class="fileTypeSupport" *ngIf="!isImageTypeSupported">
                    <span class="col-xs-12">{{translate('Please select')}} {{supportedImageTypes}} {{translate('image format to upload')}}</span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="matDialogFooter">
        <material-button class="mx-2 noOutline" [label]="translate('Cancel')" color="default" (onTrigger)="closeModal()"></material-button>
        <material-button class="noOutline submitColor chngBtn" [disabled]="!passPhrase || isPassphraseDisabled" [label]="translate('Upload')" color="primary" [title]="translate('Upload')" (onTrigger)="uploadUserDsc()">
        </material-button>
    </mat-dialog-actions>
</div>
