import { createAction, props } from '@ngrx/store';

import { MastersResponseRecord } from '../../interface/master-records-response.interface';
import { ChangeLog } from '../../interface/record-change-log.interface';
import { MasterTables } from '../../models/masters/mastertables.class';
import { SearchFilter } from '../../models/searchfilter.class';

export enum MasterActionTypes {
    GET_MASTERS = '[GET_MASTERS] Get Masters',
    GET_MASTERS_SUCCESS = '[GET_MASTERS_SUCCESS] Get Masters Success',
    SET_MASTER_TABLE = '[SET_MASTER_TABLE] Set Master Table',
    SET_PAGINATION = '[SET_PAGINATION] Set Pagination',
    SET_SELECTED_MASTER_ID = '[SET_SELECTED_MASTER_ID] Set Selected Master Id',
    SET_SELECTED_MASTER = '[SET_SELECTED_MASTER] Set Selected Master',
    GET_MASTER_METADATA = '[GET_MASTER_METADATA] Get Master Meatadata',
    SET_MASTER_METADATA = '[SET_MASTER_METADATA] Set Master Metadata',
    GET_MASTER_LANGUAGE_TEMPLATE = '[GET_MASTER_LANGUAGE_TEMPLATE] Get Master Language Template',
    SET_MASTER_LANGUAGE_TEMPLATE = '[SET_MASTER_LANGUAGE_TEMPLATE] Set Master Language Template',
    GET_MASTER_RECORDS = '[GET_MASTER_RECORDS] Get Master Records',
    SET_MASTER_RECORDS = '[SET_MASTER_RECORDS] Set Master Records',
    SET_RECORDS_LOADING = '[SET_RECORDS_LOADING] Set Records Loading',
    SET_MASTER_PAGINATION = '[SET_MASTER_PAGINATION] Set Master Pagination',
    GET_SEARCH_MASTER_RECORDS = '[GET_SEARCH_MASTER_RECORDS] Get Search Master Records',
    GET_SEARCH_MASTER_RECORDS_COUNT = '[GET_SEARCH_MASTER_RECORDS] Get Search Master Records Count',
    SET_SEARCHED_RECORDS_COUNT = '[SET_SEARCHED_RECORDS_COUNT] Set Searched Records Count',
    SET_IS_FROM_SEARCH = '[SET_IS_FROM_SEARCH] Set Is From Search',
    GET_CUSTOM_FILTERS = '[GET_CUSTOM_FILTERS] Get Custom Filters',
    SET_CUSTOM_FILTERS = '[SET_CUSTOM_FILTERS] Set Custom Filters',
    CREATE_RECORD = '[CREATE_RECORD] Create Record',
    UPDATE_MASTER_RECORD = '[UPDATE_MASTER_RECORD] Update Master Record',
    UPDATE_MASTER_RECORD_SUCCESS = '[UPDATE_MASTER_RECORD_SUCCESS] Update Master Record success',
    DELETE_MASTER_RECORDS = '[DELETE_MASTER_RECORDS] Delete Master Records',
    DELETE_MASTER_RECORDS_SUCCESS = '[DELETE_MASTER_RECORDS_SUCCESS] Delete Master Records Success',
    CRUD_OPERATION_RECORD = '[CREATE_OR_UPDATE_RECORD] Create Or Update Record',
    GET_PARENT_PRIMARY_KEYS = '[GET_PARENT_PRIMARY_KEYS] Get Parent Primary Keys',
    SET_PARENT_PRIMARY_KEYS = '[SET_PARENT_PRIMARY_KEYS] Set Parent Primary Keys',
    SET_PARENT_TABLE_ID = '[SET_PARENT_TABLE_ID] Set Parent Table Id',
    SET_PARENT_TABLE_REST_API_NAME = '[SET_PARENT_TABLE_ID] Set Parent Table Rest API Name',
    GET_PARENT_MASTER_RECORDS = '[GET_PARENT_MASTER_RECORDS] Get Parent Master Records',
    GET_MASETERS_INBOUND_MAPPINGS = '[GET_MASETERS_INBOUND_MAPPINGS] Get Masters Inbound Mappings',
    SET_MASETERS_INBOUND_MAPPINGS = '[SET_MASETERS_INBOUND_MAPPINGS] Set Masters Inbound Mappings',
    GET_MASTER_CHAIN_DISPLAY_NAMES = '[GET_MASTER_CHAIN_DISPLAY_NAMES] Get Master Chain Display Names',
    SET_MASTER_CHAIN_DISPLAY_NAMES = '[SET_MASTER_CHAIN_DISPLAY_NAMES] Set Master Chain Display Names',
    GET_MASTER_REQUESTS = '[GET_MASTER_REQUESTS] Get Master Requests',
    SET_MASTER_REQUESTS = '[SET_MASTER_REQUESTS] Set Master Requests',
    SET_MASTER_REQUESTS_PAGINATION = '[SET_MASTER_REQUESTS_PAGINATION] Set Master Requests Pagination',
    GET_MASETERS_OUTBOUND_MAPPINGS = '[GET_MASETERS_OUTBOUND_MAPPINGS] Get Masters Outbound Mappings',
    SET_MASETERS_OUTBOUND_MAPPINGS = '[SET_MASETERS_OUTBOUND_MAPPINGS] Set Masters Outbound Mappings',
    SAVE_SEARCH_CONFIGURATION = '[SAVE_SEARCH_CONFIGURATION] Save Search Configuration',
    SET_SEARCH_FILTER = '[SET_SEARCH_FILTER] Set Search Filter',
    DELETE_SEARCH_CONFIGURATION = '[DELETE_SEARCH_CONFIGURATION] Delete Search Configuration',
    GET_REPORT_DETAILS = '[GET_REPORT_DETAILS] Get Report Details',
    SET_REPORT_DETAILS = '[SET_REPORT_DETAILS] Set Report Details',
    GENERATE_MASTER_REPORT = '[GENERATE_MASTER_REPORT] Generate Master Report',
    GENERATE_MASTER_REPORT_SUCCESS = '[GENERATE_MASTER_REPORT_SUCCESS] Generate Master Report Success',
    GENERATE_MASTER_REPORT_FAILURE = '[GENERATE_MASTER_REPORT_FAILURE] Generate Master Report Failure',
    GET_MASTER_UPLOAD_FILES = '[GET_MASTER_UPLOAD_FILES] Get Master Upload Files',
    SET_MASTER_UPLOAD_FILES = '[SET_MASTER_UPLOAD_FILES] Set Master Upload Files',
    GET_MASTER_RECORD_CHANGELOGS = '[GET_MASTER_RECORD_CHANGELOGS] Get Master Record Change Logs',
    SET_MASTER_RECORD_CHANGELOGS = '[SET_MASTER_RECORD_CHANGELOGS] Set Master Record Change Logs',
    DELETE_PARENT_RELATED_CHILD_RECORDS = '[DELETE_PARENT_RELATED_CHILD_RECORDS] Delete Parent Related Child Records',
}

export const GetMasters = createAction(MasterActionTypes.GET_MASTERS);

export const GetMastersSuccess = createAction(
    MasterActionTypes.GET_MASTERS_SUCCESS,
    props<{
        masters: MasterTables[];
        organizationId: string;
    }>()
);

export const SetMasterTable = createAction(
    MasterActionTypes.SET_MASTER_TABLE,
    props<{
        tableId: string;
        restApiName: string;
    }>()
);

export const GetMasterMetadata = createAction(
    MasterActionTypes.GET_MASTER_METADATA,
    props<{
        organizationId: string;
        tableId: string;
        isParent: boolean;
    }>()
);

export const SetMasterMetadata = createAction(
    MasterActionTypes.SET_MASTER_METADATA,
    props<{
        metaData: any;
        organizationId: string;
    }>()
);

export const GetMasterLanguageTemplate = createAction(
    MasterActionTypes.GET_MASTER_LANGUAGE_TEMPLATE,
    props<{
        organizationId: string;
        tableId: string;
    }>()
);

export const SetMasterLanguageTemplate = createAction(
    MasterActionTypes.SET_MASTER_LANGUAGE_TEMPLATE,
    props<{
        masterLanguageTemplate: {};
        organizationId: string;
        restApiName: string;
    }>()
);

export const GetMasterRecords = createAction(
    MasterActionTypes.GET_MASTER_RECORDS,
    props<{
        size: number;
        pageIndex: number;
        pagingState: any;
    }>()
);

export const SetMasterRecords = createAction(
    MasterActionTypes.SET_MASTER_RECORDS,
    props<{
        records: MastersResponseRecord[];
        organizationId: string;
        restApiName: string;
        isClearSearch?: boolean;
    }>()
);

export const SetMasterRecordPagination = createAction(
    MasterActionTypes.SET_MASTER_PAGINATION,
    props<{
        pageSize: number;
        pageIndex: number;
        pageState?: string;
        organizationId: string;
        restApiName: string;
        providerOrgId?: string;
        search?: {
            queryString?: string;
            sort?: {};
            searchParent?: boolean;
            searchAfter?: any;
        };
        isUpdate?: boolean;
    }>()
);

export const GetSearchedMasterRecords = createAction(MasterActionTypes.GET_SEARCH_MASTER_RECORDS);

export const GetSearchedMasterRecordsCount = createAction(MasterActionTypes.GET_SEARCH_MASTER_RECORDS_COUNT);

export const SetSearchedRecordsCount = createAction(
    MasterActionTypes.SET_SEARCHED_RECORDS_COUNT,
    props<{
        searchRecordsCount: number;
    }>()
);

export const setRecordsLoading = createAction(
    MasterActionTypes.SET_RECORDS_LOADING,
    props<{
        loading: boolean;
    }>()
);

export const GetCustomFilters = createAction(MasterActionTypes.GET_CUSTOM_FILTERS);

export const SetCustomFilters = createAction(
    MasterActionTypes.SET_CUSTOM_FILTERS,
    props<{
        searchFilters: SearchFilter[];
        organizationId: string;
        restApiName: string;
    }>()
);

export const CreateRecord = createAction(
    MasterActionTypes.CREATE_RECORD,
    props<{
        data: any;
    }>()
);

export const UpdateMasterRecord = createAction(
    MasterActionTypes.UPDATE_MASTER_RECORD,
    props<{
        data: any;
        recordId: string;
    }>()
);
export const UpdateMasterRecordSuccess = createAction(
    MasterActionTypes.UPDATE_MASTER_RECORD_SUCCESS,
    props<{
        record: any;
        organizationId: string;
        restApiName: string;
    }>()
);

export const DeleteMasterRecords = createAction(
    MasterActionTypes.DELETE_MASTER_RECORDS,
    props<{
        data: any;
        selectedRecordIds: string[];
    }>()
);

export const DeleteParentRelatedChildRecords = createAction(
    MasterActionTypes.DELETE_PARENT_RELATED_CHILD_RECORDS,
    props<{
        tableId: string;
        orgId: string;
    }>()
);

export const DeleteMasterRecordsSuccess = createAction(
    MasterActionTypes.DELETE_MASTER_RECORDS_SUCCESS,
    props<{
        recordIds: string[];
        organizationId: string;
        restApiName: string;
    }>()
);

export const CRUDOperationRecord = createAction(
    MasterActionTypes.CRUD_OPERATION_RECORD,
    props<{
        actionType: 'CREATE' | 'UPDATE' | 'DELETE';
    }>()
);

export const GetParentPrimaryKeys = createAction(
    MasterActionTypes.GET_PARENT_PRIMARY_KEYS,
    props<{
        organizationId: string;
        tableId: string;
    }>()
);

export const SetParentPrimaryKeys = createAction(
    MasterActionTypes.SET_PARENT_PRIMARY_KEYS,
    props<{
        organizationId: string;
        tableId: string;
        primaryKeys: any[];
    }>()
);

export const SetParentTableId = createAction(
    MasterActionTypes.SET_PARENT_TABLE_ID,
    props<{
        parentTableId: string;
    }>()
);

export const SetParentTableRestApiName = createAction(
    MasterActionTypes.SET_PARENT_TABLE_REST_API_NAME,
    props<{
        parentTableRestApiName: string;
    }>()
);

export const GetParentMasterRecords = createAction(
    MasterActionTypes.GET_PARENT_MASTER_RECORDS,
    props<{
        restApiName: string;
        size: number;
        pagingState: any;
        noAlerts?: boolean;
    }>()
);

export const GetMastersInboundMappings = createAction(
    MasterActionTypes.GET_MASETERS_INBOUND_MAPPINGS,
    props<{
        masterTableId: string;
    }>()
);

export const SetMastersInboundMappings = createAction(
    MasterActionTypes.SET_MASETERS_INBOUND_MAPPINGS,
    props<{
        organizationId: string;
        tableId: string;
        inboundMappings: any;
    }>()
);

export const GetMasterChainDisplayNames = createAction(
    MasterActionTypes.GET_MASTER_CHAIN_DISPLAY_NAMES,
    props<{
        restAPIName: string;
    }>()
);

export const SetMasterChainDisplayNames = createAction(
    MasterActionTypes.SET_MASTER_CHAIN_DISPLAY_NAMES,
    props<{
        organizationId: string;
        restAPIName: string;
        chainDisplayNames: any;
    }>()
);

export const SetMasterRequestsPagination = createAction(
    MasterActionTypes.SET_MASTER_REQUESTS_PAGINATION,
    props<{
        pageSize: number;
        pageIndex: number;
        pageState: string;
        organizationId?: string;
        restApiName?: string;
        timeRange?: any[];
        isUpdate?: boolean;
    }>()
);

export const GetMasterRequests = createAction(
    MasterActionTypes.GET_MASTER_REQUESTS,
    props<{
        restApiName: string;
        size: number;
        pageIndex: number;
        previousPagingState: string;
        timeRange: any[];
    }>()
);

export const SetMasterRequests = createAction(
    MasterActionTypes.SET_MASTER_REQUESTS,
    props<{
        requests: any[];
        organizationId: string;
        restApiName: string;
        isClearSearch?: boolean;
    }>()
);

export const GetMastersOutboundMappings = createAction(
    MasterActionTypes.GET_MASETERS_OUTBOUND_MAPPINGS,
    props<{
        masterTableId: string;
    }>()
);

export const SetMastersOutboundMappings = createAction(
    MasterActionTypes.SET_MASETERS_OUTBOUND_MAPPINGS,
    props<{
        organizationId: string;
        tableId: string;
        outboundMappings: any;
    }>()
);

export const SaveSearchConfiguration = createAction(
    MasterActionTypes.SAVE_SEARCH_CONFIGURATION,
    props<{
        objectId: string;
        criteria: string;
        filterName: string;
        id?: string;
    }>()
);

export const SavedSearchFilter = createAction(
    MasterActionTypes.SET_SEARCH_FILTER,
    props<{
        filterObject: any;
    }>()
);

export const DeleteSearchConfiguration = createAction(
    MasterActionTypes.DELETE_SEARCH_CONFIGURATION,
    props<{
        id: string;
    }>()
);

export const GetReportDetails = createAction(
    MasterActionTypes.GET_REPORT_DETAILS,
    props<{
        tableId: string;
        isFromGenerate: boolean;
    }>()
);

export const SetReportDetails = createAction(
    MasterActionTypes.SET_REPORT_DETAILS,
    props<{
        organizationId: string;
        tableId: string;
        reportDetails: any;
    }>()
);

export const GenerateMasterReport = createAction(
    MasterActionTypes.GENERATE_MASTER_REPORT,
    props<{
        data: {
            transformationName: string;
            restApiName: string;
        };
    }>()
);

export const GenerateMasterReportSuccess = createAction(MasterActionTypes.GENERATE_MASTER_REPORT_SUCCESS);

export const GenerateMasterReportFailure = createAction(
    MasterActionTypes.GENERATE_MASTER_REPORT_FAILURE,
    props<{
        msg: any;
        transformationName: string;
    }>()
);
export const GetMasterUploadFiles = createAction(MasterActionTypes.GET_MASTER_UPLOAD_FILES);

export const SetMasterUploadFiles = createAction(
    MasterActionTypes.SET_MASTER_UPLOAD_FILES,
    props<{
        organizationId: string;
        tableId: string;
        uploadedFiles: any;
    }>()
);

export const GetMasterRecordChangeLogs = createAction(
    MasterActionTypes.GET_MASTER_RECORD_CHANGELOGS,
    props<{
        keyvalues: any;
    }>()
);

export const SetMasterRecordChangeLogs = createAction(
    MasterActionTypes.SET_MASTER_RECORD_CHANGELOGS,
    props<{
        changeLogs: ChangeLog[];
    }>()
);
