import { Injectable } from '@angular/core';

import { AnalyticsService } from '../analytics/analytics.service';
import { AssetsService } from '../assets/assets.service';
import { CollaborationService } from '../collaboration/collaboration.service';
import { CommonService } from '../common/common.service';
import { CommonReportsService } from '../commonreports/common-reports.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { EntityService } from '../entity/entity.service';
import { EventsubscriptionService } from '../events/eventsubscription.service';
import { IdentityprovidersService } from '../identityproviders/identityproviders.service';
import { InstancesService } from '../instances/instances.service';
import { IntegrationsService } from '../integrations/integrations.service';
import { LocationsService } from '../locations/locations.service';
import { LookupsService } from '../lookups/lookups.service';
import { MastersService } from '../masters/masters.service';
import { NotificationsService } from '../notifications/notifications.service';
import { PermissionsService } from '../permissions/permissions.service';
import { RecordsService } from '../records/records.service';
import { ReportsService } from '../reports/reports.service';
import { RequestsService } from '../requests/requests.service';
import { RolesService } from '../roles/roles.service';
import { SessionService } from '../session/session.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { TagsService } from '../tags/tags.service';
import { TransformationsService } from '../transformations/transformations.service';
import { UsersService } from '../users/users.service';
import { ValidationsService } from '../validations/validations.service';
import { WorkflowService } from '../workflow/workflow.service';
import { WorkqueService } from '../workque/workque.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(
        private _records: RecordsService,
        private _validations: ValidationsService,
        private _workflow: WorkflowService,
        private _entity: EntityService,
        private _session: SessionService,
        private _users: UsersService,
        private _roles: RolesService,
        private _permissions: PermissionsService,
        private _masters: MastersService,
        private _lookups: LookupsService,
        private _instance: InstancesService,
        private _requests: RequestsService,
        private _locations: LocationsService,
        private _assets: AssetsService,
        private _subscriptions: SubscriptionsService,
        private _common: CommonService,
        private _reports: ReportsService,
        private _integrations: IntegrationsService,
        private _transformations: TransformationsService,
        private _workQ: WorkqueService,
        private _events: EventsubscriptionService,
        private _dashboard: DashboardService,
        private _identityProviders: IdentityprovidersService,
        private _collabService: CollaborationService,
        private _commonReports: CommonReportsService,
        private _tags: TagsService,
        private _analytics: AnalyticsService,
        private _notifications: NotificationsService
    ) {}

    /**
     * Creating objects that exposes all the exposable functions
     */

    /**
     * exposing record operations
     */
    records = {
        /**
         * Method to submit a record
         * @param data Map of properties to be send to process record
         * @param callbacks Map of callbacks for each scenario
         */
        submitRecord: this._records.submitRecord,
        /**
         * Method to save records
         * @param data Map of properties to be send to process record
         */
        saveRecords: this._records.saveRecords,
        /**
         * Method to update records
         * @param data Map of properties to be send to process record
         */
        updateRecordsViaPromise: this._records.updateRecordsViaPromise,
        /**
         * Method to submit multiple records
         * @param data Map of properties to be send to process record
         * @param callbacks Map of callbacks for each scenario
         */
        updateRecords: this._records.updateRecords,
        /**
         * Method to validate a record includes system and custom validations
         */
        validateRecord: this._validations.hasValidData,
        /**
         * Method to get record
         */
        getRecord: this._records.getRecord,
        /**
         * Method to get record
         */
        getRecordViaPromise: this._records.getRecordViaPromise,
        /**
         * Method to get records
         */
        getRecords: this._records.getRecords,
        /**
         * Method to get records
         */
        getRecordsPromise: this._records.getRecordsPromise,
        /**
         * Method to get errors present in records
         */
        getErrorInRecords: this._records.getErrorInRecords,
        /**
         * Method to delete record
         */
        deleteRecord: this._records.deleteRecord,
        /**
         * Method to delete record
         */
        deleteRecords: this._records.deleteRecords,
        /**
         * Method to fetch logs details
         */
        fetchAuditTrails: this._records.fetchAuditTrails,
        /**
         * Method to download audit trail zip file
         */
        downloadIntegrationAuditTrail: this._records.downloadIntegrationAuditTrail,
        /**
         * Method to get records in an entity by audit trail log
         */
        getEntityRecordsByAuditLog: this._records.getEntityRecordsByAuditLog,
        /**
         * Method to delete field attachment url
         */
        deleteFieldAttachment: this._records.deleteFieldAttachment,
        /**
         * Method to download logs details
         */
        getAuditChanges: this._records.getAuditChanges,
        /**
         * Method to download logs details
         */
        getAuditRecordChangeLog: this._records.getAuditRecordChangeLog,
        /**
         * Method to get record change logs
         */
        getRecordChangeLogs: this._records.getRecordChangeLog,
        /**
         * Method to get record change logs
         */
        getRecordsBasedOnFilterType: this._records.getRecordsBasedOnFilterType,
        /**
         * Method to get instances by sending entityId
         */
        searchEntityRecords: this._records.searchEntityRecords,

        searchEntityRecordsObservable: this._records.searchEntityRecordsObservable,
        /**
         * Method to apply field calculations
         */
        applyFieldCalculations: this._validations.applyFieldCalculations,
    };

    /**
     * exposing workflow operations
     */
    workflow = {
        /**
         * Method to get workflow rules
         */
        getCurrentWorkflow: this._workflow.getCurrentWorkflow,

        /**
         * Method to get workflow rules
         */
        getAllWorkflowStages: this._workflow.getAllWorkflowStages,

        /**
         * Method to execute workflow
         */
        executeWorkflow: this._workflow.executeWorkflow,

        /**
         * Method to execute generate workflow processes
         */
        // generateWorkflowProcesses: this._workflow.generateWorkflowProcesses,
        /**
         * Method to get getWorkflowStages
         */
        getWorkflowStages: this._workflow.getWorkflowStages,
        /**
         * Method to update Workflow
         */
        updateWorkflow: this._workflow.updateWorkflow,
    };

    /**
     * Exposing methods related to entities
     */
    entities = {
        /**
         * Method to submit entity data
         */
        submitEntitiesData: this._entity.submitEntitiesData,
    };

    /**
     * Pre login methods
     */
    session = {
        /**
         * submission method for a login
         */
        submitLoginDetails: this._session.login,

        /**
         * Method to get session details
         */
        getSessionDetails: this._session.getSessionDetails,

        /**
         * method for a setting password
         */
        setPassword: this._session.setPassword,

        /**
         * method for a forgot password
         */
        forgotPassword: this._session.forgotPassword,

        /**
         * method for a forgot username
         */
        forgotUsername: this._session.forgotUsername,

        /**
         * method for a reset password
         */
        resetPassword: this._session.resetPassword,

        /**
         * method for fetching captcha
         */
        fetchCaptcha: this._session.fetchCaptcha,

        /**
         * method for register
         */
        register: this._session.register,

        /**
         * method to check whether gstin is nic registred or not
         */
        isGstinNicRegistred: this._session.isGstinNicRegistred,

        /**
         * method to update password
         */
        updatePassword: this._session.updatePassword,

        /**
         * method to get conext details with auth key
         */
        getContextDetailsWithAuthorizationKey: this._session.getContextDetailsWithAuthorizationKey,
        /**
         * method to logout
         */
        logout: this._session.logout,

        /**
         * method to get security questions
         */
        getSecurityQuestions: this._session.getSecurityQuestions,

        /**
         * method to get random security questions
         */
        getRandomSecurityQuestions: this._session.getRandomSecurityQuestions,

        /**
         * method to evaluate security questions
         */
        evaluateSecurityQuestions: this._session.evaluateSecurityQuestions,

        /**
         * method to get application properties
         */
        getApplicationProperties: this._session.getApplicationProperties,
        /**
         * method to get application properties from integration
         */
        getApplicationPropertiesFromIntegration: this._session.getApplicationPropertiesFromIntegration,

        /**
         * method to get application misc properties
         */
        getApplicationMiscProps: this._session.getApplicationMiscProps,

        /**
         * method to get application misc properties
         */
        getUserMiscProps: this._session.getUserMiscProps,

        /**
         * method to get provider
         */
        getProvider: this._session.getProvider,

        /**
         * Method to authorize code
         */
        authorizeCode: this._session.authorizeCode,

        /**
         * Method to validate code
         */
        validateCode: this._session.validateCode,

        /**
         * Method to authenticate token
         */
        authenticateToken: this._session.authenticateToken,

        /**
         * Method to get client details by domain
         */
        getClientDetailsByDomain: this._session.getClientDetailsByDomain,

        /**
         * Method to authorize code
         */
        authorizeExternalIP: this._session.authorizeExternalIP,

        /**
         * Method to validate code for external IP
         */
        validateCodeExternally: this._session.validateCodeExternally,

        /**
         * Method to get google authenticator secret key
         */
        getGoogleAuthenticatorSecretKey: this._session.getGoogleAuthenticatorSecretKey,

        /**
         * Method to get Google Authenticator QR Code
         */
        getGoogleAuthenticatorQRCode: this._session.getGoogleAuthenticatorQRCode,

        /**
         * Method to set google authenticator secret key
         */
        setGoogleAuthenticatorSecretKey: this._session.setGoogleAuthenticatorSecretKey,

        /**
         * Method to submit google authenticator code
         */
        submitGoogleAuthentication: this._session.submitGoogleAuthentication,

        /**
         * Method to generate email OTP Code
         */
        generateEmailOTP: this._session.generateEmailOTP,

        /**
         * Method to evaluate email OTP code
         */
        evaluateEmailOTP: this._session.evaluateEmailOTP,

        /**
         * Method to submit LDAP client details
         */
        submitLDAPDetails: this._session.submitLDAPDetails,
    };

    /**
     * Organization methods
     */
    organization = {
        /**
         * Method to get organization hierarchy
         */
        getOrganizationHierarchy: this._locations.getOrganizationHierarchy,

        /**
         * Method to get organization child hierarchy
         */
        getOrganizationChildHierarchy: this._locations.getOrganizationChildHierarchy,

        /**
         * Method to get count of provider organizations
         */
        getCountOfProviderOrganizations: this._locations.getCountOfProviderOrganizations,

        /**
         * Method to get provider organizations dropdownlist
         */
        providerOrganizationsDropdownList: this._locations.providerOrganizationsDropdownList,
    };

    /**
     * user methods
     */
    user = {
        /**
         * method to get users
         */
        getUsers: this._users.getUsers,

        /**
         * method to add a user
         */
        addUser: this._users.addUser,

        /**
         * method to update user details
         */
        updateUserDetails: this._users.updateUserDetails,

        /**
         * method to activate user
         */
        activateUser: this._users.activateUser,

        /**
         * method to deactivate user
         */
        deactivateUser: this._users.deactivateUser,

        /**
         * method to check if userId already exists
         */
        validateUserId: this._users.validateUserId,
        /**
         * method to check if emailId already exists
         */
        validateEmailId: this._users.validateEmailId,

        /**
         * method to get roles before adding a user
         */
        getUserRoles: this._users.getUserRoles,

        /**
         * method to get roles before adding a user
         */
        getUserRolesPromise: this._users.getUserRolesPromise,

        /**
         * method to get users by passing organazation ID
         */
        getOrgUsers: this._users.getOrgUsers,

        /**
         * method to get users by passing organazation ID
         */
        getOrgUsersObservable: this._users.getOrgUsersObservable,

        /**
         * method to get users by using pagination
         */
        getUsersByPagination: this._users.getUsersByPagination,

        /**
         * method to get users count
         */
        getUsersCount: this._users.getUsersCount,

        /**
         * method to reset user password
         */
        reserUserPassword: this._users.reserUserPassword,

        /**
         * method to get users without having user management permission
         */
        getAllUsers: this._users.getAllUsers,
        getResetPasswordUsers: this._users.getResetPasswordUsers,
        /**
         * method to get user details
         */
        getCurrentUserDetails: this._users.getCurrentUserDetails,
        getUserDetailsById: this._users.getUserDetailsById,
        getUserDetails: this._users.getUserDetails,
        /**
         * method to get digital signature
         */
        getDigitalSignatureDetails: this._users.getDigitalSignatureDetails,
        /**
         * method to get user details
         */
        getSupportedRegions: this._users.getSupportedRegions,

        /**
         * method to reset user password
         */
        resetUserPassword: this._users.resetUserPassword,

        /**
         * method for a unlock password
         */
        unlockPassword: this._users.unlockPassword,
    };

    /**
     * exposed roles method
     */
    roles = {
        /**
         * Method to get roles
         */
        getRoles: this._roles.getRoles,

        /**
         * Method to get all permissions
         */
        getAllPermissions: this._roles.getAllPermissions,

        /**
         * Method to get role permissions
         */
        getRolePermissions: this._roles.getRolePermissions,

        /**
         * Method to update role
         */
        updateRole: this._roles.updateRole,

        /**
         * Method for role users
         */
        roleUsers: this._roles.roleUsers,

        /**
         * Method for copy role
         */
        copyRole: this._roles.copyRole,

        /**
         * Method to get Roles By OrganizationId
         */
        getRolesByOrganizationId: this._roles.getRolesByOrganizationId,

        /**
         * Method to assign users
         */
        assignUsers: this._roles.assignUsers,

        /**
         * Method to delete role
         */
        deleteRole: this._roles.deleteRole,

        /**
         * Method to create role
         */
        createRole: this._roles.createRole,

        /**
         * Method to update role data
         */
        updateRoleData: this._roles.updateRoleData,

        /**
         * Method to get role permissions
         */
        getRequestRolePermissions: this._roles.getRequestRolePermissions,

        /**
         * Method to get role permissions
         */
        getRequestRolePermissionsNew: this._roles.getRequestRolePermissionsNew,

        /**
         * Method to update role without permissions
         */
        updateRoleWithoutPermissions: this._roles.updateRoleWithoutPermissions,
        /**
         * Method to get bundle roles
         */
        getBundleRoles: this._roles.getBundleRoles,
        /**
         * Method to get bundle role permissions
         */
        getBundlePermissions: this._roles.getBundlePermissions,
        /**
         * Method to create role
         */
        createBundleRole: this._roles.createBundleRole,
        /**
         * Method to update role
         */
        updateBundleRole: this._roles.updateBundleRole,
        /**
         * Method to delete bundle role
         */
        deleteBundleRole: this._roles.deleteBundleRole,
        /**
         * Method to get bundle role
         */
        getBundleRolePermissions: this._roles.getBundleRolePermissions,
        /**
         * Method to update role permissions
         */
        updateBundleRolePermissions: this._roles.updateBundleRolePermissions,
    };

    /**
     * exposed permissions method
     */
    permissions = this._permissions;

    /**
     * Exposed masters methods
     */
    masters = {
        /**
         * Method to get all masters
         */
        getAllMasters: this._masters.getAllMasters,

        /**
         * Method to get Masters meta data
         */
        getMetaData: this._masters.getMetaData,

        /**
         * Method to edit Masters Records
         */
        editRecords: this._masters.updateRecord,
        /**
         * Method to add Masters Records
         */
        addRecords: this._masters.addRecord,

        /**
         * Method to search master table
         */
        searchMasters: this._masters.searchMasters,

        /**
         * Method to get total count of search master table
         */
        getSearchMasterRecordsCount: this._masters.getSearchMasterRecordsCount,

        /**
         * Method to get getParentPrimaryKeys
         */
        getParentPrimaryKeys: this._masters.getParentPrimaryKeys,

        /**
         * Method to check master records
         */
        checkMasterRecord: this._masters.checkMasterRecord,

        /**
         * Method to Delete Master records
         */
        deleteMastersRecord: this._masters.deleteMastersRecords,

        /**
         * Method to get primary column values of a master table
         */
        getPrimaryValuesInMasterTable: this._masters.getPrimaryValuesInMasterTable,

        /**
         * Method to get master record with primary value
         */
        getMasterRecordWithprimaryValue: this._masters.getMasterRecordWithprimaryValue,

        /**
         * Method to get master requests
         */
        getMasterRequests: this._masters.getMasterRequests,

        /**
         * Method to check master record
         */
        validateRecord: this._validations.hasValidMasterRecordData,
    };

    /**
     * Exposed lookup methods
     */
    lookups = {
        /**
         * Method to get lookup values
         */
        fetchLookupValuesByEntityField: this._lookups.fetchLookupValuesByEntityField,

        /**
         * Method to get lookup values
         */
        fetchLookupValuesByField: this._lookups.fetchLookupValuesByField,

        /**
         * Method to get lookup values
         */
        fetchLookupValuesByRecordField: this._lookups.fetchLookupValuesByRecordField,

        /**
         * Method to get lookup values
         */
        searchLookupValuesByRecordField: this._lookups.searchLookupValuesByRecordField,

        /**
         * Method to get multiple lookups values
         */
        fetchMultipleLookupValues: this._lookups.fetchMultipleLookupValues,
    };

    /**
     * Exposing instance process methods
     */
    instances = {
        /**
         * Method to check instance status
         */
        checkInstanceStatus: this._instance.getInstanceStatus,
        /**
         * Method to get analytic report names
         */
        getAnalyticReportsMetaData: this._instance.getAnalyticReportsMetaData,
        /**
         * Method to generate report
         */
        generateReport: this._instance.generateReport,
        /**
         * Process incomplete instance
         */
        processInstance: this._instance.processInstance,
        /**
         * Method to get instances by sending requestId and status (optional)
         */
        getInstanceByRequestId: this._instance.getInstanceByRequestId,
        /**
         * Method to create instance from bridge
         */
        createInstanceFromBridge: this._instance.createInstanceFromBridge,

        /**
         * Method to save search configuration
         */
        saveSearchConfiguration: this._instance.saveSearchConfiguration,

        /**
         * Method to get search configuration
         */
        getSearchConfiguration: this._instance.getSearchConfiguration,

        /**
         * Method to delete search configuration
         */
        deleteSearchConfiguration: this._instance.deleteSearchConfiguration,

        /**
         * Method to get default search filters
         */
        getDefaultSearchFilters: this._instance.getDefaultSearchFilters,

        /**
         * Method to fetch instances based on default search filters
         */
        fetchInstancesBasedOnDefaultFilters: this._instance.fetchInstancesBasedOnDefaultFilters,
        /**
         * Method to search instance
         */
        searchInstance: this._instance.searchInstance,
        /**
         * Method to fetch reports instance
         */
        getAnalyticReports: this._instance.getAnalyticReports,
        /**
         * Method to fetch reports instance
         */
        getAnalyticReportsWithNames: this._instance.getAnalyticReportsWithNames,
        /**
         * Method to fetch reports details
         */
        fetchReports: this._instance.fetchReports,
        /**
         * Method to get  generatedReports details
         */
        getGeneratedReports: this._instance.getGeneratedReports,
        /**
         * Method to get instances
         */
        getInstances: this._instance.getInstances,
        /**
         * Method to get instances
         */
        getInstance: this._instance.getInstance,
        /**
         * Method to get Integration Status
         */
        getIntegrationStatusForInstance: this._instance.getIntegrationStatusForInstance,
        /**
         * Method to get Integration Refresh Instance Status
         */
        getIntegrationRefreshStatusForInstance: this._instance.getIntegrationRefreshStatusForInstance,
        /**
         * Method to get Integration Refresh Request Status
         */
        getIntegrationRefreshStatusForRequest: this._instance.getIntegrationRefreshStatusForRequest,
        /**
         * Method to get search Error Reports
         */
        searchErrorReports: this._instance.searchErrorReports,
        /**
         * Method to search instances with serviceID
         */
        searchInstances: this._instance.searchInstances,
        /**
         * Method to get transformation chain names
         */
        getTransformationChains: this._instance.getTransformationChains,
        /**
         * Method to update request
         */
        updateCurrentRequest: this._instance.updateCurrentRequest,
        /**
         * Method to get instances
         */
        getIncompleteInstances: this._instance.getIncompleteInstances,
        /**
         * Method to get pending instances
         */
        getPendingInstance: this._instance.getPendingInstances,
        /**
         * Method to download logs details
         */
        downloadAuditLogs: this._instance.downloadAuditLogs,
        /**
         * Method to cancel Instances
         */
        cancelInstances: this._instance.cancelInstances,
        /**
         * Method to cancel Instances
         */
        cancelInstancesViaPromise: this._instance.cancelInstancesViaPromise,
        /**
         * Method to get generated audit reports
         */
        getGeneratedAuditReports: this._instance.getGeneratedAuditReports,
        /**
         * Method to get audit report metadata
         */
        getAuditReportMetadata: this._instance.getAuditReportMetadata,
        /**
         * Method to generate audit report
         */
        generateAuditReport: this._instance.generateAuditReport,
        /**
         * Method to create download request
         */
        createDownloadRequest: this._instance.createDownloadRequest,
        /**
         * Method to get widget data
         */
        getWidgetCoordinates: this._instance.getWidgetCoordinates,
        /**
         * Method to update widget data
         */
        updateWidgetCoordinates: this._instance.updateWidgetCoordinates,
        /**
         * Method to delete widget data
         */
        deleteWidgetCoordinates: this._instance.deleteWidgetCoordinates,

        /**
         * Method to get instance errors
         */
        getInstanceState: this._instance.getInstanceState,

        /**
         * Method to get reconciliation processes
         */
        getReconciliationProcesses: this._instance.getReconciliationProcesses,
        /**
         * Method to get reconciliation processes
         */
        getReconRequestGroupCount: this._instance.getReconRequestGroupCount,
    };

    /**
     * Exposing requests methods
     */
    requests = {
        /**
         * Method to submit business keys
         */
        submitBusinessKeys: this._requests.submitBusinessKeys,
        /**
         * Method to create new request
         */
        createNewRequest: this._requests.createNewRequest,
        /**
         * Method to create new reconciliation
         */
        createNewReconciliation: this._requests.createNewReconciliation,
        /**
         * Method to create new recon template
         */
        createNewReconTemplate: this._requests.createNewReconTemplate,
        /**
         * Method to get all recon templates
         */
        getAllReconTemplates: this._requests.getAllReconTemplates,
        /**
         * Method to delete recon template
         */
        deleteReconTemplate: this._requests.deleteReconTemplate,
        /**
         * Method to get requests by sending assetName and serviceId
         */
        getRequests: this._requests.getRequests,
        /**
         * Method to get reconciliation requests
         */
        getReconciliationRequests: this._requests.getReconciliationRequests,
        /**
         * Method to get Request filing attributes
         */
        getFilingAttributes: this._requests.getFilingAttributes,
        /**
         * Method to get Request level errors
         */
        getRequestLevelErrors: this._requests.getRequestLevelErrors,
        /**
         * Method to get Request index data
         */
        getRequestIndexData: this._requests.getRequestIndexData,
        /**
         * Method to get Request index status
         */
        getRequestIndexStatus: this._requests.getRequestIndexStatus,
        /**
         * Method to download new uploaded files
         */
        downloadNewUploadedFiles: this._requests.downloadNewUploadedFiles,
        /**
         * Method to get Integration Status
         */
        getIntegrationStatus: this._requests.getIntegrationStatus,
        /**
         * Method to search requests
         */
        searchRequests: this._requests.searchRequests,
        /**
         * Method to get request state using requestId
         */
        getRequestWithRequestId: this._requests.getRequestWithRequestId,
        /**
         * Method to get requests by instanceIds
         */
        getRequestsByInstanceId: this._requests.getRequestsByInstanceId,
        /**
         * Method to get status based processes
         */
        getStatusBasedProcesses: this._requests.getStatusBasedProcesses,
        /**
         * Method to get status based procesesses with requestId
         */
        getStatusIdBasedProcesses: this._requests.getStatusIdBasedProcesses,
        /**
         * Method to generate processConsignorReport
         */
        processConsignorReport: this._requests.processConsignorReport,
        /**
         * Method to get report attributes
         */
        getReportAttributes: this._reports.getFilingAttributes,
        /**
         * Method to cancel request
         */
        cancelRequest: this._requests.cancelRequest,
        /**
         * Method to get request info
         */
        getRequestInfo: this._requests.getRequestInfo,
        /**
         * Method to get collaboration request info
         */
        getCollaborationRequestInfo: this._requests.getCollaborationRequestInfo,
        /**
         * Method to get refresh wrapper request
         */
        refreshWrapperRequest: this._requests.refreshWrapperRequest,
        /**
         * Method to Reconciliation Request Info
         */
        getReconciliationRequestInfo: this._requests.getReconciliationRequestInfo,
        /**
         * Method to Reconciliation Request State
         */
        getReconciliationState: this._requests.getReconciliationState,
        /**
         * Method to load reconciliation log data
         */
        getReconciliationLogs: this._requests.getReconciliationLogs,
        /**
         * Method to get reconciliation reports
         */
        getReconciliationReports: this._reports.getReconciliationReports,
        /**
         * Method to get generated reconciliation reports
         */
        getGeneratedReconciliationReports: this._reports.getGeneratedReconciliationReports,
    };

    /**
     * Exposing location methods
     */
    locations = {
        /**
         * Method to switch to organization
         */
        switchToOrganization: this._locations.switchToOrganization,
        /**
         * Method to search locations with org ID
         */
        searchLocationsWithOrgId: this._locations.searchOrganizationsWithOrgId,
        /**
         * Method to get locations
         */
        getLocations: this._locations.getLocations,
        /**
         * Method to add location
         */
        addLocation: this._locations.addLocation,
        /**
         * Method to update organization details
         */
        updateOrganizationDetails: this._locations.updateOrganizationDetails,
        /**
         * Method to upload organization logo
         */
        uploadOrganizationLogo: this._locations.uploadOrganizationLogo,
        /**
         * Method to delete organization logo
         */
        deleteOrganizationLogo: this._locations.deleteOrganizationLogo,
        /**
         * Method to get organization logo
         */
        getOrganizationLogo: this._locations.getOrganizationLogo,
        /**
         * Method to get organization details
         */
        getOrganizationDetailsData: this._locations.getOrganizationDetailsData,
        /**
         * Method to get activate locations
         */
        activateLocations: this._locations.activateLocations,
        /**
         * Method to get deactivate locations
         */
        deactivateLocations: this._locations.deactivateLocations,
        /**
         * Method to get all organizations
         */
        getOrganizations: this._locations.getOrganizations,
        /**
         * Method to get all organizations
         */
        getOrganizationsPromise: this._locations.getOrganizationsPromise,
        /**
         * Method to get parent organization list
         */
        getParentOrgDetails: this._locations.getParentOrgDetails,
        /**
         * Method to get partners
         */
        getPartners: this._locations.getPartners,
        /**
         * Method to get allPartners
         */
        getAllSentInvitations: this._locations.getAllSentInvitations,
        /**
         * Method to get managePartners
         */
        getReceivedPartners: this._locations.getReceivedPartners,
        /**
         * Method to get getPartnersInvitedByOrg
         */
        getPartnersInvitedByOrg: this._locations.getPartnersInvitedByOrg,
        /**
         * Method to get partner Orgs
         */
        getPartnerOrgs: this._locations.getPartnerOrgs,
        /**
         * Method to send partner invitation
         */
        sendPartnerInvitation: this._locations.sendPartnerInvitation,
        /**
         * Method to get all flexFields
         */
        getAllFlexFields: this._locations.getFlexFields,
        /**
         * Method to get all flexFields
         */
        getLocationsCodes: this._locations.getLocationsCodes,

        getLocationsCodesData: this._locations.getLocationsCodesData,
        /**
         * Method to fetch all related tenants subscribed to an app
         */
        getRelatedTenants: this._locations.getRelatedTenants,
        /**
         * Method to get names of orgs given orgIds
         */
        getOrgNamesByIds: this._locations.getOrgNamesByIds,
        /**
         * Method to reject partnerOrganization
         */
        rejectPartner: this._locations.rejectPartner,
        /**
         * Method to delete partner invitation
         */
        deletePartnerInvitation: this._locations.deletePartnerInvitation,
        /**
         * Method to resend partner invitation
         */
        resendPartnerInvitation: this._locations.resendPartnerInvitation,
        /**
         * Method to approve partnerOrganization
         */
        approvePartner: this._locations.approvePartner,
        /**
         * Method to update partnerOrganization
         */
        updatePartner: this._locations.updatePartner,
        /**
         * Method to get organization properties map
         */
        getOrganizationFieldIdVsNameMap: this._locations.getOrganizationFieldIdVsNameMap,
    };

    /**
     * Exposing asset methods
     */
    assets = {
        /**
         * Method to get asset meta data
         */
        getAssetMetadata: this._assets.getAssetMetaData,
        /**
         * Method to get asset meta data
         */
        getAssetMetaDataViaPromise: this._assets.getAssetMetaDataViaPromise,
        /**
         * Method to get asset reconciliation configuration
         */
        getReconciliationConfiguration: this._assets.getAssetReconciliationConfiguration,
        /**
         * Method to get asset work flow stages data
         */
        getAssetWorkflowSatages: this._assets.getAssetWorkflowSatages,
        /**
         * Method to get asset filing attributes
         */
        getFilingAttributes: this._assets.getFilingAttributes,
        /**
         * Method to get transformations
         */
        getTransformations: this._assets.getTransformations,
        /**
         * Method to get transformations
         */
        getReconciliationTransformations: this._assets.getReconciliationTransformations,
        /**
         * Method to get all map transformations
         */
        getAllMapTransformations: this._assets.getAllMapTransformations,
        /**
         * Method to get transformations
         */
        getAllTransformations: this._assets.getAllTransformations,
        /**
         * Method to get active inbound / outbound transformation names
         */
        getActiveTransformationsByType: this._assets.getActiveTransformationsByType,
        /**
         * Method to get active inbound / outbound transformation names
         */
        updateActiveTransformationsByType: this._assets.updateActiveTransformationsByType,
        /**
         * Method to get ServiceSetUpAttributes
         */
        getServiceSetUpAttributes: this._assets.getServiceSetUpAttributes,
        /**
         * Method to save ServiceAssetSetupAttr
         */
        saveServiceAssetSetupAttr: this._assets.saveServiceAssetSetupAttr,
        /**
         * Method to get assetMetaData permissions
         */
        getAssetMetaDataPermissions: this._assets.getAssetMetaDataPermissions,
        /**
         * Method to fetch tag based metadata-types (assets, bridges, widgets)
         */
        getTagBasedMetadataType: this._assets.getTagBasedMetadataType,
        /**
         * Method to fetchEntities
         */
        fetchEntities: this._assets.fetchEntities,
        /**
         * Method to get Reports
         */
        getReports: this._assets.getReports,
        /**
         * Method to get Report Processes
         */
        getReportProcesses: this._reports.getReportProcesses,
        /**
         * Method to get Report request
         */
        getReportRequest: this._reports.getReportRequest,
        /**
         * Method to get getAssetWithVersion
         */
        getAssetWithVersion: this._assets.getAssetWithVersion,
        /**
         * Method to get getAssetDescription
         */
        getAssetDescription: this._assets.getAssetDescription,
        /**
         * Method to validate Filing Attributes
         */
        validateFilingAttributes: this._assets.validateFilingAttributes,
        /**
         * Process Transformation
         */
        processTransformations: this._transformations.processTransformations,
        /**
         * Method to get bridge source nodes
         */
        getBridgeSourceNodes: this._assets.getBridgeSourceNodes,
        /**
         * Method to get filtering attributes
         */
        getFilteringAttributes: this._assets.getFilteringAttributes,
        /**
         * Method to get asset tags
         */
        getAssetTags: this._assets.getAssetTags,
        /**
         * Method to get all asset tags
         */
        getAllAssetTags: this._assets.getAllAssetTags,
        /**
         * Method to get asset filing attributes
         */
        getReportFilingAttributes: this._assets.getReportFilingAttributes,
        /**
         * Method to get error reports
         */
        downloadErrorReport: this._assets.downloadErrorReport,
        /**
         * Method to generate error reports
         */
        generateErrorReport: this._reports.generateErrorReport,
        /**
         * Method to get all keys
         */
        getAllKeys: this._assets.getAllkeys,
        /**
         * Method to get all key values
         */
        getTagValuesByKey: this._assets.getTagValuesByKey,
        /**
         * Method to get services based on tag values
         */
        getServicesByTagValues: this._assets.getServicesByTagValues,
        /**
         * Method to get chain displaynames
         */
        getChainDisplayNames: this._assets.getChainDisplayNames,
        /**
         * Method to get help document
         */
        getHelpDocument: this._assets.getHelpDocument,
        /**
         * Method to get help document data
         */
        getHelpDocumentUrl: this._assets.getHelpDocumentUrl,
        /**
         * Method to get setup attribute change logs
         */
        getSetupAttributeChangeLogs: this._assets.getSetupAttributeChangeLogs,
        /**
         * Method to create bundle data
         */
        updateBundle: this._assets.updateBundle,
        /**
         * Method to get bundle data
         */
        getBundles: this._assets.getBundles,
        /**
         * Method to delete bundle data
         */
        deleteBundle: this._assets.deleteBundle,
        /**
         * Method to get assignable subscriptions
         */
        getAssignableSubscriptions: this._assets.getAssignableSubscriptions,
        /**
         * Method to get bundle subscriptions
         */
        getBundleSubscriptions: this._assets.getBundleSubscriptions,
        /**
         * Method to get bundle subscriptions
         */
        updateBundleSubscriptions: this._assets.updateBundleSubscriptions,
        /**
         * Method to get bundle invitations
         */
        getBundleInvitations: this._assets.getBundleInvitations,
        /**
         * Method to get bundle invitations
         */
        createBundleInvitations: this._assets.createBundleInvitations,
        /**
         * Method to delete bundle invitations
         */
        deleteBundleInvitations: this._assets.deleteBundleInvitations,
        /*
         * Get recon outbound report configurations
         */
        getReconOverrideConfigurations: this._assets.getReconOverrideConfigurations,
        /**
         * update recon outbound report configurations
         */
        submitReconOverrideConfigurations: this._assets.submitReconOverrideConfigurations,
        /**
         * Get recon outbound report configurations
         */
        getReconOutboundReportConfigurations: this._assets.getReconOutboundReportConfigurations,
        /**
         * update recon outbound report configurations
         */
        submitReconOutboundReportConfigurations: this._assets.submitReconOutboundReportConfigurations,
        /**
         * Get recon outbound app configurations
         */
        getReconOutboundAppConfigurations: this._assets.getReconOutboundAppConfigurations,
        /**
         * update recon outbound app configurations
         */
        submitReconOutboundAppConfigurations: this._assets.submitReconOutboundAppConfigurations,
        /**
         * delete recon outbound app configurations
         */
        deleteReconOutboundAppConfiguration: this._assets.deleteReconOutboundAppConfiguration,
        /**
         * Method to get subscriptions by tag key
         */
        getSubscriptionsByTagKey: this._assets.getSubscriptionsByTagKey,
        /**
         * Method to get and transform bridge nodes
         */
        getAndTransformBridgeNodes: this._assets.getAndTransformBridgeNodes,
        /**
         * Method to transform bridge nodes response
         */
        transformBridgeNodesResponse: this._assets.transformBridgeNodesResponse,
        /**
         * Method to get bridge nodes
         */
        getBridgeNodes: this._assets.getBridgeNodes,
        /**
         * Method to get app template
         */
        getAppTemplate: this._assets.getAppTemplate,
        /**
         * Method to submit app template
         */
        submitAppTemplate: this._assets.submitAppTemplate,
        /**
         * Method to delete app template
         */
        deleteAppTemplate: this._assets.deleteAppTemplate,
    };

    /**
     * Exposing Services methods
     */
    subscriptions = {
        /**
         * Method to search subscribed services with organization id
         */
        searchSubscribedServicesByOrganizationId: this._subscriptions.searchSubscribedServicesByOrganizationId,
        /**
         * Method to get subscribed services
         */
        getSubscribedServices: this._subscriptions.getSubscribedServices,
        /**
         * Method to get reconciliation services
         */
        getReconciliationServices: this._subscriptions.getReconciliationServices,
        /**
         * Method to get collaborated services
         */
        getCollaboratedApps: this._subscriptions.getCollaboratedApps,
        /**
         * Method to get subscribed services
         */
        getServiceDetails: this._subscriptions.getServiceDetails,
        /**
         * Method to get services underlying bridge
         */
        servicesUnderlyingBridge: this._subscriptions.fetchServicesUnderlyingBridge,
        /**
         * Method to get bridge nodes
         */
        fetchBridgeNodes: this._subscriptions.fetchBridgeNodes,
        /**
         * Method to get services underlying bridge using serviceId
         */
        fetchAppsUnderlyingBridge: this._subscriptions.fetchAppsUnderlyingBridge,
        /**
         * Method to get services underlying bridge
         */
        indexAppOrMasterData: this._subscriptions.indexAppOrMasterData,
        /**
         * Method to get services underlying bridge
         */
        getBridgeNodes: this._subscriptions.getBridgeNodes,
        /**
         * Method to update pending services
         */
        subscribeToServices: this._subscriptions.subscribeToServices,
        /**
         * Method to subscribe to services
         */
        updateSubscribedServices: this._subscriptions.updateSubscribedServices,
        /**
         * Method to fetch subscribed widgets
         */
        fetchSubscribedWidgets: this._subscriptions.fetchSubscribedWidgets,
        /**
         * Method to fetch subscribed Assets
         */
        fetchSubscribedAssets: this._subscriptions.fetchSubscribedAssets,
        /**
         * Method to fetch allOrg
         */
        getAllOrganizations: this._subscriptions.getAllOrganizations,
        /**
         * Method to fetch allOrg
         */
        searchAllOrganizations: this._subscriptions.searchAllOrganizations,
        /**
         * Method to fetch allOrgAssets
         */
        getAllAssets: this._subscriptions.getAllAssets,
        /**
         * Method to get the count of the subscribed assets
         */
        subscribedAssetsCount: this._subscriptions.subscribedAssetsCount,
        /**
         * Method get assets
         */
        getAssets: this._subscriptions.getAssets,
        /**
         * Method to get Recently accessed services
         */
        getRecentlyAccessedServices: this._subscriptions.getRecentlyAccessedServices,
        /**
         * Method to get bridge metadata
         */
        getBridgeMetaData: this._subscriptions.getBridgeMetaData,
    };

    /**
     *  Exposing event subscription methods
     */
    events = {
        /**
         *  Method to get subscribed events
         */
        getSubscribedEvents: this._events.getSubscribedEvents,
        /**
         *  Method to get event definations
         */
        getEventDefinitions: this._events.getEventDefinitions,
        /**
         * Method to create event subscriptions
         */
        createEventSubscriptions: this._events.createEventSubscriptions,
        /**
         * Method to edit event subscriptions
         */
        editEventSubscriptions: this._events.editEventSubscriptions,
        /**
         * Method to delete an event subscription
         */
        deleteEventSubscription: this._events.deleteEventSubscription,
        /**
         * Method to get email Notifications
         */
        getEmailNotifications: this._events.getEmailNotifications,
        /**
         * Method to get email Content
         */
        getEmailContent: this._events.getEmailContent,
        /**
         * Method to resend Email
         */
        resendEmail: this._events.resendEmail,
    };

    /**
     *  Exposing idenity providers methods
     */
    identityProviders = {
        /**
         * Method to get identity providers
         */
        getIdentityProviders: this._identityProviders.getIdentityProviders,
        /**
         * Method to create identity providers
         */
        createIdentityProvider: this._identityProviders.createIdentityProvider,
        /**
         * Method to update identity providers
         */
        updateIdentityProvider: this._identityProviders.updateIdentityProvider,
        /**
         * Method to delete identity provider
         */
        deleteIdentityProvider: this._identityProviders.deleteIdentityProvider,
    };

    /**
     * Exposing Collaboration related method
     */

    collaboration = {
        /**
         * Method to get collaboration configuration
         */
        getCollabConfig: this._collabService.getCollabConfig,
        /**
         * Method to create collaboration configuration
         */
        createCollabConfig: this._collabService.createCollabConfig,
        /**
         * Method to create partner request
         */
        createPartnerRequest: this._collabService.createPartnerRequest,
        /**
         * Method to delete partner request
         */
        deleteCollabConfig: this._collabService.deleteCollabConfig,
        /**
         * Method to get list of collaborators
         */
        getCollaboratorsList: this._collabService.getCollaboratorsList,
        /**
         * Approve collaboration request
         */
        approveCollaborationRequest: this._collabService.approveCollaborationRequest,
        /**
         * Method to get bundle app collaboration configuration
         */
        getBundleCollabConfig: this._collabService.getBundleCollabConfig,
        /**
         * Method to create bundle collaboration configuration
         */
        updateBundleCollaboration: this._collabService.updateBundleCollaboration,
        /**
         * Method to delete bundle app partner request
         */
        deleteBundleCollabConfig: this._collabService.deleteBundleCollabConfig,
    };

    /**
     * Exposing common methods
     */
    common = this._common;

    /**
     * Exposing integration methods
     */
    integrations = this._integrations;

    /**
     * Exposing transformation services
     */
    transformations = this._transformations;

    /**
     * Exposing workQ methods
     */
    workQ = this._workQ;
    /**
     * Exposing dashboard methods
     */
    dashboard = this._dashboard;
    /**
     * Exposing validations service
     */
    validations = this._validations;
    /**
     * Exposing common reports
     */
    commonReports = this._commonReports;
    /**
     * Exposing common reports
     */
    tags = this._tags;
    /**
     * Reports
     */
    reports = {
        /**
         * Method to print file
         */
        printFile: this._reports.printFile,
        /**
         * Method to download/print file
         */
        takeBlobAction: this._reports.takeBlobAction,
    };
    /**
     * Analytics
     */
    analytics = this._analytics;
    /**
     * Notifications
     */
    notifications = this._notifications;
}
