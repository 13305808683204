import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';

import { AssetService } from '../../models/assetservice.class';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'package-card',
    templateUrl: './home-package-process.component.html',
    styleUrls: ['./home-package-process.component.scss'],
})
export class HomePackageProcessComponent implements OnInit, AfterContentChecked, AfterViewInit {
    @Input() bridge: AssetService;
    @Input() newProcessInSameTab?: boolean;
    @Input() allProcessesInSameTab?: boolean;
    @Input() processInSameTab?: boolean;
    @Input() navigateToAppHomeOnly: boolean;
    @Input() allReportsInSameTab: boolean;

    @ViewChild('verticalStepper') matStepper: MatStepper;

    selectedIndex = 0;

    constructor(private cdref: ChangeDetectorRef, private _router: Router) {}

    navigateToAppHome = (app: AssetService) => {
        this._router.navigate(['packages', this.bridge.restApiName, 'apps', app.restApiName, 'home']);
    };

    fetchmore = (indexRecieved) => {
        this.selectedIndex = indexRecieved;
    };
    ngOnInit() {
        // console.log(this.app);
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // this.matStepper && (this.matStepper.selectedIndex = 0);
        }, 100);
    }
}
