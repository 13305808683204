import { Instance } from '../../models/instance.interface';

export const instancesState: InstancesState = {
    instances: {},
    organizationsMap: {},
    instanceEntitiesState: {},
    instancesState: {},
};

export interface InstancesState {
    organizationsMap: {
        [property: string]: {
            // organizationId vs serviceIdMap
            [property: string]: {
                // serviceId vs searchInstances Map
                searchedInstances: {
                    // stringifiedSearchCriteria Vs instanceIds
                    [property: string]: string[];
                };
                selectedInstances: {
                    // stringified criteria vs instanceIds
                    [property: string]: string[];
                };
                instancesByStatus: {
                    // status vs instanceIds
                    [property: string]: { [property: string]: string[] };
                };
                selectedStatus: string;
                statuses: {
                    // status vs count
                    [property: string]: number;
                };
                loading: {
                    instances: boolean;
                    statuses: boolean;
                };
            };
        };
    };
    instances: {
        [property: string]: Instance; // instanceId Vs instance
    };
    instancesState: {
        [property: string]: { errors: string[]; warnings: string[] };
    };
    instanceEntitiesState: {
        [property: string]: { [property: string]: string };
    };
}
