import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { FilingAttributeField } from '../../models/filingattributefield.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { InstancesService } from '../../services/instances/instances.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-updaterequest',
    templateUrl: './updaterequest.component.html',
})
export class UpdateRequestComponent implements OnInit {
    instanceId: any;
    requestId: any;
    assetId: any;
    bridgeId: any;
    bridgeRestAPIName: any;
    backToNewProcesspage: any;
    updateAssetRequest: any;
    bridgeSourceNodes: any;
    navigateToInstancePage: any;
    assetRestApiName: any;
    isAssetToAssetCase: any;
    processIncompleteInstances: any;
    filingAttributeFields: any;

    constructor(
        public _utils: UtilsService,
        private dialog: MatDialog,
        protected _router: Router,
        private _instances: InstancesService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            requestId: any;
            instanceId: any;
            assetId: any;
            bridgeId: any;
            bridgeRestAPIName: any;
            bridgeSourceNodes: any[];
            assetRestApiName: any;
            isAssetToAssetCase: boolean;
            filingAttributeFields: any[];
        }
    ) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    updateRequest = () => {
        this.bridgeSourceNodes ??= this.data.bridgeSourceNodes;
        this.filingAttributeFields ??= this.data.filingAttributeFields;
        const hasBridgeSourceNodes = this.bridgeSourceNodes?.length;
        if (hasBridgeSourceNodes > 0) {
            const formData = new FormData();
            const hasFilingAttributeFields = this.filingAttributeFields?.length;
            if (hasFilingAttributeFields > 0) {
                this.filingAttributeFields.forEach((attribute: FilingAttributeField) => {
                    if (attribute.value !== undefined) {
                        if (Array.isArray(attribute.value)) {
                            attribute.value.forEach((element) => {
                                formData.append(attribute.fullId, element);
                            });
                        } else {
                            let value;
                            if (attribute.uiType === 'DATE') {
                                value = CommonUtilsService.transformDate(
                                    attribute.value as string,
                                    'dd/mm/yyyy',
                                    attribute.outputFormat as any
                                );
                                formData.append(attribute.fullId, value);
                            } else {
                                formData.append(attribute.fullId, attribute.value);
                            }
                        }
                    }
                });
            }

            const data = {
                instanceId: this.instanceId ?? this.data.instanceId,
                assetRestApiname: this.assetRestApiName ?? this.data.assetRestApiName,
                bridgeRestApiName: this.bridgeRestAPIName ?? this.data.bridgeRestAPIName,
                requestId: this.requestId ?? this.data.requestId,
                payload: formData,
            };
            this._instances.updateCurrentRequest(data, {
                successCallback: (res) => {
                    this._utils.alertSuccess((res && res.msg) || 'Asset To Asset Pull Request Accepted.');
                    this.openCurrentInstance();
                },
                failureCallback: (res) => {
                    if (res && res.response && res.response.pendingAssetDataIds) {
                        this.closeModal();
                        this.processIncompleteInstances(res);
                    } else {
                        this._utils.alertError((res && res.msg) || 'Failed to update process');
                    }
                },
            });
        } else {
            this.updateAssetRequest();
        }
    };

    openCurrentInstance = () => {
        // this._utils.navigateToInstancePage(undefined, this.instanceId);
        this.closeModal();
        this.navigateToInstancePage();
    };

    ngOnInit() {}
}
