export interface GSTFilingState {
    currentYear: string;
    currentMonth: string;
    currentQuarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    currentAppGroup: string;
    otpAuthApp: string;
    gstFilingAppServiceIds: string[];
    reconPagination: {
        //organization Id vs map
        [property: string]: {
            // serviceId as string vs map
            [property: string]: {
                // criteria as string vs map
                [property: string]: {
                    pageSize: number;
                    pageIndex: number;
                    pagingState: {
                        // pageIndex vs string
                        [property: number]: string;
                    };
                };
            };
        };
    };
    selected: {
        serviceId: string;
        instanceId: string;
        currentViewLevel: number;
        detachedSource1EntityId: string;
        detachedSource2EntityId: string;
        nonDetachedSourceEntityId: string;
        viewLevel: {
            [property: string]: {
                recordId: string;
                entityId: string;
            };
        };
    };
}

export const gstFilingState: GSTFilingState = {
    currentMonth: undefined,
    currentYear: undefined,
    currentQuarter: undefined,
    currentAppGroup: undefined,
    otpAuthApp: 'gstin-otp-authentication',
    gstFilingAppServiceIds: [],
    reconPagination: {},
    selected: {
        serviceId: undefined,
        instanceId: undefined,
        currentViewLevel: undefined,
        viewLevel: {},
        detachedSource1EntityId: undefined,
        detachedSource2EntityId: undefined,
        nonDetachedSourceEntityId: undefined,
    },
};
