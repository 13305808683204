import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    AssetData,
    AssetService,
    BridgeNode,
    FilingAttributeField,
    MastersTableMetaDataInterface,
    Organization,
    User,
} from 'taxilla-library';

import { AnimationsService } from '../animations/animations.service';

@Injectable({
    providedIn: 'root',
})
export class RootScope {
    TAXILLA_API_URL = environment.taxilla_api;
    TAXILLA_IDM_API_URL = environment.taxilla_user_identity_api;
    ENTRANSACT_API_URL = environment.eninvoice_api;
    TAXILLA_HOST_API_URL = environment['encomply-ui'];
    LOGO_HREF = environment.logoHref;

    orientation: string;
    device: string;
    selectedEntityRecord: any[] = [];
    session = new BehaviorSubject(false);
    generateReports = new BehaviorSubject(false);
    loadingCall = {};
    userPermissions: BehaviorSubject<any> = new BehaviorSubject(undefined);
    user: BehaviorSubject<User> = new BehaviorSubject(undefined);
    allSubscribedApps: BehaviorSubject<AssetService[]> = new BehaviorSubject(undefined);
    currentOrganizationId: BehaviorSubject<string> = new BehaviorSubject('');
    currentOrganization: BehaviorSubject<Organization> = new BehaviorSubject(undefined);
    selectedAppAPI: BehaviorSubject<string> = new BehaviorSubject(undefined);
    selectedSubAppAPI: BehaviorSubject<string> = new BehaviorSubject(undefined);
    selectedReportName: BehaviorSubject<string> = new BehaviorSubject(undefined);
    selectedSubApp: BehaviorSubject<AssetService> = new BehaviorSubject(undefined);
    allSubApps: { [property: string]: BridgeNode } = {};
    selectedApp: BehaviorSubject<AssetService> = new BehaviorSubject(undefined);
    selectedReport: BehaviorSubject<AssetService | BridgeNode> = new BehaviorSubject(undefined);
    assetMetaData: BehaviorSubject<AssetData> = new BehaviorSubject(undefined);
    workflowStages = new BehaviorSubject([]);
    mastersTables: BehaviorSubject<{
        property?: MastersTableMetaDataInterface;
    }> = new BehaviorSubject(undefined);
    current: {
        app: AssetService;
        bridge: AssetService;
        appBridgePermissions: any;
        appFilingAttributes: FilingAttributeField[];
        appPermissions: any;
        report: BridgeNode | AssetService;
        reportPermissions: any;
        mainPermissions: any;
        instanceId: BehaviorSubject<string>;
        inboundTransmissionId: BehaviorSubject<string>;
        relatedApps: any[];
        master: BehaviorSubject<any[]>;
        showing: 'PROCESSES' | 'INBOUND_TRANSMISSIONS' | 'PROCESS' | 'REPORTS';
        state: BehaviorSubject<'all' | 'in-progress' | 'completed' | 'terminated' | 'cancelled'>;
    } = {
        app: undefined,
        bridge: undefined,
        appBridgePermissions: undefined,
        appFilingAttributes: undefined,
        appPermissions: undefined,
        report: undefined,
        reportPermissions: undefined,
        mainPermissions: undefined,
        instanceId: new BehaviorSubject(undefined),
        inboundTransmissionId: new BehaviorSubject(undefined),
        relatedApps: undefined,
        master: new BehaviorSubject(undefined),
        showing: 'PROCESSES',
        state: new BehaviorSubject(undefined),
    };
    isAdmin = false;
    actualAssetId: any;
    rootOrganization: BehaviorSubject<Organization> = new BehaviorSubject(undefined);
    applicationInitialized = new BehaviorSubject(false);
    // added at library search service
    INSTANCE_STATES: any[] = [
        {
            name: 'In-Progress',
            value: 'IN_PROGRESS',
        },
        {
            name: 'Request Completed',
            value: 'REQUEST_COMPLETED',
        },
        {
            name: 'Process Cancelled',
            value: 'REQUEST_CANCELLED',
        },
    ];

    VALIDATION_STATUS: any[] = [
        {
            name: 'Success',
            value: 'SUCCESS',
        },
        {
            name: 'Warning',
            value: 'WARNING',
        },
        {
            name: 'Error',
            value: 'ERROR',
        },
    ];
    // added at library search service

    forcedTransmissionStatus: any;
    fromNewProcess = false;
    currentRequestId: string;
    pendingAssetData: {
        sourceAssetRestApiName: string;
        targetAssetRestApiName: string;
        filteringAttributes: any[];
        instanceIds: any[];
        targetReportName: string;
    } = {
        sourceAssetRestApiName: undefined,
        filteringAttributes: undefined,
        targetAssetRestApiName: undefined,
        instanceIds: undefined,
        targetReportName: undefined,
    };

    userCreatedTransmissionsSearchFilterCriteria: BehaviorSubject<{
        entityId: string;
        restApiServiceName: string;
        filterCriteria: {
            criteriaRelations: any;
            entityFilterCriterias: any[];
            serviceId: string;
        };
        sort: {
            [property: string]: string;
        };
    }> = new BehaviorSubject(undefined);
    userCreatedProcessessSearchFilterCriteria: BehaviorSubject<{
        entityId: string;
        restApiServiceName: string;
        filterCriteria: {
            criteriaRelations: any;
            entityFilterCriterias: any[];
            serviceId: string;
        };
        sort: {
            [property: string]: string;
        };
    }> = new BehaviorSubject(undefined);
    userCreatedProcessesStatussesCriteria: BehaviorSubject<{
        entityId: string;
        restApiServiceName: string;
        filterCriteria: {
            entityFilterCriterias: any[];
            serviceId: string;
        };
        sort: {
            [property: string]: string;
        };
    }> = new BehaviorSubject(undefined);
    activateSearch = new BehaviorSubject(false);
    searchFilters = {};
    defaultFilters = {};
    erOrganizations = null;

    // Observable sources
    private _selectedProcessChange = new Subject<{ recordProcess: any }>();
    private _clearSearchCriteria = new Subject<any>();
    private _searchEvent = new Subject<boolean>();

    // Observable streams
    selectedProcessChange$ = this._selectedProcessChange.asObservable();
    clearSearchCriteria$ = this._clearSearchCriteria.asObservable();
    searchEvent$ = this._searchEvent.asObservable();

    constructor(public animations: AnimationsService) {}

    selectedProcessChangeEvent = (event) => {
        this._selectedProcessChange.next(event);
    };
    clearSearchCriteriaEvent = (event) => {
        this._clearSearchCriteria.next(event);
    };
    isSearchEvent = (event) => {
        this._searchEvent.next(event);
    };
}
