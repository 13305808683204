import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, delay, Subject, takeUntil } from 'rxjs';
import {
    CommonUtilsService,
    getCurrentOrganizationId$,
    getLeftNavCategory$,
    getLeftNavCategoryWithSearch$,
    getLeftNavMap$,
    getLeftNavSearchText$,
    SetSearchedValue,
    UtilsService,
} from 'taxilla-library';

import { SharedState } from '../../store/states';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
    @Input() selectedLeftNav: SharedState['leftNavMap'][0];
    @Output() closeMenu = new EventEmitter();
    @Output() bridgeDialogOpen = new EventEmitter<boolean>();
    private unSubscribe = new Subject<void>();
    private currentOrganizationId: string;
    public packages = [];
    public groups = [];
    public apps = [];
    public activeCategory: string;
    public filterAppName: string = '';
    public isNewUI: boolean;

    constructor(
        private store$: Store,
        private _router: Router,
        private _route: ActivatedRoute,
        private _commonUtils: CommonUtilsService,
        private _utils: UtilsService
    ) {
        this.isNewUI = this._commonUtils.getCookie('new-ui') === 'true';
    }

    public searchPackage = (event: Event) => {
        const searchText = (event.target as HTMLInputElement).value ? (event.target as HTMLInputElement).value : '';
        this.store$.dispatch(SetSearchedValue({ searchText }));
        if (this.selectedLeftNav && searchText?.length > 0) {
            this.store$
                .select(getLeftNavCategoryWithSearch$(this.selectedLeftNav))
                .pipe(takeUntil(this.unSubscribe))
                .subscribe((leftNav) => {
                    this.packages = leftNav?.packages ?? [];
                    this.groups = leftNav?.groups ?? [];
                    this.apps = leftNav?.apps ?? [];
                });
        }
    };

    public clearSearchApp = () => {
        this.filterAppName = '';
        this.store$.dispatch(SetSearchedValue({ searchText: '' }));
    };

    private navigate = (tab: string) => {
        if (!location.href.includes(this.currentOrganizationId)) {
            return;
        }
        const url = '/organizations/' + this.currentOrganizationId + '/category/' + tab;
        this._router.navigate([url]);
    };

    public closeMatmenu = () => {
        this.closeMenu.emit();
    };

    public trackById = (index, item) => {
        return item?.id || item?.serviceId || index;
    };

    ngOnInit() {
        if (!this.isNewUI) {
            this._utils.navigateToHomePage();
            return;
        }
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event) => {
                this.currentOrganizationId = event;
            });
        if (this.selectedLeftNav) {
            this.filterAppName = '';
            this.store$
                .select(getLeftNavCategoryWithSearch$(this.selectedLeftNav))
                .pipe(takeUntil(this.unSubscribe))
                .subscribe((leftNav) => {
                    this.packages = leftNav?.packages ?? [];
                    this.groups = leftNav?.groups ?? [];
                    this.apps = leftNav?.apps ?? [];
                });
        } else {
            combineLatest([this._route.params, this.store$.select(getLeftNavCategory$), this.store$.select(getLeftNavMap$)])
                .pipe(takeUntil(this.unSubscribe), delay(10))
                .subscribe(([params, category, navItems]) => {
                    this.activeCategory = category?.id;
                    if (params?.category) {
                        this.packages = category?.packages ?? [];
                        this.groups = category?.groups ?? [];
                        this.apps = category?.apps ?? [];
                    } else if (navItems?.[0]?.id) {
                        this.navigate(navItems?.[0]?.id);
                    }
                });
        }
        this.store$
            .select(getLeftNavSearchText$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((searchText) => {
                this.filterAppName = searchText;
            });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
