import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, pipe } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Entity } from '../../models/entity.class';
import { Record } from '../../models/record/record.class';
import { ApiService } from '../../services/api/api.service';
import {
    AlertError,
    ClearInstanceData,
    ClearRecords,
    DeletedRecords,
    DeleteRecords,
    GetRecords,
    SaveRecords,
    SearchRecords,
    SetRecords,
    SetRecordsLoading,
    SetRecordsPagination,
    SetSearchRecords,
    UpdateRecord,
} from '../actions';
import {
    getAppsMetaDataMap$,
    getCurrentOrganizationId$,
    getEntity,
    getRecords$,
    getRecordsOrg$,
    getSubscribedAppsMap$,
} from '../selectors';

@Injectable()
export class RecordsEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    private getRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRecords),
            pipe(delay(10)),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getRecordsOrg$),
                this.store$.select(getAppsMetaDataMap$)
            ),
            mergeMap(([action, organizationId, appsMap, recordsServicesMap, metaDataMap]) => {
                const app = appsMap?.[action.serviceId];
                const metaData = metaDataMap?.[action.serviceId]?.[app?.assetMetaUId];
                const entity = metaData?.entities && (getEntity(metaData.entities, action.entityUid) as any as Entity);
                if (!action.serviceId) {
                    return [];
                }
                const recordsMap =
                    recordsServicesMap?.[action.serviceId]?.[action.instanceId]?.[`${action.parentRecordId || ''}|${action.entityUid}`];
                if (entity?.array && recordsMap?.recordIds?.length >= (recordsMap?.currentPageIndex + 1) * recordsMap?.pageSize) {
                    return [];
                } else if (!entity?.array && recordsMap?.recordIds?.length === 1) {
                    return [];
                }
                this.store$.dispatch(
                    SetRecordsLoading({
                        entityUid: action.entityUid,
                        instanceId: action.instanceId,
                        organizationId,
                        serviceId: app.serviceId,
                        parentRecordId: action.parentRecordId,
                        loading: true,
                    })
                );
                return from(
                    this._api.records.getRecordsPromise({
                        entityId: action.entityUid,
                        instanceId: action.instanceId,
                        pagingState: recordsMap?.pagingState,
                        parentRecordId: action.parentRecordId,
                        restApiName: app.restApiName,
                        size: recordsMap?.pageSize || 20,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((response) => {
                        this.store$.dispatch(
                            SetRecordsLoading({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        );
                        this.store$.dispatch(
                            SetRecordsPagination({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                cursor: response?.pagingState,
                                pageIndex: recordsMap?.currentPageIndex,
                                noAlerts: undefined,
                            })
                        );
                        return SetRecords({
                            entityUid: action.entityUid,
                            instanceId: action.instanceId,
                            organizationId,
                            records: response.records,
                            serviceId: app.serviceId,
                            parentRecordId: action.parentRecordId,
                        });
                    }),
                    catchError((e) =>
                        of(
                            SetRecordsLoading({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        )
                    )
                );
            })
        )
    );

    private clearRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClearInstanceData),
            pipe(delay(10)),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) => {
                if (!action.serviceId) {
                    return [];
                }
                return of(
                    ClearRecords({
                        instanceId: action.instanceId,
                        organizationId,
                        serviceId: action.serviceId,
                    })
                );
            })
        )
    );

    private searchRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SearchRecords),
            pipe(delay(10)),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getRecordsOrg$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getRecords$)
            ),
            mergeMap(([action, organizationId, appsMap, recordsServicesMap, metaDataMap, recordsMap]) => {
                const app = appsMap?.[action.serviceId];
                const metaData = metaDataMap?.[action.serviceId]?.[app?.assetMetaUId];
                const entity = metaData?.entities && (getEntity(metaData.entities, action.entityUid) as any as Entity);
                if (!action.serviceId) {
                    return [];
                }
                const recordsStateMap =
                    recordsServicesMap?.[action.serviceId]?.[action.instanceId]?.[`${action.parentRecordId || ''}|${action.entityUid}`];
                const reconSpecificMap = recordsStateMap?.reconSpecific?.[action.reconSpecific];
                const associationsCount =
                    action.reconSpecific === 'grouped' &&
                    this.getAssociationsCount(action.associationKey, reconSpecificMap?.recordIds, recordsMap as any);
                if (
                    action.associationKey &&
                    reconSpecificMap?.pagingState &&
                    associationsCount > (reconSpecificMap.currentPageIndex + 1) * reconSpecificMap.pageSize
                ) {
                    return [];
                } else if (
                    !action.associationKey &&
                    action.reconSpecific &&
                    reconSpecificMap.recordIds.length >= (reconSpecificMap.currentPageIndex + 1) * reconSpecificMap.pageSize
                ) {
                    return [];
                } else if (
                    !action.reconSpecific &&
                    recordsStateMap.searchRecordIds.length >= (recordsStateMap.currentPageIndex + 1) * recordsStateMap.pageSize
                ) {
                    return [];
                }
                this.store$.dispatch(
                    SetRecordsLoading({
                        entityUid: action.entityUid,
                        instanceId: action.instanceId,
                        organizationId,
                        serviceId: app.serviceId,
                        parentRecordId: action.parentRecordId,
                        loading: true,
                    })
                );
                return from(
                    this._api.records.searchEntityRecordsObservable({
                        assetName: app?.name,
                        query: action?.reconSpecific ? reconSpecificMap?.criteria : action['criteria'],
                        searchAfter: action.reconSpecific ? reconSpecificMap?.pagingState : recordsStateMap?.pagingState,
                        searchEntityId: action.entityUid,
                        restApiServiceName: app?.restApiName,
                        size: (action?.reconSpecific ? reconSpecificMap?.pageSize : recordsStateMap?.pageSize) || 20,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((response: any) => {
                        this.store$.dispatch(
                            SetRecordsLoading({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        );
                        this.store$.dispatch(
                            SetRecordsPagination({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                cursor: response?.searchAfter,
                                pageIndex: action?.reconSpecific ? reconSpecificMap?.currentPageIndex : recordsStateMap?.currentPageIndex,
                                reconSpecific: action.reconSpecific,
                                noAlerts: action.noAlerts,
                            })
                        );
                        return SetSearchRecords({
                            entityUid: action.entityUid,
                            instanceId: action.instanceId,
                            organizationId,
                            records: response.records,
                            serviceId: app.serviceId,
                            parentRecordId: action.parentRecordId,
                            reconSpecific: action.reconSpecific,
                            associationKey: action.associationKey,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(AlertError({ message: e?.msg }));
                        return of(
                            SetRecordsLoading({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        );
                    })
                );
            })
        )
    );

    private getAssociationsCount = (associationKey: string, recordIds: string[], records: { [recordId: string]: Record }) => {
        return recordIds?.reduce((associationIds, recordId) => {
            const associationId = records?.[recordId]?.fields?.find((field) => field.id === associationKey)?.value as string;
            associationId && !associationIds.includes(associationId) && associationIds.push(associationId);
            return associationIds;
        }, [] as string[]).length;
    };

    private saveRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SaveRecords),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, organizationId, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                this.store$.dispatch(
                    SetRecordsLoading({
                        entityUid: action.entityUid,
                        instanceId: action.instanceId,
                        organizationId,
                        serviceId: app.serviceId,
                        parentRecordId: action.parentRecordId,
                        loading: true,
                    })
                );
                return this._api.records
                    .saveRecords({
                        entityId: action.entityUid,
                        assetDataId: action.instanceId,
                        restApiName: app.restApiName,
                        noAlerts: false,
                        payload: action.recordsPayload,
                        noMessages: action.noMessages,
                    })
                    .pipe(
                        map((res) => {
                            this.store$.dispatch(
                                SetRecordsLoading({
                                    entityUid: action.entityUid,
                                    instanceId: action.instanceId,
                                    organizationId,
                                    serviceId: app.serviceId,
                                    parentRecordId: action.parentRecordId,
                                    loading: false,
                                })
                            );
                            return SetRecords({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                records: res?.response?.records as any as Record[],
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                deleteFromSource: action.deleteFromSource,
                            });
                        }),
                        catchError((e) =>
                            of(
                                SetRecordsLoading({
                                    entityUid: action.entityUid,
                                    instanceId: action.instanceId,
                                    organizationId,
                                    serviceId: app.serviceId,
                                    parentRecordId: action.parentRecordId,
                                    loading: false,
                                })
                            )
                        )
                    );
            })
        )
    );

    private updateRecord$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateRecord),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, organizationId, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                this.store$.dispatch(
                    SetRecordsLoading({
                        entityUid: action.entityUid,
                        instanceId: action.instanceId,
                        organizationId,
                        serviceId: app.serviceId,
                        parentRecordId: action.parentRecordId,
                        loading: true,
                    })
                );
                return from(
                    this._api.records.updateRecords({
                        entityId: action.entityUid,
                        instanceId: action.instanceId,
                        restApiName: app.restApiName,
                        noAlerts: false,
                        recordsPayload: action.recordsPayload,
                        records: action.records,
                    })
                ).pipe(
                    map((response) => {
                        this.store$.dispatch(
                            SetRecordsLoading({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        );
                        return SetRecords({
                            entityUid: action.entityUid,
                            instanceId: action.instanceId,
                            organizationId,
                            records: response as any as Record[],
                            serviceId: app.serviceId,
                            parentRecordId: action.parentRecordId,
                        });
                    }),
                    catchError((e) =>
                        of(
                            SetRecordsLoading({
                                entityUid: action.entityUid,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        )
                    )
                );
            })
        )
    );

    private setRecordsPagination$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SetRecordsPagination),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getRecordsOrg$)),
            mergeMap(([action, organizationId, recordsMap]) => {
                if (action.organizationId) {
                    return [];
                }
                this.store$.dispatch(
                    SetRecordsPagination({
                        ...action,
                        organizationId,
                    })
                );
                if (action.reconSpecific) {
                    return of(
                        SearchRecords({
                            ...action,
                        })
                    );
                }
                const searchCriteria =
                    recordsMap?.[action.serviceId]?.[action.instanceId]?.[`${action.parentRecordId || ''}|${action.entityUid}`]
                        ?.searchCriteria;
                if (searchCriteria) {
                    return of(
                        SearchRecords({
                            ...action,
                            criteria: typeof searchCriteria === 'string' ? JSON.parse(searchCriteria) : searchCriteria,
                        })
                    );
                }
                return of(
                    GetRecords({
                        ...action,
                    })
                );
            })
        )
    );

    private deleteRecords$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeleteRecords),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, organizationId, appsMap]) => {
                const app = appsMap?.[action.serviceId];
                this.store$.dispatch(
                    SetRecordsLoading({
                        entityUid: action.entityId,
                        instanceId: action.instanceId,
                        organizationId,
                        serviceId: app.serviceId,
                        parentRecordId: action.parentRecordId,
                        loading: true,
                    })
                );
                return from(
                    this._api.records.deleteRecords({
                        entityId: action.entityId,
                        assetId: app.assetMetaUId,
                        instanceId: action.instanceId,
                        restApiName: app.restApiName,
                        noAlerts: action.noAlerts,
                        payload: action.payload,
                        noMessages: action.noMessages,
                    })
                ).pipe(
                    map((response) => {
                        this.store$.dispatch(
                            SetRecordsLoading({
                                entityUid: action.entityId,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        );
                        return DeletedRecords({
                            entityUid: action.entityId,
                            instanceId: action.instanceId,
                            organizationId,
                            recordIds: action.payload?.reduce((ids, payloadObject) => {
                                ids.push(payloadObject.id);
                                return ids;
                            }, []),
                            serviceId: app.serviceId,
                            parentRecordId: action.parentRecordId,
                            deleteFromSource: action.deleteFromSource,
                            reconSpecific: action.reconSpecific,
                        });
                    }),
                    catchError((e) =>
                        of(
                            SetRecordsLoading({
                                entityUid: action.entityId,
                                instanceId: action.instanceId,
                                organizationId,
                                serviceId: app.serviceId,
                                parentRecordId: action.parentRecordId,
                                loading: false,
                            })
                        )
                    )
                );
            })
        )
    );
}
