import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { Organization } from '../../models/organization.class';
import { Password } from '../../models/password.class';
import { User } from '../../models/user.class';
import { BridgeService } from '../bridge/bridge.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    constructor(private _bridge: BridgeService, private _utils: UtilsService, private _store: StoreService) {}

    /**
     * Method to submit login details
     * @param data contains userid and password
     * @param callbacks Contains Success callback method, Failure callback method
     */
    login = (
        data: any,
        callbacks: {
            successCallback: (response: {
                fullyAuthenticated: boolean;
                organization: Organization;
                session_logged_in_token: string;
                user: User;
            }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.submitLogin(
            data,
            (res) => {
                callbacks.successCallback(res?.response || res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get session details
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getSessionDetails = (
        data?: {
            noAlerts: boolean;
        },
        custom?: {
            headers?: { [property: string]: string };
        }
    ) => {
        return new Observable<{
            fullyAuthenticated: boolean;
            currentOrg: Organization;
            session_logged_in_token: string;
            loggedInOrg: Organization;
            user: User;
            lastLogin: any;
            isAdmin: boolean;
            passwordExpiresInDays: number;
            timeZoneOffset: number;
        }>((observer) => {
            this._bridge.getLoggedInSessionDetails(
                { noAlerts: data && data.noAlerts },
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                },
                undefined,
                undefined,
                custom
            );
        });
    };

    /**
     * Method to setting password
     * @param data Contains Organization ID, payload (contains newPassword,confirmPassword,userID,authenticationKey,userName,dateOfBirth,   secretQuestionAndAnswersCommand)
     * @param callbacks Contains Success callback method, Failure callback method
     */
    setPassword = (data: { orgId: string; payload: Password }) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.setPassword(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to forgot password
     * @param data Contains User ID
     * @param callbacks Contains Success callback method, Failure callback method
     */
    forgotPassword = (
        data: { userId: string },
        callbacks?: {
            successCallback?: (response: { msg: string; success: boolean | 'false' | 'true' }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.forgotPassword(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to forgot username
     * @param data Contains organization code,email
     * @param callbacks Contains Success callback method, Failure callback method
     */
    forgotUsername = (
        data: { orgId: string; email: string; captcha: string },
        callbacks?: {
            successCallback?: (response: { msg: string; success: boolean | 'false' | 'true' }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.forgotUsername(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to reset password
     * @param data Contains Key, Password, Confirm Password
     */
    resetPassword = (data: {
        key: string;
        password: string;
        confirmPassword: string;
        secretQuestionAndAnswersCommand: object;
        captcha: string;
    }) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.resetPassword(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to fetch Captcha
     */
    fetchCaptcha = () => {
        return new Observable((observer) => {
            this._bridge.fetchCaptcha(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to register
     * @param data Contains Organization, Captcha, User
     * @param callbacks Contains Success callback method, Failure callback method
     */
    register = (data: { org: Organization; captcha: string; invitationId: string; user: User }) => {
        return new Observable((observer) => {
            this._bridge.register(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to check whether gstin is nic registred or not
     *  @param data Contains GSTIN Number, URL
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    isGstinNicRegistred = (
        data: { gstin: string; url: string },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.gstnValidateFunction(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to update/change password
     *  @param data Contains oldPassword, newPassword and confirmPassword
     *  @param callbacks Contains Success callback method, Failure callback method
     */
    updatePassword = (data: { oldPassword: string; newPassword: string; confirmPassword: string }) => {
        return new Promise<string>((resolve, reject) => {
            this._bridge.updateChangePassword(
                data,
                (res) => {
                    resolve(res?.msg);
                },
                (res) => {
                    reject(res?.msg);
                }
            );
        });
    };

    getContextDetailsWithAuthorizationKey = (data: { key: string }): Observable<unknown> => {
        return new Observable((observer) => {
            this._bridge.getContextDetailsWithAutorizationKey(
                data,
                (res) => {
                    observer.next(res || {});
                    observer.complete();
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to logout
     * @param callbacks Contains Success callback method, Failure callback method
     */
    logout = () => {
        return new Observable((observer) => {
            this._bridge.logout(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.next(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get security questions
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getSecurityQuestions = () => {
        return new Observable((observer) => {
            this._bridge.getSecurityQuestions(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get random security questions
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getRandomSecurityQuestions = (callbacks?: { successCallback: (...args: any[]) => void; failureCallback: (...args: any[]) => void }) => {
        this._bridge.getRandomSecurityQuestions(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to evaluate security questions
     * @param callbacks Contains Success callback method, Failure callback method
     */
    evaluateSecurityQuestions = (
        data: any,
        callbacks?: {
            successCallback: (response: {
                fullyAuthenticated: boolean;
                organization: Organization;
                user: User;
                isAdmin: boolean;
                lastLogin: any;
            }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.evaluateSecurityQuestions(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get application properties
     * @param callbacks Contains Success callback method, Failure callback method
     */
    // getApplicationProperties = async (callbacks?: {
    //     successCallback: (response: {
    //         'analytics.service.types': string;
    //         'default.provider.id': string;
    //         'digio.url': string;
    //         'logo.path': string;
    //         'macre.ui.url': string;
    //         'multiple.providers.enabled': boolean;
    //         'password.regex': string;
    //         'taxilla.build.version': string;
    //         'taxilla.js.minified': string;
    //         'taxilla.session.max.inactive.interval': string;
    //         'taxilla.version': string;
    //         'two.factor.authentication': string;
    //     }) => void;
    //     failureCallback?: (...args: any[]) => void;
    // }): Promise<void> => {
    //     const response = await this._store.publicScope.fetchValues(() => {
    //         return new Promise((resolve) => {
    //             this._bridge.getApplicationProperties(
    //                 (res) => {
    //                     resolve(res);
    //                 },
    //                 (res) => {
    //                     if (callbacks.failureCallback) {
    //                         callbacks.failureCallback(res);
    //                     }
    //                 }
    //             );
    //         });
    //     }, 'applicationProperties');
    //     callbacks.successCallback(response?.applicationProperties);
    // };

    getApplicationProperties = () => {
        return new Observable((observer) => {
            this._bridge.getApplicationProperties(
                (res: any) => {
                    observer.next(res);
                    observer.complete();
                },
                (res: any) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };
    /**
     * Method to get application properties from integration
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getApplicationPropertiesFromIntegration = async (callbacks?: {
        successCallback: (response: { 'com.taxilla.integration.master.scheduler.enabled': boolean }) => void;
    }): Promise<void> => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve) => {
                this._bridge.getApplicationPropertiesFromIntegration(
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        resolve?.({});
                    }
                );
            });
        }, 'applicationPropertiesFromIntegration');
        callbacks.successCallback(response?.applicationPropertiesFromIntegration);
    };

    /**
     * Method to get application misc properties
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getApplicationMiscProps = () => {
        return new Promise<{
            'min-mfa-answers': number;
            'password-policy': string;
            'password-policy-msg': string;
            'seed-token': string;
            'x-auth-token': string;
        }>((resolve, reject) => {
            this._bridge.getApplicationMiscProps(
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to get application misc properties
     */
    getUserMiscProps = () => {
        return new Promise<{
            passwordPolicy: string;
            passwordPolicyMsg: string;
        }>((resolve, reject) => {
            this._bridge.getUserMiscProps(
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to get provider
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getProvider = () => {
        return new Observable((observer) => {
            this._bridge.getProvider(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to authorize code
     */
    authorizeCode = (
        data: {
            stateId: string;
            clientId: string;
            code: string;
            redirectUri: string;
        },
        callbacks?: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.authorizeCode(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                }
            }
        );
    };

    /**
     * Method to authorize code
     */
    validateCode = (data: { clientId: string; authCode: string; code: string }) => {
        return new Observable((observer) => {
            this._bridge.validateCode(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to authorize code
     */
    authenticateToken = (token: string) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.authenticateToken(
                token,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get client by domain
     */
    getClientDetailsByDomain = (data: { domain: string }) => {
        return new Observable((observer) => {
            this._bridge.getClientDetailsByDomain(
                data,
                (res) => {
                    res.idpType = res?.type;
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to authorize code
     */
    authorizeExternalIP = (
        data: {
            authURL: string;
            stateId: string;
            clientId: string;
            code: string;
            redirectUri: string;
            type: string;
        },
        callbacks?: {
            successCallback: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.authorizeExternalIP(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                }
            }
        );
    };

    /**
     * Method to authorize code
     */
    validateCodeExternally = (data: { domain: string; redirectURL: string; authCode: string; code: string }) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.validateCodeExternally(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get google authenticator secret key
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getGoogleAuthenticatorSecretKey = () => {
        return new Promise<{ secretKey: string }>((resolve, reject) => {
            this._bridge.getGoogleAuthenticatorSecretKey(
                (res) => {
                    resolve(res);
                },
                (res) => {
                    this._utils.alertError(res?.msg || translate('Failed to get authenticator secret key'));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get Google Authenticator QR Code
     * @param callbacks Contains Success callback method, Failure callback method
     */
    getGoogleAuthenticatorQRCode = () => {
        return new Promise<{ QRCode: string }>((resolve, reject) => {
            this._bridge.getGoogleAuthenticatorQRCode(
                (res) => {
                    resolve(res);
                },
                (res) => {
                    this._utils.alertError(res?.msg || translate('Failed to get authenticator QR Code'));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to set google authenticator secret key
     * @param callbacks Contains Success callback method, Failure callback method
     */
    setGoogleAuthenticatorSecretKey = () => {
        return new Promise<{ secretKey: string }>((resolve, reject) => {
            this._bridge.setGoogleAuthenticatorSecretKey(
                (res) => {
                    resolve(res);
                },
                (res) => {
                    this._utils.alertError(res?.msg || translate('Failed to set authenticator secret key'));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to submit google authentication code
     * @param callbacks Contains Success callback method, Failure callback method
     */
    submitGoogleAuthentication = (data: { OTP: string }, isFormSubmit?: boolean) => {
        return new Promise<{ secretKey: string }>((resolve, reject) => {
            this._bridge.submitGoogleAuthentication(
                data,
                isFormSubmit,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    this._utils.alertError(res?.msg || translate('Failed to submit authentication code'));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to generate email otp
     * @param callbacks Contains Success callback method, Failure callback method
     */
    generateEmailOTP = () => {
        return new Promise((resolve, reject) => {
            this._bridge.generateEmailOTP(
                (res) => {
                    resolve(res);
                    this._utils.alertSuccess(res && res?.msg);
                },
                (res) => {
                    this._utils.alertError(res?.msg || translate('Failed to generate email OTP'));
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to evaluate email otp
     * @param callbacks Contains Success callback method, Failure callback method
     */
    evaluateEmailOTP = (
        data: { value: string },
        isFormSubmit?: boolean,
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.evaluateEmailOTP(
            data,
            isFormSubmit,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                this._utils.alertError(res?.msg || translate('Failed to evaluate email OTP'));
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to submit LDAP client details
     */
    submitLDAPDetails = (data: { attribute: string; password: string; identityProviderId: string }) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.submitLDAPDetails(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response);
                    observer.complete();
                }
            );
        });
    };
}
