import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();
    error: string;

    constructor(private _router: Router, private _route: ActivatedRoute) {}

    redirectToLogin = () => {
        this._router.navigate(['auth']);
    };

    ngOnInit(): void {
        this._route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            this.error = event?.errorMsg;
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
