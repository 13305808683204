<div class="d-flex justify-content-between align-items-center matDialogBorderBottom">
    <h4 mat-dialog-title class="mat-dialog-title">TAXILLA ONLINE SERVICE</h4>
    <span class="material-icons mb-1" mat-dialog-close>close</span>
</div>
<mat-dialog-content class="mat-typography">
    <div class="EulaBody">
        <p class="terms-p">Thank you for selecting the Services offered by Udyog Software India Limited and/or its subsidiaries and affiliates (referred to as "USIL", "we", "our", or "us"). Review these Terms of Service ("Agreement") thoroughly. This Agreement is a legal agreement between you and USIL. By accepting electronically (for example, clicking “I Agree”), installing, accessing or using the Services, you agree to these terms. If you do not agree to this Agreement, then you may not use the Services.</p>
        <h3 class="terms-h3">A. GENERAL TERMS</h3>
        <h4 class="terms-h4">AGREEMENT</h4>
        <p class="terms-p">This Agreement describes the terms governing your use of the USIL online services provided to you on this website, including content, updates and new releases, (collectively, the “Services”). It includes by reference. USIL's Privacy Statement provided to you in the Services available on the website or provided to you otherwise. Additional Terms and Conditions, which may include those from third parties. Any terms provided separately to you for the Services, including product or program terms, ordering, activation, payment terms, etc.</p>
        <h4 class="terms-h4">YOUR RIGHTS TO USE THE SERVICES</h4>
        <p class="terms-p">The Services are protected by copyright, trade secret, and other intellectual property laws. You are only granted the right to use the Services and only for the purposes described by USIL. USIL reserves all other rights in the Services. Until termination of this Agreement and as long as you meet any applicable payment obligations and comply with this Agreement, USIL grants to you a personal, limited, nonexclusive, nontransferable right and license to use the Services.</p>
        <p class="terms-p">You agree not to use, nor permit any third party to use, the Services or content in a manner that violates any applicable law, regulation or this Agreement. You agree you will not.</p>
        <ul class="list_style">
            <li>Provide access to or give any part of the Services to any unauthorized third party.</li>
            <li>Reproduce, modify, copy, deconstruct, sell, trade or resell the Services.</li>
            <li>Make the Services available on any file-sharing or application hosting service.</li>
        </ul>
        <p class="terms-p">Reverse engineer, decompile, disassemble or otherwise attempt to derive the source code, techniques, processes, algorithms, know-how or other information from the binary code portions of the Taxilla Online Service (collectively, "Reverse Engineering") or permit or induce the foregoing. If however, directly applicable law prohibits enforcement of the foregoing, Licensee may engage in Reverse Engineering solely for purposes of obtaining such information as is necessary to achieve interoperability of independently created software with the Taxilla Online Service, or as otherwise and to the limited extent permitted by directly applicable law, but only if: (a) Reverse Engineering is strictly necessary to obtain such information; and (b) Licensee has first requested such information from USIL and USIL failed to make such information available (for a fee or otherwise) under reasonable terms and conditions. Any information supplied to or obtained by Licensee under this section is confidential information of USIL subject to the obligations of Section 15, may only be used by Licensee for the purpose described in this section, and will not be disclosed to any third party or used to create any software/service which is substantially similar to the expression of the Taxilla Online Service Technology.</p>
        <h4 class="terms-h4">PAYMENT</h4>
        <p class="terms-p">For Services offered on a payment or subscription basis, the following terms apply, unless USIL or its vendor(s) notifies you otherwise in writing. This Agreement also incorporates by reference and includes program ordering and payment terms provided to you on the website for the Services.</p>
        <p class="terms-p">Payments will be billed to you by USIL and/or its vendor(s) in Indian National Rupee (INR), or other currencies which may be made available (plus any and all applicable taxes, including without limitation VAT and/or any other tax wherever applicable) as shown in the product ordering and subscription terms, and your account will be debited when you subscribe and provide your payment information, unless stated otherwise in the program ordering or payment terms on the website for the Services.</p>
        <p class="terms-p">You must pay with one of the following.</p>
        <ul class="list_style">
            <li>A valid credit card acceptable to USIL and/or its vendor(s);</li>
            <li>A valid debit card acceptable to USIL and/or its vendor(s);</li>
        </ul>
        <p class="terms-p">In cases, where accepted by USIL in writing, sufficient funds in a checking or savings account to cover an electronic debit of the payment due;</p>
        <p class="terms-p">Or</p>
        <ul class="list_style">
            <li>By another payment option USIL and/or its vendor(s) provides to you in writing.</li>
        </ul>
        <p class="terms-p">If your payment and registration information is not accurate, current, and complete and you do not notify us promptly when such information changes, we may suspend or terminate your account and refuse any use of the Services.</p>
        <p class="terms-p">If you do not notify us of updates to your payment method (e.g., credit card expiration date), to avoid interruption of your service, USIL and/or its vendor(s) may participate in programs supported by your card provider (e.g., updater services, recurring billing programs, etc.) to try to update your payment information, and you authorize us to continue billing your account with the updated information that we obtain.</p>
        <ul class="list_style">
            <li>USIL and/or its vendor(s) will automatically renew your monthly, quarterly, or annual Services at the current rates, unless the Services are cancelled or terminated under this Agreement.</li>
            <li>Additional cancellation or renewal terms, if any may be provided to you on the website for the Services.</li>
            <li>All authorized refunds will be made in the original form of payment to USIL or its vendor(s).</li>
        </ul>
        <h4 class="terms-h4">REFUND POLICY</h4>
        <p class="terms-p">USIL has made a free __ day trial available in order to enable you to experience the product prior to purchase, and fully satisfy yourself as to its suitability for your business. During this period and thereafter, all possible assistance is made available to you, including free online training and technical support. However, if you are not satisfied with the product for any reason, USIL will refund the amount paid towards your subscription, only for the unused part of the subscription and thereby calculating in a pro-rata manner.</p>
        <h4 class="terms-h4">USE WITH YOUR MOBILE DEVICE</h4>
        <p class="terms-p">Use of these Services may be available through a compatible mobile device using Internet access and may require software or an application. You agree that you are solely responsible for these requirements, including any applicable changes, updates and fees for SMS messages, data plans, and general usage, as well as the terms of your agreement with your mobile device and telecommunications provider.</p>
        <p class="terms-p">USIL MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO.</p>
        <p class="terms-p">THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY LOCATION;</p>
        <p class="terms-p">ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES; AND</p>
        <p class="terms-p">ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE SERVICES.</p>
        <h4 class="terms-h4">YOUR PERSONAL INFORMATION</h4>
        <p class="terms-p">You can view USIL’s Privacy Statement provided with the Services and on the website for the Services. You agree to the applicable USIL Privacy Statement, and any changes published by USIL. You agree that USIL may use and maintain your data according to the USIL Privacy Statement, as part of the Services. You give USIL permission to combine information you enter or upload for the Services with that of other users of the Services and/or other USIL services. For example, this means that USIL may use your and other users’ non-identifiable, aggregated data to improve the Services or to design promotions and provide ways for you to compare business practices with other users. USIL is a global company and may access or store personal information in the locations it operates, including countries outside of your own country to the extent permitted by applicable law.</p>
        <h4 class="terms-h4">CONTENT</h4>
        <p class="terms-p">You are responsible for your content. You are responsible for all materials ("Content") uploaded, posted or stored through your use of the Services. Archive your Content frequently. You grant USIL a worldwide, royalty-free, non-exclusive license to host and use any Content provided through your use of the Services. You are responsible for any lost or unrecoverable Content. You must provide all required and appropriate warnings, information and disclosures. USIL is not responsible for the Content or data you submit through the Services.</p>
        <p class="terms-p">You agree not to use, nor permit any third party to use, the Services to upload, post, distribute, link to, publish, reproduce, engage in or transmit any of the following, including but not limited to.</p>
        <ul class="list_style">
            <li>Illegal, fraudulent, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, harassing, offensive, inappropriate or objectionable information or communications of any kind, including without limitation conduct that would encourage "flaming" others, or criminal or civil liability under any local, state, federal or foreign law;</li>
            <li>Content that would impersonate someone else or falsely represent your identity or qualifications, or that constitutes a breach of any individual’s privacy;</li>
            <li>Except as permitted by USIL in writing, investment opportunities, solicitations, chain letters, pyramid schemes, other unsolicited commercial communication or engage in spamming or flooding;</li>
            <li>Virus, Trojan horse, worm or other disruptive or harmful software or data; and</li>
            <li>Any information, software or Content which is not legally yours and without permission from the copyright owner or intellectual property rights owner.</li>
        </ul>
        <p class="terms-p">USIL may freely use feedback you provide. You agree that USIL may use your feedback, suggestions, or ideas in any way, including in future modifications of the Services, other products or services, advertising or marketing materials. You grant USIL a perpetual, worldwide, fully transferable, sub licensable, non-revocable, fully paid-up, royalty free license to use the feedback you provide to USIL in any way.</p>
        <p class="terms-p">USIL may monitor your Content. USIL may, but has no obligation to, monitor content on the Services. We may disclose any information necessary to satisfy our legal obligations, protect USIL or its customers, or operate the Services properly. USIL, in its sole discretion, may refuse to post, remove, or refuse to remove, any Content, in whole or in part, alleged to be unacceptable, undesirable, inappropriate, or in violation of this Agreement.</p>
        <h4 class="terms-h4">ADDITIONAL TERMS</h4>
        <p class="terms-p">USIL does not give professional advice. Unless specifically included with the Services, USIL is not in the business of providing any advisory services, such as legal, financial, accounting, tax or any such advisory services. Consult the services of a competent professional when you need this type of assistance.</p>
        <p class="terms-p">We may tell you about other USIL services. You may be offered other services, products, or promotions by USIL ("USIL Services"). Additional terms and conditions and fees may apply. With some USIL Services you may upload or enter data from your account(s) such as names, addresses and phone numbers, purchases, etc., to the Internet. You grant USIL permission to use information about your business and experience to help us to provide the USIL Services to you and to enhance the Services. You grant USIL permission to combine your business data, if any, with that of others in a way that does not identify you or any individual personally. You also grant USIL permission to share or publish summary results relating to research data and to distribute or license such data to third parties.</p>
        <p class="terms-p">Communications. USIL may send you communications about the Services or Third Party Products. You agree that USIL may send these communications to you via email or mail or by any other medium convenient to USIL.</p>
        <p class="terms-p">You will manage your passwords and accept updates. You are responsible for securely managing your password(s) for the Services and to contact USIL if you become aware of any unauthorized access to your account. The Services may periodically be updated with tools, utilities, improvements, third party applications, or general updates to improve the Services. You agree to receive these updates.</p>
        <h4 class="terms-h4">DISCLAIMER OF WARRANTIES</h4>
        <p class="terms-p">YOUR USE OF THE SERVICES AND CONTENT IS ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS AGREEMENT, THE SERVICES ARE PROVIDED "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, USIL, ITS AFFILIATES, AND ITS THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY,"SUPPLIERS") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY THAT THE SERVICES ARE FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, DATA LOSS, NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OR CONTENT IN OR LINKED TO THE SERVICES. USIL AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SERVICES ARE SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER.</p>
        <p class="terms-p">USIL, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.</p>
        <p class="terms-p">YOU ARE SOLELY RESPONSIBLE FOR ENSURING THAT YOUR USE OF THE SERVICES IS IN ACCORDANCE WITH APPLICABLE LAW. NOTWITHSTANDING THE FOREGOING, NO PROVISION OF THIS AGREEMENT SHALL EXCLUDE OR LIMIT LIABILITY TO THE EXTENT THAT SUCH EXCLUSION OR LIMITATION IS PROHIBITED BY THE APPLICABLE LAWS OF REPUBLIC OF INDIA AND FOR THE AVOIDANCE OF DOUBT USIL DOES NOT EXCLUDE LIABILITY FOR. (I) DEATH OR PERSONAL INJURY CAUSED BY ITS NEGLIGENCE OR THE NEGLIGENCE OF ITS OFFICERS, EMPLOYEES, CONTRACTORS OR AGENTS; (II) FRAUD OR FRAUDULENT MISREPRESENTATION; (III) ANY OTHER LIABILITY WHICH CANNOT BE LAWFULLY EXCLUDED BY CONTRACTUAL AGREEMENT OF THE PARTIES.</p>
        <h4 class="terms-h4">LIMITATION OF LIABILITY AND INDEMNITY</h4>
        <p class="terms-p">TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF USIL, ITS AFFILIATES AND SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM. SUBJECT TO APPLICABLE LAW, USIL, ITS AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR ANY OF THE FOLLOWING. (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OF TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES, SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF SOFTWARE OR HARDWARE THAT DOES NOT MEET USIL SYSTEMS REQUIREMENTS. THE ABOVE LIMITATIONS APPLY EVEN IF USIL AND ITS AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF USIL, ITS AFFILIATES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS USE.</p>
        <p class="terms-p">You agree to indemnify and hold USIL and its Affiliates and Suppliers harmless from any and all claims, liability and expenses, including reasonable attorneys' fees and costs, arising out of your use of the Services or breach of this Agreement (collectively referred to as "Claims"). USIL reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any Claims. You agree to reasonably cooperate as requested by USIL in the defense of any Claims.</p>
        <h4 class="terms-h4">CHANGES</h4>
        <p class="terms-p">We reserve the right to change this Agreement at any time, and the changes will be effective when posted through the Services, on our website for the Services or when we notify you by other means. We may also change or discontinue the Services, in whole or in part. Your continued use of the Services indicates your agreement to the changes.</p>
        <h4 class="terms-h4">TERMINATION</h4>
        <p class="terms-p">USIL may immediately, in its sole discretion and without notice terminate this Agreement or suspend the Services if you fail to comply with this Agreement or if you no longer agree to receive electronic communications. Upon termination you must immediately stop using the Services and any outstanding payments will become due. Any termination of this Agreement shall not affect USIL’s rights to any payments due to it. USIL may terminate a free account at any time. Sections A.2.2, A.3 through 15 will survive and remain in effect even if the Agreement is terminated.</p>
        <h4 class="terms-h4">GOVERNING LAW AND JURISDICTION.</h4>
        <p class="terms-p">Governing Law; Jurisdiction and Venue. This Agreement shall be governed by and construed and enforced in accordance with the laws of the Republic of India without reference to conflicts of law rules and principles. To the extent permitted by law, the provisions of this Agreement shall supersede any provisions of the law as enacted in any competent jurisdiction. This Agreement shall not be governed by the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded. You agree that this Agreement is to be performed in the Republic of India. and that any action, dispute, controversy, or claim that may be instituted based on this Agreement, or arising out of or related to this Agreement or any alleged breach thereof, shall be subjected to Arbitration as stated in clause 12.2, hereby waive the right to change venue to any other state or jurisdiction other than that of claimed in the clause 12.2.</p>
        <p class="terms-p">Arbitration. Any controversy or claim or dispute arising out of or relating to the Terms shall be settled by binding arbitration in accordance with the commercial arbitration rules of the Indian Arbitration Association. Any such controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party. The decision of the arbitrator shall be final and unappealable. The arbitration shall be conducted in Hyderabad, Telangana and judgment on the arbitration award may be entered into any court having jurisdiction thereof. Notwithstanding anything to the contrary, Udyog Software India Limited may at any time seek injunctions or other forms of equitable relief from any court of competent jurisdiction.</p>
        <h4 class="terms-h4">LANGUAGE</h4>
        <p class="terms-p">Any translation of this Agreement is done for local requirements and in the event of a dispute between the English and any non-English version, the English version of this Agreement shall govern. In the event of a dispute the parties confirm that they have requested that this Agreement and all related documents be drafted in English.</p>
        <h4 class="terms-h4">Confidential Information.</h4>
        <p class="terms-p">You agree that, unless otherwise specifically provided herein the Product, including the specific design and structure of individual programs and the Product, constitute confidential proprietary information of USIL or its suppliers. You agree not to transfer, copy, disclose, provide or otherwise make available such confidential information in any form to any third party. You agree to implement reasonable security measures to protect such confidential information. If you download the Software from the Internet or similar online source or if you access the Taxilla Online Service, you must include the copyright notices resident on the Software/Service with any online distribution and on any media you distribute that includes the Software/Access to service.</p>
        <h4 class="terms-h4">GENERAL</h4>
        <p class="terms-p">This Agreement, including the Additional Terms below, is the entire agreement between you and USIL and replaces all prior understandings, communications and agreements, oral or written, regarding its subject matter. If any court of law, having jurisdiction, rules that any part of this Agreement is invalid, that section will be removed without affecting the remainder of the Agreement. The remaining terms will be valid and enforceable. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement. You cannot assign or transfer ownership of this Agreement to anyone without written approval of USIL. However, USIL may assign or transfer it without your consent to (a) an affiliate, (b) a company through a sale of assets by USIL or (c) a successor by merger. Any assignment in violation of this Section shall be void. If you want to request a transfer of this Agreement, contact USIL via an email to. _____________@USIL.com.</p>
        <h3 class="terms-h3">B. ADDITIONAL TERMS AND CONDITIONS FOR THE SERVICES</h3>
        <p class="terms-p">Your use of the Services provided by USIL are subject to the general Terms of Service above including these Additional Terms and Conditions which govern your use of the Services indicated below. To the extent of any conflict or inconsistency with the general Terms of Service above, these Additional Terms and Conditions shall prevail.</p>
        <p class="terms-p">Taxilla Online Supplemental Agreement and Terms of Service</p>
        <p class="terms-p">Thank you for selecting Taxilla Online (“TAXILLA Online Service”). This licence agreement in addition to the USIL Terms of Service (the “Agreement”) is a legal agreement between the User, a single legal entity identified in the registration process provided as part of the start-up process (“User”, “you”), and Udyog Software India Limited. and/or its subsidiaries and affiliates (“USIL,” “we”, “our” or “us”).</p>
        <p class="terms-p">By selecting “ACCEPT” or by “ACCESSING OR OTHERWISE USING THE TAXILLA ONLINE SERVICE,” You indicate that you have read and understood, and assent to be bound by, the terms of this Agreement. If the person selecting “ACCEPT” or is using/accessing “THE TAXILLA ONLINE SERVICE” is an individual working for you (“Agent”), such person is agreeing to the terms and conditions of this Agreement on behalf of you and represents and warrants to USIL that he/she has full power and authority to enter into this Agreement on your behalf.</p>
        <p class="terms-p">If you do not agree to the terms of the Agreement, you are not granted any rights whatsoever in the Services. If you are not willing to be bound by these terms and conditions, you should not select “ACCEPT,” and may not access or otherwise use the TAXILLA Online Services.</p>
        <p class="terms-p">SUBSCRIPTION. The TAXILLA Online Service is licenced on a monthly or yearly subscription basis, as selected by you or your agent.</p>
        <p class="terms-p">TRIAL VERSION AND BETA FEATURES. If you registered for a trial use of the TAXILLA Online Service ("Trial Period"), you must decide to purchase a license to the TAXILLA Online Service, at the current rate, within the Trial Period in order to retain any Content that you have entered through the TAXILLA Online Services, created within the data file, posted or uploaded during the Trial Period. If you do not purchase a license to the TAXILLA Online Services by the end of the Trial Period, your Content will no longer be available to you. To be very clear, after using the TAXILLA Online Service during the trial period, if you decide not to purchase the license to the full version of the TAXILLA Online Service, you will not be able to access or retrieve any of the data you added or created with the Services during the trial.</p>
        <p class="terms-p">From time to time, USIL may, at its sole discretion, include new and/or updated beta features (“Beta Features”) in the TAXILLA Online Service for your use and which permit you to provide feedback. You understand and agree that your use of the Beta Features, if any is voluntary and USIL is not obligated to provide you with any Beta Features. The Beta Features, if any are provided on an “as is” basis and may contain errors or inaccuracies that could cause failures, corruption or loss of data and/or information from any connected device. You acknowledge and agree that all use of the Beta Features, if any is at your sole risk.</p>
        <p class="terms-p">PERMITTED DISCLOSURES AND USE OF DATA. You acknowledge and agree that in order to provide you with access to and use of the TAXILLA Online Services, USIL may provide your access information and account data to (i) your employee or agent who is identified in the registration data as the Power User for your account (the “Power User”), and (ii) such other employee or agent who may be designated by you as a replacement power user for the your account by following the procedures required by USIL to effectuate such replacement. Any other person you identified as an authorized user of the TAXILLA Online Services will have access to the account data subject to the access permissions you or the Power User assigns to them.</p>
        <h4 class="terms-h4">ACCESS TO YOUR FINANCIAL INSTITUTION SERVICES DATA.</h4>
        <p class="terms-p">General. In connection with your use of the TAXILLA Online Service and as part of the functionality of certain versions of the TAXILLA Online Services, you may wish to have access to your online account(s) and financial information, including your account access number(s), password(s), security question(s) and answer(s), account number(s), login information, and any other security or access information used to access your financial institution(s) (collectively, your “FI Login Data”) and the data made available by your financial institutions(s) with such data, which may include transaction activity, financial data and any messages or notices between you and the financial institution(s) (“FI Account Data”). The TAXILLA Online Services are designed to allow you to access and download your FI Account Data through the TAXILLA Online Services, to allow USIL to access your financial institution account(s) using your FI Login Data, to allow USIL to download and use your FI Account Data, and to allow USIL to aggregate and combine your FI Account Data with other data. If you lose or forget your user name or password, it will be necessary for you to return to the appropriate financial institution if you have any problems with respect to that user name or password.</p>
        <p class="terms-p">You acknowledge and agree that except as set forth in this Agreement, USIL has no control over your FI Login Data and no control over the access to your FI Account Data, does not guarantee that you will be able to use the TAXILLA Online Service with your financial institution(s), and will have no liability whatsoever for any actions or inactions on the part of the financial institution(s) resulting in your inability to use the TAXILLA Online Service to access your accounts, obtain data, download transactions, or otherwise use or access your FI Account Data.</p>
        <p class="terms-p">Collection of FI Login Data and FI Account Data. By agreeing to these terms and conditions, you.</p>
        <p class="terms-p">acknowledge that in accessing your financial institution account(s) through the USIL Taxilla Online Service, your FI Login Data and FI Account Data may be collected, converted, stored in encrypted form and used by USIL for the purpose of providing the TAXILLA Online Service;</p>
        <p class="terms-p">authorize USIL to (i) collect and store in encrypted form your FI Login Data, (ii) download and store your FI Account Data; (iii) reformat and manipulate your FI Account Data; (iv) create and provide hypertext links to your financial institution(s) FI Account Data; (v) enhance the type of data and services we can provide to you in the future, and (vi) take such other actions as are reasonably necessary to perform the actions described in this Agreement as it relates to your use of the \ TAXILLA Online Service;</p>
        <p class="terms-p">hereby represent that the financial institution(s)’ account(s) and FI Login Data belong to you, you have the right to use the FI Login Data and FI Account Data as set out above and that you have the authority to appoint, and hereby expressly do appoint, USIL as your agent with all necessary power and authority to use your FI Login Data and to access and retrieve your FI Account Data, as described above, on your behalf;</p>
        <p class="terms-p">acknowledge that USIL does not review your FI Account Data and agree that USIL is not responsible for its completeness or accuracy;</p>
        <p class="terms-p">acknowledge that you are solely responsible for any charges, fees or costs associated with your financial institution account(s) when accessed through the TAXILLA Online Service by you.</p>
        <p class="terms-p">TAXILLA ONLINE SERVICE USE, STORAGE AND ACCESS. USIL shall have the right, in its sole discretion and with reasonable notice posted on the TAXILLA Online Service site and/or sent to you at the Power User’s email address provided in the Registration Data, to revise, update, or otherwise modify the TAXILLA Online Service and establish or change limits concerning use of the TAXILLA Online Service, temporarily or permanently, including but not limited to (i) the amount of storage space you have on the TAXILLA Online Service at any time, and (ii) the number of times (and the maximum duration for which) you may access the TAXILLA Online Service in a given period of time. USIL reserves the right to make any such changes effective immediately to maintain the security of the system or User Access Information or to comply with any laws or regulations, and to provide you with electronic or written notice within thirty (30) days after such change. You may reject changes by discontinuing use of the TAXILLA Online Service to which such changes relate. Your continued use of the TAXILLA Online Service will constitute your acceptance of and agreement to such changes. USIL may, from time to time, perform maintenance upon the TAXILLA Online Service resulting in interrupted service, delays or errors in the TAXILLA Online Service. USIL will attempt to provide prior notice of scheduled maintenance but cannot guarantee that such notice will be provided.</p>
        <p class="terms-p">CANCELLATION. Upon cancellation you will be able to access the TAXILLA Online Service only through the end of the subscription term, as specified in the product or product program pages. After the subscription term ends, you will not have any access to the TAXILLA Online Service. There are no refunds upon cancellation. Please follow product instructions to cancel your account.</p>
        <p class="terms-p">SOCIAL MEDIA SITES. USIL may provide experiences on social media platforms such as Facebook®, Twitter® and LinkedIn® that enable online sharing and collaboration among anyone who has registered to use them. Any content you post, such as pictures, information, opinions, or any Personal Information that you make available to other participants on these social platforms, is subject to the Terms of Use and Privacy Policies of those platforms. Please refer to those social media platforms to better understand your rights and obligations with regard to such content.</p>
        <p class="terms-p">THIRD PARTY PRODUCTS AND SERVICES. USIL may tell you about third party products or services, including via the Service. USIL may offer products and services on behalf of third parties who are not affiliated with USIL (“Third Party Products”) and/or provide access or links to third party websites ("Third Party Sites"). If you decide to use any Third Party Products or access any Third Party Sites, you are solely responsible for your selection, review of separate product terms, website terms and privacy policies. . USIL is not affiliated with these Third Party Products or Third Party Sites and does not endorse or recommend Third Party Products even if such products are marketed or distributed via our products, website or associated with USIL in any way. You agree that the third parties, and not USIL, are solely responsible for the Third Party Product’s performance (including technical support), the content on their websites and their use or disclosure of your data. USIL will not be liable for any damages, claims or liabilities arising from the third parties, Third Party Products or Third Party Sites.</p>
        <p class="terms-p">You agree that you will (i) comply with all applicable laws, regulation and ordinances; (ii) not use the Third Party Products in any manner that would infringe or violate the rights of USIL or any other party; and (iii) not use the Third Party Products in any way in furtherance of criminal, fraudulent or other unlawful activity.</p>
        <p class="terms-p">© UDYOG SOFTWARE 2015. ALL RIGHTS RESERVED.</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <material-button [label]="'Accept'" (onTrigger)="acceptEula(); closeBtnAction()"></material-button>
</mat-dialog-actions>
