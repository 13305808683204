import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ClientDetails } from '../../interface/client-details.interface';
import { AUTH_REDUCER_KEY, RESET_PASSWORD_REDUCER_KEY } from '../reducers';
import { AuthInitialState, ResetPasswordInitialState } from '../states';

export const selectAuthState = createFeatureSelector<AuthInitialState>(AUTH_REDUCER_KEY);

export const selectResetPasswordState = createFeatureSelector<ResetPasswordInitialState>(RESET_PASSWORD_REDUCER_KEY);

/**
 * Client Details
 */
export const getClientDetailsState = createSelector(selectAuthState, (state): ClientDetails => state?.client?.clientDetails);

export const getClientDetailsFailedState = createSelector(
    selectAuthState,
    (state): AuthInitialState['client']['errorResponse'] => state?.client?.errorResponse
);

/**
 * Internal Auth Code Validation
 */
export const getInternalAuthTokenDetailsState = createSelector(selectAuthState, (state) => state?.token?.details);

export const getApplicationProperties$ = createSelector(selectAuthState, (state) => state?.applicationProperties);

/**
 * Get Security Questions
 */
export const GetSecurityQuestionsSuccessState = createSelector(selectResetPasswordState, (state) => state?.security?.secretQuestions);
/**
 * Get Captcha
 */
export const fetchCaptchaSuccessState = createSelector(selectResetPasswordState, (state) => state?.captcha);
/**
 * Get Context
 */
export const getContextSuccessState = createSelector(selectResetPasswordState, (state) => state?.context);

export const has4ECAccess$ = createSelector(
    selectAuthState,
    (state) => state?.applicationProperties?.['foureyecheck.enabled'] && state.is4ecEnabled
);
