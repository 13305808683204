<div [ngSwitch]="cellMeta?.datatype" [class.hasError]="cell.errors?.length > 0" [matTooltip]="cell.errors?.[0]">
    <ng-container *ngSwitchCase="(cellMeta?.datatype === 'STRING' || cellMeta?.datatype === 'INT' || cellMeta?.datatype === 'LONG' || cellMeta?.datatype === 'DOUBLE')  ? cellMeta?.datatype : ''">
        <ng-container *ngIf="!disabled; else readOnlyCell">
            <input class="w-full" type="text" [(ngModel)]="cell.value" [debounce]="1000" />
        </ng-container>
        <ng-template #readOnlyCell>
            {{ cell?.value }}
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'DATE'">
        <ng-container *ngIf="!disabled; else readOnlyCell">
            <material-input-date [formFieldClass]="'datefield'" [title]="cellMeta.outputFormat" [model]="cell.value" [errors]="[]" [dateFormat]="cellMeta.outputFormat" [debounceAfter]="2000" [inputOnlyDisabled]="true" (modelChange)="cell.value = $event;">
            </material-input-date>
        </ng-container>
        <ng-template #readOnlyCell>
            {{ cell?.value }}
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'BOOLEAN'">
        <ng-container *ngIf="!disabled; else readOnlyCell">
            <div class="relative">
                <select class="w-full h-full bg-white pl-1 pr-8 py-0.5" [(ngModel)]="cell.value">
                    <option [value]="''">--Select--</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                </select>
            </div>
        </ng-container>
        <ng-template #readOnlyCell>
            {{ cell?.value !== undefined ? cell.value : "--"}}
        </ng-template>
    </ng-container>
</div>
